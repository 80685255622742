import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {Lightbox} from 'angular2-lightbox';

declare let swal: any;
declare let Ladda: any;
declare let toastr: any;
declare let FullCalendar: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;


@Component({
    selector: 'page-calendar',
    templateUrl: './calendar.component.html'
})
export class FullCalendarComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Permission = -1;
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true};

    currentUser = null;

    currentView = 'Calendar view';
    currentDate;
    currentWeek = null;
    currentMonth;
    currentYear;

    constructor(private _lightbox: Lightbox) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.Permission = this.currentUser.user_type > 2 ? this.currentUser.modules.calendar : 1;

        let d = new Date();
        let m = d.getMonth();
        let y = d.getFullYear();
        this.currentMonth = m;
        this.currentYear = y;

        this.shortLinkShared = this.currentUser.calendar;
        // this.shareCalendarUrl = this.APPCONFIG.apiRoot+'general/calendar/'+this.currentUser.team+'/calendar.ics';
        this.shareCalendarUrl = this.APPCONFIG.shortLink+'/c/'+btoa(this.currentUser.modules.group_id);

        setTimeout(function () {
            THIS.renderCalendar();
        }, 1000);


        $('body').on('click', '.___dayView', function () {
            let time = $('.___dayView').attr('data-type');
            THIS.Calendar.changeView('listDay', new Date(time));
            THIS.currentView = 'List';
        });
    }

    Calendar;
    changeCalendar = 0;
    renderCalendar() {
        let THIS = this;
        let calendarEl = document.getElementById('calendar');
        THIS.Calendar = new FullCalendar.Calendar(calendarEl, {
            contentHeight: window.innerHeight - 91,
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dayNamesShort: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            timeZone: 'local',
            columnHeader: true,
            views: {
                dayGridMonth: {
                    columnHeaderFormat: { weekday: 'short'},
                },
                timeGridWeek: {
                    columnHeaderText: function(date) {
                        return THIS.CustomHelper.dateDynamicFormatFC(date);
                    }
                }
            },
            plugins: ['dayGrid', 'timeGrid', 'list', 'interaction'],
            header: {
                left: '',
                // left: 'today',
                center: 'prev,title,next',
                // right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                right: ''
            },
            navLinks: true,
            editable: true,
            eventLimit: true,
            datesRender: function (info) {
                if (info.view.type == 'dayGridDay') {
                    THIS.DayViewTimeGrid(info.view.activeStart);
                } else {
                    if (THIS.changeCalendar == 0) {
                        let nowD = THIS.Calendar.getDate();
                        let view = THIS.Calendar.view;
                        THIS.currentDate = nowD.getDate();
                        THIS.currentWeek = {
                            start: THIS.CustomHelper.formatDateOnlyToDB(view.activeStart) + ' 00:00:00',
                            end: THIS.CustomHelper.formatDateOnlyToDB(view.activeEnd) + ' 00:00:00'
                        };
                        THIS.currentMonth = nowD.getMonth();
                        THIS.currentYear = nowD.getFullYear();
                        THIS.changeCalendar = 1;
                        THIS.RenderEvents();
                        if (view.type == 'dayGridDay') {
                            THIS.currentView = 'Day';
                        }
                    }
                }
            },
            eventRender: function (info) {
                let status_code = info.event._def.extendedProps.status_code;
                let statusIcon = '<i class="material-icons __cl-jts">' + THIS.CustomHelper.workStatusIcon(status_code) + '</i>';
                // fc-list-item-title
                if ($(info.el).find('.fc-list-item-title').length > 0) {
                    $(info.el).find('.fc-list-item-title').prepend(statusIcon);
                } else {
                    $(info.el).append(statusIcon);
                }
            },
            eventDrop: function (info) {
                let param = {
                    id: info.event.id,
                    created_at: THIS.CustomHelper.formatDateTimeToDB(info.event.start),
                    due: THIS.CustomHelper.formatDateTimeToDB(info.event.end),
                };
                if (param.due == null || param.due == '') {
                    param.due = param.created_at;
                }
                THIS.updateWorkOrder(param);
            },
            eventResize: function (info) {
                let param = {
                    id: info.event.id,
                    created_at: THIS.CustomHelper.formatDateTimeToDB(info.event.start),
                    due: THIS.CustomHelper.formatDateTimeToDB(info.event.end),
                };
                if (param.due == null || param.due == '') {
                    param.due = param.created_at;
                }
                THIS.updateWorkOrder(param);
            },
            eventClick: function (info) {
                THIS.getDateWorkOrder({uid: info.event.extendedProps.uid, type: info.event.extendedProps.type});
            }
        });
        THIS.Calendar.render();
    }

    removeAllEvents() {
        let THIS = this;
        let events = THIS.Calendar.getEvents();
        $.each(events, function (i, v) {
            let id = v.id;
            let ev = THIS.Calendar.getEventById(id);
            ev.remove();
        });
    }

    interval = null;
    TotalDesc = 0;
    Done = 0;
    TotalProgress(){
        let progress = 0;
        if(this.TotalDesc > 0){
            progress = this.Done/this.TotalDesc;
            progress = progress * 100;
            progress = this.CustomHelper.makeInt(progress);
            if(progress > 100){
                progress = 100;
            }
        }
        return progress;
    }

    RenderEvents() {
        let THIS = this;
        let param = {
            view: this.Calendar.view.type,
            date: this.currentDate,
            week: this.currentWeek,
            month: this.currentMonth + 1,
            year: this.currentYear,
        };
        THIS.removeAllEvents();
        if (THIS.interval != null) {
            clearInterval(THIS.interval);
        }
        THIS.TotalDesc = 0;
        THIS.Done = 0;
        $.ajax({
            url: THIS.APPCONFIG.api + 'calendar/work/list?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                THIS.changeCalendar = 0;
                if (res.status == 2000) {
                    if (res.data.length > 0) {
                        THIS.TotalDesc = res.data.length;

                        if (res.data.length > 20) {
                            let done  = 0;
                            let loop = 0;
                            let batch = 20;
                            let loopTotal = THIS.CustomHelper.makeInt(THIS.CustomHelper.makeInt(res.data.length)/THIS.CustomHelper.makeInt(batch))+1;
                            let interval = setInterval(function () {
                                $.each(res.data, function(i, v){
                                    if(THIS.CustomHelper.makeInt(loop*batch) <= THIS.CustomHelper.makeInt(i)  && THIS.CustomHelper.makeInt(i) < THIS.CustomHelper.makeInt(batch*(loop+1))){
                                        THIS.Calendar.addEvent(v);
                                        THIS.Done++;
                                    }
                                });
                                loop++;
                                if(loopTotal == loop){
                                    clearInterval(interval);
                                }
                            }, 1000);
                        } else {
                            $.each(res.data, function (i, v) {
                                v['className'] = 'animated fadeIn';
                                THIS.Calendar.addEvent(v);
                                THIS.Done++;
                            });
                        }


                    } else {
                        $('.fc-list-empty').show();
                    }
                    setTimeout(function () {
                        $('.fc-scroller').animate({scrollTop: 0}, 400);
                    }, 100);
                }
            }
        });
    }

    updateWorkOrder(param) {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'calendar/work/update?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                THIS.changeCalendar = 0;
                if (res.status == 2000) {
                    toastr.success('Work Order Start & Due date has been updated', 'Calendar Update', THIS.toastrOption);
                }
            }
        });
    }

    workDetails = null;
    _albums = [];
    taskType = 1; // 1.Tasks  2.Inspections
    selectedFilesPreview = [];
    selectedWorkAttach = [];
    add2Calendar = 0;
    calendarStatus = '_blue';

    getDateWorkOrder(param) {
        let THIS = this;
        THIS.workDetails = null;

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
        let child = ___selectedTab+1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
        (<any>$('#_pop_wo_workOrderModal')).modal('show');
        let url = '';
        if(param.type == 1){
            THIS.SubType = 1;
            url = THIS.APPCONFIG.api + 'work-orders/single?token=' + THIS.currentUser.token;
        } else {
            THIS.SubType = 2;
            url = THIS.APPCONFIG.apiInc + 'get-single?token=' + THIS.currentUser.token;
        }

        $.ajax({
            url: url,
            type: 'post',
            data: {uid: param.uid},
            success: function (res) {
                if (res.status == 2000) {
                    let work = res.data;
                    work['modType'] = param.type;
                    THIS.workDetails = work;
                    THIS.workDetails.dates.creation_date = THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.creation_date);
                    THIS.workDetails.dates.edited_date = THIS.workDetails.dates.edited_date != "" && THIS.workDetails.dates.edited_date != null ? THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.edited_date) : "";
                    THIS.workDetails.dates.created_at = THIS.CustomHelper.formatDateTime(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
                    THIS.workDetails.dates.due_at = THIS.CustomHelper.formatDateTime(work.dates.due_at != null && work.dates.due_at.date != undefined ? work.dates.due_at.date : work.dates.due_at);

                    THIS.workDetails.facStr = THIS.workDetails.facStr.reverse();
                    THIS.workDetails.progress = THIS.workDetails.progress > 100 ? 100 : THIS.workDetails.progress;
                    THIS._albums.length = 0;
                    if(work.modType == 1){
                        if (THIS.workDetails.media_files !== undefined) {
                            for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
                                const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path;
                                const caption = '';
                                const thumb = '';
                                const album = {
                                    src: src,
                                    caption: caption,
                                    thumb: thumb,
                                    w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
                                    h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
                                };
                                THIS._albums.push(album);
                            }
                        }
                        if (work.tasks.length > 0) {
                            THIS.taskType = work.tasks[0].type;
                        }
                    }
                    setTimeout(function () {
                        if(work.modType == 1){
                            (<any>$("#summerDesc")).html(work.description);
                        }
                        $('.tooltip').remove();
                        (<any>$('[data-toggle="tooltip"]')).tooltip('destroy');
                        setTimeout(function () {
                            THIS.CustomHelper.tooltipRender();
                        }, 100);
                    }, 100);


                    THIS.add2Calendar = work.calendar.add_to_calendar;
                    THIS.calendarStatus = work.calendar.calendar_color == '' || work.calendar.calendar_color == null ? '_blue' : work.calendar.calendar_color;
                    THIS.selectedFilesPreview = work.media_files;
                    THIS.selectedWorkAttach = work.attach_files;
                }
            }
        });
    }

    open(index: number): void {
        let THIS = this;
        let pswpElement = document.querySelectorAll('.pswp')[0];
        let options = {
            index: index,
            shareEl: false,
            tapToClose: false,
            zoomEl: true
        };
        let gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, THIS._albums, options);
        gallery.init();
        history.pushState(null, null, location.href);
        window.onpopstate = function () {
            gallery.close();
            history.go(1);
        };
    }

    pro2work = 0;
    workDel = null;

    openWorkOrder(work) {
        let THIS = this;
        this.workDel = work;
        this.pro2work = 1;
        setTimeout(function () {
            THIS.workDel = null;
            THIS.pro2work = 0;
        }, 5000);
        (<any>$('#_pop_wo_workOrderModal')).modal('hide');
    }

    rightSecHide() {
        $('._pop_wo_content_right').removeClass('__openIt');
    }

    rightSecShow() {
        $('._pop_wo_content_right').addClass('__openIt');
    }


    DayView(time) {
        if (time != undefined) {
            time = this.CustomHelper.formatDateOnlyToDB(parseFloat(time));
            let view = this.Calendar.view;
            if (view.type == 'timeGridDay') {
                this.Calendar.gotoDate(new Date(time));
                this.currentView = 'Day';
            } else {
                this.Calendar.changeView('timeGridDay', new Date(time));
                this.currentView = 'Day';
            }
            setTimeout(function () {
                $('.fc-scroller').animate({scrollTop: 0}, 400);
            }, 100);
        }
    }

    MonthViewNow() {
        this.Calendar.changeView('dayGridMonth', new Date());
        this.currentView = 'Calendar view';
    }

    WeekViewNow() {
        this.Calendar.changeView('timeGridWeek', new Date());
        this.currentView = 'Week';
    }

    DayViewNow() {
        this.Calendar.changeView('timeGridDay', new Date());
        this.currentView = 'Day';
    }

    DayViewTimeGrid(date) {
        this.Calendar.changeView('timeGridDay', new Date(date));
        this.currentView = 'Day';
    }

    ListViewNow() {
        this.Calendar.changeView('listMonth', new Date());
        this.currentView = 'List';
    }


    shareCalendarUrl = '';
    shortLinkUrlCopied = 0;
    shortLinkShared = 0;
    openShareModal(){
        let THIS = this;

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
        let child = ___selectedTab+1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
        (<any>$('#shareCalendarModal')).modal('show');
    }
    allowCopyClip(){
        let THIS = this;
        THIS.shortLinkShared == 1 ? THIS.shortLinkShared = 0 : THIS.shortLinkShared = 1;
        $.ajax({
            url: THIS.APPCONFIG.api+'calendar/share/update?token='+THIS.currentUser.token,
            data: {calendar : THIS.shortLinkShared},
            type: 'post',
            success: function (res) {
            }
        });
    }
    copyToClipboard(){
        let copyTextarea = $('#shareCalendarUrl');
        copyTextarea.focus();
        copyTextarea.select();
        try {
            document.execCommand('copy');
            this.shortLinkUrlCopied = 1;
        } catch (err) {
            this.shortLinkUrlCopied = -1;
        }
    }



    SubType = 0;
    discardPreviewWork() {
        (<any>$('#_pop_wo_workOrderModal')).modal('hide');
        this.RenderEvents();
    }
    removeWoThisWork(id){
        let THIS = this;
        swal({
            title: "Delete Work Order",
            text: "Are you sure you want to delete this work order?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let ids = [id];
                $.ajax({
                    url: THIS.APPCONFIG.api+'get/work-orders/remove?token='+THIS.currentUser.token,
                    data: {ids:ids},
                    type: 'post',
                    success: function (res) {
                        (<any>$('#_pop_wo_workOrderModal')).modal('hide');
                        THIS.RenderEvents();
                        swal({
                            title: "Success",
                            text: res.msg,
                            icon: "success",
                            buttons: ['Undo Remove', 'Ok'],
                            dangerMode: true,
                        }).then((willDelete) => {
                            if(willDelete){
                                swal.stopLoading();
                                swal.close();
                            } else {
                                THIS.undoWoRemoveThisWork(ids);
                            }
                        });
                    }
                });
            }
        });
    }
    undoWoRemoveThisWork(ids){
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api+'get/work-orders/undo/remove?token='+THIS.currentUser.token,
            data: {ids:ids},
            type: 'post',
            success: function (res) {
                THIS.RenderEvents();
            }
        });
    }
    archiveWoThisWork(id){
        let THIS = this;
        swal({
            title: "Archive Work Order",
            text: "Are you sure you want to archive this work order?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let ids = [id];
                $.ajax({
                    url: THIS.APPCONFIG.api+'get/work-orders/archive?token='+THIS.currentUser.token,
                    data: {ids:ids},
                    type: 'post',
                    success: function (res) {
                        (<any>$('#_pop_wo_workOrderModal')).modal('hide');
                        THIS.RenderEvents();
                        swal({
                            title: "Success",
                            text: res.msg,
                            icon: "success",
                            buttons: ['Undo Archive', 'Ok'],
                            dangerMode: true,
                        }).then((willDelete) => {
                            if(willDelete){
                                swal.stopLoading();
                                swal.close();
                            } else {
                                THIS.undoWoArchiveThisWork(ids);
                            }
                        });
                    }
                });
            }
        });
    }
    undoWoArchiveThisWork(ids){
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api+'get/work-orders/undo/archive?token='+THIS.currentUser.token,
            data: {ids:ids},
            type: 'post',
            success: function (res) {
                THIS.RenderEvents();
            }
        });
    }
    duplicateWoWorkOrder(id){
        if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
            let freeAccountData = localStorage.getItem('freeAccountData');
            if (freeAccountData != null) {
                let freeAccount = JSON.parse(freeAccountData);
                if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
                    (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
                    (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
                    (freeAccount['user_count'] >= freeAccount['user_limit'])) {
                    $('.openSubscription').click();
                    return false;
                }
            }
        }
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api+'work-orders/duplicate?token='+THIS.currentUser.token,
            data: {id: id},
            type: 'post',
            success: function (res) {
                if(res.status != undefined && res.status == 3000){
                    swal({
                        title: "Work Order",
                        text: "You have reached your free plan limit. To create more work orders, please subscribe to the Pro plan from your account page.",
                        icon: "warning",
                        dangerMode: true,
                        className: 'swal-btn-center'
                    });
                    return true;
                }
                THIS.RenderEvents();
                swal({
                    title: "Duplicate Work Order",
                    text: "Work Order has been successfully duplicated",
                    icon: "success",
                    buttons: ['Undo', 'Ok'],
                    dangerMode: true,
                }).then((willDelete) => {
                    if(willDelete){
                        swal.stopLoading();
                        swal.close();
                    } else {
                        let ids = [res.id];
                        $.ajax({
                            url: THIS.APPCONFIG.api+'get/work-orders/remove?token='+THIS.currentUser.token,
                            data: {ids: ids},
                            type: 'post',
                            success: function (res) {
                                THIS.RenderEvents();
                            }
                        });
                    }
                });
            }
        });
    }

    removeInsThisWork(id){
        let THIS = this;
        swal({
            title: "Delete Inspection",
            text: "Are you sure you want to delete this inspection?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let ids = [id];
                $.ajax({
                    url: THIS.APPCONFIG.apiInc+'erase/remove?token='+THIS.currentUser.token,
                    data: {ids:ids},
                    type: 'post',
                    success: function (res) {
                        (<any>$('#_pop_wo_workOrderModal')).modal('hide');
                        THIS.RenderEvents();
                        swal({
                            title: "Success",
                            text: res.msg,
                            icon: "success",
                            buttons: ['Undo Remove', 'Ok'],
                            dangerMode: true,
                        }).then((willDelete) => {
                            if(willDelete){
                                swal.stopLoading();
                                swal.close();
                            } else {
                                THIS.undoInsRemoveThisWork(ids);
                            }
                        });
                    }
                });
            }
        });
    }
    undoInsRemoveThisWork(ids){
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiInc+'erase/undo/remove?token='+THIS.currentUser.token,
            data: {ids:ids},
            type: 'post',
            success: function (res) {
                THIS.RenderEvents();
            }
        });
    }
    archiveInsThisWork(id){
        let THIS = this;
        swal({
            title: "Archive Inspection",
            text: "Are you sure you want to archive this inspection?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let ids = [id];
                $.ajax({
                    url: THIS.APPCONFIG.apiInc+'erase/archive?token='+THIS.currentUser.token,
                    data: {ids:ids},
                    type: 'post',
                    success: function (res) {
                        (<any>$('#_pop_wo_workOrderModal')).modal('hide');
                        THIS.RenderEvents();
                        swal({
                            title: "Success",
                            text: res.msg,
                            icon: "success",
                            buttons: ['Undo Archive', 'Ok'],
                            dangerMode: true,
                        }).then((willDelete) => {
                            if(willDelete){
                                swal.stopLoading();
                                swal.close();
                            } else {
                                THIS.undoInsArchiveThisWork(ids);
                            }
                        });
                    }
                });
            }
        });
    }
    undoInsArchiveThisWork(ids){
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiInc+'erase/undo/archive?token='+THIS.currentUser.token,
            data: {ids:ids},
            type: 'post',
            success: function (res) {
                THIS.RenderEvents();
            }
        });
    }
    duplicateInsWorkOrder(id){
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiInc+'duplicate?token='+THIS.currentUser.token,
            data: {id: id},
            type: 'post',
            success: function (res) {
                THIS.RenderEvents();
                swal({
                    title: "Duplicate Inspection",
                    text: "Inspection has been successfully duplicated",
                    icon: "success",
                    buttons: ['Undo', 'Ok'],
                    dangerMode: true,
                }).then((willDelete) => {
                    if(willDelete){
                        swal.stopLoading();
                        swal.close();
                    } else {
                        let ids = [res.data.id];
                        $.ajax({
                            url: THIS.APPCONFIG.apiInc+'erase/remove?token='+THIS.currentUser.token,
                            data: {ids: ids},
                            type: 'post',
                            success: function (res) {
                                THIS.RenderEvents();
                            }
                        });
                    }
                });
            }
        });
    }

}
