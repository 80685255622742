import {Component, TemplateRef, Inject} from '@angular/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';

@Component({
    selector: 'my-welcome',
    templateUrl: './welcome.component.html'
})
export class WelcomeComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};


    currentUser;
    ROUTES = '/dashboard';
    constructor(private router: Router, private route: ActivatedRoute) {

        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let routeR = THIS.CustomHelper.getRouteStr();
        let url = '/'+Session.team+THIS.ROUTES;
        window.history.pushState("","", url);
        THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;

    }

}
