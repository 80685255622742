import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { APPCONFIG } from '../config';

import { FacilityModel } from '../_models/facility';

import { HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";
import {Job_typeModel} from "../_models/job_type";

@Injectable()
export class Job_typesService {

    public token: string;
    job_types: Array<Job_typeModel> = [];

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }

    getJobTypeList(): Observable<any> {
            return this.http
                .get<any>(`${APPCONFIG.api}job-types?token=`+this.token)
                .pipe(map(res => {
                    return res.data;
                }));
    }
    createJobType(param): Observable<any> {
            return this.http
                .post<any>(`${APPCONFIG.api}job-types?token=`+this.token, param)
                .pipe(map(res => {
                    return res.data;
                }));
    }
    sortJobType(param): Observable<any> {
            return this.http
                .post<any>(`${APPCONFIG.api}job-types/sort?token=`+this.token, param)
                .pipe(map(res => {
                    return res;
                }));
    }
    editJobType(param): Observable<any> {
            return this.http
                .post<any>(`${APPCONFIG.api}job-types/`+param.id+`?token=`+this.token, {name:param.name})
                .pipe(map(res => {
                    return res;
                }));
    }
    removeJobType(id): Observable<any> {
            return this.http
                .delete<any>(`${APPCONFIG.api}job-types/`+id+`?token=`+this.token)
                .pipe(map(res => {
                    return res;
                }));
    }

    getPrioritiesList(): Observable<any> {
            return this.http
                .get<any>(`${APPCONFIG.api}priorities?token=`+this.token)
                .pipe(map(res => {
                    return res.data;
                }));
    }
    createPriorities(param): Observable<any> {
            return this.http
                .post<any>(`${APPCONFIG.api}priorities?token=`+this.token, param)
                .pipe(map(res => {
                    return res.data;
                }));
    }
    editPriorities(param): Observable<any> {
            return this.http
                .post<any>(`${APPCONFIG.api}priorities/`+param.id+`?token=`+this.token, {name:param.name})
                .pipe(map(res => {
                    return res;
                }));
    }
    removePriorities(id): Observable<any> {
            return this.http
                .delete<any>(`${APPCONFIG.api}priorities/`+id+`?token=`+this.token)
                .pipe(map(res => {
                    return res;
                }));
    }

}
