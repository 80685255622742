import {Component, EventEmitter, Input, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {FacilityService} from '../../../_services/facility.service';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';
import {MediaService} from '../../../_services/media.service';
import {a} from '@angular/core/src/render3';

declare let masonry: any;
declare let swal: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
  selector: 'page-facility-single',
  templateUrl: './singleFacility.component.html'
})
export class SingleFacilityComponent {
  @Input() selectedFacility: any;
  @Input() Removed: any;
  @Output() discard = new EventEmitter<number>();
  @Output() preview = new EventEmitter<number>();
  @Output() edit = new EventEmitter<number>();
  @Output() import = new EventEmitter<number>();
  @Output() remove = new EventEmitter<number>();
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
  contacts: any = [];
  contactsLoading = false;
  currentUser = null;
  noteId = null;
  selectedContacts = [];
  searchParam: any = '';

  constructor(protected facilityService: FacilityService, private mediaService: MediaService) {
    let THIS = this;
    this.currentUser = JSON.parse(localStorage.currentUserInfo);
    (<any>$('#FAC_UID_ID')).tooltip('destroy');
    this.getAllLabel();
    this.checkNowTime();
    this.facilityService.currentSelectedFac.subscribe(value => this.getSingleFacility());

    (<any>$('[data-toggle="tooltip"]')).tooltip('destroy');
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);

    this.dateCreatedOption.onClose = function (selectedDates, dateStr, instance) {
      if (THIS.SelectedRemData != null) {
        THIS.SelectedRemData.reminder = THIS.CustomHelper.formatDateTimeToDB(dateStr);
        if (dateStr !== '') {
          THIS.EditNoteReminderData(THIS.SelectedRemData);
        }
      }
    };
    this.dateCreatedOptionNew.onClose = function (selectedDates, dateStr, instance) {
      THIS.facNotesParam.reminder = THIS.CustomHelper.formatDateTimeToDB(dateStr);
    };

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedFacility != undefined) {
      let Facility = changes.selectedFacility.currentValue;
      this.getSingleFacility();
    }
  }

  getSingleFacility() {
    this.contactsLoading = true;
    if (this.selectedFacility != undefined) {
      let param = {id: this.selectedFacility.id};
      this.facilityService.getFacilitySingle(param).subscribe(res => {
        (<any>$('#FAC_UID_ID')).tooltip('destroy');
        if (res.status == 2000) {
          this.selectedFacility = res.data;
          this.contacts = this.selectedFacility.contacts;
          // this.contactsLoading = false;
          this.getAllNotes();
        } else {
          this.Removed = 1;
          this.selectedFacility = null;
          delete localStorage.selectedFacility;
        }
      }).add(() => {
        this.contactsLoading = false;
      });
    }
  }

  editThisFacility() {
    $('#editFacilityTrigger').attr('data-id', JSON.stringify(this.selectedFacility));
    $('#editFacilityTrigger').click();
  }

  importThisFacility() {
    this.import.emit(this.selectedFacility);
  }

  removeThisFacility() {
    this.remove.emit(this.selectedFacility);
  }

  confirmDiscard() {
    this.discard.emit(1);
  }


  copyToClipboard() {
    let copyTextarea = $('#FAC_UID_ID');
    copyTextarea.focus();
    copyTextarea.select();
    try {
      document.execCommand('copy');
      (<any>$('#FAC_UID_ID')).tooltip('show');
    } catch (err) {
      (<any>$('#FAC_UID_ID')).tooltip('destroy');
    }
  }

  selectAllCheck() {
    let THIS = this;
    let WO = this.selectedFacility.contacts;
    console.log(THIS.selectedContacts.length, THIS.selectedFacility.contacts.length);
    if (this.selectedContacts.length == this.selectedFacility.contacts.length) {
      this.selectedContacts.length = 0;
      console.log(1);
    } else {
      this.selectedContacts.length = 0;
      $.each(WO, function (i, v) {
        THIS.selectedContacts.push(v.id);
      });
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    console.log(THIS.selectedContacts);
  }

  checkThisRow(e, index, work) {
    e.preventDefault();
    let THIS = this;
    let iIndex = this.selectedContacts.indexOf(work.id);
    if (iIndex > -1) {
      if (this.selectedContacts.length == 1) {
        this.selectedContacts.length = 0;
      } else {
        this.selectedContacts.splice(iIndex, 1);
      }
    } else {
      this.selectedContacts.push(work.id);
    }
    $('.drop-menu-right').removeClass('open');
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    console.log(THIS.selectedContacts);
  }

  Labels = [];

  getAllLabel() {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'contact/label/getAll?token=' + THIS.currentUser.token,
      type: 'post',
      data: {},
      success: function (res) {
        THIS.Labels = res.data;
        setTimeout(function () {
          // (<any>$(".chosen-default-multiple-label")).val(THIS.AssetSelect);
          (<any>$('.chosen-default-multiple-label')).chosen('destroy');
          (<any>$('.chosen-default-multiple-label')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No Label Selected'
          }).on('change', function (e, p) {
            if (p.selected != undefined) {
              THIS.param.label.push(p.selected);
            } else {
              THIS.param.label.splice(THIS.param.label.indexOf(p.deselected), 1);
            }
          });
        }, 100);
      }
    });
  }

  param = {
    facility_id: 0,
    avatar: '',
    name: '',
    company: '',
    job_title: '',
    email: '',
    phone: '',
    address: '',
    label: []
  };

  closeCreateContact() {
    (<any>$('#createContactModal')).modal('hide');
  }

  createNewContact() {
    $('.show-facility-name-error-inmodal').html('');
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#createContactModal')).modal('show');
  }

  submitNewContact() {
    if (this.param.name == '') {
      $('.show-facility-name-error-inmodal').html('Name field is required');
      return true;
    }
    let ldd = document.getElementById('addContBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    this.param.facility_id = this.selectedFacility.id;
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'contact/create?token=' + THIS.currentUser.token,
      type: 'post',
      data: THIS.param,
      success: function (res) {
        ladda.stop();
        (<any>$('#createContactModal')).modal('hide');
        toastr.success('Contact has been created successfuylly', 'Contact Created', this.toastrOption);
        THIS.getSingleFacility();
        THIS.param = {
          facility_id: 0,
          avatar: '',
          name: '',
          company: '',
          job_title: '',
          email: '',
          phone: '',
          address: '',
          label: []
        };
        $('.show-facility-name-error-inmodal').html('');
      }
    });
  }

  avatarLoading = 0;
  selectedFilesPreview = '';

  uploadThisMedia(file, media_type, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  selectTheseFiles(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg') {
        THIS.avatarLoading = 1;
        this.uploadThisMedia(eachFile, '1', function (uploaded) {
          THIS.avatarLoading = 0;
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Please upload an image in Jpeg or Png only.',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.param.avatar = uploaded.file_path;
          }
        });
      } else {
        swal('Only jpg/jpeg and png files are allowed');
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  noteEditorTrigger = 0;

  facNotesImages = null;

  removeThisMedia(id, media_type) {
    let param = {id: id, media_type: media_type};
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  removeThisNoteImage(index, id) {
    this.facNotesImages = null;
    this.facNotesParam.images = '';
    this.removeThisMedia(id, 1);
  }

  uploadThisNoteMedia(file, media_type, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  addNoteMedia() {
    $('#attachImgNotes').click();
  }

  selectThisNoteImage(e) {
    let THIS = this;
    e.preventDefault();
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      THIS.uploadThisNoteMedia(eachFile, '1', function (uploaded) {
        if (THIS.facNotesImages != null) {
          THIS.removeThisNoteImage(0, THIS.facNotesImages.id);
        }
        THIS.facNotesImages = uploaded;
      });
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  selectQuickNoteImage(e, note) {
    let THIS = this;
    e.preventDefault();
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      THIS.uploadThisNoteMedia(eachFile, '1', function (uploaded) {
        if (THIS.facNotesImages != null) {
          THIS.removeThisNoteImage(0, THIS.facNotesImages.id);
        }
        let param = {
          id: note.id,
          facility_id: note.facility_id,
          title: note.title,
          description: note.description,
          images: uploaded.file_path,
          reminder: note.reminder
        };
        $.ajax({
          url: THIS.APPCONFIG.api + 'facility/notes/edit?token=' + THIS.currentUser.token,
          type: 'post',
          data: param,
          success: function (res) {
            if (res.status == 2000) {
              toastr.success('Facility note image has been updated successfully', 'Facility Note Edit', THIS.toastrOption);
              THIS.getAllNotes();
            }
          }
        });
      });
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  facNotesParam = {
    facility_id: 0,
    title: '',
    description: '',
    images: '',
    reminder: ''
  };

  clearNewReminder() {
    console.log('amie');
    this.facNotesParam.reminder = '';
  }

  cancelCreator() {
    $('.show-facility-name-error').html('');
    this.noteEditorTrigger = 0;
    this.facNotesImages = null;
    this.noteEditorTrigger = 0;
    this.facNotesParam = {
      facility_id: 0,
      title: '',
      description: '',
      images: '',
      reminder: ''
    };
    this.EditNoteId = 0;
  }

  clearCreator() {
    if (this.facNotesParam.title == '') {
      this.noteEditorTrigger = 0;
      this.facNotesImages = null;
      this.noteEditorTrigger = 0;
      this.facNotesParam = {
        facility_id: 0,
        title: '',
        description: '',
        images: '',
        reminder: ''
      };
      this.EditNoteId = 0;
    }
  }

  copyThisReminder(note) {
    this.facNotesParam = {
      facility_id: note.facility_id,
      title: note.title,
      description: note.description,
      images: note.images.length > 0 ? note.images[0] : '',
      reminder: note.reminder
    };
    this.createNotes('duplicate');
  }

  EditNoteId = 0;
  SelectedRemData = null;

  editThisReminder(note) {
    let THIS = this;
    THIS.EditNoteId = note.id;
    this.facNotesParam = {
      facility_id: note.facility_id,
      title: note.title,
      description: note.description,
      images: note.images.length > 0 ? note.images[0] : '',
      reminder: note.reminder
    };
    if (note.images.length > 0) {
      this.facNotesImages = {file_path: note.images.length > 0 ? note.images[0] : ''};
    } else {
      this.facNotesImages = null;
    }
    setTimeout(function () {
      THIS.noteEditorTrigger = 1;
      var elmnt = document.getElementById('_noteCreator');
      elmnt.scrollIntoView();
    }, 400);
  }

  createNotes(msg: any) {
    $('.show-facility-name-error').html('');
    const THIS = this;
    const ldd = document.getElementById('noteIdCreate');
    const ladda = Ladda.create(ldd);
    if ($.trim(THIS.facNotesParam.title) == '') {
      $('.show-facility-name-error').html('Title is required!');
      return false;
    }
    ladda.start();
    THIS.facNotesParam.facility_id = THIS.selectedFacility.id;
    if (THIS.facNotesParam.images == '') {
      THIS.facNotesParam.images = THIS.facNotesImages != null ? THIS.facNotesImages.file_path : '';
    }
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/notes/create?token=' + THIS.currentUser.token,
      type: 'post',
      data: THIS.facNotesParam,
      success: function (res) {
        ladda.stop();
        if (res.status == 2000) {
          THIS.facNotesImages = null;
          THIS.noteEditorTrigger = 0;
          THIS.facNotesParam = {
            facility_id: 0,
            title: '',
            description: '',
            images: '',
            reminder: ''
          };
          if (msg != 'create') {
            toastr.success('New Facility Note has been duplicated successfully', 'Facility Note Duplicate', THIS.toastrOption);
          } else {
            toastr.success('New Facility Note has been created successfully', 'Facility Note Create', THIS.toastrOption);
          }
          THIS.getAllNotes();
        }
      }
    });
  }

  EditNotes() {
    let THIS = this;
    let ldd = document.getElementById('noteIdEdit');
    let ladda = Ladda.create(ldd);
    if ($.trim(THIS.facNotesParam.title) == '') {
      swal({
        title: 'Edit Notes',
        text: 'Title is required!',
        icon: 'error',
        className: 'swal-btn-center'
      });
      return false;
    }
    ladda.start();
    if (THIS.facNotesParam.images == '') {
      THIS.facNotesParam.images = THIS.facNotesImages != null ? THIS.facNotesImages.file_path : '';
    }
    THIS.facNotesParam['id'] = THIS.EditNoteId;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/notes/edit?token=' + THIS.currentUser.token,
      type: 'post',
      data: THIS.facNotesParam,
      success: function (res) {
        ladda.stop();
        if (res.status == 2000) {
          THIS.facNotesImages = null;
          THIS.noteEditorTrigger = 0;
          THIS.EditNoteId = 0;
          THIS.facNotesParam = {
            facility_id: 0,
            title: '',
            description: '',
            images: '',
            reminder: ''
          };
          toastr.success('Facility Note has been updated successfully', 'Facility Note Update', THIS.toastrOption);
          THIS.getAllNotes();
        }
      }
    });
  }

  setReminderNotesNew(dt) {
    this.facNotesParam.reminder = dt;
  }

  setReminderNotes(note, dt) {
    note.reminder = dt;
    this.EditNoteReminderData(note);
  }

  EditNoteReminderData(note) {
    let THIS = this;
    let param = {
      id: note.id,
      facility_id: note.facility_id,
      reminder: note.reminder
    };
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/notes/edit?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        if (res.status == 2000) {
          toastr.success('Reminder of Facility Note has been updated successfully', 'Facility Note Reminder', THIS.toastrOption);
          THIS.getAllNotes();
        }
      }
    });
  }

  FacNotes = [];
  FacNotesFetch = 0;

  getAllNotes() {
    let THIS = this;
    THIS.FacNotesFetch = 1;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/notes/get/all?token=' + THIS.currentUser.token,
      type: 'post',
      data: {facility_id: THIS.selectedFacility.id},
      success: function (res) {
        THIS.FacNotesFetch = 0;
        if (res.status == 2000) {
          if (THIS.FacNotes.length > 0) {
            (<any>$('.grid')).masonry('destroy');
            (<any>$('.grid')).removeData('masonry');
          }
          THIS.FacNotes = res.data;
          if (window.innerWidth > 640) {
            setTimeout(function () {
              (<any>$('.grid')).masonry({
                // options
                itemSelector: '.grid-item',
                columnWidth: 0
              });
              (<any>$('[data-toggle="tooltip"]')).tooltip('destroy');
              setTimeout(function () {
                THIS.CustomHelper.tooltipRender();
              }, 500);
            }, 2000);
          }
        }
      }
    });
  }

  OpenRemoveFacNote(id: any) {
    this.noteId = id;
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#DeleteFacSuccess')).modal('show');
  }

  removeThisReminder() {
    let THIS = this;
    let param = {id: this.noteId, facility_id: THIS.selectedFacility.id};
    let ldd = document.getElementById('removeWorkOrderBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/notes/remove?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        ladda.stop();
        if (res.status == 2000) {
          THIS.getAllNotes();
          this.noteId = null;
          (<any>$('#DeleteFacSuccess')).modal('hide');
          toastr.success('Facility Note has been removed successfully', 'Facility Note Remove', THIS.toastrOption);
        }
      }
    });
  }

  dateCreated = this.CustomHelper.dateToTz(new Date());
  changingStart = 0;
  dateCreatedOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
    onClose: function (selectedDates, dateStr, instance) {
    },
  };
  dateCreatedOptionNew = {
    timePicker: true,
    enableTime: true,
    minDate: 'today',
    altInput: true,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
    onClose: function (selectedDates, dateStr, instance) {
    },
  };

  openReminderSelector(e) {
    let trigger = $(e.target);
    let target = trigger.closest('.FacilityNotes').find('.RemainderNoteArea');
    setTimeout(function () {
      target.addClass('_open');
    }, 100);
  }

  createReminderSelector(e) {
    let trigger = $(e.target);
    let target = trigger.closest('.createNote').find('.RemainderNoteArea');
    setTimeout(function () {
      target.addClass('_open');
    }, 100);
  }

  closeReminderSelector(e) {
    let trigger = $(e.target);
    let targetCheck = trigger.closest('.RemainderNoteArea._open');
    let calendarCheck = $('.flatpickr-calendar.open');
    if (targetCheck.length == 0 && calendarCheck.length == 0) {
      let target = $('.RemainderNoteArea._open');
      target.removeClass('_open');
    }
  }

  backReminderSelector(e) {
    let trigger = $(e.target);
    let targetCheck = trigger.closest('.RemainderNoteArea._open');
    let calendarCheck = $('.flatpickr-calendar.open');
    if (targetCheck.length > 0 && calendarCheck.length == 0) {
      let target = $('.RemainderNoteArea._open');
      target.removeClass('_open');
    }
  }

  NowTime = 0;
  NextDayName = '';
  dtFirst = '';
  dtSecond = '';

  checkNowTime() {
    let dt = new Date();
    let H = dt.getHours();
    let day = dt.getDay();
    day = day + 1;
    if (day > 6) {
      day = 0;
    }
    this.NextDayName = this.CustomHelper.day[day];
    if (H < 20) {
      this.NowTime = 1;
    }
    if (H >= 20) {
      this.NowTime = 2;
    }
    if (this.NowTime == 1) {
      this.dtFirst = this.CustomHelper.formatDateOnlyToDB(dt) + ' 20:00:00';
      dt.setDate(dt.getDate() + 1);
      this.dtSecond = this.CustomHelper.formatDateOnlyToDB(dt) + ' 08:00:00';
    }
    if (this.NowTime == 2) {
      dt.setDate(dt.getDate() + 1);
      this.dtSecond = this.CustomHelper.formatDateOnlyToDB(dt) + ' 08:00:00';
      dt.setDate(dt.getDate() + 1);
      this.dtSecond = this.CustomHelper.formatDateOnlyToDB(dt) + ' 08:00:00';
    }
  }


  searchContact() {
    this.contactsLoading = true;
  setTimeout(() => {
    this.contacts = this.searchArray(this.selectedFacility.contacts, this.searchParam);
    this.contactsLoading = false;
  }, 200);

  }

  searchArray(array: any[], searchTerm: string): any[] {
    if (!searchTerm) {
      return array;
    }

    return array.filter(item => {
      return Object.values(item).some(val =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }


}
