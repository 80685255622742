import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()
export class ChatService {


    ActiveChat = new BehaviorSubject<any>([]);
    getActiveChat = this.ActiveChat.asObservable();
    Conversation = new BehaviorSubject<any>(false);
    NewConversation = this.Conversation.asObservable();

    updateActiveChat(ActiveChat) {
        localStorage.ActiveChat = JSON.stringify(ActiveChat);
        this.ActiveChat.next(ActiveChat);
    }
    UpdateNewConversation(v) {
        this.Conversation.next(v);
    }

    constructor(private http: HttpClient, private router: Router ) {
        let ActiveChatData = localStorage.getItem('ActiveChat');
        if(ActiveChatData !== null){
            let ActiveChat = [];
            let ActiveChatD = JSON.parse(ActiveChatData);
            ActiveChatD.forEach((v) => {
                if(v.thread_id > 0){
                    ActiveChat.push(v);
                }
            });
            this.ActiveChat.next(ActiveChat);
        }
    }
}
