import {Component, EventEmitter, Output} from '@angular/core';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';

import {WorkOrderService} from '../../../_services/work-orders.service';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';

declare let swal: any;
declare let Ladda: any;



@Component({
  // tslint:disable-next-line:component-selector
  selector: 'project-create',
  templateUrl: './createProject.component.html'
})
export class CreateProjectComponent {
  @Output() discard = new EventEmitter<number>();
  @Output() preview = new EventEmitter<number>();
  FacilityOptionData: any = [];
  id = 0;
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  currentUser;

  constructor(protected facilityService: FacilityService,
              private workOrderService: WorkOrderService,
              private mediaService: MediaService) {
    const THIS = this;
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.currentUser = Session;
    this.getAllFacility();
    this.jt_get();
    this.personnel_get();

    this.param.start_date = this.CustomHelper.formatDateTimeToDB(new Date());
    this.dateCreatedOption.onChange = function (selectedDates, dateStr, instance) {
      if (selectedDates.length > 0) {
        THIS.dateCreated = new Date(selectedDates);
      } else {
        THIS.dateCreated = null;
      }
      THIS.onCreatedSelect();
    };
    this.dateDueOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.dateDue = new Date(selectedDates);
      THIS.onDueSelect();
    };

    setTimeout(function () {
      (<any>$('textarea.summernote')).summernote({
        maxHeight: '900px',
        minHeight: '150px',
        toolbar: [
          ['style', ['bold', 'underline', 'clear']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['link', 'video']],
          ['misc', ['fullscreen']]
        ]
      });
    }, 100);
  }


  param = {
    facility_id: '',
    job_type_id: '',
    start_date: null,
    due_date: null,
    subject: '',
    description: '',
    attach_files: [],
    managers: [],
    notes: [],
  };
  validForm = 0;
  submitting = 0;
  Facility: any = [];
  ManageFacility: any = [];
  FacilityComp: any = [];
  JobType = [];
  selectedWorkAttach = [];
  Personnel = {
    org: [],
    person: []
  };
  rightSecPreview = 0;
  dateCreated = this.CustomHelper.dateToTz(new Date());
  changingStart = 0;
  dateCreatedOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: new Date(),
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  dateDue = null;
  dateDueOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: null,
    minDate: null,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  validationThisForm() {
    let valid = 1;
    const form = $('#workCreateForm');
    if (this.param.facility_id === '') {
      valid = 0;
      form.find('[data-el="facility_id"]').removeClass('has-error').addClass('has-error');
    }
    if (this.param.subject === '') {
      valid = 0;
      form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
    }
    this.validForm = valid;
    return valid;
  }

  clearValidation(e) {
    const trigger = $(e.target);
    const target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }


  getAllFacility() {
    const THIS = this;
    THIS.ManageFacility  = [];
    THIS.Facility.length = 0;
    THIS.FacilityComp.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.FacilityComp = res.facility;
        THIS.ManageFacility = res.facility;
        $.each(res.facility, function (i, v) {
          v['pp'] = 0;
          THIS.Facility.push(v);
          $.each(v.child, function (j, vC) {
            vC['pp'] = 1;
            THIS.Facility.push(vC);
            $.each(vC.child, function (k, vCC) {
              vCC['pp'] = 2;
              THIS.Facility.push(vCC);
              $.each(vCC.child, function (l, vCCC) {
                vCCC['pp'] = 3;
                THIS.Facility.push(vCCC);
              });
            });
          });
        });
        setTimeout(function () {
          if ($('.reRenderFacility').length > 0) {
            // tslint:disable-next-line:radix
            const facilityId = parseInt($('.reRenderFacility').attr('data-id'));
            if (facilityId > 0) {
              THIS.param.facility_id = facilityId.toString();
            }
          }
          (<any>$('.chosen-default-single-fac')).chosen('destroy');
          (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
          (<any>$('.chosen-default-single-fac')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Facility'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
              (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#createFacilityModal')).modal('show');
            } else {
              THIS.param.facility_id = e.target.value;
              THIS.validationThisForm();
            }
          });
        }, 100);
      }
    });
  }

  personnel_get(): void {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status === 2000) {
          THIS.Personnel = res;
        }
        setTimeout(function () {
          if ($('.reRenderPerson').length > 0) {
            // tslint:disable-next-line:radix
            const personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
            if (personnel_id > 0) {
              THIS.param.managers = [personnel_id];
            }
          }
          const pp_val = THIS.param.managers;
          (<any>$('.chosen-default-multiple-per')).chosen('destroy');
          (<any>$('.chosen-default-multiple-per')).val(pp_val.length > 0 ? pp_val[0] : '');
          (<any>$('.chosen-default-multiple-per')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'select manager'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              (<any>$('.chosen-default-multiple-per')).val(THIS.param.managers);
              (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#PersonQuickModal')).modal('show');
            } else {
              THIS.param.managers = [e.target.value];
            }
          });
        }, 100);
      }
    });
  }

  jt_get() {
    const THIS = this;
    this.JobType.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-types?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.JobType = res.data;
        setTimeout(function () {
          if ($('.reRenderJobType').length > 0) {
            // tslint:disable-next-line:radix
            const job_type_id = parseInt($('.reRenderJobType').attr('data-id'));
            if (job_type_id > 0) {
              THIS.param.job_type_id = job_type_id.toString();
            }
          }
          (<any>$('.chosen-default-single-jti')).chosen('destroy');
          (<any>$('.chosen-default-single-jti')).val(THIS.param.job_type_id);
          (<any>$('.chosen-default-single-jti')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'select job type'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              (<any>$('.chosen-default-single-jti')).val(THIS.param.job_type_id);
              (<any>$('.chosen-default-single-jti')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#JobTypesModal')).modal('show');
            } else {
              THIS.param.job_type_id = e.target.value;
            }
          });
        }, 100);
      }
    });
  }


  confirmDiscard() {
    (<any>$('#createProjectModal')).modal('hide');
    this.discard.emit(1);
  }

  saveWorkOrderSubmit() {
    const THIS = this;
    const ldd = document.getElementById('SaveThisProjectLadda2');
    const ladda = Ladda.create(ldd);
    const mediaAttach: any = [];
    $.each(THIS.selectedWorkAttach, function (i, v) {
      mediaAttach.push(v.id);
    });
    this.param.attach_files = mediaAttach;
    // tslint:disable-next-line:max-line-length
    this.param.start_date = this.param.start_date != null && this.param.start_date !== '' ? this.CustomHelper.formatDateTimeToDB(this.param.start_date) : '';
    // tslint:disable-next-line:max-line-length
    this.param.due_date = this.param.due_date != null && this.param.due_date !== '' ? this.CustomHelper.formatDateTimeToDB(this.param.due_date) : '';

    const valid = this.validationThisForm();
    if (valid === 1) {
      // tslint:disable-next-line:no-shadowed-variable
      const THIS = this;
      ladda.start();
      $.ajax({
        url: THIS.APPCONFIG.api + 'projects/create?token=' + THIS.currentUser.token,
        type: 'post',
        data: THIS.param,
        success: function (res) {
          ladda.stop();
          if (res.status === 2000) {
            (<any>$('#createProjectModal')).modal('hide');
            THIS.preview.emit(res.data);
          }
        }
      });

    }

  }
  onCreatedSelect() {
    const THIS = this;
    if (this.dateCreated !== null) {
      this.param.start_date = this.CustomHelper.formatDateTimeToDB(this.dateCreated);
      this.param.due_date = '';
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
        THIS.dateDueOption.defaultDate = this.dateDue;
        THIS.changingStart = 0;
      }, 400);
    } else {
      this.param.start_date = '';
      this.param.due_date = '';
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date();
        THIS.dateDueOption.defaultDate = null;
        THIS.changingStart = 0;
      }, 400);
    }
  }



  onDueSelect() {
    this.param.due_date = this.CustomHelper.formatDateTimeToDB(this.dateDue);
  }

  uploadThisMedia(file, media_type, localtion, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  removeThisMedia(id, media_type) {
    const param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  addWorkAttach() {
    $('#workAttach').click();
  }

  removeThisAttach(index, id) {
    this.selectedWorkAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        const eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status !== undefined) {
            if (uploaded.status === 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
                className: 'swal-btn-center'
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allwoed',
                icon: 'error',
                dangerMode: true,
                className: 'swal-btn-center'
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
          }
        });
      }
    }
  }

  OnSelect(e: any) {
    this.param.facility_id = e.id;
    if (e.id === '000') {
      this.NewFacility();
    }
  }

  NewFacility() {
    if (this.param.facility_id === '000') {
      const THIS = this;
      (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
      (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
      let ___selectedTab = 0;
      const tabs: any = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#createFacilityModal')).modal('show');

    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    const THIS = this;
    $('.reRenderFacility').on('click', function () {
      THIS.getAllFacility();
    });
    $('.reRenderJobType').on('click', function () {
      THIS.jt_get();
    });
    $('.reRenderPerson').on('click', function () {
      THIS.personnel_get();
    });
  }

}
