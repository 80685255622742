import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {MediaService} from '../../_services/media.service';
import {FacilityService} from '../../_services/facility.service';
import {setTime} from 'ngx-bootstrap/chronos/utils/date-setters';

declare let Ladda: any;
declare let swal: any;
declare let toastr: any;
declare let $: any;


@Component({
    selector: 'page-keys',
    templateUrl: './keys.component.html'
})
export class KeysComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Permission = 1;
    Template = 1;
    ROUTES = '/keys';

    routeMake() {
        let route = this.CustomHelper.getRouteStr();
        if (route[2] != undefined) {

        } else {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + this.ROUTES;
            window.history.pushState('', '', url);
            this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
            this.Template = 0;
        }
    }

    currentUser = null;
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

    mdScreen = 0;
    miniScreen = 0;

    sPadWidth = 380;
    invalidEmail = false;
    PrintDetails = null;

    constructor(private mediaService: MediaService, private facilityService: FacilityService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        THIS.Permission = this.currentUser.user_type > 2 ? this.currentUser.modules.keys : 1;
        if (THIS.Permission == 1) {
            this.getAllKeys();
            THIS.facilityService.currentSelectedFac.subscribe(value => THIS.getAllKeys());
        }
        if (window.innerWidth <= 1500) {
            THIS.mdScreen = 1;
        }
        if (window.innerWidth > 480 && window.innerWidth <= 640) {
            THIS.sPadWidth = 300;
        }
        if (window.innerWidth <= 480) {
            THIS.sPadWidth = 280;
        }
    }

    Facility = [];
    FacilityComp = [];
    facilityEmpty = 0;

    AllEnableFacility() {
        let ldd = document.getElementById('allEnableFac');
        let ladda = Ladda.create(ldd);
        ladda.start();
        this.facilityService.enableAll(0).subscribe(res => {
            ladda.stop();
            this.facilityService.refreshFacility();
        });
    }

    getAllFacility() {
        let THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            this.FacilityComp.length = 0;
            this.FacilityComp = res.facility;
            $.each(res.facility, function (i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                $.each(v.child, function (j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                    $.each(vC.child, function (k, vCC) {
                        vCC['pp'] = 2;
                        THIS.Facility.push(vCC);
                        $.each(vCC.child, function (l, vCCC) {
                            vCCC['pp'] = 3;
                            THIS.Facility.push(vCCC);
                        });
                    });
                });
            });
            setTimeout(function () {
                if ($('.reRenderFacility').length > 0) {
                    let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
                    if (facilityId > 0) {
                        THIS.createParam.facility_id = facilityId.toString();
                        (<any>$('.chosen-default-single-fac')).val(THIS.createParam.facility_id);
                    }
                }
                (<any>$('.chosen-default-single-fac')).chosen('destroy');
                (<any>$('.chosen-default-single-fac')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Facility'
                }).on('change', function (e, p) {
                    if (e.target.value === '000') {
                        (<any>$('.chosen-default-single-fac')).val(THIS.createParam.facility_id);
                        (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
                        let ___selectedTab = 0;
                        let tabs = $('#sort-tabs').find('li');
                        tabs.each(function (i, v) {
                            if ($(v).find('a.active').length > 0) {
                                ___selectedTab = i;
                            }
                        });
                        let child = ___selectedTab + 1;
                        (<any>$('.owl-carousel')).owlCarousel('destroy');
                        $('#sort-tabs').find('li').find('a').removeClass('active');
                        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                        (<any>$('#createFacilityModal')).modal('show');
                    } else {
                        THIS.createParam.facility_id = e.target.value;
                    }

                });
            }, 100);
            setTimeout(function () {
                (<any>$('.chosen-default-single-fac-edit')).val(THIS.editParam.facility_id);
                (<any>$('.chosen-default-single-fac-edit')).chosen('destroy');
                (<any>$('.chosen-default-single-fac-edit')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Facility'
                }).on('change', function (e, p) {
                    if (e.target.value === '000') {
                        (<any>$('.chosen-default-single-fac-edit')).val(THIS.editParam.facility_id);
                        (<any>$('.chosen-default-single-fac-edit')).trigger('chosen:updated');
                        let ___selectedTab = 0;
                        let tabs = $('#sort-tabs').find('li');
                        tabs.each(function (i, v) {
                            if ($(v).find('a.active').length > 0) {
                                ___selectedTab = i;
                            }
                        });
                        let child = ___selectedTab + 1;
                        (<any>$('.owl-carousel')).owlCarousel('destroy');
                        $('#sort-tabs').find('li').find('a').removeClass('active');
                        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                        (<any>$('#createFacilityModal')).modal('show');
                    } else {
                        THIS.editParam.facility_id = e.target.value;
                    }

                });
            }, 100);
        });
    }


    FilterOn = 0;
    FilterScroll = 235;

    ToggleFilter(c) {
        if (this.wo_is_active != 0) {
            this.FilterScroll = 235;
        } else {
            this.FilterScroll = 270;
        }
    }

    Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
    changeDensity(density) {
        let THIS = this;
        this.Density = density;
        localStorage.DensityAss = this.Density;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
        let param = {keys: ''};
        density == 1 ? param.keys = 'd' : '';
        density == 2 ? param.keys = 'cf' : '';
        density == 3 ? param.keys = 'cp' : '';
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'me/density?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
            }
        });
    }

    selectedIds = [];

    selectAllCheck() {
        let THIS = this;
        let Keys = this.Keys;
        if (this.selectedIds.length == this.Keys.length) {
            this.selectedIds.length = 0;
        } else {
            this.selectedIds.length = 0;
            $.each(Keys, function (i, v) {
                THIS.selectedIds.push(v.id);
            });
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }

    checkThisRow(e, key) {
        e.preventDefault();
        let THIS = this;
        let index = this.selectedIds.indexOf(key.id);
        if (index > -1) {
            if (this.selectedIds.length == 1) {
                this.selectedIds.length = 0;
            } else {
                this.selectedIds.splice(index, 1);
            }
        } else {
            this.selectedIds.push(key.id);
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);

    }

    Total = 0;
    perPage = 25;
    pageNo = 1;
    sort_by = 'key_manager.id';
    order_by = 'desc';
    SortTitle = 'Created';
    SortOptions = [
        {title: 'Status', slug: 'key_manager.status'},
        {title: 'Key ID', slug: 'key_manager.key_id'},
        {title: 'Facility', slug: 'facilities.name'},
        {title: 'Serial', slug: 'key_manager.serial'},
        {title: 'Label', slug: 'key_manager.label'},
        {title: 'Due Back', slug: 'key_manager.due_back'},
    ];
    pageEvent;
    previewList = 0;
    wo_is_active = 1;
    searchKeyword = '';
    Keys = [];

    searchKeywordClear() {
        this.searchKeyword = '';
        this.filter();
    }

    filter() {
        let THIS = this;
        clearInterval(THIS.CustomHelper.woListSearch);
        THIS.CustomHelper.woListSearch = setInterval(function () {
            clearInterval(THIS.CustomHelper.woListSearch);
            THIS.getAllKeys();
        }, 1000);
    }

    highlighter(subject) {
        if (subject == '' || subject == null) {
            return subject;
        } else {
            subject = this.CustomHelper.makeStr(subject);
        }
        let matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
        let res = '';
        $.each(matches, function (i, v) {
            res = subject.replace(v, '<span class=\'text-warning-highlight\'>' + v + '</span>');
        });
        if (res == '') {
            res = subject;
        }
        return res;
    }

    paginateList() {
        if (this.pageEvent.pageSize == this.perPage) {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex + 1;
            this.getAllKeys();
            localStorage.perpage = this.perPage;
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
            this.getAllKeys();
            localStorage.perpage = this.perPage;
        }
    }

    getAllKeys() {
        let THIS = this;
        THIS.previewList = 0;
        $.ajax({
            url: THIS.APPCONFIG.api + 'key/manager/get/list?token=' + THIS.currentUser.token,
            type: 'post',
            data: {
                keyword: this.searchKeyword,
                pageNo: this.pageNo,
                perPage: this.perPage,
                sort_by: this.sort_by,
                order_by: this.order_by,
                is_active: this.wo_is_active
            },
            success: function (res) {
                THIS.previewList = 1;
                if (res.status == 2000) {
                    THIS.Keys = res.data;
                    THIS.Total = res.total;
                }
            }
        });
    }


    avatarLoading = 0;
    selectedFilesPreview = '';

    uploadThisMedia(file, media_type, localtion, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        if (localtion != null) {
            formData.append('lat', localtion.lat);
            formData.append('lon', localtion.lon);
        }
        this.mediaService.create(formData).subscribe(res => {
            callback(res);
        });
    }

    selectTheseFiles(e) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            let eachFile = accepted[i];
            if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg') {
                THIS.avatarLoading = 1;
                this.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    THIS.avatarLoading = 0;
                    THIS.selectedFilesPreview = uploaded.file_path;
                });
            } else {
                swal({
                    text: 'Only jpg/jpeg and png files are allowed',
                    icon: 'error',
                    dangerMode: true,
                    className: 'swal-btn-center'
                });
            }
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }

    createParam = {
        facility_id: '',
        serial: '',
        label: '',
        avatar: '',
    };
    errorShow: boolean = false;

    createKeySubmit(type) {
        let THIS = this;
        let ldd, ladda;
        if (type == 1) {
            ldd = document.getElementById('createKeyBtn');
            ladda = Ladda.create(ldd);
        } else {
            ldd = document.getElementById('createKeyBtnSN');
            ladda = Ladda.create(ldd);
        }
        if (this.createParam.facility_id == '' || this.createParam.serial == '') {
            this.errorShow = true;
            toastr.error('Please input required field\(s\).');
            return false;
        } else {
            this.errorShow = false;
            ladda.start();
            this.createParam.avatar = this.selectedFilesPreview;
            $.ajax({
                url: THIS.APPCONFIG.api + 'key/manager/create?token=' + THIS.currentUser.token,
                type: 'post',
                data: THIS.createParam,
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                        toastr.success('A new Key has been created successfully', 'Create new key', THIS.toastrOption);
                        THIS.createParam = {
                            facility_id: '',
                            serial: '',
                            label: '',
                            avatar: ''
                        };
                        THIS.selectedFilesPreview = '';
                        THIS.getAllFacility();
                        THIS.getAllKeys();
                        if (type == 1) {
                            (<any>$('#createKeyModal')).modal('hide');
                        }
                    }
                }
            });
        }
    }

    CreateNewKey() {
        this.errorShow = false;
        this.createParam = {
            facility_id: '',
            serial: '',
            label: '',
            avatar: '',
        };
        this.selectedFilesPreview = '';
        this.getAllFacility();
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#createKeyModal')).modal('show');
    }

    selectedForEdit = null;
    editParam = {
        id: '',
        facility_id: '',
        serial: '',
        label: '',
        key_id: '',
        avatar: '',
    };

    EditNewKey() {
        this.editParam = {
            id: this.selectedForEdit.id,
            facility_id: this.selectedForEdit.facility_id,
            serial: this.selectedForEdit.serial,
            label: this.selectedForEdit.label,
            key_id: this.selectedForEdit.key_id,
            avatar: this.selectedForEdit.avatar,
        };
        this.selectedFilesPreview = this.selectedForEdit.avatar;
        this.getAllFacility();
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#editKeyModal')).modal('show');
    }

    editKeySubmit() {
        let THIS = this;
        let ldd = document.getElementById('editKeyBtn');
        let ladda = Ladda.create(ldd);
        if (this.editParam.id == '' || this.editParam.facility_id == '' || this.editParam.serial == '') {
            return false;
        } else {
            ladda.start();
            if (this.selectedFilesPreview != '') {
                this.editParam.avatar = this.selectedFilesPreview;
            }
            $.ajax({
                url: THIS.APPCONFIG.api + 'key/manager/edit?token=' + THIS.currentUser.token,
                type: 'post',
                data: THIS.editParam,
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                        toastr.success('Key has been updated successfully', 'Edit Key', THIS.toastrOption);
                        THIS.singlePreview(THIS.editParam);
                        THIS.editParam = {
                            id: '',
                            facility_id: '',
                            serial: '',
                            label: '',
                            key_id: '',
                            avatar: '',
                        };
                        THIS.getAllFacility();
                        THIS.getAllKeys();
                        (<any>$('#editKeyModal')).modal('hide');
                    }
                }
            });
        }
    }

    removeThisKeys() {
        let THIS = this;
        let text = 'Are you sure you want to delete this key?';
        if (THIS.selectedIds.length > 1) {
            text = 'Are you sure you want to delete these keys?';
        }

        if (this.selectedForEdit !== null) {
            this.selectedIds.push(this.selectedForEdit.id);
        }
        let index;
        if (THIS.rightKeyInfo != null && THIS.Keys.length > 0) {
            index = THIS.selectedIds.find(id => id == THIS.rightKeyInfo.id);
            console.log(index);
        }
        let ldd = document.getElementById('removeWorkOrderBtn');
        let ladda = Ladda.create(ldd);
        console.log(THIS.selectedIds.length);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'key/manager/remove?token=' + THIS.currentUser.token,
            type: 'post',
            data: {ids: THIS.selectedIds},
            success: function (res) {
                if (res.status == 2000) {
                    ladda.stop();
                    toastr.success('Key has been removed successfully', 'Remove Key', THIS.toastrOption);
                    THIS.getAllKeys();
                    (<any>$('#deleteWorkOrderModal')).modal('hide');
                    if (THIS.selectedIds.length == 1 && THIS.rightKeyInfo != null && THIS.selectedIds[0] == THIS.rightKeyInfo.id) {
                        THIS.rightKeyInfo = null;
                    }
                    if (index) {
                        THIS.rightKeyInfo = null;
                    }
                    THIS.selectedIds.length = 0;
                    console.log(THIS.selectedIds.length);
                }
            }
        });
    }

    deleteThisWorkOrder(type = null) {
        if (type != null) {
            this.selectedIds = [];
            if (this.selectedForEdit != null) {
                this.selectedIds = [this.selectedForEdit.id];
            }
        }
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#deleteWorkOrderModal')).modal('show');
    }

    rightKeyInfo = null;
    HistoryShort = [];
    History = [];

    singlePreview(key) {
        this.rightKeyInfo = key;
        // this.selectedIds = [key.id];
        this.getSingleHistory();
    }

    getSingleHistory() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'key/manager/signed/history?token=' + THIS.currentUser.token,
            type: 'post',
            data: {key_id: THIS.rightKeyInfo.id},
            success: function (res) {
                if (res.status == 2000) {
                    THIS.History = res.data;
                    if (res.data.length > 6) {
                        res.data.length = 6;
                    }
                    THIS.HistoryShort = res.data;
                    console.log(THIS.HistoryShort);
                }
            }
        });
    }

    singlePreviewMobile(key) {
        this.rightKeyInfo = key;
        // this.selectedIds = [key.id];
        this.getSingleHistoryMobile();
    }

    getSingleHistoryMobile() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'key/manager/signed/history?token=' + THIS.currentUser.token,
            type: 'post',
            data: {key_id: THIS.rightKeyInfo.id},
            success: function (res) {
                if (res.status == 2000) {
                    THIS.History = res.data;
                    if (res.data.length > 6) {
                        res.data.length = 6;
                    }
                    THIS.HistoryShort = res.data;
                    let ___selectedTab = 0;
                    let tabs = $('#sort-tabs').find('li');
                    tabs.each(function (i, v) {
                        if ($(v).find('a.active').length > 0) {
                            ___selectedTab = i;
                        }
                    });
                    let child = ___selectedTab + 1;
                    (<any>$('.owl-carousel')).owlCarousel('destroy');
                    $('#sort-tabs').find('li').find('a').removeClass('active');
                    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                    (<any>$('#viewModal')).modal('show');
                }
            }
        });
    }

    openSignOutModal() {
        let THIS = this;
        THIS.openSignOutPad = 0;
        THIS.dateCreated = null;
        THIS.signOutParam = {
            key_id: '',
            name: '',
            company: '',
            email: '',
            phone: '',
            due_back: '',
            print_visitor_pass: 0,
            notes: '',
            signed_out_sign: '',
        };
        $('.name-error').html('');
        $('.signature-error').html('');
        $('.email-error').html('');
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#signKeyOutModal')).modal('show');

        let minDate = new Date();
        THIS.dateCreated = null;
        THIS.dateCreatedOption = {
            timePicker: true,
            enableTime: true,
            altInput: true,
            defaultDate: THIS.dateCreated,
            minDate: minDate,
            altFormat: THIS.CustomHelper.dateFormatFlat() + ' ' + THIS.CustomHelper.timeFormatFlat('format'),
            onChange: function (selectedDates, dateStr, instance) {
                if (selectedDates.length > 0) {
                    THIS.dateCreated = new Date(selectedDates);
                } else {
                    THIS.dateCreated = null;
                }
                THIS.signOutParam.due_back = THIS.CustomHelper.formatDateTimeToDB(THIS.dateCreated);
            },
        };
        setTimeout(function () {
            THIS.openSignOutPad = 1;
        }, 1000);
    }

    openSignInModal() {
        let THIS = this;
        THIS.openSignOutPad = 0;
        THIS.dateCreated = null;
        THIS.uploadItNow = -1;
        $('.name-error').html('');
        $('.signature-error').html('');
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#signKeyInModal')).modal('show');
        $.ajax({
            url: THIS.APPCONFIG.api + 'key/manager/signed/last/history?token=' + THIS.currentUser.token,
            type: 'post',
            data: {key_id: THIS.rightKeyInfo.id},
            success: function (res) {
                if (res.status == 2000) {
                    console.log(res.data);
                    THIS.signInParam = {
                        key_id: res.data.key_id,
                        name: res.data.name,
                        company: res.data.company,
                        email: res.data.email,
                        phone: res.data.phone,
                        due_back: res.data.due_back,
                        print_visitor_pass: res.data.print_visitor_pass,
                        notes: res.data.notes,
                        signed_in_sign: res.data.signed_in_sign,
                    };
                    let minDate = new Date();
                    THIS.dateCreated = res.data.due_back != null ? (new Date(res.data.due_back)) : null;
                    if (THIS.dateCreated != null) {
                        THIS.signOutParam.due_back = THIS.CustomHelper.formatDateTimeToDB(THIS.dateCreated);
                        minDate = new Date(Date.now() - 1000 * 60);
                        ;
                        if (minDate > THIS.dateCreated) {
                            minDate = new Date(THIS.dateCreated.getTime() - 1000 * 60);
                        }
                    }
                    THIS.dateCreatedOption = {
                        timePicker: true,
                        enableTime: true,
                        altInput: true,
                        defaultDate: THIS.dateCreated != null ? THIS.dateCreated : null,
                        minDate: minDate,
                        altFormat: THIS.CustomHelper.dateFormatFlat() + ' ' + THIS.CustomHelper.timeFormatFlat('format'),
                        onChange: function (selectedDates, dateStr, instance) {
                            if (selectedDates.length > 0) {
                                THIS.dateCreated = new Date(selectedDates);
                            } else {
                                THIS.dateCreated = null;
                            }
                            THIS.signInParam.due_back = THIS.CustomHelper.formatDateTimeToDB(THIS.dateCreated);
                        },
                    };
                    setTimeout(function () {
                        THIS.openSignOutPad = 1;
                    }, 1000);
                }
            }
        });
    }

    openHistoryModal() {
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#historyModal')).modal('show');
    }

    openContactModal() {
        (<any>$('#signKeyOutModal')).modal('hide');
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#contactModal')).modal('show');
    }

    closeContactModal() {
        (<any>$('#contactModal')).modal('hide');
        (<any>$('#signKeyOutModal')).modal('show');
    }


    freshDatePicker = 0;
    dateCreated = null;
    dateCreatedOption = {
        timePicker: true,
        enableTime: true,
        altInput: true,
        defaultDate: null,
        minDate: new Date(),
        altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
        onChange: function (selectedDates, dateStr, instance) {
        },
    };
    signOutParam = {
        key_id: '',
        name: '',
        company: '',
        email: '',
        phone: '',
        due_back: '',
        print_visitor_pass: 0,
        notes: '',
        signed_out_sign: '',
    };

    signOutKey(e) {
        e.preventDefault();
        $('.name-error').html('');
        $('.signature-error').html('');
        this.signOutParam.key_id = this.rightKeyInfo.id;
        if (this.signOutParam.name === '') {
            $('.name-error').html('Name field  is required');
            return;
        } else if (this.signOutParam.signed_out_sign === '') {
            $('.signature-error').html('Signature field  is required');
            return;
        } else if (this.signOutParam.email != '' && this.invalidEmail == true) {
            return;
        } else {
            let THIS = this;
            let ldd = document.getElementById('signOutBtn');
            let ladda = Ladda.create(ldd);
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.api + 'key/manager/signed/out?token=' + THIS.currentUser.token,
                type: 'post',
                data: THIS.signOutParam,
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                      console.log(res);
                        toastr.success('Key has been signed out successfully', 'Sign Out Key', THIS.toastrOption);
                        if(THIS.signOutParam.print_visitor_pass == 1){
                          THIS.PrintDetails = THIS.signOutParam;
                          setTimeout(() => {
                            $('#print-area').show();
                            THIS.PrintOpen();
                          }, 400);
                        }
                        THIS.signOutParam = {
                            key_id: '',
                            name: '',
                            company: '',
                            email: '',
                            phone: '',
                            due_back: '',
                            print_visitor_pass: 0,
                            notes: '',
                            signed_out_sign: '',
                        };
                        THIS.rightKeyInfo.status = 2;
                        THIS.getAllKeys();
                        THIS.getSingleHistory();
                        (<any>$('#signKeyOutModal')).modal('hide');
                    }
                }
            });
        }
    }
    signInParam = {
        key_id: '',
        name: '',
        company: '',
        email: '',
        phone: '',
        due_back: '',
        print_visitor_pass: 0,
        notes: '',
        signed_in_sign: '',
    };

    signInKey(e) {
        e.preventDefault();
        this.signInParam.key_id = this.rightKeyInfo.id;
        $('.name-error').html('');
        $('.sign-in-error').html('');
        if (this.signInParam.name == '') {
            $('.name-error').html('Name field is required');
            return;
        }
        if (this.signInParam.signed_in_sign == null) {
            $('.sign-in-error').html('Signature field is required');
            return;
        }
        console.log(this.signInParam.name, this.signInParam.signed_in_sign);
        if (this.signInParam.name === '' || this.signInParam.signed_in_sign === '') {
            swal({
                title: 'Sign In Key',
                text: 'Name and signature both are required',
                icon: 'warning',
                dangerMode: true,
                className: 'swal-btn-center'
            });
        } else {
            let THIS = this;
            let ldd = document.getElementById('signInBtn');
            let ladda = Ladda.create(ldd);
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.api + 'key/manager/signed/in?token=' + THIS.currentUser.token,
                type: 'post',
                data: THIS.signInParam,
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                        toastr.success('Key has been signed in successfully', 'Sign In Key', THIS.toastrOption);
                        if(THIS.signInParam.print_visitor_pass == 1){
                          THIS.PrintDetails = THIS.signInParam;
                          setTimeout(() => {
                            $('#print-area').show();
                            THIS.PrintOpen();
                          }, 400);
                        }
                        THIS.signInParam = {
                          key_id: '',
                          name: '',
                          company: '',
                          email: '',
                          phone: '',
                          due_back: '',
                          print_visitor_pass: 0,
                          notes: '',
                          signed_in_sign: '',
                        };
                        THIS.rightKeyInfo.status = 1;
                        THIS.getAllKeys();
                        THIS.getSingleHistory();
                        (<any>$('#signKeyInModal')).modal('hide');
                    }
                }
            });
        }
    }

  PrintOpen() {
    setTimeout(function () {
      let WinPrint = window.open(window.location.href , '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write($('#print-area').html());
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
      $('#print-area').hide();
    }, 500);
  }
    openSignOutPad = 0;
    uploadItNow = -1;

    saveImage(eachFile) {
        if (this.uploadItNow === 1) {
            let THIS = this;
            this.uploadItNow = 2;
            eachFile = eachFile.replace('data:image/png;base64,', '');
            let param = {
                imgData: eachFile
            };
            $.ajax({
                url: THIS.APPCONFIG.apiMedia + 'media/signature?token=' + THIS.currentUser.token,
                data: param,
                type: 'post',
                success: function (res) {
                    THIS.uploadItNow = 1;
                    THIS.signOutParam.signed_out_sign = res.file_path;
                }
            });
        } else {
            this.uploadItNow = 1;
        }
    }

    saveImageIn(eachFile) {
        if (this.uploadItNow === 1) {
            let THIS = this;
            this.uploadItNow = 2;
            eachFile = eachFile.replace('data:image/png;base64,', '');
            let param = {
                imgData: eachFile
            };
            $.ajax({
                url: THIS.APPCONFIG.apiMedia + 'media/signature?token=' + THIS.currentUser.token,
                data: param,
                type: 'post',
                success: function (res) {
                    THIS.uploadItNow = 1;
                    THIS.signInParam.signed_in_sign = res.file_path;
                }
            });
        } else {
            this.uploadItNow = 1;
        }
    }

    checkNumber(evt: any) {
        var theEvent: any = evt || window.event;

        // Handle paste
        if (theEvent.type === 'paste') {
            // @ts-ignore
            key = event.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /^\d*$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) {
                theEvent.preventDefault();
            }
        }
    }

    validEmail(email: any) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    checkEmail() {
        if (this.signOutParam.email == '') {
            $('.email-error').html('');
            this.invalidEmail = false;
            return;
        }
        if (this.validEmail(this.signOutParam.email)) {
            $('.email-error').html('');
            this.invalidEmail = false;
        } else {
            $('.email-error').html('Invalid email address');
            this.invalidEmail = true;
        }
    };

    historyDetail = null;

    ngOnInit() {
        let THIS = this;
        $('.reRenderFacility').on('click', function () {
            THIS.getAllFacility();
        });
    }

}
