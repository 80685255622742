import {Component, Input, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {UserService} from '../../../_services/user.service';
import {MediaService} from '../../../_services/media.service';
declare let swal: any;
declare let toastr: any;

@Component({
    selector: 'page-group-single',
    templateUrl: './group-single.component.html'
})
export class GroupSingleComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    @Output() editPreview = new EventEmitter<number>();
    @Output() removePreview = new EventEmitter<number>();
    @Output() listRefresh = new EventEmitter<number>();
    @Input() Template: any;
    TemplateCheck = 2;
    @Input() GroupInfo: any;
    DataDetailsChange = 0;
    Data: any;
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};

    inlinePrefixId = 0;
    updateChangesDiscard(){
        // this.CustomHelper.woRouteDiscard = 1;
    }
    confirmDiscard(){
        this.discard.emit(1);
    }

    currentUser: any;
    constructor(private userService: UserService,
                private mediaService: MediaService) {
        this.currentUser = JSON.parse(localStorage.currentUserInfo);
    }

    param = {
        id : 0,
        name : '',
        email : '',
        hourly_rate : '',
        address : '',
        phone : '',
        abn : '',
        logo : '',
        is_org : 0,
    };
    validForm = 1;
    saveSubmit(){
        let valid = this.validationThisForm();
        if (valid == 1) {
            if(this.selectedFilesPreview != null){
                this.param.logo = this.selectedFilesPreview.file_path;
            }
            this.userService.editGroup(this.param).subscribe(res => {
                if(res.status == 2000){
                    let THIS = this;
                    toastr.success('Personnel group has been successfully updated', 'Update Group', THIS.toastrOption);
                    this.preview.emit(res.data);
                } else {
                    swal({
                        text: res.error,
                        icon: "error",
                        dangerMode: true,
                        className: 'swal-btn-center'
                    });
                }
            });
        }
    }
    validationThisForm() {
        let valid = 1;
        if (this.param.name == '') {
            valid = 0;
            swal({
                text:'First name is required',
                icon: "error",
                dangerMode: true,
                className: 'swal-btn-center'
            });
            return valid;
        }
        this.validForm = valid;
        return valid;
    }
    checkThisForm() {
        this.param.name = $.trim(this.param.name);
        let valid = 1;
        if (this.param.name == '') {
            valid = 0;
        }
        this.validForm = valid;
        return valid;
    }


    selectedFilesPreview = null;
    uploadThisMedia(file, media_type, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        this.mediaService.create(formData).subscribe(res => {
            callback(res);
        });
    }
    selectTheseFiles(e) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            let eachFile = accepted[i];
            if(eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg'){
                this.uploadThisMedia(eachFile, '1', function (uploaded) {
                    THIS.selectedFilesPreview = uploaded;
                });
            } else {
                swal({
                    text: 'Only jpg/jpeg and png files are allowed',
                    icon: "error",
                    dangerMode: true,
                    className: 'swal-btn-center'
                });
            }
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }
    dragFilesDropped(e) {
        e.preventDefault();
        let THIS = this;
        if (e.dataTransfer.files.length > 0) {
            let eachFile = e.dataTransfer.files[0];
            if(eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg'){
                this.uploadThisMedia(eachFile, '1', function (uploaded) {
                    THIS.selectedFilesPreview = uploaded;
                });
            } else {
                swal({
                    text: 'Only jpg/jpeg and png files are allowed',
                    icon: "error",
                    dangerMode: true,
                    className: 'swal-btn-center'
                });
            }
        }
    }
    removeThisMedia(id, media_type) {
        let param = {
            id: id, media_type: media_type
        };
        this.mediaService.remove(param).subscribe(res => {
        });
    }
    removeThisImage(id) {
        this.selectedFilesPreview = null;
        this.removeThisMedia(id, 1);
    }


    ngOnChanges(changes: SimpleChanges): void {
        if(changes.Template != undefined){
            this.TemplateCheck = 0;
            this.TemplateCheck = changes.Template.currentValue;
        }
        if(changes.GroupInfo != undefined) {
            this.DataDetailsChange = 0;
            this.Data = changes.GroupInfo.currentValue;
            this.DataDetailsChange = 1;
            this.param = {
                id : this.Data.id,
                name : this.Data.name,
                email : this.Data.email,
                hourly_rate : this.Data.hourly_rate,
                address : this.Data.address,
                phone : this.Data.phone,
                abn : this.Data.abn,
                logo : this.Data.logo,
                is_org : this.Data.is_org,
            };
        }
    }
    editThisPerson(){
        this.editPreview.emit(this.Data);
    }
    removeThisPerson(){
        this.removePreview.emit(this.Data);
    }
    currencyIcon():string {    
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
    }

}
