import {Component, EventEmitter, Input, Output, SimpleChanges, Inject, TemplateRef} from '@angular/core';
import {WorkOrderService} from '../../../_services/work-orders.service';
import { TabsService } from '../../../_services/tabs.service';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';
import { WorkOrdersComponent } from '../../../pages/work-orders/work-orders.component';
import { InspectionComponent } from '../../../pages/inspection/inspection.component';
declare let toastr: any;
declare let swal: any;
declare let google: any;
declare let Sortable: any;
declare let owlCarousel: any;

@Component({
    selector: 'pro-to-work-preview',
    templateUrl: './proToWork.component.html'
})
export class ProToWorkComponent {
    @Input() workDel: any;
    currentUser;
    APPCONFIG = APPCONFIG;
    CustomHelper = CustomHelper;
    tabs = [];

    constructor(private workOrderService: WorkOrderService,
                private tabsService: TabsService) {
        this.tabsService.getTabs().subscribe(tabs => { this.tabs = tabs; });
        this.tabsService.getSelectedTab.subscribe(tab => { this.selectedTab = tab; });
    }
    ngOnChanges(changes: SimpleChanges): void {
        let THIS = this;
        if(changes.workDel != undefined){
            if(changes.workDel.currentValue != null){
                this.proToWork(changes.workDel.currentValue);
            }
        }
    }
    selectedTab: number;
    TabSlide;
    proToWork(work){
        localStorage.WoPreId = work.uid;
        let THIS = this;
        setTimeout(function () {
            let idx = -1;
            if(work.modType == 1){
                idx = THIS.tabs.map(function(e) { return e.description; }).indexOf('Work Orders');
            } else {
                idx = THIS.tabs.map(function(e) { return e.description; }).indexOf('Inspections');
            }
            delete THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute];
            THIS.CustomHelper.woRouteDiscard = 0;
            console.log(THIS.CustomHelper.ConfRoute);
            let index = THIS.CustomHelper.ConfRoute.map(function(e) { return e.tab; }).indexOf(idx);
            if(index > -1){
                THIS.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
            }
            if(THIS.CustomHelper.woRouteDiscard == 1 && idx > -1){
                swal({
                    title: "Are you sure?",
                    text: "You have unsaved changes on that page. Click CANCEL to stay where you are. Otherwise, click LEAVE to discard your changes and open this work order preview",
                    icon: "warning",
                    buttons: ['CANCEL','LEAVE'],
                    dangerMode: true,
                }).then((res) => {
                    if (res) {
                        (<any>$('.owl-carousel')).owlCarousel('destroy');
                        if(idx > -1){
                            THIS.tabs.splice(idx, 1);
                        }
                        THIS.CustomHelper.woRouteDiscard = 0;
                        THIS.CustomHelper.ConfRoute.splice(index, 1);
                        if(work.modType == 1){
                            THIS.tabsService.addTab({
                                description: 'Work Orders',
                                component: WorkOrdersComponent,
                                id: (new Date).getTime()
                            });
                        } else {
                            THIS.tabsService.addTab({
                                description: 'Inspections',
                                component: InspectionComponent,
                                id: (new Date).getTime()
                            });
                        }
                        setTimeout(function () {
                            THIS.TabSlide = (<any>$('.owl-carousel')).owlCarousel({
                                loop:false,
                                nav:false,
                                dots:false,
                                mouseDrag:false,
                                touchDrag:false,
                                items:1,
                                animateIn: 'fadeIn faster',
                                autoplay: false
                            }).on('changed.owl.carousel', function(event) {
                                THIS.selectedTab = event.item.index;
                                THIS.tabsService.setSelectedTab(THIS.selectedTab);
                            });
                            (<any>$('.owl-carousel')).trigger('to.owl.carousel', THIS.tabs.length-1);
                        }, 500);
                    }
                });
            }
            else {
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                THIS.CustomHelper.woRouteDiscard = 0;
                THIS.CustomHelper.ConfRoute.splice(index, 1);
                if(work.modType == 1){
                    THIS.tabsService.addTab({
                        description: 'Work Orders',
                        component: WorkOrdersComponent,
                        id: (new Date).getTime()
                    });
                } else {
                    THIS.tabsService.addTab({
                        description: 'Inspections',
                        component: InspectionComponent,
                        id: (new Date).getTime()
                    });
                }
                setTimeout(function () {
                    THIS.TabSlide = (<any>$('.owl-carousel')).owlCarousel({
                        loop:false,
                        nav:false,
                        dots:false,
                        mouseDrag:false,
                        touchDrag:false,
                        items:1,
                        animateIn: 'fadeIn faster',
                        autoplay: false
                    }).on('changed.owl.carousel', function(event) {
                        THIS.selectedTab = event.item.index;
                        THIS.tabsService.setSelectedTab(THIS.selectedTab);
                    });
                    (<any>$('.owl-carousel')).trigger('to.owl.carousel', THIS.tabs.length-1);
                }, 500);
            }
        }, 500);
    }

}


