import {Component, EventEmitter, Output, TemplateRef, SimpleChanges} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ContactsService} from '../../../_services/contacts.service';
import {InvoicesService} from '../../../_services/invoices.service';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {FacilityService} from '../../../_services/facility.service';
import {AuthService} from '../../../_services/auth.service';
import {FcmService} from '../../../_services/fcm.service';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let Inputmask: any;

@Component({
  selector: 'create-invoice',
  templateUrl: './createInvoice.component.html'
})
export class CreateInvoiceComponent {
  @Output() newInv = new EventEmitter<number>();
  @Output() discard = new EventEmitter<number>();
  @Output() emailIt = new EventEmitter<number>();
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
  APPCONFIG = APPCONFIG;
  currentUser;

  constructor(private contactsService: ContactsService,
              public snackBar: MatSnackBar,
              private facilityService: FacilityService,
              private fcmService: FcmService,
              private authService: AuthService,
              private invoicesService: InvoicesService) {
    let THIS = this;
    this.currentUser = JSON.parse(localStorage.currentUserInfo);
    this.getPersonalInfo();
    this.getAllFacility();
    this._startDateConfig.onChange = function (selectedDates, dateStr, instance) {
      THIS.param.invoice_date = THIS.CustomHelper.formatDateToDB(selectedDates);
      THIS.onCreatedSelect();
    };
    this._dueDateConfig.onChange = function (selectedDates, dateStr, instance) {
      THIS.param.due_date = THIS.CustomHelper.formatDateToDB(selectedDates);
      THIS.onDueSelect();
    };
    if (localStorage.work_to_invoice !== undefined) {
      let workOrder = JSON.parse(localStorage.work_to_invoice);
      delete localStorage.work_to_invoice;
      this.param.facility_id = workOrder.facility.id.toString();
      this.param.work_id = THIS.CustomHelper.makeStr(workOrder.id);
      this.param.terms = '1';
      this._startDateConfig.defaultDate = new Date();
      this._dueDateConfig.defaultDate = new Date();
      this.param.invoice_date = THIS.CustomHelper.formatDateToDB(this._startDateConfig.defaultDate);
      this.param.due_date = THIS.CustomHelper.formatDateToDB(this._dueDateConfig.defaultDate);
      if (workOrder.attachInventories.length > 0) {
        THIS.createCartItems.length = 0;
      }
      $.each(workOrder.attachInventories, function (i, v) {
        let itemData = {
          item_name: v.title,
          item_qty: THIS.CustomHelper.makeFloat(v.pick),
          item_price: THIS.CustomHelper.makeFloat(v.retail_price),
          item_tax: 'Tax Included',
          item_total: THIS.CustomHelper.makeFloat(v.pick) * THIS.CustomHelper.makeFloat(v.retail_price)
        };
        THIS.createCartItems.push(itemData);
      });
      this.changeInvoiceTotal();
      this.getAllContact(THIS.param.facility_id);
    }
    setTimeout(function () {
      THIS.changeInvoiceTotal();
      THIS.genEditor();
    }, 1000);

    this.checkCurrency();
    console.log(this.checkCurrency());
  }

  genEditor() {
    let THIS = this;
    setTimeout(function () {
      (<any>$('.summernote')).summernote({
        maxHeight: '900px',
        minHeight: '150px',
        toolbar: [
          ['style', []],
        ]
      });
    }, 100);
  }

  getPersonalInfo() {
    let THIS = this;
    this.authService.getPersonalInfo().subscribe(res => {
      if (res.data.terms != null) {
        if (res.data.terms.inv != undefined) {
          // this.param.customer_notes = res.data.terms.inv.customer_notes;
          // this.param.terms_condition = res.data.terms.inv.terms_condition;
          // (<any>$('#customer_note')).summernote('code', this.param.customer_notes);
          // (<any>$('#terms_condition')).summernote('code', this.param.terms_condition);
        }
      }
    });
  }

  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction
    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],
    ['clean'],                                         // remove formatting button
    // ['link', 'image', 'video']                         // link and image, video
    // ['image']                         // link and image, video
  ];


  Facility = [];
  FacilityComp = [];

  getAllFacility() {
    let THIS = this;
    this.facilityService.getFacilitiesList().subscribe(res => {
      this.Facility.length = 0;
      this.FacilityComp.length = 0;
      this.FacilityComp = res.facility;
      $.each(res.facility, function (i, v) {
        v['pp'] = 0;
        THIS.Facility.push(v);
        $.each(v.child, function (j, vC) {
          vC['pp'] = 1;
          THIS.Facility.push(vC);
          $.each(vC.child, function (k, vCC) {
            vCC['pp'] = 2;
            THIS.Facility.push(vCC);
            $.each(vCC.child, function (l, vCCC) {
              vCCC['pp'] = 3;
              THIS.Facility.push(vCCC);
            });
          });
        });
      });
      setTimeout(function () {
        (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'select facility'
        }).on('change', function (e, p) {
          THIS.contact.length = 0;
          if (e.target.value === '000') {
            (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
            (<any>$('.chosen-default-single-fac')).trigger("chosen:updated");
            let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            let child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#createFacilityModal')).modal('show');
          } else {
            THIS.param.facility_id = e.target.value;
            THIS.getAllContact(THIS.param.facility_id);
            THIS.validationThisForm();
          }
        });

        setTimeout(function () {
          (<any>$('.chosen-default-single-contact')).chosen('destroy');
          (<any>$('.chosen-default-single-contact')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Contact'
          }).on('change', function (e, p) {
            THIS.param.contact_id = e.target.value;
          });
        }, 100);
        setTimeout(function () {
          (<any>$('.chosen-default-single-terms')).val(THIS.param.terms);
          (<any>$('.chosen-default-single-terms')).chosen('destroy');
          (<any>$('.chosen-default-single-terms')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Invoice Terms'
          }).on('change', function (e, p) {
            THIS.param.terms = e.target.value;
            THIS.onCreatedSelect();
          });
        }, 100);

      }, 100);
    });
  }

  contact = [];

  getAllContact(id) {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/getSingle/contact?token=' + THIS.currentUser.token,
      data: { id: id },
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          THIS.contact = res.data;
        }
        setTimeout(function () {
          (<any>$('.chosen-default-single-contact')).chosen('destroy');
          (<any>$('.chosen-default-single-contact')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Contact'
          }).on('change', function (e, p) {
            THIS.param.contact_id = e.target.value;
          });
        }, 100);
        setTimeout(function () {
          (<any>$('.chosen-default-single-terms')).val(THIS.param.terms);
          (<any>$('.chosen-default-single-terms')).chosen('destroy');
          (<any>$('.chosen-default-single-terms')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Invoice Terms'
          }).on('change', function (e, p) {
            THIS.param.terms = e.target.value;
            THIS.onCreatedSelect();
          });
        }, 100);
      }
    });
  }

  createCartItemsSubTotal = 0;
  createCartItemsTax = {
    val: 10,
    type: 'exclusive'
  };

  createCartItemsTotal = 0;
  createCartItems = [
    {
      item_name: '',
      item_qty: 1,
      item_price: 1,
      item_tax: 'Tax Included',
      item_total: 1
    }
  ];

  addNewItemInCart(): void {
    let newCart = {
      item_name: '',
      item_qty: 1,
      item_price: 1,
      item_tax: 'Tax Included',
      item_total: 1,
    };
    this.createCartItems.push(newCart);
    this.changeInvoiceTotal();
    this.checkCurrency();
  }

  removeFromCart(index): void {
    if (this.createCartItems.length > 1) {
      this.createCartItems.splice(index, 1);
    }
    this.changeInvoiceTotal();
  }

  copyThisInCart(cart): void {
    let newCart = {
      item_name: cart.item_name,
      item_qty: cart.item_qty,
      item_price: cart.item_price,
      item_tax: cart.item_tax,
      item_total: cart.item_total,
    };
    this.createCartItems.push(newCart);
    this.changeInvoiceTotal();
    this.checkCurrency();
  }

  updateLineName(e, index: number): void {
    let trigger = $(e.target);
    let value = trigger.val();
    let name = value.toString();
    this.createCartItems[index].item_name = name;
  }

  updateLineQty(e, index: number): void {
    let trigger = $(e.target);
    let value = trigger.val();
    let Qty = Number(value);
    if (Qty < 0) {
      this.createCartItems[index].item_qty = 0;
      $('.val0').val(0);
      return;
    }
    this.createCartItems[index].item_qty = Qty;
    let lineTotal = this.createCartItems[index].item_qty * this.createCartItems[index].item_price;
    this.createCartItems[index].item_total = lineTotal;
    this.changeInvoiceTotal();
  }

  updateLinePrice(e, index: number): void {
    let trigger = $(e.target);
    let inputValue = this.CustomHelper.makeStr(trigger.val());
    let value = inputValue.replace(/,/, '');
    value = value.substring(this.currencyIcon().length);
    let price = Number(value);
    this.createCartItems[index].item_price = price;
    let lineTotal = this.createCartItems[index].item_qty * this.createCartItems[index].item_price;
    this.createCartItems[index].item_total = lineTotal;
    this.changeInvoiceTotal();
  }

  changeInvoiceTax(tax: number, type: string): void {
    this.createCartItemsTax.val = tax;
    this.createCartItemsTax.type = type;
    this.changeInvoiceTotal();
    if (tax == 10 && type == 'exlusive') {
      this.param.tax_type = 1;
    }
    if (tax == 10 && type == 'inlusive') {
      this.param.tax_type = 2;
    }
    if (tax == 5 && type == 'exlusive') {
      this.param.tax_type = 3;
    }
    if (tax == 5 && type == 'inclusive') {
      this.param.tax_type = 4;
    }
    this.param.tax_value = tax;
  }

  changeInvoiceTotal(): void {
    let subTotal = 0;
    $.each(this.createCartItems, function (i, v) {
      subTotal = subTotal + v.item_total;
    });
    this.createCartItemsSubTotal = subTotal;
    this.createCartItemsTotal = this.createCartItemsSubTotal + (this.createCartItemsSubTotal * (this.createCartItemsTax.val / 100));
  }

  discardNewInvoice() {
    this.discard.emit(1);
  }


  param = {
    prefix: 'IN',
    work_id: '',
    invoice_id: '',
    facility_id: '',
    contact_id: '',
    invoice_date: this.CustomHelper.formatDateToDB(new Date()),
    invoice_number: Date.now(),
    order_number: '',
    terms: '',
    due_date: this.CustomHelper.formatDateToDB(new Date()),
    sub_total: 0,
    tax_type: 1,
    tax_value: 10,
    total: 0,
    customer_notes: '',
    terms_condition: '',
    customer_note_on: 1,
    term_on: 1,
    items: '',
  };

  emailThisInvoice() {
    this.emailIt.emit(1);
  }

  valid = 0;
  createThisInvoice() {
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      let freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        let freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    let ldd = document.getElementById('SaveThisLadda');
    let ladda = Ladda.create(ldd);
    let valid = this.validationThisForm();
    if (valid == 1) {
      let THIS = this;
      ladda.start();
      // this.param.customer_notes = (<any>$('#customer_note')).summernote('code');
      //
      // this.param.terms_condition = (<any>$('#terms_condition')).summernote('code');

      this.param.sub_total = this.createCartItemsSubTotal;
      this.param.total = this.createCartItemsTotal;
      this.param.items = JSON.stringify(this.createCartItems);
      this.invoicesService.createInvoice(this.param).subscribe(res => {
        if (res.status == 2000) {
          ladda.stop();
          this.newInv.emit(res.data);
          toastr.success('New invoice successfully created', 'New Invoice', this.toastrOption);
          // Pusher emit
        } else {
          swal({
            text: 'Somethign is wrong! Please try again',
            icon: 'error',
            className: 'swal-btn-center',
            dangerMode: true,
          });
        }
      });
    }
  }

  validationThisForm() {
    this.valid = 1;
    if (this.param.facility_id == '') {
      this.valid = 0;
      // swal({
      //   text: 'Please select a facility.',
      //   icon: 'warning',
      //   className: 'swal-btn-center',
      // });
      $('.invalid-title').html('Please select a facility.');
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#ValidationModal')).modal('show');
      return false;
    }
    if (this.param.invoice_date == '') {
      this.valid = 0;
      // swal({
      //   text: 'Please select invoice date.',
      //   icon: 'warning',
      //   className: 'swal-btn-center',
      // });
      $('.invalid-title').html('Please select invoice date.');
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#ValidationModal')).modal('show');
      return false;
    }
    return this.valid;
  }


  inlineEdit = 0;

  getLastInvoiceId() {
    this.invoicesService.getLastInvoiceId().subscribe(res => {
      this.param.prefix = res.prefix;
      this.param.invoice_id = res.invoice_id;
    });
  }


  due_date_preview = 1;
  _startDateConfig = {
    timePicker: false,
    defaultDate: new Date(),
    dateFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    }
  };

  onCreatedSelect() {
    let THIS = this;
    this.due_date_preview = 0;
    let terms = parseInt(this.param.terms);
    let invDate = null;
    if (terms > 1 && THIS.param.invoice_date != '') {
      invDate = new Date(THIS.param.invoice_date);
      invDate.setDate(invDate.getDate() + terms);
      this.param.due_date = THIS.CustomHelper.formatDateToDB(invDate);
    } else if (terms == 1) {
      invDate = new Date(THIS.param.invoice_date);
      this.param.due_date = THIS.CustomHelper.formatDateToDB(invDate);
    } else {
      this.param.due_date = '';
    }
    setTimeout(function () {
      THIS._dueDateConfig.minDate = new Date(THIS.param.invoice_date);
      THIS._dueDateConfig.defaultDate = invDate;
      THIS.due_date_preview = 1;
    }, 400);
  }

  _dueDateConfig = {
    timePicker: false,
    minDate: null,
    defaultDate: null,
    dateFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    }
  };

  onDueSelect() {
    let THIS = this;
    setTimeout(function () {
      (<any>$('.chosen-default-single-terms')).val(THIS.param.terms);
      (<any>$('.chosen-default-single-terms')).chosen('destroy');
      (<any>$('.chosen-default-single-terms')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Invoice Terms'
      }).on('change', function (e, p) {
        THIS.param.terms = e.target.value;
        THIS.onCreatedSelect();
      });
    }, 100);
  }

  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_fac_'+Session.team, function (v) {
        THIS.getAllFacility();
    });*/

    $('.reRenderFacility').on('click', function () {
      THIS.getAllFacility();
    });
  }

  ngOnDestroy() {
  }

  checkCurrency() {
    Inputmask.extendAliases({
      pesos: {
        prefix: `${this.currencyIcon()}`,
        groupSeparator: '',
        alias: 'numeric',
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        radixPoint: '.',
        placeholder: '0.00'
      }
    });

    setTimeout(function () {
      (<any>$('._currency_validate_eq')).inputmask({alias: 'pesos'});
    }, 1000);
  }

  currencyIcon(): string {
    if (this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }
}
