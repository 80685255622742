import {Component, EventEmitter, Input, Output, SimpleChanges, TemplateRef} from '@angular/core';
import { ContactsService } from '../../../_services/contacts.service';
import { AssetService } from '../../../_services/asset.service';
import { FacilityService } from '../../../_services/facility.service';
import { APPCONFIG } from '../../../config';
import { CustomHelper } from '../../../customhelper';
import * as XLSX from 'xlsx';
declare let swal: any;
declare let Ladda: any;


@Component({
    selector: 'page-facility-create',
    templateUrl: './createFacility.component.html'
})
export class CreateFacilityComponent {
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    APPCONFIG = APPCONFIG;
    CustomHelper = CustomHelper;



    constructor(protected facilityService:FacilityService,
                protected assetService:AssetService,
                protected contactsService:ContactsService) {
        this.getAllFacility();
    }
    Facility = [];
    getAllFacility() {
        let THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            $.each(res.facility, function(i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                $.each(v.child, function(j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                    $.each(vC.child, function(k, vCC) {
                        vCC['pp'] = 2;
                        THIS.Facility.push(vCC);
                    });
                });
            });
            setTimeout(function () {
                (<any>$('.chosen-default-single')).chosen({
                    allow_single_deselect: true
                }).on('change', function(e,p){
                    THIS.param.parent_id = e.target.value;
                });
            }, 100);
        });
    }


    step = 1;
    confirmDiscard(){
        this.discard.emit(1);
    }


    facility: any;
    param = {
        id : 0,
        name : '',
        parent_id : '0',
        address : '',
        str_addr_1 : '',
        str_addr_2 : '',
        city : '',
        state : '',
        zip_code : '',
        country : '',
        site_name : '',
        site_email : '',
        site_phone : '',
        site_mobile : ''
    };
    handleAddressChange(place){
        this.param.address = place.formatted_address;
        for (let i = 0; i < place.address_components.length; i++) {
            let addressType = place.address_components[i].types[0];
            if(addressType == 'street_number'){
                this.param.str_addr_1 = place.address_components[i]['short_name'];
            }
            if(addressType == 'route'){
                this.param.str_addr_2 = place.address_components[i]['long_name'];
            }
            if(addressType == 'locality'){
                this.param.city = place.address_components[i]['long_name'];
            }
            if(addressType == 'administrative_area_level_1'){
                this.param.state = place.address_components[i]['short_name'];
            }
            if(addressType == 'country'){
                this.param.country = place.address_components[i]['long_name'];
            }
            if(addressType == 'postal_code'){
                this.param.zip_code = place.address_components[i]['short_name'];
            }
        }
    }
    clearValidation(e) {
        let trigger = $(e.target);
        let target = trigger.closest('.form-group');
        target.removeClass('has-error');
    }
    create(){
        let THIS = this;
        let ldd = document.getElementById('facCreate');
        let ladda = Ladda.create(ldd);
        let form = $('#facCreateForm');
        if(this.param.name != ''){
            ladda.start();
            this.facilityService.createFacility(this.param).subscribe(res => {
                if(res.status != undefined && res.status == 3000){
                    swal({
                        title: "Crete Fcility",
                        text: "You have reached your free plan limit. To create more facilities, please subscribe to the Pro plan from your account page.",
                        icon: "warning",
                        dangerMode: true,
                        className: 'swal-btn-center'
                    });
                    return true;
                }

                ladda.stop();
                // this.step = 2;
                this.facility = res;
                this.param['id'] = this.facility.id;
                this.previewThisFacility();
                // Pusher emit
            });
        } else {
            let myDiv = document.getElementById('_thisScrool');
            myDiv.scrollTop = 0;
            form.find('[data-el="name"]').removeClass('has-error').addClass('has-error');
        }
    }
    previewThisFacility(){
        this.preview.emit(this.facility);
    }

    imp_contact(){ $('#imp_contact').click(); }
    imp_assets(){ $('#imp_assets').click(); }
    imp_WO(){ $('#imp_WO').click(); }
    importStep = 0;
    importModule = 0; // 1. Contact  2.Assets  3. Work Orders
    importFile:any;
    importFields = [];
    paramJson = [];

    parsedContact:any;
    parseContact(fileInput){
        let THIS = this;
        let parsedContact = fileInput.target.files[0];
        let extArr = parsedContact.name.split('.');
        let ext = extArr[extArr.length - 1];
        parsedContact['ext'] = ext;
        this.importFile = parsedContact;
        if(ext.toLowerCase() == 'csv'){
            let reader: FileReader = new FileReader();
            reader.readAsText(parsedContact);
            reader.onload = (e) => {
                let csv: string = reader.result;
                let allTextLines = csv.split(/\r|\n|\r/);
                let headers = allTextLines[0].split(',');
                let lines = [];
                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma
                    let data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        let tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }
                        lines.push(tarr);
                    }
                }
                THIS.parsedContact = lines;
                THIS.importStep = 1;
                THIS.importModule = 1;
            };
        } else if(ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx'){
            const target: DataTransfer = <DataTransfer>(fileInput.target);
            if (target.files.length !== 1) throw new Error('Cannot use multiple files');
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                let data = XLSX.utils.sheet_to_json(ws, {header: 1});
                THIS.parsedContact = data;
                THIS.importStep = 1;
                THIS.importModule = 1;
            };
            reader.readAsBinaryString(target.files[0]);
        } else {
            // Invalid file
        }



    }
    contactFields = [
        {name: 'first_name', title: 'First Name', map : 0},
        {name: 'last_name', title: 'Last Name', map : 0},
        {name: 'email', title: 'Email Address', map : 0},
        {name: 'phone', title: 'Phone', map : 0},
        {name: 'mobile', title: 'Mobile', map : 0},
        {name: 'location', title: 'Location', map : 0},
        {name: 'city', title: 'City', map : 0},
        {name: 'state', title: 'State', map : 0},
        {name: 'zip_code', title: 'Zip Code', map : 0},
    ];
    makeMappedContact(){
        let THIS = this;
        this.importStep = 2;
        let importFields = this.parsedContact[0];
        let rv = [];
        $.each(importFields, function (i, v) {
            let f = { title: v, map : THIS.matchMapContact(v)};
            rv.push(f);
        });
        this.importFields = rv;
    }
    matchMapContact(v){
        for(let i=0;i<this.contactFields.length;i++){
            let field = this.contactFields[i];
            if ((field.title || '').match(new RegExp(v, 'gi'))) {
                this.contactFields[i].map = 1;
                return field.name;
            }
        }
        return '0';
    }
    makeParamContact(){
        let THIS = this;
        let paramJson = [];
        $.each(THIS.parsedContact, function (i, v) {
            if(THIS.CustomHelper.makeInt(i) > 0){
                let fName = THIS.importFields.map(e => e.map).indexOf('first_name') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('first_name')] : '';
                let lName = THIS.importFields.map(e => e.map).indexOf('last_name') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('last_name')] : '';
                let param = {
                    facility_id : THIS.facility.id,
                    name: fName+' '+lName,
                    email: THIS.importFields.map(e => e.map).indexOf('email') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('email')] : '',
                    phone: THIS.importFields.map(e => e.map).indexOf('phone') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('phone')] : '',
                    mobile: THIS.importFields.map(e => e.map).indexOf('mobile') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('mobile')] : '',
                    address: THIS.importFields.map(e => e.map).indexOf('location') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('location')] : '',
                    city: THIS.importFields.map(e => e.map).indexOf('city') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('city')] : '',
                    state: THIS.importFields.map(e => e.map).indexOf('state') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('state')] : '',
                    zip_code: THIS.importFields.map(e => e.map).indexOf('zip_code') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('zip_code')] : '',
                };
                paramJson.push(param);
            }
        });
        this.paramJson = paramJson;
        this.contactsService.createLot({contacts : this.paramJson}).subscribe(res => {
            this.importStep = 3;
            this.facilityService.refreshFacility();
            // Pusher emit
        });
    }

    parsedAssets:any;
    parseAssets(fileInput){
        let THIS = this;
        let parsedAssets = fileInput.target.files[0];
        let extArr = parsedAssets.name.split('.');
        let ext = extArr[extArr.length - 1];
        parsedAssets['ext'] = ext;
        this.importFile = parsedAssets;
        if(ext.toLowerCase() == 'csv'){
            let reader: FileReader = new FileReader();
            reader.readAsText(parsedAssets);
            reader.onload = (e) => {
                let csv: string = reader.result;
                let allTextLines = csv.split(/\r|\n|\r/);
                let headers = allTextLines[0].split(',');
                let lines = [];
                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma
                    let data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        let tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }
                        lines.push(tarr);
                    }
                }
                THIS.parsedAssets = lines;
                THIS.importStep = 1;
                THIS.importModule = 2;
            };
        } else if(ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx'){
            const target: DataTransfer = <DataTransfer>(fileInput.target);
            if (target.files.length !== 1) throw new Error('Cannot use multiple files');
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                let data = XLSX.utils.sheet_to_json(ws, {header: 1});
                THIS.parsedAssets = data;
                THIS.importStep = 1;
                THIS.importModule = 2;
            };
            reader.readAsBinaryString(target.files[0]);
        } else {
            // Invalid file
        }



    }
    AssetsFields = [
        {name: 'asset_id', title: 'Asset Id', map : 0},
        {name: 'name', title: 'Name', map : 0},
        {name: 'location', title: 'Location', map : 0},
        {name: 'category_id', title: 'Category Id', map : 0},
        {name: 'brand', title: 'Brand', map : 0},
        {name: 'model', title: 'Model', map : 0},
        {name: 'serial_number', title: 'Serial Number', map : 0},
    ];
    makeMappedAssets(){
        let THIS = this;
        this.importStep = 2;
        let importFields = this.parsedAssets[0];
        let rv = [];
        $.each(importFields, function (i, v) {
            let f = { title: v, map : THIS.matchMapAssets(v)};
            rv.push(f);
        });
        this.importFields = rv;
    }
    matchMapAssets(v){
        for(let i=0;i<this.AssetsFields.length;i++){
            let field = this.AssetsFields[i];
            if ((field.title || '').match(new RegExp(v, 'gi'))) {
                this.AssetsFields[i].map = 1;
                return field.name;
            }
        }
        return '0';
    }
    makeParamAssets(){
        let THIS = this;
        let paramJson = [];
        $.each(THIS.parsedAssets, function (i, v) {
            if(THIS.CustomHelper.makeInt(i) > 0){
                let param = {
                    facility : THIS.facility.id,
                    asset_id: THIS.importFields.map(e => e.map).indexOf('asset_id') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('asset_id')] : '',
                    name: THIS.importFields.map(e => e.map).indexOf('name') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('name')] : '',
                    location: THIS.importFields.map(e => e.map).indexOf('location') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('location')] : '',
                    category_id: THIS.importFields.map(e => e.map).indexOf('category_id') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('category_id')] : 5,
                    brand: THIS.importFields.map(e => e.map).indexOf('brand') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('brand')] : '',
                    model: THIS.importFields.map(e => e.map).indexOf('model') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('model')] : '',
                    serial_number: THIS.importFields.map(e => e.map).indexOf('serial_number') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('serial_number')] : '',
                };
                paramJson.push(param);
            }
        });
        this.paramJson = paramJson;
        this.importStep = 3;
        this.assetService.createLot({assets : this.paramJson}).subscribe(res => {
            this.importStep = 3;
            this.facilityService.refreshFacility();
            // Pusher emit
        });
    }

    parsedWO:any;
    parseWO(fileInput){
        let THIS = this;
        let parsedWO = fileInput.target.files[0];
        let extArr = parsedWO.name.split('.');
        let ext = extArr[extArr.length - 1];
        parsedWO['ext'] = ext;
        this.importFile = parsedWO;
        if(ext.toLowerCase() == 'csv'){
            let reader: FileReader = new FileReader();
            reader.readAsText(parsedWO);
            reader.onload = (e) => {
                let csv: string = reader.result;
                let allTextLines = csv.split(/\r|\n|\r/);
                let headers = allTextLines[0].split(',');
                let lines = [];
                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma
                    let data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        let tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }
                        lines.push(tarr);
                    }
                }
                THIS.parsedWO = lines;
                THIS.importStep = 1;
                THIS.importModule = 3;
            };
        } else if(ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx'){
            const target: DataTransfer = <DataTransfer>(fileInput.target);
            if (target.files.length !== 1) throw new Error('Cannot use multiple files');
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                let data = XLSX.utils.sheet_to_json(ws, {header: 1});
                THIS.parsedWO = data;
                THIS.importStep = 1;
                THIS.importModule = 3;
            };
            reader.readAsBinaryString(target.files[0]);
        } else {
            // Invalid file
        }



    }
    WOFields = [
        {name: 'prefix', title: 'Prefix', map : 0},
        {name: 'work_order_id', title: 'Work Order Id', map : 0},
        {name: 'subject', title: 'Subject', map : 0},
        {name: 'description', title: 'Description', map : 0},
        {name: 'due', title: 'Due Date', map : 0},
        {name: 'created_at', title: 'Created Date', map : 0},
        {name: 'add_to_calendar', title: 'Add To Calendar', map : 0},
        {name: 'calendar_color', title: 'Calendar Color', map : 0},
    ];
    makeMappedWO(){
        let THIS = this;
        this.importStep = 2;
        let importFields = this.parsedWO[0];
        let rv = [];
        $.each(importFields, function (i, v) {
            let f = { title: v, map : THIS.matchMapWO(v)};
            rv.push(f);
        });
        this.importFields = rv;
    }
    matchMapWO(v){
        for(let i=0;i<this.WOFields.length;i++){
            let field = this.WOFields[i];
            if ((field.title || '').match(new RegExp(v, 'gi'))) {
                this.WOFields[i].map = 1;
                return field.name;
            }
        }
        return '0';
    }
    makeParamWO(){
        let THIS = this;
        let paramJson = [];
        $.each(THIS.parsedWO, function (i, v) {
            if(THIS.CustomHelper.makeInt(i) > 0){
                let param = {
                    facility_id : THIS.facility.id,
                    prefix: THIS.importFields.map(e => e.map).indexOf('prefix') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('prefix')] : '',
                    work_order_id: THIS.importFields.map(e => e.map).indexOf('work_order_id') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('work_order_id')] : '',
                    subject: THIS.importFields.map(e => e.map).indexOf('subject') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('subject')] : '',
                    description: THIS.importFields.map(e => e.map).indexOf('description') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('description')] : '',
                    due: THIS.importFields.map(e => e.map).indexOf('due') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('due')] : '',
                    created_at: THIS.importFields.map(e => e.map).indexOf('created_at') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('created_at')] : '',
                    add_to_calendar: THIS.importFields.map(e => e.map).indexOf('add_to_calendar') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('add_to_calendar')] : '',
                    calendar_color: THIS.importFields.map(e => e.map).indexOf('calendar_color') > -1 ? v[THIS.importFields.map(e => e.map).indexOf('calendar_color')] : '',
                };
                paramJson.push(param);
            }
        });
        this.paramJson = paramJson;
        this.importStep = 3;
    }

    ngOnInit() {
        let THIS = this;
    }
    ngOnDestroy() {
    }


}
