import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {MediaService} from '../../_services/media.service';
declare let swal: any;

@Component({
    selector: 'page-mail-inbox',
    templateUrl: './inbox.component.html'
})
export class InboxComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    templateT = 1;

    currentUser;
    ROUTES = '/mails';
    miniScreen = 0;

    FilterOn = 0;
    FilterScroll = 295;
    ToggleFilter(c){
        if(c == ''){
            this.FilterOn = this.FilterOn == 1 ? 0 : 1;
        }
        if(this.FilterOn == 1){
            this.FilterScroll = 340;
        } else {
            this.FilterScroll = 295;
        }
    }

    constructor(private mediaService: MediaService) {
        let THIS = this;
        THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        if (window.innerWidth <= 1200) {
            THIS.miniScreen = 1;
        }
        THIS.reloadReceiveMail();
        $('body').off().on('click', '#__mailCOmposer', function () {
            THIS.composeNewMail();
        });
        $('body').off().on('click', '#__mailFolder__', function () {
            THIS.MailFolder = $('#__mailFolder__').attr('data-type');
            THIS.pageNo = 1;
            THIS.getReceiveMail();
        });
        THIS.pageNo = 1;
        THIS.routeMake();
    }

    refreshView() {
        this.previewPage = 0;
    }
    routeMake() {
        let THIS = this;
        let route = this.CustomHelper.getRouteStr();
        this.refreshView();
        if (route[2] != undefined) {
            if (route[2] == '') {
                this.previewPage = 0;
                this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
            }
            else if (route[2] == 'compose') {
                this.previewPage = 1;
            }
            else if (route[2] == 'reply') {
                this.previewPage = 3;
            }
            else if (route[2] == 'forward') {
                this.previewPage = 4;
            }
        } else {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + THIS.ROUTES;
            window.history.pushState("", "", url);
            this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
            this.previewPage = 0;
        }
    }
    searchKeyword = '';
    searchKeywordClear(){
        this.searchKeyword = '';
        this.getReceiveMail();
    }
    filter() {
        let THIS = this;
        clearInterval(THIS.CustomHelper.woListSearch);
        THIS.CustomHelper.woListSearch = setInterval(function() {
            clearInterval(THIS.CustomHelper.woListSearch);
            THIS.pageNo = 1;
            THIS.getReceiveMail();
        }, 1000);
    }
    highlighter(subject) {
        if (subject == '' || subject == null) {
            return subject;
        } else {
            subject = this.CustomHelper.makeStr(subject);
        }
        let searchKeyword = this.searchKeyword.replace(/\)/g, '\\)');
        let matches = subject.match(new RegExp(searchKeyword, 'gi'));
        let res = '';
        $.each(matches, function (i, v) {
            res = subject.replace(v, "<span class='text-warning-highlight'>" + v + "</span>");
        });
        if (res == '') {
            res = subject;
        }
        return res;
    }

    MailLoading = 1;
    totalMail = 0;
    pageNo = 1;
    perPage = this.CustomHelper.getPerpage();
    pageEvent;
    paginateList() {
        if (this.pageEvent.pageSize == this.perPage) {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex + 1;
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
        }
        this.getReceiveMail();
    }

    MailFolder = 'INBOX';
    Mails = [];
    FilterSearch = ['SUBJECT','FROM'];
    addFilterSearch(f){
        let idx = this.FilterSearch.indexOf(f);
        if(idx > -1){
            this.FilterSearch.splice(idx, 1);
        } else {
            this.FilterSearch.push(f);
        }
        this.reloadReceiveMail();
    }
    FilterFolder = 'All';
    addFilterFolder(f){
        this.FilterFolder = f;
        this.reloadReceiveMail();
    }
    reloadReceiveMail() {
        this.previewPage = 0;
        this.getReceiveMail();
    }
    getReceiveMail() {
        let THIS = this;
        let param = {
            pageNo: THIS.pageNo,
            perPage: THIS.perPage,
            keyword: THIS.searchKeyword,
            filterSearch : THIS.FilterSearch,
            filterFolder : THIS.FilterFolder,
            folder : THIS.MailFolder
        };
        if (THIS.pageNo > 0 && THIS.MailLoading > 0) {
            THIS.MailLoading = 0;
            $.ajax({
                url: THIS.APPCONFIG.api + 'mailbox/mails?token=' + this.currentUser.token,
                type: 'get',
                data: param,
                success: function (res) {
                    if (res.status == 2000) {
                        THIS.Mails = res.data;
                        if (THIS.miniScreen == 0 && THIS.Mails.length > 0) {
                            THIS.previewPage = 2;
                            THIS.singleSelectedMail = THIS.Mails[0];
                            THIS.selectedIde = [THIS.singleSelectedMail.msgno];
                        }
                        THIS.totalMail = res.total;
                    } else {
                        THIS.Mails.length = 0;
                    }
                    THIS.MailLoading = 1;
                    setTimeout(function () {
                        THIS.CustomHelper.tooltipRender();
                    }, 400);
                }
            });
        }
    }

    removeMails(){
        let THIS = this;
        let param = {
            folder : THIS.MailFolder,
            uids : THIS.selectedIde
        };
        swal({
            title: "Delete Mail",
            text: "Do you really want to delete these  selected Mail(s)?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                $.ajax({
                    url: THIS.APPCONFIG.api+'mailbox/removeReceivedMail?token='+THIS.currentUser.token,
                    type: 'post',
                    data: param,
                    success: function (res) {
                        THIS.getReceiveMail();
                    }
                });
            }
        });
    }
    flagMails(flag, set){
        let THIS = this;
        let param = {
            folder : THIS.MailFolder,
            flag : flag,
            set : set,
            uids : THIS.selectedIde
        };
        let msg = "Do you really want to Mark as "+flag+" these selected Mail(s)?";
        if(set == 0){
            msg = "Do you really want to Mark as Un"+flag.toLowerCase()+" these selected Mail(s)?";
        }
        swal({
            title: flag+" Mail",
            text: msg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                $.ajax({
                    url: THIS.APPCONFIG.api+'mailbox/setFlagStatus?token='+THIS.currentUser.token,
                    type: 'post',
                    data: param,
                    success: function (res) {
                        THIS.getReceiveMail();
                    }
                });
            }
        });
    }


    selectedIde = [];
    checkThisRow(e, index, mail) {
        e.preventDefault();
        let iIndex = this.selectedIde.indexOf(mail.msgno);
        if (iIndex > -1) {
            if (this.selectedIde.length == 1) {
                this.selectedIde.length = 0;
            } else {
                this.selectedIde.splice(iIndex, 1);
            }
        } else {
            this.selectedIde.push(mail.msgno);
        }
        $('.drop-menu-right').removeClass('open');

        let THIS = this;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }
    singlePreview(e, index, mail) {
        if (e.ctrlKey) {
            if (e.which == 1) {
                if (this.selectedIde.indexOf(mail.msgno) > -1) {
                    this.selectedIde.splice(this.selectedIde.indexOf(mail.msgno), 1);
                } else {
                    this.selectedIde.push(mail.msgno);
                }
                $('.drop-menu-right').removeClass('open');
            }
        } else {
            this.previewPage = 2;
            this.singleSelectedMail = mail;
            this.selectedIde.length = 0;
            // this.selectedIde = [mail.msgno];
            /*this.mediaService.readReceivedMail({'uids':this.selectedIde}).subscribe(res => {

            });*/
        }
        let THIS = this;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }
    selectAllCheck() {
        let THIS = this;
        let M = this.Mails;
        if (this.selectedIde.length == this.Mails.length) {
            this.selectedIde.length = 0;
        } else {
            this.selectedIde.length = 0;
            $.each(M, function (i, v) {
                THIS.selectedIde.push(v.msgno);
            });
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }
    _currentMail(msgno) {
        if (this.singleSelectedMail != null) {
            if (this.singleSelectedMail.msgno == msgno) {
                return true;
            }
        }
        return false;
    }


    singleSelectedMail = null;
    previewPage = 0; // 0.default  1.Compose  2.SinglePreview  3.Reply  4.Forward
    discardCompose() {
        this.previewPage = 0;
        if (this.singleSelectedMail == null) {
            if (this.Mails.length > 0 && this.miniScreen == 0) {
                this.singleSelectedMail = this.Mails[0];
                this.previewPage = 2;
            }
        } else {
            if (this.Mails.length > 0 && this.miniScreen == 0) {
                this.singleSelectedMail = this.Mails[0];
                this.previewPage = 2;
            }
        }
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + this.ROUTES;
        window.history.pushState("", "", url);
    }
    composeNewMail() {
        let THIS = this;
        let param = {
            type : 'mail',
            subject: '',
            to: [],
            msg: '',
        };
        $('#sendMailModalTrigger').attr('data-type', JSON.stringify(param));
        $('#sendMailModalTrigger').click();
    }
    ReplyMail = null;
    replyMail() {
        let mail = null;
        if(this.selectedIde.length == 1){
            let THIS = this;
            let id = THIS.selectedIde[0];
            let index = THIS.Mails.map(function(e) { return e.msgno; }).indexOf(id);
            mail = this.Mails[index];
        }
        this.replyThis(mail);
    }
    replyThis(mail) {
        let THIS = this;
        let param = {
            folder : THIS.MailFolder,
            msgno : mail.msgno
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'mailbox/mails/single?token=' + this.currentUser.token,
            type: 'get',
            data: param,
            success: function (res) {
                if(res.status == 2000){
                    let bodyMail = '';
                    if(res.data.message.html != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.html)); }
                    else if(res.data.message.html2 != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.html2)); }
                    else if(res.data.message.html3 != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.html3)); }
                    else if(res.data.message.html1 != ''){ bodyMail = atob(res.data.message.html1); }
                    else if(res.data.message.text != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.text)); }


                    let intro = '-------- Original Message --------<br>' +
                        ': '+mail.subject+'<br>' +
                        'Date: '+mail.date+'<br>' +
                        'From: '+mail.from+'<br>' +
                        'To: '+mail.to+'<br>' +
                        'Reply-To: '+mail.from+'<br><br>';
                    let param = {
                        type : 'mail',
                        subject: 'Re: '+mail.subject,
                        to: [mail.from],
                        msg: intro+'<blockquote>'+bodyMail+'</blockquote><br><br>',
                    };
                    $('#sendMailModalTrigger').attr('data-type', JSON.stringify(param));
                    $('#sendMailModalTrigger').click();
                }
            }
        });
    }
    ForwardMail = null;
    forwardMail() {
        let mail = null;
        if(this.selectedIde.length == 1){
            let THIS = this;
            let id = THIS.selectedIde[0];
            let index = THIS.Mails.map(function(e) { return e.msgno; }).indexOf(id);
            mail = this.Mails[index];
        }
        this.forwardThis(mail);
    }
    forwardThis(mail) {
        let THIS = this;
        let param = {
            folder : THIS.MailFolder,
            msgno : mail.msgno
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'mailbox/mails/single?token=' + this.currentUser.token,
            type: 'get',
            data: param,
            success: function (res) {
                if(res.status == 2000){
                    let bodyMail = '';
                    if(res.data.message.html != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.html)); }
                    else if(res.data.message.html2 != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.html2)); }
                    else if(res.data.message.html3 != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.html3)); }
                    else if(res.data.message.html1 != ''){ bodyMail = atob(res.data.message.html1); }
                    else if(res.data.message.text != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.text)); }


                    let intro = '-------- Original Message --------<br>' +
                        ': '+mail.subject+'<br>' +
                        'Date: '+mail.date+'<br>' +
                        'From: '+mail.from+'<br>' +
                        'To: '+mail.to+'<br>' +
                        'Reply-To: '+mail.from+'<br><br>';
                    let param = {
                        type : 'mail',
                        subject: mail.subject,
                        to: [],
                        msg: intro+'<blockquote>'+bodyMail+'</blockquote><br><br>',
                    };

                    $('#sendMailModalTrigger').attr('data-type', JSON.stringify(param));
                    $('#sendMailModalTrigger').click();
                }
            }
        });
    }


    printMail(el) {
        let w = window.open();
        w.document.write(document.getElementById(el).innerHTML);
        w.print();
        w.close();
    }
}
