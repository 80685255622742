import {Component} from '@angular/core';
import {Router, Event, NavigationEnd, ActivatedRoute, Params} from '@angular/router';
import {WorkOrderService} from '../../_services/work-orders.service';
import {APPCONFIG} from '../../config';
import {MatTooltipModule} from '@angular/material';


@Component({
  selector: 'inspection-online-layout',
  templateUrl: './inspectionOnline.component.html'
})
export class InspectionOnlineComponent {

  short_code;
  loading = 1;
  workOrders = [];
  currentUrl = '';
  APPCONFIG = APPCONFIG;

  constructor(private router: Router, private route: ActivatedRoute,
              private workOrderService: WorkOrderService,
              public tooltip: MatTooltipModule) {
    let THIS = this;
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
    this.route.params.forEach((params: Params) => {
      $.ajax({
        url: THIS.APPCONFIG.apiInc + 'get-single-short-code',
        type: 'post',
        data: {short_code: params.short_code},
        success: function (res) {
          if (res.status == 2000) {
            THIS.workOrders.push(res.data);
          } else {
            THIS.loading = 0;
          }
        }
      });
    });

  }
}
