import {Component, Input, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {UserService} from '../../../_services/user.service';
import {MediaService} from '../../../_services/media.service';
import {TabsService} from '../../../_services/tabs.service';
import { WorkOrdersComponent } from '../../../pages/work-orders/work-orders.component';
import { ProfileComponent } from '../../../pages/profile/profile.component';
import { AccountComponent } from '../../../pages/account/account.component';
declare let swal: any;
declare let toastr: any;
declare let Sortable: any;
declare let owlCarousel: any;

@Component({
    selector: 'page-personnel-single',
    templateUrl: './personnel-single.component.html'
})
export class PersonnelSingleComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    @Output() editPreview = new EventEmitter<number>();
    @Output() removePreview = new EventEmitter<number>();
    @Output() listRefresh = new EventEmitter<number>();
    @Input() Template: any;
    TemplateCheck = 2;
    @Input() preId: any;
    ErrNo = 0;
    DataDetailsChange = 0;
    Data: any;
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};

    inlinePrefixId = 0;
    updateChangesDiscard(){
        // this.CustomHelper.woRouteDiscard = 1;
    }
    confirmDiscard(){
        this.discard.emit(1);
    }
    ContractStartOption = {
        altInput: true,
        defaultDate: null,
        minDate: null,
        altFormat: this.CustomHelper.dateFormatFlat(),
        onChange: function(selectedDates, dateStr, instance) {},
    };
    param_name = '';
    param = {
        id : 0,
        avatar : '',
        first_name : '',
        last_name : '',
        email : '',
        phone : '',
        address : '',
        hourly_rate : '',
        group_id : [],
        licenced : 0
    };

    validForm = 1;
    saveSubmit(){
        let param_name = this.param_name.split(' ');
        this.param.first_name = param_name[0] != undefined ? param_name[0] : '';
        this.param.last_name = param_name[1] != undefined ? param_name[1] : '';
        this.param.avatar = this.selectedFilesPreview;
        let valid = this.validationThisForm();
        if (valid == 1) {
            this.userService.editPersonnel(this.param).subscribe(res => {
                if(res.status == 2000){
                    let THIS = this;
                    toastr.success('Personnel information has been updated successfully', 'Personnel Information Update', THIS.toastrOption);
                    this.preview.emit(this.preId);
                    // Pusher emit

                } else {
                    swal({
                        text: res.error,
                        icon: "error",
                        dangerMode: true,
                        className: 'swal-btn-center'
                    });
                }
            });
        }
    }
    validationThisForm(){
        let valid = 1;
        if (this.param.first_name == '') {
            valid = 0;
            swal({
              text: 'First name is required',
              icon: "error",
              dangerMode: true,
              className: 'swal-btn-center'
            });
            return valid;
        }
        if (this.param.last_name == '') {
            valid = 0;
            swal({
              text: 'Last name is required',
              icon: "error",
              dangerMode: true,
              className: 'swal-btn-center'
            });
            return valid;
        }
        if (this.param.email == '') {
            valid = 0;
            swal({
              text: 'Email address is required',
              icon: "error",
              dangerMode: true,
              className: 'swal-btn-center'
            });
            return valid;
        } else {
            let check = this.CustomHelper.emailValidation(this.param.email);
            if(!check){
                swal({
                    text: this.param.email+'- is not a valid email address. Please type valid email address.',
                    icon: "error",
                    dangerMode: true,
                    className: 'swal-btn-center'
                });
                this.param.email = '';
                valid = 0;
                return valid;
            }
        }

        if(this.param.email == ''){
            valid = 0;
            swal({
                text: 'Email is required',
                icon: "error",
                dangerMode: true,
                className: 'swal-btn-center'
            });
            return valid;
        } else {
            let domain = this.param.email.substring(this.param.email.lastIndexOf("@") +1);
            // let restrDomain = ['gmail.com','yahoo.com','live.com','outlook.com','hotmail.com.au','live.com.au','hotmail.com'];
            let restrDomain = ['equidesk.com'];
            if(restrDomain.indexOf(domain.toLowerCase()) > -1){
                valid = 0;
                swal({
                    text: 'Free emails are not allowed. Please use your work/official email address',
                    icon: "error",
                    dangerMode: true,
                    className: 'swal-btn-center'
                });
                return valid;
            }
        }
        this.validForm = valid;
        return valid;
    }
    checkThisForm() {
        this.param.first_name = $.trim(this.param.first_name);
        this.param.last_name = $.trim(this.param.last_name);
        let valid = 1;
        if (this.param.first_name == '') {
            valid = 0;
        }
        if (this.param.last_name == '') {
            valid = 0;
        }
        if (this.param.email == '') {
            valid = 0;
        }
        this.validForm = valid;
        return valid;
    }


    currentUser: any;
    constructor(private userService: UserService,
                private tabsService: TabsService,
                private mediaService: MediaService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.currentUserInfo);
        this.tabsService.getTabs().subscribe(tabs => {
            this.tabs = tabs;
        });
    }


    avatarLoading = 0;
    selectedFilesPreview = '';
    uploadThisMedia(file, media_type, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        this.mediaService.create(formData).subscribe(res => {
            callback(res);
        });
    }
    selectTheseFiles(e) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            let eachFile = accepted[i];
            if(eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg'){
                THIS.avatarLoading = 1;
                this.uploadThisMedia(eachFile, '1', function (uploaded) {
                    THIS.avatarLoading = 0;
                    if(uploaded.status != undefined){
                        if(uploaded.status == 9000){
                            swal({
                                title: "Storage Full",
                                text: 'Your Storage is full. Please delete some files',
                                icon: "error",
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                        } else {
                            swal({
                                title: "Invalid file format uploaded",
                                text: 'Please upload an image in Jpeg or Png only.',
                                icon: "error",
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                        }
                    } else {
                        THIS.selectedFilesPreview = uploaded.file_path;
                    }
                });
            } else {
                swal({
                    title: "Invalid File Type",
                    text: 'Only jpg/jpeg and png files are allowed',
                    icon: "error",
                    dangerMode: true,
                    className: 'swal-btn-center'
                });
            }
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }

    DataGroup = [];
    getAllGroup(){
        let THIS = this;
        let param = {
            keyword : '',
            pageNo : 1,
            perPage : 100,
            sort_by : 'name',
            order_by : 'asc',
        };
        this.userService.getAllGroups(param).subscribe(res => {
            if(res.status == 2000){
                this.DataGroup = res.data;
                setTimeout(function () {
                    (<any>$('.chosen-default-multiple-group')).val(THIS.param.group_id);
                    (<any>$('.chosen-default-multiple-group')).chosen('destroy');
                    (<any>$('.chosen-default-multiple-group')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_multiple: 'Select groups'
                    }).on('change', function(e,p){
                        if(p.selected != undefined){
                            THIS.param.group_id.push(p.selected);
                        } else {
                            THIS.param.group_id.splice(THIS.param.group_id.indexOf(p.deselected), 1);
                        }
                    });
                }, 100);
            }
        });
    }

    changeLicenced(){
        this.param.licenced = (this.param.licenced == 0 ? 1 : 0);
        let THIS = this;

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
        let child = ___selectedTab+1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
        (<any>$('#licencedModal')).modal('show');
        // swal('No Licenced yet');
        setTimeout(function () {
            THIS.param.licenced = 0;
        }, 100);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.Template != undefined){
            this.TemplateCheck = 0;
            this.TemplateCheck = changes.Template.currentValue;
        }
        if(changes.preId != undefined){
            this.DataDetailsChange = 0;
            let preId = changes.preId.currentValue;
            if(preId != ''){
                this.userService.singlePersonnel({id:preId}).subscribe(res => {
                    if(res.status == 2000){
                        let data = res.data;
                        this.Data = data;

                        this.selectedFilesPreview = data.avatar;
                        this.param.id = data.id;
                        this.param.avatar = data.avatar;
                        this.param.first_name = data.first_name;
                        this.param.last_name = data.last_name;
                        this.param_name = this.param.first_name+' '+this.param.last_name;
                        this.param.email = data.email;
                        this.param.phone = data.phone;
                        this.param.address = data.copany_address;
                        this.param.hourly_rate = data.rate;
                        this.param.licenced = data.licenced;
                        this.DataDetailsChange = 1;
                        let THIS = this;
                        $.each(data.groups, function(i, v){
                            THIS.param.group_id.push(v.id.toString());
                        });
                        this.getAllGroup();

                    } else{
                        this.DataDetailsChange = -1;
                        if(res.status == 5001){
                            this.ErrNo = 5001;
                        }
                        else if(res.status == 5002){
                            this.ErrNo = 5002;
                        }
                        else if(res.status == 5003){
                            this.ErrNo = 5003;
                        } else {
                            this.ErrNo = 0;
                        }
                    }
                });
            }
        }
    }
    editThisPerson(){
        this.editPreview.emit(this.preId);
    }
    removeThisPerson(){
        this.removePreview.emit(this.preId);
    }

    tabs = [];
    selectedTab = 0;
    TabSlide;
    openWO(info){
        localStorage.WoPreId = info.uid;
        let THIS = this;
        this.tabsService.addTab({
            description: 'Work Orders',
            component: WorkOrdersComponent,
            id: (new Date).getTime()
        });
        this.NewTabSwitch();
        let list = document.getElementById("sort-tabs");
        Sortable.create(list,{
            onEnd: function (e) {
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                THIS.tabs[e.oldIndex] = THIS.tabs.splice(e.newIndex, 1, THIS.tabs[e.oldIndex])[0];
                THIS.selectedTab = e.newIndex;
                THIS.slideUpdate(THIS.selectedTab, 1);
            },
        });
    }
    NewTabSwitch() {
        this.selectedTab = this.tabs.length-1;
        this.tabsService.setSelectedTab(this.selectedTab);
        this.slideUpdate(this.selectedTab, 1);
    }
    slideUpdate(selectedTab, newD){
        let THIS = this;
        setTimeout(function () {
            if(newD == 1){
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                THIS.TabSlide = (<any>$('.owl-carousel')).owlCarousel({
                    loop:false,
                    nav:false,
                    dots:false,
                    mouseDrag:false,
                    touchDrag:false,
                    items:1,
                    animateIn: 'fadeIn faster',
                    autoplay: false
                }).on('changed.owl.carousel', function(event) {
                    THIS.selectedTab = event.item.index;
                    THIS.tabsService.setSelectedTab(THIS.selectedTab);
                });
            }
            (<any>$('.owl-carousel')).trigger('to.owl.carousel', selectedTab);
        }, 100);
    }
    openProfile(){
        let THIS = this;
        this.tabsService.addTab({
            description: 'Profile',
            component: ProfileComponent,
            id: (new Date).getTime()
        });
        this.NewTabSwitch();
        let list = document.getElementById("sort-tabs");
        Sortable.create(list,{
            onEnd: function (e) {
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                THIS.tabs[e.oldIndex] = THIS.tabs.splice(e.newIndex, 1, THIS.tabs[e.oldIndex])[0];
                THIS.selectedTab = e.newIndex;
                THIS.slideUpdate(THIS.selectedTab, 1);
            },
        });
    }
    ManageProfile(preId){
        let THIS = this;
        localStorage.AccPreId = preId;
        this.tabsService.addTab({
            description: 'Account',
            component: AccountComponent,
            id: (new Date).getTime()
        });
        this.NewTabSwitch();
        let list = document.getElementById("sort-tabs");
        Sortable.create(list,{
            onEnd: function (e) {
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                THIS.tabs[e.oldIndex] = THIS.tabs.splice(e.newIndex, 1, THIS.tabs[e.oldIndex])[0];
                THIS.selectedTab = e.newIndex;
                THIS.slideUpdate(THIS.selectedTab, 1);
            },
        });
    }
    currencyIcon():string {    
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
    }

}
