import {Component, EventEmitter, Output, ViewChild, Input, SimpleChanges} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';

import {WorkOrderService} from '../../../_services/work-orders.service';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let fabric: any;
declare let Pickr: any;
declare let Pusher: any;

@Component({
  selector: 'inspection-create',
  templateUrl: './createInspection.component.html'
})
export class CreateInspectionComponent {
  @ViewChild('myPond') myPond: any;
  @Output() discard = new EventEmitter<number>();
  @Output() preview = new EventEmitter<number>();
  @Output() addnewRe = new EventEmitter<number>();
  @Input() ProjectId: any;
  @Input() facility_id: any;
  @Input() max_start_date: any = null;
  @Input() max_due_date: any = null;

  id = 0;
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  currentUser;

  constructor(
    public snackBar: MatSnackBar,
    protected facilityService: FacilityService,
    private workOrderService: WorkOrderService,
    private mediaService: MediaService) {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.currentUser = Session;
    this.getAllFacility();
    this.getAllJobStatus(0);
    this.pty_get(0);
    this.personnel_get();


    this.param.created_at = this.CustomHelper.formatDateTimeToDB(new Date());
    this.dateCreatedOption.onChange = function (selectedDates, dateStr, instance) {
      if (selectedDates.length > 0) {
        THIS.dateCreated = new Date(selectedDates);
      } else {
        THIS.dateCreated = null;
      }
      THIS.onCreatedSelect();
    };
    this.dateDueOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.dateDue = new Date(selectedDates);
      THIS.onDueSelect();
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ProjectId != undefined) {
      this.param.project_id = changes.ProjectId.currentValue;
    }
    if (changes.facility_id != undefined) {
      this.param.facility_id = changes.facility_id.currentValue;
    }
    if (changes.max_start_date != undefined) {
      this.max_start_date = changes.max_start_date.currentValue;
      this.dateCreatedOption.minDate = this.max_start_date;
      this.dateDueOption.minDate = this.max_start_date;
    }
    if (changes.max_due_date != undefined) {
      this.max_due_date = changes.max_due_date.currentValue;
      this.dateCreatedOption.maxDate = this.max_due_date;
      this.dateDueOption.maxDate = this.max_due_date;
    }
  }


  Facility = [];
  FacilityComp = [];
  FacilityContact = [];

  getSingleFacilityContact(id) {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/getSingle/contact?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          THIS.FacilityContact = res.data;
        }
      }
    });
  }

  getAllFacility() {
    let THIS = this;
    THIS.Facility.length = 0;
    THIS.FacilityComp.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.FacilityComp = res.facility;
        $.each(res.facility, function (i, v) {
          v['pp'] = 0;
          THIS.Facility.push(v);
          $.each(v.child, function (j, vC) {
            vC['pp'] = 1;
            THIS.Facility.push(vC);
            $.each(vC.child, function (k, vCC) {
              vCC['pp'] = 2;
              THIS.Facility.push(vCC);
              $.each(vCC.child, function (l, vCCC) {
                vCCC['pp'] = 3;
                THIS.Facility.push(vCCC);
              });
            });
          });
        });
        setTimeout(function () {
          if ($('.reRenderFacility').length > 0) {
            let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
            if (facilityId > 0) {
              THIS.param.facility_id = facilityId.toString();
            }
          }
          (<any>$('.chosen-default-single-fac')).chosen('destroy');
          (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
          (<any>$('.chosen-default-single-fac')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Facility'
          }).on('change', function (e, p) {
            // THIS.FacilityContact.length = 0;
            if (e.target.value === '000') {
              (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
              (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
              let ___selectedTab = 0;
              let tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              let child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#createFacilityModal')).modal('show');
            } else {
              THIS.param.facility_id = e.target.value;
              THIS.validationThisForm();
              THIS.updateChangesDiscard();
              // THIS.getSingleFacilityContact(THIS.param.facility_id);
            }
          });
        }, 100);
      }
    });
  }

  SelectedFacility = null;

  getSingleFacility(id) {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/get/contact?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        if (res.status == 2000) {
          THIS.SelectedFacility = res.data;
        } else {
          THIS.SelectedFacility = null;
        }
      }
    });
  }

  Priority = [];

  pty_get(refresh): void {
    let THIS = this;
    this.Priority.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'priorities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.Priority = res.data;
      }
    });
  }

  choosePty(priority) {
    let THIS = this;
    if (priority != null) {
      THIS.param.priority = priority.id.toString();
      if (THIS.param.created_at != null && THIS.param.created_at != '') {
        let v = new Date(THIS.dateCreated);
        let min = THIS.CustomHelper.makeFloat(priority.amount) * THIS.CustomHelper.makeFloat(priority.duration);
        v.setMinutes(v.getMinutes() + min);
        THIS.dateDue = THIS.CustomHelper.formatDateTimeToDB(v);
        THIS.changingStart = 1;
        setTimeout(function () {
          THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
          THIS.dateDueOption.defaultDate = new Date(THIS.dateDue);
          THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue));
          THIS.changingStart = 0;
        }, 400);
      }
    } else {
      THIS.param.priority = '0';
      if (THIS.param.created_at != null && THIS.param.created_at != '') {
        THIS.param.due = THIS.dateDue != null ? THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue)) : '';
      }
    }
  }

  Personnel = {
    org: [],
    person: []
  };

  personnel_get(): void {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Personnel = res;
        }
        setTimeout(function () {
          if ($('.reRenderPerson').length > 0) {
            let personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
            if (personnel_id > 0) {
              THIS.param.personnel_id.push(personnel_id.toString());
            }
          }
          let pp_val = THIS.param.personnel_id;
          (<any>$('.chosen-default-multiple-per')).val(pp_val);
          (<any>$('.chosen-default-multiple-per')).chosen('destroy');
          (<any>$('.chosen-default-multiple-per')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No personnel selected'
          }).on('change', function (e, p) {
            if (p.selected != undefined) {
              let v = p.selected;
              if (v === '000') {
                (<any>$('.chosen-default-multiple-per')).val(THIS.param.personnel_id.join());
                (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
                let ___selectedTab = 0;
                (<any>$('.chosen-default-multiple-per')).val('');
                let tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) {
                  if ($(v).find('a.active').length > 0) {
                    ___selectedTab = i;
                  }
                });
                let child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#PersonQuickModal')).modal('show');
              } else {
                THIS.param.personnel_id.push(v.toString());
              }
            } else {
              let v = p.deselected;
              if (THIS.param.personnel_id.indexOf(v) > -1) {
                THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
              }
            }
            THIS.updateChangesDiscard();
          });
        }, 100);
      }
    });
  }

  jobStatusList = [];
  jobStatusDefault = [];
  jobStatusCustom = [];

  getAllJobStatus(refresh) {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-status?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.jobStatusDefault.length = 0;
          THIS.jobStatusCustom.length = 0;
          THIS.jobStatusList = res.data;
          localStorage._jsts = JSON.stringify(res.data);
          $.each(res.data, function (i, v) {
            if (v.type == 0) {
              THIS.jobStatusDefault.push(v);
            } else {
              THIS.jobStatusCustom.push(v);
            }
          });
        }
        setTimeout(function () {
          if ($('.reRenderJobStatus').length > 0) {
            let status_code = parseInt($('.reRenderJobStatus').attr('data-id'));
            if (status_code > 0) {
              THIS.param.status_code = status_code;
            }
          }
          (<any>$('.chosen-default-single-status')).chosen('destroy');
          (<any>$('.chosen-default-single-status')).val(THIS.param.status_code);
          (<any>$('.chosen-default-single-status')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select job status'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              let ___selectedTab = 0;
              let tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              let child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#jobStatusModal')).modal('show');
              e.target.value = '';
              (<any>$('.chosen-default-single-status')).val(THIS.param.status_code);
              (<any>$('.chosen-default-single-status')).trigger('chosen:updated');
            } else {
              THIS.param.status_code = e.target.value;
              THIS.updateChangesDiscard();
            }
          });
        }, 100);
      }
    });
  }

  invPersonnels = {
    org: [],
    person: []
  };

  getAllSupplier() {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.invPersonnels.org = res.org;
          THIS.invPersonnels.person = res.person;
        }
        setTimeout(function () {
          (<any>$('.chosen-default-single-supplier')).chosen('destroy');
          (<any>$('.chosen-default-single-supplier')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Supplier'
          }).on('change', function (e, p) {
            let v = e.target.value;
          });
        }, 500);
      }
    });
  }


  confirmDiscard() {
    /*let param = {
        prefix : this.param.prefix,
        work_order_id : this.param.work_order_id
    };
    this.workOrderService.getLastWorkOrderIdRemove(param).subscribe(res => {});*/
    this.discard.emit(1);
  }

  selectedWorkAttach = [];
  selectedFiles = [];
  selectedFilesPreview = [];

  uploadThisMedia(file, media_type, localtion, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  removeThisMedia(id, media_type) {
    let param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  dragFileOverStart() {
    let trigger = $('#uploadDragWork');
    trigger.addClass('onEnter');
  }

  dragFileOverEnd() {
    let trigger = $('#uploadDragWork');
    trigger.removeClass('onEnter');
  }

  _uploadError = 0;
  _locPermit = 0;

  dragFilesDropped(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    if (e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        if (this.selectedFiles.length < 3) {
          let eachFile = e.dataTransfer.files[i];
          this.selectedFiles.push(eachFile);
          if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            if (THIS.currentUser.modules.geo_tagged_photo == 1) {
              navigator.geolocation.getCurrentPosition(function (position) {
                if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                  THIS._locPermit = 1;
                  let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                  THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  THIS._locPermit = -1;
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                }
              }, function (error) {
                swal({
                  title: 'Share Geo Location',
                  text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                  icon: 'warning',
                  buttons: ['Want to share location', 'Don\'t want to share Location'],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                      if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                          swal({
                            title: 'Storage Full',
                            text: 'Your Storage is full. Please delete some files',
                            icon: 'error',
                            dangerMode: true,
                            className: 'swal-btn-center'
                          });
                        } else {
                          swal({
                            title: 'Invalid file format uploaded',
                            text: 'Please upload an image in Jpeg or Png only.',
                            icon: 'error',
                            dangerMode: true,
                            className: 'swal-btn-center'
                          });
                        }
                      } else {
                        THIS.selectedFilesPreview.push(uploaded);
                      }
                    });
                  } else {
                    swal({
                      title: 'Share Location',
                      text: 'If you want to share location then change the browser settings and remove the block status',
                      icon: 'warning',
                      dangerMode: true,
                      className: 'swal-btn-center'
                    });
                  }
                });
              });
            } else {
              THIS._locPermit = -1;
              THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                if (uploaded.status != undefined) {
                  if (uploaded.status == 9000) {
                    swal({
                      title: 'Storage Full',
                      text: 'Your Storage is full. Please delete some files',
                      icon: 'error',
                      dangerMode: true,
                      className: 'swal-btn-center'
                    });
                  } else {
                    swal({
                      title: 'Invalid file format uploaded',
                      text: 'Please upload an image in Jpeg or Png only.',
                      icon: 'error',
                      dangerMode: true,
                      className: 'swal-btn-center'
                    });
                  }
                } else {
                  THIS.selectedFilesPreview.push(uploaded);
                }
              });
            }
          } else {
            this._uploadError = 1;
          }
        }
      }
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedFiles.length < 3) {
        let eachFile = accepted[i];
        this.selectedFiles.push(eachFile);
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                THIS._locPermit = 1;
                let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              } else {
                THIS._locPermit = -1;
                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              }
            }, function (error) {
              swal({
                title: 'Share Geo Location',
                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                icon: 'warning',
                buttons: ['Want to share location', 'Don\'t want to share Location'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  swal({
                    title: 'Share Location',
                    text: 'If you want to share location then change the browser settings and remove the block status',
                    icon: 'warning',
                    dangerMode: true,
                    className: 'swal-btn-center'
                  });
                }
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please delete some files',
                    icon: 'error',
                    dangerMode: true,
                    className: 'swal-btn-center'
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                    className: 'swal-btn-center'
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
              }
            });
          }
        } else {
          this._uploadError = 1;
        }
      }
    }
  }

  removeThisImage(index, id) {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesPreview.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  addWorkMedia() {
    $('#workMediaInput').click();
  }

  workDetails = {
    personnel: []
  };
  param = {
    project_id: 0,
    subject: '',
    media_files: '',
    attach_files: '',
    facility_id: '',
    due: '',
    created_at: '',
    overall_ratings: 0,
    add_to_calendar: 0,
    calendar_color: '',
    priority: '',
    status_code: 1,
    personnel_id: [],
    attachInventories: [],
    tasks: [],
  };
  taskEmpty = false;

  saveWorkOrderSubmit(newD) {
    this.taskEmpty = false;
    $('.show-task-error').html('');
    this.taskList.forEach((v, i) => {
      if (v.title == '') {
        this.taskEmpty = true;
        return;
      }
    });
    if (this.taskEmpty) {
      $('.show-task-error').html('Task field shouldn\'t be empty');
      return;
    }
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      let freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        let freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    let THIS = this;
    let ldd = document.getElementById('SaveThisLadda');
    let ladda = Ladda.create(ldd);
    let mediaFiles = '';
    $.each(THIS.selectedFilesPreview, function (i, v) {
      if (i == 0) {
        mediaFiles += v.id;
      } else {
        mediaFiles += ',' + v.id;
      }
    });
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    this.param.media_files = mediaFiles;
    this.param.attach_files = mediaAttach;
    this.param.tasks = THIS.taskList;

    this.param.add_to_calendar = this.add2Calendar;
    this.param.calendar_color = this.calendarStatus;

    this.param.created_at = this.param.created_at != null && this.param.created_at != '' ? this.CustomHelper.formatDateTimeToDB(this.param.created_at) : '';
    this.param.due = this.param.due != null && this.param.due != '' ? this.CustomHelper.formatDateTimeToDB(this.param.due) : '';

    let valid = this.validationThisForm();
    if (valid == 1) {
      let THIS = this;
      ladda.start();
      this.submitting = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiInc + 'create?token=' + THIS.currentUser.token,
        type: 'post',
        data: THIS.param,
        success: function (res) {
          ladda.stop();
          this.submitting = 0;
          if (res.status == 2000) {
            THIS.CustomHelper.woRouteDiscard = 0;
            let index = THIS.CustomHelper.ConfRoute.map(function (e) {
              return e.tab;
            }).indexOf(THIS.CustomHelper.ConfCurRoute);
            if (index > -1) {
              THIS.CustomHelper.ConfRoute.splice(index, 1);
            }
            if (newD == 'new') {
              THIS.addnewRe.emit(res.data);
            } else {
              THIS.preview.emit(res.data);
            }
            toastr.success('This new inspection has been successfully created', 'New Inspection', THIS.toastrOption);
            // Pusher emit
          }
        }
      });
    }


  }

  clearValidation(e) {
    let trigger = $(e.target);
    let target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  validForm = 0;
  submitting = 0;

  validationThisForm() {
    let valid = 1;
    let form = $('#workCreateForm');
    if (this.param.facility_id == '') {
      valid = 0;
      form.find('[data-el="facility"]').removeClass('has-error').addClass('has-error');
    } else {
      form.find('[data-el="facility"]').removeClass('has-error');
    }
    // if (this.param.prefix == '') {
    //     valid = 0;
    // }
    // if (this.param.work_order_id == '') {
    //     valid = 0;
    // }
    if (this.param.subject == '') {
      valid = 0;
      form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
    } else {
      form.find('[data-el="subject"]').removeClass('has-error');
    }
    this.validForm = valid;
    return valid;
  }

  inlinePrefixId = 0;

  updateChangesDiscard() {
    let THIS = this;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      this.CustomHelper.ConfRoute[index].change = 1;
    } else {
      this.CustomHelper.ConfRoute.push({tab: this.CustomHelper.ConfCurRoute, change: 1});
    }
  }


  taskType = 1; // 1.Tasks  2.Inspections
  taskList = [{title: '', person: '', personDetails: null}];

  taskCreateOnF() {
    this.taskList.push({title: '', person: '', personDetails: null});
    this.updateChangesDiscard();
  }

  removeTsk(index) {
    this.taskList.splice(index, 1);
  }

  selectTsk = null;
  selectTskIndex = -1;

  selectTskNow(index) {
    this.selectTskIndex = index;
    this.selectTsk = this.taskList[index];
  }

  assignTskHim(person) {
    let index = this.selectTskIndex;
    this.taskList[index].person = person.id;
    this.taskList[index].personDetails = person;
    this.selectTsk = this.taskList[index];

    if (this.param.personnel_id.indexOf(person.id) == -1) {
      this.param.personnel_id.push(person.id);
      (<any>$('.chosen-default-multiple-per')).val(this.param.personnel_id);
      (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
    }
  }

  changeTaskRating() {
    let THIS = this;
    this.param.overall_ratings == 1 ? this.param.overall_ratings = 0 : this.param.overall_ratings = 1;
  }


  dateCreated = this.CustomHelper.dateToTz(new Date());
  changingStart = 0;
  dateCreatedOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    disableMobile: true,
    defaultDate: new Date(),
    minDate: this.max_start_date,
    maxDate: this.max_due_date,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  onCreatedSelect() {
    let THIS = this;
    THIS.param.priority = '0';
    if (this.dateCreated != null) {
      this.param.created_at = this.CustomHelper.formatDateTimeToDB(this.dateCreated);
      this.param.due = '';
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
        THIS.dateDueOption.defaultDate = this.dateDue;
        THIS.changingStart = 0;
      }, 400);
      this.updateChangesDiscard();
    } else {
      this.param.created_at = '';
      this.param.due = '';
      this.add2Calendar = 0;
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date();
        THIS.dateDueOption.defaultDate = null;
        THIS.changingStart = 0;
      }, 400);
    }
  }

  dateDue = null;
  dateDueOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: null,
    minDate: this.max_start_date,
    maxDate: this.max_due_date,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  onDueSelect() {
    let THIS = this;
    this.param.due = this.CustomHelper.formatDateTimeToDB(this.dateDue);
    this.param.priority = '0';
    this.updateChangesDiscard();
  }

  add2Calendar = 0;
  calendarStatus = '_red';

  addToCalendar() {
    if (this.param.created_at != '' && this.param.created_at != null) {
      this.add2Calendar == 1 ? this.add2Calendar = 0 : this.add2Calendar = 1;
      this.updateChangesDiscard();
    } else {
      this.add2Calendar = 0;
      swal({
        title: 'Calendar Order',
        text: 'You must have to provide start Date to add this work order to Calendar',
        icon: 'warning',
        dangerMode: true,
        className: 'swal-btn-center'
      });
    }
  }


  addWorkAttach() {
    console.log(12);
    const acceptValue = 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf';
    const fileInput = document.getElementById('workAttach');
    if (fileInput) {
      fileInput.setAttribute('accept', acceptValue);
    }
   setTimeout(() => {
     $('#workAttach').click();
   }, 0);
  }

  removeThisAttach(index, id) {
    this.selectedWorkAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        let eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
                className: 'swal-btn-center'
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Please upload only valid document files',
                icon: 'error',
                dangerMode: true,
                className: 'swal-btn-center'
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
            console.log(THIS.selectedWorkAttach);
          }
        });
      }
    }
  }

  rightSecPreview = 0;

  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    $('.reRenderFacility').on('click', function () {
      THIS.getAllFacility();
    });
    $('.reRenderPerson').on('click', function () {
      THIS.personnel_get();
    });
    //===============
    // Pusher
    //===============
    Pusher.logToConsole = true;
    let pusher = new Pusher('501b3b237bd799758df0', {
      cluster: 'ap4'
    });
    let channel = pusher.subscribe('bm-work-orders');
    channel.bind('RefreshFacility', function (data) {
      if (Session.team === data.message.team) {
        THIS.getAllFacility();
      }
    });
    channel.bind('RefreshJobStatus', function (data) {
      if (Session.team === data.message.team) {
        THIS.getAllJobStatus(1);
      }
    });
    //===============
    // Pusher
    //===============


    $('.reRenderJobStatus').on('click', function () {
      THIS.getAllJobStatus(0);
    });
  }

  ngOnDestroy() {
  }


  UID_IMG = 0;
  // =============================
  // Photo Editor
  // =============================
  CANVAS = null;
  SHAPE = 'brush';
  IMAGE_PATH = '';
  CanvasTarget = 0;

  discardEditor() {
    let THIS = this;
    let modal = (<any>$('#photoEditorModal'));
    if (THIS.CANVAS != null) {
      THIS.CANVAS.dispose();
    }
    modal.modal('hide');

  }

  editImgCallUnique = 0;
  EditCurrentCanvas = null;
  CurrentEditPhoto = null;

  openPhotoEditor(preview) {
    let THIS = this;
    THIS.CurrentEditPhoto = preview;
    THIS.CanvasTarget = 0;
    let modal = (<any>$('#photoEditorModal'));
    let canvasW = window.innerWidth - 20;
    let canvasH = window.innerHeight - 130;
    $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
    if (THIS.CANVAS != null) {
      this.CANVAS.clear();
    }
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    let param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

    if (THIS.editImgCallUnique == 0) {
      THIS.editImgCallUnique = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          THIS.editImgCallUnique = 0;
          if (res.status === 2000) {
            THIS.EditCurrentCanvas = res.data;
            let canvasW = res.data.width;
            let canvasH = res.data.height;
            $('#canvas').attr('width', canvasW + 'px');
            $('#canvas').attr('height', canvasH + 'px');
            setTimeout(function () {
              // if(THIS.CANVAS != null){
              //     THIS.CANVAS.dispose();
              // }

              THIS.CANVAS = new fabric.Canvas('canvas');
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.on('object:selected', function (e) {
                THIS.CANVAS.isDrawingMode = false;
                THIS.SHAPE = '';
              });
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.isDrawingMode = true;
              THIS.CANVAS.freeDrawingBrush.width = 5;

              let startY = 0,
                startX = 0;
              THIS.CANVAS.on('mouse:down', function (option) {
                startY = option.e.offsetY;
                startX = option.e.offsetX;
              });
              THIS.CANVAS.on('mouse:up', function (option) {
                let selected = THIS.CANVAS.getActiveObjects();
                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                  if (THIS.SHAPE === 'circle') {
                    let e = option.e;
                    if (e.offsetX - startX > 5) {
                      let circle = new fabric.Circle({
                        top: startY,
                        left: startX,
                        radius: (e.offsetX - startX) / 2,
                        stroke: THIS.CANVAS.freeDrawingBrush.color,
                        strokeWidth: 5,
                        fill: ''
                      });
                      THIS.CANVAS.add(circle);
                    }
                  }
                }
              });
              THIS.CustomHelper.tooltipRender();

              THIS.IMAGE_PATH = preview.file_path;
              let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
              $('.canvas-container').css({
                backgroundImage: 'url(\'' + src + '\')'
              });
              THIS.CANVAS.renderAll();
              setTimeout(function () {
                THIS.CanvasTarget = 1;
              }, 1000);
            }, 1000);
          }
        }
      });


      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        modal.modal('hide');
        history.go(1);
      };

      if ($('.color-picker-editor').length > 0) {
        Pickr.create({
          el: '.color-picker-editor',
          theme: 'nano', // or 'monolith', or 'nano'
          defaultRepresentation: 'HEX',
          useAsButton: true,
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: true,
              input: true,
              save: true
            }
          }
        }).on('change', (color, instance) => {
          $('.color-picker-editor').css('color', color.toHEXA().toString());
          THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
          THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
          });
        });
      }
      $('.color-picker-editor').css('color', '#ff3773');
    }
  }

  // =============================
  // Photo Editor Actions
  // =============================
  SketchEnable() {
    this.CANVAS.isDrawingMode = !this.CANVAS.isDrawingMode;
    if (this.CANVAS.isDrawingMode === true) {
      this.SHAPE = 'brush';
    }
  }

  eraser() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    let obj = THIS.CANVAS.getObjects();
    for (let i = 0; i < obj.length; i++) {
      $.each(obj, function (j, v) {
        if (parseInt(j) > 0) {
          THIS.CANVAS.remove(v);
        }
      });
    }
    THIS.UID_IMG = Date.now();
    let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
    $('.canvas-container').css({
      backgroundImage: 'url(\'' + src + '\')'
    });
  }

  eraserSelected() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
      THIS.CANVAS.remove(obj);
    });
  }

  drawCircle() {
    let THIS = this;
    this.CANVAS.isDrawingMode = false;
    this.SHAPE = 'circle';
    let circle = new fabric.Circle({
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      radius: 50,
      stroke: THIS.CANVAS.freeDrawingBrush.color,
      strokeWidth: 5,
      fill: ''
    });
    THIS.CANVAS.add(circle);
  }

  UpdatePhoto(iid) {
    let THIS = this;
    let ldd = document.getElementById(iid);
    let ladda = Ladda.create(ldd);
    ladda.start();
    THIS.CANVAS.discardActiveObject();
    THIS.CANVAS.requestRenderAll();
    setTimeout(function () {
      let modal = (<any>$('#photoEditorModal'));
      let canvas = (<any>document.getElementById('canvas'));
      let imgData = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      imgData = imgData.replace('data:image/png;base64,', '');
      let param = {
        imgData: imgData,
        media_type: 1,
        file_path: THIS.IMAGE_PATH
      };
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          ladda.stop();
          modal.modal('hide');
          THIS.UID_IMG = Date.now();
        }
      });
    }, 1000);
  }

}
