import { Component, Input, Output, EventEmitter, SimpleChanges, Inject} from '@angular/core';
import { FacilityService } from '../_services/facility.service';
import { InventoriesService } from '../_services/inventories.service';
import { Job_typesService } from '../_services/job_types.service';
import { AssetService } from '../_services/asset.service';
import { UserService } from '../_services/user.service';
import { MediaService } from '../_services/media.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {CustomHelper} from '../customhelper';
import {APPCONFIG} from '../config';
declare let swal: any;
declare let toastr: any



@Component({
    selector: 'search-panel',
    templateUrl: './search_panel.component.html',
})
export class Search_panelComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    @Input() value: any;
    @Input() option: any;
    @Output() activeData = new EventEmitter<any>();
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};

    settings: any;

    _open = false;

    constructor(private facilityService:FacilityService,
                private inventoriesService:InventoriesService,
                private job_typesService:Job_typesService,
                private assetService:AssetService,
                private mediaService:MediaService,
                private userService:UserService,
                public dialog: MatDialog) {
        this.value = null;
        this.option = null;
        let THIS = this;
        setTimeout(function () {
            THIS.Fac_refresh_name_value();
        }, 500);

        (<any>$('#personnelCreateModal')).on('hidden.bs.modal', function () {
            THIS.personnelOff = 0;
            console.log(THIS.personnelOff);
        });
    }
    onOffScreen(){
        this._open = false;
        this.Fac_search_keyword = '';
        this.filter_show = false;
    }


    // =====================================
    // FacilitySection
    // =====================================
    Facility = [];
    facility_id = '';
    facility_name = '';
    filter_show = false;
    Fac_alp = 'asc';
    Fac_search_keyword = '';
    Fac_SortALP(){
        if(this.Fac_alp == 'asc'){
            if(this.Facility.length > 0){
                this.Facility.sort(function(a, b){
                    if(a.name < b.name) return -1;
                    if(a.name > b.name) return 1;
                    return 0;
                });
            }
        } else {
            if(this.Facility.length > 0){
                this.Facility.sort(function(a, b){
                    if(a.name < b.name) return 1;
                    if(a.name > b.name) return -1;
                    return 0;
                });
            }
        }
    }
    Fac_filter = [];
    Fac_filter_removable = true;
    Fac_filter_remove(filter){
        var index = this.Fac_filter.indexOf(filter);
        this.Fac_filter.splice(index, 1);
    }
    Fac_activityFilter(filter){
        let index = this.Fac_filter.indexOf(filter);
        if(index > -1){
            this.Fac_filter.splice(index, 1);
        } else {
            this.Fac_filter.push(filter);
        }
    }
    Fac_selectData(facility){
        this.facility_id = facility.id;
        this.facility_name = facility.name;
        this.activeData.emit(facility.id);
        this.filter_show = false;
        this.onOffScreen();
    }
    Fac_match_search(name){
        if(this.Fac_search_keyword == ''){
            return 1;
        }
        if (name.match(new RegExp(this.Fac_search_keyword, 'gi'))) {
            return 1;
        } else {
            return 0;
        }
    }
    Fac_refresh_name_value(){
        if(this.facility_id != ''){
            let THIS = this;
            $.each(THIS.Facility, function(i, v) {
                if(v.id == THIS.facility_id){
                    THIS.facility_name = v.name;
                }
                $.each(v.child, function(j, vC) {
                    if(vC.id == THIS.facility_id){
                        THIS.facility_name = vC.name;
                    }
                    $.each(vC.child, function(k, vCC) {
                        if(vCC.id == THIS.facility_id){
                            THIS.facility_name = vCC.name;
                        }
                    });
                });
            });
        }
    }
    Fac_getAllFacility() {
        let THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            this.Facility = res.facility;
            this.Fac_SortALP();
            this.Fac_refresh_name_value();
        });
    }


    // =====================================
    // Store Room
    // =====================================
    StoreRooms = [];
    room_id = 0;
    room_name = '';
    SR_search_keyword = '';
    SR_alp = 'asc';
    SR_SortALP(){
        if(this.SR_alp == 'asc'){
            if(this.StoreRooms.length > 0){
                this.StoreRooms.sort(function(a, b){
                    if(a.title < b.title) return -1;
                    if(a.title > b.title) return 1;
                    return 0;
                });
            }
        } else {
            if(this.StoreRooms.length > 0){
                this.StoreRooms.sort(function(a, b){
                    if(a.title < b.title) return 1;
                    if(a.title > b.title) return -1;
                    return 0;
                });
            }
        }
    }
    SR_getStoreRoom(): void {
        this.inventoriesService.getStoreList().subscribe(res => {
            if(res.status == 2000){
                this.StoreRooms = res.data;
                this.SR_SortALP();
            }
        });
    }
    SR_createStoreRoom(): void {
        const dialogRef = this.dialog.open(SearchPanelStoreRoomDialog, {
            width: '400px',
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'new'){
                this.SR_getStoreRoom();
            }
        });
    }
    SR_selectData(room){
        this.room_id = room.id;
        this.room_name = room.title;
        this.activeData.emit(room.id);
        this.filter_show = false;
        this.onOffScreen();
    }
    SR_match_search(name){
        if(this.SR_search_keyword == ''){
            return 1;
        }
        if (name.match(new RegExp(this.SR_search_keyword, 'gi'))) {
            return 1;
        } else {
            return 0;
        }
    }


    // =====================================
    // Assets
    // =====================================
    asset_pp = 0;
    Assets = [];
    asset_id = [];
    asset_name = [];
    asset_search_keyword = '';
    asset_alp = 'asc';
    asset_SortALP(){
        if(this.asset_alp == 'asc'){
            if(this.Assets.length > 0){
                this.Assets.sort(function(a, b){
                    if(a.name < b.name) return -1;
                    if(a.name > b.name) return 1;
                    return 0;
                });
            }
        } else {
            if(this.Assets.length > 0){
                this.Assets.sort(function(a, b){
                    if(a.name < b.name) return 1;
                    if(a.name > b.name) return -1;
                    return 0;
                });
            }
        }
    }
    asset_getAsset(): void {
        this.asset_pp = 0;
        this.assetService.getAssetList().subscribe(res => {
            this.asset_pp = 1;
            let THIS = this;
            this.Assets = res;
            this.asset_SortALP();

            //personnel_ids
            THIS.asset_name.length = 0;
            $.each(THIS.Assets, function (i, v) {
                if(THIS.asset_id.indexOf(v.id.toString()) > -1){
                    THIS.asset_name.push(v.name);
                }
            });
            setTimeout(function(){
                THIS.CustomHelper.tooltipRender();
            }, 100);
        });
    }
    asset_createAsset(): void {
        const dialogRef = this.dialog.open(SearchPanelAssetDialog, {
            width: '500px',
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result > 0){
                this.asset_id = result;
                this.asset_getAsset();
            }
        });
    }
    asset_selectData(asset){
        let index = this.asset_id.indexOf(asset.id.toString());
        if(index > -1){
            this.asset_id.splice(index,1);
        } else {
            this.asset_id.push(asset.id);
        }
        if(index > -1){
            this.asset_name.splice(index,1);
        } else {
            this.asset_name.push(asset.name);
        }

        this.activeData.emit(this.asset_id.join());
        this.filter_show = false;

        let THIS = this;
        setTimeout(function(){
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }
    asset_selectData_index(index){
        let asset = this.Assets[index];
        if(index > -1){
            this.asset_id.splice(index,1);
        } else {
            this.asset_id.push(asset.id);
        }
        if(index > -1){
            this.asset_name.splice(index,1);
        } else {
            this.asset_name.push(asset.name);
        }

        this.activeData.emit(this.asset_id.join());
        this.filter_show = false;
    }
    asset_match_search(name){
        if(this.asset_search_keyword == ''){
            return 1;
        }
        if (name.match(new RegExp(this.asset_search_keyword, 'gi'))) {
            return 1;
        } else {
            return 0;
        }
    }


    // =====================================
    // Assets
    // =====================================
    personnel_type = 4;
    personnel_changeType(){
        this.personnel_ids.length = 0;
        this.personnel_name.length = 0;
        let res = {
            personnel : this.personnel_type,
            personnelIds : this.personnel_ids,
        };
        this.activeData.emit(res);
        this.personnel_get();
    }
    Personnel = [];
    per_pp = 0;
    personnel_ids = [];
    personnel_name = [];
    personnel_search_keyword = '';
    personnel_alp = 'asc';
    personnel_SortALP(){
        if(this.personnel_alp == 'asc'){
            if(this.Personnel.length > 0){
                this.Personnel.sort(function(a, b){
                    if(a.name < b.name) return -1;
                    if(a.name > b.name) return 1;
                    return 0;
                });
            }
        } else {
            if(this.Personnel.length > 0){
                this.Personnel.sort(function(a, b){
                    if(a.name < b.name) return 1;
                    if(a.name > b.name) return -1;
                    return 0;
                });
            }
        }
    }
    personnel_Total = 0;
    personnel_pageNo = 1;
    personnel_perPage = 25;
    personnel_pageEvent;
    personnel_sort_by = 'created_at';
    personnel_order_by = 'desc';
    personnel_paginateList(){
        if(this.personnel_pageEvent.pageSize == this.personnel_perPage){
            this.personnel_perPage = this.personnel_pageEvent.pageSize;
            this.personnel_pageNo = this.personnel_pageEvent.pageIndex+1;
            this.personnel_get();
        } else {
            this.personnel_perPage = this.personnel_pageEvent.pageSize;
            this.personnel_pageNo = 1;
            this.personnel_get();
        }
        let THIS = this;
    }
    personnel_get(): void {
        this.per_pp = 0;
        this.newPersonnelFetch = 0;
        this.Personnel.length = 0;
        let param = {
            keyword : '',
            pageNo : this.personnel_pageNo,
            perPage : this.personnel_perPage,
            sort_by : this.personnel_sort_by,
            order_by : this.personnel_order_by
        };
        this.userService.getAllPersonnel(param).subscribe(res => {
            this.per_pp = 1;
            if(res.status == 2000){
                this.Personnel = res.data;
                this.personnel_Total = res.total;
                this.personnel_SortALP();
                //personnel_ids
                let THIS = this;
                if(THIS.personnel_name.length == 0){
                    $.each(THIS.Personnel, function (i, v) {
                        if(THIS.personnel_ids.indexOf(v.id) > -1){
                            THIS.personnel_name.push(v);
                        }
                    });
                }
            }
        });
    }
    newPersonnelFetch = 0;
    personnelOff = 0;
    personnel_create(): void {
        this.personnelOff = 1;

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
        let child = ___selectedTab+1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
        (<any>$('#personnelCreateModal')).modal('show');
    }
    personnel_param = {
        avatar : '',
        first_name : '',
        last_name : '',
        email : '',
        phone : '',
        address : '',
        hourly_rate : '',
        group_id : '',
        licenced : 0
    };
    personnel_validForm = 0;
    personnel_saveSubmit(){
        this.personnel_param.avatar = this.personnel_selectedFilesPreview;
        let valid = this.personnel_validationThisForm();
        if (valid == 1) {
            this.userService.createPersonnel(this.personnel_param).subscribe(res => {
                if(res.status == 2000){
                    this.personnelOff = 0;
                    let THIS = this;
                    this.newPersonnelFetch = 1;
                    this.personnel_ids.push(res.data.id);
                    this.personnel_name.push(res.data.name);
                    setTimeout(function () {
                        THIS.personnel_get();
                    }, 500);
                    (<any>$('#personnelCreateModal')).modal('hide');
                    toastr.success('New personnel has been successfully created', 'New personnel', THIS.toastrOption);
                } else {
                    swal(res.error);
                }
            });
        }
    }
    personnel_validationThisForm() {
        let valid = 1;
        if (this.personnel_param.first_name == '') {
            valid = 0;
            swal('First name is required');
            return valid;
        }
        if (this.personnel_param.last_name == '') {
            valid = 0;
            swal('Last name is required');
            return valid;
        }
        if (this.personnel_param.email == '') {
            valid = 0;
            swal('Last name is required');
            return valid;
        }

        if(this.personnel_param.email == ''){
            valid = 0;
            swal('Email is required');
            return valid;
        }
        return valid;
    }
    personnel_checkThisForm() {
        this.personnel_param.first_name = $.trim(this.personnel_param.first_name);
        this.personnel_param.last_name = $.trim(this.personnel_param.last_name);
        let valid = 1;
        if (this.personnel_param.first_name == '') {
            valid = 0;
        }
        if (this.personnel_param.last_name == '') {
            valid = 0;
        }
        if (this.personnel_param.email == '') {
            valid = 0;
        }
        return valid;
    }
    personnel_avatarLoading = 0;
    personnel_selectedFilesPreview = '';
    uploadThisMedia(file, media_type, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        this.mediaService.create(formData).subscribe(res => {
            callback(res);
        });
    }
    personnel_selectTheseFiles(e) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            let eachFile = accepted[i];
            if(eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg'){
                THIS.personnel_avatarLoading = 1;
                this.uploadThisMedia(eachFile, '1', function (uploaded) {
                    THIS.personnel_avatarLoading = 0;
                    THIS.personnel_selectedFilesPreview = uploaded.file_path;
                });
            } else {
                swal('Only jpg/jpeg and png files are allowed');
            }
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }
    personnel_changeLicenced(){
        this.personnel_param.licenced = (this.personnel_param.licenced == 0 ? 1 : 0);
        let THIS = this;

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
        let child = ___selectedTab+1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
        (<any>$('#licencedModal')).modal('show');
        // swal('No Licenced yet');
        setTimeout(function () {
            THIS.personnel_param.licenced = 0;
        }, 100);
    }
    personnel_selectData(personnel){
        let index = this.personnel_ids.indexOf(personnel.id);
        if(index > -1){
            this.personnel_ids.splice(index,1);
            this.personnel_name.splice(index,1);
        } else {
            this.personnel_ids.push(personnel.id);
            this.personnel_name.push(personnel);
        }
        let res = {
            personnel : this.personnel_type,
            personnelIds : this.personnel_ids,
        };
        console.log(res);
        this.activeData.emit(res);
        this.filter_show = false;
        // this.onOffScreen();
    }
    personnel_match_search(name){
        if(this.personnel_search_keyword == ''){
            return 1;
        }
        if (name.match(new RegExp(this.personnel_search_keyword, 'gi'))) {
            return 1;
        } else {
            return 0;
        }
    }


    // =====================================
    // Job Type
    // =====================================
    JobType = [];
    jt_id = 0;
    jt_name = '';
    jt_search_keyword = '';
    jt_alp = 'asc';
    jt_SortALP(){
        if(this.jt_alp == 'asc'){
            if(this.JobType.length > 0){
                this.JobType.sort(function(a, b){
                    if(a.name < b.name) return -1;
                    if(a.name > b.name) return 1;
                    return 0;
                });
            }
        }
        else {
            if(this.JobType.length > 0){
                this.JobType.sort(function(a, b){
                    if(a.name < b.name) return 1;
                    if(a.name > b.name) return -1;
                    return 0;
                });
            }
        }
    }
    jt_get(): void {
        this.JobType.length = 0;
        this.job_typesService.getJobTypeList().subscribe(res => {
            let THIS = this;
            this.JobType = res;
            this.jt_SortALP();
            let index = this.JobType.map((el) => el.id).indexOf(THIS.jt_id);
            if(index>-1){
                this.jt_selectData(this.JobType[index]);
            }
        });
    }
    /*personnel_create(): void {
        const dialogRef = this.dialog.open(SearchPanelPersonnelDialog, {
            width: '500px',
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result != undefined){
                this.personnel_selectData(result)
                this.personnel_get();
            }
        });
    }*/
    jt_selectData(jt){
        this.jt_id = jt.id;
        this.jt_name = jt.name;
        this.activeData.emit(this.jt_id);
        this.filter_show = false;
        this.onOffScreen();
    }
    jt_match_search(name){
        if(this.jt_search_keyword == ''){
            return 1;
        }
        if (name.match(new RegExp(this.jt_search_keyword, 'gi'))) {
            return 1;
        } else {
            return 0;
        }
    }


    // =====================================
    // Job Type
    // =====================================
    Priority = [];
    pty_id = 0;
    pty_name = '';
    pty_search_keyword = '';
    pty_alp = 'asc';
    pty_SortALP(){
        if(this.pty_alp == 'asc'){
            if(this.Priority.length > 0){
                this.Priority.sort(function(a, b){
                    if(a.name < b.name) return -1;
                    if(a.name > b.name) return 1;
                    return 0;
                });
            }
        }
        else {
            if(this.Priority.length > 0){
                this.Priority.sort(function(a, b){
                    if(a.name < b.name) return 1;
                    if(a.name > b.name) return -1;
                    return 0;
                });
            }
        }
    }
    pty_get(): void {
        this.Priority.length = 0;
        this.job_typesService.getPrioritiesList().subscribe(res => {
            let THIS = this;
            this.Priority = res;
            this.pty_SortALP();
            let index = this.Priority.map((el) => el.id).indexOf(THIS.pty_id);
            if(index > -1){
                this.pty_selectData(this.Priority[index]);
            } else {
                this.pty_selectData(null);
            }
        });
    }
    /*personnel_create(): void {
        const dialogRef = this.dialog.open(SearchPanelPersonnelDialog, {
            width: '500px',
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result != undefined){
                this.personnel_selectData(result)
                this.personnel_get();
            }
        });
    }*/
    pty_selectData(pty){
        if(pty != null){
            this.pty_id = pty.id;
            this.pty_name = pty.name+' < '+pty.amount+' '+CustomHelper.priorityDuration(pty.duration);
            this.activeData.emit(pty);
            this.filter_show = false;
            this.onOffScreen();
        } else {
            this.pty_id = 0;
            this.pty_name = 'No priority';
            this.activeData.emit(null);
            this.filter_show = false;
            this.onOffScreen();
        }
    }
    pty_match_search(name){
        if(this.pty_search_keyword == ''){
            return 1;
        }
        if (name.match(new RegExp(this.pty_search_keyword, 'gi'))) {
            return 1;
        } else {
            return 0;
        }
    }















    ngOnChanges(changes: SimpleChanges): void {
        if(changes.option != undefined){
            this.settings = changes.option.currentValue;
            if(this.settings.module == 1){
                this.Fac_getAllFacility();
            }
            if(this.settings.module == 2){
                this.SR_getStoreRoom();
            }
            if(this.settings.module == 3){
                this.asset_getAsset();
            }
            if(this.settings.module == 4){
                this.personnel_get();
            }
            if(this.settings.module == 5){
                this.jt_get();
            }
            if(this.settings.module == 6){
                this.pty_get();
            }
        }
        if(changes.value != undefined){
            if(this.settings.module == 1){
                this.facility_id = changes.value.currentValue;
                this.Fac_refresh_name_value();
            }
            if(this.settings.module == 2){
                this.room_id = changes.value.currentValue;
            }
            if(this.settings.module == 3){
                this.asset_id = this.CustomHelper.makeStr(changes.value.currentValue).split(',');
                this.asset_getAsset();
            }
            if(this.settings.module == 4){
                let p = changes.value.currentValue;
                this.personnel_type = p.personnel;
                this.personnel_ids = p.personnel_ids;
                this.personnel_get();
            }
            if(this.settings.module == 5){
                let jt_id = this.CustomHelper.makeInt(changes.value.currentValue);
                this.jt_id = jt_id;
                this.jt_get();
            }
            if(this.settings.module == 6){
                let pty_id = this.CustomHelper.makeInt(changes.value.currentValue);
                this.pty_id = pty_id;
                this.pty_get();
            }
        }
    }
}



// ============================
// Store Room Create
// ============================
export interface StoreDialogData {}
@Component({
    selector: 'search-panel-storeroom-dialog',
    templateUrl: './searchPanelStoreRoomDialog.component.html'
})
export class SearchPanelStoreRoomDialog {

    constructor(public dialogRef: MatDialogRef<SearchPanelStoreRoomDialog>,
        private inventoriesService:InventoriesService,
        @Inject(MAT_DIALOG_DATA) public data: StoreDialogData) {

    }

    processing = 0;
    errMSg = '';
    form = {title:''};




    create() {
        this.processing = 1;
        this.inventoriesService.createStore(this.form).subscribe(res => {
            this.processing = 0;
            if(res.status == 2000){
                this.dialogRef.close('new');
            }
            if(res.status == 1000){
                this.errMSg = res.msg;
            }
        });
    }

}
// ============================
// Asset Create
// ============================
export interface AssetDialogData {}
@Component({
    selector: 'search-panel-asset-dialog',
    templateUrl: './searchPanelAssetDialog.component.html'
})
export class SearchPanelAssetDialog {

    constructor(public dialogRef: MatDialogRef<SearchPanelAssetDialog>,
        private assetService:AssetService,
        private facilityService:FacilityService,
        @Inject(MAT_DIALOG_DATA) public data: AssetDialogData) {
        this.getAllFacility();
        this.getLastPreId();
    }

    processing = 0;
    errMSg = '';
    form = {
        asset_id: '',
        prefix: '',
        facility:'',
        name:'',
    };


    Facility = [];
    getAllFacility() {
        let THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            $.each(res.facility, function(i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                $.each(v.child, function(j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                    $.each(vC.child, function(k, vCC) {
                        vCC['pp'] = 2;
                        THIS.Facility.push(vCC);
                        $.each(vCC.child, function(l, vCCC) {
                            vCCC['pp'] = 3;
                            THIS.Facility.push(vCCC);
                        });
                    });
                });
            });
        });
    }
    getLastPreId() {
        let THIS = this;
        this.assetService.getLastPreId().subscribe(res => {
            if(res.status == 2000){
                this.form.prefix = res.data.prefix;
                this.form.asset_id = res.data.asset_id;
            }
        });
    }





    create() {
        this.processing = 1;
        this.assetService.createAsset(this.form).subscribe(res => {
            this.processing = 0;
            if(res.status != undefined){
                if(res.status == 7000){
                    this.errMSg = res.msg;
                    this.getLastPreId();
                }
            } else {
                this.dialogRef.close(res.id);
            }
        });
    }

}
// ============================
// Asset Create
// ============================
export interface PersonnelDialogData {}
@Component({
    selector: 'search-panel-personnel-dialog',
    templateUrl: './searchPanelPersonnelDialog.component.html'
})
export class SearchPanelPersonnelDialog {

    constructor(public dialogRef: MatDialogRef<SearchPanelPersonnelDialog>,
        private userService:UserService,
        @Inject(MAT_DIALOG_DATA) public data: PersonnelDialogData) {
    }

    processing = 0;
    errMSg = '';
    duplicatePersonnel = 0;
    paramPersonnel = {
        user_type: '4',
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        mobile: '',
        rate: '',
        avatar: ''
    };

    validationPersonnel() {
        if (this.paramPersonnel.first_name == '') {
            return false;
        }
        if (this.paramPersonnel.last_name == '') {
            return false;
        }
        if (this.paramPersonnel.email == '') {
            return false;
        }
        if (this.paramPersonnel.phone == '') {
            return false;
        }
        if (this.paramPersonnel.mobile == '') {
            return false;
        }
        if (this.paramPersonnel.rate == '') {
            return false;
        }
        return true;
    }
    create() {
        if(this.validationPersonnel()){
            this.duplicatePersonnel = 0;
            this.errMSg = '';
            this.processing = 1;
            this.userService.createPersonnel(this.paramPersonnel).subscribe(res => {
                this.processing = 0;
                if (res.status === 3000) {
                    this.duplicatePersonnel = 1;
                }
                if (res.status === 5000) {
                    this.errMSg = 'Invalid Request';
                }
                if (res.status === 2000) {
                    this.dialogRef.close(res.data);
                }
            });
        } else {
            alert('Please fill up the form correctly');
        }
    }

}

