import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {APPCONFIG} from '../../config';

@Component({
  selector: 'app-categories-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss']
})

export class CategorySelectComponent {
  isActive = false;
  @Input() maxWidth = 350;
  @Input() categories = [
    {name: 'Plumbing'},
    {name: 'HVAC'},
    {name: 'Electrical'},
    {name: 'Cleaning'},
    {name: 'Fire Services'},
    {name: 'Pest Control'},
    {name: 'Handyman'},

  ];
  @Input() selectedCategories = [];
  @Output() categoriesChange: any = new EventEmitter<number>();


  constructor(private router: Router) {}

  openDropdown() {
    this.isActive = !this.isActive;
  }
  selectCategory(each: any) {
    if (!this.selectedCategories.includes(each)) {
      this.selectedCategories.push(each);
      this.categoriesChange.emit(this.selectedCategories);
    }
    this.isActive = false;
  }
  checkIfSelected(each: any) {
    const isSelected = this.selectedCategories.some(
      (category: any) => category.name === each.name
    );
    return isSelected;
  }
  removeCategory(category: any, index: any) {
    this.selectedCategories.splice(index, 1);
    this.categoriesChange.emit(this.selectedCategories);
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const clickedInside = targetElement.closest('.positive-relative');

    if (!clickedInside) {
      this.isActive = false;
    }
  }
}
