import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {FacilityService} from "../../_services/facility.service";

declare let swal: any;

@Component({
    selector: 'page-work-report',
    templateUrl: './work-order-report.component.html',
    styles: []
})
export class WorkOrderReportComponent {
    // =======================
    // General Variables
    // =======================
    Permission = -1;
    currentUser;
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;


    // =======================
    // Routes & Preview
    // =======================
    ROUTES = '/work-order-report';

    previewList = 1;
    pageLoading: any = false;

    constructor(protected facilityService: FacilityService) {
        let THIS = this;
        THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        THIS.routeMake();
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        THIS.Permission = Session.user_type > 2 ? Session.modules.work_orders : 1;
        setTimeout(function () {
            THIS.getAllFacility();
            THIS.getAllJobStatus();
            THIS.jt_get();
            setTimeout(function () {
                THIS.CustomHelper.tooltipRender();
            }, 100);
        }, 400);


        let Today = new Date();
        let FirstDay = new Date(Today.getFullYear(), Today.getMonth(), 1);
        THIS.start_from = THIS.CustomHelper.formatDateToDB(FirstDay);
        THIS.start_to = THIS.CustomHelper.formatDateToDB(Today);

        this.dateCreatedOption.defaultDate = [FirstDay, Today];
        this.dateCreatedOption.onChange = function (selectedDates, dateStr, instance) {
            if (selectedDates.length > 1) {
                THIS.start_from = THIS.CustomHelper.formatDateToDB(new Date(selectedDates[0]));
                THIS.start_to = THIS.CustomHelper.formatDateToDB(new Date(selectedDates[1]));
            } else {
                THIS.start_from = '';
                THIS.start_to = '';
            }
            THIS.goGetWorkOders(0);
        };
        THIS.facilityService.currentSelectedFac.subscribe(value => THIS.getWorkOrders(0));

    }

    routeMake() {
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + this.ROUTES;
        window.history.pushState("", "", url);
    }

    dateCreated = this.CustomHelper.dateToTz(new Date());
    dateCreatedOption = {
        disableMobile: false,
        defaultDate: null,
        mode: "range",
        timePicker: false,
        enableTime: false,
        altInput: true,
        altFormat: this.CustomHelper.dateFormatFlat(),
        onChange: function (selectedDates, dateStr, instance) {
        },
    }


    Facility = [];

    getAllFacility() {
        let THIS = this;
        THIS.Facility.length = 0;
        $.ajax({
            url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
            data: {},
            type: 'get',
            success: function (res) {
                $.each(res.facility, function (i, v) {
                    v['pp'] = 0;
                    THIS.Facility.push(v);
                    $.each(v.child, function (j, vC) {
                        vC['pp'] = 1;
                        THIS.Facility.push(vC);
                        $.each(vC.child, function (k, vCC) {
                            vCC['pp'] = 2;
                            THIS.Facility.push(vCC);
                            $.each(vCC.child, function (l, vCCC) {
                                vCCC['pp'] = 3;
                                THIS.Facility.push(vCCC);
                            });
                        });
                    });
                });
                setTimeout(function () {
                    (<any>$('.chosen-facility')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_single: 'Select Facility'
                    }).on('change', function (e, p) {
                        THIS.facility_id = e.target.value;
                        THIS.goGetWorkOders(0);
                    });
                }, 100);
            }
        });
    }

    jobStatusList = [];
    jobStatusDefault = [];
    jobStatusCustom = [];

    getAllJobStatus() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'job-status?token=' + THIS.currentUser.token,
            data: {},
            type: 'get',
            success: function (res) {
                if (res.status == 2000) {
                    THIS.jobStatusDefault.length = 0;
                    THIS.jobStatusCustom.length = 0;
                    THIS.jobStatusList = res.data;
                    $.each(res.data, function (i, v) {
                        if (v.type == 0) {
                            THIS.jobStatusDefault.push(v);
                        } else {
                            THIS.jobStatusCustom.push(v);
                        }
                    });
                }
                setTimeout(function () {
                    (<any>$('.chosen-default-single-status')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_single: 'Select job status'
                    }).on('change', function (e, p) {
                        THIS.status_code = e.target.value;
                        THIS.goGetWorkOders(0);
                    });
                }, 100);
            }
        });
    }

    JobType = [];

    jt_get() {
        let THIS = this;
        this.JobType.length = 0;
        $.ajax({
            url: THIS.APPCONFIG.api + 'job-types?token=' + THIS.currentUser.token,
            data: {},
            type: 'get',
            success: function (res) {
                THIS.JobType = res.data;
                setTimeout(function () {
                    (<any>$('.chosen-default-single-jti')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_single: 'Select Job Type'
                    }).on('change', function (e, p) {
                        THIS.job_types_id = e.target.value;
                        THIS.goGetWorkOders(0);
                    });
                }, 100);
            }
        });
    }


    pdfURL = '';
    QRenabled = 0;

    checkEnability() {
        if (this.QRenabled == 0) {
            this.QRenabled = 1;
        } else {
            this.QRenabled = 0;
        }
    }

    pdfGenration = 0;
    excludeThem = [];

    excludeIt(work) {
        let i = this.excludeThem.indexOf(work.id);
        if (i == -1) {
            this.excludeThem.push(work.id);
        } else {
            this.excludeThem.splice(i, 1);
        }
    }

    Workders = [];
    pageNo = 1;
    perPage = 25;
    TotalWorks = 0;
    TotalWorkIds = [];
    totalPage = 0;
    facility_id = '';
    status_code = '';
    job_types_id = '';
    start_from = '';
    start_to = '';

    goGetWorkOders(type) {
        this.pageNo = 1;
        this.getWorkOrders(type);
    }

    pageEvent;

    paginateList() {
        if (this.pageEvent.pageSize == this.perPage) {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex + 1;
            this.getWorkOrders(0);
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
            this.getWorkOrders(0);
        }
    }

    order_by = 'desc';
    sort_by = 'work_orders.uid';

    getWorkOrders(type) {
        let THIS = this;
        THIS.pageLoading = true;
        THIS.LargeRequest = 0;
        $.ajax({
            url: THIS.APPCONFIG.api + 'get/work-order/report?token=' + THIS.currentUser.token,
            type: 'POST',
            data: {
                pageNo: THIS.pageNo,
                perPage: THIS.perPage,
                facility_id: THIS.facility_id,
                status_code: THIS.status_code,
                job_types_id: THIS.job_types_id,
                start_from: THIS.start_from,
                order_by: THIS.order_by,
                sort_by: THIS.sort_by,
                start_to: THIS.start_to
            },
            success: function (res) {
              THIS.pageLoading = false;
                if (res.status === 2000) {
                    THIS.Workders = [];
                    $.each(res.data, function (i, v) {
                        v.creation_date = v.creation_date != null ? THIS.CustomHelper.DateSelectedFormat(v.creation_date, '') : null;
                        v.completed = v.completed != null ? THIS.CustomHelper.DateSelectedFormat(v.completed, '') : null;
                        THIS.Workders.push(v);
                    });
                    THIS.TotalWorks = res.total;
                    THIS.totalPage = Math.ceil(THIS.TotalWorks / THIS.perPage);
                    THIS.TotalWorkIds = res.totalDataIds;
                    THIS.pdfURL = THIS.APPCONFIG.apiAuth + 'preview/upload/pdf/wo/report';
                    if (type === 1) {
                        THIS.generatePDF();
                    } else {
                        if (res.largeReq != null) {
                            THIS.LargeRequest = 1;
                            THIS.LargeRequestMsg = '<strong>Report Requested: ' + THIS.CustomHelper.formatDateTime(res.largeReq.created_at);
                            if (res.largeReq.status === 0) {
                                THIS.LargeRequestMsg += ' (Pending) </strong>'
                            }
                            if (res.largeReq.status === 1) {
                                THIS.LargeRequestMsg += ' (<a href="' + APPCONFIG.uploadPath + '/reports/pdf/WorkOrders.' + res.largeReq.uid + '.zip">Download</a>) </strong>'
                            }
                        }
                    }
                }
            }
        });
    }

    generatePDFlarge() {
        let THIS = this;
        THIS.LargeRequest = 0;
        THIS.pdfGenration = 1;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'preview/upload/pdf/wo/report/large?token=' + THIS.currentUser.token,
            type: 'POST',
            data: {
                facility_id: THIS.facility_id,
                status_code: THIS.status_code,
                job_types_id: THIS.job_types_id,
                start_from: THIS.start_from,
                start_to: THIS.start_to
            },
            success: function (res) {
                THIS.pdfGenration = 0;
                if (res.status === 2000) {
                    THIS.pdfURL = '';
                    THIS.LargeRequest = 1;
                    THIS.LargeRequestMsg = res.msg;
                }
            }
        });
    }

    LargeRequest = 0;
    LargeRequestMsg = '';

    generatePDF() {
        let THIS = this;
        this.LargeRequest = 0;

        let idList = THIS.TotalWorkIds;
        let arr2 = THIS.excludeThem;
        let ids: any = idList.filter(x => !arr2.includes(x));
        if (ids.length > 150) {
            // this.generatePDFlarge();
            swal({
                text: 'The report you are generating is quite large and will be placed in a queue. You will receive an email once the report is ready for download. <br>' +
                    'Note: This can take up to 24hrs depending on the amount of data to be generated, but is usually completed in under an hour. <br> ' +
                    '<strong>You can only generate one large report at a time. Continue?</strong>',
                buttons: ['Cancel', 'Continue'], //
                dangerMode: false,
            }).then(res => {
                if (res) {
                    THIS.generatePDFlarge();
                }
            });
            $('.swal-text').css({textAlign: 'center'}).html('The report you are generating is quite large and will be placed in a queue. You will receive an email once the report is ready for download. <br><br>' +
                'Note: This can take up to 24hrs depending on the amount of data to be generated, but is usually completed in under an hour. <br><br>' +
                '<strong>You can only generate one large report at a time. Continue?</strong>');
        } else {
            ids = btoa(ids.join());
            if (ids.length == 0) {
                this.pdfURL = '';
                this.LargeRequest = 1;
                this.LargeRequestMsg = 'No work order selected. Please select work orders to make your report.';
                return false;
            }
            const prm = {
                facility_id: THIS.facility_id,
                status_code: THIS.status_code,
                job_types_id: THIS.job_types_id,
                from_date: THIS.start_from,
                to_date: THIS.start_to,
                created_by: THIS.currentUser.id
            }
            THIS.pdfURL = THIS.APPCONFIG.apiAuth + 'preview/upload/pdf/wo/report/' + ids + '?prm='+JSON.stringify(prm);


            THIS.pdfGenration = 1;
            if (THIS.QRenabled == 1) {
                THIS.pdfURL = THIS.pdfURL + '&qr=1';
            }
            let win = window.open(THIS.pdfURL, '_top');
            window.addEventListener('blur', function (ev) {
                $('#GODownload').addClass('animated fadeOut');
                setTimeout(function () {
                    THIS.pdfGenration = 0;
                    $('#GODownload').removeClass('animated fadeOut');
                }, 1200);
            }, false);
        }
    }


}
