import { Component} from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
    selector: 'invalid-pin-layout',
    templateUrl: './invalidPin.component.html',
})
export class InvalidPinComponent {


    mobileMenu = false;

    constructor(private router: Router) {
        const currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        if (currentUser != null) {
            this.router.navigate(['/dashboard']);
        }
    }



}
