import {Component, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';

@Component({
    selector: 'page-mail-single-preview',
    templateUrl: './mail-single-preview.component.html'
})
export class Mail_single_previewComponent {
    @Input() singleSelectedMail: any;
    @Input() templateT: any;
    @Input() MailFolder: any;
    @Output() discard = new EventEmitter<number>();
    @Output() replyThis = new EventEmitter<number>();
    @Output() forwardThis = new EventEmitter<number>();
    APPCONFIG = APPCONFIG;
    CustomHelper = CustomHelper;
    discardNow(){
        this.discard.emit(1);
    }

    currentUser;
    constructor() {
        let THIS = this;
        THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    }


    PreviewTemplate  = 0;
    PreviewData;
    getSingleMail() {
        let THIS = this;
        let param = {
            folder : THIS.MailFolder,
            msgno : THIS.singleSelectedMail.msgno
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'mailbox/mails/single?token=' + this.currentUser.token,
            type: 'get',
            data: param,
            success: function (res) {
                if(res.status == 2000){
                    THIS.PreviewData = res.data;
                    let mail = THIS.PreviewData;
                    THIS.To = mail.to != undefined ? mail.to : [];
                    THIS.CC.length = 0;
                    if(mail.message.header.to != undefined){
                        if(mail.message.header.to.length > 0){
                            $.each(mail.message.header.to, function(i , cc){
                                let cname = cc.mailbox+'@'+cc.host;
                                if(cname != THIS.To){
                                    THIS.CC.push(cc.mailbox+'@'+cc.host);
                                }
                            });
                        }
                    }
                    console.log(THIS.PreviewData.message.attachments);
                    console.log(THIS.PreviewData.message.attachments.length);


                    let bodyMail = '';
                    if(res.data.message.html != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.html)); }
                    else if(res.data.message.html2 != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.html2)); }
                    else if(res.data.message.html3 != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.html3)); }
                    else if(res.data.message.html1 != ''){ bodyMail = atob(res.data.message.html1); }
                    else if(res.data.message.text != ''){ bodyMail = THIS.CustomHelper.nl2br(atob(res.data.message.text)); }
                    THIS.PreviewTemplate = 1;
                    setTimeout(function () {
                        $('#__mailSkin').html(bodyMail);
                        $('.__mailSkin__').html(bodyMail);
                    }, 100);
                }
            }
        });
    }
    To = '';
    CC = [];
    calcCurWidth(){
        let W = $('.def_content_global').width();
        let w = 450;
        return (W-w)+'px';
    }



    replyMail() {
        this.replyThis.emit(this.singleSelectedMail);
    }
    forwardMail() {
        this.forwardThis.emit(this.singleSelectedMail);
    }


    ngOnChanges(changes: SimpleChanges): void {
        if(changes.MailFolder != undefined){
            console.log(changes.MailFolder.currentValue);
        }
        if(changes.singleSelectedMail != undefined){
            this.PreviewTemplate = 0;
            let THIS = this;
            this.getSingleMail();
        }
    }



}
