import {RouterModule, Routes} from '@angular/router';

import {LayoutComponent} from './layout/layout.component';
import {NotFoundComponent} from './layout/not-found.component';
import {WorkOnlineComponent} from './pages/work-orders/workOnline.component';
import {InspectionOnlineComponent} from './pages/inspection/inspectionOnline.component';
import {AssetOnlineComponent} from './pages/assets/assetOnline.component';
import {LoginComponent} from './pages/login/login.component';
import {ForgotComponent} from './pages/login/forgot.component';
import {ResetPassComponent} from './pages/login/resetPass.component';

import {RegisterComponent} from './pages/login/register.component';
import {RegisterSuccessComponent} from './pages/login/registerSuccess.component';
import {RegisterPasswordComponent} from './pages/login/registerPassword.component';
import {RegisterCompanyComponent} from './pages/login/registerCompany.component';
import {RegisterVendorProgram} from './pages/login/vendorProgram';
import {RegisterProcessComponent} from './pages/login/registerProcess.component';
import {InvalidPinComponent} from './pages/login/invalidPin.component';

import {PersonActivationComponent} from './pages/login/personActivation.component';
import {TeamActivationComponent} from './pages/login/teamActivation.component';
import {RestoreComponent} from './pages/login/restore.component';

const AppRoutes: Routes = [
  {path: '', component: LoginComponent, pathMatch: 'full'},
  {path: 'login', component: LoginComponent, pathMatch: 'full'},
  {path: 'auth/forgot', component: ForgotComponent, pathMatch: 'full'},
  // =================
  // New Sign Up
  // =================
  {path: 'register', component: RegisterComponent, pathMatch: 'full'},
  {path: 'auth/register/success', component: RegisterSuccessComponent, pathMatch: 'full'},
  {path: 'auth/invalid/pin', component: InvalidPinComponent, pathMatch: 'full'},
  {path: 'auth/register/password/:code', component: RegisterPasswordComponent, pathMatch: 'full'},
  {path: 'auth/register/company/:code', component: RegisterCompanyComponent, pathMatch: 'full'},
  {path: 'auth/register/process/:name', component: RegisterProcessComponent, pathMatch: 'full'},
  {path: 'auth/register/vendor/process/:name', component: RegisterVendorProgram, pathMatch: 'full'},
  {path: 'auth/reset/pass/:pin', component: ResetPassComponent, pathMatch: 'full'},
  {path: 'auth/activation/:pin', component: PersonActivationComponent, pathMatch: 'full'},
  {path: 'auth/team/:pin', component: TeamActivationComponent, pathMatch: 'full'},
  {path: '__eq_authenticate/:token', component: RestoreComponent, pathMatch: 'full'},
  // =================
  // Pages Routing
  // =================
  {path: ':team', component: LoginComponent, pathMatch: 'full'},
  // =================
  {path: ':team/asset/preview/:short_code', component: AssetOnlineComponent},
  {path: ':team/inspection/preview/:short_code', component: InspectionOnlineComponent},
  {path: ':team/work-order/preview/:short_code', component: WorkOnlineComponent},
  // =================
  {path: ':team/:page', component: LayoutComponent},
  {path: ':team/:page/new', component: LayoutComponent},
  {path: ':team/:page/compose', component: LayoutComponent},
  {path: ':team/:page/reply', component: LayoutComponent},
  {path: ':team/:page/forward', component: LayoutComponent},
  {path: ':team/:page/edit/:id', component: LayoutComponent},
  {path: ':team/:page/:id', component: LayoutComponent},
  {path: '**', redirectTo: ''}
];

export const AppRoutingModule = RouterModule.forRoot(AppRoutes, {useHash: false});
