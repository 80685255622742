import {Component, EventEmitter, Output, TemplateRef, SimpleChanges} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ContactsService} from '../../../_services/contacts.service';
import {InvoicesService} from '../../../_services/invoices.service';
import { CustomHelper } from '../../../customhelper';
import { APPCONFIG } from '../../../config';
import { FacilityService } from '../../../_services/facility.service';
import { AuthService } from '../../../_services/auth.service';
import { FcmService } from '../../../_services/fcm.service';
declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let Inputmask: any;

@Component({
    selector: 'create-rcti',
    templateUrl: './createRcti.component.html'
})
export class CreateRctiComponent {
    @Output() newInv = new EventEmitter<number>();
    @Output() discard = new EventEmitter<number>();
    @Output() emailIt = new EventEmitter<number>();
    APPCONFIG = APPCONFIG;
    CustomHelper = CustomHelper;
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};
    currentUser;

    constructor(private contactsService: ContactsService,
                public snackBar: MatSnackBar,
                public fcmService: FcmService,
                private facilityService: FacilityService,
                private authService: AuthService,
                private invoicesService: InvoicesService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this._startDateConfig.onChange = function(selectedDates, dateStr, instance){
            THIS.param.invoice_date = THIS.CustomHelper.formatDateToDB(selectedDates);
            THIS.onCreatedSelect();
        };
        this._dueDateConfig.onChange = function(selectedDates, dateStr, instance){
            THIS.param.due_date = THIS.CustomHelper.formatDateToDB(selectedDates);
            THIS.onDueSelect();
        };
        if (localStorage.work_to_invoice !== undefined) {
            let workOrder = JSON.parse(localStorage.work_to_invoice);
            delete localStorage.work_to_invoice;
            this.param.facility_id = 0;
            this.param.work_id = THIS.CustomHelper.makeInt(workOrder.id);
            this.param.order_number = workOrder.prefix+workOrder.work_order_id;
            this._startDateConfig.defaultDate = new Date();
            this.param.invoice_date = THIS.CustomHelper.formatDateToDB(this._startDateConfig.defaultDate);
            if(workOrder.attachInventories.length > 0){
                THIS.createCartItems.length = 0;
            }
            $.each(workOrder.attachInventories, function (i, v) {
                let itemData = {
                    item_name : v.title,
                    item_qty : THIS.CustomHelper.makeFloat(v.pick),
                    item_price : THIS.CustomHelper.makeFloat(v.retail_price),
                    item_tax : 'Tax Included',
                    item_total : THIS.CustomHelper.makeFloat(v.pick)*THIS.CustomHelper.makeFloat(v.retail_price)
                };
                THIS.createCartItems.push(itemData);
            });
            if(workOrder.personnel.length == 1){
                THIS.param.contact_id = workOrder.personnel[0].id.toString();
            }
            this.changeInvoiceTotal();
        }
        setTimeout(function () {
            THIS.changeInvoiceTotal();
            THIS.genEditor();
        }, 1000);
        this.getPersonalInfo();
        this.getAllFacility();
        this.getAllContact();
        this.checkCurrency();
    }
    genEditor(){
        let THIS = this;
        setTimeout(function () {
            (<any>$('.summernote')).summernote({
                maxHeight : '900px',
                minHeight : '150px',
                toolbar: [
                    ['style', ['bold', 'underline', 'clear']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    // ['insert', ['link','video']],
                    ['misc', ['fullscreen']]
                ]
            });
        }, 100);
    }

    getPersonalInfo(){
        let THIS = this;
        this.authService.getPersonalInfo().subscribe(res => {
            if(res.data.terms != null){
                if(res.data.terms.rcti != undefined){
                    this.param.customer_notes = res.data.terms.rcti.customer_notes;
                    this.param.terms_condition = res.data.terms.rcti.terms_condition;
                    (<any>$('#customer_note')).summernote('code', this.param.customer_notes);
                    (<any>$('#terms_condition')).summernote('code', this.param.terms_condition);
                }
            }
        });
    }

    toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{'font': []}],
        [{'align': []}],
        ['clean'],                                         // remove formatting button
        // ['link', 'image', 'video']                         // link and image, video
        // ['image']                         // link and image, video
    ];


    Facility = [];
    FacilityComp = [];
    getAllFacility() {
        let THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            this.FacilityComp.length = 0;
            this.FacilityComp = res.facility;
            $.each(res.facility, function(i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                $.each(v.child, function(j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                    $.each(vC.child, function(k, vCC) {
                        vCC['pp'] = 2;
                        THIS.Facility.push(vCC);
                        $.each(vCC.child, function(l, vCCC) {
                            vCCC['pp'] = 3;
                            THIS.Facility.push(vCCC);
                        });
                    });
                });
            });
            setTimeout(function () {
                // (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                (<any>$('.chosen-default-single-fac')).chosen('destroy');
                (<any>$('.chosen-default-single-fac')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Facility'
                }).on('change', function(e,p){
                    THIS.param.facility_id = e.target.value;
                });
            }, 100);
        });
    }
    contact = {
        org: [],
        person: []
    };
    WoInvtFilter = '3';
    getAllContact(){
        let THIS = this;
        this.contactsService.getAllSupplierTotal().subscribe(res => {
            this.contact = res;
            setTimeout(function () {
                if(THIS.param.contact_id != ''){
                    (<any>$('.chosen-default-single-supplier')).val(THIS.param.contact_id);
                }
                (<any>$('.chosen-default-single-supplier')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Supplier'
                }).on('change', function(e,p){
                    let v = e.target.value;
                    THIS.param.contact_id = v;
                    THIS.param.p_group_id = '';
                    if(THIS.param.contact_id != ''){
                        let pparam = {
                            createdBy: THIS.param.contact_id,
                            groupBy: THIS.param.p_group_id
                        };
                        THIS.getCompWo(pparam);

                        let ___selectedTab = 0;
                        let tabs = $('#sort-tabs').find('li');
                        tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
                        let child = ___selectedTab+1;
                        (<any>$('.owl-carousel')).owlCarousel('destroy');
                        $('#sort-tabs').find('li').find('a').removeClass('active');
                        $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
                        (<any>$('#InvItemsModal')).modal('show');
                        setTimeout(function () {
                            THIS.WoInvtFilter = '3';
                            (<any>$('.chosen-default-comp-wo')).val('3');
                            (<any>$('.chosen-default-comp-wo')).chosen('destroy');
                            (<any>$('.chosen-default-comp-wo')).chosen({
                                allow_single_deselect: true,
                                placeholder_text_single: 'Select Supplier'
                            }).on('change', function(eee,ppp){
                                THIS.WoInvtFilter = eee.target.value;
                            });
                        }, 100);
                    }
                });
            }, 100);
            setTimeout(function () {
                (<any>$('.chosen-default-single-terms')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Invoice Terms'
                }).on('change', function(e,p){
                    THIS.param.terms = e.target.value;
                    THIS.onCreatedSelect();
                });
            }, 100);
        });
    }
    CompWo = [];
    getCompWo(param){
        let THIS = this;
        THIS.CompWo.length = 0;
        $.ajax({
            url: THIS.APPCONFIG.api+'get/work-orders-comp?token='+THIS.currentUser.token,
            type: 'post',
            data : param,
            success : function(res){
                if(res.status == 2000){
                    $.each(res.data, function (i, v) {
                        v.creation_date = THIS.CustomHelper.formatDateTime(v.creation_date);
                        THIS.CompWo.push(v);
                    });
                }
                setTimeout(function () {
                    THIS.CustomHelper.tooltipRender();
                }, 100);
            }
        });
    }
    updateItemsList(){
        let THIS = this;
        let woList = THIS.CompWo;
        let iitems = [];
        this.param.woComp.length = 0;
        $.each(woList, function (i, v) {
            if(v.done == 0 && v.selected == 1){
                $.each(v.sor, function (j, s) {
                    let sVdata = {
                        item_name : v.uid+'-'+v.subject+'-'+s.sor_id,
                        item_qty : s.wo_qty,
                        item_price : s.rate,
                        item_tax : 'Tax Included',
                        item_total : s.wo_qty*s.rate
                    };
                    iitems.push(sVdata);
                });
                $.each(v.inventories, function (k, w) {
                    let sVdata = {
                        item_name : v.uid+'-'+v.subject+'-'+w.title,
                        item_qty : w.pick,
                        item_price : w.retail_price,
                        item_tax : 'Tax Included',
                        item_total : w.pick*w.retail_price
                    };
                    iitems.push(sVdata);
                });
                THIS.param.woComp.push(v.id);
            }
        });
        if(iitems.length > 0){
            this.createCartItems = iitems;
            this.changeInvoiceTotal();
        }
        (<any>$('#InvItemsModal')).modal('hide');
    }

    createCartItemsSubTotal = 0;
    createCartItemsTax = 10;
    createCartItemsTotal = 0;
    createCartItems = [
        {
            item_name : '',
            item_qty : 1,
            item_price : 1,
            item_tax : 'Tax Included',
            item_total : 1
        }
    ];
    addNewItemInCart(): void {
        let newCart = {
            item_name : '',
            item_qty : 1,
            item_price : 1,
            item_tax : 'Tax Included',
            item_total : 1,
        };
        this.createCartItems.push(newCart);
        this.changeInvoiceTotal();
        this.checkCurrency();
    }
    removeFromCart(index): void{
        if(this.createCartItems.length > 1){
            this.createCartItems.splice(index, 1);
        }
        this.changeInvoiceTotal();
    }
    copyThisInCart(cart): void{
        let newCart = {
            item_name : cart.item_name,
            item_qty : cart.item_qty,
            item_price : cart.item_price,
            item_tax : cart.item_tax,
            item_total : cart.item_total,
        };
        this.createCartItems.push(newCart);
        this.changeInvoiceTotal();
    }
    updateLineName(e,index:number): void{
        let trigger = $(e.target);
        let value = trigger.val();
        let name = value.toString();
        this.createCartItems[index].item_name = name;
    }
    updateLineQty(e,index:number): void{
        let trigger = $(e.target);
        let value = trigger.val();
        let Qty = Number(value);
        this.createCartItems[index].item_qty = Qty;
        let lineTotal = this.createCartItems[index].item_qty * this.createCartItems[index].item_price;
        this.createCartItems[index].item_total = lineTotal;
        this.changeInvoiceTotal();
    }
    updateLinePrice(e,index:number): void{
      let trigger = $(e.target);
      let inputValue = this.CustomHelper.makeStr(trigger.val());
      let value = inputValue.replace(/,/, '');
      value = value.substring(1);
      let price = Number(value);
      this.createCartItems[index].item_price = price;
      let lineTotal = this.createCartItems[index].item_qty * this.createCartItems[index].item_price;
      this.createCartItems[index].item_total = lineTotal;
      this.changeInvoiceTotal();
    }
    changeInvoiceTax(tax: number): void{
        this.createCartItemsTax = tax;
        this.changeInvoiceTotal();
        if(tax == 10){this.param.tax_type = 1;}
        if(tax == 15){this.param.tax_type = 2;}
        this.param.tax_value = tax;
    }
    changeInvoiceTotal(): void{
        let subTotal = 0;
        $.each(this.createCartItems, function (i, v) {
            subTotal = subTotal+v.item_total;
        });
        this.createCartItemsSubTotal = subTotal;
        this.createCartItemsTotal = this.createCartItemsSubTotal + (this.createCartItemsSubTotal * (this.createCartItemsTax/100));
    }
    discardNewInvoice() {
        this.discard.emit(1);
    }



    param = {
        rcti: 1,
        prefix: 'IN',
        work_id: null,
        invoice_id: '',
        facility_id: 0,
        contact_id: '',
        p_group_id: '',
        invoice_date : '',
        invoice_number : '',
        order_number : '',
        terms : '',
        due_date : '',
        sub_total : 0,
        tax_type : 1,
        tax_value : 10,
        total : 0,
        customer_notes : '',
        terms_condition : '',
        customer_note_on : 1,
        term_on : 1,
        items : '',
        woComp: []
    };
    emailThisInvoice(){
        this.emailIt.emit(1);
    }
    createThisInvoice(){
        let ldd = document.getElementById('SaveThisLadda');
        let ladda = Ladda.create(ldd);
        let valid = this.validationThisForm();
        if(valid == 1){
            let THIS = this;
            ladda.start();
            this.param.customer_notes = (<any>$('#customer_note')).summernote('code');
            this.param.terms_condition = (<any>$('#terms_condition')).summernote('code');
            this.param.sub_total = this.createCartItemsSubTotal;
            this.param.total = this.createCartItemsTotal;
            this.param.items = JSON.stringify(this.createCartItems);
            this.invoicesService.createInvoice(this.param).subscribe(res => {
                if(res.status == 2000){
                    ladda.stop();
                    this.newInv.emit(res.data);
                    toastr.success('New RCTI successfully created', 'New RCTI', this.toastrOption);
                    // Pusher emit
                } else {
                    swal({
                        title: 'Somethign is wrong! Please try again',
                        className: 'swal-btn-center'
                    });
                }
            });
        }
    }
    validationThisForm(){
        let valid = 1;
        /*if(this.param.facility_id == ''){
            valid = 0;
            swal('Please select a facility.');
            return false;
        }*/
        if(this.param.contact_id == '' && this.param.p_group_id == ''){
            valid = 0;
            swal({
                title: 'Please select a supplier / personnel group.',
                className: 'swal-btn-center'
            });
            return false;
        }
        if(this.param.invoice_date == ''){
            valid = 0;
            swal({
                title: 'Please select invoice date.',
                className: 'swal-btn-center'
            });
            return false;
        }
        return valid;
    }


    inlineEdit = 0;
    getLastInvoiceId() {
        this.invoicesService.getLastRctiId().subscribe(res => {
            this.param.prefix = res.prefix;
            this.param.invoice_id = res.invoice_id;
        });
    }



    due_date_preview = 1;
    _startDateConfig = {
        timePicker: false,
        defaultDate: null,
        dateFormat: this.CustomHelper.dateFormatFlat(),
        onChange: function(selectedDates, dateStr, instance) {}
    };
    onCreatedSelect() {
        let THIS = this;
        this.due_date_preview = 0;
        let terms = parseInt(this.param.terms);
        let invDate = null;
        if(terms > 1 && THIS.param.invoice_date != ''){
            invDate = new Date(THIS.param.invoice_date);
            invDate.setDate(invDate.getDate() + terms);
            this.param.due_date = THIS.CustomHelper.formatDateToDB(invDate);
        } else {
            this.param.due_date = '';
        }
        setTimeout(function () {
            THIS._dueDateConfig.minDate = new Date(THIS.param.invoice_date);
            THIS._dueDateConfig.defaultDate = invDate;
            THIS.due_date_preview = 1;
        }, 400);
    }
    _dueDateConfig = {
        timePicker: false,
        minDate: null,
        defaultDate: null,
        dateFormat: this.CustomHelper.dateFormatFlat(),
        onChange: function(selectedDates, dateStr, instance) {}
    };
    onDueSelect() {
        let THIS = this;
        setTimeout(function () {
            (<any>$('.chosen-default-single-terms')).val('0');
            (<any>$('.chosen-default-single-terms')).chosen('destroy');
            (<any>$('.chosen-default-single-terms')).chosen({
                allow_single_deselect: true,
                placeholder_text_single: 'Select Invoice Terms'
            }).on('change', function(e,p){
                THIS.param.terms = e.target.value;
                THIS.onCreatedSelect();
            });
        }, 100);
    }


    ngOnInit() {
        let THIS = this;
    }
    ngOnDestroy() {
    }

  checkCurrency() {
    Inputmask.extendAliases({
      pesos: {
        prefix: "$",
        groupSeparator: "",
        alias: "numeric",
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        radixPoint: '.',
        placeholder: '0.00'
      }
    });

    setTimeout(function () {
      (<any>$("._currency_validate_eq")).inputmask({ alias : "pesos" });
    }, 300);
  }

    currencyIcon():string {    
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
    }

}
