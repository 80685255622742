import {Component, TemplateRef, Input, SimpleChanges, OnInit} from '@angular/core';
import {CustomHelper} from '../../customhelper';

@Component({
    selector: 'page-settings',
    templateUrl: './settings.component.html'
})
export class SettingsComponent {
    @Input() openSetting: any;
    CustomHelper = CustomHelper;
    ROUTES = '/settings';
    userInfo = null;
    constructor() {
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.userInfo = Session;
        if(this.userInfo.user_type > 2){
            this.section = 3;
        }
        console.log(this.section);
    }


    section = 1; // 1=WO, 2=Asset
    refreshSections(){
        this.section = 0;
    }

    ngOnChanges(changes: SimpleChanges): void {
        let THIS = this;
        if(changes.openSetting != undefined){
          THIS.section = changes.openSetting.currentValue;
        }
    }

}
