import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';
import {ContactsService} from '../../../_services/contacts.service';
import {FcmService} from '../../../_services/fcm.service';
import {APPCONFIG} from '../../../config';
import {Lightbox} from 'angular2-lightbox';
import {CustomHelper} from '../../../customhelper';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ChatService} from 'src/app/_services/chat.service';
import {THIS_EXPR} from '@angular/compiler/src/output/output_ast';

declare let toastr: any;
declare let swal: any;
declare let google: any;
declare let Ladda: any;
declare let Pusher: any;
declare let fabric: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;
declare let Pickr: any;
declare let Inputmask: any;

@Component({
  selector: 'work-order-single-preview',
  templateUrl: './singlePreviewWork.component.html'
})
export class SinglePreviewWorkComponent {
  modalRef: BsModalRef;
  @Output() currentID = new EventEmitter<number>();
  @Output() discard = new EventEmitter<number>();
  @Output() statusChange = new EventEmitter<any>();
  @Output() editThis = new EventEmitter<any>();
  @Output() refreshThis = new EventEmitter<any>();
  @Output() archiveThis = new EventEmitter<any>();
  @Output() activeThis = new EventEmitter<any>();
  @Output() removeThis = new EventEmitter<any>();
  @Output() duplicateThis = new EventEmitter<any>();
  @Output() previewQuick = new EventEmitter<number>();
  @Output() QuickList = new EventEmitter<number>();
  @Output() invoiceThis = new EventEmitter<number>();
  @Output() rctiThis = new EventEmitter<number>();
  @Output() loaded = new EventEmitter<number>();

  @Input() workId: any;
  @Input() projectView: any = false;
  @Input() editOptionForm: any;
  @Input() printThis: any;
  @Input() pdfThis: any;
  @Input() tmlThis: any;
  @Input() wo_is_active: any;
  @Input() statusUpdate: any;
  workDetails;
  workDetailsChange = 0;
  currentUser;
  APPCONFIG = APPCONFIG;
  _albums = [];
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
  activeAnonymousUsers = '';
  editAnonymousUsers = '';
  activeAnonymous = [];
  editAnonymous = [];
  updateCheckID = '';
  pageViewerCheck = false;
  ActiveChat = [];


  constructor(private _lightbox: Lightbox,
              private fcmService: FcmService,
              private contactsService: ContactsService,
              private mediaService: MediaService,
              private facilityService: FacilityService,
              private chatService: ChatService) {
    const THIS = this;
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.getSessionUserInfo();
    this.sortOptions.onUpdate = function () {
      THIS.sortTaskList();
    };

    this.chatService.getActiveChat.subscribe(ActiveChat => {
      this.ActiveChat = ActiveChat;
    });

    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.dateCreatedOption.onChange = function (selectedDates, dateStr, instance) {
      if (selectedDates.length > 0) {
        THIS.dateCreated = new Date(selectedDates);
      } else {
        THIS.dateCreated = null;
      }
      THIS.onCreatedSelect();
    };
    this.dateDueOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.dateDue = new Date(selectedDates);
      THIS.onDueSelect();
    };

    THIS.facilityService.currentSelectedFac.subscribe(value => {
      THIS.refreshsinglePage({uid: THIS.workId});
    });

    this.updateCheckID = Date.now() + '_' + Session.id;

    $('body').on('click', '.pswp__button--edit', function () {
      console.log('amiemai');
      if (THIS.Gallery !== null) {
        THIS.Gallery.close();
      }
      THIS.Preview = JSON.parse($('#EditImageData').attr('data-type'));
      setTimeout(function () {
        THIS.openPhotoEditor(THIS.Preview);
      }, 400);
    });

    this.checkCurrency();
  }

  userInfo = {
    email: '',
    name: '',
    avatar: '',
    settings: '',
    user_type: '',
  };

  getSessionUserInfo() {
    this.userInfo = {
      email: this.currentUser.email,
      name: this.currentUser.nae,
      avatar: this.currentUser.avatar,
      settings: this.currentUser.settings,
      user_type: this.currentUser.user_type,
    };
    /*this.authService.getSessionUserInfo().subscribe(res => {
            this.userInfo = {
                email: res.email,
                name: res.first_name+' '+res.last_name,
                avatar: res.avatar,
                settings : res.settings,
                user_type: res.user_type,
            };
        });*/
  }

  inlinePrefixId = 0;
  inlineFacility = 0;
  inlineSubject = 0;
  inlineDescription = 0;
  toolbarOptions = [
    ['bold', 'italic', 'underline'],        // toggled buttons
    ['blockquote', 'code-block'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    // [{'font': []}],
    [{'align': []}],

    // ['clean'],                                         // remove formatting button
    // ['link', 'image', 'video'],                         // link and image, video
    // ['image']                         // link and image, video
  ];
  rightRefresh = 1;

  CMap;

  GmapZoomIn(data) {
    this.CMap.setZoom(this.CMap.getZoom() + data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const THIS = this;
    if (changes.editOptionForm != undefined) {
      if (changes.editOptionForm.currentValue == 1) {
        this.inlinePrefixId = 1;
        this.inlineFacility = 1;
        this.inlineSubject = 1;
        this.inlineDescription = 1;
      } else {
        this.inlinePrefixId = 0;
        this.inlineFacility = 0;
        this.inlineSubject = 0;
        this.inlineDescription = 0;
      }
      this.workDetailsChange = 1;
      this.rightRefresh = 0;
      setTimeout(function () {
        THIS.CustomHelper.tooltipRender();
      }, 100);
    }
    if (changes.printThis != undefined) {
      if (changes.printThis.currentValue == 1) {
        this.printActive = 1;
      }
    }
    if (changes.pdfThis != undefined) {
      if (changes.pdfThis.currentValue == 1) {
        this.pdfActive = 1;
      }
    }
    if (changes.tmlThis != undefined) {
      if (changes.tmlThis.currentValue == 1) {
        this.tmlPage = 1;
        this.getTimeLine();
      }
    }
    if (changes.workId != undefined) {
      this.workDetailsChange = 0;
      this.rightRefresh = 1;
      const id = changes.workId.currentValue;
      if (id != '') {
        const param = {uid: id};
        this.workDetailsChange = 0;
        this.refreshsinglePage(param);
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      }
    }
    if (changes.projectView != undefined) {
      this.projectView = changes.projectView.currentValue;
    }
  }

  showMapBtn = 0;
  AssetMap = 1;
  param_created_at = '';
  param_due = '';

  refreshsinglePage(param) {
    const THIS = this;
    THIS.UID_IMG = Date.now();
    if (this.CustomHelper.woRender != null) {
      this.CustomHelper.woRender.abort();
    }
    if (param.uid !== undefined) {
      $.ajax({
        url: THIS.APPCONFIG.api + 'work-orders/single?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          THIS.AssetMap = 1;
          if (res.status == 2000) {
            const work = res.data;
            THIS.workDetails = work;
            THIS.receiveSocket();
            THIS.currentIDUpdate();
            THIS.workDetails.dates.creation_date = THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.creation_date);
            THIS.workDetails.dates.edited_date = THIS.workDetails.dates.edited_date != '' && THIS.workDetails.dates.edited_date != null ? THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.edited_date) : '';

            THIS.workDetails.facStr = THIS.workDetails.facStr.reverse();
            THIS._albums.length = 0;
            if (THIS.workDetails.media_files !== undefined) {
              for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
                const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path + '&ref=' + Date.now();
                const caption = '';
                const thumb = '';
                const album = {
                  src: src,
                  caption: caption,
                  thumb: thumb,
                  w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
                  h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
                };
                THIS._albums.push(album);
              }
            }

            THIS.id = work.id;
            THIS.taskParam.work_id = work.id;
            THIS.taskRemoveParam.work_id = work.id;
            if (work.tasks.length > 0) {
              THIS.taskType = work.tasks[0].type;
            }


            THIS.param.project_id = work.project_id;
            THIS.param.subject = work.subject;
            THIS.param.prefix = work.prefix;
            THIS.param.work_order_id = work.work_order_id;
            THIS.param.description = work.description;
            THIS.param.contact_id = work.contact != null ? work.contact_id.toString() : '';
            THIS.param.asset_id = work.asset_id != null ? work.asset_id : '';
            THIS.AssetSelect = THIS.param.asset_id != '' ? THIS.param.asset_id.split(',') : [];
            THIS.param.job_types_id = work.job_type != null ? work.job_type.id.toString() : '';
            THIS.param.personnel = work.personnel_id > 0 ? work.personnel_id.toString() : '';
            THIS.param.facility_id = work.facility.id.toString();
            THIS.param.status_code = work.status_code.id.toString();
            THIS.param.sor = work.sor;
            //
            if (work.dates.created_at != null) {
              THIS.param.created_at = THIS.CustomHelper.formatDateTimeToDB(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
              THIS.param_created_at = THIS.CustomHelper.formatDateTime(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
              const ca = THIS.CustomHelper.formatDateTime(new Date(THIS.param.created_at));
              THIS.dateCreated = new Date(THIS.CustomHelper.invToDate(ca));
              THIS.dateCreatedOption.defaultDate = THIS.dateCreated;
              THIS.dateDueOption.minDate = THIS.dateCreated;
            }
            //
            if (work.dates.due_at != null) {
              THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(work.dates.due_at);
              THIS.param_due = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
              const pd = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
              THIS.dateDue = new Date(THIS.CustomHelper.invToDate(pd));
              THIS.dateDueOption.defaultDate = THIS.dateDue;
            }

            THIS.progress = work.progress;
            THIS.incoming = work.incoming;
            THIS.param.incoming = THIS.incoming;
            THIS.attachInventories = work.attachInventories;
            THIS.param.attachInventories = THIS.attachInventories;
            //
            THIS.add2Calendar = work.calendar.add_to_calendar;
            THIS.param.add_to_calendar = THIS.add2Calendar;
            THIS.calendarStatus = work.calendar.calendar_color == '' || work.calendar.calendar_color == null ? '_blue' : work.calendar.calendar_color;
            THIS.param.calendar_color = THIS.calendarStatus;
            //
            THIS.param.personnel_id.length = 0;
            for (let p = 0; p < work.personnel.length; p++) {
              THIS.param.personnel_id.push(work.personnel[p].id.toString());
            }
            THIS.param.priority = work.priority !== null ? work.priority.id.toString() : '0';
            THIS.selectedFilesPreview = work.media_files;
            THIS.selectedWorkAttach = work.attach_files;
            // THIS.timeLineList.length = 0;
            // THIS.tmlPage = 1;
            THIS.getTimeLine();
            //
            //
            THIS.workDetailsChange = 1;
            THIS.rightRefresh = 0;
            //
            if (THIS.printActive == 1) {
              setTimeout(function () {
                const w = window.open();
                w.document.write($('#export').html());
                w.print();
                w.close();
              }, 1000);
            }
            if (THIS.pdfActive == 1) {
              setTimeout(function () {
                THIS.pdfThisWorkOrder();
              }, 500);
            }
            THIS.getAllFacility();
            THIS.jt_get();
            THIS.getAllJobStatus(0);
            THIS.pty_get(0);
            THIS.asset_get(THIS.param.facility_id);
            THIS.personnel_get();
            setTimeout(function () {
              (<any>$('.summernote')).summernote({
                maxHeight: '900px',
                minHeight: '150px',
                toolbar: [
                  ['style', ['bold', 'underline', 'clear']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['insert', ['link', 'video']],
                  ['misc', ['fullscreen']]
                ],
                callbacks: {
                  onKeyup: function (e) {
                    THIS.updateChangesDiscard();
                  }
                }
              });
              (<any>$('.summernote')).summernote('code', THIS.param.description);
              (<any>$('#summerDesc')).html(THIS.param.description);
              (<any>$('#woSubject')).val(THIS.param.subject);
              $('.tooltip').remove();
              (<any>$('[data-toggle="tooltip"]')).tooltip('destroy');
              setTimeout(function () {
                THIS.CustomHelper.tooltipRender();
              }, 1000);

              if (work.asset.length > 0 && document.getElementById('geo-map-single') != null) {
                let mapStart = 0;
                THIS.CMap = null;
                const latlng = [];
                const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                let labelIndex = 0;
                const infowindow = [];
                $.each(work.asset, function (i, v) {
                  THIS.showMapBtn = 1;
                  if (v.geo != '' && v.geo != null) {
                    const geo = JSON.parse(v.geo);
                    if (mapStart == 0) {
                      mapStart = 1;
                      THIS.AssetMap = 1;
                      const mapOptions = {
                        zoom: 12,
                        center: new google.maps.LatLng(33.8688, 151.2093),
                        gestureHandling: 'cooperative',
                        mapTypeControl: false,
                        streetViewControl: false,
                        zoomControl: false
                      };
                      THIS.CMap = new google.maps.Map(document.getElementById('geo-map-single'), mapOptions);
                    }
                    if (THIS.CMap != null) {
                      latlng.push(geo.geometry);
                      const marker = new google.maps.Marker({
                        position: geo.geometry,
                        map: THIS.CMap,
                        draggable: false,
                        title: geo.address,
                        label: labels[labelIndex++ % labels.length]
                      });
                      /*infowindow[i] = new google.maps.InfoWindow({
                                                content: `<div class="width-100" style="min-width: 600px"><h4 style="text-align: center">`+v.name+`</h4>
                                                            <div class="width-100">
                                                            <div class="margin-10"></div>
                                                            <div class="margin-10"></div>
                                                            <div class="col-sm-4">
                                                            <div class="_each_asset_Bar">
                                                            <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Bran/Model</strong>
                                                            <br>
                                                            <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                            <em>`+v.brand+`</em>
                                                            </span>
                                                            </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                            <div class="_each_asset_Bar">
                                                            <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Serial Number</strong>
                                                            <br>
                                                            <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                            <em>`+v.serial_number+`</em>
                                                            </span>
                                                            </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                            <div class="_each_asset_Bar"><i class="nav-icon material-icons _theme-color">money</i><strong>Purchase Cost</strong>
                                                            <br>
                                                            <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                            <em>`+v.purchase_cost+`</em>
                                                            </span>
                                                            </div>
                                                            </div>
                                                            <div class="margin-10">
                                                            </div>
                                                            <div class="margin-10">
                                                            </div></div></div>`
                                            });
                                            marker.addListener('click', function() {
                                                $.each(infowindow, function (iw, inf) {
                                                    if(inf != i){
                                                        infowindow[iw].close();
                                                    }
                                                });
                                                infowindow[i].open(map, marker);
                                            });*/
                    }
                  }
                });
                const latlngbounds = new google.maps.LatLngBounds();
                if (latlng.length > 0) {
                  for (let i = 0; i < latlng.length; i++) {
                    latlngbounds.extend(latlng[i]);
                  }
                  THIS.CMap.fitBounds(latlngbounds);
                  new google.maps.Rectangle({
                    bounds: latlngbounds,
                    map: THIS.CMap,
                    fillColor: 'transparent',
                    fillOpacity: 0.1,
                    strokeWeight: 0
                  });
                } else {
                  THIS.AssetMap = 0;
                }
              } else {
                THIS.AssetMap = 0;
              }
            }, 100);

            THIS.loaded.emit(1);
            // Pusher emit

            setTimeout(function () {
              THIS.CustomHelper.tooltipRender();
            }, 100);
            if (!THIS.pageViewerCheck) {
              /*THIS.socket.on('pageViewer_' + THIS.workDetails.id, function (v) {
                                THIS.presence = v;
                                THIS.renderPresence();
                            });*/

              THIS.pageViewerCheck = true;
            }
          } else if (res.status == 2200) {
            THIS.param.facility_id = res.facility_id.toString();
            THIS.asset_get(THIS.param.facility_id);
            THIS.workDetailsChange = -22;
            THIS.loaded.emit(1);
          } else {
            THIS.workDetailsChange = -1;
            THIS.loaded.emit(1);
          }
        }
      });
    }
  }

  confirmDiscard() {
    const THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    const index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (this.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Discard Alert',
        text: 'Are you sure you want to discard?',
        icon: 'warning',
        buttons: ['Stay on this page', 'Leave page'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          THIS.discard.emit(1);
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
          setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
          }, 100);
        }
      });
    } else {
      THIS.discard.emit(1);
      setTimeout(function () {
        THIS.CustomHelper.tooltipRender();
      }, 400);
    }
  }

  Facility = [];
  FacilityRecent = [];
  FacilityComp = [];

  getAllFacility() {
    const THIS = this;
    THIS.Facility.length = 0;
    THIS.FacilityComp.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.FacilityRecent = res.recent;
        THIS.FacilityComp = res.facility;
        $.each(res.facility, function (i, v) {
          v['pp'] = 0;
          THIS.Facility.push(v);
          $.each(v.child, function (j, vC) {
            vC['pp'] = 1;
            THIS.Facility.push(vC);
            $.each(vC.child, function (k, vCC) {
              vCC['pp'] = 2;
              THIS.Facility.push(vCC);
              $.each(vCC.child, function (l, vCCC) {
                vCCC['pp'] = 3;
                THIS.Facility.push(vCCC);
              });
            });
          });
        });
        setTimeout(function () {
          if ($('.reRenderFacility').length > 0) {
            const facilityId = parseInt($('.reRenderFacility').attr('data-id'));
            if (facilityId > 0) {
              THIS.param.facility_id = facilityId.toString();
              THIS.asset_get(THIS.param.facility_id);
            }
          }
          (<any>$('.chosen-default-single-fac')).chosen('destroy');
          (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
          (<any>$('.chosen-default-single-fac')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Facility'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#createFacilityModal')).modal('show');

            } else {
              THIS.param.facility_id = e.target.value;
              THIS.asset_get(THIS.param.facility_id);
              THIS.validationThisForm();
              THIS.updateChangesDiscard();
            }
          });
          THIS.SA_fac = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  selectFacility(facility_id) {
    this.param.facility_id = facility_id.toString();
    this.asset_get(this.param.facility_id);
    const form = $('#workCreateForm');
    this.validationThisForm();
  }

  _fc_updating = 0;

  updateThisFacility(id) {
    const THIS = this;
    this._fc_updating = 1;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/enable?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        THIS._fc_updating = 0;
        THIS.workDetailsChange = 0;

        const id = THIS.workId;
        const param = {uid: id};
        THIS.refreshsinglePage(param);
        THIS.facilityService.refreshFacility();
      }
    });
  }

  JobType = [];

  jt_get() {
    const THIS = this;
    this.JobType.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-types?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.JobType = res.data;
        setTimeout(function () {
          if ($('.reRenderJobType').length > 0) {
            const job_types_id = parseInt($('.reRenderJobType').attr('data-id'));
            if (job_types_id > 0) {
              THIS.param.job_types_id = job_types_id;
            }
          }
          (<any>$('.chosen-default-single-jti')).chosen('destroy');
          (<any>$('.chosen-default-single-jti')).val(THIS.param.job_types_id);
          (<any>$('.chosen-default-single-jti')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Job Type'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#JobTypesModal')).modal('show');
            } else {
              THIS.param.job_types_id = e.target.value;
              THIS.updateChangesDiscard();
            }
          });
          THIS.SA_type = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  Priority = [];

  pty_get(refresh): void {
    const THIS = this;
    this.Priority.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'priorities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.Priority = res.data;
      }
    });
  }

  choosePty(priority) {
    const THIS = this;
    if (priority != null) {
      THIS.param.priority = priority.id.toString();
      if (THIS.param.created_at != null && THIS.param.created_at != '') {
        const v = new Date(THIS.dateCreated);
        const min = THIS.CustomHelper.makeFloat(priority.amount) * THIS.CustomHelper.makeFloat(priority.duration);
        v.setMinutes(v.getMinutes() + min);
        THIS.dateDue = THIS.CustomHelper.formatDateTimeToDB(v);
        THIS.changingStart = 1;
        setTimeout(function () {
          THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
          THIS.dateDueOption.defaultDate = new Date(THIS.dateDue);
          THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue));
          THIS.changingStart = 0;
        }, 400);
      }
    } else {
      THIS.param.priority = '0';
      if (THIS.param.created_at != null && THIS.param.created_at != '') {
        THIS.param.due = THIS.dateDue != null ? THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue)) : '';
      }
    }
  }

  Assets = [];
  AssetSelect = [];

  asset_get(facility_id = ''): void {
    const THIS = this;
    this.Assets.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'assets/get/Asset/allList?token=' + THIS.currentUser.token,
      data: {facility_id: facility_id},
      type: 'post',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Assets = res.data;
        }
        setTimeout(function () {
          if ($('.reRenderAssets').length > 0) {
            const AssetSelectId = parseInt($('.reRenderAssets').attr('data-id'));
            if (AssetSelectId > 0) {
              THIS.AssetSelect.push(AssetSelectId);
              THIS.param.asset_id = THIS.AssetSelect.join(',');
              THIS.updateChangesDiscard();
            }
          }
          (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
          (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
          (<any>$('.chosen-default-multiple-asset')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No asset selected'
          }).on('change', function (e, p) {
            if (p.selected === '000') {
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#assetModal')).modal('show');
            } else {
              if (p.selected != undefined) {
                THIS.AssetSelect.push(p.selected);
              } else {
                THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
              }
              THIS.param.asset_id = THIS.AssetSelect.join(',');
              THIS.updateChangesDiscard();
            }
          });
          THIS.SA_asset = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  Personnel = {
    org: [],
    person: []
  };

  personnel_get(): void {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Personnel = res;
        }
        setTimeout(function () {
          if ($('.reRenderPerson').length > 0) {
            const personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
            if (personnel_id > 0) {
              THIS.param.personnel_id.push(personnel_id.toString());
            }
          }
          const pp_val = THIS.param.personnel_id;
          (<any>$('.chosen-default-multiple-per')).val(pp_val);
          (<any>$('.chosen-default-multiple-per')).chosen('destroy');
          (<any>$('.chosen-default-multiple-per')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No personnel selected'
          }).on('change', function (e, p) {
            if (p.selected != undefined) {
              const v = p.selected;
              if (v === '000') {
                let ___selectedTab = 0;
                const tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) {
                  if ($(v).find('a.active').length > 0) {
                    ___selectedTab = i;
                  }
                });
                const child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#PersonQuickModal')).modal('show');
              } else {
                THIS.param.personnel_id.push(v.toString());
              }
            } else {
              const v = p.deselected;
              if (THIS.param.personnel_id.indexOf(v) > -1) {
                let ssCount = 0;
                const ssSor = [];
                $.each(THIS.param.sor, function (i, sor_v) {
                  if (sor_v.user_id == v) {
                    ssCount++;
                  } else {
                    ssSor.push(sor_v);
                  }
                });
                if (ssCount > 0) {
                  swal({
                    title: 'SOR Assign Alert',
                    text: 'This personnel has been attached with ' + ssCount + ' SOR item. If you remove, all sor history will be gone also',
                    icon: 'warning',
                    buttons: ['Cancel', 'Remove'],
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
                      THIS.param.sor = ssSor;
                    } else {
                      (<any>$('.chosen-default-multiple-per')).val(THIS.param.personnel_id);
                      (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
                    }
                  });
                } else {
                  THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
                }
              }
            }
            THIS.updateChangesDiscard();
          });
          THIS.SA_person = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  jobStatusList = [];
  jobStatusDefault = [];
  jobStatusCustom = [];

  getAllJobStatus(refresh) {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-status?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.jobStatusDefault.length = 0;
          THIS.jobStatusCustom.length = 0;
          THIS.jobStatusList = res.data;
          localStorage._jsts = JSON.stringify(res.data);
          $.each(res.data, function (i, v) {
            if (v.type == 0) {
              THIS.jobStatusDefault.push(v);
            } else {
              THIS.jobStatusCustom.push(v);
            }
          });
        }
        setTimeout(function () {
          if ($('.reRenderJobStatus').length > 0) {
            const status_code = parseInt($('.reRenderJobStatus').attr('data-id'));
            if (status_code > 0) {
              THIS.param.status_code = status_code;
            }
          }
          (<any>$('.chosen-default-single-status')).chosen('destroy');
          (<any>$('.chosen-default-single-status')).val(THIS.param.status_code);
          (<any>$('.chosen-default-single-status')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select job status'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#jobStatusModal')).modal('show');
            } else {
              THIS.param.status_code = e.target.value;
              THIS.updateChangesDiscard();
            }
          });
          THIS.SA_status = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  invPersonnels = {
    org: [],
    person: []
  };

  getAllSupplier() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.invPersonnels.org = res.org;
          THIS.invPersonnels.person = res.person;
        }
        setTimeout(function () {
          (<any>$('.chosen-default-single-supplier')).chosen('destroy');
          (<any>$('.chosen-default-single-supplier')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Supplier'
          }).on('change', function (e, p) {
            const v = e.target.value;
            THIS.incomingCreate.personnel = v;
            THIS.incomingCreate.groups = '';
          });
        }, 500);
      }
    });
  }

  siModalOpen() {
    this.getAllSupplier();

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#SIModal')).modal('show');
  }

  closeWorkOrderModalOpen() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#closeWorkOrderModal')).modal('show');
  }

  openWorkOrderModalOpen() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#openWorkOrderModal')).modal('show');
  }

  workFilterOption = [
    {title: 'New'},
    {title: 'Awaiting Approval'},
    {title: 'In Progress'},
    {title: 'Completed'},
    {title: 'Cancelled'}
  ];
  inventory = [
    {
      item: 'Light Globe R1',
      qty: 1,
      each: 10,
      total: 10
    },
    {
      item: 'Labour',
      qty: 1,
      each: 100,
      total: 100
    }
  ];

  selectedWorkAttach = [];
  selectedFiles = [];
  selectedFilesPreview = [];

  uploadThisMedia(file, media_type, localtion, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  removeThisMedia(id, media_type) {
    const param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
      this.timeLineCommentCheck();
    });
  }

  dragFileOverStart() {
    const trigger = $('#uploadDragWork');
    trigger.addClass('onEnter');
  }

  dragFileOverEnd() {
    const trigger = $('#uploadDragWork');
    trigger.removeClass('onEnter');
  }

  _uploadError = 0;
  _locPermit = 0;

  dragFilesDropped(e) {
    e.preventDefault();
    this._uploadError = 0;
    const THIS = this;
    if (e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        if (this.selectedFiles.length < 3) {
          const eachFile = e.dataTransfer.files[i];
          this.selectedFiles.push(eachFile);
          if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            if (THIS.currentUser.modules.geo_tagged_photo == 1) {
              navigator.geolocation.getCurrentPosition(function (position) {
                if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                  THIS._locPermit = 1;
                  const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                  THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please trash some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  THIS._locPermit = -1;
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please trash some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                }
              }, function (error) {
                swal({
                  title: 'Share Geo Location',
                  text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                  icon: 'warning',
                  buttons: ['Want to share location', 'Don\'t want to share Location'],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                      if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                          swal({
                            title: 'Storage Full',
                            text: 'Your Storage is full. Please trash some files',
                            icon: 'error',
                            dangerMode: true,
                          });
                        } else {
                          swal({
                            title: 'Invalid file format uploaded',
                            text: 'Please upload an image in Jpeg or Png only.',
                            icon: 'error',
                            dangerMode: true,
                          });
                        }
                      } else {
                        THIS.selectedFilesPreview.push(uploaded);
                      }
                    });
                  } else {
                    swal('If you want to share location then change the browser settings and remove the block status');
                  }
                });
              });
            } else {
              THIS._locPermit = -1;
              THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                if (uploaded.status != undefined) {
                  if (uploaded.status == 9000) {
                    swal({
                      title: 'Storage Full',
                      text: 'Your Storage is full. Please trash some files',
                      icon: 'error',
                      dangerMode: true,
                    });
                  } else {
                    swal({
                      title: 'Invalid file format uploaded',
                      text: 'Please upload an image in Jpeg or Png only.',
                      icon: 'error',
                      dangerMode: true,
                    });
                  }
                } else {
                  THIS.selectedFilesPreview.push(uploaded);
                }
              });
            }
          } else {
            this._uploadError = 1;
          }
        }
      }
      // this.uploaderPlate.preview = 1;
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedFiles.length < 3) {
        const eachFile = accepted[i];
        this.selectedFiles.push(eachFile);
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                THIS._locPermit = 1;
                const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please trash some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              } else {
                THIS._locPermit = -1;
                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please trash some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              }
            }, function (error) {
              swal({
                title: 'Share Geo Location',
                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                icon: 'warning',
                buttons: ['Want to share location', 'Don\'t want to share Location'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please trash some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  swal('If you want to share location then change the browser settings and remove the block status');
                }
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please trash some files',
                    icon: 'error',
                    dangerMode: true,
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
              }
            });
          }
        } else {
          this._uploadError = 1;
        }
      }
    }
    // this.uploaderPlate.preview = 1;
  }

  uploadThisComentMedia(file, media_type, localtion, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  upReq = 0;

  selectTheseCommentImg(e) {
    const THIS = this;
    if (this.upReq > 5) {
      return false;
    }
    this.upReq++;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    e.preventDefault();
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      const eachFile = accepted[i];
      if (THIS.currentUser.modules.geo_tagged_photo == 1) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            THIS._locPermit = 1;
            const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
            THIS.uploadThisComentMedia(eachFile, '1', localtion, function (uploaded) {
              THIS.commentAttachArr.push({id: uploaded.id, file_path: uploaded.file_path, width: uploaded.width, height: uploaded.height});
              THIS.timeLineCommentCheck();
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
              THIS.commentAttachArr.push({id: uploaded.id, file_path: uploaded.file_path, width: uploaded.width, height: uploaded.height});
              THIS.timeLineCommentCheck();
            });
          }
        }, function (error) {
          swal({
            title: 'Share Geo Location',
            text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
            icon: 'warning',
            buttons: ['Want to share location', 'Don\'t want to share Location'],
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
                THIS.commentAttachArr.push({
                  id: uploaded.id,
                  file_path: uploaded.file_path,
                  width: uploaded.width,
                  height: uploaded.height
                });
                THIS.timeLineCommentCheck();
              });
            } else {
              swal('If you want to share location then change the browser settings and remove the block status');
            }
          });
        });
      } else {
        THIS._locPermit = -1;
        THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
          THIS.commentAttachArr.push({id: uploaded.id, file_path: uploaded.file_path, width: uploaded.width, height: uploaded.height});
          THIS.timeLineCommentCheck();
        });
      }
    }
  }

  upReqEdit = 0;

  selectTheseEditCommentImg(e) {
    const THIS = this;
    if (this.upReqEdit > 5) {
      return false;
    }
    this.upReqEdit++;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    e.preventDefault();
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      const eachFile = accepted[i];
      if (THIS.currentUser.modules.geo_tagged_photo == 1) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            THIS._locPermit = 1;
            const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
            THIS.uploadThisComentMedia(eachFile, '1', localtion, function (uploaded) {
              THIS.commentAttachEditArr.push(uploaded);
              THIS.timeLineCommentCheck();
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
              THIS.commentAttachEditArr.push(uploaded);
              THIS.timeLineCommentCheck();
            });
          }
        }, function (error) {
          swal({
            title: 'Share Geo Location',
            text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
            icon: 'warning',
            buttons: ['Want to share location', 'Don\'t want to share Location'],
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
                THIS.commentAttachEditArr.push(uploaded);
                THIS.timeLineCommentCheck();
              });
            } else {
              swal('If you want to share location then change the browser settings and remove the block status');
            }
          });
        });
      } else {
        THIS._locPermit = -1;
        THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
          THIS.commentAttachEditArr.push(uploaded);
          THIS.timeLineCommentCheck();
        });
      }
    }
  }

  removeThisImage(index, id) {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesPreview.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  addWorkMedia() {
    if (this.commentAttachArr.length < 6) {
      $('#workMediaInput').click();
    }
  }

  addCommentMedia() {
    if (this.commentAttachArr.length < 6) {
      $('#attachImgComment').click();
    }
  }

  editCommentMedia() {
    $('#attachImgEditComment').click();
  }

  closeOrderStatus: any = '';
  closeOrderId: any = '';


  updateThisWorkStatusNow(status, id) {
    const ldd = document.getElementById('statusUpdate');
    const ladda = Ladda.create(ldd);
    const THIS = this;
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/update/' + id + '?token=' + THIS.currentUser.token,
      data: {status_code: status.id},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.workDetails.status_code = res.data.status_code;
        THIS.statusUpdate = 1;
        THIS.tmlThis = 1;
        setTimeout(function () {
          THIS.statusUpdate = 0;
          THIS.tmlThis = 0;
        }, 1000);
        // Pusher emit
        THIS.refreshThisWo();
      }
    });
  }

  updateThisWorkStatusModal(status, id, type) {
    if (type == 'close') {
      this.closeWorkOrderModalOpen();
    }
    if (type == 'open') {
      this.openWorkOrderModalOpen();
    }
    this.closeOrderStatus = status;
    this.closeOrderId = id;
  }

  confirmUpdateWorkOrder() {
    this.workDetails.status_code = this.closeOrderStatus;
    const data = {
      status: this.closeOrderStatus.id,
      id: this.closeOrderId,
    };
    this.updateThisWorkStatusNow(this.workDetails.status_code, this.workDetails.id);
    this.statusChange.emit(data);
    (<any>$('#closeWorkOrderModal')).modal('hide');
    (<any>$('#openWorkOrderModal')).modal('hide');
  }

  makeServiceUpdate() {
    const THIS = this;
    const ldd = document.getElementById('serviceStatus');
    const ladda = Ladda.create(ldd);
    const param = {id: this.workDetails.id};
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/service/status/update?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        ladda.stop();
        if (res.status == 2000) {
          const param = {uid: THIS.workDetails.uid};
          THIS.refreshsinglePage(param);
          THIS.QuickList.emit(1);
        }
      }
    });
  }

  refreshThisWo() {
    this.refreshThis.emit(this.workDetails.uid);
  }

  archiveThisWorkOrder() {
    this.archiveThis.emit(this.workDetails.id);
    (<any>$('#ArchiveModal')).modal('hide');
    toastr.success('Job has been successfully archived', 'Archive Job', this.toastrOption);
    setTimeout(() => {
      this.confirmDiscard();
    }, 500);
  }

  archiveThisWork(type: any) {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    if (type == 1) {
      (<any>$('#ArchiveModal')).modal('show');
    } else {
      (<any>$('#ArchiveModal')).modal('hide');
    }
  }

  activeThisWorkOrder() {
    this.activeThis.emit(this.workDetails.id);
    setTimeout(() => {
      this.confirmDiscard();
    }, 500);
  }

  removeThisWorkOrder() {
    this.removeThis.emit(this.workDetails.id);
    setTimeout(() => {
      this.confirmDiscard();
    }, 500);
  }

  unarchiveThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#UnarchiveModal')).modal('show');
  }

  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }

  duplicateThisWorkOrder() {
    this.duplicateThis.emit(this.workDetails.id);
  }

  currentIDUpdate() {
    this.currentID.emit(this.workDetails.id);
  }

  workToInvoice() {
    this.invoiceThis.emit(this.workDetails);
  }

  workToRCTI() {
    this.rctiThis.emit(this.workDetails);
  }

  allProjects = [];

  addToProject() {
    const THIS = this;
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#addToProjectModal')).modal('show');
    $.ajax({
      url: THIS.APPCONFIG.api + 'projects/list/all/minimum?token=' + THIS.currentUser.token,
      type: 'post',
      data: {},
      success: function (res) {
        if (res.status === 2000) {
          THIS.allProjects = res.data;
          setTimeout(function () {
            (<any>$('.chosen-default-single-project')).chosen('destroy');
            (<any>$('.chosen-default-single-project')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: ''
            }).on('change', function (e, p) {
              const v = e.target.value;
              THIS.param.project_id = v;
            });
          }, 500);
        }
      }
    });
  }

  qckAddToProject() {
    const THIS = this;
    (<any>$('#addToProjectModal')).modal('hide');
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/update/' + THIS.id + '?token=' + THIS.currentUser.token,
      type: 'post',
      data: THIS.param,
      success: function (res) {
        THIS._updatingInfo = 0;
        if (res.status != undefined) {
          if (res.status == 7000) {
          }
        } else {
          THIS.refreshsinglePage({uid: THIS.workDetails.uid});
          toastr.success('Add to project has been done', 'Add to project', THIS.toastrOption);
        }
        THIS.refreshThisWo();
      }
    });
  }

  discardNewInvoice() {
    this.discard.emit(1);
  }

  printActive = 0;

  printThisWorkOrder() {
    const w = window.open();
    w.document.write($('#export').html());
    w.print();
    w.close();
  }

  pdfActive = 0;

  pdfThisWorkOrder() {
    $('#exportPDFTrigger').click();
  }

  zipThisWorkOrder() {
    const THIS = this;
    const win = window.open(THIS.APPCONFIG.api + 'work-orders/zip/image/' + this.workDetails.id + '?token=' + THIS.currentUser.token, '_blank');
    win.focus();
  }

  mailThisWo() {
    const work = this.workDetails;
    const param = {
      id: work.id,
      uid: work.uid,
      team: this.currentUser.team,
      short_code: work.short_code,
      subject: work.subject,
    };
    $('#sendMailModalTrigger').attr('data-type', JSON.stringify(param));
    $('#sendMailModalTrigger').click();
  }

  // ========================
  // workToInvoice
  // ========================


  //====================
  // ___panel_expand
  //====================
  id = 0;
  param = {
    project_id: 0,
    prefix: 'w',
    work_order_id: '',
    subject: '',
    description: '',
    media_files: '',
    attach_files: '',
    facility_id: '',
    contact_id: 0,
    asset_id: '',
    job_types_id: 0,
    due: '',
    created_at: '',
    add_to_calendar: 0,
    calendar_color: '',
    priority: '0',
    status_code: 1,
    personnel: '4',
    personnel_id: [],
    group_id: [],
    personnel_group: '',
    attachInventories: [],
    taskType: 1,
    oldTasks: [],
    tasks: [],
    incoming: [],
    sor: []
  };
  _panel_expand = 0;
  _panel_clear = 0;
  _panel_clear_now = 0;
  error = {
    facility: 0,
    status_code: 0,
    personnel: 0,
    contact: 0,
    jobType: 0,
    priority: 0,
  };

  didThisSave() {
    this.saveWorkOrderSubmit('');
  }

  qckUpdate() {
    const THIS = this;
    this.param.incoming = this.incoming;
    this.param.attachInventories = this.attachInventories;
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    this.param.attach_files = mediaAttach;
    this.param.subject = this.workDetails.subject;
    console.log(this.param, this.incoming);
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/update/' + THIS.id + '?token=' + THIS.currentUser.token,
      type: 'post',
      data: THIS.param,
      success: function (res) {
        THIS._updatingInfo = 0;
        if (res.status != undefined) {
          if (res.status == 7000) {
          }
        } else {
          toastr.success('Job has been successfully updated', 'Update Job', THIS.toastrOption);
        }
        THIS.refreshThisWo();
      }
    });
  }

  clearValidation(e) {
    const trigger = $(e.target);
    const target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  validForm = 1;
  _updatingInfo = 0;

  validationThisForm() {
    let valid = 1;
    const form = $('#workCreateForm');
    if (this.param.facility_id == '') {
      valid = 0;
      this.error.facility = 1;
    } else {
      this.error.facility = 0;
    }

    // if (this.param.status_code > 0) {
    //     valid = 0;
    //     this.error.status_code = 1;
    // } else {
    //   this.error.status_code = 0;
    // }

    // if (this.param.prefix == '') {
    //     valid = 0;
    // }
    // if (this.param.work_order_id == '') {
    //     valid = 0;
    // }
    if (this.param.subject == '') {
      valid = 0;
      form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
    } else {
      form.find('[data-el="subject"]').removeClass('has-error');
    }
    /*if (this.param.description == '' || this.param.description == null) {
            valid = 0;
            form.find('[data-el="description"]').removeClass('has-error').addClass('has-error');
        } else {
            form.find('[data-el="description"]').removeClass('has-error');
        }*/
    /*if (this.param.contact_id < 1) {
            valid = 0;
            this.error.contact = 1;
        } else {
            this.error.contact = 0;
        }
        if (this.param.job_types_id < 1) {
            valid = 0;
            this.error.jobType = 1;
        } else {
            this.error.jobType = 0;
        }
        if (this.param.priority == '') {
            valid = 0;
            this.error.priority = 1;
        } else {
            this.error.priority = 0;
        }
        if (this.param.personnel_id.length == 0) {
            valid = 0;
            this.error.personnel = 1;
        } else {
            this.error.personnel = 0;
        }*/
    this.validForm = valid;
    // this.param.prefix = this.CustomHelper.makeUpperCase(this.param.prefix);
    return valid;
  }

  saveWorkOrderSubmit(quick) {
    const THIS = this;
    const ldd = document.getElementById('SaveThisLadda');
    const ladda = Ladda.create(ldd);
    let mediaFiles = '';
    $.each(THIS.selectedFilesPreview, function (i, v) {
      if (i == 0) {
        mediaFiles += v.id;
      } else {
        mediaFiles += ',' + v.id;
      }
    });
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    this.param.media_files = mediaFiles;
    this.param.attach_files = mediaAttach;

    this.param.oldTasks = this.workDetails.tasks;

    const woTaskList = $('#woTaskList').find('._eachTask');
    // this.param['id'] = this.taskType;
    this.param['taskType'] = this.taskType;
    // this.param.tasks.length = 0;
    this.param.tasks = this.taskList;
    this.param.incoming = this.incoming;
    this.param.add_to_calendar = this.add2Calendar;
    this.param.calendar_color = this.calendarStatus;
    this.param.attachInventories = this.attachInventories;
    this.param.description = (<any>$('.summernote')).summernote('code');

    this.param_created_at = '';
    if (this.param.created_at != null && this.param.created_at != '') {
      this.param.created_at = this.CustomHelper.formatDateTimeToDB(this.param.created_at);
      this.param_created_at = this.CustomHelper.formatDateTime(new Date(this.param.created_at));
    }
    this.param_due = '';
    if (this.param.due != null && this.param.due != '') {
      this.param.due = this.param.due != null && this.param.due != '' ? this.CustomHelper.formatDateTimeToDB(this.param.due) : '';
      this.param_due = this.CustomHelper.formatDateTime(new Date(this.param.due));
    }
    /*woTaskList.each(function(i, v){
            let tsk = $(v).val();
            THIS.param.tasks.push(tsk);
        });*/

    const valid = this.validationThisForm();
    if (valid == 1) {
      ladda.start();
      this._updatingInfo = 1;
      $.ajax({
        url: THIS.APPCONFIG.api + 'work-orders/update/' + THIS.id + '?token=' + THIS.currentUser.token,
        type: 'post',
        data: THIS.param,
        success: function (res) {
          ladda.stop();
          THIS._updatingInfo = 0;
          if (res.status != undefined) {
            if (res.status == 7000) {

            }
          } else {
            THIS.CustomHelper.woRouteDiscard = 0;
            if (quick !== 'quick') {
              const work = res.data;
              THIS.workDetails = res.data;
              THIS.CustomHelper.woRouteDiscard = 0;
              const index = THIS.CustomHelper.ConfRoute.map(function (e) {
                return e.tab;
              }).indexOf(THIS.CustomHelper.ConfCurRoute);
              if (index > -1) {
                THIS.CustomHelper.ConfRoute.splice(index, 1);
              }
              THIS.workDetails.dates.creation_date = THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.creation_date);
              THIS.workDetails.dates.edited_date = THIS.workDetails.dates.edited_date != '' && THIS.workDetails.dates.edited_date != null ? THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.edited_date) : '';
              THIS.workDetails.facStr = THIS.workDetails.facStr.reverse();
              //
              if (work.dates.created_at != null) {
                THIS.param.created_at = THIS.CustomHelper.formatDateTimeToDB(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
                THIS.param.created_at = THIS.CustomHelper.formatDateTime(new Date(THIS.param.created_at));
                THIS.dateCreated = new Date(THIS.CustomHelper.invToDate(THIS.param.created_at));
                THIS.dateCreatedOption.defaultDate = THIS.dateCreated;
                THIS.dateDueOption.minDate = THIS.dateCreated;
              }
              //
              if (work.dates.due_at != null) {
                THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(work.dates.due_at);
                THIS.param.due = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
                THIS.dateDue = new Date(THIS.CustomHelper.invToDate(THIS.param.due));
                THIS.dateDueOption.defaultDate = THIS.dateDue;
              }
              THIS.quickSinglePreview();

            }
            toastr.success('Job has been successfully updated', 'Update Job', THIS.toastrOption);
            THIS.taskList.length = 0;
            // Pusher emit
          }
          THIS.refreshThisWo();
          THIS.refreshsinglePage({uid: THIS.workId});
        }
      });
    }
  }

  updateChangesDiscard() {
    this.param.subject = (<any>$('#woSubject')).val();
    const THIS = this;
    const index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      this.CustomHelper.ConfRoute[index].change = 1;
    } else {
      this.CustomHelper.ConfRoute.push({tab: this.CustomHelper.ConfCurRoute, change: 1});
    }
  }

  timeline = {
    work_id: 0,
    comment: '',
  };
  cTvalid = 0;

  timeLineCommentCheck() {
    const c = this.timeline.comment;
    const cR = $.trim(c);
    if (cR.length > 0 || this.commentAttachArr.length > 0) {
      this.cTvalid = 1;
    } else {
      this.cTvalid = 0;
    }
  }

  auto_grow() {
    const target = $('._module_comment_editor_text');
    const element = target[0];
    element.style.height = '5px';
    element.style.height = (element.scrollHeight) + 'px';
  }

  auto_grow_sloq() {
    const THIS = this;
    setTimeout(function () {
      THIS.auto_grow();
    }, 500);
  }

  newComment() {
    const THIS = this;
    this.cTvalid = 0;
    const comV = this.timeline.comment;
    const com = comV.replace(/<p><br><\/p>/gi, '\n\r');
    const domCom = $('<div>' + com + '</div>');
    let comment = domCom.text().trim();
    let type = '';
    if (this.commentAttachArr.length > 0) {
      const msg = {
        comment: comment.trim(),
        image: this.commentAttachArr
      };
      type = 'attach';
      comment = JSON.stringify(msg);
    } else {
      if (domCom.text() == '') {
        return true;
      }
    }
    const param = {
      work_id: this.id,
      comment: comment,
      type: type
    };
    this.timeline = {
      work_id: 0,
      comment: '',
    };
    $('._module_comment_editor_text').val('');
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/timeline?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.commentAttachArr.length = 0;
        if (res.status === 2000) {
          THIS.upReq = 0;
          res.data['target_pn_type'] = 'wo_comment_' + THIS.workDetails.id;
          // Pusher emit
          THIS.timeline = {
            work_id: 0,
            comment: '',
          };
          setTimeout(function () {
            THIS.auto_grow();
          }, 1000);
        }
        THIS.timeLineCommentCheck();
      }
    });
  }

  onEnterEffect(e) {
    if (e.which == 13 && (!e.shiftKey && !e.ctrlKey)) {
      e.preventDefault();
    }
    this.auto_grow();
  }

  onEnterCreate(e) {
    this.auto_grow();
    this.timeLineCommentCheck();
    if (e.which == 13 && (!e.shiftKey && !e.ctrlKey)) {
      e.preventDefault();
      this.newComment();
    }
  }

  CommentCreate(e) {
    this.auto_grow();
    this.timeLineCommentCheck();
    e.preventDefault();
    this.newComment();
  }

  timeLineList = [];
  tmlInterval: any;
  tmlPage = 1;
  tmlFetch = 0;
  timeline_Type = 1;

  getTimeLine() {
    const THIS = this;
    if (this.tmlFetch == 0) {
      if (this.id > 0) {
        this.tmlFetch = 1;
        THIS.timeLineList = [];
        $.ajax({
          url: THIS.APPCONFIG.api + 'work-orders/timeline/' + THIS.id + '?token=' + THIS.currentUser.token,
          data: {pageNo: THIS.tmlPage, type: THIS.timeline_Type},
          type: 'post',
          success: function (res) {
            if (res.status == 2000) {
              const tml = res.data.reverse();
              let dateStr = '';
              $.each(tml, function (i, v) {
                try {
                  const com = JSON.parse(v.attrs.comment);
                  v['commentT'] = com.comment;
                  v['images'] = com.image;
                } catch (e) {
                }
                const albumT = [];
                if (v.images !== undefined && v.images.length) {
                  for (let i = 0; i < v.images.length; i++) {
                    const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + v.images[i].file_path;
                    const caption = '';
                    const thumb = '';
                    const album = {
                      src: src,
                      caption: caption,
                      thumb: thumb,
                      w: v.images[i].width !== undefined ? v.images[i].width : 1500,
                      h: v.images[i].height !== undefined ? v.images[i].height : 1500
                    };
                    albumT.push(album);
                  }
                }
                v['album'] = albumT;
                v['created_at_human'] = THIS.CustomHelper.formatDateTime(v.created_at);
                v['updated_at_human'] = THIS.CustomHelper.formatDateTime(v.updated_at);
                if (v.module_id == THIS.id) {
                  v['dateStr'] = 0;
                  if (v.created_at_human_format !== dateStr) {
                    dateStr = v.created_at_human_format;
                    v['dateStr'] = 1;
                  }
                  // if(this.tmlPage == 1){
                  THIS.timeLineList.push(v);
                  // } else {
                  //     THIS.timeLineList.splice(0,0,v);
                  // }
                }
              });
              if (this.tmlPage == 1) {
                setTimeout(function () {
                  const tarComP = $('.Comment-Section-Train-way');
                  if (tarComP.length > 0) {
                    tarComP[0].scrollTop = tarComP[0].scrollHeight;
                  }
                }, 500);
              } else {
                setTimeout(function () {
                  const tarComP = $('.Comment-Section-Train-way');
                  if (tarComP.length > 0) {
                    tarComP[0].scrollTop = 100;
                  }
                }, 500);
              }
            }
            if (res.data.length == 0) {
              THIS.tmlPage = 0;
            }
            THIS.tmlFetch = 0;
          }
        });
      }
    }
  }

  scrollTmlTop(e) {
    /*if(e.target.scrollTop < 20){
            if(this.tmlFetch == 0 && this.tmlPage > 0){
                this.tmlPage = this.tmlPage + 1;
                this.getTimeLine();
            }
        }*/
  }

  removeTimelineId = 0;

  openRemoveComment() {
    const THIS = this;
    // swal({
    //     title: "Comment Delete",
    //     text: "Are you sure you want to delete this comment?",
    //     icon: "warning",
    //     buttons: ['Cancel', 'Delete'],
    //     dangerMode: true,
    // }).then((willDelete) => {
    //     if (willDelete) {
    //         if (THIS.removeTimelineId > 0) {
    //             let param = {id: THIS.removeTimelineId, work_id: THIS.id};
    //             $.ajax({
    //                 url: THIS.APPCONFIG.api + 'work-orders/timeline/remove?token=' + THIS.currentUser.token,
    //                 data: param,
    //                 type: 'post',
    //                 success: function (res) {
    //                     swal.stopLoading();
    //                     swal.close();
    //                     // Pusher emit
    //                 }
    //             });
    //         } else {
    //             swal("You didn't select any comment");
    //         }
    //     } else {
    //         swal.stopLoading();
    //         swal.close();
    //     }
    // });
    if (THIS.removeTimelineId > 0) {
      const param = {id: THIS.removeTimelineId, work_id: THIS.id};
      $.ajax({
        url: THIS.APPCONFIG.api + 'work-orders/timeline/remove?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          (<any>$('#deleteCommentModal')).modal('hide');
          // Pusher emit
        }
      });
    }
  }

  deleteThisComment() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteCommentModal')).modal('show');
  }

  editLogCommentId = 0;
  editLogCommentParam = {id: 0, comment: '', work_id: 0};
  cNTvalid = 1;

  timeLineNewCommentCheck() {
    const c = this.editLogCommentParam.comment;
    const cR = $.trim(c);
    if (cR.length > 0) {
      this.cNTvalid = 1;
    } else {
      this.cNTvalid = 0;
    }
    const tarCom = $('.ql-container');
    if (tarCom.length > 0) {
      tarCom[0].scrollTop = tarCom[0].scrollHeight;
    }
  }

  newEditComment() {
    const THIS = this;
    let comment = this.editLogCommentParam.comment.trim();
    let type = '';
    if (this.commentAttachEditArr.length > 0) {
      const msg = {
        comment: comment.trim(),
        image: this.commentAttachEditArr
      };
      type = 'attach';
      comment = JSON.stringify(msg);
    }
    const param = {
      id: this.editLogCommentId,
      work_id: this.id,
      comment: comment,
      type: type
    };
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/timeline/edit?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          THIS.upReqEdit = 0;
          res.data['target_pn_type'] = 'wo_comment_upd_' + THIS.workDetails.id;
          // Pusher emit
          THIS.editLogCommentId = 0;
          THIS.editLogCommentParam = {
            id: 0,
            comment: '',
            work_id: 0
          };
          THIS.cNTvalid = 1;
          THIS.commentAttachEditArr.length = 0;
        }
      }
    });
  }

  commentAttachArr = [];
  commentAttachEditArr = [];
  commentAttach = '';

  removeThisCommentImage(index, id) {
    this.commentAttachArr.splice(index, 1);
    this.upReq--;
    this.removeThisMedia(id, 1);
  }

  removeThisCommentEditImage(index, id) {
    this.commentAttachEditArr.splice(index, 1);
    this.upReqEdit--;
    this.removeThisMedia(id, 1);
  }

  checkEditImageList(log) {
    const THIS = this;
    this.commentAttachEditArr.length = 0;
    this.upReqEdit = 0;
    if (log.images != undefined) {
      this.commentAttachEditArr = log.images;
      this.upReqEdit = this.commentAttachEditArr.length;
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  emojiList = [];
  emojiListCurrentIndex = 0;
  emojiListCurrent = [];

  getEmoji() {
    this.emojiListCurrentIndex = 0;
    this.mediaService.getEmoji().subscribe(res => {
      if (res.status == 2000) {
        this.emojiList = res.data;
        this.emojiListCurrent = this.emojiList[this.emojiListCurrentIndex];
      }
    });
  }

  writeEmoji(sign) {
    this.timeline.comment = this.timeline.comment + sign + ' ';
    this.timeLineCommentCheck();
  }

  writeEmojiEdit(sign) {
    this.editLogCommentParam.comment = this.editLogCommentParam.comment + sign + ' ';
    this.timeLineNewCommentCheck();
  }


  taskType = 1; // 1.Tasks  2.Inspections
  taskCreateSubmit = 0;
  taskList = [];
  taskParam = {tasks: [], work_id: 0};

  taskCreateOnF() {
    this.taskList.push({title: '', type: 1});
    setTimeout(function () {
      const objDiv = document.getElementById('_tsk_list_scroll');
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 500);
    this.updateChangesDiscard();
  }

  taskRemoveParam = {id: 0, work_id: 0};
  removeTask = {submitted: 0, index: -1};

  statusUpdateTask(id, statusP, index) {
    const THIS = this;
    const status = statusP == 1 ? 2 : 1;
    if (id > 0 && status > 0) {
      const param = {id: id, work_id: this.workDetails.id, status: status};
      $.ajax({
        url: THIS.APPCONFIG.api + 'work-orders/task/status?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          if (res.status == 2000) {
            THIS.workDetails.tasks[index].status = status;
          }
          // THIS.QuickList.emit(1);
        }
      });
    }
  }

  rateUpdateTask(id, rate, index) {
    const THIS = this;
    if (id > 0 && rate > 0) {
      const param = {id: id, work_id: this.workDetails.id, rate: rate};
      $.ajax({
        url: THIS.APPCONFIG.api + 'work-orders/task/rate?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          if (res.status == 2000) {
            THIS.workDetails.tasks[index].rate = rate;
          }
          THIS.QuickList.emit(1);
        }
      });
    }
  }

  sortTaskList() {
    const THIS = this;
    const sort = [];
    $.each(THIS.workDetails.tasks, function (i, v) {
      const eachT = {id: v.id, sort_id: (i + 1)};
      sort.push(eachT);
    });
    const param = {work_id: this.workDetails.id, sort: sort};
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/task/sorting?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.QuickList.emit(1);
      }
    });
  }

  sortOptions = {
    onUpdate: function (evt) {
    }
  };


  dateCreated = null;
  changingStart = 0;
  dateCreatedOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: null,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  onCreatedSelect() {
    const THIS = this;
    THIS.param.priority = '0';
    if (this.dateCreated != null) {
      this.param.created_at = this.CustomHelper.formatDateTimeToDB(this.dateCreated);
      this.param.due = '';
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
        THIS.dateDueOption.defaultDate = this.dateDue;
        THIS.changingStart = 0;
      }, 400);
      this.updateChangesDiscard();
    } else {
      this.param.created_at = '';
      this.param.due = '';
      this.add2Calendar = 0;
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date();
        THIS.dateDueOption.defaultDate = null;
        THIS.changingStart = 0;
      }, 400);
    }
  }

  dateDue = null;
  dateDueOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: null,
    minDate: null,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  onDueSelect() {
    const THIS = this;
    this.param.due = this.dateDue != null && this.dateDue != '' ? this.CustomHelper.formatDateTimeToDB(this.dateDue) : '';
    this.param.priority = '0';

  }

  add2Calendar = 0;
  calendarStatus = '_red';

  addToCalendar() {
    if (this.param.created_at != '' && this.param.created_at != null) {
      this.add2Calendar == 1 ? this.add2Calendar = 0 : this.add2Calendar = 1;
      this.updateChangesDiscard();
    } else {
      this.add2Calendar = 0;
      swal('You must have to provide start Date to add this work order to Calendar');
    }
  }


  // ======================
  // Incoming Invoice
  // ======================
  incoming = [];
  incomingCreate = {
    invoice_number: '',
    invoice_amount: '',
    personnel: '',
    groups: '',
    comment: '',
    file_name: []
  };

  filterInvAmt() {
    this.incomingCreate.invoice_amount = this.incomingCreate.invoice_amount.replace(/\-/, '');
  }

  invoiceUploadFileChoose() {
    $('#invoiceUpload').click();
  }

  invoiceUpload(e) {
    e.preventDefault();
    const THIS = this;
    const files = e.target.files;
    $.each(files, function (i, v) {
      THIS.uploadThisMedia(v, '4', null, function (uploaded) {
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please trash some files',
              icon: 'error',
              dangerMode: true,
            });
          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
              icon: 'error',
              dangerMode: true,
            });
          }
        } else {
          THIS.incomingCreate.file_name.push(uploaded);
        }
      });
    });
  }

  createIncomingInvoice() {
    this.incomingCreate.invoice_amount = ((<any>$('#siCrt')).find('input[name="invoice_amount"]').val()).replace(/\$/, '');
    if (this.incomingCreate.invoice_number != '' && this.incomingCreate.invoice_amount != '') {
      this.incoming.push(this.incomingCreate);
      this.incomingCreate = {
        invoice_number: '',
        invoice_amount: '',
        personnel: '',
        groups: '',
        comment: '',
        file_name: []
      };
      ((<any>$('#siCrt')).find('input[name="invoice_amount"]').val(''));
    }
    (<any>$('#SIModal')).modal('hide');
    if (this.inlinePrefixId == 0) {
      this.qckUpdate();
    }
  }


  shortLinkWo = null;
  shortLinkShared = 0;
  shared_edit = 0;
  shortLinkUrl = '';
  shortLinkUrlCopied = 0;

  generateShortLink() {
    this.shortLinkUrl = '';
    const work = this.workDetails;
    const Session = JSON.parse(localStorage.currentUserInfo);
    this.shortLinkWo = work;
    this.shortLinkShared = work.shared != undefined ? work.shared : 0;
    this.shared_edit = work.shared_edit != undefined ? work.shared_edit : 0;
    this.shortLinkUrl = this.APPCONFIG.shortLink + '/' + Session.team + '/w/' + work.short_code;

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#shortLinkSingleModal')).modal('show');
  }

  shareViaWhatsapp() {
    // if (navigator.canShare && navigator.canShare({ files: [] })) {
    //   navigator.share({
    //     files: [],
    //     title: 'Vacation Pictures',
    //     text: 'Photos from September 27 to October 14.',
    //     url: 'whatsapp://send'
    //   })
    //   .then(() => console.log('Share was successful.'))
    //   .catch((error) => console.log('Sharing failed', error));
    // } else {
    //   console.log(`Your system doesn't support sharing files.`);
    // }
  }

  allowCopyClip() {
    const THIS = this;
    THIS.shortLinkShared == 1 ? THIS.shortLinkShared = 0 : THIS.shortLinkShared = 1;
    THIS.workDetails['shared'] = THIS.shortLinkShared;
    if (THIS.shortLinkShared == 0) {
      THIS.shared_edit = 0;
    }
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/' + THIS.shortLinkWo.id + '/shared?token=' + THIS.currentUser.token,
      data: {shared_edit: THIS.shared_edit, shared: THIS.shortLinkShared},
      type: 'post',
      success: function (res) {
      }
    });
  }

  allowEditCopyClip() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/' + THIS.shortLinkWo.id + '/shared?token=' + THIS.currentUser.token,
      data: {shared_edit: THIS.shared_edit, shared: THIS.shortLinkShared},
      type: 'post',
      success: function (res) {
      }
    });
  }

  copyToClipboard() {
    const copyTextarea = $('#shortLinkUrlSingle');
    copyTextarea.focus();
    copyTextarea.select();
    try {
      document.execCommand('copy');
      this.shortLinkUrlCopied = 1;
    } catch (err) {
      this.shortLinkUrlCopied = -1;
    }
  }


  SelectedPersonList = [];
  attachInventories = [];

  inventoryOpen(): void {
    const THIS = this;

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#invLabModal')).modal('show');
    (<any>$('.invFacilitySelector')).val('');

    THIS.SelectedPersonList.length = 0;
    $.each(THIS.param.personnel_id, function (i, v) {
      const index = THIS.Personnel.person.map(function (element) {
        return element.id;
      }).indexOf(parseInt(v));
      if (index > -1) {
        THIS.SelectedPersonList.push(THIS.Personnel.person[index]);
      }
    });
    THIS.LabourData = THIS.SelectedPersonList;
    THIS.InvLabData.length = 0;
    THIS.InvLabDataTotal = 0;
    THIS.InvLabEmpty = 0;

    setTimeout(function () {
      (<any>$('.invFacilitySelector')).chosen('destroy');
      // (<any>$('.invFacilitySelector')).val(THIS.param.facility_id);
      (<any>$('.invFacilitySelector')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Facility'
      }).on('change', function (e, p) {
        if (p != undefined) {
          THIS.getAllInventory(p.selected);
          THIS.recentFacility(p.selected);
        }
      });

      /*if (THIS.param.facility_id != '') {
                THIS.getAllInventory(THIS.param.facility_id);
            }*/
    }, 1000);
  }

  recentFacility(facility_id) {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/recent/use?token=' + THIS.currentUser.token,
      type: 'post',
      data: {facility_id: facility_id},
      success: function (res) {
      }
    });
  }

  InvLabData = [];
  LabourData = [];
  InvLabDataTotal = 0;
  InvLabEmpty = 0;

  getAllInventory(facility_id) {
    const THIS = this;
    this.InvLabData.length = 0;
    this.InvLabDataTotal = 0;
    THIS.InvLabEmpty = 0;
    const param = {
      facility_id: facility_id
    };
    $.ajax({
      url: THIS.APPCONFIG.api + 'inventory/getAll?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        if (res.status == 2000) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]['pick'] = '';
            // let index = THIS.attachInventories.map(function (element) {
            //     return element.inv_id;
            // }).indexOf(res.data[i]['id']);
            // if (index > -1) {
            //     if (THIS.attachInventories[index].type_id == 0) {
            //         res.data[i]['pick'] = THIS.attachInventories[index].pick;
            //     }
            // }
          }
          THIS.InvLabData = res.data;
          THIS.InvLabDataTotal = res.data.length;
        }
        if (res.data.length == 0) {
          THIS.InvLabEmpty = 1;
        }
      }
    });
  }

  updateInventory() {
    const THIS = this;
    // this.attachInventories.length = 0;
    const attachInventories = this.attachInventories;
    $.each(this.InvLabData, function (i, v) {
      if (v.pick != '' && v.sell_price != '' && v.pick != null && v.sell_price != null) {
        const invIndex = attachInventories.map(function (e) {
          return e.inv_id;
        }).indexOf(v.id);
        if (invIndex > -1) {
          attachInventories[invIndex].retail_price = v.sell_price;
          attachInventories[invIndex].pick = attachInventories[invIndex].pick + v.pick;
        } else {
          attachInventories.push({
            type_id: 0,
            inv_id: v.id,
            title: v.title,
            retail_price: v.sell_price,
            pick: v.pick,
          });
        }
      }
    });
    $.each(this.LabourData, function (i, v) {
      if (v.pick != '' && v.rate != '' && v.pick != null && v.rate != null) {
        attachInventories.push({
          type_id: 1,
          inv_id: v.id,
          title: v.name,
          retail_price: v.rate,
          pick: v.pick,
        });
      }
    });
    this.attachInventories = attachInventories;
    (<any>$('#invLabModal')).modal('hide');
    if (this.inlinePrefixId == 0) {
      this.qckUpdate();
    }
  }


  addWorkAttach() {
    $('#workAttach').click();
  }

  removeThisAttach(index, id) {
    this.selectedWorkAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        const eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please trash some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
          }
        });
      }
    }
    setTimeout(function () {
      if (THIS.inlinePrefixId == 0) {
        THIS.qckUpdate();
      }
    }, 5000);
  }

  progress = 0;
  rightSecPreview = 0;


  SaveAction = 0;
  SA_fac = 0;
  SA_status = 0;
  SA_type = 0;
  SA_pr = 0;
  SA_asset = 0;
  SA_person = 0;

  checkSaveAction() {
    if (this.SA_fac == 1 && this.SA_status == 1 && this.SA_type == 1 && this.SA_pr == 1 && this.SA_asset == 1 && this.SA_person == 1) {
      this.SaveAction = 1;
    } else {
      this.SaveAction = 0;
    }
  }


  // ==============================
  // Presence
  // ==============================
  __singlepreview() {
    const THIS = this;
    THIS.CustomHelper.woPresence = THIS.id;
  }

  __editpreview() {
    const THIS = this;
    THIS.CustomHelper.woPresence = THIS.id;
  }

  quickSinglePreview() {
    const THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    const index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (this.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Discard Alert',
        text: 'Are you sure you want to discard?',
        icon: 'warning',
        buttons: ['Stay on this page', 'Leave page'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          THIS.inlinePrefixId = 0;
          setTimeout(function () {
            if ($('.reRenderAssets').length > 0) {
              const AssetSelectId = parseInt($('.reRenderAssets').attr('data-id'));
              if (AssetSelectId > 0) {
                THIS.AssetSelect.push(AssetSelectId);
                THIS.param.asset_id = THIS.AssetSelect.join(',');
                THIS.updateChangesDiscard();
              }
            }
            (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
            (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
            (<any>$('.chosen-default-multiple-asset')).chosen({
              allow_single_deselect: true,
              placeholder_text_multiple: 'No asset selected'
            }).on('change', function (e, p) {
              if (p.selected === '000') {
                let ___selectedTab = 0;
                const tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) {
                  if ($(v).find('a.active').length > 0) {
                    ___selectedTab = i;
                  }
                });
                const child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#assetModal')).modal('show');
              } else {
                if (p.selected != undefined) {
                  THIS.AssetSelect.push(p.selected);
                } else {
                  THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
                }
                THIS.param.asset_id = THIS.AssetSelect.join(',');
                THIS.updateChangesDiscard();
              }
            });
            THIS.SA_asset = 1;
            THIS.checkSaveAction();
          }, 100);
          setTimeout(function () {
            const work = THIS.workDetails;
            if (work.asset.length > 0 && document.getElementById('geo-map-single') != null) {
              let mapStart = 0;
              THIS.CMap = null;
              const latlng = [];
              const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
              let labelIndex = 0;
              const infowindow = [];
              $.each(work.asset, function (i, v) {
                THIS.showMapBtn = 1;
                if (v.geo != '' && v.geo != null) {
                  const geo = JSON.parse(v.geo);
                  if (mapStart == 0) {
                    mapStart = 1;
                    const mapOptions = {
                      zoom: 0,
                      center: new google.maps.LatLng(33.8688, 151.2093),
                      gestureHandling: 'cooperative',
                      mapTypeControl: false,
                      streetViewControl: false,
                      zoomControl: false
                    };
                    THIS.CMap = new google.maps.Map(document.getElementById('geo-map-single'), mapOptions);
                  }
                  if (THIS.CMap != null) {
                    latlng.push(geo.geometry);
                    const marker = new google.maps.Marker({
                      position: geo.geometry,
                      map: THIS.CMap,
                      draggable: false,
                      title: geo.address,
                      label: labels[labelIndex++ % labels.length]
                    });
                    infowindow[i] = new google.maps.InfoWindow({
                      content: `<div class="width-100" style="min-width: 600px"><h4 style="text-align: center">` + v.name + `</h4>
                                                        <div class="width-100">
                                                        <div class="margin-10"></div>
                                                        <div class="margin-10"></div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Bran/Model</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.brand + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Serial Number</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.serial_number + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar"><i class="nav-icon material-icons _theme-color">money</i><strong>Purchase Cost</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.purchase_cost + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="margin-10">
                                                        </div>
                                                        <div class="margin-10">
                                                        </div></div></div>`
                    });
                    marker.addListener('click', function () {
                      $.each(infowindow, function (iw, inf) {
                        if (inf != i) {
                          infowindow[iw].close();
                        }
                      });
                      infowindow[i].open(THIS.CMap, marker);
                    });
                  }
                }
              });
              const latlngbounds = new google.maps.LatLngBounds();
              for (let i = 0; i < latlng.length; i++) {
                latlngbounds.extend(latlng[i]);
              }
              if (THIS.CMap != null) {
                THIS.CMap.fitBounds(latlngbounds);
                new google.maps.Rectangle({
                  bounds: latlngbounds,
                  map: THIS.CMap,
                  fillColor: 'transparent',
                  fillOpacity: 0.1,
                  strokeWeight: 0
                });
              }

            }
          });
          THIS._albums.length = 0;
          if (THIS.workDetails.media_files !== undefined) {
            for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
              const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path + '&ref=' + Date.now();
              const caption = '';
              const thumb = '';
              const album = {
                src: src,
                caption: caption,
                thumb: thumb,
                w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
                h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
              };
              THIS._albums.push(album);
            }
          }
          setTimeout(function () {
            (<any>$('#summerDesc')).html(THIS.param.description);
            (<any>$('#woSubject')).val(THIS.param.subject);
          }, 100);
          THIS.__singlepreview();
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
        }
      });
    } else {
      THIS.inlinePrefixId = 0;
      setTimeout(function () {
        if ($('.reRenderAssets').length > 0) {
          const AssetSelectId = parseInt($('.reRenderAssets').attr('data-id'));
          if (AssetSelectId > 0) {
            THIS.AssetSelect.push(AssetSelectId);
            THIS.param.asset_id = THIS.AssetSelect.join(',');
            THIS.updateChangesDiscard();
          }
        }
        (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
        (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
        (<any>$('.chosen-default-multiple-asset')).chosen({
          allow_single_deselect: true,
          placeholder_text_multiple: 'No asset selected'
        }).on('change', function (e, p) {
          if (p.selected === '000') {
            let ___selectedTab = 0;
            const tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            const child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#assetModal')).modal('show');
          } else {
            if (p.selected != undefined) {
              THIS.AssetSelect.push(p.selected);
            } else {
              THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
            }
            THIS.param.asset_id = THIS.AssetSelect.join(',');
            THIS.updateChangesDiscard();
          }
        });
        THIS.SA_asset = 1;
        THIS.checkSaveAction();
      }, 100);
      setTimeout(function () {
        const work = THIS.workDetails;
        if (work.asset.length > 0 && document.getElementById('geo-map-single') != null) {
          THIS.showMapBtn = 1;
          let mapStart = 0;
          THIS.CMap = null;
          const latlng = [];
          const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
          let labelIndex = 0;
          const infowindow = [];
          $.each(work.asset, function (i, v) {
            if (v.geo != '' && v.geo != null) {
              const geo = JSON.parse(v.geo);
              if (mapStart == 0) {
                mapStart = 1;
                const mapOptions = {
                  zoom: 0,
                  center: new google.maps.LatLng(33.8688, 151.2093),
                  gestureHandling: 'cooperative',
                  mapTypeControl: false,
                  streetViewControl: false,
                  zoomControl: false
                };
                THIS.CMap = new google.maps.Map(document.getElementById('geo-map-single'), mapOptions);
              }
              if (THIS.CMap != null) {
                latlng.push(geo.geometry);
                const marker = new google.maps.Marker({
                  position: geo.geometry,
                  map: THIS.CMap,
                  draggable: false,
                  title: geo.address,
                  label: labels[labelIndex++ % labels.length]
                });
                infowindow[i] = new google.maps.InfoWindow({
                  content: `<div class="width-100" style="min-width: 600px"><h4 style="text-align: center">` + v.name + `</h4>
                                                        <div class="width-100">
                                                        <div class="margin-10"></div>
                                                        <div class="margin-10"></div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Bran/Model</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.brand + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Serial Number</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.serial_number + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar"><i class="nav-icon material-icons _theme-color">money</i><strong>Purchase Cost</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.purchase_cost + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="margin-10">
                                                        </div>
                                                        <div class="margin-10">
                                                        </div></div></div>`
                });
                marker.addListener('click', function () {
                  $.each(infowindow, function (iw, inf) {
                    if (inf != i) {
                      infowindow[iw].close();
                    }
                  });
                  infowindow[i].open(THIS.CMap, marker);
                });
              }
            }
          });
          const latlngbounds = new google.maps.LatLngBounds();
          for (let i = 0; i < latlng.length; i++) {
            latlngbounds.extend(latlng[i]);
          }
          if (THIS.CMap != null) {
            THIS.CMap.fitBounds(latlngbounds);
            new google.maps.Rectangle({
              bounds: latlngbounds,
              map: THIS.CMap,
              fillColor: 'transparent',
              fillOpacity: 0.1,
              strokeWeight: 0
            });
          }

        }
      });
      THIS._albums.length = 0;
      if (THIS.workDetails.media_files !== undefined) {
        for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
          const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path + '&ref=' + Date.now();
          const caption = '';
          const thumb = '';
          const album = {
            src: src,
            caption: caption,
            thumb: thumb,
            w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
            h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
          };
          THIS._albums.push(album);
        }
      }
      setTimeout(function () {
        (<any>$('#summerDesc')).html(THIS.param.description);
        (<any>$('#woSubject')).val(THIS.param.subject);
      }, 100);
      THIS.__singlepreview();
    }
    THIS.getTimeLine();
    // Pusher emit
  }

  quickSingleEdit() {
    const THIS = this;
    this.inlinePrefixId = 1;
    setTimeout(function () {
      if ($('.reRenderFacility').length > 0) {
        const facilityId = parseInt($('.reRenderFacility').attr('data-id'));
        if (facilityId > 0) {
          THIS.param.facility_id = facilityId.toString();
          THIS.asset_get(THIS.param.facility_id);
        }
      }
      (<any>$('.chosen-default-single-fac')).chosen('destroy');
      (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
      (<any>$('.chosen-default-single-fac')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Facility'
      }).on('change', function (e, p) {
        if (e.target.value === '000') {
          let ___selectedTab = 0;
          const tabs = $('#sort-tabs').find('li');
          tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
              ___selectedTab = i;
            }
          });
          const child = ___selectedTab + 1;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          $('#sort-tabs').find('li').find('a').removeClass('active');
          $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
          (<any>$('#createFacilityModal')).modal('show');

        } else {
          THIS.param.facility_id = e.target.value;
          THIS.asset_get(THIS.param.facility_id);
          THIS.validationThisForm();
          THIS.updateChangesDiscard();
        }
      });
      THIS.SA_fac = 1;
      THIS.checkSaveAction();

      if ($('.reRenderJobStatus').length > 0) {
        const status_code = parseInt($('.reRenderJobStatus').attr('data-id'));
        if (status_code > 0) {
          THIS.param.status_code = status_code;
        }
      }
      (<any>$('.chosen-default-single-status')).val(THIS.param.status_code);
      (<any>$('.chosen-default-single-status')).chosen({
        allow_single_deselect: false,
        placeholder_text_single: 'Select job status'
      }).on('change', function (e, p) {
        if (e.target.value === '000') {
          let ___selectedTab = 0;
          const tabs = $('#sort-tabs').find('li');
          tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
              ___selectedTab = i;
            }
          });
          const child = ___selectedTab + 1;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          $('#sort-tabs').find('li').find('a').removeClass('active');
          $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
          (<any>$('#jobStatusModal')).modal('show');
        } else {
          THIS.param.status_code = e.target.value;
          THIS.validationThisForm();
          THIS.updateChangesDiscard();
        }
      });
      THIS.SA_status = 1;
      THIS.checkSaveAction();

      if ($('.reRenderAssets').length > 0) {
        const AssetSelectId = parseInt($('.reRenderAssets').attr('data-id'));
        if (AssetSelectId > 0) {
          THIS.AssetSelect.push(AssetSelectId);
          THIS.param.asset_id = THIS.AssetSelect.join(',');
          THIS.updateChangesDiscard();
        }
      }
      (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
      (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
      (<any>$('.chosen-default-multiple-asset')).chosen({
        allow_single_deselect: true,
        placeholder_text_multiple: 'No asset selected'
      }).on('change', function (e, p) {
        if (p.selected === '000') {
          let ___selectedTab = 0;
          const tabs = $('#sort-tabs').find('li');
          tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
              ___selectedTab = i;
            }
          });
          const child = ___selectedTab + 1;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          $('#sort-tabs').find('li').find('a').removeClass('active');
          $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
          (<any>$('#assetModal')).modal('show');
        } else {
          if (p.selected != undefined) {
            THIS.AssetSelect.push(p.selected);
          } else {
            THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
          }
          THIS.param.asset_id = THIS.AssetSelect.join(',');
          THIS.updateChangesDiscard();
        }
      });
      THIS.SA_asset = 1;
      THIS.checkSaveAction();

      (<any>$('.chosen-default-single-jti')).val(THIS.param.job_types_id);
      (<any>$('.chosen-default-single-jti')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Job Type'
      }).on('change', function (e, p) {
        THIS.param.job_types_id = e.target.value;
        THIS.updateChangesDiscard();
      });
      THIS.SA_type = 1;
      THIS.checkSaveAction();


      if ($('.reRenderPerson').length > 0) {
        const personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
        if (personnel_id > 0) {
          THIS.param.personnel_id.push(personnel_id.toString());
        }
      }
      const pp_val = THIS.param.personnel_id;
      (<any>$('.chosen-default-multiple-per')).val(pp_val);
      (<any>$('.chosen-default-multiple-per')).chosen('destroy');
      (<any>$('.chosen-default-multiple-per')).chosen({
        allow_single_deselect: true,
        placeholder_text_multiple: 'No personnel selected'
      }).on('change', function (e, p) {
        if (p.selected != undefined) {
          const v = p.selected;
          if (v === '000') {
            let ___selectedTab = 0;
            const tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            const child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#PersonQuickModal')).modal('show');
          } else {
            THIS.param.personnel_id.push(v.toString());
          }
        } else {
          const v = p.deselected;
          if (THIS.param.personnel_id.indexOf(v) > -1) {
            let ssCount = 0;
            const ssSor = [];
            $.each(THIS.param.sor, function (i, sor_v) {
              if (sor_v.user_id == v) {
                ssCount++;
              } else {
                ssSor.push(sor_v);
              }
            });
            if (ssCount > 0) {
              swal({
                title: 'SOR Assign Alert',
                text: 'This personnel has been attached with ' + ssCount + ' SOR item. If you remove, all sor history will be gone also',
                icon: 'warning',
                buttons: ['Cancel', 'Remove'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
                  THIS.param.sor = ssSor;
                } else {
                  (<any>$('.chosen-default-multiple-per')).val(THIS.param.personnel_id);
                  (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
                }
              });
            } else {
              THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
            }
          }
        }
        THIS.updateChangesDiscard();
      });
      THIS.SA_person = 1;
      THIS.checkSaveAction();

      (<any>$('.summernote')).summernote({
        maxHeight: '900px',
        minHeight: '150px',
        toolbar: [
          ['style', ['bold', 'underline', 'clear']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['link', 'video']],
          ['misc', ['fullscreen']]
        ],
        callbacks: {
          onKeyup: function (e) {
            THIS.updateChangesDiscard();
          }
        }
      });
      (<any>$('.summernote')).summernote('code', THIS.param.description);
      (<any>$('#woSubject')).val(THIS.param.subject);
    }, 100);
    this.__editpreview();
    this.__editpreview();
    // Pusher emit
  }


  SorAssigned = {org: [], person: []};

  sorOpen() {
    const THIS = this;
    this.SOR_projectItems.length = 0;
    this.SorAssigned.org.length = 0;
    this.SorAssigned.person.length = 0;
    if (this.param.personnel_id.length > 0) {
      $.each(THIS.Personnel.org, function (i, v) {
        if (v.user_type != 6) {
          if (THIS.param.personnel_id.indexOf(THIS.CustomHelper.makeStr(v.id)) > -1) {
            THIS.SorAssigned.org.push(v);
          }
        }
      });
      $.each(THIS.Personnel.person, function (i, v) {
        if (v.user_type != 3) {
          if (THIS.param.personnel_id.indexOf(THIS.CustomHelper.makeStr(v.id)) > -1) {
            THIS.SorAssigned.person.push(v);
          }
        }
      });
      this.getAllClients();

      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#sorModal')).modal('show');
    } else {
      swal({
        title: 'No Personnel Assigned',
        text: 'To add SOR you first have to assign personnel to this work order',
        icon: 'warning',
        dangerMode: true,
      });
    }
  }

  SOR_clients = [];
  SOR_client = '';
  SOR_Person = '';

  getAllClients() {
    const THIS = this;
    (<any>$('.chosen-default-single-sor-per')).val('');
    (<any>$('.clientData')).val('');
    (<any>$('.projectData')).val('');
    if (this.currentUser.user_type > 2) {
      THIS.SOR_Person = this.currentUser.id;
    }
    $.ajax({
      url: THIS.APPCONFIG.api + 'sor/project/getAllSor?token=' + THIS.currentUser.token,
      type: 'get',
      data: {},
      success: function (res) {
        if (res.status == 2000) {
          THIS.SOR_clients = res.data;
          setTimeout(function () {
            (<any>$('.clientData')).chosen('destroy');
            (<any>$('.clientData')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Client'
            }).on('change', function (e, p) {
              if (p != undefined) {
                THIS.SOR_client = p.selected;
                THIS.getAllProjects();
              }
            });
            (<any>$('.projectData')).chosen('destroy');
            (<any>$('.projectData')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Project'
            });
            (<any>$('.chosen-default-single-sor-per')).chosen('destroy');
            (<any>$('.chosen-default-single-sor-per')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Personnel'
            }).on('change', function (e, p) {
              if (p != undefined) {
                THIS.SOR_Person = p.selected;
              }
              THIS.getAllProjects();
            });
          }, 1000);
        }
      }
    });
  }

  SOR_projects = [];
  SOR_project = '';

  getAllProjects() {
    const THIS = this;
    THIS.SOR_projects.length = 0;
    if (THIS.SOR_Person == '') {
      swal({
        title: 'Personnel Selection',
        text: 'Please choose a personnel first whom to add SOR',
        icon: 'warning',
        dangerMode: true,
      });
    }
    if (THIS.SOR_client != '') {
      $.ajax({
        url: THIS.APPCONFIG.api + 'sor/project/getAll?token=' + THIS.currentUser.token,
        type: 'post',
        data: {sor_id: THIS.SOR_client, personId: THIS.SOR_Person, onlyMe: 1, workId: THIS.workDetails.id},
        success: function (res) {
          if (res.status == 2000) {
            THIS.SOR_projects = res.data;
            setTimeout(function () {
              (<any>$('.projectData')).chosen('destroy');
              (<any>$('.projectData')).chosen({
                allow_single_deselect: true,
                placeholder_text_single: 'Select Project'
              }).on('change', function (e, p) {
                if (p != undefined) {
                  THIS.SOR_project = p.selected;
                  THIS.getAllProjectItems();
                }
              });
            }, 1000);
          }
        }
      });
    }
  }

  SOR_projectItems = [];
  SOR_projectItem_start = 0;
  SOR_projectItem_end = 10;
  SOR_projectItem_pageNo = 1;
  SOR_empty = 0;

  getAllProjectItems() {
    const THIS = this;

    this.SOR_projectItem_start = 0;
    this.SOR_projectItem_end = 10;
    this.SOR_projectItem_pageNo = 1;

    const index = this.SOR_projects.map(function (e) {
      return e.id.toString();
    }).indexOf(THIS.SOR_project);
    if (index > -1) {
      this.SOR_empty = 0;
      this.SOR_projectItems = this.SOR_projects[index].info;
      if (this.SOR_projectItems.length == 0) {
        this.SOR_empty = 1;
      }
      if (this.SOR_projectItems.length > 0) {
        for (let i = 0; i < this.SOR_projectItems.length; i++) {
          const v = this.SOR_projectItems[i];
          const indexP = this.param.sor.map(function (e) {
            return e.id;
          }).indexOf(v.id);
          if (indexP > -1) {
            if (this.param.sor[indexP].user_id == THIS.SOR_Person) {
              this.SOR_projectItems[i].wo_qty = this.param.sor[indexP].wo_qty;
            }
          }
        }
      }
    }
  }

  SOR_pro_item_pagination(type) {
    if (type == 'next') {
      this.SOR_projectItem_pageNo++;
      this.SOR_projectItem_start = (this.SOR_projectItem_pageNo - 1) * 10;
      this.SOR_projectItem_end = (this.SOR_projectItem_pageNo - 0) * 10;
      if (this.SOR_projectItem_end > this.SOR_projectItems.length) {
        this.SOR_projectItem_end = this.SOR_projectItems.length;
      }
    }
    if (type == 'prev') {
      if (this.SOR_projectItem_pageNo > 1) {
        this.SOR_projectItem_pageNo--;
        this.SOR_projectItem_start = (this.SOR_projectItem_pageNo - 1) * 10;
        this.SOR_projectItem_end = (this.SOR_projectItem_pageNo - 0) * 10;
        if (this.SOR_projectItem_end > this.SOR_projectItems.length) {
          this.SOR_projectItem_end = this.SOR_projectItems.length;
        }
      }
    }
  }

  levelIt(index) {
    const THIS = this;
    /*setTimeout(function(){
            console.log(THIS.SOR_projectItems[index].wo_qty , THIS.SOR_projectItems[index].qty);
            if(THIS.SOR_projectItems[index].wo_qty  THIS.SOR_projectItems[index].qty){
                THIS.SOR_projectItems[index]['wo_qty'] = THIS.SOR_projectItems[index].qty;
            }
        }, 500);*/
  }

  saveSorUserData() {
    const THIS = this;
    const Data = this.SOR_projectItems;
    const OldData = THIS.param.sor;
    const items = [];
    $.each(OldData, function (i, v) {
      if (v.user_id != THIS.SOR_Person) {
        items.push(v);
      }
    });

    $.each(Data, function (i, v) {
      if (v.wo_qty != null && v.wo_qty != '' && v.wo_qty > 0) {
        v['user_id'] = THIS.SOR_Person;
        items.push(v);
      }
    });
    THIS.param.sor = items;
    (<any>$('#sorModal')).modal('hide');
    if (this.inlinePrefixId == 0) {
      this.qckUpdate();
    }
  }

  removeSORQuick() {
    if (this.inlinePrefixId == 0) {
      this.qckUpdate();
    }
  }

  ngOnInit() {
    const THIS = this;
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_fac_' + Session.team, function (v) {
            THIS.getAllFacility();
        });

        this.socket.on('_rfr_team_data_asset_' + Session.team, function (v) {
            THIS.asset_get();
        });

        this.socket.on('_rfr_team_data_jobType_' + Session.team, function (v) {
            THIS.jt_get();
        });

        this.socket.on('_rfr_team_data_jobStatus_' + Session.team, function (v) {
            THIS.getAllJobStatus(1);
        });*/

    window.addEventListener('beforeunload', function (e) {
      // Cancel the event
      e.preventDefault();
      // Pusher emit
    });
    $('body').on('click', '.reRenderFacility', function () {
      THIS.getAllFacility();
    });

    $('body').on('click', '.reRenderJobType', function () {
      THIS.jt_get();
    });

    $('body').on('click', '.reRenderAssets', function () {
      THIS.asset_get();
    });

    $('body').on('click', '.reRenderPerson', function () {
      THIS.personnel_get();
    });
  }

  ngOnDestroy() {
  }

  receiveSocket() {
    const THIS = this;
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    //===============
    // Pusher
    //===============
    Pusher.logToConsole = true;
    const pusher = new Pusher(APPCONFIG.PUSHER_KEY, {cluster: 'ap4'});
    const channel = pusher.subscribe('bm-work-orders');
    channel.bind('WoSendComment', function (signal) {
      console.log(signal);
      if (Session.team_id === signal.message.team_id && signal.message.pn_type == 'wo_comment') {
        const v = signal.message.data;
        if (v.module == 1 && v.module_id == THIS.workDetails.id) {
          try {
            const com = JSON.parse(v.attrs.comment);
            v['commentT'] = com.comment;
            v['images'] = com.image;
          } catch (e) {
            // Nothing to do
          }
          const albumT = [];
          if (v.images !== undefined && v.images.length) {
            for (let i = 0; i < v.images.length; i++) {
              const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + v.images[i].file_path;
              const caption = '';
              const thumb = '';
              const album = {
                src: src,
                caption: caption,
                thumb: thumb,
                w: v.images[i].width !== undefined ? v.images[i].width : 1500,
                h: v.images[i].height !== undefined ? v.images[i].height : 1500
              };
              albumT.push(album);
            }
          }
          v['album'] = albumT;
          v['created_at_human'] = THIS.CustomHelper.formatDateTime(v.created_at);
          v['updated_at_human'] = THIS.CustomHelper.formatDateTime(v.updated_at);
          const tmI = THIS.timeLineList.map(function (element) {
            return element.id;
          }).indexOf(v.id);
          if (tmI == -1) {
            THIS.timeLineList.push(v);
            setTimeout(function () {
              THIS.auto_grow();
              const tarCom = $('.CommentScrool');
              if (tarCom.length > 0) {
                tarCom[0].scrollTop = tarCom[0].scrollHeight + 200;
              }
              const tarComP = $('.Comment-Section-Train-way');
              if (tarComP.length > 0) {
                tarComP[0].scrollTop = tarComP[0].scrollHeight + 200;
              }
            }, 500);
          }
        }
      } else if (Session.team_id === signal.message.team_id && signal.message.pn_type == 'wo_comment_upd') {
        const v = signal.message.data;
        if (v.module == 1 && v.module_id == THIS.workDetails.id) {
          try {
            const com = JSON.parse(v.attrs.comment);
            v['commentT'] = com.comment;
            v['images'] = com.image;
          } catch (e) {
            // Nothing to do
          }
          const albumT = [];
          if (v.images !== undefined && v.images.length) {
            for (let i = 0; i < v.images.length; i++) {
              const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + v.images[i].file_path;
              const caption = '';
              const thumb = '';
              const album = {
                src: src,
                caption: caption,
                thumb: thumb,
                w: v.images[i].width !== undefined ? v.images[i].width : 1500,
                h: v.images[i].height !== undefined ? v.images[i].height : 1500
              };
              albumT.push(album);
            }
          }
          v['album'] = albumT;
          v['created_at_human'] = THIS.CustomHelper.formatDateTime(v.created_at);
          v['updated_at_human'] = THIS.CustomHelper.formatDateTime(v.updated_at);

          const index = THIS.timeLineList.map(function (e) {
            return e.id;
          }).indexOf(v.id);
          THIS.timeLineList[index] = v;
          setTimeout(function () {
            THIS.auto_grow();
          }, 1000);
        }
      } else if (Session.team_id === signal.message.team_id && signal.message.pn_type == 'wo_comment_del') {
        const v = signal.message.data;
        console.log(v.module_id, THIS.workDetails.id);
        console.log(THIS.timeLineList);
        if (v.module == 1 && v.module_id == THIS.workDetails.id) {
          const index = THIS.timeLineList.map(function (e) {
            return e.id;
          }).indexOf(parseInt(v.id));
          console.log(index);
          if (index > -1) {
            THIS.timeLineList[index].is_active = 0;
          }
          console.log(THIS.timeLineList[index]);
        }
      }
    });
    //==================
    // Pusher Presence
    //==================
    /*let presenceChannel = pusher.subscribe('presence-wo-single-presence');
        presenceChannel.members.each(function(member) {
            // let userId = member.id;
            // let userInfo = member.info;
            console.log(member)
        });
        presenceChannel.bind('pusher:subscription_succeeded', function(members) {
            console.log(members)
        })*/
    //===============
    // Pusher
    //===============
    /*

        this.socket.on('wo_comment_del_' + THIS.workDetails.id, function (v) {
            let index = THIS.timeLineList.map(function (e) {
                return e.id;
            }).indexOf(v.id);
            if (index > -1) {
                THIS.timeLineList[index].is_active = 0;
            }
        });*/
  }


  // =============================
  // Photo Editor
  // =============================
  CANVAS = null;
  SHAPE = 'brush';
  IMAGE_PATH = '';
  CanvasTarget = 0;

  discardEditor() {
    const THIS = this;
    const modal = (<any>$('#photoEditorModal'));
    if (THIS.CANVAS != null) {
      THIS.CANVAS.dispose();
    }
    modal.modal('hide');

  }

  editImgCallUnique = 0;
  EditCurrentCanvas = null;
  CurrentEditPhoto = null;

  openPhotoEditor(preview) {
    const THIS = this;
    THIS.CurrentEditPhoto = preview;
    THIS.CanvasTarget = 0;
    const modal = (<any>$('#photoEditorModal'));
    const canvasW = window.innerWidth - 20;
    const canvasH = window.innerHeight - 130;
    $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
    if (THIS.CANVAS != null) {
      this.CANVAS.clear();
    }
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    const param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

    if (THIS.editImgCallUnique == 0) {
      THIS.editImgCallUnique = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          THIS.editImgCallUnique = 0;
          if (res.status === 2000) {
            THIS.EditCurrentCanvas = res.data;
            const canvasW = res.data.width;
            const canvasH = res.data.height;
            $('#canvas').attr('width', canvasW + 'px');
            $('#canvas').attr('height', canvasH + 'px');
            setTimeout(function () {
              // if(THIS.CANVAS != null){
              //     THIS.CANVAS.dispose();
              // }

              THIS.CANVAS = new fabric.Canvas('canvas');
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.on('object:selected', function (e) {
                THIS.CANVAS.isDrawingMode = false;
                THIS.SHAPE = '';
              });
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.isDrawingMode = true;
              THIS.CANVAS.freeDrawingBrush.width = 5;

              let startY = 0,
                startX = 0;
              THIS.CANVAS.on('mouse:down', function (option) {
                startY = option.e.offsetY;
                startX = option.e.offsetX;
              });
              THIS.CANVAS.on('mouse:up', function (option) {
                const selected = THIS.CANVAS.getActiveObjects();
                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                  if (THIS.SHAPE === 'circle') {
                    const e = option.e;
                    if (e.offsetX - startX > 5) {
                      const circle = new fabric.Circle({
                        top: startY,
                        left: startX,
                        radius: (e.offsetX - startX) / 2,
                        stroke: THIS.CANVAS.freeDrawingBrush.color,
                        strokeWidth: 5,
                        fill: ''
                      });
                      THIS.CANVAS.add(circle);
                    }
                  }
                }
              });
              THIS.CustomHelper.tooltipRender();

              THIS.IMAGE_PATH = preview.file_path;
              const src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
              $('.canvas-container').css({
                backgroundImage: 'url(\'' + src + '\')'
              });
              THIS.CANVAS.renderAll();
              setTimeout(function () {
                THIS.CanvasTarget = 1;
              }, 1000);
            }, 1000);
          }
        }
      });


      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        modal.modal('hide');
        history.go(1);
      };

      if ($('.color-picker-editor').length > 0) {
        Pickr.create({
          el: '.color-picker-editor',
          theme: 'nano', // or 'monolith', or 'nano'
          defaultRepresentation: 'HEX',
          useAsButton: true,
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: true,
              input: true,
              save: true
            }
          }
        }).on('change', (color, instance) => {
          $('.color-picker-editor').css('color', color.toHEXA().toString());
          THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
          THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
          });
        });
      }
      $('.color-picker-editor').css('color', '#ff3773');
    }
  }

  // =============================
  // Photo Editor Actions
  // =============================
  SketchEnable() {
    this.CANVAS.isDrawingMode = true;
    this.SHAPE = 'brush';
  }

  eraser() {
    const THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    const obj = THIS.CANVAS.getObjects();
    for (let i = 0; i < obj.length; i++) {
      $.each(obj, function (j, v) {
        if (parseInt(j) > 0) {
          THIS.CANVAS.remove(v);
        }
      });
    }
    THIS.UID_IMG = Date.now();
    const src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
    $('.canvas-container').css({
      backgroundImage: 'url(\'' + src + '\')'
    });
  }

  eraserSelected() {
    const THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
      THIS.CANVAS.remove(obj);
    });
  }

  drawCircle() {
    const THIS = this;
    this.CANVAS.isDrawingMode = false;
    this.SHAPE = 'circle';
    const circle = new fabric.Circle({
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      radius: 50,
      stroke: THIS.CANVAS.freeDrawingBrush.color,
      strokeWidth: 5,
      fill: ''
    });
    THIS.CANVAS.add(circle);
  }

  UpdatePhoto(iid) {
    const THIS = this;
    const ldd = document.getElementById(iid);
    const ladda = Ladda.create(ldd);
    ladda.start();
    THIS.CANVAS.discardActiveObject();
    THIS.CANVAS.requestRenderAll();
    setTimeout(function () {
      const modal = (<any>$('#photoEditorModal'));
      const canvas = (<any>document.getElementById('canvas'));
      let imgData = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      imgData = imgData.replace('data:image/png;base64,', '');
      const param = {
        imgData: imgData,
        media_type: 1,
        file_path: THIS.IMAGE_PATH
      };
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          ladda.stop();
          modal.modal('hide');
          THIS.UID_IMG = Date.now();
        }
      });
    }, 1000);
  }


  UID_IMG = 0;
  Gallery = null;
  Preview;

  open(index, preview) {
    const THIS = this;
    THIS.Preview = preview;
    $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    const _albums = [];
    if (THIS.workDetails.media_files !== undefined) {
      for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
        const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + THIS.workDetails.media_files[i].file_path + '&ref=' + Date.now();
        const caption = '';
        const thumb = '';
        const album = {
          src: src,
          caption: caption,
          thumb: thumb,
          w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
          h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
        };
        _albums.push(album);
      }
    }
    const pswpElement = document.querySelectorAll('.pswp')[0];
    const options = {
      index: index,
      shareEl: false,
      editEl: true,
      downEl: true,
      tapToClose: false,
      zoomEl: true,
      history: false
    };
    THIS.Gallery = null;
    THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
    THIS.Gallery.init();
    THIS.Gallery.listen('afterChange', function (r) {
      THIS.Preview = THIS.workDetails.media_files[THIS.Gallery.getCurrentIndex()];
      $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    });
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      THIS.Gallery.close();
      history.go(1);
    };

  }

  openTimelineLight(index, indexT, preview) {
    const THIS = this;
    THIS.Preview = preview[0];
    $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    const _albums = [];
    if (THIS.timeLineList[indexT].album !== undefined) {
      for (let i = 0; i < THIS.timeLineList[indexT].album.length; i++) {
        const src = THIS.timeLineList[indexT].album[i].src + '&ref=' + Date.now();
        const caption = '';
        const thumb = '';
        const album = {
          src: src,
          caption: caption,
          thumb: thumb,
          w: THIS.timeLineList[indexT].album[i].w !== undefined ? THIS.timeLineList[indexT].album[i].w : 1500,
          h: THIS.timeLineList[indexT].album[i].h !== undefined ? THIS.timeLineList[indexT].album[i].h : 1500
        };
        _albums.push(album);
      }
    }
    const pswpElement = document.querySelectorAll('.pswp')[0];
    const options = {
      index: index,
      shareEl: false,
      editEl: true,
      downEl: true,
      tapToClose: false,
      zoomEl: true,
      history: false
    };
    THIS.Gallery = null;
    THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
    THIS.Gallery.init();
    THIS.Gallery.listen('afterChange', function (r) {
      THIS.Preview = preview[THIS.Gallery.getCurrentIndex()];
      $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    });
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      THIS.Gallery.close();
      history.go(1);
    };
  }

  checkCurrency() {
    Inputmask.extendAliases({
      pesos: {
        prefix: '$',
        groupSeparator: '',
        alias: 'numeric',
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        radixPoint: '.',
        placeholder: '0.00'
      }
    });

    setTimeout(function () {
      (<any>$('._currency_validate_eq')).inputmask({alias: 'pesos'});
    }, 300);
  }


  /*=======================*/

  /*====== Presence =======*/
  /*=======================*/
  presence = [];
  presenceView = [];
  presenceEdit = [];

  presenceNameView = '';
  presenceNameEdit = '';

  renderPresence() {
    const THIS = this;
    THIS.presenceView = [];
    THIS.presenceEdit = [];
    THIS.presenceNameView = '';
    THIS.presenceNameEdit = '';
    if (this.presence.length > 0) {
      const presenceNameEdit = [];
      const presenceNameView = [];
      $.each(THIS.presence, function (i, v) {
        if (v.id != THIS.currentUser.id) {
          if (v.type == 'edit') {
            THIS.presenceEdit.push(v);
            presenceNameEdit.push(v.name);
          } else {
            THIS.presenceView.push(v);
            presenceNameView.push(v.name);
          }
        }
      });
      THIS.presenceNameView = presenceNameView.join(', ');
      THIS.presenceNameEdit = presenceNameEdit.join(', ');
    }

    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  addNewChat(user) {
    // if(user.id == this.currentUser.id) {
    //   return window.alert('You cant send message to yourself!');
    // }

    const index = this.ActiveChat.map(function (e) {
      return e.id;
    }).indexOf(user.id);
    if (index == -1) {
      if (this.ActiveChat.length > 2) {
        this.ActiveChat.length = 2;
      }
      this.ActiveChat.push(user);
      this.chatService.updateActiveChat(this.ActiveChat);
    }
  }


  removeSupInvoice(index) {
    this.incomingCreate.file_name.splice(index, 1);
  }

  currencyIcon(): string {
    if (this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }
}
