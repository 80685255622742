import {Component, EventEmitter, Output, ViewChild, Inject, Input, SimpleChanges} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';

import {WorkOrderService} from '../../../_services/work-orders.service';
import {FacilityService} from '../../../_services/facility.service';
import {ContactsService} from '../../../_services/contacts.service';
import {AssetService} from '../../../_services/asset.service';
import {Job_typesService} from '../../../_services/job_types.service';
import {Job_statusService} from '../../../_services/job_status.service';
import {MediaService} from '../../../_services/media.service';
import {FcmService} from '../../../_services/fcm.service';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let fabric: any;
declare let Pickr: any;
declare let Inputmask: any;

@Component({
  selector: 'work-order-create',
  templateUrl: './createWork.component.html'
})
export class CreateWorkComponent {
  @ViewChild('myPond') myPond: any;
  @Output() discard = new EventEmitter<number>();
  @Output() preview = new EventEmitter<number>();
  @Output() addnewRe = new EventEmitter<number>();
  @Input() ProjectId: any;
  @Input() facility_id: any;
  @Input() job_types_id: any;
  @Input() max_start_date: any = null;
  @Input() max_due_date: any = null;

  id = 0;
  facilities;
  contact;
  assets;
  job_types;
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  currentUser;

  constructor(
    public snackBar: MatSnackBar,
    private job_typesService: Job_typesService,
    private job_statusService: Job_statusService,
    private assetService: AssetService,
    private contactsService: ContactsService,
    private fcmService: FcmService,
    protected facilityService: FacilityService,
    private workOrderService: WorkOrderService,
    private mediaService: MediaService) {
    const THIS = this;
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.currentUser = Session;
    // this.getLastWorkOrderId();
    this.getAllFacility();
    this.jt_get();
    this.getAllJobStatus(0);
    this.pty_get(0);
    this.asset_get();
    this.personnel_get();


    this.param.created_at = this.CustomHelper.formatDateTimeToDB(new Date());
    this.dateCreatedOption.onChange = function (selectedDates, dateStr, instance) {
      if (selectedDates.length > 0) {
        THIS.dateCreated = new Date(selectedDates);
      } else {
        THIS.dateCreated = null;
      }
      THIS.onCreatedSelect();
    };
    this.dateDueOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.dateDue = new Date(selectedDates);
      THIS.onDueSelect();
    };
    setTimeout(function () {
      (<any>$('.summernote')).summernote({
        maxHeight: '900px',
        minHeight: '150px',
        toolbar: [
          ['style', ['bold', 'underline', 'clear']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['link', 'video']],
          ['misc', ['fullscreen']]
        ],
        callbacks: {
          onKeyup: function (e) {
            THIS.updateChangesDiscard();
          }
        }
      });
    }, 100);
    this.checkCurrency();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ProjectId != undefined) {
      this.param.project_id = changes.ProjectId.currentValue;
    }
    if (changes.facility_id != undefined) {
      this.param.facility_id = changes.facility_id.currentValue;
      this.asset_get(this.param.facility_id);
    }
    if (changes.job_types_id != undefined) {
      this.param.job_types_id = changes.job_types_id.currentValue;
    }
    if (changes.max_start_date != undefined) {
      this.max_start_date = changes.max_start_date.currentValue;
      this.dateCreatedOption.minDate = this.max_start_date;
      this.dateDueOption.minDate = this.max_start_date;
    }
    if (changes.max_due_date != undefined) {
      this.max_due_date = changes.max_due_date.currentValue;
      this.dateCreatedOption.maxDate = this.max_due_date;
      this.dateDueOption.maxDate = this.max_due_date;
    }
  }


  Facility = [];
  FacilityRecent = [];
  FacilityComp = [];

  getAllFacility() {
    const THIS = this;
    THIS.Facility.length = 0;
    THIS.FacilityComp.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.FacilityRecent = res.recent;
        THIS.FacilityComp = res.facility;
        $.each(res.facility, function (i, v) {
          v['pp'] = 0;
          THIS.Facility.push(v);
          $.each(v.child, function (j, vC) {
            vC['pp'] = 1;
            THIS.Facility.push(vC);
            $.each(vC.child, function (k, vCC) {
              vCC['pp'] = 2;
              THIS.Facility.push(vCC);
              $.each(vCC.child, function (l, vCCC) {
                vCCC['pp'] = 3;
                THIS.Facility.push(vCCC);
              });
            });
          });
        });
        setTimeout(function () {
          if ($('.reRenderFacility').length > 0) {
            const facilityId = parseInt($('.reRenderFacility').attr('data-id'));
            if (facilityId > 0) {
              THIS.param.facility_id = facilityId.toString();
              THIS.asset_get(THIS.param.facility_id);
            }
          }
          (<any>$('.chosen-default-single-fac')).chosen('destroy');
          (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
          (<any>$('.chosen-default-single-fac')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Facility'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
              (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#createFacilityModal')).modal('show');
                $('.invalid-feedback').html('');

            } else {
              THIS.param.facility_id = e.target.value;
              THIS.validationThisForm();
              THIS.updateChangesDiscard();
              THIS.asset_get(THIS.param.facility_id);
            }
          });
        }, 100);
          setTimeout(function () {
              if ((<any>$('.invFacilitySelector')) !== undefined) {
                  (<any>$('.invFacilitySelector')).chosen('destroy');
                  (<any>$('.invFacilitySelector')).val(THIS.param.facility_id);
                  (<any>$('.invFacilitySelector')).chosen({
                      allow_single_deselect: true,
                      placeholder_text_single: 'Select Facility'
                  }).on('change', function (e, p) {
                      if (p !== undefined) {
                          THIS.getAllInventory(p.selected);
                      }
                      if (e.target.value === '000') {
                          (<any>$('.invFacilitySelector')).val(THIS.param.facility_id);
                          (<any>$('.invFacilitySelector')).trigger('chosen:updated');
                          let ___selectedTab = 0;
                          const tabs = (<any>$('#sort-tabs')).find('li');
                          tabs.each(function (i, v) {
                              if ((<any>$(v)).find('a.active').length > 0) {
                                  ___selectedTab = i;
                              }
                          });
                          const child = ___selectedTab + 1;
                          (<any>$('.owl-carousel')).owlCarousel('destroy');
                          (<any>$('#sort-tabs')).find('li').find('a').removeClass('active');
                          (<any>$('#sort-tabs')).find('li:nth-child(' + child + ')').find('a').addClass('active');
                          (<any>$('#createFacilityModal')).modal('show');
                         (<any> $('.invalid-feedback')).html('');
                          e.target.value = '';
                      }
                  });

                  if (THIS.param.facility_id != '') {
                      THIS.getAllInventory(THIS.param.facility_id);
                  }
              }
          }, 1000);
      }
    });
  }

  JobType = [];

  jt_get() {
    const THIS = this;
    this.JobType.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-types?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.JobType = res.data;
        setTimeout(function () {
          if ($('.reRenderJobType').length > 0) {
            const job_types_id = parseInt($('.reRenderJobType').attr('data-id'));
            if (job_types_id > 0) {
              THIS.param.job_types_id = job_types_id;
            }
          }
          (<any>$('.chosen-default-single-jti')).chosen('destroy');
          (<any>$('.chosen-default-single-jti')).val(THIS.param.job_types_id);
          (<any>$('.chosen-default-single-jti')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Job Type'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              (<any>$('.chosen-default-single-jti')).val(THIS.param.job_types_id);
              (<any>$('.chosen-default-single-jti')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#JobTypesModal')).modal('show');
            } else {
              THIS.param.job_types_id = e.target.value;
              THIS.updateChangesDiscard();
            }
          });
        }, 100);
      }
    });
  }

  Priority = [];

  pty_get(refresh): void {
    const THIS = this;
    this.Priority.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'priorities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.Priority = res.data;
      }
    });
  }

  choosePty(priority) {
    const THIS = this;
    if (priority != null) {
      THIS.param.priority = priority.id.toString();
      if (THIS.param.created_at != null && THIS.param.created_at != '') {
        const v = new Date(THIS.dateCreated);
        const min = THIS.CustomHelper.makeFloat(priority.amount) * THIS.CustomHelper.makeFloat(priority.duration);
        v.setMinutes(v.getMinutes() + min);
        THIS.dateDue = THIS.CustomHelper.formatDateTimeToDB(v);
        THIS.changingStart = 1;
        setTimeout(function () {
          THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
          THIS.dateDueOption.defaultDate = new Date(THIS.dateDue);
          THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue));
          THIS.changingStart = 0;
        }, 400);
      }
    } else {
      THIS.param.priority = '0';
      if (THIS.param.created_at != null && THIS.param.created_at != '') {
        THIS.param.due = THIS.dateDue != null ? THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue)) : '';
      }
    }
  }

  Assets = [];
  AssetSelect = [];

  asset_get(facility_id = ''): void {
    const THIS = this;
    this.Assets.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'assets/get/Asset/allList?token=' + THIS.currentUser.token,
      data: {facility_id: facility_id},
      type: 'post',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Assets = res.data;
        }
        setTimeout(function () {
          if ($('.reRenderAssets').length > 0) {
            const AssetSelectId = parseInt($('.reRenderAssets').attr('data-id'));
            if (AssetSelectId > 0) {
              THIS.AssetSelect.push(AssetSelectId);
              THIS.param.asset_id = THIS.AssetSelect.join(',');
              THIS.updateChangesDiscard();
            }
          }
          (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
          (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
          (<any>$('.chosen-default-multiple-asset')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No asset selected'
          }).on('change', function (e, p) {
            if (p.selected === '000') {
              (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
              (<any>$('.chosen-default-multiple-asset')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#assetModal')).modal('show');
            } else {
              if (p.selected != undefined) {
                THIS.AssetSelect.push(p.selected);
              } else {
                THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
              }
              THIS.param.asset_id = THIS.AssetSelect.join(',');
              THIS.updateChangesDiscard();
            }

          });
        }, 100);
      }
    });
  }

  Personnel = {
    org: [],
    person: []
  };

  personnel_get(): void {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Personnel = res;
        }
        setTimeout(function () {
          if ($('.reRenderPerson').length > 0) {
            const personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
            if (personnel_id > 0) {
              THIS.param.personnel_id.push(personnel_id.toString());
            }
          }
          const pp_val = THIS.param.personnel_id;
          (<any>$('.chosen-default-multiple-per')).chosen('destroy');
          (<any>$('.chosen-default-multiple-per')).val(pp_val);
          (<any>$('.chosen-default-multiple-per')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No personnel selected'
          }).on('change', function (e, p) {
            if (p.selected != undefined) {
              const v = p.selected;
              if (v === '000') {
                (<any>$('.chosen-default-multiple-per')).val(THIS.param.personnel_id);
                (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
                $('.show-name-error').html('');
                $('.show-email-error').html('');
                let ___selectedTab = 0;
                const tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) {
                  if ($(v).find('a.active').length > 0) {
                    ___selectedTab = i;
                  }
                });
                const child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#PersonQuickModal')).modal('show');
              } else {
                THIS.param.personnel_id.push(v.toString());
              }
            } else {
              const v = p.deselected;
              if (THIS.param.personnel_id.indexOf(v) > -1) {
                let ssCount = 0;
                const ssSor = [];
                $.each(THIS.param.sor, function (i, sor_v) {
                  if (sor_v.user_id == v) {
                    ssCount++;
                  } else {
                    ssSor.push(sor_v);
                  }
                });
                if (ssCount > 0) {
                  swal({
                    title: 'SOR Assign Alert',
                    text: 'This personnel has been attached with ' + ssCount + ' SOR item. If you remove, all sor history will be gone also',
                    icon: 'warning',
                    buttons: ['Cancel', 'Remove'],
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
                      THIS.param.sor = ssSor;
                    } else {
                      (<any>$('.chosen-default-multiple-per')).val(THIS.param.personnel_id);
                      (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
                    }
                  });
                } else {
                  THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
                }
              }
            }
            THIS.updateChangesDiscard();
          });
        }, 100);
      }
    });
  }

  jobStatusList = [];
  jobStatusDefault = [];
  jobStatusCustom = [];

  getAllJobStatus(refresh) {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-status?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.jobStatusDefault.length = 0;
          THIS.jobStatusCustom.length = 0;
          THIS.jobStatusList = res.data;
          localStorage._jsts = JSON.stringify(res.data);
          $.each(res.data, function (i, v) {
            if (v.type == 0) {
              THIS.jobStatusDefault.push(v);
            } else {
              THIS.jobStatusCustom.push(v);
            }
          });
        }
        setTimeout(function () {
          if ($('.reRenderJobStatus').length > 0) {
            const status_code = parseInt($('.reRenderJobStatus').attr('data-id'));
            if (status_code > 0) {
              THIS.param.status_code = status_code;
            }
          }
          (<any>$('.chosen-default-single-status')).chosen('destroy');
          (<any>$('.chosen-default-single-status')).val(THIS.param.status_code);
          (<any>$('.chosen-default-single-status')).chosen({
            allow_single_deselect: false,
            placeholder_text_single: 'Select job status'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              (<any>$('.chosen-default-single-status')).val(THIS.param.status_code);
              (<any>$('.chosen-default-single-status')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#jobStatusModal')).modal('show');
            } else {
              THIS.param.status_code = e.target.value;
              THIS.validationThisForm();
              THIS.updateChangesDiscard();
            }

          });
        }, 100);
      }
    });
  }

  invPersonnels = {
    org: [],
    person: []
  };

  getAllSupplier() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {


        if (res.status == 2000) {
          THIS.invPersonnels.org = res.org;
          THIS.invPersonnels.person = res.person;
        }
        setTimeout(function () {

          (<any>$('.chosen-default-single-supplier')).chosen('destroy');
          (<any>$('.chosen-default-single-supplier')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Supplier'
          }).on('change', function (e, p) {
            const v = e.target.value;
              if (v === '000') {
                  (<any>$('.chosen-default-single-supplier')).val(THIS.param.personnel_id);
                  (<any>$('.chosen-default-single-supplier')).trigger('chosen:updated');

                  let ___selectedTab = 0;
                  const tabs = $('#sort-tabs').find('li');
                  tabs.each(function (i, v) {
                      if ($(v).find('a.active').length > 0) {
                          ___selectedTab = i;
                      }
                  });
                  const child = ___selectedTab + 1;
                  (<any>$('.owl-carousel')).owlCarousel('destroy');
                  $('#sort-tabs').find('li').find('a').removeClass('active');
                  $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                  (<any>$('#PersonQuickModal')).modal('show');
              } else {
                  THIS.incomingCreate.personnel = v;
                  THIS.incomingCreate.groups = '';
              }
          });
        }, 500);
      }
    });
  }

  siModalOpen() {
    this.incomingCreate = {
      invoice_number: '',
      invoice_amount: '',
      personnel: '',
      groups: '',
      comment: '',
      file_name: []
    };
    this.getAllSupplier();
    $('.error_invoice_amount').html('');
    $('._currency_validate_eq').val('');

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#SIModal')).modal('show');
  }


  toolbarOptions = [
    ['bold', 'italic', 'underline'],
    ['blockquote', 'code-block'],
    [{'color': []}, {'background': []}],
    [{'align': []}],
  ];

  confirmDiscard() {
    /*let param = {
        prefix : this.param.prefix,
        work_order_id : this.param.work_order_id
    };
    this.workOrderService.getLastWorkOrderIdRemove(param).subscribe(res => {});*/
    console.log(this.discard);
    this.discard.emit(1);
  }

  selectedWorkAttach = [];
  selectedFiles = [];
  selectedFilesPreview = [];

  uploadThisMedia(file, media_type, localtion, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  removeThisMedia(id, media_type) {
    const param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  dragFileOverStart() {
    const trigger = $('#uploadDragWork');
    trigger.addClass('onEnter');
  }

  dragFileOverEnd() {
    const trigger = $('#uploadDragWork');
    trigger.removeClass('onEnter');
  }

  _uploadError = 0;
  _locPermit = 0;

  dragFilesDropped(e) {
    e.preventDefault();
    this._uploadError = 0;
    const THIS = this;
    if (e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        if (this.selectedFiles.length < 3) {
          const eachFile = e.dataTransfer.files[i];
          this.selectedFiles.push(eachFile);
          if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            if (THIS.currentUser.modules.geo_tagged_photo == 1) {
              navigator.geolocation.getCurrentPosition(function (position) {
                if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                  THIS._locPermit = 1;
                  const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                  THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please trash some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  THIS._locPermit = -1;
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please trash some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                }
              }, function (error) {
                swal({
                  title: 'Share Geo Location',
                  text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                  icon: 'warning',
                  buttons: ['Want to share location', 'Don\'t want to share Location'],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                      if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                          swal({
                            title: 'Storage Full',
                            text: 'Your Storage is full. Please trash some files',
                            icon: 'error',
                            dangerMode: true,
                          });
                        } else {
                          swal({
                            title: 'Invalid file format uploaded',
                            text: 'Please upload an image in Jpeg or Png only.',
                            icon: 'error',
                            dangerMode: true,
                          });
                        }
                      } else {
                        THIS.selectedFilesPreview.push(uploaded);
                      }
                    });
                  } else {
                    swal('If you want to share location then change the browser settings and remove the block status');
                  }
                });
              });
            } else {
              THIS._locPermit = -1;
              THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                if (uploaded.status != undefined) {
                  if (uploaded.status == 9000) {
                    swal({
                      title: 'Storage Full',
                      text: 'Your Storage is full. Please trash some files',
                      icon: 'error',
                      dangerMode: true,
                    });
                  } else {
                    swal({
                      title: 'Invalid file format uploaded',
                      text: 'Please upload an image in Jpeg or Png only.',
                      icon: 'error',
                      dangerMode: true,
                    });
                  }
                } else {
                  THIS.selectedFilesPreview.push(uploaded);
                }
              });
            }
          } else {
            this._uploadError = 1;
          }
        }
      }
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedFiles.length < 3) {
        const eachFile = accepted[i];
        this.selectedFiles.push(eachFile);
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                THIS._locPermit = 1;
                const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please trash some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              } else {
                THIS._locPermit = -1;
                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please trash some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              }
            }, function (error) {
              swal({
                title: 'Share Geo Location',
                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                icon: 'warning',
                buttons: ['Want to share location', 'Don\'t want to share Location'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please trash some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  swal('If you want to share location then change the browser settings and remove the block status');
                }
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please trash some files',
                    icon: 'error',
                    dangerMode: true,
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
              }
            });
          }
        } else {
          this._uploadError = 1;
        }
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  removeThisImage(index, id) {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesPreview.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  addWorkMedia() {
    $('#workMediaInput').click();
  }

  workDetails = {
    personnel: [],
    incoming: []
  };
  param = {
    project_id: 0,
    prefix: 'w',
    work_order_id: '',
    subject: '',
    description: '',
    media_files: '',
    attach_files: '',
    facility_id: '',
    contact_id: 0,
    asset_id: '',
    job_types_id: 0,
    due: '',
    created_at: '',
    add_to_calendar: 0,
    calendar_color: '',
    priority: '0',
    status_code: 1,
    personnel: '4',
    personnel_id: [],
    group_id: [],
    personnel_group: '',
    attachInventories: [],
    taskType: 1,
    tasks: [],
    incoming: [],
    sor: []
  };

  getLastWorkOrderId() {
    this.workOrderService.getLastWorkOrderId().subscribe(res => {
      this.param.prefix = res.prefix;
      this.param.work_order_id = res.work_order_id;
    });
  }

  saveWorkOrderSubmit(newD) {
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      const freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        const freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    const THIS = this;
    let mediaFiles = '';
    $.each(THIS.selectedFilesPreview, function (i, v) {
      if (i == 0) {
        mediaFiles += v.id;
      } else {
        mediaFiles += ',' + v.id;
      }
    });
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    this.param.media_files = mediaFiles;
    this.param.attach_files = mediaAttach;
    this.param.description = (<any>$('.summernote')).summernote('code');

    const woTaskList = $('#woTaskList').find('._eachTask');
    this.param['taskType'] = this.taskType;
    this.param.tasks.length = 0;
    this.param.tasks = this.taskList;
    this.param.incoming = this.incoming;
    this.param.add_to_calendar = this.add2Calendar;
    this.param.calendar_color = this.calendarStatus;
    this.param.attachInventories = this.attachInventories;

    this.param.created_at = this.param.created_at != null && this.param.created_at != '' ? this.CustomHelper.formatDateTimeToDB(this.param.created_at) : '';
    this.param.due = this.param.due != null && this.param.due != '' ? this.CustomHelper.formatDateTimeToDB(this.param.due) : '';
    /*woTaskList.each(function(i, v){
        let tsk = $(v).val();
        THIS.param.tasks.push(tsk);
    });*/


    const valid = this.validationThisForm();
    if (valid == 1) {
      const THIS = this;
      this.submitting = 1;
      const ldd = document.getElementById('SaveThisLadda');
      const ladda = Ladda.create(ldd);
      ladda.start();
      this.workOrderService.createWorkOrder(this.param).subscribe(res => {
        ladda.stop();
        this.submitting = 0;
        if (res.status != undefined && res.status == 7000) {
          swal({
            text: 'The Work Order ID is already been taken. Please use a unique one',
            icon: 'warning',
            className: 'swal-btn-center'
          });
        } else if (res.status != undefined && res.status == 3000) {
          swal({
            title: 'Work Order',
            text: 'You have reached your free plan limit. To create more work orders, please subscribe to the Pro plan from your account page.',
            icon: 'warning',
            dangerMode: true,
            className: 'swal-btn-center'
          });
        } else {
          THIS.CustomHelper.woRouteDiscard = 0;
          const index = THIS.CustomHelper.ConfRoute.map(function (e) {
            return e.tab;
          }).indexOf(THIS.CustomHelper.ConfCurRoute);
          if (index > -1) {
            THIS.CustomHelper.ConfRoute.splice(index, 1);
          }
          if (newD == 'new') {
            THIS.addnewRe.emit(res);
          } else {
            THIS.preview.emit(res);
          }
          toastr.success('This new job has been successfully created', 'New Job', THIS.toastrOption);
          // Pusher emit
        }
      });
    }


  }

  clearValidation(e) {
    const trigger = $(e.target);
    const target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  validForm = 0;
  submitting = 0;

  validationThisForm() {
    let valid = 1;
    const form = $('#workCreateForm');
    if (this.param.facility_id == '') {
      valid = 0;
      form.find('[data-el="facility"]').removeClass('has-error').addClass('has-error');
    } else {
      form.find('[data-el="facility"]').removeClass('has-error');
    }

    // if (this.param.status_code > 0) {
    //     valid = 0;
    //     form.find('[data-el="status_code"]').removeClass('has-error').addClass('has-error');
    // } else {
    //     form.find('[data-el="status_code"]').removeClass('has-error');
    // }

    // if (this.param.prefix == '') {
    //     valid = 0;
    // }
    // if (this.param.work_order_id == '') {
    //     valid = 0;
    // }
    if (this.param.subject == '') {
      valid = 0;
      form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
    } else {
      form.find('[data-el="subject"]').removeClass('has-error');
    }
    this.validForm = valid;
    return valid;
  }

  inlinePrefixId = 0;

  updateChangesDiscard() {
    const THIS = this;
    const index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      this.CustomHelper.ConfRoute[index].change = 1;
    } else {
      this.CustomHelper.ConfRoute.push({tab: this.CustomHelper.ConfCurRoute, change: 1});
    }
  }


  taskType = 1; // 1.Tasks  2.Inspections
  taskList = [{title: '', type: 1}];

  taskCreateOnF() {
    this.taskList.push({title: '', type: 1});
    setTimeout(function () {
      const objDiv = document.getElementById('_tsk_list_scroll');
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 500);
    this.updateChangesDiscard();
  }


  selectThisPersonnelId(data) {
    this.param.personnel = data.personnel;
    this.param.personnel_id = data.personnelIds;
    // this.param.asset_id = asset_id.toString();
    this.updateChangesDiscard();
  }

  dateCreated = this.CustomHelper.dateToTz(new Date());
  changingStart = 0;
  dateCreatedOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: new Date(),
    minDate: this.max_start_date,
    maxDate: this.max_due_date,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  onCreatedSelect() {
    const THIS = this;
    THIS.param.priority = '0';
    if (this.dateCreated != null) {
      this.param.created_at = this.CustomHelper.formatDateTimeToDB(this.dateCreated);
      this.param.due = '';
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
        THIS.dateDueOption.defaultDate = this.dateDue;
        THIS.changingStart = 0;
      }, 400);
      this.updateChangesDiscard();
    } else {
      this.param.created_at = '';
      this.param.due = '';
      this.add2Calendar = 0;
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date();
        THIS.dateDueOption.defaultDate = null;
        THIS.changingStart = 0;
      }, 400);
    }
  }

  dateDue = null;
  dateDueOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: null,
    minDate: this.max_start_date,
    maxDate: this.max_due_date,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  onDueSelect() {
    const THIS = this;
    this.param.due = this.CustomHelper.formatDateTimeToDB(this.dateDue);
    this.param.priority = '0';
    this.updateChangesDiscard();
  }

  add2Calendar = 0;
  calendarStatus = '_red';

  addToCalendar() {
    if (this.param.created_at != '' && this.param.created_at != null) {
      this.add2Calendar == 1 ? this.add2Calendar = 0 : this.add2Calendar = 1;
      this.updateChangesDiscard();
    } else {
      this.add2Calendar = 0;
      swal('You must have to provide start Date to add this work order to Calendar');
    }
  }


  // ======================
  // Incoming Invoice
  // ======================
  incoming = [];
  incomingCreate = {
    invoice_number: '',
    invoice_amount: '',
    personnel: '',
    groups: '',
    comment: '',
    file_name: []
  };

  invoiceUploadFileChoose() {
    $('#invoiceUpload').click();
  }

  invoiceUpload(e) {
    e.preventDefault();
    const THIS = this;
    const files = e.target.files;
    $.each(files, function (i, v) {
      THIS.uploadThisMedia(v, '4', null, function (uploaded) {
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please trash some files',
              icon: 'error',
              dangerMode: true,
            });
          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
              icon: 'error',
              dangerMode: true,
            });
          }
        } else {
          THIS.incomingCreate.file_name.push(uploaded);
        }
      });
    });
  }

  filterNegAmt(val) {
    $.each(this.InvLabData, function (i, v) {
      const str = v.pick.toString();
      if (v.pick != '' && v.pick != null) {
        v.pick = str.replace(/\-/, '');
      }
    });
  }
  filterInvAmt() {
    console.log(this.incomingCreate.invoice_amount);
    this.incomingCreate.invoice_amount = this.incomingCreate.invoice_amount.replace(/\-/, '');
  }
  createIncomingInvoice() {
    console.log((<any>$('input[name="invoice_amount"]').val()));
    this.incomingCreate.invoice_amount = (<any>$('input[name="invoice_amount"]').val()).replace(/\$/, '');
    if ((<any>$('input[name="invoice_amount"]')).val().includes('AED')) {
      this.incomingCreate.invoice_amount = (<any>$('input[name="invoice_amount"]').val()).replace(this.currencyIcon(), '');
    }
    if ((<any>$('input[name="invoice_amount"]')).val().includes('AUD')) {
      this.incomingCreate.invoice_amount = (<any>$('input[name="invoice_amount"]').val()).replace(this.currencyIcon(), '');
      if (Number(this.incomingCreate.invoice_amount) < 0) {
        console.log('invoice amount should be positive.');
      }
      console.log(this.currencyIcon());
    }
    if (parseInt(this.incomingCreate.invoice_amount) == 0) {
      this.incomingCreate.invoice_amount = '';
    }
    if (parseInt(this.incomingCreate.invoice_amount) < 0) {
      $('.error_invoice_amount').html('Invoice amount should be positive');
      return;
    }
    if (this.incomingCreate.invoice_number != '' && this.incomingCreate.invoice_amount != '') {
      this.incoming.push(this.incomingCreate);
      $('.error_invoice_id').html('');
      $('.error_invoice_amount').html('');
      $('._currency_validate_eq').val('');
      (<any>$('#SIModal')).modal('hide');
      this.incomingCreate = {
        invoice_number: '',
        invoice_amount: '',
        personnel: '',
        groups: '',
        comment: '',
        file_name: []
      };
    } else {
      if (this.incomingCreate.invoice_number == '') {
        $('.error_invoice_id').html('Invoice id is required');
      } else {
        $('.error_invoice_id').html('');
      }
      if (this.incomingCreate.invoice_amount == '') {
        $('.error_invoice_amount').html('Invoice amount is required');
      } else {
        $('.error_invoice_amount').html('');

        this.incomingCreate.invoice_amount = '';
      }
    }
  }

  SelectedPersonList = [];
  attachInventories = [];

  inventoryOpen(): void {
    const THIS = this;

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#invLabModal')).modal('show');

    THIS.SelectedPersonList.length = 0;
    $.each(THIS.param.personnel_id, function (i, v) {
      const index = THIS.Personnel.person.map(function (element) {
        return element.id;
      }).indexOf(parseInt(v));
      if (index > -1) {
        THIS.SelectedPersonList.push(THIS.Personnel.person[index]);
      }
    });
    THIS.LabourData = THIS.SelectedPersonList;
    THIS.InvLabData.length = 0;
    THIS.InvLabDataTotal = 0;
    THIS.InvLabEmpty = 0;

    setTimeout(function () {
      (<any>$('.invFacilitySelector')).chosen('destroy');
      (<any>$('.invFacilitySelector')).val(THIS.param.facility_id);
      (<any>$('.invFacilitySelector')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Facility'
      }).on('change', function (e, p) {
        if (p != undefined) {
          THIS.getAllInventory(p.selected);
        }
          if (e.target.value === '000') {
              (<any>$('.invFacilitySelector')).val(THIS.param.facility_id);
              (<any>$('.invFacilitySelector')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                  if ($(v).find('a.active').length > 0) {
                      ___selectedTab = i;
                  }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#createFacilityModal')).modal('show');
              $('.invalid-feedback').html('');
              e.target.value = '';
          }
      });

      if (THIS.param.facility_id != '') {
        THIS.getAllInventory(THIS.param.facility_id);
      }
    }, 1000);
  }

  InvLabData = [];
  InvLabDataLoading = false;
  LabourData = [];
  INVT_projectItem_start = 0;
  INVT_projectItem_end = 0;
  INVT_projectItem_pageNo = 1;
  InvLabDataTotal = 0;
  InvLabEmpty = 0;

  getAllInventory(facility_id) {
    const THIS = this;
    this.InvLabData.length = 0;
    this.InvLabDataTotal = 0;
    THIS.InvLabEmpty = 0;
    let ladda = null;
    const ldd = document.getElementById('palLoad');
    if (ldd != undefined && ldd != null) {
      ladda = Ladda.create(ldd);
      THIS.InvLabDataLoading = true;
      ladda.start();
    }
    const param = {
      facility_id: facility_id
    };
    $.ajax({
      url: THIS.APPCONFIG.api + 'inventory/getAll?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        if (res.status == 2000) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]['pick'] = '';
            const index = THIS.attachInventories.map(function (element) {
              return element.inv_id;
            }).indexOf(res.data[i]['id']);
            if (index > -1) {
              if (THIS.attachInventories[index].type_id == 0) {
                res.data[i]['pick'] = THIS.attachInventories[index].pick;
              }
            }
          }
          THIS.InvLabData = res.data;
          THIS.InvLabDataLoading = false;
          THIS.InvLabDataTotal = res.data.length;

          THIS.INVT_projectItem_start = 1;
          THIS.INVT_projectItem_end = 10;
          THIS.INVT_projectItem_pageNo = 1;
          if (THIS.INVT_projectItem_end > THIS.InvLabData.length) {
            THIS.INVT_projectItem_end = THIS.InvLabData.length;
          }
        }
        if (res.data.length == 0) {
          THIS.InvLabEmpty = 1;
        }
      }
    });
  }

  INVT_item_pagination(type) {
    if (type == 'next') {
      this.INVT_projectItem_pageNo++;
      this.INVT_projectItem_start = (this.INVT_projectItem_pageNo - 1) * 10;
      this.INVT_projectItem_end = (this.INVT_projectItem_pageNo - 0) * 10;
      if (this.INVT_projectItem_end > this.InvLabData.length) {
        this.INVT_projectItem_end = this.InvLabData.length;
      }
    }
    if (type == 'prev') {
      if (this.INVT_projectItem_pageNo > 1) {
        this.INVT_projectItem_pageNo--;
        this.INVT_projectItem_start = (this.INVT_projectItem_pageNo - 1) * 10;
        this.INVT_projectItem_end = (this.INVT_projectItem_pageNo - 0) * 10;
        if (this.INVT_projectItem_end > this.InvLabData.length) {
          this.INVT_projectItem_end = this.InvLabData.length;
        }
      }
    }
  }

  updateInventory() {
    const ldd = document.getElementById('invUpBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    this.attachInventories.length = 0;
    const attachInventories = [];
    $.each(this.InvLabData, function (i, v) {
      if (v.pick != '' && v.sell_price != '' && v.pick != null && v.sell_price != null) {
        attachInventories.push({
          type_id: 0,
          inv_id: v.id,
          title: v.title,
          retail_price: v.sell_price,
          pick: v.pick,
        });
      }
    });
    $.each(this.LabourData, function (i, v) {
      if (v.pick != '' && v.rate != '' && v.pick != null && v.rate != null) {
        attachInventories.push({
          type_id: 1,
          inv_id: v.id,
          title: v.name,
          retail_price: v.rate,
          pick: v.pick,
        });
      }
    });
    this.attachInventories = attachInventories;
    setTimeout(() => {
        ladda.stop();
        (<any>$('#invLabModal')).modal('hide');
    }, 500);
  }


  addWorkAttach() {
    $('#workAttach').click();
  }

  removeThisAttach(index, id) {
    this.selectedWorkAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        const eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please trash some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }


  rightSecPreview = 0;
  pondOptions = {
    class: 'my-filepond',
    multiple: true,
    labelIdle: 'Drop files here',
    acceptedFileTypes: 'image/jpeg, image/png',
    instantUpload: true,
    server: {
      url: this.APPCONFIG.apiAuth,
      timeout: 30000,
      process: {
        url: 'media/upload?token=' + this.CustomHelper.getCurrentToken(),
        method: 'POST',
        headers: {
          'x-customheader': 'Hello World'
        },
        withCredentials: false,
        onload: (response) => {
          this.updateChangesDiscard();
          const res = JSON.parse(response);
          this.selectedFilesPreview.push(res);
        },
        onerror: (response) => console.log(response),
        ondata: (formData) => {
          formData.append('media_type', 1);
          return formData;
        }
      },
      revert: 'media/remove?token=' + this.CustomHelper.getCurrentToken(),
      restore: './restore/',
      load: './load/',
      fetch: './fetch/'
    }
  };
  pondFiles = [];

  pondHandleInit() {
    // console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any) {
    const accepted = event.file.file;
  }


  sorOpen() {
    this.getAllClients();

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#sorModal')).modal('show');
  }

  SOR_clients = [];
  SOR_client = '';

  getAllClients() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'sor/project/getAllSor?token=' + THIS.currentUser.token,
      type: 'get',
      data: {},
      success: function (res) {
        if (res.status == 2000) {
          THIS.SOR_clients = res.data;
          setTimeout(function () {
            (<any>$('.clientData')).chosen('destroy');
            (<any>$('.clientData')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Client'
            }).on('change', function (e, p) {
              if (p != undefined) {
                THIS.SOR_client = p.selected;
                THIS.getAllProjects();
              }
            });
            (<any>$('.projectData')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Project'
            });
          }, 100);
        }
      }
    });
  }

  SOR_projects = [];
  SOR_project = '';

  getAllProjects() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'sor/project/getAll?token=' + THIS.currentUser.token,
      type: 'post',
      data: {sor_id: THIS.SOR_client, personId: THIS.currentUser.id, onlyMe: 1},
      success: function (res) {
        if (res.status == 2000) {
          THIS.SOR_projects = res.data;
          setTimeout(function () {
            (<any>$('.projectData')).chosen('destroy');
            (<any>$('.projectData')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Project'
            }).on('change', function (e, p) {
              if (p != undefined) {
                THIS.SOR_project = p.selected;
                THIS.getAllProjectItems();
              }
            });
          }, 100);
        }
      }
    });
  }

  SOR_projectItems = [];
  SOR_projectItem_start = 0;
  SOR_projectItem_end = 10;
  SOR_projectItem_pageNo = 1;
  SOR_empty = 0;

  getAllProjectItems() {
    const THIS = this;

    this.SOR_projectItem_start = 0;
    this.SOR_projectItem_end = 10;
    this.SOR_projectItem_pageNo = 1;

    const index = this.SOR_projects.map(function (e) {
      return e.id.toString();
    }).indexOf(THIS.SOR_project);
    if (index > -1) {
      this.SOR_empty = 0;
      this.SOR_projectItems = this.SOR_projects[index].info;
      if (this.SOR_projectItems.length == 0) {
        this.SOR_empty = 1;
      }
    }
  }

  SOR_pro_item_pagination(type) {
    if (type == 'next') {
      this.SOR_projectItem_pageNo++;
      this.SOR_projectItem_start = (this.SOR_projectItem_pageNo - 1) * 10;
      this.SOR_projectItem_end = (this.SOR_projectItem_pageNo - 0) * 10;
      if (this.SOR_projectItem_end > this.SOR_projectItems.length) {
        this.SOR_projectItem_end = this.SOR_projectItems.length;
      }
    }
    if (type == 'prev') {
      if (this.SOR_projectItem_pageNo > 1) {
        this.SOR_projectItem_pageNo--;
        this.SOR_projectItem_start = (this.SOR_projectItem_pageNo - 1) * 10;
        this.SOR_projectItem_end = (this.SOR_projectItem_pageNo - 0) * 10;
        if (this.SOR_projectItem_end > this.SOR_projectItems.length) {
          this.SOR_projectItem_end = this.SOR_projectItems.length;
        }
      }
    }
  }

  saveSorUserData() {
    const THIS = this;
    const Data = this.SOR_projectItems;

    const items = [];
    $.each(Data, function (i, v) {
      if (v.wo_qty != null && v.wo_qty != '' && v.wo_qty > 0) {
        items.push(v);
      }
    });
    THIS.param.sor = items;
    (<any>$('#sorModal')).modal('hide');
  }

  ngOnInit() {
    const THIS = this;
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_fac_' + Session.team, function (v) {
        THIS.getAllFacility();
    });

    this.socket.on('_rfr_team_data_asset_' + Session.team, function (v) {
        THIS.asset_get();
    });

    this.socket.on('_rfr_team_data_jobType_' + Session.team, function (v) {
        THIS.jt_get();
    });

    this.socket.on('_rfr_team_data_jobStatus_' + Session.team, function (v) {
        THIS.getAllJobStatus(1);
    });*/

    $('.reRenderFacility').on('click', function () {
      THIS.getAllFacility();
    });

    $('.reRenderJobStatus').on('click', function () {
      THIS.getAllJobStatus(0);
    });

    $('.reRenderJobType').on('click', function () {
      THIS.jt_get();
    });

    $('.reRenderAssets').on('click', function () {
      THIS.asset_get();
    });

    $('.reRenderPerson').on('click', function () {
      THIS.getAllSupplier();
    });
  }

  ngOnDestroy() {
  }


  UID_IMG = 0;
  // =============================
  // Photo Editor
  // =============================
  CANVAS = null;
  SHAPE = 'brush';
  IMAGE_PATH = '';
  CanvasTarget = 0;

  discardEditor() {
    const THIS = this;
    const modal = (<any>$('#photoEditorModal'));
    if (THIS.CANVAS != null) {
      THIS.CANVAS.dispose();
    }
    modal.modal('hide');

  }

  editImgCallUnique = 0;
  EditCurrentCanvas = null;
  CurrentEditPhoto = null;

  openPhotoEditor(preview) {
    const THIS = this;
    THIS.CurrentEditPhoto = preview;
    THIS.CanvasTarget = 0;
    const modal = (<any>$('#photoEditorModal'));
    const canvasW = window.innerWidth - 20;
    const canvasH = window.innerHeight - 130;
    $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
    if (THIS.CANVAS != null) {
      this.CANVAS.clear();
    }
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    const param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

    if (THIS.editImgCallUnique == 0) {
      THIS.editImgCallUnique = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          THIS.editImgCallUnique = 0;
          if (res.status === 2000) {
            THIS.EditCurrentCanvas = res.data;
            const canvasW = res.data.width;
            const canvasH = res.data.height;
            $('#canvas').attr('width', canvasW + 'px');
            $('#canvas').attr('height', canvasH + 'px');
            setTimeout(function () {
              // if(THIS.CANVAS != null){
              //     THIS.CANVAS.dispose();
              // }

              THIS.CANVAS = new fabric.Canvas('canvas');
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.on('object:selected', function (e) {
                THIS.CANVAS.isDrawingMode = false;
                THIS.SHAPE = '';
              });
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.isDrawingMode = true;
              THIS.CANVAS.freeDrawingBrush.width = 5;

              let startY = 0,
                startX = 0;
              THIS.CANVAS.on('mouse:down', function (option) {
                startY = option.e.offsetY;
                startX = option.e.offsetX;
              });
              THIS.CANVAS.on('mouse:up', function (option) {
                const selected = THIS.CANVAS.getActiveObjects();
                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                  if (THIS.SHAPE === 'circle') {
                    const e = option.e;
                    if (e.offsetX - startX > 5) {
                      const circle = new fabric.Circle({
                        top: startY,
                        left: startX,
                        radius: (e.offsetX - startX) / 2,
                        stroke: THIS.CANVAS.freeDrawingBrush.color,
                        strokeWidth: 5,
                        fill: ''
                      });
                      THIS.CANVAS.add(circle);
                    }
                  }
                }
              });
              THIS.CustomHelper.tooltipRender();

              THIS.IMAGE_PATH = preview.file_path;
              const src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
              $('.canvas-container').css({
                backgroundImage: 'url(\'' + src + '\')'
              });
              THIS.CANVAS.renderAll();
              setTimeout(function () {
                THIS.CanvasTarget = 1;
              }, 1000);
            }, 1000);
          }
        }
      });


      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        modal.modal('hide');
        history.go(1);
      };

      if ($('.color-picker-editor').length > 0) {
        Pickr.create({
          el: '.color-picker-editor',
          theme: 'nano', // or 'monolith', or 'nano'
          defaultRepresentation: 'HEX',
          useAsButton: true,
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: true,
              input: true,
              save: true
            }
          }
        }).on('change', (color, instance) => {
          $('.color-picker-editor').css('color', color.toHEXA().toString());
          THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
          THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
          });
        });
      }
      $('.color-picker-editor').css('color', '#ff3773');
    }
  }

  // =============================
  // Photo Editor Actions
  // =============================
  SketchEnable() {
    this.CANVAS.isDrawingMode = !this.CANVAS.isDrawingMode;
    if (this.CANVAS.isDrawingMode === true) {
      this.SHAPE = 'brush';
    }
  }

  eraser() {
    const THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    const obj = THIS.CANVAS.getObjects();
    for (let i = 0; i < obj.length; i++) {
      $.each(obj, function (j, v) {
        if (parseInt(j) > 0) {
          THIS.CANVAS.remove(v);
        }
      });
    }
    THIS.UID_IMG = Date.now();
    const src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
    $('.canvas-container').css({
      backgroundImage: 'url(\'' + src + '\')'
    });
  }

  eraserSelected() {
    const THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
      THIS.CANVAS.remove(obj);
    });
  }

  drawCircle() {
    const THIS = this;
    this.CANVAS.isDrawingMode = false;
    this.SHAPE = 'circle';
    const circle = new fabric.Circle({
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      radius: 50,
      stroke: THIS.CANVAS.freeDrawingBrush.color,
      strokeWidth: 5,
      fill: ''
    });
    THIS.CANVAS.add(circle);
  }

  UpdatePhoto(iid) {
    const THIS = this;
    const ldd = document.getElementById(iid);
    const ladda = Ladda.create(ldd);
    ladda.start();
    THIS.CANVAS.discardActiveObject();
    THIS.CANVAS.requestRenderAll();
    setTimeout(function () {
      const modal = (<any>$('#photoEditorModal'));
      const canvas = (<any>document.getElementById('canvas'));
      let imgData = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      imgData = imgData.replace('data:image/png;base64,', '');
      const param = {
        imgData: imgData,
        media_type: 1,
        file_path: THIS.IMAGE_PATH
      };
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          ladda.stop();
          modal.modal('hide');
          THIS.UID_IMG = Date.now();
        }
      });
    }, 1000);
  }


  checkCurrency() {
    Inputmask.extendAliases({
      pesos: {
        prefix: `${this.currencyIcon()}`,
        groupSeparator: '',
        alias: 'numeric',
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        radixPoint: '.',
        placeholder: '0.00'
      }
    });

    setTimeout(function () {
      (<any>$('._currency_validate_eq')).inputmask({alias: 'pesos'});
    }, 300);
  }

  // showUploadOption: any = 1;

  // showUpload() {
  //   if (this.showUploadOption === 1) {
  //     this.showUploadOption = 0;
  //   } else {
  //     this.showUploadOption = 1;
  //   }
  // }

  cancelSIModal() {
    (<any>$('#SIModal')).modal('hide');
  }

  cancelInvLabModal() {
    (<any>$('#invLabModal')).modal('hide');
  }

  AssetOption: any = 1;

  showAssets() {
    if (this.AssetOption === 1) {
      this.AssetOption = 0;
    } else {
      this.AssetOption = 1;
    }
  }
  currencyIcon(): string {
      if (this.currentUser.settings.currency == 'USD') {
        return '$';
      } else {
        return this.currentUser.settings.currency + ' ';
      }
  }

}
