import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';

declare let swal: any;
declare let Ladda: any;

@Component({
  selector: 'my-page-person-reset',
  styles: [],
  templateUrl: './resetPass.component.html'
})

export class ResetPassComponent {
  passType: any = 'password';
  confirmationPassType: any = 'password';
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  user = {
    pin: '',
    password: '',
    password_confirmation: ''
  };
  loading = false;
  errorMessage = '';

  mobileMenu = false;

  constructor(private router: Router) {
    if (localStorage.getItem('currentUserInfo') !== null) {
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      this.router.navigate([Session.team + '/dashboard']);
    }
    let comp = this.CustomHelper.getRouteStr();
    this.user.pin = comp[3];
  }

  resetPass() {
    let ldd = document.getElementById('submitBTN');
    let ladda = Ladda.create(ldd);
    let THIS = this;
    let param = THIS.user;
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'resetPass',
      type: 'post',
      data: param,
      success: function (res) {
        ladda.stop();
        console.log(res);
        if (res.status == 5000) {
          THIS.errorMessage = res.error;
        }
        if (res.status == 2000) {
          THIS.errorMessage = '';
          swal({
            title: 'Reset Password',
            text: res.msg,
            icon: 'success',
            dangerMode: true,
            className: 'swal-btn-center'
          }).then(function (res) {
            if (res) {
              THIS.router.navigate(['']);
            }
          });
        }
      }
    });
  }

  changePassType() {
    if (this.passType === 'password') {
      this.passType = 'text';
    } else if (this.passType === 'text') {
      this.passType = 'password';
    }
  }

  changePassTypeConfirm() {
    if (this.confirmationPassType === 'password') {
      this.confirmationPassType = 'text';
    } else if (this.confirmationPassType === 'text') {
      this.confirmationPassType = 'password';
    }
  }

}
