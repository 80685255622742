import {Component, Input, Output, EventEmitter} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {FacilityService} from '../../../_services/facility.service';
import {InventoriesService} from '../../../_services/inventories.service';
import {MediaService} from '../../../_services/media.service';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let fabric: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;
declare let Pickr: any;
declare let Inputmask: any;

@Component({
  selector: 'page-inventory-create',
  templateUrl: './inventory-create.component.html'
})
export class InventoryCreateComponent {
  @Output() discard = new EventEmitter<number>();
  @Output() preview = new EventEmitter<number>();
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  viewDates = 1;
  inlinePrefixId = 0;
  showPhotos: any = false;

  togglePhoto() {
    if (this.showPhotos === false) {
      this.showPhotos = true;
    } else {
      this.showPhotos = false;
    }
  }

  updateChangesDiscard() {
    let THIS = this;
    setTimeout(function () {
      THIS.validationQuickForm();
    }, 500);
  }

  ContractStartOption = {
    altInput: true,
    defaultDate: null,
    minDate: null,
    altFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };
  ContractEndOption = {
    altInput: true,
    defaultDate: null,
    minDate: null,
    altFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };
  param = {
    inv_id: '',
    prefix: '',
    title: '',
    store_id: '',
    facility_id: '',
    media_files: '',
    attach_files: '',
    qty: '1',
    purchase_cost: '',
    sell_price: '',
  };

  validForm = 0;
  submitCreate = 0;

  saveWorkOrderSubmit() {
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      let freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        let freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    let ldd = document.getElementById('createInv');
    let ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    let mediaFiles = '';
    $.each(THIS.selectedFilesPreview, function (i, v) {
      if (i == 0) {
        mediaFiles += v.id;
      } else {
        mediaFiles += ',' + v.id;
      }
    });
    this.param.media_files = mediaFiles;
    let attachFiles = '';
    $.each(THIS.selectedAssetAttach, function (i, v) {
      if (i == 0) {
        attachFiles += v.id;
      } else {
        attachFiles += ',' + v.id;
      }
    });
    this.param.attach_files = attachFiles;
    console.log(this.param.purchase_cost);
    console.log(((<any>$('input[name="purchase_cost"]')).val()).replace(/\$/, ''));
    console.log(((<any>$('input[name="purchase_cost"]')).val()).replace(this.currencyIcon(), ''));
    console.log(this.currencyIcon().length);
    if (this.currencyIcon() == 'AED ' || this.currencyIcon() == 'AUD ') {
      console.log(1);
      this.param.purchase_cost = ((<any>$('input[name="purchase_cost"]')).val()).replace(this.currencyIcon(), '');
      this.param.sell_price = ((<any>$('input[name="sell_price"]')).val()).replace(this.currencyIcon(), '');
    } else {
      console.log(2);
      this.param.purchase_cost = ((<any>$('input[name="purchase_cost"]')).val()).replace(/\$/, '');
      this.param.sell_price = ((<any>$('input[name="sell_price"]')).val()).replace(/\$/, '');
    }
    let valid = this.validationThisForm();
    if (valid == 1) {
      this.submitCreate = 1;
      this.inventoriesService.createInventory(this.param).subscribe(res => {
        ladda.stop();
        this.submitCreate = 0;
        if (res.status == 7000) {
          swal('The Asset ID is already been taken. Please use a unique one');
        } else if (res.status == 2000) {
          this.preview.emit(res.data);
          toastr.success('New inventory has been successfully created', 'New Inventory', this.toastrOption);
        }
      });
    }
  }

  validationThisForm() {
    let valid = 1;
    let form = $('#workCreateForm');
    if (this.param.facility_id == '') {
      valid = 0;
      swal('Please select a facility');
      return valid;
    }
    // if (this.param.prefix == '') {
    //     valid = 0;
    //     swal("Please use a prefix");
    //     return valid;
    // }
    // if (this.param.inv_id == '') {
    //     valid = 0;
    //     swal("Please provide an inventory ID");
    //     return valid;
    // }
    if (this.param.title == '') {
      valid = 0;
      swal('Please titled this inventory title');
      return valid;
    }
    if (parseInt(this.param.qty) < 1) {
      valid = 0;
      swal('Quantity can not be less than 1');
      return valid;
    }
    this.validForm = valid;
    return valid;
  }

  validationQuickForm() {
    let valid = 1;
    let form = $('#workCreateForm');
    if (this.param.facility_id == '') {
      valid = 0;
    }
    // if (this.param.prefix == '') {
    //     valid = 0;
    //     return valid;
    // }
    // if (this.param.inv_id == '') {
    //     valid = 0;
    //     return valid;
    // }
    if (this.param.title == '') {
      valid = 0;
    }
    if (parseInt(this.param.qty) < 1 || isNaN(parseInt(this.param.qty))) {
      this.param.qty = '0';
      valid = 0;
    } else {
      this.param.qty = parseInt(this.param.qty).toString();
    }
    this.validForm = valid;
    return valid;
  }

  clearValidation(e) {
    let trigger = $(e.target);
    let target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  getLastPreId() {
    let THIS = this;
    this.inventoriesService.getLastPreId().subscribe(res => {
      if (res.status == 2000) {
        this.param.prefix = res.data.prefix;
        this.param.inv_id = res.data.inv_id;
      }
    });
  }


  currentUser;

  constructor(private inventoriesService: InventoriesService,
              private mediaService: MediaService,
              private facilityService: FacilityService,
              public snackBar: MatSnackBar) {
    this.getLastPreId();
    this.getAllFacility();

    let THIS = this;
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.checkCurrency();
  }

  Facility = [];
  FacilityComp = [];

  getAllFacility() {
    let THIS = this;
    this.facilityService.getFacilitiesList().subscribe(res => {
      this.Facility.length = 0;
      this.FacilityComp.length = 0;
      this.FacilityComp = res.facility;
      $.each(res.facility, function (i, v) {
        v['pp'] = 0;
        THIS.Facility.push(v);
        $.each(v.child, function (j, vC) {
          vC['pp'] = 1;
          THIS.Facility.push(vC);
          $.each(vC.child, function (k, vCC) {
            vCC['pp'] = 2;
            THIS.Facility.push(vCC);
            $.each(vCC.child, function (l, vCCC) {
              vCCC['pp'] = 3;
              THIS.Facility.push(vCCC);
            });
          });
        });
      });
      setTimeout(function () {
        if ($('.reRenderFacility').length > 0) {
          let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
          if (facilityId > 0) {
            THIS.param.facility_id = facilityId.toString();
            (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
          }
        }
        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'select facility'
        }).on('change', function (e, p) {
          if (e.target.value === '000') {
            (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
            (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
            let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            let child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#createFacilityModal')).modal('show');
          } else {
            THIS.param.facility_id = e.target.value;
            THIS.validationQuickForm();
            THIS.updateChangesDiscard();
          }
        });
      }, 1000);
    });
  }

  selectedFiles = [];
  selectedFilesPreview = [];

  removeThisMedia(id, media_type) {
    let param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  removeThisImage(index, id) {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesPreview.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  _uploadError = 0;

  uploadThisMedia(file, media_type, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  dragFilesDropped(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    if (e.dataTransfer.files.length > 0) {
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        if (this.selectedFiles.length < 1) {
          let eachFile = e.dataTransfer.files[i];
          this.selectedFiles.push(eachFile);
          if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            this.uploadThisMedia(eachFile, '1', function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please delete some files',
                    icon: 'error',
                    dangerMode: true,
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
              }
            });
          } else {
            this._uploadError = 1;
          }
        }
      }
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    let accepted = e.target.files;
    for (var i = 0; i < accepted.length; i++) {
      if (this.selectedFiles.length < 1) {
        let eachFile = accepted[i];
        this.selectedFiles.push(eachFile);
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          this.uploadThisMedia(eachFile, '1', function (uploaded) {
            if (uploaded.status != undefined) {
              if (uploaded.status == 9000) {
                swal({
                  title: 'Storage Full',
                  text: 'Your Storage is full. Please delete some files',
                  icon: 'error',
                  dangerMode: true,
                });
              } else {
                swal({
                  title: 'Invalid file format uploaded',
                  text: 'Please upload an image in Jpeg or Png only.',
                  icon: 'error',
                  dangerMode: true,
                });
              }
            } else {
              THIS.selectedFilesPreview.push(uploaded);
            }
          });
        } else {
          this._uploadError = 1;
        }
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  dragFileOverStart() {
    let trigger = $('#uploadDragWork');
    trigger.addClass('onEnter');
  }

  dragFileOverEnd() {
    let trigger = $('#uploadDragWork');
    trigger.removeClass('onEnter');
  }

  selectedAssetAttach = [];

  uploadConfileTrigger() {
    $('#fileCon').click();
  }

  uploadConFile(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (var i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      this.uploadThisMedia(eachFile, '4', function (uploaded) {
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please delete some files',
              icon: 'error',
              dangerMode: true,
            });
          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
              icon: 'error',
              dangerMode: true,
            });
          }
        } else {
          THIS.selectedAssetAttach.push(uploaded);
        }
      });
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  removeThisAttach(index, id) {
    this.selectedAssetAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  addWorkAttach() {
    $('#workAttach').click();
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedAssetAttach.length < 3) {
        let eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.selectedAssetAttach.push(uploaded);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  confirmDiscard() {
    this.discard.emit(1);
  }

  intcheck(e) {
    let v = e.target.value;
    v = this.CustomHelper.makeFloat(v);
    if (isNaN(v)) {
      v = 0;
    }
    e.target.value = v;
  }

  rightSecPreview = 0;

  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_fac_' + Session.team, function (v) {
        THIS.getAllFacility();
    });*/
    $('.reRenderFacility').on('click', function () {
      THIS.getAllFacility();
    });
  }

  ngOnDestroy() {
  }


  UID_IMG = 0;
  // =============================
  // Photo Editor
  // =============================
  CANVAS = null;
  SHAPE = 'brush';
  IMAGE_PATH = '';
  CanvasTarget = 0;

  discardEditor() {
    let THIS = this;
    let modal = (<any>$('#photoEditorModal'));
    if (THIS.CANVAS != null) {
      THIS.CANVAS.dispose();
    }
    modal.modal('hide');

  }

  editImgCallUnique = 0;
  EditCurrentCanvas = null;
  CurrentEditPhoto = null;

  openPhotoEditor(preview) {
    let THIS = this;
    THIS.CurrentEditPhoto = preview;
    THIS.CanvasTarget = 0;
    let modal = (<any>$('#photoEditorModal'));
    let canvasW = window.innerWidth - 20;
    let canvasH = window.innerHeight - 130;
    $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
    if (THIS.CANVAS != null) {
      this.CANVAS.clear();
    }
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    let param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

    if (THIS.editImgCallUnique == 0) {
      THIS.editImgCallUnique = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          THIS.editImgCallUnique = 0;
          if (res.status === 2000) {
            THIS.EditCurrentCanvas = res.data;
            let canvasW = res.data.width;
            let canvasH = res.data.height;
            $('#canvas').attr('width', canvasW + 'px');
            $('#canvas').attr('height', canvasH + 'px');
            setTimeout(function () {
              // if(THIS.CANVAS != null){
              //     THIS.CANVAS.dispose();
              // }

              THIS.CANVAS = new fabric.Canvas('canvas');
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.on('object:selected', function (e) {
                THIS.CANVAS.isDrawingMode = false;
                THIS.SHAPE = '';
              });
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.isDrawingMode = true;
              THIS.CANVAS.freeDrawingBrush.width = 5;

              let startY = 0,
                startX = 0;
              THIS.CANVAS.on('mouse:down', function (option) {
                startY = option.e.offsetY;
                startX = option.e.offsetX;
              });
              THIS.CANVAS.on('mouse:up', function (option) {
                let selected = THIS.CANVAS.getActiveObjects();
                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                  if (THIS.SHAPE === 'circle') {
                    let e = option.e;
                    if (e.offsetX - startX > 5) {
                      let circle = new fabric.Circle({
                        top: startY,
                        left: startX,
                        radius: (e.offsetX - startX) / 2,
                        stroke: THIS.CANVAS.freeDrawingBrush.color,
                        strokeWidth: 5,
                        fill: ''
                      });
                      THIS.CANVAS.add(circle);
                    }
                  }
                }
              });
              THIS.CustomHelper.tooltipRender();

              THIS.IMAGE_PATH = preview.file_path;
              let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
              $('.canvas-container').css({
                backgroundImage: 'url(\'' + src + '\')'
              });
              THIS.CANVAS.renderAll();
              setTimeout(function () {
                THIS.CanvasTarget = 1;
              }, 1000);
            }, 1000);
          }
        }
      });


      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        modal.modal('hide');
        history.go(1);
      };

      if ($('.color-picker-editor').length > 0) {
        Pickr.create({
          el: '.color-picker-editor',
          theme: 'nano', // or 'monolith', or 'nano'
          defaultRepresentation: 'HEX',
          useAsButton: true,
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: true,
              input: true,
              save: true
            }
          }
        }).on('change', (color, instance) => {
          $('.color-picker-editor').css('color', color.toHEXA().toString());
          THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
          THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
          });
        });
      }
      $('.color-picker-editor').css('color', '#ff3773');
    }
  }

  // =============================
  // Photo Editor Actions
  // =============================
  SketchEnable() {
    this.CANVAS.isDrawingMode = !this.CANVAS.isDrawingMode;
    if (this.CANVAS.isDrawingMode === true) {
      this.SHAPE = 'brush';
    }
  }

  eraser() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    let obj = THIS.CANVAS.getObjects();
    for (let i = 0; i < obj.length; i++) {
      $.each(obj, function (j: any, v) {
        if (parseInt(j) > 0) {
          THIS.CANVAS.remove(v);
        }
      });
    }
    THIS.UID_IMG = Date.now();
    let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
    $('.canvas-container').css({
      backgroundImage: 'url(\'' + src + '\')'
    });
  }

  eraserSelected() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
      THIS.CANVAS.remove(obj);
    });
  }

  drawCircle() {
    let THIS = this;
    this.CANVAS.isDrawingMode = false;
    this.SHAPE = 'circle';
    let circle = new fabric.Circle({
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      radius: 50,
      stroke: THIS.CANVAS.freeDrawingBrush.color,
      strokeWidth: 5,
      fill: ''
    });
    THIS.CANVAS.add(circle);
  }

  UpdatePhoto(iid) {
    let THIS = this;
    let ldd = document.getElementById(iid);
    let ladda = Ladda.create(ldd);
    ladda.start();
    THIS.CANVAS.discardActiveObject();
    THIS.CANVAS.requestRenderAll();
    setTimeout(function () {
      let modal = (<any>$('#photoEditorModal'));
      let canvas = (<any>document.getElementById('canvas'));
      let imgData = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      imgData = imgData.replace('data:image/png;base64,', '');
      let param = {
        imgData: imgData,
        media_type: 1,
        file_path: THIS.IMAGE_PATH
      };
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          ladda.stop();
          modal.modal('hide');
          THIS.UID_IMG = Date.now();
        }
      });
    }, 1000);
  }

  checkCurrency() {
    Inputmask.extendAliases({
      pesos: {
        prefix: `${this.currencyIcon()}`,
        groupSeparator: '',
        alias: 'numeric',
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        radixPoint: '.',
        placeholder: '0.00'
      }
    });

    setTimeout(function () {
      (<any>$('._currency_validate_eq')).inputmask({alias: 'pesos'});
    }, 300);
  }

  currencyIcon() {
    if (this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }
}
