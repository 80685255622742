import { Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
    selector: 'create-email',
    templateUrl: './createEmail.component.html'
})
export class CreateEmailComponent {
    modalRef: BsModalRef;
    @Output() discard = new EventEmitter<number>();

    constructor(private modalService: BsModalService) {}
    toolbarOptions = [
        ['bold', 'italic', 'underline'],        // toggled buttons
        ['blockquote', 'code-block'],

        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        // [{ 'font': [] }],
        [{ 'align': [] }],

        // ['clean']                                         // remove formatting button
    ];
    customers = [
        { name :  'jim.andrews@gmail.com'},
        { name :  'harry.houdini@gmail.com'},
        { name :  'michael.schofields@gmail.com'},
        { name :  'nelly.nell@gmail.com'},
        { name :  'chris.hayes@gmail.com'},
        { name :  'steve.hodor@gmail.com'},
    ];

    sentToSettings = {
        singleSelection: false,
        text:"Sent To",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"
    };
    ccToSettings = {
        singleSelection: false,
        text:"CC",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"
    };
    sendToList = [
        {"id":1,"itemName":"jim.andrews@gmail.com"},
        {"id":2,"itemName":"harry.houdini@gmail.com"},
        {"id":3,"itemName":"michael.schofields@gmail.com"},
        {"id":4,"itemName":"nelly.nell@gmail.com"},
        {"id":5,"itemName":"chris.hayes@gmail.com"},
        {"id":6,"itemName":"steve.hodor@gmail.com"}
    ];
    sentTo = [];
    ccTo = [];
    addSentToCustom(e){

    }

    openSettingsModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, Object.assign({}, {}, { class: 'modal-lg' }));
    }
    discardNewInvoice() {
        this.discard.emit(1);
    }

}
