import {Component, Input, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {MatSnackBar} from '@angular/material';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';

declare let Ladda: any;
declare let swal: any;
declare let collapse: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;
declare let toastr: any;

@Component({
    selector: 'page-library-single',
    templateUrl: './library-single.component.html'
})
export class LibrarySingleComponent {
    @Output() Template = new EventEmitter<number>();
    @Input() PreviewLibrary: any;
    @Input() libraryData: any;
    @Input() rightlibraryInfo: any;
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Permission = 1;
    LibraryDetailsChange = 0;
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true};

    libraryType = '';
    updateChangesDiscard() {
        let THIS = this;
    }


    currentUser: any;

    constructor(public snackBar: MatSnackBar,
                private mediaService: MediaService,
                private facilityService: FacilityService) {
        this.currentUser = JSON.parse(localStorage.currentUserInfo);
    }

    rightSecPreview = 0;
    rightLibInfo = 1;
    previewId = '';
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.PreviewLibrary != undefined) {
            this.LibraryDetailsChange = 0;
            let id = changes.PreviewLibrary.currentValue.id;
            if (id != '') {
              this.previewId = id;
              this.getSingleMedia(id, false);
            }
        }
    }

    confirmDiscard() {
        this.Template.emit(0);
    }

    openChildLibrary(library) {
        if (library.activity == 1) {
            this.LibraryDetailsChange = 0;
            this.getSingleMedia(library.id, false);
        } else {
            if (library.photo == 1) {
                this.open(library);
            } else {
                this.CustomHelper.openDocViewer(this.APPCONFIG.uploadPath + '/attachments/' + library.file_path);
            }
        }
    }

    openMobileChildLibrary(child_folder) {
        let THIS = this;
        if (window.innerWidth <= 991) {
            THIS.openChildLibrary(child_folder);
        }
    }

    backParentLibrary(parent_folder) {
        this.LibraryDetailsChange = 0;
        this.getSingleMedia(parent_folder, false);
    }

    getSingleMedia(id, showMsg = true) {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'media/library/get/single?token=' + THIS.currentUser.token,
            data: {id: id},
            type: 'post',
            success: function (res) {
                if (res.status == 2000) {
                    THIS.LibraryDetailsChange = 1;
                    THIS.libraryData = res.data;
                    THIS.rightlibraryInfo = res.data;
                    THIS.selectedLibrary = res.data;
                     if (showMsg) {
                       toastr.success('File has been created successfully', 'File Created', this.toastrOption);
                     }
                }
                setTimeout(() => {
                    (<any>$('[data-toggle="tooltip"]')).tooltip();
                }, 500);
            }
        });
    }

  searchKeyword = '';

  searchKeywordClear() {
    let THIS = this;
    THIS.searchKeyword = '';
  }
  filter() {
    let THIS = this;
    clearInterval(THIS.CustomHelper.woListSearch);
    THIS.CustomHelper.woListSearch = setInterval(function () {
      clearInterval(THIS.CustomHelper.woListSearch);
    }, 1000);
  }

    selectedLibrary = null;

    rightClickMenu(e, library) {
        e.preventDefault();
        this.selectedLibrary = library;
        let target = $(e.target);
        let w = target.closest('#right-click-holder').width();
        let h = target.closest('#right-click-holder').height();
        let p = target.closest('#right-click-holder').offset();
        let left = e.clientX - p.left;
        let top = e.clientY - p.top;
        let clickH = $('.drop-menu-right').height();

        let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
        if (ttarget.hasClass('open')) {
            $('.drop-menu-right').removeClass('open');
            ttarget.css({
                top: top,
                left: left
            });
            setTimeout(function () {
                ttarget.addClass('open');
            }, 100);
        } else {
            ttarget.css({
                top: top,
                left: left
            });
            ttarget.addClass('open');
        }
    }

    dropMenuRightToggle(e) {
        let container = $('.invoice-list');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.drop-menu-right').removeClass('open');
        }
    }


    selectedFiles = [];
    selectedFilesPreview = [];

    removeThisMedia(id, media_type) {
        let param = {
            id: id, media_type: media_type
        };
        this.mediaService.remove(param).subscribe(res => {
        });
    }

    removeThisImage(index, id) {
        this.selectedFiles.splice(index, 1);
        this.selectedFilesPreview.splice(index, 1);
        this.removeThisMedia(id, 1);
    }

    _uploadError = 0;
    _locPermit = 0;

    uploadThisMedia(file, media_type, localtion, callback) {
        let THIS = this;
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        formData.append('is_folder', "1");
        if (THIS.selectedLibrary != null) {
            if (THIS.selectedLibrary.activity == 1) {
                formData.append('folder_id', THIS.selectedLibrary.id);
            } else {
                formData.append('folder_id', THIS.libraryData.id);
            }
        } else {
            formData.append('folder_id', THIS.libraryData.id);
        }

        THIS.selectedLibrary = null;
        if (localtion != null) {
            formData.append('lat', localtion.lat);
            formData.append('lon', localtion.lon);
        }
        this.mediaService.create(formData).subscribe(res => {
            this.updateChangesDiscard();
            callback(res);
        });
    }

    addUploadFile() {
        $('#libraryAttachChi').click();
    }

    addChildFile() {
        $('#childFileUpload').click();
    }

    uploadLibraryAttach(e) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            let eachFile = accepted[i];
            this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
                if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                        swal({
                            title: "Storage Full",
                            text: 'Your Storage is full. Please delete some files',
                            icon: "error",
                            dangerMode: true,
                            className: 'swal-btn-center'
                        });
                    } else {
                        swal({
                            title: "Invalid file format uploaded",
                            text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
                            icon: "error",
                            dangerMode: true,
                            className: 'swal-btn-center'
                        });
                    }
                } else {
                    THIS.getSingleMedia(THIS.libraryData.id);
                }
            });
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }

    createNewFolderModal() {
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#createFolderModalChi')).modal('show');
    }

    newFolder = '';

    createNewFolder() {
        let THIS = this;
        let ldd = document.getElementById('folderCreate');
        let ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'media/library/create/folder?token=' + THIS.currentUser.token,
            data: {folder_id: THIS.libraryData.id, title: THIS.newFolder},
            type: 'post',
            success: function (res) {
                ladda.stop();
                if (res.status == 2000) {
                    THIS.newFolder = '';
                    THIS.getSingleMedia(THIS.libraryData.id, false);
                    toastr.success(res.msg, 'Folder Created', this.toastrOption);
                    (<any>$('#createFolderModalChi')).modal('hide');
                }
            }
        });
    }


    openThisLibrary(library) {
        this.rightlibraryInfo = library;
        this.selectedLibrary = library;
        /*if (library.activity == 1) {
            this.rightlibraryInfo = library;
        } else {
            if (library.photo == 1) {
                this.open(library);
            } else {
                this.CustomHelper.openDocViewer(this.APPCONFIG.uploadPath + '/attachments/' + library.file_path);
            }
        }*/
    }

    UID_IMG = 0;
    Gallery = null;
    Preview;

    open(library) {
        let THIS = this;
        THIS.Preview = library;
        // $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
        let _albums = [];
        const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + library.file_path + '&ref=' + Date.now();
        const caption = '';
        const thumb = '';
        const album = {
            src: src,
            caption: caption,
            thumb: thumb,
            w: library.width !== undefined ? library.width : 1500,
            h: library.height !== undefined ? library.height : 1500
        };
        _albums.push(album);
        let pswpElement = document.querySelectorAll('.pswp')[0];
        let options = {
            index: 0,
            shareEl: false,
            editEl: false,
            downEl: true,
            tapToClose: false,
            zoomEl: true,
            history: false
        };
        THIS.Gallery = null;
        THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
        THIS.Gallery.init();
        // THIS.Gallery.listen('afterChange', function(r) {
        //   THIS.Preview = library.media_files[THIS.Gallery.getCurrentIndex()];
        //   $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
        // });
        history.pushState(null, null, location.href);
        window.onpopstate = function () {
            THIS.Gallery.close();
            history.go(1);
        };

    }

    editFolderModal() {
      let THIS = this;
      if(THIS.libraryType == 'folder') {
        if (this.selectedIds.length == 1) {
          let index = this.libraryData.child_folders.map(function (e) {
            return e.id;
          }).indexOf(THIS.selectedIds[0]);
          this.selectedLibrary = JSON.parse(JSON.stringify(this.libraryData.child_folders[index]));
        }
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
          if ($(v).find('a.active').length > 0) {
            ___selectedTab = i;
          }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#editFolderModal')).modal('show');
      }
    }

    editFolder() {
        if (this.selectedLibrary != null) {
            let THIS = this;
            let ldd = document.getElementById('folderEdit');
            let ladda = Ladda.create(ldd);
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.apiAuth + 'media/library/edit/folder?token=' + THIS.currentUser.token,
                data: {title: THIS.selectedLibrary.attrs.name, id: THIS.selectedLibrary.id, folder_id: THIS.selectedLibrary.folder_id},
                type: 'post',
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                        THIS.selectedLibrary = null;
                        THIS.selectedIds = [];
                        THIS.getSingleMedia(THIS.libraryData.id, false);
                        (<any>$('#editFolderModal')).modal('hide');
                        toastr.success(res.msg, 'Folder Update', this.toastrOption);
                    }
                }
            });
        }
    }

    deleteFolderModal() {
      let THIS =  this;
      const ldd = document.getElementById('removeWorkOrderBtn');
      let ladda = Ladda.create(ldd);
      ladda.start();
      if (this.selectedLibrary != null || this.selectedIds.length) {
        let ids = [];
        if (this.selectedIds.length === 0) {
          ids = [THIS.selectedLibrary.id];
        } else {
          ids = THIS.selectedIds;
        }
        $.ajax({
          url: THIS.APPCONFIG.apiAuth + 'media/library/delete/folder?token=' + THIS.currentUser.token,
          data: {ids: ids},
          type: 'post',
          success: function (res) {
            ladda.stop();
            THIS.selectedIds.length = 0;
            if (res.status == 2000) {
              THIS.selectedLibrary = null;
              THIS.getSingleMedia(THIS.previewId);
              THIS.rightlibraryInfo = null;
              (<any>$('#deleteWorkOrderModal')).modal('hide');
            }
          }
        });
      }
    }
  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }
  DeleteThisFile(id: any) {
    const THIS = this;
    THIS.rightlibraryInfo = null;
  }
  selectedIds = [];
  selectAllCheck(){
    let THIS = this;
    let Library = THIS.libraryData.child_folders;
    if(THIS.selectedIds.length == Library.length){
      THIS.selectedIds = [];
    } else {
      THIS.selectedIds = [];
      $.each(Library, function(i, v){
        THIS.selectedIds.push(v.id);
      });
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }
  checkThisRow(e,library){
    e.preventDefault();
    let THIS = this;
    THIS.libraryType = library.attrs.ext;
    let index = this.selectedIds.indexOf(library.id);
    if(index > -1){
      if(this.selectedIds.length == 1){
        this.selectedIds.length = 0;
      } else {
        this.selectedIds.splice(index, 1);
      }
    } else {
      this.selectedIds.push(library.id);
    }
    $('.drop-menu-right').removeClass('open');
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }
    downloadThisFile() {
        let THIS = this;
      this.selectedLibrary = THIS.selectedLibrary;
      if(THIS.selectedLibrary != null){
        let win = window.open(THIS.APPCONFIG.apiAuth + 'media/library/get/single/download/' + THIS.selectedLibrary.id + '?token=' + THIS.currentUser.token, '_blank');
        win.focus();
      }
    }

  DownloadThisFile() {
    let THIS: any = this;
    THIS.selectedIds.push(this.selectedLibrary.id);
    THIS.downloadThisFile();
  }


    selectedItemLib = null;
    selectedFolderLib = null;

    onItemDragStart(event) {
        let cls = event.currentTarget.classList.value.split(' ');
        let ind = cls.indexOf('moving');
        if (ind == -1) {
            event.currentTarget.classList += ' moving';
        }
        let id = event.currentTarget.id;
        let index = id.replace('lib-drag-', '');
        this.selectedItemLib = this.libraryData.child_folders[index];

    }

    onItemDragEnd(event) {
        $('.SingleLibraryTable-eachItem').removeClass('moving');
        $('.SingleLibraryTable-eachItem').removeClass('onitemover');

        let THIS = this;
        var param = {
            id: this.selectedItemLib.id,
            folder_id: this.selectedFolderLib.id
        };
        if (param.id != param.folder_id) {
            $.ajax({
                url: THIS.APPCONFIG.apiAuth + 'media/library/edit/folder?token=' + THIS.currentUser.token,
                data: param,
                type: 'post',
                success: function (res) {
                    if (res.status == 2000) {
                        THIS.getSingleMedia(THIS.libraryData.id);
                        if (THIS.selectedItemLib.activity == 1) {
                            toastr.success('Your folder has been moved', 'Folder Moved', this.toastrOption);
                        } else {
                            toastr.success('Your file has been moved', 'File Moved', this.toastrOption);
                        }
                    }
                }
            });
        }

    }

    onItemDragEnter(event) {
        $('.SingleLibraryTable-eachItem').removeClass('onitemover');
        let cls = event.currentTarget.classList.value.split(' ');
        let ind = cls.indexOf('onitemover');
        if (ind == -1) {
            event.currentTarget.classList += ' onitemover';
        }

        let id = event.currentTarget.id;
        let index = id.replace('lib-drag-', '');
        this.selectedFolderLib = this.libraryData.child_folders[index];
    }

    moveToParent(parentId) {
        let THIS = this;
        var param = {
            id: this.selectedLibrary.id,
            folder_id: parentId
        };

        if (param.id != param.folder_id) {
            $.ajax({
                url: THIS.APPCONFIG.apiAuth + 'media/library/edit/folder?token=' + THIS.currentUser.token,
                data: param,
                type: 'post',
                success: function (res) {
                    if (res.status == 2000) {
                        THIS.getSingleMedia(THIS.libraryData.id);
                        if (THIS.selectedLibrary.activity == 1) {
                            toastr.success('Your folder has been moved', 'Folder Moved', this.toastrOption);
                        } else {
                            toastr.success('Your file has been moved', 'File Moved', this.toastrOption);
                        }
                    }
                }
            });
        }
    }


    dragFileOverStart() {
        let trigger = $('#uploadDragWork');
        trigger.addClass('onEnterFile');
    }

    dragFileOverEnd() {
        let trigger = $('#uploadDragWork');
        trigger.removeClass('onEnterFile');
    }

    dragFilesDropped(e) {
        e.preventDefault();
        let THIS = this;
        $('#uploadDragWork').removeClass('onEnterFile');
        let accepted = e.dataTransfer.files;
        for (let i = 0; i < accepted.length; i++) {
            let eachFile = accepted[i];
            this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
                if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                        swal({
                            title: "Storage Full",
                            text: 'Your Storage is full. Please delete some files',
                            icon: "error",
                            dangerMode: true,
                            className: 'swal-btn-center'
                        });
                    } else {
                        swal({
                            title: "Invalid file format uploaded",
                            text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
                            icon: "error",
                            dangerMode: true,
                            className: 'swal-btn-center'
                        });
                    }
                } else {
                    THIS.getSingleMedia(THIS.libraryData.id);
                }
            });
        }

    }


}




