import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ChatService} from 'src/app/_services/chat.service';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let fabric: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;
declare let Pusher: any;
declare let Pickr: any;

@Component({
  selector: 'inspection-single',
  templateUrl: './singleInspection.component.html'
})
export class SingleInspectionComponent {
  modalRef: BsModalRef;
  @Output() currentID = new EventEmitter<number>();
  @Output() discard = new EventEmitter<number>();
  @Output() statusChange = new EventEmitter<any>();
  @Output() editThis = new EventEmitter<any>();
  @Output() refreshThis = new EventEmitter<any>();
  @Output() archiveThis = new EventEmitter<any>();
  @Output() activeThis = new EventEmitter<any>();
  @Output() removeThis = new EventEmitter<any>();
  @Output() duplicateThis = new EventEmitter<any>();
  @Output() previewQuick = new EventEmitter<number>();
  @Output() QuickList = new EventEmitter<number>();
  @Output() loaded = new EventEmitter<number>();
  @Output() invoiceThis = new EventEmitter<number>();
  @Output() rctiThis = new EventEmitter<number>();

  @Input() workId: any;
  @Input() editOptionForm: any;
  @Input() printThis: any;
  @Input() pdfThis: any;
  @Input() wo_is_active: any;
  @Input() statusUpdate: any;
  @Input() projectView: any = false;
  workDetails;
  workDetailsChange = 0;
  currentUser;
  APPCONFIG = APPCONFIG;
  _albums = [];
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
  activeAnonymousUsers = '';
  editAnonymousUsers = '';
  activeAnonymous = [];
  editAnonymous = [];
  updateCheckID = '';
  ActiveChat = [];


  constructor(private mediaService: MediaService,
              private facilityService: FacilityService,
              private chatService: ChatService) {
    let THIS = this;
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.getSessionUserInfo();

    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.dateCreatedOption.onChange = function (selectedDates, dateStr, instance) {
      if (selectedDates.length > 0) {
        THIS.dateCreated = new Date(selectedDates);
      } else {
        THIS.dateCreated = null;
      }
      THIS.onCreatedSelect();
    };
    this.dateDueOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.dateDue = new Date(selectedDates);
      THIS.onDueSelect();
    };

    this.updateCheckID = Date.now() + '_' + Session.id;

    $('body').on('click', '.pswp__button--edit', function () {
      if (THIS.Gallery !== null) {
        THIS.Gallery.close();
      }
      THIS.Preview = JSON.parse($('#EditImageData').attr('data-type'));
      (<any>$('#InspectionChatModal')).modal('hide');
      setTimeout(function () {
        THIS.openPhotoEditor(THIS.Preview);
      }, 400);
    });
    this.chatService.getActiveChat.subscribe(ActiveChat => {
      this.ActiveChat = ActiveChat;
    });

  }

  userInfo = {
    email: '',
    name: '',
    avatar: '',
    settings: '',
    user_type: '',
  };

  getSessionUserInfo() {
    this.userInfo = {
      email: this.currentUser.email,
      name: this.currentUser.nae,
      avatar: this.currentUser.avatar,
      settings: this.currentUser.settings,
      user_type: this.currentUser.user_type,
    };
  }

  inlinePrefixId = 0;
  inlineFacility = 0;
  inlineSubject = 0;
  inlineDescription = 0;
  rightRefresh = 1;

  ngOnChanges(changes: SimpleChanges): void {
    let THIS = this;
    if (changes.editOptionForm != undefined) {
      if (changes.editOptionForm.currentValue == 1) {
        this.inlinePrefixId = 1;
        this.inlineFacility = 1;
        this.inlineSubject = 1;
        this.inlineDescription = 1;
      } else {
        this.inlinePrefixId = 0;
        this.inlineFacility = 0;
        this.inlineSubject = 0;
        this.inlineDescription = 0;
      }
      this.workDetailsChange = 1;
      this.rightRefresh = 0;
    }
    if (changes.printThis != undefined) {
      if (changes.printThis.currentValue == 1) {
        this.printActive = 1;
      }
    }
    if (changes.projectView != undefined) {
      this.projectView = changes.projectView.currentValue;
    }
    if (changes.pdfThis != undefined) {
      if (changes.pdfThis.currentValue == 1) {
        this.pdfActive = 1;
      }
    }
    if (changes.workId != undefined) {
      this.workDetailsChange = 0;
      this.rightRefresh = 1;
      let id = changes.workId.currentValue;
      if (id != '') {
        let param = {uid: id};
        this.workDetailsChange = 0;
        this.refreshsinglePage(param);
      }
    }
  }

  AssetMap = 1;
  param_created_at = '';
  param_due = '';

  refreshsinglePage(param) {
    let THIS = this;
    THIS.UID_IMG = Date.now();
    if (this.CustomHelper.woRender != null) {
      this.CustomHelper.woRender.abort();
    }
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'get-single?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        if (res.status == 2000) {
          let work = res.data;
          THIS.workDetails = work;
          THIS.workDetails.dates.creation_date = THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.creation_date);
          THIS.workDetails.dates.edited_date = THIS.workDetails.dates.edited_date != '' && THIS.workDetails.dates.edited_date != null ? THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.edited_date) : '';

          THIS.workDetails.facStr = THIS.workDetails.facStr.reverse();
          THIS._albums.length = 0;
          if (THIS.workDetails.media_files !== undefined) {
            for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
              const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path + '&ref=' + Date.now();
              const caption = '';
              const thumb = '';
              const album = {
                src: src,
                caption: caption,
                thumb: thumb,
                w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
                h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
              };
              THIS._albums.push(album);
            }
          }

          THIS.id = work.id;
          THIS.param.id = work.id;
          THIS.taskList = work.tasks;
          THIS.param.overall_ratings = work.overall_ratings;


          THIS.param.subject = work.subject;
          THIS.param.facility_id = work.facility.id.toString();
          THIS.param.status_code = work.status_code.id.toString();
          //
          if (work.dates.created_at != null) {
            THIS.param.created_at = THIS.CustomHelper.formatDateTimeToDB(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
            THIS.param_created_at = THIS.CustomHelper.formatDateTime(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
            let ca = THIS.CustomHelper.formatDateTime(new Date(THIS.param.created_at));
            THIS.dateCreated = new Date(THIS.CustomHelper.invToDate(ca));
            THIS.dateCreatedOption.defaultDate = THIS.dateCreated;
            THIS.dateDueOption.minDate = THIS.dateCreated;
          }
          //
          if (work.dates.due_at != null) {
            THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(work.dates.due_at);
            THIS.param_due = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
            let pd = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
            THIS.dateDue = new Date(THIS.CustomHelper.invToDate(pd));
            THIS.dateDueOption.defaultDate = THIS.dateDue;
          }

          THIS.progress = work.progress;
          THIS.FacilityContact = work.facility.contact;
          //
          THIS.add2Calendar = work.calendar.add_to_calendar;
          THIS.param.add_to_calendar = THIS.add2Calendar;
          THIS.calendarStatus = work.calendar.calendar_color == '' || work.calendar.calendar_color == null ? '_blue' : work.calendar.calendar_color;
          THIS.param.calendar_color = THIS.calendarStatus;
          //
          THIS.param.personnel_id.length = 0;
          for (let p = 0; p < work.personnel.length; p++) {
            THIS.param.personnel_id.push(work.personnel[p].id.toString());
          }
          THIS.param.priority = work.priority !== null ? work.priority.id.toString() : '0';
          THIS.selectedWorkAttach = work.attach_files;
          //
          THIS.workDetailsChange = 1;
          THIS.rightRefresh = 0;
          //
          if (THIS.printActive == 1) {
            setTimeout(function () {
              let w = window.open();
              w.document.write($('#export').html());
              w.print();
              w.close();
            }, 1000);
          }
          if (THIS.pdfActive == 1) {
            setTimeout(function () {
              THIS.pdfThisWorkOrder();
            }, 500);
          }
          THIS.getAllFacility();
          THIS.getAllJobStatus(0);
          THIS.pty_get(0);
          THIS.personnel_get(0);
          THIS.loaded.emit(1);
          THIS.calculateInspectionRating();
          THIS.checkOverRateAllow();
          THIS.getTimeLine();
        } else if (res.status == 2200) {
          THIS.param.facility_id = res.facility_id.toString();
          THIS.workDetailsChange = -22;
          THIS.loaded.emit(1);
        } else {
          THIS.workDetailsChange = -1;
          THIS.loaded.emit(1);
        }
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      }
    });
  }

  confirmDiscard() {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (this.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Discard Alert',
        text: 'Are you sure you want to discard?',
        icon: 'warning',
        buttons: ['Stay on this page', 'Leave page'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          THIS.discard.emit(1);
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
          setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
          }, 100);
        }
      });
    } else {
      THIS.discard.emit(1);
      setTimeout(function () {
        THIS.CustomHelper.tooltipRender();
      }, 400);
    }
  }

  Facility = [];
  FacilityComp = [];
  FacilityContact = [];

  getSingleFacilityContact(id) {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/getSingle/contact?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          THIS.FacilityContact = res.data;
        }
      }
    });
  }

  getAllFacility() {
    let THIS = this;
    THIS.Facility.length = 0;
    THIS.FacilityComp.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.FacilityComp = res.facility;
        $.each(res.facility, function (i, v) {
          v['pp'] = 0;
          THIS.Facility.push(v);
          $.each(v.child, function (j, vC) {
            vC['pp'] = 1;
            THIS.Facility.push(vC);
            $.each(vC.child, function (k, vCC) {
              vCC['pp'] = 2;
              THIS.Facility.push(vCC);
              $.each(vCC.child, function (l, vCCC) {
                vCCC['pp'] = 3;
                THIS.Facility.push(vCCC);
              });
            });
          });
        });
        setTimeout(function () {
          if ($('.reRenderFacility').length > 0) {
            let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
            if (facilityId > 0) {
              THIS.param.facility_id = facilityId.toString();
            }
          }
          (<any>$('.chosen-default-single-fac')).chosen('destroy');
          (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
          (<any>$('.chosen-default-single-fac')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Facility'
          }).on('change', function (e, p) {
            THIS.FacilityContact.length = 0;
            if (e.target.value === '000') {
              let ___selectedTab = 0;
              let tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              let child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#createFacilityModal')).modal('show');
            } else {
              THIS.param.facility_id = e.target.value;
              THIS.validationThisForm();
              THIS.updateChangesDiscard();
              THIS.getSingleFacilityContact(THIS.param.facility_id);
            }
          });
          THIS.SA_fac = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  selectFacility(facility_id) {
    this.param.facility_id = facility_id.toString();
    let form = $('#workCreateForm');
    this.validationThisForm();
  }

  _fc_updating = 0;

  updateThisFacility(id) {
    let THIS = this;
    this._fc_updating = 1;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/enable?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        THIS._fc_updating = 0;
        THIS.workDetailsChange = 0;

        let id = THIS.workId;
        let param = {uid: id};
        THIS.refreshsinglePage(param);
        THIS.facilityService.refreshFacility();
      }
    });
  }

  Priority = [];

  pty_get(refresh): void {
    let THIS = this;
    this.Priority.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'priorities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.Priority = res.data;
        setTimeout(function () {
          (<any>$('.chosen-default-single-priority')).val(THIS.param.priority);
          (<any>$('.chosen-default-single-priority')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Priority'
          }).on('change', function (e, p) {
            THIS.updateChangesDiscard();
            let index = THIS.Priority.map(function (element) {
              return element.id;
            }).indexOf(parseInt(e.target.value));
            let priority = (index > -1) ? THIS.Priority[index] : null;
            if (priority != null) {
              THIS.param.priority = priority.id.toString();
              console.log(THIS.param.created_at);
              if (THIS.param.created_at != null && THIS.param.created_at != '') {
                let v = new Date(THIS.dateCreated);
                let min = THIS.CustomHelper.makeFloat(priority.amount) * THIS.CustomHelper.makeFloat(priority.duration);
                v.setMinutes(v.getMinutes() + min);
                THIS.dateDue = THIS.CustomHelper.formatDateTimeToDB(v);
                THIS.changingStart = 1;
                setTimeout(function () {
                  THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
                  THIS.dateDueOption.defaultDate = new Date(THIS.dateDue);
                  THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue));
                  THIS.changingStart = 0;
                }, 400);
              }
            } else {
              THIS.param.priority = '0';
              if (THIS.param.created_at != null && THIS.param.created_at != '') {
                THIS.param.due = THIS.dateDue != null ? THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue)) : '';
              }
            }
          });
          THIS.SA_pr = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  Personnel = {
    org: [],
    person: []
  };

  personnel_get(refresh): void {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Personnel = res;
        }
        setTimeout(function () {
          if ($('.reRenderPerson').length > 0) {
            let personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
            if (personnel_id > 0) {
              THIS.param.personnel_id.push(personnel_id.toString());
            }
          }
          let pp_val = THIS.param.personnel_id;
          (<any>$('.chosen-default-multiple-per')).val(pp_val);
          (<any>$('.chosen-default-multiple-per')).chosen('destroy');
          (<any>$('.chosen-default-multiple-per')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No personnel selected'
          }).on('change', function (e, p) {
            if (p.selected != undefined) {
              let v = p.selected;
              if (v === '000') {
                let ___selectedTab = 0;
                let tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) {
                  if ($(v).find('a.active').length > 0) {
                    ___selectedTab = i;
                  }
                });
                let child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#PersonQuickModal')).modal('show');
              } else {
                THIS.param.personnel_id.push(v.toString());
              }
            } else {
              let v = p.deselected;
              if (THIS.param.personnel_id.indexOf(v) > -1) {
                THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
              }
            }
            THIS.updateChangesDiscard();
          });
          THIS.SA_person = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  jobStatusList = [];
  jobStatusDefault = [];
  jobStatusCustom = [];

  getAllJobStatus(refresh) {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-status?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.jobStatusDefault.length = 0;
          THIS.jobStatusCustom.length = 0;
          THIS.jobStatusList = res.data;
          localStorage._jsts = JSON.stringify(res.data);
          $.each(res.data, function (i, v) {
            if (v.type == 0) {
              THIS.jobStatusDefault.push(v);
            } else {
              THIS.jobStatusCustom.push(v);
            }
          });
        }
        setTimeout(function () {
          if ($('.reRenderJobStatus').length > 0) {
            let status_code = parseInt($('.reRenderJobStatus').attr('data-id'));
            if (status_code > 0) {
              THIS.param.status_code = status_code;
            }
          }
          (<any>$('.chosen-default-single-status')).chosen('destroy');
          (<any>$('.chosen-default-single-status')).val(THIS.param.status_code);
          (<any>$('.chosen-default-single-status')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select job status'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              let ___selectedTab = 0;
              let tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              let child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#jobStatusModal')).modal('show');
            } else {
              THIS.param.status_code = e.target.value;
              THIS.updateChangesDiscard();
            }
          });
          THIS.SA_status = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  workFilterOption = [
    {title: 'New'},
    {title: 'Awaiting Approval'},
    {title: 'In Progress'},
    {title: 'Completed'},
    {title: 'Cancelled'}
  ];

  selectedWorkAttach = [];
  selectedFiles = 0;
  selectedFilesPreview = [];

  uploadThisMedia(file, media_type, localtion, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  removeThisMedia(id, media_type) {
    let param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  _uploadError = 0;
  _locPermit = 0;

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedFiles < 3) {
        let eachFile = accepted[i];
        this.selectedFiles++;
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                THIS._locPermit = 1;
                let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              } else {
                THIS._locPermit = -1;
                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              }
            }, function (error) {
              swal({
                title: 'Share Geo Location',
                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                icon: 'warning',
                buttons: ['Want to share location', 'Don\'t want to share Location'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  swal('If you want to share location then change the browser settings and remove the block status');
                }
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please delete some files',
                    icon: 'error',
                    dangerMode: true,
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
                THIS.updateTaskMediaQuick();
              }
            });
          }
        } else {
          this._uploadError = 1;
        }
      }
    }
  }

  uploadThisComentMedia(file, media_type, localtion, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  removeThisImage(index, id) {
    let THIS = this;
    swal({
      title: 'Remove Photo',
      text: 'Do you really want to remove this photo?',
      icon: 'warning',
      buttons: ['CANCEL', 'REMOVE'],
      dangerMode: true
    }).then(res => {
      if (res) {
        THIS.selectedFiles--;
        THIS.selectedFilesPreview.splice(index, 1);
        THIS.removeThisMedia(id, 1);

        let ids = THIS.selectedFilesPreview.map(a => (a.id));
        let task = THIS.SelectedTask;
        let param = {
          inspection_id: THIS.workDetails.id,
          id: task.id,
          media_files: ids.join()
        };
        $.ajax({
          url: THIS.APPCONFIG.apiInc + 'task/update/media?token=' + THIS.currentUser.token,
          data: param,
          type: 'post',
          success: function (res) {
            res.data['target_pn_type'] = 'wo_med_tsk_' + task.id;
            // Pusher emit
          }
        });
      }
    });
  }

  closeOrderStatus: any = '';
  closeOrderId: any = '';

  updateThisWorkStatus(status, id) {
    let ldd = document.getElementById('statusUpdate');
    let ladda = Ladda.create(ldd);
    let THIS = this;
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.apiRoot + 'inspections/edit?token=' + THIS.currentUser.token,
      data: {status_code: status.id, id: id},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.statusUpdate = 1;
        setTimeout(function () {
          THIS.statusUpdate = 0;
        }, 1000);
        THIS.workDetails.status_code = res.data.status_code;
        // Pusher emit
      }
    });
  }

  updateThisWorkStatusModal(status, id, type) {
    if (type == 'close') {
      this.closeWorkOrderModalOpen();
    }
    if (type == 'open') {
      this.openWorkOrderModalOpen();
    }
    this.closeOrderStatus = status;
    this.closeOrderId = id;
  }

  confirmUpdateWorkOrder() {
    this.workDetails.status_code = this.closeOrderStatus;
    const data = {
      status: this.closeOrderStatus.id,
      id: this.closeOrderId,
    };
    this.updateThisWorkStatus(this.workDetails.status_code, this.workDetails.id);
    this.statusChange.emit(data);
    (<any>$('#closeWorkOrderModal')).modal('hide');
    (<any>$('#openWorkOrderModal')).modal('hide');
  }

  refreshThisWo() {
    this.refreshThis.emit(this.workDetails.uid);
  }

  archiveThisWorkOrder() {
    this.archiveThis.emit(this.workDetails.id);
  }

  archiveThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#ArchiveModal')).modal('show');
  }

  activeThisWorkOrder() {
    this.archiveThis.emit(this.workDetails.id);
  }

  removeThisWorkOrder() {
    this.removeThis.emit(this.workDetails.id);
  }

  duplicateThisWorkOrder() {
    this.duplicateThis.emit(this.workDetails.id);
  }

  duplicateThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#DuplicateModal')).modal('show');
  }

  discardNewInvoice() {
    this.discard.emit(1);
  }

  unarchiveThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#UnarchiveModal')).modal('show');
  }

  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }

  printActive = 0;

  printThisWorkOrder() {
    let w = window.open();
    w.document.write($('#export').html());
    w.print();
    w.close();
  }

  pdfActive = 0;

  pdfThisWorkOrder() {
    let THIS = this;
    let id = this.workDetails.id;
    window.open(THIS.APPCONFIG.apiInc + 'make/pdf/' + id);
  }

  zipThisWorkOrder() {
    let THIS = this;
    let win = window.open(THIS.APPCONFIG.apiInc + 'zip/image/' + this.workDetails.id + '?token=' + THIS.currentUser.token, '_blank');
    win.focus();
  }


  //====================
  // ___panel_expand
  //====================
  id = 0;
  param = {
    id: 0,
    subject: '',
    media_files: '',
    attach_files: '',
    facility_id: '',
    due: '',
    created_at: '',
    add_to_calendar: 0,
    calendar_color: '',
    priority: '0',
    status_code: 1,
    personnel_id: [],
    oldTasks: [],
    tasks: [],
    overall_ratings: 1,
  };
  error = {
    facility: 0,
    personnel: 0,
    contact: 0,
    jobType: 0,
    priority: 0,
  };

  clearValidation(e) {
    let trigger = $(e.target);
    let target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  validForm = 1;
  _updatingInfo = 0;

  validationThisForm() {
    let valid = 1;
    let form = $('#workCreateForm');
    if (this.param.facility_id == '') {
      valid = 0;
      this.error.facility = 1;
    } else {
      this.error.facility = 0;
    }
    if (this.param.subject == '') {
      valid = 0;
      form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
    } else {
      form.find('[data-el="subject"]').removeClass('has-error');
    }
    this.validForm = valid;
    return valid;
  }

  taskEmpty = false;

  saveWorkOrderSubmit(quick) {
    let THIS = this;
    THIS.taskEmpty = false;
    $('.show-task-error').html('');
    THIS.taskList.forEach((v, i) => {
      if (v.title == '') {
        THIS.taskEmpty = true;
        return;
      }
    });
    if (THIS.taskEmpty) {
      $('.show-task-error').html('Task field shouldn\'t be empty');
      return;
    }
    let ldd = document.getElementById('SaveThisLadda');
    let ladda = Ladda.create(ldd);
    let mediaFiles = '';
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    this.param.media_files = mediaFiles;
    this.param.attach_files = mediaAttach;

    this.param.tasks = this.taskList;
    this.param.add_to_calendar = this.add2Calendar;
    this.param.calendar_color = this.calendarStatus;

    this.param_created_at = '';
    if (this.param.created_at != null && this.param.created_at != '') {
      this.param.created_at = this.CustomHelper.formatDateTimeToDB(this.param.created_at);
      this.param_created_at = this.CustomHelper.formatDateTime(new Date(this.param.created_at));
    }
    this.param_due = '';
    if (this.param.due != null && this.param.due != '') {
      this.param.due = this.param.due != null && this.param.due != '' ? this.CustomHelper.formatDateTimeToDB(this.param.due) : '';
      this.param_due = this.CustomHelper.formatDateTime(new Date(this.param.due));
    }

    let valid = this.validationThisForm();
    if (valid == 1) {
      ladda.start();
      this._updatingInfo = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiInc + 'edit?token=' + THIS.currentUser.token,
        type: 'post',
        data: THIS.param,
        success: function (res) {
          ladda.stop();
          THIS._updatingInfo = 0;
          if (res.status === 2000) {
            THIS.CustomHelper.woRouteDiscard = 0;
            THIS.inlinePrefixId = 0;
            THIS.refreshsinglePage({uid: res.data.uid});
            toastr.success('Inspection has been successfully updated', 'Update Inspection', THIS.toastrOption);
            // Pusher emit
            THIS.refreshThisWo();
          }
        }
      });
    }
  }

  updateChangesDiscard() {
    this.param.subject = (<any>$('#woSubject')).val();
    let THIS = this;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      this.CustomHelper.ConfRoute[index].change = 1;
    } else {
      this.CustomHelper.ConfRoute.push({tab: this.CustomHelper.ConfCurRoute, change: 1});
    }
  }


  taskType = 1; // 1.Tasks  2.Inspections
  taskList = [{title: '', person: '', status: 0, personDetails: null}];

  taskCreateOnF() {
    this.taskList.push({title: '', person: '', status: 0, personDetails: null});
    this.updateChangesDiscard();
  }

  removeTsk(index) {
    this.taskList.splice(index, 1);
  }

  selectTsk = null;
  selectTskIndex = -1;

  selectTskNow(index) {
    this.selectTskIndex = index;
    this.selectTsk = this.taskList[index];
  }

  assignTskHim(person) {
    let index = this.selectTskIndex;
    this.taskList[index].person = person.id;
    this.taskList[index].personDetails = person;
    this.selectTsk = this.taskList[index];

    if (this.param.personnel_id.indexOf(person.id) == -1) {
      this.param.personnel_id.push(person.id);
      (<any>$('.chosen-default-multiple-per')).val(this.param.personnel_id);
      (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
    }
  }

  updateTaskMediaQuick() {
    let THIS = this;
    let ids = THIS.selectedFilesPreview.map(a => (a.id));
    let task = THIS.SelectedTask;
    let param = {
      inspection_id: THIS.workDetails.id,
      id: task.id,
      media_files: ids.join()
    };
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'task/update/media?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        res.data['target_pn_type'] = 'wo_med_tsk_' + task.id;
        // Pusher emit
      }
    });
  }

  updateTaskStatusQuick(task) {
    let THIS = this;
    THIS.SelectedTask.status = (THIS.SelectedTask.status == 1 ? 0 : 1);
    THIS.calculateInspectionRating();
    let param = {
      inspection_id: THIS.workDetails.id,
      id: task.id
    };
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'task/update/status?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        toastr.success('Inspection task status updated successfully', 'Task Status Update', THIS.toastrOption);
        res.data['target_pn_type'] = 'wo_med_tsk_' + task.id;
        // Pusher emit
      }
    });
  }

  updateTaskStatus(index, task) {
    let THIS = this;
    THIS.workDetails.tasks[index].status = (THIS.workDetails.tasks[index].status == 1 ? 0 : 1);
    THIS.calculateInspectionRating();
    let param = {
      inspection_id: THIS.workDetails.id,
      id: task.id
    };
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'task/update/status?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        toastr.success('Inspection task status updated successfully', 'Task Status Update', THIS.toastrOption);
        THIS.checkOverRateAllow();
        res.data['target_pn_type'] = 'wo_med_tsk_' + task.id;
        // Pusher emit
      }
    });
  }

  InspectionRating = 0;

  calculateInspectionRating() {
    let THIS = this;
    let task = THIS.workDetails.tasks;
    let totalTask = task.length;
    let doneTask = 0;
    $.each(task, function (i, v) {
      if (v.status == 1) {
        doneTask++;
      }
    });
    let result = (doneTask / totalTask) * 5;
    THIS.InspectionRating = Math.round(result);
  }

  SelectedTask = null;
  TskMsgDtSep = [];

  SelectedTaskMsg = [];

  getCurrentTskMsg() {
    let THIS = this;
    let task = THIS.SelectedTask;
    let param = {
      inspection_id: THIS.workDetails.id,
      task_id: task.id
    };
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'task/msg/get?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          let dateStr = '';
          let dt = [];
          for (let i = 0; i < res.data.length; i++) {
            let v = res.data[i];
            v['dateStr'] = 0;
            if (v.created_at_human_format !== dateStr) {
              dateStr = v.created_at_human_format;
              v['dateStr'] = 1;
            }
            dt.push(v);
          }
          THIS.SelectedTaskMsg = dt;
          setTimeout(function () {
            $('._chat_body_scroll').animate({scrollTop: $('._chat_rail_body').height()}, 1000);
          }, 500);
        }
      }
    });
  }

  Sconnections = [];

  openInspectionTask(task) {
    let THIS = this;
    THIS.SelectedTask = task;
    THIS.selectedFilesPreview = task.media_files !== undefined ? task.media_files : [];
    THIS.selectedFiles = task.media_files !== undefined ? task.media_files.length : 0;
    let modal = <any>$('#InspectionChatModal');

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    THIS.SelectedTaskMsg = [];
    THIS.getCurrentTskMsg();

    if (THIS.Sconnections.indexOf(task.id) == -1) {
      THIS.Sconnections.push(task.id);
      //===============
      // Pusher
      //===============
      Pusher.logToConsole = true;
      let pusher = new Pusher(APPCONFIG.PUSHER_KEY, {cluster: 'ap4'});
      let channel = pusher.subscribe('bm-inspections');
      channel.bind('InspectionTaskComment', function (data) {
        let v = data.message;
        console.log(v, THIS.workDetails.id);
        if (THIS.workDetails.id == v.inspection_id) {
          if (v.pn_type == 'wo_comment_tsk') {
            if (v.data.task_id == THIS.SelectedTask.id) {
              THIS.SelectedTaskMsg.push(v.data);
              setTimeout(function () {
                $('._chat_body_scroll').animate({scrollTop: $('._chat_rail_body').height()}, 1000);
              }, 500);
            }
          } else if (v.pn_type == 'wo_comment_tsk_del') {
            if (v.task_id == THIS.SelectedTask.id) {
              let index = THIS.SelectedTaskMsg.map(function (e) {
                return e.id;
              }).indexOf(parseInt(v.msg_id));
              if (index > -1) {
                THIS.SelectedTaskMsg[index]['is_active'] = 0;
              }
              setTimeout(function () {
                $('._chat_body_scroll').animate({scrollTop: $('._chat_rail_body').height()}, 1000);
              }, 500);
            }
          } else if (v.pn_type == 'wo_comment_tsk_edit') {
            if (v.data.task_id == THIS.SelectedTask.id) {
              let index = THIS.SelectedTaskMsg.map(function (e) {
                return e.id;
              }).indexOf(v.task_id);
              if (index > -1) {
                THIS.SelectedTaskMsg[index] = v.data;
              }
              setTimeout(function () {
                $('._chat_body_scroll').animate({scrollTop: $('._chat_rail_body').height()}, 1000);
              }, 500);
            }
          }
        }
      });
      //===============
      // Pusher
      //===============
    }

  }

  changeTaskRating() {
    let THIS = this;
    this.param.overall_ratings == 1 ? this.param.overall_ratings = 0 : this.param.overall_ratings = 1;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'edit?token=' + THIS.currentUser.token,
      type: 'post',
      data: {id: THIS.workDetails.id, overall_ratings: THIS.param.overall_ratings},
      success: function (res) {
        THIS.refreshThisWo();
        toastr.success('Inspection overall rating has been updated successfully', 'Inspection Overall Rating', THIS.toastrOption);
      }
    });
  }

  updateRating(rating) {
    let THIS = this;
    this.workDetails.rating = rating;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'edit?token=' + THIS.currentUser.token,
      type: 'post',
      data: {id: THIS.workDetails.id, rating: rating},
      success: function (res) {
        THIS.refreshThisWo();
        toastr.success('Inspection overall rating has been updated successfully', 'Inspection Overall Rating', THIS.toastrOption);
      }
    });
  }


  tskMsg = '';
  tskMsgImages = [];

  createTaskMsg(e) {
    let THIS = this;
    if (e.which == 1 || (e.which == 13 && (!e.shiftKey && !e.ctrlKey))) {
      e.preventDefault();
      let task = THIS.SelectedTask;
      let msg = THIS.tskMsg;
      msg = $.trim(msg);
      if (msg !== '' || THIS.tskMsgImages.length > 0) {
        THIS.tskMsg = '';
        let param;
        if (THIS.editTskMsg == 1) {
          param = {
            inspection_id: THIS.workDetails.id,
            task_id: task.id,
            id: THIS.selectedMessage.id,
            msg: msg,
            images: THIS.tskMsgImages
          };
        } else {
          param = {
            inspection_id: THIS.workDetails.id,
            task_id: task.id,
            msg: msg,
            images: THIS.tskMsgImages
          };
        }
        let ldd = document.getElementById('SendBtn');
        let ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
          url: THIS.APPCONFIG.apiInc + 'task/msg/create?token=' + THIS.currentUser.token,
          type: 'post',
          data: param,
          success: function (res) {
            if (res.status == 2000) {
              ladda.stop();
              if (THIS.editTskMsg == 1) {
                res.data['target_pn_type'] = 'wo_comment_tsk_edit_' + task.id;
                // Pusher emit
              } else {
                res.data['target_pn_type'] = 'wo_comment_tsk_' + task.id;
                // Pusher emit
              }
              let id = THIS.workId;
              let param = {uid: id};
              THIS.refreshsinglePage(param);
            }
            THIS.editTskMsg = 0;
            THIS.selectedMessage = null;
            THIS.tskMsg = '';
            THIS.tskMsgImages = [];
          }
        });
      }
    } else {
      let task = THIS.SelectedTask;
      let msg = THIS.tskMsg;
      msg = $.trim(msg);
      if (msg == '') {
        THIS.editTskMsg = 0;
        THIS.selectedMessage = null;
      }
    }
  }

  editTskMsg = 0;
  selectedMessage = null;

  removeThisTskMsg() {
    let THIS = this;
    let task = THIS.SelectedTask;
    let paramData = {
      inspection_id: THIS.workDetails.id,
      task_id: task.id,
      id: THIS.selectedMessage.id
    };
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'task/msg/remove?token=' + THIS.currentUser.token,
      data: paramData,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          paramData['target_pn_type'] = 'wo_comment_tsk_del_' + task.id;
          // Pusher emit
          let id = THIS.workId;
          let param = {uid: id};
          THIS.refreshsinglePage(param);
          (<any>$('#removeInsChatModal')).modal('hide');
        }
      }
    });
  }

  InspectionChatModal() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#removeInsChatModal')).modal('show');
  }

  emojiList = [];
  emojiListCurrentIndex = 0;
  emojiListCurrent = [];

  getEmoji() {
    this.emojiListCurrentIndex = 0;
    this.mediaService.getEmoji().subscribe(res => {
      if (res.status == 2000) {
        this.emojiList = res.data;
        this.emojiListCurrent = this.emojiList[this.emojiListCurrentIndex];
      }
    });
  }


  writeEmoji(sign, type: any = null) {
    if (type == null) {
      this.tskMsg = this.tskMsg + ' ' + sign + ' ';
    } else {
      this.timeline.comment = this.timeline.comment + ' ' + sign + ' ';
    }
  }

  addCommentMedia() {
    $('#attachImgComment').click();
  }

  addCommentMedia2() {
    $('#attachImgComment2').click();
  }

  addNewChat(user) {

    let index = this.ActiveChat.map(function (e) {
      return e.id;
    }).indexOf(user.id);
    if (index == -1) {
      if (this.ActiveChat.length > 2) {
        this.ActiveChat.length = 2;
      }
      this.ActiveChat.push(user);
      this.chatService.updateActiveChat(this.ActiveChat);
    }
  }

  tskMsgImages2: any = null;

  selectTheseCommentImg(e: any, type: any = null) {
    let THIS = this;
    e.preventDefault();
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      if (THIS.currentUser.modules.geo_tagged_photo == 1) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            THIS._locPermit = 1;
            let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
            THIS.uploadThisComentMedia(eachFile, '1', localtion, function (uploaded) {
              if (type == 1) {
                THIS.tskMsgImages.push({
                  id: uploaded.id,
                  file_path: uploaded.file_path,
                  width: uploaded.width,
                  height: uploaded.height
                });
              } else {
                THIS.tskMsgImages2.push({
                  id: uploaded.id,
                  file_path: uploaded.file_path,
                  width: uploaded.width,
                  height: uploaded.height
                });
              }
              THIS.commentAttachArr.push({
                id: uploaded.id,
                file_path: uploaded.file_path,
                width: uploaded.width,
                height: uploaded.height
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
              if (type == 1) {
                THIS.tskMsgImages.push({
                  id: uploaded.id,
                  file_path: uploaded.file_path,
                  width: uploaded.width,
                  height: uploaded.height
                });
              } else {
                THIS.tskMsgImages2.push({
                  id: uploaded.id,
                  file_path: uploaded.file_path,
                  width: uploaded.width,
                  height: uploaded.height
                });
              }

              THIS.commentAttachArr.push({
                id: uploaded.id,
                file_path: uploaded.file_path,
                width: uploaded.width,
                height: uploaded.height
              });
            });
          }
        }, function (error) {
          swal({
            title: 'Share Geo Location',
            text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
            icon: 'warning',
            buttons: ['Want to share location', 'Don\'t want to share Location'],
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
                if (type == 1) {
                  THIS.tskMsgImages.push({
                    id: uploaded.id,
                    file_path: uploaded.file_path,
                    width: uploaded.width,
                    height: uploaded.height
                  });
                } else {
                  THIS.tskMsgImages2.push({
                    id: uploaded.id,
                    file_path: uploaded.file_path,
                    width: uploaded.width,
                    height: uploaded.height
                  });
                }

                THIS.commentAttachArr.push({
                  id: uploaded.id,
                  file_path: uploaded.file_path,
                  width: uploaded.width,
                  height: uploaded.height
                });
              });
            } else {
              swal('If you want to share location then change the browser settings and remove the block status');
            }
          });
        });
      } else {
        THIS._locPermit = -1;
        THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
          if (type == 1) {
            THIS.tskMsgImages.push({
              id: uploaded.id,
              file_path: uploaded.file_path,
              width: uploaded.width,
              height: uploaded.height
            });
          } else {
            THIS.tskMsgImages2.push({
              id: uploaded.id,
              file_path: uploaded.file_path,
              width: uploaded.width,
              height: uploaded.height
            });
          }
        });

      }
    }
  }


  overall_ratings_allow = 0;

  checkOverRateAllow() {
    let allow = 1;
    let tasks = this.workDetails.tasks;
    if (tasks.length == 0) {
      allow = 0;
    }
    $.each(tasks, function (i, v) {
      if (v.status == 0) {
        allow = 0;
      }
    });
    this.overall_ratings_allow = allow;
  }


  dateCreated = null;
  changingStart = 0;
  dateCreatedOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: null,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  onCreatedSelect() {
    let THIS = this;
    THIS.param.priority = '0';
    (<any>$('.chosen-default-single-priority')).val(THIS.param.priority).trigger('chosen:updated');
    if (this.dateCreated != null) {
      this.param.created_at = this.CustomHelper.formatDateTimeToDB(this.dateCreated);
      this.param.due = '';
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
        THIS.dateDueOption.defaultDate = this.dateDue;
        THIS.changingStart = 0;
      }, 400);
      this.updateChangesDiscard();
    } else {
      this.param.created_at = '';
      this.param.due = '';
      this.add2Calendar = 0;
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date();
        THIS.dateDueOption.defaultDate = null;
        THIS.changingStart = 0;
      }, 400);
    }
  }

  dateDue = null;
  dateDueOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: null,
    minDate: null,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  onDueSelect() {
    let THIS = this;
    this.param.due = this.dateDue != null && this.dateDue != '' ? this.CustomHelper.formatDateTimeToDB(this.dateDue) : '';
    this.param.priority = '0';
    setTimeout(function () {
      (<any>$('.chosen-default-single-priority')).val(THIS.param.priority);
      (<any>$('.chosen-default-single-priority')).chosen('destroy');
      (<any>$('.chosen-default-single-priority')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Priority'
      }).on('change', function (e, p) {
        // THIS.updateChangesDiscard();
        let index = THIS.Priority.map(function (element) {
          return element.id;
        }).indexOf(parseInt(e.target.value));
        let priority = (index > -1) ? THIS.Priority[index] : null;
        if (priority != null) {
          THIS.param.priority = priority.id.toString();
          console.log(THIS.param.created_at);
          if (THIS.param.created_at != null && THIS.param.created_at != '') {
            let v = new Date(THIS.dateCreated);
            let min = THIS.CustomHelper.makeFloat(priority.amount) * THIS.CustomHelper.makeFloat(priority.duration);
            v.setMinutes(v.getMinutes() + min);
            THIS.dateDue = THIS.CustomHelper.formatDateTimeToDB(v);
            THIS.changingStart = 1;
            setTimeout(function () {
              THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
              THIS.dateDueOption.defaultDate = new Date(THIS.dateDue);
              THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue));
              THIS.changingStart = 0;
            }, 400);
          }
        } else {
          THIS.param.priority = '0';
          if (THIS.param.created_at != null && THIS.param.created_at != '') {
            THIS.param.due = THIS.dateDue != null && THIS.dateDue != '' ? THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue)) : '';
          }
        }
      });
    }, 100);

  }

  add2Calendar = 0;
  calendarStatus = '_red';

  addToCalendar() {
    if (this.param.created_at != '' && this.param.created_at != null) {
      this.add2Calendar == 1 ? this.add2Calendar = 0 : this.add2Calendar = 1;
      this.updateChangesDiscard();
    } else {
      this.add2Calendar = 0;
      swal('You must have to provide start Date to add this work order to Calendar');
    }
  }


  shortLinkWo = null;
  shortLinkShared = 0;
  shared_edit = 0;
  shortLinkUrl = '';
  shortLinkUrlCopied = 0;

  generateShortLink() {
    this.shortLinkUrl = '';
    let work = this.workDetails;
    let Session = JSON.parse(localStorage.currentUserInfo);
    this.shortLinkWo = work;
    this.shortLinkShared = work.shared != undefined ? work.shared : 0;
    this.shared_edit = work.shared_edit != undefined ? work.shared_edit : 0;
    this.shortLinkUrl = this.APPCONFIG.shortLink + '/' + Session.team + '/i/' + work.short_code;

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#shortLinkSingleModal')).modal('show');
  }

  allowCopyClip() {
    let THIS = this;
    THIS.shortLinkShared == 1 ? THIS.shortLinkShared = 0 : THIS.shortLinkShared = 1;
    THIS.workDetails['shared'] = THIS.shortLinkShared;
    if (THIS.shortLinkShared == 0) {
      THIS.shared_edit = 0;
    }
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'shared/enable?token=' + THIS.currentUser.token,
      data: {id: THIS.shortLinkWo.id, shared_edit: THIS.shared_edit, shared: THIS.shortLinkShared},
      type: 'post',
      success: function (res) {
      }
    });
  }

  allowEditCopyClip() {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'shared/enable?token=' + THIS.currentUser.token,
      data: {id: THIS.shortLinkWo.id, shared_edit: THIS.shared_edit, shared: THIS.shortLinkShared},
      type: 'post',
      success: function (res) {
      }
    });
  }

  copyToClipboard() {
    let copyTextarea = $('#shortLinkUrlSingle');
    copyTextarea.focus();
    copyTextarea.select();
    try {
      document.execCommand('copy');
      this.shortLinkUrlCopied = 1;
    } catch (err) {
      this.shortLinkUrlCopied = -1;
    }
  }

  mailThisWo() {
    let work = this.workDetails;
    let param = {
      id: work.id,
      uid: work.uid,
      type: 'inspection',
      team: this.currentUser.team,
      short_code: work.short_code,
      subject: work.subject,
    };
    console.log(param);
    $('#sendMailModalTrigger').attr('data-type', JSON.stringify(param));
    $('#sendMailModalTrigger').click();
  }


  addWorkAttach() {
    $('#workAttach').click();
  }

  removeThisAttach(index, id) {
    this.selectedWorkAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        let eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Please upload only valid document files',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
          }
        });
      }
    }
  }

  progress = 0;
  rightSecPreview = 0;


  SaveAction = 0;
  SA_fac = 0;
  SA_status = 0;
  SA_type = 0;
  SA_pr = 0;
  SA_asset = 0;
  SA_person = 0;

  checkSaveAction() {
    if (this.SA_fac == 1 && this.SA_status == 1 && this.SA_type == 1 && this.SA_pr == 1 && this.SA_asset == 1 && this.SA_person == 1) {
      this.SaveAction = 1;
    } else {
      this.SaveAction = 0;
    }
  }


  // ==============================
  // Presence
  // ==============================
  __singlepreview() {
    let THIS = this;
    THIS.CustomHelper.woPresence = THIS.id;
  }

  __editpreview() {
    let THIS = this;
    THIS.CustomHelper.woPresence = THIS.id;
  }

  quickSinglePreview() {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (this.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Discard Alert',
        text: 'Are you sure you want to discard?',
        icon: 'warning',
        buttons: ['Stay on this page', 'Leave page'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          THIS.inlinePrefixId = 0;
          THIS._albums.length = 0;
          if (THIS.workDetails.media_files !== undefined) {
            for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
              const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path + '&ref=' + Date.now();
              const caption = '';
              const thumb = '';
              const album = {
                src: src,
                caption: caption,
                thumb: thumb,
                w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
                h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
              };
              THIS._albums.push(album);
            }
          }
          setTimeout(function () {
            (<any>$('#woSubject')).val(THIS.param.subject);
          }, 100);
          THIS.__singlepreview();
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
        }
      });
    } else {
      THIS.inlinePrefixId = 0;
      THIS._albums.length = 0;
      if (THIS.workDetails.media_files !== undefined) {
        for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
          const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path + '&ref=' + Date.now();
          const caption = '';
          const thumb = '';
          const album = {
            src: src,
            caption: caption,
            thumb: thumb,
            w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
            h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
          };
          THIS._albums.push(album);
        }
      }
      setTimeout(function () {
        (<any>$('#woSubject')).val(THIS.param.subject);
      }, 100);
      THIS.__singlepreview();
    }
  }

  quickSingleEdit() {
    let THIS = this;
    this.inlinePrefixId = 1;
    setTimeout(function () {
      if ($('.reRenderFacility').length > 0) {
        let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
        if (facilityId > 0) {
          THIS.param.facility_id = facilityId.toString();
        }
      }
      (<any>$('.chosen-default-single-fac')).chosen('destroy');
      (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
      (<any>$('.chosen-default-single-fac')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Facility'
      }).on('change', function (e, p) {
        THIS.FacilityContact.length = 0;
        if (e.target.value === '000') {
          let ___selectedTab = 0;
          let tabs = $('#sort-tabs').find('li');
          tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
              ___selectedTab = i;
            }
          });
          let child = ___selectedTab + 1;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          $('#sort-tabs').find('li').find('a').removeClass('active');
          $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
          (<any>$('#createFacilityModal')).modal('show');
        } else {
          THIS.param.facility_id = e.target.value;
          THIS.validationThisForm();
          THIS.updateChangesDiscard();
          THIS.getSingleFacilityContact(THIS.param.facility_id);
        }
      });
      THIS.SA_fac = 1;
      THIS.checkSaveAction();
    }, 100);
    setTimeout(function () {
      if ($('.reRenderJobStatus').length > 0) {
        let status_code = parseInt($('.reRenderJobStatus').attr('data-id'));
        if (status_code > 0) {
          THIS.param.status_code = status_code;
        }
      }
      (<any>$('.chosen-default-single-status')).val(THIS.param.status_code);
      (<any>$('.chosen-default-single-status')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select job status'
      }).on('change', function (e, p) {
        if (e.target.value === '000') {
          let ___selectedTab = 0;
          let tabs = $('#sort-tabs').find('li');
          tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
              ___selectedTab = i;
            }
          });
          let child = ___selectedTab + 1;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          $('#sort-tabs').find('li').find('a').removeClass('active');
          $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
          (<any>$('#jobStatusModal')).modal('show');
        } else {
          THIS.param.status_code = e.target.value;
          THIS.updateChangesDiscard();
        }
      });
      THIS.SA_status = 1;
      THIS.checkSaveAction();
    }, 100);
    setTimeout(function () {
      (<any>$('.chosen-default-single-priority')).val(THIS.param.priority);
      (<any>$('.chosen-default-single-priority')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Priority'
      }).on('change', function (e, p) {
        THIS.updateChangesDiscard();
        let index = THIS.Priority.map(function (element) {
          return element.id;
        }).indexOf(parseInt(e.target.value));
        let priority = (index > -1) ? THIS.Priority[index] : null;
        if (priority != null) {
          THIS.param.priority = priority.id.toString();
          if (THIS.param.created_at != null && THIS.param.created_at != '') {
            let v = new Date(THIS.dateCreated);
            let min = THIS.CustomHelper.makeFloat(priority.amount) * THIS.CustomHelper.makeFloat(priority.duration);
            v.setMinutes(v.getMinutes() + min);
            THIS.dateDue = THIS.CustomHelper.formatDateTimeToDB(v);
            THIS.changingStart = 1;
            setTimeout(function () {
              THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
              THIS.dateDueOption.defaultDate = new Date(THIS.dateDue);
              THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue));
              THIS.changingStart = 0;
            }, 400);
          }
          console.log(THIS.param.created_at);
        } else {
          THIS.param.priority = '0';
          if (THIS.param.created_at != null && THIS.param.created_at != '') {
            THIS.param.due = THIS.dateDue != null && THIS.dateDue != '' ? THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue)) : '';
          }
        }
      });
      THIS.SA_pr = 1;
      THIS.checkSaveAction();
    }, 100);
    setTimeout(function () {
      if ($('.reRenderPerson').length > 0) {
        let personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
        if (personnel_id > 0) {
          THIS.param.personnel_id.push(personnel_id.toString());
        }
      }
      let pp_val = THIS.param.personnel_id;
      (<any>$('.chosen-default-multiple-per')).val(pp_val);
      (<any>$('.chosen-default-multiple-per')).chosen('destroy');
      (<any>$('.chosen-default-multiple-per')).chosen({
        allow_single_deselect: true,
        placeholder_text_multiple: 'No personnel selected'
      }).on('change', function (e, p) {
        if (p.selected != undefined) {
          let v = p.selected;
          if (v === '000') {
            let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            let child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#PersonQuickModal')).modal('show');
          } else {
            THIS.param.personnel_id.push(v.toString());
          }
        } else {
          let v = p.deselected;
          if (THIS.param.personnel_id.indexOf(v) > -1) {
            THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
          }
        }
        THIS.updateChangesDiscard();
      });
      THIS.SA_person = 1;
      THIS.checkSaveAction();
    }, 100);
    this.__editpreview();
  }


  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    $('body').on('click', '.reRenderFacility', function () {
      THIS.getAllFacility();
    });
    $('body').on('click', '.reRenderPerson', function () {
      THIS.getAllFacility();
    });
    //===============
    // Pusher
    //===============
    Pusher.logToConsole = true;
    let pusher = new Pusher('501b3b237bd799758df0', {
      cluster: 'ap4'
    });
    let channel = pusher.subscribe('bm-work-orders');
    channel.bind('RefreshFacility', function (data) {
      if (Session.team === data.message.team) {
        THIS.getAllFacility();
      }
    });
    channel.bind('RefreshJobStatus', function (data) {
      if (Session.team === data.message.team) {
        THIS.getAllJobStatus(1);
      }
    });
    //===============
    // Pusher
    //===============

  }

  ngOnDestroy() {
  }


  // =============================
  // Photo Editor
  // =============================
  CANVAS = null;
  SHAPE = 'brush';
  IMAGE_PATH = '';
  CanvasTarget = 0;

  discardEditor() {
    let THIS = this;
    let modal = (<any>$('#photoEditorModal'));
    if (THIS.CANVAS != null) {
      THIS.CANVAS.dispose();
    }
    modal.modal('hide');

  }

  editImgCallUnique = 0;
  EditCurrentCanvas = null;
  CurrentEditPhoto = null;

  openPhotoEditor(preview) {
    let THIS = this;
    THIS.CurrentEditPhoto = preview;
    THIS.CanvasTarget = 0;
    let modal = (<any>$('#photoEditorModal'));
    let canvasW = window.innerWidth - 20;
    let canvasH = window.innerHeight - 130;
    $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
    if (THIS.CANVAS != null) {
      this.CANVAS.clear();
    }
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    let param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

    if (THIS.editImgCallUnique == 0) {
      THIS.editImgCallUnique = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          THIS.editImgCallUnique = 0;
          if (res.status === 2000) {
            THIS.EditCurrentCanvas = res.data;
            let canvasW = res.data.width;
            let canvasH = res.data.height;
            $('#canvas').attr('width', canvasW + 'px');
            $('#canvas').attr('height', canvasH + 'px');
            setTimeout(function () {
              // if(THIS.CANVAS != null){
              //     THIS.CANVAS.dispose();
              // }

              THIS.CANVAS = new fabric.Canvas('canvas');
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.on('object:selected', function (e) {
                THIS.CANVAS.isDrawingMode = false;
                THIS.SHAPE = '';
              });
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.isDrawingMode = true;
              THIS.CANVAS.freeDrawingBrush.width = 5;

              let startY = 0,
                startX = 0;
              THIS.CANVAS.on('mouse:down', function (option) {
                startY = option.e.offsetY;
                startX = option.e.offsetX;
              });
              THIS.CANVAS.on('mouse:up', function (option) {
                let selected = THIS.CANVAS.getActiveObjects();
                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                  if (THIS.SHAPE === 'circle') {
                    let e = option.e;
                    if (e.offsetX - startX > 5) {
                      let circle = new fabric.Circle({
                        top: startY,
                        left: startX,
                        radius: (e.offsetX - startX) / 2,
                        stroke: THIS.CANVAS.freeDrawingBrush.color,
                        strokeWidth: 5,
                        fill: ''
                      });
                      THIS.CANVAS.add(circle);
                    }
                  }
                }
              });
              THIS.CustomHelper.tooltipRender();

              THIS.IMAGE_PATH = preview.file_path;
              let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
              $('.canvas-container').css({
                backgroundImage: 'url(\'' + src + '\')'
              });
              THIS.CANVAS.renderAll();
              setTimeout(function () {
                THIS.CanvasTarget = 1;
              }, 1000);
            }, 1000);
          }
        }
      });


      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        modal.modal('hide');
        history.go(1);
      };

      if ($('.color-picker-editor').length > 0) {
        Pickr.create({
          el: '.color-picker-editor',
          theme: 'nano', // or 'monolith', or 'nano'
          defaultRepresentation: 'HEX',
          useAsButton: true,
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: true,
              input: true,
              save: true
            }
          }
        }).on('change', (color, instance) => {
          $('.color-picker-editor').css('color', color.toHEXA().toString());
          THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
          THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
          });
        });
      }
      $('.color-picker-editor').css('color', '#ff3773');
    }
  }

  // =============================
  // Photo Editor Actions
  // =============================
  SketchEnable() {
    this.CANVAS.isDrawingMode = !this.CANVAS.isDrawingMode;
    if (this.CANVAS.isDrawingMode === true) {
      this.SHAPE = 'brush';
    }
  }

  eraser() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    let obj = THIS.CANVAS.getObjects();
    for (let i = 0; i < obj.length; i++) {
      $.each(obj, function (j, v) {
        if (parseInt(j) > 0) {
          THIS.CANVAS.remove(v);
        }
      });
    }
    THIS.UID_IMG = Date.now();
    let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
    $('.canvas-container').css({
      backgroundImage: 'url(\'' + src + '\')'
    });
  }

  eraserSelected() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
      THIS.CANVAS.remove(obj);
    });
  }

  drawCircle() {
    let THIS = this;
    this.CANVAS.isDrawingMode = false;
    this.SHAPE = 'circle';
    let circle = new fabric.Circle({
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      radius: 50,
      stroke: THIS.CANVAS.freeDrawingBrush.color,
      strokeWidth: 5,
      fill: ''
    });
    THIS.CANVAS.add(circle);
  }

  UpdatePhoto(iid) {
    let THIS = this;
    let ldd = document.getElementById(iid);
    let ladda = Ladda.create(ldd);
    ladda.start();
    THIS.CANVAS.discardActiveObject();
    THIS.CANVAS.requestRenderAll();
    setTimeout(function () {
      let modal = (<any>$('#photoEditorModal'));
      let canvas = (<any>document.getElementById('canvas'));
      let imgData = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      imgData = imgData.replace('data:image/png;base64,', '');
      let param = {
        imgData: imgData,
        media_type: 1,
        file_path: THIS.IMAGE_PATH
      };
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          ladda.stop();
          modal.modal('hide');
          THIS.UID_IMG = Date.now();
        }
      });
    }, 1000);
  }


  UID_IMG = 0;
  Gallery = null;
  Preview;
  upReq = 0;

  open(index, preview) {
    let THIS = this;
    THIS.Preview = preview;
    $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    let _albums = [];
    if (THIS.selectedFilesPreview !== undefined) {
      for (let i = 0; i < THIS.selectedFilesPreview.length; i++) {
        const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + THIS.selectedFilesPreview[i].file_path + '&ref=' + Date.now();
        const caption = '';
        const thumb = '';
        const album = {
          src: src,
          caption: caption,
          thumb: thumb,
          w: THIS.selectedFilesPreview[i].width !== undefined ? THIS.selectedFilesPreview[i].width : 1500,
          h: THIS.selectedFilesPreview[i].height !== undefined ? THIS.selectedFilesPreview[i].height : 1500
        };
        _albums.push(album);
      }
    }
    let pswpElement = document.querySelectorAll('.pswp')[0];
    let options = {
      index: index,
      shareEl: false,
      editEl: true,
      tapToClose: false,
      zoomEl: true,
      history: false
    };
    THIS.Gallery = null;
    THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
    THIS.Gallery.init();
    THIS.Gallery.listen('afterChange', function (r) {
      THIS.Preview = THIS.selectedFilesPreview[THIS.Gallery.getCurrentIndex()];
      $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    });
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      THIS.Gallery.close();
      history.go(1);
    };

  }

  closeWorkOrderModalOpen() {
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#closeWorkOrderModal')).modal('show');
  }

  openWorkOrderModalOpen() {
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#openWorkOrderModal')).modal('show');
  }


  editLogCommentId = 0;
  editLogCommentParam = {id: 0, comment: '', work_id: 0};
  cNTvalid = 1;

  timeLineNewCommentCheck() {
    let c = this.editLogCommentParam.comment;
    let cR = $.trim(c);
    if (cR.length > 0) {
      this.cNTvalid = 1;
    } else {
      this.cNTvalid = 0;
    }
    let tarCom = $('.ql-container');
    if (tarCom.length > 0) {
      tarCom[0].scrollTop = tarCom[0].scrollHeight;
    }
  }

  newEditComment(index: any) {
    let THIS = this;
    let ldd = document.getElementById('updateComment');
    let ladda = Ladda.create(ldd);
    ladda.start();

    let comment = this.editLogCommentParam.comment.trim();
    let type = '';
    if (this.commentAttachEditArr.length > 0) {
      let msg = {
        comment: comment.trim(),
        image: this.commentAttachEditArr
      };
      type = 'attach';
      comment = JSON.stringify(msg);
    }
    let param = {
      id: this.editLogCommentId,
      inspection_id: this.id,
      comment: comment,
      type: type
    };
    $.ajax({
      url: THIS.APPCONFIG.apiRoot + 'inspections/timeline/edit?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        ladda = stop();
        if (res.status === 2000) {
          THIS.upReqEdit = 0;
          res.data['target_pn_type'] = 'wo_comment_upd_' + THIS.workDetails.id;
          THIS.timeLineList[index].attrs.comment = res.data.attrs.comment;
          // Pusher emit
          THIS.editLogCommentId = 0;
          THIS.editLogCommentParam = {
            id: 0,
            comment: '',
            work_id: 0
          };
          THIS.cNTvalid = 1;
          THIS.commentAttachEditArr.length = 0;
        }
      }
    });
  }

  commentAttachArr = [];
  commentAttachEditArr = [];
  commentAttach = '';

  removeThisCommentImage(index, id) {
    this.commentAttachArr.splice(index, 1);
    this.upReq--;
    this.removeThisMedia(id, 1);
  }

  removeThisCommentImage2(index, id) {
    this.tskMsgImages.splice(index, 1);
    this.upReq--;
    this.removeThisMedia(id, 1);
  }

  removeThisCommentEditImage(index, id) {
    this.commentAttachEditArr.splice(index, 1);
    this.upReqEdit--;
    this.removeThisMedia(id, 1);
  }

  checkEditImageList(log) {
    let THIS = this;
    this.commentAttachEditArr.length = 0;
    this.upReqEdit = 0;
    if (log.images != undefined) {
      this.commentAttachEditArr = log.images;
      this.upReqEdit = this.commentAttachEditArr.length;
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }


  cTvalid = 0;


  timeline: any = {
    inspection_id: 0,
    comment: '',
  };

  timeLineCommentCheck() {
    let c = this.timeline.comment;
    let cR = $.trim(c);
    if (cR.length > 0 || this.commentAttachArr.length > 0) {
      this.cTvalid = 1;
    } else {
      this.cTvalid = 0;
    }
  }


  timeLineList = [];
  tmlInterval: any;
  tmlPage = 1;
  tmlFetch = 0;
  timeline_Type = 1;


  getTimeLine() {
    let THIS = this;
    if (this.tmlFetch == 0) {
      if (this.id > 0) {
        this.tmlFetch = 1;
        THIS.timeLineList = [];
        $.ajax({
          url: THIS.APPCONFIG.apiInc + 'timeline/' + THIS.id + '?token=' + THIS.currentUser.token,
          data: {pageNo: THIS.tmlPage, type: THIS.timeline_Type},
          type: 'post',
          success: function (res) {
            if (res.status == 2000) {
              let tml = res.data.reverse();
              let dateStr = '';
              $.each(tml, function (i, v) {
                try {
                  let com = JSON.parse(v.attrs.comment);
                  v['commentT'] = com.comment;
                  v['images'] = com.image;
                } catch (e) {
                }
                let albumT = [];
                if (v.images !== undefined && v.images.length) {
                  for (let i = 0; i < v.images.length; i++) {
                    const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + v.images[i].file_path;
                    const caption = '';
                    const thumb = '';
                    const album = {
                      src: src,
                      caption: caption,
                      thumb: thumb,
                      w: v.images[i].width !== undefined ? v.images[i].width : 1500,
                      h: v.images[i].height !== undefined ? v.images[i].height : 1500
                    };
                    albumT.push(album);
                  }
                }
                v['album'] = albumT;
                v['created_at_human'] = THIS.CustomHelper.formatDateTime(v.created_at);
                v['updated_at_human'] = THIS.CustomHelper.formatDateTime(v.updated_at);
                if (v.module_id == THIS.id) {
                  v['dateStr'] = 0;
                  if (v.created_at_human_format !== dateStr) {
                    dateStr = v.created_at_human_format;
                    v['dateStr'] = 1;
                  }
                  // if(this.tmlPage == 1){
                  THIS.timeLineList.push(v);
                  // } else {
                  //     THIS.timeLineList.splice(0,0,v);
                  // }
                }
              });
              if (this.tmlPage == 1) {
                setTimeout(function () {
                  let tarComP = $('.Comment-Section-Train-way');
                  if (tarComP.length > 0) {
                    tarComP[0].scrollTop = tarComP[0].scrollHeight;
                  }
                }, 500);
              } else {
                setTimeout(function () {
                  let tarComP = $('.Comment-Section-Train-way');
                  if (tarComP.length > 0) {
                    tarComP[0].scrollTop = 100;
                  }
                }, 500);
              }
            }
            if (res.data.length == 0) {
              THIS.tmlPage = 0;
            }
            THIS.tmlFetch = 0;
          }
        });
      }
    }
  }

  removeTimelineId = 0;


  openRemoveComment() {
    let THIS = this;
    if (THIS.removeTimelineId > 0) {
      const param = {id: THIS.removeTimelineId, inspection_id: THIS.id};
      $.ajax({
        url: THIS.APPCONFIG.apiInc + 'timeline/remove?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          THIS.getTimeLine();
          (<any>$('#deleteCommentModal')).modal('hide');
        }
      });
    }
  }

  deleteThisComment() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteCommentModal')).modal('show');
  }

  openTimelineLight(index, indexT, preview) {
    let THIS = this;
    THIS.Preview = preview[0];
    $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    let _albums = [];
    if (THIS.timeLineList[indexT].album !== undefined) {
      for (let i = 0; i < THIS.timeLineList[indexT].album.length; i++) {
        const src = THIS.timeLineList[indexT].album[i].src + '&ref=' + Date.now();
        const caption = '';
        const thumb = '';
        const album = {
          src: src,
          caption: caption,
          thumb: thumb,
          w: THIS.timeLineList[indexT].album[i].w !== undefined ? THIS.timeLineList[indexT].album[i].w : 1500,
          h: THIS.timeLineList[indexT].album[i].h !== undefined ? THIS.timeLineList[indexT].album[i].h : 1500
        };
        _albums.push(album);
      }
    }
    let pswpElement = document.querySelectorAll('.pswp')[0];
    let options = {
      index: index,
      shareEl: false,
      editEl: true,
      downEl: true,
      tapToClose: false,
      zoomEl: true,
      history: false
    };
    THIS.Gallery = null;
    THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
    THIS.Gallery.init();
    THIS.Gallery.listen('afterChange', function (r) {
      THIS.Preview = preview[THIS.Gallery.getCurrentIndex()];
      $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    });
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      THIS.Gallery.close();
      history.go(1);
    };
  }

  writeEmojiEdit(sign) {
    this.editLogCommentParam.comment = this.editLogCommentParam.comment + sign + ' ';
    this.timeLineNewCommentCheck();
  }

  toolbarOptions = [
    ['bold', 'italic', 'underline'],        // toggled buttons
    ['blockquote', 'code-block'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    // [{'font': []}],
    [{'align': []}],

    // ['clean'],                                         // remove formatting button
    // ['link', 'image', 'video'],                         // link and image, video
    // ['image']                         // link and image, video
  ];

  editCommentMedia() {
    $('#attachImgEditComment').click();
  }

  upReqEdit = 0;

  selectTheseEditCommentImg(e) {
    let THIS = this;
    if (this.upReqEdit > 5) {
      return false;
    }
    this.upReqEdit++;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    e.preventDefault();
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      if (THIS.currentUser.modules.geo_tagged_photo == 1) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            THIS._locPermit = 1;
            let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
            THIS.uploadThisComentMedia(eachFile, '1', localtion, function (uploaded) {
              THIS.commentAttachEditArr.push(uploaded);
              THIS.timeLineCommentCheck();
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
              THIS.commentAttachEditArr.push(uploaded);
              THIS.timeLineCommentCheck();
            });
          }
        }, function (error) {
          swal({
            title: 'Share Geo Location',
            text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
            icon: 'warning',
            buttons: ['Want to share location', 'Don\'t want to share Location'],
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
                THIS.commentAttachEditArr.push(uploaded);
                THIS.timeLineCommentCheck();
              });
            } else {
              swal('If you want to share location then change the browser settings and remove the block status');
            }
          });
        });
      } else {
        THIS._locPermit = -1;
        THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
          THIS.commentAttachEditArr.push(uploaded);
          THIS.timeLineCommentCheck();
        });
      }
    }
  }

  auto_grow() {
    let target = $('._module_comment_editor_text');
    let element = target[0];
    element.style.height = '5px';
    element.style.height = (element.scrollHeight) + 'px';
  }

  auto_grow_sloq() {
    let THIS = this;
    console.log(THIS.editLogCommentId);
    setTimeout(function () {
      THIS.auto_grow();
    }, 500);
  }

  onEnterEffect(e) {
    if (e.which == 13 && (!e.shiftKey && !e.ctrlKey)) {
      e.preventDefault();
    }
    this.auto_grow();
  }

  newComment() {
    let THIS = this;
    this.cTvalid = 0;
    let comV = this.timeline.comment;
    let com = comV.replace(/<p><br><\/p>/gi, '\n\r');
    let domCom = $('<div>' + com + '</div>');
    let comment = domCom.text().trim();
    let type = '';
    if (this.commentAttachArr.length > 0) {
      let msg = {
        comment: comment.trim(),
        image: this.commentAttachArr
      };
      type = 'attach';
      comment = JSON.stringify(msg);
    } else {
      if (domCom.text() == '') {
        return true;
      }
    }
    let param = {
      inspection_id: this.id,
      comment: comment,
      type: type
    };
    this.timeline = {
      inspection_id: 0,
      comment: '',
    };
    $('._module_comment_editor_text').val('');
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'timeline?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.commentAttachArr.length = 0;
        if (res.status === 2000) {
          THIS.upReq = 0;
          res.data['target_pn_type'] = 'wo_comment_' + THIS.workDetails.id;
          // Pusher emit
          THIS.timeline = {
            work_id: 0,
            comment: '',
          };
          setTimeout(function () {
            THIS.auto_grow();
          }, 1000);
        }
        THIS.timeLineCommentCheck();
        THIS.getTimeLine();
      }
    });
  }

  onEnterCreate(e) {
    this.auto_grow();
    this.timeLineCommentCheck();
    if (e.which == 13 && (!e.shiftKey && !e.ctrlKey)) {
      e.preventDefault();
      this.newComment();
    }
  }

  CommentCreate(e) {
    this.auto_grow();
    this.timeLineCommentCheck();
    e.preventDefault();
    this.newComment();
  }
}
