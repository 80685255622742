import { Component} from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { APPCONFIG } from '../../config';
declare let Ladda: any;
declare let toastr: any;

@Component({
    selector: 'register-success-layout',
    templateUrl: './registerSuccess.component.html',
})
export class RegisterSuccessComponent {
    APPCONFIG = APPCONFIG;
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};

    constructor(private router: Router) {
        const currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        if (currentUser != null) {
            this.router.navigate(['/dashboard']);
        }
        if(localStorage.registered == undefined){
            this.router.navigate(['/register']);
        }
        this.registered = JSON.parse(localStorage.registered);
    }


    registered = null;

    mobileMenu = false;

    resendActivation(){
        let THIS = this;
        let ldd = document.getElementById('resendBtn');
        let ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url : THIS.APPCONFIG.apiAuth+'resend-activation',
            type: 'post',
            data: {email: THIS.registered.email},
            success: function(res){
                ladda.stop();
                if(res.status == 2000){
                    toastr.success('A new activation mail has been sent to your email address. Please check your mail and activate your account', 'Account Activation', THIS.toastrOption);
                }
            }
        });
    }

}
