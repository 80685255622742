import { Component, Input } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { TabsService } from '../../../_services/tabs.service';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
declare let swal: any;

@Component({
    selector: 'my-app-sidenav-mail-menu',
    styles: [],
    templateUrl: './sidenav-mail-menu.component.html'
})

export class SidenavMailMenuComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    @Input() changeTab:any;

    activeLinks: Array<number>;
    selectedTab: number;
    selectedPage: any;

    currentUser;
    constructor(private tabsService: TabsService,private authService: AuthService) {
        let THIS = this;
        THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.tabsService
            .getSelectedTab
            .subscribe(tab => {
                this.selectedTab = tab;
            });
        this.tabsService
            .getSelectedPage
            .subscribe(page => {
                this.selectedPage = page;
            });
        this.tabsService
            .getActiveLinks
            .subscribe(activeLinks => this.activeLinks = activeLinks);

        this.authService
            .getUserType
            .subscribe(userType => {
            });
        this.getMailFolders();
    }


    currentFolder = 'INBOX';
    Folders = [];
    getMailFolders() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'mailbox/folders?token=' + this.currentUser.token,
            type: 'get',
            data: {},
            success: function (res) {
                if (res.status == 2000) {
                    THIS.Folders = res.data.reverse();
                }
                if(THIS.selectedPage == 'mail_send'){
                    THIS.currentFolder = '';
                }
            }
        });
    }
    openMail(folder){
        this.currentFolder = folder.slug;
        $('#__mailFolder__').attr('data-type', folder.slug);
        $('#__mailFolder__').click();
    }
    sendFromEq(folder){
        this.currentFolder = folder;
        $('#__mailFolder__').attr('data-type', folder);
        $('#__mailFolder__').click();
    }

    ComposeEmail(){

    }
    presenceAlert(){
    }

    switchView(page: string) {
        let THIS = this;
        delete THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute];
        this.CustomHelper.woRouteDiscard = 0;
        let index = THIS.CustomHelper.ConfRoute.map(function(e) { return e.tab; }).indexOf(THIS.CustomHelper.ConfCurRoute);
        if(index > -1){
            // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
        }
        if(this.CustomHelper.woRouteDiscard == 1){
            swal({
                title: "Are you sure you want to leave?",
                text: "You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.",
                icon: "warning",
                buttons: ['CANCEL','LEAVE'],
                dangerMode: true,
            }).then((res) => {
                if (res) {
                    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
                    window.history.pushState("","", '/'+Session.team+'/'+page);
                    THIS.tabsService.makeFirstTab(page);
                    THIS.CustomHelper.woRouteDiscard = 0;
                    THIS.CustomHelper.ConfRoute.splice(index, 1);
                    if(page == 'mail_send'){
                        THIS.currentFolder = '';
                    }
                }
            });
        } else {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            window.history.pushState("","", '/'+Session.team+'/'+page);
            THIS.tabsService.makeFirstTab(page);
            THIS.CustomHelper.woRouteDiscard = 0;
            if(page == 'mail_send'){
                THIS.currentFolder = '';
            }
        }
    }
}
