import {Component, EventEmitter, Input, Output, SimpleChanges, TemplateRef} from '@angular/core';
import { ContactsService } from '../../../_services/contacts.service';
import { AssetService } from '../../../_services/asset.service';
import { InventoriesService } from '../../../_services/inventories.service';
import { FacilityService } from '../../../_services/facility.service';
import { WorkOrderService } from '../../../_services/work-orders.service';
import { APPCONFIG } from '../../../config';
import { CustomHelper } from '../../../customhelper';
import * as XLSX from 'xlsx';
declare let swal: any;
declare let Ladda: any;

@Component({
    selector: 'page-facility-edit',
    templateUrl: './editFacility.component.html'
})
export class EditFacilityComponent {
    @Input() selectedFacility : any;
    @Input() step : any;
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    APPCONFIG = APPCONFIG;
    CustomHelper = CustomHelper;

    constructor(protected facilityService:FacilityService,
                protected assetService:AssetService,
                protected inventoriesService:InventoriesService,
                protected workOrderService:WorkOrderService,
                protected contactsService:ContactsService) {
        this.getAllFacility();
    }
    Facility = [];
    getAllFacility() {
        let THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            $.each(res.facility, function(i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                $.each(v.child, function(j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                });
            });
            setTimeout(function () {
                (<any>$('.chosen-default-single')).chosen('destroy');
                (<any>$('.chosen-default-single')).val(THIS.param.parent_id);
                (<any>$('.chosen-default-single')).chosen({
                    allow_single_deselect: true
                }).on('change', function(e,p){
                    THIS.param.parent_id = e.target.value;
                });
            }, 100);
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.selectedFacility != undefined){
            let Facility = changes.selectedFacility.currentValue;
            console.log(Facility);
            let param = {id : Facility.id};
            this.facilityService.getFacilitySingle(param).subscribe(res => {
                if(res.status == 2000){
                    this.selectedFacility = res.data;
                    this.facility = this.selectedFacility;
                    this.param = {
                        id : this.facility.id,
                        name : this.facility.name,
                        parent_id : this.CustomHelper.makeStr(this.facility.parent_id),
                        address : this.facility.address,
                        str_addr_1 : this.facility.str_addr_1,
                        str_addr_2 : this.facility.str_addr_2,
                        city : this.facility.city,
                        state : this.facility.state,
                        zip_code : this.facility.zip_code,
                        country : this.facility.country,
                        site_name : this.facility.site_name,
                        site_email : this.facility.site_email,
                        site_phone : this.facility.site_phone,
                        site_mobile : this.facility.site_mobile
                    };
                    let THIS = this;
                    setTimeout(function () {
                        (<any>$('.chosen-default-single')).chosen('destroy');
                        (<any>$('.chosen-default-single')).val(THIS.param.parent_id);
                        (<any>$('.chosen-default-single')).chosen({
                            allow_single_deselect: true
                        }).on('change', function(e,p){
                            THIS.param.parent_id = e.target.value;
                        });
                    }, 100);
                }
            });
        }
    }


    facility: any = null;
    param = {
        id : 0,
        name : '',
        parent_id : '0',
        address : '',
        str_addr_1 : '',
        str_addr_2 : '',
        city : '',
        state : '',
        zip_code : '',
        country : '',
        site_name : '',
        site_email : '',
        site_phone : '',
        site_mobile : ''
    };
    handleAddressChange(place){
        this.param.address = place.formatted_address;
        for (let i = 0; i < place.address_components.length; i++) {
            let addressType = place.address_components[i].types[0];
            if(addressType == 'street_number'){
                this.param.str_addr_1 = place.address_components[i]['short_name'];
            }
            if(addressType == 'route'){
                this.param.str_addr_2 = place.address_components[i]['long_name'];
            }
            if(addressType == 'locality'){
                this.param.city = place.address_components[i]['long_name'];
            }
            if(addressType == 'administrative_area_level_1'){
                this.param.state = place.address_components[i]['short_name'];
            }
            if(addressType == 'country'){
                this.param.country = place.address_components[i]['long_name'];
            }
            if(addressType == 'postal_code'){
                this.param.zip_code = place.address_components[i]['short_name'];
            }
        }
    }
    clearValidation(e) {
        let trigger = $(e.target);
        let target = trigger.closest('.form-group');
        target.removeClass('has-error');
    }
    create(){
        let THIS = this;
        let ldd = document.getElementById('facCreate');
        let ladda = Ladda.create(ldd);
        let form = $('#facCreateForm');
        if(this.param.name != ''){
            ladda.start();
            this.facilityService.editFacility(this.param).subscribe(res => {
                ladda.stop();
                this.step = 2;
                this.facility = res;
                this.previewThisFacility();
                // Pusher emit
            });
        } else {
            let myDiv = document.getElementById('_thisScrool');
            myDiv.scrollTop = 0;
            form.find('[data-el="name"]').removeClass('has-error').addClass('has-error');
        }
    }
    previewThisFacility(){
        this.preview.emit(this.facility);
    }

    imp_contact(){ $('#imp_contact').click(); }
    imp_assets(){ $('#imp_assets').click(); }
    imp_inv(){ $('#imp_inv').click(); }
    imp_WO(){ $('#imp_WO').click(); }
    importStep = 0;
    importModule = 0; // 1. Contact  2.Assets  3. Work Orders
    importFile:any;
    importFields = [];
    paramJson = [];
    Submit = 0;

    parsedContact:any;
    parseContact(fileInput){
        let THIS = this;
        let parsedContact = fileInput.target.files[0];
        let extArr = parsedContact.name.split('.');
        let ext = extArr[extArr.length - 1];
        parsedContact['ext'] = ext;
        this.importFile = parsedContact;
        if(ext.toLowerCase() == 'csv'){
            let reader: FileReader = new FileReader();
            reader.readAsText(parsedContact);
            reader.onload = (e) => {
                let csv: string = reader.result;
                let allTextLines = csv.split(/\r|\n|\r/);
                let headers = allTextLines[0].split(',');
                let lines = [];
                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma
                    let data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        let tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }
                        lines.push(tarr);
                    }
                }
                THIS.parsedContact = lines;
                THIS.importStep = 1;
                THIS.importModule = 1;
            };
        } else if(ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx'){
            const target: DataTransfer = <DataTransfer>(fileInput.target);
            if (target.files.length !== 1) throw new Error('Cannot use multiple files');
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                let data = XLSX.utils.sheet_to_json(ws, {header: 1});
                THIS.parsedContact = data;
                THIS.importStep = 1;
                THIS.importModule = 1;
            };
            reader.readAsBinaryString(target.files[0]);
        } else {
            // Invalid file
        }



    }
    contactFields = [
        {name: 'name', title: 'Name', map : '-1'},
        {name: 'email', title: 'Email', map : '-1'},
        {name: 'phone', title: 'Phone', map : '-1'},
        {name: 'mobile', title: 'Mobile', map : '-1'},
        {name: 'address', title: 'Address', map : '-1'},
        {name: 'city', title: 'City', map : '-1'},
        {name: 'state', title: 'State', map : '-1'},
        {name: 'zip_code', title: 'Zip Code', map : '-1'},
    ];
    makeMappedContact(){
        let THIS = this;
        this.importFields = this.parsedContact[0];
        this.importStep = 2;
        $.each(THIS.contactFields, function (i, v) {
            $.each(THIS.importFields, function (j, k) {
                if (k.match(new RegExp(v.title, 'gi'))) {
                    THIS.contactFields[i].map = j.toString();
                } else if(v.title.match(new RegExp(k, 'gi'))) {
                    THIS.contactFields[i].map = j.toString();
                }
            });
        });
    }
    makeParamContact(){
        let THIS = this;
        let paramJson = [];
        $.each(THIS.parsedContact, function (i, v) {
            if(i != '0'){
                let param = {
                    facility : THIS.facility.id,
                    name: v[THIS.contactFields[0].map] == undefined ? '' : v[THIS.contactFields[0].map],
                    email: v[THIS.contactFields[1].map] == undefined ? '' : v[THIS.contactFields[1].map],
                    phone: v[THIS.contactFields[2].map] == undefined ? '' : v[THIS.contactFields[2].map],
                    mobile: v[THIS.contactFields[3].map] == undefined ? '' : v[THIS.contactFields[3].map],
                    address: v[THIS.contactFields[4].map] == undefined ? '' : v[THIS.contactFields[4].map],
                    city: v[THIS.contactFields[5].map] == undefined ? '' : v[THIS.contactFields[5].map],
                    zip_code: v[THIS.contactFields[6].map] == undefined ? '' : v[THIS.contactFields[6].map]
                };
                if(param.name != ''){
                    paramJson.push(param);
                }
            }
        });
        this.paramJson = paramJson;
        if(this.paramJson.length > 1000){
            swal({
                title: "Import Limitation",
                text: "Maximum allowed rows is 1000. If you continue, only the first 1000 rows will be imported?",
                icon: "warning",
                buttons: ['Cancel', 'Continue'],
                dangerMode: true,
            }).then((willDelete) => {
                if(willDelete){
                    this.paramJson.length = 1000;
                    this.contactsService.createLot({contacts : this.paramJson}).subscribe(res => {
                        this.importStep = 3;
                        this.facilityService.refreshFacility();
                        // Pusher emit
                    });
                } else {
                    swal.stopLoading();
                    swal.close();
                }
            });


        } else {
            this.Submit = 1;
            this.contactsService.createLot({contacts : this.paramJson}).subscribe(res => {
                this.Submit = 0;
                this.importStep = 3;
                this.facilityService.refreshFacility();
                // Pusher emit
            });
        }
    }

    parsedAssets:any;
    parseAssets(fileInput){
        let THIS = this;
        let parsedAssets = fileInput.target.files[0];
        let extArr = parsedAssets.name.split('.');
        let ext = extArr[extArr.length - 1];
        parsedAssets['ext'] = ext;
        this.importFile = parsedAssets;
        if(ext.toLowerCase() == 'csv'){
            let reader: FileReader = new FileReader();
            reader.readAsText(parsedAssets);
            reader.onload = (e) => {
                let csv: string = reader.result;
                let allTextLines = csv.split(/\r|\n|\r/);
                let headers = allTextLines[0].split(',');
                let lines = [];
                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma
                    let data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        let tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }
                        lines.push(tarr);
                    }
                }
                THIS.parsedAssets = lines;
                THIS.importStep = 1;
                THIS.importModule = 2;
            };
        }
        else if(ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx'){
            const target: DataTransfer = <DataTransfer>(fileInput.target);
            if (target.files.length !== 1) throw new Error('Cannot use multiple files');
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                let data = XLSX.utils.sheet_to_json(ws, {header: 1});
                THIS.parsedAssets = data;
                THIS.importStep = 1;
                THIS.importModule = 2;
            };
            reader.readAsBinaryString(target.files[0]);
        }
        else {
            // Invalid file
        }
    }
    AssetsFields = [
        {name: 'name', title: 'Asset Name', map : '-1'},
        {name: 'category', title: 'Category', map : '-1'},
        {name: 'location', title: 'Location', map : '-1'},
        {name: 'brand', title: 'Brand/Model', map : '-1'},
        {name: 'serial_number', title: 'Serial Number', map : '-1'},
        {name: 'purchase_cost', title: 'Purchase Cost', map : '-1'},
        {name: 'geo', title: 'Geo Location (Address or Coordinates)', map : '-1'},
        {name: 'depreciation_cost', title: 'Commissioning Cost', map : '-1'},
        {name: 'eol', title: 'End of life Value', map : '-1'},
        {name: 'asset_life', title: 'Asset Life (Months)', map : '-1'},
    ];
    makeMappedAssets(){
        let THIS = this;
        this.importFields = this.parsedAssets[0];
        this.importStep = 2;
        $.each(THIS.AssetsFields, function (i, v) {
            $.each(THIS.importFields, function (j, k) {
                if (k.match(new RegExp(v.title, 'gi'))) {
                    THIS.AssetsFields[i].map = j.toString();
                } else if(v.title.match(new RegExp(k, 'gi'))) {
                    THIS.AssetsFields[i].map = j.toString();
                }
            });
        });
    }
    makeParamAssets(){
        let THIS = this;
        let paramJson = [];
        $.each(THIS.parsedAssets, function (i, v) {
            if(i != '0'){
                let param = {
                    facility : THIS.facility.id,
                    name: v[THIS.AssetsFields[0].map] == undefined ? '' : v[THIS.AssetsFields[0].map],
                    category: v[THIS.AssetsFields[1].map] == undefined ? '' : v[THIS.AssetsFields[1].map],
                    location: v[THIS.AssetsFields[2].map] == undefined ? '' : v[THIS.AssetsFields[2].map],
                    brand: v[THIS.AssetsFields[3].map] == undefined ? '' : v[THIS.AssetsFields[3].map],
                    serial_number: v[THIS.AssetsFields[4].map] == undefined ? '' : v[THIS.AssetsFields[4].map],
                    purchase_cost: v[THIS.AssetsFields[5].map] == undefined ? '' : v[THIS.AssetsFields[5].map],
                    geo: v[THIS.AssetsFields[6].map] == undefined ? '' : v[THIS.AssetsFields[6].map],
                    depreciation_cost: v[THIS.AssetsFields[7].map] == undefined ? '' : v[THIS.AssetsFields[7].map],
                    eol: v[THIS.AssetsFields[8].map] == undefined ? '' : v[THIS.AssetsFields[8].map],
                    asset_life: v[THIS.AssetsFields[9].map] == undefined ? '' : v[THIS.AssetsFields[9].map]
                };
                if(param.name != ''){
                    paramJson.push(param);
                }
            }
        });
        this.paramJson = paramJson;
        console.log(this.paramJson);
        this.Submit = 1;
        if(this.paramJson.length > 1000){
            swal({
                title: "Import Limitation",
                text: "Maximum allowed rows is 1000. If you continue, only the first 1000 rows will be imported?",
                icon: "warning",
                buttons: ['Cancel', 'Continue'],
                dangerMode: true,
            }).then((willDelete) => {
                if(willDelete){
                    THIS.paramJson.length = 1000;
                    THIS.assetService.createLot({assets : this.paramJson}).subscribe(res => {
                        THIS.importStep = 3;
                        THIS.facilityService.refreshFacility();
                        THIS.Submit = 0;
                        // Pusher emit
                    });
                } else {
                    THIS.Submit = 0;
                    swal.stopLoading();
                    swal.close();
                }
            });
        } else {
            THIS.Submit = 1;
            THIS.assetService.createLot({assets : this.paramJson}).subscribe(res => {
                THIS.Submit = 0;
                THIS.importStep = 3;
                this.facilityService.refreshFacility();
                // Pusher emit
            });
        }
    }

    parsedInv:any;
    parseInv(fileInput){
        let THIS = this;
        let parsedAssets = fileInput.target.files[0];
        let extArr = parsedAssets.name.split('.');
        let ext = extArr[extArr.length - 1];
        parsedAssets['ext'] = ext;
        this.importFile = parsedAssets;
        if(ext.toLowerCase() == 'csv'){
            let reader: FileReader = new FileReader();
            reader.readAsText(parsedAssets);
            reader.onload = (e) => {
                let csv: string = reader.result;
                let allTextLines = csv.split(/\r|\n|\r/);
                let headers = allTextLines[0].split(',');
                let lines = [];
                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma
                    let data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        let tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }
                        lines.push(tarr);
                    }
                }
                THIS.parsedAssets = lines;
                THIS.importStep = 1;
                THIS.importModule = 2;
            };
        }
        else if(ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx'){
            const target: DataTransfer = <DataTransfer>(fileInput.target);
            if (target.files.length !== 1) throw new Error('Cannot use multiple files');
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                let data = XLSX.utils.sheet_to_json(ws, {header: 1});
                console.log(data);
                THIS.parsedInv = data;
                THIS.importStep = 1;
                THIS.importModule = 4;
            };
            reader.readAsBinaryString(target.files[0]);
        } else {
            // Invalid file
        }
    }
    invFields = [
        {name: 'title', title: 'Item Name', map : '-1'},
        {name: 'qty', title: 'Quantity', map : '-1'},
        {name: 'purchase_cost', title: 'Purchase Cost', map : '-1'},
        {name: 'sell_price', title: 'Sell Price', map : '-1'}
    ];
    makeMappedInv(){
        let THIS = this;
        this.importFields = this.parsedInv[0];
        this.importStep = 2;
        $.each(THIS.invFields, function (i, v) {
            $.each(THIS.importFields, function (j, k) {
                if (k.match(new RegExp(v.title, 'gi'))) {
                    THIS.invFields[i].map = j.toString();
                } else if(v.title.match(new RegExp(k, 'gi'))) {
                    THIS.invFields[i].map = j.toString();
                }
            });
        });
    }
    makeParamInv(){
        let THIS = this;
        let paramJson = [];
        $.each(THIS.parsedInv, function (i, v) {
            if(i != '0'){
                let param = {
                    facility_id : THIS.facility.id,
                    title: v[THIS.invFields[0].map] == undefined ? '' : v[THIS.invFields[0].map],
                    qty: v[THIS.invFields[1].map] == undefined ? '' : v[THIS.invFields[1].map],
                    purchase_cost: v[THIS.invFields[2].map] == undefined ? '' : v[THIS.invFields[2].map],
                    sell_price: v[THIS.invFields[3].map] == undefined ? '' : v[THIS.invFields[3].map]
                };
                if(param.title != ''){
                    paramJson.push(param);
                }
            }
        });
        this.paramJson = paramJson;
        THIS.Submit = 1;
        if(this.paramJson.length > 1000){
            swal({
                title: "Import Limitation",
                text: "Maximum allowed rows is 1000. If you continue, only the first 1000 rows will be imported?",
                icon: "warning",
                buttons: ['Cancel', 'Continue'],
                dangerMode: true,
            }).then((willDelete) => {
                if(willDelete){
                    THIS.paramJson.length = 1000;
                    THIS.inventoriesService.createInventoryLot({inv : this.paramJson}).subscribe(res => {
                        THIS.importStep = 3;
                        THIS.Submit = 0;
                    });
                } else {
                    THIS.Submit = 0;
                    swal.stopLoading();
                    swal.close();
                }
            });
        } else {
            THIS.Submit = 1;
            THIS.inventoriesService.createInventoryLot({inv : this.paramJson}).subscribe(res => {
                THIS.Submit = 0;
                THIS.importStep = 3;
            });
        }
    }

    parsedWO:any;
    parseWO(fileInput){
        let THIS = this;
        let parsedWO = fileInput.target.files[0];
        let extArr = parsedWO.name.split('.');
        let ext = extArr[extArr.length - 1];
        parsedWO['ext'] = ext;
        this.importFile = parsedWO;
        if(ext.toLowerCase() == 'csv'){
            let reader: FileReader = new FileReader();
            reader.readAsText(parsedWO);
            reader.onload = (e) => {
                let csv: string = reader.result;
                let allTextLines = csv.split(/\r|\n|\r/);
                let headers = allTextLines[0].split(',');
                let lines = [];
                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma
                    let data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        let tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }
                        lines.push(tarr);
                    }
                }
                THIS.parsedWO = lines;
                THIS.importStep = 1;
                THIS.importModule = 3;
            };
        } else if(ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx'){
            const target: DataTransfer = <DataTransfer>(fileInput.target);
            if (target.files.length !== 1) throw new Error('Cannot use multiple files');
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                let data = XLSX.utils.sheet_to_json(ws, {header: 1});
                THIS.parsedWO = data;
                THIS.importStep = 1;
                THIS.importModule = 3;
            };
            reader.readAsBinaryString(target.files[0]);
        } else {
            // Invalid file
        }



    }
    WOFields = [
        {name: 'status_code', title: 'Job Status', map : '-1'},
        {name: 'job_type', title: 'Job Type', map : '-1'},
        {name: 'created_at', title: 'Start Date', map : '-1'},
        {name: 'due', title: 'Due Date', map : '-1'},
        {name: 'subject', title: 'Title', map : '-1'},
        {name: 'description', title: 'Description', map : '-1'},
        {name: 'asset', title: 'Asset', map : '-1'},
    ];
    makeMappedWO(){
        let THIS = this;
        this.importFields = this.parsedWO[0];
        this.importStep = 2;
        $.each(THIS.WOFields, function (i, v) {
            $.each(THIS.importFields, function (j, k) {
                if (k.match(new RegExp(v.title, 'gi'))) {
                    THIS.WOFields[i].map = j.toString();
                } else if(v.title.match(new RegExp(k, 'gi'))) {
                    THIS.WOFields[i].map = j.toString();
                }
            });
        });
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 500);
    }
    makeParamWO(){
        let THIS = this;
        let paramJson = [];
        $.each(THIS.parsedWO, function (i, v) {
            if(i != '0'){
                let param = {
                    facility_id : THIS.facility.id,
                    status_code: v[THIS.WOFields[0].map] == undefined ? '' : v[THIS.WOFields[0].map],
                    job_type: v[THIS.WOFields[1].map] == undefined ? '' : v[THIS.WOFields[1].map],
                    created_at: v[THIS.WOFields[2].map] == undefined ? '' : v[THIS.WOFields[2].map],
                    due: v[THIS.WOFields[3].map] == undefined ? '' : v[THIS.WOFields[3].map],
                    subject: v[THIS.WOFields[4].map] == undefined ? '' : v[THIS.WOFields[4].map],
                    description: v[THIS.WOFields[5].map] == undefined ? '' : v[THIS.WOFields[5].map],
                    asset: v[THIS.WOFields[6].map] == undefined ? '' : v[THIS.WOFields[6].map]
                };
                if(param.subject != ''){
                    param.created_at = THIS.CustomHelper.formatDateTimeToDB(THIS.CustomHelper.invToDate(param.created_at));
                    param.due = THIS.CustomHelper.formatDateTimeToDB(THIS.CustomHelper.invToDate(param.due));
                    paramJson.push(param);
                }
            }
        });
        this.paramJson = paramJson;
        if(this.paramJson.length > 1000){
            swal({
                title: "Import Limitation",
                text: "Maximum allowed rows is 1000. If you continue, only the first 1000 rows will be imported?",
                icon: "warning",
                buttons: ['Cancel', 'Continue'],
                dangerMode: true,
            }).then((willDelete) => {
                if(willDelete){
                    this.paramJson.length = 1000;
                    this.workOrderService.createWorkOrderLot({wo : this.paramJson}).subscribe(res => {
                        if(res.status != undefined && res.status == 3000){
                            swal({
                                title: "Work Order",
                                text: "You have reached your free plan limit. To create more work orders, please subscribe to the Pro plan from your account page.",
                                icon: "warning",
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                            return true;
                        }

                        this.importStep = 3;
                        this.facilityService.refreshFacility();
                        // Pusher emit
                    });
                } else {
                    swal.stopLoading();
                    swal.close();
                }
            });
        } else {
            this.Submit = 1;
            this.workOrderService.createWorkOrderLot({wo : this.paramJson}).subscribe(res => {
                if(res.status != undefined && res.status == 3000){
                    swal({
                        title: "Work Order",
                        text: "You have reached your free plan limit. To create more work orders, please subscribe to the Pro plan from your account page.",
                        icon: "warning",
                        dangerMode: true,
                        className: 'swal-btn-center'
                    });
                    return true;
                }

                this.Submit = 0;
                this.importStep = 3;
                this.facilityService.refreshFacility();
                // Pusher emit
            });
        }
    }

    mappTool(){
        let THIS = this;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }
    confirmDiscard(){
        this.previewThisFacility();
    }

    ngOnInit() {
        let THIS = this;
    }
    ngOnDestroy() {
    }

}
