import {Component, EventEmitter, Output, Input, SimpleChanges, OnInit, OnChanges, ViewChild} from '@angular/core';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';

import {WorkOrderService} from '../../../_services/work-orders.service';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';

declare let swal: any;
declare let Ladda: any;

@Component({
    selector: 'project-edit',
    templateUrl: './editProject.component.html'
})
export class EditProjectComponent implements OnInit, OnChanges {
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    @Input() EditSingleData: any;

    id = 0;
    APPCONFIG = APPCONFIG;
    CustomHelper = CustomHelper;
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

    currentUser;

    constructor(protected facilityService: FacilityService,
                private workOrderService: WorkOrderService,
                private mediaService: MediaService) {
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.currentUser = Session;
    }

    EditData = null;

    ngOnChanges(changes: SimpleChanges): void {
        let THIS = this;
        if (changes.EditSingleData !== undefined) {
            let EditSingleData = changes.EditSingleData.currentValue;
            this.param = {
                id: EditSingleData.id,
                facility_id: EditSingleData.facility_id,
                job_type_id: EditSingleData.job_type_id,
                start_date: EditSingleData.start_date,
                due_date: EditSingleData.due_date,
                subject: EditSingleData.subject,
                description: EditSingleData.description,
                attach_files: [],
                managers: EditSingleData.managers,
                notes: [],
            };
            this.selectedWorkAttach = EditSingleData.fileList;
            this.dateCreated = EditSingleData.start_date !== null ? new Date(EditSingleData.start_date) : null;
            this.dateDue = EditSingleData.due_date !== null ? new Date(EditSingleData.due_date) : null;

            this.dateCreatedOption = {
                timePicker: true,
                enableTime: true,
                altInput: true,
                defaultDate: new Date(this.dateCreated),
                altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
                onChange: function (selectedDates, dateStr, instance) {
                },
            };
            this.dateDueOption = {
                timePicker: true,
                enableTime: true,
                altInput: true,
                defaultDate: new Date(this.dateDue),
                minDate: new Date(this.dateCreated),
                altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
                onChange: function (selectedDates, dateStr, instance) {
                },
            };
            this.dateCreatedOption.onChange = function (selectedDates, dateStr, instance) {
                if (selectedDates.length > 0) {
                    THIS.dateCreated = new Date(selectedDates);
                } else {
                    THIS.dateCreated = null;
                }
                THIS.onCreatedSelect();
            };
            this.dateDueOption.onChange = function (selectedDates, dateStr, instance) {
                THIS.dateDue = new Date(selectedDates);
                THIS.onDueSelect();
            };
            this.EditData = EditSingleData;

            setTimeout(function () {
                (<any>$('textarea.summernote')).summernote({
                    maxHeight: '900px',
                    minHeight: '150px',
                    toolbar: [
                        ['style', ['bold', 'underline', 'clear']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['insert', ['link', 'video']],
                        ['misc', ['fullscreen']]
                    ],
                    callbacks: {
                        onKeyup: function (e) {
                            console.log(e);
                        }
                    }
                });
                (<any>$("textarea.summernote")).summernote("code", THIS.param.description);
              THIS.getAllFacility();
              THIS.jt_get();
              THIS.personnel_get();
            }, 100);
        }
    }


    param = {
        id: '',
        facility_id: '',
        job_type_id: '',
        start_date: null,
        due_date: null,
        subject: '',
        description: '',
        attach_files: [],
        managers: [],
        notes: [],
    };
    validForm = 0;
    submitting = 0;

    validationThisForm() {
        let valid = 1;
        let form = $('#workCreateForm');
        if (this.param.facility_id == '') {
            valid = 0;
            form.find('[data-el="facility_id"]').removeClass('has-error').addClass('has-error');
        }
        if (this.param.subject == '') {
            valid = 0;
            form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
        }
        this.validForm = valid;
        return valid;
    }

    clearValidation(e) {
        let trigger = $(e.target);
        let target = trigger.closest('.form-group');
        target.removeClass('has-error');
    }

    Facility = [];
    FacilityComp = [];

    getAllFacility() {
        let THIS = this;
        THIS.Facility.length = 0;
        THIS.FacilityComp.length = 0;
        $.ajax({
            url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
            data: {},
            type: 'get',
            success: function (res) {
                THIS.FacilityComp = res.facility;
                $.each(res.facility, function (i, v) {
                    v['pp'] = 0;
                    THIS.Facility.push(v);
                    $.each(v.child, function (j, vC) {
                        vC['pp'] = 1;
                        THIS.Facility.push(vC);
                        $.each(vC.child, function (k, vCC) {
                            vCC['pp'] = 2;
                            THIS.Facility.push(vCC);
                            $.each(vCC.child, function (l, vCCC) {
                                vCCC['pp'] = 3;
                                THIS.Facility.push(vCCC);
                            });
                        });
                    });
                });
                setTimeout(function () {
                    if ($('.reRenderFacility').length > 0) {
                        let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
                        if (facilityId > 0) {
                            THIS.param.facility_id = facilityId.toString();
                        }
                    }
                    (<any>$('.chosen-default-single-fac')).chosen('destroy');
                    (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                    (<any>$('.chosen-default-single-fac')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_single: 'Select Facility'
                    }).on('change', function (e, p) {
                        if (e.target.value === '000') {
                            (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                            (<any>$('.chosen-default-single-fac')).trigger("chosen:updated");
                            let ___selectedTab = 0;
                            let tabs = $('#sort-tabs').find('li');
                            tabs.each(function (i, v) {
                                if ($(v).find('a.active').length > 0) {
                                    ___selectedTab = i;
                                }
                            });
                            let child = ___selectedTab + 1;
                            (<any>$('.owl-carousel')).owlCarousel('destroy');
                            $('#sort-tabs').find('li').find('a').removeClass('active');
                            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                            (<any>$('#createFacilityModal')).modal('show');

                        } else {
                            THIS.param.facility_id = e.target.value;
                            THIS.validationThisForm();
                        }
                    });
                }, 100);
            }
        });
    }

    Personnel = {
        org: [],
        person: []
    };

    personnel_get(): void {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
            data: {},
            type: 'get',
            success: function (res) {
                if (res.status === 2000) {
                    THIS.Personnel = res;
                }
                setTimeout(function () {
                    if ($('.reRenderPerson').length > 0) {
                        let personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
                        if (personnel_id > 0) {
                            THIS.param.managers = [personnel_id];
                        }
                    }
                    let pp_val = THIS.param.managers;
                    (<any>$('.chosen-default-multiple-per')).val(pp_val.length > 0 ? pp_val[0] : '');
                    (<any>$('.chosen-default-multiple-per')).chosen('destroy');
                    (<any>$('.chosen-default-multiple-per')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_single: 'Select manager'
                    }).on('change', function (e, p) {
                        if (e.target.value === '000') {
                            (<any>$('.chosen-default-multiple-per')).val(THIS.param.managers);
                            (<any>$('.chosen-default-multiple-per')).trigger("chosen:updated");
                            let ___selectedTab = 0;
                            let tabs = $('#sort-tabs').find('li');
                            tabs.each(function (i, v) {
                                if ($(v).find('a.active').length > 0) {
                                    ___selectedTab = i;
                                }
                            });
                            let child = ___selectedTab + 1;
                            (<any>$('.owl-carousel')).owlCarousel('destroy');
                            $('#sort-tabs').find('li').find('a').removeClass('active');
                            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                            (<any>$('#PersonQuickModal')).modal('show');
                        } else {
                            THIS.param.managers = [e.target.value];
                            THIS.validationThisForm();
                        }
                    });
                }, 500);
            }
        });
    }

    JobType = [];

    jt_get() {
        let THIS = this;
        this.JobType.length = 0;
        $.ajax({
            url: THIS.APPCONFIG.api + 'job-types?token=' + THIS.currentUser.token,
            data: {},
            type: 'get',
            success: function (res) {
                THIS.JobType = res.data;
                setTimeout(function () {
                    if ($('.reRenderJobType').length > 0) {
                        let job_type_id = parseInt($('.reRenderJobType').attr('data-id'));
                        if (job_type_id > 0) {
                            THIS.param.job_type_id = job_type_id.toString();
                        }
                    }
                    (<any>$('.chosen-default-single-jti')).chosen('destroy');
                    (<any>$('.chosen-default-single-jti')).val(THIS.param.job_type_id);
                    (<any>$('.chosen-default-single-jti')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_single: 'Select Job Type'
                    }).on('change', function (e, p) {
                        if (e.target.value === '000') {
                            (<any>$('.chosen-default-single-jti')).val(THIS.param.job_type_id);
                            (<any>$('.chosen-default-single-jti')).trigger("chosen:updated");
                            let ___selectedTab = 0;
                            let tabs = $('#sort-tabs').find('li');
                            tabs.each(function (i, v) {
                                if ($(v).find('a.active').length > 0) {
                                    ___selectedTab = i;
                                }
                            });
                            let child = ___selectedTab + 1;
                            (<any>$('.owl-carousel')).owlCarousel('destroy');
                            $('#sort-tabs').find('li').find('a').removeClass('active');
                            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                            (<any>$('#JobTypesModal')).modal('show');
                        } else {
                            THIS.param.job_type_id = e.target.value;
                            THIS.validationThisForm();
                        }
                    });
                }, 100);
            }
        });
    }


    confirmDiscard() {
        this.discard.emit(1);
    }

    saveWorkOrderSubmit() {
        let THIS = this;
        let ldd = document.getElementById('UpdateThisProjectLadda');
        let ladda = Ladda.create(ldd);
        let mediaAttach = [];
        $.each(THIS.selectedWorkAttach, function (i, v) {
            mediaAttach.push(v.id);
        });
        // this.param.description = (<any>$('textarea.summernote')).summernote('code');
        this.param.attach_files = mediaAttach;
        this.param.start_date = this.param.start_date != null && this.param.start_date != '' ? this.CustomHelper.formatDateTimeToDB(this.param.start_date) : '';
        this.param.due_date = this.param.due_date != null && this.param.due_date != '' ? this.CustomHelper.formatDateTimeToDB(this.param.due_date) : '';

        let valid = this.validationThisForm();
        if (valid == 1) {
            let THIS = this;
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.api + 'projects/edit?token=' + THIS.currentUser.token,
                type: 'post',
                data: THIS.param,
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                        (<any>$('#editProjectModal')).modal('hide');
                        THIS.preview.emit(res.data);
                    }
                }
            });

        }

    }


    dateCreated = this.CustomHelper.dateToTz(new Date());
    changingStart = 0;
    dateCreatedOption = null;

    onCreatedSelect() {
        let THIS = this;
        if (this.dateCreated != null) {
            this.param.start_date = this.CustomHelper.formatDateTimeToDB(this.dateCreated);
            this.param.due_date = '';
            this.dateDue = null;
            this.changingStart = 1;
            setTimeout(function () {
                THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
                THIS.dateDueOption.defaultDate = this.dateDue;
                THIS.changingStart = 0;
            }, 400);
        } else {
            this.param.start_date = '';
            this.param.due_date = '';
            this.dateDue = null;
            this.changingStart = 1;
            setTimeout(function () {
                THIS.dateDueOption.minDate = new Date();
                THIS.dateDueOption.defaultDate = null;
                THIS.changingStart = 0;
            }, 400);
        }
        THIS.validationThisForm();
    }

    dateDue = null;
    dateDueOption = null;

    onDueSelect() {
        this.param.due_date = this.CustomHelper.formatDateTimeToDB(this.dateDue);
        this.validationThisForm();
    }


    selectedWorkAttach = [];

    uploadThisMedia(file, media_type, localtion, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        if (localtion != null) {
            formData.append('lat', localtion.lat);
            formData.append('lon', localtion.lon);
        }
        this.mediaService.create(formData).subscribe(res => {
            callback(res);
        });
    }

    removeThisMedia(id, media_type) {
        let param = {
            id: id, media_type: media_type
        };
        this.mediaService.remove(param).subscribe(res => {
        });
    }

    addWorkAttach() {
        $('#workAttach').click();
    }

    removeThisAttach(index, id) {
        this.selectedWorkAttach.splice(index, 1);
        this.removeThisMedia(id, 4);
        this.validationThisForm();
    }

    uploadWorkAttach(e) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            if (this.selectedWorkAttach.length < 3) {
                let eachFile = accepted[i];
                this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                            swal({
                                title: 'Storage Full',
                                text: 'Your Storage is full. Please delete some files',
                                icon: 'error',
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                        } else {
                            swal({
                                title: 'Invalid file format uploaded',
                                text: 'Your request is not valid or there is something wrong.Please try again',
                                icon: 'error',
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                        }
                    } else {
                        THIS.selectedWorkAttach.push(uploaded);
                        THIS.validationThisForm();
                    }
                });
            }
        }
    }

    rightSecPreview = 0;

    ngOnInit() {
        let THIS = this;
        $('.reRenderFacility').on('click', function () {
            THIS.getAllFacility();
        });
        $('body').on('click', '.reRenderJobType', function () {
            THIS.jt_get();
        });
        $('body').on('click', '.reRenderPerson', function () {
            THIS.personnel_get();
        });
    }

    ngOnDestroy() {
    }
}
