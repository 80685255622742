import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CustomHelper } from '../../../customhelper';
import { InvoicesService } from '../../../_services/invoices.service';
import { TabsService } from '../../../_services/tabs.service';
declare let swal: any;
declare let toastr: any;

@Component({
    selector: 'invoice-single-recurring',
    templateUrl: './singleRecurring.component.html'
})
export class SingleRecurringComponent {
    @Input() preId: any;
    @Output() discard = new EventEmitter<number>();
    @Output() removeThis = new EventEmitter<number>();
    @Output() editThis = new EventEmitter<number>();
    @Output() newUpdate = new EventEmitter<number>();
    CustomHelper = CustomHelper;

    currentUser = null;

    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true};
    constructor(private invoicesService: InvoicesService,private tabsService: TabsService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.preId != undefined){
            let preId = changes.preId.currentValue;
            this.getSingleInv(preId);
        }
    }

    Template = 0;
    invoice = null;
    getSingleInv(preId){
        this.invoice = null;
        this.Template = 0;
        let param = {id : preId};
        this.invoicesService.getRecurInvoiceSingle(param).subscribe(res => {
            if(res.status == 2000){
                res.data['invoice_date'] = this.CustomHelper.formatDate(res.data['invoice_date']);
                res.data['nextCreation'] = this.CustomHelper.DateTimeSelectedFormat(res.data['nextCreation']);
                res.data['dates']['start'] = this.CustomHelper.DateTimeSelectedFormat(res.data['dates']['start']);
                res.data['dates']['expire'] = res.data['dates']['expire'] == null ? '' : this.CustomHelper.DateTimeSelectedFormat(res.data['dates']['expire']);
                this.invoice = res.data;
                this.Template = 1;
            } else {
                this.Template = -1;
            }
        });
    }
    echoRepeatDays(repeatDaysStr){
        let repeatDays = repeatDaysStr.split(',');
        let days = '';
        $.each(repeatDays, function (i, v) {
            if(i == '0'){
                days += v;
            } else {
                days += ', '+v;
            }
        });
        days = days.replace(/,([^,]*)$/, ' and $1');
        return days;
    }
    currencyIcon():string {
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
      }
    isRecurExpire(invoice){
        if(invoice.dates.expire != undefined && invoice.dates.expire != null){
            if(new Date() > new Date(invoice.dates.expire)){
                return true;
            }
            if(new Date(invoice.nextCreation) > new Date(invoice.dates.expire)){
                return true;
            }
            if(invoice.status == 2){
                return true;
            }
            return false;
        } else {
            return false;
        }
    }

    cancelSingleView(){
        this.discard.emit(1);
    }
    removeThisInv(){
        this.removeThis.emit(this.invoice.id);
    }
    editThisInv(){
        this.editThis.emit(this.invoice.id);
    }

    childShow = 0;

    changeStatus(status){
        this.invoice.status = status;
        let param = {id : this.invoice.id, status : status};
        this.invoicesService.updateRecurInvoiceStatus(param).subscribe(res => {
            this.newUpdate.emit(1);
          toastr.success('Status has been updated successfully', 'Status Update', this.toastrOption);
        });
    }


    previewView(inv){
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/'+Session.team+'/invoices';
        window.history.pushState("","", url);
        localStorage.singleInv = JSON.stringify(inv);
        this.tabsService.makeFirstTab('invoices');
    }

}
