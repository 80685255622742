import { Component, Output, EventEmitter } from '@angular/core';
import { CustomHelper } from '../../../customhelper';
import { APPCONFIG } from '../../../config';
import { UserService } from '../../../_services/user.service';
import { TabsService } from '../../../_services/tabs.service';
import { MediaService } from '../../../_services/media.service';
import { WorkOrderService } from '../../../_services/work-orders.service';
import { AuthService } from '../../../_services/auth.service';
import { FacilityService } from '../../../_services/facility.service';
import { ProfileComponent } from '../../../pages/profile/profile.component';
declare let swal: any;
declare let toastr: any;
declare let Sortable: any;
declare let owlCarousel: any;
declare let Ladda: any;

@Component({
    selector: 'page-acc-users',
    templateUrl: './accUsers.component.html'
})
export class AccUsersComponent {
    @Output() singleView = new EventEmitter<number>();
    @Output() createNew = new EventEmitter<number>();
    @Output() editThis = new EventEmitter<number>();
    toastrOption = { timeOut: 5000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true };
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;

    ROUTES = '/account/users';
    currentUser = null;
    innerCase = 430;
    constructor(protected userService: UserService,
        protected workOrderService: WorkOrderService,
        protected authService: AuthService,
        protected mediaService: MediaService,
        protected facilityService: FacilityService,
        protected tabsService: TabsService) {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.currentUser = Session;
        // Pagination
        this.currentUser.pagination != null ? this.perPage = this.currentUser.pagination.acc : '';
        this.plan = this.currentUser.subscription.plan;
        this.cycle = this.currentUser.subscription.cycle;
        this.employee = this.currentUser.subscription.employee;
        this.employeeCur = this.currentUser.subscription.employee;

        let url = '/' + Session.team + this.ROUTES;
        window.history.pushState("", "", url);
        this.getUserList();
        this.getSubscriptionPlans();
        this.getReport();
        this.getCustomerInfo();
        this.tabsService.getTabs().subscribe(tabs => {
            this.tabs = tabs;
        });
        if (window.innerWidth <= 600) {
            THIS.innerCase = 520;
        }
    }


    createNewAsset() {
        this.createNew.emit(1);
    }
    errorEmailMsg = '';
    errorEmailExist = [];
    inviteEmails = '';
    sendingInviteStatus = 0;
    inviteUserType = 0;
    inviteUserPermission = 3;
    inviteStep = 1;
    InviteUser() {
        let THIS = this;

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if ($(v).find('a.active').length > 0) { ___selectedTab = i; } });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#sendInviteModal')).modal('show');
        this.errorEmailExist.length = 0;
        this.To.length = 0;
        this.errorEmailMsg = '';
        this.inviteUserType = 0;
        this.inviteStep = 1;

        setTimeout(function () {
            (<any>$('.chosen-default-single-ut')).chosen('destroy');
            (<any>$('.chosen-default-single-ut')).val(THIS.inviteUserType);
            (<any>$('.chosen-default-single-ut')).chosen({
                allow_single_deselect: true,
                placeholder_text_single: 'Select User Type'
            }).on('change', function (e, p) {
                THIS.inviteUserType = e.target.value;
            });
        }, 600);
    }
    stepBackToInvite() {
        let THIS = this;
        if (this.inviteStep == 1) {
            setTimeout(function () {
                (<any>$('.chosen-default-single-ut')).chosen('destroy');
                (<any>$('.chosen-default-single-ut')).val(THIS.inviteUserType);
                (<any>$('.chosen-default-single-ut')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select User Type'
                }).on('change', function (e, p) {
                    THIS.inviteUserType = e.target.value;
                });
            }, 600);
        }
    }
    refreshPermission() {
        let THIS = this;
        this.modAccess.length = 0;
        this.ModuleList = THIS.currentUser.ModuleList;
        THIS.modAccess.push(1);
        if (this.inviteUserType < 3) {
            $.each(THIS.ModuleList, function (i, v) {
                THIS.modAccess.push(v.id);
            });
        } else {
            if (THIS.currentUser.modules.work_orders == 1) { this.modAccess.push(2) }
            if (THIS.currentUser.modules.invoices == 1) { this.modAccess.push(3) }
            if (THIS.currentUser.modules.assets == 1) { this.modAccess.push(4) }
            if (THIS.currentUser.modules.personnel == 1) { this.modAccess.push(5) }
            if (THIS.currentUser.modules.inventory == 1) { this.modAccess.push(6) }
            if (THIS.currentUser.modules.contacts == 1) { this.modAccess.push(7) }
            if (THIS.currentUser.modules.calendar == 1) { this.modAccess.push(8) }
            if (THIS.currentUser.modules.projects == 1) { this.modAccess.push(9) }
            if (THIS.currentUser.modules.inspections == 1) { this.modAccess.push(10) }
            if (THIS.currentUser.modules.reports == 1) { this.modAccess.push(11) }
            if (THIS.currentUser.modules.library == 1) { this.modAccess.push(12) }
            if (THIS.currentUser.modules.keys == 1) { this.modAccess.push(13) }
            if (THIS.currentUser.modules.fm360 == 1) { this.modAccess.push(14) }
        }
        THIS.Data_facility_id = ['0'];
        this.getAllFacility();
    }
    sendInvitation() {
        this.errorEmailMsg = '';
        this.errorEmailExist.length = 0;
        this.sendingInviteStatus = 1;
        this.inviteEmails = this.To.join(',');
        let param = {
            emails: this.inviteEmails,
            user_type: this.inviteUserType,
            permission: this.inviteUserPermission,
            modAccess: this.modAccess,
            facility: this.Data_facility_id
        }
        this.userService.invitation(param).subscribe(res => {
            if (res.status != undefined && res.status == 3000) {
                swal({
                    title: "Create User",
                    text: "You have reached your free plan limit. To create more users, please subscribe to the Pro plan from your account page.",
                    icon: "warning",
                    dangerMode: true,
                    className: 'swal-btn-center'
                });
                swal({
                    title: 'Subscribe',
                    text: 'You crossed your free plan limit to create user. Please subscribe.',
                    icon: 'warning',
                    className: 'swal-btn-center'
                });
            } else if (res.status == 2000) {
                this.inviteEmails = '';
                this.sendingInviteStatus = 0;
                this.getUserList();
                if (res.eExist.length > 0) {
                    this.errorEmailExist = res.eExist;
                } else {
                    this.inviteStep = 4
                }
            } else {
                this.errorEmailMsg = res.msg;
            }
        });
    }


    To = [];
    selectable = true;
    removable = true;
    readonly separatorKeysCodes: number[] = [12];
    allEvalid = 1;
    validateEmail() {
        let THIS = this;
        THIS.allEvalid = 1;
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        $.each(THIS.To, function (i, v) {
            if (re.test(v)) {
            } else {
                THIS.allEvalid = 0;
            }
        });
    }
    add(e): void {
        if (e.which == 13 || e.which == 32 || e.which == 188) {
            e.preventDefault();
            e.stopPropagation();
            let email = e.target.value;
            if ((email || '').trim()) {
                if (this.To.indexOf(email) < 0) {
                    this.To.push(email);
                }
            }
            e.target.value = '';
            this.validateEmail();
            /*let left = this.currentUser.subscription.employee - this.Users.length;
            if(this.To.length >= left){
                this.To.length = left;
            }*/
        }
    }
    addOnChange(e): void {
        let email = e.target.value;
        if ((email || '').trim()) {
            if (this.To.indexOf(email) < 0) {
                this.To.push(email);
            }
        }
        e.target.value = '';
        this.validateEmail();
        /*let left = this.currentUser.subscription.employee - this.Users.length;
        if(this.To.length >= left){
            this.To.length = left;
        }*/
    }

    remove(email): void {
        const index = this.To.indexOf(email);
        if (index > -1) {
            this.To.splice(index, 1);
        }
        this.validateEmail();
    }

    Users = [];
    licensedActive = 0;
    licensedEnabled = 0;
    licensePerc = 0;
    previewList = 0;
    sort_by = 'users.user_type';
    order_by = 'asc';
    sessionUpdate() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.plan = this.currentUser.subscription.plan;
        this.cycle = this.currentUser.subscription.cycle;
        this.employee = this.currentUser.subscription.employee;
        this.employeeCur = this.currentUser.subscription.employee;
    }
    getUserList() {
        let THIS = this;
        this.Users.length = 0;
        this.previewList = 0;
        this.licensedActive = 0;
        let param = {
            sort_by: this.sort_by,
            order_by: this.order_by,
            keyword: this.searchKeyword,
            pageNo: this.pageNo,
            perPage: this.perPage
        };
        this.userService.getAllRegUsers(param).subscribe(res => {
            this.sessionUpdate();
            this.previewList = 1;
            if (res.status == 2000) {
                this.Total = res.total;
                THIS.licensedActive = res.licenced;
                $.each(res.data, function (i, v) {
                    let company_address = '';
                    try {
                        v.company_address = JSON.parse(v.company_address);
                        company_address = v.company_address.address1 + ' ' + v.company_address.address2 + ' ' + v.company_address.city + ' ' + v.company_address.state + ' ' + v.company_address.country;
                    } catch (error) {
                        // nothing
                    }
                    res.data[i].company_address = company_address;
                });

                let thisUsers = [];
                let user = null;
                $.each(res.data, function (i, v) {
                    if (v.id == THIS.currentUser.id) {
                        thisUsers.push(v);
                    }
                    if (localStorage.AccPreId != undefined && localStorage.AccPreId == v.id) {
                        user = v;
                    }
                });
                $.each(res.data, function (i, v) {
                    if (v.id != THIS.currentUser.id) {
                        thisUsers.push(v);
                    }
                    if (localStorage.AccPreId != undefined && localStorage.AccPreId == v.id) {
                        user = v;
                    }
                });
                this.Users = thisUsers;

                if (user != null) {
                    delete localStorage.AccPreId;
                    this.selectedData.length = 0;
                    this.selectedData = [user.id];
                    this.singleView.emit(user);
                }

                this.licensePerc = (this.licensedActive / this.currentUser.subscription.employee) * 100;
                setTimeout(function () {
                    THIS.CustomHelper.tooltipRender();
                }, 100);

            }

        });
    }


    Total = 0;
    pageNo = 1;
    perPage = 25;
    pageEvent;
    paginateList() {
        if (this.pageEvent.pageSize == this.perPage) {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex + 1;
            this.getUserList();
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
            this.getUserList();
        }
    }
    searchKeyword = '';
    searchKeywordClear() {
        this.searchKeyword = '';
        this.getUserList();
    }
    filter() {
        let THIS = this;
        clearInterval(THIS.CustomHelper.woListSearch);
        THIS.CustomHelper.woListSearch = setInterval(function () {
            THIS.getUserList();
            clearInterval(THIS.CustomHelper.woListSearch);
        }, 1000);
    }
    highlighter(subject) {
        if (subject == '' || subject == null) {
            return subject;
        } else {
            subject = this.CustomHelper.makeStr(subject);
        }
        let matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
        let res = '';
        $.each(matches, function (i, v) {
            res = subject.replace(v, "<span class='text-warning-highlight'>" + v + "</span>");
        });
        if (res == '') {
            res = subject;
        }
        return res;
    }

    selectedData = [];
    checkThisRow(e, data) {
        e.preventDefault();
        let index = this.selectedData.indexOf(data.id);
        if (index > -1) {
            if (this.selectedData.length == 1) {
                this.selectedData.length = 0;
            } else {
                this.selectedData.splice(index, 1);
            }
        } else {
            this.selectedData.push(data.id);
        }
        $('.drop-menu-right').removeClass('open');

    }
    rightClickMenu(e, data) {
        e.preventDefault();
        this.DataSingle = null;

        if (this.selectedData.length < 2) {
            this.selectedData.length = 0;
            this.selectedData = [data.id];
        }

        let target = $(e.target);
        let w = target.closest('#right-click-holder').width();
        let h = target.closest('#right-click-holder').height();
        let p = target.closest('#right-click-holder').offset();
        let left = e.clientX - p.left;
        let top = e.clientY - p.top;
        let clickH = $('.drop-menu-right').height();
        // clickH = clickH < 150 ? 400 : clickH;
        if (this.selectedData.length > 1) { clickH = 150; }
        if ((w - left) < 230) { left = w - 250; }
        if (h < top + clickH) { top = top - (top + clickH - h); }
        if (top < 10) { top = 10; }

        let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
        if (ttarget.hasClass('open')) {
            $('.drop-menu-right').removeClass('open');
            ttarget.css({
                top: top,
                left: left
            });
            setTimeout(function () {
                ttarget.addClass('open');
            }, 100);
        }
        else {
            ttarget.css({
                top: top,
                left: left
            });
            ttarget.addClass('open');
        }
    }
    dropMenuRightToggle(e) {
        var container = $('.invoice-list');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.drop-menu-right').removeClass('open');
        }
    }
    singlePreview(e, user) {
        let THIS = this;
        if (e.ctrlKey) {
            if (e.which == 1) {
                let index = this.selectedData.indexOf(user.id);
                if (index > -1) {
                    this.selectedData.splice(user, 1);
                } else {
                    this.selectedData.push(user.id);
                }
            }
        } else {
            this.selectedData.length = 0;
            // if(user.id != this.currentUser.id) {
                this.selectedData = [user.id];
            // }
            this.DataSingle = null;
            this._tab = 1;

            this.SOR_projectItems.length = 0;
            this.SOR_projectItem_start = 0;
            this.SOR_projectItem_end = 10;
            this.SOR_projectItem_pageNo = 1;


            if (this.currentUser.id != user.id) {

                let ___selectedTab = 0;
                let tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) { if ($(v).find('a.active').length > 0) { ___selectedTab = i; } });
                let child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#SingleUsertModal')).modal('show');
                this.userService.singlePersonnel({ id: user.id, docs: 1 }).subscribe(res => {
                    if (res.status == 2000) {
                        this.DataSingle = res.data;
                        this.uDocs = res.data.docs;
                        this.profileEdit = 0;
                        this.selectedProfileGroupId.length = 0;
                        $.each(res.data.groups, function (i, v) {
                            THIS.selectedProfileGroupId.push(v.id.toString());
                        });
                        if (this.DataSingle.settings.mail_info == null) {
                            this.DataSingle.settings.mail_info = {
                                imap_port: "",
                                incoming_server: "",
                                outgoing_server: "",
                                password: "",
                                smtp_port: "",
                                username: ""
                            };
                        }
                        this.getAllGrouppersonnel();

                        this.modAccess.length = 0;
                        this.ModuleList = res.data.ModuleList;
                        if (res.data.user_type < 3) {
                            $.each(res.data.ModuleList, function (i, v) {
                                THIS.modAccess.push(v.id);
                            });
                        } else {
                            if (res.data.modules.work_orders == 1) { this.modAccess.push(2) }
                            if (res.data.modules.invoices == 1) { this.modAccess.push(3) }
                            if (res.data.modules.assets == 1) { this.modAccess.push(4) }
                            if (res.data.modules.personnel == 1) { this.modAccess.push(5) }
                            if (res.data.modules.inventory == 1) { this.modAccess.push(6) }
                            if (res.data.modules.contacts == 1) { this.modAccess.push(7) }
                            if (res.data.modules.calendar == 1) { this.modAccess.push(8) }
                            if (res.data.modules.projects == 1) { this.modAccess.push(9) }
                            if (res.data.modules.inspections == 1) { this.modAccess.push(10) }
                            if (res.data.modules.reports == 1) { this.modAccess.push(11) }
                            if (res.data.modules.library == 1) { this.modAccess.push(12) }
                            if (res.data.modules.keys == 1) { this.modAccess.push(13) }
                            if (res.data.modules.fm360 == 1) { this.modAccess.push(14) }
                        }
                    }
                });
            }
        }

    }

    checkEnability(user) {
        if (user.disabled == 0) {
          console.log(user)
            this.disabelUser = user;
            let ___selectedTab = 0;
            const tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                    ___selectedTab = i;
                }
            });
            const child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#disableUser')).modal('show');
            // this.disableThisUser(user)
        } else {
            this.enableThisUser(user);
        }
    }
    enableThisUser(user) {
        let THIS = this;
        THIS.selectedData = [user.id];
        swal({
            title: "Enable User",
            text: "Do you really want to enable this User?",
            icon: "warning",
            buttons: true,
        }).then((willDelete) => {
            if (willDelete) {
                let ids = THIS.selectedData;
                this.userService.enablePersonnel({ ids: ids }).subscribe(res => {
                    if (res.status == 2000) {
                        user.disabled = 0;

                        THIS.getUserList();
                        THIS.getSubscriptionPlans();
                        THIS.getReport();
                    } else {
                        swal({
                            title: "Enable User",
                            text: res.msg,
                            icon: "warning",
                            className: 'swal-btn-center'
                        });
                    }
                });
            }
        });
    }
    disabelUser: any = {};
    disableThisUser(user: any = null) {
        let THIS = this;
        THIS.selectedData = [this.disabelUser.id];
        // THIS.selectedData = [user];
        // swal({
        //     title: "Disable User",
        //     text: "Do you really want to disable this User?",
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        // }).then((willDelete) => {
        //     if (willDelete) {
        //         let ids = THIS.selectedData;
        //         this.userService.disablePersonnel({ids : ids}).subscribe(res => {
        //             if(res.status == 2000){
        //               // this.disabelUser.disabled = 1;
        //               user.disabled = 1;
        //
        //                 THIS.getUserList();
        //                 THIS.getSubscriptionPlans();
        //                 THIS.getReport();
        //             } else {
        //                 swal({
        //                     title: "Disable User",
        //                     text: res.msg,
        //                     icon: "warning",
        //                     className: 'swal-btn-center'
        //                 });
        //             }
        //         });
        //     }
        // });
        let ids = THIS.selectedData;
        this.userService.disablePersonnel({ ids: ids }).subscribe(res => {
            if (res.status == 2000) {
                this.disabelUser.disabled = 1;
                THIS.getUserList();
                THIS.getSubscriptionPlans();
                THIS.getReport();
                (<any>$('#disableUser')).modal('hide');
            } else {
                (<any>$('#disableUser')).modal('hide');
                // swal({
                //   title: "Disable User",
                //   text: res.msg,
                //   icon: "warning",
                //   className: 'swal-btn-center'
                // });
            }
        });
    }

    removeThisSelectedUser() {
        this.removeThisData();
    }
    deleteThisUser() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#deleteUser')).modal('show');
    }
    removeThisUser(user) {
        this.DataSingle = user;
        this.removeThisData();
    }

    reRenderGroups() {
        let THIS = this;
        setTimeout(function () {
            (<any>$('.chosen-default-multiple-group')).val(THIS.selectedProfileGroupId);
            (<any>$('.chosen-default-multiple-group')).chosen('destroy');
            (<any>$('.chosen-default-multiple-group')).chosen({
                allow_single_deselect: true,
                placeholder_text_multiple: 'Select groups'
            }).on('change', function (e, p) {
                if (p.selected != undefined) {
                    THIS.selectedProfileGroupId.push(p.selected);
                } else {
                    THIS.selectedProfileGroupId.splice(THIS.selectedProfileGroupId.indexOf(p.deselected), 1);
                }
            });
        }, 500);
    }
    editThisUser() {
        let THIS = this;
        let id = [this.selectedData[0]];
        this.DataSingle = null;
        this._tab = 1;


        this.SOR_projectItems.length = 0;
        this.SOR_projectItem_start = 0;
        this.SOR_projectItem_end = 10;
        this.SOR_projectItem_pageNo = 1;

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if ($(v).find('a.active').length > 0) { ___selectedTab = i; } });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#SingleUsertModal')).modal('show');
        this.userService.singlePersonnel({ id: id, docs: 1 }).subscribe(res => {
            if (res.status == 2000) {
                this.DataSingle = res.data;
                this.uDocs = res.data.docs;
                this.profileEdit = 1;
                this.selectedProfileGroupId.length = 0;
                $.each(res.data.groups, function (i, v) {
                    THIS.selectedProfileGroupId.push(v.id.toString());
                });
                if (this.DataSingle.settings.mail_info == null) {
                    this.DataSingle.settings.mail_info = {
                        imap_port: "",
                        incoming_server: "",
                        outgoing_server: "",
                        password: "",
                        smtp_port: "",
                        username: ""
                    };
                }
                this.getAllGrouppersonnel();
                setTimeout(function () {
                    (<any>$('.chosen-default-multiple-group')).val(THIS.selectedProfileGroupId);
                    (<any>$('.chosen-default-multiple-group')).chosen('destroy');
                    (<any>$('.chosen-default-multiple-group')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_multiple: 'Select groups'
                    }).on('change', function (e, p) {
                        if (p.selected != undefined) {
                            THIS.selectedProfileGroupId.push(p.selected);
                        } else {
                            THIS.selectedProfileGroupId.splice(THIS.selectedProfileGroupId.indexOf(p.deselected), 1);
                        }
                    });
                }, 500);
            }
        });
    }
    removeThisData() {
        let THIS = this;
        if (this.DataSingle == null) {
            //THIS.selectedData
        } else {
            THIS.selectedData = [this.DataSingle.id];
        }
        let alertMsg = "Are you sure you want to delete this user? This user will be unassigned from any work order(s) assigned to if you proceed.";
        if (THIS.selectedData.length == 1) {
            let _id = THIS.selectedData[0];
            let index = this.Users.map(function (e) { return e.id; }).indexOf(_id);
            let _user = this.Users[index];
            if (_user.woAssign > 0) {
                alertMsg = "This user profile is assigned to " + _user.woAssign + " work order(s). This profile will be unassigned from the work order(s) if you proceed."
            }
        }
        // swal({
        //     title: "Delete User(s)",
        //     text: alertMsg,
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        // }).then((willDelete) => {
        //     if (willDelete) {
        //         let ids = THIS.selectedData;
        //         this.userService.removePersonnel({ids : ids}).subscribe(res => {
        //             if(res.status == 2000){
        //                 this.getUserList();
        //                 let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        //                 let ___selectedTab = 0;
        //                 let tabs = $('#sort-tabs').find('li');
        //                 tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
        //                 let child = ___selectedTab+1;
        //                 (<any>$('.owl-carousel')).owlCarousel('destroy');
        //                 $('#sort-tabs').find('li').find('a').removeClass('active');
        //                 $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
        //                 (<any>$('#SingleUsertModal')).modal('hide');
        //                 (<any>$('#deleteUser')).modal('show');
        //                 swal({
        //                     title: "Success",
        //                     text: res.msg,
        //                     icon: "success",
        //                     dangerMode: false,
        //                 }).then((willDelete) => {
        //                     swal.stopLoading();
        //                     swal.close();
        //                 });
        //             }
        //         });
        //     } else {
        //         swal.stopLoading();
        //         swal.close();
        //     }
        // });
        let ids = THIS.selectedData;
        this.userService.removePersonnel({ ids: ids }).subscribe(res => {
            if (res.status == 2000) {
                this.getUserList();
                let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
                let ___selectedTab = 0;
                let tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) { if ($(v).find('a.active').length > 0) { ___selectedTab = i; } });
                let child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#SingleUsertModal')).modal('hide');
                (<any>$('#deleteUser')).modal('hide');
              toastr.success('User(s) has been deleted successfully' , 'Delete User(s)', THIS.toastrOption);
            }
        });
    }

    tabs = [];
    selectedTab = 0;
    TabSlide;
    NewTabSwitch() {
        this.selectedTab = this.tabs.length - 1;
        this.tabsService.setSelectedTab(this.selectedTab);
        this.slideUpdate(this.selectedTab, 1);
    }
    slideUpdate(selectedTab, newD) {
        let THIS = this;
        setTimeout(function () {
            if (newD == 1) {
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                THIS.TabSlide = (<any>$('.owl-carousel')).owlCarousel({
                    loop: false,
                    nav: false,
                    dots: false,
                    mouseDrag: false,
                    touchDrag: false,
                    items: 1,
                    animateIn: 'fadeIn faster',
                    autoplay: false
                }).on('changed.owl.carousel', function (event) {
                    THIS.selectedTab = event.item.index;
                    THIS.tabsService.setSelectedTab(THIS.selectedTab);
                });
            }
            (<any>$('.owl-carousel')).trigger('to.owl.carousel', selectedTab);
        }, 100);
    }
    openProfile() {
        let THIS = this;
        this.tabsService.addTab({
            description: 'Profile',
            component: ProfileComponent,
            id: (new Date).getTime()
        });
        this.NewTabSwitch();
        let list = document.getElementById("sort-tabs");
        Sortable.create(list, {
            onEnd: function (e) {
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                THIS.tabs[e.oldIndex] = THIS.tabs.splice(e.newIndex, 1, THIS.tabs[e.oldIndex])[0];
                THIS.selectedTab = e.newIndex;
                THIS.slideUpdate(THIS.selectedTab, 1);
            },
        });
    }



    // =================================
    // Modal Part
    // =================================
    DataSingle = null;
    previewDetails = 1;
    profileEdit = 0;
    _tab = 1;
    ProfileGroup = [];
    selectedProfileGroupId = []
    getAllGrouppersonnel() {
        let THIS = this;
        let param = {
            keyword: '',
            pageNo: 1,
            perPage: 100,
            sort_by: 'name',
            order_by: 'asc',
        };
        this.userService.getAllGroups(param).subscribe(res => {
            if (res.status == 2000) {
                this.ProfileGroup = res.data;
            }
        });
    }
    updateGeneralInfo() {
        let THIS = this;
        let uDocs = [];
        let errCheck = 0;
        $.each(this.uDocs, function (i, v) {
            if ($.trim(v.title).length == 0) {
                v.title = '';
                swal({
                    title: "Blank Title",
                    text: 'Can\'t save document with blank title',
                    icon: "success",
                    dangerMode: false,
                    className: 'swal-btn-center'
                });
                errCheck = 1;
            }
            let e = v.expiry != null ? THIS.CustomHelper.formatDateTimeToDB(v.expiry) : v.expiry;
            v.expiry = e;
            uDocs.push(v);
        });
        if (errCheck == 1) {
            return true;
        }


        let names = this.DataSingle.name.split(' ');
        let param = {
            id: this.DataSingle.id,
            first_name: names[0],
            last_name: names[1],
            email: this.DataSingle.email,
            avatar: this.DataSingle.avatar,
            phone: this.DataSingle.profile.phone,
            address: this.DataSingle.profile.company_address,
            hourly_rate: this.DataSingle.profile.hourly_rate,
            group_id: this.selectedProfileGroupId,
            mail_info: this.DataSingle.settings.mail_info,
            mail_info_active: this.DataSingle.settings.mail_info_active,
            facility_id: this.Data_facility_id,
            user_type: this.DataSingle.user_type,
            permission: this.DataSingle.permit,
            modAccess: this.modAccess,
            docs: uDocs
        };
        this.userService.editPersonnel(param).subscribe(res => {
            if (res.status == 2000) {
                this.DataSingle = res.data;
                let THIS = this;
                toastr.success('Personnel information has been updated successfully', 'Personnel Information Update', THIS.toastrOption);
                this.profileEdit = 0;
                this.getUserList();
            } else {
                swal({
                    text: res.error,
                    icon: "error",
                    dangerMode: true,
                    className: 'swal-btn-center'
                });
            }
        });
    }
    DataSingleWork = [];
    woHistory = {
        sort_by: 'creation_date',
        order_by: 'desc',
        searchKeyword: '',
        pageNo: 1,
        perPage: 10,
        totalWorkOrders: 0
    };
    paginateWoList() {
        if (this.pageEvent.pageSize == this.woHistory.perPage) {
            this.woHistory.perPage = this.pageEvent.pageSize;
            this.woHistory.pageNo = this.pageEvent.pageIndex + 1;
            this.getWorkOrderRelates();
        } else {
            this.woHistory.perPage = this.pageEvent.pageSize;
            this.woHistory.pageNo = 1;
            this.getWorkOrderRelates();
        }
    }
    getWorkOrderRelates() {
        let THIS = this;
        this.previewDetails = 0;
        let param = {
            sort_by: this.woHistory.sort_by,
            order_by: this.woHistory.order_by,
            is_active: 1,
            keyword: this.woHistory.searchKeyword,
            pageNo: this.woHistory.pageNo,
            perPage: this.woHistory.perPage,
            status: [],
            createdBy: this.DataSingle != null ? this.DataSingle.id : 0,
            onlyMe: 1
        };
        this.DataSingleWork.length = 0;
        this.workOrderService.getWorkOrdes(param).subscribe(res => {
            this.previewDetails = 1;
            if (res.status == 2000 && res.data.length > 0) {
                let Data = [];
                $.each(res.data, function (i, v) {
                    Data.push(v);
                });
                this.DataSingleWork = Data;
                this.woHistory.totalWorkOrders = res.total;
                setTimeout(function () {
                    THIS.CustomHelper.tooltipRender();
                }, 100);
            } else {
                this.DataSingleWork.length = 0;
                this.woHistory.totalWorkOrders = 0;
            }
        });
    }
    showGraph = 0;
    lineOptions = {
        responsive: true,
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                gridLines: {
                    display: false
                }
            }]
        },
        legend: {
            display: false
        },
    };
    dataGraph = {
        labels: ['Day 7', 'Day 6', 'Day 5', 'Day 4', 'Day 3', 'Yesterday', 'Today'],
        datasets: [{
            label: "",
            backgroundColor: 'rgba(158,217,205,0.8)',
            borderColor: "rgba(158,217,205,1)",
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
            data: [0, 0, 0, 0, 0, 0, 0]
        }
        ]
    };
    getPersonalLoginHistory() {
        this.previewDetails = 0;
        let id = this.DataSingle != null ? this.DataSingle.id : 0;
        this.showGraph = 0;
        this.authService.getUserLoginHistory(id).subscribe(res => {
            this.previewDetails = 1;
            this.dataGraph.datasets[0]['data'] = res.data.reverse();
            this.showGraph = 1;
        });
    }
    modAccess = [];
    ModuleList = [];
    selectModAccess(id) {
        let THIS = this;
        if (id == 1) {
            this.modAccess.length = 0;
            THIS.modAccess.push(1);
            $.each(THIS.ModuleList, function (i, v) {
                THIS.modAccess.push(v.id);
            });
        } else {
            if (this.modAccess.indexOf(1) > -1) {
                this.modAccess.splice(this.modAccess.indexOf(1), 1);
            }
            let index = this.modAccess.indexOf(id);
            if (index > -1) {
                this.modAccess.splice(index, 1);
            } else {
                this.modAccess.push(id);
            }
        }
    }
    Data_facility_id = [];
    dtFacIdUpdate() {
        let THIS = this;
        $.each(THIS.DataSingle.facilities, function (i, v) {
            THIS.Data_facility_id.push(v.id.toString());
        });
    }
    changePermission(id) {
        this.DataSingle.permit = id;
    }
    selectUserType(type) {
        this.DataSingle.user_type = type;
        if (type == 2) {
            this.DataSingle.permit = 1;
            this.modAccess.length = 0;
            this.modAccess = [1, 2, 3, 4, 5, 6, 7, 8];


            let THIS = this;
            setTimeout(function () {
                (<any>$('.chosen-default-single-fac')).val('0');
                (<any>$('.chosen-default-single-fac')).attr('disabled', true);
                (<any>$('.chosen-default-single-fac')).chosen('destroy');
                (<any>$('.chosen-default-single-fac')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_multiple: 'Select Facility'
                });
            }, 400);
        } else {
            let THIS = this;
            setTimeout(function () {
                (<any>$('.chosen-default-single-fac')).val(THIS.Data_facility_id);
                (<any>$('.chosen-default-single-fac')).removeAttr('disabled');
                (<any>$('.chosen-default-single-fac')).chosen('destroy');
                (<any>$('.chosen-default-single-fac')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_multiple: 'Select Facility'
                }).on('change', function (e, p) {
                    if (p.selected != undefined) {
                        THIS.Data_facility_id.push(p.selected);
                    } else {
                        THIS.Data_facility_id.splice(THIS.Data_facility_id.indexOf(p.deselected), 1);
                    }
                });
            }, 400);
        }


    }
    uNotes = [];
    newNotes = '';
    newNtStatus = 0;
    selectedNote = {
      delNotId: 0,
      editNoteItem: null
    }

    createNewNote() {
        if (this.editNoteItem == null) {
            if (this.newNotes != '') {
                this.newNtStatus = 1;
                let param = {
                    user_id: this.DataSingle.id,
                    note: this.newNotes,
                };
                this.userService.noteCreate(param).subscribe(res => {
                    $('#empty-note').html('');
                    this.newNotes = '';
                    this.newNtStatus = 0;
                    this.getAllNote();
                });
            } else {
                $('#empty-note').html('Input field can\'t be empty!');
            }
        } else {
            if (this.newNotes != '') {
                this.newNtStatus = 1;
                let param = {
                    id: this.editNoteItem.id,
                    note: this.newNotes,
                };
                this.userService.noteEdit(param).subscribe(res => {
                    $('#empty-note').html('');
                    this.newNotes = '';
                    this.newNtStatus = 0;
                    this.editNoteItem = null;
                    this.getAllNote();
                });
            } else {
                $('#empty-note').html('Input field can\'t be empty!');
            }
        }
    }
    getAllNote() {
        let THIS = this;
        this.userService.noteGetAll({ user_id: [this.DataSingle.id] }).subscribe(res => {
            let Data = [];
            $.each(res.data, function (i, v) {
                v['created_at_human'] = THIS.CustomHelper.formatDate(v.created_at);
                Data.push(v);
            });
            this.uNotes = Data;
        });
    }
    delNotId = 0;
    deleteThisNote() {
      this.delNotId = this.selectedNote.delNotId;
        let THIS = this;
        if (this.delNotId > 0) {
            let param = { ids: [THIS.delNotId] };
            $.ajax({
                url: THIS.APPCONFIG.api + 'users/notes/remove?token=' + THIS.currentUser.token,
                type: 'post',
                data: param,
                success: function (res) {
                    if (res.status == 2000) {
                        THIS.getAllNote();
                        (<any>$('#deleteUserNote')).modal('hide');
                    }
                }
            });
        }
    }
    deleteNote() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#deleteUserNote')).modal('show');
    }
    editNoteItem = null;
    editThisNote() {
      this.editNoteItem = this.selectedNote.editNoteItem;
        if (this.editNoteItem != null) {
            this.newNotes = this.editNoteItem.note;
        }
    }


    uDocs = [];
    docGetAll() {
        /*this.docCreate.user_id = this.DataSingle.id;
        let param = this.docCreate;
        this.userService.docGetAll({user_id : [this.DataSingle.id]}).subscribe(res => {
            this.uDocs = res.data;
        });*/
    }
    addNewDoc = 0;
    docCreate = {
        user_id: 0,
        title: '',
        expiry: null,
        notify_admin: 0,
        notify_admin_option: '0',
        notify_user: 0,
        notify_user_option: '0',
        files: []
    };
    createNewDocument() {
        this.docCreate.user_id = this.DataSingle.id;
        let param = this.docCreate;
        this.userService.docCreate(param).subscribe(res => {
            this.docCreate = {
                user_id: 0,
                title: '',
                expiry: null,
                notify_admin: 0,
                notify_admin_option: '0',
                notify_user: 0,
                notify_user_option: '0',
                files: []
            };
            this.addNewDoc = 0;
            this.docGetAll();
        });
    }
    cancelDocUpload(index) {
        if (index > -1) {
            this.uDocs.splice(index, 1);
        }
    }
    addNewDocItem() {
        let docCreate = {
            user_id: this.DataSingle.id,
            title: 'Untitled',
            expiry: null,
            notify_admin: 0,
            notify_admin_option: '0',
            notify_user: 0,
            notify_user_option: '0',
            files: []
        };
        this.uDocs.splice(0, 0, docCreate);
    }
    dateCreatedOption = [];
    dateCreatedOptionGen(index, dateTime) {
        let THIS = this;
        this.dateCreatedOption[index] = {
            timePicker: true,
            enableTime: true,
            altInput: true,
            defaultDate: dateTime != null ? new Date(dateTime) : null,
            altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
            onChange: function (selectedDates, dateStr, instance) {
            },
        };
        this.dateCreatedOption[index].onChange = function (selectedDates, dateStr, instance) {
            THIS.uDocs[index].expiry = dateStr;
        };

        return this.dateCreatedOption[index];
    }



    uploadThisMedia(file, media_type, localtion, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        if (localtion != null) {
            formData.append('lat', localtion.lat);
            formData.append('lon', localtion.lon);
        }
        this.mediaService.create(formData).subscribe(res => {
            callback(res);
        });
    }
    selectTheseFiles(e, index) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            if (THIS.uDocs[index].files.length == 0) {
                let eachFile = accepted[i];
                this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                            swal({
                                title: "Storage Full",
                                text: 'Your Storage is full. Please delete some files',
                                icon: "error",
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                        } else {
                            swal({
                                title: "nvalid file format uploaded",
                                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
                                icon: "error",
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                        }
                    } else {
                        THIS.uDocs[index].files.push(uploaded);
                    }
                });
            }
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }
    chooseDocFile(e) {
        $(e.target).find('.eachDocFile').click();
    }

    Facility = [];
    FacilityComp = [];
    facIds = [];
    getAllFacility() {
        let THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            this.FacilityComp.length = 0;
            this.FacilityComp = res.facility;
            $.each(res.facility, function (i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                THIS.facIds.push(v.id);
                $.each(v.child, function (j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                    THIS.facIds.push(vC.id);
                    $.each(vC.child, function (k, vCC) {
                        vCC['pp'] = 2;
                        THIS.Facility.push(vCC);
                        THIS.facIds.push(vCC.id);
                        $.each(vCC.child, function (l, vCCC) {
                            vCCC['pp'] = 3;
                            THIS.Facility.push(vCCC);
                            THIS.facIds.push(vCCC.id);
                        });
                    });
                });
            });
            setTimeout(function () {
                (<any>$('.chosen-default-single-fac')).val(THIS.Data_facility_id);
                (<any>$('.chosen-default-single-fac')).chosen('destroy');
                (<any>$('.chosen-default-single-fac')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_multiple: 'Select Facility'
                }).on('change', function (e, p) {
                    if (p.selected != undefined) {
                        if (p.selected != '0') {
                            let ind = THIS.Data_facility_id.indexOf('0');
                            if (ind > -1) {
                                THIS.Data_facility_id.splice(THIS.Data_facility_id.indexOf('0'), 1);
                            }
                        } else {
                            THIS.Data_facility_id.length = 0;
                        }
                        THIS.Data_facility_id.push(p.selected);
                    } else {
                        THIS.Data_facility_id.splice(THIS.Data_facility_id.indexOf(p.deselected), 1);
                    }

                    (<any>$('.chosen-default-single-fac')).val(THIS.Data_facility_id);
                    (<any>$('.chosen-default-single-fac')).trigger("chosen:updated");
                });
            }, 100);
        });
    }


    SOR_clients = [];
    SOR_client = '';
    getAllClients() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'sor/project/getAllSor?token=' + THIS.currentUser.token,
            type: 'get',
            data: {},
            success: function (res) {
                if (res.status == 2000) {
                    THIS.SOR_clients = res.data;
                    setTimeout(function () {
                        (<any>$('.clientData')).chosen('destroy');
                        (<any>$('.clientData')).chosen({
                            allow_single_deselect: true,
                            placeholder_text_single: 'Select Client'
                        }).on('change', function (e, p) {
                            if (p != undefined) {
                                THIS.SOR_client = p.selected;
                                THIS.getAllProjects();
                            }
                        });
                        (<any>$('.projectData')).chosen({
                            allow_single_deselect: true,
                            placeholder_text_single: 'Select Project'
                        })
                    }, 100);
                }
            }
        });
    }
    SOR_projects = [];
    SOR_project = '';
    getAllProjects() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'sor/project/getAll?token=' + THIS.currentUser.token,
            type: 'post',
            data: { sor_id: THIS.SOR_client, personId: THIS.DataSingle.id },
            success: function (res) {
                if (res.status == 2000) {
                    THIS.SOR_projects = res.data;
                    setTimeout(function () {
                        (<any>$('.projectData')).chosen('destroy');
                        (<any>$('.projectData')).chosen({
                            allow_single_deselect: true,
                            placeholder_text_single: 'Select Project'
                        }).on('change', function (e, p) {
                            if (p != undefined) {
                                THIS.SOR_project = p.selected;
                                THIS.getAllProjectItems();
                            }
                        });
                    }, 100);
                }
            }
        });
    }
    SOR_projectItems = [];
    SOR_projectItem_start = 0;
    SOR_projectItem_end = 10;
    SOR_projectItem_pageNo = 1;
    SOR_empty = 0;
    getAllProjectItems() {
        let THIS = this;

        this.SOR_projectItem_start = 0;
        this.SOR_projectItem_end = 10;
        this.SOR_projectItem_pageNo = 1;

        let index = this.SOR_projects.map(function (e) { return e.id.toString(); }).indexOf(THIS.SOR_project);
        if (index > -1) {
            this.SOR_empty = 0;
            this.SOR_projectItems = this.SOR_projects[index].info;
            if (this.SOR_projectItems.length == 0) {
                this.SOR_empty = 1;
            }
        }
    }
    SOR_pro_item_pagination(type) {
        if (type == 'next') {
            this.SOR_projectItem_pageNo++;
            this.SOR_projectItem_start = (this.SOR_projectItem_pageNo - 1) * 10;
            this.SOR_projectItem_end = (this.SOR_projectItem_pageNo - 0) * 10;
            if (this.SOR_projectItem_end > this.SOR_projectItems.length) {
                this.SOR_projectItem_end = this.SOR_projectItems.length;
            }
        }
        if (type == 'prev') {
            if (this.SOR_projectItem_pageNo > 1) {
                this.SOR_projectItem_pageNo--;
                this.SOR_projectItem_start = (this.SOR_projectItem_pageNo - 1) * 10;
                this.SOR_projectItem_end = (this.SOR_projectItem_pageNo - 0) * 10;
                if (this.SOR_projectItem_end > this.SOR_projectItems.length) {
                    this.SOR_projectItem_end = this.SOR_projectItems.length;
                }
            }
        }
    }
    saveSorUserData() {
        let THIS = this;
        let Data = this.SOR_projectItems;

        let items = [];
        $.each(Data, function (i, v) {
            if (v.rate != null) {
                items.push({ sor_item_id: v.id, user_id: THIS.DataSingle.id, rate: v.rate });
            }
        });

        $.ajax({
            url: THIS.APPCONFIG.api + 'sor/user/add?token=' + THIS.currentUser.token,
            type: 'post',
            data: { sor: items },
            success: function (res) {
                if (res.status == 2000) {
                    swal({
                        title: "Item Update",
                        text: "SOR item have been updated successfully",
                        icon: "success",
                        dangerMode: true,
                        className: 'swal-btn-center'
                    });
                }
            }
        });
    }

    uploadAvatar() {
        if (this.profileEdit == 1) {
            $('#changeAvatar').click();
        }
    }
    uploadThisAvatar(e) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        let eachFile = accepted[0];
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            this.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                        swal({
                            title: "Storage Full",
                            text: 'Your Storage is full. Please delete some files',
                            icon: "error",
                            dangerMode: true,
                            className: 'swal-btn-center'
                        });
                    } else {
                        swal({
                            title: "Invalid file format uploaded",
                            text: 'Please upload an image in Jpeg or Png only.',
                            icon: "error",
                            dangerMode: true,
                            className: 'swal-btn-center'
                        });
                    }
                } else {
                    THIS.DataSingle.avatar = uploaded.file_path;
                }
            });
        } else {
            swal({
                title: "Invalid File",
                text: 'This file is not supported for avatar. Please select jpg/png/gif file.',
                icon: "error",
                dangerMode: true,
                className: 'swal-btn-center'
            });
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }



    EditSubscription = 0;
    SubscriptionStep = 3;
    plan = 'Pro';
    planSelected = null;
    cycle = 'year';
    employee = 1;
    employeeCur = 1;
    employeeUpdate() {
        if (this.employee < 1) {
            this.employee = 1;
        }
    }
    Plans = [];
    getSubscriptionPlans() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/getPlans?token=' + THIS.currentUser.token,
            type: 'get',
            success: function (res) {
                THIS.Plans = res.data;
                $.each(res.data, function (i, v) {
                    if (v.name == THIS.plan && v.type == THIS.cycle) {
                        THIS.planSelected = v;
                    }
                });
            }
        });
    }
    validateStripecard() {
        let THIS = this;
        let ldd = document.getElementById('Subscribed');
        let ladda = Ladda.create(ldd);
        let param = {
            plan: THIS.plan,
            cycle: THIS.cycle,
            employee: THIS.employee,
        };
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/UpdateSubscription?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                ladda.stop();
                if (res.status == 2000) {
                    THIS.SubscriptionStep = 5;
                    THIS.employee = 1;
                }
            }
        });
    }
    StripeCurrency = null;
    getCustomerInfo() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/getCustomerInfo?token=' + THIS.currentUser.token,
            type: 'get',
            success: function (res) {
                if (res.status == 2000) {
                    if (res.data.length > 0) {
                        THIS.StripeCurrency = res.data;
                    }
                }
            }
        });
    }
    resetSubscriptionInfo() {
        let THIS = this;
        if (THIS.currentUser.subscription.status == 99) {
            swal({
                title: "Cancelled Subscription",
                text: "Your subscription is cancelled. Do you want to reactivate it again?",
                icon: "warning",
                buttons: ['Cancel', 'Reactivate Current Subscription'],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    $.ajax({
                        url: THIS.APPCONFIG.api + 'subscription/reactivateSubscription?token=' + THIS.currentUser.token,
                        type: 'get',
                        success: function (res) {
                            THIS.getSessionUserInfo();
                            if (res.status == 2000) {
                                swal({
                                    title: "Reactivate Subscription",
                                    text: "Your subscription has been reactivated successfully",
                                    icon: "success"
                                }).then(() => {
                                    THIS.EditSubscription = 1;
                                    THIS.SubscriptionStep = 3;
                                    THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
                                    THIS.plan = THIS.currentUser.subscription.plan;
                                    THIS.cycle = THIS.currentUser.subscription.cycle;
                                    THIS.employee = THIS.currentUser.subscription.employee;
                                    THIS.employeeCur = THIS.currentUser.subscription.employee;
                                });
                            }
                        }
                    });
                }
            });
        } else {
            if (this.StripeCurrency == null || (this.StripeCurrency.currency.toLowerCase() == this.StripeCurrency.uCurrency.toLowerCase())) {
                let ___selectedTab = 0;
                let tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) { if ($(v).find('a.active').length > 0) { ___selectedTab = i; } });
                let child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');

                this.EditSubscription = 1;
                this.SubscriptionStep = 3;
                this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
                this.plan = this.currentUser.subscription.plan;
                this.cycle = this.currentUser.subscription.cycle;
                this.employee = this.currentUser.subscription.employee;
                this.employeeCur = this.currentUser.subscription.employee;
            } else {
                swal({
                    title: "Subscription Currency",
                    text: "It looks like you are currently subscribed to a plan in AUD. If you need to change your plan, please email us at support@equidesk.com",
                    icon: "warning",
                    className: 'swal-btn-center'
                });
                setTimeout(function () {
                    $('.swal-text').html('It looks like you are currently subscribed to a plan in AUD. <br> If you need to change your plan, <br> please email us at <a class="_theme-color" href="mailto:support@equidesk.com">support@equidesk.com</a>')
                }, 500)
            }
        }
    }
    mainTab: any;
    modalHideEffect() {
        let THIS = this;
        let selectedTab = 0;
        let tabs = (<any>$('#sort-tabs').find('li'));
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) { selectedTab = i; }
        });
        THIS.TabSlide = (<any>$('.owl-carousel')).owlCarousel({
            loop: false,
            nav: false,
            dots: false,
            mouseDrag: false,
            touchDrag: false,
            items: 1,
            animateIn: 'fadeIn faster',
            autoplay: false
        }).on('changed.owl.carousel', function (event) {
            THIS.selectedTab = event.item.index;
            THIS.tabsService.setSelectedTab(THIS.selectedTab);
        });
        (<any>$('.owl-carousel')).trigger('to.owl.carousel', selectedTab);
        THIS.tabsService.setSelectedTab(selectedTab);
    }
    Prorate = null;
    getProrate() {
        let THIS = this;
        THIS.Prorate = null;
        let param = {
            plan: THIS.plan,
            cycle: THIS.cycle,
            employee: THIS.employee
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/getChangeProrate?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                console.log(res);
                THIS.Prorate = res.data;
                THIS.Prorate.CMFD = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.CMFD, '');
                THIS.Prorate.CMFDNY = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.CMFDNY, '');
                THIS.Prorate.CMFDNYNI = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.CMFDNYNI, '');
                THIS.Prorate.TD = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.TD, '');
                setTimeout(function () {
                    THIS.CustomHelper.tooltipRender();
                }, 100);
            }
        });
    }

    Report = null;
    getReport() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/getAccountSubscription?token=' + THIS.currentUser.token,
            type: 'get',
            success: function (res) {
                if (res.status == 2000) {
                    THIS.Report = res.data;
                    if (THIS.Report.Subscription !== null) {
                        THIS.Report.Subscription.current_period_end = THIS.CustomHelper.dateDynamicFormat(THIS.Report.Subscription.current_period_end, '');
                    }
                }
            }
        });
    }

    getSessionUserInfo() {
        this.authService.getSessionUserInfo().subscribe(res => {
            if (res.status == 2000) {
                this.currentUser = res.data;
            }
        });
    }
    
    currencyIcon():string {    
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
    }
}
