import { Component } from '@angular/core';
import { CustomHelper } from '../../../customhelper';

@Component({
    selector: 'invoice-overview',
    templateUrl: './overview.component.html'
})
export class InvoiceOverviewComponent {
    CustomHelper = CustomHelper;
    constructor() {}

    type = 'horizontalBar';
    data = {
        labels: ["CURRENT", "OVERDUE 1-15 DAYS", "OVERDUE 16-30 DAYS", "OVERDUE 31-45 DAYS", "OVERDUE 45+ DAYS"],
        datasets: [
            {
                label: false,
                data: [2700, 7000, 3000, 25000, 1500],
                backgroundColor: '#36a2eb'
            }
        ]
    };
    options = {
        responsive: true,
        maintainAspectRatio: false,
        padding: 15,
        legend: {
            display: false,
        },
    };

}
