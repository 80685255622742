import {Router, ActivatedRoute} from '@angular/router';
import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {WorkOrderService} from '../../_services/work-orders.service';
import {AuthService} from '../../_services/auth.service';
import {MediaService} from '../../_services/media.service';
import {AssetService} from '../../_services/asset.service';
import {ChatService} from '../../_services/chat.service';
import {ContactsService} from '../../_services/contacts.service';
import {FacilityService} from '../../_services/facility.service';
import {InventoriesService} from '../../_services/inventories.service';
import {InvoicesService} from '../../_services/invoices.service';
import {Job_statusService} from '../../_services/job_status.service';
import {Job_typesService} from '../../_services/job_types.service';
import {UserService} from '../../_services/user.service';
import {FcmService} from '../../_services/fcm.service';
import {TabsService} from '../../_services/tabs.service';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';

declare let swal: any;
declare let toastr: any;
declare let Ladda: any;
declare let Inputmask: any;

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html'
})
export class CompanyProfileComponent {
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  Template = 1;
  countries = this.CustomHelper.getCountry();
  timezones = this.CustomHelper.getTimeZone();

  currentTab = 1;

  currentUser;
  vendorInfo;

  TeamInfo = null;
  teamInfoLoading: any = false;
  vendorInfoLoading: any = false;
  newDocParam: any = {
    info_name: null,
    info_value: null,
    info_doc: null,
    expiry: null,
    loading: false,
  };
  dateOption = null;
  expireLoading: boolean;
  tabType: 'business-info';
  vendor_param: any = {
    name: '',
    company_logo: null,
    email: '',
    website: '',
    phone: '',
    address: '',
    address_lat: '',
    address_lon: '',
    service_description: '',
    operation_radius: '50',
    industry: '',
    verify_doc: [],
    gallery: [],
  };
  sanitizedVendorParam: any = {};
  selectedCategoriesArray: any = [];
  VendorErrors: any = {};
  companyLoading = false;
  galleryLoading = false;

  constructor(private workOrderService: WorkOrderService, private activatedRoute: ActivatedRoute, private router: Router,
              private mediaService: MediaService,
              private authService: AuthService,
              private assetService: AssetService,
              private chatService: ChatService,
              private contactsService: ContactsService,
              private facilityService: FacilityService,
              private inventoriesService: InventoriesService,
              private invoicesService: InvoicesService,
              private job_statusService: Job_statusService,
              private job_typesService: Job_typesService,
              private userService: UserService,
  ) {

    this.currentUser = JSON.parse(localStorage.currentUserInfo);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const type = params['type'];
      console.log(type, 'type');
      // If 'type' is undefined, update the route with 'business-profile'
      if (!type) {
        this.setQueryParam('business-profile');
      }
      if (type === 'business-profile') {
        this.currentTab = 1;
        this.teamInfo();
      } else if (type === 'vendor-info') {
        this.currentTab = 2;
        this.getVendorInfo();
      }
    });
  }

  setQueryParam(paramValue: string): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {type: paramValue},
      queryParamsHandling: 'merge', // This keeps existing query params
    });
  }

  teamInfo() {
    const THIS = this;
    THIS.teamInfoLoading = true;
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'me/teamInfo?token=' + THIS.currentUser.token,
      type: 'post',
      data: {},
      success: function (res) {
        if (res.status == 2000) {
          THIS.teamInfoLoading = false;
          THIS.TeamInfo = res.data;
        }
      },
      error: function (err) {
        THIS.teamInfoLoading = false;
        console.error('Error:', err);
      }
    });
  }

  getVendorInfo() {
    const THIS = this;
    THIS.vendorInfoLoading = true;
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'verify-vendor-details?token=' + THIS.currentUser.token,
      type: 'post',
      contentType: 'application/json',
      success: function (res) {
        THIS.vendorInfoLoading = false;
        THIS.vendorInfo = res.data;
      },
      error: function (err) {
        THIS.vendorInfoLoading = false;
        console.error('Error:', err);
      }
    });
  }

  getIcon(file_name) {
    // Define arrays of image and file extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
    const fileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'zip', 'rar'];

    // Extract the file extension from the file name
    const extension = file_name.split('.').pop().toLowerCase();

    // Check if the extension matches an image or file type
    if (imageExtensions.includes(extension)) {
      return 'image'; // Material Icon for image files
    } else if (fileExtensions.includes(extension)) {
      return 'insert_drive_file'; // Material Icon for generic files
    } else {
      return 'help_outline'; // Material Icon for unknown types
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    const ldd = document.getElementById('Ladda');
    const ladda = Ladda.create(ldd);
    ladda.start();
    for (let i = 0; i < accepted.length; i++) {
      const eachFile = accepted[i];
      if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
        THIS.uploadThisMedia(eachFile, '1', function (uploaded) {
          THIS.TeamInfo.team.logo = uploaded.file_path;
          THIS.updateTeamInfo();
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  uploadThisMedia(file, media_type, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  updateTeamInfo() {
    const THIS = this;
    const ldd = document.getElementById('Ladda');
    const ladda = Ladda.create(ldd);
    const param = {
      email: THIS.TeamInfo.team.email,
      phone: THIS.TeamInfo.team.phone,
      address: THIS.TeamInfo.team.address,
      abn: THIS.TeamInfo.team.abn,
      logo: THIS.TeamInfo.team.logo
    };
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'me/teamInfo/update?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        ladda.stop();
        if (res.status == 2000) {
          toastr.success('Company information has been updated successfully', 'Company Information Update', this.toastrOption);
          (<any>$('._cTeamInvc')).click();
        }
      }
    });
  }

  addNewVersion(data: any) {
    const THIS = this;
    THIS.expireLoading = true;
    THIS.newDocParam.info_name = data.info_name;
    THIS.newDocParam.id = data.id;
    THIS.newDocParam.info_value = null;
    THIS.newDocParam.info_doc = null;
    THIS.newDocParam.expiry = null;

    setTimeout(() => {
      THIS.expireLoading = false;
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#newDocModal')).modal('show');
    }, 100);
  }

  closeNewVersionModal() {
    (<any>$('#newDocModal')).modal('hide');
  }

  restore() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'me?token=' + THIS.currentUser.token,
      type: 'GET',
      success: function (res) {
        if (res.status == 2000) {
          localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
          setTimeout(function () {
            THIS.assetService.refreshToken();
            THIS.authService.refreshToken();
            THIS.contactsService.refreshToken();
            THIS.facilityService.refreshToken();
            THIS.inventoriesService.refreshToken();
            THIS.invoicesService.refreshToken();
            THIS.job_statusService.refreshToken();
            THIS.job_typesService.refreshToken();
            THIS.mediaService.refreshToken();
            THIS.userService.refreshToken();
            THIS.workOrderService.refreshToken();
          }, 2000);
        }
      }
    });

  }

  submitVendor() {
    const THIS = this;
    const ldd = document.getElementById('updateSubmit');
    const ladda = Ladda.create(ldd);
    ladda.start();
    const param = {
      id: THIS.newDocParam.id,
      vendor_info: [{
        id: THIS.newDocParam.id,
        info_name: THIS.newDocParam.info_name,
        info_value: THIS.newDocParam.info_value,
        info_doc: THIS.newDocParam.info_doc,
        expiry: THIS.newDocParam.expiry,
      }]
    };
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'verify-vendor-apply?token=' + THIS.currentUser.token,
      type: 'post',
      data: JSON.stringify(param),
      contentType: 'application/json',
      success: function (res) {
        ladda.stop();
        if (res.status === 2000) {
          toastr.success('Document has been updated successfully', 'Vendor Submission', THIS.toastrOption);
          THIS.getVendorInfo();
          THIS.closeNewVersionModal();
        }
      },
      error: function (err) {
        ladda.stop();
        console.error('Error:', err);
      }
    });
  }

  submitVendor2() {
    const THIS = this;
    THIS.VendorErrors = {};
    const ldd = document.getElementById('updateSubmit2');
    const ladda = Ladda.create(ldd);
    ladda.start();

    /* Validate data */
    if (THIS.validateData()) {
      /*Sanitize info*/
      if (THIS.sanitizeInfo()) {
        $.ajax({
          url: this.APPCONFIG.apiAuth + 'verify-vendor-update?token=' + THIS.currentUser.token,
          type: 'post',
          data: JSON.stringify(THIS.sanitizedVendorParam),
          contentType: 'application/json',
          success: function (res) {
            ladda.stop();
            if (res.status === 2000) {
              THIS.closeSignupVendorModal();
              toastr.success('Vendor Info has been updated successfully', 'Vendor Submission', THIS.toastrOption);
              THIS.restore();
              THIS.getVendorInfo();
            }
          },
          error: function (err) {
            ladda.stop();
            console.error('Error:', err);
          }
        });
      } else {
        ladda.stop();
      }
    } else {
      ladda.stop();
      console.log(this.VendorErrors);
    }
  }

  validateData() {
    let validate = true;

    if (this.vendor_param.company_logo == null || this.vendor_param.company_logo == '') {
      this.VendorErrors['company_logo'] = 'Company logo is required';
      validate = false;
    } else {
      this.VendorErrors['company_logo'] = null;
    }


    if (this.vendor_param.operation_radius == null || this.vendor_param.operation_radius == '') {
      this.VendorErrors['operation_radius'] = 'Operation radius is required';
      validate = false;
    } else {
      this.VendorErrors['operation_radius'] = null;
    }

    if (this.vendor_param.industry == null || this.vendor_param.industry == '') {
      this.VendorErrors['industry'] = 'Industry is required';
      validate = false;
    } else {
      this.VendorErrors['industry'] = null;
    }


    if (this.vendor_param.address == null || this.vendor_param.address == '') {
      this.VendorErrors['address'] = 'Address is required';
      validate = false;
    } else {
      this.VendorErrors['company_address'] = null;
    }

    if (this.vendor_param.verify_doc.length == 0) {
      this.VendorErrors['verify_doc'] = 'Vendor info is required';
      validate = false;
    } else {
      this.VendorErrors['verify_doc'] = null;

      this.vendor_param.verify_doc.forEach((v, i) => {
        this.VendorErrors['document_' + i] = '';
        if (v['required'] && v['document_required']) {
          if (v['info_name'] == null) {
            this.VendorErrors['document_' + i] = this.VendorErrors['document_' + i] + ' &nbsp; Name field is required. ';
            validate = false;
          }
          if (v['info_value'] == null) {
            this.VendorErrors['document_' + i] = this.VendorErrors['document_' + i] + '&nbsp; Number field is required.';
            validate = false;
          }
          if (v['info_doc'] == null || v['info_doc'] == '') {
            this.VendorErrors['document_' + i] = this.VendorErrors['document_' + i] + '&nbsp; Document field is required.';
            validate = false;
          }
          if (v['expiry'] == null || v['expiry'] == '') {
            this.VendorErrors['document_' + i] = this.VendorErrors['document_' + i] + '&nbsp; Expiry field is required.';
            validate = false;
          }
        }
      });

    }
    console.log(this.VendorErrors);
    return validate;
  }

  sanitizeInfo() {
    this.sanitizedVendorParam = {
      vendor_info: [],
      name: this.vendor_param.name,
      email: this.vendor_param.email,
      phone: this.vendor_param.phone,
      website: this.vendor_param.website,
      address: this.vendor_param.address,
      address_lat: JSON.stringify(this.vendor_param.address_lat),
      address_lon: JSON.stringify(this.vendor_param.address_lon),
      service_description: this.vendor_param.service_description,
      operation_radius: this.vendor_param.operation_radius,
    };
    if (this.sanitizedVendorParam.website !== '' && this.sanitizedVendorParam.website !== null) {
      if (!this.sanitizedVendorParam.website.startsWith('https://')) {
        this.sanitizedVendorParam.website = 'https://' + this.vendor_param.website;
      }
    }

    if (this.vendor_param.company_address != null && this.vendor_param.company_address != '') {
      this.sanitizedVendorParam.vendor_info.push(
        {
          info_name: 'address',
          info_value: this.vendor_param.company_address,
          expiry: null,
          info_doc: null,
        },
      );
    }

    if (this.vendor_param.operation_radius != null && this.vendor_param.operation_radius != '') {
      this.sanitizedVendorParam.vendor_info.push(
        {
          info_name: 'operation_radius',
          info_value: this.vendor_param.operation_radius,
          expiry: null,
          info_doc: null,
        },
      );
    }

    if (this.vendor_param.industry != null && this.vendor_param.industry != '') {
      this.sanitizedVendorParam.vendor_info.push(
        {
          info_name: 'industry',
          info_value: this.vendor_param.industry,
          expiry: null,
          info_doc: null,
        },
      );
    }

    this.vendor_param.verify_doc.forEach((v) => {
      if (v['info_value'] != null && v['info_value'] != '') {
        this.sanitizedVendorParam.vendor_info.push({
          info_name: v['info_name'],
          info_value: v['info_value'],
          expiry: v['expiry'],
          info_doc: v['info_doc'],
        });
      }
    });

    return true;
  }

  expiryConfig(dateTime: any) {
    const THIS: any = this;
    this.dateOption = {
      altInput: true,
      minDate: 'today',
      disableMobile: true,
      defaultDate: dateTime != null ? new Date(dateTime) : null,
      onChange: function (selectedDates, dateStr, instance) {
      },
    };
    this.dateOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.newDocParam.expiry = dateStr;
    };
    return this.dateOption;
  }

  uploadDoc(e) {
    e.preventDefault();
    const THIS = this;
    THIS.newDocParam.loading = true;
    const accepted = e.target.files;
    const eachFile = accepted[0];
    let media_type: any = 1;
    if (eachFile.type.startsWith('image/') === false) {
      media_type = 4;
    }
    this.uploadThisMedia(eachFile, media_type, function (uploaded) {
      e.target.value = '';
      THIS.newDocParam.loading = false;
      if (uploaded.status !== undefined) {
        if (uploaded.status === 9000) {
          swal({
            title: 'Storage Full',
            text: 'Your Storage is full. Please delete some files',
            icon: 'error',
            dangerMode: true,
          });
        } else {
          swal({
            title: 'Invalid file format uploaded',
            text: 'Your request is not valid or there is something wrong.Please try again',
            icon: 'error',
            dangerMode: true,
          });
        }
      } else {
        THIS.newDocParam.info_doc = uploaded.file_path;
        THIS.newDocParam.loading = false;
      }
    });
    e.target.type = 'text';
    e.target.type = 'file';
    setTimeout(() => {
      this.newDocParam.loading = false;
    }, 5000);
  }

  validateUpload() {
    if (this.newDocParam.info_name == null || this.newDocParam.info_name == '') {
      return false;
    }
    if (this.newDocParam.info_value == null || this.newDocParam.info_value == '') {
      return false;
    }
    if (this.newDocParam.info_doc == null || this.newDocParam.info_doc == '') {
      return false;
    }
    if (this.newDocParam.expiry == null || this.newDocParam.expiry == '') {
      return false;
    }
    return true;
  }

  copyToClipboard(copyText) {
    const THIS = this;
    (navigator as any).clipboard.writeText(copyText).then(() => {
      console.log('Text copied to clipboard');
      toastr.success('Organisation ID has been copied to clipboard', '', THIS.toastrOption);
    }).catch((error) => {
      console.error('Could not copy text: ', error);
      toastr.error('Could not copy the Organisation ID.', '', THIS.toastrOption);

    });
  }

  changeCurrentTab(type: any) {
    if (type === 1) {
      this.setQueryParam('business-profile');
      this.currentTab = 1;
      this.teamInfo();
    } else if (type === 2) {
      this.setQueryParam('vendor-info');
      this.currentTab = 2;
      this.getVendorInfo();
    }
  }

  openSignupVendorModal() {
    this.vendor_param = {};
    this.vendor_param.verify_doc = [];
    // Define required documents
    const requiredDocuments = [
      'Company or Business Name Registration Certificate',
      'Public Liability Insurance',
      'Contractor/Trade License',
      'Workers Compensation Insurance',
    ];
    const vendorInfo: any = JSON.parse(JSON.stringify(this.vendorInfo));
    // Initialize vendor_param with safe null checks
    this.vendor_param = {
      company_logo: vendorInfo.company_logo || '',
      address: vendorInfo.company_info.address || '',
      service_description: vendorInfo.service_description || '',
      operation_radius: vendorInfo.company_info.operation_radius || '',
      industry: vendorInfo.company_info.industry || '',
      name: vendorInfo.company_name || '',
      email: vendorInfo.email || '',
      phone: vendorInfo.phone || '',
      website: vendorInfo.website || '',
      verify_doc: vendorInfo.verify_doc || [],
      gallery: vendorInfo.gallery || [],
    };
    console.log(this.vendor_param.website, 1);
    if (this.vendor_param.website.startsWith('https://')) {
      this.vendor_param.website = this.vendor_param.website.replace('https://', '');
    }
    console.log(this.vendor_param.website, 2);

    // Clear selectedCategoriesArray to prevent duplicates
    this.selectedCategoriesArray = [];

    // Process industry if it exists
    if (this.vendor_param.industry) {
      const industries = this.vendor_param.industry.split(',').map(ind => ind.trim());
      industries.forEach((industry) => {
        this.selectedCategoriesArray.push({name: industry});
      });
    }

    // Clear validation errors
    this.VendorErrors = {};

    // Check if required documents are in verify_doc, if not, add them
    requiredDocuments.forEach((docName) => {
      const existingDoc = this.vendor_param.verify_doc.find(doc => doc.info_name === docName);
      const isRequired = !(docName === 'Contractor/Trade License' || docName === 'Workers Compensation Insurance');
      if (!existingDoc) {
        this.vendor_param.verify_doc.push({
          info_name: docName,
          info_value: null,
          info_doc: null,
          expiry: null,
          loading: false,
          allowDelete: false,
          required: true,
          document_required: false,
        });
      }
    });

    // Add additional properties to verify_doc
    if (this.vendor_param.verify_doc.length > 0) {
      this.vendor_param.verify_doc.forEach((v) => {
        v['loading'] = false;
        const isRequiredDoc = requiredDocuments.includes(v['info_name']);
        v['allowDelete'] = !isRequiredDoc;
        v['required'] = true;
        // Only set required to false if it's Contractor/Trade License or Workers Compensation Insurance and info_value is null or empty
        if ((v['info_value'] == null || v['info_value'] === '') &&
          (v['info_name'] === 'Contractor/Trade License' || v['info_name'] === 'Workers Compensation Insurance')) {
          v['document_required'] = false;
        } else {
          v['document_required'] = true;
        }
      });
    }

    // Filter out the items to move
    const itemsToMove = this.vendor_param.verify_doc.filter(item =>
      item.info_name === 'Workers Compensation Insurance' || item.info_name === 'Contractor/Trade License'
    );

// Remove the filtered items from the original array
    const filteredDocuments = this.vendor_param.verify_doc.filter(item =>
      item.info_name !== 'Workers Compensation Insurance' && item.info_name !== 'Contractor/Trade License'
    );

// Find the index of "Public Liability Insurance"
    const pliIndex = filteredDocuments.findIndex(item => item.info_name === 'Public Liability Insurance');

// Insert the filtered items after "Public Liability Insurance"
    filteredDocuments.splice(pliIndex + 1, 0, ...itemsToMove);

    this.vendor_param.verify_doc = filteredDocuments;

    // Handle modal activation
    const modal = document.getElementById('signupVendorModal2');
    if (modal) {
      modal.classList.add('active');
    }
  }


  closeSignupVendorModal() {
    const modal = document.getElementById('signupVendorModal2');
    if (modal !== undefined && modal.classList.contains('active')) {
      modal.classList.remove('active');
    }
  }

  removeItem(i: any) {
    this.vendor_param.verify_doc.splice(i, 1);
  }

  addNewItem() {
    const param = {
      info_name: null,
      info_value: null,
      info_doc: null,
      expiry: null,
      loading: false,
      allowDelete: true,
      required: true,
      document_required: true,
    };
    this.vendor_param.verify_doc.push(param);
  }

  expiryConfig2(index: any, dateTime: any) {
    const THIS: any = this;
    this.dateOption[index] = {
      altInput: true,
      minDate: 'today',
      disableMobile: true,
      defaultDate: dateTime != null ? new Date(dateTime) : null,
      onChange: function (selectedDates, dateStr, instance) {
      },
    };
    this.dateOption[index].onChange = function (selectedDates, dateStr, instance) {
      THIS.vendor_param.verify_doc[index].expiry = dateStr;
      THIS.checkIfHasError(index);
    };
    return this.dateOption[index];
  }

  checkIfRequired(e, data, index) {
    data.document_required = e;
    this.VendorErrors['document_' + index] = null;
  }


  checkRequired(data: any) {
    if (data.info_name == 'Company or Business Name Registration Certificate' || data.info_name == 'Public Liability Insurance') {
      return false;
    }
    return true;
  }


  companyLogo(logo: any) {
    const THIS = this;
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'company-logo-update?token=' + THIS.currentUser.token,
      type: 'post',
      data: JSON.stringify({logo: logo}),
      contentType: 'application/json',
      success: function (res) {
        if (res.status === 2000) {
          THIS.vendor_param.company_logo = res.data.company_logo;
        }
      },
      error: function (err) {
        console.error('Error:', err);
      }
    });
  }

  galleryUpdate(file_path: any) {
    const THIS = this;
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'company-gallery-update?token=' + THIS.currentUser.token,
      type: 'post',
      data: JSON.stringify({file_path: file_path}),
      contentType: 'application/json',
      success: function (res) {
        if (res.status === 2000) {
        }
      },
      error: function (err) {
        console.error('Error:', err);
      }
    });
  }

  removeGalleryImage(id) {
    const THIS = this;
    const index = THIS.vendor_param.gallery.findIndex(item => item.id === id);
    if (index !== -1) {
      THIS.vendor_param.gallery.splice(index, 1);
    }
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'company-gallery-remove?token=' + THIS.currentUser.token,
      type: 'post',
      data: JSON.stringify({gallery_id: id}),
      contentType: 'application/json',
      success: function (res) {
      },
      error: function (err) {
        console.error('Error:', err);
      }
    });
  }

  uploadDoc2(e, each: any, index: any) {
    each.loading = true;
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    const eachFile = accepted[0];
    let media_type: any = 1;
    if (eachFile.type.startsWith('image/') === false) {
      media_type = 4;
    }
    this.uploadThisMedia(eachFile, media_type, function (uploaded) {
      e.target.value = '';
      each.loading = false;
      if (uploaded.status !== undefined) {
        if (uploaded.status === 9000) {
          swal({
            title: 'Storage Full',
            text: 'Your Storage is full. Please delete some files',
            icon: 'error',
            dangerMode: true,
          });
        } else {
          swal({
            title: 'Invalid file format uploaded',
            text: 'Your request is not valid or there is something wrong.Please try again',
            icon: 'error',
            dangerMode: true,
          });
        }
      } else {
        each.info_doc = uploaded.file_path;
        THIS.checkIfHasError(index);
      }
    });
    e.target.type = 'text';
    e.target.type = 'file';
  }

  updateRadius(e) {
    this.vendor_param.operation_radius = JSON.stringify(e);
    this.checkIfHasError();
  }

  selectedCategories(e: any[]) {
    this.selectedCategoriesArray = e;
    this.vendor_param.industry = e.map(v => v.name).join(',');
    this.checkIfHasError();
  }

  checkIfHasError(index: any = null) {
    if (this.VendorErrors['company_address'] != undefined || this.VendorErrors['company_address'] != null || this.VendorErrors['company_address'] != '') {
      if (this.vendor_param.company_address != null || this.vendor_param.company_address != '') {
        this.VendorErrors['company_address'] = null;
      }
    }
    if (this.VendorErrors['operation_radius'] != undefined || this.VendorErrors['operation_radius'] != null || this.VendorErrors['operation_radius'] != '') {
      if (this.vendor_param.operation_radius != null || this.vendor_param.operation_radius != '') {
        this.VendorErrors['operation_radius'] = null;
      }
    }
    if (this.VendorErrors['industry'] != undefined || this.VendorErrors['industry'] != null || this.VendorErrors['industry'] != '') {
      if (this.vendor_param.industry != null || this.vendor_param.industry != '') {
        this.VendorErrors['industry'] = null;
      }
    }
    if (this.VendorErrors['company_logo'] != undefined || this.VendorErrors['company_logo'] != null || this.VendorErrors['company_logo'] != '') {
      if (this.vendor_param.company_logo != null || this.vendor_param.company_logo != '') {
        this.VendorErrors['company_logo'] = null;
      }
    }
    if (index != null) {
      if (this.VendorErrors['document_' + index] != undefined || this.VendorErrors['document_' + index] != null || this.VendorErrors['document_' + index] != '') {

        this.vendor_param.verify_doc.forEach((v, i) => {
          if (index == i) {
            if (v['required'] && v['document_required']) {
              if (v['info_name'] != null && v['info_name'] != '') {
                this.VendorErrors['document_' + i] = null;
              }
              if (v['info_value'] != null || v['info_value'] != '') {
                this.VendorErrors['document_' + i] = null;

              }
              if (v['info_doc'] != null || v['info_doc'] != '') {
                this.VendorErrors['document_' + i] = null;
              }
              if (v['expiry'] != null || v['expiry'] != '') {
                this.VendorErrors['document_' + i] = null;
              }
            }
          }

        });
      }
    }
  }

  upload(e, type) {

    e.preventDefault();
    const THIS = this;
    if (type == 'company-logo') {
      THIS.companyLoading = true;
    } else {
      THIS.galleryLoading = true;
    }
    const accepted = e.target.files;
    const eachFile = accepted[0];
    if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
      this.uploadThisMedia(eachFile, '1', function (uploaded) {
        if (type == 'company-logo') {
          THIS.companyLoading = false;
        } else {
          THIS.galleryLoading = false;
        }
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please delete some files',
              icon: 'error',
              dangerMode: true,
            });

          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Your request is not valid or there is something wrong.Please try again',
              icon: 'error',
              dangerMode: true,
            });
          }
          if (type == 'company-logo') {
            THIS.companyLoading = false;
          } else {
            THIS.galleryLoading = false;
          }
        } else {
          if (type == 'company-logo') {
            THIS.companyLogo(uploaded.file_path);
            THIS.companyLoading = false;
          } else {
            THIS.galleryUpdate(uploaded.file_path);
            THIS.vendor_param.gallery.push({id: uploaded.id, file_path: uploaded.file_path});
            THIS.galleryLoading = false;
          }
          THIS.checkIfHasError();
        }
      });
    } else {
      swal('This file is not supported for avatar. Please select jpg/png/gif file.');
      if (type == 'company-logo') {
        THIS.companyLoading = false;
      } else {
        THIS.galleryLoading = false;
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
    setTimeout(() => {
      if (type == 'company-logo') {
        THIS.companyLoading = false;
      } else {
        THIS.galleryLoading = false;
      }
    }, 5000);
    this.checkIfHasError();
  }

  handleAddressChange2(place) {
    this.vendor_param.address = place.formatted_address;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    // Store latitude and longitude in vendor_param
    this.vendor_param.address_lat = lat;
    this.vendor_param.address_lon = lng;
    this.checkIfHasError();
  }

  signupVendor() {
    (<any>$('#signUpVendorTrigger')).click();
  }

}
