declare let Inputmask: any;
declare let inputmask: any;
function helper(){
    const Helper = {
        socketUrl : 'https://assets.equidesk.com:3200',
        fcmConfig : {
            apiKey: "AIzaSyAdcdFip66Ig5s6KYdWmftatRPymcyLr_A",
            authDomain: "equidesk-development.firebaseapp.com",
            databaseURL: "https://equidesk-development.firebaseio.com",
            projectId: "equidesk-development",
            storageBucket: "equidesk-development.appspot.com",
            messagingSenderId: "381779380351"
        },

        ConfCurRoute : 0,
        ConfRoute : [],
        ConfRouteHistory : [],
        woRouteDiscard : 0,
        woPresence : 0,
        woRender : null,

        woTimeCount : 0,
        woListSearch : null,
        woRecListSearch : null,
        getPerpage: function () {
            let perpage = localStorage.perpage != undefined ? localStorage.perpage : 10;
            return perpage;
        },
        tooltipRender: function(){
            (<any>$("body")).tooltip({ selector: '[data-toggle=tooltip]' });
            if($('._facilityLayout_cntn').length == 0){
                (<any>$(".nav-label-icon")).tooltip('destroy');
            }
        },

        timezone : [
            {
                "value": "UTC-11",
                "abbr": "U",
                "offset": -11,
                "isdst": false,
                "text": "(UTC-11:00) Coordinated Universal Time-11",
                "utc": [
                    "Pacific/Midway",
                    "Pacific/Niue",
                    "Pacific/Pago_Pago"
                ]
            },
            {
                "value": "Hawaiian Standard Time",
                "abbr": "HST",
                "offset": -10,
                "isdst": false,
                "text": "(UTC-10:00) Hawaii",
                "utc": [
                    "Pacific/Honolulu",
                    "Pacific/Johnston",
                    "Pacific/Rarotonga",
                    "Pacific/Tahiti"
                ]
            },
            {
                "value": "Alaskan Standard Time",
                "abbr": "AKDT",
                "offset": -8,
                "isdst": true,
                "text": "(UTC-09:00) Alaska",
                "utc": [
                    "America/Anchorage",
                    "America/Juneau",
                    "America/Nome",
                    "America/Sitka",
                    "America/Yakutat"
                ]
            },
            {
                "value": "Pacific Standard Time (Mexico)",
                "abbr": "PDT",
                "offset": -7,
                "isdst": true,
                "text": "(UTC-08:00) Baja California",
                "utc": [
                    "America/Santa_Isabel"
                ]
            },
            {
                "value": "Pacific Standard Time",
                "abbr": "PDT",
                "offset": -7,
                "isdst": true,
                "text": "(UTC-08:00) Pacific Time (US & Canada)",
                "utc": [
                    "America/Dawson",
                    "America/Los_Angeles",
                    "America/Tijuana",
                    "America/Vancouver",
                    "America/Whitehorse",
                    "PST8PDT"
                ]
            },
            {
                "value": "US Mountain Standard Time",
                "abbr": "UMST",
                "offset": -7,
                "isdst": false,
                "text": "(UTC-07:00) Arizona",
                "utc": [
                    "America/Creston",
                    "America/Dawson_Creek",
                    "America/Hermosillo",
                    "America/Phoenix",
                    "Etc/GMT+7"
                ]
            },
            {
                "value": "Mountain Standard Time (Mexico)",
                "abbr": "MDT",
                "offset": -6,
                "isdst": true,
                "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
                "utc": [
                    "America/Chihuahua",
                    "America/Mazatlan"
                ]
            },
            {
                "value": "Mountain Standard Time",
                "abbr": "MDT",
                "offset": -6,
                "isdst": true,
                "text": "(UTC-07:00) Mountain Time (US & Canada)",
                "utc": [
                    "America/Boise",
                    "America/Cambridge_Bay",
                    "America/Denver",
                    "America/Edmonton",
                    "America/Inuvik",
                    "America/Ojinaga",
                    "America/Yellowknife",
                    "MST7MDT"
                ]
            },
            {
                "value": "Central America Standard Time",
                "abbr": "CAST",
                "offset": -6,
                "isdst": false,
                "text": "(UTC-06:00) Central America",
                "utc": [
                    "America/Belize",
                    "America/Costa_Rica",
                    "America/El_Salvador",
                    "America/Guatemala",
                    "America/Managua",
                    "America/Tegucigalpa",
                    "Etc/GMT+6",
                    "Pacific/Galapagos"
                ]
            },
            {
                "value": "Central Standard Time",
                "abbr": "CDT",
                "offset": -5,
                "isdst": true,
                "text": "(UTC-06:00) Central Time (US & Canada)",
                "utc": [
                    "America/Chicago",
                    "America/Indiana/Knox",
                    "America/Indiana/Tell_City",
                    "America/Matamoros",
                    "America/Menominee",
                    "America/North_Dakota/Beulah",
                    "America/North_Dakota/Center",
                    "America/North_Dakota/New_Salem",
                    "America/Rainy_River",
                    "America/Rankin_Inlet",
                    "America/Resolute",
                    "America/Winnipeg",
                    "CST6CDT"
                ]
            },
            {
                "value": "Central Standard Time (Mexico)",
                "abbr": "CDT",
                "offset": -5,
                "isdst": true,
                "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
                "utc": [
                    "America/Bahia_Banderas",
                    "America/Cancun",
                    "America/Merida",
                    "America/Mexico_City",
                    "America/Monterrey"
                ]
            },
            {
                "value": "Canada Central Standard Time",
                "abbr": "CCST",
                "offset": -6,
                "isdst": false,
                "text": "(UTC-06:00) Saskatchewan",
                "utc": [
                    "America/Regina",
                    "America/Swift_Current"
                ]
            },
            {
                "value": "SA Pacific Standard Time",
                "abbr": "SPST",
                "offset": -5,
                "isdst": false,
                "text": "(UTC-05:00) Bogota, Lima, Quito",
                "utc": [
                    "America/Bogota",
                    "America/Cayman",
                    "America/Coral_Harbour",
                    "America/Eirunepe",
                    "America/Guayaquil",
                    "America/Jamaica",
                    "America/Lima",
                    "America/Panama",
                    "America/Rio_Branco",
                    "Etc/GMT+5"
                ]
            },
            {
                "value": "Eastern Standard Time",
                "abbr": "EDT",
                "offset": -4,
                "isdst": true,
                "text": "(UTC-05:00) Eastern Time (US & Canada)",
                "utc": [
                    "America/Detroit",
                    "America/Havana",
                    "America/Indiana/Petersburg",
                    "America/Indiana/Vincennes",
                    "America/Indiana/Winamac",
                    "America/Iqaluit",
                    "America/Kentucky/Monticello",
                    "America/Louisville",
                    "America/Montreal",
                    "America/Nassau",
                    "America/New_York",
                    "America/Nipigon",
                    "America/Pangnirtung",
                    "America/Port-au-Prince",
                    "America/Thunder_Bay",
                    "America/Toronto",
                    "EST5EDT"
                ]
            },
            {
                "value": "US Eastern Standard Time",
                "abbr": "UEDT",
                "offset": -4,
                "isdst": true,
                "text": "(UTC-05:00) Indiana (East)",
                "utc": [
                    "America/Indiana/Marengo",
                    "America/Indiana/Vevay",
                    "America/Indianapolis"
                ]
            },
            {
                "value": "Venezuela Standard Time",
                "abbr": "VST",
                "offset": -4.5,
                "isdst": false,
                "text": "(UTC-04:30) Caracas",
                "utc": [
                    "America/Caracas"
                ]
            },
            {
                "value": "Paraguay Standard Time",
                "abbr": "PYT",
                "offset": -4,
                "isdst": false,
                "text": "(UTC-04:00) Asuncion",
                "utc": [
                    "America/Asuncion"
                ]
            },
            {
                "value": "Atlantic Standard Time",
                "abbr": "ADT",
                "offset": -3,
                "isdst": true,
                "text": "(UTC-04:00) Atlantic Time (Canada)",
                "utc": [
                    "America/Glace_Bay",
                    "America/Goose_Bay",
                    "America/Halifax",
                    "America/Moncton",
                    "America/Thule",
                    "Atlantic/Bermuda"
                ]
            },
            {
                "value": "Central Brazilian Standard Time",
                "abbr": "CBST",
                "offset": -4,
                "isdst": false,
                "text": "(UTC-04:00) Cuiaba",
                "utc": [
                    "America/Campo_Grande",
                    "America/Cuiaba"
                ]
            },
            {
                "value": "SA Western Standard Time",
                "abbr": "SWST",
                "offset": -4,
                "isdst": false,
                "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
                "utc": [
                    "America/Anguilla",
                    "America/Antigua",
                    "America/Aruba",
                    "America/Barbados",
                    "America/Blanc-Sablon",
                    "America/Boa_Vista",
                    "America/Curacao",
                    "America/Dominica",
                    "America/Grand_Turk",
                    "America/Grenada",
                    "America/Guadeloupe",
                    "America/Guyana",
                    "America/Kralendijk",
                    "America/La_Paz",
                    "America/Lower_Princes",
                    "America/Manaus",
                    "America/Marigot",
                    "America/Martinique",
                    "America/Montserrat",
                    "America/Port_of_Spain",
                    "America/Porto_Velho",
                    "America/Puerto_Rico",
                    "America/Santo_Domingo",
                    "America/St_Barthelemy",
                    "America/St_Kitts",
                    "America/St_Lucia",
                    "America/St_Thomas",
                    "America/St_Vincent",
                    "America/Tortola",
                    "Etc/GMT+4"
                ]
            },
            {
                "value": "Pacific SA Standard Time",
                "abbr": "PSST",
                "offset": -4,
                "isdst": false,
                "text": "(UTC-04:00) Santiago",
                "utc": [
                    "America/Santiago",
                    "Antarctica/Palmer"
                ]
            },
            {
                "value": "Newfoundland Standard Time",
                "abbr": "NDT",
                "offset": -2.5,
                "isdst": true,
                "text": "(UTC-03:30) Newfoundland",
                "utc": [
                    "America/St_Johns"
                ]
            },
            {
                "value": "E. South America Standard Time",
                "abbr": "ESAST",
                "offset": -3,
                "isdst": false,
                "text": "(UTC-03:00) Brasilia",
                "utc": [
                    "America/Sao_Paulo"
                ]
            },
            {
                "value": "Argentina Standard Time",
                "abbr": "AST",
                "offset": -3,
                "isdst": false,
                "text": "(UTC-03:00) Buenos Aires",
                "utc": [
                    "America/Argentina/La_Rioja",
                    "America/Argentina/Rio_Gallegos",
                    "America/Argentina/Salta",
                    "America/Argentina/San_Juan",
                    "America/Argentina/San_Luis",
                    "America/Argentina/Tucuman",
                    "America/Argentina/Ushuaia",
                    "America/Buenos_Aires",
                    "America/Catamarca",
                    "America/Cordoba",
                    "America/Jujuy",
                    "America/Mendoza"
                ]
            },
            {
                "value": "SA Eastern Standard Time",
                "abbr": "SEST",
                "offset": -3,
                "isdst": false,
                "text": "(UTC-03:00) Cayenne, Fortaleza",
                "utc": [
                    "America/Araguaina",
                    "America/Belem",
                    "America/Cayenne",
                    "America/Fortaleza",
                    "America/Maceio",
                    "America/Paramaribo",
                    "America/Recife",
                    "America/Santarem",
                    "Antarctica/Rothera",
                    "Atlantic/Stanley",
                    "Etc/GMT+3"
                ]
            },
            {
                "value": "Greenland Standard Time",
                "abbr": "GDT",
                "offset": -3,
                "isdst": true,
                "text": "(UTC-03:00) Greenland",
                "utc": [
                    "America/Godthab"
                ]
            },
            {
                "value": "Montevideo Standard Time",
                "abbr": "MST",
                "offset": -3,
                "isdst": false,
                "text": "(UTC-03:00) Montevideo",
                "utc": [
                    "America/Montevideo"
                ]
            },
            {
                "value": "Bahia Standard Time",
                "abbr": "BST",
                "offset": -3,
                "isdst": false,
                "text": "(UTC-03:00) Salvador",
                "utc": [
                    "America/Bahia"
                ]
            },
            {
                "value": "UTC-02",
                "abbr": "U",
                "offset": -2,
                "isdst": false,
                "text": "(UTC-02:00) Coordinated Universal Time-02",
                "utc": [
                    "America/Noronha",
                    "Atlantic/South_Georgia",
                    "Etc/GMT+2"
                ]
            },
            {
                "value": "Mid-Atlantic Standard Time",
                "abbr": "MDT",
                "offset": -1,
                "isdst": true,
                "text": "(UTC-02:00) Mid-Atlantic - Old",
                "utc": []
            },
            {
                "value": "Azores Standard Time",
                "abbr": "ADT",
                "offset": 0,
                "isdst": true,
                "text": "(UTC-01:00) Azores",
                "utc": [
                    "America/Scoresbysund",
                    "Atlantic/Azores"
                ]
            },
            {
                "value": "Cape Verde Standard Time",
                "abbr": "CVST",
                "offset": -1,
                "isdst": false,
                "text": "(UTC-01:00) Cape Verde Is.",
                "utc": [
                    "Atlantic/Cape_Verde",
                    "Etc/GMT+1"
                ]
            },
            {
                "value": "Morocco Standard Time",
                "abbr": "MDT",
                "offset": 1,
                "isdst": true,
                "text": "(UTC) Casablanca",
                "utc": [
                    "Africa/Casablanca",
                    "Africa/El_Aaiun"
                ]
            },
            {
                "value": "UTC",
                "abbr": "UTC",
                "offset": 0,
                "isdst": false,
                "text": "(UTC) Coordinated Universal Time",
                "utc": [
                    "America/Danmarkshavn",
                    "Etc/GMT"
                ]
            },
            {
                "value": "GMT Standard Time",
                "abbr": "GDT",
                "offset": 1,
                "isdst": true,
                "text": "(UTC) Dublin, Edinburgh, Lisbon, London",
                "utc": [
                    "Atlantic/Canary",
                    "Atlantic/Faeroe",
                    "Atlantic/Madeira",
                    "Europe/Dublin",
                    "Europe/Guernsey",
                    "Europe/Isle_of_Man",
                    "Europe/Jersey",
                    "Europe/Lisbon",
                    "Europe/London"
                ]
            },
            {
                "value": "Greenwich Standard Time",
                "abbr": "GST",
                "offset": 0,
                "isdst": false,
                "text": "(UTC) Monrovia, Reykjavik",
                "utc": [
                    "Africa/Abidjan",
                    "Africa/Accra",
                    "Africa/Bamako",
                    "Africa/Banjul",
                    "Africa/Bissau",
                    "Africa/Conakry",
                    "Africa/Dakar",
                    "Africa/Freetown",
                    "Africa/Lome",
                    "Africa/Monrovia",
                    "Africa/Nouakchott",
                    "Africa/Ouagadougou",
                    "Africa/Sao_Tome",
                    "Atlantic/Reykjavik",
                    "Atlantic/St_Helena"
                ]
            },
            {
                "value": "W. Europe Standard Time",
                "abbr": "WEDT",
                "offset": 2,
                "isdst": true,
                "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
                "utc": [
                    "Arctic/Longyearbyen",
                    "Europe/Amsterdam",
                    "Europe/Andorra",
                    "Europe/Berlin",
                    "Europe/Busingen",
                    "Europe/Gibraltar",
                    "Europe/Luxembourg",
                    "Europe/Malta",
                    "Europe/Monaco",
                    "Europe/Oslo",
                    "Europe/Rome",
                    "Europe/San_Marino",
                    "Europe/Stockholm",
                    "Europe/Vaduz",
                    "Europe/Vatican",
                    "Europe/Vienna",
                    "Europe/Zurich"
                ]
            },
            {
                "value": "Central Europe Standard Time",
                "abbr": "CEDT",
                "offset": 2,
                "isdst": true,
                "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
                "utc": [
                    "Europe/Belgrade",
                    "Europe/Bratislava",
                    "Europe/Budapest",
                    "Europe/Ljubljana",
                    "Europe/Podgorica",
                    "Europe/Prague",
                    "Europe/Tirane"
                ]
            },
            {
                "value": "Romance Standard Time",
                "abbr": "RDT",
                "offset": 2,
                "isdst": true,
                "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
                "utc": [
                    "Africa/Ceuta",
                    "Europe/Brussels",
                    "Europe/Copenhagen",
                    "Europe/Madrid",
                    "Europe/Paris"
                ]
            },
            {
                "value": "Central European Standard Time",
                "abbr": "CEDT",
                "offset": 2,
                "isdst": true,
                "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
                "utc": [
                    "Europe/Sarajevo",
                    "Europe/Skopje",
                    "Europe/Warsaw",
                    "Europe/Zagreb"
                ]
            },
            {
                "value": "W. Central Africa Standard Time",
                "abbr": "WCAST",
                "offset": 1,
                "isdst": false,
                "text": "(UTC+01:00) West Central Africa",
                "utc": [
                    "Africa/Algiers",
                    "Africa/Bangui",
                    "Africa/Brazzaville",
                    "Africa/Douala",
                    "Africa/Kinshasa",
                    "Africa/Lagos",
                    "Africa/Libreville",
                    "Africa/Luanda",
                    "Africa/Malabo",
                    "Africa/Ndjamena",
                    "Africa/Niamey",
                    "Africa/Porto-Novo",
                    "Africa/Tunis",
                    "Etc/GMT-1"
                ]
            },
            {
                "value": "Namibia Standard Time",
                "abbr": "NST",
                "offset": 1,
                "isdst": false,
                "text": "(UTC+01:00) Windhoek",
                "utc": [
                    "Africa/Windhoek"
                ]
            },
            {
                "value": "GTB Standard Time",
                "abbr": "GDT",
                "offset": 3,
                "isdst": true,
                "text": "(UTC+02:00) Athens, Bucharest",
                "utc": [
                    "Asia/Nicosia",
                    "Europe/Athens",
                    "Europe/Bucharest",
                    "Europe/Chisinau"
                ]
            },
            {
                "value": "Middle East Standard Time",
                "abbr": "MEDT",
                "offset": 3,
                "isdst": true,
                "text": "(UTC+02:00) Beirut",
                "utc": [
                    "Asia/Beirut"
                ]
            },
            {
                "value": "Egypt Standard Time",
                "abbr": "EST",
                "offset": 2,
                "isdst": false,
                "text": "(UTC+02:00) Cairo",
                "utc": [
                    "Africa/Cairo"
                ]
            },
            {
                "value": "Syria Standard Time",
                "abbr": "SDT",
                "offset": 3,
                "isdst": true,
                "text": "(UTC+02:00) Damascus",
                "utc": [
                    "Asia/Damascus"
                ]
            },
            {
                "value": "E. Europe Standard Time",
                "abbr": "EEDT",
                "offset": 3,
                "isdst": true,
                "text": "(UTC+02:00) E. Europe",
                "utc": [
                    "Asia/Nicosia",
                    "Europe/Athens",
                    "Europe/Bucharest",
                    "Europe/Chisinau",
                    "Europe/Helsinki",
                    "Europe/Kiev",
                    "Europe/Mariehamn",
                    "Europe/Nicosia",
                    "Europe/Riga",
                    "Europe/Sofia",
                    "Europe/Tallinn",
                    "Europe/Uzhgorod",
                    "Europe/Vilnius",
                    "Europe/Zaporozhye"

                ]
            },
            {
                "value": "South Africa Standard Time",
                "abbr": "SAST",
                "offset": 2,
                "isdst": false,
                "text": "(UTC+02:00) Harare, Pretoria",
                "utc": [
                    "Africa/Blantyre",
                    "Africa/Bujumbura",
                    "Africa/Gaborone",
                    "Africa/Harare",
                    "Africa/Johannesburg",
                    "Africa/Kigali",
                    "Africa/Lubumbashi",
                    "Africa/Lusaka",
                    "Africa/Maputo",
                    "Africa/Maseru",
                    "Africa/Mbabane",
                    "Etc/GMT-2"
                ]
            },
            {
                "value": "FLE Standard Time",
                "abbr": "FDT",
                "offset": 3,
                "isdst": true,
                "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
                "utc": [
                    "Europe/Helsinki",
                    "Europe/Kiev",
                    "Europe/Mariehamn",
                    "Europe/Riga",
                    "Europe/Sofia",
                    "Europe/Tallinn",
                    "Europe/Uzhgorod",
                    "Europe/Vilnius",
                    "Europe/Zaporozhye"
                ]
            },
            {
                "value": "Turkey Standard Time",
                "abbr": "TDT",
                "offset": 3,
                "isdst": false,
                "text": "(UTC+03:00) Istanbul",
                "utc": [
                    "Europe/Istanbul"
                ]
            },
            {
                "value": "Israel Standard Time",
                "abbr": "JDT",
                "offset": 3,
                "isdst": true,
                "text": "(UTC+02:00) Jerusalem",
                "utc": [
                    "Asia/Jerusalem"
                ]
            },
            {
                "value": "Libya Standard Time",
                "abbr": "LST",
                "offset": 2,
                "isdst": false,
                "text": "(UTC+02:00) Tripoli",
                "utc": [
                    "Africa/Tripoli"
                ]
            },
            {
                "value": "Jordan Standard Time",
                "abbr": "JST",
                "offset": 3,
                "isdst": false,
                "text": "(UTC+03:00) Amman",
                "utc": [
                    "Asia/Amman"
                ]
            },
            {
                "value": "Arabic Standard Time",
                "abbr": "AST",
                "offset": 3,
                "isdst": false,
                "text": "(UTC+03:00) Baghdad",
                "utc": [
                    "Asia/Baghdad"
                ]
            },
            {
                "value": "Kaliningrad Standard Time",
                "abbr": "KST",
                "offset": 3,
                "isdst": false,
                "text": "(UTC+03:00) Kaliningrad, Minsk",
                "utc": [
                    "Europe/Kaliningrad",
                    "Europe/Minsk"
                ]
            },
            {
                "value": "Arab Standard Time",
                "abbr": "AST",
                "offset": 3,
                "isdst": false,
                "text": "(UTC+03:00) Kuwait, Riyadh",
                "utc": [
                    "Asia/Aden",
                    "Asia/Bahrain",
                    "Asia/Kuwait",
                    "Asia/Qatar",
                    "Asia/Riyadh"
                ]
            },
            {
                "value": "E. Africa Standard Time",
                "abbr": "EAST",
                "offset": 3,
                "isdst": false,
                "text": "(UTC+03:00) Nairobi",
                "utc": [
                    "Africa/Addis_Ababa",
                    "Africa/Asmera",
                    "Africa/Dar_es_Salaam",
                    "Africa/Djibouti",
                    "Africa/Juba",
                    "Africa/Kampala",
                    "Africa/Khartoum",
                    "Africa/Mogadishu",
                    "Africa/Nairobi",
                    "Antarctica/Syowa",
                    "Etc/GMT-3",
                    "Indian/Antananarivo",
                    "Indian/Comoro",
                    "Indian/Mayotte"
                ]
            },
            {
                "value": "Moscow Standard Time",
                "abbr": "MSK",
                "offset": 3,
                "isdst": false,
                "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
                "utc": [
                    "Europe/Kirov",
                    "Europe/Moscow",
                    "Europe/Simferopol",
                    "Europe/Volgograd"
                ]
            },
            {
                "value": "Samara Time",
                "abbr": "SAMT",
                "offset": 4,
                "isdst": false,
                "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
                "utc": [
                    "Europe/Astrakhan",
                    "Europe/Samara",
                    "Europe/Ulyanovsk"
                ]
            },
            {
                "value": "Iran Standard Time",
                "abbr": "IDT",
                "offset": 4.5,
                "isdst": true,
                "text": "(UTC+03:30) Tehran",
                "utc": [
                    "Asia/Tehran"
                ]
            },
            {
                "value": "Arabian Standard Time",
                "abbr": "AST",
                "offset": 4,
                "isdst": false,
                "text": "(UTC+04:00) Abu Dhabi, Muscat",
                "utc": [
                    "Asia/Dubai",
                    "Asia/Muscat",
                    "Etc/GMT-4"
                ]
            },
            {
                "value": "Azerbaijan Standard Time",
                "abbr": "ADT",
                "offset": 5,
                "isdst": true,
                "text": "(UTC+04:00) Baku",
                "utc": [
                    "Asia/Baku"
                ]
            },
            {
                "value": "Mauritius Standard Time",
                "abbr": "MST",
                "offset": 4,
                "isdst": false,
                "text": "(UTC+04:00) Port Louis",
                "utc": [
                    "Indian/Mahe",
                    "Indian/Mauritius",
                    "Indian/Reunion"
                ]
            },
            {
                "value": "Georgian Standard Time",
                "abbr": "GST",
                "offset": 4,
                "isdst": false,
                "text": "(UTC+04:00) Tbilisi",
                "utc": [
                    "Asia/Tbilisi"
                ]
            },
            {
                "value": "Caucasus Standard Time",
                "abbr": "CST",
                "offset": 4,
                "isdst": false,
                "text": "(UTC+04:00) Yerevan",
                "utc": [
                    "Asia/Yerevan"
                ]
            },
            {
                "value": "Afghanistan Standard Time",
                "abbr": "AST",
                "offset": 4.5,
                "isdst": false,
                "text": "(UTC+04:30) Kabul",
                "utc": [
                    "Asia/Kabul"
                ]
            },
            {
                "value": "West Asia Standard Time",
                "abbr": "WAST",
                "offset": 5,
                "isdst": false,
                "text": "(UTC+05:00) Ashgabat, Tashkent",
                "utc": [
                    "Antarctica/Mawson",
                    "Asia/Aqtau",
                    "Asia/Aqtobe",
                    "Asia/Ashgabat",
                    "Asia/Dushanbe",
                    "Asia/Oral",
                    "Asia/Samarkand",
                    "Asia/Tashkent",
                    "Etc/GMT-5",
                    "Indian/Kerguelen",
                    "Indian/Maldives"
                ]
            },
            {
                "value": "Pakistan Standard Time",
                "abbr": "PST",
                "offset": 5,
                "isdst": false,
                "text": "(UTC+05:00) Islamabad, Karachi",
                "utc": [
                    "Asia/Karachi"
                ]
            },
            {
                "value": "India Standard Time",
                "abbr": "IST",
                "offset": 5.5,
                "isdst": false,
                "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
                "utc": [
                    "Asia/Kolkata"
                ]
            },
            {
                "value": "Sri Lanka Standard Time",
                "abbr": "SLST",
                "offset": 5.5,
                "isdst": false,
                "text": "(UTC+05:30) Sri Jayawardenepura",
                "utc": [
                    "Asia/Colombo"
                ]
            },
            {
                "value": "Nepal Standard Time",
                "abbr": "NST",
                "offset": 5.75,
                "isdst": false,
                "text": "(UTC+05:45) Kathmandu",
                "utc": [
                    "Asia/Katmandu"
                ]
            },
            {
                "value": "Central Asia Standard Time",
                "abbr": "CAST",
                "offset": 6,
                "isdst": false,
                "text": "(UTC+06:00) Astana",
                "utc": [
                    "Antarctica/Vostok",
                    "Asia/Almaty",
                    "Asia/Bishkek",
                    "Asia/Qyzylorda",
                    "Asia/Urumqi",
                    "Etc/GMT-6",
                    "Indian/Chagos"
                ]
            },
            {
                "value": "Bangladesh Standard Time",
                "abbr": "BST",
                "offset": 6,
                "isdst": false,
                "text": "(UTC+06:00) Dhaka",
                "utc": [
                    "Asia/Dhaka",
                    "Asia/Thimphu"
                ]
            },
            {
                "value": "Ekaterinburg Standard Time",
                "abbr": "EST",
                "offset": 6,
                "isdst": false,
                "text": "(UTC+06:00) Ekaterinburg",
                "utc": [
                    "Asia/Yekaterinburg"
                ]
            },
            {
                "value": "Myanmar Standard Time",
                "abbr": "MST",
                "offset": 6.5,
                "isdst": false,
                "text": "(UTC+06:30) Yangon (Rangoon)",
                "utc": [
                    "Asia/Rangoon",
                    "Indian/Cocos"
                ]
            },
            {
                "value": "SE Asia Standard Time",
                "abbr": "SAST",
                "offset": 7,
                "isdst": false,
                "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
                "utc": [
                    "Antarctica/Davis",
                    "Asia/Bangkok",
                    "Asia/Hovd",
                    "Asia/Jakarta",
                    "Asia/Phnom_Penh",
                    "Asia/Pontianak",
                    "Asia/Saigon",
                    "Asia/Vientiane",
                    "Etc/GMT-7",
                    "Indian/Christmas"
                ]
            },
            {
                "value": "N. Central Asia Standard Time",
                "abbr": "NCAST",
                "offset": 7,
                "isdst": false,
                "text": "(UTC+07:00) Novosibirsk",
                "utc": [
                    "Asia/Novokuznetsk",
                    "Asia/Novosibirsk",
                    "Asia/Omsk"
                ]
            },
            {
                "value": "China Standard Time",
                "abbr": "CST",
                "offset": 8,
                "isdst": false,
                "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
                "utc": [
                    "Asia/Hong_Kong",
                    "Asia/Macau",
                    "Asia/Shanghai"
                ]
            },
            {
                "value": "North Asia Standard Time",
                "abbr": "NAST",
                "offset": 8,
                "isdst": false,
                "text": "(UTC+08:00) Krasnoyarsk",
                "utc": [
                    "Asia/Krasnoyarsk"
                ]
            },
            {
                "value": "Singapore Standard Time",
                "abbr": "MPST",
                "offset": 8,
                "isdst": false,
                "text": "(UTC+08:00) Kuala Lumpur, Singapore",
                "utc": [
                    "Asia/Brunei",
                    "Asia/Kuala_Lumpur",
                    "Asia/Kuching",
                    "Asia/Makassar",
                    "Asia/Manila",
                    "Asia/Singapore",
                    "Etc/GMT-8"
                ]
            },
            {
                "value": "W. Australia Standard Time",
                "abbr": "WAST",
                "offset": 8,
                "isdst": false,
                "text": "(UTC+08:00) Perth",
                "utc": [
                    "Antarctica/Casey",
                    "Australia/Perth"
                ]
            },
            {
                "value": "Taipei Standard Time",
                "abbr": "TST",
                "offset": 8,
                "isdst": false,
                "text": "(UTC+08:00) Taipei",
                "utc": [
                    "Asia/Taipei"
                ]
            },
            {
                "value": "Ulaanbaatar Standard Time",
                "abbr": "UST",
                "offset": 8,
                "isdst": false,
                "text": "(UTC+08:00) Ulaanbaatar",
                "utc": [
                    "Asia/Choibalsan",
                    "Asia/Ulaanbaatar"
                ]
            },
            {
                "value": "North Asia East Standard Time",
                "abbr": "NAEST",
                "offset": 9,
                "isdst": false,
                "text": "(UTC+09:00) Irkutsk",
                "utc": [
                    "Asia/Irkutsk"
                ]
            },
            {
                "value": "Tokyo Standard Time",
                "abbr": "TST",
                "offset": 9,
                "isdst": false,
                "text": "(UTC+09:00) Osaka, Sapporo, Tokyo",
                "utc": [
                    "Asia/Dili",
                    "Asia/Jayapura",
                    "Asia/Tokyo",
                    "Etc/GMT-9",
                    "Pacific/Palau"
                ]
            },
            {
                "value": "Korea Standard Time",
                "abbr": "KST",
                "offset": 9,
                "isdst": false,
                "text": "(UTC+09:00) Seoul",
                "utc": [
                    "Asia/Pyongyang",
                    "Asia/Seoul"
                ]
            },
            {
                "value": "Cen. Australia Standard Time",
                "abbr": "CAST",
                "offset": 9.5,
                "isdst": false,
                "text": "(UTC+09:30) Adelaide",
                "utc": [
                    "Australia/Adelaide",
                    "Australia/Broken_Hill"
                ]
            },
            {
                "value": "AUS Central Standard Time",
                "abbr": "ACST",
                "offset": 9.5,
                "isdst": false,
                "text": "(UTC+09:30) Darwin",
                "utc": [
                    "Australia/Darwin"
                ]
            },
            {
                "value": "E. Australia Standard Time",
                "abbr": "EAST",
                "offset": 10,
                "isdst": false,
                "text": "(UTC+10:00) Brisbane",
                "utc": [
                    "Australia/Brisbane",
                    "Australia/Lindeman"
                ]
            },
            {
                "value": "AUS Eastern Standard Time",
                "abbr": "AEST",
                "offset": 10,
                "isdst": false,
                "text": "(UTC+10:00) Canberra, Melbourne, Sydney",
                "utc": [
                    "Australia/Melbourne",
                    "Australia/Sydney"
                ]
            },
            {
                "value": "West Pacific Standard Time",
                "abbr": "WPST",
                "offset": 10,
                "isdst": false,
                "text": "(UTC+10:00) Guam, Port Moresby",
                "utc": [
                    "Antarctica/DumontDUrville",
                    "Etc/GMT-10",
                    "Pacific/Guam",
                    "Pacific/Port_Moresby",
                    "Pacific/Saipan",
                    "Pacific/Truk"
                ]
            },
            {
                "value": "Tasmania Standard Time",
                "abbr": "TST",
                "offset": 10,
                "isdst": false,
                "text": "(UTC+10:00) Hobart",
                "utc": [
                    "Australia/Currie",
                    "Australia/Hobart"
                ]
            },
            {
                "value": "Yakutsk Standard Time",
                "abbr": "YST",
                "offset": 10,
                "isdst": false,
                "text": "(UTC+10:00) Yakutsk",
                "utc": [
                    "Asia/Chita",
                    "Asia/Khandyga",
                    "Asia/Yakutsk"
                ]
            },
            {
                "value": "Central Pacific Standard Time",
                "abbr": "CPST",
                "offset": 11,
                "isdst": false,
                "text": "(UTC+11:00) Solomon Is., New Caledonia",
                "utc": [
                    "Antarctica/Macquarie",
                    "Etc/GMT-11",
                    "Pacific/Efate",
                    "Pacific/Guadalcanal",
                    "Pacific/Kosrae",
                    "Pacific/Noumea",
                    "Pacific/Ponape"
                ]
            },
            {
                "value": "Vladivostok Standard Time",
                "abbr": "VST",
                "offset": 11,
                "isdst": false,
                "text": "(UTC+11:00) Vladivostok",
                "utc": [
                    "Asia/Sakhalin",
                    "Asia/Ust-Nera",
                    "Asia/Vladivostok"
                ]
            },
            {
                "value": "New Zealand Standard Time",
                "abbr": "NZST",
                "offset": 12,
                "isdst": false,
                "text": "(UTC+12:00) Auckland, Wellington",
                "utc": [
                    "Antarctica/McMurdo",
                    "Pacific/Auckland"
                ]
            },
            {
                "value": "UTC+12",
                "abbr": "U",
                "offset": 12,
                "isdst": false,
                "text": "(UTC+12:00) Coordinated Universal Time+12",
                "utc": [
                    "Etc/GMT-12",
                    "Pacific/Funafuti",
                    "Pacific/Kwajalein",
                    "Pacific/Majuro",
                    "Pacific/Nauru",
                    "Pacific/Tarawa",
                    "Pacific/Wake",
                    "Pacific/Wallis"
                ]
            },
            {
                "value": "Fiji Standard Time",
                "abbr": "FST",
                "offset": 12,
                "isdst": false,
                "text": "(UTC+12:00) Fiji",
                "utc": [
                    "Pacific/Fiji"
                ]
            },
            {
                "value": "Magadan Standard Time",
                "abbr": "MST",
                "offset": 12,
                "isdst": false,
                "text": "(UTC+12:00) Magadan",
                "utc": [
                    "Asia/Anadyr",
                    "Asia/Kamchatka",
                    "Asia/Magadan",
                    "Asia/Srednekolymsk"
                ]
            },
            {
                "value": "Kamchatka Standard Time",
                "abbr": "KDT",
                "offset": 13,
                "isdst": true,
                "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
                "utc": [
                    "Asia/Kamchatka"
                ]
            },
            {
                "value": "Tonga Standard Time",
                "abbr": "TST",
                "offset": 13,
                "isdst": false,
                "text": "(UTC+13:00) Nuku'alofa",
                "utc": [
                    "Etc/GMT-13",
                    "Pacific/Enderbury",
                    "Pacific/Fakaofo",
                    "Pacific/Tongatapu"
                ]
            },
            {
                "value": "Samoa Standard Time",
                "abbr": "SST",
                "offset": 13,
                "isdst": false,
                "text": "(UTC+13:00) Samoa",
                "utc": [
                    "Pacific/Apia"
                ]
            }
        ],
        country : [
            {name: 'Afghanistan', code: 'AF'},
            {name: 'Åland Islands', code: 'AX'},
            {name: 'Albania', code: 'AL'},
            {name: 'Algeria', code: 'DZ'},
            {name: 'American Samoa', code: 'AS'},
            {name: 'AndorrA', code: 'AD'},
            {name: 'Angola', code: 'AO'},
            {name: 'Anguilla', code: 'AI'},
            {name: 'Antarctica', code: 'AQ'},
            {name: 'Antigua and Barbuda', code: 'AG'},
            {name: 'Argentina', code: 'AR'},
            {name: 'Armenia', code: 'AM'},
            {name: 'Aruba', code: 'AW'},
            {name: 'Australia', code: 'AU'},
            {name: 'Austria', code: 'AT'},
            {name: 'Azerbaijan', code: 'AZ'},
            {name: 'Bahamas', code: 'BS'},
            {name: 'Bahrain', code: 'BH'},
            {name: 'Bangladesh', code: 'BD'},
            {name: 'Barbados', code: 'BB'},
            {name: 'Belarus', code: 'BY'},
            {name: 'Belgium', code: 'BE'},
            {name: 'Belize', code: 'BZ'},
            {name: 'Benin', code: 'BJ'},
            {name: 'Bermuda', code: 'BM'},
            {name: 'Bhutan', code: 'BT'},
            {name: 'Bolivia', code: 'BO'},
            {name: 'Bosnia and Herzegovina', code: 'BA'},
            {name: 'Botswana', code: 'BW'},
            {name: 'Bouvet Island', code: 'BV'},
            {name: 'Brazil', code: 'BR'},
            {name: 'British Indian Ocean Territory', code: 'IO'},
            {name: 'Brunei Darussalam', code: 'BN'},
            {name: 'Bulgaria', code: 'BG'},
            {name: 'Burkina Faso', code: 'BF'},
            {name: 'Burundi', code: 'BI'},
            {name: 'Cambodia', code: 'KH'},
            {name: 'Cameroon', code: 'CM'},
            {name: 'Canada', code: 'CA'},
            {name: 'Cape Verde', code: 'CV'},
            {name: 'Cayman Islands', code: 'KY'},
            {name: 'Central African Republic', code: 'CF'},
            {name: 'Chad', code: 'TD'},
            {name: 'Chile', code: 'CL'},
            {name: 'China', code: 'CN'},
            {name: 'Christmas Island', code: 'CX'},
            {name: 'Cocos (Keeling) Islands', code: 'CC'},
            {name: 'Colombia', code: 'CO'},
            {name: 'Comoros', code: 'KM'},
            {name: 'Congo', code: 'CG'},
            {name: 'Congo, The Democratic Republic of the', code: 'CD'},
            {name: 'Cook Islands', code: 'CK'},
            {name: 'Costa Rica', code: 'CR'},
            {name: 'Cote D\'Ivoire', code: 'CI'},
            {name: 'Croatia', code: 'HR'},
            {name: 'Cuba', code: 'CU'},
            {name: 'Cyprus', code: 'CY'},
            {name: 'Czech Republic', code: 'CZ'},
            {name: 'Denmark', code: 'DK'},
            {name: 'Djibouti', code: 'DJ'},
            {name: 'Dominica', code: 'DM'},
            {name: 'Dominican Republic', code: 'DO'},
            {name: 'Ecuador', code: 'EC'},
            {name: 'Egypt', code: 'EG'},
            {name: 'El Salvador', code: 'SV'},
            {name: 'Equatorial Guinea', code: 'GQ'},
            {name: 'Eritrea', code: 'ER'},
            {name: 'Estonia', code: 'EE'},
            {name: 'Ethiopia', code: 'ET'},
            {name: 'Falkland Islands (Malvinas)', code: 'FK'},
            {name: 'Faroe Islands', code: 'FO'},
            {name: 'Fiji', code: 'FJ'},
            {name: 'Finland', code: 'FI'},
            {name: 'France', code: 'FR'},
            {name: 'French Guiana', code: 'GF'},
            {name: 'French Polynesia', code: 'PF'},
            {name: 'French Southern Territories', code: 'TF'},
            {name: 'Gabon', code: 'GA'},
            {name: 'Gambia', code: 'GM'},
            {name: 'Georgia', code: 'GE'},
            {name: 'Germany', code: 'DE'},
            {name: 'Ghana', code: 'GH'},
            {name: 'Gibraltar', code: 'GI'},
            {name: 'Greece', code: 'GR'},
            {name: 'Greenland', code: 'GL'},
            {name: 'Grenada', code: 'GD'},
            {name: 'Guadeloupe', code: 'GP'},
            {name: 'Guam', code: 'GU'},
            {name: 'Guatemala', code: 'GT'},
            {name: 'Guernsey', code: 'GG'},
            {name: 'Guinea', code: 'GN'},
            {name: 'Guinea-Bissau', code: 'GW'},
            {name: 'Guyana', code: 'GY'},
            {name: 'Haiti', code: 'HT'},
            {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
            {name: 'Holy See (Vatican City State)', code: 'VA'},
            {name: 'Honduras', code: 'HN'},
            {name: 'Hong Kong', code: 'HK'},
            {name: 'Hungary', code: 'HU'},
            {name: 'Iceland', code: 'IS'},
            {name: 'India', code: 'IN'},
            {name: 'Indonesia', code: 'ID'},
            {name: 'Iran, Islamic Republic Of', code: 'IR'},
            {name: 'Iraq', code: 'IQ'},
            {name: 'Ireland', code: 'IE'},
            {name: 'Isle of Man', code: 'IM'},
            {name: 'Israel', code: 'IL'},
            {name: 'Italy', code: 'IT'},
            {name: 'Jamaica', code: 'JM'},
            {name: 'Japan', code: 'JP'},
            {name: 'Jersey', code: 'JE'},
            {name: 'Jordan', code: 'JO'},
            {name: 'Kazakhstan', code: 'KZ'},
            {name: 'Kenya', code: 'KE'},
            {name: 'Kiribati', code: 'KI'},
            {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
            {name: 'Korea, Republic of', code: 'KR'},
            {name: 'Kuwait', code: 'KW'},
            {name: 'Kyrgyzstan', code: 'KG'},
            {name: 'Lao People\'S Democratic Republic', code: 'LA'},
            {name: 'Latvia', code: 'LV'},
            {name: 'Lebanon', code: 'LB'},
            {name: 'Lesotho', code: 'LS'},
            {name: 'Liberia', code: 'LR'},
            {name: 'Libyan Arab Jamahiriya', code: 'LY'},
            {name: 'Liechtenstein', code: 'LI'},
            {name: 'Lithuania', code: 'LT'},
            {name: 'Luxembourg', code: 'LU'},
            {name: 'Macao', code: 'MO'},
            {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
            {name: 'Madagascar', code: 'MG'},
            {name: 'Malawi', code: 'MW'},
            {name: 'Malaysia', code: 'MY'},
            {name: 'Maldives', code: 'MV'},
            {name: 'Mali', code: 'ML'},
            {name: 'Malta', code: 'MT'},
            {name: 'Marshall Islands', code: 'MH'},
            {name: 'Martinique', code: 'MQ'},
            {name: 'Mauritania', code: 'MR'},
            {name: 'Mauritius', code: 'MU'},
            {name: 'Mayotte', code: 'YT'},
            {name: 'Mexico', code: 'MX'},
            {name: 'Micronesia, Federated States of', code: 'FM'},
            {name: 'Moldova, Republic of', code: 'MD'},
            {name: 'Monaco', code: 'MC'},
            {name: 'Mongolia', code: 'MN'},
            {name: 'Montserrat', code: 'MS'},
            {name: 'Morocco', code: 'MA'},
            {name: 'Mozambique', code: 'MZ'},
            {name: 'Myanmar', code: 'MM'},
            {name: 'Namibia', code: 'NA'},
            {name: 'Nauru', code: 'NR'},
            {name: 'Nepal', code: 'NP'},
            {name: 'Netherlands', code: 'NL'},
            {name: 'Netherlands Antilles', code: 'AN'},
            {name: 'New Caledonia', code: 'NC'},
            {name: 'New Zealand', code: 'NZ'},
            {name: 'Nicaragua', code: 'NI'},
            {name: 'Niger', code: 'NE'},
            {name: 'Nigeria', code: 'NG'},
            {name: 'Niue', code: 'NU'},
            {name: 'Norfolk Island', code: 'NF'},
            {name: 'Northern Mariana Islands', code: 'MP'},
            {name: 'Norway', code: 'NO'},
            {name: 'Oman', code: 'OM'},
            {name: 'Pakistan', code: 'PK'},
            {name: 'Palau', code: 'PW'},
            {name: 'Palestinian Territory, Occupied', code: 'PS'},
            {name: 'Panama', code: 'PA'},
            {name: 'Papua New Guinea', code: 'PG'},
            {name: 'Paraguay', code: 'PY'},
            {name: 'Peru', code: 'PE'},
            {name: 'Philippines', code: 'PH'},
            {name: 'Pitcairn', code: 'PN'},
            {name: 'Poland', code: 'PL'},
            {name: 'Portugal', code: 'PT'},
            {name: 'Puerto Rico', code: 'PR'},
            {name: 'Qatar', code: 'QA'},
            {name: 'Reunion', code: 'RE'},
            {name: 'Romania', code: 'RO'},
            {name: 'Russian Federation', code: 'RU'},
            {name: 'RWANDA', code: 'RW'},
            {name: 'Saint Helena', code: 'SH'},
            {name: 'Saint Kitts and Nevis', code: 'KN'},
            {name: 'Saint Lucia', code: 'LC'},
            {name: 'Saint Pierre and Miquelon', code: 'PM'},
            {name: 'Saint Vincent and the Grenadines', code: 'VC'},
            {name: 'Samoa', code: 'WS'},
            {name: 'San Marino', code: 'SM'},
            {name: 'Sao Tome and Principe', code: 'ST'},
            {name: 'Saudi Arabia', code: 'SA'},
            {name: 'Senegal', code: 'SN'},
            {name: 'Serbia and Montenegro', code: 'CS'},
            {name: 'Seychelles', code: 'SC'},
            {name: 'Sierra Leone', code: 'SL'},
            {name: 'Singapore', code: 'SG'},
            {name: 'Slovakia', code: 'SK'},
            {name: 'Slovenia', code: 'SI'},
            {name: 'Solomon Islands', code: 'SB'},
            {name: 'Somalia', code: 'SO'},
            {name: 'South Africa', code: 'ZA'},
            {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
            {name: 'Spain', code: 'ES'},
            {name: 'Sri Lanka', code: 'LK'},
            {name: 'Sudan', code: 'SD'},
            {name: 'Suriname', code: 'SR'},
            {name: 'Svalbard and Jan Mayen', code: 'SJ'},
            {name: 'Swaziland', code: 'SZ'},
            {name: 'Sweden', code: 'SE'},
            {name: 'Switzerland', code: 'CH'},
            {name: 'Syrian Arab Republic', code: 'SY'},
            {name: 'Taiwan, Province of China', code: 'TW'},
            {name: 'Tajikistan', code: 'TJ'},
            {name: 'Tanzania, United Republic of', code: 'TZ'},
            {name: 'Thailand', code: 'TH'},
            {name: 'Timor-Leste', code: 'TL'},
            {name: 'Togo', code: 'TG'},
            {name: 'Tokelau', code: 'TK'},
            {name: 'Tonga', code: 'TO'},
            {name: 'Trinidad and Tobago', code: 'TT'},
            {name: 'Tunisia', code: 'TN'},
            {name: 'Turkey', code: 'TR'},
            {name: 'Turkmenistan', code: 'TM'},
            {name: 'Turks and Caicos Islands', code: 'TC'},
            {name: 'Tuvalu', code: 'TV'},
            {name: 'Uganda', code: 'UG'},
            {name: 'Ukraine', code: 'UA'},
            {name: 'United Arab Emirates', code: 'AE'},
            {name: 'United Kingdom', code: 'GB'},
            {name: 'United States', code: 'US'},
            {name: 'United States Minor Outlying Islands', code: 'UM'},
            {name: 'Uruguay', code: 'UY'},
            {name: 'Uzbekistan', code: 'UZ'},
            {name: 'Vanuatu', code: 'VU'},
            {name: 'Venezuela', code: 'VE'},
            {name: 'Viet Nam', code: 'VN'},
            {name: 'Virgin Islands, British', code: 'VG'},
            {name: 'Virgin Islands, U.S.', code: 'VI'},
            {name: 'Wallis and Futuna', code: 'WF'},
            {name: 'Western Sahara', code: 'EH'},
            {name: 'Yemen', code: 'YE'},
            {name: 'Zambia', code: 'ZM'},
            {name: 'Zimbabwe', code: 'ZW'}
        ],
        day : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
        dayShort : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
        months : ['January','February','March','April','May','June','July','August','September','October','November','December'],
        monthsNum : ['01','02','03','04','05','06','07','08','09','10','11','12'],
        today : function () {
            let date = new Date();
            let rv = this.formatDate(date);
            return rv;
        },


        dateDynamicFormatFC: function (dateTime) {
            let date = new Date(dateTime);
            if(dateTime == null || isNaN(date.getTime())){ return ''; }
            let MM = this.monthsNum[date.getMonth()];
            let ddD = date.getDate();
            let dayD = date.getDay();
            let day = this.dayShort[dayD];
            let dd = ddD < 10 ? '0'+ddD : ddD;
            let dformat = day+' '+dd+'/'+MM;
            return dformat;

        },
        dateDynamicFormat: function (dateTime, format) {
            let currentUserInfo = null;
            if(localStorage.currentUserInfo != undefined){
                currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            }  else {
                currentUserInfo = {
                    settings: {
                        date_format: 'dd MMM yyyy',
                        tz: 'Australia/Melbourne'
                    }
                };
            }
            let date_format = '';
            if(format == null || format == '' || format == undefined){
                date_format = currentUserInfo.settings.date_format;
            } else {
                date_format = format;
            }

            let date = new Date(dateTime);
            if(dateTime == null || isNaN(date.getTime())){ return ''; }
            let dateT = new Date(date+' UTC');
            let dateTz = new Date(dateT).toLocaleString('en-US', { timeZone: currentUserInfo.settings.tz });
            date = new Date(dateTz);

            let yyyy = date.getFullYear();
            let yy = this.makeStr(yyyy).substring(2);
            let M = this.months[date.getMonth()];
            let MM = this.monthsNum[date.getMonth()];
            let MMM = this.makeStr(M).substr(0, 3);
            let MMMM = M;
            let ddD = date.getDate();
            let dd = ddD < 10 ? '0'+ddD : ddD;

            let dformat = '';
            if(date_format == 'MM/dd/yy'){ dformat = MM+'/'+dd+'/'+yy; }
            else if(date_format == 'dd/MM/yy'){ dformat = dd+'/'+MM+'/'+yy; }
            else if(date_format == 'dd/MM/yyyy'){ dformat = dd+'/'+MM+'/'+yyyy; }
            else if(date_format == 'yy/MM/dd'){ dformat = yy+'/'+MM+'/'+dd; }
            else if(date_format == 'MM/dd/yyyy'){ dformat = MM+'/'+dd+'/'+yyyy; }
            else if(date_format == 'yyyy/MM/dd'){ dformat = yyyy+'/'+MM+'/'+dd; }
            else if(date_format == 'dd MMM yyyy'){ dformat = dd+' '+MMM+' '+yyyy; }
            else if(date_format == 'dd MMMM yyyy'){ dformat = dd+' '+MMMM+' '+yyyy; }
            else if(date_format == 'MMM dd, yyyy'){ dformat = MMM+' '+dd+', '+yyyy; }
            else if(date_format == 'MMMM dd, yyyy'){ dformat = MMMM+' '+dd+', '+yyyy; }
            else { dformat = dd+' '+MMMM+' '+yyyy; }

            return dformat;

        },
        timeDynamicFormat: function (dateTime) {
            let currentUserInfo = null;
            if(localStorage.currentUserInfo != undefined){
                currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            }  else {
                currentUserInfo = {
                    settings: {
                        time_format: '12',
                        tz: 'Australia/Melbourne'
                    }
                };
            }
            let time_format = currentUserInfo.settings.time_format;

            let date = new Date(dateTime);
            if(dateTime == null || isNaN(date.getTime())){ return ''; }
            let dateT = new Date(date+' UTC');
            let dateTz = new Date(dateT).toLocaleString('en-US', { timeZone: currentUserInfo.settings.tz });
            date = new Date(dateTz);

            let hours = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();

            let ampm = '';
            if(time_format != '24'){
                ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
            }
            let hh = hours < 10 ? '0'+hours : hours;
            let ii = minutes < 10 ? '0'+minutes : minutes;
            let ss = seconds < 10 ? '0'+seconds : seconds;

            let tformat = '';
            if(time_format == '12'){
                tformat = hh+':'+ii+' '+ampm;
            } else {
                tformat = hh+':'+ii;
            }

            return tformat;

        },
        formatDate : function (dateTime) {
            return this.DateSelectedFormat(dateTime);
        },
        formatDateTimeDynamic : function (dateTime) {
            let dt = this.dateDynamicFormat(dateTime, '')+' '+this.timeDynamicFormat(dateTime);
            return dt;
        },
        formatDateTime : function (dateTime) {
            let dt = this.DateSelectedFormat(dateTime)+' '+this.TimeSelectedFormat(dateTime);
            return dt;
        },
        dateTimeFormatFlat : function (dateTime) {
            return this.dateFormatFlat(dateTime)+ ' ' +this.timeFormatFlat(dateTime);
        },
        datePickerFormat: function () {
            let currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            let date_format = currentUserInfo.settings.date_format;
            let time_format = currentUserInfo.settings.time_format;
            let ampm = time_format == '12' ? ' a' : '';

            return date_format+' hh:mm'+ampm;

        },
        dateFormatFlat: function () {
            let currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            let date_format = currentUserInfo.settings.date_format;
            let dformat = '';

            if(date_format == 'MM/dd/yy'){ dformat = 'm/d/y'; }
            else if(date_format == 'dd/MM/yy'){ dformat = 'd/m/y'; }
            else if(date_format == 'dd/MM/yyyy'){ dformat = 'd/m/Y'; }
            else if(date_format == 'yy/MM/dd'){ dformat = 'y/m/d'; }
            else if(date_format == 'MM/dd/yyyy'){ dformat = 'm/d/Y'; }
            else if(date_format == 'yyyy/MM/dd'){ dformat = 'Y/m/d'; }
            else if(date_format == 'dd MMM yyyy'){ dformat = 'd M Y'; }
            else if(date_format == 'dd MMMM yyyy'){ dformat = 'd F Y'; }
            else if(date_format == 'MMM dd, yyyy'){ dformat = 'M d, Y'; }
            else if(date_format == 'MMMM dd, yyyy'){ dformat = 'F d, Y'; }
            else { dformat = 'd F Y'; }
            return dformat;

        },
        timeFormatFlat: function (format) {
            let currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            let time_format = currentUserInfo.settings.time_format;
            if(format == 'format'){
                let ampm = time_format == '12' ? 'h:i K' : 'H:i';
                return ampm;
            } else {
                if(time_format == '12'){
                    return false;
                } else {
                    return true;
                }
            }
        },

        formatDateToDB : function (dateTime) {
            let date = new Date(dateTime+' UTC');
            let dateTz = new Date(date).toLocaleString('en-US', { timeZone: 'Etc/UTC' });
            date = new Date(dateTz);
            if(dateTime == null || isNaN(date.getTime())){
                return '';
            }
            let year = date.getFullYear();
            let month = this.monthsNum[date.getMonth()];
            let day = date.getDate();
            let format =  year+'-'+month+'-'+day;
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();
            let hourS = hours > 9 ? ''+hours : '0'+hours;
            let minuteS = minutes > 9 ? ''+minutes : '0'+minutes;
            let secondS = seconds > 9 ? ''+seconds : '0'+seconds;
            return format+' '+hourS+':'+minuteS+':'+secondS;
        },
        formatTimeOnlyToDB : function (dateTime) {
            let date = new Date(dateTime);
            if(dateTime == null || isNaN(date.getTime())){
                return '';
            }
            let h = date.getHours();
            let i = date.getSeconds();
            let s = date.getMilliseconds();
            let format =  h+':'+i+':'+s;
            return format;
        },
        dateToTz: function(dateTime){
            let currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            let date = new Date(dateTime);
            let dateTz = new Date(date).toLocaleString('en-US', { timeZone: currentUserInfo.settings.tz });
            date = new Date(dateTz);
            if(dateTime == null || isNaN(date.getTime())){
                return new Date();
            }
            return date;
        },
        changeDaysFormat : function (dateTime, days) {
            let date = new Date(dateTime);
            if(dateTime == null || isNaN(date.getTime())){
                return '';
            }
            date.setDate(date.getDate()+parseInt(days));
            let year = date.getFullYear();
            let month = this.monthsNum[date.getMonth()];
            let day = date.getDate();
            let format =  year+'-'+month+'-'+day;
            return format;
        },
        time_ellapsed : function(date){
            let currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            let dateT = new Date(date+' UTC');
            let d = new Date(dateT).toLocaleString('en-US', { timeZone: currentUserInfo.settings.tz });
            let dT = new Date(d);

            let cur = new Date().toLocaleString('en-US', { timeZone: currentUserInfo.settings.tz });

            let curT = new Date(cur);
            let sec = (curT.getTime() - dT.getTime()) / 1000;
            let seconds = this.makeInt(sec);
            let min = this.makeInt(seconds / 60);
            let hour = this.makeInt(seconds / (60*60));
            let day = this.makeInt(seconds / (60*60*24));
            let week = this.makeInt(seconds / (60*60*24*7));
            let month = this.makeInt(seconds / (60*60*24*30));
            let year = this.makeInt(seconds / (60*60*24*365));

            if(seconds > 59){
                if(min > 59){
                    if(hour > 23){
                        if(day > 6){
                            if(week > 3){
                                if(month > 11){
                                    if(year > 1){ return year+' years ago'; }
                                    if(year == 1){ return year+' month ago'; }
                                }
                                if(month > 1){ return month+' months ago'; }
                                if(month == 1){ return month+' month ago'; }
                            }
                            if(week > 1){ return week+' weeks ago'; }
                            if(week == 1){ return week+' week ago'; }
                        }
                        if(day > 1){ return day+' days ago'; }
                        if(day == 1){ return day+' day ago'; }
                    }
                    if(hour > 1){ return hour+' hours ago'; }
                    if(hour == 1){ return hour+' hour ago'; }
                }
                if(min > 1){ return min+' minutes ago'; }
                if(min == 1){ return min+' minute ago'; }
            }
            if(seconds > 1){ return seconds+' seconds ago'; }
            if(seconds == 1){ return seconds+' second ago'; }
            return seconds+' seconds ago';
        },

        scrollHeight(remove){
            let height = $(window).height();
            height = height-remove;
            return height+'px';
        },
        validNumber(min,max,e){
            let v = e.target.value;
            if(v == ''){v = ''; return v;}
            else if(v < min){v = min}
            else if(v > max){v = max}
            e.target.value = v;
        },
        validAmount(min,max,e){
            let v = e.target.value;
            if(v == ''){v = ''; return v;}
            else if(v < min){v = min}
            else if(v > max){v = max}
            e.target.value = (this.makeFloat(v)).toFixed(2);
        },
        makeStr(number){
            if(number != undefined && number != null && number != ''){
                let a = number.toString();
                return a;
            } else {
                return '';
            }
        },
        makePP(name){
            if(name == undefined){
                return 'PP';
            }
            let nArr = name.split(' ');
            let a1 = nArr[0] != undefined ? nArr[0].charAt(0) : '';
            let a2 = nArr[1] != undefined ? nArr[1].charAt(0) : '';
            return a1.toUpperCase()+a2.toUpperCase();
        },
        makeInt(number){
            let a = parseInt(number);
            return a;
        },
        makeFloat(number){
            let a = parseFloat(number);
            return a;
        },
        makeFloatCR(number){
            let a = parseFloat(number);
            let b = '';
            if(a < 0){
                a = -a;
                b = a+' (CR)'
            } else {
                b = a+'';
            }
            return b;
        },
        makeUpperCase(str){
            let a = str.toUpperCase();
            return a;
        },
        makeTeamID(a){
            let s = 2128 + this.makeFloat(a);
            return s;
        },
        getTimeZone: function(){
            return this.timezone;
        },
        getCountry: function(){
            return this.country;
        },
        getCountryName: function(code){
            let index = this.country.map(function(e) { return e.code; }).indexOf(code);
            if(index > -1){
                return this.country[index].name;
            } else {
                return null;
            }
        },
        priorityDuration(duration){
            if(duration == 1){
                return 'min';
            } else {
                return 'hr';
            }
        },

        fullHeight: function(height){
          let h = window.innerHeight;
          let r = h - height;
          return r+'px';
        },

        fullHeightSideNav: function(height, makeChatActive){
          if(makeChatActive == false){
            let h = window.innerHeight;
            let r = h - height;
            return r+'px';
          }else{
            let h = window.innerHeight;
            let r = h - (height + 120);
            return r+'px';
          }
        },


        workStatus: function(status){
            let str = '';
            status == 1 ? str = 'New' : '';
            status == 2 ? str = 'In Progress' : '';
            status == 3 ? str = 'Awaiting Approval' : '';
            status == 4 ? str = 'Complete' : '';
            status == 5 ? str = 'Cancelled' : '';
            return str;
        },
        workStatusIcon: function(status){
            let str = '';
            status == 1 ? str = 'star' : '';
            status == 2 ? str = 'play_arrow' : '';
            status == 3 ? str = 'hourglass_full' : '';
            status == 4 ? str = 'check' : '';
            status == 5 ? str = 'pause' : '';
            return str;
        },
        commentImage: function(comment){
            let str = JSON.parse(comment);
            return str.file_path;
        },

        getRouteStr: function(){
            let comp = window.location.pathname.substring(1).split('/');
            return comp;
        },



        // =================================
        // Media Upload
        // =================================
        uploadListMinMax : 1,
        uploadListVisible : null,
        uploadList: [],
        uploadPortalFadeClear: function(){
            let THIS = this;
            clearInterval(THIS.uploadListVisible);
        },
        uploadPortalFade: function(){
            let THIS = this;
            clearInterval(THIS.uploadListVisible);
            THIS.uploadListVisible = setInterval(function() {
                (<any>$('#uploadPortalGallery')).fadeOut('slow');
                setTimeout(function () {
                    THIS.uploadList.length = 0;
                }, 3000);
                clearInterval(THIS.uploadListVisible);
            }, 5000);
        },
        DefaultMediaPath: 'https://api.equidesk.com/defaults',
        // DefaultMediaPath: 'http://localhost/projects/mediusware/Equidesk/API/public/defaults',
        DefaultAvatar: [1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,34,35,36],
        existEmoji: ["angry","annoyed","blush","call_me","cash_mouth","clap","clown","confused","cool","cowboy","cwl","dancing_man","drooling_face","embarrassed","eyes","fist","frown","grimacing","grin","grinning","hand_metal","hand_splayed","headache","heart_eyes","imp","innocent","kiss","kiss_blushing","kiss_eyes_closed","kissing_heart","laughing","lying_face","middle_finger","muscle","nerd","ok_hand","open_hands","peace","point_down","point_left","point_right","point_up","point_up_2","poop","pray","punch","rage","raised_hand","relaxed","relieved","robot","rofl","rolling_eyes","sad","silence","sleeping","slight_smile","smile","smiley","smiley_cat","smiling_imp","smirk","smirk_cat","sneezing_face","sob","speechless","speechless_eyes_shut","stuck_out_tongue","surprised","tear_smiley","thinking","thumbsdown","thumbsup","tongue","unamused","upside_down","very_angry","vulcan","wave","wink","wink_tongue_stuck_out","worried","writing_hand","xd_tongue_stuck_out","yay","yum","zip","zzz"],
        generateEmoji: function(str){
            let THIS = this;
            let newStr = '';
            let checkStr = str.replace(/[:]+[0-9a-z_]+[:]+/gim, function (m) {
                let file_name = m.slice(1, -1);
                return '';
            });
            if(checkStr.trim() == ''){
                newStr = str.replace(/[:]+[0-9a-z_]+[:]+/gim, function (m) {
                    let file_name = m.slice(1, -1);
                    if(THIS.existEmoji.indexOf(file_name) > -1){
                        return '<img class="emojiIcon emojiIcon40" src="'+THIS.DefaultMediaPath+'/emoji/1/'+file_name+'.svg"/>';
                    } else {
                        return m;
                    }
                });
            } else {
                newStr = str.replace(/[:]+[0-9a-z_]+[:]+/gim, function (m) {
                    let file_name = m.slice(1, -1);
                    if(THIS.existEmoji.indexOf(file_name) > -1){
                        return '<img class="emojiIcon emojiIcon20" src="'+THIS.DefaultMediaPath+'/emoji/1/'+file_name+'.svg"/>';
                    } else {
                        return m;
                    }
                });
            }

            return newStr;
        },
        nl2br: function(str) {
            if (typeof str === 'undefined' || str === null) {
                return '';
            }
            var breakTag = '<br>';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        },



        // =================================
        // New Tz
        // =================================
        formatDateOnlyToDB : function (dt) {
            let dateTime = dt;
            let date = new Date(dateTime);
            if(dateTime == null || isNaN(date.getTime())){
                return '';
            }
            let year = date.getFullYear();
            let month = this.monthsNum[date.getMonth()];
            let day = date.getDate();
            let format =  year+'-'+month+'-'+day;
            return format;
        },
        formatDateTimeToDB : function (dt) {
            let dateTime = dt;
            let date = new Date(dateTime);
            if(dateTime == null || isNaN(date.getTime())){
                return '';
            }
            let year = date.getFullYear();
            let month = this.monthsNum[date.getMonth()];
            let day = date.getDate();
            let format =  year+'-'+month+'-'+day;
            let hourD = date.getHours();
            let hours = hourD < 10 ? '0'+hourD : hourD;
            let minuteD = date.getMinutes();
            let minutes = minuteD < 10 ? '0'+minuteD : minuteD;
            let secondD = date.getSeconds();
            let seconds = secondD < 10 ? '0'+secondD : secondD;
            return format+' '+hours+':'+minutes+':'+seconds;
        },
        dateStrFormat : function (dt) {
            let dateTime = dt;
            let date = new Date(dateTime);
            if(dateTime == null || isNaN(date.getTime())){
                return '';
            }
            let year = date.getFullYear();
            let month = this.monthsNum[date.getMonth()];
            let day = date.getDate();
            let format =  year+'-'+month+'-'+day;
            return format;
        },
        timeStrFormat: function (dt) {
            let dateTime = dt
            let currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            let time_format = currentUserInfo.settings.time_format;
            let date = new Date(dateTime);
            if(dateTime == null || isNaN(date.getTime())){ return ''; }
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();
            let hh = hours < 10 ? '0'+hours : hours;
            let ii = minutes < 10 ? '0'+minutes : minutes;
            let ss = seconds < 10 ? '0'+seconds : seconds;
            let tformat = hh+':'+ii+':'+ss;
            return tformat;
        },
        DateSelectedFormat: function (dt, format) {
            let dateTime = dt;
            let currentUserInfo = null;
            if(localStorage.currentUserInfo !== undefined){
                currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            }
            let date_format = '';
            if(currentUserInfo !== null){
                if(format == null || format == '' || format == undefined){
                    date_format = currentUserInfo.settings != null ? currentUserInfo.settings.date_format : '';
                } else {
                    date_format = format;
                }
            } else {
                if(format == null || format == '' || format == undefined){
                    date_format = 'MM/dd/yyyy';
                } else {
                    date_format = format;
                }
            }
            let date = new Date(dateTime);
            let yyyy = date.getFullYear();
            let yy = this.makeStr(yyyy).substring(2);
            let M = this.months[date.getMonth()];
            let MM = this.monthsNum[date.getMonth()];
            let MMM = this.makeStr(M).substr(0, 3);
            let MMMM = M;
            let dd = date.getDate();

            let dformat = '';
            if(date_format == 'dd/MM/yy'){ dformat = dd+'/'+MM+'/'+yy; }
            else if(date_format == 'MM/dd/yy'){ dformat = MM+'/'+dd+'/'+yy; }
            else if(date_format == 'yy/MM/dd'){ dformat = yy+'/'+MM+'/'+dd; }
            else if(date_format == 'dd/MM/yyyy'){ dformat = dd+'/'+MM+'/'+yyyy; }
            else if(date_format == 'MM/dd/yyyy'){ dformat = MM+'/'+dd+'/'+yyyy; }
            else if(date_format == 'yyyy/MM/dd'){ dformat = yyyy+'/'+MM+'/'+dd; }
            else if(date_format == 'dd MMM yyyy'){ dformat = dd+' '+MMM+' '+yyyy; }
            else if(date_format == 'dd MMMM yyyy'){ dformat = dd+' '+MMMM+' '+yyyy; }
            else if(date_format == 'MMM dd, yyyy'){ dformat = MMM+' '+dd+', '+yyyy; }
            else if(date_format == 'MMMM dd, yyyy'){ dformat = MMMM+' '+dd+', '+yyyy; }
            else { dformat = dd+' '+MMMM+' '+yyyy; }

            return dformat;

        },
        TimeSelectedFormat: function (dt) {
            let dateTime = dt;
            let currentUserInfo = null;
            if(localStorage.currentUserInfo !== undefined){
                currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            }
            let time_format = '';
            if(currentUserInfo !== null){
                time_format = currentUserInfo.settings != null ? currentUserInfo.settings.time_format : '';
            } else {
                time_format = '12';
            }

            let date = new Date(dateTime);
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();

            let ampm = '';
            if(time_format != '24'){
                ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
            }
            let hh = hours < 10 ? '0'+hours : hours;
            let ii = minutes < 10 ? '0'+minutes : minutes;
            let ss = seconds < 10 ? '0'+seconds : seconds;

            let tformat = '';
            if(time_format == '12'){
                tformat = hh+':'+ii+' '+ampm;
            } else {
                tformat = hh+':'+ii;
            }

            return tformat;

        },
        DateTimeSelectedFormat: function(dateTime){
            let dt = this.DateSelectedFormat(dateTime)+' '+this.TimeSelectedFormat(dateTime);
            return dt;
        },
        DateTimeSelectedFormatTz: function(dateTime){
            let dtTz = dateTime.substring(0, dateTime.indexOf('+'));
            let dt = this.DateSelectedFormat(dtTz)+' '+this.TimeSelectedFormat(dtTz);
            return dt;
        },
        invToDate(date){
            if(date == '' || date == null){return date;}
            let currentUserInfo = JSON.parse(localStorage.currentUserInfo);
            let date_format = currentUserInfo.settings.date_format;
            if(date_format == 'dd/MM/yy' || date_format == 'dd/MM/yyyy'){
                let dateTimeArray = date.split(' ');
                let dd = '';
                let tt = '';
                if(dateTimeArray.length > 1){
                    dd = date.substr(0,date.indexOf(' '));
                    tt = date.substr(date.indexOf(' ')+1);
                }
                else {
                    dd = date;
                }
                let dateArray = dd.split('/');
                if(dateArray.length < 3){
                    dateArray = dd.split('-');
                }
                let d = null;
                if(tt != ''){
                    d = new Date(dateArray[1]+'-'+dateArray[0]+'-'+dateArray[2]+' '+tt);
                }
                else {
                    d = new Date(dateArray[1]+'-'+dateArray[0]+'-'+dateArray[2]);
                }
                return d;
            } else {
                return date;
            }
        },

        //==========================
        // Validation
        //==========================
        preIDvalidation: function(e){
            let a = e.target.value;
            let b = a.replace(/\D/g,'');
            e.target.value = b;
        },
        prefixValidation: function(e){
            let a = e.target.value;
            let b = a.replace(/[^A-Za-z\s!?]/g,'');
            e.target.value = this.makeUpperCase(b);
        },
        emailValidation: function(email){
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        CurrencyValid: function() {
          const THIS: any = this;
            setTimeout(function () {
                (<any>$('.__currency')).inputmask({
                    'alias': 'numeric',
                    'radixPoint': ',',
                    'groupSeparator': '',
                    'autoGroup': true,
                    'digits': 2,
                    'digitsOptional': false,
                    'prefix': '' +THIS.currencyIcon()+ ' ',
                    'placeholder': '0.00'
                });
            }, 1000);
        },
      currencyIcon():string {
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
      },

        getCurrentToken: function(){
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            return Session.token;
        },

        openDocViewer: function (filepath) {
            const target = $('#docViewer');
            target.find('._iframe').attr('src', '');
            const url = 'https://docs.google.com/viewer?embedded=true&url='+filepath;
            target.find('._iframe').attr('src', url);let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
            let child = ___selectedTab+1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
            (<any>$('#docViewerModal')).modal('show');
        }
    };
    return Helper;
}
export const CustomHelper = helper();
