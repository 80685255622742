import {Component, TemplateRef, Input, SimpleChanges, OnInit} from '@angular/core';
import {InvoicesService} from '../../_services/invoices.service';
import {FacilityService} from '../../_services/facility.service';
import {FcmService} from '../../_services/fcm.service';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;

@Component({
  selector: 'page-invoices',
  templateUrl: './invoices.component.html'
})
export class InvoicesComponent {
  Permission = -1;

  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  currentUser;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  templateInvoice = 1;
  miniScreen = 0;
  invoiceSections = {
    overview: 1,
    singlePreview: 0,
    createForm: 0,
    editForm: 0,
    createRecurringForm: 0,
    createEmail: 0
  };

  restoreAlert = false;

  ROUTES = '/invoices';

  target = '';
  payment: any = {};

  constructor(private invoicesService: InvoicesService,
              private facilityService: FacilityService,
              private fcmService: FcmService) {

    const THIS = this;

    setTimeout(function () {

      if (THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] != undefined) {
        window.history.pushState('', '', THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute]);
      }

      THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
      // Pagination
      THIS.currentUser.pagination != null ? THIS.perPage = THIS.currentUser.pagination.invc : '';
      // 1 = Default, 2 = Comfortable, 3 = Compact
      THIS.currentUser.list_view.invc.d == 1 ? THIS.Density = 1 : '';
      THIS.currentUser.list_view.invc.cf == 1 ? THIS.Density = 2 : '';
      THIS.currentUser.list_view.invc.cp == 1 ? THIS.Density = 3 : '';

      THIS.Permission = THIS.currentUser.user_type > 2 ? THIS.currentUser.modules.invoices : 1;
      THIS.facilityService.currentSelectedFac.subscribe(value => THIS.getInvoices());
      THIS.routeMake();
      if (localStorage.work_to_invoice !== undefined) {
        THIS.createNewInvoice();
      }
      if (localStorage.singleInv !== undefined) {
        const inv = JSON.parse(localStorage.singleInv);
        THIS.singlePreviewInvoiceQuick(inv);
        delete localStorage.singleInv;
      }
      if (localStorage.INPreId !== undefined) {
        THIS.getSinglePreview(localStorage.INPreId);
        delete localStorage.INPreId;
      }
      if (window.innerWidth <= 1024) {
        THIS.miniScreen = 1;
        THIS.FilterScroll = 290;
      }

    }, 500);
    $('body').off().on('click', '._cTeamInvc', function () {
      THIS.openTeamInfo();
    });
  }

  routeMake() {
    const route = this.CustomHelper.getRouteStr();
    if (route[2] != undefined) {
      if (route[2] == '') {
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
      } else if (route[2] == 'new') {
        this.createNewInvoice();
      } else if (route[2] == 'edit') {
        //this.editInvoice();
      } else {
        // let preId = route[2];
        // this.previewAsset(preId);
      }
    } else {
      const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      const url = '/' + Session.team + this.ROUTES;
      window.history.pushState('', '', url);
      this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    }
  }

  wo_is_active = 1;
  totalInvoice = 0;
  pageNo = 1;
  perPage = '10';
  pageEvent;

  paginateList() {
    if (this.pageEvent.pageSize == this.perPage) {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = this.pageEvent.pageIndex + 1;
      this.getInvoices();
    } else {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = 1;
      this.getInvoices();
    }
  }

  currencyIcon(): string {
    if (this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }

  checkNumber(evt: any) {
    var theEvent: any = evt || window.event;

    // Handle paste
    if (theEvent.type === 'paste') {
      // @ts-ignore
      key = event.clipboardData.getData('text/plain');
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /^\d*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }

  searchKeyword = '';

  searchKeywordClear(e) {
    this.searchKeyword = '';
    this.getInvoices();
  }

  invoices = [];
  previewList = 1;
  order_by = 'desc';

  getInvoices() {
    this.previewList = 0;
    const param = {
      id: 0,
      pageNo: this.pageNo,
      perPage: this.perPage,
      sort_by: this.sort_by,
      order_by: this.order_by,
      keyword: this.searchKeyword,
      is_active: this.wo_is_active,
    };
    localStorage.perPageInv = this.perPage;
    localStorage.sort_byInv = this.sort_by;
    localStorage.order_byInv = this.order_by;
    this.invoices.length = 0;
    this.invoicesService.getInvoiceList(param).subscribe(res => {
      this.previewList = 1;
      if (res.status == 2000 && res.data.length > 0) {
        this.totalInvoice = res.total;
        const THIS = this;
        const DATA = [];
        $.each(res.data, function (i, v) {
          if (v['due_date'] !== null) {
            if (THIS.CustomHelper.formatDate(v['due_date']) < THIS.CustomHelper.formatDate(new Date())) {
              v['status'] = 0;
            }
            if (THIS.CustomHelper.formatDate(v['due_date']) == THIS.CustomHelper.formatDate(v['invoice_date'])) {
              v['status'] = 99;
            }
            v['due_date'] = THIS.CustomHelper.formatDate(v['due_date']);
          }
          v['uid'] = THIS.CustomHelper.makeStr(v['uid']);
          v['invoice_date'] = THIS.CustomHelper.formatDate(v['invoice_date']);
          DATA.push(v);
        });
        this.invoices = DATA;

        if (THIS.singleInvoice) {
          if (THIS.singleInvoice.id > 0) {
            const index = this.invoices.map(function (e) {
              return e.id;
            }).indexOf(THIS.singleInvoice.id);
            THIS.singleInvoice = this.invoices[index];
          }
        }
      } else {
        this.invoices.length = 0;
        this.totalInvoice = 0;
      }
    });
    this.getInvStatus();
  }

  filter(): void {
    const THIS = this;
    clearInterval(THIS.CustomHelper.woListSearch);
    THIS.CustomHelper.woListSearch = setInterval(function () {
      clearInterval(THIS.CustomHelper.woListSearch);
      THIS.getInvoices();
    }, 400);
  }

  highlighter(subject) {
    if (subject == '' || subject == null) {
      return subject;
    } else {
      subject = this.CustomHelper.makeStr(subject);
    }
    const matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
    let res = '';
    $.each(matches, function (i, v) {
      res = subject.replace(v, '<span class=\'text-warning-highlight\'>' + v + '</span>');
    });
    if (res == '') {
      res = subject;
    }
    return res;
  }

  selectAllCheck() {
    const THIS = this;
    const WO = this.invoices;
    if (this.selectedInvoice.length == this.invoices.length) {
      this.selectedInvoice.length = 0;
    } else {
      this.selectedInvoice.length = 0;
      $.each(WO, function (i, v) {
        THIS.selectedInvoice.push(v.id);
      });
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  FilterOn = 0;
  FilterScroll = 230;

  ToggleFilter(c) {
    if (this.wo_is_active != 0) {
      this.FilterScroll = 230;
      if (this.miniScreen == 1) {
        this.FilterScroll = 290;
      }
    } else {
      this.FilterScroll = 290;
      if (this.miniScreen == 1) {
        this.FilterScroll = 290;
      }
    }
  }

  SortTitle = 'Created Date';
  sort_by = 'created_at';
  SortOptions = [
    {title: 'Created Date', slug: 'created_at'},
    {title: 'Status', slug: 'status'},
    {title: 'Invoice ID', slug: 'prefix'},
    {title: 'Facility', slug: 'facility'},
    {title: 'Contact', slug: 'contact'},
    {title: 'Invoice Date', slug: 'invoice_date'},
    {title: 'Due Date', slug: 'due_date'},
  ];
  DateDensity = 1;
  Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
  changeDensity(density) {
    const THIS = this;
    this.Density = density;
    localStorage.DensityInv = this.Density;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    const param = {invc: ''};
    density == 1 ? param.invc = 'd' : '';
    density == 2 ? param.invc = 'cf' : '';
    density == 3 ? param.invc = 'cp' : '';
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'me/density?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
      }
    });
  }

  statusCheck = [];
  FilterName = 'All';

  updateStatusCheck(status) {
    this.wo_is_active = status;
    if (this.wo_is_active == 1) {
      this.FilterName = 'All';
    } else if (this.wo_is_active == 2) {
      this.FilterName = 'Archived';
    } else if (this.wo_is_active == 0) {
      this.FilterName = 'Trashed';
    }
    this.ToggleFilter('1');
    this.getInvoices();
  }

  CounterStatus = {
    'total': 0,
    'archived': 0,
    'trash': 0,
  };

  getInvStatus() {
    const THIS = this;
    const param = {
      rcti: 0
    };
    this.invoicesService.getInvoiceStatus(param).subscribe(res => {
      if (res.status == 2000) {
        this.CounterStatus = {
          'total': res.total,
          'archived': res.archived,
          'trash': res.trash,
        };
      }
    });
  }


  createNewInvoice() {
    this.invoiceSections.overview = 0;
    this.invoiceSections.singlePreview = 0;
    this.invoiceSections.createForm = 1;
    this.invoiceSections.editForm = 0;
    this.invoiceSections.createRecurringForm = 0;
    this.invoiceSections.createEmail = 0;
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    const url = '/' + Session.team + this.ROUTES + '/new';
    window.history.pushState('', '', url);
    this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
  }

  editInvoice(type: any) {
    let id = 0;
    if(type === 1){
      if (this.selectedInvoice.length > 0) {
        id = this.selectedInvoice[0];
      } else {
        id = this.singleInvoice.id;
      }
    }else{
      if (this.singleInvoice.id) {
        id = this.singleInvoice.id;
      } else if (this.selectedInvoice.length > 0) {
        id = this.selectedInvoice[0];
      }
    }
    const index = this.invoices.map(function (e) {
      return e.id;
    }).indexOf(id);
    if (index > -1) {
      this.singleInvoice = this.invoices[index];
    } else {
      const indexP = this.invoices.map(function (e) {
        return e.id;
      }).indexOf(id.toString());
      if (indexP > -1) {
        this.singleInvoice = this.invoices[indexP];
      }
    }

    this.invoiceSections.overview = 0;
    this.invoiceSections.singlePreview = 0;
    this.invoiceSections.createForm = 0;
    this.invoiceSections.editForm = 0;
    this.invoiceSections.createRecurringForm = 0;
    this.invoiceSections.createEmail = 0;
    const THIS = this;
    setTimeout(function () {
      THIS.invoiceSections.editForm = 1;
    }, 400);
  }

  editThisInvoice(id) {
    this.selectedInvoice = [id];
    this.editInvoice(1);
  }

  undoRemoveThisInvoice(ids) {
    const ldd = document.getElementById('udltBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    const THIS = this;
    THIS.invoicesService.removeUndoInvoice(ids).subscribe(res => {
      ladda.stop();
      this.getInvoices();
      this.selectedInvoice = [];
      toastr.success('This invoice has been restored successfully', 'Invoice Restore', this.toastrOption);
      (<any>$('#RestoreModal')).modal('hide');
      // Pusher emit
    });
  }

  restoreThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#RestoreModal')).modal('show');
  }


  removeThisInvoice() {
    const ldd = document.getElementById('dltBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    if (this.singleInvoiceId === 0) {
      if (this.selectedInvoice.length > 0) {
        const ids = this.selectedInvoice;
        this.invoicesService.removeInvoice(ids).subscribe(res => {
            if (res.status === 2000) {
              ladda.stop();
              this.getInvoices();
              toastr.success('This invoice has been deleted successfully', 'Invoice Delete', this.toastrOption);
              this.ManageDeleteModal(2);
              this.selectedInvoice.length = 0;
            } else {
              ladda.stop();
            }
          }
        );
      }
    } else {
      const ids = [this.singleInvoiceId];
      this.invoicesService.removeInvoice(ids).subscribe(res => {
          if (res.status === 2000) {
            this.getInvoices();
            this.ManageDeleteModal(2);
            this.singleInvoiceId = 0;
            this.selectedInvoice.length = 0;
            this.discardCreateInvoice('');
            toastr.success('This invoice has been deleted successfully', 'Invoice Delete', this.toastrOption);
          } else {
            ladda.stop();
          }
        }
      );
    }

  }

  ManageDeleteModal(type: any) {
    if (type === 1) {
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#DeleteModal')).modal('show');
    } else {
      (<any>$('#DeleteModal')).modal('hide');
    }
  }
  undoArchiveThisInvoice(ids) {
    const ldd = document.getElementById('uarcBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    this.invoicesService.archiveUndoInvoice(ids).subscribe(res => {
      ladda.stop();
      this.getInvoices();
      toastr.success('This invoice has been unarchived successfully', 'Invoice Unarchive', this.toastrOption);
      this.selectedInvoice.length = 0;
      (<any>$('#UnarchiveModal')).modal('hide');
      // Pusher emit
    });
  }

  ManageUnarchiveModal() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#UnarchiveModal')).modal('show');
  }

  singleInvoiceId = 0;

  archiveThisInvoice() {
    const ldd = document.getElementById('arcBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    if (this.singleInvoiceId === 0) {
      if (this.selectedInvoice.length > 0) {
        const ids = this.selectedInvoice;
        this.invoicesService.archiveInvoice(ids).subscribe(res => {
          if (res.status === 2000) {
            ladda.stop();
            this.getInvoices();
            toastr.success('This invoice has been archived successfully', 'Invoice Archive', this.toastrOption);
            this.selectedInvoice.length = 0;
            this.ManageArchiveModal(2);
          }
        });
      }
    } else {
      const ids = [this.singleInvoiceId];
      this.invoicesService.archiveInvoice(ids).subscribe(res => {
        if (res.status === 2000) {
          ladda.stop();
          this.singleInvoiceId = 0;
          this.ManageArchiveModal(2);
          this.discardCreateInvoice('');
          toastr.success('This invoice has been archived successfully', 'Invoice Archive', this.toastrOption);
        } else {
          ladda.stop();
        }
      });
    }

  }

  ManageArchiveModal(type: any) {
    if (type === 1) {
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#ArchiveModal')).modal('show');
    } else {
      (<any>$('#ArchiveModal')).modal('hide');
    }
  }

  changeInvoiceStatus(status) {
    this.invoicesService.statusUpdateInvoice(status, this.singleInvoice.id).subscribe(res => {
      this.singleInvoice.status = status;
      if (status == 1) {
        this.singleInvoice.paid = 0;
      }
      if (status == 3) {
        this.singleInvoice.paid = this.singleInvoice.total;
      }
    });
  }

  partial = {
    payment: 0,
    submitted: 0
  };

  paymentThisInvoice() {
    const THIS = this;
    const ldd = document.getElementById('paymentThisInvoice');
    const ladda = Ladda.create(ldd);
    let PayM = this.CustomHelper.makeStr($('#partial_payment').val());
    PayM = PayM.replace(/[^0-9.]/, '');
    PayM = this.CustomHelper.makeFloat(PayM);


    const min = 0;
    const max = this.singleInvoice.total - this.singleInvoice.paid;
    if (PayM == 0) {
      // swal({
      //   title: 'Invalid Payment Amount',
      //   text: 'Invalid Payment Amount',
      //   icon: 'warning',
      //   dangerMode: true,
      //   className: 'swal-btn-center'
      // });
      $('.invalid-des').html('Invalid Payment Amount');
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#InvalidPaymentModal')).modal('show');
      return false;
    } else if (PayM < min) {
      // swal({
      //   title: 'Invalid Payment Amount',
      //   text: 'Invalid Payment Amount',
      //   icon: 'warning',
      //   dangerMode: true,
      //   className: 'swal-btn-center'
      // });
      $('.invalid-des').html('Invalid Payment Amount');
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#InvalidPaymentModal')).modal('show');
      return false;
    } else if (PayM > max) {
      // swal({
      //   title: 'Invalid Payment Amount',
      //   text: 'Payment Amount can\'t be greater than balance due',
      //   icon: 'warning',
      //   dangerMode: true,
      //   className: 'swal-btn-center'
      // });
      $('.invalid-des').html('Payment Amount can\'t be greater than balance due');
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#InvalidPaymentModal')).modal('show');
      return false;
    }

    this.partial.payment = this.CustomHelper.makeFloat(PayM);
    if (this.singleInvoice.id > 0 && this.CustomHelper.makeFloat(this.partial.payment) > 0) {
      this.partial.submitted = 1;

      ladda.start();
      this.invoicesService.paymentInvoice(this.partial.payment, this.singleInvoice.id).subscribe(res => {
        ladda.stop();
        (<any>$('#paymentInvoiceModal')).modal('hide');
        toastr.success('Your payment has been made successfully', 'Invoice Payment', this.toastrOption);
        this.partial.payment = 0;
        this.partial.submitted = 0;
        this.singleInvoice = res;
        this.singleInvoice.facStr = this.singleInvoice.facStr.reverse();
        if (this.singleInvoice.created_at !== undefined || this.singleInvoice.created_at !== null) {
          this.singleInvoice['created_at_human'] = this.CustomHelper.time_ellapsed(this.singleInvoice.created_at.date);
        }
        this.getInvoices();
        // Pusher emit
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      });
    }
  }

  openComment = 0;

  commentThisInvoice(e) {
    const input = $(e.target).closest('.invComment').find('input');
    const comment = input.val();
    if (typeof comment == 'string') {
      if ($.trim(comment).length > 0) {
        this.invoicesService.paymentThisInvoice(comment, this.singleInvoice.id).subscribe(res => {
          this.singleInvoice = res;
          input.val('');
          this.openComment = 0;
        });
      } else {
        input.val('');
      }
    }
  }

  updatedInvoice(invoice) {
    this.invoiceSections.overview = 0;
    this.invoiceSections.singlePreview = 0;
    this.invoiceSections.createForm = 0;
    this.invoiceSections.editForm = 0;
    this.invoiceSections.createRecurringForm = 0;
    this.invoiceSections.createEmail = 0;

    const THIS = this;
    setTimeout(function () {
      THIS.templateInvoice = 1;
      THIS.singleInvoice = invoice;
      THIS.singleInvoice.facStr = THIS.singleInvoice.facStr.reverse();
      if (THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null) {
        THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
      }
      THIS.invoiceSections.singlePreview = 1;
    }, 400);
    this.getInvoices();
  }

  discardCreateInvoice(disca) {
    const THIS = this;
    this.invoiceSections.overview = 1;
    this.invoiceSections.singlePreview = 0;
    this.invoiceSections.createForm = 0;
    this.invoiceSections.editForm = 0;
    this.invoiceSections.createRecurringForm = 0;
    this.invoiceSections.createEmail = 0;
    this.getInvoices();
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    const url = '/' + Session.team + this.ROUTES;
    window.history.pushState('', '', url);
    this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);

    this.ToggleFilter('1');
  }

  discardCreateEmail(disca) {
    this.invoiceSections.overview = 0;
    this.invoiceSections.singlePreview = 1;
    this.invoiceSections.createForm = 0;
    this.invoiceSections.editForm = 0;
    this.invoiceSections.createRecurringForm = 0;
    this.invoiceSections.createEmail = 0;

    this.ToggleFilter('1');
  }

  cancelSingleView() {
    this.invoiceSections.overview = 1;
    this.invoiceSections.singlePreview = 0;
    this.invoiceSections.createForm = 0;
    this.invoiceSections.editForm = 0;
    this.invoiceSections.createRecurringForm = 0;
    this.invoiceSections.createEmail = 0;
  }

  pdfThisInvoice() {
    const THIS = this;
    let id = 0;
    if (this.singleInvoice.id == 0 && this.selectedInvoice.length > 0) {
      id = this.selectedInvoice[0];
    } else {
      id = this.singleInvoice.id;
    }
    window.open(THIS.APPCONFIG.apiAuth + 'preview/upload/pdf/invc?id=' + id);
  }

  emailThisInvoice() {
    const THIS = this;
    let id = 0;
    if (this.singleInvoice.id == 0 && this.selectedInvoice.length > 0) {
      id = this.selectedInvoice[0];
    } else {
      id = this.singleInvoice.id;
    }
    const index = this.invoices.map(function (e) {
      return e.id;
    }).indexOf(id);
    const inv = this.invoices[index];
    const param = {
      id: inv.id,
      uid: inv.uid,
      type: 'invc',
      team: this.currentUser.team,
      subject: 'Invoice To: ' + inv.client.name,
    };
    $('#sendMailModalTrigger').attr('data-type', JSON.stringify(param));
    $('#sendMailModalTrigger').click();
  }


  selectedInvoice = [];
  // singleInvoice = null;
  singleInvoice: any = {id: 0};

  openPaymentModal(type: any) {
    if (type === 1) {
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#paymentInvoiceModal')).modal('show');
      // this.CustomHelper.CurrencyValid();
      $('#partial_payment').val('');
    } else {
      (<any>$('#paymentInvoiceModal')).modal('hide');
      $('#partial_payment').val('');
    }
  }

  checkThisRow(e, invoice) {
    e.preventDefault();

    const index = this.selectedInvoice.indexOf(invoice.id);
    if (index > -1) {
      if (this.selectedInvoice.length == 1) {
        this.selectedInvoice.length = 0;
      } else {
        this.selectedInvoice.splice(index, 1);
      }
    } else {
      this.selectedInvoice.push(invoice.id);
    }
    $('.drop-menu-right').removeClass('open');
    this.openTeamInfo();

  }

  selectThisRow(e, invoice) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.which == 1) {
        const index = this.selectedInvoice.indexOf(invoice.id);
        if (index > -1) {
          this.selectedInvoice.splice(index, 1);
        } else {
          this.selectedInvoice.push(invoice.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    }
    this.openTeamInfo();

  }

  singlePreviewInvoice(e, invoice) {
    const THIS = this;
    if (e != null) {
      e.preventDefault();
    }
    if (e.ctrlKey) {
      if (e.which == 1) {
        const index = this.selectedInvoice.indexOf(invoice.id);
        if (index > -1) {
          this.selectedInvoice.splice(index, 1);
        } else {
          this.selectedInvoice.push(invoice.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    } else {
      if (e.which == 1) {
        this.previewInvoice(e, invoice);
      } else if (e.which == 3) {
        if (THIS.selectedInvoice.length < 2) {
          this.invoiceSections.overview = 0;
          this.invoiceSections.singlePreview = 0;
          this.invoiceSections.createForm = 0;
          this.invoiceSections.editForm = 0;
          this.invoiceSections.createRecurringForm = 0;
          this.invoiceSections.createEmail = 0;

          setTimeout(function () {
            THIS.templateInvoice = 1;
            THIS.singleInvoice = invoice;
            THIS.selectedInvoice.length = 0;
            THIS.selectedInvoice[0] = invoice.id;
            THIS.singleInvoice.facStr = THIS.singleInvoice.facStr.reverse();
            if (THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null) {
              THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
            }
            THIS.invoiceSections.singlePreview = 1;
            setTimeout(function () {
              THIS.CustomHelper.tooltipRender();
            }, 100);
          }, 400);
        }
        const target = $(e.target);
        const w = target.closest('#right-click-holder').width();
        const h = target.closest('#right-click-holder').height();
        const p = target.closest('#right-click-holder').offset();
        let left = e.clientX - p.left;
        let top = e.clientY - p.top;
        let clickH = $('.drop-menu-right').height();
        clickH = clickH < 150 ? 400 : clickH;
        if (this.selectedInvoice.length > 1) {
          clickH = 150;
        }
        if ((w - left) < 230) {
          left = w - 250;
        }
        if (h < top + clickH) {
          top = top - (top + clickH - h);
        }
        if (top < 10) {
          top = 10;
        }

        const ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
        if (ttarget.hasClass('open')) {
          $('.drop-menu-right').removeClass('open');
          ttarget.css({
            top: top,
            left: left
          });
          setTimeout(function () {
            ttarget.addClass('open');
          }, 100);
        } else {
          ttarget.css({
            top: top,
            left: left
          });
          ttarget.addClass('open');
        }
      }
    }
    this.openTeamInfo();
  }

  previewInvoice(e, invoice) {
    const THIS = this;
    if (this.wo_is_active != 0 && this.wo_is_active != 2) {
      this.invoiceSections.overview = 0;
      this.invoiceSections.singlePreview = 0;
      this.invoiceSections.createForm = 0;
      this.invoiceSections.editForm = 0;
      this.invoiceSections.createRecurringForm = 0;
      this.invoiceSections.createEmail = 0;
      // THIS.selectedInvoice.length = 0;
      // THIS.selectedInvoice[0] = invoice.id;

      setTimeout(function () {
        THIS.templateInvoice = 1;
        THIS.singleInvoice = invoice;
        THIS.singleInvoice.facStr = THIS.singleInvoice.facStr.reverse();
        if (THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null) {
          THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.dateDynamicFormat(THIS.singleInvoice.created_at, null);
        }
        THIS.invoiceSections.singlePreview = 1;
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      }, 400);
      $('.drop-menu-right').removeClass('open');
    } else {
      if (this.wo_is_active == 0) {
        this.restoreAlert = true;
      } else {
        this.restoreAlert = false;
      }
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#PreviewAlert')).modal('show');
    }

  }

  ClosePreviewAlert() {
    (<any>$('#PreviewAlert')).modal('hide');
  }

  singlePreviewInvoiceQuick(invoice) {
    const THIS = this;
    this.invoiceSections.overview = 0;
    this.invoiceSections.singlePreview = 0;
    this.invoiceSections.createForm = 0;
    this.invoiceSections.editForm = 0;
    this.invoiceSections.createRecurringForm = 0;
    this.invoiceSections.createEmail = 0;
    setTimeout(function () {
      THIS.templateInvoice = 1;
      THIS.singleInvoice = invoice;
      THIS.selectedInvoice.length = 0;
      THIS.selectedInvoice[0] = invoice.id;
      if (THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null) {
        THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
      }
      THIS.invoiceSections.singlePreview = 1;
      setTimeout(function () {
        THIS.CustomHelper.tooltipRender();
      }, 100);
    }, 400);
    $('.drop-menu-right').removeClass('open');
    this.openTeamInfo();
    this.getInvoices();
  }

  rightClickMenu(e, invoice) {
    e.preventDefault();
    this.selectedInvoice.length = 0;
    this.selectedInvoice = [invoice.id];

    const target = $(e.target);
    const w = target.closest('#right-click-holder').width();
    const h = target.closest('#right-click-holder').height();
    const p = target.closest('#right-click-holder').offset();
    let left = e.clientX - p.left;
    let top = e.clientY - p.top;
    let clickH = $('.drop-menu-right').height();
    // clickH = clickH < 150 ? 400 : clickH;
    if (this.selectedInvoice.length > 1) {
      clickH = 150;
    }
    if ((w - left) < 230) {
      left = w - 250;
    }
    if (h < top + clickH) {
      top = top - (top + clickH - h);
    }
    if (top < 10) {
      top = 10;
    }

    const ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
    if (ttarget.hasClass('open')) {
      $('.drop-menu-right').removeClass('open');
      ttarget.css({
        top: top,
        left: left
      });
      setTimeout(function () {
        ttarget.addClass('open');
      }, 100);
    } else {
      ttarget.css({
        top: top,
        left: left
      });
      ttarget.addClass('open');
    }
  }

  dropMenuRightToggle(e) {
    const container = $('.invoice-list');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $('.drop-menu-right').removeClass('open');
    }
  }

  getSinglePreview(uid) {
    this.invoicesService.getInvoiceSingle({uid: uid}).subscribe(res => {
      if (res.status == 2000) {
        this.singlePreviewInvoiceQuick(res.data);
      }
    });
  }

  cTeam = null;

  openTeamInfo() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'me/teamInfo?token=' + THIS.currentUser.token,
      type: 'post',
      data: {},
      success: function (res) {
        if (res.status == 2000) {
          THIS.cTeam = res.data;
        }
      }
    });
  }

  paymentLog = [];

  generatePaymentLog() {
    this.paymentLog.length = 0;
    const THIS = this;
    $.each(THIS.singleInvoice.log, function (i, v) {
      if (v.pn_type == 'status-update-2') {
        THIS.paymentLog.push(v);
      }
    });
  }

  ShowPaymentHistory() {

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#paymentHistoryModal')).modal('show');
  }
  DeletePaymentHistory(e: any, payment: any){
    this.target = e;
    this.payment = payment;

    (<any>$('#paymentHistoryModal')).modal('hide');
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#DeletePaymentHistoryModal')).modal('show');
  }
  removePaymentHistory() {
    const THIS = this;
    const param = {
      id: this.payment.id,
      inv_id: THIS.singleInvoice.id,
    };
    const ldd = document.getElementById('paymentDelete');
    const ladda = Ladda.create(ldd);
    ladda.start();
    THIS.invoicesService.paymentRemove(param).subscribe(res => {
      ladda.stop();
      THIS.singleInvoice = res.data;
      THIS.singleInvoice.facStr = THIS.singleInvoice.facStr.reverse();
      if (THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null) {
        THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
      }
      THIS.getInvoices();
      (<any>$('#DeletePaymentHistoryModal')).modal('hide');
      (<any>$('#paymentHistoryModal')).modal('show');
      toastr.success('Single payment history has been deleted successfully', 'Payment History Delete', this.toastrOption);
    });
  }
  updatePaymentStatus(status) {
    const THIS = this;
    if (this.singleInvoice.status != status) {
      if (status == 1) {
        swal({
          title: 'Update Payment Status',
          text: 'Are you sure you want to make this invoice unpaid? All previous payment record will be removed permanently',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        }).then(res => {
          if (res) {
            $.ajax({
              url: THIS.APPCONFIG.api + 'invoice/payment/remove/all?token=' + THIS.currentUser.token,
              data: {inv_id: THIS.singleInvoice.id},
              type: 'post',
              success: function (res) {
                if (res.status == 2000) {
                  THIS.singleInvoice = res.data;
                  THIS.singleInvoice.facStr = THIS.singleInvoice.facStr.reverse();
                  if (THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null) {
                    THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
                  }
                  THIS.getInvoices();
                }
              }
            });
          }
        });
      } else if (status == 3) {
        const payment = THIS.singleInvoice.total - THIS.singleInvoice.paid;
        $.ajax({
          url: THIS.APPCONFIG.api + 'invoices/' + THIS.singleInvoice.id + '/paymentUpdate?token=' + THIS.currentUser.token,
          data: {payment: payment},
          type: 'post',
          success: function (res) {
            THIS.singleInvoice = res;
            THIS.singleInvoice.facStr = THIS.singleInvoice.facStr.reverse();
            if (THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null) {
              THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
            }
            THIS.getInvoices();
            setTimeout(function () {
              THIS.CustomHelper.tooltipRender();
            }, 100);
          }
        });
      }
    }
  }

  emptyTrash() {
    const ldd = document.getElementById('empBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'invoices/empty/trash?token=' + THIS.currentUser.token,
      data: {},
      type: 'post',
      success: function (res) {
        THIS.selectedInvoice.length = 0;
        ladda.stop();
        (<any>$('#EmptyTrashModal')).modal('hide');
        toastr.success('This invoice has been permanently deleted successfully', 'Invoice Delete', this.toastrOption);

        THIS.getInvoices();
        // Pusher emit
      }
    });
  }

  emptyThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#EmptyTrashModal')).modal('show');
  }

  duplicateInvoice(id: any = null) {
    let ldd = document.getElementById('loadingDuplicate');
    let ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    id = (this.selectedInvoice[0] !== undefined) ? this.selectedInvoice[0] : id;
    $.ajax({
      url: THIS.APPCONFIG.api + 'invoices/duplicate?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.getInvoices();
        THIS.selectedInvoice = [];
        (<any>$('#DuplicateModal')).modal('hide');
        toastr.success('This invoice has been duplicated successfully', 'Invoice Duplicated', this.toastrOption);
        if (res.status != undefined && res.status == 3000) {
          swal({
            title: 'Invoice',
            text: '',
            icon: 'warning',
            dangerMode: true,
            className: 'swal-btn-center'
          });
          return true;
        }
        let ids = [res.id];
        $.ajax({
          url: THIS.APPCONFIG.api + 'get/invoices/remove?token=' + THIS.currentUser.token,
          data: {ids: ids},
          type: 'post',
          success: (res: any) => {
            THIS.getInvoices();
          }
        });
      }
    });
  };

  duplicateModal() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#DuplicateModal')).modal('show');
  }

  ngOnInit() {
    const THIS = this;
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_inv_'+Session.team, function (v) {
        THIS.getInvoices();
    });*/

    $(document).mouseup(function (e: any) {
      const container = $('.outsideClick2');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass('open');
      }
    });
  }

  ngOnDestroy() {
  }
}
