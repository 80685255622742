import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {FacilityService} from '../../_services/facility.service';

declare let swal: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
    selector: 'page-fm360',
    templateUrl: './fm360.component.html',
    styles: []
})
export class Fm360Component {
    // =======================
    // General Variables
    // =======================
    currentUser;
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Permission = 1;
    Template = 1;

    restoreAlert = false;

    embed = '';
    src = '';

    CounterStatus: any = {
        total: 0,
        archived: 0,
        trash: 0,
    };
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};


    ROUTES = '/fm360';

    routeMake() {
        let route = this.CustomHelper.getRouteStr();
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        if (route[2] != undefined) {
            if (route[2] == 'new') {
                this.createFM();
            } else if (route[2] == 'edit' && route[3] != undefined) {
                this.singleEditUrl(route[3]);
            } else {
                let preId = route[2];
                this.previewFmUrl(preId);
            }
        } else {
            let url = '/' + Session.team + this.ROUTES;
            window.history.pushState('', '', url);
            this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
        }
    }

    constructor(private facilityService: FacilityService) {
        let THIS = this;
        this.routeMake();
        THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        THIS.Permission = this.currentUser.user_type > 2 ? this.currentUser.modules.fm360 : 1;
        // 1 = Default, 2 = Comfortable, 3 = Compact
        if (THIS.currentUser.list_view.fm != undefined) {
            THIS.currentUser.list_view.fm.d == 1 ? THIS.Density = 1 : '';
            THIS.currentUser.list_view.fm.cf == 1 ? THIS.Density = 2 : '';
            THIS.currentUser.list_view.fm.cp == 1 ? THIS.Density = 3 : '';
        }
        if (THIS.Permission == 1) {
            this.getFm();
            THIS.facilityService.currentSelectedFac.subscribe(value => THIS.getFm());
        }
        if (window.innerWidth <= 1200) {
            THIS.FilterScroll = 270;
        }
    }

    wo_is_active = 1;
    order_by = 'desc';
    SortTitle = 'Created Date';
    sort_by = 'created_at';
    SortOptions = [
        {title: 'Create Date', slug: 'created_at'},
        {title: 'Fm360 ID', slug: 'uid'},
        {title: 'Title', slug: 'title'},
        {title: 'Facility', slug: 'facility_id'},
    ];
    previewList = 1;
    searchKeyword = '';

    searchKeywordClear(e) {
        this.searchKeyword = '';
        this.getFm();
    }

    filter(): void {
        let THIS = this;
        clearInterval(THIS.CustomHelper.woListSearch);
        THIS.CustomHelper.woListSearch = setInterval(function () {
            clearInterval(THIS.CustomHelper.woListSearch);
            THIS.pageNo = 1;
            THIS.getFm();
        }, 400);
    }

    highlighter(subject) {
        if (subject == '' || subject == null) {
            return subject;
        } else {
            subject = this.CustomHelper.makeStr(subject);
        }
        let searchKeyword = this.searchKeyword.replace(/\)/g, '\\)');
        let matches = subject.match(new RegExp(searchKeyword, 'gi'));
        let res = '';
        $.each(matches, function (i, v) {
            res = subject.replace(v, '<span class=\'text-warning-highlight\'>' + v + '</span>');
        });
        if (res == '') {
            res = subject;
        }
        return res;
    }

    pageNo = 1;
    perPage = 20;
    pageEvent;

    paginateList() {
        if (this.pageEvent.pageSize == this.perPage) {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex + 1;
            this.getFm();
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
            this.getFm();
        }
    }

    FMData = [];
    totalFm = 0;
    FMPagination = 0;
    facilityEmpty = 0;

    getFm() {
        let THIS = this;
        THIS.FMPagination = 0;
        THIS.previewList = 0;
        let param = {
            sort_by: THIS.sort_by,
            order_by: THIS.order_by,
            is_active: THIS.wo_is_active,
            keyword: THIS.searchKeyword,
            pageNo: THIS.pageNo,
            perPage: THIS.perPage
        };
        THIS.FMData.length = 0;
        THIS.facilityEmpty = 0;
        THIS.selectedIde = [];
        THIS.selectedForAction = [];
        $.ajax({
            url: THIS.APPCONFIG.api + 'fm360/list?token=' + THIS.currentUser.token,
            data: param,
            type: 'post',
            success: function (res) {
                THIS.previewList = 1;
                if (res.status == 2000) {
                    THIS.CounterStatus = {
                        total: res.total,
                        archived: res.archived,
                        trash: res.trash,
                    };
                    if (res.data.length > 0) {
                        let Data = [];
                        $.each(res.data, function (i, v) {
                            v['uid'] = THIS.CustomHelper.makeStr(v['uid']);
                            Data.push(v);
                        });

                        THIS.FMData = Data;
                        THIS.totalFm = res.total;
                        THIS.FMPagination = 1;
                        setTimeout(function () {
                            THIS.CustomHelper.tooltipRender();
                        }, 100);
                    }

                } else if (res.status == 400) {
                    THIS.facilityEmpty = 1;
                    THIS.FMData.length = 0;
                    THIS.totalFm = 0;
                    THIS.FMPagination = 1;
                } else {
                    THIS.FMData.length = 0;
                    THIS.totalFm = 0;
                    THIS.FMPagination = 1;
                }
            }
        });
    }

    RefreshList() {
        this.Template = 1;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + this.ROUTES;
        window.history.pushState('', '', url);
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
        this.getFm();
    }

    selectedIde = [];

    selectAllCheck() {
        let THIS = this;
        let WO = this.FMData;
        if (this.selectedIde.length == this.FMData.length) {
            this.selectedIde.length = 0;
        } else {
            this.selectedIde.length = 0;
            $.each(WO, function (i, v) {
                THIS.selectedIde.push(v.id);
            });
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }

    checkThisRow(e, index, fm) {
        e.preventDefault();
        let THIS = this;
        let iIndex = this.selectedIde.indexOf(fm.id);
        if (iIndex > -1) {
            if (this.selectedIde.length == 1) {
                this.selectedIde.length = 0;
            } else {
                this.selectedIde.splice(iIndex, 1);
            }
        } else {
            this.selectedIde.push(fm.id);
        }
        $('.drop-menu-right').removeClass('open');
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);

    }

    singlePreviewInvoice(e, index, fm) {
        e.preventDefault();
        if (e.ctrlKey) {
            if (e.which == 1) {
                if (this.selectedIde.indexOf(fm.id) > -1) {
                    this.selectedIde.splice(this.selectedIde.indexOf(fm.id), 1);
                } else {
                    this.selectedIde.push(fm.id);
                }
                $('.drop-menu-right').removeClass('open');
            }
        } else {
            if (e.which == 1) {
                this.selectedIde = [fm.id];
                this.previewFm(fm);
                $('.drop-menu-right').removeClass('open');
            } else if (e.which == 3) {
                if (this.selectedIde.length > 1) {
                    if (this.selectedIde.indexOf(fm.id) == -1) {
                        this.selectedIde.push(fm.id);
                    }
                } else {
                    this.selectedIde = [fm.id];
                }
                let target = $(e.target);
                let w = target.closest('#right-click-holder').width();
                let h = target.closest('#right-click-holder').height();
                let p = target.closest('#right-click-holder').offset();
                let left = e.clientX - p.left;
                let top = e.clientY - p.top;
                let clickH = $('.drop-menu-right').height();
                clickH = clickH < 150 ? 400 : clickH;
                if (this.selectedIde.length > 1) {
                    clickH = 150;
                }
                if ((w - left) < 230) {
                    left = w - 250;
                }
                if (h < top + clickH) {
                    top = top - (top + clickH - h);
                }
                if (top < 10) {
                    top = 10;
                }

                let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
                if (ttarget.hasClass('open')) {
                    $('.drop-menu-right').removeClass('open');
                    ttarget.css({
                        top: top,
                        left: left
                    });
                    setTimeout(function () {
                        ttarget.addClass('open');
                    }, 100);
                } else {
                    ttarget.css({
                        top: top,
                        left: left
                    });
                    ttarget.addClass('open');
                }
            }
        }

        let THIS = this;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }

    previewFmUrl(uid) {
        let THIS = this;
        THIS.FmId = uid;
        THIS.Template = 3;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + this.ROUTES + '/' + uid;
        window.history.pushState('', '', url);
        THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
    }

    FmId = 0;

    previewFm(work) {
        if (this.wo_is_active != 0 && this.wo_is_active != 2) {
            let THIS = this;
            THIS.FmId = work.uid;
            THIS.Template = 3;
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + this.ROUTES + '/' + work.uid;
            window.history.pushState('', '', url);
            THIS.CustomHelper.woRouteDiscard = 0;
            THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
            setTimeout(function () {
                THIS.CustomHelper.tooltipRender();
            }, 100);
        } else {
            if (this.wo_is_active == 0) {
                this.restoreAlert = true;
            } else {
                this.restoreAlert = false;
            }
            let ___selectedTab = 0;
            const tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                    ___selectedTab = i;
                }
            });
            const child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#PreviewAlert')).modal('show');
        }
    }

    ClosePreviewAlert() {
        (<any>$('#PreviewAlert')).modal('hide');
    }

    dropMenuRightToggle(e) {
        let container = $('.invoice-list');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.drop-menu-right').removeClass('open');
        }
    }

    singleEditUrl(uid) {
        let THIS = this;
        THIS.FmId = uid;
        THIS.Template = 4;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + this.ROUTES + '/edit/' + uid;
        window.history.pushState('', '', url);
        THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
    }

    singleEdit() {
        let THIS = this;
        let index = THIS.FMData.map(function (e) {
            return e.id;
        }).indexOf(THIS.selectedIde[0]);
        if (index > -1) {
            let fm = THIS.FMData[index];
            THIS.FmId = fm.uid;
            THIS.Template = 4;
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + this.ROUTES + '/edit/' + fm.uid;
            window.history.pushState('', '', url);
            THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
        }
    }

    removeWork = {
        submitted: 0
    };
    selectedForAction = [];

    removeThisWork(id = null) {
        let THIS = this;
        let ids = (id !== null) ? [id] : THIS.selectedForAction;
        const ldd = document.getElementById('removeWorkOrderBtn');
        const ladda = Ladda.create(ldd);
        ladda.start();
        THIS.removeWork.submitted = 1;
        $.ajax({
            url: THIS.APPCONFIG.api + 'fm360/make/remove?token=' + THIS.currentUser.token,
            data: {ids: ids},
            type: 'post',
            success: function (res) {
                ladda.stop();
                THIS.getFm();
                // THIS.removeUndoThisWork(THIS.selectedForAction);
                toastr.success(res.msg, 'Trash FM360', THIS.toastrOption);
                (<any>$('#deleteWorkOrderModal')).modal('hide');
            }
        });
    }

    deleteThisWorkOrder() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#deleteWorkOrderModal')).modal('show');
    }

    removeUndoThisWork(ids) {
        let THIS = this;
        const ldd = document.getElementById('restore-btn');
        const ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'fm360/make/remove/undo?token=' + THIS.currentUser.token,
            data: {ids: ids},
            type: 'post',
            success: function (res) {
                ladda.stop();
                THIS.getFm();
                toastr.success(res.msg, 'Restore FM360', THIS.toastrOption);
                (<any>$('#RestoreModal')).modal('hide');
            }
        });
    }

    restoreThisFM() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#RestoreModal')).modal('show');
    }

    archiveThisWork(id = null) {
        let THIS = this;
        let ids = (id !== null) ? [id] : THIS.selectedForAction;
        THIS.removeWork.submitted = 1;
        const ldd = document.getElementById('archive-btn');
        const ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'fm360/make/archive?token=' + THIS.currentUser.token,
            data: {ids: ids},
            type: 'post',
            success: function (res) {
                ladda.stop();
                THIS.getFm();
                THIS.RefreshList();
                THIS.selectedIde = [];
                THIS.selectedForAction = [];
                toastr.success(res.msg, 'Archive FM360', THIS.toastrOption);
                (<any>$('#ArchiveModal')).modal('hide');
            }
        });
    }

    archiveThisFM() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#ArchiveModal')).modal('show');
    }

    archiveUndoThisWork(ids) {
        let THIS = this;
        const ldd = document.getElementById('unarchive-btn');
        const ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'fm360/make/archive/undo?token=' + THIS.currentUser.token,
            data: {ids: ids},
            type: 'post',
            success: function (res) {
                ladda.stop();
                THIS.getFm();
                toastr.success(res.msg, 'Unarchive FM360', THIS.toastrOption);
                (<any>$('#UnarchiveModal')).modal('hide');
            }
        });
    }

    unarchiveThisWork() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#UnarchiveModal')).modal('show');
    }

    FilterOn = 0;
    FilterScroll = 235;

    ToggleFilter() {
        if (this.wo_is_active != 0) {
            this.FilterScroll = 235;
        } else {
            this.FilterScroll = 275;
        }
    }

    statusCheck = [];

    updateStatusCheck(status) {
        if (status == -2) {
            if (this.wo_is_active == 2) {
                this.statusCheck.length = 0;
                this.wo_is_active = 1;
            } else {
                this.statusCheck.length = 0;
                this.wo_is_active = 2;
            }
        } else if (status == -1) {
            if (this.wo_is_active == 0) {
                this.statusCheck.length = 0;
                this.wo_is_active = 1;
            } else {
                this.statusCheck.length = 0;
                this.wo_is_active = 0;
            }
        } else if (status == 0) {
            this.statusCheck.length = 0;
            this.wo_is_active = 1;
        } else {
            this.wo_is_active = 1;
            let index = this.statusCheck.indexOf(status);
            if (index > -1) {
                this.statusCheck.splice(index, 1);
            } else {
                this.statusCheck.push(status);
            }
        }
        this.ToggleFilter();
        this.getFm();
    }

    Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
    changeDensity(density) {
        let THIS = this;
        this.Density = density;
        localStorage.DensityPro = this.Density;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
        let param = {fm: ''};
        density == 1 ? param.fm = 'd' : '';
        density == 2 ? param.fm = 'cf' : '';
        density == 3 ? param.fm = 'cp' : '';
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'me/density?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
            }
        });
    }

    createFM() {
        this.Template = 2;
    }

    emptyTrash() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'fm360/make/empty/trash?token=' + THIS.currentUser.token,
            data: {},
            type: 'post',
            success: function (res) {
                THIS.selectedIde.length = 0;
                THIS.selectedForAction.length = 0;
                toastr.success(res.msg, 'Empty Trash FM360', THIS.toastrOption);
                THIS.getFm();
                (<any>$('#EmptyTrashModal')).modal('hide');
            }
        });
    }

    emptyThisFM() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#EmptyTrashModal')).modal('show');
    }

    AllEnableFacility() {
        let ldd = document.getElementById('allEnableFac');
        let ladda = Ladda.create(ldd);
        ladda.start();
        this.facilityService.enableAll(0).subscribe(res => {
            ladda.stop();
            this.facilityService.refreshFacility();
        });
    }
}
