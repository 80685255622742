import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHelper } from '../../customhelper';
import { APPCONFIG } from '../../config';
declare let swal: any;
declare let Ladda: any;

@Component({
    selector: 'my-page-login',
    styles: [],
    templateUrl: './forgot.component.html'
})

export class ForgotComponent {

    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    email = '';
    errorMessage = '';

    mobileMenu = false;

    constructor(private router: Router) {
        if (localStorage.getItem('currentUserInfo') !== null) {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            this.router.navigate([Session.team+'/dashboard']);
        } else {
            delete localStorage.currentUserInfo;
            localStorage.clear();
        }
    }

    sendReq() {
        let ldd = document.getElementById('submitBTN');
        let ladda = Ladda.create(ldd);
        let THIS = this;
        let param = {email : THIS.email};
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.apiAuth+'forgot',
            type: 'post',
            data: param,
            success: function(res){
                ladda.stop();
                if(res.status == 5000){
                    THIS.errorMessage = 'Invalid email address';
                }
                if(res.status == 7000){
                    THIS.errorMessage = res.error;
                }
                if(res.status == 2000){
                    THIS.errorMessage = '';
                    THIS.email = '';
                    swal({
                        title: "Reset Password",
                        text: res.msg,
                        icon: "success",
                        dangerMode: true,
                        className: 'swal-btn-center'
                    });
                }
            }
        });
    }

}
