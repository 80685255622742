import {Component, EventEmitter, Output} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
declare let swal: any;
declare let Ladda: any;
declare let toastr: any;

@Component({
    selector: 'page-createFm',
    templateUrl: './createFm.component.html',
    styles: []
})
export class CreateFmComponent {
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    // =======================
    // General Variables
    // =======================
    currentUser;
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Permission = 1;

    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
    embed = '';
    src = '';
    runFM() {
        const h = this.CustomHelper.fullHeight(320);
        this.param.embed = this.src;
      // tslint:disable-next-line:max-line-length
        const fm = '<iframe class="_well rounded ash" style="height : ' + h + '" src="' + this.src + '" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe>';
        setTimeout(() => {
            (<any>$('#fmNow')).html(fm);
        }, 400);
    }
    parseSrc() {
        if ( this.embed.indexOf('<iframe') >= 0) {
            const element = document.createElement('div');
            element.innerHTML = this.embed;
            const imgSrcUrls = element.getElementsByTagName('iframe');
            if (imgSrcUrls.length > 0) {
                this.src = imgSrcUrls[0].getAttribute('src');
                this.runFM();
            } else {
                let ___selectedTab = 0;
                const tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) {
                    if ($(v).find('a.active').length > 0) {
                        ___selectedTab = i;
                    }
                });
                const child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#fmModal')).modal('show');
                // swal({
                //     title: 'FM360° Preview',
                //     text: 'The FM360° media may be not valid. Please check the embeded code.',
                //     icon: "error",
                //     dangerMode: true,
                //     className: 'swal-btn-center'
                // });
            }
        } else {
            if ($.trim(this.embed) !== '') {
                this.src = this.embed;
                this.runFM();
            } else {
                let ___selectedTab = 0;
                const tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) {
                    if ($(v).find('a.active').length > 0) {
                        ___selectedTab = i;
                    }
                });
                const child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#fmModal')).modal('show');
                // swal({
                //     title: 'FM360° Preview',
                //     text: 'The FM360° media may be not valid. Please check the embeded code.',
                //     icon: "error",
                //     dangerMode: true,
                //     className: 'swal-btn-center'
                // });
            }
        }
        this.getAllFacility();
    }

    ROUTES = '/fm360';
    routeMake() {
        const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        const url = '/' + Session.team + this.ROUTES + '/new';
        window.history.pushState('', '', url);
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    }
    constructor() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.routeMake();
    }
  updateChangesDiscard() {
    const THIS = this;
    const index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      this.CustomHelper.ConfRoute[index].change = 1;
    } else {
      this.CustomHelper.ConfRoute.push({tab: this.CustomHelper.ConfCurRoute, change: 1});
    }
  }

    Facility = [];
    FacilityComp = [];
    getAllFacility() {
        const THIS = this;
        THIS.Facility.length = 0;
        THIS.FacilityComp.length = 0;
        $.ajax({
            url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
            data: {},
            type: 'get',
            success: function (res) {
                THIS.FacilityComp = res.facility;
                $.each(res.facility, function (i, v) {
                    v['pp'] = 0;
                    THIS.Facility.push(v);
                    $.each(v.child, function (j, vC) {
                        vC['pp'] = 1;
                        THIS.Facility.push(vC);
                        $.each(vC.child, function (k, vCC) {
                            vCC['pp'] = 2;
                            THIS.Facility.push(vCC);
                            $.each(vCC.child, function (l, vCCC) {
                                vCCC['pp'] = 3;
                                THIS.Facility.push(vCCC);
                            });
                        });
                    });
                });
                setTimeout(function () {
                    if ($('.reRenderFacility').length > 0) {
                        const facilityId = parseInt($('.reRenderFacility').attr('data-id'));
                        if (facilityId > 0) {
                            THIS.param.facility_id = facilityId.toString();
                            (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                        }
                    }
                    (<any>$('.chosen-default-single-fac')).chosen('destroy');
                    (<any>$('.chosen-default-single-fac')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_single: 'Select Facility'
                    }).on('change', function (e, p) {
                        if (e.target.value === '000') {
                          (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                          (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
                            let ___selectedTab = 0;
                            const tabs = $('#sort-tabs').find('li');
                            tabs.each(function (i, v) {
                                if ($(v).find('a.active').length > 0) {
                                    ___selectedTab = i;
                                }
                            });
                            const child = ___selectedTab + 1;
                            (<any>$('.owl-carousel')).owlCarousel('destroy');
                            $('#sort-tabs').find('li').find('a').removeClass('active');
                            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                            (<any>$('#createFacilityModal')).modal('show');

                        } else {
                            THIS.param.facility_id = e.target.value;
                            // THIS.validationThisForm();
                            THIS.updateChangesDiscard();
                        }
                    });
                }, 100);
            }
        });
    }

    param = {
        facility_id: '',
        title: '',
        embed: '',
    };
    validationThisForm() {
        let valid = 1;
        $('.show-facility-error').html('');
        $('.show-title-error').html('');
        if (this.param.title === '') {
            valid = 0;
          $('.show-title-error').html('Title is required');
        }
        if (this.param.facility_id === '') {
            valid = 0;
          $('.show-facility-error').html('Facility is required');
        }
        return valid;
    }
    createFm() {
      console.log(123);
        if (this.validationThisForm() === 1) {const THIS = this;
            const ldd = document.getElementById('createFm');
            const ladda = Ladda.create(ldd);
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.api + 'fm360/create?token=' + THIS.currentUser.token,
                type: 'post',
                data: THIS.param,
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                        THIS.preview.emit(res.data);
                    }
                  toastr.success(res.msg, 'FM360 Created', this.toastrOption);
                }
            });
        }
    }

    discardFM() {
        this.discard.emit();
    }

}
