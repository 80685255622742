import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {FcmService} from '../../_services/fcm.service';
import {FacilityService} from '../../_services/facility.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-page-verified-vendors',
  templateUrl: './verified-vendors.component.html',
  styles: [],
})

export class VerifiedVendorsComponent {

  /*variables here*/
  currentUser;
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  dropdown: any = false;
  loading: any = true;
  industries = [
    {name: 'Plumbing'},
    {name: 'HVAC'},
    {name: 'Electrical'},
    {name: 'Cleaning'},
    {name: 'Fire Services'},
    {name: 'Pest Control'},
    {name: 'Handyman'},
  ];
  companies: any = [];

  Template = 1;
  miniScreen = 0;
  ROUTES = '/eq_verified_vendor';
  preId = '';
  param: any = {
    sort_by: 'title',
    order_by: 'desc',
    industry: '',
    keyword: '',
    pageNo: 1,
    perPage: 20,
  };

  constructor(private fcmService: FcmService, private facilityService: FacilityService, private activatedRoute: ActivatedRoute, private router: Router) {
    const THIS = this;
    setTimeout(function () {
      THIS.routeMake();
      THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
      THIS.fetchTableData();
    }, 500);

  }


  fetchTableData() {
    const THIS = this;
    THIS.loading = true;
    $.ajax({
      url: THIS.APPCONFIG.api + 'verified/vendors/get/list?token=' + THIS.currentUser.token,
      data: THIS.param,
      type: 'post',
      success: function (res) {
        if (res.status == 2000) {
          THIS.loading = false;
          THIS.companies = res.data;
        }
      },
      error: function (err) {
        THIS.loading = false;
      }
    });
  }


  routeMake() {
    let route: any = this.CustomHelper.getRouteStr();
    if (route[2] == undefined) {
      this.Template = 1;
      this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    } else if(route[2] != undefined){
      const preId = route[2];
      this.previewCompany(preId);
    }
  }

  discardAll() {
    let THIS = this;
    this.Template = 1;
    this.preId = '';
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES;
    window.history.pushState('', '', url);
    THIS.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    THIS.fetchTableData();
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 500);
  }

  previewCompanySingle(asset) {
    const preId = asset.id;
    this.previewCompany(preId);
  }

  previewCompany(preId) {
    const THIS = this;
    THIS.Template = 2;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + THIS.ROUTES + '/' + preId;
    THIS.preId = preId;
    window.history.pushState('', '', url);
    THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 500);
  }

  search() {
    let THIS = this;
    clearInterval(THIS.CustomHelper.woListSearch);
    THIS.CustomHelper.woListSearch = setInterval(function () {
      clearInterval(THIS.CustomHelper.woListSearch);
      THIS.fetchTableData();
    }, 1000);
  }

  filter(name) {
    const THIS = this;
    setTimeout(() => {
      THIS.param.industry = name;
      THIS.fetchTableData();
    });
  }

  formatIndustry(industry) {
    return industry.split(',').join(', ');
  }

}
