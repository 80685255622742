import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AssetService} from '../../_services/asset.service';
import {AuthService} from '../../_services/auth.service';
import {ContactsService} from '../../_services/contacts.service';
import {FacilityService} from '../../_services/facility.service';
import {InventoriesService} from '../../_services/inventories.service';
import {InvoicesService} from '../../_services/invoices.service';
import {Job_statusService} from '../../_services/job_status.service';
import {Job_typesService} from '../../_services/job_types.service';
import {TabsService} from '../../_services/tabs.service';
import {MediaService} from '../../_services/media.service';
import {UserService} from '../../_services/user.service';
import {WorkOrderService} from '../../_services/work-orders.service';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';

declare let Ladda: any;

@Component({
  selector: 'register-password-layout',
  templateUrl: './registerPassword.component.html',
})

export class RegisterPasswordComponent {
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;

  constructor(private router: Router,
              private assetService: AssetService,
              private authService: AuthService,
              private contactsService: ContactsService,
              private facilityService: FacilityService,
              private inventoriesService: InventoriesService,
              private invoicesService: InvoicesService,
              private job_statusService: Job_statusService,
              private job_typesService: Job_typesService,
              private tabsService: TabsService,
              private mediaService: MediaService,
              private userService: UserService,
              private workOrderService: WorkOrderService) {
    let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    if (currentUser != null) {
      this.router.navigate(['/dashboard']);
    }
    let comp = this.CustomHelper.getRouteStr();
    this.param.pin = comp[3];
    this.pinCheck();

  }

  errorMessage = '';
  valid = {
    title: 0,
    address: 0,
    employee: 0,
    name: 0
  };
  param = {
    title: '',
    address: '',
    employee: '0',
    name: '',
    pin: '',
    address_lat: '',
    address_lon: '',
  };

  mobileMenu = false;


  handleAddressChange(place) {
    this.param.address = place.formatted_address;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    // Store latitude and longitude in vendor_param
    this.param.address_lat = JSON.stringify(lat);
    this.param.address_lon = JSON.stringify(lng);
  }

  validationCheck() {
    let THIS = this;
    if ($.trim(THIS.param.title) == '') {
      THIS.param.title = '';
      return false;
    }
    if ($.trim(THIS.param.address) == '') {
      THIS.param.address = '';
      return false;
    }
    // if($.trim(THIS.param.employee) == ''){THIS.param.employee = ''; return false}
    if ($.trim(THIS.param.name) == '') {
      THIS.param.name = '';
      return false;
    }
    return true;
  }


  pinCheck() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'pin-check',
      type: 'post',
      data: {pin: THIS.param.pin},
      success: function (res) {
        if (res.status != 2000) {
          THIS.router.navigate(['auth/invalid/pin']);
        }
      }
    });
  }

  validPortal = 1;

  portalCheck() {
    const THIS = this;
    this.usernameTrim();
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'user-check',
      type: 'post',
      data: {username: THIS.param.name},
      success: function (res) {
        if (res.status == 2000) {
          THIS.validPortal = 1;
        } else {
          THIS.validPortal = 0;
        }
      }
    });
  }

  usernameTrim() {
    let u = this.param.name;
    u = $.trim(u);
    u = u.replace(/[^a-zA-Z1-9]/g, '');
    this.param.name = u;
  }

  activateCompany() {
    const THIS = this;
    let ldd = document.getElementById('submitBTN');
    let ladda = Ladda.create(ldd);
    this.valid.title = 1;
    this.valid.address = 1;
    // this.valid.employee = 1;
    this.valid.name = 1;
    if (this.validationCheck()) {
      ladda.start();
      $.ajax({
        url: THIS.APPCONFIG.apiAuth + 'company-update',
        type: 'post',
        data: THIS.param,
        success: function (res) {
          ladda.stop();
          if (res.status == 2000) {
            localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
            THIS.assetService.refreshToken();
            THIS.authService.refreshToken();
            THIS.contactsService.refreshToken();
            THIS.facilityService.refreshToken();
            THIS.inventoriesService.refreshToken();
            THIS.invoicesService.refreshToken();
            THIS.job_statusService.refreshToken();
            THIS.job_typesService.refreshToken();
            THIS.mediaService.refreshToken();
            THIS.userService.refreshToken();
            THIS.workOrderService.refreshToken();
            THIS.tabsService.removeTemp();
            if (res.data.verified_vendor === 1) {
              console.log(res.data.verified_vendor, 'vendor');

              THIS.router.navigate(['/auth/register/vendor/process/' + res.data.team]);
            } else {
              THIS.router.navigate(['/auth/register/process/' + res.data.team]);
            }
          } else {
            THIS.errorMessage = res.error;
          }
        }
      });
    }
  }


}
