import {Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {FacilityService} from '../../_services/facility.service';
import {InvoicesService} from '../../_services/invoices.service';
import {FcmService} from '../../_services/fcm.service';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';

declare let swal: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
  selector: 'recurring-invoices',
  templateUrl: './invoicesRecur.component.html'
})
export class InvoicesRecurComponent {
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  Permission = 1;

  invoiceSections = {template: 1};
  miniScreen = 0;
  restoreAlert = false;

  ROUTES = '/invoicesRecur';
  currentUser = null;

  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true};

  constructor(private invoicesService: InvoicesService,
              private fcmService: FcmService,
              private facilityService: FacilityService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    // Pagination
    this.currentUser.pagination != null ? this.perPage = this.currentUser.pagination.invcRecur : '';
    // 1 = Default, 2 = Comfortable, 3 = Compact
    this.currentUser.list_view.invcRecur.d == 1 ? this.Density = 1 : '';
    this.currentUser.list_view.invcRecur.cf == 1 ? this.Density = 2 : '';
    this.currentUser.list_view.invcRecur.cp == 1 ? this.Density = 3 : '';
    this.Permission = this.currentUser.user_type > 2 ? this.currentUser.modules.invoices : 1;
    this.facilityService.currentSelectedFac.subscribe(value => this.getRecurringInvoices());
    this.routeMake();
    if (window.innerWidth <= 1024) {
      this.miniScreen = 1;
    }
  }

  routeMake() {
    let route = this.CustomHelper.getRouteStr();
    if (route[2] != undefined) {
      if (route[2] == '') {
      } else if (route[2] == 'new') {
        this.createNewInvoice();
      } else if (route[2] == 'edit') {
        if (route[3] != undefined) {
          let preId = route[3];
          this.editThisInvoiceQuick(preId);
        }
      } else {
        let preId = route[2];
        this.previewInvoice(preId);
      }
    } else {
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      let url = '/' + Session.team + this.ROUTES;
      window.history.pushState('', '', url);
    }
  }


  preId = '';
  selectedInvoice = [];
  selectedSingleInvoice = null;

  rightClickMenu(e, invoice) {
    e.preventDefault();

    this.selectedInvoice.length = 0;
    this.selectedInvoice = [invoice.id];

    let target = $(e.target);
    let w = target.closest('#right-click-holder').width();
    let h = target.closest('#right-click-holder').height();
    let p = target.closest('#right-click-holder').offset();
    let left = e.clientX - p.left;
    let top = e.clientY - p.top;
    let clickH = $('.drop-menu-right').height();
    // clickH = clickH < 150 ? 400 : clickH;
    if (this.selectedInvoice.length > 1) {
      clickH = 150;
    }
    if ((w - left) < 230) {
      left = w - 250;
    }
    if (h < top + clickH) {
      top = top - (top + clickH - h);
    }
    if (top < 10) {
      top = 10;
    }

    let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
    if (ttarget.hasClass('open')) {
      $('.drop-menu-right').removeClass('open');
      ttarget.css({
        top: top,
        left: left
      });
      setTimeout(function () {
        ttarget.addClass('open');
      }, 100);
    } else {
      ttarget.css({
        top: top,
        left: left
      });
      ttarget.addClass('open');
    }
  }

  dropMenuRightToggle(e) {
    let container = $('.invoice-list');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $('.drop-menu-right').removeClass('open');
    }
  }

  createNewInvoice() {
    this.invoiceSections.template = 3;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES + '/new';
    window.history.pushState('', '', url);
  }

  cancelCreateRecurring(filter: number) {
    this.invoiceSections.template = 1;
  }

  cancelSingleView(filter: number) {
    this.invoiceSections.template = 1;
    this.getRecurringInvoices();
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES;
    window.history.pushState('', '', url);
    this.ToggleFilter(1);
  }

  editThisInvoiceQuick(preId) {
    this.preId = preId;
    this.selectedInvoice.length = 0;
    // this.selectedInvoice = [parseInt(preId)];
    this.invoiceSections.template = 4;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES + '/edit/' + preId;
    window.history.pushState('', '', url);
  }

  editThisInvoice() {
    if (this.selectedInvoice.length == 1) {
      let id = this.selectedInvoice[0];
      this.editThisInvoiceQuick(id);
    }
  }

  singlePreviewInvoice(e, invoice) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.which == 1) {
        let index = this.selectedInvoice.indexOf(invoice.id);
        if (index > -1) {
          this.selectedInvoice.splice(index, 1);
        } else {
          this.selectedInvoice.push(invoice.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    } else {
      if (e.which == 1) {
        // this.selectedInvoice = [invoice.id];
        let THIS = this;
        setTimeout(function () {
          THIS.previewInvoice(invoice.id);
        }, 500);
        $('.drop-menu-right').removeClass('open');
      } else if (e.which == 3) {
        if (this.selectedInvoice.length > 1) {
          this.selectedInvoice.push(invoice.id);
          this.selectedSingleInvoice = null;
        } else {
          this.selectedInvoice = [invoice.id];
        }
        let target = $(e.target);
        let w = target.closest('#right-click-holder').width();
        let h = target.closest('#right-click-holder').height();
        let p = target.closest('#right-click-holder').offset();
        let left = e.clientX - p.left;
        let top = e.clientY - p.top;
        let clickH = $('.drop-menu-right').height();
        if ((w - left) < 230) {
          left = w - 250;
        }

        let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
        if (ttarget.hasClass('open')) {
          $('.drop-menu-right').removeClass('open');
          ttarget.css({
            top: top,
            left: left
          });
          setTimeout(function () {
            ttarget.addClass('open');
          }, 100);
        } else {
          ttarget.css({
            top: top,
            left: left
          });
          ttarget.addClass('open');
        }
      }
    }

    this.getRecurringInvoicesCron();
  }

  previewInvoice(preId) {
    if(this.wo_is_active != 0 && this.wo_is_active != 2){
      this.invoiceSections.template = 2;
      this.preId = preId;
      this.selectedInvoice.length = 0;
      // this.selectedInvoice = [parseInt(preId)];
      this.invoiceSections.template = 2;
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      let url = '/' + Session.team + this.ROUTES + '/' + preId;
      window.history.pushState('', '', url);
    }else{
      if(this.wo_is_active == 0){
        this.restoreAlert = true;
      }else{
        this.restoreAlert = false;
      }
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#PreviewAlert')).modal('show');
    }
  }
  ClosePreviewAlert(){
    (<any>$('#PreviewAlert')).modal('hide');
  }

  wo_is_active = 1;
  wo_status = 1;
  totalInvoice = 0;
  pageNo = 1;
  perPage = '10';
  pageEvent;

  paginateList() {
    if (this.pageEvent.pageSize == this.perPage) {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = this.pageEvent.pageIndex + 1;
      this.getRecurringInvoices();
    } else {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = 1;
      this.getRecurringInvoices();
    }
  }

  searchKeyword = '';

  searchKeywordClear(e) {
    this.searchKeyword = '';
  }

  order_by = 'desc';
  invoices = [];
  previewList = 0;

  getRecurringInvoices() {
    let THIS = this;
    this.previewList = 0;
    let param = {
      sort_by: this.sort_by,
      order_by: this.order_by,
      perPage: this.perPage,
      pageNo: this.pageNo,
      keyword: this.searchKeyword,
      is_active: this.wo_is_active,
      status: this.wo_status
    };
    this.invoices.length = 0;
    localStorage.perPageInvR = this.perPage;
    localStorage.sort_byInvR = this.sort_by;
    localStorage.order_byInvR = this.order_by;
    this.invoicesService.getRecurInvoiceList(param).subscribe(res => {
      this.previewList = 1;
      let Data = [];
      $.each(res.data, function (i, v) {
        v['invoice_date'] = THIS.CustomHelper.formatDate(v['invoice_date']);
        v['nextCreation'] = THIS.CustomHelper.DateTimeSelectedFormat(v['nextCreation']);
        v['dates']['start'] = THIS.CustomHelper.DateTimeSelectedFormat(v['dates']['start']);
        v['dates']['expire'] = v['dates']['expire'] != null ? THIS.CustomHelper.DateTimeSelectedFormat(v['dates']['expire']) : null;
        Data.push(v);
      });
      this.totalInvoice = res.total;
      this.invoices = Data;
    });
    this.getInvStatus(param);
  }

  getRecurringInvoicesCron() {
    let THIS = this;
    let param = {
      sort_by: this.sort_by,
      order_by: this.order_by,
      perPage: this.perPage,
      pageNo: this.pageNo,
      keyword: this.searchKeyword,
      is_active: this.wo_is_active
    };
    localStorage.perPageInvR = this.perPage;
    localStorage.sort_byInvR = this.sort_by;
    localStorage.order_byInvR = this.order_by;
    this.invoicesService.getRecurInvoiceList(param).subscribe(res => {
      this.previewList = 1;
      let Data = [];
      $.each(res.data, function (i, v) {
        v['invoice_date'] = THIS.CustomHelper.formatDate(v['invoice_date']);
        v['nextCreation'] = THIS.CustomHelper.DateTimeSelectedFormat(v['nextCreation']);
        v['dates']['start'] = THIS.CustomHelper.DateTimeSelectedFormat(v['dates']['start']);
        v['dates']['expire'] = v['dates']['expire'] != null ? THIS.CustomHelper.DateTimeSelectedFormat(v['dates']['expire']) : null;
        Data.push(v);
      });
      this.totalInvoice = res.total;
      this.invoices = Data;
    });
  }

  filter(): void {
    let THIS = this;
    clearInterval(THIS.CustomHelper.woListSearch);
    THIS.CustomHelper.woListSearch = setInterval(function () {
      clearInterval(THIS.CustomHelper.woListSearch);
      THIS.pageNo = 1;
      THIS.getRecurringInvoices();
    }, 400);
  }

  highlighter(subject) {
    if (subject == '' || subject == null) {
      return subject;
    } else {
      subject = this.CustomHelper.makeStr(subject);
    }
    let matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
    let res = '';
    $.each(matches, function (i, v) {
      res = subject.replace(v, '<span class=\'text-warning-highlight\'>' + v + '</span>');
    });
    if (res == '') {
      res = subject;
    }
    return res;
  }

  selectAllCheck() {
    let THIS = this;
    let WO = this.invoices;
    if (this.selectedInvoice.length == this.invoices.length) {
      this.selectedInvoice.length = 0;
    } else {
      this.selectedInvoice.length = 0;
      $.each(WO, function (i, v) {
        THIS.selectedInvoice.push(v.id);
      });
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  FilterOn = 0;
  FilterScroll = 246;

  ToggleFilter(c) {
    if (c == '') {
      this.FilterOn = this.FilterOn == 1 ? 0 : 1;
    }
    let alrtH = 0;
    if (this.wo_is_active != 0) {
      alrtH = 0;
    } else {
      alrtH = 50;
    }
    if (this.invoiceSections.template == 1 && this.miniScreen == 0) {
      if (this.wo_is_active != 0) {
        this.FilterScroll = 246;
      } else {
        this.FilterScroll = 290;
      }
    } else {
      if (this.FilterOn == 1) {
        this.FilterScroll = 290 + alrtH;
      } else {
        this.FilterScroll = 246 + alrtH;
      }
    }
  }

  SortTitle = 'Created Date';
  sort_by = 'created_at';
  SortOptions = [
    {title: 'Created Date', slug: 'created_at'},
    {title: 'Status', slug: 'status'},
    // {title: 'Invoice ID', slug: 'invoice_id'},
    {title: 'Facility', slug: 'facility'},
    {title: 'Contact', slug: 'contact'},
    {title: 'Expire', slug: 'expire'},
    {title: 'Repeat', slug: 'repeat'},
    {title: 'Custom Repeat', slug: 'custom_repeat'},
    {title: 'Invoice Date', slug: 'invoice_date'},
    // {title: 'Due Date', slug: 'due_date'},
  ];
  Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
  changeDensity(density) {
    let THIS = this;
    this.Density = density;
    localStorage.DensityInvR = this.Density;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    let param = {invcRecur: ''};
    density == 1 ? param.invcRecur = 'd' : '';
    density == 2 ? param.invcRecur = 'cf' : '';
    density == 3 ? param.invcRecur = 'cp' : '';
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'me/density?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
      }
    });
  }

  statusCheck = [];
  FilterName = 'Active';

  updateStatusCheck(status) {
    this.wo_is_active = status;
    if (this.wo_is_active == 1) {
      this.FilterName = 'All';
      this.wo_status = null;
    } else if (this.wo_is_active == 2) {
      this.FilterName = 'Archived';
      this.wo_status = null;
    } else if (this.wo_is_active == 0) {
      this.FilterName = 'Trashed';
      this.wo_status = null;
    } else if (this.wo_is_active == 101) {
      this.FilterName = 'Active';
      this.wo_is_active = 1;
      this.wo_status = 1;
    }
    this.ToggleFilter('1');
    this.getRecurringInvoices();
  }

  CounterStatus = {
    'total': 0,
    'active': 0,
    'archived': 0,
    'trash': 0,
  };

  getInvStatus(param) {
    let THIS = this;
    this.invoicesService.getRecurInvoiceStatus(param).subscribe(res => {
      if (res.status == 2000) {
        this.CounterStatus = {
          'total': res.total,
          'active': res.active,
          'archived': res.archived,
          'trash': res.trash,
        };
      }
    });
  }

  emptyTrash() {
    const ldd = document.getElementById('empBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    $.ajax({
        url: THIS.APPCONFIG.api + 'invoice-recurring/empty/trash?token=' + THIS.currentUser.token,
        data: {},
        type: 'post',
        success: function (res) {
          ladda.stop();
          THIS.selectedInvoice.length = 0;
          (<any>$('#EmptyTrashModal')).modal('hide');
          toastr.success('Recurring invoice has been permanently deleted', 'Recurring Invoice Deleted', this.toastrOption);
          THIS.getRecurringInvoices();
          // Pusher emit
        }
    });
}
emptyThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
            ___selectedTab = i;
        }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#EmptyTrashModal')).modal('show');
}

  echoRepeatDays(repeatDaysStr) {
    let repeatDays = repeatDaysStr.split(',');
    let days = '';
    $.each(repeatDays, function (i, v) {
      if (i == '0') {
        days += v;
      } else {
        days += ', ' + v;
      }
    });
    days = days.replace(/,([^,]*)$/, ' and $1');
    return days;
  }

  isRecurExpire(invoice) {
    if (invoice.dates.expire != undefined && invoice.dates.expire != null) {
      if (new Date() > new Date(invoice.dates.expire)) {
        return true;
      }
      if (new Date(invoice.nextCreation) > new Date(invoice.dates.expire)) {
        return true;
      }
      if (invoice.status == 2) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  checkThisRow(e, invoice) {
    e.preventDefault();

    let index = this.selectedInvoice.indexOf(invoice.id);
    if (index > -1) {
      if (this.selectedInvoice.length == 1) {
        this.selectedInvoice.length = 0;
      } else {
        this.selectedInvoice.splice(index, 1);
      }
    } else {
      this.selectedInvoice.push(invoice.id);
    }
    $('.drop-menu-right').removeClass('open');

  }

  selectThisRow(e, invoice) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.which == 1) {
        let index = this.selectedInvoice.indexOf(invoice.id);
        if (index > -1) {
          this.selectedInvoice.splice(index, 1);
        } else {
          this.selectedInvoice.push(invoice.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    }

  }

  removeInvoice = {
    submitted: 0
  };
  prevPage = false;
  removeThisQInvoice(id) {
    this.selectedInvoice.length = 0;
    this.selectedInvoice = [id];
    this.prevPage = true;
    this.deleteThisWorkOrder();
  }

  removeThisInvoice() {
    const ldd = document.getElementById('removeWorkOrderBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    if (this.selectedInvoice.length > 0) {
      let THIS = this;
      let ids = THIS.selectedInvoice;
      THIS.invoicesService.removeRecurringInvoice(ids).subscribe(res => {
        ladda.stop()
        THIS.getRecurringInvoices();
        THIS.selectedInvoice = [];
        // THIS.removeUndoThisInvoice(ids);
        (<any>$('#deleteWorkOrderModal')).modal('hide');
        toastr.success('Recurring invoice has been deleted successfully', 'Recurring Invoice Deleted', this.toastrOption);
        if(this.prevPage == true){
          this.cancelSingleView(0);
          this.getRecurringInvoices();
          this.prevPage = false;
        }
        // Pusher emit
      });
    }
  }

  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }

  removeUndoThisInvoice(ids) {
    const ldd = document.getElementById('udltBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    THIS.invoicesService.removeUndoRecurringInvoice(ids).subscribe(res => {
      ladda.stop()
      THIS.getRecurringInvoices();
      THIS.selectedInvoice.length = 0;
      (<any>$('#RestoreModal')).modal('hide');
      toastr.success('Recurring invoice has been restored successfully', 'Recurring Invoice Restore', this.toastrOption);

      // Pusher emit
    });
  }
  restoreThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#RestoreModal')).modal('show');
  }

  archiveThisInvoice() {
    if (this.selectedInvoice.length > 0) {
      const ldd = document.getElementById('arcBtn');
      const ladda = Ladda.create(ldd);
      ladda.start();
      let THIS = this;
      let ids = THIS.selectedInvoice;
      THIS.invoicesService.archiveRecurringInvoice(ids).subscribe(res => {
        ladda.stop();
        THIS.getRecurringInvoices();
        // THIS.archiveUndoThisInvoice(ids);
        THIS.selectedInvoice.length = 0;
        (<any>$('#ArchiveModal')).modal('hide');
        toastr.success('Recurring invoice has been archived successfully', 'Recurring Invoice Archive', this.toastrOption);

        // Pusher emit
      });
    }
  }

  archiveThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#ArchiveModal')).modal('show');
  }

  archiveUndoThisInvoice(ids) {
    const ldd = document.getElementById('uarcBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    this.invoicesService.removeUndoRecurringInvoice(ids).subscribe(res => {
      ladda.stop();
      this.getRecurringInvoices();
      // Pusher emit
      (<any>$('#UnarchiveModal')).modal('hide');
      toastr.success('Recurring invoice has been unarchived successfully', 'Recurring Invoice Unarchived', this.toastrOption);
    });
  }
  unarchiveThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#UnarchiveModal')).modal('show');
  }

  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_invRc_'+Session.team, function (v) {
        THIS.getRecurringInvoices();
    });*/
  }

  ngOnDestroy() {
  }

}
