function makeAppConfig() {
  const date = new Date();
  const year = date.getFullYear();
  const AppConfig = {
    brand: 'Equidesk',
    user: 'Lisa',
    year,
    layoutBoxed: false,               // true, false
    navCollapsed: false,              // true, false
    navBehind: false,                 // true, false
    fixedHeader: true,                // true, false
    sidebarWidth: 'middle',           // small, middle, large
    theme: 'light',                   // light, gray, dark
    colorOption: '31',                // 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    AutoCloseMobileNav: true,         // true, false. Automatically close sidenav on route change (Mobile only)
    PUSHER_KEY: '501b3b237bd799758df0',
    // @ts-ignore
 /*   productLink: 'http://equidesk.com',
    FrontAppURL: 'http://localhost:4200',
    appUrl: 'http://equidesk-portal-api.test',
    apiRoot: 'http://equidesk-portal-api.test/api/',
    api: 'http://equidesk-portal-api.test/api/bm/',
    apiIncRecurring: 'http://equidesk-portal-api.test/api/bm/work-orders-recurring/',
    apiInc: 'http://equidesk-portal-api.test/api/inspections/',
    apiAsset: 'http://equidesk-portal-api.test/api/assets/',
    apiMsg: 'http://equidesk-portal-api.test/api/messages/',
    apiAuth: 'http://equidesk-portal-api.test/api/auth/',
    apiMedia: 'http://equidesk-portal-api.test/api/auth/',
    uploadPath: 'http://equidesk-portal-api.test/uploads',
    DefaultMediaPath: 'http://equidesk-portal-api.test/defaults',
    uploadPreview: 'http://equidesk-portal-api.test/api/auth/preview/upload',
    shortLink: 'http://eq.link',*/
    // @ts-ignore
    // Production Config
    productLink: 'https://equidesk.com',
    FrontAppURL: 'https://portal.equidesk.io',
    // FrontAppURL: 'http://localhost:4200',
    appUrl: 'https://api.equidesk.io' ,
    apiRoot: 'https://api.equidesk.io/api/',
    api: 'https://api.equidesk.io/api/bm/',
    apiIncRecurring: 'https://api.equidesk.io/api/bm/work-orders-recurring/',
    apiInc: 'https://api.equidesk.io/api/inspections/',
    apiAsset: 'https://api.equidesk.io/api/assets/',
    apiMsg: 'https://api.equidesk.io/api/messages/',
    apiAuth: 'https://api.equidesk.io/api/auth/',
    apiMedia: 'https://api.equidesk.io/api/auth/',
    uploadPath: 'https://api.equidesk.io/uploads',
    DefaultMediaPath: 'https://api.equidesk.io/defaults',
    uploadPreview: 'https://api.equidesk.io/api/auth/preview/upload',
    shortLink: 'https://eq.link'
  };

  return AppConfig;
}

export const APPCONFIG = makeAppConfig();
