import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { APPCONFIG } from '../config';

import { AssetModel } from '../_models/asset';

import { HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";

@Injectable()
export class AssetService {

    public token: string;
    assets: Array<AssetModel> = [];

    constructor(private http: HttpClient,private router: Router) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }

    getAssets() {
        this.http
            .get<any>(`${APPCONFIG.api}assets?token=`+this.token)
            .pipe(map(res => {
                return res.data;
            }))
            .subscribe(assets => {
                this.assets = [];
                assets.forEach(asset => {
                    this.assets.push(new AssetModel(
                        asset.id,
                        asset.name,
                        asset.location,
                        asset.category,
                        asset.status,
                        asset.brand,
                        asset.serial
                    ));
                });
            }, error => {
                if (error.status === 401) {
                    localStorage.removeItem('currentUserInfo');
                    this.router.navigate(['/']);
                }
            });
    }

    getAssetAll(): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/get/Asset/allList?token=`+this.token, {})
            .pipe(map(res => {
                return res.data;
            }));
    }
  RecursiveAssets(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}assets/recursive?token=`+this.token, {})
            .pipe(map(res => {
                return res.data;
            }));
    }
    getAssetList(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}assets/getAll?token=`+this.token, {})
            .pipe(map(res => {
                return res.data;
            }));
    }
    getLatestId(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}assets/getLastId?token=`+this.token)
            .pipe(map(res => {
                return res.asset_id;
            }));
    }
    getAssetCatList(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}assets/category/getAll?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    createAsset(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/create?token=`+this.token,param)
            .pipe(map(res => {
                return res;
            }));
    }
    editAsset(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/edit?token=`+this.token,param)
            .pipe(map(res => {
                return res;
            }));
    }
    createLot(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/create/lot?token=`+this.token,param)
            .pipe(map(res => {
                return res;
            }));
    }


    createAssetCategory(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/category/create?token=`+this.token,param)
            .pipe(map(res => {
                return res;
            }));
    }
    editAssetCategory(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/category/edit?token=`+this.token,param)
            .pipe(map(res => {
                return res;
            }));
    }
    getAssetCategory(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}assets/category/getAll?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }


    // =======================
    // New Updates
    // =======================
    getAssetPaginate(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/getAssetPaginate?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getAssetStatus(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/getAssetStatus?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getAssetSingle(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/get/single?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getLastPreId(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}assets/get/lastPreId?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    removeAssets(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/remove?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    undoRemoveAssets(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/remove/undo?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    archiveAssets(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/archive?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    undoArchiveAssets(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/archive/undo?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getWorkOrder(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}assets/get/work-orders?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }


}
