import { Component, TemplateRef, Input, SimpleChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { InvoicesService } from '../../_services/invoices.service';
import { FacilityService } from '../../_services/facility.service';
import { FcmService } from '../../_services/fcm.service';
import { CustomHelper } from '../../customhelper';
import { APPCONFIG } from '../../config';
declare let toastr: any;
declare let swal: any;
declare let Ladda: any;

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html'
})
export class SelectComponent {
  @Input() ListData: any = [];
  @Input() tree: any = false;
  @Output() OnSelect = new EventEmitter();
  keyword: any = '';
  SelectedData: any = {
    title: '',
    value: ''
  };
  OptionsData: any = [];

  constructor(private invoicesService: InvoicesService, private facilityService: FacilityService, private fcmService: FcmService,
              private modalService: BsModalService) {

  }
  toggleDropdown(e: any) {
    this.OptionsData = this.ListData;
   const trigger = $(e.target).closest('.custom-select-box').find('.select-options');
    if ($(trigger).hasClass('active')) {
      $(trigger).removeClass('active');
    } else {
      $(trigger).addClass('active');
    }
  }
  SelectedOption(data: any) {
    if (data.id !== '000') {
      this.SelectedData = {
        title: data.name,
        value: data.id
      };
    }
    this.OnSelect.emit({id: data.id});
    $('.select-options').removeClass('active');
  }
  formInit() {
    let THIS = this;
    setTimeout( () => {
      this.OptionsData = this.ListData;
    }, 1000 );
    $(document).mouseup(function (e: any) {
      const container = $('.outsideClick');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass('active');
      }
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit(): void {
    this.formInit();
  }
   Search () {
    const SearchData: any = [];
      setTimeout(() => {
      $.each(this.ListData,  (i: any, v: any) => {
        let item = null;
        if (v.name.match(new RegExp(this.keyword, 'gi'))) {
          item = ({name: v.name});
        }
        if (v.child !== undefined) {
          $.each(v.child,  (u: any, vv: any) => {
            if (vv.name.match(new RegExp(this.keyword, 'gi'))) {
              if (item == null) {
                item = ({name: vv.name});
              } else if (item.child === undefined) {
                item.child = [];
                item.child.push({name: vv.name});
              } else {
                item.child.push({name: vv.name});
              }
            }
            if (vv.child !== undefined) {
              const parentLess = [];
              $.each(vv.child, (uu: any, vvv: any) => {
                if (vvv.name.match(new RegExp(this.keyword, 'gi'))) {
                  if (item == null) {
                    item = ({name: vvv.name});
                  } else if (item.child === undefined) {
                    item.child = [];
                    item.child.push({name: vvv.name});
                  } else if (item.child[u] === undefined) {
                    parentLess.push({name: vvv.name});
                    if (parentLess.length === vv.child.length) {
                      item.child = item.child.concat(parentLess);
                    }
                  } else if (item.child[u].child === undefined) {
                    item.child[u].child = [];
                    item.child[u].child.push({name: vvv.name});
                  } else {
                    item.child[u].child.push({name: vvv.name});
                  }
                }
              });
            }
          });
        }
        if (item !== null) {
          SearchData.push(item);
        }
      });
      setTimeout(() => {
        this.OptionsData = SearchData;
      }, 200);
    }, 500);
  }
}
