export class AssetModel {

    id: number;
    name: string;
    location: string;
    category: number;
    status: number;
    brand: string;
    serial: string;

    constructor(
        id: number,
        name: string,
        location: string,
        category: number,
        status: number,
        brand: string,
        serial: string
    ) {
        this.id = id;
        this.name = name;
        this.location = location;
        this.category = category;
        this.status = status;
        this.brand = brand;
        this.serial = serial;
    }
}
/**

   "id": 1,
   "name": "New Category 911",
   "location": "Location 171",
   "category_id": 1,
   "status": {
   "code": 0,
   "description": "online"
   },
   "brand": null,
   "model": null,
   "serial_number": null,
   "purchase": {
   "date": null,
   "cost": 0
   },
   "depreciation": {
   "cost": 0,
   "end_of_life_value": 0,
   "method": 0,
   "asset_life": 0
   },
   "contract": {
   "personnel_id": 0,
   "starts": null,
   "ends": null,
   "notify": {
   "code": 0,
   "description": "2 weeks"
   }
   }**/
