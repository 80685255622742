import {Component} from '@angular/core';

import {Job_typesService} from '../../../_services/job_types.service';
import {Job_statusService} from '../../../_services/job_status.service';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
declare let toastr: any;
declare let swal: any;

@Component({
    selector: 'work-order-settings',
    templateUrl: './work-order-settings.component.html'
})
export class WorkOrderSettingsComponent {
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;


    currentUser;
    constructor(private job_typesService: Job_typesService,
                private job_statusService: Job_statusService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.getAllJobTypes();
        this.getAllJobStatus();
        this.getPriorities();
        this.sortOptions.onUpdate = function () {
            THIS.updateJobTypeSort();
        };
        this.sortStatusOptions.onUpdate = function () {
            THIS.updateJobStatusSort();
        };
        this.hours_f();
    }

    JobTypeLoading: boolean;
    createJT = 0;
    jobTypes = [];
    createJobType = {
        name : '',
        submitted : 0
    };
    editJobTypeForm = {
        id : 0,
        name : '',
        submitted : 0
    };
    removeJob = 0;
    getAllJobTypes() {
      this.JobTypeLoading = true;
        this.job_typesService.getJobTypeList().subscribe(res => {
          this.JobTypeLoading = false;
            const rv = [];
            for (let i = 0; i < res.length; i++) {
                res[i]['edit_status'] = 0;
                rv.push(res[i]);
            }
            this.jobTypes = rv;
            this.editJobTypeForm.submitted = 0;
        });
    }
    createNewJobType(){
        let THIS = this;
        if(this.createJobType.name != '' && this.createJobType.submitted == 0){
            this.createJobType.submitted = 1;
            let param = {name:this.createJobType.name};
            this.job_typesService.createJobType(param).subscribe(res => {
                this.createJobType.submitted = 0;
                this.createJobType.name = '';
                this.getAllJobTypes();
                // Pusher emit
            });
        }
    }
    removeJobType(){
        let THIS = this;
        if(this.removeJob > 0){
            let id = this.removeJob;
            swal({
                title: "Delete Job Type",
                text: "Are you sure you want to delete this job type?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    $.ajax({
                        url: THIS.APPCONFIG.api+'job-types/delete/'+id+'?token='+THIS.currentUser.token,
                        type: 'get',
                        success : function (res) {
                            THIS.getAllJobTypes();
                            // Pusher emit
                        }
                    });
                }
            });
        }
    }
    openEditForm(jobType){
        this.editJobTypeForm.id=jobType.id;
        this.editJobTypeForm.name=jobType.name;
        let res = this.jobTypes;
        let rv = [];
        for(let i=0;i<res.length;i++){
            res[i]['edit_status'] = 0;
            if(res[i].id == jobType.id){
                res[i]['edit_status'] = 1;
            }
            rv.push(res[i]);
        }
        this.jobTypes = rv;
    }
    editJobType(){
        let THIS = this;
        if(this.editJobTypeForm.id > 0){
            this.editJobTypeForm.submitted = 1;
            this.job_typesService.editJobType(this.editJobTypeForm).subscribe(res => {
                this.editJobTypeForm.id=0;
                this.editJobTypeForm.name='';
                this.getAllJobTypes();
                // Pusher emit
            });
        }
    }
    sortingUpdating = 0;
    sortOptions = {
        onUpdate: function () {}
    };
    updateJobTypeSort(){
        this.sortingUpdating = 1;
        let items = $('.sortJobType');
        let sortings = [];
        items.each(function (i, v) {
            let id = $(v).attr('id');
            id = id.replace('sortJobType-','');
            let eachSort = {
                id: id,
                sort_id : i+1
            };
            sortings.push(eachSort);
        });
        sortings.length = sortings.length - 1;
        this.job_typesService.sortJobType({sort : sortings}).subscribe(res => {
            let THIS = this;
            toastr.success('Job status has been successfully sorted', 'Job Status Sorting', THIS.toastrOption);
            setTimeout(function () {
                THIS.sortingUpdating = 0;
            }, 500);
            this.getAllJobTypes();
            // Pusher emit
        });
    }

    createJS = 0;
    jobStatus=[];
    createJobStatus = {
        name : '',
        submitted : 0
    };
    editJobStatusForm = {
        id : 0,
        name : '',
        submitted : 0
    };
    removeStatus = 0;
    getAllJobStatus(){
        this.job_statusService.getJobStatusList().subscribe(res => {
            if(res.status == 2000){
                let rv = [];
                for(let i=0;i<res.data.length;i++){
                    res.data[i]['edit_status'] = 0;
                    rv.push(res.data[i]);
                }
                this.jobStatus = rv;
                this.editJobStatusForm.submitted = 0;
            }
        });
    }
    createNewJobStatus(){
        let THIS = this;
        if(this.createJobStatus.name != ''){
            this.createJobStatus.submitted = 1;
            let param = {name:this.createJobStatus.name};
            this.job_statusService.createJobStatus(param).subscribe(res => {
                this.createJobStatus.name = '';
                this.createJobStatus.submitted = 0;
                this.getAllJobStatus();
                // Pusher emit
            });
        }
    }
    removeJobStatus(){
        let THIS = this;
        if(this.removeStatus > 0){
            let id = this.removeStatus;
            swal({
                title: "Delete Job Status",
                text: "Are you sure you want to delete this job status?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    $.ajax({
                        url: THIS.APPCONFIG.api+'job-status/delete/'+id+'?token='+THIS.currentUser.token,
                        type: 'get',
                        success : function (res) {
                            THIS.getAllJobStatus();
                            // Pusher emit
                        }
                    });
                }
            });
        }
    }
    openEditFormStatus(status){
        this.editJobStatusForm.id=status.id;
        this.editJobStatusForm.name=status.name;
        let res = this.jobStatus;
        let rv = [];
        for(let i=0;i<res.length;i++){
            res[i]['edit_status'] = 0;
            if(res[i].id == status.id){
                res[i]['edit_status'] = 1;
            }
            rv.push(res[i]);
        }
        this.jobStatus= rv;
    }
    editJobStatus(){
        let THIS = this;
        if(this.editJobStatusForm.id > 0){
            this.editJobStatusForm.submitted = 1;
            this.job_statusService.editJobStatus(this.editJobStatusForm).subscribe(res => {
                this.getAllJobStatus();
                this.editJobStatusForm.id=0;
                this.editJobStatusForm.name='';
                // Pusher emit
            });
        }
    }
    sortingStatusUpdating = 0;
    sortStatusOptions = {
        onUpdate: function () {}
    };
    updateJobStatusSort(){
        let THIS = this;
        this.sortingStatusUpdating = 1;
        let items = $('.sortJobStatus');
        let sortings = [];
        items.each(function (i, v) {
            let id = $(v).attr('id');
            id = id.replace('sortJobStatus-','');
            let eachSort = {
                id: id,
                sort_id : i+1
            };
            sortings.push(eachSort);
        });
        // sortings.length = sortings.length - 1;

        $.ajax({
            url: THIS.APPCONFIG.api+'job-status/sort?token='+THIS.currentUser.token,
            data: {sort : sortings},
            type: 'post',
            success : function (res) {
                toastr.success('Job status has been successfully sorted', 'Job Status Sorting', THIS.toastrOption);
                setTimeout(function () {
                    THIS.sortingStatusUpdating = 0;
                }, 500);
                THIS.getAllJobTypes();
                // Pusher emit
            }
        });
    }
    hours: any = [];
    hours_f() {
      for (let i = 1; i <= 24; i++) {
        this.hours.push(i);
      }
    }

    workPriority = [];
    getPriorities(){
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api+'priorities?token='+THIS.currentUser.token,
            data: {},
            type: 'get',
            success: function (res) {
                THIS.workPriority = res.data;
            }
        });
    }
    savePriority(index){
        let THIS = this;
        let g = THIS.workPriority[index];
        $.ajax({
            url: THIS.APPCONFIG.api+'priorities/'+g.id+'?token='+THIS.currentUser.token,
            data: g,
            type: 'post',
            success: function (res) {
                THIS.workPriority[index]['editable'] = false;
                toastr.success('Priority has been successfully sorted', 'Priority Update', THIS.toastrOption);
            }
        });
    }

    ngOnInit() {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        /*this.socket.on('_rfr_team_data_jobType_'+Session.team, function (v) {
            THIS.getAllJobTypes();
        });*/
        /*this.socket.on('_rfr_team_data_jobStatus_'+Session.team, function (v) {
            THIS.getAllJobStatus();
        });*/
    }
    ngOnDestroy() {
    }
}
