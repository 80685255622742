import {BrowserModule} from '@angular/platform-browser';
import {NgModule, ApplicationRef} from '@angular/core';
import {HttpModule} from '@angular/http';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatButtonModule, MatCheckboxModule, MatCardModule, MatGridListModule, MatFormFieldModule, MatInputModule,
  MatTabsModule, MatIconModule, MatMenuModule, MatSelectModule, MatSnackBarModule, MatRadioModule, MatTooltipModule,
  MatSlideToggleModule, MatPaginatorModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule,
  MatAutocompleteModule, MatChipsModule
} from '@angular/material';
import {AppRoutingModule} from './app-routing.module';
import {SignaturePadModule} from '@ng-plus/signature-pad';

import * as $ from 'jquery';


import {AppComponent} from './app.component';

// =========================
// helpers
// =========================
import {TokenInterceptor} from './_helpers/token.interceptor';
import {
  Search_panelComponent, SearchPanelStoreRoomDialog, SearchPanelAssetDialog,
  SearchPanelPersonnelDialog
} from './_global_comp/search_panel.component';

// =========================
// Plugin/Packages
// =========================
import {QuillModule} from 'ngx-quill';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ClickOutsideModule} from 'ng-click-outside';
import {SortablejsModule} from 'angular-sortablejs';
import {Ng2FlatpickrModule} from 'ng2-flatpickr';
import {LightboxModule} from 'angular2-lightbox';
import {NgxCoolDialogsModule} from 'ngx-cool-dialogs';
import {QRCodeModule} from 'angular2-qrcode';
import {ProgressHttpModule} from 'angular-progress-http';
import {ChartjsModule} from '@ctrl/ngx-chartjs';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {FilePondModule, registerPlugin} from 'ngx-filepond';
import {NgxCurrencyModule} from 'ngx-currency';

// =========================
// Services
// =========================
import {AuthService} from './_services/auth.service';
import {TabsService} from './_services/tabs.service';
import {FacilityService} from './_services/facility.service';
import {ContactsService} from './_services/contacts.service';
import {AssetService} from './_services/asset.service';
import {WorkOrderService} from './_services/work-orders.service';
import {InventoriesService} from './_services/inventories.service';
import {Job_statusService} from './_services/job_status.service';
import {Job_typesService} from './_services/job_types.service';
import {MediaService} from './_services/media.service';
import {UserService} from './_services/user.service';
import {InvoicesService} from './_services/invoices.service';

import {FcmService} from './_services/fcm.service';
import {ChatService} from './_services/chat.service';

// =========================
// Auth Component
// =========================
import {LoginComponent} from './pages/login/login.component';
import {ForgotComponent} from './pages/login/forgot.component';

import {ResetPassComponent} from './pages/login/resetPass.component';
import {RegisterComponent} from './pages/login/register.component';
import {RegisterSuccessComponent} from './pages/login/registerSuccess.component';
import {RegisterPasswordComponent} from './pages/login/registerPassword.component';
import {RegisterCompanyComponent} from './pages/login/registerCompany.component';
import {RegisterProcessComponent} from './pages/login/registerProcess.component';
import {InvalidPinComponent} from './pages/login/invalidPin.component';

import {PersonActivationComponent} from './pages/login/personActivation.component';
import {TeamActivationComponent} from './pages/login/teamActivation.component';
import {RestoreComponent} from './pages/login/restore.component';

import {RegisterVendorProgram} from './pages/login/vendorProgram';

// =========================
// Layout Component
// =========================
import {LayoutComponent} from './layout/layout.component';
import {AppHeaderComponent} from './layout/header/header.component';
import {AppSidenavComponent} from './layout/sidenav/sidenav.component';
import {AppSidenavMenuComponent} from './layout/sidenav/sidenav-menu/sidenav-menu.component';
import {SidenavMailMenuComponent} from './layout/sidenav/sidenav-menu/sidenav-mail-menu.component';
import {SidenavContactMenuComponent} from './layout/sidenav/sidenav-menu/sidenav-contact-menu.component';
import {SidenavEquistoreMenuComponent} from './layout/sidenav/sidenav-menu/sidenav-equistore-menu.component';
import {SidenavCalendarMenuComponent} from './layout/sidenav/sidenav-menu/sidenav-calendar-menu.component';
import {ChatComponent} from './layout/chat/chat.component';


import {NotFoundComponent} from './layout/not-found.component';


// =========================
// Dashboard Component
// =========================
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {WelcomeComponent} from './pages/dashboard/welcome.component';
// =========================
// Facility Component
// =========================
import {FacilitiesComponent} from './layout/sidenav/facilities.component';
import {FacilityComponent} from './pages/facility/facility.component';
import {CreateFacilityComponent} from './pages/facility/section/createFacility.component';
import {EditFacilityComponent} from './pages/facility/section/editFacility.component';
import {SingleFacilityComponent} from './pages/facility/section/singleFacility.component';
import {WorkOrdersComponent} from './pages/work-orders/work-orders.component';
// =========================
// Building Management Component
// =========================
import {BmComponent} from './pages/bm/bm.component';
import {CreateBmComponent} from './pages/bm/sections/createBm.component';
import {SingleBmComponent} from './pages/bm/sections/singleBm.component';
// =========================
// Projects Component
// =========================
import {ProjectsComponent} from './pages/projects/projects.component';
import {CreateProjectComponent} from './pages/projects/sections/createProject.component';
import {EditProjectComponent} from './pages/projects/sections/editProject.component';
import {SingleProjectComponent} from './pages/projects/sections/singleProject.component';
// =========================
// Inspection Component
// =========================
import {InspectionComponent} from './pages/inspection/inspection.component';
import {CreateInspectionComponent} from './pages/inspection/sections/createInspection.component';
import {SingleInspectionComponent} from './pages/inspection/sections/singleInspection.component';
import {InspectionOnlineComponent} from './pages/inspection/inspectionOnline.component';
import {InspectionExportComponent} from './pages/inspection/sections/inspection-export.component';
import {InspectionsRecurringComponent} from './pages/inspection/inspections-recurring.component';
import {CreateInspectionRecurringComponent} from './pages/inspection/sections/createInspectionRecurring.component';
import {SinglePreviewInspectionRecurringComponent} from './pages/inspection/sections/singlePreviewInspectionRecurring.component';
// =========================
// Work Order Component
// =========================
import {WorkOrdersBoardComponent} from './pages/work-orders/work-orders-board.component';
import {WorkOrdersRecurringComponent} from './pages/work-orders/work-orders-recurring.component';
import {CreateWorkComponent} from './pages/work-orders/sections/createWork.component';
import {CreateWorkRecurringComponent} from './pages/work-orders/sections/createWorkRecurring.component';
import {SinglePreviewWorkComponent} from './pages/work-orders/sections/singlePreviewWork.component';
import {WorkToInvComponent} from './pages/work-orders/sections/workToInv.component';
import {SinglePreviewWorkRecurringComponent} from './pages/work-orders/sections/singlePreviewWorkRecurring.component';
import {WorkOnlineComponent} from './pages/work-orders/workOnline.component';
import {WorkOrderExportComponent} from './pages/work-orders/sections/work-order-export.component';
import {WorkOrderReportComponent} from './pages/work-orders/work-order-report.component';
// =========================
// Invoice Component
// =========================
import {InvoicesComponent} from './pages/invoices/invoices.component';
import {InvoicesRecurComponent} from './pages/invoices/invoicesRecur.component';
import {CreateEmailComponent} from './pages/invoices/sections/createEmail.component';
import {CreateInvoiceComponent} from './pages/invoices/sections/createInvoice.component';
import {CreateRecurringComponent} from './pages/invoices/sections/createRecurring.component';
import {EditInvoiceComponent} from './pages/invoices/sections/editInvoice.component';
import {EditRecurringComponent} from './pages/invoices/sections/editRecurring.component';
import {InvoiceOverviewComponent} from './pages/invoices/sections/overview.component';
import {SingleRecurringComponent} from './pages/invoices/sections/singleRecurring.component';
// =========================
// RCTI Component
// =========================
import {SpiComponent} from './pages/supplier-invoice/spi.component';
import {RctiComponent} from './pages/rcti/rcti.component';
import {CreateRctiComponent} from './pages/rcti/sections/createRcti.component';
import {EditRctiComponent} from './pages/rcti/sections/editRcti.component';
// =========================
// Assets Component
// =========================
import {AssetsComponent} from './pages/assets/assets.component';
import {AssetCreateComponent} from './pages/assets/sections/asset-create.component';
import {AssetSingleComponent} from './pages/assets/sections/asset-single.component';
import {AssetOnlineComponent} from './pages/assets/assetOnline.component';
// =========================
// FM 360 Component
// =========================
import {Fm360Component} from './pages/fm360/fm360.component';
import {CreateFmComponent} from './pages/fm360/sections/createFm.component';
import {PreviewFmComponent} from './pages/fm360/sections/previewFm.component';
import {EditFmComponent} from './pages/fm360/sections/editFm.component';
// =========================
// Personnel Component
// =========================
import {PersonnelComponent} from './pages/personnel/personnel.component';
import {PersonnelCreateComponent} from './pages/personnel/section/personnel-create.component';
import {GroupCreateComponent} from './pages/personnel/section/group-create.component';
import {GroupSingleComponent} from './pages/personnel/section/group-single.component';
import {PersonnelSingleComponent} from './pages/personnel/section/personnel-single.component';

// =========================
// Equidesk verified vendors
// =========================
import {VerifiedVendorsComponent} from './pages/verified-vendors/verified-vendors.component';
import {ViewVerifiedVendorComponent} from './pages/verified-vendors/sections/view-verified-vendor.component';

// =========================
// Inventories Component
// =========================
import {InventoriesComponent, InventoryTopListUpDialog} from './pages/inventories/inventories.component';
import {InventoryCreateComponent} from './pages/inventories/sections/inventory-create.component';
import {InventorySingleComponent, inventoryTopUpDialog} from './pages/inventories/sections/inventory-single.component';
// =========================
// Profile Component
// =========================
import {ProfileComponent} from './pages/profile/profile.component';
import {CompanyProfileComponent} from './pages/companyProfile/company-profile.component';
import {ProToWorkComponent} from './pages/profile/sections/proToWork.component';
import {AccountComponent} from './pages/account/account.component';
import {AccUsersComponent} from './pages/account/sections/accUsers.component';
import {AccComponent} from './pages/account/sections/acc.component';
import {AccBillingHistoryComponent} from './pages/account/sections/accBillingHistory.component';
import {AccCardsComponent} from './pages/account/sections/accCards.component';
// =========================
// Mail Box Component
// =========================
import {InboxComponent} from './pages/mails/inbox.component';
import {SentComponent} from './pages/mails/sent.component';
import {ComposeComponent} from './pages/mails/sections/compose.component';
import {ReplyComponent} from './pages/mails/sections/reply.component';
import {ForwardComponent} from './pages/mails/sections/forward.component';
import {Mail_single_previewComponent} from './pages/mails/sections/mail_single_preview.component';

// =========================
// Settings Component
// =========================
import {SettingsComponent} from './pages/settings/settings.component';
import {AssetsSettingsComponent} from './pages/settings/sections/assets-settings.component';
import {WorkOrderSettingsComponent} from './pages/settings/sections/work-order-settings.component';
import {LayoutSettingComponent} from './pages/settings/sections/layout-setting.component';
import {NotificationSettingComponent} from './pages/settings/sections/notification-setting.component';
import {MenuSettingComponent} from './pages/settings/sections/menu-setting.component';

// =========================
// Equistore Component
// =========================
import {EquistoreComponent} from './pages/equistore/equistore.component';
import {IntroComponent} from './pages/intro/intro.component';

// =========================
// Contact + SOR Component
// =========================
import {ContactComponent} from './pages/contact/contact.component';
import {KeysComponent} from './pages/keys/keys.component';


// =========================
// library Component
// =========================
import {LibraryComponent} from './pages/library/library.component';
import {LibrarySingleComponent} from './pages/library/sections/library-single.component';
// =========================
// Calendar
// =========================
import {FullCalendarComponent} from './pages/calendar/calendar.component';
import {SelectComponent} from './pages/select/select.component';
import {HiveComponent} from './pages/hive/hive.component';
import {OperationRadiusComponent} from './components/operation-radius/operation-radius.component';
import {CategorySelectComponent} from './components/app-category-select/category-select.component';
import {AppToggleSwitchComponent} from './components/app-toggle-switch/app-toggle-switch.component';




@NgModule({
  imports: [
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    BrowserModule, BrowserAnimationsModule,

    QuillModule,
    ModalModule.forRoot(),
    ClickOutsideModule,
    SortablejsModule.forRoot({animation: 150}),
    Ng2FlatpickrModule,
    LightboxModule,
    NgxCoolDialogsModule.forRoot(),
    QRCodeModule,
    ProgressHttpModule,
    ChartjsModule,
    GooglePlaceModule,
    FilePondModule,
    NgxCurrencyModule,
    SignaturePadModule,

    MatButtonModule, MatCheckboxModule, MatCardModule, MatGridListModule, MatFormFieldModule, MatInputModule,
    MatTabsModule, MatIconModule, MatMenuModule, MatSelectModule, MatSnackBarModule, MatRadioModule,
    MatTooltipModule, MatSlideToggleModule, MatPaginatorModule, MatDatepickerModule, MatNativeDateModule,
    MatDialogModule, MatAutocompleteModule, ReactiveFormsModule, MatChipsModule,


  ],
  declarations: [
    AppComponent,

    // =========================
    // Helper Component
    // =========================
    Search_panelComponent, SearchPanelStoreRoomDialog, SearchPanelAssetDialog,
    SearchPanelPersonnelDialog,
    OperationRadiusComponent,
    CategorySelectComponent,
    AppToggleSwitchComponent,

    // =========================
    // Auth Component
    // =========================
    LoginComponent, RegisterComponent, RegisterSuccessComponent, RegisterPasswordComponent, RegisterCompanyComponent,
    RegisterProcessComponent,
    InvalidPinComponent,
    ForgotComponent, ResetPassComponent, RegisterVendorProgram,
    PersonActivationComponent, TeamActivationComponent,
    RestoreComponent,

    // =========================
    // layout Component
    // =========================
    LayoutComponent,
    AppHeaderComponent,
    AppSidenavComponent, AppSidenavMenuComponent, SidenavMailMenuComponent, SidenavEquistoreMenuComponent,
    SidenavContactMenuComponent, SidenavCalendarMenuComponent,
    ChatComponent,

    NotFoundComponent,

    // =========================
    // Dashboard Component
    // =========================
    DashboardComponent, WelcomeComponent,

    // =========================
    // Facility Component
    // =========================
    FacilitiesComponent,
    FacilityComponent, CreateFacilityComponent, EditFacilityComponent, SingleFacilityComponent,

    // =========================
    // BM Component
    // =========================
    BmComponent, CreateBmComponent, SingleBmComponent,

    // =========================
    // Projects Component
    // =========================
    ProjectsComponent, CreateProjectComponent, EditProjectComponent, SingleProjectComponent,

    // =========================
    // Inspection Component
    // =========================
    InspectionComponent, CreateInspectionComponent, SingleInspectionComponent,
    InspectionOnlineComponent, InspectionExportComponent,
    InspectionsRecurringComponent, CreateInspectionRecurringComponent, SinglePreviewInspectionRecurringComponent,

    // =========================
    // Work Order Component
    // =========================
    WorkOrdersComponent, WorkOrdersRecurringComponent,
    WorkOrdersBoardComponent,
    CreateWorkComponent, CreateWorkRecurringComponent,
    SinglePreviewWorkComponent,
    WorkToInvComponent,
    SinglePreviewWorkRecurringComponent,
    WorkOnlineComponent, WorkOrderExportComponent,
    WorkOrderReportComponent,

    // =========================
    // Invoice Component
    // =========================
    InvoicesComponent, InvoicesRecurComponent, CreateEmailComponent, CreateInvoiceComponent,
    CreateRecurringComponent, EditInvoiceComponent, EditRecurringComponent,
    InvoiceOverviewComponent, SingleRecurringComponent,

    // =========================
    // RCTI Component
    // =========================
    SpiComponent,
    RctiComponent, CreateRctiComponent, EditRctiComponent,

    // =========================
    // FM 360 Component
    // =========================
    Fm360Component, CreateFmComponent, PreviewFmComponent, EditFmComponent,

    // =========================
    // Hive Component
    // =========================
    HiveComponent,

    // =========================
    // Assets Component
    // =========================
    AssetsComponent,
    AssetCreateComponent,
    AssetSingleComponent,
    AssetOnlineComponent,

    // =========================
    // Personnel Component
    // =========================
    PersonnelComponent, PersonnelCreateComponent, GroupCreateComponent, GroupSingleComponent,
    PersonnelSingleComponent,

    // =========================
    // Verified vendors Component
    // =========================
    VerifiedVendorsComponent,
    ViewVerifiedVendorComponent,

    // =========================
    // Inventories Component
    // =========================
    InventoriesComponent, InventoryTopListUpDialog,
    InventoryCreateComponent,
    InventorySingleComponent, inventoryTopUpDialog,

    // =========================
    // Profile Component
    // =========================
    ProfileComponent, ProToWorkComponent,
    AccountComponent,
    AccUsersComponent, AccComponent, AccBillingHistoryComponent, AccCardsComponent,

    // =========================
    // Company Profile Component
    // =========================
    CompanyProfileComponent,

    // =========================
    // Mail Box Component
    // =========================
    InboxComponent, SentComponent, ComposeComponent, ReplyComponent, ForwardComponent,
    Mail_single_previewComponent,

    // =========================
    // Settings Component
    // =========================
    SettingsComponent, AssetsSettingsComponent, WorkOrderSettingsComponent, LayoutSettingComponent, NotificationSettingComponent,
    MenuSettingComponent,

    // =========================
    // Equistore Component
    // =========================
    EquistoreComponent, IntroComponent,

    // =========================
    // Contact + SOR Component
    // =========================
    ContactComponent,
    KeysComponent,

    // =========================
    // Calender
    // =========================
    FullCalendarComponent,

    // =========================
    // Library Component
    // =========================
    LibraryComponent,
    LibrarySingleComponent,


    // =========================
    // Select
    // =========================
    SelectComponent,
  ],
  entryComponents: [
    AppComponent,

    // =========================
    // Helper Component
    // =========================
    Search_panelComponent, SearchPanelStoreRoomDialog, SearchPanelAssetDialog,
    SearchPanelPersonnelDialog,

    // =========================
    // Auth Component
    // =========================
    LoginComponent, RegisterComponent, RegisterSuccessComponent, RegisterPasswordComponent, RegisterCompanyComponent,
    RegisterProcessComponent,
    InvalidPinComponent,
    ForgotComponent, ResetPassComponent, RegisterVendorProgram,
    PersonActivationComponent, TeamActivationComponent,
    RestoreComponent,

    // =========================
    // layout Component
    // =========================
    LayoutComponent,
    AppHeaderComponent,
    AppSidenavComponent, AppSidenavMenuComponent, SidenavMailMenuComponent, SidenavEquistoreMenuComponent,
    SidenavContactMenuComponent, SidenavCalendarMenuComponent,
    ChatComponent,

    NotFoundComponent,

    // =========================
    // Dashboard Component
    // =========================
    DashboardComponent, WelcomeComponent,

    // =========================
    // Facility Component
    // =========================
    FacilitiesComponent,
    FacilityComponent, CreateFacilityComponent, EditFacilityComponent, SingleFacilityComponent,

    // =========================
    // BM Component
    // =========================
    BmComponent, CreateBmComponent, SingleBmComponent,

    // =========================
    // Projects Component
    // =========================
    ProjectsComponent, CreateProjectComponent, EditProjectComponent, SingleProjectComponent,

    // =========================
    // Inspection Component
    // =========================
    InspectionComponent, CreateInspectionComponent, SingleInspectionComponent,
    InspectionOnlineComponent, InspectionExportComponent,
    InspectionsRecurringComponent, CreateInspectionRecurringComponent, SinglePreviewInspectionRecurringComponent,

    // =========================
    // Work Order Component
    // =========================
    WorkOrdersComponent, WorkOrdersRecurringComponent,
    WorkOrdersBoardComponent,
    CreateWorkComponent, CreateWorkRecurringComponent,
    SinglePreviewWorkComponent,
    SinglePreviewWorkRecurringComponent,
    WorkOnlineComponent, WorkOrderExportComponent,
    WorkOrderReportComponent,

    // =========================
    // Invoice Component
    // =========================
    InvoicesComponent, InvoicesRecurComponent, CreateEmailComponent, CreateInvoiceComponent,
    CreateRecurringComponent, EditInvoiceComponent, EditRecurringComponent,
    InvoiceOverviewComponent, SingleRecurringComponent,

    // =========================
    // RCTI Component
    // =========================
    SpiComponent,
    RctiComponent, CreateRctiComponent, EditRctiComponent,

    // =========================
    // FM 360 Component
    // =========================
    Fm360Component, CreateFmComponent, PreviewFmComponent, EditFmComponent,

    // =========================
    // Hide Component
    // =========================
    HiveComponent,

    // =========================
    // Assets Component
    // =========================
    AssetsComponent,
    AssetCreateComponent,
    AssetSingleComponent,
    AssetOnlineComponent,


    // =========================
    // Personnel Component
    // =========================
    PersonnelComponent, PersonnelCreateComponent, GroupCreateComponent, GroupSingleComponent,
    PersonnelSingleComponent,

    // =========================
    // Inventories Component
    // =========================
    InventoriesComponent, InventoryTopListUpDialog,
    InventoryCreateComponent,
    InventorySingleComponent, inventoryTopUpDialog,

    // =========================
    // Profile Component
    // =========================
    ProfileComponent, ProToWorkComponent,
    AccountComponent,
    AccUsersComponent, AccComponent, AccBillingHistoryComponent, AccCardsComponent,

    // =========================
    // Company Profile Component
    // =========================
    CompanyProfileComponent,
    // =========================
    // Mail Box Component
    // =========================
    InboxComponent, SentComponent, ComposeComponent, ReplyComponent, ForwardComponent,
    Mail_single_previewComponent,

    // =========================
    // Settings Component
    // =========================
    SettingsComponent, AssetsSettingsComponent, WorkOrderSettingsComponent, LayoutSettingComponent, NotificationSettingComponent,
    MenuSettingComponent,

    // =========================
    // Equistore Component
    // =========================
    EquistoreComponent, IntroComponent,

    // =========================
    // Contact + SOR Component
    // =========================
    ContactComponent,
    KeysComponent,

    // =========================
    // Calender
    // =========================
    FullCalendarComponent,

    // =========================
    // Library Component
    // =========================
    LibraryComponent,
    LibrarySingleComponent,

    // =========================
    // Verified vendors Component
    // =========================
    VerifiedVendorsComponent,
    ViewVerifiedVendorComponent,

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },

    AuthService,
    TabsService,
    FacilityService,
    ContactsService,
    AssetService,
    WorkOrderService,
    InventoriesService,
    Job_typesService,
    Job_statusService,
    MediaService,
    UserService,
    InvoicesService,
    FcmService,
    ChatService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
