import { Component, TemplateRef, Input, SimpleChanges, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { InvoicesService } from '../../_services/invoices.service';
import { FacilityService } from '../../_services/facility.service';
import { CustomHelper } from '../../customhelper';
import { APPCONFIG } from '../../config';
declare let toastr: any;
declare let swal: any;

@Component({
    selector: 'page-spi',
    templateUrl: './spi.component.html'
})
export class SpiComponent {
    APPCONFIG = APPCONFIG;
    CustomHelper = CustomHelper;
    currentUser;
    Permission = -1;


    ROUTES = '/spi';
    constructor(private invoicesService: InvoicesService,
                private facilityService: FacilityService) {

        let THIS = this;

        setTimeout(function () {

            if(THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] != undefined){
                window.history.pushState("","", THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute]);
            }

            THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
            // Pagination
            THIS.currentUser.pagination != null ? THIS.perPage = THIS.currentUser.pagination.invSi : '';
            // 1 = Default, 2 = Comfortable, 3 = Compact
            THIS.currentUser.list_view.invSi.d == 1 ?  THIS.Density = 1 : '';
            THIS.currentUser.list_view.invSi.cf == 1 ?  THIS.Density = 2 : '';
            THIS.currentUser.list_view.invSi.cp == 1 ?  THIS.Density = 3 : '';

            THIS.Permission = THIS.currentUser.user_type > 2 ? THIS.currentUser.modules.invoices : 1;
            THIS.facilityService.currentSelectedFac.subscribe(value => THIS.getInvoices());
            THIS.routeMake();

        }, 500);
    }
    routeMake(){
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/'+Session.team+this.ROUTES;
        window.history.pushState("","", url);
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    }


    totalInvoice = 0;
    pageNo = 1;
    perPage = '10';
    pageEvent;
    paginateList(){
        if(this.pageEvent.pageSize == this.perPage){
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex+1;
            this.getInvoices();
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
            this.getInvoices();
        }
    }
    searchKeyword = '';
    searchKeywordClear(e) {
        this.searchKeyword = '';
    }
    invoices = [];
    previewList = 1;
    order_by = 'desc';
    getInvoices() {
        this.previewList = 0;
        let param = {
            id : 0,
            rcti : 1,
            pageNo : this.pageNo,
            perPage : this.perPage,
            sort_by : this.sort_by,
            order_by : this.order_by,
            keyword : this.searchKeyword,
        };
        localStorage.perPageSI = this.perPage;
        localStorage.sort_bySI = this.sort_by;
        localStorage.order_bySI = this.order_by;
        this.invoices.length = 0;
        this.invoicesService.getSupplierInvoiceList(param).subscribe(res => {
            this.previewList = 1;
            if(res.status == 2000 && res.data.length > 0){
                this.totalInvoice = res.total;
                let THIS = this;
                let DATA = [];
                $.each(res.data, function (i, v) {
                    v['created_at'] = THIS.CustomHelper.formatDate(v['created_at']);
                    DATA.push(v);
                });
                this.invoices = DATA;
            } else {
                this.invoices.length = 0;
                this.totalInvoice = 0;
            }
        });
    }
    filter(): void {
        let THIS = this;
        clearInterval(THIS.CustomHelper.woListSearch);
        THIS.CustomHelper.woListSearch = setInterval(function() {
            clearInterval(THIS.CustomHelper.woListSearch);
            THIS.getInvoices();
        }, 400);
    }
    highlighter(subject){
        if(subject == '' || subject == null){
            return subject;
        } else {
            subject = this.CustomHelper.makeStr(subject);
        }
        let matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
        let res = '';
        $.each(matches, function (i, v) {
            res = subject.replace(v, "<span class='text-warning-highlight'>" + v + "</span>");
        });
        if (res == '') {
            res = subject;
        }
        return res;
    }
    selectAllCheck(){
        let THIS = this;
        let WO = this.invoices;
        if(this.selectedInvoice.length == this.invoices.length){
            this.selectedInvoice.length = 0;
        } else {
            this.selectedInvoice.length = 0;
            $.each(WO, function(i, v){
                THIS.selectedInvoice.push(v.id);
            });
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }
    FilterOn = 0;
    FilterScroll = 246;
    ToggleFilter(){
        this.FilterOn = this.FilterOn == 1 ? 0 : 1;
        if(this.FilterOn == 1){
            this.FilterScroll = 280;
        } else {
            this.FilterScroll = 246;
        }
    }
    SortTitle = 'Created Date';
    sort_by = 'created_at';
    SortOptions = [
        {title:'Uploaded', slug:'wo_incoming_invoices_list.created_at'},
        {title:'Status', slug:'wo_incoming_invoices_list.status'},
        {title:'Customer Invoice ID', slug:'wo_incoming_invoices_list.invoice_number'},
        {title:'Suppliers', slug:'personnel_groups.name'},
        {title:'Work Order ID', slug:'work_orders.uid'},
        {title:'Facility', slug:'facilities.name'},
        {title:'Amount', slug:'wo_incoming_invoices_list.invoice_amount'},
        {title:'Due Date', slug:'due'},
    ];
    Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
    changeDensity(density){
        let THIS = this;
        this.Density = density;
        localStorage.DensityInv = this.Density;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
        let param = {invSi : ''};
        density == 1 ? param.invSi = 'd' : '';
        density == 2 ? param.invSi = 'cf' : '';
        density == 3 ? param.invSi = 'cp' : '';
        $.ajax({
            url : THIS.APPCONFIG.apiAuth+'me/density?token='+THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function(res){
            }
        });
    }



    removeThisInvoice() {
        if(this.selectedInvoice.length > 0){
            let ids = this.selectedInvoice;
            swal({
                title: "Delete Invoice",
                text: "Are you sure you want to delete this supplier invoice?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(res => {
                if (res) {
                    this.invoicesService.removeSpiInvoice(ids).subscribe(res => {
                        swal({
                            title: "Delete Invoice",
                            text: "Invoice(s) have been deleted successfully",
                            icon: "success",
                            dangerMode: true,
                        }).then(res => {
                            this.getInvoices();
                        });
                    });
                }
            });
        }
    }
    statusChecking = 0;
    changeStatus(status) {
        let param = {
            status : status,
            ids : [this.singleInvoice.id]
        };

        let id = this.singleInvoice.id;
        let index = this.invoices.map(function(e) { return e.id; }).indexOf(id);
        this.statusChecking = 1;
        this.invoicesService.statusSpiInvoice(param).subscribe(res => {
            this.singleInvoice.status = status;
            this.invoices[index].status = status;
            this.statusChecking = 0;
        });
    }
    previewAttachFiles(){

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
        let child = ___selectedTab+1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
        (<any>$('#previewFileModal')).modal('show');
        this.getIframe(this.singleInvoice.files[0].file_path);
    }
    getIframe(file_path){
        setTimeout(function () {
            let iframe = '<iframe width="100%" height="500px" src="https://docs.google.com/gview?url='+APPCONFIG.uploadPath+'/attachments/'+file_path+'&embedded=true" frameborder="0"></iframe>';
            $('#attachPreview').html(iframe);
        }, 1000);
    }




    selectedInvoice = [];
    singleInvoice = null;
    checkThisRow(e, invoice) {
        e.preventDefault();
        this.singleInvoice = invoice;
        let index = this.selectedInvoice.indexOf(invoice.id);
        if(index > -1){
            if(this.selectedInvoice.length == 1){
                this.selectedInvoice.length = 0;
            } else {
                this.selectedInvoice.splice(index, 1);
            }
        } else {
            this.selectedInvoice.push(invoice.id);
        }
        $('.drop-menu-right').removeClass('open');

    }
    selectThisRow(e, invoice) {
        e.preventDefault();
        if (e.ctrlKey) {
            if (e.which == 1) {
                let index = this.selectedInvoice.indexOf(invoice.id);
                if (index > -1) {
                    this.selectedInvoice.splice(index, 1);
                } else {
                    this.selectedInvoice.push(invoice.id);
                }
                $('.drop-menu-right').removeClass('open');
            }
        } else {
            this.selectedInvoice.length = 0;
            this.selectedInvoice = [invoice.id];
            this.singleInvoice = invoice;
        }

    }
    rightClickMenu(e,invoice){
        e.preventDefault();
        this.singleInvoice = invoice;

        if(this.selectedInvoice.length < 2){
            this.selectedInvoice.length = 0;
            this.selectedInvoice = [invoice.id];
        }

        let target = $(e.target);
        let w = target.closest('#right-click-holder').width();
        let h = target.closest('#right-click-holder').height();
        let p = target.closest('#right-click-holder').offset();
        let left = e.clientX - p.left;
        let top = e.clientY - p.top;
        let clickH = $('.drop-menu-right').height();
        // clickH = clickH < 150 ? 400 : clickH;
        if(this.selectedInvoice.length > 1){ clickH = 150; }
        if ((w - left) < 230) { left = w - 250; }
        if(h < top+clickH){ top = top - (top+clickH - h); }
        if(top < 10){ top = 10; }

        let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
        if(ttarget.hasClass('open')){
            $('.drop-menu-right').removeClass('open');
            ttarget.css({
                top : top,
                left : left
            });
            setTimeout(function () {
                ttarget.addClass('open');
            }, 100);
        }
        else {
            ttarget.css({
                top : top,
                left : left
            });
            ttarget.addClass('open');
        }
    }
    dropMenuRightToggle(e){
        let container = $('.invoice-list');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.drop-menu-right').removeClass('open');
        }
    }
    currencyIcon():string {    
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
    }

}
