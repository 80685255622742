import {Component, Input} from '@angular/core';

import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {AssetService} from '../../../_services/asset.service';
import {AuthService} from '../../../_services/auth.service';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;

@Component({
    selector: 'notification-setting',
    templateUrl: './notification-setting.component.html'
})
export class NotificationSettingComponent {
    @Input() openSetting: any;
    toastrOption = {timeOut: 2000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true};
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;

    currentUser;

    constructor(private authService: AuthService, protected assetService: AssetService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.getAllSettingsNotification();
    }

    notLoading = 0;
    isLoading = 1;
    NotificationSettings = null;
    NotSettings = null;
    _setting_template_not = 1;

    getAllSettingsNotification() {
        this.isLoading = 1;
        this.authService.getAllSettingsNotification({}).subscribe(res => {
            this.isLoading = 0;
            if (res.status == 2000) {
                this.NotSettings = res.data;
            }
        });
    }

    updateSettingsNotification(settings) {
        let param = {
            'not_id': settings.not_id,
            'app': settings.app,
            'email': settings.email,
        };
        this.notLoading = 1;
        this.authService.updateSettingsNotification(param).subscribe(res => {
            this.notLoading = 0;
            toastr.success('Notification preferences saved', 'Notification Settings', this.toastrOption);
        });
    }

    runTooltip() {
        let THIS = this;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }


}
