import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {MediaService} from '../../_services/media.service';

@Component({
    selector: 'page-mail-sent',
    templateUrl: './sent.component.html'
})
export class SentComponent {
    CustomHelper = CustomHelper;


    ROUTES = '/mail_send';
    constructor(private mediaService:MediaService) {
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/'+Session.team+this.ROUTES;
        window.history.pushState("","", url);
        this.getSendMail();
    }


    totalAssets = 0;
    pageNo = 1;
    perPage = this.CustomHelper.getPerpage();
    pageEvent;
    paginateList(){
        if(this.pageEvent.pageSize == this.perPage){
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex+1;
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
        }
    }
    Mails = [];
    getSendMail() {
        let THIS = this;
        this.mediaService.getSendMail().subscribe(res => {
            if(res.status == 2000){
                this.Mails = res.data;
            }
            setTimeout(function () {
                THIS.CustomHelper.tooltipRender();
            }, 400);
        });
    }


    singleSelectedMail;
    previewPage = 0; // 0.default  1.Compose  2.SinglePreview


}