import {Component, Inject, Input, OnInit} from '@angular/core';
import {APPCONFIG} from '../../config';
import {CustomHelper} from "../../customhelper";
import {ChatService} from "../../_services/chat.service";
import {MediaService} from "../../_services/media.service";

declare let swal: any;
declare let Pusher: any;


@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
})
export class ChatComponent implements OnInit {
    @Input() chat:any;
    @Input() index:any;

    APPCONFIG = APPCONFIG;
    CustomHelper = CustomHelper;
    currentUser;

    ngOnInit() {
        let THIS = this;
        this.getMessage();
        //===============
        // Pusher
        //===============
        Pusher.logToConsole = true;
        let pusher = new Pusher('501b3b237bd799758df0', {
            cluster: 'ap4'
        });
        let channel = pusher.subscribe('eq-msg');
        channel.bind('SendMessage', function(data) {
            const v = data.message;
            if(v.pn_type == 'new-msg'){
                if(v.sender_id == THIS.chat.id || v.receiver_id == THIS.chat.id){
                    THIS.Messages.push(v);
                    setTimeout(() => {
                        let objDiv = document.getElementById("EachChat_body_"+THIS.index);
                        objDiv.scrollTop = objDiv.scrollHeight;
                    }, 500);
                    if(v.sender_id != THIS.currentUser.id){
                        const audio = <HTMLVideoElement>document.getElementById('chat_audio');
                        audio.play();
                    }
                }
            } else if(v.pn_type == 'edit-msg'){
                if(v.sender_id == THIS.chat.id || v.receiver_id == THIS.chat.id){
                    let index = THIS.Messages.map(function(e) { return e.id; }).indexOf(v.id);
                    if(index > -1){
                        THIS.Messages[index] = v;
                    }
                    if(v.sender_id != THIS.currentUser.id){
                        const audio = <HTMLVideoElement>document.getElementById('chat_audio');
                        audio.play();
                    }
                }
            } else if(v.pn_type == 'del-msg'){
                let index = THIS.Messages.map(function(e) { return parseInt(e.id); }).indexOf(parseInt(v.msg_id));
                if(index > -1){
                    THIS.Messages[index]['is_active'] = 0;
                    // THIS.Messages.splice(index, 1);
                }
            }
            THIS.chatService.UpdateNewConversation(true);
            setTimeout(() => {
                THIS.chatService.UpdateNewConversation(false);
            })
        });
        //===============
        // Pusher
        //===============
    }

    constructor(private chatService:ChatService, private mediaService:MediaService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.chatService.getActiveChat.subscribe(ActiveChat => {
            this.ActiveChat = ActiveChat;
        });
    }

    ActiveChat = [];
    closeChat(){
        this.ActiveChat.splice(this.index, 1);
        this.chatService.updateActiveChat(this.ActiveChat);
    }

    param = {
        receiver_id: '',
        thread_id: '',
        chat_type: 1,
        msg: '',
        images:'',
        attachment:'',
        media: ''
    }
    sendMessage(e){
        let THIS = this;
        e.preventDefault();
        if(e.which === 13){
            let param = this.param;
            param.receiver_id = this.chat.id;
            let images = [];
            this.commentAttachArr.forEach((v) => {
                images.push(v.file_path);
            });
            param.images = images.join(',');
            if(this.selectedMsg != null){
                param['msg_id'] = this.selectedMsg.id;
                param['thread_id'] = this.selectedMsg.thread_id;
            }
            $.ajax({
                url: THIS.APPCONFIG.apiMsg + 'send?token=' + THIS.currentUser.token,
                data: THIS.param,
                type: 'post',
                success: function (res) {
                    if(res.status === 2000){
                        THIS.param = {
                            receiver_id: '',
                            thread_id: '',
                            chat_type: 1,
                            msg: '',
                            images:'',
                            attachment:'',
                            media: ''
                        }
                        THIS.commentAttachArr.length = 0;
                        THIS.selectedMsg = null;
                    }
                }
            });
        }
        else {
            if($.trim(THIS.param.msg) == ''){
                THIS.selectedMsg = null;
            }
        }
    }

    Messages = [];
    selectedMsg = null;
    getMessage(){
        let THIS = this;
        if(this.chat.thread_id > 0){
            const param = {
                pageNo:1,
                limit:100,
                thread_id: this.chat.thread_id,
                chat_type:1
            }
            $.ajax({
                url: THIS.APPCONFIG.apiMsg + 'get?token=' + THIS.currentUser.token,
                data: param,
                type: 'post',
                success: function (res) {
                    console.log(res);
                    if(res.status === 2000){
                        THIS.Messages = res.data.reverse();
                    }
                    setTimeout(() => {
                        let objDiv = document.getElementById("EachChat_body_"+THIS.index);
                        objDiv.scrollTop = objDiv.scrollHeight;
                    }, 500);
                }
            });
        }
    }
    editThisMsg(){
        this.param.msg = this.selectedMsg.msg;
    }
    removeThisMsg(){
        let THIS = this;
        // swal({
        //     title: "Delete Message",
        //     text: "Are you sure you want to delete this message?",
        //     icon: "warning",
        //     buttons: ['Cancel', 'Delete'],
        //     dangerMode: true,
        // }).then((willDelete) => {
        //     if (willDelete) {
        //         const param = {
        //             thread_id: THIS.selectedMsg.thread_id,
        //             msg_id: THIS.selectedMsg.id,
        //         }
        //         $.ajax({
        //             url: THIS.APPCONFIG.apiMsg + 'delete?token=' + THIS.currentUser.token,
        //             data: param,
        //             type: 'post',
        //             success: function (res) {
        //                 if(res.status === 2000){
        //                     THIS.selectedMsg = null;
        //                 }
        //             }
        //         });
        //     }
        // });
        const param = {
          thread_id: THIS.selectedMsg.thread_id,
          msg_id: THIS.selectedMsg.id,
        };
        $.ajax({
          url: THIS.APPCONFIG.apiMsg + 'delete?token=' + THIS.currentUser.token,
          data: param,
          type: 'post',
          success: function (res) {
            if(res.status === 2000){
              THIS.selectedMsg = null;
              (<any>$('#removeInsChatModal')).modal('hide');
            }
          }
        });
    }
  removeMsgModal() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#removeInsChatModal')).modal('show');
  }

    emojiList = [];
    emojiListCurrentIndex = 0;
    emojiListCurrent = [];
    getEmoji() {
        this.emojiListCurrentIndex = 0;
        this.mediaService.getEmoji().subscribe(res => {
            if (res.status == 2000) {
                this.emojiList = res.data;
                this.emojiListCurrent = this.emojiList[this.emojiListCurrentIndex];
            }
        });
    }
    writeEmoji(sign) {
        this.param.msg = this.param.msg + sign + ' ';
    }

    commentAttachArr = [];
    uploadThisComentMedia(file, media_type, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        this.mediaService.create(formData).subscribe(res => {
            callback(res);
        });
    }
    addCommentMedia() {
        $('#attachImgComment_'+this.index).click();
    }
    selectTheseCommentImg(e) {
        let THIS = this;
        e.preventDefault();
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            const eachFile = accepted[i];
            THIS.uploadThisComentMedia(eachFile, '1', function (uploaded) {
                THIS.commentAttachArr.push(uploaded);
                console.log(THIS.commentAttachArr);
            });
        }
    }
    removeThisCommentImage(index, id) {
        this.commentAttachArr.splice(index, 1);
        this.removeThisMedia(id, 1);
    }
    removeThisMedia(id, media_type) {
        let param = {
            id: id, media_type: media_type
        };
        this.mediaService.remove(param).subscribe(res => {
        });
    }
}
