import {Component, EventEmitter, Input, Output, SimpleChanges, Inject, TemplateRef} from '@angular/core';
import {WorkOrderService} from '../../../_services/work-orders.service';
import { TabsService } from '../../../_services/tabs.service';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';
import { InvoicesComponent } from '../../../pages/invoices/invoices.component';
import { RctiComponent } from '../../../pages/rcti/rcti.component';
declare let toastr: any;
declare let swal: any;
declare let google: any;
declare let Sortable: any;
declare let owlCarousel: any;

@Component({
    selector: 'work-to-inv-preview',
    templateUrl: './workToInv.component.html'
})
export class WorkToInvComponent {
    @Input() rcti: any;
    @Input() workId: any;
    @Input() wo2in: any;
    workDetails;
    currentUser;
    APPCONFIG = APPCONFIG;
    CustomHelper = CustomHelper;

    constructor(private workOrderService: WorkOrderService,
                private tabsService: TabsService) {
        this.tabsService.getTabs().subscribe(tabs => {
            this.tabs = tabs;
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        let THIS = this;
        if(changes.wo2in != undefined){
            if(changes.wo2in.currentValue == 1){
                this.wo2inActive = 1;
            }
        }
        if(changes.workId != undefined){
            let uid = changes.workId.currentValue;
            if(uid != ''){
                let param = {uid : uid};
                this.workOrderService.getSingleWorkPreId(param).subscribe(res => {
                    if(res.status == 2000){
                        let work = res.data;
                        delete work.contact;
                        delete work.invoice;
                        delete work.notify;
                        delete work.recurring;
                        delete work.timeline;
                        this.workDetails = work;
                        if(this.wo2inActive == 1){
                            setTimeout(function () {
                                if(THIS.rcti == 0){
                                    THIS.workToInvoice();
                                } else {
                                    THIS.workToRCTI();
                                }
                            }, 500);
                        }

                    }
                });
            }
        }
    }

    tabs = [];
    wo2inActive = 0;
    selectedTab: number;
    workToInvoice(){
        localStorage.work_to_invoice = JSON.stringify(this.workDetails);
        let THIS = this;
        this.tabsService.addTab({
            description: 'Invoices',
            component: InvoicesComponent,
            id: (new Date).getTime()
        });


        this.tabsService.getTabs().subscribe(tabs => {
            this.tabs = tabs;
            this.selectedTab = this.tabs.length-1;
            this.tabsService.setSelectedTab(this.selectedTab);
            this.slideUpdate(this.selectedTab, 1);
            let list = document.getElementById("sort-tabs");
            Sortable.create(list,{
                onEnd: function (e) {
                    (<any>$('.owl-carousel')).owlCarousel('destroy');
                    THIS.tabs[e.oldIndex] = THIS.tabs.splice(e.newIndex, 1, THIS.tabs[e.oldIndex])[0];
                    THIS.selectedTab = e.newIndex;
                    THIS.slideUpdate(THIS.selectedTab, 1);
                },
            });
        });

    }
    //RctiComponent
    workToRCTI(){
        localStorage.work_to_invoice = JSON.stringify(this.workDetails);
        let THIS = this;
        this.tabsService.addTab({
            description: 'RCTI',
            component: RctiComponent,
            id: (new Date).getTime()
        });


        this.tabsService.getTabs().subscribe(tabs => {
            this.tabs = tabs;
            this.selectedTab = this.tabs.length-1;
            this.tabsService.setSelectedTab(this.selectedTab);
            this.slideUpdate(this.selectedTab, 1);
            let list = document.getElementById("sort-tabs");
            Sortable.create(list,{
                onEnd: function (e) {
                    (<any>$('.owl-carousel')).owlCarousel('destroy');
                    THIS.tabs[e.oldIndex] = THIS.tabs.splice(e.newIndex, 1, THIS.tabs[e.oldIndex])[0];
                    THIS.selectedTab = e.newIndex;
                    THIS.slideUpdate(THIS.selectedTab, 1);
                },
            });
        });
    }
    slideUpdate(selectedTab, newD){
        let THIS = this;
        setTimeout(function () {
            if(newD == 1){
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                (<any>$('.owl-carousel')).owlCarousel({
                    loop:false,
                    nav:false,
                    dots:false,
                    mouseDrag:false,
                    touchDrag:false,
                    items:1,
                    animateIn: 'fadeIn faster',
                    autoplay: false
                }).on('changed.owl.carousel', function(event) {
                    THIS.selectedTab = event.item.index;
                    THIS.tabsService.setSelectedTab(THIS.selectedTab);
                });
            }
            (<any>$('.owl-carousel')).trigger('to.owl.carousel', selectedTab);
        }, 100);
    }


}


