import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { APPCONFIG } from '../config';

import { WorkOrderModel } from '../_models/work-order';

import { HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";

@Injectable()
export class WorkOrderService {

    public token: string;
    workOrders: Array<WorkOrderModel> = [];
    currentWorkOrder = new BehaviorSubject(null);
    getCurrentWorkOrder = this.currentWorkOrder.asObservable();
    workOrderList = (new BehaviorSubject(this.workOrders)).asObservable();

    constructor( private http: HttpClient, private router: Router ) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }


    createWorkOrder(workOrder): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/create?token=`+this.token, workOrder)
            .pipe(map(res => {
                return res;
            }));
    }
    createWorkOrderLot(workOrder): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/createLot?token=`+this.token, workOrder)
            .pipe(map(res => {
                return res;
            }));
    }

    editWorkOrder(workOrder, id): Observable<any> {
        return this.http
            .put<any>(`${APPCONFIG.api}work-orders/`+id+`?token=`+this.token, workOrder)
            .pipe(map(res => {
                return res;
            }));
    }
    commentWorkOrder(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/timeline?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getCommentWorkOrder(id, pageNo, type): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/timeline/`+id+`?token=`+this.token, {pageNo : pageNo, type: type})
            .pipe(map(res => {
                return res;
            }));
    }
    removeCommentWorkOrder(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/timeline/remove?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    editCommentWorkOrder(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/timeline/edit?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    updateStatusWorkOrder(id,status): Observable<any> {
        return this.http
            .put<any>(`${APPCONFIG.api}work-orders/`+id+`?token=`+this.token, {status_code: status})
            .pipe(map(res => {
                return res;
            }));
    }
    removeWorkOrder(type,ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}get/work-orders/`+type+`?token=`+this.token, {ids: ids})
            .pipe(map(res => {
                return res;
            }));
    }
    removeUndoWorkOrder(type,ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}get/work-orders/undo/`+type+`?token=`+this.token, {ids:ids})
            .pipe(map(res => {
                return res;
            }));
    }


    duplicateWorkOrder(id): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/duplicate?token=`+this.token, {id: id})
            .pipe(map(res => {
                return res;
            }));
    }

    getSingleWork(short_code): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.apiAuth}work-orders/single/`+short_code)
            .pipe(map(res => {
                return res;
            }));
    }
    getSingleWorkPreId(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/single?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getWorkOrdes(param): Observable<any> {
        if(localStorage.childWork != undefined){
            let id = localStorage.childWork;
            param.id = id;
        }
        return this.http
            .post<any>(`${APPCONFIG.api}get/work-orders?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getWorkOrdesStatus(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}get/work-orders-status?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getLastWorkOrderId(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}get/work-orders/lastId?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }


    currentView = new BehaviorSubject(null);


    //============================
    // Task List
    //============================
    createTask(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/task?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    removeTask(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/task/remove?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    statusUpdateTask(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/task/status?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    rateUpdateTask(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/task/rate?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    sortingUpdateTask(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders/task/sorting?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }


    //============================
    // Recurring Work Order
    //============================
    createRecurringWorkOrder(workOrder): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders-recurring/create?token=`+this.token, workOrder)
            .pipe(map(res => {
                return res;
            }));
    }
    editRecurringWorkOrder(workOrder): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-orders-recurring/edit?token=`+this.token, workOrder)
            .pipe(map(res => {
                return res;
            }));
    }
    getPastRec(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}work-order-recurring/get/wo?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getRecurringWorkOrders(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}get/work-orders-recurring?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getRecurringWorkOrderSingle(id): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}get/work-orders-recurring/`+id+`?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getRecurringWorkOrdesStatus(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}get/work-orders-recurring-status?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }

    removeWorkOrderRecurring(type,ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}get/work-orders-recurring/`+type+`?token=`+this.token, {ids: ids})
            .pipe(map(res => {
                return res;
            }));
    }
    removeUndoWorkOrderRecurring(type,ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}get/work-orders-recurring/undo/`+type+`?token=`+this.token, {ids:ids})
            .pipe(map(res => {
                return res;
            }));
    }


}
