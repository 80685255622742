import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';
import {ContactsService} from '../../../_services/contacts.service';
import {FcmService} from '../../../_services/fcm.service';
import {APPCONFIG} from '../../../config';
import {Lightbox} from 'angular2-lightbox';
import {CustomHelper} from '../../../customhelper';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';

declare let toastr: any;
declare let swal: any;
declare let google: any;
declare let Ladda: any;
declare let fabric: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;
declare let Pickr: any;

@Component({
  selector: 'bm-single',
  templateUrl: './singleBm.component.html'
})
export class SingleBmComponent {
  modalRef: BsModalRef;
  @Output() currentID = new EventEmitter<number>();
  @Output() discard = new EventEmitter<number>();
  @Output() statusChange = new EventEmitter<any>();
  @Output() editThis = new EventEmitter<any>();
  @Output() archiveThis = new EventEmitter<any>();
  @Output() activeThis = new EventEmitter<any>();
  @Output() removeThis = new EventEmitter<any>();
  @Output() duplicateThis = new EventEmitter<any>();
  @Output() previewQuick = new EventEmitter<number>();
  @Output() QuickList = new EventEmitter<number>();
  @Output() invoiceThis = new EventEmitter<number>();
  @Output() rctiThis = new EventEmitter<number>();
  @Output() loaded = new EventEmitter<number>();

  @Input() workId: any;
  @Input() editOptionForm: any;
  @Input() printThis: any;
  @Input() pdfThis: any;
  @Input() tmlThis: any;
  @Input() wo_is_active: any;
  @Input() statusUpdate: any;
  workDetails;
  workDetailsChange = 0;
  currentUser;
  APPCONFIG = APPCONFIG;
  _albums = [];
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
  activeAnonymousUsers = '';
  editAnonymousUsers = '';
  activeAnonymous = [];
  editAnonymous = [];
  updateCheckID = '';

  constructor(private _lightbox: Lightbox,
              private fcmService: FcmService,
              private contactsService: ContactsService,
              private mediaService: MediaService,
              private facilityService: FacilityService) {
    let THIS = this;
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.getSessionUserInfo();
    this.sortOptions.onUpdate = function () {
      THIS.sortTaskList();
    };

    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.dateCreatedOption.onChange = function (selectedDates, dateStr, instance) {
      if (selectedDates.length > 0) {
        THIS.dateCreated = new Date(selectedDates);
      } else {
        THIS.dateCreated = null;
      }
      THIS.onCreatedSelect();
    };
    this.dateDueOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.dateDue = new Date(selectedDates);
      THIS.onDueSelect();
    };

    this.updateCheckID = Date.now() + '_' + Session.id;
  }

  userInfo = {
    email: '',
    name: '',
    avatar: '',
    settings: '',
    user_type: '',
  };

  getSessionUserInfo() {
    this.userInfo = {
      email: this.currentUser.email,
      name: this.currentUser.nae,
      avatar: this.currentUser.avatar,
      settings: this.currentUser.settings,
      user_type: this.currentUser.user_type,
    };
    /*this.authService.getSessionUserInfo().subscribe(res => {
            this.userInfo = {
                email: res.email,
                name: res.first_name+' '+res.last_name,
                avatar: res.avatar,
                settings : res.settings,
                user_type: res.user_type,
            };
        });*/
  }

  inlinePrefixId = 0;
  inlineFacility = 0;
  inlineSubject = 0;
  inlineDescription = 0;
  toolbarOptions = [
    ['bold', 'italic', 'underline'],        // toggled buttons
    ['blockquote', 'code-block'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    // [{'font': []}],
    [{'align': []}],

    // ['clean'],                                         // remove formatting button
    // ['link', 'image', 'video'],                         // link and image, video
    // ['image']                         // link and image, video
  ];
  CMap;

  GmapZoomIn(data) {
    this.CMap.setZoom(this.CMap.getZoom() + data);
  }

  rightRefresh = 1;

  ngOnChanges(changes: SimpleChanges): void {
    let THIS = this;
    if (changes.editOptionForm != undefined) {
      if (changes.editOptionForm.currentValue == 1) {
        this.inlinePrefixId = 1;
        this.inlineFacility = 1;
        this.inlineSubject = 1;
        this.inlineDescription = 1;
      } else {
        this.inlinePrefixId = 0;
        this.inlineFacility = 0;
        this.inlineSubject = 0;
        this.inlineDescription = 0;
      }
      this.workDetailsChange = 1;
      this.rightRefresh = 0;
    }
    if (changes.printThis != undefined) {
      if (changes.printThis.currentValue == 1) {
        this.printActive = 1;
      }
    }
    if (changes.pdfThis != undefined) {
      if (changes.pdfThis.currentValue == 1) {
        this.pdfActive = 1;
      }
    }
    if (changes.tmlThis != undefined) {
      if (changes.tmlThis.currentValue == 1) {
        this.tmlPage = 1;
        this.getTimeLine();
      }
    }
    if (changes.workId != undefined) {
      this.workDetailsChange = 0;
      this.rightRefresh = 1;
      let id = changes.workId.currentValue;
      if (id != '') {
        let param = {uid: id};
        this.workDetailsChange = 0;
        this.refreshsinglePage(param);
      }
    }
  }

  showMapBtn = 0;
  AssetMap = 1;

  refreshsinglePage(param) {
    let THIS = this;
    if (this.CustomHelper.woRender != null) {
      this.CustomHelper.woRender.abort();
    }
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/single?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.AssetMap = 1;
        if (res.status == 2000) {
          let work = res.data;
          THIS.workDetails = work;
          THIS.receiveSocket();
          THIS.currentIDUpdate();
          THIS.workDetails.dates.creation_date = THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.creation_date);
          THIS.workDetails.dates.edited_date = THIS.workDetails.dates.edited_date != '' && THIS.workDetails.dates.edited_date != null ? THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.edited_date) : '';

          THIS.workDetails.facStr = THIS.workDetails.facStr.reverse();
          THIS._albums.length = 0;
          if (THIS.workDetails.media_files !== undefined) {
            for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
              const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path;
              const caption = '';
              const thumb = '';
              const album = {
                src: src,
                caption: caption,
                thumb: thumb,
                w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
                h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
              };
              THIS._albums.push(album);
            }
          }

          THIS.id = work.id;
          THIS.taskParam.work_id = work.id;
          THIS.taskRemoveParam.work_id = work.id;
          if (work.tasks.length > 0) {
            THIS.taskType = work.tasks[0].type;
          }


          THIS.param.subject = work.subject;
          THIS.param.prefix = work.prefix;
          THIS.param.work_order_id = work.work_order_id;
          THIS.param.description = work.description;
          THIS.param.contact_id = work.contact != null ? work.contact_id.toString() : '';
          THIS.param.asset_id = work.asset_id != null ? work.asset_id : '';
          THIS.AssetSelect = THIS.param.asset_id != '' ? THIS.param.asset_id.split(',') : [];
          THIS.param.job_types_id = work.job_type != null ? work.job_type.id.toString() : '';
          THIS.param.personnel = work.personnel_id > 0 ? work.personnel_id.toString() : '';
          THIS.param.facility_id = work.facility.id.toString();
          THIS.param.status_code = work.status_code.id.toString();
          THIS.param.sor = work.sor;
          //
          if (work.dates.created_at != null) {
            THIS.param.created_at = THIS.CustomHelper.formatDateTimeToDB(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
            let ca = THIS.CustomHelper.formatDateTime(new Date(THIS.param.created_at));
            THIS.dateCreated = new Date(THIS.CustomHelper.invToDate(ca));
            THIS.dateCreatedOption.defaultDate = THIS.dateCreated;
          }
          //
          if (work.dates.due_at != null) {
            THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(work.dates.due_at);
            THIS.param.due = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
            THIS.dateDue = new Date(THIS.CustomHelper.invToDate(THIS.param.due));
            THIS.dateDueOption.defaultDate = THIS.dateDue;
          }

          THIS.progress = work.progress;
          THIS.incoming = work.incoming;
          THIS.param.incoming = THIS.incoming;
          THIS.attachInventories = work.attachInventories;
          THIS.param.attachInventories = THIS.attachInventories;
          //
          THIS.add2Calendar = work.calendar.add_to_calendar;
          THIS.param.add_to_calendar = THIS.add2Calendar;
          THIS.calendarStatus = work.calendar.calendar_color == '' || work.calendar.calendar_color == null ? '_blue' : work.calendar.calendar_color;
          THIS.param.calendar_color = THIS.calendarStatus;
          //
          THIS.param.personnel_id.length = 0;
          for (let p = 0; p < work.personnel.length; p++) {
            THIS.param.personnel_id.push(work.personnel[p].id.toString());
          }
          THIS.param.priority = work.priority !== null ? work.priority.id.toString() : '0';
          THIS.selectedFilesPreview = work.media_files;
          THIS.selectedWorkAttach = work.attach_files;
          // THIS.timeLineList.length = 0;
          // THIS.tmlPage = 1;
          THIS.getTimeLine();
          //
          //
          THIS.workDetailsChange = 1;
          THIS.rightRefresh = 0;
          //
          if (THIS.printActive == 1) {
            setTimeout(function () {
              let w = window.open();
              w.document.write($('#export').html());
              w.print();
              w.close();
            }, 1000);
          }
          if (THIS.pdfActive == 1) {
            setTimeout(function () {
              THIS.pdfThisWorkOrder();
            }, 500);
          }
          THIS.getAllFacility(0);
          THIS.jt_get(0);
          THIS.getAllJobStatus(0);
          THIS.pty_get(0);
          THIS.asset_get(0);
          THIS.personnel_get(0);
          setTimeout(function () {
            (<any>$('.summernote')).summernote({
              maxHeight: '900px',
              minHeight: '150px',
              toolbar: [
                ['style', ['bold', 'underline', 'clear']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['insert', ['link', 'video']],
                ['misc', ['fullscreen']]
              ],
              callbacks: {
                onKeyup: function (e) {
                  THIS.updateChangesDiscard();
                }
              }
            });
            (<any>$('.summernote')).summernote('code', THIS.param.description);
            (<any>$('#summerDesc')).html(THIS.param.description);
            (<any>$('#woSubject')).val(THIS.param.subject);
            $('.tooltip').remove();
            (<any>$('[data-toggle="tooltip"]')).tooltip('destroy');
            setTimeout(function () {
              THIS.CustomHelper.tooltipRender();
            }, 100);

            if (work.asset.length > 0 && document.getElementById('geo-map-single') != null) {
              let mapStart = 0;
              THIS.CMap = null;
              let latlng = [];
              let labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
              let labelIndex = 0;
              let infowindow = [];
              $.each(work.asset, function (i, v) {
                THIS.showMapBtn = 1;
                if (v.geo != '' && v.geo != null) {
                  let geo = JSON.parse(v.geo);
                  if (mapStart == 0) {
                    mapStart = 1;
                    THIS.AssetMap = 1;
                    let mapOptions = {
                      zoom: 0,
                      center: new google.maps.LatLng(33.8688, 151.2093),
                      gestureHandling: 'cooperative',
                      mapTypeControl: false,
                      streetViewControl: false,
                      zoomControl: false
                    };
                    THIS.CMap = new google.maps.Map(document.getElementById('geo-map-single'), mapOptions);
                  }
                  if (THIS.CMap != null) {
                    latlng.push(geo.geometry);
                    let marker = new google.maps.Marker({
                      position: geo.geometry,
                      map: THIS.CMap,
                      draggable: false,
                      title: geo.address,
                      label: labels[labelIndex++ % labels.length]
                    });
                    infowindow[i] = new google.maps.InfoWindow({
                      content: `<div class="width-100" style="min-width: 600px"><h4 style="text-align: center">` + v.name + `</h4>
                                                        <div class="width-100">
                                                        <div class="margin-10"></div>
                                                        <div class="margin-10"></div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Bran/Model</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.brand + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Serial Number</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.serial_number + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar"><i class="nav-icon material-icons _theme-color">money</i><strong>Purchase Cost</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.purchase_cost + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="margin-10">
                                                        </div>
                                                        <div class="margin-10">
                                                        </div></div></div>`
                    });
                    marker.addListener('click', function () {
                      $.each(infowindow, function (iw, inf) {
                        if (inf != i) {
                          infowindow[iw].close();
                        }
                      });
                      infowindow[i].open(THIS.CMap, marker);
                    });
                  }
                }
              });
              let latlngbounds = new google.maps.LatLngBounds();
              if (latlng.length > 0) {
                for (let i = 0; i < latlng.length; i++) {
                  latlngbounds.extend(latlng[i]);
                }
                THIS.CMap.fitBounds(latlngbounds);
                new google.maps.Rectangle({
                  bounds: latlngbounds,
                  map: THIS.CMap,
                  fillColor: 'transparent',
                  fillOpacity: 0.1,
                  strokeWeight: 0
                });
              } else {
                THIS.AssetMap = 0;
              }
            } else {
              THIS.AssetMap = 0;
            }
          }, 100);

          THIS.loaded.emit(1);

        } else if (res.status == 2200) {
          THIS.param.facility_id = res.facility_id.toString();
          THIS.workDetailsChange = -22;
          THIS.loaded.emit(1);
        } else {
          THIS.workDetailsChange = -1;
          THIS.loaded.emit(1);
        }
      }
    });
  }

  confirmDiscard() {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (this.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Discard Alert',
        text: 'Are you sure you want to discard?',
        icon: 'warning',
        buttons: ['Stay on this page', 'Leave page'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          THIS.discard.emit(1);
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
          setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
          }, 100);
        }
      });
    } else {
      THIS.discard.emit(1);
      setTimeout(function () {
        THIS.CustomHelper.tooltipRender();
      }, 400);
    }
  }

  Facility = [];
  FacilityComp = [];

  getAllFacility(refresh) {
    let THIS = this;
    THIS.Facility.length = 0;
    THIS.FacilityComp.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.FacilityComp = res.facility;
        $.each(res.facility, function (i, v) {
          v['pp'] = 0;
          THIS.Facility.push(v);
          $.each(v.child, function (j, vC) {
            vC['pp'] = 1;
            THIS.Facility.push(vC);
            $.each(vC.child, function (k, vCC) {
              vCC['pp'] = 2;
              THIS.Facility.push(vCC);
              $.each(vCC.child, function (l, vCCC) {
                vCCC['pp'] = 3;
                THIS.Facility.push(vCCC);
              });
            });
          });
        });
        setTimeout(function () {
          (<any>$('.chosen-default-single-fac')).chosen('destroy');
          (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
          (<any>$('.chosen-default-single-fac')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Facility'
          }).on('change', function (e, p) {
            THIS.param.facility_id = e.target.value;
            THIS.validationThisForm();
            THIS.updateChangesDiscard();
          });
          THIS.SA_fac = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  selectFacility(facility_id) {
    this.param.facility_id = facility_id.toString();
    let form = $('#workCreateForm');
    this.validationThisForm();
  }

  _fc_updating = 0;

  updateThisFacility(id) {
    let THIS = this;
    this._fc_updating = 1;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        THIS._fc_updating = 0;
        THIS.workDetailsChange = 0;

        let id = THIS.workId;
        let param = {uid: id};
        THIS.refreshsinglePage(param);
        THIS.facilityService.refreshFacility();
      }
    });
  }

  JobType = [];

  jt_get(refresh) {
    let THIS = this;
    this.JobType.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-types?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.JobType = res.data;
        setTimeout(function () {
          (<any>$('.chosen-default-single-jti')).chosen('destroy');
          (<any>$('.chosen-default-single-jti')).val(THIS.param.job_types_id);
          (<any>$('.chosen-default-single-jti')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Job Type'
          }).on('change', function (e, p) {
            THIS.param.job_types_id = e.target.value;
            THIS.updateChangesDiscard();
          });
          THIS.SA_type = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  Priority = [];

  pty_get(refresh): void {
    let THIS = this;
    this.Priority.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'priorities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.Priority = res.data;
        setTimeout(function () {
          (<any>$('.chosen-default-single-priority')).val(THIS.param.priority);
          (<any>$('.chosen-default-single-priority')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Priority'
          }).on('change', function (e, p) {
            THIS.updateChangesDiscard();
            let index = THIS.Priority.map(function (element) {
              return element.id;
            }).indexOf(parseInt(e.target.value));
            let priority = (index > -1) ? THIS.Priority[index] : null;
            if (priority != null) {
              THIS.param.priority = priority.id.toString();
              console.log(THIS.param.created_at);
              if (THIS.param.created_at != null && THIS.param.created_at != '') {
                let v = new Date(THIS.dateCreated);
                let min = THIS.CustomHelper.makeFloat(priority.amount) * THIS.CustomHelper.makeFloat(priority.duration);
                v.setMinutes(v.getMinutes() + min);
                THIS.dateDue = THIS.CustomHelper.formatDateTimeToDB(v);
                THIS.changingStart = 1;
                setTimeout(function () {
                  THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
                  THIS.dateDueOption.defaultDate = new Date(THIS.dateDue);
                  THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue));
                  THIS.changingStart = 0;
                }, 400);
              }
            } else {
              THIS.param.priority = '0';
              if (THIS.param.created_at != null && THIS.param.created_at != '') {
                THIS.param.due = THIS.dateDue != null ? THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue)) : '';
              }
            }
          });
          THIS.SA_pr = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  Assets = [];
  AssetSelect = [];

  asset_get(refresh): void {
    let THIS = this;
    this.Assets.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'assets/get/Asset/allList?token=' + THIS.currentUser.token,
      data: {},
      type: 'post',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Assets = res.data;
        }
        setTimeout(function () {
          (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
          (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
          (<any>$('.chosen-default-multiple-asset')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No asset selected'
          }).on('change', function (e, p) {
            if (p.selected != undefined) {
              THIS.AssetSelect.push(p.selected);
            } else {
              THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
            }
            THIS.param.asset_id = THIS.AssetSelect.join(',');
            THIS.updateChangesDiscard();
          });
          THIS.SA_asset = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  Personnel = {
    org: [],
    person: []
  };

  personnel_get(refresh): void {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Personnel = res;
        }
        setTimeout(function () {
          let pp_val = THIS.param.personnel_id;
          (<any>$('.chosen-default-multiple-per')).val(pp_val);
          (<any>$('.chosen-default-multiple-per')).chosen('destroy');
          (<any>$('.chosen-default-multiple-per')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No personnel selected'
          }).on('change', function (e, p) {
            if (p.selected != undefined) {
              let v = p.selected;
              THIS.param.personnel_id.push(v.toString());
            } else {
              let v = p.deselected;
              if (THIS.param.personnel_id.indexOf(v) > -1) {
                let ssCount = 0;
                let ssSor = [];
                $.each(THIS.param.sor, function (i, sor_v) {
                  if (sor_v.user_id == v) {
                    ssCount++;
                  } else {
                    ssSor.push(sor_v);
                  }
                });
                if (ssCount > 0) {
                  swal({
                    title: 'SOR Assign Alert',
                    text: 'This personnel has been attached with ' + ssCount + ' SOR item. If you remove, all sor history will be gone also',
                    icon: 'warning',
                    buttons: ['Cancel', 'Remove'],
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
                      THIS.param.sor = ssSor;
                    } else {
                      (<any>$('.chosen-default-multiple-per')).val(THIS.param.personnel_id);
                      (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
                    }
                  });
                } else {
                  THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
                }
              }
            }
            THIS.updateChangesDiscard();
          });
          THIS.SA_person = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  jobStatusList = [];
  jobStatusDefault = [];
  jobStatusCustom = [];

  getAllJobStatus(refresh) {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-status?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.jobStatusDefault.length = 0;
          THIS.jobStatusCustom.length = 0;
          THIS.jobStatusList = res.data;
          localStorage._jsts = JSON.stringify(res.data);
          $.each(res.data, function (i, v) {
            if (v.type == 0) {
              THIS.jobStatusDefault.push(v);
            } else {
              THIS.jobStatusCustom.push(v);
            }
          });
        }
        setTimeout(function () {
          (<any>$('.chosen-default-single-status')).chosen('destroy');
          (<any>$('.chosen-default-single-status')).val(THIS.param.status_code);
          (<any>$('.chosen-default-single-status')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select job status'
          }).on('change', function (e, p) {
            THIS.param.status_code = e.target.value;
            THIS.updateChangesDiscard();
          });
          THIS.SA_status = 1;
          THIS.checkSaveAction();
        }, 100);
      }
    });
  }

  invPersonnels = {
    org: [],
    person: []
  };

  getAllSupplier() {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.invPersonnels.org = res.org;
          THIS.invPersonnels.person = res.person;
        }
        setTimeout(function () {
          (<any>$('.chosen-default-single-supplier')).chosen('destroy');
          (<any>$('.chosen-default-single-supplier')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Supplier'
          }).on('change', function (e, p) {
            let v = e.target.value;
            THIS.incomingCreate.personnel = v;
            THIS.incomingCreate.groups = '';
          });
        }, 500);
      }
    });
  }

  siModalOpen() {
    this.getAllSupplier();

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#SIModal')).modal('show');
  }

  workFilterOption = [
    {title: 'New'},
    {title: 'Awaiting Approval'},
    {title: 'In Progress'},
    {title: 'Completed'},
    {title: 'Cancelled'}
  ];
  inventory = [
    {
      item: 'Light Globe R1',
      qty: 1,
      each: 10,
      total: 10
    },
    {
      item: 'Labour',
      qty: 1,
      each: 100,
      total: 100
    }
  ];

  selectedWorkAttach = [];
  selectedFiles = [];
  selectedFilesPreview = [];

  uploadThisMedia(file, media_type, localtion, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  removeThisMedia(id, media_type) {
    let param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
      this.timeLineCommentCheck();
    });
  }

  dragFileOverStart() {
    var trigger = $('#uploadDragWork');
    trigger.addClass('onEnter');
  }

  dragFileOverEnd() {
    var trigger = $('#uploadDragWork');
    trigger.removeClass('onEnter');
  }

  _uploadError = 0;
  _locPermit = 0;

  dragFilesDropped(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    if (e.dataTransfer.files.length > 0) {
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        if (this.selectedFiles.length < 3) {
          let eachFile = e.dataTransfer.files[i];
          this.selectedFiles.push(eachFile);
          if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            if (THIS.currentUser.modules.geo_tagged_photo == 1) {
              navigator.geolocation.getCurrentPosition(function (position) {
                if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                  THIS._locPermit = 1;
                  let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                  THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  THIS._locPermit = -1;
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                }
              }, function (error) {
                swal({
                  title: 'Share Geo Location',
                  text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                  icon: 'warning',
                  buttons: ['Want to share location', 'Don\'t want to share Location'],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                      if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                          swal({
                            title: 'Storage Full',
                            text: 'Your Storage is full. Please delete some files',
                            icon: 'error',
                            dangerMode: true,
                            className: 'swal-btn-center'
                          });
                        } else {
                          swal({
                            title: 'Invalid file format uploaded',
                            text: 'Please upload an image in Jpeg or Png only.',
                            icon: 'error',
                            dangerMode: true,
                            className: 'swal-btn-center'
                          });
                        }
                      } else {
                        THIS.selectedFilesPreview.push(uploaded);
                      }
                    });
                  } else {
                    swal({
                      title: 'Share Location',
                      text: 'If you want to share location then change the browser settings and remove the block status',
                      icon: 'warning',
                      className: 'swal-btn-center'
                    });
                  }
                });
              });
            } else {
              THIS._locPermit = -1;
              THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                if (uploaded.status != undefined) {
                  if (uploaded.status == 9000) {
                    swal({
                      title: 'Storage Full',
                      text: 'Your Storage is full. Please delete some files',
                      icon: 'error',
                      dangerMode: true,
                      className: 'swal-btn-center'
                    });
                  } else {
                    swal({
                      title: 'Invalid file format uploaded',
                      text: 'Please upload an image in Jpeg or Png only.',
                      icon: 'error',
                      dangerMode: true,
                      className: 'swal-btn-center'
                    });
                  }
                } else {
                  THIS.selectedFilesPreview.push(uploaded);
                }
              });
            }
          } else {
            this._uploadError = 1;
          }
        }
      }
      // this.uploaderPlate.preview = 1;
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    let accepted = e.target.files;
    for (var i = 0; i < accepted.length; i++) {
      if (this.selectedFiles.length < 3) {
        let eachFile = accepted[i];
        this.selectedFiles.push(eachFile);
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                THIS._locPermit = 1;
                let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              } else {
                THIS._locPermit = -1;
                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              }
            }, function (error) {
              swal({
                title: 'Share Geo Location',
                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                icon: 'warning',
                buttons: ['Want to share location', 'Don\'t want to share Location'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  swal({
                    title: 'Share Location',
                    text: 'If you want to share location then change the browser settings and remove the block status',
                    icon: 'warning',
                    dangerMode: true,
                    className: 'swal-btn-center'
                  });
                }
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please delete some files',
                    icon: 'error',
                    dangerMode: true,
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
              }
            });
          }
        } else {
          this._uploadError = 1;
        }
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
    // this.uploaderPlate.preview = 1;
  }

  uploadThisComentMedia(file, media_type, localtion, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  upReq = 0;

  selectTheseCommentImg(e) {
    let THIS = this;
    if (this.upReq > 5) {
      return false;
    }
    this.upReq++;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    e.preventDefault();
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      if (THIS.currentUser.modules.geo_tagged_photo == 1) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            THIS._locPermit = 1;
            let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
            THIS.uploadThisComentMedia(eachFile, '1', localtion, function (uploaded) {
              THIS.commentAttachArr.push(uploaded);
              THIS.timeLineCommentCheck();
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
              THIS.commentAttachArr.push(uploaded);
              THIS.timeLineCommentCheck();
            });
          }
        }, function (error) {
          swal({
            title: 'Share Geo Location',
            text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
            icon: 'warning',
            buttons: ['Want to share location', 'Don\'t want to share Location'],
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
                THIS.commentAttachArr.push(uploaded);
                THIS.timeLineCommentCheck();
              });
            } else {
              swal('If you want to share location then change the browser settings and remove the block status');
            }
          });
        });
      } else {
        THIS._locPermit = -1;
        THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
          THIS.commentAttachArr.push(uploaded);
          THIS.timeLineCommentCheck();
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  upReqEdit = 0;

  selectTheseEditCommentImg(e) {
    let THIS = this;
    if (this.upReqEdit > 5) {
      return false;
    }
    this.upReqEdit++;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    e.preventDefault();
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      if (THIS.currentUser.modules.geo_tagged_photo == 1) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            THIS._locPermit = 1;
            let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
            THIS.uploadThisComentMedia(eachFile, '1', localtion, function (uploaded) {
              THIS.commentAttachEditArr.push(uploaded);
              THIS.timeLineCommentCheck();
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
              THIS.commentAttachEditArr.push(uploaded);
              THIS.timeLineCommentCheck();
            });
          }
        }, function (error) {
          swal({
            title: 'Share Geo Location',
            text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
            icon: 'warning',
            buttons: ['Want to share location', 'Don\'t want to share Location'],
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
                THIS.commentAttachEditArr.push(uploaded);
                THIS.timeLineCommentCheck();
              });
            } else {
              swal('If you want to share location then change the browser settings and remove the block status');
            }
          });
        });
      } else {
        THIS._locPermit = -1;
        THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
          THIS.commentAttachEditArr.push(uploaded);
          THIS.timeLineCommentCheck();
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  removeThisImage(index, id) {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesPreview.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  addWorkMedia() {
    if (this.commentAttachArr.length < 6) {
      $('#workMediaInput').click();
    }
  }

  addCommentMedia() {
    if (this.commentAttachArr.length < 6) {
      $('#attachImgComment').click();
    }
  }

  editCommentMedia() {
    $('#attachImgEditComment').click();
  }

  updateThisWorkStatus(status, id) {
    this.workDetails.status_code = status;
    let data = {
      status: status.id,
      id: id
    };
    this.statusChange.emit(data);
  }

  editThisWorkOrder() {
    this.editThis.emit(this.workDetails.prefix + '-' + this.workDetails.work_order_id);
  }

  archiveThisWorkOrder() {
    this.archiveThis.emit(this.workDetails.id);
  }

  activeThisWorkOrder() {
    this.activeThis.emit(this.workDetails.id);
  }

  removeThisWorkOrder() {
    this.removeThis.emit(this.workDetails.id);
  }

  duplicateThisWorkOrder() {
    this.duplicateThis.emit(this.workDetails.id);
  }

  currentIDUpdate() {
    this.currentID.emit(this.workDetails.id);
  }

  workToInvoice() {
    this.invoiceThis.emit(this.workDetails.id);
  }

  workToRCTI() {
    this.rctiThis.emit(this.workDetails.id);
  }

  discardNewInvoice() {
    this.discard.emit(1);
  }

  printActive = 0;

  printThisWorkOrder() {
    let w = window.open();
    w.document.write($('#export').html());
    w.print();
    w.close();
  }

  pdfActive = 0;

  pdfThisWorkOrder() {
    $('#exportPDFTrigger').click();
  }

  zipThisWorkOrder() {
    let THIS = this;
    let win = window.open(THIS.APPCONFIG.api + 'work-orders/zip/image/' + this.workDetails.id + '?token=' + THIS.currentUser.token, '_blank');
    win.focus();
  }

  mailThisWo() {
    let work = this.workDetails;
    let param = {
      id: work.id,
      uid: work.uid,
      team: this.currentUser.team,
      short_code: work.short_code,
      subject: work.subject,
    };
    $('#sendMailModalTrigger').attr('data-type', JSON.stringify(param));
    $('#sendMailModalTrigger').click();
  }

  // ========================
  // workToInvoice
  // ========================


  //====================
  // ___panel_expand
  //====================
  id = 0;
  param = {
    prefix: 'w',
    work_order_id: '',
    subject: '',
    description: '',
    media_files: '',
    attach_files: '',
    facility_id: '',
    contact_id: 0,
    asset_id: '',
    job_types_id: 0,
    due: '',
    created_at: '',
    add_to_calendar: 0,
    calendar_color: '',
    priority: '0',
    status_code: 1,
    personnel: '4',
    personnel_id: [],
    group_id: [],
    personnel_group: '',
    attachInventories: [],
    taskType: 1,
    oldTasks: [],
    tasks: [],
    incoming: [],
    sor: []
  };
  _panel_expand = 0;
  _panel_clear = 0;
  _panel_clear_now = 0;
  error = {
    facility: 0,
    personnel: 0,
    contact: 0,
    jobType: 0,
    priority: 0,
  };

  didThisSave() {
    this.saveWorkOrderSubmit('');
  }

  qckUpdate() {
    let THIS = this;
    this.param.incoming = this.incoming;
    this.param.attachInventories = this.attachInventories;
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    this.param.attach_files = mediaAttach;
    this.param.subject = this.workDetails.subject;
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/update/' + THIS.id + '?token=' + THIS.currentUser.token,
      type: 'post',
      data: THIS.param,
      success: function (res) {
        THIS._updatingInfo = 0;
        if (res.status != undefined) {
          if (res.status == 7000) {
          }
        } else {
          toastr.success('Job has been successfully updated', 'Update Job', THIS.toastrOption);
        }
      }
    });
  }

  clearValidation(e) {
    let trigger = $(e.target);
    let target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  validForm = 1;
  _updatingInfo = 0;

  validationThisForm() {
    let valid = 1;
    let form = $('#workCreateForm');
    if (this.param.facility_id == '') {
      valid = 0;
      this.error.facility = 1;
    } else {
      this.error.facility = 0;
    }
    // if (this.param.prefix == '') {
    //     valid = 0;
    // }
    // if (this.param.work_order_id == '') {
    //     valid = 0;
    // }
    if (this.param.subject == '') {
      valid = 0;
      form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
    } else {
      form.find('[data-el="subject"]').removeClass('has-error');
    }
    /*if (this.param.description == '' || this.param.description == null) {
            valid = 0;
            form.find('[data-el="description"]').removeClass('has-error').addClass('has-error');
        } else {
            form.find('[data-el="description"]').removeClass('has-error');
        }*/
    /*if (this.param.contact_id < 1) {
            valid = 0;
            this.error.contact = 1;
        } else {
            this.error.contact = 0;
        }
        if (this.param.job_types_id < 1) {
            valid = 0;
            this.error.jobType = 1;
        } else {
            this.error.jobType = 0;
        }
        if (this.param.priority == '') {
            valid = 0;
            this.error.priority = 1;
        } else {
            this.error.priority = 0;
        }
        if (this.param.personnel_id.length == 0) {
            valid = 0;
            this.error.personnel = 1;
        } else {
            this.error.personnel = 0;
        }*/
    this.validForm = valid;
    // this.param.prefix = this.CustomHelper.makeUpperCase(this.param.prefix);
    return valid;
  }

  saveWorkOrderSubmit(quick) {
    let THIS = this;
    let ldd = document.getElementById('SaveThisLadda');
    let ladda = Ladda.create(ldd);
    let mediaFiles = '';
    $.each(THIS.selectedFilesPreview, function (i, v) {
      if (i == 0) {
        mediaFiles += v.id;
      } else {
        mediaFiles += ',' + v.id;
      }
    });
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    this.param.media_files = mediaFiles;
    this.param.attach_files = mediaAttach;

    this.param.oldTasks = this.workDetails.tasks;
    this.param.description = (<any>$('.summernote')).summernote('code');

    let woTaskList = $('#woTaskList').find('._eachTask');
    // this.param['id'] = this.taskType;
    this.param['taskType'] = this.taskType;
    // this.param.tasks.length = 0;
    this.param.tasks = this.taskList;
    this.param.incoming = this.incoming;
    this.param.add_to_calendar = this.add2Calendar;
    this.param.calendar_color = this.calendarStatus;
    this.param.attachInventories = this.attachInventories;

    if (this.param.created_at != null && this.param.created_at != '') {
      this.param.created_at = this.CustomHelper.formatDateTimeToDB(this.param.created_at);
    }
    if (this.param.due != null && this.param.due != '') {
      this.param.due = this.param.due != null && this.param.due != '' ? this.CustomHelper.formatDateTimeToDB(this.param.due) : '';
    }
    /*woTaskList.each(function(i, v){
            let tsk = $(v).val();
            THIS.param.tasks.push(tsk);
        });*/

    console.log(this.param.created_at);
    let valid = this.validationThisForm();
    if (valid == 1) {
      ladda.start();
      this._updatingInfo = 1;
      $.ajax({
        url: THIS.APPCONFIG.api + 'work-orders/update/' + THIS.id + '?token=' + THIS.currentUser.token,
        type: 'post',
        data: THIS.param,
        success: function (res) {
          ladda.stop();
          THIS._updatingInfo = 0;
          if (res.status != undefined) {
            if (res.status == 7000) {

            }
          } else {
            THIS.CustomHelper.woRouteDiscard = 0;
            if (quick !== 'quick') {
              let work = res.data;
              THIS.workDetails = res.data;
              THIS.CustomHelper.woRouteDiscard = 0;
              let index = THIS.CustomHelper.ConfRoute.map(function (e) {
                return e.tab;
              }).indexOf(THIS.CustomHelper.ConfCurRoute);
              if (index > -1) {
                THIS.CustomHelper.ConfRoute.splice(index, 1);
              }
              THIS.workDetails.dates.creation_date = THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.creation_date);
              THIS.workDetails.dates.edited_date = THIS.workDetails.dates.edited_date != '' && THIS.workDetails.dates.edited_date != null ? THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.edited_date) : '';
              //
              if (work.dates.created_at != null) {
                THIS.param.created_at = THIS.CustomHelper.formatDateTimeToDB(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
                THIS.param.created_at = THIS.CustomHelper.formatDateTime(new Date(THIS.param.created_at));
                THIS.dateCreated = new Date(THIS.CustomHelper.invToDate(THIS.param.created_at));
                THIS.dateCreatedOption.defaultDate = THIS.dateCreated;
              }
              //
              if (work.dates.due_at != null) {
                THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(work.dates.due_at);
                THIS.param.due = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
                THIS.dateDue = new Date(THIS.CustomHelper.invToDate(THIS.param.due));
                THIS.dateDueOption.defaultDate = THIS.dateDue;
              }
              THIS.quickSinglePreview();
            }
            toastr.success('Job has been successfully updated', 'Update Job', THIS.toastrOption);
            THIS.taskList.length = 0;
            // Pusher emit
          }
        }
      });
    }
  }

  updateChangesDiscard() {
    this.param.subject = (<any>$('#woSubject')).val();
    let THIS = this;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      this.CustomHelper.ConfRoute[index].change = 1;
    } else {
      this.CustomHelper.ConfRoute.push({tab: this.CustomHelper.ConfCurRoute, change: 1});
    }
  }

  timeline = {
    work_id: 0,
    comment: '',
  };
  cTvalid = 0;

  timeLineCommentCheck() {
    let c = this.timeline.comment;
    let cR = $.trim(c);
    if (cR.length > 0 || this.commentAttachArr.length > 0) {
      this.cTvalid = 1;
    } else {
      this.cTvalid = 0;
    }
  }

  auto_grow() {
    let target = $('._module_comment_editor_text');
    let element = target[0];
    element.style.height = '5px';
    element.style.height = (element.scrollHeight) + 'px';
  }

  auto_grow_sloq() {
    let THIS = this;
    setTimeout(function () {
      THIS.auto_grow();
    }, 500);
  }

  newComment() {
    let THIS = this;
    this.cTvalid = 0;
    let comV = this.timeline.comment;
    let com = comV.replace(/<p><br><\/p>/gi, '\n\r');
    let domCom = $('<div>' + com + '</div>');
    let comment = domCom.text().trim();
    let type = '';
    if (this.commentAttachArr.length > 0) {
      let msg = {
        comment: comment.trim(),
        image: this.commentAttachArr
      };
      type = 'attach';
      comment = JSON.stringify(msg);
    } else {
      if (domCom.text() == '') {
        return true;
      }
    }
    let param = {
      work_id: this.id,
      comment: comment,
      type: type
    };
    this.timeline = {
      work_id: 0,
      comment: '',
    };
    $('._module_comment_editor_text').val('');
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/timeline?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.commentAttachArr.length = 0;
        if (res.status === 2000) {
          THIS.upReq = 0;
          res.data['target_pn_type'] = 'wo_comment_' + THIS.workDetails.id;
          // Pusher emit
          THIS.timeline = {
            work_id: 0,
            comment: '',
          };
          setTimeout(function () {
            THIS.auto_grow();
          }, 1000);
        }
        THIS.timeLineCommentCheck();
      }
    });
  }

  onEnterEffect(e) {
    if (e.which == 13 && (!e.shiftKey && !e.ctrlKey)) {
      e.preventDefault();
    }
    this.auto_grow();
  }

  onEnterCreate(e) {
    this.auto_grow();
    this.timeLineCommentCheck();
    if (e.which == 13 && (!e.shiftKey && !e.ctrlKey)) {
      e.preventDefault();
      this.newComment();
    }
  }

  timeLineList = [];
  tmlInterval: any;
  tmlPage = 1;
  tmlFetch = 0;
  timeline_Type = 1;

  getTimeLine() {
    let THIS = this;
    if (this.tmlFetch == 0) {
      if (this.id > 0) {
        this.tmlFetch = 1;
        $.ajax({
          url: THIS.APPCONFIG.api + 'work-orders/timeline/' + THIS.id + '?token=' + THIS.currentUser.token,
          data: {pageNo: THIS.tmlPage, type: THIS.timeline_Type},
          type: 'post',
          success: function (res) {
            if (res.status == 2000) {
              if (THIS.tmlPage == 1) {
                THIS.timeLineList.length = 0;
              }
              let tml = res.data;
              $.each(tml, function (i, v) {
                try {
                  let com = JSON.parse(v.attrs.comment);
                  v['commentT'] = com.comment;
                  v['images'] = com.image;
                } catch (e) {
                }
                let albumT = [];
                if (v.images !== undefined && v.images.length) {
                  for (let i = 0; i < v.images.length; i++) {
                    const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + v.images[i].file_path;
                    const caption = '';
                    const thumb = '';
                    const album = {
                      src: src,
                      caption: caption,
                      thumb: thumb,
                      w: v.images[i].width !== undefined ? v.images[i].width : 1500,
                      h: v.images[i].height !== undefined ? v.images[i].height : 1500
                    };
                    albumT.push(album);
                  }
                }
                v['album'] = albumT;
                v['created_at_human'] = THIS.CustomHelper.formatDateTime(v.created_at);
                v['updated_at_human'] = THIS.CustomHelper.formatDateTime(v.updated_at);
                if (v.module_id == THIS.id) {
                  if (this.tmlPage == 1) {
                    THIS.timeLineList.push(v);
                  } else {
                    THIS.timeLineList.splice(0, 0, v);
                  }
                }
              });
              if (this.tmlPage == 1) {
                setTimeout(function () {
                  let tarComP = $('.Comment-Section-Train-way');
                  if (tarComP.length > 0) {
                    tarComP[0].scrollTop = tarComP[0].scrollHeight;
                  }
                }, 500);
              } else {
                setTimeout(function () {
                  let tarComP = $('.Comment-Section-Train-way');
                  if (tarComP.length > 0) {
                    tarComP[0].scrollTop = 100;
                  }
                }, 500);
              }
            }
            if (res.data.length == 0) {
              THIS.tmlPage = 0;
            }
            THIS.tmlFetch = 0;
          }
        });
      }
    }
  }

  scrollTmlTop(e) {
    if (e.target.scrollTop < 20) {
      if (this.tmlFetch == 0 && this.tmlPage > 0) {
        this.tmlPage = this.tmlPage + 1;
        this.getTimeLine();
      }
    }
  }

  removeTimelineId = 0;

  openRemoveComment() {
    let THIS = this;
    swal({
      title: 'Comment Delete',
      text: 'Are you sure you want to delete this comment?',
      icon: 'warning',
      buttons: ['Cancel', 'Delete'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (THIS.removeTimelineId > 0) {
          let param = {id: THIS.removeTimelineId, work_id: THIS.id};
          $.ajax({
            url: THIS.APPCONFIG.api + 'work-orders/timeline/remove?token=' + THIS.currentUser.token,
            data: param,
            type: 'post',
            success: function (res) {
              swal.stopLoading();
              swal.close();
              // Pusher emit
            }
          });
        } else {
          swal('You didn\'t select any comment');
        }
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
  }

  editLogCommentId = 0;
  editLogCommentParam = {id: 0, comment: '', work_id: 0};
  cNTvalid = 1;

  timeLineNewCommentCheck() {
    let c = this.editLogCommentParam.comment;
    let cR = $.trim(c);
    if (cR.length > 0) {
      this.cNTvalid = 1;
    } else {
      this.cNTvalid = 0;
    }
    let tarCom = $('.ql-container');
    if (tarCom.length > 0) {
      tarCom[0].scrollTop = tarCom[0].scrollHeight;
    }
  }

  newEditComment() {
    let THIS = this;
    let comment = this.editLogCommentParam.comment.trim();
    let type = '';
    if (this.commentAttachEditArr.length > 0) {
      let msg = {
        comment: comment.trim(),
        image: this.commentAttachEditArr
      };
      type = 'attach';
      comment = JSON.stringify(msg);
    }
    let param = {
      id: this.editLogCommentId,
      work_id: this.id,
      comment: comment,
      type: type
    };
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/timeline/edit?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          THIS.upReqEdit = 0;
          res.data['target_pn_type'] = 'wo_comment_upd_' + THIS.workDetails.id;
          // Pusher emit
          THIS.editLogCommentId = 0;
          THIS.editLogCommentParam = {
            id: 0,
            comment: '',
            work_id: 0
          };
          THIS.cNTvalid = 1;
          THIS.commentAttachEditArr.length = 0;
        }
      }
    });
  }

  commentAttachArr = [];
  commentAttachEditArr = [];
  commentAttach = '';

  removeThisCommentImage(index, id) {
    this.commentAttachArr.splice(index, 1);
    this.upReq--;
    this.removeThisMedia(id, 1);
  }

  removeThisCommentEditImage(index, id) {
    this.commentAttachEditArr.splice(index, 1);
    this.upReqEdit--;
    this.removeThisMedia(id, 1);
  }

  checkEditImageList(log) {
    let THIS = this;
    this.commentAttachEditArr.length = 0;
    this.upReqEdit = 0;
    if (log.images != undefined) {
      this.commentAttachEditArr = log.images;
      this.upReqEdit = this.commentAttachEditArr.length;
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  emojiList = [];
  emojiListCurrentIndex = 0;
  emojiListCurrent = [];

  getEmoji() {
    this.emojiListCurrentIndex = 0;
    this.mediaService.getEmoji().subscribe(res => {
      if (res.status == 2000) {
        this.emojiList = res.data;
        this.emojiListCurrent = this.emojiList[this.emojiListCurrentIndex];
      }
    });
  }

  writeEmoji(sign) {
    this.timeline.comment = this.timeline.comment + sign + ' ';
    this.timeLineCommentCheck();
  }

  writeEmojiEdit(sign) {
    this.editLogCommentParam.comment = this.editLogCommentParam.comment + sign + ' ';
    this.timeLineNewCommentCheck();
  }


  taskType = 1; // 1.Tasks  2.Inspections
  taskCreateSubmit = 0;
  taskList = [];
  taskParam = {tasks: [], work_id: 0};

  taskCreateOnF() {
    this.taskList.push({title: '', type: 1});
    setTimeout(function () {
      let objDiv = document.getElementById('_tsk_list_scroll');
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 500);
    this.updateChangesDiscard();
  }

  taskRemoveParam = {id: 0, work_id: 0};
  removeTask = {submitted: 0, index: -1};

  statusUpdateTask(id, statusP, index) {
    let THIS = this;
    let status = statusP == 1 ? 2 : 1;
    if (id > 0 && status > 0) {
      let param = {id: id, work_id: this.workDetails.id, status: status};
      $.ajax({
        url: THIS.APPCONFIG.api + 'work-orders/task/status?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          if (res.status == 2000) {
            THIS.workDetails.tasks[index].status = status;
          }
          // THIS.QuickList.emit(1);
        }
      });
    }
  }

  rateUpdateTask(id, rate, index) {
    let THIS = this;
    if (id > 0 && rate > 0) {
      let param = {id: id, work_id: this.workDetails.id, rate: rate};
      $.ajax({
        url: THIS.APPCONFIG.api + 'work-orders/task/rate?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          if (res.status == 2000) {
            THIS.workDetails.tasks[index].rate = rate;
          }
          THIS.QuickList.emit(1);
        }
      });
    }
  }

  sortTaskList() {
    let THIS = this;
    let sort = [];
    $.each(THIS.workDetails.tasks, function (i, v) {
      let eachT = {id: v.id, sort_id: (i + 1)};
      sort.push(eachT);
    });
    let param = {work_id: this.workDetails.id, sort: sort};
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/task/sorting?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.QuickList.emit(1);
      }
    });
  }

  sortOptions = {
    onUpdate: function (evt) {
    }
  };


  dateCreated = null;
  changingStart = 0;
  dateCreatedOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: null,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  onCreatedSelect() {
    let THIS = this;
    if (this.dateCreated != null) {
      this.param.created_at = this.CustomHelper.formatDateTimeToDB(this.dateCreated);
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
        THIS.dateDueOption.defaultDate = this.dateDue;
        THIS.changingStart = 0;
      }, 400);
      this.updateChangesDiscard();
    } else {
      this.param.created_at = '';
      this.param.due = '';
      this.add2Calendar = 0;
      this.dateDue = null;
      this.changingStart = 1;
      setTimeout(function () {
        THIS.dateDueOption.minDate = new Date();
        THIS.dateDueOption.defaultDate = null;
        THIS.changingStart = 0;
      }, 400);
    }
  }

  dateDue = null;
  dateDueOption = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: null,
    minDate: null,
    altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };

  onDueSelect() {
    let THIS = this;
    this.param.due = this.dateDue != null && this.dateDue != '' ? this.CustomHelper.formatDateTimeToDB(this.dateDue) : '';
    this.param.priority = '0';
    setTimeout(function () {
      (<any>$('.chosen-default-single-priority')).val(THIS.param.priority);
      (<any>$('.chosen-default-single-priority')).chosen('destroy');
      (<any>$('.chosen-default-single-priority')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Priority'
      }).on('change', function (e, p) {
        // THIS.updateChangesDiscard();
        let index = THIS.Priority.map(function (element) {
          return element.id;
        }).indexOf(parseInt(e.target.value));
        let priority = (index > -1) ? THIS.Priority[index] : null;
        if (priority != null) {
          THIS.param.priority = priority.id.toString();
          console.log(THIS.param.created_at);
          if (THIS.param.created_at != null && THIS.param.created_at != '') {
            let v = new Date(THIS.dateCreated);
            let min = THIS.CustomHelper.makeFloat(priority.amount) * THIS.CustomHelper.makeFloat(priority.duration);
            v.setMinutes(v.getMinutes() + min);
            THIS.dateDue = THIS.CustomHelper.formatDateTimeToDB(v);
            THIS.changingStart = 1;
            setTimeout(function () {
              THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
              THIS.dateDueOption.defaultDate = new Date(THIS.dateDue);
              THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue));
              THIS.changingStart = 0;
            }, 400);
          }
        } else {
          THIS.param.priority = '0';
          if (THIS.param.created_at != null && THIS.param.created_at != '') {
            THIS.param.due = THIS.dateDue != null && THIS.dateDue != '' ? THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue)) : '';
          }
        }
      });
    }, 100);

  }

  add2Calendar = 0;
  calendarStatus = '_blue';

  addToCalendar() {
    if (this.param.created_at != '' && this.param.created_at != null) {
      this.add2Calendar == 1 ? this.add2Calendar = 0 : this.add2Calendar = 1;
      this.updateChangesDiscard();
    } else {
      this.add2Calendar = 0;
      swal('You must have to provide start Date to add this work order to Calendar');
    }
  }


  // ======================
  // Incoming Invoice
  // ======================
  incoming = [];
  incomingCreate = {
    invoice_number: '',
    invoice_amount: '',
    personnel: '',
    groups: '',
    comment: '',
    file_name: []
  };

  invoiceUploadFileChoose() {
    $('#invoiceUpload').click();
  }

  invoiceUpload(e) {
    e.preventDefault();
    let THIS = this;
    let files = e.target.files;
    $.each(files, function (i, v) {
      THIS.uploadThisMedia(v, '4', null, function (uploaded) {
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please delete some files',
              icon: 'error',
              dangerMode: true,
            });
          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
              icon: 'error',
              dangerMode: true,
            });
          }
        } else {
          THIS.incomingCreate.file_name.push(uploaded);
        }
      });
    });
  }

  createIncomingInvoice() {
    if (this.incomingCreate.invoice_number != '' && this.incomingCreate.invoice_amount != '') {
      this.incoming.push(this.incomingCreate);
      this.incomingCreate = {
        invoice_number: '',
        invoice_amount: '',
        personnel: '',
        groups: '',
        comment: '',
        file_name: []
      };
    }
    (<any>$('#SIModal')).modal('hide');
    if (this.inlinePrefixId == 0) {
      this.qckUpdate();
    }
  }


  shortLinkWo = null;
  shortLinkShared = 0;
  shortLinkUrl = '';
  shortLinkUrlCopied = 0;

  generateShortLink() {
    this.shortLinkUrl = '';
    let work = this.workDetails;
    let Session = JSON.parse(localStorage.currentUserInfo);
    this.shortLinkWo = work;
    this.shortLinkShared = work.shared != undefined ? work.shared : 0;
    this.shortLinkUrl = this.APPCONFIG.shortLink + '/' + Session.team + '/w/' + work.short_code;

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#shortLinkSingleModal')).modal('show');
  }

  allowCopyClip() {
    let THIS = this;
    THIS.shortLinkShared == 1 ? THIS.shortLinkShared = 0 : THIS.shortLinkShared = 1;
    THIS.workDetails['shared'] = THIS.shortLinkShared;
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/' + THIS.shortLinkWo.id + '/shared?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
      }
    });
  }

  copyToClipboard() {
    let copyTextarea = $('#shortLinkUrlSingle');
    copyTextarea.focus();
    copyTextarea.select();
    try {
      document.execCommand('copy');
      this.shortLinkUrlCopied = 1;
    } catch (err) {
      this.shortLinkUrlCopied = -1;
    }
  }


  SelectedPersonList = [];
  attachInventories = [];

  inventoryOpen(): void {
    let THIS = this;

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#invLabModal')).modal('show');
    (<any>$('.invFacilitySelector')).val('');

    THIS.SelectedPersonList.length = 0;
    $.each(THIS.param.personnel_id, function (i, v) {
      let index = THIS.Personnel.person.map(function (element) {
        return element.id;
      }).indexOf(parseInt(v));
      if (index > -1) {
        THIS.SelectedPersonList.push(THIS.Personnel.person[index]);
      }
    });
    THIS.LabourData = THIS.SelectedPersonList;
    THIS.InvLabData.length = 0;
    THIS.InvLabDataTotal = 0;
    THIS.InvLabEmpty = 0;

    setTimeout(function () {
      (<any>$('.invFacilitySelector')).chosen('destroy');
      (<any>$('.invFacilitySelector')).val(THIS.param.facility_id);
      (<any>$('.invFacilitySelector')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Facility'
      }).on('change', function (e, p) {
        if (p != undefined) {
          THIS.getAllInventory(p.selected);
        }
      });

      if (THIS.param.facility_id != '') {
        THIS.getAllInventory(THIS.param.facility_id);
      }
    }, 1000);
  }

  InvLabData = [];
  LabourData = [];
  InvLabDataTotal = 0;
  InvLabEmpty = 0;

  getAllInventory(facility_id) {
    let THIS = this;
    this.InvLabData.length = 0;
    this.InvLabDataTotal = 0;
    THIS.InvLabEmpty = 0;
    let param = {
      facility_id: facility_id
    };
    $.ajax({
      url: THIS.APPCONFIG.api + 'inventory/getAll?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        if (res.status == 2000) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]['pick'] = '';
            let index = THIS.attachInventories.map(function (element) {
              return element.inv_id;
            }).indexOf(res.data[i]['id']);
            if (index > -1) {
              if (THIS.attachInventories[index].type_id == 0) {
                res.data[i]['pick'] = THIS.attachInventories[index].pick;
              }
            }
          }
          THIS.InvLabData = res.data;
          THIS.InvLabDataTotal = res.data.length;
        }
        if (res.data.length == 0) {
          THIS.InvLabEmpty = 1;
        }
      }
    });
  }

  updateInventory() {
    this.attachInventories.length = 0;
    let attachInventories = [];
    $.each(this.InvLabData, function (i, v) {
      if (v.pick != '' && v.sell_price != '' && v.pick != null && v.sell_price != null) {
        attachInventories.push({
          type_id: 0,
          inv_id: v.id,
          title: v.title,
          retail_price: v.sell_price,
          pick: v.pick,
        });
      }
    });
    $.each(this.LabourData, function (i, v) {
      if (v.pick != '' && v.rate != '' && v.pick != null && v.rate != null) {
        attachInventories.push({
          type_id: 1,
          inv_id: v.id,
          title: v.name,
          retail_price: v.rate,
          pick: v.pick,
        });
      }
    });
    this.attachInventories = attachInventories;
    (<any>$('#invLabModal')).modal('hide');
    if (this.inlinePrefixId == 0) {
      this.qckUpdate();
    }
  }


  addWorkAttach() {
    $('#workAttach').click();
  }

  removeThisAttach(index, id) {
    this.selectedWorkAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        let eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
    setTimeout(function () {
      if (THIS.inlinePrefixId == 0) {
        THIS.qckUpdate();
      }
    }, 5000);
  }

  progress = 0;
  rightSecPreview = 0;


  SaveAction = 0;
  SA_fac = 0;
  SA_status = 0;
  SA_type = 0;
  SA_pr = 0;
  SA_asset = 0;
  SA_person = 0;

  checkSaveAction() {
    if (this.SA_fac == 1 && this.SA_status == 1 && this.SA_type == 1 && this.SA_pr == 1 && this.SA_asset == 1 && this.SA_person == 1) {
      this.SaveAction = 1;
    } else {
      this.SaveAction = 0;
    }
  }


  // ==============================
  // Presence
  // ==============================
  __singlepreview() {
    let THIS = this;
    THIS.CustomHelper.woPresence = THIS.id;
  }

  __editpreview() {
    let THIS = this;
    THIS.CustomHelper.woPresence = THIS.id;
  }

  quickSinglePreview() {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (this.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Discard Alert',
        text: 'Are you sure you want to discard?',
        icon: 'warning',
        buttons: ['Stay on this page', 'Leave page'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          THIS.inlinePrefixId = 0;
          setTimeout(function () {
            (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
            (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
            (<any>$('.chosen-default-multiple-asset')).chosen({
              allow_single_deselect: true,
              placeholder_text_multiple: 'No asset selected'
            }).on('change', function (e, p) {
              if (p.selected != undefined) {
                THIS.AssetSelect.push(p.selected);
              } else {
                THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
              }
              THIS.param.asset_id = THIS.AssetSelect.join(',');
              THIS.updateChangesDiscard();
            });
            THIS.SA_asset = 1;
            THIS.checkSaveAction();
          }, 100);
          setTimeout(function () {
            let work = THIS.workDetails;
            if (work.asset.length > 0 && document.getElementById('geo-map-single') != null) {
              let mapStart = 0;
              THIS.CMap = null;
              let latlng = [];
              let labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
              let labelIndex = 0;
              let infowindow = [];
              $.each(work.asset, function (i, v) {
                if (v.geo != '' && v.geo != null) {
                  let geo = JSON.parse(v.geo);
                  if (mapStart == 0) {
                    mapStart = 1;
                    let mapOptions = {
                      zoom: 0,
                      center: new google.maps.LatLng(33.8688, 151.2093),
                      gestureHandling: 'cooperative',
                      mapTypeControl: false,
                      streetViewControl: false,
                      zoomControl: false
                    };
                    THIS.CMap = new google.maps.Map(document.getElementById('geo-map-single'), mapOptions);
                  }
                  if (THIS.CMap != null) {
                    latlng.push(geo.geometry);
                    var marker = new google.maps.Marker({
                      position: geo.geometry,
                      map: THIS.CMap,
                      draggable: false,
                      title: geo.address,
                      label: labels[labelIndex++ % labels.length]
                    });
                    infowindow[i] = new google.maps.InfoWindow({
                      content: `<div class="width-100" style="min-width: 600px"><h4 style="text-align: center">` + v.name + `</h4>
                                                        <div class="width-100">
                                                        <div class="margin-10"></div>
                                                        <div class="margin-10"></div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Bran/Model</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.brand + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Serial Number</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.serial_number + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar"><i class="nav-icon material-icons _theme-color">money</i><strong>Purchase Cost</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.purchase_cost + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="margin-10">
                                                        </div>
                                                        <div class="margin-10">
                                                        </div></div></div>`
                    });
                    marker.addListener('click', function () {
                      $.each(infowindow, function (iw, inf) {
                        if (inf != i) {
                          infowindow[iw].close();
                        }
                      });
                      infowindow[i].open(THIS.CMap, marker);
                    });
                  }
                }
              });
              let latlngbounds = new google.maps.LatLngBounds();
              for (let i = 0; i < latlng.length; i++) {
                latlngbounds.extend(latlng[i]);
              }
              if (THIS.CMap != null) {
                THIS.CMap.fitBounds(latlngbounds);
                new google.maps.Rectangle({
                  bounds: latlngbounds,
                  map: THIS.CMap,
                  fillColor: 'transparent',
                  fillOpacity: 0.1,
                  strokeWeight: 0
                });
              }

            }
          });
          THIS._albums.length = 0;
          if (THIS.workDetails.media_files !== undefined) {
            for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
              const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path;
              const caption = '';
              const thumb = '';
              const album = {
                src: src,
                caption: caption,
                thumb: thumb,
                w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
                h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
              };
              THIS._albums.push(album);
            }
          }
          setTimeout(function () {
            (<any>$('#summerDesc')).html(THIS.param.description);
            (<any>$('#woSubject')).val(THIS.param.subject);
          }, 100);
          THIS.__singlepreview();
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
        }
      });
    } else {
      THIS.inlinePrefixId = 0;
      setTimeout(function () {
        (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
        (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
        (<any>$('.chosen-default-multiple-asset')).chosen({
          allow_single_deselect: true,
          placeholder_text_multiple: 'No asset selected'
        }).on('change', function (e, p) {
          if (p.selected != undefined) {
            THIS.AssetSelect.push(p.selected);
          } else {
            THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
          }
          THIS.param.asset_id = THIS.AssetSelect.join(',');
          THIS.updateChangesDiscard();
        });
        THIS.SA_asset = 1;
        THIS.checkSaveAction();
      }, 100);
      setTimeout(function () {
        let work = THIS.workDetails;
        if (work.asset.length > 0 && document.getElementById('geo-map-single') != null) {
          let mapStart = 0;
          THIS.CMap = null;
          let latlng = [];
          let labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
          let labelIndex = 0;
          let infowindow = [];
          $.each(work.asset, function (i, v) {
            if (v.geo != '' && v.geo != null) {
              let geo = JSON.parse(v.geo);
              if (mapStart == 0) {
                mapStart = 1;
                let mapOptions = {
                  zoom: 0,
                  center: new google.maps.LatLng(33.8688, 151.2093),
                  gestureHandling: 'cooperative',
                  mapTypeControl: false,
                  streetViewControl: false,
                  zoomControl: false
                };
                THIS.CMap = new google.maps.Map(document.getElementById('geo-map-single'), mapOptions);
              }
              if (THIS.CMap != null) {
                latlng.push(geo.geometry);
                var marker = new google.maps.Marker({
                  position: geo.geometry,
                  map: THIS.CMap,
                  draggable: false,
                  title: geo.address,
                  label: labels[labelIndex++ % labels.length]
                });
                infowindow[i] = new google.maps.InfoWindow({
                  content: `<div class="width-100" style="min-width: 600px"><h4 style="text-align: center">` + v.name + `</h4>
                                                        <div class="width-100">
                                                        <div class="margin-10"></div>
                                                        <div class="margin-10"></div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Bran/Model</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.brand + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Serial Number</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.serial_number + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar"><i class="nav-icon material-icons _theme-color">money</i><strong>Purchase Cost</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.purchase_cost + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="margin-10">
                                                        </div>
                                                        <div class="margin-10">
                                                        </div></div></div>`
                });
                marker.addListener('click', function () {
                  $.each(infowindow, function (iw, inf) {
                    if (inf != i) {
                      infowindow[iw].close();
                    }
                  });
                  infowindow[i].open(THIS.CMap, marker);
                });
              }
            }
          });
          let latlngbounds = new google.maps.LatLngBounds();
          for (let i = 0; i < latlng.length; i++) {
            latlngbounds.extend(latlng[i]);
          }
          if (THIS.CMap != null) {
            THIS.CMap.fitBounds(latlngbounds);
            new google.maps.Rectangle({
              bounds: latlngbounds,
              map: THIS.CMap,
              fillColor: 'transparent',
              fillOpacity: 0.1,
              strokeWeight: 0
            });
          }

        }
      });
      THIS._albums.length = 0;
      if (THIS.workDetails.media_files !== undefined) {
        for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
          const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path;
          const caption = '';
          const thumb = '';
          const album = {
            src: src,
            caption: caption,
            thumb: thumb,
            w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
            h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
          };
          THIS._albums.push(album);
        }
      }
      setTimeout(function () {
        (<any>$('#summerDesc')).html(THIS.param.description);
        (<any>$('#woSubject')).val(THIS.param.subject);
      }, 100);
      THIS.__singlepreview();
    }
  }

  quickSingleEdit() {
    let THIS = this;
    this.inlinePrefixId = 1;
    setTimeout(function () {
      (<any>$('.chosen-default-single-fac')).chosen('destroy');
      (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
      (<any>$('.chosen-default-single-fac')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Facility'
      }).on('change', function (e, p) {
        THIS.param.facility_id = e.target.value;
        THIS.validationThisForm();
        THIS.updateChangesDiscard();
      });
      THIS.SA_fac = 1;
      THIS.checkSaveAction();
    }, 100);
    setTimeout(function () {
      (<any>$('.chosen-default-single-status')).val(THIS.param.status_code);
      (<any>$('.chosen-default-single-status')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select job status'
      }).on('change', function (e, p) {
        THIS.param.status_code = e.target.value;
        THIS.updateChangesDiscard();
      });
      THIS.SA_status = 1;
      THIS.checkSaveAction();
    }, 100);
    setTimeout(function () {
      (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
      (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
      (<any>$('.chosen-default-multiple-asset')).chosen({
        allow_single_deselect: true,
        placeholder_text_multiple: 'No asset selected'
      }).on('change', function (e, p) {
        if (p.selected != undefined) {
          THIS.AssetSelect.push(p.selected);
        } else {
          THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
        }
        THIS.param.asset_id = THIS.AssetSelect.join(',');
        THIS.updateChangesDiscard();
      });
      THIS.SA_asset = 1;
      THIS.checkSaveAction();
    }, 100);
    setTimeout(function () {
      (<any>$('.chosen-default-single-jti')).val(THIS.param.job_types_id);
      (<any>$('.chosen-default-single-jti')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Job Type'
      }).on('change', function (e, p) {
        THIS.param.job_types_id = e.target.value;
        THIS.updateChangesDiscard();
      });
      THIS.SA_type = 1;
      THIS.checkSaveAction();
    }, 100);
    setTimeout(function () {
      (<any>$('.chosen-default-single-priority')).val(THIS.param.priority);
      (<any>$('.chosen-default-single-priority')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Priority'
      }).on('change', function (e, p) {
        THIS.updateChangesDiscard();
        let index = THIS.Priority.map(function (element) {
          return element.id;
        }).indexOf(parseInt(e.target.value));
        let priority = (index > -1) ? THIS.Priority[index] : null;
        if (priority != null) {
          THIS.param.priority = priority.id.toString();
          if (THIS.param.created_at != null && THIS.param.created_at != '') {
            let v = new Date(THIS.dateCreated);
            let min = THIS.CustomHelper.makeFloat(priority.amount) * THIS.CustomHelper.makeFloat(priority.duration);
            v.setMinutes(v.getMinutes() + min);
            THIS.dateDue = THIS.CustomHelper.formatDateTimeToDB(v);
            THIS.changingStart = 1;
            setTimeout(function () {
              THIS.dateDueOption.minDate = new Date(THIS.dateCreated);
              THIS.dateDueOption.defaultDate = new Date(THIS.dateDue);
              THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue));
              THIS.changingStart = 0;
            }, 400);
          }
          console.log(THIS.param.created_at);
        } else {
          THIS.param.priority = '0';
          if (THIS.param.created_at != null && THIS.param.created_at != '') {
            THIS.param.due = THIS.dateDue != null && THIS.dateDue != '' ? THIS.CustomHelper.formatDateTimeToDB(new Date(THIS.dateDue)) : '';
          }
        }
      });
      THIS.SA_pr = 1;
      THIS.checkSaveAction();
    }, 100);
    setTimeout(function () {
      let pp_val = THIS.param.personnel_id;
      (<any>$('.chosen-default-multiple-per')).val(pp_val);
      (<any>$('.chosen-default-multiple-per')).chosen('destroy');
      (<any>$('.chosen-default-multiple-per')).chosen({
        allow_single_deselect: true,
        placeholder_text_multiple: 'No personnel selected'
      }).on('change', function (e, p) {
        if (p.selected != undefined) {
          let v = p.selected;
          THIS.param.personnel_id.push(v.toString());
        } else {
          let v = p.deselected;
          if (THIS.param.personnel_id.indexOf(v) > -1) {
            let ssCount = 0;
            let ssSor = [];
            $.each(THIS.param.sor, function (i, sor_v) {
              if (sor_v.user_id == v) {
                ssCount++;
              } else {
                ssSor.push(sor_v);
              }
            });
            if (ssCount > 0) {
              swal({
                title: 'SOR Assign Alert',
                text: 'This personnel has been attached with ' + ssCount + ' SOR item. If you remove, all sor history will be gone also',
                icon: 'warning',
                buttons: ['Cancel', 'Remove'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
                  THIS.param.sor = ssSor;
                } else {
                  (<any>$('.chosen-default-multiple-per')).val(THIS.param.personnel_id);
                  (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
                }
              });
            } else {
              THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
            }
          }
        }
        THIS.updateChangesDiscard();
      });
      THIS.SA_person = 1;
      THIS.checkSaveAction();
    }, 100);
    setTimeout(function () {
      (<any>$('.summernote')).summernote({
        maxHeight: '900px',
        minHeight: '150px',
        toolbar: [
          ['style', ['bold', 'underline', 'clear']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['link', 'video']],
          ['misc', ['fullscreen']]
        ],
        callbacks: {
          onKeyup: function (e) {
            THIS.updateChangesDiscard();
          }
        }
      });
      (<any>$('.summernote')).summernote('code', THIS.param.description);
      (<any>$('#woSubject')).val(THIS.param.subject);
    }, 100);
    this.__editpreview();
    this.__editpreview();
  }


  SorAssigned = {org: [], person: []};

  sorOpen() {
    let THIS = this;
    this.SOR_projectItems.length = 0;
    this.SorAssigned.org.length = 0;
    this.SorAssigned.person.length = 0;
    if (this.param.personnel_id.length > 0) {
      $.each(THIS.Personnel.org, function (i, v) {
        if (v.user_type != 6) {
          if (THIS.param.personnel_id.indexOf(THIS.CustomHelper.makeStr(v.id)) > -1) {
            THIS.SorAssigned.org.push(v);
          }
        }
      });
      $.each(THIS.Personnel.person, function (i, v) {
        if (v.user_type != 3) {
          if (THIS.param.personnel_id.indexOf(THIS.CustomHelper.makeStr(v.id)) > -1) {
            THIS.SorAssigned.person.push(v);
          }
        }
      });
      this.getAllClients();

      let ___selectedTab = 0;
      let tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      let child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#sorModal')).modal('show');
    } else {
      swal({
        title: 'No Personnel Assigned',
        text: 'To add SOR you first have to assign personnel to this work order',
        icon: 'warning',
        dangerMode: true,
      });
    }
  }

  SOR_clients = [];
  SOR_client = '';
  SOR_Person = '';

  getAllClients() {
    let THIS = this;
    (<any>$('.chosen-default-single-sor-per')).val('');
    (<any>$('.clientData')).val('');
    (<any>$('.projectData')).val('');
    if (this.currentUser.user_type > 2) {
      THIS.SOR_Person = this.currentUser.id;
    }
    $.ajax({
      url: THIS.APPCONFIG.api + 'sor/project/getAllSor?token=' + THIS.currentUser.token,
      type: 'get',
      data: {},
      success: function (res) {
        if (res.status == 2000) {
          THIS.SOR_clients = res.data;
          setTimeout(function () {
            (<any>$('.clientData')).chosen('destroy');
            (<any>$('.clientData')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Client'
            }).on('change', function (e, p) {
              if (p != undefined) {
                THIS.SOR_client = p.selected;
                THIS.getAllProjects();
              }
            });
            (<any>$('.projectData')).chosen('destroy');
            (<any>$('.projectData')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Project'
            });
            (<any>$('.chosen-default-single-sor-per')).chosen('destroy');
            (<any>$('.chosen-default-single-sor-per')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Personnel'
            }).on('change', function (e, p) {
              if (p != undefined) {
                THIS.SOR_Person = p.selected;
              }
              THIS.getAllProjects();
            });
          }, 1000);
        }
      }
    });
  }

  SOR_projects = [];
  SOR_project = '';

  getAllProjects() {
    let THIS = this;
    THIS.SOR_projects.length = 0;
    if (THIS.SOR_Person == '') {
      swal({
        title: 'Personnel Selection',
        text: 'Please choose a personnel first whom to add SOR',
        icon: 'warning',
        dangerMode: true,
      });
    }
    if (THIS.SOR_client != '') {
      $.ajax({
        url: THIS.APPCONFIG.api + 'sor/project/getAll?token=' + THIS.currentUser.token,
        type: 'post',
        data: {sor_id: THIS.SOR_client, personId: THIS.SOR_Person, onlyMe: 1, workId: THIS.workDetails.id},
        success: function (res) {
          if (res.status == 2000) {
            THIS.SOR_projects = res.data;
            setTimeout(function () {
              (<any>$('.projectData')).chosen('destroy');
              (<any>$('.projectData')).chosen({
                allow_single_deselect: true,
                placeholder_text_single: 'Select Project'
              }).on('change', function (e, p) {
                if (p != undefined) {
                  THIS.SOR_project = p.selected;
                  THIS.getAllProjectItems();
                }
              });
            }, 1000);
          }
        }
      });
    }
  }

  SOR_projectItems = [];
  SOR_projectItem_start = 0;
  SOR_projectItem_end = 10;
  SOR_projectItem_pageNo = 1;
  SOR_empty = 0;

  getAllProjectItems() {
    let THIS = this;

    this.SOR_projectItem_start = 0;
    this.SOR_projectItem_end = 10;
    this.SOR_projectItem_pageNo = 1;

    let index = this.SOR_projects.map(function (e) {
      return e.id.toString();
    }).indexOf(THIS.SOR_project);
    if (index > -1) {
      this.SOR_empty = 0;
      this.SOR_projectItems = this.SOR_projects[index].info;
      if (this.SOR_projectItems.length == 0) {
        this.SOR_empty = 1;
      }
      if (this.SOR_projectItems.length > 0) {
        for (let i = 0; i < this.SOR_projectItems.length; i++) {
          let v = this.SOR_projectItems[i];
          let indexP = this.param.sor.map(function (e) {
            return e.id;
          }).indexOf(v.id);
          if (indexP > -1) {
            if (this.param.sor[indexP].user_id == THIS.SOR_Person) {
              this.SOR_projectItems[i].wo_qty = this.param.sor[indexP].wo_qty;
            }
          }
        }
      }
    }
  }

  SOR_pro_item_pagination(type) {
    if (type == 'next') {
      this.SOR_projectItem_pageNo++;
      this.SOR_projectItem_start = (this.SOR_projectItem_pageNo - 1) * 10;
      this.SOR_projectItem_end = (this.SOR_projectItem_pageNo - 0) * 10;
      if (this.SOR_projectItem_end > this.SOR_projectItems.length) {
        this.SOR_projectItem_end = this.SOR_projectItems.length;
      }
    }
    if (type == 'prev') {
      if (this.SOR_projectItem_pageNo > 1) {
        this.SOR_projectItem_pageNo--;
        this.SOR_projectItem_start = (this.SOR_projectItem_pageNo - 1) * 10;
        this.SOR_projectItem_end = (this.SOR_projectItem_pageNo - 0) * 10;
        if (this.SOR_projectItem_end > this.SOR_projectItems.length) {
          this.SOR_projectItem_end = this.SOR_projectItems.length;
        }
      }
    }
  }

  levelIt(index) {
    let THIS = this;
    /*setTimeout(function(){
            console.log(THIS.SOR_projectItems[index].wo_qty , THIS.SOR_projectItems[index].qty);
            if(THIS.SOR_projectItems[index].wo_qty  THIS.SOR_projectItems[index].qty){
                THIS.SOR_projectItems[index]['wo_qty'] = THIS.SOR_projectItems[index].qty;
            }
        }, 500);*/
  }

  saveSorUserData() {
    let THIS = this;
    let Data = this.SOR_projectItems;
    let OldData = THIS.param.sor;
    let items = [];
    $.each(OldData, function (i, v) {
      if (v.user_id != THIS.SOR_Person) {
        items.push(v);
      }
    });

    $.each(Data, function (i, v) {
      if (v.wo_qty != null) {
        v['user_id'] = THIS.SOR_Person;
        items.push(v);
      }
    });
    THIS.param.sor = items;
    (<any>$('#sorModal')).modal('hide');
    if (this.inlinePrefixId == 0) {
      this.qckUpdate();
    }
  }

  removeSORQuick() {
    if (this.inlinePrefixId == 0) {
      this.qckUpdate();
    }
  }


  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_fac_'+Session.team, function (v) {
            THIS.getAllFacility(1);
        });

        this.socket.on('_rfr_team_data_asset_'+Session.team, function (v) {
            THIS.asset_get(1);
        });

        this.socket.on('_rfr_team_data_jobType_'+Session.team, function (v) {
            THIS.jt_get(1);
        });

        this.socket.on('_rfr_team_data_jobStatus_'+Session.team, function (v) {
            THIS.getAllJobStatus(1);
        });*/
  }

  ngOnDestroy() {
  }


  receiveSocket() {
    let THIS = this;
    /*this.socket.on('wo_comment_'+THIS.workDetails.id, function (v) {
            try{
                let com = JSON.parse(v.attrs.comment);
                v['commentT'] = com.comment;
                v['images'] = com.image;
            } catch (e){
                // Nothing to do
            }
            let albumT = [];
            if(v.images !== undefined && v.images.length){
                for (let i = 0; i < v.images.length; i++) {
                    const src = APPCONFIG.uploadPreview+'/images?w=800&img='+ v.images[i].file_path;
                    const caption = '';
                    const thumb = '';
                    const album = {
                        src: src,
                        caption: caption,
                        thumb: thumb,
                        w: v.images[i].width !== undefined ? v.images[i].width : 1500,
                        h: v.images[i].height !== undefined ? v.images[i].height : 1500
                    };
                    albumT.push(album);
                }
            }
            v['album'] = albumT;
            v['created_at_human'] = THIS.CustomHelper.formatDateTime(v.created_at);
            v['updated_at_human'] = THIS.CustomHelper.formatDateTime(v.updated_at);
            let tmI = THIS.timeLineList.map(function (element) {return element.id;}).indexOf(v.id);
            if(tmI == -1){
                THIS.timeLineList.push(v);
                setTimeout(function () {
                    THIS.auto_grow();
                    let tarCom = $('.CommentScrool');
                    if(tarCom.length > 0){
                        tarCom[0].scrollTop = tarCom[0].scrollHeight;
                    }
                    let tarComP = $('.Comment-Section-Train-way');
                    if(tarComP.length > 0){
                        tarComP[0].scrollTop = tarComP[0].scrollHeight;
                    }
                }, 500);
            }
        });
        this.socket.on('wo_comment_upd_'+THIS.workDetails.id, function (v) {
            try{
                let com = JSON.parse(v.attrs.comment);
                v['commentT'] = com.comment;
                v['images'] = com.image;
            } catch (e){
                // Nothing to do
            }
            let albumT = [];
            if(v.images !== undefined && v.images.length){
                for (let i = 0; i < v.images.length; i++) {
                    const src = APPCONFIG.uploadPreview+'/images?w=800&img='+ v.images[i].file_path;
                    const caption = '';
                    const thumb = '';
                    const album = {
                        src: src,
                        caption: caption,
                        thumb: thumb,
                        w: v.images[i].width !== undefined ? v.images[i].width : 1500,
                        h: v.images[i].height !== undefined ? v.images[i].height : 1500
                    };
                    albumT.push(album);
                }
            }
            v['album'] = albumT;
            v['created_at_human'] = THIS.CustomHelper.formatDateTime(v.created_at);
            v['updated_at_human'] = THIS.CustomHelper.formatDateTime(v.updated_at);

            let index = THIS.timeLineList.map(function(e) { return e.id; }).indexOf(v.id);
            THIS.timeLineList[index] = v;
            setTimeout(function () {
                THIS.auto_grow();
            }, 1000);
        });
        this.socket.on('wo_comment_del_'+THIS.workDetails.id, function (v) {
            let index = THIS.timeLineList.map(function(e) { return e.id; }).indexOf(v.id);
            if(index > -1){
                THIS.timeLineList[index].is_active = 0;
            }
        });*/
  }


  // =============================
  // Photo Editor
  // =============================
  CANVAS = null;
  SHAPE = 'brush';
  IMAGE_PATH = '';
  CanvasTarget = 0;

  discardEditor() {
    let THIS = this;
    let modal = (<any>$('#photoEditorModal'));
    if (THIS.CANVAS != null) {
      THIS.CANVAS.dispose();
    }
    modal.modal('hide');

  }

  editImgCallUnique = 0;
  EditCurrentCanvas = null;
  CurrentEditPhoto = null;

  openPhotoEditor(preview) {
    let THIS = this;
    THIS.CurrentEditPhoto = preview;
    THIS.CanvasTarget = 0;
    let modal = (<any>$('#photoEditorModal'));
    let canvasW = window.innerWidth - 20;
    let canvasH = window.innerHeight - 130;
    $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
    if (THIS.CANVAS != null) {
      this.CANVAS.clear();
    }
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    let param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

    if (THIS.editImgCallUnique == 0) {
      THIS.editImgCallUnique = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          THIS.editImgCallUnique = 0;
          if (res.status === 2000) {
            THIS.EditCurrentCanvas = res.data;
            let canvasW = res.data.width;
            let canvasH = res.data.height;
            $('#canvas').attr('width', canvasW + 'px');
            $('#canvas').attr('height', canvasH + 'px');
            setTimeout(function () {
              // if(THIS.CANVAS != null){
              //     THIS.CANVAS.dispose();
              // }

              THIS.CANVAS = new fabric.Canvas('canvas');
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.on('object:selected', function (e) {
                THIS.CANVAS.isDrawingMode = false;
                THIS.SHAPE = '';
              });
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.isDrawingMode = true;
              THIS.CANVAS.freeDrawingBrush.width = 5;

              let startY = 0,
                startX = 0;
              THIS.CANVAS.on('mouse:down', function (option) {
                startY = option.e.offsetY;
                startX = option.e.offsetX;
              });
              THIS.CANVAS.on('mouse:up', function (option) {
                let selected = THIS.CANVAS.getActiveObjects();
                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                  if (THIS.SHAPE === 'circle') {
                    let e = option.e;
                    if (e.offsetX - startX > 5) {
                      let circle = new fabric.Circle({
                        top: startY,
                        left: startX,
                        radius: (e.offsetX - startX) / 2,
                        stroke: THIS.CANVAS.freeDrawingBrush.color,
                        strokeWidth: 5,
                        fill: ''
                      });
                      THIS.CANVAS.add(circle);
                    }
                  }
                }
              });
              THIS.CustomHelper.tooltipRender();

              THIS.IMAGE_PATH = preview.file_path;
              let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
              $('.canvas-container').css({
                backgroundImage: 'url(\'' + src + '\')'
              });
              THIS.CANVAS.renderAll();
              setTimeout(function () {
                THIS.CanvasTarget = 1;
              }, 1000);
            }, 1000);
          }
        }
      });


      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        modal.modal('hide');
        history.go(1);
      };

      if ($('.color-picker-editor').length > 0) {
        Pickr.create({
          el: '.color-picker-editor',
          theme: 'nano', // or 'monolith', or 'nano'
          defaultRepresentation: 'HEX',
          useAsButton: true,
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: true,
              input: true,
              save: true
            }
          }
        }).on('change', (color, instance) => {
          $('.color-picker-editor').css('color', color.toHEXA().toString());
          THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
          THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
          });
        });
      }
      $('.color-picker-editor').css('color', '#ff3773');
    }
  }

  // =============================
  // Photo Editor Actions
  // =============================
  SketchEnable() {
    this.CANVAS.isDrawingMode = !this.CANVAS.isDrawingMode;
    if (this.CANVAS.isDrawingMode === true) {
      this.SHAPE = 'brush';
    }
  }

  eraser() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    let obj = THIS.CANVAS.getObjects();
    for (let i = 0; i < obj.length; i++) {
      $.each(obj, function (j, v) {
        if (parseInt(j) > 0) {
          THIS.CANVAS.remove(v);
        }
      });
    }
    THIS.UID_IMG = Date.now();
    let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
    $('.canvas-container').css({
      backgroundImage: 'url(\'' + src + '\')'
    });
  }

  eraserSelected() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
      THIS.CANVAS.remove(obj);
    });
  }

  drawCircle() {
    let THIS = this;
    this.CANVAS.isDrawingMode = false;
    this.SHAPE = 'circle';
    let circle = new fabric.Circle({
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      radius: 50,
      stroke: THIS.CANVAS.freeDrawingBrush.color,
      strokeWidth: 5,
      fill: ''
    });
    THIS.CANVAS.add(circle);
  }

  UpdatePhoto(iid) {
    let THIS = this;
    let ldd = document.getElementById(iid);
    let ladda = Ladda.create(ldd);
    ladda.start();
    THIS.CANVAS.discardActiveObject();
    THIS.CANVAS.requestRenderAll();
    setTimeout(function () {
      let modal = (<any>$('#photoEditorModal'));
      let canvas = (<any>document.getElementById('canvas'));
      let imgData = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      imgData = imgData.replace('data:image/png;base64,', '');
      let param = {
        imgData: imgData,
        media_type: 1,
        file_path: THIS.IMAGE_PATH
      };
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          ladda.stop();
          modal.modal('hide');
          THIS.UID_IMG = Date.now();
        }
      });
    }, 1000);
  }


  UID_IMG = 0;
  Gallery = null;
  Preview;

  open(index, preview) {
    let THIS = this;
    THIS.Preview = preview;
    $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    let _albums = [];
    if (THIS.workDetails.media_files !== undefined) {
      for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
        const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + THIS.workDetails.media_files[i].file_path + '&ref=' + Date.now();
        const caption = '';
        const thumb = '';
        const album = {
          src: src,
          caption: caption,
          thumb: thumb,
          w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
          h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
        };
        _albums.push(album);
      }
    }
    let pswpElement = document.querySelectorAll('.pswp')[0];
    let options = {
      index: index,
      shareEl: false,
      editEl: true,
      tapToClose: false,
      zoomEl: true,
      history: false
    };
    THIS.Gallery = null;
    THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
    THIS.Gallery.init();
    THIS.Gallery.listen('afterChange', function (r) {
      THIS.Preview = THIS.workDetails.media_files[THIS.Gallery.getCurrentIndex()];
      $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    });
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      THIS.Gallery.close();
      history.go(1);
    };

  }

  openTimelineLight(index, indexT, preview) {
    let THIS = this;
    THIS.Preview = preview[0];
    $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    let _albums = [];
    if (THIS.timeLineList[indexT].album !== undefined) {
      for (let i = 0; i < THIS.timeLineList[indexT].album.length; i++) {
        const src = THIS.timeLineList[indexT].album[i].src + '&ref=' + Date.now();
        const caption = '';
        const thumb = '';
        const album = {
          src: src,
          caption: caption,
          thumb: thumb,
          w: THIS.timeLineList[indexT].album[i].w !== undefined ? THIS.timeLineList[indexT].album[i].w : 1500,
          h: THIS.timeLineList[indexT].album[i].h !== undefined ? THIS.timeLineList[indexT].album[i].h : 1500
        };
        _albums.push(album);
      }
    }
    let pswpElement = document.querySelectorAll('.pswp')[0];
    let options = {
      index: index,
      shareEl: false,
      editEl: true,
      tapToClose: false,
      zoomEl: true,
      history: false
    };
    THIS.Gallery = null;
    THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
    THIS.Gallery.init();
    THIS.Gallery.listen('afterChange', function (r) {
      THIS.Preview = preview[THIS.Gallery.getCurrentIndex()];
      $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    });
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      THIS.Gallery.close();
      history.go(1);
    };
  }
  currencyIcon():string {    
      if(this.currentUser.settings.currency == 'USD') {
        return '$';
      } else {
        return this.currentUser.settings.currency + ' ';
      }
  }

}
