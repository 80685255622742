
import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
declare let toastr: any;

declare let swal: any;
declare let Ladda: any;

@Component({
  selector: 'page-hive',
  templateUrl: './hive.component.html',
  styles: []
})
export class HiveComponent {
  // =======================
  // General Variables
  // =======================
  currentUser;
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  Permission = 1;
  Template = 1;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  embed = '';
  src = '';
  ListData: any = [];
  singleData: any = null;
  ROUTES = '/hive';
  param: any = {};
  manageParam: any = {
    title: '',
    contents: '',
  };
  singleParam: any = {
    id: '',
  };
  selectedIds = [];
  editHive = false;
  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
  }
  GetData() {
    $.ajax({
      url: `${APPCONFIG.api}hive/get/list?token=${this.currentUser.token}`,
      type: 'post',
      data: this.manageParam,
      success:  (res) => {
        if (res.status === 2000) {
          this.ListData = res.data;
        } else {
        }
      }
    });
  }
  checkThisRow(e, hive) {
    e.preventDefault();
    let index = this.selectedIds.indexOf(hive.id);
    if (index > -1) {
      if (this.selectedIds.length == 1) {
        this.selectedIds.length = 0;
      } else {
        this.selectedIds.splice(index, 1);
      }
    } else {
      this.selectedIds.push(hive.id);
    }
    $('.drop-menu-right').removeClass('open');
    setTimeout(function () {
      this.CustomHelper.tooltipRender();
    }, 100);

  }
  ManageHiveModal(type: any, data: any) {
    $('.invalid-feedback').html('');
    if ( data !== null ) {
      this.manageParam.title = data.title;
      this.manageParam.contents = data.contents;
      this.manageParam.id = data.id;
      this.editHive = true;
    } else {
      this.editHive = false;
    }
    if ( type === 1) {
      let ___selectedTab = 0;
      let tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      let child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#ManageHive')).modal('show');
    } else {
      this.manageParam = {
        title: '',
        contents: '',
      };
      (<any>$('#ManageHive')).modal('hide');
    }
  }
  ManageData(e: any) {
    e.preventDefault();
    let ldd = document.getElementById('hiveSaveBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $('.invalid-feedback').html('');
    let url: any = null;
    if ( this.manageParam.id != null ) {
      url = `${APPCONFIG.api}hive/edit?token=${this.currentUser.token}`;
    } else {
      url = `${APPCONFIG.api}hive/create?token=${this.currentUser.token}`;
    }
    $.ajax({
      url: url,
      type: 'post',
      data: this.manageParam,
      success:  (res) => {
        ladda.stop();
        if (res.status === 2000) {
          if (this.editHive) {
            toastr.success('Hive has been updated successfully', 'Hive Update', this.toastrOption);
          } else {
            toastr.success('Hive has been created successfully', 'Hive Create', this.toastrOption);
          }
          this.ManageHiveModal(2, null);
          this.GetData();
          this.manageParam = {
            title: '',
            contents: '',
          };
        } else {
          this.ErrorHandler(res.error);
        }
      }
    });
  }
  ErrorHandler(error: any) {
    $.each(error, function(i: any, v: any) {
      $('[name=' + i + ']').closest('.form-group').find('.invalid-feedback').html(v);
    });
  }
  ViewHiveModal(type: any, data: any) {
    $('.invalid-feedback').html('');
    if ( data !== null ) {
      this.singleParam.id = data.id;
      this.GetSingleData();
    }
    if ( type === 1) {
      let ___selectedTab = 0;
      let tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      let child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#ViewHive')).modal('show');
    } else {
      (<any>$('#ViewHive')).modal('hide');
    }
  }

  GetSingleData() {
    $.ajax({
      url: `${APPCONFIG.api}hive/get/single?token=${this.currentUser.token}`,
      type: 'post',
      data: this.singleParam,
      success:  (res) => {
        if (res.status === 2000) {
          this.singleData = res.data;
        } else {
        }
      }
    });
  }
  ngOnInit() {
    this.GetData();
  }


}
