import {Component, EventEmitter, Output, Input, TemplateRef, SimpleChanges} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ContactsService} from '../../../_services/contacts.service';
import {InvoicesService} from '../../../_services/invoices.service';
import { CustomHelper } from '../../../customhelper';
import { FacilityService } from '../../../_services/facility.service';
import {FcmService} from '../../../_services/fcm.service';
declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let Inputmask: any;

@Component({
    selector: 'edit-recurring-invoice',
    templateUrl: './editRecurring.component.html'
})
export class EditRecurringComponent {
    @Input() preId: any;
    @Output() discard = new EventEmitter<number>();
    @Output() emailIt = new EventEmitter<number>();
    CustomHelper = CustomHelper;
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};

    toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{'color': []}, {'background': []}],
        [{'font': []}],
        [{'align': []}],
        ['clean']
    ];
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.preId != undefined){
            let preId = changes.preId.currentValue;
            this.getSingleInv(preId);
        }
    }
    invoice = null;
    getSingleInv(preId){
        this.invoice = null;
        let param = {id : preId};
        this.invoicesService.getRecurInvoiceSingle(param).subscribe(res => {
            if(res.status == 2000){
                res.data['invoice_date'] = this.CustomHelper.formatDate(res.data['invoice_date']);
                res.data['nextCreation'] = this.CustomHelper.DateTimeSelectedFormat(res.data['nextCreation']);
                res.data['dates']['start'] = this.CustomHelper.DateTimeSelectedFormat(res.data['dates']['start']);
                res.data['dates']['expire'] = res.data['dates']['expire'] == null ? '' : this.CustomHelper.DateTimeSelectedFormat(res.data['dates']['expire']);

                this.param = {
                    id: res.data.id,
                    work_id: res.data.work_id,
                    facility_id: res.data.facility.id,
                    contact_id: res.data.contact.id,
                    invoice_date : res.data.invoice_date,
                    order_number : res.data.order_number,
                    terms : res.data.terms,
                    tax_type : res.data.tax_type,
                    tax_value : res.data.tax_value,
                    customer_notes : res.data.customer_notes,
                    customer_note_on : res.data.customer_note_on,
                    terms_condition : res.data.terms_condition,
                    term_on : res.data.term_on,
                    items : res.data.items,
                    repeat : res.data.repeat,
                    repeat_days : res.data.repeat_days,
                    start : res.data.dates.start,
                    expire : res.data.dates.expire == null ? '' : res.data.dates.expire,
                };
                this.createCartItems = res.data.items;
                this.createCartItemsSubTotal = res.data.sub_total;
                this.createCartItemsTax = res.data.tax_value;
                this.createCartItemsTotal = res.data.total;
                this._invDateConfig = {
                    timePicker: false,
                    defaultDate: new Date(this.param.invoice_date),
                    dateFormat: this.CustomHelper.dateFormatFlat(),
                    onChange: function(selectedDates, dateStr, instance) {}
                };


                this.repeatValue = res.data.dates.repeat;
                let repeatValue = this.repeatValue.split(' ');
                this.repeatItem = repeatValue[1];
                this.staticDay = res.data.dates.repeat_days;
                this.repeatDays = res.data.dates.repeat_days.split(',');

                this._startDate = this.CustomHelper.formatDateOnlyToDB(this.param.start);
                this._startTime = this.CustomHelper.formatTimeOnlyToDB(this.param.start);
                this._expireDate = this.param.expire == '' ? '' : this.CustomHelper.formatDateOnlyToDB(this.param.expire);
                this._expireTime = this.param.expire == '' ? '' : this.CustomHelper.formatTimeOnlyToDB(this.param.expire);
                this._startDateConfig = {
                    timePicker: false,
                    defaultDate: new Date(this.param.start),
                    minDate: new Date() > new Date(this.param.start) ? new Date(this.param.start) : new Date(),
                    dateFormat: this.CustomHelper.dateFormatFlat(),
                    onChange: function(selectedDates, dateStr, instance) {}
                };
                this._startTimeConfig = {
                    enableTime: true,
                    noCalendar: true,
                    defaultDate: new Date(this.param.start),
                    dateFormat: this.CustomHelper.timeFormatFlat('format'),//"H:i k",
                    time_24hr: this.CustomHelper.timeFormatFlat(''),
                    onChange: function(selectedDates, dateStr, instance) {},
                    onOpen: function(selectedDates, dateStr, instance) {},
                };
                this._expireDateConfig = {
                    timePicker: false,
                    defaultDate: this.param.expire == '' ? null : new Date(this.param.expire),
                    minDate: new Date(this.param.start),
                    dateFormat: this.CustomHelper.dateFormatFlat(),
                    onChange: function(selectedDates, dateStr, instance) {},
                };
                this._expireTimeConfig = {
                    enableTime: true,
                    noCalendar: true,
                    defaultDate: this.param.expire == '' ? null : new Date(this.param.expire),
                    dateFormat: this.CustomHelper.timeFormatFlat('format'),//"H:i k",
                    time_24hr: this.CustomHelper.timeFormatFlat(''),
                    onChange: function(selectedDates, dateStr, instance) {},
                    onOpen: function(selectedDates, dateStr, instance) {},
                };


                let THIS = this;
                this._startDateConfig.onChange = function (selectedDates, dateStr, instance) {
                    THIS._expireDatepreview = 0;
                    THIS._expireDateConfig.minDate = new Date(selectedDates);
                    setTimeout(function () {
                        THIS._expireDatepreview = 1;
                    }, 400);
                    THIS._startDate = THIS.CustomHelper.formatDateOnlyToDB(new Date(selectedDates));
                    THIS.staticDay = THIS.CustomHelper.day[THIS._expireDateConfig.minDate.getDay()];
                    THIS.repeatDays.length = 0;
                    THIS.repeatDays = [THIS.staticDay];
                };
                this._startTimeConfig.onChange = function (selectedDates, dateStr, instance) {
                    THIS._startTime = dateStr;
                };
                this._startTimeConfig.onOpen = function (selectedDates, dateStr, instance) {
                    if(dateStr == ''){
                        THIS._startTime = '12:00 PM';
                    }
                };
                this._expireDateConfig.onChange = function (selectedDates, dateStr, instance) {
                    THIS._expireDate = THIS.CustomHelper.formatDateOnlyToDB(new Date(selectedDates));
                };
                this._expireTimeConfig.onChange = function (selectedDates, dateStr, instance) {
                    THIS._expireTime = dateStr;
                };
                this._expireTimeConfig.onOpen = function (selectedDates, dateStr, instance) {
                    if(dateStr == ''){
                        THIS._expireTime = '12:00 PM';
                    }
                };

                this._invDateConfig.onChange = function(selectedDates, dateStr, instance){
                    THIS.param.invoice_date = THIS.CustomHelper.formatDateToDB(selectedDates);
                };

                this.getAllContact();
                this.getAllFacility();
                this.checkCurrency();
                this.invoice = res.data;

                setTimeout(function () {
                    (<any>$('.summernote')).summernote({
                        maxHeight : '900px',
                        minHeight : '150px',
                        toolbar: [
                            ['style', ['bold', 'underline', 'clear']],
                            ['color', ['color']],
                            ['para', ['ul', 'ol', 'paragraph']],
                            // ['insert', ['link','video']],
                            ['misc', ['fullscreen']]
                        ]
                    });
                    (<any>$('#customer_note')).summernote('code', THIS.param.customer_notes);
                    (<any>$('#terms_condition')).summernote('code', THIS.param.terms_condition);
                }, 100);
            }
        });
    }



    currentUser;
    constructor(private contactsService: ContactsService,
                public snackBar: MatSnackBar,
                private facilityService: FacilityService,
                private fcmService: FcmService,
                private invoicesService: InvoicesService) {
      this.currentUser = JSON.parse(localStorage.currentUserInfo);
      this.checkCurrency();
        setTimeout(function () {
            (<any>$('.summernote')).summernote({
                maxHeight : '900px',
                minHeight : '150px',
                toolbar: [
                    ['style', ['bold', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['insert', ['link','video']],
                    ['misc', ['fullscreen']]
                ]
            });
        }, 100);
    }

    Facility = [];
    FacilityComp = [];
    getAllFacility() {
        let THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            this.FacilityComp.length = 0;
            this.FacilityComp = res.facility;
            $.each(res.facility, function(i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                $.each(v.child, function(j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                    $.each(vC.child, function(k, vCC) {
                        vCC['pp'] = 2;
                        THIS.Facility.push(vCC);
                        $.each(vCC.child, function(l, vCCC) {
                            vCCC['pp'] = 3;
                            THIS.Facility.push(vCCC);
                        });
                    });
                });
            });
            setTimeout(function () {
                (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                (<any>$('.chosen-default-single-fac')).chosen('destroy');
                (<any>$('.chosen-default-single-fac')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Facility'
                }).on('change', function(e,p){
                    THIS.param.facility_id = e.target.value;
                });
            }, 100);
        });
    }
    contact = [];
    getAllContact(){
        let THIS = this;
        this.contactsService.getAll().subscribe(res => {
            this.contact = res;
            setTimeout(function () {
                (<any>$('.chosen-default-single-contact')).val(THIS.param.contact_id);
                (<any>$('.chosen-default-single-contact')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Contact'
                }).on('change', function(e,p){
                    THIS.param.contact_id = e.target.value;
                });
            }, 100);
            setTimeout(function () {
                (<any>$('.chosen-default-single-terms')).val(THIS.param.terms);
                (<any>$('.chosen-default-single-terms')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Invoice Terms'
                }).on('change', function(e,p){
                    THIS.param.terms = e.target.value;
                });
            }, 100);
        });
    }

    createCartItemsSubTotal = 0;
    createCartItemsTax = 10;
    createCartItemsTotal = 0;
    createCartItems = [
        {
            item_name : '',
            item_qty : 1,
            item_price : 1,
            item_tax : 'Tax Included',
            item_total : 1
        }
    ];
    addNewItemInCart(): void {
        let newCart = {
            item_name : '',
            item_qty : 1,
            item_price : 1,
            item_tax : 'Tax Included',
            item_total : 1,
        };
        this.createCartItems.push(newCart);
        this.checkCurrency();
    }
    removeFromCart(index): void{
        if(this.createCartItems.length > 1){
            this.createCartItems.splice(index, 1);
        }
    }
    copyThisInCart(cart): void{
      let newCart = {
        item_name : cart.item_name,
        item_qty : cart.item_qty,
        item_price : cart.item_price,
        item_tax : cart.item_tax,
        item_total : cart.item_total,
      };
      this.createCartItems.push(newCart);
      this.checkCurrency();
    }
    updateLineName(e,index:number): void{
        let trigger = $(e.target);
        let value = trigger.val();
        let name = value.toString();
        this.createCartItems[index].item_name = name;
    }
    updateLineQty(e,index:number): void{
        let trigger = $(e.target);
        let value = trigger.val();
        let Qty = Number(value);
        this.createCartItems[index].item_qty = Qty;
        let lineTotal = this.createCartItems[index].item_qty * this.createCartItems[index].item_price;
        this.createCartItems[index].item_total = lineTotal;
        this.changeInvoiceTotal();
    }
    updateLinePrice(e,index:number): void{
        let trigger = $(e.target);
        let inputValue = this.CustomHelper.makeStr(trigger.val());
        let value = inputValue.replace(/,/, '');
        value = value.substring(1);
        let price = Number(value);
        this.createCartItems[index].item_price = price;
        let lineTotal = this.createCartItems[index].item_qty * this.createCartItems[index].item_price;
        this.createCartItems[index].item_total = lineTotal;
        this.changeInvoiceTotal();
    }
    changeInvoiceTax(tax: number): void{
        this.createCartItemsTax = tax;
        this.changeInvoiceTotal();
        if(tax == 10){this.param.tax_type = 1;}
        if(tax == 15){this.param.tax_type = 2;}
        this.param.tax_value = tax;
    }
    changeInvoiceTotal(): void{
        let subTotal = 0;
        $.each(this.createCartItems, function (i, v) {
            subTotal = subTotal+v.item_total;
        });
        this.createCartItemsSubTotal = subTotal;
        this.createCartItemsTotal = this.createCartItemsSubTotal + (this.createCartItemsSubTotal * (this.createCartItemsTax/100));
    }
    discardNewInvoice() {
        this.discard.emit(1);
    }


    facilities = [];
    param = {
        id: 0,
        work_id: null,
        facility_id: '',
        contact_id: '',
        invoice_date : '',
        order_number : '',
        terms : '1',
        tax_type : 1,
        tax_value : 10,
        customer_notes : '',
        customer_note_on : 0,
        terms_condition : '',
        term_on : 0,
        items : '',
        repeat : '',
        repeat_days : '',
        start : '',
        expire : '',
    };
    emailThisInvoice(){
        this.emailIt.emit(1);
    }
    createThisInvoice(){
        if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
            let freeAccountData = localStorage.getItem('freeAccountData');
            if (freeAccountData != null) {
                let freeAccount = JSON.parse(freeAccountData);
                if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
                    (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
                    (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
                    (freeAccount['user_count'] >= freeAccount['user_limit'])) {
                    $('.openSubscription').click();
                    return false;
                }
            }
        }
        let ldd = document.getElementById('SaveThisLadda');
        let ladda = Ladda.create(ldd);
        let valid = this.validationThisForm();
        if(valid == 1){
            let THIS = this;
            ladda.start();
            this.param.customer_notes = (<any>$('#customer_note')).summernote('code');
            this.param.terms_condition = (<any>$('#terms_condition')).summernote('code');

            this.param.repeat = this.repeatCount+' '+this.repeatItem;
            this.param.repeat_days = this.repeatDays.join();
            this.param.items = JSON.stringify(this.createCartItems);
            // this.param.invoice_date = CustomHelper.formatDateToDB(this.invoice_date[0] == undefined ? this.invoice_date : this.invoice_date[0]);
            this.invoicesService.editRecurringInvoice(this.param).subscribe(res => {
                ladda.stop();
                if(res.status == 2000){
                    this.discardNewInvoice();
                    toastr.success('Recurring invoice has been updated successfully', 'Recurring Invoice Update', this.toastrOption);
                    // Pusher emit
                } else {
                    swal({
                        title: "Something is wrong! Pleaes try again.",
                        className: 'swal-btn-center'
                    });
                }
            });
        }
    }
    clearValidation(e){
        let trigger = $(e.target);
        let target = trigger.closest('.form-group');
        target.removeClass('has-error');
    }
    validationThisForm(){
        let valid = 1;
        if(this.param.facility_id == ''){
            valid = 0;
            swal({
                title: "Please select a facility",
                className: 'swal-btn-center'
            });
            return valid;
        }
        if(this.param.contact_id == ''){
            valid = 0;
            swal({
                title: "Please select a contact",
                className: 'swal-btn-center'
            });
            return valid;
        }
        /*if(this.param.invoice_date == ''){
            valid = 0;
            swal({
                title: "Please select an invoice date",
                className: 'swal-btn-center'
            });
            return valid;
        }*/
        if(this._startDate == ''){
            valid = 0;
            swal({
                title: "Please select a start date",

            });
            return valid;
        }
        if(this._startTime == ''){
            valid = 0;
            swal("Please select a start time");
            return valid;
        }
        if(this.repeatItem == ''){
            valid = 0;
            swal("Please select a recurring period");
            return valid;
        }
        if(this.repeatItem == ''){
            valid = 0;
            swal("Please select a recurring period");
            return valid;
        }
        if(this._expireDate != ''){
            if(this._expireTime == ''){
                valid = 0;
                swal("Please select an expire time");
                return valid;
            }
        }
        if(this._expireTime != ''){
            if(this._expireDate == ''){
                valid = 0;
                swal("Please select an expire date");
                return valid;
            }
        }
        let __start_New = new Date(this._startDate + ' ' + this._startTime);
        this.param.start = this.CustomHelper.formatDateTimeToDB(__start_New);
        if(this._expireDate != '' && this._expireTime != ''){
            let __expire_New = new Date(this._expireDate + ' ' + this._expireTime);
            this.param.expire = this.CustomHelper.formatDateTimeToDB(__expire_New);
            if (Date.parse(this.param.start) > Date.parse(this.param.expire)) {
                swal("Recurrance start date time can't be greater than expire time");
                valid = 0;
            }
        }

        return valid;
    }


    repeatValue = '1 Day';
    repeatCount = 1;
    repeatItem = '';
    staticDay = '';
    repeatDays = [];
    updateRepeatDays(day){
        if(day != this.staticDay){
            let index = this.repeatDays.indexOf(day);
            if(index > -1){
                this.repeatDays.splice(index, 1);
            } else {
                this.repeatDays.push(day);
            }
        }
    }
    echoRepeatDays(repeatDays){
        let days = '';
        $.each(repeatDays, function (i, v) {
            if(i == '0'){
                days += v;
            } else {
                days += ', '+v;
            }
        });
        days = days.replace(/,([^,]*)$/, ' and $1');
        return days;
    }
    _startDate = '';
    _startTime = '';
    _expireDate = '';
    _expireTime = '';
    _expireDatepreview = 1;
    _startDateConfig = null;
    _startTimeConfig = null;
    _expireDateConfig = null;
    _expireTimeConfig = null;
    expireView = 0;
    noExpire(){
        this._expireDate = '';
        this._expireTime = '';
        this._expireDateConfig.defaultDate = null;
        this._expireTimeConfig.defaultDate = null;
        this.param.expire = '';
        this.expireView = 1;
        let THIS = this;
        setTimeout(function () {
            THIS.expireView = 0;
        },100);
    }


    _invDateConfig = null;

    ngOnInit() {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        /*this.socket.on('_rfr_team_data_fac_'+Session.team, function (v) {
            THIS.getAllFacility();
        });*/
    }
    ngOnDestroy() {
    }

  checkCurrency() {
    Inputmask.extendAliases({
      pesos: {
        prefix: "$",
        groupSeparator: "",
        alias: "numeric",
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        radixPoint: '.',
        placeholder: '0.00'
      }
    });

    setTimeout(function () {
      (<any>$("._currency_validate_eq")).inputmask({ alias : "pesos" });
    }, 300);
  }

    currencyIcon():string {
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
    }
}
