import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AssetService} from '../../_services/asset.service';
import {AuthService} from '../../_services/auth.service';
import {ContactsService} from '../../_services/contacts.service';
import {FacilityService} from '../../_services/facility.service';
import {InventoriesService} from '../../_services/inventories.service';
import {InvoicesService} from '../../_services/invoices.service';
import {Job_statusService} from '../../_services/job_status.service';
import {Job_typesService} from '../../_services/job_types.service';
import {TabsService} from '../../_services/tabs.service';
import {MediaService} from '../../_services/media.service';
import {UserService} from '../../_services/user.service';
import {WorkOrderService} from '../../_services/work-orders.service';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';

declare let swal: any;
declare let Ladda: any;

@Component({
  selector: 'my-page-login',
  styles: [],
  templateUrl: './login.component.html'
})

export class LoginComponent {

  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  user: any = {};
  loading = false;
  errorMessage = '';
  remember = false;
  passType: any = 'password';

  mobileMenu = false;

  constructor(
    private assetService: AssetService,
    private authService: AuthService,
    private contactsService: ContactsService,
    private facilityService: FacilityService,
    private inventoriesService: InventoriesService,
    private invoicesService: InvoicesService,
    private job_statusService: Job_statusService,
    private job_typesService: Job_typesService,
    private tabsService: TabsService,
    private mediaService: MediaService,
    private userService: UserService,
    private workOrderService: WorkOrderService,
    private router: Router
  ) {
    if (localStorage.getItem('currentUserInfo') !== null) {
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      if (Session.step != 0) {
        delete localStorage.currentUserInfo;
        localStorage.clear();

        delete localStorage.currentUserInfo;
        this.assetService.refreshToken();
        this.authService.refreshToken();
        this.contactsService.refreshToken();
        this.facilityService.refreshToken();
        this.inventoriesService.refreshToken();
        this.invoicesService.refreshToken();
        this.job_statusService.refreshToken();
        this.job_typesService.refreshToken();
        this.mediaService.refreshToken();
        this.userService.refreshToken();
        this.workOrderService.refreshToken();
        this.tabsService.removeTemp();

      } else {
        this.router.navigate([Session.team + '/dashboard']);
      }
    } else {
      delete localStorage.currentUserInfo;
      localStorage.clear();


      delete localStorage.currentUserInfo;
      this.assetService.refreshToken();
      this.authService.refreshToken();
      this.contactsService.refreshToken();
      this.facilityService.refreshToken();
      this.inventoriesService.refreshToken();
      this.invoicesService.refreshToken();
      this.job_statusService.refreshToken();
      this.job_typesService.refreshToken();
      this.mediaService.refreshToken();
      this.userService.refreshToken();
      this.workOrderService.refreshToken();
      this.tabsService.removeTemp();
    }
  }

    className = 'animated fadeInUp';
    login() {
        let THIS = this;
        let ldd = document.getElementById('submitBTN');
        let ladda = Ladda.create(ldd);
        let param = {
            email : this.user.email,
            password : this.user.password,
        };
        this.loading = true;
        ladda.start();
        this.authService.loginNew(param).subscribe(res => {
            THIS.loading = false;
            ladda.stop();
            if (res.status == 2000) {
                setTimeout(function () {
                    if(res.data.step == 0){
                        THIS.assetService.refreshToken();
                        THIS.authService.refreshToken();
                        THIS.contactsService.refreshToken();
                        THIS.facilityService.refreshToken();
                        THIS.inventoriesService.refreshToken();
                        THIS.invoicesService.refreshToken();
                        THIS.job_statusService.refreshToken();
                        THIS.job_typesService.refreshToken();
                        THIS.mediaService.refreshToken();
                        THIS.userService.refreshToken();
                        THIS.workOrderService.refreshToken();
                        this.className = 'animated zoomOut';
                        THIS.router.navigate(['/'+res.data.team+'/dashboard']);
                    }
                    else if(res.data.step == 6){
                        delete localStorage.currentUserInfo;
                        swal({
                            title: "Inactive Accocunt",
                            text: "Your account has not been activated. We have sent you another activation link to your email address.",
                            icon: "warning",
                            buttons: ['Cancel','Resend Activation'], //
                            dangerMode: true,
                        }).then(res => {
                            if (res) {
                                $.ajax({
                                    url: THIS.APPCONFIG.apiAuth+'resend-activation',
                                    type: 'post',
                                    data: {email: THIS.user.email},
                                    success: function (res) {
                                        if (res.status == 2000) {
                                            swal({
                                                title: "Resend Activation",
                                                text: "A new activation link is send to your email. Please check and activate your account.",
                                                icon: "success",
                                                dangerMode: true,
                                                className: 'swal-btn-center'
                                            });
                                        } else {
                                            swal({
                                                title: 'Invalid Request',
                                                className: 'swal-btn-center'
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    } else {
                        THIS.router.navigate(['/register']);
                    }
                },400);
            } else if(res.status == 7000) {
                this.errorMessage = res.error;
                this.loading = false;
            } else {
                this.errorMessage = 'Email address or password is incorrect';
                this.loading = false;
            }
        });
    }


  rememberMe() {
    this.remember = !this.remember;
  }

  changePassType() {
    if (this.passType === 'password') {
      this.passType = 'text';
    } else if (this.passType === 'text') {
      this.passType = 'password';
    }
  }

}
