import {Component, Input, EventEmitter, Output, SimpleChanges, Inject} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {Lightbox} from 'angular2-lightbox';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {AssetService} from '../../../_services/asset.service';
import {InventoriesService} from '../../../_services/inventories.service';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;

declare let fabric: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;
declare let Pickr: any;
declare let Inputmask: any;

@Component({
    selector: 'page-inventory-single',
    templateUrl: './inventory-single.component.html'
})
export class InventorySingleComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    @Output() editPreview = new EventEmitter<number>();
    @Output() removeThis = new EventEmitter<number>();
    @Output() archiveThis = new EventEmitter<number>();
    @Output() listRefresh = new EventEmitter<number>();
    @Input() Template: any;
    TemplateCheck = 2;
    @Input() preId: any;
    InvDetailsChange = 0;
    Inventory: any;
    _albums = [];
    _selected_tab = 1;
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

    inv_id = 0;
    inlinePrefixId = 0;
    rightSecPreview = 0;

    updateChangesDiscard() {
        this.validationQuickForm();
    }

    Inventory_rest_qty = 0;
    param = {
        id: 0,
        inv_id: '',
        prefix: '',
        title: '',
        store_id: '',
        facility_id: '',
        media_files: '',
        attach_files: '',
        qty: '',
        rest_qty: '',
        purchase_cost: '',
        sell_price: '',
    };

    validForm = 1;

    saveWorkOrderSubmit() {
        let ldd = document.getElementById('editInv');
        let ladda = Ladda.create(ldd);
        ladda.start();
        if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
            const freeAccountData = localStorage.getItem('freeAccountData');
            if (freeAccountData != null) {
                const freeAccount = JSON.parse(freeAccountData);
                if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
                    (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
                    (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
                    (freeAccount['user_count'] >= freeAccount['user_limit'])) {
                    $('.openSubscription').click();
                    return false;
                }
            }
        }
        const THIS = this;
        let mediaFiles = '';
        $.each(THIS.selectedFilesPreview, function (i, v) {
            if (i == 0) {
                mediaFiles += v.id;
            } else {
                mediaFiles += ',' + v.id;
            }
        });
        this.param.media_files = mediaFiles;
        let attachFiles = '';
        $.each(THIS.selectedAssetAttach, function (i, v) {
            if (i == 0) {
                attachFiles += v.id;
            } else {
                attachFiles += ',' + v.id;
            }
        });
        const incr = this.Inventory_rest_qty - this.Inventory.rest_qty;
        this.param.qty = this.param.qty + incr;

        this.param.attach_files = attachFiles;
        this.param.purchase_cost = ((<any>$('input[name="purchase_cost"]')).val()).replace(/\$/, '');
        this.param.sell_price = ((<any>$('input[name="sell_price"]')).val()).replace(/\$/, '');

        const valid = this.validationThisForm();
        if (valid == 1) {
            this.inventoriesService.editInventory(this.param).subscribe(res => {
                ladda.stop();
                if (res.status == 7000) {
                    swal({
                        title: 'Asset ID',
                        text: 'The Asset ID is already been taken. Please use a unique one',
                        icon: 'warning',
                        dangerMode: true,
                        className: 'swal-btn-center'
                    });
                } else if (res.status == 2000) {
                    this.preId = this.Inventory.uid;
                    this.preview.emit(this.preId);
                    toastr.success('Inventory has been successfully updated', 'Update Inventory', this.toastrOption);
                }
            });
        }
    }

    validationThisForm() {
        let valid = 1;
        const form = $('#workCreateForm');
        if (this.param.facility_id == '') {
            valid = 0;
            swal({
                title: 'Facility',
                text: 'Please select a facility',
                icon: 'warning',
                dangerMode: true,
                className: 'swal-btn-center'
            });
            return valid;
        }
        /*if (this.param.prefix == '') {
            valid = 0;
            swal("Please use a prefix");
            return valid;
        }
        if (this.param.inv_id == '') {
            valid = 0;
            swal("Please provide an inventory ID");
            return valid;
        }*/
        if (this.param.title == '') {
            valid = 0;
            swal({
                title: 'Inventory Title',
                text: 'Please titled this inventory title',
                icon: 'warning',
                dangerMode: true,
                className: 'swal-btn-center'
            });
            return valid;
        }
        this.validForm = valid;
        return valid;
    }

    validationQuickForm() {
        let valid = 1;
        const form = $('#workCreateForm');
        if (this.param.facility_id == '') {
            valid = 0;
            return valid;
        }
        /*if (this.param.prefix == '') {
            valid = 0;
            return valid;
        }
        if (this.param.inv_id == '') {
            valid = 0;
            return valid;
        }*/
        if (this.param.title == '') {
            valid = 0;
            return valid;
        }
        this.validForm = valid;
        return valid;
    }

    clearValidation(e) {
        const trigger = $(e.target);
        const target = trigger.closest('.form-group');
        target.removeClass('has-error');
    }


    currentUser: any;

    constructor(private assetService: AssetService,
                private mediaService: MediaService,
                private inventoriesService: InventoriesService,
                private _lightbox: Lightbox,
                private coolDialogs: NgxCoolDialogsService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private facilityService: FacilityService) {
        const THIS = this;
        this.currentUser = JSON.parse(localStorage.currentUserInfo);

        $('body').off().on('click', '.pswp__button--edit', function () {
            if (THIS.Gallery !== null) {
                THIS.Gallery.close();
            }
            THIS.Preview = JSON.parse($('#EditImageData').attr('data-type'));
            THIS.openPhotoEditor(THIS.Preview);
        });
    }

    Facility = [];
    FacilityComp = [];

    getAllFacility() {
        const THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            this.FacilityComp.length = 0;
            this.FacilityComp = res.facility;
            $.each(res.facility, function (i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                $.each(v.child, function (j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                    $.each(vC.child, function (k, vCC) {
                        vCC['pp'] = 2;
                        THIS.Facility.push(vCC);
                        $.each(vCC.child, function (l, vCCC) {
                            vCCC['pp'] = 3;
                            THIS.Facility.push(vCCC);
                        });
                    });
                });
            });
            setTimeout(function () {
                if ($('.reRenderFacility').length > 0) {
                    const facilityId = parseInt($('.reRenderFacility').attr('data-id'));
                    if (facilityId > 0) {
                        THIS.param.facility_id = facilityId.toString();
                    }
                }
                (<any>$('.chosen-default-single-fac')).chosen('destroy');
                (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                (<any>$('.chosen-default-single-fac')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Facility'
                }).on('change', function (e, p) {
                    if (e.target.value === '000') {
                        let ___selectedTab = 0;
                        const tabs = $('#sort-tabs').find('li');
                        tabs.each(function (i, v) {
                            if ($(v).find('a.active').length > 0) {
                                ___selectedTab = i;
                            }
                        });
                        const child = ___selectedTab + 1;
                        (<any>$('.owl-carousel')).owlCarousel('destroy');
                        $('#sort-tabs').find('li').find('a').removeClass('active');
                        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                        (<any>$('#createFacilityModal')).modal('show');
                    } else {
                        THIS.param.facility_id = e.target.value;
                        THIS.validationQuickForm();
                        THIS.updateChangesDiscard();
                    }
                });
            }, 1000);
        });
    }

    showPhotos: any = false;

    togglePhoto() {
      if ( this.showPhotos === false) {
        this.showPhotos = true;
      } else {
        this.showPhotos = false;
      }
    }

    selectedFiles = [];
    selectedFilesPreview = [];

    removeThisMedia(id, media_type) {
        let param = {
            id: id, media_type: media_type
        };
        this.mediaService.remove(param).subscribe(res => {
        });
    }

    removeThisImage(index, id) {
        // this.selectedFiles.splice(index, 1);
        this.selectedFilesPreview.splice(index, 1);
        this.removeThisMedia(id, 1);
    }

    _uploadError = 0;
    _locPermit = 0;

    uploadThisMedia(file, media_type, localtion, callback) {
        const formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        if (localtion != null) {
            formData.append('lat', localtion.lat);
            formData.append('lon', localtion.lon);
        }
        this.mediaService.create(formData).subscribe(res => {
            this.updateChangesDiscard();
            callback(res);
        });
    }

    dragFilesDropped(e) {
        e.preventDefault();
        this._uploadError = 0;
        const THIS = this;
        if (e.dataTransfer.files.length > 0) {
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                if (this.selectedFilesPreview.length < 1) {
                    const eachFile = e.dataTransfer.files[i];
                    // this.selectedFiles.push(eachFile);
                    if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
                        if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                            navigator.geolocation.getCurrentPosition(function (position) {
                                if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                                    THIS._locPermit = 1;
                                    const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                                    THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                                        if (uploaded.status != undefined) {
                                            if (uploaded.status == 9000) {
                                                swal({
                                                    title: 'Storage Full',
                                                    text: 'Your Storage is full. Please delete some files',
                                                    icon: 'error',
                                                    dangerMode: true,
                                                    className: 'swal-btn-center'
                                                });
                                            } else {
                                                swal({
                                                    title: 'Invalid file format uploaded',
                                                    text: 'Please upload an image in Jpeg or Png only.',
                                                    icon: 'error',
                                                    dangerMode: true,
                                                    className: 'swal-btn-center'
                                                });
                                            }
                                        } else {
                                            THIS.selectedFilesPreview.push(uploaded);
                                        }
                                    });
                                } else {
                                    THIS._locPermit = -1;
                                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                                        if (uploaded.status != undefined) {
                                            if (uploaded.status == 9000) {
                                                swal({
                                                    title: 'Storage Full',
                                                    text: 'Your Storage is full. Please delete some files',
                                                    icon: 'error',
                                                    dangerMode: true,
                                                    className: 'swal-btn-center'
                                                });
                                            } else {
                                                swal({
                                                    title: 'Invalid file format uploaded',
                                                    text: 'Please upload an image in Jpeg or Png only.',
                                                    icon: 'error',
                                                    dangerMode: true,
                                                    className: 'swal-btn-center'
                                                });
                                            }
                                        } else {
                                            THIS.selectedFilesPreview.push(uploaded);
                                        }
                                    });
                                }
                            }, function (error) {
                                (<any>$('#geoLocationModal')).modal('show');
                                swal({
                                    title: 'Share Geo Location',
                                    text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                                    icon: 'warning',
                                    buttons: ['Want to share location', 'Don\'t want to share Location'],
                                    dangerMode: true,
                                }).then((willDelete) => {
                                    if (willDelete) {
                                        THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                                            if (uploaded.status != undefined) {
                                                if (uploaded.status == 9000) {
                                                    swal({
                                                        title: 'Storage Full',
                                                        text: 'Your Storage is full. Please delete some files',
                                                        icon: 'error',
                                                        dangerMode: true,
                                                        className: 'swal-btn-center'
                                                    });
                                                } else {
                                                    swal({
                                                        title: 'Invalid file format uploaded',
                                                        text: 'Please upload an image in Jpeg or Png only.',
                                                        icon: 'error',
                                                        dangerMode: true,
                                                        className: 'swal-btn-center'
                                                    });
                                                }
                                            } else {
                                                THIS.selectedFilesPreview.push(uploaded);
                                            }
                                        });
                                    } else {
                                        swal({
                                            title: 'Share Location',
                                            text: 'If you want to share location then change the browser settings and remove the block status',
                                            icon: 'warning',
                                            dangerMode: true,
                                            className: 'swal-btn-center'
                                        });
                                    }
                                });
                            });
                        } else {
                            THIS._locPermit = -1;
                            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                                if (uploaded.status != undefined) {
                                    if (uploaded.status == 9000) {
                                        swal({
                                            title: 'Storage Full',
                                            text: 'Your Storage is full. Please delete some files',
                                            icon: 'error',
                                            dangerMode: true,
                                            className: 'swal-btn-center'
                                        });
                                    } else {
                                        swal({
                                            title: 'Invalid file format uploaded',
                                            text: 'Please upload an image in Jpeg or Png only.',
                                            icon: 'error',
                                            dangerMode: true,
                                            className: 'swal-btn-center'
                                        });
                                    }
                                } else {
                                    THIS.selectedFilesPreview.push(uploaded);
                                }
                            });
                        }
                    } else {
                        this._uploadError = 1;
                    }
                }
            }
        }
    }
    photoParam  = {
      file: ''
    }
    selectTheseFiles(e) {
        e.preventDefault();
        this._uploadError = 0;
        const THIS = this;
        const accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            if (this.selectedFilesPreview.length < 1) {
                const eachFile = accepted[i];
                // this.selectedFiles.push(eachFile);
                if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
                    if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                                THIS._locPermit = 1;
                                const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                                    if (uploaded.status != undefined) {
                                        if (uploaded.status == 9000) {
                                            swal({
                                                title: 'Storage Full',
                                                text: 'Your Storage is full. Please delete some files',
                                                icon: 'error',
                                                dangerMode: true,
                                                className: 'swal-btn-center'
                                            });
                                        } else {
                                            swal({
                                                title: 'Invalid file format uploaded',
                                                text: 'Please upload an image in Jpeg or Png only.',
                                                icon: 'error',
                                                dangerMode: true,
                                                className: 'swal-btn-center'
                                            });
                                        }
                                    } else {
                                        THIS.selectedFilesPreview.push(uploaded);
                                    }
                                });
                            } else {
                                THIS._locPermit = -1;
                                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                                    if (uploaded.status != undefined) {
                                        if (uploaded.status == 9000) {
                                            swal({
                                                title: 'Storage Full',
                                                text: 'Your Storage is full. Please delete some files',
                                                icon: 'error',
                                                dangerMode: true,
                                                className: 'swal-btn-center'
                                            });
                                        } else {
                                            swal({
                                                title: 'Invalid file format uploaded',
                                                text: 'Please upload an image in Jpeg or Png only.',
                                                icon: 'error',
                                                dangerMode: true,
                                                className: 'swal-btn-center'
                                            });
                                        }
                                    } else {
                                        THIS.selectedFilesPreview.push(uploaded);
                                    }
                                });
                            }
                        }, function (error) {
                          (<any>$('#LocationModal')).modal('show');
                          THIS.photoParam = {
                            file: eachFile,
                          }
                           /* swal({
                                title: 'Share Geo Location',
                                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                                icon: 'warning',
                                buttons: ['Want to share location', 'Don\'t want to share Location'],
                                dangerMode: true,
                            }).then((willDelete) => {
                                if (willDelete) {
                                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                                        if (uploaded.status != undefined) {
                                            if (uploaded.status == 9000) {
                                                swal({
                                                    title: 'Storage Full',
                                                    text: 'Your Storage is full. Please delete some files',
                                                    icon: 'error',
                                                    dangerMode: true,
                                                    className: 'swal-btn-center'
                                                });
                                            } else {
                                                swal({
                                                    title: 'Invalid file format uploaded',
                                                    text: 'Please upload an image in Jpeg or Png only.',
                                                    icon: 'error',
                                                    dangerMode: true,
                                                    className: 'swal-btn-center'
                                                });
                                            }
                                        } else {
                                            THIS.selectedFilesPreview.push(uploaded);
                                        }
                                    });
                                } else {
                                    swal({
                                        title: 'Share Location',
                                        text: 'If you want to share location then change the browser settings and remove the block status',
                                        icon: 'warning',
                                        className: 'swal-btn-center'
                                    });
                                }
                            });*/
                        });
                    } else {
                        THIS._locPermit = -1;
                        THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                            if (uploaded.status != undefined) {
                                if (uploaded.status == 9000) {
                                    swal({
                                        title: 'Storage Full',
                                        text: 'Your Storage is full. Please delete some files',
                                        icon: 'error',
                                        dangerMode: true,
                                        className: 'swal-btn-center'
                                    });
                                } else {
                                    swal({
                                        title: 'Invalid file format uploaded',
                                        text: 'Please upload an image in Jpeg or Png only.',
                                        icon: 'error',
                                        dangerMode: true,
                                        className: 'swal-btn-center'
                                    });
                                }
                            } else {
                                THIS.selectedFilesPreview.push(uploaded);
                            }
                        });
                    }
                } else {
                    this._uploadError = 1;
                }
            }
        }
    }
    photoUploaded(){
      let THIS = this;
      const ldd = document.getElementById('locationBtn');
      const ladda = Ladda.create(ldd);
      ladda.start();
       if(THIS.photoParam.file != ''){
         THIS.uploadThisMedia(this.photoParam.file, '1', null, function (uploaded) {
           ladda.stop();
           if (uploaded.status != undefined) {
             if (uploaded.status == 9000) {
               swal({
                 title: 'Storage Full',
                 text: 'Your Storage is full. Please delete some files',
                 icon: 'error',
                 dangerMode: true,
                 className: 'swal-btn-center'
               });
             } else {
               swal({
                 title: 'Invalid file format uploaded',
                 text: 'Please upload an image in Jpeg or Png only.',
                 icon: 'error',
                 dangerMode: true,
                 className: 'swal-btn-center'
               });
             }
           } else {
             THIS.selectedFilesPreview.push(uploaded);
             (<any>$('#LocationModal')).modal('hide');
           }
         });
       }
    }
    shareLocation(){
      (<any>$('#ShareLocationModal')).modal('show');
    }

    dragFileOverStart() {
        const trigger = $('#uploadDragWork');
        trigger.addClass('onEnter');
    }

    dragFileOverEnd() {
        const trigger = $('#uploadDragWork');
        trigger.removeClass('onEnter');
    }

    selectedAssetAttach = [];

    uploadConfileTrigger() {
        $('#fileCon').click();
    }

    uploadConFile(e) {
        e.preventDefault();
        const THIS = this;
        const accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            const eachFile = accepted[i];
            this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
                if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                        swal({
                            title: 'Storage Full',
                            text: 'Your Storage is full. Please delete some files',
                            icon: 'error',
                            dangerMode: true,
                            className: 'swal-btn-center'
                        });
                    } else {
                        swal({
                            title: 'Invalid file format uploaded',
                            text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allwoed',
                            icon: 'error',
                            dangerMode: true,
                            className: 'swal-btn-center'
                        });
                    }
                } else {
                    THIS.selectedAssetAttach.push(uploaded);
                }
            });
        }
    }

    removeThisAttach(index, id) {
        this.selectedAssetAttach.splice(index, 1);
        this.removeThisMedia(id, 4);
    }

    addWorkAttach() {
        $('#workAttach').click();
    }

    uploadWorkAttach(e) {
        e.preventDefault();
        const THIS = this;
        const accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            if (this.selectedAssetAttach.length < 3) {
                const eachFile = accepted[i];
                this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                            swal({
                                title: 'Storage Full',
                                text: 'Your Storage is full. Please delete some files',
                                icon: 'error',
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                        } else {
                            swal({
                                title: 'Invalid file format uploaded',
                                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allwoed',
                                icon: 'error',
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                        }
                    } else {
                        THIS.selectedAssetAttach.push(uploaded);
                    }
                });
            }
        }
    }

    confirmDiscard() {
        this.discard.emit(1);
    }

    intcheck(e) {
        let v = e.target.value;
        v = this.CustomHelper.makeFloat(v);
        if (isNaN(v)) {
            v = 0;
        }
        e.target.value = v;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.Template != undefined) {
            this.TemplateCheck = 0;
            this.TemplateCheck = changes.Template.currentValue;
        }
        if (changes.preId != undefined) {
            this.InvDetailsChange = 0;
            const preId = changes.preId.currentValue;
            if (preId != '') {
                const param = {uid: preId};
                this.inventoriesService.getSingleInventory(param).subscribe(res => {
                    if (res.status == 2000) {
                        const inv = res.data;
                        console.log(inv);
                        inv.facStr = inv.facStr.reverse();
                        this.Inventory = inv;
                        this._albums.length = 0;
                        if (this.Inventory.media !== undefined) {
                            for (let i = 0; i < this.Inventory.media.length; i++) {
                                const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + this.Inventory.media[i].file_path;
                                const caption = '';
                                const thumb = '';
                                const album = {
                                    src: src,
                                    caption: caption,
                                    thumb: thumb,
                                    w: this.Inventory.media[i].width !== undefined ? this.Inventory.media[i].width : 1500,
                                    h: this.Inventory.media[i].height !== undefined ? this.Inventory.media[i].height : 1500
                                };
                                this._albums.push(album);
                            }
                        }
                        this.selectedAssetAttach = this.Inventory.attach;
                        console.log(this.selectedAssetAttach);
                        this.selectedFilesPreview = this.Inventory.media;
                        this.selectedFiles = this.Inventory.media.length;

                        this.inv_id = inv.id;
                        this.param.id = inv.id;
                        this.param.inv_id = inv.inv_id;
                        this.param.prefix = inv.prefix;
                        this.param.title = inv.title;
                        this.param.store_id = inv.store_id;
                        this.param.facility_id = inv.facility_id.toString();
                        this.param.qty = inv.qty;
                        this.Inventory_rest_qty = inv.rest_qty;
                        this.param.purchase_cost = inv.purchase_cost;
                        this.param.sell_price = inv.sell_price;

                        this.InvDetailsChange = 1;

                        this.getAllFacility();
                        this.getGraphData();
                        this.checkCurrency();

                    } else {
                        this.InvDetailsChange = -1;
                    }
                });
            }
        }
    }

    // ===================
    // View Actions
    // ===================
    editThisInv() {
        this.editPreview.emit(this.preId);
    }

    removeThisInv() {
       this.removeThis.emit(this.param.id);
       this.confirmDiscard();
    }
    deleteThisWorkOrder() {
      (<any>$('#deleteWorkOrderModal')).modal('show');
    }

    archiveThisInv() {
      this.archiveThis.emit(this.param.id);
      this.confirmDiscard();
    }
    archiveThisOpen() {
      (<any>$('#ArchiveModal')).modal('show');
    }
    getGraphData() {
        const THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'inventory/graph/' + THIS.Inventory.id + '?token=' + THIS.currentUser.token,
            type: 'get',
            success: function (res) {
                if (res.status == 2000) {
                    const labels = [];
                    const dataset = [];
                    let MaxValue = 10;
                    $.each(res.data, function (i, v) {
                        labels.push(v.date);
                        dataset.push(v.value);
                        if (MaxValue < v.value) {
                            MaxValue = v.value;
                        }
                    });
                    THIS.options = {
                        responsive: true,
                      legend: {
                        display: false
                      },
                      tooltips: {
                        callbacks: {
                          label: function(tooltipItem) {
                            return tooltipItem.yLabel;
                          }
                        }
                      },
                        scales: {
                          lineWidth: 0,
                          xAxes: [{
                            gridLines: {
                              display: false
                            },
                          }],
                            yAxes: [{
                                ticks: {
                                    max: MaxValue,
                                    min: 0,
                                    stepSize: 1
                                }
                            }]
                        }
                    };
                    THIS.data = {
                      scales: {
                        lineWidth: 0,
                        yAxes: [{
                          ticks: {
                            max: MaxValue,
                            min: 0,
                            stepSize: 1
                          }
                        }],
                        xAxes: [{
                          gridLines: {
                            display: false
                          },
                        }]
                      },
                        labels: labels.reverse(),
                        datasets: [
                            {
                                label: '',
                                data: dataset.reverse(),
                                fill: false,
                                pointBackgroundColor: '#D53D43',
                                pointBorderColor: '#D53D43',
                                pointBorderWidth: 0,
                                scaleLineColor: '#fff',
                                pointRadius: 6,
                                borderColor: '#fff',
                                borderWidth: 0,
                            },
                        ],
                    };
                }
            }
        });
    }

    advance_option = 1;
    today = new Date();
    contractRange = 0;
    data = null;
    options = null;


    createTopUp(): void {
        const dialogRef = this.dialog.open(inventoryTopUpDialog, {
            width: '400px',
            disableClose: true,
            data: {inv_id: this.inv_id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {
                this.Inventory.rest_qty = this.Inventory.rest_qty + (result - this.Inventory.qty);
                this.Inventory.qty = result;
                this.listRefresh.emit(1);
            }
            console.log('The dialog was closed');
        });
    }

    ngOnInit() {
        const THIS = this;
        const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        /*this.socket.on('_rfr_team_data_fac_' + Session.team, function (v) {
            THIS.getAllFacility();
        });*/
        $('body').on('click', '.reRenderFacility', function () {
            THIS.getAllFacility();
        });
    }

    ngOnDestroy() {
    }


    // =============================
    // Photo Editor
    // =============================
    CANVAS = null;
    SHAPE = 'brush';
    IMAGE_PATH = '';
    CanvasTarget = 0;

    discardEditor() {
        const THIS = this;
        const modal = (<any>$('#photoEditorModal'));
        if (THIS.CANVAS != null) {
            THIS.CANVAS.dispose();
        }
        modal.modal('hide');

    }

    editImgCallUnique = 0;
    EditCurrentCanvas = null;
    CurrentEditPhoto = null;

    openPhotoEditor(preview) {
        const THIS = this;
        THIS.CurrentEditPhoto = preview;
        THIS.CanvasTarget = 0;
        const modal = (<any>$('#photoEditorModal'));
        const canvasW = window.innerWidth - 20;
        const canvasH = window.innerHeight - 130;
        $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
        if (THIS.CANVAS != null) {
            this.CANVAS.clear();
        }
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        modal.modal('show');
        const param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

        if (THIS.editImgCallUnique == 0) {
            THIS.editImgCallUnique = 1;
            $.ajax({
                url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
                type: 'post',
                data: param,
                success: function (res) {
                    THIS.editImgCallUnique = 0;
                    if (res.status === 2000) {
                        THIS.EditCurrentCanvas = res.data;
                        const canvasW = res.data.width;
                        const canvasH = res.data.height;
                        $('#canvas').attr('width', canvasW + 'px');
                        $('#canvas').attr('height', canvasH + 'px');
                        setTimeout(function () {
                            // if(THIS.CANVAS != null){
                            //     THIS.CANVAS.dispose();
                            // }

                            THIS.CANVAS = new fabric.Canvas('canvas');
                            THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
                            THIS.CANVAS.on('object:selected', function (e) {
                                THIS.CANVAS.isDrawingMode = false;
                                THIS.SHAPE = '';
                            });
                            THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
                            THIS.CANVAS.isDrawingMode = true;
                            THIS.CANVAS.freeDrawingBrush.width = 5;

                            let startY = 0,
                                startX = 0;
                            THIS.CANVAS.on('mouse:down', function (option) {
                                startY = option.e.offsetY;
                                startX = option.e.offsetX;
                            });
                            THIS.CANVAS.on('mouse:up', function (option) {
                                const selected = THIS.CANVAS.getActiveObjects();
                                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                                    if (THIS.SHAPE === 'circle') {
                                        const e = option.e;
                                        if (e.offsetX - startX > 5) {
                                            const circle = new fabric.Circle({
                                                top: startY,
                                                left: startX,
                                                radius: (e.offsetX - startX) / 2,
                                                stroke: THIS.CANVAS.freeDrawingBrush.color,
                                                strokeWidth: 5,
                                                fill: ''
                                            });
                                            THIS.CANVAS.add(circle);
                                        }
                                    }
                                }
                            });
                            THIS.CustomHelper.tooltipRender();

                            THIS.IMAGE_PATH = preview.file_path;
                            const src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
                            $('.canvas-container').css({
                                backgroundImage: 'url(\'' + src + '\')'
                            });
                            THIS.CANVAS.renderAll();
                            setTimeout(function () {
                                THIS.CanvasTarget = 1;
                            }, 1000);
                        }, 1000);
                    }
                }
            });


            history.pushState(null, null, location.href);
            window.onpopstate = function () {
                modal.modal('hide');
                history.go(1);
            };

            if ($('.color-picker-editor').length > 0) {
                Pickr.create({
                    el: '.color-picker-editor',
                    theme: 'nano', // or 'monolith', or 'nano'
                    defaultRepresentation: 'HEX',
                    useAsButton: true,
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,
                        // Input / output Options
                        interaction: {
                            hex: true,
                            input: true,
                            save: true
                        }
                    }
                }).on('change', (color, instance) => {
                    $('.color-picker-editor').css('color', color.toHEXA().toString());
                    THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
                    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
                        obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
                    });
                });
            }
            $('.color-picker-editor').css('color', '#ff3773');
        }
    }

    // =============================
    // Photo Editor Actions
    // =============================
    SketchEnable() {
        this.CANVAS.isDrawingMode = !this.CANVAS.isDrawingMode;
        if (this.CANVAS.isDrawingMode === true) {
            this.SHAPE = 'brush';
        }
    }

    eraser() {
        const THIS = this;
        THIS.CANVAS.isDrawingMode = false;
        THIS.SHAPE = '';
        const obj = THIS.CANVAS.getObjects();
        for (let i = 0; i < obj.length; i++) {
            $.each(obj, function (j:any, v) {
                if (parseInt(j) > 0) {
                    THIS.CANVAS.remove(v);
                }
            });
        }
        THIS.UID_IMG = Date.now();
        const src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
        $('.canvas-container').css({
            backgroundImage: 'url(\'' + src + '\')'
        });
    }

    eraserSelected() {
        const THIS = this;
        THIS.CANVAS.isDrawingMode = false;
        THIS.SHAPE = '';
        THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            THIS.CANVAS.remove(obj);
        });
    }

    drawCircle() {
        const THIS = this;
        this.CANVAS.isDrawingMode = false;
        this.SHAPE = 'circle';
        const circle = new fabric.Circle({
            top: 100,
            left: 100,
            width: 100,
            height: 100,
            radius: 50,
            stroke: THIS.CANVAS.freeDrawingBrush.color,
            strokeWidth: 5,
            fill: ''
        });
        THIS.CANVAS.add(circle);
    }

    UpdatePhoto(iid) {
        const THIS = this;
        const ldd = document.getElementById(iid);
        const ladda = Ladda.create(ldd);
        ladda.start();
        THIS.CANVAS.discardActiveObject();
        THIS.CANVAS.requestRenderAll();
        setTimeout(function () {
            const modal = (<any>$('#photoEditorModal'));
            const canvas = (<any>document.getElementById('canvas'));
            let imgData = canvas.toDataURL({
                format: 'png',
                quality: 1
            });
            imgData = imgData.replace('data:image/png;base64,', '');
            const param = {
                imgData: imgData,
                media_type: 1,
                file_path: THIS.IMAGE_PATH
            };
            $.ajax({
                url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
                data: param,
                type: 'post',
                success: function (res) {
                    ladda.stop();
                    modal.modal('hide');
                    THIS.UID_IMG = Date.now();
                }
            });
        }, 1000);
    }


    UID_IMG = 0;
    Gallery = null;
    Preview;

    open(index, preview) {
        const THIS = this;
        THIS.Preview = preview;
        $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
        const _albums = [];
        if (THIS.Inventory.media !== undefined) {
            for (let i = 0; i < THIS.Inventory.media.length; i++) {
                const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + THIS.Inventory.media[i].file_path + '&ref=' + Date.now();
                const caption = '';
                const thumb = '';
                const album = {
                    src: src,
                    caption: caption,
                    thumb: thumb,
                    w: THIS.Inventory.media[i].width !== undefined ? THIS.Inventory.media[i].width : 1500,
                    h: THIS.Inventory.media[i].height !== undefined ? THIS.Inventory.media[i].height : 1500
                };
                _albums.push(album);
            }
        }
        const pswpElement = document.querySelectorAll('.pswp')[0];
        const options = {
            index: index,
            shareEl: false,
            editEl: true,
            tapToClose: false,
            zoomEl: true,
            history: false
        };
        THIS.Gallery = null;
        THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
        THIS.Gallery.init();
        THIS.Gallery.listen('afterChange', function (r) {
            THIS.Preview = THIS.Inventory.media[THIS.Gallery.getCurrentIndex()];
            $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
        });
        history.pushState(null, null, location.href);
        window.onpopstate = function () {
            THIS.Gallery.close();
            history.go(1);
        };

    }

    checkCurrency() {
        Inputmask.extendAliases({
            pesos: {
                prefix: '$',
                groupSeparator: '',
                alias: 'numeric',
                autoGroup: true,
                digits: 2,
                digitsOptional: false,
                radixPoint: '.',
                placeholder: '0.00'
            }
        });

        setTimeout(function () {
            (<any>$('._currency_validate_eq')).inputmask({alias: 'pesos'});
        }, 300);
    }

    currencyIcon():string {
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
    }

}


// ============================
// Store Room Create
// ============================
export interface DialogData {
    inv_id: number;
}

@Component({
    selector: 'inventory-top-up-dialog',
    templateUrl: './inventory-top-up.component.html'
})
export class inventoryTopUpDialog {
    CustomHelper = CustomHelper;

    constructor(public dialogRef: MatDialogRef<inventoryTopUpDialog>,
                private inventoriesService: InventoriesService,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        console.log(this.data);

    }

    processing = 0;
    errMSg = '';
    form = {qty: 0, id: 0};

    intcheck() {
        this.form.qty = this.CustomHelper.makeInt(this.form.qty);
        if (isNaN(this.form.qty)) {
            this.form.qty = 0;
        }
    }


    create() {
        this.form.id = this.data.inv_id;
        this.processing = 1;
        this.inventoriesService.topUp(this.form).subscribe(res => {
            this.processing = 0;
            if (res.status == 2000) {
                this.dialogRef.close(res.data);
            }
        });
    }

    rightSecPreview = 0;


}
