import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { APPCONFIG } from '../../config';
import {CustomHelper} from '../../customhelper';
import { AssetService } from '../../_services/asset.service';
import { FacilityService } from '../../_services/facility.service';
import { FcmService } from '../../_services/fcm.service';
declare let swal: any;
declare let toastr: any;
declare let Ladda: any;
declare let Pusher: any;


@Component({
    selector: 'page-assets',
    templateUrl: './assets.component.html'
})
export class AssetsComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Permission = -1;

    restoreAlert = false;

    totalAssets = 0;
    pageNo = 1;
    perPage = this.CustomHelper.getPerpage();
    pageEvent;
    order_by = 'desc';
    paginateList() {
        if (this.pageEvent.pageSize == this.perPage) {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex + 1;
            this.getAllAssets();
            localStorage.perpage = this.perPage;
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
            this.getAllAssets();
            localStorage.perpage = this.perPage;
        }
    }


    facilityEmpty = 0;
    AllEnableFacility() {
        let ldd = document.getElementById('allEnableFac');
        let ladda = Ladda.create(ldd);
        ladda.start();
        this.facilityService.enableAll(0).subscribe(res => {
            ladda.stop();
            this.facilityService.refreshFacility();
        });
    }
    previewList = 0;
    assets = [];
    getAllAssets() {
        let THIS = this;
        this.previewList = 0;
        this.assets.length = 0;
        let param = {
            keyword: this.searchKeyword,
            pageNo: this.pageNo,
            perPage: this.perPage,
            sort_by: this.sort_by,
            order_by: this.order_by,
            is_active: this.wo_is_active
        };
        localStorage.perPageAss = this.perPage;
        localStorage.sort_byAss = this.sort_by;
        localStorage.order_byAss = this.order_by;
        this.assets.length = 0;
        this.facilityEmpty = 0;
        this.assetService.getAssetPaginate(param).subscribe(res => {
            this.previewList = 1;
            if (res.status == 2000) {
                this.totalAssets = res.total;
                this.assets = res.data;
                setTimeout(function () {
                    THIS.CustomHelper.tooltipRender();
                }, 100);
            }
            else if (res.status == 400) {
                THIS.facilityEmpty = 1;
                THIS.assets.length = 0;
                THIS.totalAssets = 0;
            }
        });
        this.getAssetStatus();
    }
    formatDate(data: any){
      let date = new Date(data);
      let options = {
         year: 'numeric', month: 'long',
        day: 'numeric', hour: '2-digit', minute: '2-digit'
      };
      const arr = date.toLocaleTimeString( 'en-us', options).split(' ');
      return arr[1].split(',')[0]+' '+arr[0]+' '+arr[2]+' '+arr[4];
    }
    selectedAssets = [];
    selectThisAssets(e, asset) {
        e.preventDefault();
        if (e.ctrlKey) {
            if (e.which == 1) {
                let index = this.selectedAssets.indexOf(asset.id);
                if (index > -1) {
                    this.selectedAssets.splice(index, 1);
                } else {
                    this.selectedAssets.push(asset.id);
                }
            }
        } else {
            this.selectedAssets.length = 0;
            if(this.wo_is_active !== 0 && this. wo_is_active !==2 ){
              this.previewAssetSingle(asset);
            }else{
              if(this.wo_is_active == 0){
                this.restoreAlert = true;
              }else{
                this.restoreAlert = false;
              }
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#PreviewAlert')).modal('show');
            }
        }
    }
  ClosePreviewAlert(){
    (<any>$('#PreviewAlert')).modal('hide');
  }
    rightClickMenu(e, asset) {
        e.preventDefault();

        let THIS = this;
        if (this.selectedAssets.length <= 2) {
            this.selectedAssets.length = 0;
            this.selectedAssets = [asset.id];
        }

        let target = $(e.target);
        let w = target.closest('#right-click-holder').width();
        let h = target.closest('#right-click-holder').height();
        let p = target.closest('#right-click-holder').offset();
        let left = e.clientX - p.left;
        let top = e.clientY - p.top;
        let clickH = $('.drop-menu-right').height();
        // clickH = clickH < 150 ? 400 : clickH;
        if (this.selectedAssets.length > 1) { clickH = 150; }
        if ((w - left) < 230) { left = w - 250; }
        if (h < top + clickH) { top = top - (top + clickH - h); }
        if (top < 10) { top = 10; }

        let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
        if (ttarget.hasClass('open')) {
            $('.drop-menu-right').removeClass('open');
            ttarget.css({
                top: top,
                left: left
            });
            setTimeout(function () {
                ttarget.addClass('open');
            }, 100);
        }
        else {
            ttarget.css({
                top: top,
                left: left
            });
            ttarget.addClass('open');
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }
    dropMenuRightToggle(e) {
        var container = $('.outsideClick2');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.drop-menu-right').removeClass('open');
        }
    }

    checkThisRow(e, asset) {
        e.preventDefault();
        let THIS = this;
        let index = this.selectedAssets.indexOf(asset.id);
        if (index > -1) {
            if (this.selectedAssets.length == 1) {
                this.selectedAssets.length = 0;
            } else {
                this.selectedAssets.splice(index, 1);
            }
        } else {
            this.selectedAssets.push(asset.id);
        }
        $('.drop-menu-right').removeClass('open');
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);

    }
    selectThisRow(e, work) {
        e.preventDefault();
        let THIS = this;
        if (e.ctrlKey) {
            if (e.which == 1) {
                let index = this.selectedAssets.indexOf(work.id)
                if (index > -1) {
                    this.selectedAssets.splice(index, 1);
                } else {
                    this.selectedAssets.push(work.id);
                }
                $('.drop-menu-right').removeClass('open');
            }
        }

        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }




    removeAssetPreview(id) {
        this.selectedAssets.length = 0;
        this.selectedAssets = [id];
        this.removeThisAssets();
    }
    emptyTrash() {
        let THIS = this;
        let ldd = document.getElementById('emptyTrashBtn');
        let ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'assets/empty/trash?token=' + THIS.currentUser.token,
            data: {},
            type: 'post',
            success: function (res) {
                THIS.selectedAssets.length = 0;
                ladda.stop();
                (<any>$('#EmptyTrashModal')).modal('hide');
                toastr.success("Assets have been permanently deleted.", "Assets Empty", this.toastrOption);
                THIS.getAllAssets();
                // Pusher emit
            }
        });
    }
    emptyThisWork() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#EmptyTrashModal')).modal('show');
    }
    removeThisAssets() {
        let THIS = this;
        let ldd = document.getElementById('removeWorkOrderBtn');
        let ladda = Ladda.create(ldd);
        ladda.start();
        let text = 'Are you sure you want to delete this asset(s)?';
        if (THIS.selectedAssets.length == 1) {
            let index = this.assets.map(e => e.id).indexOf(this.selectedAssets[0]);
            let woCount = this.assets[index].wo_count;
            if (woCount > 0) {
                text = 'This Asset is assigned to ' + woCount + ' work order(s). This Asset will be unassigned from the work order(s) if you proceed.';
            }
        }
        let ids = THIS.selectedAssets;
      console.log(ids);
        THIS.assetService.removeAssets({ ids: ids }).subscribe(res => {
            THIS.selectedAssets.length = 0;
            THIS.discardAll();
            THIS.getAllAssets();
            ladda.stop();
            // THIS.undoRemoveThisAssets(ids);
            (<any>$('#deleteWorkOrderModal')).modal('hide');
            toastr.success('This asset has been deleted successfully', 'Asset Delete', this.toastrOption);
        });

    }
    deleteThisWorkOrder() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('.drop-menu-right').removeClass('open');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#deleteWorkOrderModal')).modal('show');
    }
    undoRemoveThisAssets(ids) {
        let THIS = this;
        let ldd = document.getElementById('restoreWorkBtn');
        let ladda = Ladda.create(ldd);
        ladda.start();
        THIS.assetService.undoRemoveAssets({ ids: ids }).subscribe(res => {
            THIS.selectedAssets.length = 0;
            THIS.discardAll();
            THIS.getAllAssets();
            ladda.stop();
            (<any>$('#RestoreModal')).modal('hide');
            toastr.success('This asset has been restored successfully', 'Asset Restore', this.toastrOption);
            // Pusher Emit
        });
    }
    restoreThisWork() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#RestoreModal')).modal('show');
    }

    archiveAssetSlow(id) {
      this.selectedAssets = [];
      this.selectedAssets = [id];
      this.archiveThisAssets();
    }
    archiveThisAssets() {
        let THIS = this;
        let ldd = document.getElementById('archiveWorkBtn');
        let ladda = Ladda.create(ldd);
        ladda.start();
        let ids = THIS.selectedAssets;
        THIS.assetService.archiveAssets({ ids: ids }).subscribe(res => {
            THIS.selectedAssets.length = 0;
            THIS.discardAll();
            THIS.getAllAssets();
            ladda.stop();
            (<any>$('#ArchiveModal')).modal('hide');
            toastr.success('This asset has been archived successfully', 'Asset Archive', this.toastrOption);
            // Pusher Emit
        });
    }
    archiveThisWorkOrder() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('.drop-menu-right').removeClass('open');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#ArchiveModal')).modal('show');
    }
    undoArchiveThisAssets(ids) {
        let THIS = this;
        let ldd = document.getElementById('unarchiveWorkBtn');
        let ladda = Ladda.create(ldd);
        ladda.start();
        THIS.assetService.undoArchiveAssets({ ids: ids }).subscribe(res => {
            THIS.discardAll();
            THIS.getAllAssets();
            THIS.selectedAssets.length = 0;
            ladda.stop();
            (<any>$('#UnarchiveModal')).modal('hide');
            toastr.success('This inspection has been unarchived successfully', 'Asset Unarchive', this.toastrOption);
        });
    }
    unarchiveThisWorkOrder() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#UnarchiveModal')).modal('show');
    }
    editThisAssets() {
        let preId = '';
        const index = this.assets.map(e => e.id).indexOf(this.selectedAssets[0]);
        if (index > -1) {
            let asset = this.assets[index];
            preId = asset.uid;
            this.editAsset(preId);
        }
    }

    wo_is_active = 1;
    searchKeyword = '';
    searchKeywordClear() {
        this.searchKeyword = '';
        this.getAllAssets();
    }
    filter() {
        let THIS = this;
        clearInterval(THIS.CustomHelper.woListSearch);
        THIS.CustomHelper.woListSearch = setInterval(function () {
            clearInterval(THIS.CustomHelper.woListSearch);
            THIS.getAllAssets();
        }, 1000);
    }
    highlighter(subject) {
        if (subject == '' || subject == null) {
            return subject;
        } else {
            subject = this.CustomHelper.makeStr(subject);
        }
        let matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
        let res = '';
        $.each(matches, function (i, v) {
            res = subject.replace(v, "<span class='text-warning-highlight'>" + v + "</span>");
        });
        if (res == '') {
            res = subject;
        }
        return res;
    }
    selectAllCheck() {
        let THIS = this;
        let WO = this.assets;
        if (this.selectedAssets.length == this.assets.length) {
            this.selectedAssets.length = 0;
        } else {
            this.selectedAssets.length = 0;
            $.each(WO, function (i, v) {
                THIS.selectedAssets.push(v.id);
            });
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }
    FilterOn = 0;
    FilterScroll = 235;
    ToggleFilter(c) {
        if (this.wo_is_active != 0) {
            this.FilterScroll = 235;
            if (this.miniScreen == 1) {
                this.FilterScroll = 290;
            }
        } else {
            this.FilterScroll = 275;
            if (this.miniScreen == 1) {
                this.FilterScroll = 290;
            }
        }
    }
    SortTitle = 'Created Date';
    sort_by = 'created_at';
    SortOptions = [
        { title: 'Created Date', slug: 'created_at' },
        { title: 'Status', slug: 'status_code' },
        { title: 'Asset ID', slug: 'uid' },
        { title: 'Facility', slug: 'facility' },
        { title: 'Asset Name', slug: 'name' },
        { title: 'Brand/Model', slug: 'brand' },
        { title: 'Serial Number', slug: 'serial_number' },
        { title: 'Location', slug: 'location' },
        { title: 'Purchase Cost', slug: 'purchase_cost' },
    ];
    Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
    changeDensity(density) {
        let THIS = this;
        this.Density = density;
        localStorage.DensityAss = this.Density;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
        let param = { asset: '' };
        density == 1 ? param.asset = 'd' : '';
        density == 2 ? param.asset = 'cf' : '';
        density == 3 ? param.asset = 'cp' : '';
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'me/density?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
            }
        });
    }
    statusCheck = [];
    FilterName = 'All';
    updateStatusCheck(status) {
      this.selectedAssets=[];
        this.wo_is_active = status;
        if (this.wo_is_active == 1) {
            this.FilterName = 'All';
        } else if (this.wo_is_active == 2) {
            this.FilterName = 'Archived';
        } else if (this.wo_is_active == 0) {
            this.FilterName = 'Trashed';
        }
        this.ToggleFilter('1');
        this.getAllAssets();
    }
    CounterStatus = {
        'total': 0,
        'archived': 0,
        'trash': 0,
    };
    getAssetStatus() {
        let THIS = this;
        let param = {};
        this.assetService.getAssetStatus(param).subscribe(res => {
            if (res.status == 2000) {
                this.CounterStatus = {
                    'total': res.total,
                    'archived': res.archived,
                    'trash': res.trash,
                };
            }
        });
    }
    FacilityMenu = 0;
    changeFacilityMenu() {
        let THIS = this;
        THIS.FacilityMenu = THIS.FacilityMenu == 1 ? 0 : 1;
        $.ajax({
            url: THIS.APPCONFIG.api + 'assets/update/facility/menu?token=' + THIS.currentUser.token,
            type: 'post',
            data: {},
            success: function (res) {
                toastr.success(res.msg);
                THIS.facilityService.refreshFacility();
            }
        });
    }
    QRCode = 0;
    changeQRCode() {
        let THIS = this;
        THIS.QRCode = THIS.QRCode == 1 ? 0 : 1;
        $.ajax({
            url: THIS.APPCONFIG.api + 'assets/update/QRCode/menu?token=' + THIS.currentUser.token,
            type: 'post',
            data: {},
            success: function (res) {
                toastr.success(res.msg);
                $('#layOutVisual').click();
            }
        });
    }


    currentUser = null;
    toastrOption = { timeOut: 5000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true };
    constructor(private assetService: AssetService,
        public snackBar: MatSnackBar,
        public facilityService: FacilityService,
        private fcmService: FcmService) {
        let THIS = this;
        setTimeout(function () {

            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            THIS.Permission = Session.user_type > 2 ? Session.modules.assets : 1;
            THIS.currentUser = Session;
            THIS.FacilityMenu = THIS.currentUser.fac_asset;
            THIS.QRCode = THIS.currentUser.asset_qr;
            // Pagination
            THIS.currentUser.pagination != null ? THIS.perPage = THIS.currentUser.pagination.asset : '';
            // 1 = Default, 2 = Comfortable, 3 = Compact
            THIS.currentUser.list_view.asset.d == 1 ? THIS.Density = 1 : '';
            THIS.currentUser.list_view.asset.cf == 1 ? THIS.Density = 2 : '';
            THIS.currentUser.list_view.asset.cp == 1 ? THIS.Density = 3 : '';

            if (THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] != undefined) {
                window.history.pushState("", "", THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute]);
            }
            THIS.facilityService.currentSelectedFac.subscribe(value => THIS.getAllAssets());
            THIS.routeMake();
            if (window.innerWidth <= 1024) {
                THIS.miniScreen = 1;
                THIS.FilterScroll = 290;
            }

            let initialFacility = JSON.parse(localStorage.getItem('Facility'));
            THIS.initialFacilityId = initialFacility.length > 0 ? initialFacility[0].id : 0;

        });
    }
    Template = 0;
    miniScreen = 0;
    ROUTES = '/assets';
    preId = '';
    routeMake() {
        let route = this.CustomHelper.getRouteStr();
        if (route[2] != undefined) {
            if (route[2] == '') {
                this.Template = 0;
                this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
            }
            else if (route[2] == 'new') { this.createNewAsset(); }
            else if (route[2] == 'edit') {
                if (route[3] != undefined) {
                    let preId = route[3];
                    this.editAsset(preId);
                }
            }
            else {
                let preId = route[2];
                this.previewAsset(preId);
            }
        } else {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + this.ROUTES;
            window.history.pushState("", "", url);
            this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
            this.Template = 0;
        }
    }
    createNewAsset() {
        let THIS = this;
        this.Template = 1;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + this.ROUTES + '/new';
        this.preId = '';
        window.history.pushState("", "", url);
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 500);
    }
    previewAsset(preId) {
        let THIS = this;
        this.Template = 2;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + this.ROUTES + '/' + preId;
        this.preId = preId;
        window.history.pushState("", "", url);
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 500);
    }
    previewAssetSlow(preId) {
        this.preId = '';
        let THIS = this;
        setTimeout(function () {
            THIS.previewAsset(preId);
        }, 100);
    }
    previewAssetSingle(asset) {
        let preId = asset.uid;
        this.previewAsset(preId);
    }
    editAsset(preId) {
        let THIS = this;
        this.Template = 3;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + this.ROUTES + '/edit/' + preId;
        this.preId = preId;
        window.history.pushState("", "", url);
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 500);
    }
    editAssetSlow(preId) {
        this.preId = '';
        // this.Template = 0;
        let THIS = this;
        setTimeout(function () {
            THIS.editAsset(preId);
        }, 100);
    }
    discardAll() {
        let THIS = this;
        this.Template = 0; this.preId = '';
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + this.ROUTES;
        window.history.pushState("", "", url);
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 500);
    }


    ngOnInit() {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        //===============
        // Pusher
        //===============
        Pusher.logToConsole = true;
        let pusher = new Pusher('501b3b237bd799758df0', {
            cluster: 'ap4'
        });
        let channel = pusher.subscribe('bm-assets');
        channel.bind('RefreshAsset', function (data) {
            if (Session.team === data.message.team) {
                THIS.getAllAssets();
            }
        });
        //===============
        // Pusher
        //===============
    }
    ngOnDestroy() {
    }


    initialFacilityId = 0;
    OpenImportSection() {
        let THIS = this;

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#openImportModal')).attr('data-id', THIS.initialFacilityId);
        (<any>$('#openImportModal')).modal('show');
    }

    pdfThisAsset() {
        let THIS = this;
        let id = this.selectedAssets[0];
        window.open(THIS.APPCONFIG.apiAsset + 'make/pdf/' + id);
    }

    zipThisAsset() {
        let THIS = this;
        let ids = this.selectedAssets.join();
        let win = window.open(THIS.APPCONFIG.apiAsset + 'zip/image/' + ids + '?token=' + THIS.currentUser.token, '_blank');
        win.focus();
    }
}
