import {Component, Input, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {Lightbox} from 'angular2-lightbox';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {AssetService} from '../../../_services/asset.service';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';
import {FcmService} from '../../../_services/fcm.service';

declare let google: any;
declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let Pusher: any;
declare let fabric: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;
declare let Pickr: any;
declare let Inputmask: any;


@Component({
  selector: 'page-assets-single',
  templateUrl: './asset-single.component.html'
})
export class AssetSingleComponent {
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  @Output() discard = new EventEmitter<number>();
  @Output() preview = new EventEmitter<number>();
  @Output() editPreview = new EventEmitter<number>();
  @Output() archivePreview = new EventEmitter<number>();
  @Output() removePreview = new EventEmitter<number>();
  @Output() listRefresh = new EventEmitter<number>();
  @Input() Template: any;
  TemplateCheck = 2;
  @Input() preId: any;
  AssetDetailsChange = 0;
  Asset: any;

  order_by = 'desc';
  _albums = [];
  _selected_tab = 1;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  inlinePrefixId = 0;

  updateChangesDiscard() {
  }

  previewList = 0;
  ContractStartOption = {
    altInput: true,
    defaultDate: null,
    minDate: null,
    altFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };
  ContractEndOption = {
    altInput: true,
    defaultDate: null,
    minDate: null,
    altFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };
  param = {
    id: 0,
    asset_id: '',
    prefix: '',
    name: '',
    facility: '',
    category_id: '',
    location: '',
    geo: '',
    brand: '',
    serial_number: '',
    media_files: '',
    attach_files: '',
    purchase_cost: '',
    service_personnel: '',
    phone: '',
    email: '',
    contract_start: '',
    contract_end: '',
    notify: 0,
    notify_duration: '',
    depreciation_cost: '',
    eol: '',
    asset_life: '',
    depreciation_method: '',
  };

  validForm = 1;

  saveWorkOrderSubmit() {
    const THIS = this;
    let mediaFiles = '';
    $.each(THIS.selectedFilesPreview, function (i, v) {
      if (i == 0) {
        mediaFiles += v.id;
      } else {
        mediaFiles += ',' + v.id;
      }
    });
    let mediaAttach = '';
    $.each(THIS.selectedAssetAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });

    const ldd = document.getElementById('SaveThisLadda');
    const ladda = Ladda.create(ldd);

    this.param.media_files = mediaFiles;
    this.param.attach_files = mediaAttach;
    this.param.purchase_cost = (<any>$('input[name="purchase_cost"]').val()).replace(/\$/, '');
    this.param.depreciation_cost = (<any>$('input[name="depreciation_cost"]').val()).replace(/\$/, '');
    this.param.eol = (<any>$('input[name="eol"]').val()).replace(/\$/, '');

    const valid = this.validationThisForm();
    if (valid == 1) {
      const THIS = this;
      ladda.start();
      this.assetService.editAsset(this.param).subscribe(res => {
        ladda.stop();
        if (res.status == 7000) {
          this.coolDialogs.alert('The Asset ID is already been taken. Please use a unique one', {
            title: 'Form Validation',
            color: '#e0e0e0',
          });
        } else {
          ladda.stop();
          this.preview.emit(this.preId);
          toastr.success('Asset has been successfully updated', 'Update Asset', this.toastrOption);
          // Pusher emit
        }
      });
    }
  }

  validationThisForm() {
    let valid = 1;
    const form = $('#workCreateForm');
    if (this.param.facility == '') {
      valid = 0;
    }
    /*if (this.param.prefix == '') {
        valid = 0;
    }
    if (this.param.asset_id == '') {
        valid = 0;
    }*/
    if (this.param.name == '') {
      valid = 0;
      form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
    } else {
      form.find('[data-el="subject"]').removeClass('has-error');
    }
    this.validForm = valid;
    return valid;
  }

  clearValidation(e) {
    const trigger = $(e.target);
    const target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  statusUpdate(status_code) {
    const ldd = document.getElementById('statusUpdate');
    const ladda = Ladda.create(ldd);
    const THIS = this;
    if (status_code == 0 && THIS.Asset.wo_count > 0) {
      swal({
        title: 'Asset Switch Offline Notice',
        text: 'This Asset is assigned to ' + THIS.Asset.wo_count + ' work order. If you change the status to Offline, it will be unassigned from the work order.',
        icon: 'warning',
        buttons: ['Cancel', 'Update Status'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          const param = {
            id: THIS.Asset.id,
            name: THIS.Asset.name,
            prefix: THIS.Asset.prefix,
            asset_id: THIS.Asset.asset_id,
            facility: THIS.Asset.facility,
            status_code: status_code
          };
          ladda.start();
          THIS.assetService.editAsset(param).subscribe(res => {
            ladda.stop();
            THIS.Asset.status_code = status_code;
            THIS.Asset.wo_count = 0;
            THIS.listRefresh.emit(1);
          });
        }
      });
    } else {
      const param = {
        id: THIS.Asset.id,
        name: THIS.Asset.name,
        prefix: THIS.Asset.prefix,
        asset_id: THIS.Asset.asset_id,
        facility: THIS.Asset.facility,
        status_code: status_code
      };
      ladda.start();
      THIS.assetService.editAsset(param).subscribe(res => {
        ladda.stop();
        THIS.Asset.status_code = status_code;
        THIS.listRefresh.emit(1);
      });
    }
  }

  archiveThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('.drop-menu-right').removeClass('open');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#ArchiveModal')).modal('show');
  }

  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('.drop-menu-right').removeClass('open');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }


  SortTitle = 'Created Date';
  sort_by = 'created_at';
  SortOptions = [
    {title: 'Created Date', slug: 'created_at'},
    {title: 'Status', slug: 'status_code'},
    {title: 'Job ID', slug: 'uid'},
    {title: 'Facility', slug: 'facility'},
  ];


  currentUser: any;

  constructor(private assetService: AssetService,
              private mediaService: MediaService,
              private _lightbox: Lightbox,
              private coolDialogs: NgxCoolDialogsService,
              private fcmService: FcmService,
              public snackBar: MatSnackBar,
              private facilityService: FacilityService) {
    this.getAllcategory();
    this.currentUser = JSON.parse(localStorage.currentUserInfo);
    const THIS = this;
    this.ContractStartOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.viewDates = 0;
      setTimeout(function () {
        THIS.viewDates = 1;
        THIS.ContractEndOption.minDate = new Date(THIS.param.contract_start);
        THIS.param.contract_start = THIS.CustomHelper.formatDateOnlyToDB(selectedDates);
      }, 500);
    };
    this.ContractEndOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.param.contract_end = THIS.CustomHelper.formatDateOnlyToDB(selectedDates);
    };
    THIS.checkCurrency();
    $('body').off().on('click', '.pswp__button--edit', function () {
      if (THIS.Gallery !== null) {
        THIS.Gallery.close();
      }
      THIS.Preview = JSON.parse($('#EditImageData').attr('data-type'));
      THIS.openPhotoEditor(THIS.Preview);
    });
  }


  Facility = [];
  FacilityComp = [];

  getAllFacility() {
    const THIS = this;
    this.facilityService.getFacilitiesList().subscribe(res => {
      this.Facility.length = 0;
      this.FacilityComp.length = 0;
      this.FacilityComp = res.facility;
      $.each(res.facility, function (i, v) {
        v['pp'] = 0;
        THIS.Facility.push(v);
        $.each(v.child, function (j, vC) {
          vC['pp'] = 1;
          THIS.Facility.push(vC);
          $.each(vC.child, function (k, vCC) {
            vCC['pp'] = 2;
            THIS.Facility.push(vCC);
            $.each(vCC.child, function (l, vCCC) {
              vCCC['pp'] = 3;
              THIS.Facility.push(vCCC);
            });
          });
        });
      });
      setTimeout(function () {
        if ($('.reRenderFacility').length > 0) {
          const facilityId = parseInt($('.reRenderFacility').attr('data-id'));
          if (facilityId > 0) {
            THIS.param.facility = facilityId.toString();
          }
        }
        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).val(THIS.param.facility);
        (<any>$('.chosen-default-single-fac')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Facility'
        }).on('change', function (e, p) {
          if (e.target.value === '000') {
            let ___selectedTab = 0;
            const tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            const child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#createFacilityModal')).modal('show');
          } else {
            THIS.param.facility = e.target.value;
            THIS.validationThisForm();
            THIS.updateChangesDiscard();
          }
        });
      }, 1000);
    });
  }

  Categories = [];
  CategoryDefault = [];
  CategoryCustom = [];

  getAllcategory() {
    const THIS = this;
    this.assetService.getAssetCatList().subscribe(res => {
      this.Categories.length = 0;
      this.CategoryDefault.length = 0;
      this.CategoryCustom.length = 0;
      this.Categories = res;
      const THIS = this;
      $.each(res, function (i, v) {
        if (v.user_id == 0) {
          THIS.CategoryDefault.push(v);
        } else {
          THIS.CategoryCustom.push(v);
        }
      });
      setTimeout(function () {
        (<any>$('.chosen-default-single-cat')).chosen('destroy');
        (<any>$('.chosen-default-single-cat')).val(THIS.param.category_id);
        (<any>$('.chosen-default-single-cat')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Category'
        }).on('change', function (e, p) {
          THIS.param.category_id = e.target.value;
          THIS.updateChangesDiscard();
        });
      }, 100);
    });
  }

  CMap;

  GmapZoomIn(data) {
    this.CMap.setZoom(this.CMap.getZoom() + data);
  }

  geoLocation(place) {
    const THIS = this;
    this.param.geo = JSON.stringify({
      address: place.formatted_address,
      geometry: place.geometry.location
    });
    setTimeout(function () {
      const mapOptions = {
        zoom: 13,
        center: place.geometry.location,
        gestureHandling: 'cooperative',
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false
      };
      THIS.CMap = new google.maps.Map(document.getElementById('geo-map'), mapOptions);
      new google.maps.Marker({
        position: place.geometry.location,
        map: THIS.CMap,
        draggable: true,
        title: place.formatted_address
      });
    }, 100);
  }

  geoLocationMap(e) {
    const THIS = this;
    setTimeout(function () {
      if (THIS.param.geo == '') {
        const ltln = e.target.value.split(',');
        if (ltln.length == 2) {
          const latlon = new google.maps.LatLng(ltln[0], ltln[1]);
          THIS.param.geo = JSON.stringify({
            address: e.target.value,
            geometry: latlon
          });
          setTimeout(function () {
            const mapOptions = {
              zoom: 13,
              center: latlon,
              gestureHandling: 'cooperative',
              mapTypeControl: false,
              streetViewControl: false,
              zoomControl: false
            };
            THIS.CMap = new google.maps.Map(document.getElementById('geo-map'), mapOptions);
            new google.maps.Marker({
              position: latlon,
              map: THIS.CMap,
              draggable: false
            });
          }, 100);
        }
      }
    }, 100);
  }

  workOrders = [];
  selectedWorkOrders = [];

  getWorkOrder() {

    const THIS = this;
    this.previewList = 0;

    const param = {asset_id: this.param.id};
    this.assetService.getWorkOrder(param).subscribe(res => {
      this.previewList = 1;
      if (res.status == 2000) {
        this.workOrders = res.data;
      }
    });
  }




  // selectedFiles = [];
  selectedFilesPreview = [];

  removeThisMedia(id, media_type) {
    const param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  removeThisImage(index, id) {
    // this.selectedFiles.splice(index, 1);
    this.selectedFilesPreview.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  _uploadError = 0;
  _locPermit = 0;

  uploadThisMedia(file, media_type, localtion, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  dragFilesDropped(e) {
    e.preventDefault();
    this._uploadError = 0;
    const THIS = this;
    if (e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        if (this.selectedFilesPreview.length < 1) {
          const eachFile = e.dataTransfer.files[i];
          // this.selectedFiles.push(eachFile);
          if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            if (THIS.currentUser.modules.geo_tagged_photo == 1) {
              navigator.geolocation.getCurrentPosition(function (position) {
                if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                  THIS._locPermit = 1;
                  const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                  THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  THIS._locPermit = -1;
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                }
              }, function (error) {
                swal({
                  title: 'Share Geo Location',
                  text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                  icon: 'warning',
                  buttons: ['Want to share location', 'Don\'t want to share Location'],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                      if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                          swal({
                            title: 'Storage Full',
                            text: 'Your Storage is full. Please delete some files',
                            icon: 'error',
                            dangerMode: true,
                            className: 'swal-btn-center'
                          });
                        } else {
                          swal({
                            title: 'Invalid file format uploaded',
                            text: 'Please upload an image in Jpeg or Png only.',
                            icon: 'error',
                            dangerMode: true,
                            className: 'swal-btn-center'
                          });
                        }
                      } else {
                        THIS.selectedFilesPreview.push(uploaded);
                      }
                    });
                  } else {
                    swal('If you want to share location then change the browser settings and remove the block status');
                  }
                });
              });
            } else {
              THIS._locPermit = -1;
              THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                if (uploaded.status != undefined) {
                  if (uploaded.status == 9000) {
                    swal({
                      title: 'Storage Full',
                      text: 'Your Storage is full. Please delete some files',
                      icon: 'error',
                      dangerMode: true,
                      className: 'swal-btn-center'
                    });
                  } else {
                    swal({
                      title: 'Invalid file format uploaded',
                      text: 'Please upload an image in Jpeg or Png only.',
                      icon: 'error',
                      dangerMode: true,
                      className: 'swal-btn-center'
                    });
                  }
                } else {
                  THIS.selectedFilesPreview.push(uploaded);
                }
              });
            }
          } else {
            this._uploadError = 1;
          }
        }
      }
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedFilesPreview.length < 1) {
        const eachFile = accepted[i];
        // this.selectedFiles.push(eachFile);
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                THIS._locPermit = 1;
                const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              } else {
                THIS._locPermit = -1;
                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              }
            }, function (error) {
              swal({
                title: 'Share Geo Location',
                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                icon: 'warning',
                buttons: ['Want to share location', 'Don\'t want to share Location'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  swal('If you want to share location then change the browser settings and remove the block status');
                }
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please delete some files',
                    icon: 'error',
                    dangerMode: true,
                    className: 'swal-btn-center'
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                    className: 'swal-btn-center'
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
              }
            });
          }
        } else {
          this._uploadError = 1;
        }
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  dragFileOverStart() {
    const trigger = $('#uploadDragWork');
    trigger.addClass('onEnter');
  }

  dragFileOverEnd() {
    const trigger = $('#uploadDragWork');
    trigger.removeClass('onEnter');
  }

  selectedAssetAttach = [];

  uploadConfileTrigger() {
    $('#fileCon').click();
  }

  uploadConTaBfileTrigger() {
    $('#fileConTab').click();
  }

  uploadConFile(e) {
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      const eachFile = accepted[i];
      this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please delete some files',
              icon: 'error',
              dangerMode: true,
              className: 'swal-btn-center'
            });
          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allwoed',
              icon: 'error',
              dangerMode: true,
              className: 'swal-btn-center'
            });
          }
        } else {
          THIS.selectedAssetAttach.push(uploaded);
        }
      });
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  removeThisAttach(index, id) {
    this.selectedAssetAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  addWorkAttach() {
    $('#workAttach').click();
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedAssetAttach.length < 3) {
        const eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
                className: 'swal-btn-center'
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allwoed',
                icon: 'error',
                dangerMode: true,
                className: 'swal-btn-center'
              });
            }
          } else {
            THIS.selectedAssetAttach.push(uploaded);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  confirmDiscard() {
    this.discard.emit(1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.Template != undefined) {
      this.TemplateCheck = 0;
      this.TemplateCheck = changes.Template.currentValue;
    }
    if (changes.preId != undefined) {
      this.AssetDetailsChange = 0;
      const preId = changes.preId.currentValue;
      if (preId != '') {
        this.preId = preId;
        this.getSingleAsset(preId);
      }
    }
  }

  getSingleAsset(preId) {
    const THIS = this;
    const param = {uid: preId};
    this.viewDates = 0;
    this.assetService.getAssetSingle(param).subscribe(res => {
      if (res.status == 2000) {
        const asset = res.data;
        asset.facStr = asset.facStr.reverse();
        this.Asset = asset;
        this._albums.length = 0;
        if (this.Asset.media !== undefined) {
          for (let i = 0; i < this.Asset.media.length; i++) {
            const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + this.Asset.media[i].file_path;
            const caption = '';
            const thumb = '';
            const album = {
              src: src,
              caption: caption,
              thumb: thumb,
              w: this.Asset.media[i].width !== undefined ? this.Asset.media[i].width : 1500,
              h: this.Asset.media[i].height !== undefined ? this.Asset.media[i].height : 1500
            };
            this._albums.push(album);
          }
        }
        this.selectedFilesPreview = this.Asset.media;
        this.selectedAssetAttach = this.Asset.attach;
        // this.selectedFiles = this.Asset.media.length;

        this.param.id = asset.id;
        this.param.asset_id = asset.asset_id;
        this.param.prefix = asset.prefix;
        this.param.name = asset.name;
        this.param.facility = asset.facility;
        this.param.category_id = asset.category_id != null ? this.CustomHelper.makeStr(asset.category_id) : '';
        this.param.location = asset.location;
        this.param.brand = asset.brand;
        this.param.serial_number = asset.serial_number;
        this.param.purchase_cost = asset.purchase_cost;
        this.param.service_personnel = asset.service_personnel;
        this.param.phone = asset.phone;
        this.param.email = asset.email;
        this.param.contract_start = asset.contract_start;
        this.param.contract_end = asset.contract_end;
        this.param.notify = asset.notify;
        this.param.notify_duration = asset.notify_duration;
        this.param.depreciation_cost = asset.depreciation_cost;
        this.param.eol = asset.end_of_life_value;
        this.param.asset_life = asset.life;
        this.param.depreciation_method = asset.depreciation_method;

        this.ContractStartOption.defaultDate = this.Asset.contract_start;
        this.ContractEndOption.defaultDate = this.Asset.contract_end;
        this.viewDates = 1;

        if (this.Asset.contract_start != null && this.Asset.contract_end != null) {
          this.contractRange = (this.Asset.contractTotalGone.d / this.Asset.contractTotalDay.d) * 100;
        }
        this.AssetDetailsChange = 1;
        this.param.geo = asset.geo;
        if (this.param.geo != null && this.param.geo != '' && this.Template == 2) {
          const place = JSON.parse(this.param.geo);
          setTimeout(function () {
            const mapOptions = {
              zoom: 13,
              center: place.geometry,
              gestureHandling: 'cooperative',
              mapTypeControl: false,
              streetViewControl: false,
              zoomControl: false
            };
            THIS.CMap = new google.maps.Map(document.getElementById('geo-map-single'), mapOptions);
            // tslint:disable-next-line:no-unused-expression
            new google.maps.Marker({
              position: place.geometry,
              map: THIS.CMap,
              draggable: true,
              title: place.address
            });
          }, 100);
        }
        if (this.param.geo != null && this.param.geo != '' && this.Template == 3) {
          const place = JSON.parse(this.param.geo);
          setTimeout(function () {
            const mapOptions = {
              zoom: 13,
              center: place.geometry,
              gestureHandling: 'cooperative',
              mapTypeControl: false,
              streetViewControl: false,
              zoomControl: false
            };
            THIS.CMap = new google.maps.Map(document.getElementById('geo-map'), mapOptions);
            new google.maps.Marker({
              position: place.geometry,
              map: THIS.CMap,
              draggable: true,
              title: place.address
            });
            (<any>document.getElementById('geo-map-input')).value = place.address;
          }, 100);
        }
        this.getWorkOrder();
        this.getAllFacility();
        this.getAllcategory();
        this.checkCurrency();

      } else if (res.status === 2200) {
        THIS.param.facility = res.facility_id;
        THIS.AssetDetailsChange = -22;
      } else {
        this.AssetDetailsChange = -1;
      }
    });
  }

  _fc_updating = 0;

  updateThisFacility(id) {
    const THIS = this;
    this._fc_updating = 1;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/enable?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        THIS._fc_updating = 0;
        THIS.AssetDetailsChange = 0;

        const id = THIS.preId;
        THIS.getSingleAsset(id);
        THIS.facilityService.refreshFacility();
      }
    });
  }

  // ===================
  // View Actions
  // ===================
  editThisAsset() {
    this.editPreview.emit(this.preId);
  }

  archiveThisAsset() {
    this.archivePreview.emit(this.Asset.id);
  }

  removeThisAsset() {
    this.removePreview.emit(this.Asset.id);
    setTimeout(() => {
      (<any>$('#deleteWorkOrderModal')).modal('hide');
    }, 200);
  }

  advance_option = 1;
  viewDates = 0;
  today = new Date();
  contractRange = 0;
  data = {
    labels: ['0', '12', '24', '36', '48', '60', '72', '84', '96', '108', '120'],
    datasets: [
      {
        label: 'Months',
        data: [12000, 10800, 9600, 8400, 7200, 6000, 4800, 3600, 2400, 1200, 0],
        fill: false,
        pointBackgroundColor: '#31c1bb',
        pointBorderColor: '#ffffff',
        pointBorderWidth: 3,
        pointRadius: 6,
        borderColor: '#fe9e6b',
        borderWidth: 3,
      },
    ],
  };

  rightSecPreview = 0;


  ngOnInit() {
    const THIS = this;
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    $('body').on('click', '.reRenderFacility', function () {
      THIS.getAllFacility();
    });
    //===============
    // Pusher
    //===============
    Pusher.logToConsole = true;
    const pusher = new Pusher('501b3b237bd799758df0', {
      cluster: 'ap4'
    });
    const channel = pusher.subscribe('bm-assets');
    channel.bind('RefreshAsset', function (data) {
      if (Session.team === data.message.team) {
        THIS.getSingleAsset(THIS.preId);
      }
    });
    channel.bind('RefreshAssetCategory', function (data) {
      if (Session.team === data.message.team) {
        THIS.getAllcategory();
      }
    });
    const channelWo = pusher.subscribe('bm-work-orders');
    channelWo.bind('RefreshFacility', function (data) {
      if (Session.team === data.message.team) {
        THIS.getAllFacility();
      }
    });
    //===============
    // Pusher
    //===============
  }

  ngOnDestroy() {
  }


  // =============================
  // Photo Editor
  // =============================
  CANVAS = null;
  SHAPE = 'brush';
  IMAGE_PATH = '';
  CanvasTarget = 0;

  discardEditor() {
    const THIS = this;
    const modal = (<any>$('#photoEditorModal'));
    if (THIS.CANVAS != null) {
      THIS.CANVAS.dispose();
    }
    modal.modal('hide');

  }

  editImgCallUnique = 0;
  EditCurrentCanvas = null;
  CurrentEditPhoto = null;

  openPhotoEditor(preview) {
    const THIS = this;
    THIS.CurrentEditPhoto = preview;
    THIS.CanvasTarget = 0;
    const modal = (<any>$('#photoEditorModal'));
    const canvasW = window.innerWidth - 20;
    const canvasH = window.innerHeight - 130;
    $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
    if (THIS.CANVAS != null) {
      this.CANVAS.clear();
    }
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    const param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

    if (THIS.editImgCallUnique == 0) {
      THIS.editImgCallUnique = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          THIS.editImgCallUnique = 0;
          if (res.status === 2000) {
            THIS.EditCurrentCanvas = res.data;
            const canvasW = res.data.width;
            const canvasH = res.data.height;
            $('#canvas').attr('width', canvasW + 'px');
            $('#canvas').attr('height', canvasH + 'px');
            setTimeout(function () {
              // if(THIS.CANVAS != null){
              //     THIS.CANVAS.dispose();
              // }

              THIS.CANVAS = new fabric.Canvas('canvas');
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.on('object:selected', function (e) {
                THIS.CANVAS.isDrawingMode = false;
                THIS.SHAPE = '';
              });
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.isDrawingMode = true;
              THIS.CANVAS.freeDrawingBrush.width = 5;

              let startY = 0,
                startX = 0;
              THIS.CANVAS.on('mouse:down', function (option) {
                startY = option.e.offsetY;
                startX = option.e.offsetX;
              });
              THIS.CANVAS.on('mouse:up', function (option) {
                const selected = THIS.CANVAS.getActiveObjects();
                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                  if (THIS.SHAPE === 'circle') {
                    const e = option.e;
                    if (e.offsetX - startX > 5) {
                      const circle = new fabric.Circle({
                        top: startY,
                        left: startX,
                        radius: (e.offsetX - startX) / 2,
                        stroke: THIS.CANVAS.freeDrawingBrush.color,
                        strokeWidth: 5,
                        fill: ''
                      });
                      THIS.CANVAS.add(circle);
                    }
                  }
                }
              });
              THIS.CustomHelper.tooltipRender();

              THIS.IMAGE_PATH = preview.file_path;
              const src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
              $('.canvas-container').css({
                backgroundImage: 'url(\'' + src + '\')'
              });
              THIS.CANVAS.renderAll();
              setTimeout(function () {
                THIS.CanvasTarget = 1;
              }, 1000);
            }, 1000);
          }
        }
      });


      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        modal.modal('hide');
        history.go(1);
      };

      if ($('.color-picker-editor').length > 0) {
        Pickr.create({
          el: '.color-picker-editor',
          theme: 'nano', // or 'monolith', or 'nano'
          defaultRepresentation: 'HEX',
          useAsButton: true,
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: true,
              input: true,
              save: true
            }
          }
        }).on('change', (color, instance) => {
          $('.color-picker-editor').css('color', color.toHEXA().toString());
          THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
          THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
          });
        });
      }
      $('.color-picker-editor').css('color', '#ff3773');
    }
  }

  // =============================
  // Photo Editor Actions
  // =============================
  SketchEnable() {
    this.CANVAS.isDrawingMode = !this.CANVAS.isDrawingMode;
    if (this.CANVAS.isDrawingMode === true) {
      this.SHAPE = 'brush';
    }
  }

  eraser() {
    const THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    const obj = THIS.CANVAS.getObjects();
    for (let i = 0; i < obj.length; i++) {
      $.each(obj, function (j, v) {
        if (parseInt(j) > 0) {
          THIS.CANVAS.remove(v);
        }
      });
    }
    THIS.UID_IMG = Date.now();
    const src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
    $('.canvas-container').css({
      backgroundImage: 'url(\'' + src + '\')'
    });
  }

  eraserSelected() {
    const THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
      THIS.CANVAS.remove(obj);
    });
  }

  drawCircle() {
    const THIS = this;
    this.CANVAS.isDrawingMode = false;
    this.SHAPE = 'circle';
    const circle = new fabric.Circle({
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      radius: 50,
      stroke: THIS.CANVAS.freeDrawingBrush.color,
      strokeWidth: 5,
      fill: ''
    });
    THIS.CANVAS.add(circle);
  }

  UpdatePhoto(iid) {
    const THIS = this;
    const ldd = document.getElementById(iid);
    const ladda = Ladda.create(ldd);
    ladda.start();
    THIS.CANVAS.discardActiveObject();
    THIS.CANVAS.requestRenderAll();
    setTimeout(function () {
      const modal = (<any>$('#photoEditorModal'));
      const canvas = (<any>document.getElementById('canvas'));
      let imgData = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      imgData = imgData.replace('data:image/png;base64,', '');
      const param = {
        imgData: imgData,
        media_type: 1,
        file_path: THIS.IMAGE_PATH
      };
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          ladda.stop();
          modal.modal('hide');
          THIS.UID_IMG = Date.now();
        }
      });
    }, 1000);
  }


  UID_IMG = 0;
  Gallery = null;
  Preview;

  open(index, preview) {
    const THIS = this;
    THIS.Preview = preview;
    $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    const _albums = [];
    if (THIS.Asset.media !== undefined) {
      for (let i = 0; i < THIS.Asset.media.length; i++) {
        const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + THIS.Asset.media[i].file_path + '&ref=' + Date.now();
        const caption = '';
        const thumb = '';
        const album = {
          src: src,
          caption: caption,
          thumb: thumb,
          w: THIS.Asset.media[i].width !== undefined ? THIS.Asset.media[i].width : 1500,
          h: THIS.Asset.media[i].height !== undefined ? THIS.Asset.media[i].height : 1500
        };
        _albums.push(album);
      }
    }
    const pswpElement = document.querySelectorAll('.pswp')[0];
    const options = {
      index: index,
      shareEl: false,
      editEl: true,
      tapToClose: false,
      zoomEl: true,
      history: false
    };
    THIS.Gallery = null;
    THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
    THIS.Gallery.init();
    THIS.Gallery.listen('afterChange', function (r) {
      THIS.Preview = THIS.Asset.media[THIS.Gallery.getCurrentIndex()];
      $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    });
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      THIS.Gallery.close();
      history.go(1);
    };

  }

  checkCurrency() {
    Inputmask.extendAliases({
      pesos: {
        prefix: '$',
        groupSeparator: '',
        alias: 'numeric',
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        radixPoint: '.',
        placeholder: '0.00'
      }
    });

    setTimeout(function () {
      (<any>$('._currency_validate_eq')).inputmask({alias: 'pesos'});
    }, 300);
  }

  currencyIcon(): string {
    if (this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }

  toggleImage() {
    $('#PhotoForm').slideToggle(100);
  }

}
