import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {MatTooltipModule} from '@angular/material';
import {WorkOrderService} from '../../_services/work-orders.service';
import {FacilityService} from '../../_services/facility.service';
import {AuthService} from '../../_services/auth.service';
import {FcmService} from '../../_services/fcm.service';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {Router} from '@angular/router';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let Pusher: any;

@Component({
  selector: 'page-work-orders-recurring',
  templateUrl: './work-orders-recurring.component.html'
})
export class WorkOrdersRecurringComponent {
  Permission = -1;

  // =======================
  // General Variables
  // =======================
  currentUser;
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};


  // =======================
  // Pagination & Data
  // =======================
  pageNo = 1;
  perPage = this.CustomHelper.getPerpage();
  firstWo = '0';
  lastWo = '0';
  pageEvent;

  restoreAlert: boolean = false;

  paginateList() {
    if (this.pageEvent.pageSize == this.perPage) {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = this.pageEvent.pageIndex + 1;
      localStorage.perpage = this.perPage;
      this.getWorkOrder();
    } else {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = 1;
      localStorage.perpage = this.perPage;
      this.getWorkOrder();
    }
  }

  workOrderPagination = 0;
  totalWorkOrders = 0;
  workSections = {
    miniScreen: 0,
    overview: 0,
    singlePreview: 0,
    singlePreviewEdit: 0,
    createForm: 0,
    createRecurringForm: 0,
    createEmail: 0
  };
  workOrderList = [];
  workOrder = [];
  selectedInvoice = [];
  previewList = 1;
  facilityEmpty = 0;



  AllEnableFacility() {
    let ldd = document.getElementById('allEnableFac');
    let ladda = Ladda.create(ldd);
    ladda.start();
    this.facilityService.enableAll(0).subscribe(res => {
      ladda.stop();
      this.facilityService.refreshFacility();
    });
  }

  order_by = 'desc';

  getWorkOrder() {
    let THIS = this;
    this.previewList = 0;
    this.workOrderList.length = 0;
    this.workOrder.length = 0;
    THIS.facilityEmpty = 0;
    let param = {
      id: 0,
      module: 1,
      keyword: this.searchKeyword,
      pageNo: this.pageNo,
      perPage: this.perPage,
      sort_by: this.sort_by,
      order_by: this.order_by,
      is_active: this.wo_is_active,
      status: this.wo_status,
    };
    localStorage.perPageWOR = this.perPage;
    localStorage.sort_byWOR = this.sort_by;
    localStorage.order_byWOR = this.order_by;
    this.workOrderService.getRecurringWorkOrders(param).subscribe(res => {
      this.previewList = 1;
      if (res.status == 2000) {
        let Data = [];
        $.each(res.data, function (i, v) {
          if (v.dates.expire != undefined && v.dates.expire != null) {
            if (new Date() > new Date(v.dates.expire)) {
              v['isRecurExpire'] = 1;
            } else if (new Date(v.nextCreation) > new Date(v.dates.expire)) {
              v['isRecurExpire'] = 1;
            } else {
              v['isRecurExpire'] = 0;
            }
          } else {
            v['isRecurExpire'] = 0;
          }

          v['dates']['start'] = THIS.CustomHelper.DateTimeSelectedFormat(v['dates']['start']);
          v['dates']['expire'] = v['dates']['expire'] != null ? THIS.CustomHelper.DateTimeSelectedFormat(v['dates']['expire']) : null;
          Data.push(v);
        });
        this.workOrderList = Data;
        this.workOrder = this.workOrderList;
        this.totalWorkOrders = res.total;
      } else if (res.status == 400) {
        THIS.facilityEmpty = 1;
        this.workOrderList = [];
        this.workOrder = this.workOrderList;
        this.totalWorkOrders = 0;
      } else {
        this.workOrderList = [];
        this.workOrder = this.workOrderList;
        this.totalWorkOrders = 0;
      }
    });

    this.getWorkOrderStatus(param);
  }


  // =======================
  // Search & Filter
  // =======================\
  wo_is_active = 1;
  wo_status = 1;
  searchKeyword = '';

  searchKeywordClear(e) {
    this.searchKeyword = '';
    this.workOrder = this.workOrderList;
    this.getWorkOrder();
  }

  filter(): void {
    let THIS = this;
    clearInterval(THIS.CustomHelper.woRecListSearch);
    THIS.CustomHelper.woRecListSearch = setInterval(function () {
      clearInterval(THIS.CustomHelper.woRecListSearch);
      THIS.getWorkOrder();
    }, 1000);

  }

  highlighter(subject) {
    if (subject == '' || subject == null) {
      return subject;
    } else {
      subject = this.CustomHelper.makeStr(subject);
    }
    let matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
    let res = '';
    $.each(matches, function (i, v) {
      res = subject.replace(v, '<span class=\'text-warning-highlight\'>' + v + '</span>');
    });
    if (res == '') {
      res = subject;
    }
    return res;
  }

  FilterOn = 0;
  FilterScroll = 235;

  ToggleFilter(c) {
    if (this.wo_is_active != 0) {
      this.FilterScroll = 235;
      if (this.workSections.miniScreen == 1) {
        this.FilterScroll = 290;
      }
    } else {
      this.FilterScroll = 275;
      if (this.workSections.miniScreen == 1) {
        this.FilterScroll = 290;
      }
    }
  }

  selectAllCheck() {
    let THIS = this;
    let WO = this.workOrder;
    if (this.selectedInvoice.length == this.workOrder.length) {
      this.selectedInvoice.length = 0;
    } else {
      this.selectedInvoice.length = 0;
      $.each(WO, function (i, v) {
        THIS.selectedInvoice.push(v.id);
      });
    }
  }

  SortTitle = 'Created Date';
  sort_by = 'created_at';
  SortOptions = [
    {title: 'Created Date', slug: 'created_at'},
    {title: 'Status', slug: 'status'},
    {title: 'Repeat', slug: 'repeat'},
    {title: 'Facility', slug: 'facility'},
    {title: 'Subject', slug: 'subject'},
    {title: 'Start Date', slug: 'start'},
    {title: 'Expire Date', slug: 'expire'},
  ];
  Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
  changeDensity(density) {
    let THIS = this;
    this.Density = density;
    localStorage.DensityWOR = this.Density;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    let param = {woRecur: ''};
    density == 1 ? param.woRecur = 'd' : '';
    density == 2 ? param.woRecur = 'cf' : '';
    density == 3 ? param.woRecur = 'cp' : '';
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'me/density?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
      }
    });
  }

  statusCheck = [];
  FilterName = 'Active';

  updateStatusCheck(status) {
    this.selectedInvoice = [];
    this.wo_is_active = status;
    if (this.wo_is_active == 1) {
      this.FilterName = 'All';
      this.wo_status = null;
    } else if (this.wo_is_active == 2) {
      this.FilterName = 'Archived';
      this.wo_status = null;
    } else if (this.wo_is_active == 0) {
      this.FilterName = 'Trashed';
      this.wo_status = null;
    } else if (this.wo_is_active == 101) {
      this.FilterName = 'Active';
      this.wo_is_active = 1;
      this.wo_status = 1;
    }
    this.ToggleFilter('1');
    this.getWorkOrder();
  }

  CounterStatus = {
    'total': 0,
    'active': 0,
    'archived': 0,
    'trash': 0,
  };

  getWorkOrderStatus(param) {
    let THIS = this;
    this.workOrderService.getRecurringWorkOrdesStatus(param).subscribe(res => {
      if (res.status == 2000) {
        this.CounterStatus = {
          'total': res.total,
          'active': res.active,
          'archived': res.archived,
          'trash': res.trash,
        };
      }
    });
  }

  selectedFilter = [];
  workFilterOption = [
    // {id: 0, title: 'All'},
    // {id: 1, title: 'Save'},
    {id: 2, title: 'New'},
    {id: 3, title: 'In Progress'},
    {id: 4, title: 'Awaiting Approval'},
    {id: 5, title: 'Completed'},
    {id: 6, title: 'Cancelled'},
    // {id: 7, title: 'Removed'},
    // {id: 8, title: 'Archived'}
  ];
  FilterAllSelect = true;

  showAllFilter() {
    this.FilterAllSelect = true;
    this.selectedFilter.length = 0;
  }

  IsFilterChecked(filter) {
    var index = this.selectedFilter.findIndex(f => f === filter);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  changeFilter(filter: number): void {
    var index = this.selectedFilter.findIndex(f => f === filter);
    if (index == -1) {
      this.selectedFilter.push(filter);
    } else {
      this.selectedFilter.splice(index, 1);
    }
    if (this.selectedFilter.length > 0) {
      this.FilterAllSelect = false;
    } else {
      this.FilterAllSelect = true;
    }
  }

  sortedFilter(status) {
    var index = this.selectedFilter.findIndex(f => f === status);
    if (this.selectedFilter.length == 0 || index > -1) {
      return true;
    } else {
      return false;
    }
  }

  customFilter = [];

  checkCustomFilter(type) {
    if (type > 4) {
      var index = this.customFilter.findIndex(f => f === type);
      if (index > -1) {
        this.customFilter.splice(index, 1);
      } else {
        this.customFilter.push(type);
      }
    } else {
      var index = this.customFilter.findIndex(f => f === 1);
      index > -1 ? this.customFilter.splice(index, 1) : '';
      var index = this.customFilter.findIndex(f => f === 2);
      index > -1 ? this.customFilter.splice(index, 1) : '';
      var index = this.customFilter.findIndex(f => f === 3);
      index > -1 ? this.customFilter.splice(index, 1) : '';
      var index = this.customFilter.findIndex(f => f === 4);
      index > -1 ? this.customFilter.splice(index, 1) : '';
      this.customFilter.push(type);
    }
    this.sortItOut();
  }

  checkCustomFilterSelection(type) {
    var index = this.customFilter.findIndex(f => f === type);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  sortItOut() {
    var p = this.customFilter.findIndex(f => f === 5);
    var q = this.customFilter.findIndex(f => f === 6);
    if (p == -1 && q == -1) {
      this.workOrder = this.workOrderList;
    }

    if (this.customFilter.length > 0) {
      for (let i = 0; i < this.customFilter.length; i++) {
        let type = this.customFilter[i];
        if (type == 1) {
          let works = this.workOrder;
          works.sort(function (a, b) {
            return a.work_order_id - b.work_order_id;
          });
          this.workOrder = works;
        }
        if (type == 2) {
          let works = this.workOrder;
          works.sort(function (a, b) {
            let x = new Date(a.dates.created_at).getTime();
            let y = new Date(b.dates.created_at).getTime();
            return y - x;
          });
          this.workOrder = works;
        }
        if (type == 3) {
          let works = this.workOrder;
          works.sort(function (a, b) {
            let x = new Date(a.dates.due_at).getTime();
            let y = new Date(b.dates.due_at).getTime();
            return y - x;
          });
          this.workOrder = works;
        }
        if (type == 4) {
          let works = this.workOrder;
          works.sort(function (a, b) {
            let x = '';
            $.each(a.facility, function (i, v) {
              x += v.id + '';
            });
            let y = '';
            $.each(b.facility, function (i, v) {
              x += v.name + '';
            });

            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });
          this.workOrder = works;
        }
        if (type == 5) {
          let THIS = this;
          let works = this.workOrder;
          let newWork = [];
          $.each(works, function (i, v) {
            if (v.contact_id == THIS.currentUser) {
              newWork.push(v);
            }
          });
          this.workOrder = newWork;
        }
        if (type == 6) {
          let THIS = this;
          let works = this.workOrder;
          let newWork = [];
          $.each(works, function (i, v) {
            if (new Date(v.dates.due_at) > new Date()) {
              newWork.push(v);
            }
          });
          this.workOrder = newWork;
        }
      }
    } else {
      this.workOrder = this.workOrderList;
    }
  }

  isRecurExpire(work) {
    if (work.dates.expire != undefined && work.dates.expire != null) {
      if (new Date() > new Date(work.dates.expire)) {
        return true;
      } else if (new Date(work.nextCreation) > new Date(work.dates.expire)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // =======================
  // Actions
  // =======================
  selectedSingleWork = {};

  updateThisWorkStatus(status, id) {
    let THIS = this;
    this.workOrderService.updateStatusWorkOrder(id, status).subscribe(res => {
      var index = this.workOrderList.findIndex(work => work.id === id);
      var indexP = this.workOrder.findIndex(work => work.id === id);

      this.workOrderList[index] = res.data;
      this.workOrder[indexP] = res.data;
      this.previewWork(0);
      this.previewWork(this.workOrder[indexP]);
    });
  }

  statusUpdateEmit(data) {
    this.updateThisWorkStatus(data.status, data.id);
  }

  removeWork = {
    submitted: 0
  };
  selectedForAction = [];

  undoRemoveThisWork(ids) {
    let THIS = this;
    const ldd = document.getElementById('restoreBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    this.workOrderService.removeUndoWorkOrderRecurring('remove', ids).subscribe(res => {
      ladda.stop();
      THIS.getWorkOrder();
      THIS.selectedInvoice.length = 0;
      (<any>$('#RestoreModal')).modal('hide');
      toastr.success('This recurring job has been restored successfully', 'Recurring Job Restore', this.toastrOption);
    });
  }
  restoreThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#RestoreModal')).modal('show');
  }
  emptyTrash() {
    let THIS = this;
    const ldd = document.getElementById('emptyTrashBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'get/work-orders-recurring/empty/trash?token=' + THIS.currentUser.token,
      data: {},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.selectedForAction.length = 0;
        (<any>$('#EmptyTrashModal')).modal('hide');
        THIS.getWorkOrder();
        toastr.success('This recurring job has been trashed permanently', 'Recurring Job Empty Trash', this.toastrOption);
      }
    });
  }
  emptyThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#EmptyTrashModal')).modal('show');
  }
  removeThisWork( id = undefined) {
    let THIS = this;
    let ids = (id !== undefined) ? [id] : THIS.selectedForAction;
    THIS.selectedForAction = ids;
    const ldd = document.getElementById('removeBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    THIS.workOrderService.removeWorkOrderRecurring('remove', THIS.selectedForAction).subscribe(res => {
      // THIS.undoRemoveThisWork(ids);
      ladda.stop();
      THIS.getWorkOrder();
      THIS.selectedInvoice.length = 0;
      (<any>$('#deleteWorkOrderModal')).modal('hide');
      toastr.success('This recurring job has been trashed successfully', 'Recurring Job Trash', this.toastrOption);
    });
  }

  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }

  undoArchiveThisWork(ids) {
    let THIS = this;
    const ldd = document.getElementById('unarchiveBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    this.workOrderService.removeUndoWorkOrderRecurring('archive', ids).subscribe(res => {
      ladda.stop();
      THIS.getWorkOrder();
      THIS.selectedInvoice.length = 0;
      (<any>$('#UnarchiveModal')).modal('hide');
      toastr.success('This recurring job has been unarchived successfully', 'Recurring Job Unarchive', this.toastrOption);
    });
  }
  unarchiveThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#UnarchiveModal')).modal('show');
  }

  archiveThisWork(id = undefined) {
    let THIS = this;
    let ids = (id !== undefined) ? [id] : this.selectedForAction;
    this.removeWork.submitted = 1;
    const ldd = document.getElementById('archiveBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    this.workOrderService.removeWorkOrderRecurring('archive', ids).subscribe(res => {
      ladda.stop();
      this.removeWork.submitted = 0;
      // let toastrOption = {
      //   timeOut: 60000, progressBar: true, preventDuplicates: true, extendedTimeOut: 60000,
      //   onclick: function () {
      //     THIS.undoArchiveThisWork(ids);
      //   }
      // };
      THIS.selectedInvoice.length = 0;
      this.discardCreateInvoice();
      (<any>$('#ArchiveModal')).modal('hide');
      toastr.success('This recurring job has been archived successfully', 'Recurring Job Archive', this.toastrOption);
    });
  }
  archiveThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#ArchiveModal')).modal('show');
  }

  exportToXls() {
    let THIS = this;
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += 'ID,Subject,Contact,Facility,Asset,Job Type,Status,Created,Due Date,Priority,Creator' + '\r\n';
    $.each(THIS.workOrder, function (i, work) {
      csvContent += work.prefix + '-' + work.work_order_id + ',';
      csvContent += work.subject + ',';

      csvContent += work.contact_id + ',';
      if (work.facility.length > 0) {
        $.each(work.facility, function (j, facility) {
          csvContent += facility.name + ' | ';
        });
        csvContent += ',';
      } else {
        csvContent += '-,';
      }
      csvContent += work.asset != null ? work.asset.name + ',' : '-,';
      csvContent += work.job_type.name + ',';
      if (work.status_code.code != undefined) {
        work.status_code.code == 1 ? csvContent += 'Draft,' : '';
        work.status_code.code == 2 ? csvContent += 'New,' : '';
        work.status_code.code == 3 ? csvContent += 'In Progress,' : '';
        work.status_code.code == 4 ? csvContent += 'Awaiting Approval,' : '';
        work.status_code.code == 5 ? csvContent += 'Complete,' : '';
        work.status_code.code == 6 ? csvContent += 'Cancelled,' : '';
      } else {
        csvContent += '-,';
      }
      csvContent += work.dates.created_at + ',';
      csvContent += work.dates.due_at + ',';
      csvContent += work.priority.code + ',';
      csvContent += work.client.first_name + ' ' + work.client.last_name + ',';
      csvContent += '\r\n';
    });
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'work_orders.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  constructor(
    public snackBar: MatSnackBar,
    public tooltip: MatTooltipModule,
    private facilityService: FacilityService,
    private authService: AuthService,
    private fcmService: FcmService,
    private workOrderService: WorkOrderService, private router: Router) {

    let THIS = this;

    setTimeout(function () {

      if (THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] != undefined) {
        window.history.pushState('', '', THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute]);
      }

      THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
      THIS.Permission = THIS.currentUser.user_type > 2 ? THIS.currentUser.modules.work_orders : 1;

      if (THIS.currentUser.subscription.plan == 'Free' && THIS.currentUser.subscription.status === 1) {
        THIS.router.navigate(['/' + THIS.currentUser.team + '/dashboard']);
      }

      // Pagination
      THIS.currentUser.pagination != null ? THIS.perPage = THIS.currentUser.pagination.woRecur : '';
      // 1 = Default, 2 = Comfortable, 3 = Compact
      THIS.currentUser.list_view.woRecur.d == 1 ? THIS.Density = 1 : '';
      THIS.currentUser.list_view.woRecur.cf == 1 ? THIS.Density = 2 : '';
      THIS.currentUser.list_view.woRecur.cp == 1 ? THIS.Density = 3 : '';

      THIS.facilityService.currentSelectedFac.subscribe(value => THIS.getWorkOrder());
      THIS.facilityService.currentSelectedFac.subscribe(value => THIS.refreshView());
      THIS.facilityService.currentSelectedFac.subscribe(value => THIS.workSections.overview = 1);
      THIS.authService.current_glKeyword.subscribe(value => THIS.searchKeyword = value);
      // THIS.authService.current_glKeyword.subscribe(value => THIS.filter());
      THIS.routeMake();
      if (window.innerWidth <= 768) {
        THIS.workSections.miniScreen = 1;
        THIS.FilterScroll = 290;
      }

    }, 500);


  }

  dropMenuRightToggle(e) {
    var container = $('.invoice-list');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $('.drop-menu-right').removeClass('open');
    }
  }


  // =======================
  // Routes & Preview
  // =======================
  ROUTES = '/job-recurring';

  routeMake() {
    let route = this.CustomHelper.getRouteStr();
    this.refreshView();
    if (route[2] != undefined) {
      if (route[2] == '') {
        this.workSections.overview = 1;
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
      } else if (route[2] == 'new') {
        this.createNewWork();
      } else if (route[2] == 'edit') {
        if (route[3] != undefined) {
          let preId = route[3];
          this.quickEditInvoice(preId);
        }
      } else {
        let preId = route[2];
        this.previewWorkId(preId);
      }
    } else {
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      let url = '/' + Session.team + this.ROUTES;
      window.history.pushState('', '', url);
      this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
      this.workSections.overview = 1;
    }
  }

  refreshView() {
    this.workSections.overview = 0;
    this.workSections.singlePreview = 0;
    this.workSections.singlePreviewEdit = 0;
    this.workSections.createForm = 0;
    this.workSections.createEmail = 0;
  }

  createNewWork() {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (THIS.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          THIS.refreshView();
          THIS.workSections.createForm = 1;
          let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
          let url = '/' + Session.team + THIS.ROUTES + '/new';
          window.history.pushState('', '', url);
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
          THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
        }
      });
    } else {
      THIS.refreshView();
      THIS.workSections.createForm = 1;
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      let url = '/' + Session.team + THIS.ROUTES + '/new';
      window.history.pushState('', '', url);
      THIS.CustomHelper.woRouteDiscard = 0;
      THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
    }

  }

  echoRepeatDays(repeatDaysStr) {
    let repeatDays = repeatDaysStr.split(',');
    let days = '';
    $.each(repeatDays, function (i, v) {
      if (i == '0') {
        days += v;
      } else {
        days += ', ' + v;
      }
    });
    days = days.replace(/,([^,]*)$/, ' and $1');
    return days;
  }

  selectThisRow(e, index, work) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.which == 1) {
        if (this.selectedInvoice.indexOf(work.id) > -1) {
          let idx = this.selectedInvoice.indexOf(work.id);
          if (idx > -1) {
            this.selectedInvoice.splice(idx, 1);
          }
        } else {
          this.selectedInvoice.push(work.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    }

  }

  checkThisRow(e, index, work) {
    e.preventDefault();
    let selIndex = this.selectedInvoice.indexOf(work.id);
    if (selIndex > -1) {
      this.selectedInvoice.splice(selIndex, 1);
    } else {
      this.selectedInvoice.push(work.id);
    }
    $('.drop-menu-right').removeClass('open');

  }

  singlePreviewInvoice(e, index, work) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.which == 1) {
        let idx = this.selectedInvoice.indexOf(work.id);
        if (idx > -1) {
          this.selectedInvoice.splice(idx, 1);
        } else {
          this.selectedInvoice.push(work.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    } else {
      if (e.which == 1) {
        this.selectedSingleWork = work;
        $('.drop-menu-right').removeClass('open');
        this.previewWork(work);
      } else if (e.which == 3) {
        this.selectedInvoice = [work.id];
        this.selectedSingleWork = work;
        let target = $(e.target);
        let w = target.closest('#right-click-holder').width();
        let h = target.closest('#right-click-holder').height();
        let p = target.closest('#right-click-holder').offset();
        let left = e.clientX - p.left;
        let top = e.clientY - p.top;
        let clickH = $('.drop-menu-right').height();
        clickH = clickH < 150 ? 400 : clickH;
        if (this.selectedInvoice.length > 1) {
          clickH = 150;
        }
        if ((w - left) < 230) {
          left = w - 250;
        }
        if (h < top + clickH) {
          top = top - (top + clickH - h);
        }
        if (top < 10) {
          top = 10;
        }

        let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
        if (ttarget.hasClass('open')) {
          $('.drop-menu-right').removeClass('open');
          ttarget.css({
            top: top,
            left: left
          });
          setTimeout(function () {
            ttarget.addClass('open');
          }, 100);
        } else {
          ttarget.css({
            top: top,
            left: left
          });
          ttarget.addClass('open');
        }
      }
    }
  }

  previewCurrentWork = 0;

  previewWorkId(workId) {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (THIS.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          THIS.refreshView();
          THIS.previewCurrentWork = 0;
          setTimeout(function () {
            THIS.editOptionForm = 0;
            THIS.workSections.singlePreview = 1;
            THIS.previewCurrentWork = workId;
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + THIS.ROUTES + '/' + workId;
            window.history.pushState('', '', url);
            THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
          }, 400);
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
        }
      });
    } else {
      THIS.refreshView();
      THIS.previewCurrentWork = 0;
      setTimeout(function () {
        THIS.editOptionForm = 0;
        THIS.workSections.singlePreview = 1;
        THIS.previewCurrentWork = workId;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + THIS.ROUTES + '/' + workId;
        window.history.pushState('', '', url);
        THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
      }, 400);
      THIS.CustomHelper.woRouteDiscard = 0;
    }
  }

  previewWork(work) {
    let THIS = this;
    if(this.wo_is_active != 0 && this.wo_is_active != 2 ){
      this.CustomHelper.woRouteDiscard = 0;
      let index = THIS.CustomHelper.ConfRoute.map(function (e) {
        return e.tab;
      }).indexOf(THIS.CustomHelper.ConfCurRoute);
      if (index > -1) {
        // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
      }
      if (THIS.CustomHelper.woRouteDiscard == 1) {
        swal({
          title: 'Are you sure you want to leave?',
          text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
          icon: 'warning',
          buttons: ['CANCEL', 'LEAVE'],
          dangerMode: true,
        }).then((res) => {
          if (res) {
            THIS.refreshView();
            THIS.previewCurrentWork = 0;
            setTimeout(function () {
              THIS.refreshView();
              THIS.previewCurrentWork = 0;
              setTimeout(function () {
                THIS.editOptionForm = 0;
                THIS.workSections.singlePreview = 1;
                THIS.previewCurrentWork = work.id;
                let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
                let url = '/' + Session.team + THIS.ROUTES + '/' + work.id;
                window.history.pushState('', '', url);
                THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
              });
            }, 400);
            THIS.CustomHelper.woRouteDiscard = 0;
            THIS.CustomHelper.ConfRoute.splice(index, 1);
          }
        });
      } else {
        THIS.refreshView();
        THIS.previewCurrentWork = 0;
        setTimeout(function () {
          THIS.refreshView();
          THIS.previewCurrentWork = 0;
          setTimeout(function () {
            THIS.editOptionForm = 0;
            THIS.workSections.singlePreview = 1;
            THIS.previewCurrentWork = work.id;
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + THIS.ROUTES + '/' + work.id;
            window.history.pushState('', '', url);
            THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
          });
        }, 400);
        THIS.CustomHelper.woRouteDiscard = 0;
      }
    }else{
      if(this.wo_is_active == 0){
        this.restoreAlert = true;
      }else{
        this.restoreAlert = false;
      }
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#PreviewAlert')).modal('show');
    }
  }

  previewWorkQuick(work) {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (THIS.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          THIS.refreshView();
          THIS.previewCurrentWork = 0;
          setTimeout(function () {
            THIS.editOptionForm = 0;
            THIS.previewCurrentWork = work.id;
            THIS.workSections.singlePreview = 1;
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + THIS.ROUTES + '/' + work.id;
            window.history.pushState('', '', url);
            THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
          }, 400);
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
        }
      });
    } else {
      THIS.refreshView();
      THIS.previewCurrentWork = 0;
      setTimeout(function () {
        THIS.editOptionForm = 0;
        THIS.previewCurrentWork = work.id;
        THIS.workSections.singlePreview = 1;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + THIS.ROUTES + '/' + work.id;
        window.history.pushState('', '', url);
        THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
      }, 400);
      THIS.CustomHelper.woRouteDiscard = 0;
    }
  }

  editOptionForm = 0;

  quickEditInvoice(workId) {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (THIS.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          THIS.refreshView();
          THIS.previewCurrentWork = 0;
          setTimeout(function () {
            THIS.editOptionForm = 1;
            THIS.previewCurrentWork = workId;
            THIS.workSections.singlePreview = 1;
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + THIS.ROUTES + '/edit/' + workId;
            window.history.pushState('', '', url);
            THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
          }, 400);
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
        }
      });
    } else {
      THIS.refreshView();
      THIS.previewCurrentWork = 0;
      setTimeout(function () {
        THIS.editOptionForm = 1;
        THIS.previewCurrentWork = workId;
        THIS.workSections.singlePreview = 1;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + THIS.ROUTES + '/edit/' + workId;
        window.history.pushState('', '', url);
        THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
      }, 400);
      THIS.CustomHelper.woRouteDiscard = 0;
    }
    $('.drop-menu-right').removeClass('open');
  }

  singleEditInvoice() {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (THIS.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          THIS.refreshView();
          THIS.previewCurrentWork = 0;
          if (THIS.selectedInvoice.length == 1) {
            setTimeout(function () {
              let id = THIS.selectedInvoice[0];
              let index = THIS.workOrder.map(function (e) {
                return e.id;
              }).indexOf(id);
              let work = THIS.workOrder[index];
              THIS.editOptionForm = 1;
              THIS.previewCurrentWork = work.id;
              THIS.workSections.singlePreview = 1;
              let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
              let url = '/' + Session.team + THIS.ROUTES + '/edit/' + work.id;
              window.history.pushState('', '', url);
              THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
            }, 400);
          } else {
            THIS.workSections.overview = 0;
            THIS.workSections.singlePreview = 0;
            THIS.workSections.singlePreviewEdit = 0;
            THIS.workSections.createForm = 0;
            THIS.workSections.createEmail = 0;
            let url = '/' + this.currentUser.team + this.ROUTES;
            window.history.pushState('', '', url);
            THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
          }
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
        }
      });
    } else {
      THIS.refreshView();
      THIS.previewCurrentWork = 0;
      if (THIS.selectedInvoice.length == 1) {
        setTimeout(function () {
          let id = THIS.selectedInvoice[0];
          let index = THIS.workOrder.map(function (e) {
            return e.id;
          }).indexOf(id);
          let work = THIS.workOrder[index];
          THIS.editOptionForm = 1;
          THIS.previewCurrentWork = work.id;
          THIS.workSections.singlePreview = 1;
          let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
          let url = '/' + Session.team + THIS.ROUTES + '/edit/' + work.id;
          window.history.pushState('', '', url);
          THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
        }, 400);
      } else {
        THIS.workSections.overview = 0;
        THIS.workSections.singlePreview = 0;
        THIS.workSections.singlePreviewEdit = 0;
        THIS.workSections.createForm = 0;
        THIS.workSections.createEmail = 0;
        let url = '/' + this.currentUser.team + this.ROUTES;
        window.history.pushState('', '', url);
        THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
      }
      THIS.CustomHelper.woRouteDiscard = 0;
    }
    $('.drop-menu-right').removeClass('open');
  }

  discardCreateInvoice() {
    let THIS = this;
    this.getWorkOrder();
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (THIS.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          THIS.refreshView();
          THIS.workSections.overview = 1;
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
          let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
          let url = '/' + Session.team + THIS.ROUTES;
          window.history.pushState('', '', url);
          THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
          this.ToggleFilter('1');
        }
      });
    } else {
      THIS.refreshView();
      THIS.workSections.overview = 1;
      THIS.CustomHelper.woRouteDiscard = 0;
      THIS.CustomHelper.ConfRoute.splice(index, 1);
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      let url = '/' + Session.team + this.ROUTES;
      window.history.pushState('', '', url);
      THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
      this.ToggleFilter('1');
    }
  }

  ClosePreviewAlert(){
    (<any>$('#PreviewAlert')).modal('hide');
  }

  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    //===============
    // Pusher
    //===============
    Pusher.logToConsole = true;
    let pusher = new Pusher('501b3b237bd799758df0', {
      cluster: 'ap4'
    });
    let channel = pusher.subscribe('bm-work-order-recurring');
    channel.bind('RefreshWorkOrderRecurring', function (data) {
      if (Session.team === data.message.team) {
        THIS.getWorkOrder();
      }
    });
    //===============
    // Pusher
    //===============
  }

  ngOnDestroy() {
  }

}
