import {Component, OnInit, ViewChild} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {MediaService} from '../../_services/media.service';
import {FacilityService} from '../../_services/facility.service';
import * as XLSX from 'xlsx';

declare let swal: any;
declare let Ladda: any;
declare let toastr: any;

@Component({
    selector: 'page-contact',
    templateUrl: './contact.component.html'
})

export class ContactComponent implements OnInit {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Permission = 1;
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};


    wo_is_active = 1;
    CounterStatus = {
        'total': 0,
        'archived': 0,
        'trash': 0,
    };
    FilterName = 'All';

    updateStatusCheck(status) {
        this.selectedContact = [];
        this.wo_is_active = status;
        if (this.wo_is_active == 1) {
            this.FilterName = 'All';
        } else if (this.wo_is_active == 2) {
            this.FilterName = 'Archived';
        } else if (this.wo_is_active == 0) {
            this.FilterName = 'Trashed';
        }
        this.getAllContact();
    }

    Density = 1; // 1 = Default, 3 = Compact
    changeDensity(density) {
        const THIS = this;
        this.Density = density;
        localStorage.DensityContact = this.Density;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
        const param = {contact: ''};
        density == 1 ? param.contact = 'd' : '';
        density == 2 ? param.contact = 'cf' : '';
        density == 3 ? param.contact = 'cp' : '';
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'me/density?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
            }
        });
    }

    toggleEachTableOption(e: any) {
        const trigger = $(e.target).closest('.table-td-options').find('.table-options');
        if (trigger.hasClass('active')) {
            trigger.removeClass('active');
        } else {
            trigger.addClass('active');
        }
    }

    FilterOn = 0;
    FilterScroll = 235;

    ToggleFilter(c) {
    }


    searchKeyword = '';

    searchKeywordClear() {
        this.searchKeyword = '';
        this.getAllContact();
    }

    selectedContact = [];
    sort_by = 'name';
    order_by = 'asc';
    previewList = 0;
    Contact = [];
    totalContact = 0;
    pageNo = 1;
    perPage = this.CustomHelper.getPerpage();
    pageEvent;

    paginateList() {
        if (this.pageEvent.pageSize == this.perPage) {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex + 1;
            this.getAllContact();
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
            this.getAllContact();
        }
    }

    filter() {
        const THIS = this;
        clearInterval(THIS.CustomHelper.woListSearch);
        THIS.CustomHelper.woListSearch = setInterval(function () {
            clearInterval(THIS.CustomHelper.woListSearch);
            THIS.pageNo = 1;
            THIS.getAllContact();
        }, 400);
    }

    highlighter(subject) {
        if (subject == '' || subject == null) {
            return subject;
        } else {
            subject = this.CustomHelper.makeStr(subject);
        }
        const searchKeyword = this.searchKeyword.replace(/\)/g, '\\)');
        const matches = subject.match(new RegExp(searchKeyword, 'gi'));
        let res = '';
        $.each(matches, function (i, v) {
            res = subject.replace(v, '<span class=\'text-warning-highlight\'>' + v + '</span>');
        });
        if (res == '') {
            res = subject;
        }
        return res;
    }

    selectAllCheck() {
        const THIS = this;
        const CO = this.Contact;
        if (this.selectedContact.length == this.Contact.length) {
            this.selectedContact.length = 0;
        } else {
            this.selectedContact.length = 0;
            $.each(CO, function (i, v) {
                THIS.selectedContact.push(v.id);
            });
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }

    checkThisRow(e, index, contact) {
        const THIS = this;
        e.preventDefault();
        const iIndex = this.selectedContact.indexOf(contact.id);
        if (iIndex > -1) {
            if (this.selectedContact.length == 1) {
                this.selectedContact.length = 0;
            } else {
                this.selectedContact.splice(iIndex, 1);
            }
        } else {
            this.selectedContact.push(contact.id);
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);

    }

    profileEdit = 0;
    DataSingle = null;

    singlePreview(e, index, contact) {
        const THIS = this;
        if (THIS.wo_is_active == 0) {
            return;
        }
        e.preventDefault();
        if (e.ctrlKey) {
            if (e.which == 1) {
                if (this.selectedContact.indexOf(contact.id) > -1) {
                    this.selectedContact.splice(this.selectedContact.indexOf(contact.id), 1);
                } else {
                    this.selectedContact.push(contact.id);
                }
                $('.drop-menu-right').removeClass('open');
            }
        } else {
            this.selectedContact = [contact.id];
            this.DataSingle = contact;
            let ___selectedTab = 0;
            const tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                    ___selectedTab = i;
                }
            });
            const child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#SingleUserModal')).modal('show');
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }

    _tab = 1;

    updateGeneralInfo() {
        const THIS = this;
        const param = {
            'id': this.param.id,
            'facility_id': this.param.facility_id,
            'name': this.param.name,
            'company': this.param.company,
            'job_title': this.param.job_title,
            'email': this.param.email,
            'address': this.param.address,
            'phone': this.param.phone,
            'label': this.param.label,
            'mobile': '',
            'city': '',
            'state': '',
            'zip_code': '',
            'avatar': this.param.avatar,
        };
        const ldd = document.getElementById('editBtn');
        const ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'contact/editContact?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                ladda.stop();
                THIS.profileEdit = 0;
                THIS.getAllContact();
                toastr.success('Contact has been updated successfully', 'Contact Update', THIS.toastrOption);
                (<any>$('#SingleUserModal')).modal('hide');
                (<any>$('#EditUserModal')).modal('hide');
                (<any>$('#EditContactModal')).modal('hide');
            }
        });
    }

    emptyTrash() {
        let THIS = this;

        /*Need Api for below code*/
            $.ajax({
              url: THIS.APPCONFIG.api + 'get/work-orders/empty/trash?token=' + THIS.currentUser.token,
              data: {},
              type: 'post',
              success: function (res) {
                THIS.getAllContact();
                THIS.selectedContact.length = 0;
                (<any>$('#EmptyTrashModal')).modal('hide');
              }
            });
    }

    emptyThisWork() {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#EmptyTrashModal')).modal('show');
    }

    removeThisContact(is_active) {
        const THIS = this;
        const ids = this.selectedContact;
        const ldd = document.getElementById(is_active == '0' && 'deleteBtn' || is_active == '2' && 'archiveBtn');
        const ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'contact/remove?token=' + THIS.currentUser.token,
            type: 'post',
            data: {ids: ids, is_active: is_active},
            success: function (res) {
                ladda.stop();
                this.singleUserModal = false;
                THIS.getAllContact();
                THIS.selectedContact.length = 0;
                if (is_active == '0') {
                    toastr.success('Contact has been deleted successfully', 'Contact Delete', THIS.toastrOption);
                } else if (is_active == '2') {
                    toastr.success('Contact has been archived successfully', 'Contact Archive', THIS.toastrOption);
                }
                (<any>$('#SingleUserModal')).modal('hide');
                (<any>$('#ArchiveContactModal')).modal('hide');
                (<any>$('#DeleteContactModal')).modal('hide');
                // THIS.undoThisRemove();
            }
        });
    }

    undoThisRemove(id: any = null , type: any) {
        let Ids = [];
        if (id != null) {
            Ids.push(id);
        } else {
            Ids = this.selectedContact;
        }
        const THIS = this;
        if (type === 1) {
            const ldd = document.getElementById('unarchiveBtn');
            const ladda = Ladda.create(ldd);
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.api + 'contact/remove/undo?token=' + THIS.currentUser.token,
                type: 'post',
                data: {ids: Ids},
                success: function (res) {
                    ladda.stop();
                    this.singleUserModal = false;
                    THIS.selectedContact.length = 0;
                    THIS.getAllContact();
                    toastr.success('Contact has been unarchived successfully', 'Contact Unarchive', THIS.toastrOption);
                    (<any>$('#SingleUserModal')).modal('hide');
                    (<any>$('#UnarchiveModal')).modal('hide');
                }
            });
        } else if (type === 2) {
            const ldd = document.getElementById('restoreBtn');
            const ladda = Ladda.create(ldd);
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.api + 'contact/remove/undo?token=' + THIS.currentUser.token,
                type: 'post',
                data: {ids: Ids},
                success: function (res) {
                    ladda.stop();
                    THIS.selectedContact.length = 0;
                    THIS.getAllContact();
                    toastr.success('Contact has been restored successfully', 'Contact Restore', THIS.toastrOption);
                    (<any>$('#SingleUserModal')).modal('hide');
                    (<any>$('#RestoreModal')).modal('hide');
                }
            });
        }
    }

    unarchiveThisContact(id: any = null, show: any = false) {
        if (id != null) {
            this.selectedContact = [id];
        }
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        if (show) {
            this.singleUserModal = true;
            (<any>$('#SingleUserModal')).modal('hide');
        }
        (<any>$('#UnarchiveModal')).modal('show');
    }

    restoreThisContact(id: any = null) {
        if (id != null) {
            this.selectedContact = [id];
        }
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#RestoreModal')).modal('show');
    }

    selectedlabel = '0';

    getAllContact() {
        const THIS = this;
        THIS.previewList = 0;
        THIS.selectedlabel = $('.__contactList').attr('data-id');
        const param = {
            pageNo: this.pageNo,
            perPage: this.perPage,
            sort_by: this.sort_by,
            order_by: this.order_by,
            keyword: this.searchKeyword,
            is_active: this.wo_is_active,
            label_id: this.selectedlabel,
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'contact/paginate?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                THIS.previewList = 1;
                if (res.status == 2000) {
                    THIS.totalContact = res.total;
                    THIS.Contact = res.data;
                } else {
                    THIS.totalContact = 0;
                    THIS.Contact.length = 0;
                    THIS.selectedContact.length = 0;
                }
            }
        });
        this.getAllCounter();
    }

    getAllCounter() {
        const THIS = this;
        const param = {};
        $.ajax({
            url: THIS.APPCONFIG.api + 'contact/counter?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                if (res.status == 2000) {
                    THIS.CounterStatus = res.data;
                    (<any>$('.__conCount')).html(THIS.CounterStatus.total);
                }
            }
        });
    }

    LabelTags = [];
    taglabContact = null;

    getAllLabelTag() {
        const THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'contact/label/getAll?token=' + THIS.currentUser.token,
            type: 'post',
            data: {},
            success: function (res) {
                THIS.LabelTags = res.data;

                const concat = [];
                $.each(THIS.selectedContact, function (si, sv) {
                    const index = THIS.Contact.map(function (e) {
                        return e.id;
                    }).indexOf(sv);
                    if (index > -1) {
                        const lblData = THIS.Contact[index].labelData;
                        THIS.Contact[index]['labelIds'] = [];
                        $.each(lblData, function (svi, svD) {
                            THIS.Contact[index]['labelIds'].push(svD.id);
                        });
                        concat.push(THIS.Contact[index]['labelIds']);
                    }
                });
                THIS.selectedLabels.length = 0;
                let selctedContact = [];
                if (concat.length > 0) {
                    selctedContact = concat.reduce((p, c) => p.filter(e => c.includes(e)));
                }
                $.each(THIS.LabelTags, function (i, v) {
                    const idx = selctedContact.indexOf(v.id);
                    if (idx > -1) {
                        THIS.LabelTags[i]['checked'] = 1;
                        THIS.selectedLabels.push(THIS.LabelTags[i].id);
                    } else {
                        THIS.LabelTags[i]['checked'] = 0;
                    }
                });
            }
        });
    }

    selectedLabels = [];

    attachLabel(lab) {
        const THIS = this;
        const index = THIS.LabelTags.map(function (e) {
            return e.id;
        }).indexOf(lab.id);
        if (index > -1) {
            if (THIS.LabelTags[index]['checked'] == 1) {
                THIS.LabelTags[index]['checked'] = 0;
                if (THIS.selectedLabels.indexOf(lab.id) > -1) {
                    THIS.selectedLabels.splice(THIS.selectedLabels.indexOf(lab.id), 1);
                }
            } else {
                THIS.LabelTags[index]['checked'] = 1;
                THIS.selectedLabels.push(lab.id);
            }
        }
    }

    updateLabels() {
        const THIS = this;
        const param = {
            'contacts': this.selectedContact,
            'labels': THIS.selectedLabels
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'contact/label/attach?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                THIS.getAllContact();
                THIS.getAllLabelTag();

            }
        });
    }

    FacilityMenu = 0;

    changeFacilityMenu() {
        const THIS = this;
        THIS.FacilityMenu = THIS.FacilityMenu == 1 ? 0 : 1;
        $.ajax({
            url: THIS.APPCONFIG.api + 'contact/update/facility/menu?token=' + THIS.currentUser.token,
            type: 'post',
            data: {},
            success: function (res) {
                THIS.facilityService.refreshFacility();
            }
        });
    }


    createNewContact() {
        $('.show-facility-name-error').html('');
        this.param = {
            facility_id: 0,
            avatar: '',
            name: '',
            company: '',
            job_title: '',
            email: '',
            phone: '',
            address: '',
            label: []
        };
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        this.param.avatar = '';
        (<any>$('#createContactModal')).modal('show');
        this.getAllFacility();
        this.getAllLabel();
    }


    currentUser = null;

    constructor(private mediaService: MediaService, protected facilityService: FacilityService) {
        const THIS = this;
        const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.Permission = Session.user_type > 2 ? Session.modules.contacts : 1;
        this.currentUser = Session;
        THIS.FacilityMenu = THIS.currentUser.fac_contact;
        // Pagination
        this.currentUser.pagination != null ? this.perPage = this.currentUser.pagination.contact : '';
        // 1 = Default, 2 = Comfortable, 3 = Compact
        this.currentUser.list_view.contact.d == 1 ? this.Density = 1 : '';
        this.currentUser.list_view.contact.cf == 1 ? this.Density = 2 : '';
        this.currentUser.list_view.contact.cp == 1 ? this.Density = 3 : '';

        this.getAllContact();
        this.getAllFacility();
        this.getAllLabel();
        $('#__contactComposer').on('click', function () {
            THIS.createNewContact();
        });
        $('body').on('click', '.__contactList', function () {
            THIS.getAllContact();
        });
    }


    Facility = [];

    renderEditFacility() {
        const THIS = this;
        setTimeout(function () {
            (<any>$('.chosen-default-single-edit-fac')).chosen('destroy');
            (<any>$('.chosen-default-single-edit-fac')).val(THIS.DataSingle.facility_id);
            (<any>$('.chosen-default-single-edit-fac')).chosen({
                allow_single_deselect: true,
                placeholder_text_single: 'Select Facility'
            }).on('change', function (e, p) {
                THIS.DataSingle.facility_id = e.target.value;
            });

            (<any>$('.chosen-default-multiple-label-edit')).chosen('destroy');
            (<any>$('.chosen-default-multiple-label-edit')).val(THIS.DataSingle.label);
            (<any>$('.chosen-default-multiple-label-edit')).chosen({
                allow_single_deselect: true,
                placeholder_text_multiple: 'No Label Selected'
            }).on('change', function (e, p) {
                console.log(p, e.target.value);
                if (p.selected != undefined) {
                    THIS.DataSingle.label.push(parseInt(p.selected));
                } else {
                    THIS.DataSingle.label.splice(THIS.DataSingle.label.indexOf(parseInt(p.deselected)), 1);
                }
                console.log(THIS.DataSingle.label);
            });
        }, 800);
    }

    getAllFacility() {
        const THIS = this;
        THIS.Facility.length = 0;
        this.facilityService.getFacilitiesList().subscribe(res => {
            THIS.Facility.length = 0;
            $.each(res.facility, function (i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                $.each(v.child, function (j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                    $.each(vC.child, function (k, vCC) {
                        vCC['pp'] = 2;
                        THIS.Facility.push(vCC);
                        $.each(vCC.child, function (l, vCCC) {
                            vCCC['pp'] = 3;
                            THIS.Facility.push(vCCC);
                        });
                    });
                });
            });
            setTimeout(function () {
                (<any>$('.chosen-default-single-fac')).chosen('destroy');
                (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                (<any>$('.chosen-default-single-fac')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Facility'
                }).on('change', function (e, p) {
                    THIS.param.facility_id = e.target.value;
                    const index = THIS.Facility.map(function (e) {
                        return e.id.toString();
                    }).indexOf(THIS.param.facility_id.toString());
                    console.log(index);
                    if (index > -1) {
                        THIS.param.address = THIS.Facility[index].address;
                        console.log(THIS.param.address);
                        console.log(THIS.Facility[index]);
                    }
                });
            }, 800);
        });
    }

    Labels = [];

    getAllLabel() {
        const THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'contact/label/getAll?token=' + THIS.currentUser.token,
            type: 'post',
            data: {},
            success: function (res) {
                THIS.Labels = res.data;
                setTimeout(function () {
                    // (<any>$(".chosen-default-multiple-label")).val(THIS.AssetSelect);
                    (<any>$('.chosen-default-multiple-label')).chosen('destroy');
                    (<any>$('.chosen-default-multiple-label')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_multiple: 'No Label Selected'
                    }).on('change', function (e, p) {
                        if (p.selected != undefined) {
                            THIS.param.label.push(p.selected);
                        } else {
                            THIS.param.label.splice(THIS.param.label.indexOf(p.deselected), 1);
                        }
                    });
                }, 100);
            }
        });
    }

    // tslint:disable-next-line:member-ordering
    param: any = {
        facility_id: 0,
        avatar: '',
        name: '',
        company: '',
        job_title: '',
        email: '',
        phone: '',
        address: '',
        label: []
    };
    avatarLoading = 0;
    selectedFilesPreview = '';

    uploadThisMedia(file, media_type, callback) {
        const formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        this.mediaService.create(formData).subscribe(res => {
            callback(res);
        });
    }

    selectTheseFiles(e) {
        e.preventDefault();
        const THIS = this;
        const accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            const eachFile = accepted[i];
            if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg') {
                THIS.avatarLoading = 1;
                this.uploadThisMedia(eachFile, '1', function (uploaded) {
                    THIS.avatarLoading = 0;
                    if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                            swal({
                                title: 'Storage Full',
                                text: 'Your Storage is full. Please delete some files',
                                icon: 'error',
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                        } else {
                            swal({
                                title: 'Invalid file format uploaded',
                                text: 'Your request is not valid or there is something wrong.Please try again',
                                icon: 'error',
                                dangerMode: true,
                                className: 'swal-btn-center'
                            });
                        }
                    } else {
                        THIS.param.avatar = uploaded.file_path;
                    }
                });
            } else {
                swal('Only jpg/jpeg and png files are allowed');
            }
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }

    submitNewContact() {
        const THIS = this;
        if(THIS.param.name == ''){
          $('.show-facility-name-error').html('Name field is required');
          return true;
        }
        const ldd = document.getElementById('createBtn');
        const ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'contact/create?token=' + THIS.currentUser.token,
            type: 'post',
            data: THIS.param,
            success: function (res) {
                ladda.stop();
                toastr.success('Contact has been created successfully', 'Contact Create', THIS.toastrOption);
                (<any>$('#createContactModal')).modal('hide');
                THIS.param.avatar = '';
                THIS.getAllContact();
                $('.show-facility-name-error').html('');
            }
        });
    }


    importFile = {
        file: null,
        dataJson: [],
        dataKeyword: [],
    };
    importStep = 1;

    imp_contact() {
        $('#imp_contact').click();
    }

    parseContact(fileInput) {
        const THIS = this;
        const lines = [];
        const parsedContact = fileInput.target.files[0];
        const extArr = parsedContact.name.split('.');
        const ext = extArr[extArr.length - 1];
        parsedContact['ext'] = ext;
        this.importFile.file = parsedContact;
        if (ext.toLowerCase() == 'csv') {
            const reader: FileReader = new FileReader();
            reader.readAsText(parsedContact);
            reader.onload = (e) => {
                const csv: string = reader.result;
                const allTextLines = csv.split(/\r|\n|\r/);
                const headers = allTextLines[0].split(',');
                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma
                    const data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        const tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }
                        lines.push(tarr);
                    }
                }
                THIS.importFile.dataJson = lines;
                THIS.importFile.dataKeyword = lines[0];
            };
        } else if (ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx') {
            const target: DataTransfer = <DataTransfer>(fileInput.target);
            if (target.files.length !== 1) {
                throw new Error('Cannot use multiple files');
            }
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                THIS.importFile.dataJson = XLSX.utils.sheet_to_json(ws, {header: 1});
                THIS.importFile.dataKeyword = THIS.importFile.dataJson[0];
            };
            reader.readAsBinaryString(target.files[0]);
        } else {
            // Invalid file
        }
        this.importStep = 1;

        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#importContactModal')).modal('show');
    }

    contactFields = [
        {name: 'name', title: 'Name', map: '-1'},
        {name: 'company', title: 'Company', map: '-1'},
        {name: 'job_title', title: 'Job Title', map: '-1'},
        {name: 'email', title: 'Email Address', map: '-1'},
        {name: 'phone', title: 'Phone', map: '-1'},
        {name: 'address', title: 'Apartment/Shop/Suite', map: '-1'}
    ];
    contactFields2: any = [
        {name: 'facility_id', title: 'Add to Facility (Optional)', map: '-1'},
    ];

    makeMappedContact() {
        const THIS = this;
        this.importStep = 2;
        $.each(THIS.contactFields, function (i, v) {
            $.each(THIS.importFile.dataKeyword, function (j, k) {
                if (k.match(new RegExp(v.title, 'gi'))) {
                    THIS.contactFields[i].map = j.toString();
                }
            });
        });
    }

    makeParamContact() {
        const ldd = document.getElementById('contactImp');
        const ladda = Ladda.create(ldd);
        const THIS = this;
        console.log(THIS.contactFields2);
        const paramJson = [];
        $.each(THIS.importFile.dataJson, function (i, v) {
            if (i > 0) {
                const param = {
                    facility_id: THIS.contactFields2[0].map > 0 ? THIS.contactFields2[0].map : 0,
                    avatar: '',
                    name: v[THIS.contactFields[0].map],
                    company: v[THIS.contactFields[1].map],
                    job_title: v[THIS.contactFields[2].map],
                    email: v[THIS.contactFields[3].map],
                    phone: v[THIS.contactFields[4].map],
                    address: v[THIS.contactFields[5].map],
                    label: []
                };
                if (param.name != '') {
                    paramJson.push(param);
                }
            }
        });
        if (paramJson.length > 1000) {
            swal({
                title: 'Import Limitation',
                text: 'Maximum allowed rows is 1000. If you continue, only the first 1000 rows will be imported?',
                icon: 'warning',
                buttons: ['Cancel', 'Continue'],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    paramJson.length = 1000;
                    ladda.start();
                    $.ajax({
                        url: THIS.APPCONFIG.api + 'contact/create/lot?token=' + THIS.currentUser.token,
                        type: 'post',
                        data: {contacts: paramJson},
                        success: function (res) {
                            ladda.stop();
                            THIS.getAllContact();
                            (<any>$('#importContactModal')).modal('hide');
                            THIS.importStep = 1;
                            toastr.success('Contact has been imported successfully', 'Contact Import', THIS.toastrOption);
                        }
                    });
                }
            });
        } else {
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.api + 'contact/create/lot?token=' + THIS.currentUser.token,
                type: 'post',
                data: {contacts: paramJson},
                success: function (res) {
                    ladda.stop();
                    THIS.getAllContact();
                    (<any>$('#importContactModal')).modal('hide');
                    toastr.success('Contact has been imported successfully', 'Contact Import', THIS.toastrOption);
                }
            });
        }
    }

    singleUserModal = false;

    ManageDeleteModal(type: any, data: any, show: any = false) {
        if (type === 1) {

            if (data != null) {
                this.selectedContact = [];
                this.selectedContact.push(data.id);
            }
            let ___selectedTab = 0;
            const tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                    ___selectedTab = i;
                }
            });
            const child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            if (show) {
                this.singleUserModal = true;
                (<any>$('#SingleUserModal')).modal('hide');
            }
            (<any>$('#DeleteContactModal')).modal('show');
        } else {
            (<any>$('#DeleteContactModal')).modal('hide');
        }
    }

    showUserModal() {
        if (this.singleUserModal) {
            (<any>$('#SingleUserModal')).modal('show');
            this.singleUserModal = false;
        }
    }

    ManageArchiveModal(type: any, data: any, show: any = false) {
        if (type === 1) {
            if (data != null) {
                this.selectedContact = [];
                this.selectedContact.push(data.id);
            }
            let ___selectedTab = 0;
            const tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                    ___selectedTab = i;
                }
            });
            const child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            if (show) {
                this.singleUserModal = true;
                (<any>$('#SingleUserModal')).modal('hide');
            }
            (<any>$('#ArchiveContactModal')).modal('show');
        } else {
            (<any>$('#ArchiveContactModal')).modal('hide');
        }
    }

    FormInit() {
        const THIS = this;
        $(document).mouseup(function (e: any) {
            const container = $('.outsideClick1');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.removeClass('active');
            }
        });
        $('#createContactModal').on('hidden.bs.modal', function () {
            THIS.param.avatar = '';
        });
    }

    EditContact(data: any) {
        this.DataSingle = data;
        const THIS = this;
        setTimeout(function () {
            (<any>$('.chosen-default-single-edit-fac')).chosen('destroy');
            (<any>$('.chosen-default-single-edit-fac')).val(THIS.DataSingle.facility_id);
            (<any>$('.chosen-default-single-edit-fac')).chosen({
                allow_single_deselect: true,
                placeholder_text_single: 'Select Facility'
            }).on('change', function (e, p) {
                THIS.DataSingle.facility_id = e.target.value;
            });

            (<any>$('.chosen-default-multiple-label-edit')).chosen('destroy');
            (<any>$('.chosen-default-multiple-label-edit')).val(THIS.DataSingle.label);
            (<any>$('.chosen-default-multiple-label-edit')).chosen({
                allow_single_deselect: true,
                placeholder_text_multiple: 'No Label Selected'
            }).on('change', function (e, p) {
                console.log(p, e.target.value);
                if (p.selected != undefined) {
                    THIS.DataSingle.label.push(parseInt(p.selected));
                } else {
                    THIS.DataSingle.label.splice(THIS.DataSingle.label.indexOf(parseInt(p.deselected)), 1);
                }
                console.log(THIS.DataSingle.label);
            });
        }, 800);
        (<any>$('#EditUserModal')).modal('show');

    }

    OpenEditContactModal(data: any) {
        const THIS = this;
        THIS.param = {
            id: data.id,
            facility_id: data.facility_id,
            avatar: data.avatar,
            name: data.name,
            company: data.company,
            job_title: data.job_title,
            email: data.email,
            phone: data.phone,
            address: data.address,
            label: data.label,
        };
        setTimeout(function () {
            (<any>$('.chosen-default-single-fac')).chosen('destroy');
            (<any>$('.chosen-default-single-fac')).val(data.facility_id);
            (<any>$('.chosen-default-single-fac')).chosen({
                allow_single_deselect: true,
                placeholder_text_single: 'Select Facility'
            }).on('change', function (e, p) {
                data.facility_id = e.target.value;
            });

            (<any>$('.chosen-default-multiple-label')).chosen('destroy');
            (<any>$('.chosen-default-multiple-label')).val(data.label);
            (<any>$('.chosen-default-multiple-label')).chosen({
                allow_single_deselect: true,
                placeholder_text_multiple: 'No Label Selected'
            }).on('change', function (e, p) {
                if (p.selected !== undefined) {
                    // tslint:disable-next-line:radix
                    data.label.push(parseInt(p.selected));
                } else {
                    // tslint:disable-next-line:radix
                    data.label.splice(data.label.indexOf(parseInt(p.deselected)), 1);
                }
            });
        }, 800);
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#EditContactModal')).modal('show');
    }

    ngOnInit(): void {
        this.FormInit();
    }


}
