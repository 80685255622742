import {Component, Output, EventEmitter} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import { AuthService } from '../../../_services/auth.service';
declare let swal: any;
declare let toastr: any;

@Component({
    selector: 'page-acc-billing',
    templateUrl: './accBillingHistory.component.html'
})
export class AccBillingHistoryComponent {

    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;


    ROUTES = '/account/billing';
    currentUser = null;
    constructor(private authService: AuthService) {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.currentUser = Session;
        let url = '/'+Session.team+this.ROUTES;
        window.history.pushState("","", url);

        this.getSessionUserInfo();
        this.getInvoices();
    }
    userInfo = null;
    getSessionUserInfo(){
        this.authService.getSessionUserInfo().subscribe(res => {
            if(res.status == 2000){
                this.userInfo = res.data;
                console.log(this.userInfo);
            }
        });
    }


    totalInvoice = 0;
    pageNo = 1;
    perPage = 25;
    pageEvent;
    paginateList(){
      if(this.pageEvent.pageSize == this.perPage){
        this.perPage = this.pageEvent.pageSize;
        this.pageNo = this.pageEvent.pageIndex+1;
        this.getInvoices();
      } else {
        this.perPage = this.pageEvent.pageSize;
        this.pageNo = 1;
        this.starting_after = '';
        this.getInvoices();
      }
    }

    Invoices = [];
    ending_before = '';
    starting_after = '';
    OldPageNo = 1;
    getInvoices(){
        let THIS = this;
        THIS.Invoices.length = 0;
        let url = '';
        if(this.OldPageNo <= this.pageNo){
          url = THIS.APPCONFIG.api+'subscription/getInvoices?limit='+THIS.perPage+'&starting_after='+THIS.starting_after+'&token='+THIS.currentUser.token
        } else if(this.OldPageNo > this.pageNo){
          url = THIS.APPCONFIG.api+'subscription/getInvoices?limit='+THIS.perPage+'&ending_before='+THIS.ending_before+'&token='+THIS.currentUser.token
        }
        this.OldPageNo = this.pageNo;
        $.ajax({
            url : url,
            type: 'get',
            success: function(res){
                if(res.status == 2000){
                     THIS.totalInvoice = res.total;
                    $.each(res.data, function (i, v) {
                      res.data[i].date = THIS.CustomHelper.dateDynamicFormat(res.data[i].date,'');
                      THIS.starting_after = res.data[i].id;
                      if(THIS.CustomHelper.makeInt(i) == 0){
                        THIS.ending_before = res.data[i].id;
                      }
                    })
                    THIS.Invoices = res.data;
                }
            }
        });
    } 
    currencyIcon():string {    
      if(this.currentUser.settings.currency == 'USD') {
        return 'USD$';
      } else {
        return this.currentUser.settings.currency + ' ';
      }
    }
}
