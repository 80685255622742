import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {APPCONFIG} from '../../config';

@Component({
  selector: 'app-operation-radius',
  templateUrl: './operation-radius.component.html',
  styleUrls: ['./operation-radius.component.scss']
})

export class OperationRadiusComponent {
  @Output() radiusChange = new EventEmitter<number>();
  @Input() selectedRadius: any = 10;
  radius: any[] = [5, 10, 25, 50];
  isActive = false;
  custom_radius: any = '';

  constructor(private router: Router) {}
  setRadius() {
    if (this.custom_radius !== '') {
      // tslint:disable-next-line:radix
      this.selectedRadius = parseInt(this.custom_radius);
    }
    this.radiusChange.emit(this.selectedRadius);
    this.isActive = false;
  }
  openDropdown() {
    if (this.isActive) {
      this.custom_radius = '';
      let check = false;
      this.radius.forEach((v: any) => {
        if (v == this.selectedRadius) {
          check = true;
        }
      });

      if (!check) {
        this.custom_radius = this.selectedRadius;
      } else {
         this.custom_radius = '';
      }
    }
    this.isActive = !this.isActive;
  }


  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const clickedInside = targetElement.closest('.positive-relative');

    if (!clickedInside) {
      this.isActive = false;
    }
  }
}
