import {Injectable, EventEmitter} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';

import {DashboardComponent} from '../pages/dashboard/dashboard.component';
import {WelcomeComponent} from '../pages/dashboard/welcome.component';
import {BmComponent} from '../pages/bm/bm.component';
import {ProjectsComponent} from '../pages/projects/projects.component';
import {InspectionComponent} from '../pages/inspection/inspection.component';
import {InspectionsRecurringComponent} from '../pages/inspection/inspections-recurring.component';
import {WorkOrdersComponent} from '../pages/work-orders/work-orders.component';
import {WorkOrdersBoardComponent} from '../pages/work-orders/work-orders-board.component';
import {WorkOrdersRecurringComponent} from '../pages/work-orders/work-orders-recurring.component';
import {WorkOrderReportComponent} from '../pages/work-orders/work-order-report.component';
import {InvoicesComponent} from '../pages/invoices/invoices.component';
import {RctiComponent} from '../pages/rcti/rcti.component';
import {SpiComponent} from '../pages/supplier-invoice/spi.component';
import {InvoicesRecurComponent} from '../pages/invoices/invoicesRecur.component';
import {ProfileComponent} from '../pages/profile/profile.component';
import {CompanyProfileComponent} from '../pages/companyProfile/company-profile.component';
import {AccountComponent} from '../pages/account/account.component';
import {EquistoreComponent} from '../pages/equistore/equistore.component';
import {IntroComponent} from '../pages/intro/intro.component';
import {ContactComponent} from '../pages/contact/contact.component';
import {KeysComponent} from '../pages/keys/keys.component';
import {FullCalendarComponent} from '../pages/calendar/calendar.component';

import {AssetsComponent} from '../pages/assets/assets.component';
import {Fm360Component} from '../pages/fm360/fm360.component';
import {InboxComponent} from '../pages/mails/inbox.component';
import {SentComponent} from '../pages/mails/sent.component';
import {SettingsComponent} from '../pages/settings/settings.component';
import {PersonnelComponent} from '../pages/personnel/personnel.component';
import {InventoriesComponent} from '../pages/inventories/inventories.component';
import {FacilityComponent} from '../pages/facility/facility.component';
import {Router, ActivatedRoute, Params, Data} from '@angular/router';
import {CustomHelper} from '../customhelper';
import {LibraryComponent} from '../pages/library/library.component';
import {HiveComponent} from '../pages/hive/hive.component';
import {VerifiedVendorsComponent} from '../pages/verified-vendors/verified-vendors.component';
import {ViewVerifiedVendorComponent} from '../pages/verified-vendors/sections/view-verified-vendor.component';

@Injectable()
export class TabsService {
  CustomHelper = CustomHelper;
  currentUrl = '';
  tabUpdated = new EventEmitter();
  tabs = [];

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  welcome = {
    description: 'Welcome',
    component: WelcomeComponent
  };
  firstTab = {
    description: 'Dashboard',
    component: DashboardComponent
  };

  makeFirstTab(page) {
    let THIS = this;
    let eCheck = THIS.tabs
      .map(function (e) {
        return e && e.description; // Ensure `e` is defined before accessing `description`
      }.bind(THIS))  // Bind `THIS` to the function
      .indexOf(THIS.components[page] && this.components[page].description); // Ensure `this.components[page]` is defined


    if (eCheck > -1) {
      this.setSelectedTab(eCheck);
      this.slideUpdate(eCheck);
    } else {
      let tab = this.selectedTab;
      let targetTabLi = $('#sort-tabs').find('li').has('a.active');
      if (targetTabLi.length > 0) {
        let targetTab = $('#sort-tabs').find('li').index(targetTabLi);
        this.selectedPage.next(page);
        THIS.setTabView(page, targetTab);
      } else {
        THIS.setTabView(page, tab.value);
      }
      clearInterval(THIS.CustomHelper.woTimeCount);
    }

  }

  slideUpdate(selectedTab) {
    setTimeout(function () {
      (<any>$('.owl-carousel')).trigger('to.owl.carousel', selectedTab);
    }, 100);
  }

  invoice = {
    createRecurring: 0
  };

  makeTabArraysLength = 24;

  private selectedPage = new BehaviorSubject('dashboard');
  private selectedTab = new BehaviorSubject(0);
  private mainTab = new BehaviorSubject(this.firstTab);
  private activeLinks = new BehaviorSubject(this.makeTabArrays(this.makeTabArraysLength, 0));

  private components = {
    'welcome': {
      description: 'Welcome',
      component: WelcomeComponent
    },
    'dashboard': {
      description: 'Dashboard',
      component: DashboardComponent
    },
    'projects': {
      description: 'Projects',
      component: ProjectsComponent
    },
    'work-order-report': {
      description: 'Reports',
      component: WorkOrderReportComponent
    },
    'work-orders': {
      description: 'Jobs',
      component: WorkOrdersComponent
    },
    'jobs': {
      description: 'Jobs',
      component: WorkOrdersComponent
    },
    'work-orders-board': {
      description: 'Work Orders Board',
      component: WorkOrdersBoardComponent
    },
    'work-order-recurring': {
      description: 'Recurring Jobs',
      component: WorkOrdersRecurringComponent
    }, 'job-recurring': {
      description: 'Recurring Jobs',
      component: WorkOrdersRecurringComponent
    },
    'invoices': {
      description: 'Invoices',
      component: InvoicesComponent
    },
    'invoicesRecur': {
      description: 'Recurring Invoices',
      component: InvoicesRecurComponent
    },
    'keys': {
      description: 'Key Manager',
      component: KeysComponent
    },
    'rcti': {
      description: 'RCTI',
      component: RctiComponent
    },
    'spi': {
      description: 'Supplier Invoice',
      component: SpiComponent
    },
    'assets': {
      description: 'Assets',
      component: AssetsComponent
    },
    'fm360': {
      description: 'FM360°',
      component: Fm360Component
    },
    'hive': {
      description: 'Hive',
      component: HiveComponent
    },
    'settings': {
      description: 'Settings',
      component: SettingsComponent
    },
    'personnel': {
      description: 'Personnel',
      component: PersonnelComponent
    },
    'eq_verified_vendor': {
      description: 'Equidesk Verified Vendors',
      component: VerifiedVendorsComponent
    },
    'inventories': {
      description: 'Parts & Labour',
      component: InventoriesComponent
    },
    'profile': {
      description: 'Profile',
      component: ProfileComponent
    },
    'organization': {
      description: 'Organization',
      component: CompanyProfileComponent
    },
    'account': {
      description: 'Account',
      component: AccountComponent
    },
    'facilities': {
      description: 'Facilities',
      component: FacilityComponent
    },
    'contact': {
      description: 'Contact',
      component: ContactComponent
    },
    'mails': {
      description: 'Email',
      component: InboxComponent
    },
    'mail_send': {
      description: 'Sent Email',
      component: SentComponent
    },
    'equistore': {
      description: 'Equistore',
      component: EquistoreComponent
    },
    'calendar': {
      description: 'Calendar',
      component: FullCalendarComponent
    },
    'intro': {
      description: 'Intro JS',
      component: IntroComponent
    },
    'building-management': {
      description: 'Building Management',
      component: BmComponent
    },
    'inspections': {
      description: 'Inspections',
      component: InspectionComponent
    },
    'inspections-recurring': {
      description: 'Recurring Inspections',
      component: InspectionsRecurringComponent
    },
    'library': {
      description: 'Cloud Library',
      component: LibraryComponent
    }
  };

  getSelectedPage = this.selectedPage.asObservable();
  getSelectedTab = this.selectedTab.asObservable();
  getMainTab = this.mainTab.asObservable();
  getActiveLinks = this.activeLinks.asObservable();


  getTabs(): Observable<any> {
    return Observable.create(observer => {
      observer.next(this.tabs);
      observer.complete();
    });
  }

  getRecurring() {
    return (this.invoice.createRecurring);
  }

  addTab(tab: any) {
    // console.log(tab);
    this.tabs.push(tab);
  }

  removeTemp() {
    this.tabs.length = 0;
    this.tabs.push({
      description: 'Welcome',
      component: WelcomeComponent
    });
  }

  setSelectedTab(tab: number) {
    this.selectedTab.next(tab);
    let setTab;
    if (this.tabs[tab] == undefined) {
      setTab = this.welcome;
    } else {
      setTab = this.tabs[tab];
    }
    this.setActiveMenu(setTab);
  }

  setSelectedTabSearch(description: string, tabSelect: number) {
    let tab = {description: description};
    this.setActiveMenu(tab);
    this.tabUpdated.emit(tabSelect);
  }

  setTabView(page, tab) {
    if (tab >= 0 && this.tabs.length > 0) {
      this.tabs[tab].description = this.components[page].description;
      this.tabs[tab].component = this.components[page].component;
      this.setActiveMenu(this.tabs[tab]);
    } else {
      if (this.components[page] === undefined) {
        page = 'welcome';
      }
      this.tabs.push({
        description: this.components[page].description,
        component: this.components[page].component
      });
      this.setActiveMenu(this.tabs[0]);
    }
  }

  invoiceRecurring(status) {
    this.invoice.createRecurring = status;
  }

  private setActiveMenu(tab) {
    let links;
    switch (tab.description) {
      case 'Welcome':
        links = this.makeTabArrays(this.makeTabArraysLength, 0);
        this.selectedPage.next('welcome');
        break;
      case 'Dashboard':
        links = this.makeTabArrays(this.makeTabArraysLength, 0);
        this.selectedPage.next('dashboard');
        break;
      case 'Projects':
        links = this.makeTabArrays(this.makeTabArraysLength, 1);
        this.selectedPage.next('projects');
        break;
      case 'Jobs':
        links = this.makeTabArrays(this.makeTabArraysLength, 2);
        this.selectedPage.next('work-orders');
        break;
      case 'Jobs':
        links = this.makeTabArrays(this.makeTabArraysLength, 2);
        this.selectedPage.next('jobs');
        break;
      case 'Recurring Jobs':
        links = this.makeTabArrays(this.makeTabArraysLength, 2);
        this.selectedPage.next('work-order-recurring');
        break;
      case 'Inspections':
        links = this.makeTabArrays(this.makeTabArraysLength, 3);
        this.selectedPage.next('inspections');
        break;
      case 'Recurring Inspections':
        links = this.makeTabArrays(this.makeTabArraysLength, 3);
        this.selectedPage.next('inspections-recurring');
        break;
      case 'Assets':
        links = this.makeTabArrays(this.makeTabArraysLength, 4);
        this.selectedPage.next('assets');
        break;
      case 'Invoices':
        links = this.makeTabArrays(this.makeTabArraysLength, 5);
        this.selectedPage.next('invoices');
        break;
      case 'Recurring Invoices':
        links = this.makeTabArrays(this.makeTabArraysLength, 5);
        this.selectedPage.next('invoicesRecur');
        break;
      case 'RCTI':
        links = this.makeTabArrays(this.makeTabArraysLength, 5);
        this.selectedPage.next('rcti');
        break;
      case 'Parts & Labour':
        links = this.makeTabArrays(this.makeTabArraysLength, 6);
        this.selectedPage.next('inventories');
        break;
      case 'Personnel':
        links = this.makeTabArrays(this.makeTabArraysLength, 7);
        this.selectedPage.next('personnel');
        break;
      case 'Equidesk Verified Vendors':
        links = this.makeTabArrays(this.makeTabArraysLength, 7);
        this.selectedPage.next('eq_verified_vendor');
        break;
      case 'Reports':
        links = this.makeTabArrays(this.makeTabArraysLength, 8);
        this.selectedPage.next('work-order-report');
        break;
      case 'Cloud Library':
        links = this.makeTabArrays(this.makeTabArraysLength, 9);
        this.selectedPage.next('library');
        break;
      case 'Key Manager':
        links = this.makeTabArrays(this.makeTabArraysLength, 10);
        this.selectedPage.next('keys');
        break;
      case 'Visitors':
        links = this.makeTabArrays(this.makeTabArraysLength, 11);
        this.selectedPage.next('welcome');
        break;
      case 'Cloudsense':
        links = this.makeTabArrays(this.makeTabArraysLength, 12);
        this.selectedPage.next('welcome');
        break;
      case 'FM360°':
        links = this.makeTabArrays(this.makeTabArraysLength, 13);
        this.selectedPage.next('welcome');
        break;

      case 'Hive':
        links = this.makeTabArrays(this.makeTabArraysLength, 14);
        this.selectedPage.next('hive');
        break;


      case 'Profile':
        links = this.makeTabArrays(this.makeTabArraysLength, 101);
        this.selectedPage.next('profile');
        break;
      case 'Account':
        links = this.makeTabArrays(this.makeTabArraysLength, 102);
        this.selectedPage.next('account');
        break;
      case 'Organization':
        links = this.makeTabArrays(this.makeTabArraysLength, 102);
        this.selectedPage.next('organization');
        break;
      case 'Users':
        links = this.makeTabArrays(this.makeTabArraysLength, 103);
        this.selectedPage.next('users');
        break;
      case 'Facilities':
        links = this.makeTabArrays(this.makeTabArraysLength, 104);
        this.selectedPage.next('facilities');
        break;


      case 'Email':
        links = this.makeTabArrays(this.makeTabArraysLength, 105);
        this.selectedPage.next('mails');
        break;
      case 'Sent Email':
        links = this.makeTabArrays(this.makeTabArraysLength, 106);
        this.selectedPage.next('mail_send');
        break;
      case 'Contact':
        links = this.makeTabArrays(this.makeTabArraysLength, 107);
        this.selectedPage.next('contact');
        break;
      case 'Calendar':
        links = this.makeTabArrays(this.makeTabArraysLength, 108);
        this.selectedPage.next('calendar');
        break;
      case 'Equistore':
        links = this.makeTabArrays(this.makeTabArraysLength, 109);
        this.selectedPage.next('equistore');
        break;
    }
    setTimeout(() => {
      this.activeLinks.next(links);
    }, 100);
  }


  //=====================
  // Tabs Movement
  //=====================
  makeTabArrays(length, current) {
    let rv = [];
    for (let i = 0; i < length; i++) {
      rv[i] = 0;
      if (i === current) {
        rv[i] = 1;
      }
    }
    return rv;
  }


}
