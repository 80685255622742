import { Component} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';

@Component({
    selector: 'my-app-sidenav-equistore-menu',
    styles: [],
    templateUrl: './sidenav-equistore-menu.component.html'
})

export class SidenavEquistoreMenuComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;

    currentUser;
    constructor() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.getAllModules();
    }



    ModuleList = [];
    getAllModules(){
        let THIS = this;
        $.ajax({
            url : THIS.APPCONFIG.api+'users/modules?token='+this.currentUser.token,
            type: 'get',
            data: {},
            success: function(res){
                if(res.status == 2000){
                    THIS.ModuleList = res.data;
                    console.log(THIS.ModuleList);
                }
            }
        });
    }
}
