import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';

import {APPCONFIG} from '../config';

import {HttpClient} from '@angular/common/http';
import {map} from "rxjs/operators";

@Injectable()
export class FacilityService {

    public token: string;
    facilities = [];
    selectedFac = new BehaviorSubject<any>({});
    currentSelectedFac = this.selectedFac.asObservable();

    constructor(private http: HttpClient,private router: Router) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }


    refreshFacility(){
        this.selectedFac.next([]);
    }

    createFacility(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}facility/create?token=` + this.token, param)
            .pipe(map(res => {
                this.selectedFac.next([]);
                return res;
            }));
    }
    editFacility(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}facility/edit?token=` + this.token, param)
            .pipe(map(res => {
                this.selectedFac.next([]);
                return res;
            }));
    }
    removeFacility(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}facility/remove?token=` + this.token, param)
            .pipe(map(res => {
                this.selectedFac.next([]);
                return res;
            }));
    }


    getFacilitiesList(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}facilities?token=` + this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getFacilitySingle(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}facility/getSingle?token=` + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    updateFacility(facilities): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}facility/update?token=` + this.token, {ids: facilities})
            .pipe(map(res => {
                let ids = [];
                for(let i=0;i<res.length;i++){
                    ids.push(res[i].id);
                }
                return res;
            }));
    }
    enableFacility(id): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}facility/enable?token=` + this.token, {id: id})
            .pipe(map(res => {
                return res;
            }));
    }
    enableFacilitySingle(id): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}facility/enable/single?token=` + this.token, {id: id})
            .pipe(map(res => {
                return res;
            }));
    }
    enableAll(all): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}facility/enableAll?token=` + this.token, {all: all})
            .pipe(map(res => {
                return res;
            }));
    }
    enableGroupFacility(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}facility/enableGroup?token=` + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    selectUniqueFac(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}facility/enableUnique?token=` + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }

}
