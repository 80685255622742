import {Component, Input, Output, EventEmitter} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {UserService} from '../../../_services/user.service';
import {MediaService} from '../../../_services/media.service';
import {setTime} from "ngx-bootstrap/timepicker/timepicker.utils";
declare let swal: any;
declare let toastr: any;

@Component({
    selector: 'page-personnel-create',
    templateUrl: './personnel-create.component.html'
})
export class PersonnelCreateComponent {
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};

    inlinePrefixId = 0;
    updateChangesDiscard(){
        // this.CustomHelper.woRouteDiscard = 1;
    }
    confirmDiscard(){
        this.discard.emit(1);
    }
    param_name = '';
    param = {
        avatar : '',
        first_name : '',
        last_name : '',
        email : '',
        phone : '',
        address : '',
        hourly_rate : '',
        group_id : [],
        licenced : 0
    };

    validForm = 0;
    saveSubmit(){
        let param_name = this.param_name.split(' ');
        this.param.first_name = param_name[0] != undefined ? param_name[0] : '';
        this.param.last_name = param_name[1] != undefined ? param_name[1] : '';
        this.param.avatar = this.selectedFilesPreview;
        let valid = this.validationThisForm();
        if (valid == 1) {
            this.userService.createPersonnel(this.param).subscribe(res => {
                if(res.status == 2000){
                    let THIS = this;
                    toastr.success('New personnel has been successfully created', 'New personnel', THIS.toastrOption);
                    this.preview.emit(res.data);
                } else if(res.status == 7000) {
                    swal(res.msg);
                } else {
                    swal(res.error);
                }
            });
        }
    }
    validationThisForm() {
        let valid = 1;
        this.checkThisForm();
        if (this.param.first_name == '') {
            valid = 0;
            swal('Name is required');
            return valid;
        }
        if (this.param.email == '') {
            valid = 0;
            swal('Email address is required');
            return valid;
        } else {
            let check = this.CustomHelper.emailValidation(this.param.email);
            if(!check){
                swal(this.param.email+'- is not a valid email address. Please type valid email address.');
                this.param.email = '';
                valid = 0;
                return valid;
            }
        }

        if(this.param.email == ''){
            valid = 0;
            swal('Email is required');
            return valid;
        }
        this.validForm = valid;
        return valid;
    }
    checkThisForm() {
        let param_name = this.param_name.split(' ');
        this.param.first_name = param_name[0] != undefined ? param_name[0] : '';
        this.param.last_name = param_name[1] != undefined ? param_name[1] : '';
        let valid = 1;
        if (this.param.first_name == '') {
            valid = 0;
        }
        if (this.param.email == '') {
            valid = 0;
        }
        this.validForm = valid;
        return valid;
    }


    DataGroup = [];
    getAllGroup(){
        let THIS = this;
        let param = {
            keyword : '',
            pageNo : 1,
            perPage : 100,
            sort_by : 'name',
            order_by : 'asc',
        };
        this.userService.getAllGroups(param).subscribe(res => {
            if(res.status == 2000){
                this.DataGroup = res.data;
                setTimeout(function () {
                    (<any>$('.chosen-default-multiple-group')).chosen('destroy');
                    (<any>$('.chosen-default-multiple-group')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_multiple: 'Select groups'
                    }).on('change', function(e,p){
                        if(p.selected != undefined){
                            THIS.param.group_id.push(p.selected);
                        } else {
                            THIS.param.group_id.splice(THIS.param.group_id.indexOf(p.deselected), 1);
                        }
                    });
                }, 100);
            }
        });
    }


    currentUser = null;
    constructor(private userService: UserService,
                private mediaService: MediaService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.currentUserInfo);
        this.getAllGroup();
    }

    changeLicenced(){
        this.param.licenced = (this.param.licenced == 0 ? 1 : 0);
        let THIS = this;

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
        let child = ___selectedTab+1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
        (<any>$('#licencedModal')).modal('show');
        // swal('No Licenced yet');
        setTimeout(function () {
            THIS.param.licenced = 0;
        }, 100);
    }


    avatarLoading = 0;
    selectedFilesPreview = '';
    uploadThisMedia(file, media_type, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        this.mediaService.create(formData).subscribe(res => {
            callback(res);
        });
    }
    selectTheseFiles(e) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            let eachFile = accepted[i];
            if(eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg'){
                THIS.avatarLoading = 1;
                this.uploadThisMedia(eachFile, '1', function (uploaded) {
                    THIS.avatarLoading = 0;
                    if(uploaded.status != undefined){
                        if(uploaded.status == 9000){
                            swal({
                                title: "Storage Full",
                                text: 'Your Storage is full. Please delete some files',
                                icon: "error",
                                dangerMode: true,
                            });
                        } else {
                            swal({
                                title: "Invalid file format uploaded",
                                text: 'Please upload an image in Jpeg or Png only.',
                                icon: "error",
                                dangerMode: true,
                            });
                        }
                    } else {
                        THIS.selectedFilesPreview = uploaded.file_path;
                    }
                });
            } else {
                swal('Only jpg/jpeg and png files are allowed');
            }
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }



}
