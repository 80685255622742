import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaService} from '../../../_services/media.service';
import {of} from 'rxjs';
import {any} from 'codelyzer/util/function';
import {ifStmt} from '@angular/compiler/src/output/output_ast';

declare let Ladda: any;
declare let swal: any;

@Component({
  selector: 'app-page-view-verified-vendor',
  templateUrl: './view-verified-vendor.component.html',
  styles: [],
})
export class ViewVerifiedVendorComponent implements OnInit {

  @Output() discard = new EventEmitter<number>();
  @Input() max_start_date: any = null;
  @Input() max_due_date: any = null;

  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  currentUser;
  loading: any = false;
  isActive: any = false;
  rfqLoading: any = false;
  job_drop: any = false;
  tender_drop: any = false;

  ManageFacility: any = [];
  Facility: any = [];
  FacilityComp: any = [];

  jobTypes = [];
  dateCreatedOption: any = {};
  dateDueOption: any = {
    timePicker: true,
    enableTime: true,
    altInput: true,
    defaultDate: null,
    altFormat: 'Y-m-d',
    onChange: function (selectedDates, dateStr, instance) {
    },
  };
  changingStart = 0;
  singleId: any = null;
  vendorDetails: any = null;
  listParam: any = {
    sort_by: 'title',
    order_by: 'desc',
    industry: '',
    keyword: '',
    pageNo: 1,
    perPage: 20,
  };

  param: any = {
    id: null,
    title: null,
    facility_id: null,
    job_type_id: null,
    start_date: null,
    due_date: null,
    description: null,
    media_files: [],
    attach_files: null,
    asset_ids: null,
    vendor_ids: null,
    add_to_calendar: 0,
  };
  calendarStatus = '_red';
  dateDue: any = null;
  dateCreated = this.CustomHelper.dateToTz(new Date());
  selectedWorkAttach = [];
  selectedFiles = [];
  selectedFilesPreview = [];
  Assets = [];
  AssetSelect = [];
  _uploadError = 0;
  _locPermit = 0;
  UID_IMG = 0;

  rqfListData: any = [];
  deleteParam: any = {
    id: '',
  };
  rqfID: any = null;


  constructor(private activatedRoute: ActivatedRoute, private router: Router, private mediaService: MediaService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

    const route: any = this.CustomHelper.getRouteStr();
    if (route[2] != undefined) {
      this.singleId = route[2];

      this.getVendorDetails();
      this.getRfqList();
    }
  }

  getIcon(file_name) {
    // Define arrays of image and file extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
    const fileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'zip', 'rar'];

    // Extract the file extension from the file name
    const extension = file_name.split('.').pop().toLowerCase();

    // Check if the extension matches an image or file type
    if (imageExtensions.includes(extension)) {
      return 'image'; // Material Icon for image files
    } else if (fileExtensions.includes(extension)) {
      return 'insert_drive_file'; // Material Icon for generic files
    } else {
      return 'help_outline'; // Material Icon for unknown types
    }
  }

  ngOnInit() {
    const THIS = this;
    $('.reRenderFacility').on('click', function () {
      THIS.getAllFacility();
    });
    $('.reRenderAssets').on('click', function () {
      THIS.getAssets();
    });

  }

  getVendorDetails() {
    const THIS = this;
    THIS.loading = true;
    $.ajax({
      url: THIS.APPCONFIG.api + 'verified/vendors/get/single?token=' + THIS.currentUser.token,
      data: {id: THIS.singleId},
      type: 'post',
      success: function (res) {
        THIS.loading = false;
        THIS.vendorDetails = res.data;
      },
      error: function (err) {
        THIS.loading = false;
        console.error('Error:', err);
      }
    });
  }

  dueDateFlatpickr(dateTime: any) {
    const THIS: any = this;
    this.dateDueOption = {
      altInput: true,
      disableMobile: true,
      defaultDate: dateTime != null ? new Date(dateTime) : null,
      onChange: function (selectedDates, dateStr, instance) {
      },
    };
    this.dateDueOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.param.due_date = dateStr;
    };
    return this.dateDueOption;
  }

  startDateFlatpickr(dateTime: any) {
    const THIS: any = this;
    this.dateCreatedOption = {
      altInput: true,
      disableMobile: true,
      defaultDate: dateTime != null ? new Date(dateTime) : null,
      onChange: function (selectedDates, dateStr, instance) {
      },
    };
    this.dateCreatedOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.param.start_date = dateStr;
    };
    return this.dateCreatedOption;
  }

  getRfqList() {
    const THIS = this;
    THIS.rfqLoading = true;
    $.ajax({
      url: THIS.APPCONFIG.api + 'rfp/get/list?token=' + THIS.currentUser.token,
      data: THIS.listParam,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          THIS.rqfListData = res.data;
        }
      },
      error: function (err) {
        console.error('Error:', err);
      },
      complete: function (data) {
        THIS.rfqLoading = false;
      }
    });
  }

  getJobTypes() {
    const THIS = this;
    this.jobTypes.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-types?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.jobTypes = res.data;
        setTimeout(function () {
          if ($('.reRenderJobType').length > 0) {
            const job_types_id = parseInt($('.reRenderJobType').attr('data-id'));
            if (job_types_id > 0) {
              THIS.param.job_types_id = job_types_id;
            }
          }
          (<any>$('.chosen-default-single-jti')).chosen('destroy');
          (<any>$('.chosen-default-single-jti')).val(THIS.param.job_types_id);
          (<any>$('.chosen-default-single-jti')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Job Type'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              (<any>$('.chosen-default-single-jti')).val(THIS.param.job_types_id);
              (<any>$('.chosen-default-single-jti')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#JobTypesModal')).modal('show');
            } else {
              THIS.param.job_type_id = e.target.value;
              THIS.updateChangesDiscard();
            }
          });
        }, 100);
      }
    });
  }

  createRfq() {
    const THIS = this;
    THIS.clearAllErrors();
    const ldd = document.getElementById('ConfirmRfq');
    const ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'rfp/create?token=' + THIS.currentUser.token,
      data: THIS.param,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          ladda.stop();
          THIS.CloseRQFModal();
          THIS.getRfqList();
        }
        if (res.status === 5000) {
          THIS.errorHandler(res.error);
        }

      },
      error: function (err) {

      },
      complete: function (add) {
        ladda.stop();
      }
    });
  }

  updateRfq() {
    const THIS = this;
    THIS.clearAllErrors();
    const ldd = document.getElementById('ConfirmRfq');
    const ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'rfp/edit?token=' + THIS.currentUser.token,
      data: THIS.param,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          ladda.stop();
          THIS.CloseRQFModal();
          THIS.getRfqList();
        }
        if (res.status === 5000) {
          THIS.errorHandler(res.error);
        }

      },
      error: function (err) {

      },
      complete: function (add) {
        ladda.stop();
      }
    });
  }

  backToList() {
    this.discard.emit(1);
  }

  openDropdown(type) {
    if (type === 'jobs') {
      // Toggle jobs dropdown
      this.job_drop = !this.job_drop;
      // Ensure tender dropdown is closed when jobs is toggled
      this.tender_drop = false;
    } else if (type === 'tender') {
      // Toggle tender dropdown
      this.tender_drop = !this.tender_drop;
      // Ensure jobs dropdown is closed when tender is toggled
      this.job_drop = false;
    }
  }

  openRQFModal(data: any = null) {
    const THIS = this;
    THIS.clearAllErrors();
    setTimeout(() => {
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');

      if (data !== null) {
        /*Need to integrate Single API*/
        THIS.rqfID = data.id;
        THIS.param.id = data.id;
        THIS.param.title = data.title;
        THIS.param.facility_id = data.facility_id;
        THIS.param.job_type_id = data.job_type_id;
        THIS.param.start_date = data.start_date;
        THIS.param.due_date = data.due_date;
        THIS.param.description = data.description;
        THIS.param.media_files = data.media_files;
        THIS.param.attach_files = data.attach_files;
        THIS.param.asset_ids = data.asset_ids;
        THIS.param.vendor_ids = data.vendor_ids;
        THIS.param.add_to_calendar = data.add_to_calendar;
      } else {
        THIS.rqfID = null;
        THIS.param.id = null;
        THIS.param.title = null;
        THIS.param.facility_id = null;
        THIS.param.job_type_id = null;
        THIS.param.start_date = null;
        THIS.param.due_date = null;
        THIS.param.description = null;
        THIS.param.media_files = null;
        THIS.param.attach_files = null;
        THIS.param.asset_ids = null;
        THIS.param.vendor_ids = null;
        THIS.param.add_to_calendar = 0;
      }

      (<any>$('#rqfModal')).modal('show');
      THIS.getAllFacility();
      THIS.getJobTypes();
      THIS.asset_get();

    }, 500);
  }

  CloseRQFModal() {
    this.rqfID = null;
    (<any>$('#rqfModal')).modal('hide');
  }

  OnSelect(e: any) {
    this.param.facility_id = e.id;
    if (e.id === '000') {
      this.NewFacility();
    }
  }

  NewFacility() {
    if (this.param.facility_id === '000') {
      const THIS = this;
      (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
      (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
      let ___selectedTab = 0;
      const tabs: any = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#createFacilityModal')).modal('show');

    }
  }

  getAllFacility() {
    const THIS = this;
    THIS.ManageFacility = [];
    THIS.Facility.length = 0;
    THIS.FacilityComp.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.FacilityComp = res.facility;
        THIS.ManageFacility = res.facility;
        $.each(res.facility, function (i, v) {
          v['pp'] = 0;
          THIS.Facility.push(v);
          $.each(v.child, function (j, vC) {
            vC['pp'] = 1;
            THIS.Facility.push(vC);
            $.each(vC.child, function (k, vCC) {
              vCC['pp'] = 2;
              THIS.Facility.push(vCC);
              $.each(vCC.child, function (l, vCCC) {
                vCCC['pp'] = 3;
                THIS.Facility.push(vCCC);
              });
            });
          });
        });
        setTimeout(function () {
          if ($('.reRenderFacility').length > 0) {
            // tslint:disable-next-line:radix
            const facilityId = parseInt($('.reRenderFacility').attr('data-id'));
            if (facilityId > 0) {
              THIS.param.facility_id = facilityId.toString();
            }
          }
          (<any>$('.chosen-default-single-fac')).chosen('destroy');
          (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
          (<any>$('.chosen-default-single-fac')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Facility'
          }).on('change', function (e, p) {
            if (e.target.value === '000') {
              (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
              (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#createFacilityModal')).modal('show');
            } else {
              THIS.param.facility_id = e.target.value;
            }
          });
        }, 100);
      }
    });
  }

  formatIndustry(industry) {
    if (industry && industry !== '') {
      return industry.split(',').join(', ');
    }
  }

  updateChangesDiscard() {
    const THIS = this;
    const index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      this.CustomHelper.ConfRoute[index].change = 1;
    } else {
      this.CustomHelper.ConfRoute.push({tab: this.CustomHelper.ConfCurRoute, change: 1});
    }
  }

  addToCalendar(e) {
    const THIS = this;
    THIS.isActive = e;
    if (THIS.param.start_date == null || THIS.param.start_date == '') {
      THIS.param.add_to_calendar = 0;
      THIS.isActive = false;
      swal('You must have to provide start Date to add this RFQ to Calendar');
    } else {
      e == false ? THIS.param.add_to_calendar = 0 : THIS.param.add_to_calendar = 1;
      THIS.updateChangesDiscard();
    }
    console.log(THIS.param.add_to_calendar, 'calerdar');
  }

  uploadFile(e) {
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        const eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status !== undefined) {
            if (uploaded.status === 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please trash some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  addFile() {
    $('#fileAttach').click();
  }

  uploadThisMedia(file, media_type, localtion, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  removeThisFile(index, id) {
    this.selectedWorkAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  removeThisMedia(id, media_type) {
    const param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  removeThisImage(index, id) {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesPreview.splice(index, 1);
    this.param.media_files.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  dragFileOverEnd() {
    const trigger = $('#uploadDrag');
    trigger.removeClass('onEnter');
  }

  dragFileOverStart() {
    const trigger = $('#uploadDrag');
    trigger.addClass('onEnter');
  }

  dragFilesDropped(e) {
    e.preventDefault();
    this._uploadError = 0;
    const THIS = this;
    if (e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        if (this.selectedFiles.length < 3) {
          const eachFile = e.dataTransfer.files[i];
          this.selectedFiles.push(eachFile);
          // tslint:disable-next-line:max-line-length
          if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            if (THIS.currentUser.modules.geo_tagged_photo == 1) {
              navigator.geolocation.getCurrentPosition(function (position) {
                if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                  THIS._locPermit = 1;
                  const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                  THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please trash some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  THIS._locPermit = -1;
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please trash some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                }
              }, function (error) {
                swal({
                  title: 'Share Geo Location',
                  // tslint:disable-next-line:max-line-length
                  text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                  icon: 'warning',
                  buttons: ['Want to share location', 'Don\'t want to share Location'],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                      if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                          swal({
                            title: 'Storage Full',
                            text: 'Your Storage is full. Please trash some files',
                            icon: 'error',
                            dangerMode: true,
                          });
                        } else {
                          swal({
                            title: 'Invalid file format uploaded',
                            text: 'Please upload an image in Jpeg or Png only.',
                            icon: 'error',
                            dangerMode: true,
                          });
                        }
                      } else {
                        THIS.selectedFilesPreview.push(uploaded);
                      }
                    });
                  } else {
                    swal('If you want to share location then change the browser settings and remove the block status');
                  }
                });
              });
            } else {
              THIS._locPermit = -1;
              THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                if (uploaded.status != undefined) {
                  if (uploaded.status == 9000) {
                    swal({
                      title: 'Storage Full',
                      text: 'Your Storage is full. Please trash some files',
                      icon: 'error',
                      dangerMode: true,
                    });
                  } else {
                    swal({
                      title: 'Invalid file format uploaded',
                      text: 'Please upload an image in Jpeg or Png only.',
                      icon: 'error',
                      dangerMode: true,
                    });
                  }
                } else {
                  THIS.selectedFilesPreview.push(uploaded);
                }
              });
            }
          } else {
            this._uploadError = 1;
          }
        }
      }
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    const THIS = this;
    this.param.media_files = this.param.media_files || [];
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedFiles.length < 3) {
        const eachFile = accepted[i];
        this.selectedFiles.push(eachFile);
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                THIS._locPermit = 1;
                const localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please trash some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                    THIS.param.media_files.push(uploaded);
                  }
                });
              } else {
                THIS._locPermit = -1;
                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please trash some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                    THIS.param.media_files.push(uploaded);
                  }
                });
              }
            }, function (error) {
              swal({
                title: 'Share Geo Location',
                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                icon: 'warning',
                buttons: ['Want to share location', 'Don\'t want to share Location'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please trash some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                      THIS.param.media_files.push(uploaded);
                    }
                  });
                } else {
                  swal('If you want to share location then change the browser settings and remove the block status');
                }
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please trash some files',
                    icon: 'error',
                    dangerMode: true,
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
                THIS.param.media_files.push(uploaded);
              }
            });
          }
        } else {
          this._uploadError = 1;
        }
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
    console.log(THIS.param.media_files);
  }

  getAssets(facility_id = ''): void {
    const THIS = this;
    this.Assets.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'assets/get/Asset/allList?token=' + THIS.currentUser.token,
      data: {facility_id: facility_id},
      type: 'post',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Assets = res.data;
        }
        setTimeout(function () {
          if ($('.reRenderAssets').length > 0) {
            const AssetSelectId = parseInt($('.reRenderAssets').attr('data-id'));
            if (AssetSelectId > 0) {
              THIS.AssetSelect.push(AssetSelectId);
              THIS.param.asset_ids = THIS.AssetSelect.join(',');
              THIS.updateChangesDiscard();
            }
          }
          (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
          (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
          (<any>$('.chosen-default-multiple-asset')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No asset selected'
          }).on('change', function (e, p) {
            if (p.selected === '000') {
              (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
              (<any>$('.chosen-default-multiple-asset')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#assetModal')).modal('show');
            } else {
              if (p.selected != undefined) {
                THIS.AssetSelect.push(p.selected);
              } else {
                THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
              }
              THIS.param.asset_ids = THIS.AssetSelect.join(',');
              THIS.updateChangesDiscard();
            }

          });
        }, 100);
      }
    });
  }


  // Function to show error messages
  errorHandler(errors) {
    // Loop through the errors object
    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        // Get the input field by name or id
        const inputField = document.querySelector(`#${field}`);

        if (inputField) {
          // Create an error message element
          const errorElement = document.createElement('span');
          errorElement.classList.add('error-message');
          errorElement.textContent = errors[field][0];  // Display the first error message

          // Check if an error message already exists and remove it
          const parentElement = inputField.parentNode as Element;
          if (parentElement) {
            const existingError = parentElement.querySelector('.error-message');
            if (existingError) {
              existingError.remove();
            }
          }

          // Insert the error message after the input field
          inputField.parentNode.appendChild(errorElement);
        }
      }
    }
  }


  clearAllErrors() {
    // Select all error messages
    const errorMessages = document.querySelectorAll('.error-message');

    // Remove each error message
    Array.from(errorMessages).forEach((errorMessage) => {
      if (errorMessage instanceof Element) {
        errorMessage.remove();
      }
    });

  }


  formatDate(dateString, format: any = 'MMMM DD, YYYY') {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Handle different formats
    switch (format) {
      case 'MM/DD/YYYY':
        return `${month}/${day}/${year}`;
      case 'DD-MM-YYYY':
        return `${day}-${month}-${year}`;
      case 'MMMM DD, YYYY': // Full month name, e.g., September 13, 2024
        return `${date.toLocaleString('default', {month: 'long'})} ${day}, ${year}`;
      default:
        return `${date.toLocaleString('default', {month: 'long'})} ${day}, ${year}`; // Default to YYYY-MM-DD
    }
  }


  deleteRfqModal(id: any = null) {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    console.log(id, 'id');
    this.deleteParam.id = id;
    (<any>$('#deleteRfqModal')).modal('show');
  }

  closeRFQDeleteModal() {
    this.deleteParam.id = '';
    (<any>$('#deleteRfqModal')).modal('hide');
  }

  DeleteThisRFQ() {
    const THIS = this;
    const ldd = document.getElementById('deleteBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'rfp/delete?token=' + THIS.currentUser.token,
      data: THIS.deleteParam,
      type: 'post',
      success: function (res) {

      },
      error: function (err) {
        console.error('Error:', err);
      },
      complete: function (data) {
        ladda.stop();
      }
    });
  }


  asset_get(facility_id = ''): void {
    const THIS = this;
    this.Assets.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'assets/get/Asset/allList?token=' + THIS.currentUser.token,
      data: {facility_id: facility_id},
      type: 'post',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Assets = res.data;
        }
        setTimeout(function () {
          if ($('.reRenderAssets').length > 0) {
            const AssetSelectId = parseInt($('.reRenderAssets').attr('data-id'));
            if (AssetSelectId > 0) {
              THIS.AssetSelect.push(AssetSelectId);
              THIS.param.asset_ids = THIS.AssetSelect.join(',');
              THIS.updateChangesDiscard();
            }
          }
          (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
          (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
          (<any>$('.chosen-default-multiple-asset')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No asset selected'
          }).on('change', function (e, p) {
            if (p.selected === '000') {
              (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
              (<any>$('.chosen-default-multiple-asset')).trigger('chosen:updated');
              let ___selectedTab = 0;
              const tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              const child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#assetModal')).modal('show');
            } else {
              if (p.selected != undefined) {
                THIS.AssetSelect.push(p.selected);
              } else {
                THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
              }
              THIS.param.asset_ids = THIS.AssetSelect.join(',');
              THIS.updateChangesDiscard();
            }

          });
        }, 100);
      }
    });
  }

  manageRFQ() {
    const THIS = this;
    if (THIS.rqfID == null) {
      THIS.createRfq();
    } else {
      THIS.updateRfq();
    }
  }
}
