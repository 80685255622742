import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ProgressHttp } from "angular-progress-http";
import { APPCONFIG } from '../config';
import { CustomHelper } from '../customhelper';


import { HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";
import {Job_typeModel} from "../_models/job_type";

@Injectable()
export class MediaService {
    CustomHelper = CustomHelper;
    public token: string;

    constructor(
        private http: ProgressHttp,
        private router: Router
    ) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }

    create(formData): Observable<any> {
        let file = formData.get('files');
        let portal = {
            id : Date.now(),
            name : file.name,
            progress : 0,
            done : 0,
        };
        this.CustomHelper.uploadList.push(portal);
        return this.http
            .withUploadProgressListener(progress => {
                let index = this.CustomHelper.uploadList.map((el) => el.id).indexOf(portal.id);
                this.CustomHelper.uploadList[index].progress = progress.percentage;
                this.CustomHelper.uploadPortalFadeClear();
            })
            .post(`${APPCONFIG.apiMedia}media/upload?token=`+this.token, formData)
            .pipe(map(res => {
                let index = this.CustomHelper.uploadList.map((el) => el.id).indexOf(portal.id);
                this.CustomHelper.uploadList[index].done = 1;
                this.CustomHelper.uploadPortalFade();
                let media = JSON.parse(res['_body']);
                return media;
            }));
    }
    remove(param): Observable<any> {
        return this.http
            .withUploadProgressListener(progress => {})
            .post(`${APPCONFIG.apiMedia}media/remove?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getEmoji(token: any = null): Observable<any> {
      if (token != null) {
        this.token = token;
      }
        return this.http
            .withUploadProgressListener(progress => {})
            .get(`${APPCONFIG.apiMedia}get/emoji?token=`+this.token)
            .pipe(map(res => {
                let data = JSON.parse(res['_body']);
                return data;
            }));
    }




    // =========================
    // mail Service
    // =========================
    sendMail(param): Observable<any> {
        return this.http
            .withUploadProgressListener(progress => {})
            .post(`${APPCONFIG.api}mailbox/mail/send?token=`+this.token, param)
            .pipe(map(res => {
                let response = JSON.parse(res['_body']);
                return response;
            }));
    }
    getSendMail(): Observable<any> {
        return this.http
            .withUploadProgressListener(progress => {})
            .get(`${APPCONFIG.api}mailbox/mail/get/send?token=`+this.token)
            .pipe(map(res => {
                let response = JSON.parse(res['_body']);
                return response;
            }));
    }
    getReceiveMail(): Observable<any> {
        return this.http
            .withUploadProgressListener(progress => {})
            .get(`${APPCONFIG.api}mailbox/mail/get/receive?token=`+this.token)
            .pipe(map(res => {
                let response = JSON.parse(res['_body']);
                return response;
            }));
    }
    getReceiveMailFromMailBox(): Observable<any> {
        return this.http
            .withUploadProgressListener(progress => {})
            .get(`${APPCONFIG.api}mailbox/syncUserReceivedMail?token=`+this.token)
            .pipe(map(res => {
                let response = JSON.parse(res['_body']);
                return response;
            }));
    }
    getReceivePersonalMail(param): Observable<any> {
        return this.http
            .withUploadProgressListener(progress => {})
            .post(`${APPCONFIG.api}mailbox/mail/get/personal/receive?token=`+this.token, param)
            .pipe(map(res => {
                let response = JSON.parse(res['_body']);
                return response;
            }));
    }
    removeReceiveMail(param): Observable<any> {
        return this.http
            .withUploadProgressListener(progress => {})
            .post(`${APPCONFIG.api}mailbox/removeReceivedMail?token=`+this.token, param)
            .pipe(map(res => {
                let response = JSON.parse(res['_body']);
                return response;
            }));
    }
    readReceivedMail(param): Observable<any> {
        return this.http
            .withUploadProgressListener(progress => {})
            .post(`${APPCONFIG.api}mailbox/readReceivedMail?token=`+this.token, param)
            .pipe(map(res => {
                let response = JSON.parse(res['_body']);
                return response;
            }));
    }

}
