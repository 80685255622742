import {Component, OnInit, Input, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {APPCONFIG} from '../../../config';
import {Lightbox} from 'angular2-lightbox';
import {MatTooltipModule} from '@angular/material';
import {QRCodeModule} from 'angular2-qrcode';
import {CustomHelper} from '../../../customhelper';
import {MediaService} from '../../../_services/media.service';
import {AuthService} from '../../../_services/auth.service';

declare let Ladda: any;
declare let google: any;
declare let swal: any;
declare let toastr: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;

@Component({
  selector: 'work-order-export',
  templateUrl: './work-order-export.component.html',
  styles: []
})
export class WorkOrderExportComponent {

  @Input() workDetails: any;
  APPCONFIG = APPCONFIG;
  _albums = [];
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  currentUser = null;
  errorMessage: any = null;
  loading: any = false;
  userParam = {
    first_name: '',
    last_name: '',
    email: '',
    wo_id: '',
  };

  constructor(private _lightbox: Lightbox, private QRCodeModule: QRCodeModule, public tooltip: MatTooltipModule, private mediaService: MediaService,
              private authService: AuthService,) {
    this.currentUser = localStorage.getItem('currentUserInfo') != null ? JSON.parse(localStorage.currentUserInfo) : null;
    if (this.currentUser != null && this.currentUser.user_type != 99) {
      this.getAllFacility();
    }
  }

  ngOnInit() {
    const THIS = this;
    THIS.getAllJobStatus();
    THIS.refreshsinglePage(this.workDetails);
  }

  open(index: number): void {
    const THIS = this;
    const pswpElement = document.querySelectorAll('.pswp')[0];
    const options = {
      index: index,
      shareEl: false,
      tapToClose: false,
      zoomEl: true
    };
    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, THIS._albums, options);
    gallery.init();
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      gallery.close();
      history.go(1);
    };
  }

  workDetailsChange = 0;
  taskType = 1; // 1.Tasks  2.Inspections
  taskCreateSubmit = 0;
  taskList = [];
  taskParam = {tasks: [], work_id: 0};
  id = 0;
  param_created_at = '';
  param_due = '';
  param = {
    prefix: 'w',
    work_order_id: '',
    subject: '',
    description: '',
    media_files: '',
    attach_files: '',
    facility_id: '',
    contact_id: 0,
    asset_id: '',
    job_types_id: 0,
    due: '',
    created_at: '',
    add_to_calendar: false,
    calendar_color: '',
    priority: '0',
    status_code: 1,
    personnel: '4',
    personnel_id: [],
    group_id: [],
    personnel_group: '',
    attachInventories: [],
    taskType: 1,
    oldTasks: [],
    tasks: [],
    incoming: [],
    sor: []
  };
  progress = 0;
  rightSecPreview = 0;
  SelectedPersonList = [];
  attachInventories = [];

  selectedWorkAttach = [];
  selectedFiles = [];
  selectedFilesPreview = [];
  AssetMap = 1;

  CMap;

  GmapZoomIn(data) {
    this.CMap.setZoom(this.CMap.getZoom() + data);
  }

  refreshsinglePage(ResData) {
    const THIS = this;
    const work = ResData;
    THIS.workDetails = work;
    THIS.workDetails.dates.creation_date = THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.creation_date);
    THIS.workDetails.dates.edited_date = THIS.workDetails.dates.edited_date != '' && THIS.workDetails.dates.edited_date != null ? THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.edited_date) : '';

    THIS.workDetails.facStr = THIS.workDetails.facStr.reverse();
    THIS._albums.length = 0;
    if (THIS.workDetails.media_files !== undefined) {
      for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
        const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path;
        const caption = '';
        const thumb = '';
        const album = {
          src: src,
          caption: caption,
          thumb: thumb,
          w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
          h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
        };
        THIS._albums.push(album);
      }
    }

    THIS.id = work.id;
    THIS.taskParam.work_id = work.id;
    if (work.tasks.length > 0) {
      THIS.taskType = work.tasks[0].type;
    }


    THIS.param.subject = work.subject;
    THIS.param.prefix = work.prefix;
    THIS.param.work_order_id = work.work_order_id;
    THIS.param.description = work.description;
    THIS.param.contact_id = work.contact != null ? work.contact_id.toString() : '';
    THIS.param.asset_id = work.asset_id != null ? work.asset_id : '';
    THIS.param.job_types_id = work.job_type != null ? work.job_type.id.toString() : '';
    THIS.param.personnel = work.personnel_id > 0 ? work.personnel_id.toString() : '';
    THIS.param.facility_id = work.facility.id.toString();
    THIS.param.status_code = work.status_code.id.toString();
    THIS.param.sor = work.sor;
    //
    if (work.dates.created_at != null) {
      THIS.param.created_at = THIS.CustomHelper.formatDateTimeToDB(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
      THIS.param.created_at = THIS.CustomHelper.formatDateTime(new Date(THIS.param.created_at));
      THIS.param_created_at = THIS.CustomHelper.formatDateTime(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
    }
    //
    if (work.dates.due_at != null) {
      THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(work.dates.due_at);
      THIS.param_due = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
      THIS.param.due = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
    }

    THIS.progress = work.progress;
    THIS.incoming = work.incoming;
    THIS.param.incoming = THIS.incoming;
    THIS.attachInventories = work.attachInventories;
    THIS.param.attachInventories = THIS.attachInventories;


    THIS.param.personnel_id.length = 0;
    for (let p = 0; p < work.personnel.length; p++) {
      THIS.param.personnel_id.push(work.personnel[p].id.toString());
    }
    THIS.param.priority = work.priority !== null ? work.priority.id.toString() : '';
    THIS.selectedFilesPreview = work.media_files;
    THIS.selectedWorkAttach = work.attach_files;
    setTimeout(function () {
      (<any>$('#summerDesc')).html(THIS.param.description);
      $('.tooltip').remove();
      (<any>$('[data-toggle="tooltip"]')).tooltip('destroy');
      setTimeout(function () {
        THIS.CustomHelper.tooltipRender();
      }, 100);

      if (work.asset.length > 0 && document.getElementById('geo-map-single') != null) {
        let mapStart = 0;
        THIS.CMap = null;
        const latlng = [];
        const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let labelIndex = 0;
        const infowindow = [];
        $.each(work.asset, function (i, v) {
          if (v.geo != '' && v.geo != null) {
            const geo = JSON.parse(v.geo);
            if (mapStart == 0) {
              mapStart = 1;
              THIS.AssetMap = 1;
              const mapOptions = {
                zoom: 0,
                center: new google.maps.LatLng(33.8688, 151.2093),
                gestureHandling: 'cooperative',
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: false
              };
              THIS.CMap = new google.maps.Map(document.getElementById('geo-map-single'), mapOptions);
            }
            if (THIS.CMap != null) {
              latlng.push(geo.geometry);
              const marker = new google.maps.Marker({
                position: geo.geometry,
                map: THIS.CMap,
                draggable: false,
                title: geo.address,
                label: labels[labelIndex++ % labels.length]
              });
              infowindow[i] = new google.maps.InfoWindow({
                content: `<div class="width-100" style="min-width: 600px"><h4 style="text-align: center">` + v.name + `</h4>
                                                        <div class="width-100">
                                                        <div class="margin-10"></div>
                                                        <div class="margin-10"></div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Bran/Model</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.brand + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar">
                                                        <i class="nav-icon material-icons _theme-color">local_offer</i><strong>Serial Number</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.serial_number + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                        <div class="_each_asset_Bar"><i class="nav-icon material-icons _theme-color">money</i><strong>Purchase Cost</strong>
                                                        <br>
                                                        <span style="color: #999;font-size: 13px;" class="ng-star-inserted">
                                                        <em>` + v.purchase_cost + `</em>
                                                        </span>
                                                        </div>
                                                        </div>
                                                        <div class="margin-10">
                                                        </div>
                                                        <div class="margin-10">
                                                        </div></div></div>`
              });
              marker.addListener('click', function () {
                $.each(infowindow, function (iw, inf) {
                  if (inf != i) {
                    infowindow[iw].close();
                  }
                });
                infowindow[i].open(THIS.CMap, marker);
              });
            }
          }
        });
        const latlngbounds = new google.maps.LatLngBounds();
        if (latlng.length > 0) {
          for (let i = 0; i < latlng.length; i++) {
            latlngbounds.extend(latlng[i]);
          }
          THIS.CMap.fitBounds(latlngbounds);
          new google.maps.Rectangle({
            bounds: latlngbounds,
            map: THIS.CMap,
            fillColor: 'transparent',
            fillOpacity: 0.1,
            strokeWeight: 0
          });
        } else {
          THIS.AssetMap = 0;
        }
      } else {
        THIS.AssetMap = 0;
      }
      THIS.workDetailsChange = 1;
      setTimeout(function () {
        THIS.CustomHelper.tooltipRender();
      }, 400);
    }, 100);
    if (THIS.currentUser != null) {
      THIS.getTimeLine();
      THIS.receiveSocket();
    }
  }

  pdfThisWorkOrder() {
    $('#exportPDFTrigger').click();
  }


  overlayNone() {
    $('#comment-overlay').addClass('d-none');
  }

  removeThisAttach(index, id) {
    this.selectedWorkAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  _updatingInfo = 0;

  qckUpdate(type: any = 'all') {

    const THIS = this;
    this.param.incoming = this.incoming;
    this.param.attachInventories = this.attachInventories;
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    this.param.attach_files = mediaAttach;
    this.param.subject = this.workDetails.subject;
    let url: any = null;
    if (THIS.currentUser == null) {
      url = THIS.APPCONFIG.api + 'work-orders/update/' + THIS.id;
    } else {
      url = THIS.APPCONFIG.api + 'work-orders/update/' + THIS.id + '?token=' + THIS.currentUser.token;
    }
    let param = {};
    if (type == 'files') {
      param = {
        attach_files: this.param.attach_files,
      };
    } else if (type == 'status') {
      param = {
        status_code: this.param.status_code,
      };
    }
    $.ajax({
      url: url,
      type: 'post',
      data: param,
      success: function (res) {
        THIS._updatingInfo = 0;
        if (res.status != undefined) {
          if (res.status == 7000) {
          }
        } else {
          toastr.success('Job has been successfully updated', 'Update Job', THIS.toastrOption);
        }
      }
    });
  }

  removeSORQuick() {
    this.qckUpdate();
  }

  jobStatusList = [];
  jobStatusDefault = [];
  jobStatusCustom = [];

  getAllJobStatus() {
    const THIS: any = this;
    let url = null;
    if (THIS.currentUser == null) {
      url = THIS.APPCONFIG.api + 'job-status?w_id=' + THIS.workDetails.id;
    } else {
      let jst = '';
      if (THIS.currentUser.user_type == 99) {
        jst = '&w_id=' + THIS.workDetails.id;
      }
      url = THIS.APPCONFIG.api + 'job-status?token=' + THIS.currentUser.token + jst;
    }
    $.ajax({
      url: url,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.jobStatusDefault.length = 0;
          THIS.jobStatusCustom.length = 0;
          THIS.jobStatusList = res.data;
          localStorage._jsts = JSON.stringify(res.data);
          $.each(res.data, function (i, v) {
            if (v.type == 0) {
              THIS.jobStatusDefault.push(v);
            } else {
              THIS.jobStatusCustom.push(v);
            }
          });
        }
      }
    });
  }

  updateThisWorkStatusNow(status, id) {
    const ldd = document.getElementById('statusUpdate');
    const ladda = Ladda.create(ldd);
    const THIS = this;
    ladda.start();

    let url = null;
    if (THIS.currentUser == null) {
      url = THIS.APPCONFIG.api + 'work-orders/update/' + id;
    } else {
      url = THIS.APPCONFIG.api + 'work-orders/update/' + id + '?token=' + THIS.currentUser.token;
    }

    $.ajax({
      url: url,
      data: {status_code: status.id},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.workDetails.status_code = res.data.status_code;
        // Pusher emit
      }
    });
  }

  addWorkAttach() {
    $('#workAttach').click();
  }

  uploadThisMedia(file, media_type, localtion, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        const eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please trash some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allwoed',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
          }
        });
      }
    }
    setTimeout(function () {
      THIS.qckUpdate('files');
    }, 5000);
  }

  Facility = [];
  FacilityComp = [];

  getAllFacility() {
    const THIS = this;
    THIS.Facility.length = 0;
    THIS.FacilityComp.length = 0;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        THIS.FacilityComp = res.facility;
        $.each(res.facility, function (i, v) {
          v['pp'] = 0;
          THIS.Facility.push(v);
          $.each(v.child, function (j, vC) {
            vC['pp'] = 1;
            THIS.Facility.push(vC);
            $.each(vC.child, function (k, vCC) {
              vCC['pp'] = 2;
              THIS.Facility.push(vCC);
              $.each(vCC.child, function (l, vCCC) {
                vCCC['pp'] = 3;
                THIS.Facility.push(vCCC);
              });
            });
          });
        });
      }
    });
  }

  Personnel = {
    org: [],
    person: []
  };
  InvLabData = [];
  LabourData = [];
  InvLabDataTotal = 0;
  InvLabEmpty = 0;

  getAllInventory(facility_id) {
    const THIS = this;
    this.InvLabData.length = 0;
    this.InvLabDataTotal = 0;
    THIS.InvLabEmpty = 0;
    const param = {
      facility_id: facility_id
    };
    $.ajax({
      url: THIS.APPCONFIG.api + 'inventory/getAll?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        if (res.status == 2000) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]['pick'] = '';
            const index = THIS.attachInventories.map(function (element) {
              return element.inv_id;
            }).indexOf(res.data[i]['id']);
            if (index > -1) {
              if (THIS.attachInventories[index].type_id == 0) {
                res.data[i]['pick'] = THIS.attachInventories[index].pick;
              }
            }
          }
          THIS.InvLabData = res.data;
          THIS.InvLabDataTotal = res.data.length;
        }
        if (res.data.length == 0) {
          THIS.InvLabEmpty = 1;
        }
      }
    });
  }

  personnel_get(): void {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Personnel = res;
        }
        setTimeout(function () {
          if ($('.reRenderPerson').length > 0) {
            const personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
            if (personnel_id > 0) {
              THIS.param.personnel_id.push(personnel_id.toString());
            }
          }
          const pp_val = THIS.param.personnel_id;
          (<any>$('.chosen-default-multiple-per')).val(pp_val);
          (<any>$('.chosen-default-multiple-per')).chosen('destroy');
          (<any>$('.chosen-default-multiple-per')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'No personnel selected'
          }).on('change', function (e, p) {
            if (p.selected != undefined) {
              const v = p.selected;
              THIS.param.personnel_id.push(v.toString());
            } else {
              const v = p.deselected;
              if (THIS.param.personnel_id.indexOf(v) > -1) {
                let ssCount = 0;
                const ssSor = [];
                $.each(THIS.param.sor, function (i, sor_v) {
                  if (sor_v.user_id == v) {
                    ssCount++;
                  } else {
                    ssSor.push(sor_v);
                  }
                });
                if (ssCount > 0) {
                  swal({
                    title: 'SOR Assign Alert',
                    text: 'This personnel has been attached with ' + ssCount + ' SOR item. If you remove, all sor history will be gone also',
                    icon: 'warning',
                    buttons: ['Cancel', 'Remove'],
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
                      THIS.param.sor = ssSor;
                    } else {
                      (<any>$('.chosen-default-multiple-per')).val(THIS.param.personnel_id);
                      (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
                    }
                  });
                } else {
                  THIS.param.personnel_id.splice(THIS.param.personnel_id.indexOf(v), 1);
                }
              }
            }
          });
        }, 100);
      }
    });
  }

  inventoryOpen(): void {
    const THIS = this;

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#invLabModal')).modal('show');
    (<any>$('.invFacilitySelector')).val('');

    THIS.SelectedPersonList.length = 0;
    $.each(THIS.param.personnel_id, function (i, v) {
      const index = THIS.Personnel.person.map(function (element) {
        return element.id;
      }).indexOf(parseInt(v));
      if (index > -1) {
        THIS.SelectedPersonList.push(THIS.Personnel.person[index]);
      }
    });
    THIS.LabourData = THIS.SelectedPersonList;
    THIS.InvLabData.length = 0;
    THIS.InvLabDataTotal = 0;
    THIS.InvLabEmpty = 0;

    setTimeout(function () {
      (<any>$('.invFacilitySelector')).chosen('destroy');
      (<any>$('.invFacilitySelector')).val(THIS.param.facility_id);
      (<any>$('.invFacilitySelector')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Facility'
      }).on('change', function (e, p) {
        if (p != undefined) {
          THIS.getAllInventory(p.selected);
        }
      });

      if (THIS.param.facility_id != '') {
        THIS.getAllInventory(THIS.param.facility_id);
      }
    }, 1000);
  }

  updateInventory() {
    this.attachInventories.length = 0;
    const attachInventories = [];
    $.each(this.InvLabData, function (i, v) {
      if (v.pick != '' && v.sell_price != '' && v.pick != null && v.sell_price != null) {
        attachInventories.push({
          type_id: 0,
          inv_id: v.id,
          title: v.title,
          retail_price: v.sell_price,
          pick: v.pick,
        });
      }
    });
    $.each(this.LabourData, function (i, v) {
      if (v.pick != '' && v.rate != '' && v.pick != null && v.rate != null) {
        attachInventories.push({
          type_id: 1,
          inv_id: v.id,
          title: v.name,
          retail_price: v.rate,
          pick: v.pick,
        });
      }
    });
    this.attachInventories = attachInventories;
    (<any>$('#invLabModal')).modal('hide');
    this.qckUpdate();
  }

  incoming = [];
  incomingCreate = {
    invoice_number: '',
    invoice_amount: '',
    personnel: '',
    groups: '',
    comment: '',
    file_name: []
  };
  invPersonnels = {
    org: [],
    person: []
  };

  getAllSupplier() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'supplierTotal/list?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.invPersonnels.org = res.org;
          THIS.invPersonnels.person = res.person;
        }
        setTimeout(function () {
          (<any>$('.chosen-default-single-supplier')).chosen('destroy');
          (<any>$('.chosen-default-single-supplier')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Supplier'
          }).on('change', function (e, p) {
            const v = e.target.value;
            THIS.incomingCreate.personnel = v;
            THIS.incomingCreate.groups = '';
          });
        }, 500);
      }
    });
  }

  siModalOpen() {
    this.getAllSupplier();

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#SIModal')).modal('show');
  }

  createIncomingInvoice() {
    this.incomingCreate.invoice_amount = ((<any>$('#siCrt')).find('input[name="invoice_amount"]').val()).replace(/\$/, '');
    if (this.incomingCreate.invoice_number != '' && this.incomingCreate.invoice_amount != '') {
      this.incoming.push(this.incomingCreate);
      this.incomingCreate = {
        invoice_number: '',
        invoice_amount: '',
        personnel: '',
        groups: '',
        comment: '',
        file_name: []
      };
      ((<any>$('#siCrt')).find('input[name="invoice_amount"]').val(''));
    }
    (<any>$('#SIModal')).modal('hide');
    this.qckUpdate();
  }

  invoiceUploadFileChoose() {
    $('#invoiceUpload').click();
  }

  invoiceUpload(e) {
    e.preventDefault();
    const THIS = this;
    const files = e.target.files;
    $.each(files, function (i, v) {
      THIS.uploadThisMedia(v, '4', null, function (uploaded) {
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please trash some files',
              icon: 'error',
              dangerMode: true,
            });
          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
              icon: 'error',
              dangerMode: true,
            });
          }
        } else {
          THIS.incomingCreate.file_name.push(uploaded);
        }
      });
    });
  }

  UID_IMG = 0;
  timeLineList = [];
  tmlInterval: any;
  tmlPage = 1;
  tmlFetch = 0;
  timeline_Type = 1;

  newComment() {
    const THIS = this;
    this.cTvalid = 0;
    const comV = this.timeline.comment;
    const com = comV.replace(/<p><br><\/p>/gi, '\n\r');
    const domCom = $('<div>' + com + '</div>');
    let comment = domCom.text().trim();
    let type = '';
    if (this.commentAttachArr.length > 0) {
      const msg = {
        comment: comment.trim(),
        image: this.commentAttachArr
      };
      type = 'attach';
      comment = JSON.stringify(msg);
    } else {
      if (domCom.text() == '') {
        return true;
      }
    }
    const param = {
      work_id: this.id,
      comment: comment,
      type: type
    };
    this.timeline = {
      work_id: 0,
      comment: '',
    };
    $('._module_comment_editor_text').val('');
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/timeline?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.commentAttachArr.length = 0;
        if (res.status === 2000) {
          THIS.upReq = 0;
          res.data['target_pn_type'] = 'wo_comment_' + THIS.workDetails.id;
          // Pusher emit
          THIS.timeline = {
            work_id: 0,
            comment: '',
          };
          setTimeout(function () {
            THIS.getTimeLine();
            THIS.auto_grow();
          }, 1000);
        }
        THIS.timeLineCommentCheck();
      }
    });
  }

  addCommentMedia() {
    if (this.commentAttachArr.length < 6) {
      $('#attachImgComment').click();
    }
  }

  selectTheseCommentImg(e) {
    const THIS = this;
    if (this.upReq > 5) {
      return false;
    }
    this.upReq++;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    e.preventDefault();
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      const eachFile = accepted[i];
      THIS._locPermit = -1;
      THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
        THIS.commentAttachArr.push({
          id: uploaded.id,
          file_path: uploaded.file_path,
          width: uploaded.width,
          height: uploaded.height
        });
        THIS.timeLineCommentCheck();
      });
    }
  }

  onEnterEffect(e) {
    if (e.which == 13 && (!e.shiftKey && !e.ctrlKey)) {
      e.preventDefault();
    }
    this.auto_grow();
  }

  onEnterCreate(e) {
    this.auto_grow();
    this.timeLineCommentCheck();
    if (e.which == 13 && (!e.shiftKey && !e.ctrlKey)) {
      e.preventDefault();
      this.newComment();
    }
  }

  getTimeLine() {
    const THIS = this;
    if (this.tmlFetch == 0) {
      if (this.id > 0) {
        this.tmlFetch = 1;
        THIS.timeLineList = [];
        $.ajax({
          url: THIS.APPCONFIG.api + 'work-orders/timeline/' + THIS.id + '?token=' + THIS.currentUser.token,
          data: {pageNo: THIS.tmlPage, type: THIS.timeline_Type},
          type: 'post',
          success: function (res) {
            if (res.status == 2000) {
              const tml = res.data.reverse();
              let dateStr = '';
              $.each(tml, function (i, v) {
                try {
                  const com = JSON.parse(v.attrs.comment);
                  v['commentT'] = com.comment;
                  v['images'] = com.image;
                } catch (e) {
                }
                const albumT = [];
                if (v.images !== undefined && v.images.length) {
                  for (let i = 0; i < v.images.length; i++) {
                    const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + v.images[i].file_path;
                    const caption = '';
                    const thumb = '';
                    const album = {
                      src: src,
                      caption: caption,
                      thumb: thumb,
                      w: v.images[i].width !== undefined ? v.images[i].width : 1500,
                      h: v.images[i].height !== undefined ? v.images[i].height : 1500
                    };
                    albumT.push(album);
                  }
                }
                v['album'] = albumT;
                v['created_at_human'] = THIS.CustomHelper.formatDateTime(v.created_at);
                v['updated_at_human'] = THIS.CustomHelper.formatDateTime(v.updated_at);
                if (v.module_id == THIS.id) {
                  v['dateStr'] = 0;
                  if (v.created_at_human_format !== dateStr) {
                    dateStr = v.created_at_human_format;
                    v['dateStr'] = 1;
                  }
                  // if(this.tmlPage == 1){
                  THIS.timeLineList.push(v);
                  // } else {
                  //     THIS.timeLineList.splice(0,0,v);
                  // }
                }
              });
              if (this.tmlPage == 1) {
                setTimeout(function () {
                  const tarComP = $('.Comment-Section-Train-way');
                  if (tarComP.length > 0) {
                    tarComP[0].scrollTop = tarComP[0].scrollHeight;
                  }
                }, 500);
              } else {
                setTimeout(function () {
                  const tarComP = $('.Comment-Section-Train-way');
                  if (tarComP.length > 0) {
                    tarComP[0].scrollTop = 100;
                  }
                }, 500);
              }
            }
            if (res.data.length == 0) {
              THIS.tmlPage = 0;
            }
            THIS.tmlFetch = 0;
          }
        });
      }
    }
  }

  scrollTmlTop(e) {
    /*if(e.target.scrollTop < 20){
        if(this.tmlFetch == 0 && this.tmlPage > 0){
            this.tmlPage = this.tmlPage + 1;
            this.getTimeLine();
        }
    }*/
  }

  removeTimelineId = 0;

  openRemoveComment() {
    const THIS = this;
    swal({
      title: 'Comment Trash',
      text: 'Are you sure you want to trash this comment?',
      icon: 'warning',
      buttons: ['Cancel', 'Trash'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (THIS.removeTimelineId > 0) {
          const param = {id: THIS.removeTimelineId, work_id: THIS.id};
          $.ajax({
            url: THIS.APPCONFIG.api + 'work-orders/timeline/remove?token=' + THIS.currentUser.token,
            data: param,
            type: 'post',
            success: function (res) {
              swal.stopLoading();
              swal.close();
              this.getTimeLine();
              // Pusher emit
            }
          });
        } else {
          swal('You didn\'t select any comment');
        }
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
  }

  editLogCommentId = 0;
  editLogCommentParam = {id: 0, comment: '', work_id: 0};
  cNTvalid = 1;

  timeLineNewCommentCheck() {
    const c = this.editLogCommentParam.comment;
    const cR = $.trim(c);
    if (cR.length > 0) {
      this.cNTvalid = 1;
    } else {
      this.cNTvalid = 0;
    }
    const tarCom = $('.ql-container');
    if (tarCom.length > 0) {
      tarCom[0].scrollTop = tarCom[0].scrollHeight;
    }
  }

  timeline = {
    work_id: 0,
    comment: '',
  };
  cTvalid = 0;

  timeLineCommentCheck() {
    const c = this.timeline.comment;
    const cR = $.trim(c);
    if (cR.length > 0 || this.commentAttachArr.length > 0) {
      this.cTvalid = 1;
    } else {
      this.cTvalid = 0;
    }
  }

  auto_grow() {
    const target = $('._module_comment_editor_text');
    const element = target[0];
    element.style.height = '5px';
    element.style.height = (element.scrollHeight) + 'px';
  }

  auto_grow_sloq() {
    const THIS = this;
    setTimeout(function () {
      THIS.auto_grow();
    }, 500);
  }

  upReqEdit = 0;

  newEditComment() {
    const THIS = this;
    let comment = this.editLogCommentParam.comment.trim();
    let type = '';
    if (this.commentAttachEditArr.length > 0) {
      const msg = {
        comment: comment.trim(),
        image: this.commentAttachEditArr
      };
      type = 'attach';
      comment = JSON.stringify(msg);
    }
    const param = {
      id: this.editLogCommentId,
      work_id: this.id,
      comment: comment,
      type: type
    };
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/timeline/edit?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          THIS.upReqEdit = 0;
          res.data['target_pn_type'] = 'wo_comment_upd_' + THIS.workDetails.id;
          // Pusher emit
          THIS.editLogCommentId = 0;
          THIS.editLogCommentParam = {
            id: 0,
            comment: '',
            work_id: 0
          };
          THIS.cNTvalid = 1;
          THIS.commentAttachEditArr.length = 0;
        }
      }
    });
  }

  receiveSocket() {
    const THIS = this;
    /*this.socket.on('wo_comment_' + THIS.workDetails.id, function (v) {
        try {
            let com = JSON.parse(v.attrs.comment);
            v['commentT'] = com.comment;
            v['images'] = com.image;
        } catch (e) {
            // Nothing to do
        }
        let albumT = [];
        if (v.images !== undefined && v.images.length) {
            for (let i = 0; i < v.images.length; i++) {
                const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + v.images[i].file_path;
                const caption = '';
                const thumb = '';
                const album = {
                    src: src,
                    caption: caption,
                    thumb: thumb,
                    w: v.images[i].width !== undefined ? v.images[i].width : 1500,
                    h: v.images[i].height !== undefined ? v.images[i].height : 1500
                };
                albumT.push(album);
            }
        }
        v['album'] = albumT;
        v['created_at_human'] = THIS.CustomHelper.formatDateTime(v.created_at);
        v['updated_at_human'] = THIS.CustomHelper.formatDateTime(v.updated_at);
        let tmI = THIS.timeLineList.map(function (element) {
            return element.id;
        }).indexOf(v.id);
        if (tmI == -1) {
            THIS.timeLineList.push(v);
            setTimeout(function () {
                THIS.auto_grow();
                let tarCom = $('.CommentScrool');
                if (tarCom.length > 0) {
                    tarCom[0].scrollTop = tarCom[0].scrollHeight;
                }
                let tarComP = $('.Comment-Section-Train-way');
                if (tarComP.length > 0) {
                    tarComP[0].scrollTop = tarComP[0].scrollHeight;
                }
            }, 500);
        }
    });
    this.socket.on('wo_comment_upd_' + THIS.workDetails.id, function (v) {
        try {
            let com = JSON.parse(v.attrs.comment);
            v['commentT'] = com.comment;
            v['images'] = com.image;
        } catch (e) {
            // Nothing to do
        }
        let albumT = [];
        if (v.images !== undefined && v.images.length) {
            for (let i = 0; i < v.images.length; i++) {
                const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + v.images[i].file_path;
                const caption = '';
                const thumb = '';
                const album = {
                    src: src,
                    caption: caption,
                    thumb: thumb,
                    w: v.images[i].width !== undefined ? v.images[i].width : 1500,
                    h: v.images[i].height !== undefined ? v.images[i].height : 1500
                };
                albumT.push(album);
            }
        }
        v['album'] = albumT;
        v['created_at_human'] = THIS.CustomHelper.formatDateTime(v.created_at);
        v['updated_at_human'] = THIS.CustomHelper.formatDateTime(v.updated_at);

        let index = THIS.timeLineList.map(function (e) {
            return e.id;
        }).indexOf(v.id);
        THIS.timeLineList[index] = v;
        setTimeout(function () {
            THIS.auto_grow();
        }, 1000);
    });
    this.socket.on('wo_comment_del_' + THIS.workDetails.id, function (v) {
        let index = THIS.timeLineList.map(function (e) {
            return e.id;
        }).indexOf(v.id);
        if (index > -1) {
            THIS.timeLineList[index].is_active = 0;
        }
    });*/
  }

  commentAttachArr = [];
  commentAttachEditArr = [];
  commentAttach = '';
  upReq = 0;
  _uploadError = 0;
  _locPermit = 0;

  editCommentMedia() {
    $('#attachImgEditComment').click();
  }

  uploadThisComentMedia(file, media_type, localtion, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  selectTheseEditCommentImg(e) {
    const THIS = this;
    if (this.upReqEdit > 5) {
      return false;
    }
    this.upReqEdit++;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    e.preventDefault();
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      const eachFile = accepted[i];
      THIS._locPermit = -1;
      THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
        THIS.commentAttachEditArr.push(uploaded);
        THIS.timeLineCommentCheck();
      });
    }
  }

  removeThisMedia(id, media_type) {
    const param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
      this.timeLineCommentCheck();
    });
  }

  removeThisCommentImage(index, id) {
    this.commentAttachArr.splice(index, 1);
    this.upReq--;
    this.removeThisMedia(id, 1);
  }

  removeThisCommentEditImage(index, id) {
    this.commentAttachEditArr.splice(index, 1);
    this.upReqEdit--;
    this.removeThisMedia(id, 1);
  }

  emojiList = [];
  emojiListCurrentIndex = 0;
  emojiListCurrent = [];

  getEmoji() {
    this.emojiListCurrentIndex = 0;
    this.mediaService.getEmoji(this.currentUser.token).subscribe(res => {
      if (res.status == 2000) {
        this.emojiList = res.data;
        this.emojiListCurrent = this.emojiList[this.emojiListCurrentIndex];
      }
    });
  }

  writeEmoji(sign) {
    this.timeline.comment = this.timeline.comment + sign + ' ';
    this.timeLineCommentCheck();
  }

  writeEmojiEdit(sign) {
    this.editLogCommentParam.comment = this.editLogCommentParam.comment + sign + ' ';
    this.timeLineNewCommentCheck();
  }

  currencyIcon(): string {
    if (this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }

  CommentCreate(e) {
    this.auto_grow();
    this.timeLineCommentCheck();
    e.preventDefault();
    this.newComment();
  }

  login(e) {
    e.preventDefault();
    const THIS = this;
    const ldd = document.getElementById('submitBTN');
    const ladda = Ladda.create(ldd);
    THIS.errorMessage = null;
    THIS.loading = true;
    ladda.start();
    THIS.userParam.wo_id = THIS.workDetails.id;
    this.authService.loginNewGuest(THIS.userParam).subscribe(res => {
      THIS.loading = false;
      ladda.stop();
      if (res.status == 2000) {
        THIS.authService.refreshToken();
        this.currentUser = localStorage.getItem('currentUserInfo') != null ? JSON.parse(localStorage.currentUserInfo) : null;
        if (this.currentUser != null && this.currentUser.user_type != 99) {
          this.getAllFacility();
        }
        THIS.getAllJobStatus();
        THIS.refreshsinglePage(THIS.workDetails);
        THIS.overlayNone();
      } else if (res.status == 7000) {
        this.errorMessage = res.error;
        THIS.loading = false;
      } else {
        THIS.errorMessage = 'Email address is incorrect';
        THIS.loading = false;
      }
    });
  }

  checkInput() {
  }
}
