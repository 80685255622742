import {Component, Output, EventEmitter} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import { AuthService } from '../../../_services/auth.service';
declare let swal: any;
declare let toastr: any;
declare let Card: any;

@Component({
    selector: 'page-acc-cards',
    templateUrl: './accCards.component.html'
})
export class AccCardsComponent {

    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;


    ROUTES = '/account/cards';
    currentUser = null;
    constructor(private authService: AuthService) {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.currentUser = Session;
        let url = '/'+Session.team+this.ROUTES;
        window.history.pushState("","", url);

        this.getSessionUserInfo();
        this.getCards();
        $('body').off().on('click', '#refreshCards',function () {
            THIS.getSessionUserInfo();
            THIS.getCards();
        });
    }
    userInfo = null;
    getSessionUserInfo(){
        this.authService.getSessionUserInfo().subscribe(res => {
            if(res.status == 2000){
                this.userInfo = res.data;
                this.currentUser = res.data;
            }
        });
    }

    Cards = [];
    getCards(){
        let THIS = this;
        $.ajax({
            url : THIS.APPCONFIG.api+'subscription/getCards?token='+THIS.currentUser.token,
            type: 'get',
            success: function(res){
                if(res.status == 2000){
                    THIS.Cards = res.data;
                }
            }
        });
    }
    cardError = '';
    editThisCard(e){
        let THIS = this;
        THIS.cardError = '';
        let form = $(e.target).closest('form');
        let v = form.find('input[name="expiry"]').val().toString();
        let arr = v.split('/');
        form.find('input[name="exp_month"]').val(arr[0] != undefined ? $.trim(arr[0]) : '');
        form.find('input[name="exp_year"]').val(arr[1] != undefined ? $.trim(arr[1]) : '');
        $.ajax({
            url : THIS.APPCONFIG.api+'subscription/editCards?token='+THIS.currentUser.token,
            type: 'post',
            data: form.serialize(),
            success: function(res){
                if(res.status == 2000){
                    THIS.getCards();
                } else {
                    THIS.cardError = res.error;
                }
            }
        });
    }
    removeThisCard(card){
        let THIS = this;
        swal({
            title: "Delete Card",
            text: "Are you sure you want to delete this card information?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if(willDelete){
                $.ajax({
                    url : THIS.APPCONFIG.api+'subscription/removeCards?token='+THIS.currentUser.token,
                    type: 'post',
                    data: {id: card.id},
                    success: function(res){
                        if(res.status == 2000){
                            THIS.getCards();
                        } else {
                            THIS.cardError = res.error;
                        }
                    }
                });
            }
        });
    }

    expUpdate(e){
        let form = $(e.target).closest('form');
        let v = e.target.value;
        let arr = v.split('/');
        form.find('input[name="exp_month"]').val(arr[0] != undefined ? $.trim(arr[0]) : '');
        form.find('input[name="exp_year"]').val(arr[1] != undefined ? $.trim(arr[1]) : '');
    }
    validCardJs(){
        let THIS = this;
        setTimeout(function () {
            let card = new Card({
                form: '#payment-form-pp',
                container: '.card-wrapper',
                numberInput: 'input[name="number"]',
                expiryInput: 'input[name="expiry"]',
                cvcInput: 'input[name="cvc"]',
                nameInput: 'input[name="name"]',
                formSelectors: {
                    nameInput: 'input[name="name"]'
                },
                placeholders: {
                    number: '•••• •••• •••• ••••',
                    name: 'Full Name',
                    expiry: '••/••',
                    cvc: '•••'
                }
            });
            // jp-card jp-card-mastercard jp-card-identified
            $('#payment-form-pp').find('input').click();
            if(THIS.Cards.length > 0){
                let brand = THIS.Cards[0].brand.toLowerCase();
                $('.jp-card').addClass('jp-card-'+brand+' jp-card-identified');
            }
        }, 1000);
    }


    createForm = 0;


  StripeCurrency = null;
  getCustomerInfo(){
    let THIS = this;
    $.ajax({
      url : THIS.APPCONFIG.api+'subscription/getCustomerInfo?token='+THIS.currentUser.token,
      type: 'get',
      success: function(res){
        if(res.status == 2000){
          if(res.data.length > 0){
            THIS.StripeCurrency = res.data;
          }
        }
      }
    });
  }
    buySubscription(){
      if(this.StripeCurrency == null || (this.StripeCurrency.currency.toLowerCase() == this.StripeCurrency.uCurrency.toLowerCase())){
        (<any>$('#buySubscription')).click();
      } else {
        swal({
          title: "Subscription Currency",
          text: "It looks like you are currently subscribed to a plan in AUD. If you need to change your plan, please email us at support@equidesk.com",
          icon: "warning",
          className: 'swal-btn-center'
        });
        setTimeout(function () {
          $('.swal-text').html('It looks like you are currently subscribed to a plan in AUD. <br> If you need to change your plan, <br> please email us at <a class="_theme-color" href="mailto:support@equidesk.com">support@equidesk.com</a>')
        }, 500);
      }
    }
}
