import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {WorkOrderService} from '../../_services/work-orders.service';
import {AuthService} from '../../_services/auth.service';
import {MediaService} from '../../_services/media.service';

declare let swal: any;
declare let toastr: any;
declare let Ladda: any;
declare let Inputmask: any;

@Component({
  selector: 'page-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent {
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  countries = this.CustomHelper.getCountry();
  timezones = this.CustomHelper.getTimeZone();
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  vendorInfo = null;

  currentUser;
  dateOption = null;
  newDocParam: any = {
    info_name: null,
    info_value: null,
    info_doc: null,
    expiry: null,
    loading: false,
  };
  expireLoading: boolean;

  constructor(private workOrderService: WorkOrderService,
              private mediaService: MediaService,
              private authService: AuthService) {
    this.currentUser = JSON.parse(localStorage.currentUserInfo);
    this.getProfileInfo();
    this.getPersonalLoginHistory();
  }

  today = new Date();
  detailsTab = 1;

  totalWorkOrders = 0;
  pageNo = 1;
  perPage = 25;
  pageEvent;

  paginateList() {
    if (this.pageEvent.pageSize == this.perPage) {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = this.pageEvent.pageIndex + 1;
      this.getWorkOrder();
    } else {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = 1;
      this.getWorkOrder();
    }
  }

  workOrder = [];
  empty = 0;
  sort_by = 'creation_date';
  order_by = 'desc';
  previewList = 0;

  getWorkOrder() {
    let THIS = this;
    this.previewList = 0;
    this.empty = 0;
    let param = {
      createdBy: this.User.id,
      sort_by: this.sort_by,
      order_by: this.order_by,
      is_active: 1,
      onlyMe: 1,
      keyword: '',
      pageNo: this.pageNo,
      perPage: this.perPage
    };
    this.workOrder.length = 0;
    this.workOrderService.getWorkOrdes(param).subscribe(res => {
      this.previewList = 1;
      if (res.status == 2000 && res.data.length > 0) {
        let Data = [];
        $.each(res.data, function (i, v) {
          v['dates']['creation_date'] = THIS.CustomHelper.formatDateTime(v.dates.creation_date);
          v['dates']['due_at_tz'] = THIS.CustomHelper.formatDateTime(v.dates.due_at);
          v['dates']['due_at'] = THIS.CustomHelper.formatDateTime(v.dates.due_at);
          v['progress'] = v.progress > 100 ? 100 : v.progress;
          v['progress'] = v.progress < 0 ? 0 : v.progress;
          Data.push(v);
        });

        this.workOrder = Data;
        this.totalWorkOrders = res.total;
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      } else {
        this.workOrder.length = 0;
        this.totalWorkOrders = 0;
        this.empty = 1;
      }
    });
  }

  User = null;

  getProfileInfo() {
    this.authService.getPersonalInfo().subscribe(res => {
      let address = '';
      try {
        let addressD = JSON.parse(res.data.profile.company_address);
        address = addressD.address1 + ' ' + addressD.address2 + ' ' + addressD.city + ' ' + addressD.state + ' ' + addressD.country;

      } catch (e) {
        address = res.data.profile.company_address;
      }
      res.data.avatar == '' ? res.data.avatar = null : '';
      this.User = res.data;
      if (this.User.settings.mail_info !== null) {
        if (this.User.settings.mail_info.username !== '' && this.User.settings.mail_info.username !== null) {
          try {
            this.User.settings.mail_info.username = atob(atob(atob(this.User.settings.mail_info.username)));
            this.User.settings.mail_info.password = atob(atob(atob(this.User.settings.mail_info.password)));
          } catch (err) {
            // no
          }
        }
      }

      this.param.first_name = res.data.first_name;
      this.param.last_name = res.data.last_name;
      this.param.avatar = res.data.avatar;
      this.param.email = res.data.email;
      this.param.address = address;
      this.param.phone = res.data.profile.phone;
      this.param.hourly_rate = res.data.profile.hourly_rate;
      this.param.lang = res.data.settings.lang;
      this.param.currency = res.data.settings.currency;
      this.param.tz = res.data.settings.tz;
      this.param.date_format = res.data.settings.date_format;
      this.param.country = res.data.settings.country;
      this.param.time_format = res.data.settings.time_format;
      this.param.docs = res.data.docs;
      this.uDocs = this.param.docs;

      this.getWorkOrder();
    });
  }

  renderEditForm() {
    $('.rate-error').html('');
    let THIS = this;
    setTimeout(function () {
      (<any>$('.chosen-default-single-coun')).val(THIS.param.country);
      (<any>$('.chosen-default-single-coun')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select your country'
      }).on('change', function (e, p) {
        THIS.param.country = e.target.value;
      });
      (<any>$('.chosen-default-single-cur')).val(THIS.param.currency);
      (<any>$('.chosen-default-single-cur')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select your currency'
      }).on('change', function (e, p) {
        THIS.param.currency = e.target.value;
      });
      (<any>$('.chosen-default-single-tz')).val(THIS.param.tz);
      (<any>$('.chosen-default-single-tz')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select your timezone'
      }).on('change', function (e, p) {
        THIS.param.tz = e.target.value;
      });
      (<any>$('.chosen-default-single-date_format')).val(THIS.param.date_format);
      (<any>$('.chosen-default-single-date_format')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select your Date Format'
      }).on('change', function (e, p) {
        THIS.param.date_format = e.target.value;
      });
      (<any>$('.chosen-default-single-time_format')).val(THIS.param.time_format);
      (<any>$('.chosen-default-single-time_format')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select your Time Format'
      }).on('change', function (e, p) {
        THIS.param.time_format = e.target.value;
      });
    }, 100);
    THIS.checkCurrency();
  }

  cHid = 0;
  profileEdit = 0;
  param = {
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    address: '',
    phone: '',
    hourly_rate: '',
    lang: '',
    currency: '',
    tz: '',
    date_format: '',
    country: '',
    time_format: '',
    mail_info: null,
    mail_info_active: '',
    docs: []
  };

  discardEdit() {
    let THIS = this;
    this.profileEdit = 0;
  }

  updateInfo() {
    let THIS = this;
    let ldd = document.getElementById('profileUpdateEdit');
    let ladda = Ladda.create(ldd);
    if (this.param.first_name == '') {
      swal('First name is required');
      return true;
    }
    if (this.param.last_name == '') {
      swal('Last name is required');
      return true;
    }
    if (this.param.email == '') {
      swal('Email address is required');
      return true;
    } else {
      let check = this.CustomHelper.emailValidation(this.param.email);
      if (!check) {
        swal(this.param.email + '- is not a valid email address. Please type valid email address.');
        this.param.email = this.User;
        return true;
      }
    }

    this.param.mail_info = JSON.stringify(this.User.settings.mail_info);
    this.param.mail_info_active = this.User.settings.mail_info_active;

    if ($('input[name="hourly_rate_profile"]').length > 0) {
      this.param.hourly_rate = (<any>$('input[name="hourly_rate_profile"]').val()).replace(this.currencyIcon(), '');
    }
    if (parseInt(this.param.hourly_rate) < 0) {
      $('.rate-error').html('Hourly rate can not be negative value');
      return true;
    }

    let docs = [];
    let errCheck = 0;
    $.each(THIS.uDocs, function (i, v) {
      if ($.trim(v.title).length == 0) {
        v.title = '';
        swal('Can\'t save document with blank title');
        errCheck = 1;
      }
      docs.push(v);
    });
    console.log(errCheck);
    console.log(THIS.uDocs);
    if (errCheck == 1) {
      return true;
    }
    this.param.docs = docs;
    ladda.start();
    this.authService.updateMe(this.param).subscribe(res => {
      ladda.stop();
      if (res.status == 2000) {
        toastr.success('Profile information has been updated successfully', 'Information Update', this.toastrOption);
        this.profileEdit = 0;
        this.getProfileInfo();
      } else {
        swal(res.msg);
        return true;
      }
    });
  }


  lineOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        }
      }]
    },
    legend: {
      display: false
    },
  };
  dataGraph = {
    labels: ['Day 7', 'Day 6', 'Day 5', 'Day 4', 'Day 3', 'Yesterday', 'Today'],
    datasets: [{
      label: '',
      backgroundColor: 'rgba(158,217,205,0.8)',
      borderColor: 'rgba(158,217,205,1)',
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      data: [0, 0, 0, 0, 0, 0, 0]
    }
    ]
  };
  showGraph = 0;

  getPersonalLoginHistory() {
    this.showGraph = 0;
    this.authService.getPersonalLoginHistory().subscribe(res => {
      this.dataGraph.datasets[0]['data'] = res.data.reverse();
      this.showGraph = 1;
    });
  }


  uploadThisMedia(file, media_type, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  uploadAvatar() {
    if (this.profileEdit == 1) {
      $('#changeAvatar').click();
    }
  }

  uploadThisAvatar(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    let eachFile = accepted[0];
    if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
      this.uploadThisMedia(eachFile, '1', function (uploaded) {
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please delete some files',
              icon: 'error',
              dangerMode: true,
            });
          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Your request is not valid or there is something wrong.Please try again',
              icon: 'error',
              dangerMode: true,
            });
          }
        } else {
          THIS.User.avatar = uploaded.file_path;
          THIS.param.avatar = uploaded.file_path;
        }
      });
    } else {
      swal('This file is not supported for avatar. Please select jpg/png/gif file.');
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }


  pro2work = 0;
  workDel = null;

  openWorkOrder(work) {
    let THIS = this;
    this.workDel = work;
    this.pro2work = 1;
    setTimeout(function () {
      THIS.workDel = work;
      THIS.pro2work = 1;
    }, 5000);
  }


  uDocs = [];
  addNewDoc = 0;

  cancelDocUpload(index) {
    if (index > -1) {
      this.uDocs.splice(index, 1);
    }
  }

  addNewDocItem() {
    let docCreate = {
      user_id: this.User.id,
      title: 'Untitled',
      expiry: null,
      notify_admin: 0,
      notify_admin_option: '0',
      notify_user: 0,
      notify_user_option: '0',
      files: []
    };
    this.uDocs.splice(0, 0, docCreate);
  }

  dateCreatedOption = [];

  dateCreatedOptionGen(index, dateTime) {
    let THIS = this;
    this.dateCreatedOption[index] = {
      timePicker: true,
      enableTime: true,
      altInput: true,
      defaultDate: dateTime != null ? new Date(dateTime) : null,
      altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
      onChange: function (selectedDates, dateStr, instance) {
      },
    };
    this.dateCreatedOption[index].onChange = function (selectedDates, dateStr, instance) {
      THIS.uDocs[index].expiry = dateStr;
    };

    return this.dateCreatedOption[index];
  }

  selectTheseFiles(e, index) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (THIS.uDocs[index].files.length == 0) {
        let eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Your request is not valid or there is something wrong.Please try again',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.uDocs[index].files.push(uploaded);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  chooseDocFile(e) {
    $(e.target).find('.eachDocFile').click();
  }

  checkCurrency() {
    Inputmask.extendAliases({
      pesos: {
        prefix: this.currencyIcon(),
        groupSeparator: '',
        alias: 'numeric',
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        radixPoint: '.',
        placeholder: '0.00'
      }
    });

    setTimeout(function () {
      (<any>$('._currency_validate_eq')).inputmask({alias: 'pesos'});
    }, 300);
  }

  currencyIcon(): string {
    if (this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }
}
