import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { APPCONFIG } from '../config';

import { UserModel } from '../_models/user';

import { HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";

@Injectable()
export class UserService {

    public token: string;
    users: Array<UserModel> = [];

    constructor(private http: HttpClient, private router: Router) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }



    //======================
    // App Modules
    //======================
    getAllModules() {
        return this.http
            .get<any>(`${APPCONFIG.api}users/modules?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    moduleInstall(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}users/module/install?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    moduleUninstall(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}users/module/uninstall?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }



    //======================
    // Personnel
    //======================
    createGroup(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/group/create?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getAllGroups(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/group/getAll?token=`+this.token,param)
            .pipe(map(res => {
                return res;
            }));
    }
    editGroup(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/group/edit?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    removeGroup(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/group/remove?token=`+this.token,param)
            .pipe(map(res => {
                return res;
            }));
    }

    getAllRegUsers(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}reg/user/getAll?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }


    getAllPersonnel(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/getAll?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    createPersonnel(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/create?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    invitation(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/invitation?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    singlePersonnel(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/getSingle?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }

    editPersonnel(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/edit?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    removePersonnel(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/remove?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    enablePersonnel(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/enable?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    disablePersonnel(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/disable?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }


    updateGenerelSetting(param){
        return this.http
            .post<any>(`${APPCONFIG.api}settings/general?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    updateBankDetails(param){
        return this.http
            .post<any>(`${APPCONFIG.api}personnel/bank/details?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getGenerelSetting(){
        return this.http
            .get<any>(`${APPCONFIG.api}settings/general?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getBankDetails(){
        return this.http
            .get<any>(`${APPCONFIG.api}personnel/bank/details?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }



    docCreate(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}users/doc/create?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    docGetAll(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}users/doc/getAll?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }


    noteCreate(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}users/notes/create?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    noteEdit(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}users/notes/edit?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    noteGetAll(param) {
        return this.http
            .post<any>(`${APPCONFIG.api}users/notes/getAll?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }


}
