import {Component} from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute, Params  } from '@angular/router';
import {WorkOrderService} from '../../_services/work-orders.service';
import {APPCONFIG} from '../../config';
import {MatTooltipModule} from '@angular/material';


@Component({
    selector: 'work-online-layout',
    templateUrl: './workOnline.component.html'
})
export class WorkOnlineComponent {

    short_code;
    loading = 1;
    workOrders = [];
    currentUrl = '';
    APPCONFIG = APPCONFIG;

    constructor(private router:Router, private route: ActivatedRoute,
                private workOrderService: WorkOrderService,
                public tooltip: MatTooltipModule) {
        router.events.subscribe( (event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
            }
        });
        this.route.params.forEach((params: Params) => {
            this.short_code = params.short_code;
            this.workOrderService.getSingleWork(this.short_code).subscribe(res => {
                if(res.id > 0){
                    this.workOrders.push(res);
                } else {
                    this.loading = 0;
                }
            });
        });

    }
}
