import {Component, Input} from '@angular/core';

import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {AssetService} from '../../../_services/asset.service';
import {AuthService} from '../../../_services/auth.service';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;

@Component({
    selector: 'menu-setting',
    templateUrl: './menu-setting.component.html'
})
export class MenuSettingComponent {
    @Input() openSetting: any;
    toastrOption = {timeOut: 2000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true};
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;

    currentUser;

    constructor(private authService: AuthService, protected assetService: AssetService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.Menus = [];
        this.currentUser.settings.menus.forEach(function (v){
            if(v.slug != 'visitor' && v.slug != 'cloudsense'){
                THIS.Menus.push(v);
            }
        });
    }

    Menus = [];
    MenuOptions = {
        onUpdate: function () {
        }
    };

    UpdatemenuSort() {
        let THIS = this;
        let ldd = document.getElementById('updateMenuSort');
        let ladda = Ladda.create(ldd);
        let items = $('.sortMenu');
        let sortings = [];
        items.each(function (i, v) {
            let id = $(v).attr('id');
            id = id.replace('sortMenu-', '');
            let index = THIS.Menus.map(function (e) {
                return THIS.CustomHelper.makeInt(e.id);
            }).indexOf(THIS.CustomHelper.makeInt(id));
            if (index > -1) {
                sortings.push(THIS.Menus[index]);
            }
        });
        if (sortings.length > 0) {
            let param = {menus: sortings};
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.apiAuth + 'updateMe?token=' + THIS.currentUser.token,
                type: 'post',
                data: param,
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                        toastr.success('Menu decoration and ordering has been updated successfully', 'Menu Settings', THIS.toastrOption);
                        $('#refreshMenu').click();
                    }
                }
            });

        }
    }
}
