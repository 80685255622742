import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {AuthService} from '../../_services/auth.service';
import {UserService} from '../../_services/user.service';
import {Router} from '@angular/router';

declare let swal: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
  selector: 'page-equistore',
  templateUrl: './equistore.component.html'
})
export class EquistoreComponent {
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  permitted = 1;

  currentUser;

  constructor(protected authService: AuthService, protected userService: UserService, private router: Router) {
    this.currentUser = JSON.parse(localStorage.currentUserInfo);
    if (this.currentUser.subscription.plan == 'Free' && this.currentUser.subscription.status === 1) {
      this.router.navigate(['/' + this.currentUser.team + '/dashboard']);
    }
    this.getSessionUserInfo();
    this.getAllModules();
  }

  userInfo = {
    email: '',
    name: '',
    avatar: '',
    settings: null,
    profile: null,
    user_type: '0',
    company_name: null,
  };

  getSessionUserInfo() {
    this.authService.getSessionUserInfo().subscribe(res => {
      if (res.status == 2000) {
        this.userInfo = {
          email: res.data.email,
          name: res.data.first_name + ' ' + res.data.last_name,
          avatar: res.data.avatar,
          settings: res.data.settings,
          profile: res.data.profile,
          user_type: res.data.user_type,
          company_name: res.data.company_name,
        };
        if (res.data.subscription.plan == 'Standard') {
          this.permitted = 0;
        }
      }
    });
  }


  ModuleList = [];

  getAllModules() {
    let THIS = this;
    this.userService.getAllModules().subscribe(res => {
      if (res.status == 2000) {
        this.ModuleList = res.data;
        setTimeout(function () {
          console.log('  ');
          THIS.CustomHelper.tooltipRender();
        }, 2000);
      }
    });
  }

  moduleInstall(module, index) {
    // let ldd = document.getElementById('endis'+index);
    // let ladda = Ladda.create(ldd);
    if (module.paid == 1) {
      swal({
        title: module.title + ' Enable',
        text: 'This Feature is not available right now. Thank you.',
        icon: 'warning',
        button: 'Ok',
        className: 'swal-btn-center'
      });
    } else {
      this.ModuleList[index].installed = 0;
      // ladda.start();
      this.userService.moduleInstall({slug: module.slug}).subscribe(res => {
        // ladda.stop();
        if (res.status == 2000) {
          this.getAllModules();
        /*  swal({
            title: module.title === 'Work Orders' ? 'Jobs Enabled' : module.title + ' Enabled',
            text: module.title === 'Work Orders' ? 'Jobs has been successfully enabled' : module.title + ' has been successfully enabled',
            icon: 'success',
            button: 'Done!',
            className: 'swal-btn-center'
          });*/
          document.querySelector('#ModuleInstallModal .text-title').innerHTML = module.title === 'Work Orders' ? 'Jobs Enabled' : module.title + ' Enabled';
          document.querySelector('#ModuleInstallModal .text-des').innerHTML = module.title === 'Work Orders' ? 'Jobs has been successfully enabled' : module.title + ' has been successfully enabled';
          let ___selectedTab = 0;
          const tabs = $('#sort-tabs').find('li');
          tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
              ___selectedTab = i;
            }
          });
          const child = ___selectedTab + 1;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          $('#sort-tabs').find('li').find('a').removeClass('active');
          $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
          (<any>$('#ModuleInstallModal')).modal('show');
          // (<any>$('#EnabledModules')).click();
        } else {
          this.ModuleList[index].installed = 0;
          swal({
            title: 'Install Module',
            text: res.msg,
            icon: 'error',
            dangerMode: true,
            className: 'swal-btn-center'
          });

        }
      });
    }
  }

  moduleUninstall(module, index) {
    // let ldd = document.getElementById('endis'+index);
    // let ladda = Ladda.create(ldd);
    if (module.paid == 1) {
      swal({
        title: module.title + ' Disabled',
        text: 'This Feature is not available right now. Thank you.',
        icon: 'warning',
        button: 'Ok',
        className: 'swal-btn-center'
      });
    } else {
      this.ModuleList[index].installed = 1;
      // ladda.start();
      this.userService.moduleUninstall({slug: module.slug}).subscribe(res => {
        // ladda.stop();
        if (res.status == 2000) {
          this.getAllModules();
   /*       swal({
            title: module.title === 'Work Orders' ? 'Jobs Disabled' : module.title + ' Disabled',
            text: module.title === 'Work Orders' ? 'Jobs has been successfully disabled' : module.title + ' has been successfully disabled',
            icon: 'success',
            button: 'Done!',
            className: 'swal-btn-center'
          });*/
          document.querySelector('#ModuleInstallModal .text-title').innerHTML = module.title === 'Work Orders' ? 'Jobs Disabled' : module.title + ' Disabled';
          document.querySelector('#ModuleInstallModal .text-des').innerHTML = module.title === 'Work Orders' ? 'Jobs has been successfully disabled' : module.title + ' has been successfully disabled';
          let ___selectedTab = 0;
          const tabs = $('#sort-tabs').find('li');
          tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
              ___selectedTab = i;
            }
          });
          const child = ___selectedTab + 1;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          $('#sort-tabs').find('li').find('a').removeClass('active');
          $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
          (<any>$('#ModuleInstallModal')).modal('show');
          // (<any>$('#EnabledModules')).click();
        } else {
          this.ModuleList[index].installed = 1;
          swal({
            title: 'Uninstall Module',
            text: res.msg,
            icon: 'error',
            dangerMode: true,
            className: 'swal-btn-center'
          });
        }
      });
    }
  }

  currencyIcon(): string {
    if (this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }
}
