import { Component} from '@angular/core';

@Component({
    selector: 'not-found-layout',
    templateUrl: './not-found.component.html',
})
export class NotFoundComponent {


    constructor() {

    }
}