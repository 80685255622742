import {Component, EventEmitter, HostListener, Input, Output, SimpleChanges} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {APPCONFIG} from '../../config';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './app-toggle-switch.component.html',
  styleUrls: ['./app-toggle-switch.component.scss']
})

export class AppToggleSwitchComponent {
  @Input() IsActive = false;
  @Output() Change: any = new EventEmitter<number>();
  constructor(private router: Router) {}

  toggle() {
    this.IsActive = !this.IsActive;
    this.Change.emit(this.IsActive);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // This will log changes when the input property 'IsActive' changes
    console.log('Changes detected:', changes);
    console.log('is active or not' , this.IsActive);
    if (changes['IsActive']) {
      const prevValue = changes['IsActive'].previousValue;
      const currentValue = changes['IsActive'].currentValue;
      console.log(`Previous: ${prevValue}, Current: ${currentValue}`);
    }
  }

}
