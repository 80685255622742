import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {APPCONFIG} from '../../config';
import {TabsService} from '../../_services/tabs.service';
import {AuthService} from '../../_services/auth.service';
import {MediaService} from '../../_services/media.service';
import {CustomHelper} from '../../customhelper';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {Router} from '@angular/router';

declare let swal: any;

@Component({
    selector: 'my-app-sidenav',
    styleUrls: [],
    templateUrl: './sidenav.component.html'
})

export class AppSidenavComponent implements OnInit {
    AppConfig;
    @Input() sideNavScreen;
    @Output() headerClose = new EventEmitter<number>();
    CustomHelper = CustomHelper;

    selectedPage: any;
    selectedTab: number;
    userType: number;

    currentUser = null;

    constructor(private tabsService: TabsService, private authService: AuthService, private router: Router) {
      let THIS = this;
      THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

      if(this.currentUser.verified_vendor == 1 && (this.currentUser.verified_vendor_submitted == 0 && this.currentUser.verified_vendor_approved == 0)){
        window.history.pushState("", "", '/auth/register/vendor/process/' + this.currentUser.team);
        THIS.router.navigate(['/auth/register/vendor/process/' + this.currentUser.team]);
      }

        this.tabsService.getSelectedTab.subscribe(tab => {this.selectedTab = tab;this.currentMenuTab();this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));});
        this.tabsService.getSelectedPage.subscribe(page => {
                this.selectedPage = page;
                this.currentMenuTab();
                this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
            });
        this.authService.FetchSessionUserInfo.subscribe(user => {this.currentUser = user})

      THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
      THIS.userType = THIS.currentUser.user_type;

        $('body').on('click', '.goToDash', function () {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            window.history.pushState("", "", '/' + Session.team + '/dashboard');
            THIS.tabsService.makeFirstTab('dashboard');
        })


    }

    //======================
    // Switch Page
    //======================
    currentTab = 1;
    ngOnInit() {
        this.AppConfig = APPCONFIG;
    }
    closeSide() {
      this.sideNavScreen = false;
      this.headerClose.emit(1);
    }
    switchView(page: string) {
        if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
            let freeAccountData = localStorage.getItem('freeAccountData');
            if (freeAccountData != null) {
                let freeAccount = JSON.parse(freeAccountData);
                if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
                    (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
                    (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
                    (freeAccount['user_count'] >= freeAccount['user_limit'])) {
                    $('.openSubscription').click();
                    return false;
                }
            }
        }
        /*if(this.currentUser.subscription.plan == 'Free' && this.currentUser.subscription.status === 1 && page == 'equistore') {
            (<any>$('#restrictionModuleModal')).modal('show');
            return false;
        }*/
        let THIS = this;
        this.CustomHelper.woRouteDiscard = 0;
        let index = THIS.CustomHelper.ConfRoute.map(function (e) {
            return e.tab;
        }).indexOf(THIS.CustomHelper.ConfCurRoute);
        if (index > -1) {
            // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
        }
        if (this.CustomHelper.woRouteDiscard == 1) {
            swal({
                title: "Are you sure you want to leave?",
                text: "You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.",
                icon: "warning",
                buttons: ['CANCEL', 'LEAVE'],
                dangerMode: true,
            }).then((res) => {
                if (res) {
                    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
                    window.history.pushState("", "", '/' + Session.team + '/' + page);
                    THIS.tabsService.makeFirstTab(page);
                    THIS.CustomHelper.woRouteDiscard = 0;
                    THIS.CustomHelper.ConfRoute.splice(index, 1);
                }
            });
        } else {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            window.history.pushState("", "", '/' + Session.team + '/' + page);
            THIS.tabsService.makeFirstTab(page);
            THIS.CustomHelper.woRouteDiscard = 0;
        }
        this.currentMenuTab();
    }
    currentMenuTab() {
        if (this.selectedPage == 'contact') {
            this.currentTab = 3;
        } else if (this.selectedPage == 'calendar') {
            this.currentTab = 4;
        } else if (this.selectedPage == 'equistore') {
            this.currentTab = 5;
        } else if (this.selectedPage != 'mails' && this.selectedPage != 'mail_send') {
            this.currentTab = 1;
        } else if (this.selectedPage == 'mails' || this.selectedPage == 'mail_send') {
            this.currentTab = 2;
        }
    }
}
