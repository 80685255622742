import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { APPCONFIG } from '../config';


import { HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";
import {Job_typeModel} from "../_models/job_type";

@Injectable()
export class InventoriesService {

    public token: string;

    constructor(private http: HttpClient) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }


    createStore(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/store/room/create?token=`+this.token,param)
            .pipe(map(res => {
                return res;
            }));
    }
    getStoreList(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}inventory/store/room/getAll?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getLastPreId(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}inventory/get/lastPreId?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }


    getGraph(id): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}inventory/graph/`+id+`?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getAllInventory(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/getAll?token=`+this.token,param)
            .pipe(map(res => {
                return res;
            }));
    }
    getSingleInventory(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/get/single?token=`+this.token,param)
            .pipe(map(res => {
                return res;
            }));
    }
    createInventory(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/create?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    createInventoryLot(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/createLot?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    editInventory(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/edit?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    topUp(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/update/topUp?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    removeInventory(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/remove?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    removeInventoryUndo(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/remove/undo?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getInventoryStatus(param): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}inventory/getAllStatus?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    archiveInventory(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/archive?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    archiveInventoryUndo(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}inventory/archive/undo?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
}
