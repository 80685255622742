import {Component} from '@angular/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import {CustomHelper} from '../../customhelper';

@Component({
    selector: 'page-work-orders-board',
    templateUrl: './work-orders-board.component.html'
})
export class WorkOrdersBoardComponent {
    CustomHelper = CustomHelper;



    constructor( private router: Router,private route: ActivatedRoute) {
        this.routeMake();

        setTimeout(function () {
            (<any>$("#todo, #inprogress, #completed")).sortable({
                connectWith: ".connectList",
                update: function( event, ui ) {
                    let todo = (<any>$( "#todo" )).sortable( "toArray" );
                    let inprogress = (<any>$( "#inprogress" )).sortable( "toArray" );
                    let completed = (<any>$( "#completed" )).sortable( "toArray" );
                    (<any>$('.output')).html("ToDo: " + JSON.stringify(todo) + "<br/>" + "In Progress: " + JSON.stringify(inprogress) + "<br/>" + "Completed: " + JSON.stringify(completed));
                }
            }).disableSelection();
        }, 400);
    }

    // =======================
    // Routes & Preview
    // =======================
    ROUTES = '/work-orders-board';
    routeMake(){
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/'+Session.team+this.ROUTES;
        window.history.pushState("","", url);
    }

}
