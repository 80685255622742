import {Component, EventEmitter, Output, TemplateRef} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';

import {WorkOrderService} from '../../../_services/work-orders.service';
import {FacilityService} from '../../../_services/facility.service';
import {UserService} from '../../../_services/user.service';
import {AssetService} from '../../../_services/asset.service';
import {Job_typesService} from '../../../_services/job_types.service';
import {ContactsService} from '../../../_services/contacts.service';
import {MediaService} from '../../../_services/media.service';
import {FcmService} from '../../../_services/fcm.service';

declare let swal: any;
declare let toastr: any;
declare let Ladda: any;
declare let fabric: any;
declare let Pickr: any;

@Component({
  selector: 'inspection-create-recurring',
  templateUrl: './createInspectionRecurring.component.html'
})
export class CreateInspectionRecurringComponent {
  @Output() discard = new EventEmitter<number>();
  @Output() preview = new EventEmitter<number>();

  id = 0;
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
  nextHour = null;

  currentUser;

  constructor(
    public snackBar: MatSnackBar,
    private fcmService: FcmService,
    private workOrderService: WorkOrderService,
    private facilityService: FacilityService,
    private userService: UserService,
    private assetService: AssetService,
    private contactsService: ContactsService,
    private mediaService: MediaService,
    private coolDialogs: NgxCoolDialogsService,
    private job_typesService: Job_typesService) {
    this.getLastWorkOrderId();
    this.getAllFacility();
    this.pty_get();
    this.asset_get();
    this.personnel_get();

    let THIS = this;
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

    let date = new Date();
    let p = 2 * 60 * 60 * 1000;
    this.nextHour = new Date(Math.round(date.getTime() / p) * p);
    this._startTimeConfig.defaultDate = this.nextHour;
    this._startDate = THIS.CustomHelper.formatDateOnlyToDB(new Date());
    this._startTime = THIS.CustomHelper.timeStrFormat(this.nextHour);
    this._startDateConfig.onChange = function (selectedDates, dateStr, instance) {
      THIS._expireDatepreview = 0;
      THIS._expireDateConfig.minDate = new Date(selectedDates);
      setTimeout(function () {
        THIS._expireDatepreview = 1;
      }, 400);
      THIS._startDate = THIS.CustomHelper.formatDateOnlyToDB(new Date(selectedDates));
      THIS.staticDay = THIS.CustomHelper.day[THIS._expireDateConfig.minDate.getDay()];
      THIS.repeatDays.length = 0;
      THIS.repeatDays = [THIS.staticDay];
      THIS.updateChangesDiscard();
    };
    this._startTimeConfig.onChange = function (selectedDates, dateStr, instance) {
      THIS._startTime = dateStr;
      THIS.updateChangesDiscard();
    };
    this._startTimeConfig.onOpen = function (selectedDates, dateStr, instance) {
      if (dateStr == '') {
        THIS._startTime = '12:00 PM';
      }
      THIS.updateChangesDiscard();
    };
    this._expireDateConfig.onChange = function (selectedDates, dateStr, instance) {
      THIS._expireDate = THIS.CustomHelper.formatDateOnlyToDB(new Date(selectedDates));
      THIS.updateChangesDiscard();
    };
    this._expireTimeConfig.onChange = function (selectedDates, dateStr, instance) {
      THIS._expireTime = dateStr;
      THIS.updateChangesDiscard();
    };
    this._expireTimeConfig.onOpen = function (selectedDates, dateStr, instance) {
      if (dateStr == '') {
        THIS._expireTime = '12:00 PM';
      }
      THIS.updateChangesDiscard();
    };
  }


  Facility = [];
  FacilityComp = [];

  getAllFacility() {
    let THIS = this;
    this.facilityService.getFacilitiesList().subscribe(res => {
      this.Facility.length = 0;
      this.FacilityComp.length = 0;
      this.FacilityComp = res.facility;
      $.each(res.facility, function (i, v) {
        v['pp'] = 0;
        THIS.Facility.push(v);
        $.each(v.child, function (j, vC) {
          vC['pp'] = 1;
          THIS.Facility.push(vC);
          $.each(vC.child, function (k, vCC) {
            vCC['pp'] = 2;
            THIS.Facility.push(vCC);
            $.each(vCC.child, function (l, vCCC) {
              vCCC['pp'] = 3;
              THIS.Facility.push(vCCC);
            });
          });
        });
      });
      setTimeout(function () {
        if ($('.reRenderFacility').length > 0) {
          let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
          if (facilityId > 0) {
            THIS.param.facility_id = facilityId.toString();
          }
        }
        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
        (<any>$('.chosen-default-single-fac')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Facility'
        }).on('change', function (e, p) {
          if (e.target.value === '000') {
            let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            let child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#createFacilityModal')).modal('show');
          } else {
            THIS.param.facility_id = e.target.value;
            THIS.updateChangesDiscard();
          }
        });
      }, 100);
    });
  }


  Priority = [];

  pty_get(): void {
    this.Priority.length = 0;
    this.job_typesService.getPrioritiesList().subscribe(res => {
      let THIS = this;
      this.Priority = res;
      setTimeout(function () {
        (<any>$('.chosen-default-single-priority')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Priority'
        }).on('change', function (e, p) {
          THIS.updateChangesDiscard();
          let index = THIS.Priority.map(function (element) {
            return element.id;
          }).indexOf(parseInt(e.target.value));
          let priority = (index > -1) ? THIS.Priority[index] : null;
          if (priority != null) {
            THIS.param.priority = priority.id.toString();
          } else {
            THIS.param.priority = '0';
          }
        });
      }, 100);
    });
  }

  choosePty(priority) {
    let THIS = this;
    if (priority != null) {
      THIS.param.priority = priority.id.toString();
    } else {
      THIS.param.priority = '0';
    }
  }

  Assets = [];
  AssetSelect = [];

  asset_get(): void {
    this.assetService.getAssetList().subscribe(res => {
      let THIS = this;
      this.Assets = res;
      setTimeout(function () {
        if ($('.reRenderAssets').length > 0) {
          let AssetSelectId = parseInt($('.reRenderAssets').attr('data-id'));
          if (AssetSelectId > 0) {
            THIS.AssetSelect.push(AssetSelectId);
            THIS.param.asset_id = THIS.AssetSelect.join(',');
            THIS.updateChangesDiscard();
          }
        }
        (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
        (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
        (<any>$('.chosen-default-multiple-asset')).chosen({
          allow_single_deselect: true,
          placeholder_text_multiple: 'No asset selected'
        }).on('change', function (e, p) {
          if (p.selected === '000') {
            let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            let child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#assetModal')).modal('show');
          } else {
            if (p.selected != undefined) {
              THIS.AssetSelect.push(p.selected);
            } else {
              THIS.AssetSelect.splice(THIS.AssetSelect.indexOf(p.deselected), 1);
            }
            THIS.param.asset_id = THIS.AssetSelect.join(',');
            THIS.updateChangesDiscard();
          }
        });
      }, 100);
    });
  }

  Personnel = {
    org: [],
    person: []
  };
  personnelSelect = [];
  groupSelect = [];

  personnel_get(): void {
    let THIS = this;
    this.contactsService.getAllSupplierTotal().subscribe(res => {
      if (res.status == 2000) {
        THIS.Personnel = res;
      }
      setTimeout(function () {
        if ($('.reRenderPerson').length > 0) {
          let personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
          if (personnel_id > 0) {
            THIS.personnelSelect.push(personnel_id.toString());
            THIS.param.personnel = THIS.personnelSelect.join();
          }
        }
        let pp_val = THIS.personnelSelect;
        (<any>$('.chosen-default-multiple-per')).chosen('destroy');
        (<any>$('.chosen-default-multiple-per')).val(pp_val);
        (<any>$('.chosen-default-multiple-per')).chosen({
          allow_single_deselect: true,
          placeholder_text_multiple: 'No personnel selected'
        }).on('change', function (e, p) {
          if (p.selected != undefined) {
            let v = p.selected;
            if (v === '000') {
              (<any>$('.chosen-default-multiple-per')).val(THIS.personnelSelect);
              (<any>$('.chosen-default-multiple-per')).trigger('chosen:updated');
              let ___selectedTab = 0;
              let tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              let child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#PersonQuickModal')).modal('show');
            } else {
              THIS.personnelSelect.push(v);
              THIS.param.personnel = THIS.personnelSelect.join();
            }
          } else {
            let v = p.deselected;
            if (THIS.personnelSelect.indexOf(v) > -1) {
              THIS.personnelSelect.splice(THIS.personnelSelect.indexOf(v), 1);
            }
            THIS.param.personnel = THIS.personnelSelect.join();
          }
          THIS.updateChangesDiscard();
        });
      }, 100);
    });
  }

  add2Calendar = 0;
  calendarStatus = '_blue';

  addWorkAttach() {
    $('#workAttach').click();
  }

  removeThisAttach(index, id) {
    this.selectedWorkAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (var i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        let eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allwoed',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }


  inlinePrefixId = 0;

  getLastWorkOrderId() {
    this.workOrderService.getLastWorkOrderId().subscribe(res => {
      this.param.prefix = res.prefix;
      this.param.work_order_id = res.work_order_id;
    });
  }

  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],

    ['clean']                                         // remove formatting button
  ];

  confirmDiscard() {
    this.discard.emit(1);
  }

  selectedFiles = [];
  selectedFilesPreview = [];
  selectedWorkAttach = [];

  uploadThisMedia(file, media_type, location, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (location != null) {
      formData.append('lat', location.lat);
      formData.append('lon', location.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  removeThisMedia(id, media_type) {
    let param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  dragFileOverStart() {
    var trigger = $('#uploadDragWork');
    trigger.addClass('onEnter');
  }

  dragFileOverEnd() {
    var trigger = $('#uploadDragWork');
    trigger.removeClass('onEnter');
  }

  _uploadError = 0;
  _locPermit = 0;

  dragFilesDropped(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    if (e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        if (this.selectedFiles.length < 3) {
          let eachFile = e.dataTransfer.files[i];
          this.selectedFiles.push(eachFile);
          if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            if (THIS.currentUser.modules.geo_tagged_photo == 1) {
              navigator.geolocation.getCurrentPosition(function (position) {
                if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                  THIS._locPermit = 1;
                  let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                  THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  THIS._locPermit = -1;
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                }
              }, function (error) {
                swal({
                  title: 'Share Geo Location',
                  text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                  icon: 'warning',
                  buttons: ['Want to share location', 'Don\'t want to share Location'],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                      if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                          swal({
                            title: 'Storage Full',
                            text: 'Your Storage is full. Please delete some files',
                            icon: 'error',
                            dangerMode: true,
                          });
                        } else {
                          swal({
                            title: 'Invalid file format uploaded',
                            text: 'Please upload an image in Jpeg or Png only.',
                            icon: 'error',
                            dangerMode: true,
                          });
                        }
                      } else {
                        THIS.selectedFilesPreview.push(uploaded);
                      }
                    });
                  } else {
                    swal('If you want to share location then change the browser settings and remove the block status');
                  }
                });
              });
            } else {
              THIS._locPermit = -1;
              THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                if (uploaded.status != undefined) {
                  if (uploaded.status == 9000) {
                    swal({
                      title: 'Storage Full',
                      text: 'Your Storage is full. Please delete some files',
                      icon: 'error',
                      dangerMode: true,
                    });
                  } else {
                    swal({
                      title: 'Invalid file format uploaded',
                      text: 'Please upload an image in Jpeg or Png only.',
                      icon: 'error',
                      dangerMode: true,
                    });
                  }
                } else {
                  THIS.selectedFilesPreview.push(uploaded);
                }
              });
            }
          } else {
            this._uploadError = 1;
          }
        }
      }
      // this.uploaderPlate.preview = 1;
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    let accepted = e.target.files;
    for (var i = 0; i < accepted.length; i++) {
      if (this.selectedFiles.length < 3) {
        let eachFile = accepted[i];
        this.selectedFiles.push(eachFile);
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                THIS._locPermit = 1;
                let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              } else {
                THIS._locPermit = -1;
                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              }
            }, function (error) {
              swal({
                title: 'Share Geo Location',
                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                icon: 'warning',
                buttons: ['Want to share location', 'Don\'t want to share Location'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  swal('If you want to share location then change the browser settings and remove the block status');
                }
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please delete some files',
                    icon: 'error',
                    dangerMode: true,
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
              }
            });
          }
        } else {
          this._uploadError = 1;
        }
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
    // this.uploaderPlate.preview = 1;
  }

  removeThisImage(index, id) {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesPreview.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  addWorkMedia() {
    $('#workMediaInput').click();
  }

  configureWarningModal(template: TemplateRef<any>) {
    // this.modalRef = this.modalService.show(template, Object.assign({}, {}, {class: 'gray modal-md'}));
  }

  repeatValue = '1 Day';
  repeatCount = 1;
  repeatItem = '';
  staticDay = '';
  repeatDays = [];

  updateRepeatDays(day) {
    if (day != this.staticDay) {
      let index = this.repeatDays.indexOf(day);
      if (index > -1) {
        this.repeatDays.splice(index, 1);
      } else {
        this.repeatDays.push(day);
      }
    }
  }

  echoRepeatDays(repeatDays) {
    let days = '';
    $.each(repeatDays, function (i, v) {
      if (i == '0') {
        days += v;
      } else {
        days += ', ' + v;
      }
    });
    days = days.replace(/,([^,]*)$/, ' and $1');
    return days;
  }

  _startDate = '';
  _startTime = '';
  _expireDate = '';
  _expireTime = '';
  _expireDatepreview = 1;
  _startDateConfig = {
    timePicker: false,
    minDate: new Date(),
    defaultDate: new Date(),
    dateFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    }
  };
  _startTimeConfig = {
    enableTime: true,
    noCalendar: true,
    defaultDate: null,
    dateFormat: this.CustomHelper.timeFormatFlat('format'),//"H:i k",
    time_24hr: this.CustomHelper.timeFormatFlat(''),
    onChange: function (selectedDates, dateStr, instance) {
    },
    onOpen: function (selectedDates, dateStr, instance) {
    },
  };
  _expireDateConfig = {
    timePicker: false,
    minDate: new Date(),
    defaultDate: null,
    dateFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };
  _expireTimeConfig = {
    enableTime: true,
    noCalendar: true,
    defaultDate: null,
    dateFormat: this.CustomHelper.timeFormatFlat('format'),//"H:i k",
    time_24hr: this.CustomHelper.timeFormatFlat(''),
    onChange: function (selectedDates, dateStr, instance) {
    },
    onOpen: function (selectedDates, dateStr, instance) {
    },
  };
  expireView = 0;

  noExpire() {
    this._expireDate = '';
    this._expireTime = '';
    this._expireDateConfig.defaultDate = null;
    this._expireTimeConfig.defaultDate = null;
    this.param.expire = '';
    this.expireView = 1;
    let THIS = this;
    setTimeout(function () {
      THIS.expireView = 0;
    }, 100);
  }


  param:any = {
    module: 2,
    prefix: 'w',
    work_order_id: '',
    subject: '',
    description: '',
    media_files: '',
    attach_files: '',
    facility_id: '',
    contact_id: 0,
    asset_id: '',
    job_types_id: 0,
    start: '',
    expire: '',
    add_to_calendar: false,
    calendar_color: '',
    priority: '0',
    status_code: 1,
    personnel: '',
    groups: '',
    personnel_group: '',
    personnel_id: '4',
    repeat: '',
    repeat_days: '',
    attachInventories: [],
    taskType: 1,
    tasks: []
  };


  saveWorkOrderSubmit() {
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      let freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        let freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    let THIS = this;
    let ldd = document.getElementById('SaveThisLadda');
    let ladda = Ladda.create(ldd);
    let mediaFiles = '';
    $.each(THIS.selectedFilesPreview, function (i, v) {
      if (i == 0) {
        mediaFiles += v.id;
      } else {
        mediaFiles += ',' + v.id;
      }
    });
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    let valid = this.validationThisForm();
    this.param.media_files = mediaFiles;
    this.param.attach_files = mediaAttach;
    this.param.repeat = this.repeatCount + ' ' + this.repeatItem;
    this.param.repeat_days = this.repeatDays.join();

    let woTaskList = $('#woTaskList').find('._eachTask');
    this.param['taskType'] = this.taskType;
    this.param.tasks.length = [];
    if (this.taskList.length > 0 ){
      this.taskList.forEach((v : any) => {
        if (v.title != null && v.title != ''){
          this.param.tasks.push(v);
        }
      });
    }
    this.param.add_to_calendar = this.add2Calendar == 0 ? false : true;
    this.param.calendar_color = this.calendarStatus;

    if (valid == 1) {

      let nextTime = null;
      if (this.repeatItem == 'Day') {
        nextTime = new Date(this.param.start);
        nextTime.setDate(nextTime.getDate() + 1);
      } else if (this.repeatItem == 'Fortnight') {
        nextTime = new Date(this.param.start);
        nextTime.setDate(nextTime.getDate() + 14);
      } else if (this.repeatItem == 'Month') {
        nextTime = new Date(this.param.start);
        nextTime.setMonth(nextTime.getMonth() + 1);
      }  else if (this.repeatItem == 'Quarter') {
        nextTime = new Date(this.param.start);
        nextTime.setMonth(nextTime.getMonth() + 3);
      }  else if (this.repeatItem == 'Biannual') {
        nextTime = new Date(this.param.start);
        nextTime.setMonth(nextTime.getMonth() + 6);
      } else if (this.repeatItem == 'Year') {
        nextTime = new Date(this.param.start);
        nextTime.setFullYear(nextTime.getFullYear() + 1);
      } else if (this.repeatItem == 'Week') {
        let start = new Date(this.param.start);
        if (nextTime == null) {
          let Day1 = new Date(start);
          Day1.setDate(Day1.getDate() + 1);
          let Day1Name = this.CustomHelper.day[Day1.getDay()];
          if (this.repeatDays.indexOf(Day1Name) > -1) {
            nextTime = Day1;
          }
        }
        if (nextTime == null) {
          let Day2 = new Date(start);
          Day2.setDate(Day2.getDate() + 2);
          let Day2Name = this.CustomHelper.day[Day2.getDay()];
          if (this.repeatDays.indexOf(Day2Name) > -1) {
            nextTime = Day2;
          }
        }
        if (nextTime == null) {
          let Day3 = new Date(start);
          Day3.setDate(Day3.getDate() + 3);
          let Day3Name = this.CustomHelper.day[Day3.getDay()];
          if (this.repeatDays.indexOf(Day3Name) > -1) {
            nextTime = Day3;
          }
        }
        if (nextTime == null) {
          let Day4 = new Date(start);
          Day4.setDate(Day4.getDate() + 4);
          let Day4Name = this.CustomHelper.day[Day4.getDay()];
          if (this.repeatDays.indexOf(Day4Name) > -1) {
            nextTime = Day4;
          }
        }
        if (nextTime == null) {
          let Day5 = new Date(start);
          Day5.setDate(Day5.getDate() + 5);
          let Day5Name = this.CustomHelper.day[Day5.getDay()];
          if (this.repeatDays.indexOf(Day5Name) > -1) {
            nextTime = Day5;
          }
        }
        if (nextTime == null) {
          let Day6 = new Date(start);
          Day6.setDate(Day6.getDate() + 6);
          let Day6Name = this.CustomHelper.day[Day6.getDay()];
          if (this.repeatDays.indexOf(Day6Name) > -1) {
            nextTime = Day6;
          }
        }
        if (nextTime == null) {
          let Day7 = new Date(start);
          Day7.setDate(Day7.getDate() + 7);
          let Day7Name = this.CustomHelper.day[Day7.getDay()];
          if (this.repeatDays.indexOf(Day7Name) > -1) {
            nextTime = Day7;
          }
        }
      }

      if (nextTime > new Date(this.param.expire)) {
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
          if ($(v).find('a.active').length > 0) {
            ___selectedTab = i;
          }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#FormValidation')).modal('show');
        // THIS.coolDialogs.confirm('This work order is set to expire before the next recurrence schedule. Are you sure?', {
        //   theme: 'material',
        //   okButtonText: 'Yes',
        //   cancelButtonText: 'No',
        //   color: '#ff3f60',
        //   title: 'Form Validation - Recuurence'
        // }).subscribe(res => {
        //   if (res) {
        //     console.log(1);
        //     ladda.start();
        //     this.workOrderService.createRecurringWorkOrder(this.param).subscribe(res => {
        //       ladda.stop();
        //       this.CustomHelper.woRouteDiscard = 0;
        //       this.preview.emit(res);
        //       toastr.success('This new recurring job successfully created', 'New Recurring Job', this.toastrOption);
        //       // Pusher emit
        //       $.ajax({
        //         url: THIS.APPCONFIG.apiAuth + 'work-orders-recurring-cron',
        //         type: 'get',
        //         data: {},
        //         success: function (res) {
        //         }
        //       });
        //     });
        //   }
        // });
      } else {
        console.log(2);
        ladda.start();
        this.workOrderService.createRecurringWorkOrder(this.param).subscribe(res => {
          ladda.stop();
          this.CustomHelper.woRouteDiscard = 0;
          this.preview.emit(res);
          toastr.success('This new recurring job successfully created', 'New Recurring Job', this.toastrOption);
          // Pusher emit
          $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'work-orders-recurring-cron',
            type: 'get',
            data: {},
            success: function (res) {
            }
          });
        });
      }

    }
  }
  CreateInsReucrr(){
    let THIS = this;
    let ldd = document.getElementById('SaveThisLadda');
    let ladda = Ladda.create(ldd);
    ladda.start();
    this.workOrderService.createRecurringWorkOrder(this.param).subscribe(res => {
      (<any>$('#FormValidation')).modal('hide');
      ladda.stop();
      this.CustomHelper.woRouteDiscard = 0;
      this.preview.emit(res);
      toastr.success('This new recurring job successfully created', 'New Recurring Job', this.toastrOption);
      // Pusher emit
      $.ajax({
        url: THIS.APPCONFIG.apiAuth + 'work-orders-recurring-cron',
        type: 'get',
        data: {},
        success: function (res) {
        }
      });
    });
  }
  clearValidation(e) {
    let trigger = $(e.target);
    let target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  validForm = 0;

  validationThisForm() {
    $('.show-facility-error').html("");
    $('.show-subject-error').html('');
    $('.show-date-error').html('');
    $('.show-time-error').html('');
    $('.show-period-error').html('');
    let valid = 1;
    let form = $('#workCreateForm');
    if (this.param.facility_id == '') {
      valid = 0;
      $('.show-facility-error').html("Please select a facility");
      return valid;
    }
    if (this.param.subject == '') {
      valid = 0;
      $('.show-subject-error').html("Please type a subject for this recurring inspection");
      return valid;
    }
    if (this._startDate == '') {
      valid = 0;
      // swal({
      //   text: 'Please select a start date',
      //   className: 'swal-btn-center'
      // });
      $('.show-date-error').html("Please select a start date");
      return valid;
    }
    if (this._startTime == '') {
      valid = 0;
      // swal({
      //   text: 'Please select a start time',
      //   className: 'swal-btn-center'
      // });
      $('.show-time-error').html("Please select a start time");
      return valid;
    }
    if (this.repeatItem == '') {
      valid = 0;
      // swal({
      //   text: 'Please select a recurring period',
      //   className: 'swal-btn-center'
      // });
      $('.show-period-error').html("Please select a start period");
      return valid;
    }
    if (this.repeatItem == '') {
      valid = 0;
      swal({
        text: 'Please select a recurring period',
        className: 'swal-btn-center'
      });
      return valid;
    }
    if (this._expireDate != '') {
      if (this._expireTime == '') {
        valid = 0;
        swal({
          text: 'Please select an expire time',
          className: 'swal-btn-center'
        });
        return valid;
      }
    }
    if (this._expireTime != '') {
      if (this._expireDate == '') {
        valid = 0;
        swal({
          text: 'Please select an expire date',
          className: 'swal-btn-center'
        });
        return valid;
      }
    }
    let __start_New = new Date(this._startDate + ' ' + this._startTime);
    this.param.start = this.CustomHelper.formatDateTimeToDB(__start_New);
    if (this._expireDate != '' && this._expireTime != '') {
      let __expire_New = new Date(this._expireDate + ' ' + this._expireTime);
      this.param.expire = this.CustomHelper.formatDateTimeToDB(__expire_New);
      if (Date.parse(this.param.start) > Date.parse(this.param.expire)) {
        swal({
          text: 'Recurrance start date time can\'t be greater than expire time',
          className: 'swal-btn-center'
        });
        valid = 0;
      }
    }


    this.validForm = valid;
    return valid;
  }

  updateChangesDiscard() {
    this.CustomHelper.woRouteDiscard = 1;
  }

  //====================
  // ___panel_expand
  //====================
  _panel_expand = 0;
  _panel_clear = 0;
  _panel_clear_now = 0;
  error = {
    facility: 0,
    personnel: 0,
    contact: 0,
    jobType: 0,
    priority: 0,
    date: 0,
  };

  taskType = 2; // 1.Overall Rating ON  2.Overall Rating Off
  taskList = [{title: '', person: '', personDetails: null}];
  selectTsk = null;
  selectTskIndex = -1;

  removeTsk(index) {
    this.taskList.splice(index, 1);
  }

  selectTskNow(index) {
    this.selectTskIndex = index;
    this.selectTsk = this.taskList[index];
  }

  assignTskHim(person) {
    let index = this.selectTskIndex;
    this.taskList[index].person = person.id;
    this.taskList[index].personDetails = person;
    this.selectTsk = this.taskList[index];

    /*if (this.param.personnel_id.indexOf(person.id) == -1) {
        this.param.personnel_id.push(person.id);
        (<any>$('.chosen-default-multiple-per')).val(this.param.personnel_id);
        (<any>$('.chosen-default-multiple-per')).trigger("chosen:updated");
    }*/
  }

  changeTaskRating() {
    this.taskType == 1 ? this.taskType = 2 : this.taskType = 1;
  }

  taskCreateOnF() {
    this.taskList.push({title: '', person: '', personDetails: null});
    setTimeout(function () {
      let objDiv: any = document.getElementById('_tsk_list_scroll');
      if (objDiv !== null) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }, 500);
    this.updateChangesDiscard();
  }

  rightSecPreview = 0;


  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_fac_' + Session.team, function (v) {
        THIS.getAllFacility();
    });

    this.socket.on('_rfr_team_data_asset_' + Session.team, function (v) {
        THIS.asset_get();
    });

    this.socket.on('_rfr_team_data_jobType_' + Session.team, function (v) {
        THIS.jt_get();
    });*/

    $('.reRenderFacility').on('click', function () {
      THIS.getAllFacility();
    });
    $('.reRenderAssets').on('click', function () {
      THIS.asset_get();
    });

    $('.reRenderPerson').on('click', function () {
      THIS.personnel_get();
    });
  }

  ngOnDestroy() {
  }


  UID_IMG = 0;
  // =============================
  // Photo Editor
  // =============================
  CANVAS = null;
  SHAPE = 'brush';
  IMAGE_PATH = '';
  CanvasTarget = 0;

  discardEditor() {
    let THIS = this;
    let modal = (<any>$('#photoEditorModal'));
    if (THIS.CANVAS != null) {
      THIS.CANVAS.dispose();
    }
    modal.modal('hide');

  }

  editImgCallUnique = 0;
  EditCurrentCanvas = null;
  CurrentEditPhoto = null;

  openPhotoEditor(preview) {
    let THIS = this;
    THIS.CurrentEditPhoto = preview;
    THIS.CanvasTarget = 0;
    let modal = (<any>$('#photoEditorModal'));
    let canvasW = window.innerWidth - 20;
    let canvasH = window.innerHeight - 130;
    $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
    if (THIS.CANVAS != null) {
      this.CANVAS.clear();
    }
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    let param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

    if (THIS.editImgCallUnique == 0) {
      THIS.editImgCallUnique = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          THIS.editImgCallUnique = 0;
          if (res.status === 2000) {
            THIS.EditCurrentCanvas = res.data;
            let canvasW = res.data.width;
            let canvasH = res.data.height;
            $('#canvas').attr('width', canvasW + 'px');
            $('#canvas').attr('height', canvasH + 'px');
            setTimeout(function () {
              // if(THIS.CANVAS != null){
              //     THIS.CANVAS.dispose();
              // }

              THIS.CANVAS = new fabric.Canvas('canvas');
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.on('object:selected', function (e) {
                THIS.CANVAS.isDrawingMode = false;
                THIS.SHAPE = '';
              });
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.isDrawingMode = true;
              THIS.CANVAS.freeDrawingBrush.width = 5;

              let startY = 0,
                startX = 0;
              THIS.CANVAS.on('mouse:down', function (option) {
                startY = option.e.offsetY;
                startX = option.e.offsetX;
              });
              THIS.CANVAS.on('mouse:up', function (option) {
                let selected = THIS.CANVAS.getActiveObjects();
                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                  if (THIS.SHAPE === 'circle') {
                    let e = option.e;
                    if (e.offsetX - startX > 5) {
                      let circle = new fabric.Circle({
                        top: startY,
                        left: startX,
                        radius: (e.offsetX - startX) / 2,
                        stroke: THIS.CANVAS.freeDrawingBrush.color,
                        strokeWidth: 5,
                        fill: ''
                      });
                      THIS.CANVAS.add(circle);
                    }
                  }
                }
              });
              THIS.CustomHelper.tooltipRender();

              THIS.IMAGE_PATH = preview.file_path;
              let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
              $('.canvas-container').css({
                backgroundImage: 'url(\'' + src + '\')'
              });
              THIS.CANVAS.renderAll();
              setTimeout(function () {
                THIS.CanvasTarget = 1;
              }, 1000);
            }, 1000);
          }
        }
      });


      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        modal.modal('hide');
        history.go(1);
      };

      if ($('.color-picker-editor').length > 0) {
        Pickr.create({
          el: '.color-picker-editor',
          theme: 'nano', // or 'monolith', or 'nano'
          defaultRepresentation: 'HEX',
          useAsButton: true,
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: true,
              input: true,
              save: true
            }
          }
        }).on('change', (color, instance) => {
          $('.color-picker-editor').css('color', color.toHEXA().toString());
          THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
          THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
          });
        });
      }
      $('.color-picker-editor').css('color', '#ff3773');
    }
  }

  // =============================
  // Photo Editor Actions
  // =============================
  SketchEnable() {
    this.CANVAS.isDrawingMode = !this.CANVAS.isDrawingMode;
    if (this.CANVAS.isDrawingMode === true) {
      this.SHAPE = 'brush';
    }
  }

  eraser() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    let obj = THIS.CANVAS.getObjects();
    for (let i = 0; i < obj.length; i++) {
      $.each(obj, function (j, v) {
        if (parseInt(j) > 0) {
          THIS.CANVAS.remove(v);
        }
      });
    }
    THIS.UID_IMG = Date.now();
    let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
    $('.canvas-container').css({
      backgroundImage: 'url(\'' + src + '\')'
    });
  }

  eraserSelected() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
      THIS.CANVAS.remove(obj);
    });
  }

  drawCircle() {
    let THIS = this;
    this.CANVAS.isDrawingMode = false;
    this.SHAPE = 'circle';
    let circle = new fabric.Circle({
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      radius: 50,
      stroke: THIS.CANVAS.freeDrawingBrush.color,
      strokeWidth: 5,
      fill: ''
    });
    THIS.CANVAS.add(circle);
  }

  UpdatePhoto(iid) {
    let THIS = this;
    let ldd = document.getElementById(iid);
    let ladda = Ladda.create(ldd);
    ladda.start();
    THIS.CANVAS.discardActiveObject();
    THIS.CANVAS.requestRenderAll();
    setTimeout(function () {
      let modal = (<any>$('#photoEditorModal'));
      let canvas = (<any>document.getElementById('canvas'));
      let imgData = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      imgData = imgData.replace('data:image/png;base64,', '');
      let param = {
        imgData: imgData,
        media_type: 1,
        file_path: THIS.IMAGE_PATH
      };
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          ladda.stop();
          modal.modal('hide');
          THIS.UID_IMG = Date.now();
        }
      });
    }, 1000);
  }

}
