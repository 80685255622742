import {Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {WorkOrderService} from '../../../_services/work-orders.service';
import {TabsService} from '../../../_services/tabs.service';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';
import {AssetService} from '../../../_services/asset.service';
import {Job_typesService} from '../../../_services/job_types.service';
import {UserService} from '../../../_services/user.service';
import {ContactsService} from '../../../_services/contacts.service';
import {FcmService} from '../../../_services/fcm.service';
import {MatSnackBar} from '@angular/material';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';
import {MatTooltipModule} from '@angular/material';

declare let swal: any;
declare let toastr: any;
declare let Ladda: any;
declare let fabric: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;
declare let Pickr: any;

@Component({
  selector: 'inspection-recurring-single-preview',
  templateUrl: './singlePreviewInspectionRecurring.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SinglePreviewInspectionRecurringComponent {
  @Input() workId: any;
  @Input() editOptionForm: any;
  @Output() discard = new EventEmitter<number>();
  @Output() statusChange = new EventEmitter<any>();
  @Output() editThis = new EventEmitter<any>();
  @Output() preview = new EventEmitter<any>();
  @Output() previewQuick = new EventEmitter<any>();
  @Output() archiveThis = new EventEmitter<any>();
  @Output() removeThis = new EventEmitter<any>();
  workDetails;
  workDetailsChange = 0;
  currentUser;
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  _albums = [];
  ROUTES = '/inspections-recurring';
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  editThisWorkOrder() {
    this.editThis.emit(this.workDetails.id);
  }

  archiveInsThiswork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#ArchiveModal')).modal('show');
  }

  archiveThisWorkOrder() {
    let ldd = document.getElementById('archiveLoader');
    let ladda = Ladda.create(ldd);
    ladda.start();
    this.archiveThis.emit(this.workDetails.id);
    setTimeout(() => {
      ladda.stop();
    }, 100);
    toastr.success('This Recurring Inspection has been Archived successfully', 'Recurring Inspection Archive', this.toastrOption);
  }


  removeThisWorkOrder() {
    let ldd = document.getElementById('removeWorkOrderBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();

    this.removeThis.emit(this.workDetails.id);
    setTimeout(() => {
      ladda.stop();
      this.discardNewInvoice();
    }, 500);
    toastr.success('This Recurring Inspection has been deleted successfully', 'Recurring Inspection Delete', this.toastrOption);
  }

  deleteThisWorkOrder(type: any = null) {
    if (type === 1) {
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#deleteWorkOrderModal')).modal('show');
    } else {
      (<any>$('#deleteWorkOrderModal')).modal('hide');
    }
  }

  constructor(private workOrderService: WorkOrderService,
              private fcmService: FcmService,
              private job_typesService: Job_typesService,
              private userService: UserService,
              private tabsService: TabsService,
              private contactsService: ContactsService,
              public snackBar: MatSnackBar,
              private facilityService: FacilityService,
              private mediaService: MediaService,
              private assetService: AssetService,
              public tooltip: MatTooltipModule) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    let THIS = this;
    this._startDateConfig.onChange = function (selectedDates, dateStr, instance) {
      THIS._expireDatepreview = 0;
      THIS._expireDateConfig.minDate = new Date(selectedDates);
      setTimeout(function () {
        THIS._expireDatepreview = 1;
      }, 400);
      THIS._startDate = THIS.CustomHelper.formatDateOnlyToDB(new Date(selectedDates));
      THIS.staticDay = THIS.CustomHelper.day[THIS._expireDateConfig.minDate.getDay()];
      THIS.repeatDays.length = 0;
      THIS.repeatDays = [THIS.staticDay];
      // THIS.updateChangesDiscard();

    };
    this._startTimeConfig.onChange = function (selectedDates, dateStr, instance) {
      THIS._startTime = dateStr;
      // THIS.updateChangesDiscard();
    };
    this._startTimeConfig.onOpen = function (selectedDates, dateStr, instance) {
      if (dateStr == '') {
        THIS._startTime = '12:00 PM';
      }
      // THIS.updateChangesDiscard();
    };
    this._expireDateConfig.onChange = function (selectedDates, dateStr, instance) {
      THIS._expireDate = THIS.CustomHelper.formatDateOnlyToDB(new Date(selectedDates));
      // THIS.updateChangesDiscard();
    };
    this._expireTimeConfig.onChange = function (selectedDates, dateStr, instance) {
      THIS._expireTime = dateStr;
      // THIS.updateChangesDiscard();
    };
    this._expireTimeConfig.onOpen = function (selectedDates, dateStr, instance) {
      if (dateStr == '') {
        THIS._expireTime = '12:00 PM';
      }
      // THIS.updateChangesDiscard();
    };

    $('body').off().on('click', '.pswp__button--edit', function () {
      if (THIS.Gallery !== null) {
        THIS.Gallery.close();
      }
      THIS.Preview = JSON.parse($('#EditImageData').attr('data-type'));
      THIS.openPhotoEditor(THIS.Preview);
    });
  }

  Facility = [];
  FacilityComp = [];

  getAllFacility() {
    let THIS = this;
    this.facilityService.getFacilitiesList().subscribe(res => {
      this.Facility.length = 0;
      this.FacilityComp.length = 0;
      this.FacilityComp = res.facility;
      $.each(res.facility, function (i, v) {
        v['pp'] = 0;
        THIS.Facility.push(v);
        $.each(v.child, function (j, vC) {
          vC['pp'] = 1;
          THIS.Facility.push(vC);
          $.each(vC.child, function (k, vCC) {
            vCC['pp'] = 2;
            THIS.Facility.push(vCC);
            $.each(vCC.child, function (l, vCCC) {
              vCCC['pp'] = 3;
              THIS.Facility.push(vCCC);
            });
          });
        });
      });
      setTimeout(function () {
        if ($('.reRenderFacility').length > 0) {
          let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
          if (facilityId > 0) {
            THIS.param.facility_id = facilityId.toString();
          }
        }
        (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Facility'
        }).on('change', function (e, p) {
          if (e.target.value === '000') {
            let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            let child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#createFacilityModal')).modal('show');
          } else {
            THIS.param.facility_id = e.target.value;
            THIS.updateChangesDiscard();
          }
        });
      }, 100);
    });
  }

  JobType = [];

  jt_get(): void {
    this.JobType.length = 0;
    this.job_typesService.getJobTypeList().subscribe(res => {
      let THIS = this;
      this.JobType = res;
      setTimeout(function () {
        if ($('.reRenderJobType').length > 0) {
          let job_types_id = parseInt($('.reRenderJobType').attr('data-id'));
          if (job_types_id > 0) {
            THIS.param.job_types_id = job_types_id;
          }
        }
        (<any>$('.chosen-default-single-jti')).chosen('destroy');
        (<any>$('.chosen-default-single-jti')).val(THIS.param.job_types_id);
        (<any>$('.chosen-default-single-jti')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Job Type'
        }).on('change', function (e, p) {
          if (e.target.value === '000') {
            let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            let child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#JobTypesModal')).modal('show');
          } else {
            THIS.param.job_types_id = e.target.value;
            THIS.updateChangesDiscard();
          }
        });
      }, 100);
    });
  }

  Priority = [];

  pty_get(): void {
    this.Priority.length = 0;
    this.job_typesService.getPrioritiesList().subscribe(res => {
      let THIS = this;
      this.Priority = res;
      setTimeout(function () {
        (<any>$('.chosen-default-single-priority')).val(THIS.param.priority);
        (<any>$('.chosen-default-single-priority')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Priority'
        }).on('change', function (e, p) {
          THIS.updateChangesDiscard();
          let index = THIS.Priority.map(function (element) {
            return element.id;
          }).indexOf(parseInt(e.target.value));
          let priority = (index > -1) ? THIS.Priority[index] : null;
          if (priority != null) {
            THIS.param.priority = priority.id.toString();
          } else {
            THIS.param.priority = '0';
          }
        });
      }, 100);
    });
  }

  choosePty(priority) {
    let THIS = this;
    if (priority != null) {
      THIS.param.priority = priority.id.toString();
    } else {
      THIS.param.priority = '0';
    }
  }

  Assets = [];
  AssetSelect = [];

  asset_get(): void {
    this.assetService.getAssetList().subscribe(res => {
      let THIS = this;
      this.Assets = res;
      setTimeout(function () {
        if ($('.reRenderAssets').length > 0) {
          let AssetSelectId = parseInt($('.reRenderAssets').attr('data-id'));
          if (AssetSelectId > 0) {
            THIS.AssetSelect.push(AssetSelectId);
            THIS.param.asset_id = THIS.AssetSelect.join(',');
            THIS.updateChangesDiscard();
          }
        }
        (<any>$('.chosen-default-multiple-asset')).chosen('destroy');
        (<any>$('.chosen-default-multiple-asset')).val(THIS.AssetSelect);
        (<any>$('.chosen-default-multiple-asset')).chosen({
          allow_single_deselect: true,
          placeholder_text_multiple: 'No asset selected'
        }).on('change', function (e, p) {
          if (p.selected === '000') {
            let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            let child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#assetModal')).modal('show');
          } else {
            if (p.selected != undefined) {
              THIS.AssetSelect.push(p.selected);
            } else {
              let index = THIS.AssetSelect.indexOf(p.deselected);
              if (index > -1) {
                THIS.AssetSelect.splice(index, 1);
              }
              let indexp = THIS.AssetSelect.indexOf(parseInt(p.deselected));
              if (indexp > -1) {
                THIS.AssetSelect.splice(indexp, 1);
              }
            }
            THIS.param.asset_id = THIS.AssetSelect.join(',');
            THIS.updateChangesDiscard();
          }
        });
      }, 100);
    });
  }

  Personnel = {
    org: [],
    person: []
  };
  personnelSelect = [];
  groupSelect = [];

  personnel_get(): void {
    let THIS = this;
    this.contactsService.getAllSupplierTotal().subscribe(res => {
      if (res.status == 2000) {
        THIS.Personnel = res;
      }
      setTimeout(function () {
        if ($('.reRenderPerson').length > 0) {
          let personnel_id = parseInt($('.reRenderPerson').attr('data-id'));
          if (personnel_id > 0) {
            THIS.personnelSelect.push(personnel_id.toString());
            THIS.param.personnel = THIS.personnelSelect.join();
          }
        }
        let pp_val = THIS.personnelSelect;
        (<any>$('.chosen-default-multiple-per')).chosen('destroy');
        (<any>$('.chosen-default-multiple-per')).val(pp_val);
        (<any>$('.chosen-default-multiple-per')).chosen({
          allow_single_deselect: true,
          placeholder_text_multiple: 'No personnel selected'
        }).on('change', function (e, p) {
          if (p.selected != undefined) {
            let v = p.selected;
            if (v === '000') {
              let ___selectedTab = 0;
              let tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              let child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#PersonQuickModal')).modal('show');
            } else {
              THIS.personnelSelect.push(v);
              THIS.param.personnel = THIS.personnelSelect.join();
            }
          } else {
            let v = p.deselected;
            THIS.personnelSelect.splice(THIS.personnelSelect.indexOf(v), 1);
            THIS.param.personnel = THIS.personnelSelect.join();
          }

          THIS.updateChangesDiscard();
        });
      }, 100);
    });
  }

  add2Calendar = 0;
  calendarStatus = '_blue';


  repeatValue = '1 Day';
  repeatCount = 1;
  repeatItem = '';
  staticDay = '';
  repeatDays = [];

  updateRepeatDays(day) {
    if (day != this.staticDay) {
      let index = this.repeatDays.indexOf(day);
      if (index > -1) {
        this.repeatDays.splice(index, 1);
      } else {
        this.repeatDays.push(day);
      }
    }
  }

  echoRepeatDays(repeatDays) {
    let days = '';
    $.each(repeatDays, function (i, v) {
      if (i == '0') {
        days += v;
      } else {
        days += ', ' + v;
      }
    });
    days = days.replace(/,([^,]*)$/, ' and $1');
    return days;
  }

  isRecurExpire = 0;

  __RecurPreview = 0;
  _startDate = '';
  _startTime = '';
  _expireDate = '';
  _expireTime = '';
  _expireDatepreview = 1;
  _startDateConfig = {
    timePicker: false,
    minDate: new Date(),
    defaultDate: new Date(),
    dateFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };
  _startTimeConfig = {
    enableTime: true,
    noCalendar: true,
    defaultDate: new Date(),
    dateFormat: this.CustomHelper.timeFormatFlat('format'),//"H:i k",
    time_24hr: this.CustomHelper.timeFormatFlat(''),
    onChange: function (selectedDates, dateStr, instance) {
    },
    onOpen: function (selectedDates, dateStr, instance) {
    },
  };
  _expireDateConfig = {
    timePicker: false,
    minDate: new Date(),
    defaultDate: new Date(),
    dateFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };
  _expireTimeConfig = {
    enableTime: true,
    noCalendar: true,
    defaultDate: new Date(),
    dateFormat: this.CustomHelper.timeFormatFlat('format'),//"H:i k",
    time_24hr: this.CustomHelper.timeFormatFlat(''),
    onChange: function (selectedDates, dateStr, instance) {
    },
    onOpen: function (selectedDates, dateStr, instance) {
    },
  };
  expireView = 0;

  noExpire() {
    this._expireDate = '';
    this._expireTime = '';
    this._expireDateConfig.defaultDate = null;
    this._expireTimeConfig.defaultDate = null;
    this.param.expire = '';
    this.expireView = 1;
    let THIS = this;
    setTimeout(function () {
      THIS.expireView = 0;
    }, 100);
  }


  id = 0;
  param:any = {
    id: 0,
    module: 2,
    prefix: 'w',
    work_order_id: '',
    subject: '',
    description: '',
    media_files: '',
    attach_files: '',
    facility_id: '',
    contact_id: 0,
    asset_id: '',
    job_types_id: 1,
    expire: '',
    start: '',
    add_to_calendar: false,
    calendar_color: '',
    priority: '0',
    personnel: '',
    groups: '',
    personnel_id: '4',
    personnel_group: '',
    repeat: '',
    repeat_days: '',
    task_type: 1,
    past_recurrence: 0, // 0 = Remove All, 1 = all, 2 = only future
    tasks: []
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editOptionForm != undefined) {
      if (this.editOptionForm == 1) {
        this.inlinePrefixId = 1;
      } else {
        this.inlinePrefixId = 0;
      }
    }
    if (changes.workId.currentValue != undefined) {
      let workId = changes.workId.currentValue;
      this.getSingleRecurringWorkOrder(workId);
    }
  }

  getSingleRecurringWorkOrder(workId) {
    this.workOrderService.getRecurringWorkOrderSingle(workId).subscribe(res => {
      if (res.status == 2000) {
        let work = res.data;
        this.workDetails = res.data;
        this._albums.length = 0;
        if (this.workDetails.media_files !== undefined) {
          for (let i = 0; i < this.workDetails.media_files.length; i++) {
            const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + this.workDetails.media_files[i].file_path;
            const caption = '';
            const thumb = '';
            const album = {
              src: src,
              caption: caption,
              thumb: thumb,
              w: this.workDetails.media_files[i].width !== undefined ? this.workDetails.media_files[i].width : 1500,
              h: this.workDetails.media_files[i].height !== undefined ? this.workDetails.media_files[i].height : 1500
            };
            this._albums.push(album);
          }
        }
        this.id = work.id;
        this.param.id = work.id;
        this.param.subject = work.subject;
        this.param.prefix = work.prefix;
        this.param.work_order_id = work.work_order_id;
        this.param.description = work.description;
        this.param.facility_id = work.facility.id.toString();
        this.param.contact_id = work.contact != null ? work.contact_id.toString() : '';
        this.param.personnel_id = work.personnel_id != '' ? work.personnel_id.toString() : '4';
        this.workDetails.dates.edited_date = CustomHelper.formatDateTime(this.workDetails.dates.edited_date);
        this.workDetails.facStr = this.workDetails.facStr.reverse();

        this.param.start = this.CustomHelper.formatDateToDB(new Date(work.dates.start + ' UTC'));
        this.param.expire = work.dates.expire != null ? this.CustomHelper.formatDateToDB(new Date(work.dates.expire + ' UTC')) : '';
        if (work.dates.expire != undefined && work.dates.expire != null) {
          if (new Date() > new Date(work.dates.expire)) {
            this.isRecurExpire = 1;
          } else if (new Date(work.nextCreation) > new Date(work.dates.expire)) {
            this.isRecurExpire = 1;
          } else {
            this.isRecurExpire = 0;
          }
        } else {
          this.isRecurExpire = 0;
        }
        this.param.priority = work.priority != null ? work.priority.id.toString() : '0';

        this.selectedPerson.length = 0;
        for (let p = 0; p < work.personnel.length; p++) {
          this.selectedPerson.push(work.personnel[p].id);
        }
        this.personnelSelect = this.selectedPerson;
        this.param.personnel = this.selectedPerson.join(',');

        this.param.facility_id = work.facility != null ? work.facility.id.toString() : '';
        this.selectedFilesPreview = work.media_files;
        this.selectedWorkAttach = work.attach_files;

        this.add2Calendar = work.calendar.add_to_calendar;
        this.param.add_to_calendar = this.add2Calendar == 1 ? true : false;
        this.calendarStatus = work.calendar.calendar_color;
        this.param.calendar_color = this.calendarStatus;

        this.taskType = work.taskType;
        this.taskList = work.tasks;

        let THIS = this;
        setTimeout(function () {
          THIS._startDate = THIS.CustomHelper.dateStrFormat(new Date(work.dates.start));
          THIS._startTime = THIS.CustomHelper.timeStrFormat(new Date(work.dates.start));
          THIS._expireDate = work.dates.expire != null ? THIS.CustomHelper.dateStrFormat(new Date(work.dates.expire)) : '';
          THIS._expireTime = work.dates.expire != null ? THIS.CustomHelper.timeStrFormat(new Date(work.dates.expire)) : '';


          THIS._startDateConfig.defaultDate = new Date(work.dates.start);
          if (new Date() > new Date(work.dates.start)) {
            THIS._startDateConfig.minDate = new Date(work.dates.start);
          } else {
            THIS._startDateConfig.minDate = new Date();
          }
          THIS._startTimeConfig.defaultDate = new Date(work.dates.start);
          THIS._expireDateConfig.defaultDate = work.dates.expire != null ? new Date(work.dates.expire) : null;
          THIS._expireDateConfig.minDate = work.dates.expire != null ? new Date(work.dates.start) : null;
          THIS._expireTimeConfig.defaultDate = work.dates.expire != null ? new Date(work.dates.expire) : null;

          THIS.staticDay = THIS.CustomHelper.day[THIS._startDateConfig.defaultDate.getDay()];
          THIS.repeatDays = work.dates.repeat_days !== null ? work.dates.repeat_days.split(',') : [];
          let CustomRepeat = work.dates.repeat.split(' ');
          THIS.repeatCount = CustomRepeat[0] !== undefined ? parseInt(CustomRepeat[0]) : 1;
          THIS.repeatItem = CustomRepeat[1] !== undefined ? CustomRepeat[1] : 'Day';

          THIS.__RecurPreview = 1;
          THIS.workDetailsChange = 1;
          setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
          }, 100);
          THIS.getAllFacility();
          THIS.pty_get();
          THIS.jt_get();
          THIS.asset_get();
          THIS.personnel_get();
        }, 100);
        this.getPastRec();
      } else if (res.status == 2200) {
        this.workDetailsChange = -22;
      } else {
        this.workDetailsChange = -1;
      }
    });
  }

  selectedFilesPreview = [];
  selectedWorkAttach = [];
  selectedFiles = [];

  uploadThisMedia(file, media_type, localtion, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  removeThisMedia(id, media_type) {
    let param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  dragFileOverStart() {
    var trigger = $('#uploadDragWork');
    trigger.addClass('onEnter');
  }

  dragFileOverEnd() {
    var trigger = $('#uploadDragWork');
    trigger.removeClass('onEnter');
  }

  _uploadError = 0;
  _locPermit = 0;

  dragFilesDropped(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    if (e.dataTransfer.files.length > 0) {
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        if (this.selectedFiles.length < 3) {
          let eachFile = e.dataTransfer.files[i];
          this.selectedFiles.push(eachFile);
          if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            if (THIS.currentUser.modules.geo_tagged_photo == 1) {
              navigator.geolocation.getCurrentPosition(function (position) {
                if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                  THIS._locPermit = 1;
                  let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                  THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  THIS._locPermit = -1;
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                }
              }, function (error) {
                swal({
                  title: 'Share Geo Location',
                  text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                  icon: 'warning',
                  buttons: ['Want to share location', 'Don\'t want to share Location'],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                      if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                          swal({
                            title: 'Storage Full',
                            text: 'Your Storage is full. Please delete some files',
                            icon: 'error',
                            dangerMode: true,
                          });
                        } else {
                          swal({
                            title: 'Invalid file format uploaded',
                            text: 'Please upload an image in Jpeg or Png only.',
                            icon: 'error',
                            dangerMode: true,
                          });
                        }
                      } else {
                        THIS.selectedFilesPreview.push(uploaded);
                      }
                    });
                  } else {
                    swal('If you want to share location then change the browser settings and remove the block status');
                  }
                });
              });
            } else {
              THIS._locPermit = -1;
              THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                if (uploaded.status != undefined) {
                  if (uploaded.status == 9000) {
                    swal({
                      title: 'Storage Full',
                      text: 'Your Storage is full. Please delete some files',
                      icon: 'error',
                      dangerMode: true,
                    });
                  } else {
                    swal({
                      title: 'Invalid file format uploaded',
                      text: 'Please upload an image in Jpeg or Png only.',
                      icon: 'error',
                      dangerMode: true,
                    });
                  }
                } else {
                  THIS.selectedFilesPreview.push(uploaded);
                }
              });
            }
          } else {
            this._uploadError = 1;
          }
        }
      }
      // this.uploaderPlate.preview = 1;
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    let accepted = e.target.files;
    for (var i = 0; i < accepted.length; i++) {
      if (this.selectedFiles.length < 3) {
        let eachFile = accepted[i];
        this.selectedFiles.push(eachFile);
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                THIS._locPermit = 1;
                let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              } else {
                THIS._locPermit = -1;
                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              }
            }, function (error) {
              swal({
                title: 'Share Geo Location',
                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                icon: 'warning',
                buttons: ['Want to share location', 'Don\'t want to share Location'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  swal('If you want to share location then change the browser settings and remove the block status');
                }
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please delete some files',
                    icon: 'error',
                    dangerMode: true,
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
              }
            });
          }
        } else {
          this._uploadError = 1;
        }
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
    // this.uploaderPlate.preview = 1;
  }

  removeThisImage(index, id) {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesPreview.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  selectedPerson = [];
  selectedGroup = [];


  _fc_updating = 0;

  updateThisFacility(id) {
    let THIS = this;
    this._fc_updating = 1;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/enable?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        THIS._fc_updating = 0;
        THIS.workDetailsChange = 0;
        THIS.getSingleRecurringWorkOrder(THIS.workId);
        THIS.facilityService.refreshFacility();
      }
    });
  }

  discardNewInvoice() {
    this.discard.emit(1);
  }

  saveWorkOrderSubmit() {
    let THIS = this;
    let ldd = document.getElementById('SaveThisLadda');
    let ladda = Ladda.create(ldd);
    let mediaFiles = '';
    $.each(THIS.selectedFilesPreview, function (i, v) {
      if (i == 0) {
        mediaFiles += v.id;
      } else {
        mediaFiles += ',' + v.id;
      }
    });
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    this.param.media_files = mediaFiles;
    this.param.attach_files = mediaAttach;
    this.param.repeat = this.repeatCount + ' ' + this.repeatItem;
    this.param.repeat_days = this.repeatDays.join();

    let woTaskList = $('#woTaskList').find('._eachTask');
    this.param['task_type'] = this.taskType;
    this.param.tasks.length = [];
    if (this.taskList.length > 0 ){
      this.taskList.forEach((v : any) => {
        if (v.title != null && v.title != ''){
          this.param.tasks.push(v);
        }
      });
    }
    this.param.add_to_calendar = this.add2Calendar == 0 ? false : true;
    this.param.calendar_color = this.calendarStatus;

    let valid = this.validationThisForm();
    if (valid == 1) {
      ladda.start();
      this.workOrderService.editRecurringWorkOrder(this.param).subscribe(res => {
        ladda.stop();
        this.CustomHelper.woRouteDiscard = 0;
        THIS.preview.emit(res);
        toastr.success('This new recurring job successfully updated', 'Edit Recurring Job', THIS.toastrOption);
        // Pusher emit
        $.ajax({
          url: THIS.APPCONFIG.apiAuth + 'work-orders-recurring-cron',
          type: 'get',
          data: {},
          success: function (res) {
          }
        });
      });
    }
  }

  clearValidation(e) {
    let trigger = $(e.target);
    let target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  validationThisForm() {
    let valid = 1;
    let form = $('#workCreateForm');
    if (this.param.facility_id == '') {
      valid = 0;
      swal({
        text: 'Please select a facility',

      });
      return valid;
    }
    if (this.param.subject == '') {
      valid = 0;
      swal('Please type a subject for this recurring work order');
      return valid;
    }
    if (this._startDate == '') {
      valid = 0;
      swal('Please select a start date');
      return valid;
    }
    if (this._startTime == '') {
      valid = 0;
      swal('Please select a start time');
      return valid;
    }
    if (this.repeatItem == '') {
      valid = 0;
      swal('Please select a recurring period');
      return valid;
    }
    if (this.repeatItem == '') {
      valid = 0;
      swal('Please select a recurring period');
      return valid;
    }
    if (this._expireDate != '') {
      if (this._expireTime == '') {
        valid = 0;
        swal('Please select an expire time');
        return valid;
      }
    }
    if (this._expireTime != '') {
      if (this._expireDate == '') {
        valid = 0;
        swal('Please select an expire date');
        return valid;
      }
    }
    let __start_New = new Date(this._startDate + ' ' + this._startTime);
    this.param.start = this.CustomHelper.formatDateTimeToDB(__start_New);
    if (this._expireDate != '' && this._expireTime != '') {
      let __expire_New = new Date(this._expireDate + ' ' + this._expireTime);
      this.param.expire = this.CustomHelper.formatDateTimeToDB(__expire_New);
      if (Date.parse(this.param.start) > Date.parse(this.param.expire)) {
        swal('Recurrance start date time can\'t be greater than expire time');
        valid = 0;
      }
    }
    return valid;
  }

  PreviewThisWorkOrder(id) {
    localStorage.childWork = id;
    this.tabsService.setTabView('work-orders', 1);
  }

  updateChangesDiscard() {
    this.CustomHelper.woRouteDiscard = 1;
  }

  inlinePrefixId = 0;
  inlineFacility = 0;
  inlineSubject = 0;
  inlineDescription = 0;

  didThisSave() {
    this.saveWorkOrderSubmit();
  }

  statusUpdate(status) {
    let param = {
      id: this.id,
      status: status
    };
    this.workOrderService.editRecurringWorkOrder(param).subscribe(res => {
      this.previewQuick.emit(res);
    });
  }


  //====================
  // ___panel_expand
  //====================
  _panel_expand = 0;
  _panel_clear = 0;
  _panel_clear_now = 0;

  ClearAllRight() {
    let THIS = this;
    this._panel_clear_now = 1;
    this._panel_clear = 0;
    setTimeout(function () {
      THIS._panel_clear_now = 0;
    }, 1000);

  }

  error = {
    facility: 0,
    personnel: 0,
    contact: 0,
    jobType: 0,
    priority: 0,
    date: 0,
  };

  makeExpandIt($event) {
    this._panel_expand = 1;
  }

  getResources(order) {
    // this.param.facility_id = order.facility_id;
    this.param.contact_id = order.contact_id;
    this.param.asset_id = order.asset_id;
    this.param.job_types_id = order.job_type_id;
    this.param.add_to_calendar = order.add2Calendar;
    this.param.calendar_color = order.calendarStatus;
    this.param.priority = order.priority_id;
    this.param.personnel_id = order.personnel;
    this.param.personnel = order.personnelIds.length > 0 ? order.personnelIds.join(',') : '';
    // this.param.start = order.dateCreated;
    // this.param.expire = order.dateDue;
    // this.param.attachInventories = order.attachInventories;
    // this.selectedWorkAttach = order.selectedWorkAttach;
    this.error = {
      facility: 0,
      personnel: 0,
      contact: 0,
      jobType: 0,
      priority: 0,
      date: 0
    };
    let param = {
      id: this.id,
      contact_id: order.contact_id,
      asset_id: order.asset_id,
      job_types_id: order.job_types_id,
      add_to_calendar: order.add2Calendar,
      calendar_color: order.calendarStatus,
      priority: order.priority_id,
      personnel_id: order.personnel_id,
      personnel: this.param.personnel
    };
    this.workOrderService.editRecurringWorkOrder(param).subscribe(res => {
      this.CustomHelper.woRouteDiscard = 0;
      this.previewQuick.emit(res);
    });
  }

  initRoute() {
    let THIS = this;
    if (this.inlinePrefixId == 1) {
      let url = '/' + this.currentUser.team + this.ROUTES + '/edit/' + this.workDetails.id;
      window.history.pushState('', '', url);
      setTimeout(function () {
        THIS.getAllFacility();
        THIS.pty_get();
        THIS.jt_get();
        THIS.asset_get();
        THIS.personnel_get();
      }, 100);
    } else {
      let url = '/' + this.currentUser.team + this.ROUTES + '/' + this.workDetails.id;
      window.history.pushState('', '', url);
    }
  }


  taskType = 2; // 1.Overall Rating ON  2.Overall Rating Off
  taskList = [{title: '', person: '', personDetails: null}];
  selectTsk = null;
  selectTskIndex = -1;

  removeTsk(index) {
    this.taskList.splice(index, 1);
  }

  selectTskNow(index) {
    this.selectTskIndex = index;
    this.selectTsk = this.taskList[index];
  }

  assignTskHim(person) {
    let index = this.selectTskIndex;
    this.taskList[index].person = person.id;
    this.taskList[index].personDetails = person;
    this.selectTsk = this.taskList[index];

    /*if (this.param.personnel_id.indexOf(person.id) == -1) {
        this.param.personnel_id.push(person.id);
        (<any>$('.chosen-default-multiple-per')).val(this.param.personnel_id);
        (<any>$('.chosen-default-multiple-per')).trigger("chosen:updated");
    }*/
  }

  changeTaskRating() {
    this.taskType == 1 ? this.taskType = 2 : this.taskType = 1;
  }

  taskCreateOnF() {
    this.taskList.push({title: '', person: '', personDetails: null});
    setTimeout(function () {
      let objDiv = document.getElementById('_tsk_list_scroll');
      if (objDiv != null){
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }, 500);
    // this.updateChangesDiscard();
  }


  childPastTotal = 0;
  pageNo = 1;
  perPage = 25;
  pageEvent;

  getPastRec() {
    let param = {
      id: this.id,
      pageNo: this.pageNo,
      perPage: this.perPage
    };
    this.workOrderService.getPastRec(param).subscribe(res => {
      if (res.status == 2000) {
        this.workDetails.childWork = res.data;
        this.childPastTotal = res.total;
      }
    });
  }

  paginateList() {
    if (this.pageEvent.pageSize == this.perPage) {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = this.pageEvent.pageIndex + 1;
    } else {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = 1;
    }
    this.getPastRec();
  }

  rightSecPreview = 0;

  pastRecurrenceSettings() {
    let THIS = this;
    if (THIS.add2Calendar == 1) {
      let dom = 'Do you want to display all past and future work order occurrences on the calendar or only future occurances? <br><br>' +
        ' <div style="width: 100%;display: block;text-align: left">Note: There are currently ' + THIS.workDetails.childWork.length + ' past occurrences for this work order. If you click the <strong>Display past & future recurrences</strong>' +
        ' button, your calendar will be populated with all ' + THIS.workDetails.childWork.length + ' past occurances and any future recurring work order. </div> <br> ' +
        ' <strong>This action cannot be undone</strong> <br><br><br><br><br>';
      swal({
        html: true,
        text: ' . ',
        icon: false,
        buttons: {
          cancel: 'Cancel',
          success: 'Display past & future occurrences',
          confirm: 'Only future occurances'
        },
        dangerMode: true,
        className: 'swal-wide-lg'
      }).then(res => {
        if (res == 'success') {
          console.log(1);
          THIS.add2Calendar = 1;
          THIS.param.past_recurrence = 1;
        } else if (res == true) {
          console.log(2);
          THIS.add2Calendar = 1;
          THIS.param.past_recurrence = 2;
        } else {
          console.log(3);
          THIS.add2Calendar = 0;
        }
      });
      setTimeout(function () {
        $('.swal-text').html(dom);
      }, 100);
    } else {
      let dom = 'This toggle will stop creating work order labels in the calendar. Do you want to remove all past occurrences from the calendar too? <br><br>' +
        ' <div style="width: 100%;display: block;text-align: left">Note: There are currently ' + THIS.workDetails.childWork.length + ' past occurrences for this work order. ' +
        ' If you click the <strong>Remove past & future occurrences</strong> button, all labels for the past occurrences will be removed from the calendar. </div> <br> ' +
        ' <strong>This action cannot be undone</strong> <br><br><br><br><br>';
      swal({
        html: true,
        text: ' . ',
        icon: false,
        buttons: {
          cancel: 'Cancel',
          success: 'Remove past & future occurrences',
          confirm: 'Only remove all future occurrences'
        },
        dangerMode: true,
        className: 'swal-wide-lg'
      }).then(res => {
        if (res == 'success') {
          THIS.param.past_recurrence = 0;
        } else if (res == true) {
          THIS.param.past_recurrence = 2;
        } else {
          THIS.add2Calendar = 1;
        }
      });
      setTimeout(function () {
        $('.swal-text').html(dom);
      }, 100);
    }
  }


  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_woRc_' + Session.team, function (v) {
        THIS.getSingleRecurringWorkOrder(THIS.workId);
    });

    this.socket.on('_rfr_team_data_fac_' + Session.team, function (v) {
        THIS.getAllFacility();
    });

    this.socket.on('_rfr_team_data_asset_' + Session.team, function (v) {
        THIS.asset_get();
    });

    this.socket.on('_rfr_team_data_jobType_' + Session.team, function (v) {
        THIS.jt_get();
    });*/

    $('body').on('click', '.reRenderFacility', function () {
      THIS.getAllFacility();
    });

    $('body').on('click', '.reRenderJobType', function () {
      THIS.jt_get();
    });

    $('body').on('click', '.reRenderAssets', function () {
      THIS.asset_get();
    });

    $('body').on('click', '.reRenderPerson', function () {
      THIS.personnel_get();
    });
  }

  ngOnDestroy() {
  }


  // =============================
  // Photo Editor
  // =============================
  CANVAS = null;
  SHAPE = 'brush';
  IMAGE_PATH = '';
  CanvasTarget = 0;

  discardEditor() {
    let THIS = this;
    let modal = (<any>$('#photoEditorModal'));
    if (THIS.CANVAS != null) {
      THIS.CANVAS.dispose();
    }
    modal.modal('hide');

  }

  editImgCallUnique = 0;
  EditCurrentCanvas = null;
  CurrentEditPhoto = null;

  openPhotoEditor(preview) {
    let THIS = this;
    THIS.CurrentEditPhoto = preview;
    THIS.CanvasTarget = 0;
    let modal = (<any>$('#photoEditorModal'));
    let canvasW = window.innerWidth - 20;
    let canvasH = window.innerHeight - 130;
    $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
    if (THIS.CANVAS != null) {
      this.CANVAS.clear();
    }
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    let param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

    if (THIS.editImgCallUnique == 0) {
      THIS.editImgCallUnique = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          THIS.editImgCallUnique = 0;
          if (res.status === 2000) {
            THIS.EditCurrentCanvas = res.data;
            let canvasW = res.data.width;
            let canvasH = res.data.height;
            $('#canvas').attr('width', canvasW + 'px');
            $('#canvas').attr('height', canvasH + 'px');
            setTimeout(function () {
              // if(THIS.CANVAS != null){
              //     THIS.CANVAS.dispose();
              // }

              THIS.CANVAS = new fabric.Canvas('canvas');
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.on('object:selected', function (e) {
                THIS.CANVAS.isDrawingMode = false;
                THIS.SHAPE = '';
              });
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.isDrawingMode = true;
              THIS.CANVAS.freeDrawingBrush.width = 5;

              let startY = 0,
                startX = 0;
              THIS.CANVAS.on('mouse:down', function (option) {
                startY = option.e.offsetY;
                startX = option.e.offsetX;
              });
              THIS.CANVAS.on('mouse:up', function (option) {
                let selected = THIS.CANVAS.getActiveObjects();
                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                  if (THIS.SHAPE === 'circle') {
                    let e = option.e;
                    if (e.offsetX - startX > 5) {
                      let circle = new fabric.Circle({
                        top: startY,
                        left: startX,
                        radius: (e.offsetX - startX) / 2,
                        stroke: THIS.CANVAS.freeDrawingBrush.color,
                        strokeWidth: 5,
                        fill: ''
                      });
                      THIS.CANVAS.add(circle);
                    }
                  }
                }
              });
              THIS.CustomHelper.tooltipRender();

              THIS.IMAGE_PATH = preview.file_path;
              let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
              $('.canvas-container').css({
                backgroundImage: 'url(\'' + src + '\')'
              });
              THIS.CANVAS.renderAll();
              setTimeout(function () {
                THIS.CanvasTarget = 1;
              }, 1000);
            }, 1000);
          }
        }
      });


      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        modal.modal('hide');
        history.go(1);
      };

      if ($('.color-picker-editor').length > 0) {
        Pickr.create({
          el: '.color-picker-editor',
          theme: 'nano', // or 'monolith', or 'nano'
          defaultRepresentation: 'HEX',
          useAsButton: true,
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: true,
              input: true,
              save: true
            }
          }
        }).on('change', (color, instance) => {
          $('.color-picker-editor').css('color', color.toHEXA().toString());
          THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
          THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
          });
        });
      }
      $('.color-picker-editor').css('color', '#ff3773');
    }
  }

  // =============================
  // Photo Editor Actions
  // =============================
  SketchEnable() {
    this.CANVAS.isDrawingMode = !this.CANVAS.isDrawingMode;
    if (this.CANVAS.isDrawingMode === true) {
      this.SHAPE = 'brush';
    }
  }

  eraser() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    let obj = THIS.CANVAS.getObjects();
    for (let i = 0; i < obj.length; i++) {
      $.each(obj, function (j, v) {
        if (parseInt(j) > 0) {
          THIS.CANVAS.remove(v);
        }
      });
    }
    THIS.UID_IMG = Date.now();
    let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
    $('.canvas-container').css({
      backgroundImage: 'url(\'' + src + '\')'
    });
  }

  eraserSelected() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
      THIS.CANVAS.remove(obj);
    });
  }

  drawCircle() {
    let THIS = this;
    this.CANVAS.isDrawingMode = false;
    this.SHAPE = 'circle';
    let circle = new fabric.Circle({
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      radius: 50,
      stroke: THIS.CANVAS.freeDrawingBrush.color,
      strokeWidth: 5,
      fill: ''
    });
    THIS.CANVAS.add(circle);
  }

  UpdatePhoto(iid) {
    let THIS = this;
    let ldd = document.getElementById(iid);
    let ladda = Ladda.create(ldd);
    ladda.start();
    THIS.CANVAS.discardActiveObject();
    THIS.CANVAS.requestRenderAll();
    setTimeout(function () {
      let modal = (<any>$('#photoEditorModal'));
      let canvas = (<any>document.getElementById('canvas'));
      let imgData = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      imgData = imgData.replace('data:image/png;base64,', '');
      let param = {
        imgData: imgData,
        media_type: 1,
        file_path: THIS.IMAGE_PATH
      };
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          ladda.stop();
          modal.modal('hide');
          THIS.UID_IMG = Date.now();
        }
      });
    }, 1000);
  }


  UID_IMG = 0;
  Gallery = null;
  Preview;

  open(index, preview) {
    let THIS = this;
    THIS.Preview = preview;
    $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    let _albums = [];
    if (THIS.workDetails.media_files !== undefined) {
      for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
        const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + THIS.workDetails.media_files[i].file_path + '&ref=' + Date.now();
        const caption = '';
        const thumb = '';
        const album = {
          src: src,
          caption: caption,
          thumb: thumb,
          w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
          h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
        };
        _albums.push(album);
      }
    }
    let pswpElement = document.querySelectorAll('.pswp')[0];
    let options = {
      index: index,
      shareEl: false,
      editEl: true,
      tapToClose: false,
      zoomEl: true,
      history: false
    };
    THIS.Gallery = null;
    THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
    THIS.Gallery.init();
    THIS.Gallery.listen('afterChange', function (r) {
      THIS.Preview = THIS.workDetails.media_files[THIS.Gallery.getCurrentIndex()];
      $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
    });
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      THIS.Gallery.close();
      history.go(1);
    };

  }


  addWorkAttach() {
    $('#workAttach').click();
  }

  removeThisAttach(index, id) {
    this.selectedWorkAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        let eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }
  duplicateThisInspectionRecurring() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#DuplicateModal')).modal('show');
  }

  duplicateInspectionRecurring() {
    let ldd = document.getElementById('loadingDuplicate');
    let ladda = Ladda.create(ldd);
    ladda.start();
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      let freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        let freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiIncRecurring + 'duplicate?token=' + THIS.currentUser.token,
      data: { id: this.workId },
      type: 'post',
      success: function (res) {
        ladda.stop();
        (<any>$('#DuplicateModal')).modal('hide');
        toastr.success('This inspection Recurring has been duplicated successfully', 'Inspection Duplicate',);
        // Pusher emit
        // swal({
        //   title: 'Duplicate Inspection',
        //   text: 'Inspection has been successfully duplicated',
        //   icon: 'success',
        //   buttons: ['Undo', 'Ok'],
        //   dangerMode: true,
        // }).then((willDelete) => {
        //   if (willDelete) {
        //     swal.stopLoading();
        //     swal.close();
        //   } else {
        //     let ids = [res.id];
        //     $.ajax({
        //       url: THIS.APPCONFIG.apiInc + 'erase/remove?token=' + THIS.currentUser.token,
        //       data: {ids: ids},
        //       type: 'post',
        //       success: function (res) {
        //         swal({
        //           title: 'Undo of duplication of Inspection is done.',
        //           className: 'swal-btn-center'
        //         });
        //         // Pusher emit
        //       }
        //     });
        //   }
        // });
      }
    });
  }

}
