import {Component, Output, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AssetService} from '../_services/asset.service';
import {AuthService} from '../_services/auth.service';
import {ContactsService} from '../_services/contacts.service';
import {FacilityService} from '../_services/facility.service';
import {InventoriesService} from '../_services/inventories.service';
import {InvoicesService} from '../_services/invoices.service';
import {Job_statusService} from '../_services/job_status.service';
import {Job_typesService} from '../_services/job_types.service';
import {MediaService} from '../_services/media.service';
import {TabsService} from '../_services/tabs.service';
import {ChatService} from '../_services/chat.service';
import {UserService} from '../_services/user.service';
import {WorkOrderService} from '../_services/work-orders.service';
import {FcmService} from '../_services/fcm.service';
import {MatTooltipModule} from '@angular/material';
import {APPCONFIG} from '../config';
import {CustomHelper} from '../customhelper';

declare let toastr: any;
declare let Notification: any;
declare let $: any;

@Component({
  selector: 'my-app-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent {
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  currentUserInfo;
  @Output() headerCloseAction = new EventEmitter<number>();
  template = 0;
  tabs = [];
  mainTab: any;
  selectedTab = 1;
  currentUrl = '';
  page = '';
  id = '';
  sessionExpire = 0;
  message;
  siteNavTamp = 1;
  ActiveChat = [];
  constructor(private router: Router, private route: ActivatedRoute, public tooltip: MatTooltipModule, private assetService: AssetService, private authService: AuthService, private contactsService: ContactsService, private facilityService: FacilityService, private inventoriesService: InventoriesService, private tabsService: TabsService, private chatService: ChatService, private invoicesService: InvoicesService, private job_statusService: Job_statusService, private job_typesService: Job_typesService, private mediaService: MediaService, private userService: UserService, private fcmService: FcmService, private workOrderService: WorkOrderService) {
    let THIS = this;
    if (localStorage.getItem('currentUserInfo') == null) {
      this.router.navigate(['/']);
      this.template = 0;
    } else {
      this.currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
      this.siteNavTamp = this.currentUserInfo.side_nav;
      this.user['name'] = this.currentUserInfo.name;
      this.user['email'] = this.currentUserInfo.email;
      if (this.currentUserInfo.step != 0) {
        this.router.navigate(['/register']);
      }
      if (window.innerWidth <= 1024) {
        this.siteNavTamp = 1;
      }


      this.tabsService.getTabs().subscribe(tabs => {
        this.tabs = tabs;
      });
      this.tabsService.getMainTab.subscribe(tab => {
        this.mainTab = tab;
      });
      if (this.route.snapshot.params.page != undefined) {
        this.page = this.route.snapshot.params.page;
        this.tabsService.makeFirstTab(this.page);
      }

      this.authService.authCheckToLogout().subscribe(res => {
        if (res.status == 2000) {
          localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
          setTimeout(function () {
            THIS.template = 1;
          }, 500);
        } else {
          this.template = 0;
          localStorage.removeItem('currentUserInfo');
          this.router.navigate(['/login']);
        }
      });
      this.authService.authCheck().subscribe(res => {
        if (res.status == 2000) {
          localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
        }
      });

      let THIS = this;
      (<any>$(document)).idleTimer(55 * 60000);
      (<any>$(document)).on('idle.idleTimer', function (event, elem, obj) {
        if (THIS.sessionCheckModal == 0 && localStorage.currentUserInfo != undefined) {
          THIS.openSessionOutAlert();
        }
      });
      (<any>$(document)).keyup(function (e) {
        if (e.keyCode == 27) {
          if (THIS.sessionCheckModal == 1) {
            return false;
          }
        }
      });

      if (localStorage.firstComeWelcome != undefined) {
        if (localStorage.firstComeWelcome == 1) {
          setTimeout(function () {

            let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            let child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#welcomeModalModal')).modal('show');
          }, 1000);

        }
      }

      $.ajax({
        url: THIS.APPCONFIG.api + 'assets/es/create?token=' + THIS.currentUserInfo.token,
        type: 'get',
        data: {},
        success: function (res) {
        }
      });
      $.ajax({
        url: THIS.APPCONFIG.api + 'work-orders/es/create?token=' + THIS.currentUserInfo.token,
        type: 'get',
        data: {},
        success: function (res) {
        }
      });
      $.ajax({
        url: THIS.APPCONFIG.api + 'work-orders-recur/es/create?token=' + THIS.currentUserInfo.token,
        type: 'get',
        data: {},
        success: function (res) {
        }
      });
      $.ajax({
        url: THIS.APPCONFIG.apiAuth + 'removeJunk',
        type: 'get',
        data: {},
        success: function (res) {
        }
      });
      $.ajax({
        url: THIS.APPCONFIG.apiAuth + 'work-orders-recurring-cron',
        type: 'get',
        data: {},
        success: function (res) {
        }
      });
      $.ajax({
        url: THIS.APPCONFIG.apiAuth + 'invoice-recurring-cron',
        type: 'get',
        data: {},
        success: function (res) {
        }
      });
      this.notStatusCheck();
    }
    if (window.innerWidth <= 1024) {
      THIS.siteNavTamp = 1;
    }
    this.chatService.getActiveChat.subscribe(ActiveChat => {
      this.ActiveChat = ActiveChat;
    });
  }
  closeChat(index) {
    this.ActiveChat.splice(index, 1);
    this.chatService.updateActiveChat(this.ActiveChat);
  }
  updateSideNav(e) {
    let trigger = $(e.target);
    this.siteNavTamp = this.CustomHelper.makeInt(trigger.attr('data-type'));
    if (window.innerWidth <= 1024) {
      this.siteNavTamp = 1;
    }
  }
  sessionCheckModal = 0;
  openSessionOutAlert() {
    let THIS = this;
    this.sessionCheckModal = 1;
    let toastrOption = {
      timeOut: 60000, progressBar: true, preventDuplicates: true, extendedTimeOut: 60000,
      onclick: function () {
        THIS.sessionCheckModal = 0;
        THIS.authService.authCheck().subscribe(res => {
          if (res.status == 2000) {
            localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
          }
        });
      },
      onHidden: function () {
        if (THIS.sessionCheckModal == 1) {
          THIS.reLoginAlert();
        }
      }
    };
    toastr.warning('Your session will expire within a minute. Click here to stay signed in.', 'Session Expire Alert', toastrOption);
    let notify = {
      title: 'Session Expire Alert',
      tag: Date.now(),
      msg: 'Your session will expire within a minute. Click here to stay signed in.'
    };
    this.notify(notify);
  }
  LockedScreen = 0;
  reLoginAlert() {
    // this.sessionCheckModal = 1;
    // this.LockedScreen = 1;
    // (<any>$('#lockedScreenModal')).modal('show');
    // this.CustomHelper.woRouteDiscard = 0;
    this.authService.logout().subscribe(res => {
      this.router.navigate(['/']);
    });
  }
  ngOnInit() {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    window.addEventListener('beforeunload', function (e) {
      let confirmationMessage = '\o/';
      if (THIS.CustomHelper.woRouteDiscard == 1) {
        e.returnValue = confirmationMessage;
      }
    });

  }
  sideNavScreen = false;
  toggleSide() {
    this.sideNavScreen = !this.sideNavScreen;
  }
  headerClose() {
    this.sideNavScreen = false;
  }
  toMyProfile() {
    (<any>$('#welcomeModalModal')).modal('hide');
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + '/profile';
    window.history.pushState('', '', url);
    this.tabsService.makeFirstTab('profile');
  }
  getSessionUserInfo() {
    this.authService.getSessionUserInfo().subscribe(res => {
      if (res.status == 2000) {
        localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
        this.assetService.refreshToken();
        this.authService.refreshToken();
        this.contactsService.refreshToken();
        this.facilityService.refreshToken();
        this.inventoriesService.refreshToken();
        this.invoicesService.refreshToken();
        this.job_statusService.refreshToken();
        this.job_typesService.refreshToken();
        this.mediaService.refreshToken();
        this.userService.refreshToken();
        this.workOrderService.refreshToken();
        window.location.href = '';
      }
    });
  }
  user: any = {};
  submit = 0;
  err = '';
  login() {
    let param = {
      email: this.user.email,
      password: this.user.password
    };
    this.submit = 1;
    this.err = '';
    this.authService
      .login(param)
      .subscribe(res => {
        this.submit = 0;
        if (res != false) {
          this.user.password = '';
          this.assetService.refreshToken();
          this.authService.refreshToken();
          this.contactsService.refreshToken();
          this.facilityService.refreshToken();
          this.inventoriesService.refreshToken();
          this.invoicesService.refreshToken();
          this.job_statusService.refreshToken();
          this.job_typesService.refreshToken();
          this.mediaService.refreshToken();
          this.userService.refreshToken();
          this.workOrderService.refreshToken();
          // (<any>$('#lockedScreenModal')).modal('hide');
          this.sessionCheckModal = 0;
          this.LockedScreen = 0;
        } else {
          this.err = 'Insert correct password to unlock the screen';
        }
      });
  }
  logout() {
    localStorage.removeItem('currentUserInfo');
    window.location.href = '';
  }
  visible = 0;
  notStatusPermission = 1;
  notStatusMsg = 'Get notified of new messages';
  notStatusCheck() {
    if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
      //alert('This is an iPad or iPhone or iPod');
    } else {
      let THIS = this;
      if (!('Notification' in window)) {
        THIS.notStatusMsg = 'This browser does not support desktop notification';
        THIS.notStatusPermission = -1;
        THIS.visible = 1;
      }
      if (Notification.permission !== 'granted') {
        THIS.notStatusMsg = 'Get notified of new messages';
        THIS.notStatusPermission = 0;
        THIS.visible = 1;
      } else {
        this.requestNotifyPermission();
      }
    }
  }
  requestNotifyPermission() {
    this.visible = 0;

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#notifyPermissionModal')).modal('show');
  }
  notify(data) {
    Notification.requestPermission().then(function (result) {
      if (result === 'granted') {
        new Notification(data.title, {
          body: data.msg
        });
        return;
      } else {
        console.log('Permission is ' + result);
        return;
      }
    });
  }
}
