import {Component, Input,  Output, EventEmitter} from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { TabsService } from '../../../_services/tabs.service';
import { UserService } from '../../../_services/user.service';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
declare let swal: any;

@Component({
    selector: 'my-app-sidenav-contact-menu',
    styles: [],
    templateUrl: './sidenav-contact-menu.component.html'
})

export class SidenavContactMenuComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    @Input() changeTab:any;
    @Output() closeSide = new EventEmitter<number>();

    activeLinks: Array<number>;
    selectedTab: number;
    selectedPage: any;

    currentUser = null;
    constructor(private tabsService: TabsService,
                private authService: AuthService,
                private userService: UserService,
                private coolDialogs: NgxCoolDialogsService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

        this.tabsService
            .getSelectedTab
            .subscribe(tab => {
                this.selectedTab = tab;
            });
        this.tabsService
            .getSelectedPage
            .subscribe(page => {
                this.selectedPage = page;
            });
        this.tabsService
            .getActiveLinks
            .subscribe(activeLinks => this.activeLinks = activeLinks);

        this.authService
            .getUserType
            .subscribe(userType => {
            });

        this.getAllLabel();
    }

    switchView(page: string) {
        this.presenceAlert();
        let THIS = this;
        this.CustomHelper.woRouteDiscard = 0;
        let index = THIS.CustomHelper.ConfRoute.map(function(e) { return e.tab; }).indexOf(THIS.CustomHelper.ConfCurRoute);
        if(index > -1){
            // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
        }
        if(this.CustomHelper.woRouteDiscard == 1){
            swal({
                title: "Are you sure you want to leave?",
                text: "You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.",
                icon: "warning",
                buttons: ['CANCEL','LEAVE'],
                dangerMode: true,
            }).then((res) => {
                if (res) {
                    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
                    window.history.pushState("","", '/'+Session.team+'/'+page);
                    THIS.tabsService.makeFirstTab(page);
                    THIS.CustomHelper.woRouteDiscard = 0;
                    THIS.CustomHelper.ConfRoute.splice(index, 1);
                }
            });
        } else {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            window.history.pushState("","", '/'+Session.team+'/'+page);
            THIS.tabsService.makeFirstTab(page);
            THIS.CustomHelper.woRouteDiscard = 0;
        }
    }

    presenceAlert(){

    }

  closeSideMenu() {
    this.closeSide.emit(1);
  }


    Labels = [];
    createLabel = 0;
    labelParam = {name : ''};
    submitNewLabel(){
        let THIS = this;
        if(THIS.labelParam.name == ''){
          return $('.label-error').html('Name is required');
        }
        $.ajax({
            url: THIS.APPCONFIG.api+'contact/label/create?token='+THIS.currentUser.token,
            type: 'post',
            data : THIS.labelParam,
            success : function(res){
                $('.label-error').html('');
                THIS.labelParam.name = '';
                THIS.Labels.push(res.data);
            }
        });
    }
    removeError(){
      $('.label-error').html('');
    }
    labelParamEdit = {id: '', name : ''};
    defaultEditLab(){
        let THIS = this;
        $.each(THIS.Labels, function (i, v) {
            THIS.Labels[i]['edit_status'] = 0;
        });
    }
    submitEditLabel(){
        let THIS = this;
        if(THIS.labelParamEdit.name == ''){
          return $('.label-error').html('Name is required');
        }
        $.ajax({
            url: THIS.APPCONFIG.api+'contact/label/edit?token='+THIS.currentUser.token,
            type: 'post',
            data : THIS.labelParamEdit,
            success : function(res){
                $('.label-error').html('');
                THIS.getAllLabel();
            }
        });
    }

    labelId = '';
    deleteThisLad() {
        let THIS = this;
        // swal({
        //     title: "Label Delete",
        //     text: "Do you really want to delete this label?",
        //     icon: "warning",
        //     buttons: ['CANCEL','DELETE'],
        //     dangerMode: true,
        // }).then((res) => {
        //     if (res) {
        //
        //     }
        // });
      $.ajax({
        url: THIS.APPCONFIG.api+'contact/label/remove?token='+THIS.currentUser.token,
        type: 'post',
        data : {id: THIS.labelId},
        success : function(res){
          THIS.getAllLabel();
          (<any>$('#deleteWorkOrderModal')).modal('hide');
        }
      });
    }
  deleteLabel() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }

    getAllLabel(){
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api+'contact/label/getAll?token='+THIS.currentUser.token,
            type: 'post',
            data : {},
            success : function(res){
                THIS.Labels = res.data;
                (<any>$('#reloadContact')).click();
            }
        });
    }

    currentContact = null;
    sortThislabelContact(id){
        if($('.__contactList').length > 0){
            $('.__contactList').attr('data-id', id);
            $('.__contactList').click();
            this.currentContact = id;
        }
    }
}
