import {Component} from '@angular/core';
import {APPCONFIG} from '../../config';
import {TabsService} from '../../_services/tabs.service';
import {AuthService} from '../../_services/auth.service';
import {FacilityService} from '../../_services/facility.service';
import {MatSnackBar} from '@angular/material';
import {CustomHelper} from '../../customhelper';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';


@Component({
  selector: 'my-app-facilities',
  styleUrls: [],
  templateUrl: './facilities.component.html'
})

export class FacilitiesComponent {
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  currentUser;
  Menus = [];
  selectedPage: any;
  activeLinks: Array<number>;
  UserType = 0;
  expand = 0;
  selectedTab: number;
  Module: any = null;

  constructor(protected facilityService: FacilityService,
              protected tabsService: TabsService,
              protected authService: AuthService,
              private coolDialogs: NgxCoolDialogsService,
              public snackBar: MatSnackBar) {

    let THIS = this;
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.Module = this.currentUser.modules;
    this.Menus = this.currentUser.settings.menus;
    this.tabsService.getSelectedTab.subscribe(tab => {
      this.selectedTab = tab;
    });
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.authCheck();
    this.facilityService.currentSelectedFac.subscribe(value => this.getAllFacility());
    this.FacilityAssetMenu = this.currentUser.fac_asset;
    this.FacilityContactMenu = this.currentUser.fac_contact;
    this.getSessionUserInfo();
  }
  getSessionUserInfo() {
    this.authService.getSessionUserInfo().subscribe(res => {
      if (res.status === 2000) {
        localStorage.currentUserInfo = JSON.stringify(res.data);
        this.Module = res.data.modules;
        this.Menus = res.data.settings.menus;
        setTimeout(function () {
          (<any>$('.nav-label-icon')).tooltip('destroy');
        }, 1000);
        setTimeout(function () {
          (<any>$('.nav-label-icon')).tooltip('destroy');
        }, 5000);
      }
    });
  }

  FacilityAssetMenu = 0;

  changeFacilityAssetMenu() {
    const THIS = this;
    THIS.FacilityAssetMenu = THIS.FacilityAssetMenu == 1 ? 0 : 1;
    THIS.FacilityLoading = 1;
    $.ajax({
      url: THIS.APPCONFIG.api + 'assets/update/facility/menu?token=' + THIS.currentUser.token,
      type: 'post',
      data: {},
      success: function (res) {
        THIS.getAllFacility();
      }
    });
  }

  FacilityContactMenu = 0;

  changeFacilityContactMenu() {
    const THIS = this;
    THIS.FacilityContactMenu = THIS.FacilityContactMenu == 1 ? 0 : 1;
    THIS.FacilityLoading = 1;
    $.ajax({
      url: THIS.APPCONFIG.api + 'contact/update/facility/menu?token=' + THIS.currentUser.token,
      type: 'post',
      data: {},
      success: function (res) {
        THIS.getAllFacility();
      }
    });
  }

  authCheck() {
    this.authService.getSessionUserInfo().subscribe(res => {
      if (res.status == 2000) {
        this.currentUser = res.data;
        this.UserType = res.data.user_type;
      }
    });
  }

  sideNavToolTip(type) {
    if (type == 1) {
      (<any>$('.nav-label-icon')).tooltip();
      this.getAllFacility();
      $('._sidebar_tabs').css({opacity: 1});
    } else {
      (<any>$('.nav-label-icon')).tooltip('destroy');
      $('._sidebar_tabs').css({opacity: 1});
    }
  }


  selectedFacility = null;

  viewThisFacility() {
    if (this.selectedFacility != null) {
      localStorage.selectedFacility = JSON.stringify(this.selectedFacility);
      localStorage.selectedFacilityTemplate = 1;
      localStorage.selectedFacilityStep = 1;
      this.switchView('facilities');
      $('#previewNewFacility').click();
    }
  }

  editThisFacility() {
    if (this.selectedFacility != null) {
      $('#editFacilityTrigger').attr('data-id', JSON.stringify(this.selectedFacility));
      $('#editFacilityTrigger').click();
      // localStorage.selectedFacility = JSON.stringify(this.selectedFacility);
      // localStorage.selectedFacilityTemplate = 3;
      // localStorage.selectedFacilityStep = 1;
      // this.switchView('facilities');
      // $('#editNewFacility').click();
    }
  }

  importThisFacility() {
    if (this.selectedFacility != null) {
      localStorage.selectedFacility = JSON.stringify(this.selectedFacility);
      localStorage.selectedFacilityTemplate = 3;
      localStorage.selectedFacilityStep = 2;
      this.switchView('facilities');
      $('#importNewFacility').click();
    }
  }

  removeThisFacility() {
    if (this.selectedFacility != null) {
      localStorage.selectedFacility = JSON.stringify(this.selectedFacility);
      localStorage.selectedFacilityStepRemove = 1;
      this.switchView('facilities');
      $('#removeNewFacility').click();
    }
  }

  switchView(page: string) {
    const THIS = this;
    THIS.CustomHelper.woRouteDiscard = 0;
    window.history.pushState('', '', '/' + page);
    THIS.tabsService.makeFirstTab(page);
  }


  createFacModal() {
    $('#createFacilityTrigger').click();
  }


  EnabledFac = 0;

  countEnableFacility() {
    let count = 0;
    this.All = 1;
    const THIS = this;
    $.each(THIS.Facility, function (i, v) {
      if (v.is_enable == 1) {
        count = count + 1;
      } else {
        THIS.All = 0;
      }
      $.each(v.child, function (j, vv) {
        if (vv.is_enable == 1) {
          count = count + 1;
        } else {
          THIS.All = 0;
        }
        $.each(vv.child, function (k, vvv) {
          if (vvv.is_enable == 1) {
            count = count + 1;
          } else {
            THIS.All = 0;
          }
          $.each(vvv.child, function (l, vvvv) {
            if (vvvv.is_enable == 1) {
              count = count + 1;
            } else {
              THIS.All = 0;
            }
          });
        });
      });
    });
    this.EnabledFac = count;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  Facility = [];
  All = 0;
  _fc_eye_updating = 0;
  FacilityLoading = 0;

  getAllFacility() {
    const THIS = this;
    THIS.FacilityLoading = 1;
    this.facilityService.getFacilitiesList().subscribe(res => {
      this.Facility = res.facility;
      this.All = res.all;
      localStorage.Facility = JSON.stringify(this.Facility);
      localStorage.FacAll = JSON.stringify(this.All);
      this.countEnableFacility();
      THIS.FacilityLoading = 0;
    });
  }

  updateThisFacility(fac, type) {
    const THIS = this;
    this.countEnableFacility();
    fac['loading'] = 1;
    if (type == 0) {
      this.facilityService.enableFacility(fac.id).subscribe(res => {
        this.facilityService.refreshFacility();
        // Pusher emit
      });
    } else {
      this.facilityService.enableFacilitySingle(fac.id).subscribe(res => {
        this.facilityService.refreshFacility();
        // Pusher emit
      });
    }
  }
  openFacility(facility) {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/open?token=' + THIS.currentUser.token,
      type: 'post',
      data: {id: facility.id},
      success: function (res) {
        // THIS.getAllFacility();
      }
    });
  }

  all_loading = 0;

  AllEnableFacility(all) {
    this.all_loading = 1;
    this.facilityService.enableAll(all).subscribe(res => {
      this.all_loading = 0;

      this.Facility = res.data.facility;
      this.All = res.data.all;
      localStorage.Facility = JSON.stringify(this.Facility);
      localStorage.FacAll = JSON.stringify(this.All);
      this.countEnableFacility();

      this.facilityService.refreshFacility();
    });
  }

  selectAllFac(id, is_enable, index) {
    const param = {id: id, is_enable: is_enable};
    this.facilityService.enableGroupFacility(param).subscribe(res => {
      this.Facility = res.data.facility;
      this.Facility[index]['_fc_eye_updating'] = 0;
      this.countEnableFacility();
      this.facilityService.refreshFacility();
      // Pusher emit
    });
  }

  selectUniqueFac(id) {
    const param = {id: id};
    this.facilityService.selectUniqueFac(param).subscribe(res => {
      this.Facility = res.data.facility;
      this.countEnableFacility();
      this.facilityService.refreshFacility();
      // Pusher emit
    });
  }

  searchKeyword = '';

  highlighter(subject) {
    if (subject == '' || subject == null) {
      return subject;
    } else {
      subject = this.CustomHelper.makeStr(subject);
    }

    if (this.searchKeyword == '') {
      return subject;
    }
    let searchKeyword = this.searchKeyword;
    searchKeyword = $.trim(searchKeyword);
    const strArr = searchKeyword.split(' ');
    let res = '';
    $.each(strArr, function (i, eachKey) {
      const matches = subject.match(new RegExp(eachKey, 'gi'));
      $.each(matches, function (i, v) {
        res = subject.replace(v, '<span class=\'text-warning-highlight-ywl\'>' + v + '</span>');
      });
    });
    if (res == '') {
      res = subject;
    }
    return res;
  }

  highlighterAsset(subject) {
    if (subject == '' || subject == null) {
      return subject;
    } else {
      subject = this.CustomHelper.makeStr(subject);
    }

    if (this.searchKeyword == '') {
      return subject;
    }
    let searchKeyword = this.searchKeyword;
    searchKeyword = $.trim(searchKeyword);
    const strArr = searchKeyword.split(' ');
    let res = '';
    $.each(strArr, function (i, eachKey) {
      const matches = subject.match(new RegExp(eachKey, 'gi'));
      $.each(matches, function (i, v) {
        res = subject.replace(v, '<span class=\'text-warning-highlight-ywl\'>' + v + '</span>');
      });
    });
    if (res == '') {
      res = subject;
    }
    return res;
  }

  searchResChild(fac) {
    const THIS = this;
    if (this.searchKeyword == '') {
      return true;
    }
    let cNames = fac.name + ' ';
    $.each(fac.child, function (i, v) {
      cNames += v.name + ' ';
      $.each(v.child, function (j, vv) {
        cNames += vv.name + ' ';
        $.each(vv.child, function (k, vvv) {
          cNames += vvv.name + ' ';
          $.each(vvv.child, function (l, vvvv) {
            cNames += vvvv.name + ' ';
          });
          $.each(vvv.assets, function (i, v) {
            cNames += v.name + ' ';
          });
          $.each(vvv.contacts, function (i, v) {
            cNames += v.name + ' ';
          });
        });
        $.each(vv.assets, function (i, v) {
          cNames += v.name + ' ';
        });
        $.each(vv.contacts, function (i, v) {
          cNames += v.name + ' ';
        });
      });
      $.each(v.assets, function (i, v) {
        cNames += v.name + ' ';
      });
      $.each(v.contacts, function (i, v) {
        cNames += v.name + ' ';
      });
    });
    $.each(fac.assets, function (i, v) {
      cNames += v.name + ' ';
    });
    $.each(fac.contacts, function (i, v) {
      cNames += v.name + ' ';
    });

    const res = [];
    const strArr = this.searchKeyword.split(' ');
    $.each(strArr, function (i, eachKey) {
      const matches = cNames.match(new RegExp(eachKey, 'gi'));
      if (matches == null) {
        res.push(0);
      } else if (matches.length > 0) {
        res.push(1);
      }
    });
    if (res.indexOf(1) > -1) {
      return true;
    }
    return false;
  }


  ngOnInit() {
    const THIS = this;
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_fac_' + Session.team, function (v) {
      THIS.getAllFacility();
    });*/
  }

  ngOnDestroy() {
  }

  openImportModal = 1;

  OpenImportSection(type) {
    const THIS = this;

    // if (window.innerWidth <= 1024) {
    //   return false;
    // }
    this.openImportModal = type;

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#openImportModal')).attr('data-id', THIS.selectedFacility.id);
    (<any>$('#openImportModal')).modal('show');
  }
}
