import { Component } from '@angular/core';
import { FacilityService } from '../../_services/facility.service';
import { FcmService } from '../../_services/fcm.service';
import { CustomHelper } from '../../customhelper';
import { APPCONFIG } from '../../config';
import { Router } from '@angular/router';

declare let swal: any;
declare let toastr: any;
declare let Ladda: any;
declare let Pusher: any;

@Component({
  selector: 'page-inspection',
  templateUrl: './inspection.component.html',
  styles: []
})
export class InspectionComponent {
  // =======================
  // General Variables
  // =======================
  currentUser;
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  Permission = -1;
  Clickable = 1;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true};

  restoreAlert = false;

  // =======================
  // Pagination & Data
  // =======================
  pageNo = 1;
  perPage = this.CustomHelper.getPerpage();
  pageEvent;

  paginateList() {
    if (this.pageEvent.pageSize == this.perPage) {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = this.pageEvent.pageIndex + 1;
      this.getWorkOrder();
      localStorage.perpage = this.perPage;
    } else {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = 1;
      this.getWorkOrder();
      localStorage.perpage = this.perPage;
    }
  }

  workOrderPagination = 0;
  totalWorkOrders = 0;
  workSections = {
    miniScreen: 0,
    overview: 0,
    singlePreview: 0,
    singlePreviewEdit: 0,
    createForm: 0,
    createRecurringForm: 0,
    createEmail: 0
  };
  workOrder = [];
  selectedInvoice = [];
  selectedIde = [];
  previewList = 1;
  facilityEmpty = 0;

  AllEnableFacility() {
    let ldd = document.getElementById('allEnableFac');
    let ladda = Ladda.create(ldd);
    ladda.start();
    this.facilityService.enableAll(0).subscribe(res => {
      ladda.stop();
      this.facilityService.refreshFacility();
    });
  }

  wo_is_active = 1;
  order_by = 'desc';
  created_by = 0;

  checkAssigned() {
    this.created_by = this.created_by == 0 ? this.created_by = this.currentUser.id : this.created_by = 0;
    this.getWorkOrder();
  }

  getWorkOrder() {
    let THIS = this;
    this.previewList = 0;
    let param = {
      sort_by: this.sort_by,
      order_by: this.order_by,
      is_active: this.wo_is_active,
      keyword: this.searchKeyword,
      pageNo: this.pageNo,
      perPage: this.perPage,
      status: this.statusCheck,
      createdBy: this.created_by,
      type: 3
    };
    localStorage.perPageWO = this.perPage;
    localStorage.sort_byWO = this.sort_by;
    localStorage.order_byWO = this.order_by;
    this.workOrder.length = 0;
    this.facilityEmpty = 0;
    // if(localStorage.childWork != undefined){ let id = localStorage.childWork; param.id = id; }
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'get-list?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.previewList = 1;
        if (res.status == 2000 && res.data.length > 0) {
          let Data = [];
          $.each(res.data, function (i, v) {
            v['uid'] = THIS.CustomHelper.makeStr(v['uid']);
            v['dates']['creation_date_tz'] = THIS.CustomHelper.formatDate(v.dates.creation_date);
            v['dates']['creation_date'] = THIS.CustomHelper.DateTimeSelectedFormat(v.dates.creation_date);
            v['dates']['due_at_tz'] = v.dates.due_at != null ? THIS.CustomHelper.formatDate(v.dates.due_at) : '';
            v['dates']['due_at'] = v.dates.due_at != null ? THIS.CustomHelper.DateTimeSelectedFormat(v.dates.due_at) : '';
            v['progress'] = v.progress > 100 ? 100 : v.progress;
            v['progress'] = v.progress < 0 ? 0 : v.progress;
            Data.push(v);
          });

          THIS.workOrder = Data;
          THIS.totalWorkOrders = res.total;
          THIS.workOrderPagination = 1;
          setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
          }, 100);
        } else if (res.status == 400) {
          THIS.facilityEmpty = 1;
          THIS.workOrder.length = 0;
          THIS.totalWorkOrders = 0;
          THIS.workOrderPagination = 1;
        } else {
          THIS.workOrder.length = 0;
          THIS.totalWorkOrders = 0;
          THIS.workOrderPagination = 1;
        }
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      }
    });
    this.getWorkOrderStatus();
  }

  getWorkOrderQuick() {
    let THIS = this;
    let param = {
      sort_by: this.sort_by,
      order_by: this.order_by,
      is_active: this.wo_is_active,
      keyword: this.searchKeyword,
      pageNo: this.pageNo,
      perPage: this.perPage,
      createdBy: this.created_by,
      type: 3
    };
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'get-list?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.previewList = 1;
        if (res.status == 2000 && res.data.length > 0) {
          let Data = [];
          $.each(res.data, function (i, v) {
            v['uid'] = THIS.CustomHelper.makeStr(v['uid']);
            v['dates']['creation_date_tz'] = THIS.CustomHelper.formatDateTimeDynamic(v.dates.creation_date);
            v['dates']['creation_date'] = THIS.CustomHelper.formatDateTime(v.dates.creation_date);
            v['dates']['due_at_tz'] = v.dates.due_at != null ? THIS.CustomHelper.formatDateTimeDynamic(v.dates.due_at) : '';
            v['dates']['due_at'] = v.dates.due_at != null ? THIS.CustomHelper.formatDateTime(v.dates.due_at) : '';
            v['progress'] = v.progress > 100 ? 100 : v.progress;
            v['progress'] = v.progress < 0 ? 0 : v.progress;
            Data.push(v);
          });

          THIS.workOrder = Data;
          THIS.totalWorkOrders = res.total;
          THIS.workOrderPagination = 1;
          setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
          }, 100);
        } else {
          THIS.workOrder.length = 0;
          THIS.totalWorkOrders = 0;
          THIS.workOrderPagination = 1;
        }
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      }
    });
  }

  statusFilter = {
    default: [],
    custom: []
  };
  statusCheck = [];

  updateStatusCheck(status) {
    this.selectedIde = [];
    if (status == -2) {
      if (this.wo_is_active == 2) {
        this.statusCheck.length = 0;
        this.wo_is_active = 1;
      } else {
        this.statusCheck.length = 0;
        this.wo_is_active = 2;
      }
    } else if (status == -1) {
      if (this.wo_is_active == 0) {
        this.statusCheck.length = 0;
        this.wo_is_active = 1;
      } else {
        this.statusCheck.length = 0;
        this.wo_is_active = 0;
      }
    } else if (status == 0) {
      this.statusCheck.length = 0;
      this.wo_is_active = 1;
    } else {
      this.wo_is_active = 1;
      let index = this.statusCheck.indexOf(status);
      if (index > -1) {
        this.statusCheck.splice(index, 1);
      } else {
        this.statusCheck.push(status);
      }
    }
    const StatusHistory = {
      ins: {
        wo_is_active: this.wo_is_active,
        statusCheck: this.statusCheck,
      },
    };
    localStorage.setItem('ins_status', JSON.stringify(StatusHistory));
    this.ToggleFilter('1');
    this.getWorkOrder();
  }

  CounterStatus = {
    'total': 0,
    'archived': 0,
    'trash': 0,
  };

  getWorkOrderStatus() {
    let THIS = this;
    let param = {
      sort_by: this.sort_by,
      order_by: this.order_by,
      is_active: this.wo_is_active,
      keyword: this.searchKeyword,
      pageNo: this.pageNo,
      perPage: this.perPage,
      createdBy: this.created_by,
      type: 3
    };
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'get/inspection/status?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        console.log(res);
        if (res.status == 2000) {
          THIS.statusFilter.default.length = 0;
          THIS.statusFilter.custom.length = 0;
          $.each(res.data, function (i, v) {
            if (v.owner_id == 0) {
              THIS.statusFilter.default.push(v);
            } else {
              THIS.statusFilter.custom.push(v);
            }
          });
          THIS.CounterStatus = {
            'total': res.total,
            'archived': res.archived,
            'trash': res.trash,
          };
        }
      }
    });
    $.each(THIS.SortOptions, function (i, v) {
      if (v.slug == THIS.sort_by) {
        THIS.SortTitle = v.title;
      }
    });
  }


  // =======================
  // Search & Filter
  // =======================
  searchKeyword = '';

  searchKeywordClear(e) {
    this.searchKeyword = '';
    this.getWorkOrder();
  }

  filter(): void {
    let THIS = this;
    clearInterval(THIS.CustomHelper.woListSearch);
    THIS.CustomHelper.woListSearch = setInterval(function () {
      clearInterval(THIS.CustomHelper.woListSearch);
      THIS.pageNo = 1;
      THIS.getWorkOrder();
    }, 400);
  }

  highlighter(subject) {
    if (subject == '' || subject == null) {
      return subject;
    } else {
      subject = this.CustomHelper.makeStr(subject);
    }
    let searchKeyword = this.searchKeyword.replace(/\)/g, '\\)');
    let matches = subject.match(new RegExp(searchKeyword, 'gi'));
    let res = '';
    $.each(matches, function (i, v) {
      res = subject.replace(v, '<span class=\'text-warning-highlight\'>' + v + '</span>');
    });
    if (res == '') {
      res = subject;
    }
    return res;
  }

  FilterOn = 0;
  FilterScroll = 235;

  ToggleFilter(c) {
    if (this.wo_is_active != 0) {
      this.FilterScroll = 235;
      if (this.workSections.miniScreen == 1) {
        this.FilterScroll = 290;
      }
    } else {
      this.FilterScroll = 275;
      if (this.workSections.miniScreen == 1) {
        this.FilterScroll = 290;
      }
    }
  }

  SortTitle = 'Created Date';
  sort_by = 'creation_date';
  SortOptions = [
    { title: 'Created Date', slug: 'creation_date' },
    { title: 'Inspection ID', slug: 'uid' },
    { title: 'Status', slug: 'status_code' },
    { title: 'Subject', slug: 'subject' },
    { title: 'Facility', slug: 'facility' },
    { title: 'Due Date', slug: 'due' },
  ];
  DateDensity = 1; // 1 = Due Date, 2. Creation Date
  Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
  changeDensity(density) {
    let THIS = this;
    this.Density = density;
    localStorage.DensityWO = this.Density;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    let param = { wo: '' };
    density == 1 ? param.wo = 'd' : '';
    density == 2 ? param.wo = 'cf' : '';
    density == 3 ? param.wo = 'cp' : '';
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'me/density?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
      }
    });
  }


  // =======================
  // Actions
  // =======================
  selectedSingleWork = { uid: '' };
  statusUpdate = 0;

  updateThisWorkStatus(status, id) {
    let ldd = document.getElementById('statusUpdate');
    let ladda = Ladda.create(ldd);
    let THIS = this;
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.apiRoot + 'inspections/edit?token=' + THIS.currentUser.token,
      data: { status_code: status, id: id },
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.statusUpdate = 1;
        THIS.tmlThis = 1;
        setTimeout(function () {
          THIS.statusUpdate = 0;
          THIS.tmlThis = 0;
        }, 1000);
        // Pusher emit
      }
    });
  }

  statusUpdateEmit(data) {
    this.updateThisWorkStatus(data.status, data.id);
  }

  removeWork = {
    submitted: 0
  };
  selectedForAction = [];

  undoRemoveThisWork(ids) {
    let ldd = document.getElementById('laodingRestore');
    let ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'erase/undo/remove?token=' + THIS.currentUser.token,
      data: { ids: ids },
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.discardCreateInvoice();
        (<any>$('#RestoreModal')).modal('hide');
        toastr.success('This inspection has been restored successfully', 'Inspection Restore', this.toastrOption);
        // Pusher emit
      }
    });
  }
  restoreThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#RestoreModal')).modal('show');
  }

  emptyTrash() {
    let ldd = document.getElementById('loadingTrashPermanent');
    let ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'empty/trash?token=' + THIS.currentUser.token,
      data: {},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.selectedIde.length = 0;
        THIS.getWorkOrder();
        toastr.success('Trash has been cleared permanently', 'Empty Trash', this.toastrOption);
        (<any>$('#EmptyTrashModal')).modal('hide');
        // Pusher emit
      }
    });
  }
  emptyThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#EmptyTrashModal')).modal('show');
  }
  removeThisWork(id: any = undefined) {
    let ldd = document.getElementById('removeWorkOrderBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    let ids = (id !== undefined) ? [id] : THIS.selectedIde;
    THIS.removeWork.submitted = 1;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'erase/remove?token=' + THIS.currentUser.token,
      data: { ids: ids },
      type: 'post',
      success: function (res) {
        ladda.stop();
        if (res.status === 2000) {
          THIS.discardCreateInvoice();
          // THIS.undoRemoveThisWork(ids);
          THIS.removeWork.submitted = 0;
          THIS.selectedIde.length = 0;
          (<any>$('#deleteWorkOrderModal')).modal('hide');
          toastr.success('This inspection has been deleted successfully', 'Inspection Delete', this.toastrOption);
        } else {
        }
      }
    });
  }

  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }

  undoArchiveThisWork(ids) {
    let ldd = document.getElementById('loadingUnarchive');
    let ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'erase/undo/archive?token=' + THIS.currentUser.token,
      data: { ids: ids },
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.discardCreateInvoice();
        THIS.selectedIde.length = 0;
        (<any>$('#UnarchiveModal')).modal('hide');
        toastr.success('This inspection has been unarchived successfully', 'Inspection Unarchive', this.toastrOption);
        // Pusher emit
      }
    });
  }
  unarchiveThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }

    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#UnarchiveModal')).modal('show');
  }

  archiveThisWork(id: any = undefined) {
    let ldd = document.getElementById('SaveThisLadda');
    let ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    let ids = (id !== undefined) ? [id] : THIS.selectedForAction;
    THIS.removeWork.submitted = 1;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'erase/archive?token=' + THIS.currentUser.token,
      data: { ids: ids },
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.discardCreateInvoice();
        // THIS.undoArchiveThisWork(ids);
        THIS.selectedIde.length = 0;
        (<any>$('#ArchiveModal')).modal('hide');
        toastr.success('This inspection has been archived successfully', 'Inspection Archive', this.toastrOption);
        // Pusher emit
      }
    });
  }
  archiveThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#ArchiveModal')).modal('show');
  }

  activeThisWork(id: any = undefined) {
  /*  swal({
      title: 'Active Work Order',
      text: 'Are you sure you want to active this work order?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {

      } else {
        swal.stopLoading();
        swal.close();
      }
    });*/
    let THIS = this;
    let ids = (id !== undefined) ? [id] : THIS.selectedIde;
    THIS.undoArchiveThisWork(ids);
  }

  exportToXls() {
    let THIS = this;
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += 'ID,Subject,Contact,Facility,Asset,Job Type,Status,Created,Due Date,Priority,Creator' + '\r\n';
    $.each(THIS.workOrder, function (i, work) {
      csvContent += work.prefix + '-' + work.work_order_id + ',';
      csvContent += work.subject + ',';

      csvContent += work.contact_id + ',';
      if (work.facility.length > 0) {
        $.each(work.facility, function (j, facility) {
          csvContent += facility.name + ' | ';
        });
        csvContent += ',';
      } else {
        csvContent += '-,';
      }
      csvContent += work.asset != null ? work.asset.name + ',' : '-,';
      csvContent += work.job_type != null ? work.job_type.name + ',' : '-,';
      if (work.status_code.code != undefined) {
        work.status_code.code == 1 ? csvContent += 'Draft,' : '';
        work.status_code.code == 2 ? csvContent += 'New,' : '';
        work.status_code.code == 3 ? csvContent += 'In Progress,' : '';
        work.status_code.code == 4 ? csvContent += 'Awaiting Approval,' : '';
        work.status_code.code == 5 ? csvContent += 'Complete,' : '';
        work.status_code.code == 6 ? csvContent += 'Cancelled,' : '';
      } else {
        csvContent += '-,';
      }
      csvContent += work.dates.creation_date + ',';
      csvContent += work.dates.due_at + ',';
      csvContent += work.priority != null ? work.priority.name + ',' : '-,';
      csvContent += work.client.first_name + ' ' + work.client.last_name + ',';
      csvContent += '\r\n';
    });
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'work_orders.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  duplicateWorkOrder(id: any = null) {
    let ldd = document.getElementById('loadingDuplicate');
    let ladda = Ladda.create(ldd);
    ladda.start();
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      let freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        let freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    let THIS = this;
    id = (this.selectedIde[0] !== undefined) ? this.selectedIde[0] : id;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'duplicate?token=' + THIS.currentUser.token,
      data: { id: id },
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.getWorkOrder();
        THIS.selectedIde.length = 0;
        (<any>$('#DuplicateModal')).modal('hide');
        toastr.success('This inspection has been duplicated successfully', 'Inspection Duplicate', this.toastrOption);
        // Pusher emit
        // swal({
        //   title: 'Duplicate Inspection',
        //   text: 'Inspection has been successfully duplicated',
        //   icon: 'success',
        //   buttons: ['Undo', 'Ok'],
        //   dangerMode: true,
        // }).then((willDelete) => {
        //   if (willDelete) {
        //     swal.stopLoading();
        //     swal.close();
        //   } else {
        //     let ids = [res.id];
        //     $.ajax({
        //       url: THIS.APPCONFIG.apiInc + 'erase/remove?token=' + THIS.currentUser.token,
        //       data: {ids: ids},
        //       type: 'post',
        //       success: function (res) {
        //         swal({
        //           title: 'Undo of duplication of Inspection is done.',
        //           className: 'swal-btn-center'
        //         });
        //         // Pusher emit
        //       }
        //     });
        //   }
        // });
      }
    });
  }
  duplicateThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#DuplicateModal')).modal('show');
  }

  shortLinkWo = null;
  shortLinkShared = 0;
  shared_edit = 0;
  shortLinkUrl = '';
  shortLinkUrlCopied = 0;

  generateShortLink() {
    this.shortLinkUrl = '';
    let id = this.selectedIde[0];
    let index = this.workOrder.map(function (e) {
      return e.id;
    }).indexOf(id);
    let work = this.workOrder[index];
    let Session = JSON.parse(localStorage.currentUserInfo);
    this.shortLinkWo = work;
    this.shortLinkShared = work.shared != undefined ? work.shared : 0;
    this.shared_edit = work.shared_edit != undefined ? work.shared_edit : 0;
    this.shortLinkUrl = this.APPCONFIG.shortLink + '/' + Session.team + '/i/' + work.short_code;

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#shortLinkModal')).modal('show');
  }

  allowCopyClip() {
    let THIS = this;
    THIS.shortLinkShared == 1 ? THIS.shortLinkShared = 0 : THIS.shortLinkShared = 1;
    if (THIS.shortLinkShared == 0) {
      THIS.shared_edit = 0;
    }
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'shared/enable?token=' + THIS.currentUser.token,
      data: { id: THIS.shortLinkWo.id, shared_edit: THIS.shared_edit, shared: THIS.shortLinkShared },
      type: 'post',
      success: function (res) {
        THIS.getWorkOrder();
      }
    });
  }

  allowEditCopyClip() {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'shared/enable?token=' + THIS.currentUser.token,
      data: { id: THIS.shortLinkWo.id, shared_edit: THIS.shared_edit, shared: THIS.shortLinkShared },
      type: 'post',
      success: function (res) {
      }
    });
  }

  copyToClipboard() {
    let copyTextarea = $('#shortLinkUrl');
    copyTextarea.focus();
    copyTextarea.select();
    try {
      document.execCommand('copy');
      this.shortLinkUrlCopied = 1;
    } catch (err) {
      this.shortLinkUrlCopied = -1;
    }
  }


  wo2invTrigger = 0;
  wo2in = 0;
  rcti = 0;

  selectedInvoiceUpdate(id) {
    this.selectedIde.length = 0;
    this.selectedIde = [id];
  }

  workToInvoice() {
    let THIS = this;
    let id = this.selectedIde[0];
    let index = this.workOrder.map(function (e) {
      return e.id;
    }).indexOf(id);
    let work = this.workOrder[index];
    this.previewCurrentWork = work.prefix + '-' + work.work_order_id;
    setTimeout(function () {
      THIS.rcti = 0;
      THIS.wo2in = 1;
      THIS.wo2invTrigger = 1;
      setTimeout(function () {
        THIS.wo2in = 0;
        THIS.wo2invTrigger = 0;
        THIS.rcti = 0;
      }, 500);
    }, 400);
    $('.drop-menu-right').removeClass('open');
  }

  workToRCTI() {
    let THIS = this;
    let id = this.selectedIde[0];
    let index = this.workOrder.map(function (e) {
      return e.id;
    }).indexOf(id);
    let work = this.workOrder[index];
    this.previewCurrentWork = work.prefix + '-' + work.work_order_id;
    setTimeout(function () {
      THIS.rcti = 1;
      THIS.wo2in = 1;
      THIS.wo2invTrigger = 1;
      setTimeout(function () {
        THIS.wo2in = 0;
        THIS.wo2invTrigger = 0;
        THIS.rcti = 0;
      }, 500);
    }, 400);
    $('.drop-menu-right').removeClass('open');
  }

  selectAllCheck() {
    let THIS = this;
    let WO = this.workOrder;
    if (this.selectedIde.length == this.workOrder.length) {
      this.selectedIde.length = 0;
    } else {
      this.selectedIde.length = 0;
      $.each(WO, function (i, v) {
        THIS.selectedIde.push(v.id);
      });
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  CurrentScreen;

  constructor(private fcmService: FcmService, private facilityService: FacilityService, private router: Router) {


    let THIS = this;
    setTimeout(function () {

      if (THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] != undefined) {
        window.history.pushState('', '', THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute]);
      }

      THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
      console.log(typeof THIS.currentUser.list_view);
      if (THIS.currentUser.permit == 3 || THIS.currentUser.permit == 5 || THIS.currentUser.permit == 7) {
        THIS.created_by = THIS.currentUser.id;
      }

      if (THIS.currentUser.subscription.plan == 'Free' && THIS.currentUser.subscription.status === 1) {
        THIS.router.navigate(['/' + THIS.currentUser.team + '/dashboard']);
      }

      // Pagination
      if (THIS.currentUser.pagination != null) {
        THIS.currentUser.pagination.projects != undefined ? THIS.perPage = THIS.currentUser.pagination.insp : 25;
      } else {
        THIS.perPage = 25;
      }

      if (typeof THIS.currentUser.list_view == 'string') {
        THIS.currentUser.list_view = JSON.parse(THIS.currentUser.list_view);
      }
      // 1 = Default, 2 = Comfortable, 3 = Compact
      THIS.currentUser.list_view.wo.d == 1 ? THIS.Density = 1 : '';
      THIS.currentUser.list_view.wo.cf == 1 ? THIS.Density = 2 : '';
      THIS.currentUser.list_view.wo.cp == 1 ? THIS.Density = 3 : '';
      if (localStorage.ins_status != undefined) {
        const StatusHistory = JSON.parse(localStorage.ins_status);
        THIS.wo_is_active = StatusHistory.ins.wo_is_active;
        THIS.statusCheck = StatusHistory.ins.statusCheck;
      }

      THIS.facilityService.currentSelectedFac.subscribe(value => THIS.getWorkOrder());
      THIS.routeMake();
      THIS.CurrentScreen = window.innerWidth;
      if (window.innerWidth <= 1200) {
        THIS.workSections.miniScreen = 1;
        THIS.FilterScroll = 290;
      }
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      THIS.Permission = Session.user_type > 2 ? Session.modules.inspections : 1;
      if (localStorage.WoPreId != undefined) {
        THIS.previewWorkUrl(localStorage.WoPreId);
        delete localStorage.WoPreId;
      }
      ;
    }, 500);

  }

  dropMenuRightToggle(e) {
    let container = $('.invoice-list');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $('.drop-menu-right').removeClass('open');
    }
  }

  // =======================
  // Routes & Preview
  // =======================
  ROUTES = '/inspections';

  routeMake() {
    let route = this.CustomHelper.getRouteStr();
    this.refreshView();
    if (route[2] != undefined) {
      if (route[2] == '') {
        this.workSections.overview = 1;
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
      } else if (route[2] == 'new') {
        this.createNewWork();
      } else if (route[2] == 'edit') {
        if (route[3] != undefined) {
          let preId = route[3];
          this.singleEditInvoice(preId);
        }
      } else {
        let preId = route[2];
        this.previewWorkUrl(preId);
      }
    } else {
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      let url = '/' + Session.team + this.ROUTES;
      window.history.pushState('', '', url);
      this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
      this.workSections.overview = 1;
    }
  }

  refreshView() {
    let THIS = this;
    this.workSections.overview = 0;
    this.workSections.singlePreview = 0;
    this.workSections.singlePreviewEdit = 0;
    this.workSections.createForm = 0;
    this.workSections.createEmail = 0;
    clearInterval(THIS.CustomHelper.woTimeCount);
    this.getWorkOrder();
  }

  createNewWork() {
    this.refreshView();
    this.workSections.createForm = 1;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES + '/new';
    window.history.pushState('', '', url);
    this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    this.CustomHelper.woRouteDiscard = 0;
  }

  createNewWorkRe() {
    this.refreshView();
    let THIS = this;
    setTimeout(function () {
      THIS.workSections.createForm = 1;
    }, 400);
  }

  printThis = 0;
  printThisLoading = 0;

  printThisWorkOrder() {
    let THIS = this;
    THIS.printThisLoading = 1;
    let id = this.selectedIde[0];
    let index = this.workOrder.map(function (e) {
      return e.id;
    }).indexOf(id);
    let work = this.workOrder[index];
    this.previewWork(work);
    setTimeout(function () {
      THIS.printThisLoading = 1;
      THIS.printThis = 1;
      setTimeout(function () {
        THIS.printThis = 0;
      }, 400);
    }, 400);
    $('.drop-menu-right').removeClass('open');
  }

  tmlThis = 0;
  pdfThis = 0;
  pdfId = null;
  workDetailsChange = 0;
  workDetails = null;

  pdfThisWorkOrder() {
    let THIS = this;
    let id = this.selectedIde[0];
    window.open(THIS.APPCONFIG.apiInc + 'make/pdf/' + id);
  }

  zipThisWorkOrder() {
    let THIS = this;
    let ids = this.selectedIde.join();
    let win = window.open(THIS.APPCONFIG.apiInc + 'zip/image/' + ids + '?token=' + THIS.currentUser.token, '_blank');
    win.focus();
  }

  mailThisWo() {
    let THIS = this;
    let id = this.selectedIde[0];
    let index = this.workOrder.map(function (e) {
      return e.id;
    }).indexOf(id);
    let work = this.workOrder[index];
    let param = {
      id: work.id,
      uid: work.uid,
      type: 'inspection',
      team: this.currentUser.team,
      short_code: work.short_code,
      subject: work.subject,
    };
    console.log(param);
    $('#sendMailModalTrigger').attr('data-type', JSON.stringify(param));
    $('#sendMailModalTrigger').click();
  }

  editOptionForm = 0;

    singlePreviewInvoice(e, index, work) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.which == 1) {
        if (this.selectedIde.indexOf(work.id) > -1) {
          this.selectedIde.splice(this.selectedIde.indexOf(work.id), 1);
        } else {
          this.selectedIde.push(work.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    } else {
      if (e.which == 1) {
        // this.selectedIde = [work.id];
        this.selectedSingleWork = work;
        this.previewWork(work);
        $('.drop-menu-right').removeClass('open');
      } else if (e.which == 3) {
        this.selectedIde = [work.id];
        this.selectedSingleWork = work;
        let target = $(e.target);
        let w = target.closest('#right-click-holder').width();
        let h = target.closest('#right-click-holder').height();
        let p = target.closest('#right-click-holder').offset();
        let left = e.clientX - p.left;
        let top = e.clientY - p.top;
        let clickH = $('.drop-menu-right').height();
        clickH = clickH < 150 ? 400 : clickH;
        if (this.selectedIde.length > 1) {
          clickH = 150;
        }
        if ((w - left) < 230) {
          left = w - 250;
        }
        if (h < top + clickH) {
          top = top - (top + clickH - h);
        }
        if (top < 10) {
          top = 10;
        }

        let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
        if (ttarget.hasClass('open')) {
          $('.drop-menu-right').removeClass('open');
          ttarget.css({
            top: top,
            left: left
          });
          setTimeout(function () {
            ttarget.addClass('open');
          }, 100);
        } else {
          ttarget.css({
            top: top,
            left: left
          });
          ttarget.addClass('open');
        }
      }
    }

    let THIS = this;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  selectThisRow(e, index, work) {
    e.preventDefault();
    let THIS = this;
    if (e.ctrlKey) {
      if (e.which == 1) {
        if (this.selectedIde.indexOf(work.id) > -1) {
          this.selectedIde.splice(this.selectedIde.indexOf(work.id), 1);
        } else {
          this.selectedIde.push(work.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);

  }

  checkThisRow(e, index, work) {
    e.preventDefault();
    let THIS = this;
    let iIndex = this.selectedIde.indexOf(work.id);
    if (iIndex > -1) {
      if (this.selectedIde.length == 1) {
        this.selectedIde.length = 0;
      } else {
        this.selectedIde.splice(iIndex, 1);
      }
    } else {
      this.selectedIde.push(work.id);
    }
    $('.drop-menu-right').removeClass('open');
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);

  }

  previewCurrentWork = '';

  previewWorkUrl(uid) {
    this.refreshView();
    let THIS = this;
    THIS.editOptionForm = 0;
    THIS.workSections.singlePreview = 1;
    THIS.previewCurrentWork = uid;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES + '/' + uid;
    window.history.pushState('', '', url);
    THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
  }

  previewWork(work) {
    let THIS = this;
    if (this.wo_is_active != 0 && this.wo_is_active != 2) {
      this.CustomHelper.woRouteDiscard = 0;
      let index = THIS.CustomHelper.ConfRoute.map(function (e) {
        return e.tab;
      }).indexOf(THIS.CustomHelper.ConfCurRoute);
      if (index > -1) {
        // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
      }
      if (this.CustomHelper.woRouteDiscard == 1) {
        swal({
          title: 'Are you sure you want to leave?',
          text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
          icon: 'warning',
          buttons: ['CANCEL', 'LEAVE'],
          dangerMode: true,
        }).then((res) => {
          if (res) {
            THIS.Clickable = 0;
            THIS.refreshView();
            THIS.editOptionForm = 0;
            THIS.workSections.singlePreview = 1;
            THIS.previewCurrentWork = work.uid;
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + this.ROUTES + '/' + work.uid;
            window.history.pushState('', '', url);
            THIS.CustomHelper.woRouteDiscard = 0;
            THIS.CustomHelper.ConfRoute.splice(index, 1);
            THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
          }
        });
      } else {
        THIS.Clickable = 0;
        THIS.refreshView();
        THIS.previewCurrentWork = '';
        setTimeout(function () {
          THIS.editOptionForm = 0;
          THIS.workSections.singlePreview = 1;
          THIS.previewCurrentWork = work.uid;
        }, 100);
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + this.ROUTES + '/' + work.uid;
        window.history.pushState('', '', url);
        THIS.CustomHelper.woRouteDiscard = 0;
        THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
      }
    } else {
      if(this.wo_is_active == 0){
        this.restoreAlert = true;
      }else{
        this.restoreAlert = false;
      }
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#PreviewAlert')).modal('show');
    }

    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);

  }
    ClosePreviewAlert(){
      (<any>$('#PreviewAlert')).modal('hide');
    }

  previewWorkQuick(work) {
    this.previewWork(work);
  }

  singleEditInvoice(id) {
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    $('.drop-menu-right').removeClass('open');
    if (this.CustomHelper.woRouteDiscard == 1) {

      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          THIS.Clickable = 0;
          THIS.refreshView();
          if (id == null || id == '') {
            id = THIS.selectedSingleWork.uid;
          }
          THIS.editOptionForm = 0;
          THIS.editOptionForm = 1;
          THIS.workSections.singlePreview = 1;
          THIS.previewCurrentWork = id;
          let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
          let url = '/' + Session.team + THIS.ROUTES + '/edit/' + id;
          window.history.pushState('', '', url);
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
          THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
        }
      });

    } else {
      THIS.Clickable = 0;
      THIS.refreshView();
      if (id == null || id == '') {
        id = THIS.selectedSingleWork.uid;
      }
      THIS.editOptionForm = 0;
      THIS.previewCurrentWork = '';
      setTimeout(function () {
        THIS.editOptionForm = 1;
        THIS.workSections.singlePreview = 1;
        THIS.previewCurrentWork = id;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/' + Session.team + THIS.ROUTES + '/edit/' + id;
        window.history.pushState('', '', url);
        THIS.CustomHelper.woRouteDiscard = 0;
        THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;

        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      }, 400);
    }
  }

  discardCreateInvoice() {
    this.presenceAlert();
    let THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (this.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Discard Alert',
        text: 'Are you sure you want to discard?',
        icon: 'warning',
        buttons: ['Stay on this page', 'Leave page'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          THIS.refreshView();
          THIS.workSections.overview = 1;
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);

          let url = '/' + this.currentUser.team + this.ROUTES;
          window.history.pushState('', '', url);
          THIS.workSections.overview = 1;
          THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
          setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
          }, 100);
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
    } else {
      THIS.refreshView();
      THIS.workSections.overview = 1;
      THIS.CustomHelper.woRouteDiscard = 0;

      let url = '/' + this.currentUser.team + this.ROUTES;
      window.history.pushState('', '', url);
      THIS.workSections.overview = 1;
      THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
      setTimeout(function () {
        THIS.CustomHelper.tooltipRender();
      }, 400);
    }
    this.ToggleFilter(1);
  }

  presenceAlert() {

  }


  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    //===============
    // Pusher
    //===============
    Pusher.logToConsole = true;
    let pusher = new Pusher('501b3b237bd799758df0', {
      cluster: 'ap4'
    });
    let channel = pusher.subscribe('bm-inspections');
    channel.bind('RefreshInspection', function (data) {
      if (Session.team === data.message.team) {
        THIS.getWorkOrder();
      }
    });
    //===============
    // Pusher
    //===============
  }

  ngOnDestroy() {
  }
}
