import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {MediaService} from '../../_services/media.service';

declare let Ladda: any;
declare let swal: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;
declare let toastr: any;


@Component({
    selector: 'page-library',
    templateUrl: './library.component.html'
})
export class LibraryComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Permission = 1;

    updateChangesDiscard() {
        let THIS = this;
    }

    totalLibrary = 0;
    Library = [];

    listLoading = false;
    Recent_files = [];
    pageNo = 1;
    perPage = 25;
    pageEvent;
    order_by = 'desc';
    selectedIds = [];
    libraryType = '';
    checkThisRow(e,library){
        e.preventDefault();
        let THIS = this;
        THIS.libraryType = library.attrs.ext;
        let index = this.selectedIds.indexOf(library.id);
        if(index > -1){
            if(this.selectedIds.length == 1){
                this.selectedIds.length = 0;
            } else {
                this.selectedIds.splice(index, 1);
            }
        } else {
            this.selectedIds.push(library.id);
        }
        $('.drop-menu-right').removeClass('open');
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }
    selectAllCheck(){
        let THIS = this;
        let Library = THIS.Library;
        if(THIS.selectedIds.length == THIS.Library.length){
            THIS.selectedIds = [];
        } else {
            THIS.selectedIds = [];
            $.each(Library, function(i, v){
                THIS.selectedIds.push(v.id);
            });
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }

    paginateList() {
        if (this.pageEvent.pageSize == this.perPage) {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex + 1;
            this.getAllFolders();
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
            this.getAllFolders();
        }
    }

    getAllFolders() {
        let THIS = this;
        this.listLoading = true;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'media/library/get/all?token=' + THIS.currentUser.token,
            data: {
                perPage: THIS.perPage,
                pageNo: THIS.pageNo,
                keyword: THIS.searchKeyword,
            },
            type: 'post',
            success: function (res) {
              THIS.listLoading = false;
                if (res.status == 2000) {
                    THIS.totalLibrary = res.total;
                    THIS.Library = res.data;
                    THIS.Recent_files = res.recent_files;
                }
            }
        });
    }

    currentUser = null;
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true};

    scrollFilter = 490;
    constructor(private mediaService: MediaService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

        /*if (this.currentUser.free_plan === 1) {
          (<any>$('#restrictionModuleModal')).modal('show');
        }*/

        THIS.routeMake();
        if (window.innerWidth <= 768) {
            THIS.miniScreen = 1;
        }
        if (window.innerWidth <= 640) {
            THIS.scrollFilter = 290;
        }

        if (window.innerWidth <= 1500) {
            THIS.mdScreen = 1;
        }

    }

    ngOnInit() {
        this.getAllFolders();
    }

    previewList = 1;

    wo_is_active = 1;
    searchKeyword = '';

    searchKeywordClear() {
        this.searchKeyword = '';
        this.getAllFolders();
    }

    filter() {
        let THIS = this;
        clearInterval(THIS.CustomHelper.woListSearch);
        THIS.CustomHelper.woListSearch = setInterval(function () {
            clearInterval(THIS.CustomHelper.woListSearch);
            THIS.getAllFolders();
        }, 1000);
    }

    highlighter(subject) {
        if (subject == '' || subject == null) {
            return subject;
        } else {
            subject = this.CustomHelper.makeStr(subject);
        }
        let matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
        let res = '';
        $.each(matches, function (i, v) {
            res = subject.replace(v, "<span class='text-warning-highlight'>" + v + "</span>");
        });
        if (res == '') {
            res = subject;
        }
        return res;
    }

    FilterOn = 0;
    FilterScroll = 246;

    ToggleFilter(c) {
        if (c == '') {
            this.FilterOn = this.FilterOn == 1 ? 0 : 1;
        }
        let alrtH = 0;
        if (this.wo_is_active != 0) {
            alrtH = 0;
        } else {
            alrtH = 50;
        }
        if (this.Template == 0 && this.miniScreen == 0) {
            if (this.wo_is_active != 0) {
                this.FilterScroll = 246;
            } else {
                this.FilterScroll = 290;
            }
        } else {
            if (this.FilterOn == 1) {
                this.FilterScroll = 290 + alrtH;
            } else {
                this.FilterScroll = 246 + alrtH;
            }
        }
    }

    Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
    changeDensity(density) {
        let THIS = this;
        this.Density = density;
        localStorage.DensityAss = this.Density;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
        let param = {asset: ''};
        density == 1 ? param.asset = 'd' : '';
        density == 2 ? param.asset = 'cf' : '';
        density == 3 ? param.asset = 'cp' : '';
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'me/density?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
            }
        });
    }

    Template = 2;
    miniScreen = 0;
    ROUTES = '/library';
    preId = '';

    routeMake() {
        let route = this.CustomHelper.getRouteStr();
        if (route[2] != undefined) {

        } else {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/' + Session.team + this.ROUTES;
            window.history.pushState("", "", url);
            this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
            this.Template = 0;
        }
    }


    createNewFolderModal() {
      $('.name-error-show').html('');
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#createFolderModal')).modal('show');
    }

    selectedLibrary = null;
    PreviewLibrary = null;
    mdScreen = 0;

    openThisLibrary(library) {
        if (library.activity == 1) {
            this.PreviewLibrary = library;
            this.Template = 1;
            this.mdScreen = 1;
        } else {
            if (library.photo == 1) {
                this.open(library);
            } else {
                this.CustomHelper.openDocViewer(this.APPCONFIG.uploadPath + '/attachments/' + library.file_path);
            }
        }
    }
    rightlibraryInfo = null;
    quickPreviewLibrary(library) {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'media/library/get/single?token=' + THIS.currentUser.token,
            data: {id: library.id},
            type: 'post',
            success: function (res) {
                if (res.status == 2000) {
                    THIS.rightlibraryInfo = res.data;
                }
                setTimeout(() => {
                    (<any>$('[data-toggle="tooltip"]')).tooltip();
                }, 500);
            }
        });
    }

    quickPreviewLibraryMobile(library) {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'media/library/get/single?token=' + THIS.currentUser.token,
            data: {id: library.id},
            type: 'post',
            success: function (res) {
                if (res.status == 2000) {
                    THIS.rightlibraryInfo = res.data;
                }
                setTimeout(() => {
                    (<any>$('[data-toggle="tooltip"]')).tooltip();
                }, 500);

              let ___selectedTab = 0;
              let tabs = $('#sort-tabs').find('li');
              tabs.each(function (i, v) {
                if ($(v).find('a.active').length > 0) {
                  ___selectedTab = i;
                }
              });
              let child = ___selectedTab + 1;
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              $('#sort-tabs').find('li').find('a').removeClass('active');
              $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
              (<any>$('#viewModal')).modal('show');
            }
        });
    }

    confirmDiscard(template) {
        this.Template = template;
        this.mdScreen = 0;
        this.getAllFolders();
    }

    isFolder = '';

    rightClickMenu(e, library) {
        e.preventDefault();
        this.selectedLibrary = library;
        this.isFolder = library.activity;
        this.selectedIds.push(library.id);
        let target = $(e.target);
        let w = target.closest('#right-click-holder').width();
        let h = target.closest('#right-click-holder').height();
        let p = target.closest('#right-click-holder').offset();
        let left = e.clientX - p.left;
        let top = e.clientY - p.top;
        let clickH = $('.drop-menu-right').height();

        let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
        if (ttarget.hasClass('open')) {
            $('.drop-menu-right').removeClass('open');
            ttarget.css({
                top: top,
                left: left
            });
            setTimeout(function () {
                ttarget.addClass('open');
            }, 100);
        } else {
            ttarget.css({
                top: top,
                left: left
            });
            ttarget.addClass('open');
        }
    }

    dropMenuRightToggle(e) {
        let container = $('.invoice-list');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.drop-menu-right').removeClass('open');
        }
    }

    newFolder = '';

    createNewFolder() {
        let THIS = this;
        if(THIS.newFolder == '' ){
          $('.name-error-show').html('Name field is required');
          return;
        }
        let ldd = document.getElementById('folderCreate');
        let ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'media/library/create/folder?token=' + THIS.currentUser.token,
            data: {title: THIS.newFolder},
            type: 'post',
            success: function (res) {
              $('.name-error-show').html('');
                ladda.stop();
                if (res.status == 2000) {
                    THIS.newFolder = '';
                    THIS.getAllFolders();
                    toastr.success(res.msg, 'Folder Created', this.toastrOption);
                    (<any>$('#createFolderModal')).modal('hide');
                }
            }
        });
    }
  closeCreateFolderModal(){
    (<any>$('#createFolderModal')).modal('hide');
    this.newFolder = '';
  }
    editFolderModal() {
        let THIS = this;
        if(THIS.libraryType == 'folder'){
          if(this.selectedIds.length == 1){
            let index = this.Library.map(function(e) { return e.id; }).indexOf(THIS.selectedIds[0]);
            this.selectedLibrary = JSON.parse(JSON.stringify(this.Library[index]));
          }
          let ___selectedTab = 0;
          let tabs = $('#sort-tabs').find('li');
          tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
              ___selectedTab = i;
            }
          });
          let child = ___selectedTab + 1;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          $('#sort-tabs').find('li').find('a').removeClass('active');
          $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
          (<any>$('#editFolderModal')).modal('show');
        }
    }

    editFolder() {
        if (this.selectedLibrary != null) {
            let THIS = this;
            let ldd = document.getElementById('folderEdit');
            let ladda = Ladda.create(ldd);
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.apiAuth + 'media/library/edit/folder?token=' + THIS.currentUser.token,
                data: {title: THIS.selectedLibrary.attrs.name, id: THIS.selectedLibrary.id},
                type: 'post',
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                        THIS.selectedLibrary = null;
                        THIS.selectedIds = [];
                        THIS.getAllFolders();
                        (<any>$('#editFolderModal')).modal('hide');
                    }
                }
            });
        }
    }

    deleteFolderModal() {
        let THIS = this;
        const ldd = document.getElementById('removeWorkOrderBtn');
        let ladda = Ladda.create(ldd);
        ladda.start();
        if (this.selectedLibrary != null || this.selectedIds.length) {
            let ids = [];
            if(this.selectedIds.length === 0){
                ids = [THIS.selectedLibrary.id]
            } else {
                ids = THIS.selectedIds
            }
          $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'media/library/delete/folder?token=' + THIS.currentUser.token,
            data: {ids: ids},
            type: 'post',
            success: function (res) {
              ladda.stop();
              THIS.selectedIds.length = 0;
              if (res.status == 2000) {
                THIS.selectedLibrary = null;
                THIS.getAllFolders();
                THIS.rightlibraryInfo = null;
                THIS.selectedIds = [];
                (<any>$('#deleteWorkOrderModal')).modal('hide');
              }
            }
          });
        }
    }
  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }
    DeleteThisFile(id: any) {
        this.rightlibraryInfo = null;
    }
  deleteResLibrary(id: any){
    (<any>$('#viewModal')).modal('hide');
    this.selectedIds = [];
    this.selectedIds.push(id)
    this.deleteThisWorkOrder();
  }


    selectedFiles = [];
    selectedFilesPreview = [];

    removeThisMedia(id, media_type) {
        let param = {
            id: id, media_type: media_type
        };
        this.mediaService.remove(param).subscribe(res => {
        });
    }

    removeThisImage(index, id) {
        this.selectedFiles.splice(index, 1);
        this.selectedFilesPreview.splice(index, 1);
        this.removeThisMedia(id, 1);
    }

    _uploadError = 0;
    _locPermit = 0;

    uploadThisMedia(file, media_type, localtion, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        formData.append('is_folder', "1");
        if (localtion != null) {
            formData.append('lat', localtion.lat);
            formData.append('lon', localtion.lon);
        }
        this.mediaService.create(formData).subscribe(res => {
            this.updateChangesDiscard();
            callback(res);
        });
    }

    addUploadFile() {
        $('#libraryAttach').click();
    }

    uploadLibraryAttach(e) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            let eachFile = accepted[i];
            this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
                if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                        swal({
                            title: "Storage Full",
                            text: 'Your Storage is full. Please delete some files',
                            icon: "error",
                            dangerMode: true,
                            className: 'swal-btn-center'
                        });
                    } else {
                        swal({
                            title: "nvalid file format uploaded",
                            text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allwoed',
                            icon: "error",
                            dangerMode: true,
                            className: 'swal-btn-center'
                        });
                    }
                } else {
                    THIS.getAllFolders();
                }
            });
        }
    }


    UID_IMG = 0;
    Gallery = null;
    Preview;

    open(library) {
        let THIS = this;
        THIS.Preview = library;
        // $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
        let _albums = [];
        const src = APPCONFIG.uploadPreview + '/images?w=1500&img=' + library.file_path + '&ref=' + Date.now();
        const caption = '';
        const thumb = '';
        const album = {
            src: src,
            caption: caption,
            thumb: thumb,
            w: library.width !== undefined ? library.width : 1500,
            h: library.height !== undefined ? library.height : 1500
        };
        _albums.push(album);
        let pswpElement = document.querySelectorAll('.pswp')[0];
        let options = {
            index: 0,
            shareEl: false,
            editEl: false,
            downEl: true,
            tapToClose: false,
            zoomEl: true,
            history: false
        };
        THIS.Gallery = null;
        THIS.Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, _albums, options);
        THIS.Gallery.init();
        // THIS.Gallery.listen('afterChange', function(r) {
        //   THIS.Preview = library.media_files[THIS.Gallery.getCurrentIndex()];
        //   $('#EditImageData').attr('data-type', JSON.stringify(THIS.Preview));
        // });
        history.pushState(null, null, location.href);
        window.onpopstate = function () {
            THIS.Gallery.close();
            history.go(1);
        };

    }


    downloadThisFile() {
        let THIS = this;
        if(this.selectedIds.length == 1){
            let index = this.Library.map(function(e) { return e.id; }).indexOf(THIS.selectedIds[0]);
            this.selectedLibrary = this.Library[index];
        }
        if(THIS.selectedLibrary != null){
            let win = window.open(THIS.APPCONFIG.apiAuth + 'media/library/get/single/download/' + THIS.selectedLibrary.id + '?token=' + THIS.currentUser.token, '_blank');
            win.focus();
            THIS.selectedIds = [];
        }
    }
    DownloadThisFile(id: any) {
      let THIS: any = this;
      THIS.selectedIds.push(id);
      THIS.downloadThisFile();
    }
    downloadResLibrary(id: any){
      (<any>$('#viewModal')).modal('hide');
      this.selectedIds = [];
      this.selectedIds.push(id)
      this.downloadThisFile();
    }

    selectedItemLib = null;
    selectedFolderLib = null;

    onItemDragStart(event) {
        let cls = event.currentTarget.classList.value.split(' ');
        let ind = cls.indexOf('moving');
        if (ind == -1) {
            event.currentTarget.classList += ' moving';
        }
        let id = event.currentTarget.id;
        let index = id.replace('lib-dragg-', '');
        this.selectedItemLib = this.Library[index];

    }

    onItemDragEnd(event) {
        $('.LibraryDrag').removeClass('moving');
        $('.LibraryDrag').removeClass('onitemover');

        let THIS = this;
        if (this.selectedFolderLib != null) {
            var param = {
                id: this.selectedItemLib.id,
                folder_id: this.selectedFolderLib.id
            };
            if (param.id != param.folder_id) {
                this.selectedFolderLib = null;
                $.ajax({
                    url: THIS.APPCONFIG.apiAuth + 'media/library/edit/folder?token=' + THIS.currentUser.token,
                    data: param,
                    type: 'post',
                    success: function (res) {
                        if (res.status == 2000) {
                            THIS.getAllFolders();
                            if (THIS.selectedItemLib.activity == 1) {
                                toastr.success('Your folder has been moved', 'Folder Moved', this.toastrOption);
                            } else {
                                toastr.success('Your file has been moved', 'File Moved', this.toastrOption);
                            }
                        }
                    }
                });
            }
        }

    }

    onItemDragEnter(event) {
        $('.LibraryDrag').removeClass('onitemover');
        let cls = event.currentTarget.classList.value.split(' ');
        let ind = cls.indexOf('onitemover');
        if (ind == -1) {
            event.currentTarget.classList += ' onitemover';
        }

        let id = event.currentTarget.id;
        let index = id.replace('lib-dragg-', '');
        this.selectedFolderLib = this.Library[index];
    }


}
