import {Component} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { APPCONFIG } from './config';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    public AppConfig: any = APPCONFIG;


    // constructor(private router: Router) {
        // Scroll to top on route change
        // this.router.events.subscribe((evt) => { if (!(evt instanceof NavigationEnd)) { return; }
        //     document.body.scrollTop = 0;
        // });
    // }

}
