import {Component, Input, Output, EventEmitter} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {UserService} from '../../../_services/user.service';
import {MediaService} from '../../../_services/media.service';
import {setTime} from "ngx-bootstrap/timepicker/timepicker.utils";
declare let swal: any;
declare let toastr: any;

@Component({
    selector: 'page-personnel-group-create',
    templateUrl: './group-create.component.html'
})
export class GroupCreateComponent {
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};

    inlinePrefixId = 0;
    updateChangesDiscard(){
        // this.CustomHelper.woRouteDiscard = 1;
    }
    confirmDiscard(){
        this.discard.emit(1);
    }
    param = {
        name : '',
        email : '',
        hourly_rate : '',
        address : '',
        phone : '',
        abn : '',
        logo : '',
        is_org : 0,
    };

    validForm = 0;
    saveSubmit(){
        if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
            let freeAccountData = localStorage.getItem('freeAccountData');
            if (freeAccountData != null) {
                let freeAccount = JSON.parse(freeAccountData);
                if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
                    (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
                    (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
                    (freeAccount['user_count'] >= freeAccount['user_limit'])) {
                    $('.openSubscription').click();
                    return false;
                }
            }
        }
        let valid = this.validationThisForm();
        if (valid == 1) {
            if(this.selectedFilesPreview != null){
                this.param.logo = this.selectedFilesPreview.file_path;
            }
            this.userService.createGroup(this.param).subscribe(res => {
                if(res.status == 2000){
                    let THIS = this;
                    toastr.success('New personnel group has been successfully created', 'New Group', THIS.toastrOption);
                    this.preview.emit(res.data);
                } else {
                    swal(res.error);
                }
            });
        }
    }
    validationThisForm() {
        let valid = 1;
        this.checkThisForm();
        if (this.param.name == '') {
            valid = 0;
            swal('Group name is required');
            return valid;
        }
        this.validForm = valid;
        return valid;
    }
    checkThisForm() {
        let valid = 1;
        if (this.param.name == '') {
            valid = 0;
        }

        let check = this.param.email == '' ? true : this.CustomHelper.emailValidation(this.param.email);
        if(!check){
            swal(this.param.email+'- is not a valid email address. Please type valid email address.');
            this.param.email = '';
            valid = 0;
        }
        this.validForm = valid;
        return valid;
    }


    currentUser;
    constructor(private userService: UserService,
                private mediaService: MediaService) {
        let THIS = this;
      this.currentUser = JSON.parse(localStorage.currentUserInfo);
    }


    avatarLoading = 0;
    selectedFilesPreview = null;
    uploadThisMedia(file, media_type, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        this.mediaService.create(formData).subscribe(res => {
            this.updateChangesDiscard();
            callback(res);
        });
    }
    selectTheseFiles(e) {
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            let eachFile = accepted[i];
            if(eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg'){
                THIS.avatarLoading = 1;
                this.uploadThisMedia(eachFile, '1', function (uploaded) {
                    THIS.avatarLoading = 0;
                    THIS.selectedFilesPreview = uploaded;
                });
            } else {
                swal('Only jpg/jpeg and png files are allowed');
            }
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }
    dragFilesDropped(e) {
        e.preventDefault();
        let THIS = this;
        if (e.dataTransfer.files.length > 0) {
            let eachFile = e.dataTransfer.files[0];
            if(eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg'){
                THIS.avatarLoading = 1;
                this.uploadThisMedia(eachFile, '1', function (uploaded) {
                    THIS.avatarLoading = 0;
                    THIS.selectedFilesPreview = uploaded.file_path;
                });
            } else {
                swal('Only jpg/jpeg and png files are allowed');
            }
        }
    }
    removeThisMedia(id, media_type) {
        let param = {
            id: id, media_type: media_type
        };
        this.mediaService.remove(param).subscribe(res => {
        });
    }
    removeThisImage(id) {
        this.selectedFilesPreview = null;
        this.removeThisMedia(id, 1);
    }

}
