import {Component, Input} from '@angular/core';
import {APPCONFIG} from '../../../config';
import {Lightbox} from 'angular2-lightbox';
import {MatTooltipModule} from '@angular/material';
import {QRCodeModule} from 'angular2-qrcode';
import {CustomHelper} from '../../../customhelper';
import {MediaService} from '../../../_services/media.service';

declare let swal: any;
declare let Ladda: any;
declare let toastr: any;
declare let Pusher: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;

@Component({
  selector: 'inspection-export',
  templateUrl: './inspection-export.component.html',
  styles: []
})
export class InspectionExportComponent {

  @Input() workDetails: any;
  APPCONFIG = APPCONFIG;
  _albums = [];
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  currentUser = null;

  constructor(private _lightbox: Lightbox, private QRCodeModule: QRCodeModule, public tooltip: MatTooltipModule, private mediaService: MediaService) {
    let THIS = this;
    this.currentUser = localStorage.getItem('currentUserInfo') != null ? JSON.parse(localStorage.getItem('currentUserInfo')) : null;
    if (this.currentUser != null) {
      this.getAllJobStatus();
    }
  }

  ngOnInit() {
    let THIS = this;
    this.refreshsinglePage(this.workDetails);
  }

  open(index: number): void {
    let THIS = this;
    let pswpElement = document.querySelectorAll('.pswp')[0];
    let options = {
      index: index,
      shareEl: false,
      tapToClose: false,
      zoomEl: true
    };
    let gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, THIS._albums, options);
    gallery.init();
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      gallery.close();
      history.go(1);
    };
  }

  workDetailsChange = 0;
  taskList = [];
  id = 0;
  param_created_at = '';
  param_due = '';
  param = {
    subject: '',
    attach_files: '',
    facility_id: '',
    due: '',
    created_at: '',
    add_to_calendar: false,
    calendar_color: '',
    overall_ratings: 0,
    priority: '0',
    status_code: 1,
    personnel_id: [],
    group_id: [],
    tasks: [],
  };
  progress = 0;
  rightSecPreview = 0;
  SelectedPersonList = [];
  attachInventories = [];

  selectedWorkAttach = [];
  selectedFiles = [];
  selectedFilesPreview = [];

  overall_ratings_allow = 0;

  refreshsinglePage(ResData) {
    let THIS = this;
    let work = ResData;
    THIS.workDetails = work;
    THIS.workDetails.dates.creation_date = THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.creation_date);
    THIS.workDetails.dates.edited_date = THIS.workDetails.dates.edited_date != '' && THIS.workDetails.dates.edited_date != null ? THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.edited_date) : '';

    // THIS.checkOverRateAllow();
    THIS.workDetails.facStr = THIS.workDetails.facStr.reverse();
    THIS._albums.length = 0;
    if (THIS.workDetails.media_files !== undefined) {
      for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
        const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path;
        const caption = '';
        const thumb = '';
        const album = {
          src: src,
          caption: caption,
          thumb: thumb,
          w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
          h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
        };
        THIS._albums.push(album);
      }
    }

    THIS.id = work.id;
    THIS.param.subject = work.subject;
    THIS.param.facility_id = work.facility.id.toString();
    THIS.param.status_code = work.status_code.id.toString();
    //
    if (work.dates.created_at != null) {
      THIS.param.created_at = THIS.CustomHelper.formatDateTimeToDB(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
      THIS.param_created_at = THIS.CustomHelper.formatDateTime(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at);
      THIS.param.created_at = THIS.CustomHelper.formatDateTime(new Date(THIS.param.created_at));
    }
    //
    if (work.dates.due_at != null) {
      THIS.param.due = THIS.CustomHelper.formatDateTimeToDB(work.dates.due_at);
      THIS.param_due = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
      THIS.param.due = THIS.CustomHelper.formatDateTime(new Date(THIS.param.due));
    }

    THIS.progress = work.progress;
    THIS.param.personnel_id.length = 0;
    for (let p = 0; p < work.personnel.length; p++) {
      THIS.param.personnel_id.push(work.personnel[p].id.toString());
    }
    THIS.param.priority = work.priority !== null ? work.priority.id.toString() : '';
    THIS.selectedWorkAttach = work.attach_files;
    setTimeout(function () {
      $('.tooltip').remove();
      (<any>$('[data-toggle="tooltip"]')).tooltip('destroy');
      setTimeout(function () {
        THIS.CustomHelper.tooltipRender();
      }, 100);
      THIS.workDetailsChange = 1;
      THIS.calculateInspectionRating();
    }, 100);
  }

  InspectionRating = 0;

  calculateInspectionRating() {
    let THIS = this;
    let task = THIS.workDetails.tasks;
    let totalTask = task.length;
    let doneTask = 0;
    $.each(task, function (i, v) {
      if (v.status == 1) {
        doneTask++;
      }
    });
    let result = (doneTask / totalTask) * 5;
    THIS.InspectionRating = Math.round(result);
  }

  jobStatusList = [];
  jobStatusDefault = [];
  jobStatusCustom = [];

  getAllJobStatus() {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'job-status?token=' + THIS.currentUser.token,
      data: {},
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.jobStatusDefault.length = 0;
          THIS.jobStatusCustom.length = 0;
          THIS.jobStatusList = res.data;
          localStorage._jsts = JSON.stringify(res.data);
          $.each(res.data, function (i, v) {
            if (v.type == 0) {
              THIS.jobStatusDefault.push(v);
            } else {
              THIS.jobStatusCustom.push(v);
            }
          });
        }
      }
    });
  }

  updateThisWorkStatus(status, id) {
    let ldd = document.getElementById('statusUpdate');
    let ladda = Ladda.create(ldd);
    let THIS = this;
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.apiRoot + 'inspections/edit?token=' + THIS.currentUser.token,
      data: {status_code: status.id, id: id},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.workDetails.status_code = res.data.status_code;
        // Pusher emit
      }
    });
  }

  updateThisWorkQuick() {
    let ldd = document.getElementById('statusUpdate');
    let ladda = Ladda.create(ldd);
    let THIS = this;
    ladda.start();
    let mediaFiles = '';
    let mediaAttach = '';
    $.each(THIS.selectedWorkAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    $.ajax({
      url: THIS.APPCONFIG.apiRoot + 'inspections/edit?token=' + THIS.currentUser.token,
      data: {media_files: mediaFiles, attach_files: mediaAttach, id: THIS.workDetails.id},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.workDetails.status_code = res.data.status_code;
        // Pusher emit
      }
    });
  }

  SelectedTask = null;
  SelectedTaskMsg = [];

  getCurrentTskMsg() {
    let THIS = this;
    let task = THIS.SelectedTask;
    let param = {
      inspection_id: THIS.workDetails.id,
      task_id: task.id
    };
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'task/msg/get?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          let dateStr = '';
          let dt = [];
          for (let i = 0; i < res.data.length; i++) {
            let v = res.data[i];
            v['dateStr'] = 0;
            if (v.created_at_human_format !== dateStr) {
              dateStr = v.created_at_human_format;
              v['dateStr'] = 1;
            }
            dt.push(v);
          }
          THIS.SelectedTaskMsg = dt;
          setTimeout(function () {
            $('._chat_body_scroll').animate({scrollTop: $('._chat_rail_body').height()}, 1000);
          }, 500);
        }
      }
    });
  }

  Sconnections = [];

  openInspectionTask(task) {
    let THIS = this;
    console.log(THIS.currentUser && THIS.workDetails.shared_edit);
    if (THIS.currentUser !== null && THIS.workDetails.shared_edit == 1) {
      THIS.SelectedTask = task;
      THIS.selectedFilesPreview = task.media_files !== undefined ? task.media_files : [];
      THIS.selectedFiles = task.media_files !== undefined ? task.media_files.length : 0;
      let modal = <any>$('#InspectionChatModal');

      let ___selectedTab = 0;
      let tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      let child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      modal.modal('show');
      THIS.SelectedTaskMsg = [];
      THIS.getCurrentTskMsg();

      if (THIS.Sconnections.indexOf(task.id) == -1) {
        THIS.Sconnections.push(task.id);
        //===============
        // Pusher
        //===============
        Pusher.logToConsole = true;
        let pusher = new Pusher('501b3b237bd799758df0', {
          cluster: 'ap4'
        });
        let channel = pusher.subscribe('bm-inspections');
        channel.bind('InspectionTask', function (data) {
          let v = data.message;
          if (task.id === v.task_id) {
            if (v.pn_type === 'wo_med_tsk') {
              THIS.refreshsinglePage({uid: THIS.workDetails.uid});
              THIS.SelectedTask = v.data;
              THIS.selectedFilesPreview = v.data.media_files !== undefined ? v.data.media_files : [];
              THIS.selectedFiles = v.data.media_files !== undefined ? v.data.media_files.length : 0;
            } else if (v.pn_type === 'wo_comment_tsk') {
              if (v.data.task_id === THIS.SelectedTask.id) {
                THIS.SelectedTaskMsg.push(v.data);
                setTimeout(function () {
                  $('._chat_body_scroll').animate({scrollTop: $('._chat_rail_body').height()}, 1000);
                }, 500);
              }
            } else if (v.pn_type === 'wo_comment_tsk_del') {
              if (v.data.task_id === THIS.SelectedTask.id) {
                let index = THIS.SelectedTaskMsg.map(function (e) {
                  return e.id;
                }).indexOf(v.data.id);
                if (index > -1) {
                  THIS.SelectedTaskMsg[index]['is_active'] = 0;
                }
                setTimeout(function () {
                  $('._chat_body_scroll').animate({scrollTop: $('._chat_rail_body').height()}, 1000);
                }, 500);
              }
            } else if (v.pn_type === 'wo_comment_tsk_edit') {
              if (v.data.task_id === THIS.SelectedTask.id) {
                let index = THIS.SelectedTaskMsg.map(function (e) {
                  return e.id;
                }).indexOf(v.data.id);
                if (index > -1) {
                  THIS.SelectedTaskMsg[index] = v.data;
                }
                setTimeout(function () {
                  $('._chat_body_scroll').animate({scrollTop: $('._chat_rail_body').height()}, 1000);
                }, 500);
              }
            }
          }
        });
        //===============
        // Pusher
        //===============
      }
    }

  }

  updateTaskStatusQuick(task) {
    let THIS = this;
    THIS.SelectedTask.status = (THIS.SelectedTask.status == 1 ? 0 : 1);
    THIS.calculateInspectionRating();
    let param = {
      inspection_id: THIS.workDetails.id,
      id: task.id
    };
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'task/update/status?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        toastr.success('Inspection task status updated successfully', 'Task Status Update', THIS.toastrOption);
        // Pusher emit
      }
    });
  }

  updateTaskStatus(index, task) {
    let THIS = this;
    THIS.workDetails.tasks[index].status = (THIS.workDetails.tasks[index].status == 1 ? 0 : 1);
    THIS.calculateInspectionRating();
    let param = {
      inspection_id: THIS.workDetails.id,
      id: task.id
    };
    console.log(1);
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'task/update/status?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        console.log(2);
        toastr.success('Inspection task status updated successfully', 'Task Status Update', THIS.toastrOption);
        THIS.checkOverRateAllow();
        res.data['target_pn_type'] = 'wo_med_tsk_' + task.id;
        // Pusher emit
      }
    });
  }

  checkOverRateAllow() {
    let allow = 1;
    let tasks = this.workDetails.tasks;
    if (tasks.length == 0) {
      allow = 0;
    }
    $.each(tasks, function (i, v) {
      if (v.status == 0) {
        allow = 0;
      }
    });
    this.overall_ratings_allow = allow;
  }


  editTskMsg = 0;
  selectedMessage = null;
  tskMsg = '';
  tskMsgImages = [];

  removeThisTskMsg() {
    let THIS = this;
    // swal({
    //   title: 'Remove Comment',
    //   text: 'Do you really want to remove this comment?',
    //   icon: 'warning',
    //   buttons: ['CANCEL', 'REMOVE'],
    //   dangerMode: true
    // }).then(res => {
    //   if (res) {
    //     let task = THIS.SelectedTask;
    //     let paramData = {
    //       inspection_id: THIS.workDetails.id,
    //       task_id: task.id,
    //       id: THIS.selectedMessage.id
    //     };
    //     $.ajax({
    //       url: THIS.APPCONFIG.apiInc + 'task/msg/remove?token=' + THIS.currentUser.token,
    //       data: paramData,
    //       type: 'post',
    //       success: function (res) {
    //         if (res.status === 2000) {
    //           paramData['target_pn_type'] = 'wo_comment_tsk_del_' + task.id;
    //           // Pusher emit
    //         }
    //       }
    //     });
    //   }
    // });
    let task = THIS.SelectedTask;
    let paramData = {
      inspection_id: THIS.workDetails.id,
      task_id: task.id,
      id: THIS.selectedMessage.id
    };
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'task/msg/remove?token=' + THIS.currentUser.token,
      data: paramData,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          paramData['target_pn_type'] = 'wo_comment_tsk_del_' + task.id;
          // Pusher emit
          (<any>$('#removeInsChatModal')).modal('hide');
          THIS.getCurrentTskMsg();
        }
      }
    });
  }
  InspectionChatModal() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#removeInsChatModal')).modal('show');
  }

  createTaskMsg(e) {
    let THIS = this;
    if (e.which == 1 || (e.which == 13 && (!e.shiftKey && !e.ctrlKey))) {
      e.preventDefault();
      let task = THIS.SelectedTask;
      let msg = THIS.tskMsg;
      msg = $.trim(msg);
      console.log(msg !== '' || THIS.tskMsgImages.length > 0);
      if (msg !== '' || THIS.tskMsgImages.length > 0) {
        THIS.tskMsg = '';
        let param;
        if (THIS.editTskMsg == 1) {
          param = {
            inspection_id: THIS.workDetails.id,
            task_id: task.id,
            id: THIS.selectedMessage.id,
            msg: msg,
            images: THIS.tskMsgImages
          };
        } else {
          param = {
            inspection_id: THIS.workDetails.id,
            task_id: task.id,
            msg: msg,
            images: THIS.tskMsgImages
          };
        }
        $.ajax({
          url: THIS.APPCONFIG.apiInc + 'task/msg/create?token=' + THIS.currentUser.token,
          type: 'post',
          data: param,
          success: function (res) {
            if (res.status == 2000) {
              if (THIS.editTskMsg == 1) {
                res.data['target_pn_type'] = 'wo_comment_tsk_edit_' + task.id;
                THIS.getCurrentTskMsg();
                // Pusher emit
              } else {
                res.data['target_pn_type'] = 'wo_comment_tsk_' + task.id;
                // Pusher emit
                THIS.getCurrentTskMsg();
              }
            }
            THIS.editTskMsg = 0;
            THIS.selectedMessage = null;
            THIS.tskMsg = '';
            THIS.tskMsgImages = [];
          }
        });
      }
    } else {
      let task = THIS.SelectedTask;
      let msg = THIS.tskMsg;
      msg = $.trim(msg);
      if (msg == '') {
        THIS.editTskMsg = 0;
        THIS.selectedMessage = null;
      }
    }
  }

  removeThisMedia(id, media_type) {
    let param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  removeThisCommentImage(index, id) {
    this.tskMsgImages.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  addCommentMedia() {
    $('#attachImgComment').click();
  }

  UID_IMG = 0;
  _uploadError = 0;
  _locPermit = 0;

  uploadThisComentMedia(file, media_type, localtion, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  // selectTheseCommentImg(e) {
  //   let THIS = this;
  //   e.preventDefault();
  //   let accepted = e.target.files;
  //   for (let i = 0; i < accepted.length; i++) {
  //     let eachFile = accepted[i];
  //     THIS._locPermit = -1;
  //     THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
  //       THIS.tskMsgImages.push({id: uploaded.id, file_path: uploaded.file_path, width: uploaded.width, height: uploaded.height});
  //     });
  //   }
  // }
  selectTheseCommentImg(e) {
    let THIS = this;
    e.preventDefault();
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      if (THIS.currentUser.modules.geo_tagged_photo == 1) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            THIS._locPermit = 1;
            let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
            THIS.uploadThisComentMedia(eachFile, '1', localtion, function (uploaded) {
              THIS.tskMsgImages.push({id: uploaded.id, file_path: uploaded.file_path, width: uploaded.width, height: uploaded.height});
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
              THIS.tskMsgImages.push({id: uploaded.id, file_path: uploaded.file_path, width: uploaded.width, height: uploaded.height});
            });
          }
        }, function (error) {
          swal({
            title: 'Share Geo Location',
            text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
            icon: 'warning',
            buttons: ['Want to share location', 'Don\'t want to share Location'],
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
                THIS.tskMsgImages.push({id: uploaded.id, file_path: uploaded.file_path, width: uploaded.width, height: uploaded.height});
              });
            } else {
              swal('If you want to share location then change the browser settings and remove the block status');
            }
          });
        });
      } else {
        THIS._locPermit = -1;
        THIS.uploadThisComentMedia(eachFile, '1', null, function (uploaded) {
          THIS.tskMsgImages.push({id: uploaded.id, file_path: uploaded.file_path, width: uploaded.width, height: uploaded.height});
        });
      }
    }
  }

  emojiList = [];
  emojiListCurrentIndex = 0;
  emojiListCurrent = [];

  getEmoji() {
    this.emojiListCurrentIndex = 0;
    this.mediaService.getEmoji().subscribe(res => {
      if (res.status == 2000) {
        this.emojiList = res.data;
        this.emojiListCurrent = this.emojiList[this.emojiListCurrentIndex];
      }
    });
  }

  writeEmoji(sign) {
    this.tskMsg = this.tskMsg + ' ' + sign + ' ';
  }


  addWorkAttach() {
    $('#workAttach').click();
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedWorkAttach.length < 3) {
        let eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allwoed',
                icon: 'error',
                dangerMode: true,
              });
            }
          } else {
            THIS.selectedWorkAttach.push(uploaded);
            THIS.updateThisWorkQuick();
          }
        });
      }
    }
  }

  uploadThisMedia(file, media_type, localtion, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

}
