import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { APPCONFIG } from '../config';


import { HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";

@Injectable()
export class InvoicesService {

    public token: string;

    constructor(private http: HttpClient) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }

    createInvoice(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    editInvoice(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/edit?token=`+this.token, param)
            .pipe(map(res => {
                return res.data;
            }));
    }
    getInvoiceList(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/getAll?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getInvoiceStatus(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/getAllStatus?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    invoiceDuplicate(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/duplicate?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getInvoiceSingle(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/getSingle?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    removeInvoice(ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/remove?token=`+this.token, {ids : ids})
            .pipe(map(res => {
                return res;
            }));
    }
    removeUndoInvoice(ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/removeUndo?token=`+this.token, {ids : ids})
            .pipe(map(res => {
                return res;
            }));
    }
    archiveInvoice(ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/archive?token=`+this.token, {ids : ids})
            .pipe(map(res => {
                return res;
            }));
    }
    archiveUndoInvoice(ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/archiveUndo?token=`+this.token, {ids : ids})
            .pipe(map(res => {
                return res;
            }));
    }
    statusUpdateInvoice(status,id): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/`+id+`/statusUpdate?token=`+this.token, {status:status})
            .pipe(map(res => {
                return res;
            }));
    }
    paymentInvoice(payment,id): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/`+id+`/paymentUpdate?token=`+this.token, {payment:payment})
            .pipe(map(res => {
                return res;
            }));
    }
    paymentThisInvoice(comment,id): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/`+id+`/commentUpdate?token=`+this.token, {comment:comment})
            .pipe(map(res => {
                return res;
            }));
    }
    getLastInvoiceId(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}get/invoice/lastId?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getLastRctiId(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}get/invoice/lastId?rcti=1&token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    /*editJobType(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}job-types/`+param.id+`?token=`+this.token, {name:param.name})
            .map(res => {
                return res;
            });
    }*/

    createRecurringInvoice(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice-recurring/create?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    editRecurringInvoice(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice-recurring/edit?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    removeRecurringInvoice(ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice-recurring/remove?token=`+this.token, {ids:ids})
            .pipe(map(res => {
                return res;
            }));
    }
    removeUndoRecurringInvoice(ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice-recurring/remove/undo?token=`+this.token, {ids:ids})
            .pipe(map(res => {
                return res;
            }));
    }
    archiveRecurringInvoice(ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice-recurring/archive?token=`+this.token, {ids:ids})
            .pipe(map(res => {
                return res;
            }));
    }
    archiveUndoRecurringInvoice(ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice-recurring/archive/undo?token=`+this.token, {ids:ids})
            .pipe(map(res => {
                return res;
            }));
    }
    getRecurInvoiceList(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice-recurring/getAll?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getRecurInvoiceStatus(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice-recurring/getAllStatus?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getRecurInvoiceSingle(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice-recurring/getSingle?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    updateRecurInvoiceStatus(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice-recurring/updateStatus?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }

    paymentRemove(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoice/payment/remove?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }


    getSupplierInvoiceList(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/supplier/getAll?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    removeSpiInvoice(ids): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/supplier/remove?token=`+this.token, {ids : ids})
            .pipe(map(res => {
                return res;
            }));
    }
    statusSpiInvoice(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}invoices/supplier/status?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
}
