import {Component, TemplateRef, Inject} from '@angular/core';
import {Router, ActivatedRoute, Params, Data} from '@angular/router';
import {FacilityService} from '../../_services/facility.service';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';

declare let toastr: any;
declare let $: any;
declare let swal: any;
declare let Ladda: any;
declare let FullCalendar: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;

@Component({
    selector: 'my-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};


    currentUser;
    ROUTES = '/dashboard';

    titleRequired = null;

    constructor(private router: Router, private route: ActivatedRoute, private facilityService: FacilityService) {

        const THIS = this;
        const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        THIS.currentUser = Session;
        setTimeout(function () {

            if (THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] != undefined) {
                window.history.pushState('', '', THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute]);
            }

            THIS.facilityService.currentSelectedFac.subscribe(value => THIS.refreshFac());

            const routeR = THIS.CustomHelper.getRouteStr();
            const url = '/' + Session.team + THIS.ROUTES;
            window.history.pushState('', '', url);
            THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] = window.location.pathname;
            const d = new Date();
            const month = d.getMonth();
            THIS.reviewMonth = month + 1;
            THIS.refreshReport();
            THIS.refreshMonthReviewApp();
            THIS.getLocation();
            THIS.getWorkOrder();

            setTimeout(function () {
                (<any>$('.summernote')).summernote({
                    maxHeight: '900px',
                    minHeight: '150px',
                    toolbar: [
                        ['style', ['bold', 'underline', 'clear']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['insert', ['link', 'video']],
                        ['misc', ['fullscreen']]
                    ]
                });
            }, 100);
        });

        this.todayWO();
        this.tomorrowWO();
        this.datWO();
        this.getReminders();

    }

    refreshFac() {
        const THIS = this;
        THIS.refreshReport();
        THIS.refreshMonthReviewApp();
        THIS.getAllFacility();
    }

    count1 = 0;
    count1Max = 167;
    count2 = 0;
    count2Max = 122;
    count3 = 0;
    count3Max = 322;
    count4 = 0;
    count4Max = 79;

    makeCountIt() {
        const THIS = this;
        const time = 2000;
        const counter1 = setInterval(function () {
            if (THIS.count1 < THIS.count1Max) {
                THIS.count1 = THIS.count1 + 1;
            } else {
                clearInterval(counter1);
            }
        }, time / THIS.count1Max);
        const counter2 = setInterval(function () {
            if (THIS.count2 < THIS.count2Max) {
                THIS.count2 = THIS.count2 + 1;
            } else {
                clearInterval(counter2);
            }
        }, time / THIS.count2Max);
        const counter3 = setInterval(function () {
            if (THIS.count3 < THIS.count3Max) {
                THIS.count3 = THIS.count2 + 1;
            } else {
                clearInterval(counter3);
            }
        }, time / THIS.count3Max);
        const counter4 = setInterval(function () {
            if (THIS.count4 < THIS.count4Max) {
                THIS.count4 = THIS.count4 + 1;
            } else {
                clearInterval(counter4);
            }
        }, time / THIS.count4Max);

    }

    doughnutOptions = null;
    doughnutData = null;
    doughnutNewOpen = null;
    doughnutNewOpenOptions = null;
    doughnutJT = null;
    jtColor = ['#72A0C2', '#BE8DC0', '#19B394', '#EEA881', '#F08A8F', '#72A0C2', '#BE8DC0', '#19B394', '#EEA881', '#F08A8F', '#72A0C2', '#BE8DC0', '#19B394', '#EEA881', '#F08A8F', '#72A0C2', '#BE8DC0', '#19B394', '#EEA881', '#F08A8F', '#72A0C2', '#BE8DC0', '#19B394', '#EEA881', '#F08A8F', '#72A0C2', '#BE8DC0', '#19B394', '#EEA881', '#F08A8F', '#72A0C2', '#BE8DC0', '#19B394', '#EEA881', '#F08A8F', '#72A0C2', '#BE8DC0', '#19B394', '#EEA881', '#F08A8F', '#72A0C2', '#BE8DC0', '#19B394', '#EEA881', '#F08A8F'];

    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }


    Report = null;
    Report_jt_status_total = 0;

    refreshReport() {
        const THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'dashboard/default/report?token=' + THIS.currentUser.token,
            type: 'post',
            data: {},
            success: function (res) {
                if (res.status == 2000) {
                    let totalJT = 0;
                    const jt_status = [];
                    THIS.Report_jt_status_total = 0;
                    $.each(res.data.jt_status, function (i, v) {
                        totalJT = totalJT + v.wo;
                        THIS.Report_jt_status_total = THIS.Report_jt_status_total + v.wo;
                    });
                    $.each(res.data.jt_status, function (i, v) {
                        if (totalJT > 0) {
                            v['woPerc'] = (v.wo / totalJT) * 100;
                        } else {
                            v['woPerc'] = 0;
                        }
                        jt_status.push(v);
                    });
                    res.data.jt_status = jt_status;
                    THIS.Report = res.data;
                    THIS.doughnutOptions = {
                        responsive: true,
                        legend: {
                            display: false
                        },
                    };
                    THIS.doughnutJT = {
                        labels: [],
                        datasets: [{
                            data: [],
                            backgroundColor: []
                        }]
                    };
                    $.each(THIS.Report.jt_status, function (k, v) {
                        THIS.doughnutJT.labels.push(v.name);
                        THIS.doughnutJT.datasets[0].data.push(v.wo);
                        THIS.doughnutJT.datasets[0].backgroundColor.push(THIS.jtColor[k]);
                    });
                    THIS.doughnutData = {
                        labels: ['Open', 'Overdue'],
                        datasets: [{
                            data: [THIS.Report.total, THIS.Report.overDue],
                            backgroundColor: ['#5ca3e3', '#db686d']
                        }]
                    };
                }
                setTimeout(function () {
                    THIS.CustomHelper.tooltipRender();
                }, 100);
            }
        });
    }

    monthReviewCount = 0;
    reviewMonth = 0;

    refreshMonthReview() {
        const THIS = this;
        THIS.refreshMonthReviewApp();
    }

    refreshMonthReviewApp() {
        const THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'dashboard/month/review?token=' + THIS.currentUser.token,
            type: 'post',
            data: {},
            success: function (res) {
                if (res.status == 2000) {
                    // res.data = res.data.reverse();
                    const MaxValue = res.data.length < 10 ? 10 : res.data.length;
                    let stepSize = 2;
                    if (res.data.length > 19) {
                        stepSize = THIS.CustomHelper.makeInt(MaxValue / (MaxValue / 10));
                    }
                    THIS.doughnutNewOpenOptions = {
                        responsive: true,
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    max: MaxValue,
                                    min: 0,
                                    stepSize: stepSize
                                }
                            }]
                        }
                    };
                    THIS.doughnutNewOpen = {
                        labels: [],
                        datasets: [{
                            data: [],
                            fill: false,
                            pointBackgroundColor: '#ffffff',
                            pointBorderColor: '#d53d43',
                            pointBorderWidth: 2,
                            pointRadius: 3,
                            borderColor: '#9a9ba2',
                            borderWidth: 2,
                            lineTension: 0
                        }],
                    };
                    $.each(res.data, function (k, v) {
                        THIS.doughnutNewOpen.labels.push(v.day);
                        THIS.doughnutNewOpen.datasets[0].data.push(v.count);
                    });
                }
                setTimeout(function () {
                    THIS.CustomHelper.tooltipRender();
                }, 100);
            }
        });
    }


    Facility = [];
    FacilityComp = [];

    getAllFacility() {
        const THIS = this;
        THIS.Facility = [];
        THIS.FacilityComp = [];
        $.ajax({
            url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
            data: {},
            type: 'get',
            success: function (res) {
                THIS.Facility = [];
                THIS.FacilityComp = [];
                THIS.FacilityComp = res.facility;
                $.each(res.facility, function (i, v) {
                    v['pp'] = 0;
                    THIS.Facility.push(v);
                    $.each(v.child, function (j, vC) {
                        vC['pp'] = 1;
                        THIS.Facility.push(vC);
                        $.each(vC.child, function (k, vCC) {
                            vCC['pp'] = 2;
                            THIS.Facility.push(vCC);
                            $.each(vCC.child, function (l, vCCC) {
                                vCCC['pp'] = 3;
                                THIS.Facility.push(vCCC);
                            });
                        });
                    });
                });
                setTimeout(function () {
                    (<any>$('.chosen-default-single-fac')).chosen('destroy');
                    (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                    (<any>$('.chosen-default-single-fac')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_single: 'Select Facility'
                    }).on('change', function (e, p) {
                        THIS.param.facility_id = e.target.value;
                    });
                }, 1000);
            }
        });
    }

    param = {
        facility_id: '',
        subject: '',
        description: '',
        status_code: 1
    };

    openQuickWo() {
        const THIS = this;
        THIS.param.facility_id = '';
        THIS.param.subject = '';
        THIS.param.description = '';

        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
        (<any>$('.chosen-default-single-fac')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Facility'
        }).on('change', function (e, p) {
            THIS.param.facility_id = e.target.value;
        });
    }

    createWO() {
        if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
            const freeAccountData = localStorage.getItem('freeAccountData');
            if (freeAccountData != null) {
                const freeAccount = JSON.parse(freeAccountData);
                if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
                    (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
                    (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
                    (freeAccount['user_count'] >= freeAccount['user_limit'])) {
                    $('.openSubscription').click();
                    return false;
                }
            }
        }
        const ldd = document.getElementById('woCreate');
        const ladda = Ladda.create(ldd);
        const THIS = this;
        if (this.param.facility_id == '') {
            this.OpenTitleRequired(1);
            return true;
        }
        if (this.param.subject == '') {
          this.titleRequired = true;
            this.OpenTitleRequired(2);
            return true;
        }
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'work-orders/create?token=' + THIS.currentUser.token,
            type: 'post',
            data: this.param,
            success: function (res) {
                ladda.stop();
                THIS.openQuickWo();
                THIS.refreshReport();
                THIS.refreshMonthReviewApp();
                toastr.success('This new work order has been successfully created', 'New Work Order', THIS.toastrOption);
            }
        });
    }

    getDateFormat(day) {
        const today = new Date();
        let dd = today.getDate();
        const dd1 = dd + 1;
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();

        let res = null;
        if (day == 'today') {
            res = {
                start: yyyy + '-' + (mm < 10 ? ('0' + mm) : mm) + '-' + (dd < 10 ? ('0' + dd) : dd) + ' 00:00:00',
                end: yyyy + '-' + (mm < 10 ? ('0' + mm) : mm) + '-' + (dd1 < 10 ? ('0' + dd1) : dd1) + ' 00:00:00',
            };
        }
        if (day == 'tomorrow') {
            dd = dd + 1;
            res = {
                start: yyyy + '-' + (mm < 10 ? ('0' + mm) : mm) + '-' + (dd < 10 ? ('0' + dd) : dd) + ' 00:00:00',
                end: yyyy + '-' + (mm < 10 ? ('0' + mm) : mm) + '-' + (dd < 10 ? ('0' + dd) : dd) + ' 11:59:00',
            };
        }
        if (day == 'dat') {
            dd = dd + 2;
            res = {
                start: yyyy + '-' + (mm < 10 ? ('0' + mm) : mm) + '-' + (dd < 10 ? ('0' + dd) : dd) + ' 00:00:00',
                end: yyyy + '-' + (mm < 10 ? ('0' + mm) : mm) + '-' + (dd < 10 ? ('0' + dd) : dd) + ' 11:59:00',
            };
        }
        return res;
    }

    todayWorkOrder = [];

    todayWO() {
        const THIS = this;
        const param = {
            week: this.getDateFormat('today'),
            view: 'dayGridDay'
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'calendar/work/list?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                if (res.status == 2000) {
                    THIS.todayWorkOrder = res.data;
                }
            }
        });
    }

    tomorrowWorkOrder = [];

    tomorrowWO() {
        const THIS = this;
        const param = {
            week: this.getDateFormat('dat'),
            view: 'dayGridDay'
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'calendar/work/list?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                if (res.status == 2000) {
                    THIS.tomorrowWorkOrder = res.data;
                  console.log(THIS.tomorrowWorkOrder, 'tomorrowWorkOrder');
                }
            }
        });
    }

    datWorkOrder = [];

    datWO() {
        const THIS = this;
        const param = {
            week: this.getDateFormat('dat'),
            view: 'dayGridDay'
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'calendar/work/list?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                if (res.status == 2000) {
                    THIS.datWorkOrder = res.data;
                }
            }
        });
    }

    WidgetList = 0;
    WidgetIndex = 0;

    _me() {
        const THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'me?token=' + THIS.currentUser.token,
            type: 'get',
            success: function (res) {
                if (res.status == 2000) {
                    THIS.currentUser = res.data;
                }
            }
        });
    }

    pageNo = 1;
    perPage = 25;
    TotalWorks = 0;
    TotalWorkIds = [];
    pageEvent;
    MeAssignedResponse = false;
    MeAssigned = [];

    paginateList() {
        if (this.pageEvent.pageSize == this.perPage) {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex + 1;
            this.getWorkOrder();
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
            this.getWorkOrder();
        }
    }

    getWorkOrder() {
        const THIS = this;
        THIS.MeAssigned = [];
        THIS.TotalWorks = 0;
        $.ajax({
            url: THIS.APPCONFIG.api + 'get/work-orders?token=' + THIS.currentUser.token,
            type: 'post',
            data: {
                sort_by: 'creation_date',
                order_by: 'desc',
                is_active: 1,
                status: [1, 2],
                pageNo: THIS.pageNo,
                perPage: THIS.perPage,
                createdBy: THIS.currentUser.id
            },
            success: function (res) {
                if (res.status == 2000) {
                    THIS.MeAssigned = res.data;
                    THIS.TotalWorks = res.total;
                    THIS.MeAssignedResponse = true;
                }
            }
        });
    }

    addWidget(index, did) {
        const THIS = this;
        let param = {};
        if (this.WidgetIndex == 1) {
            param = {wid_1: index};
        } else {
            param = {wid_2: index};
        }
        const ldd = document.getElementById(did);
        const ladda = Ladda.create(ldd);
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'updateMe?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                if (THIS.WidgetIndex == 1) {
                    THIS.currentUser.wid_1 = index;
                } else {
                    THIS.currentUser.wid_2 = index;
                }
                ladda.stop();
                if (res.status == 2000) {
                    THIS._me();
                }
            }
        });
    }

    removeThisWidget(wid, did) {
        const THIS = this;
        const ldd = document.getElementById(did);
        const ladda = Ladda.create(ldd);
        let param = {};
        if (wid == 1) {
            param = {wid_1: 0};
            THIS.currentUser.wid_1 = 0;
        } else {
            param = {wid_2: 0};
            THIS.currentUser.wid_2 = 0;
        }
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'updateMe?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                ladda.stop();
                if (res.status == 2000) {
                    THIS._me();
                }
            }
        });
    }

    getLocation() {
        const THIS = this;
        navigator.geolocation.getCurrentPosition(function (position) {
            const q = 'lat=' + position.coords.latitude + '&lon=' + position.coords.longitude;
            THIS.getWeatherReport(q);
        }, function (error) {
            THIS.getIpAddress();
        });
    }

    Weather = null;
    todayDate = '';

    getWeatherReport(q) {
        const MonthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const today = new Date();
        let date: any = today.getDate();
        date = date < 10 ? '0' + date : date;
        const month = today.getMonth();
        const monthName = MonthList[month];
        const year = today.getFullYear();
        this.todayDate = date + ' ' + monthName + ' ' + year;

        const THIS = this;
        $.ajax({
            url: 'https://api.openweathermap.org/data/2.5/weather?APPID=95cf814804abbe5bcb852222d4dff412&' + q,
            type: 'get',
            success: function (res) {
                console.log(res);
                THIS.Weather = res;
                THIS.Weather.main.temp = THIS.CustomHelper.makeInt(THIS.Weather.main.temp - 273);
            }
        });
    }

    getIpAddress() {
        const THIS = this;
        $.ajax({
            url: 'https://api.ipify.org?format=json',
            type: 'get',
            success: function (res) {
                $.ajax({
                    url: 'https://api.ipgeolocation.io/ipgeo?apiKey=ee64882db1c449b3995069b01de9f415&ip=' + res.ip,
                    type: 'get',
                    success: function (res) {
                        const loc = 'lat=' + res.latitude + '&lon=' + res.longitude;
                        THIS.getWeatherReport(loc);
                    }
                });
            }
        });
    }

    workDetails = null;
    _albums = [];
    taskType = 1; // 1.Tasks  2.Inspections
    selectedFilesPreview = [];
    selectedWorkAttach = [];
    add2Calendar = 0;
    calendarStatus = '_blue';
    param_created_at = '';
    param_due = '';
    progress = 0;

    getDateWorkOrder(param) {
        const THIS = this;
        THIS.workDetails = null;

        const url = THIS.APPCONFIG.api + 'work-orders/single?token=' + THIS.currentUser.token;

        $.ajax({
            url: url,
            type: 'post',
            data: {uid: param.uid},
            success: function (res) {
                if (res.status == 2000) {

                  let ___selectedTab = 0;
                  const tabs = $('#sort-tabs').find('li');
                  tabs.each(function (i, v) {
                    if ($(v).find('a.active').length > 0) {
                      ___selectedTab = i;
                    }
                  });
                  const child = ___selectedTab + 1;
                  (<any>$('.owl-carousel')).owlCarousel('destroy');
                  $('#sort-tabs').find('li').find('a').removeClass('active');
                  $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                  (<any>$('#_pop_wo_workOrderModal')).modal('show');

                    const work = res.data;
                    work['modType'] = param.type;
                    THIS.workDetails = work;
                    THIS.workDetails.dates.creation_date = THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.creation_date);
                    THIS.workDetails.dates.edited_date = THIS.workDetails.dates.edited_date != '' && THIS.workDetails.dates.edited_date != null ? THIS.CustomHelper.formatDateTime(THIS.workDetails.dates.edited_date) : '';
                    THIS.workDetails.dates.created_at = work.dates.created_at != null ? THIS.CustomHelper.formatDateTime(work.dates.created_at.date != undefined ? work.dates.created_at.date : work.dates.created_at) : '';
                    THIS.workDetails.dates.due_at = work.dates.due_at != null ? THIS.CustomHelper.formatDateTime(work.dates.due_at.date != undefined ? work.dates.due_at.date : work.dates.due_at) : '';
                    THIS.param_created_at = THIS.workDetails.dates.created_at;
                    THIS.param_due = THIS.workDetails.dates.due_at;
                    THIS.progress = work.progress > 100 ? 100 : work.progress;
                    THIS.workDetails.facStr = THIS.workDetails.facStr.reverse();
                    THIS._albums.length = 0;
                    if (work.modType == 1) {
                        if (THIS.workDetails.media_files !== undefined) {
                            for (let i = 0; i < THIS.workDetails.media_files.length; i++) {
                                const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.workDetails.media_files[i].file_path;
                                const caption = '';
                                const thumb = '';
                                const album = {
                                    src: src,
                                    caption: caption,
                                    thumb: thumb,
                                    w: THIS.workDetails.media_files[i].width !== undefined ? THIS.workDetails.media_files[i].width : 1500,
                                    h: THIS.workDetails.media_files[i].height !== undefined ? THIS.workDetails.media_files[i].height : 1500
                                };
                                THIS._albums.push(album);
                            }
                        }
                        if (work.tasks.length > 0) {
                            THIS.taskType = work.tasks[0].type;
                        }
                    }
                    setTimeout(function () {
                        if (work.modType == 1) {
                            (<any>$('#summerDesc')).html(work.description);
                        }
                        $('.tooltip').remove();
                        (<any>$('[data-toggle="tooltip"]')).tooltip('destroy');
                        setTimeout(function () {
                            THIS.CustomHelper.tooltipRender();
                        }, 100);
                    }, 100);


                    THIS.add2Calendar = work.calendar.add_to_calendar;
                    THIS.calendarStatus = work.calendar.calendar_color == '' || work.calendar.calendar_color == null ? '_blue' : work.calendar.calendar_color;
                    THIS.selectedFilesPreview = work.media_files;
                    THIS.selectedWorkAttach = work.attach_files;
                }else{
                  toastr.error('Something went wrong');
                }
            }
        });
    }

    open(index: number): void {
        const THIS = this;
        const pswpElement = document.querySelectorAll('.pswp')[0];
        const options = {
            index: index,
            shareEl: false,
            tapToClose: false,
            zoomEl: true
        };
        const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, THIS._albums, options);
        gallery.init();
        history.pushState(null, null, location.href);
        window.onpopstate = function () {
            gallery.close();
            history.go(1);
        };
    }


    reminderTitle = '';

    createReminder(e) {
        if (e.which === 1 || e.which === 13) {
            const ldd = document.getElementById('reminderBtn');
            const ladda = Ladda.create(ldd);
            const THIS = this;
            if (this.reminderTitle == '') {
              this.OpenTitleRequired(2);
              return true;
            }
            const param = {title: this.reminderTitle};
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.api + 'reminder/create?token=' + THIS.currentUser.token,
                type: 'post',
                data: param,
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                        THIS.reminderTitle = '';
                        THIS.getReminders();
                        toastr.success('This new reminder has been successfully created', 'New Reminder', THIS.toastrOption);
                    }
                }
            });
        }
    }
    OpenTitleRequired(type){
      if(type == 1){
        this.titleRequired = false;
      }else{
        this.titleRequired = true;
      }
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#TitleRequired')).modal('show');
    }
    CloseTitleRequired(){
      (<any>$('#TitleRequired')).modal('hide');
    }

    selectedReminder = null;
    reminderEditParam = {id: 0, title: ''};

    editThisReminder() {
        this.reminderEditParam = {id: this.selectedReminder.id, title: this.selectedReminder.title};

        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#editReminderModal')).modal('show');
    }

    DeleteReminderModal() {
        this.reminderEditParam = {id: this.selectedReminder.id, title: this.selectedReminder.title};

        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#DeleteReminderModal')).modal('show');
    }

    remainderUpdate() {
        const THIS = this;
        if (this.reminderEditParam.title === '') {
          console.log(typeof this.reminderEditParam.title, 'okay');
          // document.getElementById('editReminderModal').querySelector('.error').innerHTML = 'Reminder title can\'t be empty!';
          $('#editReminderModal .error').html('Reminder title can\'t be empty!');
        } else {
          $.ajax({
            url: THIS.APPCONFIG.api + 'reminder/edit?token=' + THIS.currentUser.token,
            type: 'post',
            data: THIS.reminderEditParam,
            success: function (res) {
              if (res.status == 2000) {
                THIS.getReminders();
                $('#editReminderModal .error').html('');
                (<any>$('#editReminderModal')).modal('hide');
                toastr.success('Reminder has been updated', 'Reminder Update', THIS.toastrOption);
              }
            }
          });
        }

    }

    removeReminderLoading = false;

    removeThisReminder() {
        const THIS: any = this;
      THIS.removeReminderLoading = true;
      const param: any = {id: this.selectedReminder.id};
      $.ajax({
        url: THIS.APPCONFIG.api + 'reminder/remove?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          if (res.status == 2000) {
            THIS.removeReminderLoading = false;
            THIS.getReminders();
            (<any>$('#DeleteReminderModal')).modal('hide');
            toastr.success('Reminder removed successfully', 'Reminder Remove', THIS.toastrOption);
          } else {
            THIS.removeReminderLoading = false;
          }
        }
      });
    }

    remindersList: any = [];

    getReminders() {
        const THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'reminder/get/all?token=' + THIS.currentUser.token,
            type: 'post',
            data: {},
            success: function (res) {
                if (res.status == 2000) {
                    THIS.remindersList = res.data;
                }
            }
        });
    }

    reminderStatus(list) {
        const THIS = this;
        list.status = list.status == 1 ? 0 : 1;
        const param = {id: list.id, status: list.status};
        $.ajax({
            url: THIS.APPCONFIG.api + 'reminder/edit?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                if (res.status == 2000) {
                    toastr.success('Reminder Status has been updated', 'Reminder Status', THIS.toastrOption);
                }
            }
        });
    }

    discardPreviewWork() {
        (<any>$('#_pop_wo_workOrderModal')).modal('hide');
        this.getWorkOrder();
    }

    removeWoThisWork(id) {
        const THIS = this;
        swal({
            title: 'Delete Work Order',
            text: 'Are you sure you want to delete this work order?',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const ids = [id];
                $.ajax({
                    url: THIS.APPCONFIG.api + 'get/work-orders/remove?token=' + THIS.currentUser.token,
                    data: {ids: ids},
                    type: 'post',
                    success: function (res) {
                        (<any>$('#_pop_wo_workOrderModal')).modal('hide');
                        THIS.getWorkOrder();
                        swal({
                            title: 'Success',
                            text: res.msg,
                            icon: 'success',
                            buttons: ['Undo Remove', 'Ok'],
                            dangerMode: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                                swal.stopLoading();
                                swal.close();
                            } else {
                                THIS.undoWoRemoveThisWork(ids);
                            }
                        });
                    }
                });
            }
        });
    }

    undoWoRemoveThisWork(ids) {
        const THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'get/work-orders/undo/remove?token=' + THIS.currentUser.token,
            data: {ids: ids},
            type: 'post',
            success: function (res) {
                THIS.getWorkOrder();
            }
        });
    }

    archiveWoThisWork(id) {
        const THIS = this;
        swal({
            title: 'Archive Work Order',
            text: 'Are you sure you want to archive this work order?',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const ids = [id];
                $.ajax({
                    url: THIS.APPCONFIG.api + 'get/work-orders/archive?token=' + THIS.currentUser.token,
                    data: {ids: ids},
                    type: 'post',
                    success: function (res) {
                        (<any>$('#_pop_wo_workOrderModal')).modal('hide');
                        THIS.getWorkOrder();
                        swal({
                            title: 'Success',
                            text: res.msg,
                            icon: 'success',
                            buttons: ['Undo Archive', 'Ok'],
                            dangerMode: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                                swal.stopLoading();
                                swal.close();
                            } else {
                                THIS.undoWoArchiveThisWork(ids);
                            }
                        });
                    }
                });
            }
        });
    }

    undoWoArchiveThisWork(ids) {
        const THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'get/work-orders/undo/archive?token=' + THIS.currentUser.token,
            data: {ids: ids},
            type: 'post',
            success: function (res) {
                THIS.getWorkOrder();
            }
        });
    }

    duplicateWoWorkOrder(id) {
        const THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'work-orders/duplicate?token=' + THIS.currentUser.token,
            data: {id: id},
            type: 'post',
            success: function (res) {
                if (res.status != undefined && res.status == 3000) {
                    swal({
                        title: 'Work Order',
                        text: 'You have reached your free plan limit. To create more work orders, please subscribe to the Pro plan from your account page.',
                        icon: 'warning',
                        dangerMode: true,
                        className: 'swal-btn-center'
                    });
                    return true;
                }
                THIS.getWorkOrder();
                swal({
                    title: 'Duplicate Work Order',
                    text: 'Work Order has been successfully duplicated',
                    icon: 'success',
                    buttons: ['Undo', 'Ok'],
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        swal.stopLoading();
                        swal.close();
                    } else {
                        const ids = [res.id];
                        $.ajax({
                            url: THIS.APPCONFIG.api + 'get/work-orders/remove?token=' + THIS.currentUser.token,
                            data: {ids: ids},
                            type: 'post',
                            success: function (res) {
                                THIS.getWorkOrder();
                            }
                        });
                    }
                });
            }
        });
    }

}
