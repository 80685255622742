import {Component, Inject} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {InventoriesService} from '../../_services/inventories.service';
import {AssetService} from '../../_services/asset.service';
import {FacilityService} from '../../_services/facility.service';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;

@Component({
  selector: 'page-inventory',
  templateUrl: './inventories.component.html'
})
export class InventoriesComponent {
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
  Permission = -1;

  restoreAlert = false;

  totalInv = 0;
  pageNo = 1;
  perPage = this.CustomHelper.getPerpage();
  pageEvent;
  order_by = 'desc';

  paginateList() {
    if (this.pageEvent.pageSize == this.perPage) {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = this.pageEvent.pageIndex + 1;
      this.getAllInventory();
    } else {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = 1;
      this.getAllInventory();
    }
  }


  previewList = 0;
  Inventories = [];

  getAllInventory() {
    let THIS = this;
    let param: any;
    this.previewList = 0;
    this.Inventories.length = 0;
    param = {
      keyword: this.searchKeyword,
      pageNo: this.pageNo,
      perPage: this.perPage,
      sort_by: this.sort_by,
      order_by: this.order_by,
      is_active: this.wo_is_active,
    };
    this.inventoriesService.getAllInventory(param).subscribe(res => {
      this.previewList = 1;
      if (res.status == 2000) {
        this.totalInv = res.total;
        let INV = [];
        $.each(res.data, function (i, v) {
          v.purchase_cost = THIS.CustomHelper.makeFloat(v.purchase_cost).toFixed(2);
          v.sell_price = THIS.CustomHelper.makeFloat(v.sell_price).toFixed(2);
          INV.push(v);
        });
        this.Inventories = INV;
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 500);
      }
    });
    this.getStatusCount();
  }

  Store = [];
  selectedStore = 0;

  getAllStore() {
    this.inventoriesService.getStoreList().subscribe(res => {
      if (res.status == 2000) {
        this.Store = res.data;
      }
    });
  }

  selectedInv = [];

  selectThisAssets(e, inv) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.which == 1) {
        let index = this.selectedInv.indexOf(inv.id);
        if (index > -1) {
          this.selectedInv.splice(index, 1);
        } else {
          this.selectedInv.push(inv.id);
        }
      }
    } else {
      this.selectedInv.length = 0;
      if(this.wo_is_active !== 0 && this. wo_is_active !==2 ){
        this.previewInvSingle(inv);
      }else{
        if(this.wo_is_active == 0){
          this.restoreAlert = true;
        }else{
          this.restoreAlert = false;
        }
        let ___selectedTab = 0;
        const tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
          if ($(v).find('a.active').length > 0) {
            ___selectedTab = i;
          }
        });
        const child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#PreviewAlert')).modal('show');
      }
    }
  }

  ClosePreviewAlert(){
    (<any>$('#PreviewAlert')).modal('hide');
  }


  rightClickMenu(e, asset) {
    e.preventDefault();

    if (this.selectedInv.length < 2) {
      this.selectedInv.length = 0;
      this.selectedInv = [asset.id];
    }

    let target = $(e.target);
    let w = target.closest('#right-click-holder').width();
    let h = target.closest('#right-click-holder').height();
    let p = target.closest('#right-click-holder').offset();
    let left = e.clientX - p.left;
    let top = e.clientY - p.top;
    let clickH = $('.drop-menu-right').height();
    // clickH = clickH < 150 ? 400 : clickH;
    if (this.selectedInv.length > 1) {
      clickH = 150;
    }
    if ((w - left) < 230) {
      left = w - 250;
    }
    if (h < top + clickH) {
      top = top - (top + clickH - h);
    }
    if (top < 10) {
      top = 10;
    }

    let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
    if (ttarget.hasClass('open')) {
      $('.drop-menu-right').removeClass('open');
      ttarget.css({
        top: top,
        left: left
      });
      setTimeout(function () {
        ttarget.addClass('open');
      }, 100);
    } else {
      ttarget.css({
        top: top,
        left: left
      });
      ttarget.addClass('open');
    }
  }

  dropMenuRightToggle(e) {
    var container = $('.invoice-list');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $('.drop-menu-right').removeClass('open');
    }
  }


  removedIds = [];

  removeThisPreInv(id) {
    this.selectedInv.length = 0;
    this.selectedInv[0] = id;
    this.removeThisInv();
  }
  archiveThisPreInv(id) {
    this.selectedInv.length = 0;
    this.selectedInv[0] = id;
    this.archiveThisInventories();
  }

  removeThisInv() {
    let THIS = this;
    let ldd = document.getElementById('removeWorkOrderBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();

    let ids = THIS.selectedInv;
    THIS.removedIds = ids;
    THIS.inventoriesService.removeInventory({ids: ids}).subscribe(res => {
      ladda.stop();
      THIS.getAllInventory();
      THIS.removedIds = [];
      THIS.selectedInv = [];
      // THIS.undoRemoveThisInv(THIS.removedIds);
      toastr.success('Inventory has been deleted successfully', 'Delete Inventory', THIS.toastrOption);
      (<any>$('#deleteWorkOrderModal')).modal('hide');
    });
  }

  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }

  restoreThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#restoreInvModal')).modal('show');
  }
  undoRemoveThisInv() {
    let THIS = this;
    let ldd = document.getElementById('udltInvBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    let idsToDelete = THIS.selectedInv;
    THIS.inventoriesService.removeInventoryUndo({ids: idsToDelete}).subscribe(res => {
      ladda.stop();
      (<any>$('#restoreInvModal')).modal('hide');
      THIS.selectedInv.length = 0;
      THIS.getAllInventory();
      THIS.getStatusCount();
      toastr.success('Deleted inventories has been restored successfully', 'Restore Inventory', THIS.toastrOption);
    });
  }

  archiveThisInventories() {
    let THIS = this;
    let ldd = document.getElementById('arc');
    let ladda = Ladda.create(ldd);
    ladda.start();

    let ids = THIS.selectedInv;
    THIS.removedIds = ids;
    THIS.inventoriesService.archiveInventory({ids: ids}).subscribe(res => {
      ladda.stop();
      THIS.getAllInventory();
      THIS.removedIds = [];
      THIS.selectedInv = [];
      // THIS.undoRemoveThisInv(THIS.removedIds);
      toastr.success('Inventory has been archive successfully', 'Archive Inventory', THIS.toastrOption);
      (<any>$('#ArchiveModal')).modal('hide');
    });
  }

  unarchiveThisInventories() {
    let THIS = this;
    let ldd = document.getElementById('uarc');
    let ladda = Ladda.create(ldd);
    ladda.start();

    let ids = THIS.selectedInv;
    THIS.removedIds = ids;
    THIS.inventoriesService.archiveInventoryUndo({ids: ids}).subscribe(res => {
      ladda.stop();
      THIS.getAllInventory();
      THIS.removedIds = [];
      THIS.selectedInv = [];
      // THIS.undoRemoveThisInv(THIS.removedIds);
      toastr.success('Inventory has been unarchived successfully', 'Unarchived Inventory', THIS.toastrOption);
      (<any>$('#unArchiveModal')).modal('hide');
    });
  }

  archiveThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#ArchiveModal')).modal('show');
  }
  ManageUnarchiveModal() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#unArchiveModal')).modal('show');
}

  editThisInv() {
    let preId = '';
    const index = this.Inventories.map(e => e.id).indexOf(this.selectedInv[0]);
    if (index > -1) {
      let inv = this.Inventories[index];
      preId = inv.uid;
      this.editAsset(preId);
    }
  }

  wo_is_active = 1;
  searchKeyword = '';

  searchKeywordClear($event) {
    this.searchKeyword = '';
    // this.workOrder = this.workOrderList;
    this.getAllInventory();
  }

  filter() {
    let THIS = this;
    clearInterval(THIS.CustomHelper.woListSearch);
    THIS.CustomHelper.woListSearch = setInterval(function () {
      clearInterval(THIS.CustomHelper.woListSearch);
      THIS.getAllInventory();
    }, 1000);
  }

  highlighter(subject) {
    if (subject == '' || subject == null) {
      return subject;
    } else {
      subject = this.CustomHelper.makeStr(subject);
    }
    let matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
    let res = '';
    $.each(matches, function (i, v) {
      res = subject.replace(v, '<span class=\'text-warning-highlight\'>' + v + '</span>');
    });
    if (res == '') {
      res = subject;
    }
    return res;
  }

  selectAllCheck() {
    let THIS = this;
    let WO = this.Inventories;
    if (this.selectedInv.length == this.Inventories.length) {
      this.selectedInv.length = 0;
    } else {
      this.selectedInv.length = 0;
      $.each(WO, function (i, v) {
        THIS.selectedInv.push(v.id);
      });
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  FilterOn = 0;
  FilterScroll = 215;

  ToggleFilter(c) {
    if (c == '') {
      this.FilterOn = this.FilterOn == 1 ? 0 : 1;
    }
    let alrtH = 0;
    if (this.wo_is_active != 0) {
      alrtH = 0;
    } else {
      alrtH = 50;
    }
    if (this.Template == 0 && this.miniScreen == 0) {
      if (this.wo_is_active != 0) {
        this.FilterScroll = 246;
      } else {
        this.FilterScroll = 290;
      }
    } else {
      if (this.FilterOn == 1) {
        this.FilterScroll = 290 + alrtH;
      } else {
        this.FilterScroll = 246 + alrtH;
      }
    }
  }

  SortTitle = 'Created Date';
  sort_by = 'created_at';
  SortOptions = [
    {title: 'Created Date', slug: 'created_at'},
    {title: 'Inventory ID', slug: 'prefix'},
    {title: 'Name', slug: 'title'},
    {title: 'Facility', slug: 'facility'},
    {title: 'Quantity', slug: 'qty'},
  ];
  Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
  changeDensity(density) {
    let THIS = this;
    this.Density = density;
    localStorage.DensityInventory = this.Density;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
    let param = {invt: ''};
    density == 1 ? param.invt = 'd' : '';
    density == 2 ? param.invt = 'cf' : '';
    density == 3 ? param.invt = 'cp' : '';
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'me/density?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
      }
    });
  }

  statusCheck = [];
  FilterName = 'Active';

  updateStatusCheck(status) {
    this.wo_is_active = status;
    if (this.wo_is_active == 3) {
      this.FilterName = 'All';
    } else if (this.wo_is_active == 1) {
      this.FilterName = 'Active';
    } else if (this.wo_is_active == 2) {
      this.FilterName = 'Archived';
    } else if (this.wo_is_active == 0) {
      this.FilterName = 'Trashed';
    }
    this.ToggleFilter('1');
    this.getAllInventory();
  }

  CounterStatus = {
    'total': 0,
    'active': 0,
    'archived': 0,
    'trash': 0,
  };
  getStatusCount() {
    let THIS = this;
    let param = {
      rcti: 1
    };
    THIS.inventoriesService.getInventoryStatus(param).subscribe(res => {
      if (res.status == 2000) {
        this.CounterStatus = {
          'total': res.total,
          'active': res.active,
          'archived': res.archived,
          'trash': res.trash,
        };
      }
    });
  }

  emptyTrash() {
    const ldd = document.getElementById('empBtn');
    const ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    $.ajax({
        url: THIS.APPCONFIG.api + 'inventory/empty/trash?token=' + THIS.currentUser.token,
        data: {},
        type: 'post',
        success: function (res) {
            THIS.selectedInv.length = 0;
            ladda.stop();
            (<any>$('#EmptyTrashModal')).modal('hide');
            toastr.success('Inventories have been permanently deleted.', 'Inventories Empty', this.toastrOption);
            THIS.getAllInventory();
            // Pusher emit
        }
    });
}
emptyThisWork() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
            ___selectedTab = i;
        }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#EmptyTrashModal')).modal('show');
}

  checkThisRow(e, inv) {
    e.preventDefault();

    let index = this.selectedInv.indexOf(inv.id);
    if (index > -1) {
      if (this.selectedInv.length == 1) {
        this.selectedInv.length = 0;
      } else {
        this.selectedInv.splice(index, 1);
      }
    } else {
      this.selectedInv.push(inv.id);
    }
    $('.drop-menu-right').removeClass('open');

  }

  selectThisRow(e, inv) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.which == 1) {
        let index = this.selectedInv.indexOf(inv.id);
        if (index > -1) {
          this.selectedInv.splice(index, 1);
        } else {
          this.selectedInv.push(inv.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    }

  }


  currentUser = null;

  constructor(public inventoriesService: InventoriesService,
              public facilityService: FacilityService,
              public snackBar: MatSnackBar,
              private dialog: MatDialog) {
    let THIS = this;
    setTimeout(function () {

      if (THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] != undefined) {
        window.history.pushState('', '', THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute]);
      }

      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      THIS.Permission = Session.user_type > 2 ? Session.modules.inventory : 1;
      THIS.currentUser = Session;
      // Pagination
      THIS.currentUser.pagination != null ? THIS.perPage = THIS.currentUser.pagination.invt : '';
      // 1 = Default, 2 = Comfortable, 3 = Compact
      THIS.currentUser.list_view.invt.d == 1 ? THIS.Density = 1 : '';
      THIS.currentUser.list_view.invt.cf == 1 ? THIS.Density = 2 : '';
      THIS.currentUser.list_view.invt.cp == 1 ? THIS.Density = 3 : '';

      THIS.routeMake();
      THIS.getAllStore();
      if (window.innerWidth <= 1024) {
        THIS.miniScreen = 1;
        THIS.FilterScroll = 290;
      }
      THIS.facilityService.currentSelectedFac.subscribe(value => THIS.getAllInventory());
      if (localStorage.DensityInventory != undefined) {
        THIS.Density = localStorage.DensityInventory;
      }

    }, 500);
  }

  Template = 0;
  miniScreen = 0;
  ROUTES = '/inventories';
  preId = '';

  routeMake() {
    let route = this.CustomHelper.getRouteStr();
    if (route[2] != undefined) {
      if (route[2] == '') {
        this.Template = 0;
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
      } else if (route[2] == 'new') {
        this.createNewInv();
      } else if (route[3] == 'edit') {
        if (route[3] != undefined) {
          let preId = route[3];
          this.editAsset(preId);
        }
      } else {
        let preId = route[2];
        this.previewInv(preId);
      }
    } else {
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      let url = '/' + Session.team + this.ROUTES;
      window.history.pushState('', '', url);
      this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
      this.Template = 0;
    }
  }

  createNewInv() {
    let THIS = this;
    this.Template = 1;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES + '/new';
    this.preId = '';
    window.history.pushState('', '', url);
    this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 500);
  }

  previewInv(preId) {
    let THIS = this;
    this.Template = 2;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES + '/' + preId;
    this.preId = preId;
    window.history.pushState('', '', url);
    this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 500);
  }

  previewInvSlow(preId) {
    this.preId = '';
    // this.Template = 0;
    let THIS = this;
    setTimeout(function () {
      THIS.previewInv(preId);
    }, 500);
  }

  previewInvSingle(inv) {
    let preId = inv.uid;
    this.previewInv(preId);
  }

  editAsset(preId) {
    let THIS = this;
    this.Template = 3;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES + '/edit/' + preId;
    this.preId = preId;
    window.history.pushState('', '', url);
    this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 500);
  }

  editAssetSlow(preId) {
    this.preId = '';
    let THIS = this;
    setTimeout(function () {
      THIS.editAsset(preId);
    }, 100);
  }

  initialFacilityId = 0;

  OpenImportSection() {
    let THIS = this;

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#openImportModal')).attr('data-id', THIS.initialFacilityId);
    (<any>$('#openImportModal')).modal('show');
  }


  discardAll() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES;
    window.history.pushState('', '', url);
    this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    this.Template = 0;
    this.preId = '';
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 500);
  }

  processing = 0;
  errMSg = '';
  form = {qty: 0, id: 0};

  inv_id_top = 0;
  inv_index_top = -1;

  /* createTopUp(): void {
     const dialogRef = this.dialog.open(InventoryTopListUpDialog, {
       width: '620px',
       disableClose: true,
       data: {inv_id: this.inv_id_top}
     });

     dialogRef.afterClosed().subscribe(result => {
       if (result !== undefined) {
         const THIS = this;
         this.Inventories[this.inv_index_top].rest_qty = this.Inventories[this.inv_index_top].rest_qty + (result - this.Inventories[this.inv_index_top].qty);
         this.Inventories[this.inv_index_top].qty = result;
         toastr.success('Quantity of this inventory has been updated', 'Top Up', THIS.toastrOption);
       }
     });
   }*/
  createTopUp(type: any, data: any) {
    let THIS = this;
    if (data !== null) {
      this.form.id = data.id;
      this.form.qty = data.qty;
    }
    if (type === 1) {
      let ___selectedTab = 0;
      let tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      let child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#TopUpModal')).modal('show');
    } else {
      (<any>$('#TopUpModal')).modal('hide');
    }
  }

  intcheck() {
    this.form.qty = this.CustomHelper.makeInt(this.form.qty);
    if (isNaN(this.form.qty)) {
      this.form.qty = 0;
    }
    if(this.form.qty == 0) {
      this.processing = -1;
    } else {
      this.processing = 0
    }
  }

  create() {
    let ldd = document.getElementById('addQtyBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    this.processing = 1;
    this.inventoriesService.topUp(this.form).subscribe(res => {
      ladda.stop();
      this.processing = 0;
      if (res.status === 2000) {
        this.getAllInventory();
        this.createTopUp(2, null);
        // this.dialogRef.close(res.data);
      }
    });
  }

  currencyIcon():string {
    if(this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }
}


// ============================
// Store Room Create
// ============================
export interface DialogData {
  inv_id: number;
}

@Component({
  selector: 'inventory-top-up-list-dialog',
  templateUrl: './inventory-top-up-list.component.html'
})
export class InventoryTopListUpDialog {
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;

  constructor(public dialogRef: MatDialogRef<InventoryTopListUpDialog>,
              private inventoriesService: InventoriesService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }

  processing = 0;
  errMSg = '';
  form = {qty: 0, id: 0};

  intcheck() {
    this.form.qty = this.CustomHelper.makeInt(this.form.qty);
    if (isNaN(this.form.qty)) {
      this.form.qty = 0;
    }
  }


  create() {
    this.form.id = this.data.inv_id;
    this.processing = 1;
    this.inventoriesService.topUp(this.form).subscribe(res => {
      this.processing = 0;
      if (res.status == 2000) {
        this.dialogRef.close(res.data);
      }
    });
  }

}
