import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssetService } from '../../_services/asset.service';
import { AuthService } from '../../_services/auth.service';
import { ContactsService } from '../../_services/contacts.service';
import { FacilityService } from '../../_services/facility.service';
import { InventoriesService } from '../../_services/inventories.service';
import { InvoicesService } from '../../_services/invoices.service';
import { Job_statusService } from '../../_services/job_status.service';
import { Job_typesService } from '../../_services/job_types.service';
import { MediaService } from '../../_services/media.service';
import { UserService } from '../../_services/user.service';
import { WorkOrderService } from '../../_services/work-orders.service';
import { CustomHelper } from '../../customhelper';
declare let swal: any;
declare let Ladda: any;

@Component({
    selector: 'my-page-person-activation',
    styles: [],
    templateUrl: './personActivation.component.html'
})

export class PersonActivationComponent {

    CustomHelper = CustomHelper;
    user = {
        pin: '',
        password: '',
        password_confirmation: ''
    };
    loading = false;
    errorMessage = '';

    mobileMenu = false;

    constructor( private router: Router,
                 private assetService: AssetService,
                 private authService: AuthService,
                 private contactsService: ContactsService,
                 private facilityService: FacilityService,
                 private inventoriesService: InventoriesService,
                 private invoicesService: InvoicesService,
                 private job_statusService: Job_statusService,
                 private job_typesService: Job_typesService,
                 private mediaService: MediaService,
                 private userService: UserService,
                 private workOrderService: WorkOrderService
    ) {
        if (localStorage.getItem('currentUserInfo') !== null) {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            this.router.navigate([Session.team+'/dashboard']);
        }
        let comp = this.CustomHelper.getRouteStr();
        this.user.pin = comp[2];
    }

    className = 'animated fadeInUp';
    login() {
        let ldd = document.getElementById('submitBTN');
        let ladda = Ladda.create(ldd);
        this.loading = true;
        ladda.start();
        this.authService.activatePass(this.user).subscribe(res => {
            ladda.stop();
            if (res.status == 2000) {
                if(res.status == 2000){
                    let THIS = this;
                    setTimeout(function () {
                        THIS.assetService.refreshToken();
                        THIS.authService.refreshToken();
                        THIS.contactsService.refreshToken();
                        THIS.facilityService.refreshToken();
                        THIS.inventoriesService.refreshToken();
                        THIS.invoicesService.refreshToken();
                        THIS.job_statusService.refreshToken();
                        THIS.job_typesService.refreshToken();
                        THIS.mediaService.refreshToken();
                        THIS.userService.refreshToken();
                        THIS.workOrderService.refreshToken();
                        localStorage.firstComeWelcome = 1;
                        THIS.router.navigate(['/'+res.data.team+'/dashboard']);
                    },400);
                }
            } else {
                this.errorMessage = res.error;
                this.loading = false;
            }
        });
    }

}
