import {Component} from '@angular/core';

import {Job_typesService} from '../../../_services/job_types.service';
import {Job_statusService} from '../../../_services/job_status.service';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {AssetService} from '../../../_services/asset.service';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;

@Component({
  selector: 'layout-setting',
  templateUrl: './layout-setting.component.html'
})
export class LayoutSettingComponent {

  toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;

  currentUser;
  constructor(protected assetService: AssetService) {
    let THIS = this;
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.side_nav = this.currentUser.side_nav;
  }

  side_nav = 0;
  sideNavLayout(side_nav) {
    let THIS = this;
    this.side_nav = side_nav;
    let param = {side_nav: side_nav};
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'updateMe?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        if(res.status == 2000){
          toastr.success('Side Navigation Layout has been updated successfully', 'Side Navigation Layout', THIS.toastrOption);
          $('#sidenavTrigger').attr('data-type', side_nav);
          $('#sidenavTrigger').click();
        }
      }
    });
  }
}
