import { Component} from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { CustomHelper } from '../../customhelper';
import { APPCONFIG } from '../../config';

@Component({
    selector: 'register-process-layout',
    templateUrl: './registerProcess.component.html',
})
export class RegisterProcessComponent {

    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    name = '';

    mobileMenu = false;

    constructor(private router: Router) {
        let comp = this.CustomHelper.getRouteStr();
        this.name = comp[3];

        let THIS = this;
        setTimeout(function () {
            THIS.router.navigate(['/'+THIS.name+'/dashboard']);
        }, 5000);
    }

}
