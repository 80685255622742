import {Component, EventEmitter, Output, Input, SimpleChanges} from '@angular/core';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';

import {WorkOrderService} from '../../../_services/work-orders.service';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';
import {ChatService} from '../../../_services/chat.service';

declare let swal: any;
declare let Ladda: any;
declare let toastr: any;

@Component({
  selector: 'project-single',
  templateUrl: './singleProject.component.html'
})
export class SingleProjectComponent {
  @Output() discard = new EventEmitter<number>();
  @Output() preview = new EventEmitter<number>();
  @Output() editThis = new EventEmitter<number>();
  @Output() archiveThis = new EventEmitter<number>();
  @Output() archiveUndoThis = new EventEmitter<number>();
  @Output() removeThis = new EventEmitter<number>();
  @Output() removeUndoThis = new EventEmitter<number>();
  @Input() previewCurrentWork: any;

  id = 0;
  workId = 0;
  FacilityId = 0;
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  currentUser;
  ActiveChat = [];

  constructor(protected facilityService: FacilityService,
              private workOrderService: WorkOrderService,
              private chatService: ChatService,
              private mediaService: MediaService) {
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.currentUser = Session;

    this.chatService.getActiveChat.subscribe(ActiveChat => {
      this.ActiveChat = ActiveChat;
    });
  }

  createWork = 1;
  createInspect = 0;
  ProjectData = null;
  workDetailsChange = 0;

  ngOnChanges(changes: SimpleChanges): void {
    let THIS = this;
    if (changes.previewCurrentWork != undefined) {
      this.workId = changes.previewCurrentWork.currentValue;
      this.getWorkOrder(this.workId);
    }
  }

  getWorkOrder(uid) {
    let THIS = this;
    let param = {uid: uid};
    THIS.burnDownOptions = null;
    THIS.burnDownChart = null;
    $.ajax({
      url: THIS.APPCONFIG.api + 'projects/single?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          THIS.workDetailsChange = 1;
          res.data.facStr = res.data.facStr.reverse();
          THIS.ProjectData = res.data;
          THIS.getProjectWorkOrder();
          THIS.getProjectInspection();
          THIS.burnDownOptions = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'top',
              display: true
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: false
                }
              }],
              yAxes: [{
                gridLines: {
                  display: false
                },
                ticks: {
                  max: THIS.ProjectData.totalItems,
                  min: 0,
                  stepSize: 1
                }
              }]
            }
          };
          THIS.burnDownChart = {
            labels: THIS.ProjectData.defaultBurnDown.labels,
            datasets: [
              {
                label: 'Completed Work Report',
                data: THIS.ProjectData.defaultBurnDown.dataCompleted,
                fill: false,
                pointBackgroundColor: '#ffffff',
                backgroundColor: '#d53d43',
                pointBorderColor: '#d53d43',
                pointBorderWidth: 2,
                pointRadius: 3,
                borderColor: '#d53d43',
                borderWidth: 2,
                lineTension: 0
              },
              {
                label: 'Ideal Burn down',
                data: THIS.ProjectData.defaultBurnDown.data,
                fill: false,
                pointBackgroundColor: '#ffffff',
                pointBorderColor: '#c2c2c2',
                backgroundColor: '#c2c2c2',
                pointBorderWidth: 2,
                pointRadius: 3,
                borderColor: '#dbdbdb',
                borderWidth: 2,
                lineTension: 0
              }/*,
                            {
                                data: [20, 35, 40, 30, 20, 35, 40, 25, 25, 15, 15, 15, 20, 20, 30],
                                fill: false,
                                pointBackgroundColor: '#ffffff',
                                pointBorderColor: '#1c84c6',
                                pointBorderWidth: 2,
                                pointRadius: 3,
                                borderColor: '#1c84c6',
                                borderWidth: 2,
                                lineTension: 0
                            }*/
            ],
          };
          setTimeout(function () {
            (<any>$('#summerDescSingleProject')).html(THIS.ProjectData.description);
          }, 1000);
        } else if (res.status == 2200) {
          THIS.FacilityId = res.facility_id;
          THIS.workDetailsChange = -22;
        } else {
          THIS.workDetailsChange = -1;
        }
      }
    });
  }

  _fc_updating = 0;

  updateThisFacility(id) {
    let THIS = this;
    this._fc_updating = 1;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/enable?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        THIS._fc_updating = 0;
        THIS.workDetailsChange = 0;
        THIS.getWorkOrder(THIS.workId);
        THIS.facilityService.refreshFacility();
      }
    });
  }


  confirmDiscard() {
    this.discard.emit(1);
  }

  editThisProject() {
    this.editThis.emit(this.ProjectData.id);
  }

  archiveThisProject() {
    this.archiveThis.emit(this.ProjectData.id);
  }

  archiveUndoThisProject() {
    this.archiveUndoThis.emit(this.ProjectData.id);
  }

  removeThisProject() {
    this.removeThis.emit(this.ProjectData.id);
  }

  removeUndoThisProject() {
    this.removeUndoThis.emit(this.ProjectData.id);
  }

  reOpenThisProject() {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'projects/update/status?token=' + THIS.currentUser.token,
      data: {id: THIS.ProjectData.id, status: 0},
      type: 'post',
      success: function (res) {
        THIS.ProjectData.status = 0;
        THIS.getProjectWorkOrder();
        (<any>$('#openWorkOrderModal')).modal('hide');
        THIS.getProjectInspection();
      }
    });
  }

  updateThisWorkStatusModal() {
    // this.workDetails.status_code = this.closeOrderStatus;
    // const data = {
    //   status: this.closeOrderStatus.id,
    //   id: this.closeOrderId,
    // };
    // this.updateThisWorkStatus(this.workDetails.status_code, this.workDetails.id);
    // this.statusChange.emit(data);
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#closeWorkOrderModal')).modal('hide');
    (<any>$('#openWorkOrderModal')).modal('show');
  }

  confirmUpdateWorkOrder() {
    // this.workDetails.status_code = this.closeOrderStatus;
    // const data = {
    //   status: this.closeOrderStatus.id,
    //   id: this.closeOrderId,
    // };
    // this.updateThisWorkStatus(this.workDetails.status_code, this.workDetails.id);
    // this.statusChange.emit(data);
    (<any>$('#closeWorkOrderModal')).modal('hide');
    (<any>$('#openWorkOrderModal')).modal('hide');
  }

  closeProjectConfirm() {
    this.createWork = 1;
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#closeWorkOrderModal')).modal('show');
  }

  closeThisProject() {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'projects/update/status?token=' + THIS.currentUser.token,
      data: {id: THIS.ProjectData.id, status: 1},
      type: 'post',
      success: function (res) {
        THIS.ProjectData.status = 1;
        (<any>$('#closeWorkOrderModal')).modal('hide');
        THIS.getProjectWorkOrder();
        THIS.getProjectInspection();
      }
    });
  }

  rightSecPreview = 0;
  burnDownOptions = null;
  burnDownChart = null;

  discardCreateWork() {
    (<any>$('#createWorkModal')).modal('hide');
    this.getProjectWorkOrder();
    this.getProjectInspection();
    this.createWork = 0;
  }

  createWorkOrder() {
    this.createWork = 1;
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#createWorkModal')).modal('show');
  }

  discardCreateInspection() {
    (<any>$('#createInspection')).modal('hide');
    this.getProjectWorkOrder();
    this.getProjectInspection();
    this.createInspect = 0;
  }

  createInspection() {
    this.createInspect = 1;
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#createInspection')).modal('show');
  }

  discardPreviewWork() {
    this.previewWorkId = 0;
    (<any>$('#PreviewWorkModal')).modal('hide');
    this.getProjectWorkOrder();
    this.getProjectInspection();
  }

  discardPreviewInspection() {
    this.previewWorkId = 0;
    (<any>$('#PreviewInspectModal')).modal('hide');
    this.getProjectWorkOrder();
    this.getProjectInspection();
  }

  previewWorkId = 0;

  previewWorkOrder(work) {
    this.previewWorkId = work.uid;
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#PreviewWorkModal')).modal('show');
  }

  previewInspectId = 0;

  previewInspect(work) {
    this.previewInspectId = work.uid;
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#PreviewInspectModal')).modal('show');
  }

  addNewChat(user) {
    let index = this.ActiveChat.map(function (e) {
      return e.id;
    }).indexOf(user.id);
    if (index == -1) {
      if (this.ActiveChat.length > 2) {
        this.ActiveChat.length = 2;
      }
      this.ActiveChat.push(user);
      this.chatService.updateActiveChat(this.ActiveChat);
    }
  }


  previewList = 1;
  sort_by = 'creation_date';
  order_by = 'desc';
  SortWoTitle = 'Created Date';
  SortWoOptions = [
    {title: 'Created Date', slug: 'creation_date'},
    {title: 'Work ID', slug: 'uid'},
    {title: 'Job Status', slug: 'status_code'},
    {title: 'Subject', slug: 'subject'},
    {title: 'Facility', slug: 'facility_id'},
    {title: 'Due Date', slug: 'due'},
  ];
  wo_is_active = 1;
  pageNo = 1;
  perPage = 25;
  ProjectWorkOrder = [];
  totalWorkOrders = 0;
  pageEvent;

  paginateList() {
    if (this.pageEvent.pageSize == this.perPage) {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = this.pageEvent.pageIndex + 1;
      this.getProjectWorkOrder();
    } else {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = 1;
      this.getProjectWorkOrder();
    }
  }

  getProjectWorkOrder() {
    let THIS = this;
    this.previewList = 0;
    let param = {
      project_id: this.ProjectData.id,
      sort_by: this.sort_by,
      order_by: this.order_by,
      is_active: this.wo_is_active,
      keyword: '',
      pageNo: this.pageNo,
      perPage: this.perPage,
      status: [],
      createdBy: 0
    };
    THIS.selectedWoIde = [];
    $.ajax({
      url: THIS.APPCONFIG.api + 'get/work-orders?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.previewList = 1;
        THIS.ProjectWorkOrder.length = 0;
        if (res.status == 2000 && res.data.length > 0) {
          let Data = [];
          $.each(res.data, function (i, v) {
            v['uid'] = THIS.CustomHelper.makeStr(v['uid']);
            v['dates']['creation_date_tz'] = THIS.CustomHelper.formatDate(v.dates.creation_date);
            v['dates']['creation_date'] = THIS.CustomHelper.DateTimeSelectedFormat(v.dates.creation_date);
            v['dates']['due_at_tz'] = v.dates.due_at != null ? THIS.CustomHelper.formatDate(v.dates.due_at) : '';
            v['dates']['due_at'] = v.dates.due_at != null ? THIS.CustomHelper.DateTimeSelectedFormat(v.dates.due_at) : '';
            v['progress'] = v.progress > 100 ? 100 : v.progress;
            v['progress'] = v.progress < 0 ? 0 : v.progress;
            Data.push(v);
          });
          THIS.ProjectWorkOrder = Data;
          THIS.totalWorkOrders = res.total;
          console.log(res.total);
          setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
          }, 100);
        } else {
          THIS.ProjectWorkOrder.length = 0;
          THIS.totalWorkOrders = 0;
        }
      }
    });
  }

  selectedWoIde = [];
  selectedWoForAction = [];

  checkWoThisRow(e, index, work) {
    e.preventDefault();
    let THIS = this;
    let iIndex = this.selectedWoIde.indexOf(work.id);
    if (iIndex > -1) {
      if (this.selectedWoIde.length == 1) {
        this.selectedWoIde.length = 0;
      } else {
        this.selectedWoIde.splice(iIndex, 1);
      }
    } else {
      this.selectedWoIde.push(work.id);
    }
    $('.drop-menu-right').removeClass('open');
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);

  }

  selectWoAllCheck() {
    let THIS = this;
    let WO = this.ProjectWorkOrder;
    if (this.selectedWoIde.length == this.ProjectWorkOrder.length) {
      this.selectedWoIde.length = 0;
    } else {
      this.selectedWoIde.length = 0;
      $.each(WO, function (i, v) {
        THIS.selectedWoIde.push(v.id);
      });
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  removeWoThisWork(id: any = undefined) {
    let THIS = this;
    let ids = id != undefined ? [id] : this.selectedWoForAction;
    const ldd  = document.getElementById('removeWorkOrderBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'get/work-orders/remove?token=' + THIS.currentUser.token,
      data: {ids: ids},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.previewWorkId = 0;
        (<any>$('#PreviewWorkModal')).modal('hide');
        THIS.getProjectWorkOrder();
        THIS.selectedWoIde.length = 0;
        // THIS.undoWoRemoveThisWork(ids);
        (<any>$('#deleteWorkOrderModal')).modal('hide');
        toastr.success('This job has been trashed successfully', 'Job Trash', this.toastrOption);
      }
    });
  }

  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }

  undoWoRemoveThisWork(ids) {
    let THIS = this;
    const ldd  = document.getElementById('restoreBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'get/work-orders/undo/remove?token=' + THIS.currentUser.token,
      data: {ids: ids},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.selectedWoIde.length = 0;
        THIS.getProjectWorkOrder();
        (<any>$('#RestoreModal')).modal('hide');
        toastr.success('This Jobs has been restored successfully', 'Job Restore', this.toastrOption);
      }
    });
  }
  restoreThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#RestoreModal')).modal('show');
  }
  archiveWoThisWork() {
    let THIS = this;
    let ids = this.selectedWoForAction;
    const ldd  = document.getElementById('archiveBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'get/work-orders/archive?token=' + THIS.currentUser.token,
      data: {ids: ids},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.previewWorkId = 0;
        (<any>$('#PreviewWorkModal')).modal('hide');
        THIS.getProjectWorkOrder();
        THIS.selectedWoIde.length = 0;
        // THIS.undoWoArchiveThisWork(ids);
        (<any>$('#ArchiveModal')).modal('hide');
        toastr.success('This Job has been archived successfully', 'Job Archive', this.toastrOption);
      }
    });
  }

  archiveThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#ArchiveModal')).modal('show');
  }

  undoWoArchiveThisWork(ids) {
    let THIS = this;
    const ldd  = document.getElementById('unarchiveBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'get/work-orders/undo/archive?token=' + THIS.currentUser.token,
      data: {ids: ids},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.selectedWoIde.length = 0;
        THIS.getProjectWorkOrder();
        (<any>$('#UnarchiveModal')).modal('hide');
        toastr.success('This Job has been unarchived successfully', 'Job Unarchive', this.toastrOption);
      }
    });
  }

  unarchiveThisWorkOrder(){
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#UnarchiveModal')).modal('show');
  }

  pdfWoThisWorkOrder() {
    let THIS = this;
    let id = this.selectedWoIde[0];
    window.open(THIS.APPCONFIG.apiAuth + 'preview/upload/pdf?id=' + id);
  }

  zipWoThisWorkOrder() {
    let THIS = this;
    let ids = this.selectedWoIde.join();
    let win = window.open(THIS.APPCONFIG.api + 'work-orders/zip/image/' + ids + '?token=' + THIS.currentUser.token, '_blank');
    win.focus();
  }

  duplicateWoWorkOrder() {
    let THIS = this;
    let id = this.selectedWoIde[0];
    const ldd  = document.getElementById('duplicateBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'work-orders/duplicate?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        ladda.stop();
        if (res.status != undefined && res.status == 3000) {
          swal({
            title: 'Work Order',
            text: 'You have reached your free plan limit. To create more work orders, please subscribe to the Pro plan from your account page.',
            icon: 'warning',
            dangerMode: true,
            className: 'swal-btn-center'
          });
          return true;
        }
        THIS.getProjectWorkOrder();
        THIS.selectedWoIde.length = 0;
        (<any>$('#DuplicateWoModal')).modal('hide');
        toastr.success('This Job has been duplicated successfully', 'Job Duplicate', this.toastrOption);
      }
    });
  }

  duplicateThisWoWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#DuplicateWoModal')).modal('show');
  }

  previewListInsp = 1;
  sort_byInsp = 'creation_date';
  order_byInsp = 'desc';
  SortInsTitle = 'Created Date';
  SortInsOptions = [
    {title: 'Created Date', slug: 'creation_date'},
    {title: 'Inspection ID', slug: 'uid'},
    {title: 'Job Status', slug: 'status_code'},
    {title: 'Subject', slug: 'subject'},
    {title: 'Facility', slug: 'facility'},
    {title: 'Due Date', slug: 'due'},
  ];
  inc_is_active = 1;
  pageNoInsp = 1;
  perPageInsp = 25;
  ProjectWorkOrderInsp = [];
  totalWorkOrdersInsp = 0;
  pageEventInsp;

  paginateListInsp() {
    if (this.pageEventInsp.pageSize == this.perPageInsp) {
      this.perPageInsp = this.pageEventInsp.pageSize;
      this.pageNoInsp = this.pageEventInsp.pageIndex + 1;
      this.getProjectInspection();
    } else {
      this.perPageInsp = this.pageEventInsp.pageSize;
      this.pageNoInsp = 1;
      this.getProjectInspection();
    }
  }

  getProjectInspection() {
    let THIS = this;
    this.previewListInsp = 0;
    let param = {
      type: 3,
      project_id: this.ProjectData.id,
      sort_by: this.sort_byInsp,
      order_by: this.order_byInsp,
      is_active: this.inc_is_active,
      keyword: '',
      pageNo: this.pageNoInsp,
      perPage: this.perPageInsp,
      status: [],
      createdBy: 0
    };
    THIS.selectedInsIde = [];
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'get-list?token=' + THIS.currentUser.token,
      data: param,
      type: 'post',
      success: function (res) {
        THIS.ProjectWorkOrderInsp.length = 0;
        THIS.previewListInsp = 1;
        if (res.status == 2000 && res.data.length > 0) {
          let Data = [];
          $.each(res.data, function (i, v) {
            v['uid'] = THIS.CustomHelper.makeStr(v['uid']);
            v['dates']['creation_date_tz'] = THIS.CustomHelper.formatDate(v.dates.creation_date);
            v['dates']['creation_date'] = THIS.CustomHelper.DateTimeSelectedFormat(v.dates.creation_date);
            v['dates']['due_at_tz'] = v.dates.due_at != null ? THIS.CustomHelper.formatDate(v.dates.due_at) : '';
            v['dates']['due_at'] = v.dates.due_at != null ? THIS.CustomHelper.DateTimeSelectedFormat(v.dates.due_at) : '';
            v['progress'] = v.progress > 100 ? 100 : v.progress;
            v['progress'] = v.progress < 0 ? 0 : v.progress;
            Data.push(v);
          });
          THIS.ProjectWorkOrderInsp = Data;
          THIS.totalWorkOrdersInsp = res.total;
          setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
          }, 100);
        } else {
          THIS.ProjectWorkOrderInsp.length = 0;
          THIS.totalWorkOrdersInsp = 0;
        }
      }
    });
  }

  selectedInsIde = [];
  selectedInsForAction = [];

  selectInsAllCheck() {
    let THIS = this;
    let WO = this.ProjectWorkOrderInsp;
    if (this.selectedInsIde.length == this.ProjectWorkOrderInsp.length) {
      this.selectedInsIde.length = 0;
    } else {
      this.selectedInsIde.length = 0;
      $.each(WO, function (i, v) {
        THIS.selectedInsIde.push(v.id);
      });
    }
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  checkInsThisRow(e, index, work) {
    e.preventDefault();
    let THIS = this;
    let iIndex = this.selectedInsIde.indexOf(work.id);
    if (iIndex > -1) {
      if (this.selectedInsIde.length == 1) {
        this.selectedInsIde.length = 0;
      } else {
        this.selectedInsIde.splice(iIndex, 1);
      }
    } else {
      this.selectedInsIde.push(work.id);
    }
    $('.drop-menu-right').removeClass('open');
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);

  }

  removeInsThisWork(ids: any) {
    let THIS = this;
    const ldd  = document.getElementById('removeInsBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'erase/remove?token=' + THIS.currentUser.token,
      data: {ids: ids},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.previewWorkId = 0;
        (<any>$('#PreviewInspectModal')).modal('hide');
        THIS.getProjectInspection();
        THIS.selectedInsIde.length = 0;
        // THIS.undoInsRemoveThisWork(ids);
        (<any>$('#deleteInsWorkOrderModal')).modal('hide');
        toastr.success('This Inspection has been trashed successfully', 'Inspection Trash', this.toastrOption);
      }
    });
  }

  deleteThisInsWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#deleteInsWorkOrderModal')).modal('show');
  }

  undoInsRemoveThisWork(ids) {
    const ldd  = document.getElementById('restoreInsBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'erase/undo/remove?token=' + THIS.currentUser.token,
      data: {ids: ids},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.selectedInsIde.length = 0;
        THIS.getProjectInspection();
        (<any>$('#RestoreInsModal')).modal('hide');
        toastr.success('This Inspection has been restored successfully', 'Inspection Restore', this.toastrOption);
      }
    });
  }
  restoreInsThisWorkOrder(){
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#RestoreInsModal')).modal('show');
  }

  archiveInsThisWork() {
    let THIS = this;
    let ids = THIS.selectedInsForAction;
    const ldd  = document.getElementById('archiveInsBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'erase/archive?token=' + THIS.currentUser.token,
      data: {ids: ids},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.previewWorkId = 0;
        (<any>$('#PreviewInspectModal')).modal('hide');
        THIS.getProjectInspection();
        THIS.selectedInsIde.length = 0;
        // THIS.undoInsArchiveThisWork(ids);
        (<any>$('#ArchiveInsModal')).modal('hide');
        toastr.success('This inspection has been archived successfully', 'Inspection Archive', this.toastrOption);
      }
    });
  }

  archiveInsThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#ArchiveInsModal')).modal('show');
  }

  undoInsArchiveThisWork(ids) {
    let THIS = this;
    const ldd  = document.getElementById('unarchiveInsBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'erase/undo/archive?token=' + THIS.currentUser.token,
      data: {ids: ids},
      type: 'post',
      success: function (res) {
        console.log(res);
        ladda.stop();
        THIS.selectedInsIde.length = 0;
        THIS.getProjectInspection();
        (<any>$('#UnarchiveInsModal')).modal('hide');
        toastr.success('This inspection has been unarchived successfully', 'Inspection Unarchive', this.toastrOption);
      }
    });
  }

  unarchiveThisIns() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#UnarchiveInsModal')).modal('show');
  }

  pdfInsThisWorkOrder() {
    let THIS = this;
    let id = this.selectedInsIde[0];
    window.open(THIS.APPCONFIG.apiInc + 'make/pdf/' + id);
  }

  zipInsThisWorkOrder() {
    let THIS = this;
    let ids = this.selectedInsIde.join();
    let win = window.open(THIS.APPCONFIG.apiInc + 'zip/image/' + ids + '?token=' + THIS.currentUser.token, '_blank');
    win.focus();
  }

  duplicateInsWorkOrder() {
    let THIS = this;
    let id = this.selectedInsIde[0];
    const ldd  = document.getElementById('duplicateInsBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.apiInc + 'duplicate?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        ladda.stop();
        THIS.getProjectInspection();
        THIS.selectedInsIde.length = 0;
        (<any>$('#DuplicateInsModal')).modal('hide');
        toastr.success('This inspection has been duplicated successfully', 'Inspection Duplicate', this.toastrOption);
      }
    });
  }

  duplicateThisInsWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#DuplicateInsModal')).modal('show');
  }
}
