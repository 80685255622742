import {Component, EventEmitter, Output, Input, TemplateRef, SimpleChanges} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ContactsService} from '../../../_services/contacts.service';
import {InvoicesService} from '../../../_services/invoices.service';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {FacilityService} from '../../../_services/facility.service';
import {FcmService} from '../../../_services/fcm.service';

declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let Inputmask: any;

@Component({
  selector: 'edit-invoice',
  templateUrl: './editInvoice.component.html'
})
export class EditInvoiceComponent {
  @Input() singleInvoice: any;
  @Output() discard = new EventEmitter<number>();
  @Output() emailIt = new EventEmitter<number>();
  @Output() updated = new EventEmitter<number>();
  CustomHelper:any = CustomHelper;
  APPCONFIG = APPCONFIG;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
  currentUser;

  constructor(private contactsService: ContactsService,
              public snackBar: MatSnackBar,
              private facilityService: FacilityService,
              private fcmService: FcmService,
              private invoicesService: InvoicesService) {
    this.currentUser = JSON.parse(localStorage.currentUserInfo);
    this.checkCurrency();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.singleInvoice !== undefined) {
      let singleInvoice = changes.singleInvoice.currentValue;
      this.createCartItemsSubTotal = singleInvoice.sub_total;
      this.createCartItemsTax.val = singleInvoice.tax_value;
      console.log(this.createCartItemsTax.val);
      this.param.tax_value = singleInvoice.tax_value;
      this.createCartItemsTotal = singleInvoice.total;
      this.createCartItems = singleInvoice.items;
      this.param.contact_id = singleInvoice.contact != null ? singleInvoice.contact.id.toString() : '';
      this.param.facility_id = singleInvoice.facility_id.toString();
      this.param.invoice_date = this.CustomHelper.formatDateOnlyToDB(this.CustomHelper.invToDate(singleInvoice.invoice_date));
      this.param.due_date = this.CustomHelper.formatDateOnlyToDB(this.CustomHelper.invToDate(singleInvoice.due_date));
      this.param.invoice_number = singleInvoice.invoice_number;
      this.param.order_number = singleInvoice.order_number;
      this.param.terms = singleInvoice.terms.toString();
      this.param.tax_type = singleInvoice.tax_type;
      this.param.customer_notes = singleInvoice.customer_notes != null ? singleInvoice.customer_notes : '';
      this.param.terms_condition = singleInvoice.terms_condition != null ? singleInvoice.terms_condition : '';
      this.param.customer_note_on = singleInvoice.customer_note_on;
      this.param.term_on = singleInvoice.term_on;
      this.param.items = singleInvoice.items;
      this.param.id = singleInvoice.id;
      this.param.prefix = singleInvoice.prefix;
      this.param.invoice_id = singleInvoice.invoice_id;
      let THIS = this;
      setTimeout(function () {
        THIS._startDateConfig = {
          timePicker: false,
          defaultDate: new Date(THIS.param.invoice_date),
          dateFormat: THIS.CustomHelper.dateFormatFlat(),
          onChange: function (selectedDates, dateStr, instance) {
          }
        };
        THIS._startShow = 1;
        THIS.onCreatedSelect();

        THIS._dueDateConfig = {
          timePicker: false,
          minDate: null,
          defaultDate: new Date(THIS.param.due_date),
          dateFormat: THIS.CustomHelper.dateFormatFlat(),
          onChange: function (selectedDates, dateStr, instance) {
          }
        };

        THIS._startDateConfig.onChange = function (selectedDates, dateStr, instance) {
          THIS.param.invoice_date = CustomHelper.formatDateToDB(selectedDates);
          THIS.onCreatedSelect();
        };
        THIS._dueDateConfig.onChange = function (selectedDates, dateStr, instance) {
          THIS.param.due_date = CustomHelper.formatDateToDB(selectedDates);
          THIS.onDueSelect();
        };

        THIS.genEditor();
      });

      this.getAllFacility();
      this.getAllContact(this.param.facility_id);
    }
  }

  genEditor() {
    let THIS = this;
    setTimeout(function () {
      (<any>$('.summernote')).summernote({
        maxHeight: '900px',
        minHeight: '150px',
        toolbar: [
          ['style', ['bold', 'underline', 'clear']],
          ['para', ['ul', 'ol', 'paragraph']],
          // ['insert', ['link','video']],
          ['misc', ['fullscreen']]
        ]
      });
      if (THIS.param.customer_notes != '') {
        (<any>$('#customer_note')).summernote('code', THIS.param.customer_notes);
      }
      if (THIS.param.terms_condition != ''){
        (<any>$('#terms_condition')).summernote('code', THIS.param.terms_condition);
      }
    }, 100);
  }

  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction
    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],
    ['clean'],                                         // remove formatting button
    // ['link', 'image', 'video']                         // link and image, video
    // ['image']                         // link and image, video
  ];


  Facility = [];
  FacilityComp = [];

  getAllFacility() {
    let THIS = this;
    this.facilityService.getFacilitiesList().subscribe(res => {
      this.Facility.length = 0;
      this.FacilityComp.length = 0;
      this.FacilityComp = res.facility;
      $.each(res.facility, function (i, v) {
        v['pp'] = 0;
        THIS.Facility.push(v);
        $.each(v.child, function (j, vC) {
          vC['pp'] = 1;
          THIS.Facility.push(vC);
          $.each(vC.child, function (k, vCC) {
            vCC['pp'] = 2;
            THIS.Facility.push(vCC);
            $.each(vCC.child, function (l, vCCC) {
              vCCC['pp'] = 3;
              THIS.Facility.push(vCCC);
            });
          });
        });
      });
      setTimeout(function () {
        (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Facility'
        }).on('change', function (e, p) {
          THIS.param.facility_id = e.target.value;
          THIS.updateChangesDiscard();
          THIS.getAllContact(THIS.param.facility_id);
        });
      }, 100);
    });
  }

  contact = [];

  getAllContact(id) {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/getSingle/contact?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          THIS.contact = res.data;
        }
        setTimeout(function () {
          (<any>$('.chosen-default-single-contact')).chosen('destroy');
          (<any>$('.chosen-default-single-contact')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Contact'
          }).on('change', function (e, p) {
            THIS.param.contact_id = e.target.value;
          });
        }, 100);
        setTimeout(function () {
          (<any>$('.chosen-default-single-terms')).val(THIS.param.terms);
          (<any>$('.chosen-default-single-terms')).chosen('destroy');
          (<any>$('.chosen-default-single-terms')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Invoice Terms'
          }).on('change', function (e, p) {
            THIS.param.terms = e.target.value;
            THIS.onCreatedSelect();
          });
        }, 100);
      }
    });
  }

  createCartItemsSubTotal:any = 0;
  createCartItemsTax:any = {
    val: 10,
    type: 'exclusive'
  };
  createCartItemsTotal = 0;
  createCartItems = [
    {
      item_name: '',
      item_qty: 1,
      item_price: 1,
      item_tax: 'Tax Included',
      item_total: 1
    }
  ];

  addNewItemInCart(): void {
    let newCart = {
      item_name: '',
      item_qty: 1,
      item_price: 1,
      item_tax: 'Tax Included',
      item_total: 1,
    };
    this.createCartItems.push(newCart);
    this.changeInvoiceTotal();
    this.checkCurrency();
  }

  removeFromCart(index): void {
    if (this.createCartItems.length > 1) {
      this.createCartItems.splice(index, 1);
    }
    this.changeInvoiceTotal();
  }

  copyThisInCart(cart): void {
    let newCart = {
      item_name: cart.item_name,
      item_qty: cart.item_qty,
      item_price: cart.item_price,
      item_tax: cart.item_tax,
      item_total: cart.item_total,
    };
    this.createCartItems.push(newCart);
    this.changeInvoiceTotal();
    this.checkCurrency();
  }

  updateLineName(e, index: number): void {
    let trigger = $(e.target);
    let value = trigger.val();
    let name = value.toString();
    this.createCartItems[index].item_name = name;
  }

  updateLineQty(e, index: number): void {
    let trigger = $(e.target);
    let value = trigger.val();
    let Qty = Number(value);
    this.createCartItems[index].item_qty = Qty;
    let lineTotal = this.createCartItems[index].item_qty * this.createCartItems[index].item_price;
    this.createCartItems[index].item_total = lineTotal;
    this.changeInvoiceTotal();
  }

  updateLinePrice(e, index: number): void {
    let trigger = $(e.target);
    let inputValue = this.CustomHelper.makeStr(trigger.val());
    let value = inputValue.replace(/,/, '');
    value = value.substring(this.currencyIcon().length);
    let price = Number(value);
    this.createCartItems[index].item_price = price;
    let lineTotal = this.createCartItems[index].item_qty * this.createCartItems[index].item_price;
    this.createCartItems[index].item_total = lineTotal;
    this.changeInvoiceTotal();
  }

  changeInvoiceTax(tax: number, type: string): void {
    this.createCartItemsTax.val = tax;
    this.createCartItemsTax.type = type;
    this.changeInvoiceTotal();
    if (tax == 10 && type == 'exlusive') {
      this.param.tax_type = 1;
    }
    if (tax == 10 && type == 'inlusive') {
      this.param.tax_type = 2;
    }
    if (tax == 5 && type == 'exlusive') {
      this.param.tax_type = 3;
    }
    if (tax == 5 && type == 'inclusive') {
      this.param.tax_type = 4;
    }
    this.param.tax_value = tax;
  }

  changeInvoiceTotal(): void {
    let subTotal = 0;
    $.each(this.createCartItems, function (i, v) {
      subTotal = subTotal + v.item_total;
    });
    this.createCartItemsSubTotal = subTotal;
    this.createCartItemsTotal = this.createCartItemsSubTotal + (this.createCartItemsSubTotal * (this.createCartItemsTax.val / 100));
  }

  discardNewInvoice() {
    this.updated.emit(this.singleInvoice);
  }

  updateChangesDiscard() {
    // this.CustomHelper.woRouteDiscard = 1;
  }


  _startShow = 0;
  _startDateConfig = null;
  due_date_preview = 0;

  onCreatedSelect() {
    let THIS = this;
    this.due_date_preview = 0;
    let terms = parseInt(this.param.terms);
    let invDate = null;
    if (terms > 1 && THIS.param.invoice_date != '') {
      invDate = new Date(THIS.param.invoice_date);
      invDate.setDate(invDate.getDate() + terms);
      this.param.due_date = CustomHelper.formatDateToDB(invDate);
    } else {
      this.param.due_date = this.param.due_date;
      invDate = new Date(this.param.due_date);
    }
    setTimeout(function () {
      THIS._dueDateConfig.minDate = new Date(THIS.param.invoice_date);
      THIS._dueDateConfig.defaultDate = invDate;
      THIS.due_date_preview = 1;
    }, 400);
    this.updateChangesDiscard();
  }

  _dueDateConfig = null;

  onDueSelect() {
    let THIS = this;
    setTimeout(function () {
      THIS.param.terms = '0';
      (<any>$('.chosen-default-single-terms')).val('0');
      (<any>$('.chosen-default-single-terms')).chosen('destroy');
      (<any>$('.chosen-default-single-terms')).chosen({
        allow_single_deselect: true,
        placeholder_text_single: 'Select Invoice Terms'
      }).on('change', function (e, p) {
        THIS.param.terms = e.target.value;
        THIS.onCreatedSelect();
        THIS.updateChangesDiscard();
      });
    }, 100);
    this.updateChangesDiscard();
  }


  param = {
    id: 0,
    prefix: 'IN',
    invoice_id: '',
    facility_id: '',
    contact_id: '',
    invoice_date: '',
    invoice_number: Date.now().toString(),
    order_number: '',
    terms: '1',
    due_date: '',
    sub_total: 0,
    tax_type: 1,
    tax_value: 10,
    total: 0,
    customer_notes: '',
    terms_condition: '',
    customer_note_on: 1,
    term_on: 1,
    items: '',
  };

  emailThisInvoice() {
    this.emailIt.emit(1);
  }

  // valid = 0;
  createThisInvoice() {
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      let freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        let freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    let ldd = document.getElementById('SaveThisLadda');
    let ladda = Ladda.create(ldd);
    let valid = this.validationThisForm();
    if (valid == 1) {
      let THIS = this;
      ladda.start();
      console.log(this.param.customer_notes, this.param.terms_condition);
      this.param.customer_notes = (<any>$('#customer_note')).summernote('code');
      this.param.terms_condition = (<any>$('#terms_condition')).summernote('code');
      this.param.sub_total = this.createCartItemsSubTotal;
      this.param.total = this.createCartItemsTotal;
      this.param.items = JSON.stringify(this.createCartItems);
      this.param.invoice_date = this.CustomHelper.formatDateOnlyToDB(this.param.invoice_date);
      this.param.due_date = this.CustomHelper.formatDateOnlyToDB(this.param.due_date);
      this.invoicesService.editInvoice(this.param).subscribe(res => {
        ladda.start();
        this.singleInvoice = res;
        this.discardNewInvoice();
        toastr.success('Invoice has been successfully updated', 'Update Invoice', this.toastrOption);
        // Pusher emit
        $.ajax({
          url: THIS.APPCONFIG.apiAuth + 'invoice-recurring-cron',
          type: 'get',
          data: {},
          success: function (res) {
          }
        });
      });
    }
  }

  validationThisForm() {
    let valid = 1;
    if (this.param.facility_id == '') {
      valid = 0;
      swal({
        title: 'Please select a facility.',
        className: 'swal-btn-center'
      });
      return false;
    }
    if (this.param.invoice_date == '') {
      valid = 0;
      swal({
        title: 'Please select invoice date.',
        className: 'swal-btn-center'
      });
      return false;
    }
    return valid;
  }


  inlineEdit = 0;

  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    /*this.socket.on('_rfr_team_data_fac_'+Session.team, function (v) {
        THIS.getAllFacility();
    });*/
  }

  ngOnDestroy() {
  }

    checkCurrency() {
        Inputmask.extendAliases({
            pesos: {
                prefix: `${this.currencyIcon()}`,
                groupSeparator: "",
                alias: "numeric",
                autoGroup: true,
                digits: 2,
                digitsOptional: false,
                radixPoint: '.',
                placeholder: '0.00'
            }
        });


        setTimeout(function () {
            (<any>$("._currency_validate_eq")).inputmask({ alias: "pesos" });
        }, 300);
    }

    currencyIcon() {
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
    }
}
