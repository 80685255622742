import {Component, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {AuthService} from '../../../_services/auth.service';
import {TabsService} from '../../../_services/tabs.service';
import {ChatService} from "../../../_services/chat.service";
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';

declare let swal: any;
declare let Pusher: any;

@Component({
    selector: 'my-app-sidenav-menu',
    styles: [],
    templateUrl: './sidenav-menu.component.html'
})

export class AppSidenavMenuComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    @Input() changeTab: any;
    @Output() closeSide = new EventEmitter<number>();
    activeLinks: Array<number>;
    selectedTab: number;
    selectedPage: any;
    Module = null;
    Menus = [];
    currentUser;
    miniScreen = 0;
    ActiveChat = [];
    makeChatActive = false;
    constructor(private tabsService: TabsService, private authService: AuthService, private chatService: ChatService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.Module = this.currentUser.modules;
        this.Menus = this.currentUser.settings.menus;

        this.tabsService
            .getSelectedTab
            .subscribe(tab => {
                this.selectedTab = tab;
            });
        this.tabsService
            .getSelectedPage
            .subscribe(page => {
                this.selectedPage = page;
            });
        this.tabsService
            .getActiveLinks
            .subscribe(activeLinks => this.activeLinks = activeLinks);
        this.authService
            .getUserType
            .subscribe(userType => {
            });
        this.authService.FetchSessionUserInfo.subscribe(user => {
            if(user !== null){
                this.currentUser = user;
            }
        })
        this.getSessionUserInfo();
        this.getOnlineUsers();

        setTimeout(function () {
            (<any>$("#side-menu")).metisMenu();
        }, 500);
        if (window.innerWidth <= 650) {
            THIS.miniScreen = 1;
        }

        $('.sidebar-content').off().on('click', '#refreshMenu', function () {
            THIS.getSessionUserInfo();
        });

        this.chatService.getActiveChat.subscribe(ActiveChat => {
            this.ActiveChat = ActiveChat;
        });
        this.chatService.NewConversation.subscribe(v => {
            if (v == true) {
                this.getOnlineUsers();
            }
        });

        //===============
        // Pusher
        //===============
        Pusher.logToConsole = true;
        let pusher = new Pusher('501b3b237bd799758df0', {
            cluster: 'ap4'
        });
        let channel = pusher.subscribe('eq-msg');
        channel.bind('SendMessage', function (data) {
            const v = data.message;
            if (v.pn_type == 'new-msg') {
                if (data.message.receiver_id == THIS.currentUser.id) {
                    let index = THIS.ActiveChat.map(function (e) {
                        return e.id;
                    }).indexOf(data.message.sender_id);
                    if (index == -1) {
                        let ind = THIS.OnlineUsers.map(function (e) {
                            return parseInt(e.id);
                        }).indexOf(parseInt(data.message.sender_id));
                        if (ind > -1) {
                            THIS.addNewChat(THIS.OnlineUsers[ind]);
                            const audio = <HTMLVideoElement>document.getElementById('chat_audio');
                            audio.play();

                        }
                    }
                }
            }
        });
        //===============
        // Pusher
        //===============
    }

    closeSideMenu() {
      this.closeSide.emit(1);
    }

    addNewChat(user) {
        let index = this.ActiveChat.map(function (e) {
            return e.id;
        }).indexOf(user.id);
        if (index == -1) {
            if (this.ActiveChat.length > 2) {
                this.ActiveChat.length = 2;
            }
            this.ActiveChat.push(user);
            this.chatService.updateActiveChat(this.ActiveChat);
        }
    }

    getSessionUserInfo() {
        this.authService.getSessionUserInfo().subscribe(res => {
            if (res.status == 2000) {
                localStorage.currentUserInfo = JSON.stringify(res.data);
                this.Module = res.data.modules;
                this.Menus = res.data.settings.menus;
                setTimeout(function () {
                    (<any>$(".nav-label-icon")).tooltip('destroy');
                }, 1000);
                setTimeout(function () {
                    (<any>$(".nav-label-icon")).tooltip('destroy');
                }, 5000);
            }
        });
    }

    switchView(page: string) {
        if (this.currentUser.subscription.plan == 'Free' && this.currentUser.subscription.status === 1 && (page == 'work-order-recurring' || page == 'inspections' || page == 'inspections-recurring')) {
            (<any>$('#restrictionModuleModal')).modal('show');
            return false;
        }

        this.presenceAlert();
        let THIS = this;
        delete THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute];

        this.CustomHelper.woRouteDiscard = 0;
        let index = THIS.CustomHelper.ConfRoute.map(function (e) {
            return e.tab;
        }).indexOf(THIS.CustomHelper.ConfCurRoute);
        if (index > -1) {
            // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
        }
        if (this.CustomHelper.woRouteDiscard == 1) {
            swal({
                title: "Are you sure you want to leave?",
                text: "You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.",
                icon: "warning",
                buttons: ['CANCEL', 'LEAVE'],
                dangerMode: true,
            }).then((res) => {
                if (res) {
                    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
                    window.history.pushState("", "", '/' + Session.team + '/' + page);
                    THIS.tabsService.makeFirstTab(page);
                    THIS.CustomHelper.woRouteDiscard = 0;
                    THIS.CustomHelper.ConfRoute.splice(index, 1);
                }
            });
        } else {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            window.history.pushState("", "", '/' + Session.team + '/' + page);
            THIS.tabsService.makeFirstTab(page);
            THIS.CustomHelper.woRouteDiscard = 0;
        }
    }

    presenceAlert() {
    }

    OnlineUsers = [];

    getOnlineUsers() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiMsg + 'get/thread?token=' + THIS.currentUser.token,
            type: 'post',
            success: function (res) {
                if (res.status === 2000) {
                    THIS.OnlineUsers = res.data.filter((user: any) => {
                        return user.user_type != 3;
                    });
                }
            }
        });
    }

    inviteMsg() {
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
            }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#newThreadModal')).modal('show');
    }

    ngOnChanges(changes: SimpleChanges): void {
        let THIS = this;
        if (changes.selectedPage != undefined) {
            if (changes.selectedPage.currentValue != null) {
                console.log(changes.selectedPage.currentValue);
            }
        }
    }

}
