import {Component, EventEmitter, Output, Input, OnInit} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
declare let swal: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
    selector: 'page-previewFm',
    templateUrl: './previewFm.component.html',
    styles: []
})
export class PreviewFmComponent implements OnInit {
    @Input() FmId = 0;
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    @Output() editThis = new EventEmitter<number>();
    @Output() removeThis = new EventEmitter<number>();
    // =======================
    // General Variables
    // =======================
    currentUser;
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Permission = 1;
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true};

    src = '';
    runFM(){
        let h = this.CustomHelper.fullHeight(280);
        let fm = '<iframe class="_well rounded ash" style="height : '+h+'" src="'+this.src+'" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe>';
        setTimeout(() => {
            (<any>$('#fmNow')).html(fm);
        }, 100);
    }

    constructor() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    }
    ngOnInit() {
        this.getSingleFm();
    }

    FM = null;
    getSingleFm() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'fm360/single?token=' + THIS.currentUser.token,
            data: {uid: THIS.FmId},
            type: 'post',
            success: function (res) {
                if(res.status === 2000){
                    THIS.FM = res.data;
                    THIS.src = res.data.embed;
                    THIS.runFM()
                }
            }
        });
    }

    discardFM(){
        this.discard.emit();
    }
    editThisFm(){
        this.editThis.emit(this.FM.uid);
    }
    removeThisFm(){
        let THIS = this;
        let ldd = document.getElementById('removeWorkOrderBtn');
        let ladda = Ladda.create(ldd);
        ladda.start();
 /*       swal({
            title: "Delete Fm360(s)",
            text: "Are you sure you want to delete this Fm360(s)?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let ids = [THIS.FM.uid];
                $.ajax({
                    url: THIS.APPCONFIG.api + 'fm360/make/remove?token=' + THIS.currentUser.token,
                    data: {ids: ids},
                    type: 'post',
                    success: function (res) {
                        swal({
                            title: "Success",
                            text: res.msg,
                            icon: "success",
                            buttons: ['Undo Remove', 'Ok'],
                            dangerMode: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                                swal.close();
                                THIS.discard.emit();
                            } else {
                                THIS.removeUndoThisWork();
                            }
                        });
                    }
                });
            } else {
                swal.stopLoading();
                swal.close();
            }
        });*/
      let ids = [THIS.FM.uid];
      $.ajax({
        url: THIS.APPCONFIG.api + 'fm360/make/remove?token=' + THIS.currentUser.token,
        data: {ids: ids},
        type: 'post',
        success: function (res) {
          ladda.stop();
          THIS.discard.emit();
          toastr.success(res.msg, 'Fm360 Trash', this.toastrOption);
        }
      });
    }
    deleteThisWorkOrder() {
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#deleteWorkOrderModal')).modal('show');
    }
    removeUndoThisWork() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'fm360/make/remove/undo?token=' + THIS.currentUser.token,
            data: {ids: [THIS.FM.id]},
            type: 'post',
            success: function (res) {
            }
        });
    }
    archiveThisFm(){
        let THIS = this;
     /*   swal({
            title: "Archive Fm360(s)",
            text: "Are you sure you want to delete this Fm360(s)?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let ids = [THIS.FM.uid];
                $.ajax({
                    url: THIS.APPCONFIG.api + 'fm360/make/archive?token=' + THIS.currentUser.token,
                    data: {ids: ids},
                    type: 'post',
                    success: function (res) {
                        swal({
                            title: "Success",
                            text: res.msg,
                            icon: "success",
                            buttons: ['Undo Archive', 'Ok'],
                            dangerMode: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                                swal.close();
                                THIS.discard.emit();
                            } else {
                                THIS.archiveUndoThisWork();
                            }
                        });
                    }
                });
            } else {
                swal.stopLoading();
                swal.close();
            }
        });*/
      const ldd = document.getElementById('archiveBtn');
      let ladda = Ladda.create(ldd);
      ladda.start();
      let ids = [THIS.FM.uid];
      $.ajax({
        url: THIS.APPCONFIG.api + 'fm360/make/archive?token=' + THIS.currentUser.token,
        data: {ids: ids},
        type: 'post',
        success: function (res) {
          ladda.stop();
          THIS.discard.emit();
          toastr.success(res.msg, 'Fm360 Archive', this.toastrOption);
        }
      });
    }

    archiveThisWorkOrder() {
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#ArchiveModal')).modal('show');
    }
    archiveUndoThisWork() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'fm360/make/archive/undo?token=' + THIS.currentUser.token,
            data: {ids: [THIS.FM.id]},
            type: 'post',
            success: function (res) {
            }
        });
    }
}
