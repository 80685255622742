import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {UserService} from '../../_services/user.service';
import {AuthService} from '../../_services/auth.service';
import {MediaService} from '../../_services/media.service';
declare let swal: any;
declare let toastr: any;

@Component({
    selector: 'page-account',
    templateUrl: './account.component.html'
})
export class AccountComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Template = 1;

    currentUser = null;
    constructor(private userService:UserService,
                private mediaService : MediaService,
                private authService:AuthService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.userInfo = {
            email: this.currentUser.email,
            name: this.currentUser.name,
            avatar: this.currentUser.avatar,
            settings : this.currentUser.settings,
            profile: this.currentUser.profile,
            user_type: this.currentUser.user_type,
            company_name: this.currentUser.company_name,
        };

        let routes = this.CustomHelper.getRouteStr();
        if(routes[2] != undefined){
            if(routes[2] == 'info'){
                this.Template = 1;
            } else if(routes[2] == 'billing'){
                this.Template = 2;
            } else if(routes[2] == 'cards'){
                this.Template = 3;
            } else if(routes[2] == 'users'){
                this.Template = 4;
            }
        }
        if(localStorage.subPage !== undefined){
            if(localStorage.subPage == 'users'){
                this.Template = 4;
            }
            delete localStorage.subPage;
        }
    }
    userInfo = {
        email: '',
        name: '',
        avatar: '',
        settings : null,
        profile: null,
        user_type: '',
        company_name: null,
    };

    removeThisUser(user){
        let THIS = this;
        let alertMsg = "Are you sure you want to delete this user(s)? This user(s) will be unassigned from the work order(s) they are assigned if you proceed.";
        if(user.id > 0){
            let _user = user;
            if(_user.woAssign > 0){
                alertMsg = "This user profile is assigned to "+_user.woAssign+" work order(s). This profile will be unassigned from the work order(s) if you proceed."
            }
        }
        swal({
            title: "Delete User(s)",
            text: alertMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let ids = [user.id];
                this.userService.removePersonnel({ids : ids}).subscribe(res => {
                    if(res.status == 2000){
                        swal({
                            title: "Success",
                            text: res.msg,
                            icon: "success",
                            dangerMode: false,
                        }).then((willDelete) => {
                            swal.stopLoading();
                            swal.close();
                            THIS.Template = 1;
                        });
                    }
                });
            } else {
                swal.stopLoading();
                swal.close();
            }
        });

    }


}
