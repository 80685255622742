import {Component, EventEmitter, Output, TemplateRef, SimpleChanges} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ContactsService} from '../../../_services/contacts.service';
import {InvoicesService} from '../../../_services/invoices.service';
import { CustomHelper } from '../../../customhelper';
import { APPCONFIG } from '../../../config';
import { FacilityService } from '../../../_services/facility.service';
import { AuthService } from '../../../_services/auth.service';
import {FcmService} from '../../../_services/fcm.service';
declare let toastr: any;
declare let swal: any;
declare let Ladda: any;
declare let Inputmask: any;

@Component({
    selector: 'create-recurring-invoice',
    templateUrl: './createRecurring.component.html'
})
export class CreateRecurringComponent {
    @Output() discard = new EventEmitter<number>();
    @Output() emailIt = new EventEmitter<number>();
    CustomHelper = CustomHelper;
    formValid = 0;
    APPCONFIG = APPCONFIG;
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};

    toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{'color': []}, {'background': []}],
        [{'font': []}],
        [{'align': []}],
        ['clean']
    ];
    errorAlert = '';

    currentUser;
    constructor(private contactsService: ContactsService,
                public snackBar: MatSnackBar,
                private facilityService: FacilityService,
                private authService: AuthService,
                private fcmService: FcmService,
                private invoicesService: InvoicesService) {
        this.currentUser = JSON.parse(localStorage.currentUserInfo);
        this.getPersonalInfo();
        this.getAllContact();
        this.getAllFacility();
        this.checkCurrency();

        let THIS = this;
        this._startDateConfig.onChange = function (selectedDates, dateStr, instance) {
            THIS._expireDatepreview = 0;
            THIS._expireDateConfig.minDate = new Date(selectedDates);
            setTimeout(function () {
                THIS._expireDatepreview = 1;
            }, 400);
            THIS._startDate = THIS.CustomHelper.formatDateOnlyToDB(new Date(selectedDates));
            THIS.staticDay = THIS.CustomHelper.day[THIS._expireDateConfig.minDate.getDay()];
            THIS.repeatDays.length = 0;
            THIS.repeatDays = [THIS.staticDay];
        };
        this._startTimeConfig.onChange = function (selectedDates, dateStr, instance) {
            THIS._startTime = dateStr;
        };
        this._startTimeConfig.onOpen = function (selectedDates, dateStr, instance) {
            if(dateStr == ''){
                THIS._startTime = '12:00 PM';
            }
        };
        this._expireDateConfig.onChange = function (selectedDates, dateStr, instance) {
            THIS._expireDate = THIS.CustomHelper.formatDateOnlyToDB(new Date(selectedDates));
        };
        this._expireTimeConfig.onChange = function (selectedDates, dateStr, instance) {
            THIS._expireTime = dateStr;
        };
        this._expireTimeConfig.onOpen = function (selectedDates, dateStr, instance) {
            if(dateStr == ''){
                THIS._expireTime = '12:00 PM';
            }
        };

        this._invDateConfig.onChange = function(selectedDates, dateStr, instance){
            THIS.param.invoice_date = THIS.CustomHelper.formatDateToDB(selectedDates);
        };

        setTimeout(function () {
            (<any>$('.summernote')).summernote({
                maxHeight : '900px',
                minHeight : '150px',
                toolbar: [
                    ['style', ['bold', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    // ['insert', ['link','video']],
                    ['misc', ['fullscreen']]
                ]
            });
        }, 100);
    }


    getPersonalInfo(){
        let THIS = this;
        this.authService.getPersonalInfo().subscribe(res => {
            if(res.data.terms != null){
                if(res.data.terms.inv != undefined){
                    // this.param.customer_notes = res.data.terms.inv.customer_notes;
                    // this.param.terms_condition = res.data.terms.inv.terms_condition;
                    // (<any>$('#customer_note')).summernote('code', this.param.customer_notes);
                    // (<any>$('#terms_condition')).summernote('code', this.param.terms_condition);
                }
            }
        });
    }

    Facility = [];
    FacilityComp = [];
    getAllFacility() {
        let THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            this.FacilityComp.length = 0;
            this.FacilityComp = res.facility;
            $.each(res.facility, function(i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                $.each(v.child, function(j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                    $.each(vC.child, function(k, vCC) {
                        vCC['pp'] = 2;
                        THIS.Facility.push(vCC);
                        $.each(vCC.child, function(l, vCCC) {
                            vCCC['pp'] = 3;
                            THIS.Facility.push(vCCC);
                        });
                    });
                });
            });
            setTimeout(function () {
                if ($('.reRenderFacility').length > 0) {
                  let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
                  if (facilityId > 0) {
                    THIS.param.facility_id = facilityId.toString();
                  }
                }
                (<any>$('.chosen-default-single-fac')).chosen('destroy');
                (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                (<any>$('.chosen-default-single-fac')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Facility'
                }).on('change', function(e, p){
                  if (e.target.value === '000') {
                    (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                    (<any>$('.chosen-default-single-fac')).trigger("chosen:updated");
                    let ___selectedTab = 0;
                    let tabs = $('#sort-tabs').find('li');
                    tabs.each(function (i, v) {
                      if ($(v).find('a.active').length > 0) {
                        ___selectedTab = i;
                      }
                    });
                    let child = ___selectedTab + 1;
                    (<any>$('.owl-carousel')).owlCarousel('destroy');
                    $('#sort-tabs').find('li').find('a').removeClass('active');
                    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                    (<any>$('#createFacilityModal')).modal('show');
                  } else {
                    THIS.param.facility_id = e.target.value;
                    THIS.updateChangesDiscard();
                  }
                });
            }, 100);
        });
    }
  updateChangesDiscard() {
    let THIS = this;
    let index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      this.CustomHelper.ConfRoute[index].change = 1;
    } else {
      this.CustomHelper.ConfRoute.push({tab: this.CustomHelper.ConfCurRoute, change: 1});
    }
  }

  contact = [];
    getAllContact(){
        let THIS = this;
        this.contactsService.getAll().subscribe(res => {
            this.contact = res;
            setTimeout(function () {
                (<any>$('.chosen-default-single-contact')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Contact'
                }).on('change', function(e,p){
                    THIS.param.contact_id = e.target.value;
                });
            }, 100);
            setTimeout(function () {
                (<any>$('.chosen-default-single-terms')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Invoice Terms'
                }).on('change', function(e,p){
                    THIS.param.terms = e.target.value;
                });
            }, 100);
        });
    }

    createCartItemsSubTotal = 0;
    createCartItemsTax = {
      val: 10,
      type: 'exclusive'
    };
    createCartItemsTotal = 0;
    createCartItems = [
        {
            item_name : '',
            item_qty : 1,
            item_price : 1,
            item_tax : 'Tax Included',
            item_total : 1
        }
    ];
    addNewItemInCart(): void {
        let newCart = {
            item_name : '',
            item_qty : 1,
            item_price : 1,
            item_tax : 'Tax Included',
            item_total : 1,
        };
        this.createCartItems.push(newCart);
        this.checkCurrency();
    }
    removeFromCart(index): void{
        if(this.createCartItems.length > 1){
            this.createCartItems.splice(index, 1);
        }
    }
    copyThisInCart(cart): void{
        let newCart = {
            item_name : cart.item_name,
            item_qty : cart.item_qty,
            item_price : cart.item_price,
            item_tax : cart.item_tax,
            item_total : cart.item_total,
        };
        this.createCartItems.push(newCart);
      this.checkCurrency();
    }
    updateLineName(e,index:number): void{
        let trigger = $(e.target);
        let value = trigger.val();
        let name = value.toString();
        this.createCartItems[index].item_name = name;
    }
    updateLineQty(e,index:number): void{
        let trigger = $(e.target);
        let value = trigger.val();
        let Qty = Number(value);
        if(Qty < 0){
          this.createCartItems[index].item_qty = 0;
          $('.val0').val(0);
          return;
        }
        this.createCartItems[index].item_qty = Qty;
        let lineTotal = this.createCartItems[index].item_qty * this.createCartItems[index].item_price;
        this.createCartItems[index].item_total = lineTotal;
        this.changeInvoiceTotal();
    }
    updateLinePrice(e,index:number): void{
        let trigger = $(e.target);
        let inputValue = this.CustomHelper.makeStr(trigger.val());
        let value = inputValue.replace(/,/, '');
        value = value.substring(this.currencyIcon().length);
        let price = Number(value);
        this.createCartItems[index].item_price = price;
        let lineTotal = this.createCartItems[index].item_qty * this.createCartItems[index].item_price;
        this.createCartItems[index].item_total = lineTotal;
        this.changeInvoiceTotal();
    }
  changeInvoiceTax(tax: number, type: string): void {
    this.createCartItemsTax.val = tax;
    this.createCartItemsTax.type = type;
    this.changeInvoiceTotal();
    if (tax == 10 && type == 'exlusive') {
      this.param.tax_type = 1;
    }
    if (tax == 10 && type == 'inlusive') {
      this.param.tax_type = 2;
    }
    if (tax == 5 && type == 'exlusive') {
      this.param.tax_type = 3;
    }
    if (tax == 5 && type == 'inclusive') {
      this.param.tax_type = 4;
    }
    this.param.tax_value = tax;
  }
    changeInvoiceTotal(): void{
        let subTotal = 0;
        $.each(this.createCartItems, function (i, v) {
            subTotal = subTotal+v.item_total;
        });
        this.createCartItemsSubTotal = subTotal;
        this.createCartItemsTotal = this.createCartItemsSubTotal + (this.createCartItemsSubTotal * (this.createCartItemsTax.val / 100));
    }
    discardNewInvoice() {
        this.discard.emit(1);
    }


    facilities = [];
    param = {
        work_id: null,
        facility_id: '',
        contact_id: '',
        invoice_date : '',
        order_number : '',
        terms : '1',
        tax_type : 1,
        tax_value : 10,
        customer_notes : '',
        customer_note_on : 1,
        terms_condition : '',
        term_on : 1,
        items : '',
        repeat : '',
        repeat_days : '',
        start : '',
        expire : '',
    };
    emailThisInvoice(){
        this.emailIt.emit(1);
    }
    createThisInvoice(){
        if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
            let freeAccountData = localStorage.getItem('freeAccountData');
            if (freeAccountData != null) {
                let freeAccount = JSON.parse(freeAccountData);
                if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
                    (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
                    (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
                    (freeAccount['user_count'] >= freeAccount['user_limit'])) {
                    $('.openSubscription').click();
                    return false;
                }
            }
        }
        let ldd = document.getElementById('SaveThisLadda');
        let ladda = Ladda.create(ldd);
        let valid = this.validationThisForm();
        if(valid == 1){
            let THIS = this;
            ladda.start();
            // this.param.customer_notes = (<any>$('#customer_note')).summernote('code');
            // this.param.terms_condition = (<any>$('#terms_condition')).summernote('code');

            this.param.repeat = this.repeatCount+' '+this.repeatItem;
            this.param.repeat_days = this.repeatDays.join();
            this.param.items = JSON.stringify(this.createCartItems);
            // this.param.invoice_date = CustomHelper.formatDateToDB(this.invoice_date[0] == undefined ? this.invoice_date : this.invoice_date[0]);
            this.invoicesService.createRecurringInvoice(this.param).subscribe(res => {
                ladda.stop();
                if(res.status == 2000){
                    this.discardNewInvoice();
                    toastr.success('New Recurring invoice successfully created', 'Recurring Invoice Create', this.toastrOption);
                    // Pusher emit
                    $.ajax({
                        url: THIS.APPCONFIG.apiAuth+'invoice-recurring-cron',
                        type: 'get',
                        data: {},
                        success: function (res) {}
                    });
                } else {
                    swal({
                        title: "Something is wrong! Pleaes try again.",
                        className: 'swal-btn-center'
                    });
                }
            });
        }
    }
    clearValidation(e){
        let trigger = $(e.target);
        let target = trigger.closest('.form-group');
        target.removeClass('has-error');
    }
    validationThisForm(){
        let valid = 1;
        if(this.param.facility_id == ''){
            valid = 0;
          $('.show-facility-error').html('Please select a facility');
            return valid;
        }
        else{
          $('.show-facility-error').html('');
        }
        if(this.param.contact_id == ''){
            valid = 0;
          $('.show-contact-error').html('Please select a facility');
            return valid;
        }
        else{
          $('.show-contact-error').html('');
        }
        if(this.param.order_number == ''){
            valid = 0;
          $('.show-order-error').html('Order number field is required');
            return valid;
        }else{

          $('.show-order-error').html('');
        }
        /*if(this.param.invoice_date == ''){
            valid = 0;
            swal({
                title: "Please select an invoice date",
                className: 'swal-btn-center'
            });
            return valid;
        }*/
        if(this._startDate == ''){
            valid = 0;
            this.errorAlert = 'start-date';
            this.OpenPreviewAlert();
            return valid;
        }
        if(this._startTime == ''){
            valid = 0;
            this.errorAlert = 'start-time';
            this.OpenPreviewAlert();
            return valid;
        }
        if(this.repeatItem == ''){
            valid = 0;
            this.errorAlert = 'recurring-period';
            this.OpenPreviewAlert();
            return valid;
        }
        if(this._expireDate != ''){
            if(this._expireTime == ''){
                valid = 0;
                this.errorAlert = 'expire-time';
                this.OpenPreviewAlert();
                return valid;
            }
        }
        let __start_New = new Date(this._startDate + ' ' + this._startTime);
        this.param.start = this.CustomHelper.formatDateTimeToDB(__start_New);
        if(this._expireDate != '' && this._expireTime != ''){
            let __expire_New = new Date(this._expireDate + ' ' + this._expireTime);
            this.param.expire = this.CustomHelper.formatDateTimeToDB(__expire_New);
            if (Date.parse(this.param.start) > Date.parse(this.param.expire)) {
                this.errorAlert = 'compare-time';
                this.OpenPreviewAlert();
                valid = 0;
            }
        }

        this.formValid = valid;
        return valid;
    }
    OpenPreviewAlert(){
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#PreviewAlert')).modal('show');
    }
    ClosePreviewAlert(){
      (<any>$('#PreviewAlert')).modal('hide');
    }
    repeatValue = '1 Day';
    repeatCount = 1;
    repeatItem = '';
    staticDay = '';
    repeatDays = [];
    updateRepeatDays(day){
        if(day != this.staticDay){
            let index = this.repeatDays.indexOf(day);
            if(index > -1){
                this.repeatDays.splice(index, 1);
            } else {
                this.repeatDays.push(day);
            }
        }
    }
    echoRepeatDays(repeatDays){
        let days = '';
        $.each(repeatDays, function (i, v) {
            if(i == '0'){
                days += v;
            } else {
                days += ', '+v;
            }
        });
        days = days.replace(/,([^,]*)$/, ' and $1');
        return days;
    }
    _startDate = '';
    _startTime = '';
    _expireDate = '';
    _expireTime = '';
    _expireDatepreview = 1;
    _startDateConfig = {
        timePicker: false,
        minDate: new Date(),
        dateFormat: this.CustomHelper.dateFormatFlat(),
        onChange: function(selectedDates, dateStr, instance) {}
    };
    _startTimeConfig = {
        enableTime: true,
        noCalendar: true,
        dateFormat: this.CustomHelper.timeFormatFlat('format'),//"H:i k",
        time_24hr: this.CustomHelper.timeFormatFlat(''),
        onChange: function(selectedDates, dateStr, instance) {},
        onOpen: function(selectedDates, dateStr, instance) {},
    };
    _expireDateConfig = {
        timePicker: false,
        minDate: new Date(),
        defaultDate: null,
        dateFormat: this.CustomHelper.dateFormatFlat(),
        onChange: function(selectedDates, dateStr, instance) {},
    };
    _expireTimeConfig = {
        enableTime: true,
        noCalendar: true,
        defaultDate: null,
        dateFormat: this.CustomHelper.timeFormatFlat('format'),//"H:i k",
        time_24hr: this.CustomHelper.timeFormatFlat(''),
        onChange: function(selectedDates, dateStr, instance) {},
        onOpen: function(selectedDates, dateStr, instance) {},
    };
    expireView = 0;
    noExpire(){
        this._expireDate = '';
        this._expireTime = '';
        this._expireDateConfig.defaultDate = null;
        this._expireTimeConfig.defaultDate = null;
        this.param.expire = '';
        this.expireView = 1;
        let THIS = this;
        setTimeout(function () {
            THIS.expireView = 0;
        },100);
    }

    genEditor(){
        let THIS = this;
        setTimeout(function () {
            (<any>$('.summernote')).summernote({
                maxHeight : '900px',
                minHeight : '150px',
                toolbar: [
                    ['style', ['bold', 'underline', 'clear']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    // ['insert', ['link','video']],
                    ['misc', ['fullscreen']]
                ]
            });
        }, 100);
    }

    _invDateConfig = {
        timePicker: false,
        dateFormat: this.CustomHelper.dateFormatFlat(),
        onChange: function(selectedDates, dateStr, instance) {}
    }


    ngOnInit() {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        /*this.socket.on('_rfr_team_data_fac_'+Session.team, function (v) {
            THIS.getAllFacility();
        });*/
      $('.reRenderFacility').on('click', function () {
        THIS.getAllFacility();
      });
    }
    ngOnDestroy() {
    }

  checkCurrency() {
    Inputmask.extendAliases({
      pesos: {
        prefix: `${this.currencyIcon()}`,
        groupSeparator: "",
        alias: "numeric",
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        radixPoint: '.',
        placeholder: '0.00'
      }
    });

    setTimeout(function () {
      (<any>$("._currency_validate_eq")).inputmask({ alias : "pesos" });
    }, 300);
  }

  currencyIcon():string {
    if(this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }
}
