import {Component, Input, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute, Params} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {Lightbox} from 'angular2-lightbox';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {AssetService} from '../../_services/asset.service';
import {FacilityService} from '../../_services/facility.service';
import {MediaService} from '../../_services/media.service';
import {FcmService} from '../../_services/fcm.service';

declare let google: any;
declare let toastr: any;
declare let swal: any;
declare let Ladda: any;


@Component({
    selector: 'online-assets-single',
    templateUrl: './assetOnline.component.html'
})
export class AssetOnlineComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    @Output() editPreview = new EventEmitter<number>();
    @Output() removePreview = new EventEmitter<number>();
    @Output() listRefresh = new EventEmitter<number>();
    TemplateCheck = 2;
    @Input() preId: any;
    AssetDetailsChange = 0;
    loading = 1;
    Asset: any;
    _albums = [];
    _selected_tab = 1;
    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

    inlinePrefixId = 0;
    param = {
        id: 0,
        asset_id: '',
        prefix: '',
        name: '',
        facility: '',
        category_id: '',
        location: '',
        geo: '',
        brand: '',
        serial_number: '',
        media_files: '',
        attach_files: '',
        purchase_cost: '',
        service_personnel: '',
        phone: '',
        email: '',
        contract_start: '',
        contract_end: '',
        notify: 0,
        notify_duration: '',
        depreciation_cost: '',
        eol: '',
        asset_life: '',
        depreciation_method: '',
    };

    validForm = 1;
    Template = 2;

    saveWorkOrderSubmit() {
        let THIS = this;
        let mediaFiles = '';
        $.each(THIS.selectedFilesPreview, function (i, v) {
            if (i == 0) {
                mediaFiles += v.id;
            } else {
                mediaFiles += ',' + v.id;
            }
        });
        let mediaAttach = '';
        $.each(THIS.selectedAssetAttach, function (i, v) {
            if (i == 0) {
                mediaAttach += v.id;
            } else {
                mediaAttach += ',' + v.id;
            }
        });
        this.param.media_files = mediaFiles;
        this.param.attach_files = mediaAttach;

        let valid = this.validationThisForm();
        if (valid == 1) {
            let THIS = this;
            this.assetService.editAsset(this.param).subscribe(res => {
                if (res.status == 7000) {
                    this.coolDialogs.alert("The Asset ID is already been taken. Please use a unique one", {
                        title: 'Form Validation',
                        color: '#e0e0e0',
                    });
                } else {
                    this.preview.emit(this.preId);
                    toastr.success('Asset has been successfully updated', 'Update Asset', this.toastrOption);
                    // Pusher Emit
                }
            });
        }
    }

    validationThisForm() {
        let valid = 1;
        let form = $('#workCreateForm');
        if (this.param.facility == '') {
            valid = 0;
        }
        /*if (this.param.prefix == '') {
            valid = 0;
        }
        if (this.param.asset_id == '') {
            valid = 0;
        }*/
        if (this.param.name == '') {
            valid = 0;
            form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
        } else {
            form.find('[data-el="subject"]').removeClass('has-error');
        }
        this.validForm = valid;
        return valid;
    }

    clearValidation(e) {
        let trigger = $(e.target);
        let target = trigger.closest('.form-group');
        target.removeClass('has-error');
    }

    statusUpdate(status_code) {
        let ldd = document.getElementById('statusUpdate');
        let ladda = Ladda.create(ldd);
        let THIS = this;
        if (status_code == 0 && THIS.Asset.wo_count > 0) {
            swal({
                title: "Asset Switch Offline Notice",
                text: "This Asset is assigned to " + THIS.Asset.wo_count + " work order. If you change the status to Offline, it will be unassigned from the work order.",
                icon: "warning",
                buttons: ['Cancel', 'Update Status'],
                dangerMode: true,
            }).then((res) => {
                if (res) {
                    let param = {
                        id: THIS.Asset.id,
                        name: THIS.Asset.name,
                        prefix: THIS.Asset.prefix,
                        asset_id: THIS.Asset.asset_id,
                        facility: THIS.Asset.facility,
                        status_code: status_code
                    };
                    ladda.start();
                    THIS.assetService.editAsset(param).subscribe(res => {
                        ladda.stop();
                        THIS.Asset.status_code = status_code;
                        THIS.Asset.wo_count = 0;
                        THIS.listRefresh.emit(1);
                    });
                }
            });
        } else {
            let param = {
                id: THIS.Asset.id,
                name: THIS.Asset.name,
                prefix: THIS.Asset.prefix,
                asset_id: THIS.Asset.asset_id,
                facility: THIS.Asset.facility,
                status_code: status_code
            };
            ladda.start();
            THIS.assetService.editAsset(param).subscribe(res => {
                ladda.stop();
                THIS.Asset.status_code = status_code;
                THIS.listRefresh.emit(1);
            });
        }
    }


    currentUser: any;

    constructor(private assetService: AssetService,
                private mediaService: MediaService,
                private _lightbox: Lightbox,
                private coolDialogs: NgxCoolDialogsService,
                private fcmService: FcmService,
                public snackBar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute,
                private facilityService: FacilityService) {
        let THIS = this;
        this.route.params.forEach((params: Params) => {
            let short_code = params.short_code;
            this.getSingleAsset(short_code)
        });
    }

    Facility = [];
    FacilityComp = [];

    getAllFacility() {
        let THIS = this;
        this.facilityService.getFacilitiesList().subscribe(res => {
            this.Facility.length = 0;
            this.FacilityComp.length = 0;
            this.FacilityComp = res.facility;
            $.each(res.facility, function (i, v) {
                v['pp'] = 0;
                THIS.Facility.push(v);
                $.each(v.child, function (j, vC) {
                    vC['pp'] = 1;
                    THIS.Facility.push(vC);
                    $.each(vC.child, function (k, vCC) {
                        vCC['pp'] = 2;
                        THIS.Facility.push(vCC);
                        $.each(vCC.child, function (l, vCCC) {
                            vCCC['pp'] = 3;
                            THIS.Facility.push(vCCC);
                        });
                    });
                });
            });
            setTimeout(function () {
                (<any>$('.chosen-default-single-fac')).chosen('destroy');
                (<any>$('.chosen-default-single-fac')).val(THIS.param.facility);
                (<any>$('.chosen-default-single-fac')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Facility'
                }).on('change', function (e, p) {
                    THIS.param.facility = e.target.value;
                    THIS.validationThisForm();
                });
            }, 1000);
        });
    }

    Categories = [];
    CategoryDefault = [];
    CategoryCustom = [];

    getAllcategory() {
        let THIS = this;
        this.assetService.getAssetCatList().subscribe(res => {
            this.Categories.length = 0;
            this.CategoryDefault.length = 0;
            this.CategoryCustom.length = 0;
            this.Categories = res;
            let THIS = this;
            $.each(res, function (i, v) {
                if (v.user_id == 0) {
                    THIS.CategoryDefault.push(v);
                } else {
                    THIS.CategoryCustom.push(v);
                }
            });
            setTimeout(function () {
                (<any>$('.chosen-default-single-cat')).chosen('destroy');
                (<any>$('.chosen-default-single-cat')).val(THIS.param.category_id);
                (<any>$('.chosen-default-single-cat')).chosen({
                    allow_single_deselect: true,
                    placeholder_text_single: 'Select Category'
                }).on('change', function (e, p) {
                    THIS.param.category_id = e.target.value;
                });
            }, 100);
        });
    }

    CMap;

    GmapZoomIn(data) {
        this.CMap.setZoom(this.CMap.getZoom() + data);
    }

    geoLocation(place) {
        let THIS = this;
        this.param.geo = JSON.stringify({
            address: place.formatted_address,
            geometry: place.geometry.location
        });
        setTimeout(function () {
            let mapOptions = {zoom: 13, center: place.geometry.location, gestureHandling: 'cooperative', mapTypeControl: false, streetViewControl: false, zoomControl: false};
            THIS.CMap = new google.maps.Map(document.getElementById("geo-map"), mapOptions);
            new google.maps.Marker({
                position: place.geometry.location,
                map: THIS.CMap,
                draggable: true,
                title: place.formatted_address
            });
        }, 100);
    }

    geoLocationMap(e) {
        let THIS = this;
        setTimeout(function () {
            if (THIS.param.geo == '') {
                let ltln = e.target.value.split(',');
                if (ltln.length == 2) {
                    let latlon = new google.maps.LatLng(ltln[0], ltln[1]);
                    THIS.param.geo = JSON.stringify({
                        address: e.target.value,
                        geometry: latlon
                    });
                    setTimeout(function () {
                        let mapOptions = {zoom: 13, center: latlon, gestureHandling: 'cooperative', mapTypeControl: false, streetViewControl: false, zoomControl: false};
                        THIS.CMap = new google.maps.Map(document.getElementById("geo-map"), mapOptions);
                        new google.maps.Marker({
                            position: latlon,
                            map: THIS.CMap,
                            draggable: false
                        });
                    }, 100);
                }
            }
        }, 100);
    }

    workOrders = [];

    getWorkOrder() {
        let THIS = this;
        let param = {asset_id: this.param.id};
        this.assetService.getWorkOrder(param).subscribe(res => {
            if (res.status == 2000) {
                this.workOrders = res.data;
            }
        });
    }

    // selectedFiles = [];
    selectedFilesPreview = [];

    removeThisMedia(id, media_type) {
        let param = {
            id: id, media_type: media_type
        };
        this.mediaService.remove(param).subscribe(res => {
        });
    }

    removeThisImage(index, id) {
        // this.selectedFiles.splice(index, 1);
        this.selectedFilesPreview.splice(index, 1);
        this.removeThisMedia(id, 1);
    }

    _uploadError = 0;
    _locPermit = 0;

    selectedAssetAttach = [];

    confirmDiscard() {
        this.discard.emit(1);
    }

    getSingleAsset(preId) {
        let THIS = this;
        let param = {uid: preId};
        this.viewDates = 0;
        this.loading = 1;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth + 'getAssetSingle',
            type: 'post',
            data: param,
            success: function (res) {
                if (res.status == 2000) {
                    let asset = res.data;
                    asset.facStr = asset.facStr.reverse();
                    THIS.Asset = asset;
                    THIS._albums.length = 0;
                    if (THIS.Asset.media !== undefined) {
                        for (let i = 0; i < THIS.Asset.media.length; i++) {
                            const src = APPCONFIG.uploadPreview + '/images?w=800&img=' + THIS.Asset.media[i].file_path;
                            const caption = '';
                            const thumb = '';
                            const album = {
                                src: src,
                                caption: caption,
                                thumb: thumb,
                                w: THIS.Asset.media[i].width !== undefined ? THIS.Asset.media[i].width : 1500,
                                h: THIS.Asset.media[i].height !== undefined ? THIS.Asset.media[i].height : 1500
                            };
                            THIS._albums.push(album);
                        }
                    }
                    THIS.selectedFilesPreview = THIS.Asset.media;
                    THIS.selectedAssetAttach = THIS.Asset.attach;
                    // this.selectedFiles = this.Asset.media.length;

                    THIS.param.id = asset.id;
                    THIS.param.asset_id = asset.asset_id;
                    THIS.param.prefix = asset.prefix;
                    THIS.param.name = asset.name;
                    THIS.param.facility = asset.facility;
                    THIS.param.category_id = asset.category_id != null ? THIS.CustomHelper.makeStr(asset.category_id) : '';
                    THIS.param.location = asset.location;
                    THIS.param.brand = asset.brand;
                    THIS.param.serial_number = asset.serial_number;
                    THIS.param.purchase_cost = asset.purchase_cost;
                    THIS.param.service_personnel = asset.service_personnel;
                    THIS.param.phone = asset.phone;
                    THIS.param.email = asset.email;
                    THIS.param.contract_start = asset.contract_start;
                    THIS.param.contract_end = asset.contract_end;
                    THIS.param.notify = asset.notify;
                    THIS.param.notify_duration = asset.notify_duration;
                    THIS.param.depreciation_cost = asset.depreciation_cost;
                    THIS.param.eol = asset.end_of_life_value;
                    THIS.param.asset_life = asset.life;
                    THIS.param.depreciation_method = asset.depreciation_method;

                    THIS.viewDates = 1;

                    if (THIS.Asset.contract_start != null && THIS.Asset.contract_end != null) {
                        THIS.contractRange = (THIS.Asset.contractTotalGone.d / THIS.Asset.contractTotalDay.d) * 100;
                    }
                    THIS.AssetDetailsChange = 1;
                    THIS.param.geo = asset.geo;
                    if (THIS.param.geo != null && THIS.param.geo != '' && THIS.Template == 2) {
                        let place = JSON.parse(THIS.param.geo);
                        setTimeout(function () {
                            let mapOptions = {zoom: 13, center: place.geometry, gestureHandling: 'cooperative', mapTypeControl: false, streetViewControl: false, zoomControl: false};
                            THIS.CMap = new google.maps.Map(document.getElementById("geo-map-single"), mapOptions);
                            new google.maps.Marker({
                                position: place.geometry,
                                map: THIS.CMap,
                                draggable: true,
                                title: place.address
                            });
                        }, 1000);
                    }
                    if (THIS.param.geo != null && THIS.param.geo != '' && THIS.Template == 3) {
                        let place = JSON.parse(THIS.param.geo);
                        setTimeout(function () {
                            let mapOptions = {zoom: 13, center: place.geometry, gestureHandling: 'cooperative', mapTypeControl: false, streetViewControl: false, zoomControl: false};
                            THIS.CMap = new google.maps.Map(document.getElementById("geo-map"), mapOptions);
                            new google.maps.Marker({
                                position: place.geometry,
                                map: THIS.CMap,
                                draggable: true,
                                title: place.address
                            });
                            (<any>document.getElementById("geo-map-input")).value = place.address;
                        }, 100);
                    }
                } else {
                    THIS.AssetDetailsChange = 0;
                    THIS.loading = 0;
                }
            }
        });
    }

    // ===================
    // View Actions
    // ===================
    editThisAsset() {
        this.editPreview.emit(this.preId);
    }

    removeThisAsset() {
        this.removePreview.emit(this.Asset.id);
    }

    advance_option = 1;
    viewDates = 0;
    today = new Date();
    contractRange = 0;
    data = {
        labels: ['0', '12', '24', '36', '48', '60', '72', '84', '96', '108', '120'],
        datasets: [
            {
                label: 'Months',
                data: [12000, 10800, 9600, 8400, 7200, 6000, 4800, 3600, 2400, 1200, 0],
                fill: false,
                pointBackgroundColor: '#31c1bb',
                pointBorderColor: '#ffffff',
                pointBorderWidth: 3,
                pointRadius: 6,
                borderColor: '#fe9e6b',
                borderWidth: 3,
            },
        ],
    };

    rightSecPreview = 0;
    
    currencyIcon():string {    
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
    }
}
