import {Component} from '@angular/core';
import {AssetService} from '../../../_services/asset.service';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
declare let toastr: any;
declare let swal: any;

@Component({
    selector: 'assets-settings',
    templateUrl: './assets-settings.component.html'
})
export class AssetsSettingsComponent {
    toastrOption = {timeOut: 5000,progressBar: true,showDuration: 400,"closeButton": true,preventDuplicates: true};
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;

    currentUser;
    constructor(protected assetService: AssetService) {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.getAssetCat();
        this.sortStatusOptions.onUpdate = function () {
            THIS.updateCatSort();
        };
    }


    //============================
    // Asset Category
    //============================
    categoryAssetLoading: boolean;
    createAC = 0;
    assetsCat = [];
    getAssetCat() {
        this.categoryAssetLoading = true;
        this.assetService.getAssetCategory().subscribe(res => {
            this.categoryAssetLoading = false;
            let rv = [];
            for(let i=0;i<res.length;i++){
                res[i]['edit_status'] = 0;
                rv.push(res[i]);
            }
            this.assetsCat = rv;
        });
    }
    createAssetCategory = {
        name: '',
        submitted: 0
    };
    createAssetCat() {
        let THIS = this;
        if (this.createAssetCategory.name != '') {
            this.createAssetCategory.submitted = 1;
            let param = {name: this.createAssetCategory.name};
            this.assetService.createAssetCategory(param).subscribe(res => {
                this.createAssetCategory.name = '';
                this.createAssetCategory.submitted = 0;
                this.getAssetCat();
                // Pusher emit
            });
        }
    }
    editAssetCategory = {
        id: 0,
        name: '',
        submitted: 0
    };
    editAssetCat() {
        let THIS = this;
        if (this.editAssetCategory.name != '') {
            this.editAssetCategory.submitted = 1;
            let param = {id: this.editAssetCategory.id,name: this.editAssetCategory.name};
            this.assetService.editAssetCategory(param).subscribe(res => {
                this.editAssetCategory.submitted = 0;
                this.getAssetCat();
                // Pusher emit
            });
        }
    }
    openEditAssetCat(cat) {
        this.editAssetCategory.id = cat.id;
        this.editAssetCategory.name = cat.name;
        let res = this.assetsCat;
        let rv = [];
        for(let i=0;i<res.length;i++){
            res[i]['edit_status'] = 0;
            if(res[i].id == cat.id){
                res[i]['edit_status'] = 1;
            }
            rv.push(res[i]);
        }
        this.assetsCat = rv;
    }
    removeStatus = 0;
    removeJobStatus(){
        let THIS = this;
        if(this.removeStatus > 0){
            let ids = [this.removeStatus];
            swal({
                title: "Delete Job Status",
                text: "Are you sure you want to delete this job status?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    $.ajax({
                        url: THIS.APPCONFIG.api+'assets/category/remove?token='+THIS.currentUser.token,
                        data: {ids:ids},
                        type: 'post',
                        success : function (res) {
                            THIS.getAssetCat();
                            // Pusher emit
                        }
                    });
                }
            });
        }
    }
    sortingStatusUpdating = 0;
    sortStatusOptions = {
        onUpdate: function () {}
    };
    updateCatSort(){
        let THIS = this;
        this.sortingStatusUpdating = 1;
        let items = $('.sortJobStatus');
        let sortings = [];
        items.each(function (i, v) {
            let id = $(v).attr('id');
            id = id.replace('sortJobStatus-','');
            let eachSort = {
                id: id,
                sort_id : i+1
            };
            sortings.push(eachSort);
        });
        sortings.length = sortings.length - 1;

        $.ajax({
            url: THIS.APPCONFIG.api+'assets/category/sort?token='+THIS.currentUser.token,
            data: {sort : sortings},
            type: 'post',
            success : function (res) {
                toastr.success('Asset category has been successfully sorted', 'Asset Category Sorting', THIS.toastrOption);
                setTimeout(function () {
                    THIS.sortingStatusUpdating = 0;
                }, 500);
                THIS.getAssetCat();
                // Pusher emit
            }
        });
    }

    ngOnInit() {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        /*this.socket.on('_rfr_team_data_settingAsset_'+Session.team, function (v) {
            THIS.getAssetCat();
        });*/
    }
    ngOnDestroy() {
    }

}
