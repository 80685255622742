import {Component, Output, EventEmitter} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {AuthService} from '../../../_services/auth.service';
import {TabsService} from '../../../_services/tabs.service';

declare let swal: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
    selector: 'page-acc-info',
    templateUrl: './acc.component.html'
})
export class AccComponent {
    @Output() invoiceList = new EventEmitter<number>();
    @Output() cards = new EventEmitter<number>();

    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;


    ROUTES = '/account/info';
    currentUser = null;

    constructor(private authService: AuthService, private tabsService: TabsService) {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.currentUser = Session;
        this.plan = this.currentUser.subscription.plan;
        this.cycle = this.currentUser.subscription.cycle;
        this.employee = this.currentUser.subscription.employee;
        let url = '/' + Session.team + this.ROUTES;
        window.history.pushState('', '', url);

        this.getSessionUserInfo();
        this.getCustomerInfo();
        this.getReport();
        this.getSubscriptionPlans();
        this.freeAccountModal();
        $('body').off().on('click', '#refreshCards', function () {
            THIS.getSessionUserInfo();
            THIS.getReport();
            THIS.getSubscriptionPlans();
        });
        if (localStorage.resetSubscription !== undefined) {
            delete localStorage.resetSubscription;
            this.resetSubscriptionInfo();
        }
    }

    userInfo = null;

    getSessionUserInfo() {
        this.authService.getSessionUserInfo().subscribe(res => {
            if (res.status == 2000) {
                this.userInfo = res.data;
                this.currentUser = res.data;
            }
        });
    }

    StripeCurrency = null;

    getCustomerInfo() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/getCustomerInfo?token=' + THIS.currentUser.token,
            type: 'get',
            success: function (res) {
                if (res.status == 2000) {
                    if (res.data.length > 0) {
                        THIS.StripeCurrency = res.data;
                    }
                }
            }
        });
    }


    Report = null;

    getReport() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/getAccountSubscription?token=' + THIS.currentUser.token,
            type: 'get',
            success: function (res) {
                THIS.getSessionUserInfo();
                THIS.getSubscriptionPlans();
                if (res.status == 2000) {
                    THIS.Report = res.data;
                    if (THIS.Report.Subscription != null) {
                        THIS.Report.Subscription.current_period_end = THIS.CustomHelper.dateDynamicFormat(THIS.Report.Subscription.current_period_end, '');
                    }
                }
                THIS.getStorage();
            }
        });
    }

    Storage = null;

    getStorage() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiMedia + 'media/storage?token=' + THIS.currentUser.token,
            type: 'get',
            success: function (res) {
                if (res.status == 2000) {
                    THIS.Storage = res.storage;
                }
            }
        });
    }

    Prorate = null;

    getProrate() {
        let THIS = this;
        THIS.Prorate = null;
        let param = {
            plan: THIS.plan,
            cycle: THIS.cycle,
            employee: THIS.employee
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/getChangeProrate?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                console.log(res);
                THIS.Prorate = res.data;
                THIS.Prorate.CMFD = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.CMFD, '');
                THIS.Prorate.CMFDNY = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.CMFDNY, '');
                THIS.Prorate.CMFDNYNI = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.CMFDNYNI, '');
                THIS.Prorate.TD = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.TD, '');
                setTimeout(function () {
                    THIS.CustomHelper.tooltipRender();
                }, 100);
            }
        });
    }


    cancelSubscription() {
        let THIS = this;
        swal({
            title: 'Cancel Subscription',
            text: 'Are you sure you want to cancel your subscription?',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                $.ajax({
                    url: THIS.APPCONFIG.api + 'subscription/cancelSubscriptionReq?token=' + THIS.currentUser.token,
                    type: 'get',
                    success: function (res) {
                        THIS.getSessionUserInfo();
                        THIS.getReport();

                        THIS.getSubscriptionPlans();
                    }
                });
            }
        });
    }


    EditSubscription = 0;
    SubscriptionStep = 2;
    plan = 'Pro';
    planSelected = null;
    cycle = 'year';
    employee = 1;

    employeeUpdate() {
        if (this.employee < 1) {
            this.employee = 1;
        }
    }

    Plans = [];
    freePlan = null;

    getSubscriptionPlans() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/getPlans?token=' + THIS.currentUser.token,
            type: 'get',
            success: function (res) {
                THIS.Plans = [];
                res.data.forEach(function (v) {
                    if (v.name != 'Standard') {
                        THIS.Plans.push(v);
                    }
                });
                THIS.freePlan = res.freePlan;
            }
        });
    }

    validateStripecard() {
        let THIS = this;
        let ldd = document.getElementById('Subscribed');
        let ladda = Ladda.create(ldd);
        let param = {
            plan: THIS.plan,
            cycle: THIS.cycle,
            employee: THIS.employee,
        };
        ladda.start();
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/UpdateSubscription?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                ladda.stop();
                if (res.status == 2000) {
                    THIS.SubscriptionStep = 5;
                }
            }
        });
    }

    resetSubscriptionInfo() {
        let THIS = this;
        if (THIS.currentUser.subscription.status == 99) {
            swal({
                title: 'Cancelled Subscription',
                text: 'Your subscription is cancelled. Do you want to reactivate it again?',
                icon: 'warning',
                buttons: ['Cancel', 'Reactivate Current Subscription'],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    $.ajax({
                        url: THIS.APPCONFIG.api + 'subscription/reactivateSubscription?token=' + THIS.currentUser.token,
                        type: 'get',
                        success: function (res) {
                            THIS.getSessionUserInfo();
                            THIS.getReport();
                            if (res.status == 2000) {
                                swal({
                                    title: 'Reactivate Subscription',
                                    text: 'Your subscription has been reactivated successfully',
                                    icon: 'success',
                                    buttons: true,
                                    className: 'swal-btn-center'
                                });
                            }
                        }
                    });
                }
            });
        } else {
            if (this.StripeCurrency == null || (this.StripeCurrency.currency.toLowerCase() == this.StripeCurrency.uCurrency.toLowerCase())) {
                let ___selectedTab = 0;
                let tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) {
                    if ($(v).find('a.active').length > 0) {
                        ___selectedTab = i;
                    }
                });
                let child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');

                this.EditSubscription = 1;
                this.SubscriptionStep = 2;
                this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
                this.plan = this.currentUser.subscription.plan;
                this.cycle = this.currentUser.subscription.cycle;
                this.employee = this.currentUser.subscription.employee;
            } else {
                swal({
                    title: 'Subscription Currency',
                    text: 'It looks like you are currently subscribed to a plan in AUD. If you need to change your plan, please email us at support@equidesk.com',
                    icon: 'warning',
                    className: 'swal-btn-center'
                });
                setTimeout(function () {
                    $('.swal-text').html('It looks like you are currently subscribed to a plan in AUD. <br> If you need to change your plan, <br> please email us at <a class="_theme-color" href="mailto:support@equidesk.com">support@equidesk.com</a>');
                }, 500);
            }
        }
    }

    TabSlide;
    tabs = [];
    selectedTab = 0;
    mainTab: any;

    modalHideEffect() {
        let THIS = this;
        let selectedTab = 0;
        let tabs = (<any>$('#sort-tabs').find('li'));
        tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
                selectedTab = i;
            }
        });
        THIS.TabSlide = (<any>$('.owl-carousel')).owlCarousel({
            loop: false,
            nav: false,
            dots: false,
            mouseDrag: false,
            touchDrag: false,
            items: 1,
            animateIn: 'fadeIn faster',
            autoplay: false
        }).on('changed.owl.carousel', function (event) {
            THIS.selectedTab = event.item.index;
            THIS.tabsService.setSelectedTab(THIS.selectedTab);
        });
        (<any>$('.owl-carousel')).trigger('to.owl.carousel', selectedTab);
        THIS.tabsService.setSelectedTab(selectedTab);
    }

    goToInvoiceList() {
        this.invoiceList.emit(1);
    }

    goToCards() {
        this.cards.emit(1);
    }


    buySubscription() {
        if (this.StripeCurrency == null) {
            (<any>$('#buySubscription')).click();
        } else if (this.StripeCurrency.currency && (this.StripeCurrency.currency.toLowerCase() == this.StripeCurrency.uCurrency.toLowerCase())) {
            (<any>$('#buySubscription')).click();
        } else {
            swal({
                title: 'Subscription Currency',
                text: 'It looks like you are currently subscribed to a plan in AUD. If you need to change your plan, please email us at support@equidesk.com',
                icon: 'warning',
                className: 'swal-btn-center'
            });
            setTimeout(function () {
                $('.swal-text').html('It looks like you are currently subscribed to a plan in AUD. <br> If you need to change your plan, <br> please email us at <a class="_theme-color" href="mailto:support@equidesk.com">support@equidesk.com</a>');
            }, 500);
        }
    }


    SubscriptionFree = false;

    freeAccount() {
        let THIS = this;
        let param = {
            plan: 1,
        };
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/freeAccountSubscription?token=' + THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function (res) {
                if (res.status == 2000) {
                    THIS.SubscriptionStep = 5;
                    THIS.SubscriptionFree = true;
                }
            }
        });
    }

    freeAccountCheck = 0;
    freeAccountData = null;

    freeAccountModal() {
        let THIS = this;
        this.SubscriptionStep = 6;
        $.ajax({
            url: THIS.APPCONFIG.api + 'subscription/freeAccountLimitationCheck?token=' + THIS.currentUser.token,
            type: 'post',
            success: function (res) {
                if (res.status == 2000) {
                    THIS.freeAccountData = res.data;
                }
            }
        });
    }

    freeAccountSave() {
        this.SubscriptionStep = 3;
    }
    
  currencyIcon():string {    
    if(this.currentUser.settings.currency == 'USD') {
      return 'USD$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }
}
