import {Component, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {MediaService} from '../../../_services/media.service';

@Component({
    selector: 'page-mail-forward',
    templateUrl: './forward.component.html'
})
export class ForwardComponent {
    @Input() ForwardMail: any;
    @Output() newSendMail = new EventEmitter<number>();
    @Output() discard = new EventEmitter<number>();
    CustomHelper = CustomHelper;
    discardNow(){
        this.discard.emit(1);
    }

    composer = {
        name: '',
        email: ''
    };
    currentUser;
    miniLabScreen = 0;
    constructor(private mediaService: MediaService) {
        let THIS = this;
        THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        if(THIS.currentUser.settings.mail_info_active == 0){
            THIS.composer.email = THIS.currentUser.uemail;
        } else {
            THIS.composer.email = THIS.currentUser.settings.mail_info.username;
        }
        THIS.composer.name = THIS.currentUser.name;
        if(window.innerWidth <= 500){
            THIS.miniLabScreen = 1;
        }
        setTimeout(function () {
            (<any>$('.summernote')).summernote({
                maxHeight : '900px',
                minHeight : '300px',
                toolbar: [
                    ['style', ['bold', 'underline', 'clear']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    // ['insert', ['link','video']],
                    // ['misc', ['fullscreen']]
                ]
            });
        }, 100);
    }

    selectable = true;
    removable = true;
    readonly separatorKeysCodes: number[] = [12];
    to_add(e): void {
        if(e.which == 13 || e.which == 32 || e.which == 188){
            let email = e.target.value;
            if ((email || '').trim()) {
                this.To.push(email);
            }
            e.target.value = '';
        }
        this.success = 0;
    }
    to_addOnChange(e): void {
        let email = e.target.value;
        if ((email || '').trim()) {
            this.To.push(email);
        }
        e.target.value = '';
    }
    to_remove(email): void {
        const index = this.To.indexOf(email);
        if (index > -1) {
            this.To.splice(index, 1);
        }
    }
    cc_add(e): void {
        if(e.which == 13 || e.which == 32 || e.which == 188){
            let email = e.target.value;
            if ((email || '').trim()) {
                this.CC.push(email);
            }
            e.target.value = '';
        }
        this.success = 0;
    }
    cc_addOnChange(e): void {
        let email = e.target.value;
        if ((email || '').trim()) {
            this.CC.push(email);
        }
        e.target.value = '';
    }
    cc_remove(email): void {
        const index = this.CC.indexOf(email);
        if (index > -1) {
            this.CC.splice(index, 1);
        }
    }
    bcc_add(e): void {
        if(e.which == 13 || e.which == 32 || e.which == 188){
            let email = e.target.value;
            if ((email || '').trim()) {
                this.BCC.push(email);
            }
            e.target.value = '';
        }
        this.success = 0;
    }
    bcc_addOnChange(e): void {
        let email = e.target.value;
        if ((email || '').trim()) {
            this.BCC.push(email);
        }
        e.target.value = '';
    }
    bcc_remove(email): void {
        const index = this.BCC.indexOf(email);
        if (index > -1) {
            this.BCC.splice(index, 1);
        }
    }

    To = [];
    CC = [];
    BCC = [];
    CC_On = 0;
    BCC_On = 0;
    param = {
        to : [],
        cc : [],
        bcc : [],
        subject : '',
        message : '',
    };
    success = 0;
    sendMail() {
        let THIS = this;
        this.success = 0;
        this.param.to = this.To;
        this.param.cc = this.CC;
        this.param.bcc = this.BCC;
        this.param.message = (<any>$('#messageMail')).summernote('code');

        this.mediaService.sendMail(this.param).subscribe(res => {
            if(res.status == 2000){
                this.To.length = 0;
                this.param.to.length = 0;
                this.param.cc.length = 0;
                this.param.bcc.length = 0;
                this.param = {
                    to : [],
                    cc : [],
                    bcc : [],
                    subject : '',
                    message : ''
                };
                this.success = 1;
                (<any>$('#messageMail')).summernote('code','');
            }
        });
    }

    selectedAssetAttach = [];
    uploadConfileTrigger(){
        $('#fileCon').click();
    }
    uploadConFile(e){
        e.preventDefault();
        let THIS = this;
        let accepted = e.target.files;
        for (let i = 0; i < accepted.length; i++) {
            let eachFile = accepted[i];
            this.uploadThisMedia(eachFile, '4', function (uploaded) {
                THIS.selectedAssetAttach.push(uploaded);
            });
        }
        e.target.type = 'text';
        e.target.type = 'file';
    }
    removeThisAttach(index, id) {
        this.selectedAssetAttach.splice(index, 1);
        this.removeThisMedia(id, 1);
    }
    removeThisMedia(id, media_type) {
        let param = {
            id: id, media_type: media_type
        };
        this.mediaService.remove(param).subscribe(res => {});
    }

    uploadThisMedia(file, media_type, callback) {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('media_type', media_type);
        this.mediaService.create(formData).subscribe(res => {
            // this.updateChangesDiscard();
            callback(res);
        });
    }


    ngOnChanges(changes: SimpleChanges): void {
        if(changes.ForwardMail != undefined){
            let THIS = this;
            let mail = changes.ForwardMail.currentValue;
            if(mail == null){
                THIS.discardNow();
            } else {
                setTimeout(function () {
                    let bodyMail = '';
                    THIS.param.subject = mail.overview[0].subject != undefined ? mail.overview[0].subject : '';
                    if(mail.message.html != ''){ bodyMail = mail.message.html; }
                    else if(mail.message.html1 != ''){ bodyMail = mail.message.html1; }
                    else if(mail.message.html2 != ''){ bodyMail = mail.message.html2; }
                    else if(mail.message.html3 != ''){ bodyMail = mail.message.html3; }
                    else if(mail.message.text != ''){ bodyMail = mail.message.text; }
                    bodyMail = atob(bodyMail);
                    (<any>$('#messageMail')).summernote('code',bodyMail);
                }, 100);
            }
        }
    }


}
