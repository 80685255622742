import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import { AuthService } from '../../_services/auth.service';
import { UserService } from '../../_services/user.service';
declare let swal: any;
declare let toastr: any;

@Component({
    selector: 'page-intro',
    templateUrl: './intro.component.html'
})
export class IntroComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;

    constructor(protected authService:AuthService,protected userService:UserService) {

    }

}