import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssetService } from '../../_services/asset.service';
import { AuthService } from '../../_services/auth.service';
import { ContactsService } from '../../_services/contacts.service';
import { FacilityService } from '../../_services/facility.service';
import { InventoriesService } from '../../_services/inventories.service';
import { InvoicesService } from '../../_services/invoices.service';
import { Job_statusService } from '../../_services/job_status.service';
import { Job_typesService } from '../../_services/job_types.service';
import { TabsService } from '../../_services/tabs.service';
import { MediaService } from '../../_services/media.service';
import { UserService } from '../../_services/user.service';
import { WorkOrderService } from '../../_services/work-orders.service';
import { CustomHelper } from '../../customhelper';
import { APPCONFIG } from '../../config';
declare let swal: any;
declare let Ladda: any;

@Component({
    selector: 'my-page-login',
    styles: [],
    templateUrl: './restore.component.html'
})

export class RestoreComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    mobileMenu = false;

    token = null;
    constructor(
        private assetService: AssetService,
        private authService: AuthService,
        private contactsService: ContactsService,
        private facilityService: FacilityService,
        private inventoriesService: InventoriesService,
        private invoicesService: InvoicesService,
        private job_statusService: Job_statusService,
        private job_typesService: Job_typesService,
        private tabsService: TabsService,
        private mediaService: MediaService,
        private userService: UserService,
        private workOrderService: WorkOrderService,
        private router: Router
    ) {
        let comp = this.CustomHelper.getRouteStr();
        this.token = comp[1];
        this.restore();
    }

    restore() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth+'me?token='+THIS.token,
            type: 'GET',
            success: function(res){
                if(res.status == 2000){
                    localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
                    setTimeout(function () {
                        if(res.data.step == 0){
                            THIS.assetService.refreshToken();
                            THIS.authService.refreshToken();
                            THIS.contactsService.refreshToken();
                            THIS.facilityService.refreshToken();
                            THIS.inventoriesService.refreshToken();
                            THIS.invoicesService.refreshToken();
                            THIS.job_statusService.refreshToken();
                            THIS.job_typesService.refreshToken();
                            THIS.mediaService.refreshToken();
                            THIS.userService.refreshToken();
                            THIS.workOrderService.refreshToken();
                            this.className = 'animated zoomOut';
                            THIS.router.navigate(['/'+res.data.team+'/dashboard']);
                        }
                        else if(res.data.step == 6){
                            delete localStorage.currentUserInfo;
                            swal({
                                title: "Inactive Accocunt",
                                text: "Your account is not activated yet. Please check mail and use activation like to activate your account.",
                                icon: "warning",
                                buttons: ['Cancel','Resend Activation'], //
                                dangerMode: true,
                            }).then(res => {
                                if (res) {
                                    $.ajax({
                                        url: THIS.APPCONFIG.apiAuth+'resend-activation',
                                        type: 'post',
                                        data: {email: res.data.email},
                                        success: function (res) {
                                            if (res.status == 2000) {
                                                swal({
                                                    title: "Resend Activation",
                                                    text: "A new activation link is send to your email. Please check and activate your account.",
                                                    icon: "success",
                                                    dangerMode: true,
                                                });
                                            } else {
                                                swal({
                                                    title: 'Invalid Request',
                                                    className: 'swal-btn-center'
                                                });
                                            }
                                        }
                                    });
                                }
                            });
                        } else {
                            THIS.router.navigate(['/register']);
                        }
                    },2000);
                } else {
                    setTimeout(function () {
                        THIS.router.navigate(['/login']);
                    }, 2000)
                }
            }
        });

    }

}
