import { Component, TemplateRef, Input, SimpleChanges, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { InvoicesService } from '../../_services/invoices.service';
import { FacilityService } from '../../_services/facility.service';
import { FcmService } from '../../_services/fcm.service';
import { CustomHelper } from '../../customhelper';
import { APPCONFIG } from '../../config';
declare let toastr: any;
declare let swal: any;
declare let Ladda: any;

@Component({
    selector: 'page-rcti',
    templateUrl: './rcti.component.html'
})
export class RctiComponent {
    modalRef: BsModalRef;
    Permission = -1;

    APPCONFIG = APPCONFIG;
    CustomHelper = CustomHelper;
    currentUser;

    templateInvoice = 1;
    miniScreen = 0;
    invoiceSections = {
        overview: 1,
        singlePreview: 0,
        createForm: 0,
        editForm: 0,
        createRecurringForm: 0,
        createEmail: 0
    };

    ROUTES = '/rcti';
    constructor(private invoicesService: InvoicesService,
                private facilityService: FacilityService,
                private fcmService: FcmService,
                private modalService: BsModalService) {

        let THIS = this;

        setTimeout(function () {

            if(THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute] != undefined){
                window.history.pushState("","", THIS.CustomHelper.ConfRouteHistory[THIS.CustomHelper.ConfCurRoute]);
            }

            THIS.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
            // Pagination
            THIS.currentUser.pagination != null ? THIS.perPage = THIS.currentUser.pagination.rcti : '';
            // 1 = Default, 2 = Comfortable, 3 = Compact
            THIS.currentUser.list_view.rcti.d == 1 ?  THIS.Density = 1 : '';
            THIS.currentUser.list_view.rcti.cf == 1 ?  THIS.Density = 2 : '';
            THIS.currentUser.list_view.rcti.cp == 1 ?  THIS.Density = 3 : '';

            // THIS.Permission = THIS.currentUser.modules.invoices;
            THIS.Permission = 0;
            if(THIS.currentUser.user_type > 2){ THIS.Permission = 0; }
            THIS.facilityService.currentSelectedFac.subscribe(value => THIS.getInvoices());
            THIS.routeMake();
            if (localStorage.work_to_invoice !== undefined) {
                THIS.createNewInvoice();
            }
            if (localStorage.singleInv !== undefined) {
                let inv = JSON.parse(localStorage.singleInv);
                THIS.singlePreviewInvoiceQuick(inv);
                delete localStorage.singleInv;
            }
            if (localStorage.INPreId !== undefined) {
                THIS.getSinglePreview(localStorage.INPreId);
                delete localStorage.INPreId;
            }
            if(window.innerWidth <= 768){
                THIS.miniScreen = 1;
            }


        }, 500);
    }
    routeMake(){
        let route = this.CustomHelper.getRouteStr();
        if(route[2] != undefined){
            if(route[2] == ''){
                this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
            }
            else if(route[2] == 'new'){ this.createNewInvoice(); }
            else if(route[2] == 'edit'){
                //this.editInvoice();
            }
            else {
                // let preId = route[2];
                // this.previewRCTIet(preId);
            }
        } else {
            let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
            let url = '/'+Session.team+this.ROUTES;
            window.history.pushState("","", url);
            this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
        }
    }


    wo_is_active = 1;
    totalInvoice = 0;
    pageNo = 1;
    perPage = '10';
    pageEvent;
    paginateList(){
        if(this.pageEvent.pageSize == this.perPage){
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = this.pageEvent.pageIndex+1;
            this.getInvoices();
        } else {
            this.perPage = this.pageEvent.pageSize;
            this.pageNo = 1;
            this.getInvoices();
        }
    }
    searchKeyword = '';
    searchKeywordClear(e) {
        this.searchKeyword = '';
    }
    invoices = [];
    previewList = 1;
    order_by = 'desc';
    getInvoices() {
        this.previewList = 0;
        let param = {
            id : 0,
            rcti : 1,
            pageNo : this.pageNo,
            perPage : this.perPage,
            sort_by : this.sort_by,
            order_by : this.order_by,
            keyword : this.searchKeyword,
            is_active : this.wo_is_active,
        };
        this.invoices.length = 0;
        localStorage.perPageRCTI = this.perPage;
        localStorage.sort_byRCTI = this.sort_by;
        localStorage.order_byRCTI = this.order_by;
        this.invoicesService.getInvoiceList(param).subscribe(res => {
            this.previewList = 1;
            if(res.status == 2000 && res.data.length > 0){
                this.totalInvoice = res.total;
                let THIS = this;
                let DATA = [];
                $.each(res.data, function (i, v) {
                    if(new Date(THIS.CustomHelper.formatDate(v['due_date'])) < new Date(THIS.CustomHelper.formatDate(new Date()))){
                        v['status'] = 0;
                    }
                    if(new Date(THIS.CustomHelper.formatDate(v['due_date'])) == new Date(THIS.CustomHelper.formatDate(v['invoice_date']))) {
                        v['status'] = 99;
                    }
                    v['invoice_date'] = THIS.CustomHelper.formatDate(v['invoice_date']);
                    v['uid'] = THIS.CustomHelper.makeStr(v['uid']);
                    v['due_date'] = THIS.CustomHelper.formatDate(v['due_date']);
                    DATA.push(v);
                });
                this.invoices = DATA;

                if(THIS.singleInvoice != null) {
                    let index = this.invoices.map(function(e) { return e.id; }).indexOf(THIS.singleInvoice.id);
                    THIS.singleInvoice = this.invoices[index];
                }

            } else {
                this.invoices.length = 0;
                this.totalInvoice = 0;
            }
        });
        this.getInvStatus();
    }
    filter(): void {
        let THIS = this;
        clearInterval(THIS.CustomHelper.woListSearch);
        THIS.CustomHelper.woListSearch = setInterval(function() {
            clearInterval(THIS.CustomHelper.woListSearch);
            THIS.getInvoices();
        }, 400);
    }
    highlighter(subject){
        if(subject == '' || subject == null){
            return subject;
        } else {
            subject = this.CustomHelper.makeStr(subject);
        }
        let matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
        let res = '';
        $.each(matches, function (i, v) {
            res = subject.replace(v, "<span class='text-warning-highlight'>" + v + "</span>");
        });
        if (res == '') {
            res = subject;
        }
        return res;
    }
    selectAllCheck(){
        let THIS = this;
        let WO = this.invoices;
        if(this.selectedInvoice.length == this.invoices.length){
            this.selectedInvoice.length = 0;
        } else {
            this.selectedInvoice.length = 0;
            $.each(WO, function(i, v){
                THIS.selectedInvoice.push(v.id);
            });
        }
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
    }
    FilterOn = 0;
    FilterScroll = 246;
    ToggleFilter(c){
        if(c == ''){
            this.FilterOn = this.FilterOn == 1 ? 0 : 1;
        }
        let alrtH = 0;
        if(this.wo_is_active != 0){
            alrtH = 0;
        } else {
            alrtH = 50;
        }
        if(this.invoiceSections.overview == 1 && this.miniScreen == 0){
            if(this.wo_is_active != 0){
                this.FilterScroll = 246;
            } else {
                this.FilterScroll = 290;
            }
        } else {
            if(this.FilterOn == 1){
                this.FilterScroll = 290+alrtH;
            } else {
                this.FilterScroll = 246+alrtH;
            }
        }
    }
    SortTitle = 'Created Date';
    sort_by = 'created_at';
    SortOptions = [
        {title:'Created Date', slug:'created_at'},
        {title:'Status', slug:'status'},
        {title:'Invoice ID', slug:'uid'},
        {title:'Suppliers', slug:'contact'},
        {title:'Invoice Date', slug:'invoice_date'},
        {title:'Due Date', slug:'due_date'},
        {title:'Amount', slug:'total'},
    ];
    Density = 1; // 1 = Default, 2 = Comfortable, 3 = Compact
    changeDensity(density){
        let THIS = this;
        this.Density = density;
        localStorage.DensityRCTI = this.Density;
        setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
        }, 100);
        let param = {rcti : ''};
        density == 1 ? param.rcti = 'd' : '';
        density == 2 ? param.rcti = 'cf' : '';
        density == 3 ? param.rcti = 'cp' : '';
        $.ajax({
            url : THIS.APPCONFIG.apiAuth+'me/density?token='+THIS.currentUser.token,
            type: 'post',
            data: param,
            success: function(res){
            }
        });
    }
    statusCheck = [];
    FilterName = 'All';
    updateStatusCheck(status){
        this.wo_is_active = status;
        if(this.wo_is_active == 1){
            this.FilterName = 'All';
        } else if(this.wo_is_active == 2){
            this.FilterName = 'Archived';
        } else if(this.wo_is_active == 0){
            this.FilterName = 'Trashed';
        }
        this.ToggleFilter('1');
        this.getInvoices();
    }
    CounterStatus = {
        'total' : 0,
        'archived' : 0,
        'trash' : 0,
    };
    getInvStatus(){
        let THIS = this;
        let param = {
            rcti : 1
        };
        this.invoicesService.getInvoiceStatus(param).subscribe(res => {
            if(res.status == 2000){
                this.CounterStatus = {
                    'total' : res.total,
                    'archived' : res.archived,
                    'trash' : res.trash,
                };
            }
        });
    }



    createNewInvoice() {
        this.invoiceSections.overview = 0;
        this.invoiceSections.singlePreview = 0;
        this.invoiceSections.createForm = 1;
        this.invoiceSections.editForm = 0;
        this.invoiceSections.createRecurringForm = 0;
        this.invoiceSections.createEmail = 0;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/'+Session.team+this.ROUTES+'/new';
        window.history.pushState("","", url);
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    }
    editInvoice() {
        let id = this.selectedInvoice[0];
        let index = this.invoices.map(function(e) { return e.id; }).indexOf(id);
        if(index > -1){
            this.singleInvoice = this.invoices[index];
        } else {
            let indexP = this.invoices.map(function(e) { return e.id; }).indexOf(id.toString());
            if(indexP > -1){
                this.singleInvoice = this.invoices[indexP];
            }
        }

        this.invoiceSections.overview = 0;
        this.invoiceSections.singlePreview = 0;
        this.invoiceSections.createForm = 0;
        this.invoiceSections.editForm = 0;
        this.invoiceSections.createRecurringForm = 0;
        this.invoiceSections.createEmail = 0;
        let THIS = this;
        setTimeout(function () {
            THIS.invoiceSections.editForm = 1;
        }, 400);
    }
    editThisInvoice(id) {
        this.selectedInvoice = [id];
        this.editInvoice();
    }
    removeThisInvoice() {
        let THIS = this;
        if(this.selectedInvoice.length > 0){
            let ids = this.selectedInvoice;
            swal({
                title: "Delete Invoice",
                text: "Are you sure you want to delete this payment?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(res => {
                if (res) {
                    this.invoicesService.removeInvoice(ids).subscribe(res => {
                        this.getInvoices();
                        // Pusher emit
                        swal({
                            title: "Delete Invoice",
                            text: "Invoice(s) have been deleted successfully",
                            icon: "success",
                            buttons: ['Restore','ok'],
                            dangerMode: true,
                        }).then(res => {
                            if (!res) {
                                this.invoicesService.removeUndoInvoice(ids).subscribe(res => {
                                    this.getInvoices();
                                    // Pusher emit
                                });
                            }
                        });
                    });
                }
            });
        }
    }
    archiveThisInvoice() {
        let THIS = this;
        if(this.selectedInvoice.length > 0){
            let ids = this.selectedInvoice;
            swal({
                title: "Archived Invoice",
                text: "Are you sure you want to archived this payment?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(res => {
                if (res) {
                    this.invoicesService.archiveInvoice(ids).subscribe(res => {
                        this.getInvoices();
                        // Pusher emit
                        swal({
                            title: "Archive Invoice",
                            text: "Invoice(s) have been archived successfully",
                            icon: "success",
                            buttons: ['Undo Archive','ok'],
                            dangerMode: true,
                        }).then(res => {
                            if (!res) {
                                this.invoicesService.archiveUndoInvoice(ids).subscribe(res => {
                                    this.getInvoices();
                                    // Pusher emit
                                });
                            }
                        });
                    });
                }
            });
        }
    }
    changeInvoiceStatus(status) {
        this.invoicesService.statusUpdateInvoice(status, this.singleInvoice.id).subscribe(res => {
            this.singleInvoice.status = status;
            if (status == 1) {
                this.singleInvoice.paid = 0;
            }
            if (status == 3) {
                this.singleInvoice.paid = this.singleInvoice.total;
            }
        });
    }
    partial = {
        payment: 1,
        submitted: 0
    };
    paymentThisInvoice(e) {
        let THIS = this;
        let ldd = e.target;
        let ladda = Ladda.create(ldd);
        if (this.singleInvoice.id > 0 && this.partial.payment > 0) {
            ladda.start();
            this.partial.submitted = 1;
            this.invoicesService.paymentInvoice(this.partial.payment, this.singleInvoice.id).subscribe(res => {
                ladda.stop();
                (<any>$('#paymentInvoiceModal')).modal('hide');
                this.partial.submitted = 0;
                this.singleInvoice = res;
                this.singleInvoice.facStr = this.singleInvoice.facStr.reverse();
                if(this.singleInvoice.created_at !== undefined || this.singleInvoice.created_at !== null){
                    this.singleInvoice['created_at_human'] = this.CustomHelper.time_ellapsed(this.singleInvoice.created_at.date);
                }
                this.getInvoices();
                setTimeout(function () {
                    THIS.CustomHelper.tooltipRender();
                }, 100);
            });
        }
    }
    openComment = 0;
    commentThisInvoice(e) {
        var input = $(e.target).closest('.invComment').find('input');
        var comment = input.val();
        if (typeof comment == 'string') {
            if ($.trim(comment).length > 0) {
                this.invoicesService.paymentThisInvoice(comment, this.singleInvoice.id).subscribe(res => {
                    this.singleInvoice = res;
                    input.val('');
                    this.openComment = 0;
                });
            } else {
                input.val('');
            }
        }
    }
    updatedInvoice(invoice) {
        this.invoiceSections.overview = 0;
        this.invoiceSections.singlePreview = 0;
        this.invoiceSections.createForm = 0;
        this.invoiceSections.editForm = 0;
        this.invoiceSections.createRecurringForm = 0;
        this.invoiceSections.createEmail = 0;

        let THIS = this;
        setTimeout(function () {
            THIS.templateInvoice = 1;
            THIS.singleInvoice = invoice;
            THIS.singleInvoice.facStr = THIS.singleInvoice.facStr.reverse();
            if(THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null){
                THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
            }
            THIS.invoiceSections.singlePreview = 1;
        }, 400);
        this.getInvoices();
    }
    discardCreateInvoice(disca) {
        this.invoiceSections.overview = 1;
        this.invoiceSections.singlePreview = 0;
        this.invoiceSections.createForm = 0;
        this.invoiceSections.editForm = 0;
        this.invoiceSections.createRecurringForm = 0;
        this.invoiceSections.createEmail = 0;
        this.getInvoices();
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        let url = '/'+Session.team+this.ROUTES;
        window.history.pushState("","", url);
        this.CustomHelper.ConfRouteHistory[this.CustomHelper.ConfCurRoute] = window.location.pathname;
    }
    discardCreateEmail(disca) {
        this.invoiceSections.overview = 0;
        this.invoiceSections.singlePreview = 1;
        this.invoiceSections.createForm = 0;
        this.invoiceSections.editForm = 0;
        this.invoiceSections.createRecurringForm = 0;
        this.invoiceSections.createEmail = 0;
    }
    cancelSingleView() {
        this.invoiceSections.overview = 1;
        this.invoiceSections.singlePreview = 0;
        this.invoiceSections.createForm = 0;
        this.invoiceSections.editForm = 0;
        this.invoiceSections.createRecurringForm = 0;
        this.invoiceSections.createEmail = 0;
    }
    pdfThisInvoice(){
        let THIS= this;
        let id = 0;
        if(this.singleInvoice == null && this.selectedInvoice.length > 0){
            id = this.selectedInvoice[0];
        } else {
            id = this.singleInvoice.id;
        }
        window.open(THIS.APPCONFIG.apiAuth+'preview/upload/pdf/invc?id='+id);
    }
    emailThisInvoice() {
        let THIS= this;
        let id = 0;
        if(this.singleInvoice == null && this.selectedInvoice.length > 0){
            id = this.selectedInvoice[0];
        } else {
            id = this.singleInvoice.id;
        }
        let index = this.invoices.map(function(e) { return e.id; }).indexOf(id);
        let inv = this.invoices[index];
        let param = {
            id : inv.id,
            uid : inv.uid,
            type : 'invc',
            team : this.currentUser.team,
            subject : 'Invoice To: '+inv.client.name,
        };
        $('#sendMailModalTrigger').attr('data-type', JSON.stringify(param));
        $('#sendMailModalTrigger').click();
    }


    selectedInvoice = [];
    singleInvoice = null;
    checkThisRow(e, invoice) {
        e.preventDefault();

        let index = this.selectedInvoice.indexOf(invoice.id);
        if(index > -1){
            if(this.selectedInvoice.length == 1){
                this.selectedInvoice.length = 0;
            } else {
                this.selectedInvoice.splice(index, 1);
            }
        } else {
            this.selectedInvoice.push(invoice.id);
        }
        $('.drop-menu-right').removeClass('open');

    }
    selectThisRow(e, invoice) {
        e.preventDefault();
        if (e.ctrlKey) {
            if (e.which == 1) {
                let index = this.selectedInvoice.indexOf(invoice.id);
                if (index > -1) {
                    this.selectedInvoice.splice(index, 1);
                } else {
                    this.selectedInvoice.push(invoice.id);
                }
                $('.drop-menu-right').removeClass('open');
            }
        }

    }
    singlePreviewInvoice(e, invoice) {
        let THIS = this;
        if(e != null){
            e.preventDefault();
        }
        if (e.ctrlKey) {
            if(e.which == 1){
                let index = this.selectedInvoice.indexOf(invoice.id);
                if(index > -1){
                    this.selectedInvoice.splice(index, 1);
                } else {
                    this.selectedInvoice.push(invoice.id);
                }
                $('.drop-menu-right').removeClass('open');
            }
        }
        else{
            if(e.which == 1){
                this.invoiceSections.overview = 0;
                this.invoiceSections.singlePreview = 0;
                this.invoiceSections.createForm = 0;
                this.invoiceSections.editForm = 0;
                this.invoiceSections.createRecurringForm = 0;
                this.invoiceSections.createEmail = 0;
                THIS.selectedInvoice.length = 0;
                THIS.selectedInvoice[0] = invoice.id;

                setTimeout(function () {
                    THIS.templateInvoice = 1;
                    THIS.singleInvoice = invoice;
                    THIS.singleInvoice.facStr = THIS.singleInvoice.facStr.reverse();
                    if(THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null){
                        THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
                    }
                    THIS.invoiceSections.singlePreview = 1;
                    setTimeout(function () {
                        THIS.CustomHelper.tooltipRender();
                    }, 100);
                }, 400);
                $('.drop-menu-right').removeClass('open');
            } else if(e.which == 3){
                if(THIS.selectedInvoice.length < 2){
                    this.invoiceSections.overview = 0;
                    this.invoiceSections.singlePreview = 0;
                    this.invoiceSections.createForm = 0;
                    this.invoiceSections.editForm = 0;
                    this.invoiceSections.createRecurringForm = 0;
                    this.invoiceSections.createEmail = 0;

                    setTimeout(function () {
                        THIS.templateInvoice = 1;
                        THIS.singleInvoice = invoice;
                        THIS.selectedInvoice.length = 0;
                        THIS.selectedInvoice[0] = invoice.id;
                        THIS.singleInvoice.facStr = THIS.singleInvoice.facStr.reverse();
                        if(THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null){
                            THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
                        }
                        THIS.invoiceSections.singlePreview = 1;
                        setTimeout(function () {
                            THIS.CustomHelper.tooltipRender();
                        }, 100);
                    }, 400);
                }
                let target = $(e.target);
                let w = target.closest('#right-click-holder').width();
                let h = target.closest('#right-click-holder').height();
                let p = target.closest('#right-click-holder').offset();
                let left = e.clientX - p.left;
                let top = e.clientY - p.top;
                let clickH = $('.drop-menu-right').height();
                clickH = clickH < 150 ? 400 : clickH;
                if(this.selectedInvoice.length > 1){ clickH = 150; }
                if ((w - left) < 230) { left = w - 250; }
                if(h < top+clickH){ top = top - (top+clickH - h); }
                if(top < 10){ top = 10; }

                let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
                if(ttarget.hasClass('open')){
                    $('.drop-menu-right').removeClass('open');
                    ttarget.css({
                        top : top,
                        left : left
                    });
                    setTimeout(function () {
                        ttarget.addClass('open');
                    }, 100);
                }
                else {
                    ttarget.css({
                        top : top,
                        left : left
                    });
                    ttarget.addClass('open');
                }
            }
        }
    }
    singlePreviewInvoiceQuick(invoice) {
        let THIS = this;
        this.invoiceSections.overview = 0;
        this.invoiceSections.singlePreview = 0;
        this.invoiceSections.createForm = 0;
        this.invoiceSections.editForm = 0;
        this.invoiceSections.createRecurringForm = 0;
        this.invoiceSections.createEmail = 0;
        setTimeout(function () {
            THIS.templateInvoice = 1;
            THIS.singleInvoice = invoice;
            THIS.selectedInvoice.length = 0;
            THIS.selectedInvoice[0] = invoice.id;
            if(THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null){
                THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
            }
            THIS.invoiceSections.singlePreview = 1;
            setTimeout(function () {
                THIS.CustomHelper.tooltipRender();
            }, 100);
        }, 400);
        $('.drop-menu-right').removeClass('open');
        this.getInvoices()
    }
    rightClickMenu(e,invoice){
        e.preventDefault();

        if(this.selectedInvoice.length < 2){
            this.selectedInvoice.length = 0;
            this.selectedInvoice = [invoice.id];
        }

        let target = $(e.target);
        let w = target.closest('#right-click-holder').width();
        let h = target.closest('#right-click-holder').height();
        let p = target.closest('#right-click-holder').offset();
        let left = e.clientX - p.left;
        let top = e.clientY - p.top;
        let clickH = $('.drop-menu-right').height();
        // clickH = clickH < 150 ? 400 : clickH;
        if(this.selectedInvoice.length > 1){ clickH = 150; }
        if ((w - left) < 230) { left = w - 250; }
        if(h < top+clickH){ top = top - (top+clickH - h); }
        if(top < 10){ top = 10; }

        let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
        if(ttarget.hasClass('open')){
            $('.drop-menu-right').removeClass('open');
            ttarget.css({
                top : top,
                left : left
            });
            setTimeout(function () {
                ttarget.addClass('open');
            }, 100);
        }
        else {
            ttarget.css({
                top : top,
                left : left
            });
            ttarget.addClass('open');
        }
    }
    dropMenuRightToggle(e){
        let container = $('.invoice-list');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.drop-menu-right').removeClass('open');
        }
    }
    getSinglePreview(pre_id){
        this.invoicesService.getInvoiceSingle({pre_id: pre_id}).subscribe(res => {
            if(res.status == 2000){
                this.singlePreviewInvoiceQuick(res.data);
            }
        });
    }

    paymentLog = [];
    generatePaymentLog() {
        this.paymentLog.length = 0;
        let THIS = this;
        $.each(THIS.singleInvoice.log, function (i, v) {
            if (v.pn_type == 'status-update-2') {
                THIS.paymentLog.push(v);
            }
        });
    }

    ShowPaymentHistory(){

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
        let child = ___selectedTab+1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
        (<any>$('#paymentHistoryModal')).modal('show');
    }
    removePaymentHistory(payment){
        let THIS = this;
        swal({
            title: "Remove Payment",
            text: "Are you sure you want to remove this payment?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let param = {
                    id: payment.id,
                    inv_id: THIS.singleInvoice.id,
                };
                THIS.invoicesService.paymentRemove(param).subscribe(res => {
                    THIS.singleInvoice = res.data;
                    THIS.singleInvoice.facStr = THIS.singleInvoice.facStr.reverse();
                    if(THIS.singleInvoice.created_at !== undefined || THIS.singleInvoice.created_at !== null){
                        THIS.singleInvoice['created_at_human'] = THIS.CustomHelper.time_ellapsed(THIS.singleInvoice.created_at.date);
                    }
                    THIS.getInvoices();
                    swal({
                        title: "Remove Payment",
                        text: "Paymenthas been removed successfully",
                        icon: "success"
                    });
                    if(THIS.singleInvoice.log.length == 0){
                        (<any>$('#paymentHistoryModal')).modal('hide');
                    }
                });
            } else {
                swal.stopLoading();
                swal.close();
            }
        });
    }

    ngOnInit() {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        /*this.socket.on('_rfr_team_data_rcti_'+Session.team, function (v) {
            THIS.getInvoices();
        });*/
    }
    ngOnDestroy() {
    }
    
    currencyIcon():string {    
        if(this.currentUser.settings.currency == 'USD') {
          return '$';
        } else {
          return this.currentUser.settings.currency + ' ';
        }
    }
}
