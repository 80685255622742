import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {map} from "rxjs/operators";
import {APPCONFIG} from '../config';

@Injectable()
export class AuthService {


    public token: string;
    _glKeyword = new BehaviorSubject<any>('');
    current_glKeyword = this._glKeyword.asObservable();

    APPCONFIG = APPCONFIG;

    update_glKeyword(keyword) {
        this._glKeyword.next(keyword);
    }

    private SessionUserInfo = new BehaviorSubject(null);
    private userType = new BehaviorSubject(1);

    getUserType = this.userType.asObservable();
    currentUser: {
        id: number,
        email: string,
        first_name: string,
        last_name: string
    };

    FetchSessionUserInfo = this.SessionUserInfo.asObservable();
    updateSessionUserInfo(user){
        this.SessionUserInfo.next(user);
    }

    // authApi = 'http://localhost/projects/mediusware/Equidesk/API/public/api/auth/';
    authApi = APPCONFIG.apiAuth;

    constructor(private http: HttpClient, private router: Router ) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }
    getToken(): string {
        let stored = localStorage.getItem('currentUserInfo');
        return (JSON.parse(stored) || {}).token;
    }
    login(param): Observable<any> {
        return this.http
            .post<any>(this.authApi+'login', param)
            .pipe(map(res => {
                if (res.status == 2000) {
                    this.userType.next(res.data.user_type);
                    this.token = res.data.token;
                    localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
                    return res.data;
                } else {
                    return false;
                }
            }));
    }
    loginNew(param): Observable<any> {
        return this.http
            .post<any>(this.authApi+'login', param)
            .pipe(map(res => {
                if (res.status == 2000) {
                    this.userType.next(res.data.user_type);
                    this.token = res.data.token;
                    localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
                    return res;
                } else {
                    return res;
                }
            }));
    }
    loginNewGuest(param): Observable<any> {
        return this.http
            .post<any>(this.authApi+'login/guest', param)
            .pipe(map(res => {
                if (res.status == 2000) {
                    this.userType.next(res.data.user_type);
                    this.token = res.data.token;
                    localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
                    return res;
                } else {
                    return res;
                }
            }));
    }
    loginFcm(param): Observable<any> {
        return this.http
            .post<any>(this.authApi+'login/fcm?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    activatePass(param): Observable<any> {
        return this.http
            .post<any>(this.authApi+'activation/pass', param)
            .pipe(map(res => {
                if(res.status == 2000){
                    this.userType.next(res.data.user_type);
                    this.token = res.data.token;
                    localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
                }
                return res;
            }));
    }
    logout(): Observable<any> {
        return this.http
            .get<any>(this.authApi + 'signOut?token=' + this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getPersonalInfo(): Observable<any> {
        return this.http
            .get<any>(this.authApi + 'me?token=' + this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getPersonalLoginHistory(): Observable<any> {
        return this.http
            .get<any>(this.authApi + 'loginHistory?token=' + this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getUserLoginHistory(id): Observable<any> {
        return this.http
            .get<any>(this.authApi + 'loginHistory?id='+id+'&token=' + this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    updateMe(param): Observable<any> {
        return this.http
            .post<any>(this.authApi + 'updateMe?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    authCheckToLogout() {
        return this.http
            .get<any>(this.authApi + 'me?token=' + this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    authCheck() {
        return this.http
            .get<any>(this.authApi + 'me?token=' + this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getSessionUserInfo() {
        return this.http
            .get<any>(this.authApi + 'me?token=' + this.token)
            .pipe(map(res => {
                return res;
            }));
    }

    RecurringWorkCron() {
        return this.http.get<any>(this.authApi + 'work-orders-recurring-cron').pipe(map(res => {
            return res;
        }));
    }


    stepForm1Submit(param): Observable<any> {
        return this.http
            .post<any>(this.authApi + 'register', param)
            .pipe(map(res => {
                return res;
            }));
    }
    stepForm2Submit(param): Observable<any> {
        return this.http
            .post<any>(this.authApi + 'company-update?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    stepForm3Submit(param): Observable<any> {
        return this.http
            .post<any>(this.authApi + 'personal-update?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    stepForm4Submit(param): Observable<any> {
        return this.http
            .post<any>(this.authApi + 'team-update?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    stepForm6Submit(param): Observable<any> {
        return this.http
            .post<any>(this.authApi + 'activate-account?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }


    getAllUnreadNotification(param): Observable<any> {
        return this.http
            .post<any>(this.APPCONFIG.api + 'notifications/get/all/unread?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getAllSettingsNotification(param): Observable<any> {
        return this.http
            .post<any>(this.APPCONFIG.api + 'notifications/get/user/settings?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    updateSettingsNotification(param): Observable<any> {
        return this.http
            .post<any>(this.APPCONFIG.api + 'notifications/user/settings?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    makeAllReadNotification(param): Observable<any> {
        return this.http
            .post<any>(this.APPCONFIG.api + 'notifications/make/all/read?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    makeAllSeenNotification(param): Observable<any> {
        return this.http
            .post<any>(this.APPCONFIG.api + 'notifications/make/all/seen?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    makeDismissNotification(param): Observable<any> {
        return this.http
            .post<any>(this.APPCONFIG.api + 'notifications/make/all/dismiss?token=' + this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
}
