import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { APPCONFIG } from '../config';

import { ContactsModel } from '../_models/contacts';

import { HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";

@Injectable()
export class ContactsService {

    public token: string;
    contacts: Array<ContactsModel> = [];

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }

    create(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}contact/create?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    createLot(param): Observable<any> {
        return this.http
            .post<any>(`${APPCONFIG.api}contact/create/lot?token=`+this.token, param)
            .pipe(map(res => {
                return res;
            }));
    }
    getAll(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}contact/list?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getAllSupplier(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}supplier/list?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }
    getAllSupplierTotal(): Observable<any> {
        return this.http
            .get<any>(`${APPCONFIG.api}supplierTotal/list?token=`+this.token)
            .pipe(map(res => {
                return res;
            }));
    }

}
