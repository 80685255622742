import {Component, AfterViewInit, OnDestroy, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {Router} from '@angular/router';
import {APPCONFIG} from '../../config';
import {CustomHelper} from '../../customhelper';

import {AssetService} from '../../_services/asset.service';
import {AuthService} from '../../_services/auth.service';
import {ContactsService} from '../../_services/contacts.service';
import {FacilityService} from '../../_services/facility.service';
import {InventoriesService} from '../../_services/inventories.service';
import {InvoicesService} from '../../_services/invoices.service';
import {Job_statusService} from '../../_services/job_status.service';
import {Job_typesService} from '../../_services/job_types.service';
import {MediaService} from '../../_services/media.service';
import {TabsService} from '../../_services/tabs.service';
import {UserService} from '../../_services/user.service';
import {WorkOrderService} from '../../_services/work-orders.service';
import {FcmService} from '../../_services/fcm.service';
import {ChatService} from '../../_services/chat.service';

import {WelcomeComponent} from '../../pages/dashboard/welcome.component';
import * as XLSX from 'xlsx';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';


declare let swal: any;
declare let Sortable: any;
declare let toastr: any;
declare let Ladda: any;
declare let Pusher: any;
declare let Stripe: any;
declare let Card: any;
declare let Draggable: any;
declare let PhotoSwipe: any;
declare let PhotoSwipeUI_Default: any;


@Component({
  selector: 'my-app-header',
  styles: [],
  templateUrl: './header.component.html'
})

export class AppHeaderComponent implements AfterViewInit, OnDestroy, OnInit {
  @Output() toggleSide = new EventEmitter<number>();
  @Output() preview = new EventEmitter<number>();
  @Input() step: any;
  @Input() sideNavScreen: any;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  TabSlide;
  vendor_info: any = [];
  dateOption = [];
  errors = {};
  valid = {
    address: 0,
  };
  companyParam: {
    name: '',
    address: '',
  };

  vendor_param: any = {
    title: '',
    company_logo: null,
    email: '',
    company_address: '',
    service_description: '',
    operation_radius: '50',
    industry: '',
    verify_doc: [],
    gallery: [],
  };
  sanitizedVendorParam: any = {};
  selectedCategoriesArray: any = [];
  VendorErrors: any = {};
  companyLoading = false;
  galleryLoading = false;
  newDocParam: any = {
    info_value: null,
    info_doc: null,
    expiry: null,
  };

  private modalHiddenListener: any;

  ngAfterViewInit() {
    this.modalHiddenListener = (event: any) => {
      if (event.target.id === 'sendMailModal') {
        this.discardMail();
      }
    };
    $('#sendMailModal').on('hidden.bs.modal', this.modalHiddenListener);
  }

  ngOnDestroy() {
    if (this.modalHiddenListener) {
      $('#sendMailModal').off('hidden.bs.modal', this.modalHiddenListener);
    }
  }

  Subscription = 0;
  SubscriptionStep = 1;
  plan = 'Pro';
  selectedPlanList = [];
  planSelected = null;
  cycle = 'year';
  employee = 1;

  employeeUpdate() {
    if (this.employee < 1) {
      this.employee = 1;
    }
  }

  Plans = [];
  freePlan = null;
  cardInfo = {
    name: '',
    card_number: '',
    exp_month: '',
    exp_year: '',
    cvc: '',
  };

  getSubscriptionPlans() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'subscription/getPlans?token=' + THIS.currentUser.token,
      type: 'get',
      success: function (res) {
        THIS.Plans = [];
        res.data.forEach(function (v) {
          if (v.name != 'Standard') {
            THIS.Plans.push(v);
          }
        });
        THIS.freePlan = res.freePlan;
      }
    });
  }

  /*Sign up vendor API*/
  signUpVendor() {
    const ldd = document.getElementById('SignUpAsVendor');
    const ladda = Ladda.create(ldd);
    const THIS = this;
    ladda.start();
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'verify-vendor/active?token=' + THIS.currentUser.token,
      type: 'post',
      contentType: 'application/json',
      success: function (res) {
        ladda.stop();
        THIS.restore();
      },
      error: function (err) {
        ladda.stop();
        console.error('Error:', err);
      }
    });
  }

  openSignupVendorModal() {
    this.vendor_param = {
      company_logo: this.currentUser.company_logo,
      company_address: this.currentUser.company_address,
      service_description: '',
      operation_radius: '50',
      industry: '',
      verify_doc: [],
      gallery: [],
    };
    this.selectedCategoriesArray = [];
    this.VendorErrors = {};
    const modal = document.getElementById('signupVendorModal');
    if (modal !== undefined) {
      modal.classList.add('active');
    }
    const requiredDocuments = [
      'Company or Business Name Registration Certificate',
      'Public Liability Insurance',
      'Contractor/Trade License',
      'Workers Compensation Insurance',
    ];
    this.vendor_param.verify_doc = [];
    requiredDocuments.forEach((docName) => {
      this.vendor_param.verify_doc.push({
        info_name: docName,
        info_value: null,
        info_doc: null,
        expiry: null,
        loading: false,
        allowDelete: false,
        step: this.step,
        required: true,
        document_required: true,
      });
    });

  }


  closeSignupVendorModal() {
    const modal = document.getElementById('signupVendorModal');
    if (modal !== undefined && modal.classList.contains('active')) {
      modal.classList.remove('active');
    }
    // (<any>$('signupVendorModal')).classList.remove('active');
  }

  removeItem(i: any) {
    this.vendor_param.verify_doc.splice(i, 1);
  }

  addNewItem() {
    const param = {
      info_name: null,
      info_value: null,
      info_doc: null,
      expiry: null,
      loading: false,
      allowDelete: true,
      required: true,
      document_required: true,
    };
    this.vendor_param.verify_doc.push(param);
  }

  expiryConfig(index: any, dateTime: any) {
    const THIS: any = this;
    this.dateOption[index] = {
      altInput: true,
      minDate: 'today',
      disableMobile: true,
      defaultDate: dateTime != null ? new Date(dateTime) : null,
      onChange: function (selectedDates, dateStr, instance) {
      },
    };
    this.dateOption[index].onChange = function (selectedDates, dateStr, instance) {
      THIS.vendor_param.verify_doc[index].expiry = dateStr;
      THIS.checkIfHasError(index);
    };
    return this.dateOption[index];
  }

  checkIfRequired(e, data, index) {
    data.document_required = e;
    this.VendorErrors['document_' + index] = null;
  }

  getIcon(file_name) {
    // Define arrays of image and file extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
    const fileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'zip', 'rar'];

    // Extract the file extension from the file name
    const extension = file_name.split('.').pop().toLowerCase();

    // Check if the extension matches an image or file type
    if (imageExtensions.includes(extension)) {
      return 'image'; // Material Icon for image files
    } else if (fileExtensions.includes(extension)) {
      return 'insert_drive_file'; // Material Icon for generic files
    } else {
      return 'insert_drive_file'; // Material Icon for generic files
    }
  }

  checkRequired(data: any) {
    if (data.info_name == 'Company or Business Name Registration Certificate' || data.info_name == 'Public Liability Insurance') {
      return false;
    }
    return true;
  }

  restore() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'me?token=' + THIS.currentUser.token,
      type: 'GET',
      success: function (res) {
        if (res.status == 2000) {
          localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
          setTimeout(function () {
            THIS.assetService.refreshToken();
            THIS.authService.refreshToken();
            THIS.contactsService.refreshToken();
            THIS.facilityService.refreshToken();
            THIS.inventoriesService.refreshToken();
            THIS.invoicesService.refreshToken();
            THIS.job_statusService.refreshToken();
            THIS.job_typesService.refreshToken();
            THIS.mediaService.refreshToken();
            THIS.userService.refreshToken();
            THIS.workOrderService.refreshToken();
          }, 2000);
        }
      }
    });

  }

  planSelectedList() {
    this.selectedPlanList.length = 0;
    this.Plans.forEach((v) => {
      if (v.name === this.plan) {
        this.selectedPlanList.push(v);
        this.planSelected = v;
      }
    });
    // this.planSelected = this.selectedPlanList[0];
  }

  cardError = '';
  cardSuccess = '';
  subscriptionParam: any = {
    plan: null,
    cycle: null,
    employee: null,
    card_token: null
  };

  validateStripecard() {
    const THIS = this;
    console.log(THIS.plan);
    if (THIS.plan == 'Pro') {
      THIS.cardSuccess = 'Please wait to finish the subscription process.';

      THIS.subscriptionParam.plan = this.plan;
      THIS.subscriptionParam.cycle = this.cycle;
      THIS.subscriptionParam.employee = this.employee;
      $.ajax({
        url: THIS.APPCONFIG.api + 'subscription/makeSubscription?token=' + THIS.currentUser.token,
        type: 'post',
        data: THIS.subscriptionParam,
        success: function (res) {
          if (res.status == 2000) {
            window.location.href = res.redirect;
            // THIS.SubscriptionStep = 5;
          } else {
            THIS.cardSuccess = '';
            THIS.cardError = res.error;
          }
        }
      });
    } else {
      THIS.cardSuccess = 'Please wait to finish the subscription process.';
      const param = {
        plan: THIS.plan,
        cycle: THIS.cycle,
        employee: THIS.employee,
      };
      $.ajax({
        url: THIS.APPCONFIG.api + 'subscription/makeSubscription?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          if (res.status == 2000) {
            window.location.href = res.redirect;
            // THIS.SubscriptionStep = 5;
          } else {
            THIS.cardSuccess = '';
            THIS.cardError = res.error;
          }
        }
      });
    }
  }

  expUpdate(e) {
    const v = e.target.value;
    const arr = v.split('/');
    this.cardInfo.exp_month = arr[0] != undefined ? $.trim(arr[0]) : '';
    this.cardInfo.exp_year = arr[1] != undefined ? $.trim(arr[1]) : '';
  }

  elements: any = null;
  stripe: any = null;
  paymentLoading = false;
  card: any = null;
  style: any = null;

  submitCard(event: any) {
    const THIS = this;
    THIS.paymentLoading = true;
    event.preventDefault();
    setTimeout(() => {
      THIS.stripe.createToken(THIS.card).then(function (result) {
        THIS.paymentLoading = false;
        if (result.error) {
          console.log(result.error);
        } else {
          console.log(result.token);
          THIS.subscriptionParam.card_token = result.token.id;
          THIS.validateStripecard();
        }
      });
    }, 300);
  }

  createCustomer() {
    const THIS = this;
    const param = {
      email: THIS.currentUser.email,
      name: THIS.currentUser.first_name + ' ' + THIS.currentUser.last_name,
    };
    setTimeout(function () {
      $.ajax({
        url: THIS.APPCONFIG.api + 'subscription/create-customer?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {

        }
      });

    }, 500);
  }

  showMessage(messageText: any) {

  }

  refreshCards() {
    (<any>$('#refreshCards')).click();
  }

  Prorate = null;

  getProrate() {
    const THIS = this;
    THIS.Prorate = null;
    const param = {
      plan: THIS.plan,
      cycle: THIS.cycle,
      employee: THIS.employee
    };
    $.ajax({
      url: THIS.APPCONFIG.api + 'subscription/getProrate?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        THIS.Prorate = res.data;
        THIS.Prorate.CMFD = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.CMFD, '');
        THIS.Prorate.CMFDNY = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.CMFDNY, '');
        THIS.Prorate.CMFDNYNI = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.CMFDNYNI, '');
        THIS.Prorate.TD = THIS.CustomHelper.dateDynamicFormat(THIS.Prorate.TD, '');
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      }
    });
  }

  currentUser = null;
  ScreenW;

  constructor(
    private assetService: AssetService,
    private chatService: ChatService,
    private authService: AuthService,
    private contactsService: ContactsService,
    private facilityService: FacilityService,
    private inventoriesService: InventoriesService,
    private invoicesService: InvoicesService,
    private job_statusService: Job_statusService,
    private job_typesService: Job_typesService,
    private mediaService: MediaService,
    private userService: UserService,
    private fcmService: FcmService,
    private workOrderService: WorkOrderService,
    private tabsService: TabsService,
    private coolDialogs: NgxCoolDialogsService,
    private router: Router) {
    const THIS = this;
    this.getSessionUserInfo(0);
    this.getAllUnreadNotification();
    this.getAllSettingsNotification();
    this.tabsService.getTabs().subscribe(tabs => {
      this.tabs = tabs;
    });
    this.tabsService.getMainTab.subscribe(tab => {
      this.mainTab = tab;
    });
    this.tabsService.getSelectedTab.subscribe(tab => {
      this.selectedTab = tab;
    });

    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.user.email = this.currentUser.email;
    this.getStorage();
    this.freeAccountModal();

    $('body').on('hidden.bs.modal', '.modal', function () {
      if ($('.modal.fade.in').length === 0) {
        let selectedTab = 0;
        const tabs = (<any>$('#sort-tabs').find('li'));
        tabs.each(function (i, v) {
          if ($(v).find('a.active').length > 0) {
            selectedTab = i;
          }
        });
        THIS.TabSlide = (<any>$('.owl-carousel')).owlCarousel({
          loop: false,
          nav: false,
          dots: false,
          mouseDrag: false,
          touchDrag: false,
          items: 1,
          animateIn: 'fadeIn faster',
          autoplay: false
        }).on('changed.owl.carousel', function (event) {
          THIS.selectedTab = event.item.index;
          THIS.tabsService.setSelectedTab(THIS.selectedTab);
        });
        (<any>$('.owl-carousel')).trigger('to.owl.carousel', selectedTab);
        THIS.tabsService.setSelectedTab(selectedTab);
        if (localStorage.firstComeWelcome != undefined) {
          if (localStorage.firstComeWelcome == 1) {
            setTimeout(function () {
              THIS.openHelpCenter();
              delete localStorage.firstComeWelcome;
            }, 1000);

          }
        }
      }
    });
    $('body').on('click', '.openSubscription', function () {
      THIS.openSubscription();
    });

    Notification.requestPermission(function (permission) {
      if (permission === 'granted') {
        console.log(permission);
      }
    });
    THIS.ScreenW = window.innerWidth;

  }

  userInfo = {
    email: '',
    name: '',
    avatar: '',
    settings: null,
    profile: null,
    user_type: '',
    company_name: null,
  };

  openSubscription() {
    this.Subscription = 1;
    this.SubscriptionStep = 1;
    this.getSubscriptionPlans();
    localStorage.expiredSubscription = 1;
  }

  getSessionUserInfo(type) {
    this.authService.getSessionUserInfo().subscribe(res => {
      if (res.status == 2000) {

        this.currentUser = res.data;
        localStorage.setItem('currentUserInfo', JSON.stringify(res.data));
        this.authService.updateSessionUserInfo(res.data);
        this.assetService.refreshToken();
        this.authService.refreshToken();
        this.contactsService.refreshToken();
        this.facilityService.refreshToken();
        this.inventoriesService.refreshToken();
        this.invoicesService.refreshToken();
        this.job_statusService.refreshToken();
        this.job_typesService.refreshToken();
        this.mediaService.refreshToken();
        this.userService.refreshToken();
        this.workOrderService.refreshToken();
        this.userInfo = {
          email: res.data.email,
          name: res.data.first_name + ' ' + res.data.last_name,
          avatar: res.data.avatar,
          settings: res.data.settings,
          profile: res.data.profile,
          user_type: res.data.user_type,
          company_name: res.data.company_name,
        };
        this.user.email = res.data.email;

        // if(res.data.subscription.status == 0 && this.Subscription == 0 && this.currentUser.free_plan != 1){
        if (res.data.subscription.status == 0 && this.Subscription == 0) {
          if (localStorage.expiredSubscription != 1) {
            this.Subscription = 1;
            this.SubscriptionStep = 1;
            this.getSubscriptionPlans();
            localStorage.expiredSubscription = 1;
          }
        }

      } else {
        if (type == 0) {
          this.logout();
        } else {
          let ___selectedTab = 0;
          const tabs = $('#sort-tabs').find('li');
          tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
              ___selectedTab = i;
            }
          });
          const child = ___selectedTab + 1;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          $('#sort-tabs').find('li').find('a').removeClass('active');
          $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
          (<any>$('#reLoginModal')).modal('show');
          delete localStorage.currentUserInfo;
        }
      }
    });
  }

  toggleSideNav() {
    this.sideNavScreen = !this.sideNavScreen;
    this.toggleSide.emit(1);
  }

  logout() {
    const THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    (<any>$('#reLoginModal')).remove();
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'signOut?token=' + THIS.currentUser.token,
      type: 'get',
      success: function (res) {
        delete localStorage.currentUserInfo;
        localStorage.clear();
        window.location.href = THIS.APPCONFIG.FrontAppURL;
      },
      error: function (error) {
        delete localStorage.currentUserInfo;
        localStorage.clear();
        window.location.href = THIS.APPCONFIG.FrontAppURL;
      }
    });
  }

  user = {
    email: '',
    password: '',
  };
  errorMessage = '';

  login() {
    const THIS = this;
    const ldd = document.getElementById('submitBTN');
    const ladda = Ladda.create(ldd);
    const param = this.user;
    ladda.start();
    this.authService.loginNew(param).subscribe(res => {
      ladda.stop();
      THIS.user.password = '';
      if (res.status == 2000) {
        setTimeout(function () {
          if (res.data.step == 0) {
            THIS.assetService.refreshToken();
            THIS.authService.refreshToken();
            THIS.contactsService.refreshToken();
            THIS.facilityService.refreshToken();
            THIS.inventoriesService.refreshToken();
            THIS.invoicesService.refreshToken();
            THIS.job_statusService.refreshToken();
            THIS.job_typesService.refreshToken();
            THIS.mediaService.refreshToken();
            THIS.userService.refreshToken();
            THIS.workOrderService.refreshToken();
            (<any>$('#reLoginModal')).modal('hide');
          } else if (res.data.step == 6) {
            delete localStorage.currentUserInfo;
            swal({
              title: 'Inactive Accocunt',
              text: 'Your account is not activated yet. Please check mail and use activation like to activate your account.',
              icon: 'warning',
              buttons: ['Cancel', 'Resend Activation'], //
              dangerMode: true,
            }).then(res => {
              if (res) {
                $.ajax({
                  url: THIS.APPCONFIG.apiAuth + 'resend-activation',
                  type: 'post',
                  data: {email: THIS.user.email},
                  success: function (res) {
                    if (res.status == 2000) {
                      swal({
                        title: 'Resend Activation',
                        text: 'A new activation link is send to your email. Please check and activate your account.',
                        icon: 'success',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    } else {
                      swal('Invalid Request');
                    }
                  }
                });
              }
            });
          } else {
            THIS.router.navigate(['/register']);
          }
        }, 400);
      } else if (res.status == 7000) {
        this.errorMessage = res.error;
      } else {
        this.errorMessage = 'Password is incorrect';
      }
    });
  }


  // ===========================
  // Selected Tabs
  // ===========================
  tabs = [];
  selectedTab = 0;
  mainTab: any;

  keepCurrentTab() {
    this.CustomHelper.ConfCurRoute = this.selectedTab;
  }

  tabSwitch($event: any) {
    if ((this.tabs.length + 1) == $event.index) {
      this.selectedTab = $event.index - 1;
    } else {
      this.selectedTab = $event.index;
    }
    this.tabsService.setSelectedTab(this.selectedTab);
  }

  NewTabSwitch() {
    this.selectedTab = this.tabs.length - 1;
    this.tabsService.setSelectedTab(this.selectedTab);
    this.slideUpdate(this.selectedTab, 1);
    this.keepCurrentTab();
  }

  tabSelect(index) {
    const THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    const i = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (i > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[i].change;
    }
    if (this.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          THIS.selectedTab = index;
          THIS.tabsService.setSelectedTab(this.selectedTab);
          THIS.slideUpdate(this.selectedTab, 0);
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(i, 1);
          THIS.CustomHelper.woRouteDiscard = 0;
        }
      });
    } else {
      THIS.selectedTab = index;
      THIS.tabsService.setSelectedTab(this.selectedTab);
      THIS.slideUpdate(this.selectedTab, 0);
    }
  }

  openTab() {
    const THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    const index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (this.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          THIS.tabsService.addTab({
            description: 'New Tab',
            component: WelcomeComponent,
            id: (new Date).getTime()
          });
          THIS.NewTabSwitch();
          const list = document.getElementById('sort-tabs');
          Sortable.create(list, {
            onEnd: function (e) {
              (<any>$('.owl-carousel')).owlCarousel('destroy');
              THIS.tabs[e.oldIndex] = THIS.tabs.splice(e.newIndex, 1, THIS.tabs[e.oldIndex])[0];
              THIS.selectedTab = e.newIndex;
              THIS.slideUpdate(THIS.selectedTab, 1);
            },
          });
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
        }
      });
    } else {
      THIS.tabsService.addTab({
        description: 'Welcome',
        component: WelcomeComponent,
        id: (new Date).getTime()
      });
      THIS.NewTabSwitch();
      const list = document.getElementById('sort-tabs');
      Sortable.create(list, {
        onEnd: function (e) {
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          THIS.tabs[e.oldIndex] = THIS.tabs.splice(e.newIndex, 1, THIS.tabs[e.oldIndex])[0];
          THIS.selectedTab = e.newIndex;
          THIS.slideUpdate(THIS.selectedTab, 1);
        },
      });
    }
  }

  closeTab(id, index) {
    const THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    const i = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (i > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[i].change;
    }
    if (this.CustomHelper.woRouteDiscard == 1 && index == THIS.selectedTab) {
      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(i, 1);
          THIS.CustomHelper.woRouteDiscard = 0;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          const idx = THIS.tabs.map(function (item) {
            return item.id;
          }).indexOf(id);
          THIS.tabs.splice(idx, 1);
          THIS.selectedTab = 0;
          THIS.tabsService.setSelectedTab(THIS.selectedTab);
          THIS.slideUpdate(THIS.selectedTab, 1);
          // THIS.keepCurrentTab();
        }
      });
    } else {
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      const idx = THIS.tabs.map(function (item) {
        return item.id;
      }).indexOf(id);
      THIS.tabs.splice(idx, 1);
      if (THIS.tabs.length > idx) {
        THIS.selectedTab = idx;
      } else {
        THIS.selectedTab = THIS.tabs.length - 1;
      }
      THIS.tabsService.setSelectedTab(THIS.selectedTab);
      THIS.slideUpdate(THIS.selectedTab, 1);
      THIS.keepCurrentTab();
    }
  }

  slideUpdate(selectedTab, newD) {
    const THIS = this;
    // (<any>$('.owl-carousel')).owlCarousel('destroy');
    setTimeout(function () {
      if (newD == 1) {
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        THIS.TabSlide = (<any>$('.owl-carousel')).owlCarousel({
          loop: false,
          nav: false,
          dots: false,
          mouseDrag: false,
          touchDrag: false,
          items: 1,
          animateIn: 'fadeIn faster',
          autoplay: false
        }).on('changed.owl.carousel', function (event) {
          THIS.selectedTab = event.item.index;
          THIS.tabsService.setSelectedTab(THIS.selectedTab);
        });
      }
      (<any>$('.owl-carousel')).trigger('to.owl.carousel', selectedTab);
    }, 100);
  }

  rtcIndex = -1;

  rightClickOption(e, index) {
    const THIS = this;
    e.preventDefault();
    const target = $(e.target).closest('._custom_tab_tabs').find('.drop-menu-right._fixed');
    if (target.hasClass('open')) {
      $('.drop-menu-right').removeClass('open');
      target.css({
        top: e.clientY,
        left: e.clientX
      });
      setTimeout(function () {
        target.addClass('open');
        THIS.rtcIndex = index;
      }, 100);
    } else {
      target.addClass('open');
      target.css({
        top: e.clientY,
        left: e.clientX
      });
      THIS.rtcIndex = index;
    }
  }

  dropMenuRightToggle(e) {
    const container = $('._custom_tab_tabs_header_list');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $('.drop-menu-right').removeClass('open');
      this.rtcIndex = -1;
    }
  }

  closeTabRct(type) {
    if (type == 1) {
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      const idx = this.rtcIndex;
      this.tabs.splice(idx, 1);
      if (this.selectedTab == idx) {
        this.selectedTab = this.tabs.length - 1;
      } else {
        this.selectedTab = this.selectedTab - 1;
      }
      this.tabsService.setSelectedTab(this.selectedTab);
      this.slideUpdate(this.selectedTab, 1);
    } else if (type == 2) {
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      const idx = this.rtcIndex;
      const nTabs = this.tabs[idx];
      this.tabs.length = 0;
      this.tabs.push(nTabs);
      this.selectedTab = 0;
      this.tabsService.setSelectedTab(this.selectedTab);
      this.slideUpdate(this.selectedTab, 1);
    } else if (type == 3) {
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      const idx = this.rtcIndex;
      this.tabs.length = idx + 1;
      this.selectedTab = this.tabs.length - 1;
      this.tabsService.setSelectedTab(this.selectedTab);
      this.slideUpdate(this.selectedTab, 1);
    } else if (type == 4) {
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      const idx = this.rtcIndex;
      this.tabs.splice(0, idx);
      this.selectedTab = this.tabs.length - 1;
      this.tabsService.setSelectedTab(this.selectedTab);
      this.slideUpdate(this.selectedTab, 1);
    }
    $('.drop-menu-right').removeClass('open');
    this.keepCurrentTab();
  }

  getRangeLimitW() {
    $('#sort-tabs').width();
  }

  // ===========================
  // Global Search
  // ===========================
  glKeyword = '';

  update_glKeyword() {
    this.authService.update_glKeyword(this.glKeyword);
  }


  notLoading = 0;
  Notifications = null;
  NotificationSettings = null;
  NotificationCounter = 0;
  _setting_template = 1;
  _setting_template_not = 1;

  getAllUnreadNotification() {
    const THIS = this;
    this.authService.getAllUnreadNotification({}).subscribe(res => {
      if (res.status == 2000) {
        this.Notifications = res.data;
        this.NotificationCounter = 0;
        this.NotificationCounter = this.NotificationCounter + res.data.wo.length;
        this.NotificationCounter = this.NotificationCounter + res.data.invoice.length;
        this.NotificationCounter = this.NotificationCounter + res.data.reminder.length;
        this.runTooltip();
      }
      /*setTimeout(function () {
    if($('.app-header').length > 0){
        THIS.getAllUnreadNotification();
    }
}, 10000);*/
    });
  }

  getAllSettingsNotification() {
    const THIS = this;
    this.authService.getAllSettingsNotification({}).subscribe(res => {
      if (res.status == 2000) {
        if (res.data.wo == null) {
          res.data.wo = [];
          res.data.wo['is_active'] = 0;
        }
        if (res.data.invoice == null) {
          res.data.invoice = [];
          res.data.invoice['is_active'] = 0;
        }
        if (res.data.docNotify == null) {
          res.data.docNotify = [];
          res.data.docNotify['is_active'] = 0;
        }
        this.NotificationSettings = res.data;
      }
      /*setTimeout(function () {
    if($('.app-header').length > 0){
        THIS.getAllSettingsNotification();
    }
}, 1000);*/
    });
  }

  updateSettingsNotification(not_id, is_active) {
    const param = {
      'not_id': not_id,
      'is_active': is_active,
    };
    this.notLoading = 1;
    this.authService.updateSettingsNotification(param).subscribe(res => {
      this.notLoading = 0;
    });
  }

  makeSingleReadNotification(id) {
    this.notLoading = 1;
    this.authService.makeAllReadNotification({ids: [id]}).subscribe(res => {
      this.getAllUnreadNotification();
      this.notLoading = 0;
    });
  }

  makeSingleSeenNotification(id) {
    this.notLoading = 1;
    this.authService.makeAllSeenNotification({ids: [id]}).subscribe(res => {
      this.getAllUnreadNotification();
      this.notLoading = 0;
    });
  }

  makeGroupReadNotification(group) {
    const ids = [];
    $.each(group, function (i, v) {
      ids.push(v.id);
    });
    this.notLoading = 1;
    this.authService.makeAllReadNotification({ids: ids}).subscribe(res => {
      this.getAllUnreadNotification();
      this.notLoading = 0;
    });
  }

  makeGroupSeenNotification(group) {
    const ids = [];
    $.each(group, function (i, v) {
      ids.push(v.id);
    });
    this.notLoading = 1;
    this.authService.makeAllSeenNotification({ids: ids}).subscribe(res => {
      this.getAllUnreadNotification();
      this.notLoading = 0;
    });
  }

  makeDismissNotification() {
    this.notLoading = 1;
    this.authService.makeDismissNotification({}).subscribe(res => {
      this.getAllUnreadNotification();
      this.notLoading = 0;
    });
  }

  runTooltip() {
    const THIS = this;
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  openNot(not_id, info) {
    if (not_id == 1) {
      localStorage.WoPreId = info.uid;
      const THIS = this;

      const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      window.history.pushState('', '', '/' + Session.team + '/work-orders');
      THIS.tabsService.makeFirstTab('work-orders');
      THIS.CustomHelper.woRouteDiscard = 0;

      this.NewTabSwitch();
      const list = document.getElementById('sort-tabs');
      Sortable.create(list, {
        onEnd: function (e) {
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          THIS.tabs[e.oldIndex] = THIS.tabs.splice(e.newIndex, 1, THIS.tabs[e.oldIndex])[0];
          THIS.selectedTab = e.newIndex;
          THIS.slideUpdate(THIS.selectedTab, 1);
        },
      });
    } else if (not_id == 2) {
      localStorage.INPreId = info.uid;
      const THIS = this;

      const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      window.history.pushState('', '', '/' + Session.team + '/work-orders');
      THIS.tabsService.makeFirstTab('work-orders');
      THIS.CustomHelper.woRouteDiscard = 0;

      this.NewTabSwitch();
      const list = document.getElementById('sort-tabs');
      Sortable.create(list, {
        onEnd: function (e) {
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          THIS.tabs[e.oldIndex] = THIS.tabs.splice(e.newIndex, 1, THIS.tabs[e.oldIndex])[0];
          THIS.selectedTab = e.newIndex;
          THIS.slideUpdate(THIS.selectedTab, 1);
        },
      });
    }
  }

  makeReadDocNotification(id) {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'notifications/make/all/dismiss/doc?token=' + THIS.currentUser.token,
      type: 'post',
      data: {ids: [id]},
      success: function (res) {
        THIS.getAllUnreadNotification();
      }
    });
  }


  resetSubscription() {
    localStorage.resetSubscription = 1;
    this.switchView('account');
  }

  switchView(page: string, subPage = null) {
    this.presenceAlert();
    const THIS = this;
    this.CustomHelper.woRouteDiscard = 0;
    const index = THIS.CustomHelper.ConfRoute.map(function (e) {
      return e.tab;
    }).indexOf(THIS.CustomHelper.ConfCurRoute);
    if (index > -1) {
      // this.CustomHelper.woRouteDiscard = THIS.CustomHelper.ConfRoute[index].change;
    }
    if (subPage !== null) {
      localStorage.subPage = subPage;
    }
    if (this.CustomHelper.woRouteDiscard == 1) {
      swal({
        title: 'Are you sure you want to leave?',
        text: 'You have unsaved changes on this page. Click CANCEL to go back and save your changes. Otherwise, click LEAVE to discard your changes.',
        icon: 'warning',
        buttons: ['CANCEL', 'LEAVE'],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
          window.history.pushState('', '', '/' + Session.team + '/' + page);
          THIS.tabsService.makeFirstTab(page);

          THIS.CustomHelper.woRouteDiscard = 0;
          THIS.CustomHelper.ConfRoute.splice(index, 1);
        }
      });
    } else {
      const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      window.history.pushState('', '', '/' + Session.team + '/' + page);
      THIS.tabsService.makeFirstTab(page);
    }
  }

  presenceAlert() {
  }

  openFacilityReminder(facility) {
    localStorage.selectedFacility = JSON.stringify(facility);
    localStorage.selectedFacilityTemplate = 1;
    localStorage.selectedFacilityStep = 1;
    this.switchView('facilities');
    $('#previewNewFacility').click();
  }


  assetParam: any = {
    // for asset params
    name: '',
    asset_id: '',
    prefix: '',
    facility: '',
    media_files: '',
    attach_files: '',
    category_id: '',
    location: '',
    geo: '',
    brand: '',
    serial_number: '',
    purchase_cost: '',
    personnel: '',
    phone: '',
    email: '',
    contract_start: '',
    contract_end: '',
    notify: 0,
    notify_duration: '',
    depreciation_cost: '',
    eol: '',
    asset_life: '',
    depreciation_method: '',
    facility_id: ''
  };

  ngOnInit() {
    const THIS = this;
    this.APPCONFIG = APPCONFIG;
    const Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    $('#layOutVisual').on('click', function () {
      if ($('.app-header').length > 0) {
        THIS.getSessionUserInfo(1);
      }
    });
    $('#layOutVisual').on('click', '#sendMailModalTrigger', function () {
      THIS.openSendMailModal();
    });
    $('#layOutVisual').on('click', '#myProfileGo', function () {
      THIS.switchView('profile');
    });
    $('#layOutVisual').on('click', '#buySubscription', function () {
      THIS.Subscription = 1;
      THIS.SubscriptionStep = 2;
      THIS.getSubscriptionPlans();
    });
    $('#layOutVisual').on('click', '#createFacilityTrigger', function () {
      THIS.getAllFacility();
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#createFacilityModal')).modal('show');
    });
    $('#layOutVisual').on('click', '#editFacilityTrigger', function () {
      const facilityData = JSON.parse($('#editFacilityTrigger').attr('data-id'));
      const param = {id: facilityData.id};
      THIS.facilityService.getFacilitySingle(param).subscribe(res => {
        if (res.status == 2000) {
          THIS.paramEditFacility = {
            id: res.data.id,
            name: res.data.name,
            parent_id: THIS.CustomHelper.makeStr(res.data.parent_id),
            address: res.data.address,
            str_addr_1: res.data.str_addr_1,
            str_addr_2: res.data.str_addr_2,
            city: res.data.city,
            state: res.data.state,
            zip_code: res.data.zip_code,
            country: res.data.country,
            site_name: res.data.site_name,
            site_email: res.data.site_email,
            site_phone: res.data.site_phone,
            site_mobile: res.data.site_mobile
          };
        }
      });
      THIS.getAllFacility();
      let ___selectedTab = 0;
      const tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      const child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#EditFacilityModal')).modal('show');
    });

    document.addEventListener('visibilitychange', function () {
      if ($('.app-header').length > 0) {
        THIS.getSessionUserInfo(1);
      }
    });

    //===============
    // Pusher
    //===============
    Pusher.logToConsole = true;
    const pusher = new Pusher('501b3b237bd799758df0', {
      cluster: 'ap4'
    });
    const channel = pusher.subscribe('eq-fac-reminder');
    channel.bind('SendReminder', function (data) {
      if (data.message.receiver == Session.id) {
        const audio = <HTMLVideoElement>document.getElementById('notify_audio');
        audio.play();
        THIS.getAllUnreadNotification();
        Notification.requestPermission(function (permission) {
          if (permission === 'granted') {
            new Notification('Reminder: ' + data.message.attrs.title, {
              body: 'You have reminder notification from Facility: ' + data.message.facility.name
            });
          }
        });
      }
    });
    //===============
    // Pusher
    //===============


    this.dayRemainCal();
    this.getAllFacility();

    /*Start of Tawk.to Script*/

    (function () {
      const s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5f6ab8ab4704467e89f16ccc/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    /*End of Tawk.to Script*/
    this.chatService.getActiveChat.subscribe(ActiveChat => {
      this.ActiveChat = ActiveChat;
    });

    setTimeout(() => {
      THIS.help_center = THIS.currentUser.help_center;
      if (THIS.currentUser.help_center === 0) {
        THIS.welcomeModal();
      }
    }, 2000);
  }

  welcomeModal() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#welcomeModal')).modal('show');
  }

  help_center = 0;

  notShowHelp() {
    if (this.help_center > 0) {
      const THIS = this;
      const ldd = document.getElementById('helpCenterUpdate');
      const ladda = Ladda.create(ldd);
      ladda.start();
      $.ajax({
        url: THIS.APPCONFIG.apiAuth + 'updateMe?token=' + THIS.currentUser.token,
        type: 'post',
        data: {help_center: THIS.help_center},
        success: function (res) {
          ladda.stop();
          (<any>$('#welcomeModal')).modal('hide');
        }
      });
    }
  }

  passUpdate = {
    old_pass: '',
    password: '',
    con_pass: ''
  };

  changePassModal() {
    this.passUpdate = {
      old_pass: '',
      password: '',
      con_pass: ''
    };

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#changePassModal')).modal('show');


  }

  savePassSubmit() {
    const ldd = document.getElementById('passUpdate');
    const ladda = Ladda.create(ldd);
    if (this.passUpdate.old_pass == '' || this.passUpdate.password == '' || this.passUpdate.con_pass == '') {
      swal('Each field is required. Please fill up the form correctly');
      return true;
    }
    ladda.start();
    this.authService.updateMe(this.passUpdate).subscribe(res => {
      ladda.stop();
      if (res.status == 2000) {
        toastr.success('Password has been updated successfully', 'Password Update', this.toastrOption);
        (<any>$('#changePassModal')).modal('hide');
      } else {
        swal(res.msg);
        return true;
      }
    });
  }

  teamEdit = 0;
  DataSingle = null;

  selectedFiles = [];

  removeThisMedia(id, media_type) {
    const param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }


  uploadThisMedia(file, media_type, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }


  attachError = 0;

  selectTheseAttachment(e) {
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      const eachFile = accepted[i];
      if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
        THIS.uploadThisMedia(eachFile, '1', function (uploaded) {
          if (uploaded.status != undefined) {
            THIS.attachError = 1;
          } else {
            THIS.selectedFilesPreview.push(uploaded);
          }
        });
      } else {
        THIS.uploadThisMedia(eachFile, '4', function (uploaded) {
          if (uploaded.status != undefined) {
            THIS.attachError = 1;
          } else {
            THIS.selectedFilesPreview.push(uploaded);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  removeThisAttach(index, id, media_type) {
    this.selectedFilesPreview.splice(index, 1);
    this.removeThisMedia(id, media_type);
  }


  To = [];
  CC = [];
  BCC = [];
  MailInfo = null;

  openSendMailModal() {
    const THIS = this;
    const modal = <any>$('#sendMailModal');

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    setTimeout(function () {
      (<any>$('#sendWoMail')).summernote({
        placeholder: 'Write your mail here...',
        maxHeight: '900px',
        minHeight: '300px',
        toolbar: [
          ['style', ['style']],
          ['style', ['bold', 'underline', 'clear']],
          ['para', ['ul', 'ol', 'paragraph']]
        ]
      });

      const jsonStr = $('#sendMailModalTrigger').attr('data-type');
      if (jsonStr != '') {
        THIS.MailInfo = JSON.parse(jsonStr);
        if (THIS.MailInfo.type != undefined) {
          if (THIS.MailInfo.type == 'invc') {
            THIS.AttachName = 'Invoice.pdf';
            THIS.AttachPDFURL = THIS.APPCONFIG.apiAuth + 'preview/upload/pdf/invc?id=' + THIS.MailInfo.id;
          }
          if (THIS.MailInfo.type == 'mail') {
            THIS.To = THIS.MailInfo.to;
            THIS.selectedFilesPreview.length = 0;
            (<any>$('#sendWoMail')).summernote('code', THIS.MailInfo.msg);
          }
          if (THIS.MailInfo.type == 'inspection') {
            THIS.MailInfo.subject = 'Inspections - ' + THIS.MailInfo.subject;
            THIS.AttachName = 'Inspection.pdf';
            THIS.AttachPDFURL = THIS.APPCONFIG.apiInc + 'make/pdf/' + THIS.MailInfo.id;
          }
        } else {
          THIS.MailInfo.subject = 'Jobs - ' + THIS.MailInfo.subject;
          THIS.paramMailinfo.moduleId = THIS.MailInfo.id;
          THIS.AttachName = 'Work-Order.pdf';
          THIS.AttachPDFURL = THIS.APPCONFIG.apiAuth + 'preview/upload/pdf?id=' + THIS.MailInfo.id;
        }
      } else {
        THIS.MailInfo = {
          type: 'mail'
        };
      }
    }, 200);
  }

  selectable = true;
  removable = true;

  to_add(e): void {
    if (e.which == 13 || e.which == 32 || e.which == 188) {
      const email = e.target.value;
      if ((email || '').trim()) {
        this.To.push(email);
      }
      e.target.value = '';
    }
  }

  to_addOnChange(e): void {
    const email = e.target.value;
    if ((email || '').trim()) {
      this.To.push(email);
    }
    e.target.value = '';
  }

  to_remove(email): void {
    const index = this.To.indexOf(email);
    if (index > -1) {
      this.To.splice(index, 1);
    }
  }

  paramMailinfo = {
    to: [],
    cc: [],
    bcc: [],
    subject: '',
    message: '',
    attachmentWO: [],
    attachmentInvc: [],
    attachment: [],
    module: '',
    moduleId: 0
  };
  AttachName = '';
  AttachPDFURL = '';

  sendMail() {
    $('.mail-receiver-error').html('');
    const THIS = this;
    const ldd = document.getElementById('sendMail');
    const ladda = Ladda.create(ldd);
    this.paramMailinfo.to = this.To;
    this.paramMailinfo.cc = this.CC;
    this.paramMailinfo.bcc = this.BCC;
    this.paramMailinfo.subject = this.MailInfo.subject;
    if (THIS.MailInfo.type != undefined) {
      if (THIS.MailInfo.type == 'invc') {
        this.paramMailinfo.module = 'invc';
        this.paramMailinfo.attachmentInvc = [{
          id: this.MailInfo.id,
          uid: this.MailInfo.uid,
          team: this.MailInfo.team
        }];
      }
      if (THIS.MailInfo.type == 'mail') {
        if (THIS.selectedFilesPreview.length > 0) {
          THIS.paramMailinfo.attachment = THIS.selectedFilesPreview;
        }
      }
    } else {
      this.paramMailinfo.module = 'wo';
      this.paramMailinfo.attachmentWO = [{
        id: this.MailInfo.id,
        uid: this.MailInfo.uid,
        team: this.MailInfo.team,
        short_code: this.MailInfo.short_code
      }];
    }

    if (this.paramMailinfo.to.length > 0) {
      ladda.start();
      this.mediaService.sendMail(this.paramMailinfo).subscribe(res => {
        ladda.stop();
        if (res.status == 2000) {
          (<any>$('#sendMailModal')).modal('hide');
          this.To.length = 0;
          this.paramMailinfo.to.length = 0;
          this.paramMailinfo.cc.length = 0;
          this.paramMailinfo.bcc.length = 0;
          this.paramMailinfo.attachmentWO.length = 0;
          this.paramMailinfo.attachmentInvc.length = 0;
          this.paramMailinfo = {
            to: [],
            cc: [],
            bcc: [],
            subject: '',
            message: '',
            attachmentWO: [],
            attachmentInvc: [],
            attachment: [],
            module: '',
            moduleId: 0
          };
          toastr.success('Mail has been sent successfully', 'Send Mail', THIS.toastrOption);
          (<any>$('#sendMailModal')).modal('hide');
        }
      });
    } else {
      $('.mail-receiver-error').html('Please add a receiver email address');
      ladda.stop();
      // swal('Please add a receiver email address');
    }
  }

  discardMail() {
    const THIS = this;
    THIS.To.length = 0;
    THIS.paramMailinfo.to.length = 0;
    THIS.paramMailinfo.cc.length = 0;
    THIS.paramMailinfo.bcc.length = 0;
    THIS.paramMailinfo.attachmentWO.length = 0;
    THIS.paramMailinfo.attachmentInvc.length = 0;
    THIS.paramMailinfo = {
      to: [],
      cc: [],
      bcc: [],
      subject: '',
      message: '',
      attachmentWO: [],
      attachmentInvc: [],
      attachment: [],
      module: '',
      moduleId: 0
    };
    THIS.selectedFilesPreview = [];
    THIS.attachError = 0;
    (<any>$('#sendMailModal')).modal('hide');
  }

  selectedFilesPreview = [];


  openSetting = 1;

  OpenGlobalSettings(type) {

    if (window.innerWidth <= 1024) {
      return false;
    }
    this.openSetting = type;
    if (this.currentUser.user_type > 2) {
      this.openSetting = 3;
    }

    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#GlobalSettingsModal')).modal('show');
  }


  Storage = null;
  ShowAlert = 0;

  getStorage() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.apiAuth + 'media/storage?token=' + THIS.currentUser.token,
      type: 'get',
      success: function (res) {
        if (res.status == 2000) {
          THIS.Storage = res.storage;
          if (THIS.Storage.progress >= 90) {
            THIS.ShowAlert = 1;
          }
        }
      }
    });
  }

  feedbackBoard = 0;

  feedbackMouseUp() {
    this.feedbackBoard = 0;
    this.feedbackSuccess = 0;
  }

  feedback = '';
  feedbackSuccess = 0;

  sendFeedback() {
    const THIS = this;
    $('.show-title-error').html('');
    if (THIS.feedback === '') {
      $('.show-title-error').html('Title is required');
      return;
    }
    const ldd = document.getElementById('feedbackSend');
    const ladda = Ladda.create(ldd);
    const param = {
      feedback: THIS.feedback,
      url: location.pathname,
    };
    ladda.start();
    $.ajax({
      url: THIS.APPCONFIG.api + 'feedback/store?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        ladda.stop();
        if (res.status == 2000) {
          THIS.feedback = '';
          THIS.feedbackSuccess = 1;
        }
      }
    });
  }

  HelpCenterBox = 0;
  HelpCenterBoxContent = 0;
  HelpBox = [];
  HelpBoxSearch = [];
  HelpBoxSearching = 1;
  SelectedHelpBox = null;

  openHelpBoxPreview(art) {
    const THIS = this;
    this.supportKeyword = '';
    this.HelpBoxSearch = [];
    this.SelectedHelpBox = art;
    this.HelpCenterBoxContent = 1;
    $('#SelectedArticle').html('');
    this.getSupportSingle(art.id);
  }

  supportKeyword = '';

  filterSupport(): void {
    const THIS = this;
    clearInterval(THIS.CustomHelper.woListSearch);
    THIS.CustomHelper.woListSearch = setInterval(function () {
      clearInterval(THIS.CustomHelper.woListSearch);
      THIS.getSupportArticlesSearch();
    }, 400);
  }

  highlighter(subject) {
    if (subject == '' || subject == null) {
      return subject;
    } else {
      subject = this.CustomHelper.makeStr(subject);
    }
    const supportKeyword = this.supportKeyword.replace(/\)/g, '\\)');
    const matches = subject.match(new RegExp(supportKeyword, 'gi'));
    let res = '';
    $.each(matches, function (i, v) {
      res = subject.replace(v, '<strong class=\'text-warning-highlight\'>' + v + '</strong>');
    });
    if (res == '') {
      res = subject;
    }
    return res;
  }

  getSupportArticles() {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'support/getSupport?token=' + THIS.currentUser.token,
      type: 'post',
      data: {keyword: THIS.supportKeyword},
      success: function (res) {
        if (res.status == 2000) {
          THIS.HelpBox = res.data;
        }
      }
    });
  }

  getSupportSingle(id) {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'support/getSupport/single?token=' + THIS.currentUser.token,
      type: 'post',
      data: {id: id},
      success: function (res) {
        if (res.status == 2000) {
          THIS.supportKeyword = '';
          THIS.HelpBoxSearch = [];
          THIS.SelectedHelpBox = res.data;
          THIS.HelpCenterBoxContent = 1;
          setTimeout(function () {
            $('#SelectedArticle').html(res.data.description);
          });

        }
      }
    });
  }

  getSupportArticlesSearch() {
    const THIS = this;
    THIS.HelpBoxSearch = [];
    THIS.HelpBoxSearching = 1;
    $.ajax({
      url: THIS.APPCONFIG.api + 'support/getSupport?token=' + THIS.currentUser.token,
      type: 'post',
      data: {keyword: THIS.supportKeyword},
      success: function (res) {
        THIS.HelpBoxSearching = 0;
        if (res.status == 2000) {
          THIS.HelpBoxSearch = res.data;
        }
      }
    });
  }

  openHelpCenter() {
    this.HelpCenterBox = 1;
    this.HelpCenterBoxContent = 0;
    this.getSupportArticles();
    setTimeout(function () {
      new Draggable('HelpCenterBox');
    }, 500);
    setTimeout(function () {
      $('#HelpCenterBox').on('click', 'img', function () {
        let w = 0;
        let h = 0;
        const im = new Image;
        im.src = $(this).attr('src');
        im.onload = () => {
          w = im.width;
          h = im.height;
          if (w >= h && w <= 500) {
            w = w * 3;
            h = h * 3;
          } else if (h > w && h <= 500) {
            w = w * 3;
            h = h * 3;
          }
          const album = [
            {
              src: $(this).attr('src'),
              caption: '',
              thumb: '',
              w: w,
              h: h
            }
          ];
          const pswpElement = document.querySelectorAll('.pswp')[0];
          const options = {
            index: 0,
            shareEl: false,
            editEl: false,
            tapToClose: false,
            zoomEl: true,
            history: false
          };
          const Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, album, options);
          Gallery.init();
        };

      });
    }, 1000);

  }

  rateReason = '';

  rateThisArticle(ratings) {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'support/makeRating?token=' + THIS.currentUser.token,
      type: 'post',
      data: {note_id: THIS.SelectedHelpBox.id, ratings: ratings, reason: THIS.rateReason},
      success: function (res) {
        if (res.status == 2000) {
          THIS.rateReason = '';
          THIS.SelectedHelpBox.ratings = ratings;
          THIS.getSupportArticles();
        }
      }
    });
  }


  facilityData = [];
  facilityDataFF = [];
  paramFacility = {
    id: 0,
    name: '',
    parent_id: '0',
    address: '',
    str_addr_1: '',
    str_addr_2: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    site_name: '',
    site_email: '',
    site_phone: '',
    site_mobile: ''
  };
  paramEditFacility = {
    id: 0,
    name: '',
    parent_id: '0',
    address: '',
    str_addr_1: '',
    str_addr_2: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    site_name: '',
    site_email: '',
    site_phone: '',
    site_mobile: ''
  };

  getAllFacility() {
    const THIS = this;
    this.facilityService.getFacilitiesList().subscribe(res => {
      this.facilityData = [];
      this.facilityDataFF = [];
      $.each(res.facility, function (i, v) {
        v['pp'] = 0;
        THIS.facilityData.push(v);
        THIS.facilityDataFF.push(v);
        $.each(v.child, function (j, vC) {
          vC['pp'] = 1;
          THIS.facilityData.push(vC);
          THIS.facilityDataFF.push(vC);
          $.each(vC.child, function (k, vCC) {
            vCC['pp'] = 2;
            THIS.facilityData.push(vCC);
            THIS.facilityDataFF.push(vCC);
            $.each(vCC.child, function (l, vCCC) {
              vCCC['pp'] = 3;
              THIS.facilityData.push(vCCC);
            });
          });
        });
      });
      setTimeout(function () {
        (<any>$('.chosen-create-fac-single')).chosen('destroy');
        (<any>$('.chosen-create-fac-single')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Facility'
        }).on('change', function (e, p) {
          THIS.paramFacility.parent_id = e.target.value;
          THIS.getSingleFacility(THIS.paramFacility.parent_id);
        });

        (<any>$('.chosen-edit-fac-single')).chosen('destroy');
        (<any>$('.chosen-edit-fac-single')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Facility'
        }).on('change', function (e, p) {
          THIS.paramEditFacility.parent_id = e.target.value;
          THIS.getSingleFacility(THIS.paramEditFacility.parent_id);
        });

        (<any>$('.chosen-create-fac-single-modal')).chosen('destroy');
        (<any>$('.chosen-create-fac-single-modal')).val(THIS.assetParam.facility);
        (<any>$('.chosen-create-fac-single-modal')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Facility'
        }).on('change', function (e, p) {
          THIS.assetParam.facility = e.target.value;
        });
      }, 100);
      (<any>$('.reRenderFacility')).click();
    });
  }

  getSingleFacility(id) {
    const THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'facility/getSingle?token=' + THIS.currentUser.token,
      data: {id: id},
      type: 'post',
      success: function (res) {
        if (res.status === 2000) {
          THIS.paramFacility.address = res.data.address;
        }
      }
    });
  }

  handleAddressChange(place) {
    this.paramFacility.address = place.formatted_address;
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (addressType == 'street_number') {
        this.paramFacility.str_addr_1 = place.address_components[i]['short_name'];
      }
      if (addressType == 'route') {
        this.paramFacility.str_addr_2 = place.address_components[i]['long_name'];
      }
      if (addressType == 'locality') {
        this.paramFacility.city = place.address_components[i]['long_name'];
      }
      if (addressType == 'administrative_area_level_1') {
        this.paramFacility.state = place.address_components[i]['short_name'];
      }
      if (addressType == 'country') {
        this.paramFacility.country = place.address_components[i]['long_name'];
      }
      if (addressType == 'postal_code') {
        this.paramFacility.zip_code = place.address_components[i]['short_name'];
      }
    }
  }

  handleAddressChange2(place) {
    this.vendor_param.company_address = place.formatted_address;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    // Store latitude and longitude in vendor_param
    this.vendor_param.address_lat = JSON.stringify(lat);
    this.vendor_param.address_lon = JSON.stringify(lng);
    this.checkIfHasError();
  }

  handleAddressEditChange(place) {
    this.paramEditFacility.address = place.formatted_address;
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (addressType == 'street_number') {
        this.paramEditFacility.str_addr_1 = place.address_components[i]['short_name'];
      }
      if (addressType == 'route') {
        this.paramEditFacility.str_addr_2 = place.address_components[i]['long_name'];
      }
      if (addressType == 'locality') {
        this.paramEditFacility.city = place.address_components[i]['long_name'];
      }
      if (addressType == 'administrative_area_level_1') {
        this.paramEditFacility.state = place.address_components[i]['short_name'];
      }
      if (addressType == 'country') {
        this.paramEditFacility.country = place.address_components[i]['long_name'];
      }
      if (addressType == 'postal_code') {
        this.paramEditFacility.zip_code = place.address_components[i]['short_name'];
      }
    }
  }

  OpenNameRequiredModal(type: any) {
    if (type == 1) {
      (<any>$('#NameRequiredModal')).modal('show');
      (<any>$('#createFacilityModal')).modal('hide');
    } else {
      (<any>$('#NameRequiredModal')).modal('hide');
      (<any>$('#createFacilityModal')).modal('show');
    }
  }

  CloseFacility() {
    this.paramFacility = {
      id: 0,
      name: '',
      parent_id: '0',
      address: '',
      str_addr_1: '',
      str_addr_2: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      site_name: '',
      site_email: '',
      site_phone: '',
      site_mobile: ''
    };
    $('.show-facility-name-error').html('');
  }

  createFacility(type) {
    if (this.paramFacility.name == '') {
      $('.show-facility-name-error').html('Name field is required');
      return true;
    }
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      const freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        const freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    let ldd;
    if (type == 2) {
      ldd = document.getElementById('facCreateAnother');
    } else {
      ldd = document.getElementById('facCreate');
    }
    const ladda = Ladda.create(ldd);
    const form = $('#facCreateForm');
    if (this.paramFacility.name != '') {
      ladda.start();
      this.facilityService.createFacility(this.paramFacility).subscribe(res => {
        ladda.stop();
        if (type == 1) {
          (<any>$('#createFacilityModal')).modal('hide');
        }
        this.paramFacility = {
          id: 0,
          name: '',
          parent_id: '0',
          address: '',
          str_addr_1: '',
          str_addr_2: '',
          city: '',
          state: '',
          zip_code: '',
          country: '',
          site_name: '',
          site_email: '',
          site_phone: '',
          site_mobile: ''
        };
        this.getAllFacility();

        this.facilityService.refreshFacility();
        $('.reRenderFacility').attr('data-id', res.data.id);
        $('.reRenderFacility').click();
        $('.show-facility-name-error').html('');
      });
    } else {
      form.find('[data-el="name"]').removeClass('has-error').addClass('has-error');
    }
  }

  editFacility() {
    const THIS = this;
    $('#facEdit').addClass('d-none');
    $('#facEditLoading').removeClass('d-none');
    const ldd = document.getElementById('facEditLoading');
    const ladda = Ladda.create(ldd);
    const form = $('#facEditForm');
    if (this.paramEditFacility.name != '') {
      ladda.start();
      this.facilityService.editFacility(this.paramEditFacility).subscribe(res => {
        ladda.stop();
        $('#facEditLoading').addClass('d-none');
        $('#facEdit').removeClass('d-none');
        toastr.success('Facility has been successfully updated', 'Facility Updated', this.toastrOption);
        (<any>$('#EditFacilityModal')).modal('hide');
        this.paramEditFacility = {
          id: 0,
          name: '',
          parent_id: '0',
          address: '',
          str_addr_1: '',
          str_addr_2: '',
          city: '',
          state: '',
          zip_code: '',
          country: '',
          site_name: '',
          site_email: '',
          site_phone: '',
          site_mobile: ''
        };
        this.facilityService.refreshFacility();
      });
    } else {
      form.find('[data-el="name"]').removeClass('has-error').addClass('has-error');
    }
  }

  closeDocPreview() {
    const target = $('#docViewer');
    target.find('._iframe').attr('src', '');
  }

  downloadDocPreview() {
    const target = $('#docViewer');
    const str = target.find('._iframe').attr('src');
    let file_path = str.replace('https://docs.google.com/viewer?embedded=true&url=', '');
    file_path = this.APPCONFIG.apiAuth + 'preview/download/manager/' + btoa(file_path);
    const link = document.createElement('a');
    link.href = file_path;
    link.click();
  }

  downloadThisImage() {
    const target = $('img.pswp__img');
    let src = target.attr('src');
    const query = src.replace(this.APPCONFIG.uploadPreview + '/images?', '');
    const result = {img: ''};
    query.split('&').forEach(function (part) {
      const item = part.split('=');
      if (item[0] === 'img') {
        result.img = decodeURIComponent(item[1]);
      }
    });
    src = this.APPCONFIG.uploadPath + '/images/' + result.img;
    const file_path = this.APPCONFIG.apiAuth + 'preview/download/manager/' + btoa(src);
    const link = document.createElement('a');
    link.href = file_path;
    link.click();
  }


  SubscriptionFree = false;

  freeAccount() {
    const THIS = this;
    const param = {
      plan: 1,
    };
    $.ajax({
      url: THIS.APPCONFIG.api + 'subscription/freeAccountSubscription?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        if (res.status == 2000) {
          THIS.SubscriptionStep = 5;
          THIS.SubscriptionFree = true;
        }
      }
    });
  }

  freeAccountCheck = 0;
  freeAccountData = null;

  freeAccountModal() {
    const THIS = this;
    this.SubscriptionStep = 6;
    $.ajax({
      url: THIS.APPCONFIG.api + 'subscription/freeAccountLimitationCheck?token=' + THIS.currentUser.token,
      type: 'post',
      success: function (res) {
        if (res.status == 2000) {
          THIS.freeAccountData = res.data;
          localStorage.freeAccountData = JSON.stringify(res.data);
        }
      }
    });
  }

  freeAccountSave() {
    this.SubscriptionStep = 3;
  }

  makeUpgradeSubscription() {
    this.Subscription = 1;
    this.SubscriptionStep = 2;
    this.getSubscriptionPlans();
  }

  dayRemain = null;

  dayRemainCal() {
    const THIS = this;
    this.SubscriptionStep = 6;
    $.ajax({
      url: THIS.APPCONFIG.api + 'subscription/checkTrailLimit?token=' + THIS.currentUser.token,
      type: 'post',
      success: function (res) {
        if (res.status == 2000) {
          THIS.dayRemain = res.dayRemain;
        }
      }
    });
  }


  clearValidation(e) {
    const trigger = $(e.target);
    const target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  create() {
    const THIS = this;
    const ldd = document.getElementById('facCreate');
    const ladda = Ladda.create(ldd);
    const form = $('#facCreateForm');
    if (this.param.name != '') {
      ladda.start();
      this.facilityService.editFacility(this.param).subscribe(res => {
        ladda.stop();
        this.step = 2;
        this.facility = res;
        this.previewThisFacility();
        // Pusher emit
      });
    } else {
      const myDiv = document.getElementById('_thisScrool');
      myDiv.scrollTop = 0;
      form.find('[data-el="name"]').removeClass('has-error').addClass('has-error');
    }
  }

  previewThisFacility() {
    this.preview.emit(this.facility);
  }

  imp_assets() {
    $('#imp_assets').click();
  }

  imp_inv() {
    $('#imp_inv').click();
  }

  imp_WO() {
    $('#imp_WO').click();
  }

  importStep = 0;
  importModule = 0; // 1. Contact  2.Assets  3. Work Orders
  importFile: any;
  importFields = [];
  paramJson = [];
  Submit = 0;

  facility: any = null;
  param = {
    id: 0,
    name: '',
    parent_id: '0',
    address: '',
    str_addr_1: '',
    str_addr_2: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    site_name: '',
    site_email: '',
    site_phone: '',
    site_mobile: '',
  };

  parsedAssets: any;

  parseAssets(fileInput) {
    const THIS = this;
    const parsedAssets = fileInput.target.files[0];
    const extArr = parsedAssets.name.split('.');
    const ext = extArr[extArr.length - 1];
    parsedAssets['ext'] = ext;
    this.importFile = parsedAssets;
    if (ext.toLowerCase() == 'csv') {
      const reader: FileReader = new FileReader();
      reader.readAsText(parsedAssets);
      reader.onload = (e) => {
        const csv: string = reader.result;
        const allTextLines = csv.split(/\r|\n|\r/);
        const headers = allTextLines[0].split(',');
        const lines = [];
        for (let i = 0; i < allTextLines.length; i++) {
          // split content based on comma
          const data = allTextLines[i].split(',');
          if (data.length === headers.length) {
            const tarr = [];
            for (let j = 0; j < headers.length; j++) {
              tarr.push(data[j]);
            }
            lines.push(tarr);
          }
        }
        THIS.parsedAssets = lines;
        THIS.importStep = 1;
        THIS.importModule = 2;
        console.log(THIS.parsedAssets);
      };
    } else if (ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx') {
      const target: DataTransfer = <DataTransfer>(fileInput.target);
      if (target.files.length !== 1) {
        throw new Error('Cannot use multiple files');
      }
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws, {header: 1});
        THIS.parsedAssets = data;
        THIS.importStep = 1;
        THIS.importModule = 2;
        console.log(THIS.parsedAssets);
      };
      reader.readAsBinaryString(target.files[0]);
    } else {
      // Invalid file
    }
  }

  AssetsFields = [
    {name: 'name', title: 'Asset Name', map: '-1'},
    {name: 'category', title: 'Category', map: '-1'},
    {name: 'location', title: 'Location', map: '-1'},
    {name: 'brand', title: 'Brand/Model', map: '-1'},
    {name: 'serial_number', title: 'Serial Number', map: '-1'},
    {name: 'purchase_cost', title: 'Purchase Cost', map: '-1'},
    {name: 'geo', title: 'Geo Location (Address or Coordinates)', map: '-1'},
    {name: 'depreciation_cost', title: 'Commissioning Cost', map: '-1'},
    {name: 'eol', title: 'End of life Value', map: '-1'},
    {name: 'asset_life', title: 'Asset Life (Months)', map: '-1'},
  ];

  makeMappedAssets() {
    const THIS = this;
    this.importFields = this.parsedAssets[0];
    this.importStep = 2;
    $.each(THIS.AssetsFields, function (i, v) {
      $.each(THIS.importFields, function (j, k) {
        if (k.match(new RegExp(v.title, 'gi'))) {
          THIS.AssetsFields[i].map = j.toString();
        } else if (v.title.match(new RegExp(k, 'gi'))) {
          THIS.AssetsFields[i].map = j.toString();
        }
      });
    });
  }

  makeParamAssets() {
    const THIS = this;
    const Fac_ID = $('#openImportModal').attr('data-id');
    const paramJson = [];
    $.each(THIS.parsedAssets, function (i, v) {
      if (i != '0') {
        const param = {
          facility: Fac_ID,
          name: v[THIS.AssetsFields[0].map] == undefined ? '' : v[THIS.AssetsFields[0].map],
          category: v[THIS.AssetsFields[1].map] == undefined ? '' : v[THIS.AssetsFields[1].map],
          location: v[THIS.AssetsFields[2].map] == undefined ? '' : v[THIS.AssetsFields[2].map],
          brand: v[THIS.AssetsFields[3].map] == undefined ? '' : v[THIS.AssetsFields[3].map],
          serial_number: v[THIS.AssetsFields[4].map] == undefined ? '' : v[THIS.AssetsFields[4].map],
          purchase_cost: v[THIS.AssetsFields[5].map] == undefined ? '' : v[THIS.AssetsFields[5].map],
          geo: v[THIS.AssetsFields[6].map] == undefined ? '' : v[THIS.AssetsFields[6].map],
          depreciation_cost: v[THIS.AssetsFields[7].map] == undefined ? '' : v[THIS.AssetsFields[7].map],
          eol: v[THIS.AssetsFields[8].map] == undefined ? '' : v[THIS.AssetsFields[8].map],
          asset_life: v[THIS.AssetsFields[9].map] == undefined ? '' : v[THIS.AssetsFields[9].map]
        };
        if (param.name != '') {
          paramJson.push(param);
        }
      }
    });
    this.paramJson = paramJson;
    console.log(this.paramJson);
    this.Submit = 1;
    if (this.paramJson.length > 1000) {
      swal({
        title: 'Import Limitation',
        text: 'Maximum allowed rows is 1000. If you continue, only the first 1000 rows will be imported?',
        icon: 'warning',
        buttons: ['Cancel', 'Continue'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          THIS.paramJson.length = 1000;
          THIS.assetService.createLot({assets: this.paramJson}).subscribe(res => {
            THIS.importStep = 3;
            THIS.facilityService.refreshFacility();
            THIS.Submit = 0;
            // Pusher emit
          });
        } else {
          THIS.Submit = 0;
          swal.stopLoading();
          swal.close();
        }
      });
    } else {
      THIS.Submit = 1;
      THIS.assetService.createLot({assets: this.paramJson}).subscribe(res => {
        THIS.Submit = 0;
        THIS.importStep = 3;
        this.facilityService.refreshFacility();
        // Pusher emit
      });
    }
  }


  parsedInv: any;

  parseInv(fileInput) {
    const THIS = this;
    const parsedAssets = fileInput.target.files[0];
    const extArr = parsedAssets.name.split('.');
    const ext = extArr[extArr.length - 1];
    parsedAssets['ext'] = ext;
    this.importFile = parsedAssets;
    if (ext.toLowerCase() == 'csv') {
      const reader: FileReader = new FileReader();
      reader.readAsText(parsedAssets);
      reader.onload = (e) => {
        const csv: string = reader.result;
        const allTextLines = csv.split(/\r|\n|\r/);
        const headers = allTextLines[0].split(',');
        const lines = [];
        for (let i = 0; i < allTextLines.length; i++) {
          // split content based on comma
          const data = allTextLines[i].split(',');
          if (data.length === headers.length) {
            const tarr = [];
            for (let j = 0; j < headers.length; j++) {
              tarr.push(data[j]);
            }
            lines.push(tarr);
          }
        }
        THIS.parsedAssets = lines;
        THIS.importStep = 1;
        THIS.importModule = 2;
      };
    } else if (ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx') {
      const target: DataTransfer = <DataTransfer>(fileInput.target);
      if (target.files.length !== 1) {
        throw new Error('Cannot use multiple files');
      }
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws, {header: 1});
        console.log(data);
        THIS.parsedInv = data;
        THIS.importStep = 1;
        THIS.importModule = 4;
      };
      reader.readAsBinaryString(target.files[0]);
    } else {
      // Invalid file
    }
  }

  invFields = [
    {name: 'title', title: 'Item Name', map: '-1'},
    {name: 'qty', title: 'Quantity', map: '-1'},
    {name: 'purchase_cost', title: 'Purchase Cost', map: '-1'},
    {name: 'sell_price', title: 'Sell Price', map: '-1'}
  ];

  makeMappedInv() {
    const THIS = this;
    this.importFields = this.parsedInv[0];
    this.importStep = 2;
    $.each(THIS.invFields, function (i, v) {
      $.each(THIS.importFields, function (j, k) {
        if (k.match(new RegExp(v.title, 'gi'))) {
          THIS.invFields[i].map = j.toString();
        } else if (v.title.match(new RegExp(k, 'gi'))) {
          THIS.invFields[i].map = j.toString();
        }
      });
    });
  }

  makeParamInv() {
    const THIS = this;
    const Fac_ID = $('#openImportModal').attr('data-id');
    const paramJson = [];
    $.each(THIS.parsedInv, function (i, v) {
      if (i != '0') {
        const param = {
          facility_id: Fac_ID,
          title: v[THIS.invFields[0].map] == undefined ? '' : v[THIS.invFields[0].map],
          qty: v[THIS.invFields[1].map] == undefined ? '' : v[THIS.invFields[1].map],
          purchase_cost: v[THIS.invFields[2].map] == undefined ? '' : v[THIS.invFields[2].map],
          sell_price: v[THIS.invFields[3].map] == undefined ? '' : v[THIS.invFields[3].map]
        };
        if (param.title != '') {
          paramJson.push(param);
        }
      }
    });
    this.paramJson = paramJson;
    THIS.Submit = 1;
    if (this.paramJson.length > 1000) {
      swal({
        title: 'Import Limitation',
        text: 'Maximum allowed rows is 1000. If you continue, only the first 1000 rows will be imported?',
        icon: 'warning',
        buttons: ['Cancel', 'Continue'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          THIS.paramJson.length = 1000;
          THIS.inventoriesService.createInventoryLot({inv: this.paramJson}).subscribe(res => {
            THIS.importStep = 3;
            THIS.Submit = 0;
          });
        } else {
          THIS.Submit = 0;
          swal.stopLoading();
          swal.close();
        }
      });
    } else {
      THIS.Submit = 1;
      THIS.inventoriesService.createInventoryLot({inv: this.paramJson}).subscribe(res => {
        THIS.Submit = 0;
        THIS.importStep = 3;
      });
    }
  }

  closeImportModal() {
    (<any>$('#openImportModal')).modal('hide');
  }

  parsedWO: any;

  parseWO(fileInput) {
    const THIS = this;
    const parsedWO = fileInput.target.files[0];
    const extArr = parsedWO.name.split('.');
    const ext = extArr[extArr.length - 1];
    parsedWO['ext'] = ext;
    this.importFile = parsedWO;
    if (ext.toLowerCase() == 'csv') {
      const reader: FileReader = new FileReader();
      reader.readAsText(parsedWO);
      reader.onload = (e) => {
        const csv: string = reader.result;
        const allTextLines = csv.split(/\r|\n|\r/);
        const headers = allTextLines[0].split(',');
        const lines = [];
        for (let i = 0; i < allTextLines.length; i++) {
          // split content based on comma
          const data = allTextLines[i].split(',');
          if (data.length === headers.length) {
            const tarr = [];
            for (let j = 0; j < headers.length; j++) {
              tarr.push(data[j]);
            }
            lines.push(tarr);
          }
        }
        THIS.parsedWO = lines;
        THIS.importStep = 1;
        THIS.importModule = 3;
      };
    } else if (ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx') {
      const target: DataTransfer = <DataTransfer>(fileInput.target);
      if (target.files.length !== 1) {
        throw new Error('Cannot use multiple files');
      }
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws, {header: 1});
        THIS.parsedWO = data;
        THIS.importStep = 1;
        THIS.importModule = 3;
      };
      reader.readAsBinaryString(target.files[0]);
    } else {
      // Invalid file
    }


  }

  WOFields = [
    {name: 'status_code', title: 'Job Status', map: '-1'},
    {name: 'job_type', title: 'Job Type', map: '-1'},
    {name: 'created_at', title: 'Start Date', map: '-1'},
    {name: 'due', title: 'Due Date', map: '-1'},
    {name: 'subject', title: 'Title', map: '-1'},
    {name: 'description', title: 'Description', map: '-1'},
    {name: 'asset', title: 'Asset', map: '-1'},
  ];

  makeMappedWO() {
    const THIS = this;
    this.importFields = this.parsedWO[0];
    this.importStep = 2;
    $.each(THIS.WOFields, function (i, v) {
      $.each(THIS.importFields, function (j, k) {
        if (k.match(new RegExp(v.title, 'gi'))) {
          THIS.WOFields[i].map = j.toString();
        } else if (v.title.match(new RegExp(k, 'gi'))) {
          THIS.WOFields[i].map = j.toString();
        }
      });
    });
    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 500);
  }

  makeParamWO() {
    const THIS = this;
    const Fac_ID = $('#openImportModal').attr('data-id');
    const paramJson = [];
    $.each(THIS.parsedWO, function (i, v) {
      if (i != '0') {
        const param = {
          facility_id: Fac_ID,
          status_code: v[THIS.WOFields[0].map] == undefined ? '' : v[THIS.WOFields[0].map],
          job_type: v[THIS.WOFields[1].map] == undefined ? '' : v[THIS.WOFields[1].map],
          created_at: v[THIS.WOFields[2].map] == undefined ? '' : v[THIS.WOFields[2].map],
          due: v[THIS.WOFields[3].map] == undefined ? '' : v[THIS.WOFields[3].map],
          subject: v[THIS.WOFields[4].map] == undefined ? '' : v[THIS.WOFields[4].map],
          description: v[THIS.WOFields[5].map] == undefined ? '' : v[THIS.WOFields[5].map],
          asset: v[THIS.WOFields[6].map] == undefined ? '' : v[THIS.WOFields[6].map]
        };
        if (param.subject != '') {
          param.created_at = THIS.CustomHelper.formatDateTimeToDB(THIS.CustomHelper.invToDate(param.created_at));
          param.due = THIS.CustomHelper.formatDateTimeToDB(THIS.CustomHelper.invToDate(param.due));
          paramJson.push(param);
        }
      }
    });
    this.paramJson = paramJson;
    if (this.paramJson.length > 1000) {
      swal({
        title: 'Import Limitation',
        text: 'Maximum allowed rows is 1000. If you continue, only the first 1000 rows will be imported?',
        icon: 'warning',
        buttons: ['Cancel', 'Continue'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.paramJson.length = 1000;
          this.workOrderService.createWorkOrderLot({wo: this.paramJson}).subscribe(res => {
            if (res.status != undefined && res.status == 3000) {
              swal({
                title: 'Work Order',
                text: 'You have reached your free plan limit. To create more work orders, please subscribe to the Pro plan from your account page.',
                icon: 'warning',
                dangerMode: true,
                className: 'swal-btn-center'
              });
              return true;
            }

            this.importStep = 3;
            this.facilityService.refreshFacility();
            // Pusher emit
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
    } else {
      this.Submit = 1;
      this.workOrderService.createWorkOrderLot({wo: this.paramJson}).subscribe(res => {
        if (res.status != undefined && res.status == 3000) {
          swal({
            title: 'Work Order',
            text: 'You have reached your free plan limit. To create more work orders, please subscribe to the Pro plan from your account page.',
            icon: 'warning',
            dangerMode: true,
            className: 'swal-btn-center'
          });
          return true;
        }

        this.Submit = 0;
        this.importStep = 3;
        this.facilityService.refreshFacility();
        // Pusher emit
      });
    }
  }


  createJS = 0;
  jobStatus = [];
  createJobStatus = {
    name: '',
    submitted: 0
  };

  createNewJobStatus() {
    const THIS = this;
    if (this.createJobStatus.name != '') {
      this.createJobStatus.submitted = 1;
      const param = {name: this.createJobStatus.name};
      const ldd = document.getElementById('JSCreate');
      const ladda = Ladda.create(ldd);
      ladda.start();
      THIS.job_statusService.createJobStatus(param).subscribe(res => {
        ladda.stop();
        THIS.createJobStatus.name = '';
        THIS.createJobStatus.submitted = 0;
        // Pusher emit
        $('.reRenderJobStatus').attr('data-id', res.data.id);
        $('.reRenderJobStatus').click();
        (<any>$('#jobStatusModal')).modal('hide');

      });
    }
  }


  createJT = 0;
  jobTypes = [];
  createJobType = {
    name: '',
    submitted: 0
  };

  createNewJobType() {
    const THIS = this;
    if (this.createJobType.name != '' && this.createJobType.submitted == 0) {
      this.createJobType.submitted = 1;
      const param = {name: this.createJobType.name};
      const ldd = document.getElementById('jtCreate');
      const ladda = Ladda.create(ldd);
      ladda.start();
      this.job_typesService.createJobType(param).subscribe(res => {
        ladda.stop();
        this.createJobType.submitted = 0;
        this.createJobType.name = '';
        // Pusher emit
        (<any>$('#JobTypesModal')).modal('hide');
        $('.reRenderJobType').attr('data-id', res.id);
        $('.reRenderJobType').click();
      });
    }
  }


  validForm = 0;

  saveAssetSubmit() {
    const THIS = this;
    const valid = this.validationThisForm();
    if (valid == 1) {
      const THIS = this;
      const ldd = document.getElementById('astCreate');
      const ladda = Ladda.create(ldd);
      ladda.start();
      this.assetService.createAsset(this.assetParam).subscribe(res => {
        ladda.stop();
        if (res.status == 7000) {
          this.coolDialogs.alert('The Asset ID is already been taken. Please use a unique one', {
            title: 'Form Validation',
            color: '#e0e0e0',
          });
        }
        if (res.status == 2000) {
          this.assetParam.facility = '';
          this.assetParam.facility_id = '';
          this.assetParam.name = '';
          $('.reRenderAssets').attr('data-id', res.data[0].id);
          $('.reRenderAssets').click();
          this.preview.emit(res.data[0]);
          toastr.success('New asset has been successfully created', 'New Asset', this.toastrOption);
          // Pusher emit
          (<any>$('#assetModal')).modal('hide');
          (<any>$('.chosen-create-fac-single-modal')).chosen('destroy');
          (<any>$('.chosen-create-fac-single-modal')).val('');
          (<any>$('.chosen-create-fac-single-modal')).chosen({
            allow_single_deselect: true,
            placeholder_text_single: 'Select Facility'
          }).on('change', function (e, p) {
            THIS.assetParam.facility = e.target.value;
          });
        }
      });
    }
  }

  validationThisForm() {
    let valid = 1;
    const form = $('#workCreateForm');

    if (this.assetParam.facility == '') {
      valid = 0;
      form.find('[data-el="facility"]').removeClass('has-error').addClass('has-error');
      $('.show-facility-error').html('Facility is required');
    } else {
      form.find('[data-el="facility"]').removeClass('has-error');
      $('.show-facility-error').html('');
    }

    if (this.assetParam.name == '') {
      valid = 0;
      form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
      $('.show-name-error').html('Name is required');
    } else {
      form.find('[data-el="subject"]').removeClass('has-error');
      $('.show-name-error').html('');
    }
    this.validForm = valid;
    return valid;
  }

  param_person_name = '';
  paramPerson = {
    avatar: '',
    first_name: '',
    last_name: '',
    user_type: 3,
    email: '',
    phone: '',
    address: '',
    hourly_rate: '',
    group_id: [],
    licenced: 0,
    abn: '',
  };

  assetModalReset() {
    (<any>$('.chosen-create-fac-single-modal')).chosen('destroy');
    (<any>$('.chosen-create-fac-single-modal')).val('');
    (<any>$('.chosen-create-fac-single-modal')).chosen({
      allow_single_deselect: true,
      placeholder_text_single: 'Select Facility'
    }).on('change', function (e, p) {
      this.assetParam.facility = e.target.value;
    });
    this.assetParam.name = '';
    $('.show-facility-error').html('');
    $('.show-name-error').html('');
  }

  validPersonForm: any = 0;

  savePersonSubmit() {
    if (this.currentUser.subscription.status === 0 && this.currentUser.subscription.plan === '') {
      const freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        const freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    const ldd = document.getElementById('crtPersonQuick');
    const ladda = Ladda.create(ldd);
    const THIS = this;
    const param_person_name_arr = this.param_person_name.split(' ');
    this.paramPerson.first_name = param_person_name_arr[0] !== undefined ? param_person_name_arr[0] : '';
    this.paramPerson.last_name = param_person_name_arr[1] !== undefined ? param_person_name_arr[1] : '';
    const valid = this.validationPersonForm();
    if (valid === 1) {
      ladda.start();
      this.userService.createPersonnel(this.paramPerson).subscribe(res => {
        ladda.stop();
        if (res.status !== undefined && res.status === 3000) {
          swal({
            title: 'Create User',
            text: 'You have reached your free plan limit. To create more user, please subscribe to the Pro plan from your account page.',
            icon: 'warning',
            dangerMode: true,
            className: 'swal-btn-center'
          });
        } else if (res.status === 2000) {
          toastr.success('New personnel has been successfully created', 'New personnel', THIS.toastrOption);
          (<any>$('#PersonQuickModal')).modal('hide');
          console.log(12434);
          (<any>$('.reRenderPerson')).attr('data-id', res.data.id);
          (<any>$('.reRenderPerson')).click();
          this.param_person_name = '';
          this.paramPerson.avatar = '';
          this.paramPerson.first_name = '';
          this.paramPerson.last_name = '';
          this.paramPerson.user_type = 3;
          this.paramPerson.email = '';
          this.paramPerson.phone = '';
          this.paramPerson.address = '';
          this.paramPerson.hourly_rate = '';
          this.paramPerson.group_id = [];
          this.paramPerson.licenced = 0;
          this.paramPerson.abn = '';

        } else if (res.status === 7000) {
          $('.show-email-error').html(res.msg);
        } else {
          swal({
            text: res.error,
            icon: 'error',
            dangerMode: true,
            className: 'swal-btn-center'
          });
          console.log(res.error);
        }
      });
    }
  }

  validationPersonForm() {
    let valid = 1;
    $('.invalid-feedback').html('');
    this.checkPersonForm();
    if (this.paramPerson.first_name == '') {
      valid = 0;
      $('.show-name-error').html('Name is required');
      return valid;
    }
    if (this.paramPerson.email == '') {
      valid = 0;
      $('.show-email-error').html('Email address is required');
      return valid;
    } else {
      const check = this.CustomHelper.emailValidation(this.paramPerson.email);
      if (!check) {
        $('.show-email-error').html(this.paramPerson.email + '- is not a valid email address. Please type valid email address.',);
        valid = 0;
        return valid;
      }
    }
    this.validPersonForm = valid;
    return valid;
  }

  personModalReset() {
    this.param_person_name = '';
    this.paramPerson = {
      avatar: '',
      first_name: '',
      last_name: '',
      user_type: 3,
      email: '',
      phone: '',
      address: '',
      hourly_rate: '',
      group_id: [],
      licenced: 0,
      abn: '',
    };
    $('.show-name-error').html('');
    $('.show-email-error').html('');
  }

  checkPersonForm() {
    const param_name_arr = this.param_person_name.split(' ');
    this.paramPerson.first_name = param_name_arr[0] != undefined ? param_name_arr[0] : '';
    this.paramPerson.last_name = param_name_arr[1] != undefined ? param_name_arr[1] : '';
    let valid = 1;
    if (this.paramPerson.first_name == '') {
      valid = 0;
    }
    if (this.paramPerson.email == '') {
      valid = 0;
    }
    this.validPersonForm = valid;
    return valid;
  }

  inviteChatEmail = '';

  startMsg() {
    const THIS = this;
    $('.email-error').html('');
    $.ajax({
      url: THIS.APPCONFIG.apiMsg + 'add/thread?token=' + THIS.currentUser.token,
      type: 'post',
      data: {email: THIS.inviteChatEmail},
      success: function (res) {
        THIS.inviteChatEmail = '';
        if (res.status == 2000) {
          (<any>$('#newThreadModal')).modal('hide');
          THIS.addNewChat(res.data);
        } else {
          // swal({
          //     title: 'New Chat Invitation',
          //     text: res.error.email,
          //     icon: "error",
          //     dangerMode: true,
          //     className: 'swal-btn-center'
          // });
          //
          // swal({
          //     title: "New Chat Invitation",
          //     text: res.error.email[0],
          //     icon: "error",
          //     dangerMode: true,
          //     className: 'swal-btn-center'
          // });
          $('.email-error').html(res.error.email[0]);
        }
      }
    });
  }

  ActiveChat = [];

  addNewChat(user) {
    const index = this.ActiveChat.map(function (e) {
      return e.id;
    }).indexOf(user.id);
    if (index == -1) {
      if (this.ActiveChat.length > 2) {
        this.ActiveChat.length = 2;
      }
      this.ActiveChat.push(user);
      this.chatService.updateActiveChat(this.ActiveChat);
    }
  }

  currencyIcon(): string {
    if (this.currentUser.settings.currency == 'USD') {
      return 'USD$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }


  submitVendor() {
    const THIS = this;
    THIS.VendorErrors = {};
    const ldd = document.getElementById('updateSubmit');
    const ladda = Ladda.create(ldd);
    ladda.start();

    /* Validate data */
    if (THIS.validateData()) {
      /*Sanitize info*/
      if (THIS.sanitizeInfo()) {
        $.ajax({
          url: this.APPCONFIG.apiAuth + 'verify-vendor-apply?token=' + THIS.currentUser.token,
          type: 'post',
          data: JSON.stringify(THIS.sanitizedVendorParam),
          contentType: 'application/json',
          success: function (res) {
            ladda.stop();
            if (res.status === 2000) {
              THIS.closeSignupVendorModal();
              toastr.success('Signup as vendor has been successfully submitted', 'Vendor Submission', THIS.toastrOption);
              THIS.restore();
            }
          },
          error: function (err) {
            ladda.stop();
            console.error('Error:', err);
          }
        });
      } else {
        ladda.stop();
      }
    } else {
      ladda.stop();
      console.log(this.VendorErrors);
    }
  }

  sanitizeInfo() {
    this.sanitizedVendorParam = {
      vendor_info: [],
      verified_vendor: 1,
      address: '',
      address_lat: '',
      address_lon: '',
      operation_radius: '',
    };
    this.sanitizedVendorParam.operation_radius = this.vendor_param.operation_radius;

    if (this.vendor_param.company_address != null && this.vendor_param.company_address != '') {
      this.sanitizedVendorParam.address = this.vendor_param.company_address;
      this.sanitizedVendorParam.address_lat = this.vendor_param.address_lat;
      this.sanitizedVendorParam.address_lon = this.vendor_param.address_lon;
      this.sanitizedVendorParam.vendor_info.push(
        {
          info_name: 'address',
          info_value: this.vendor_param.company_address,
          expiry: null,
          info_doc: null,
        },
      );
    }


    if (this.vendor_param.industry != null && this.vendor_param.industry != '') {
      this.sanitizedVendorParam.vendor_info.push(
        {
          info_name: 'industry',
          info_value: this.vendor_param.industry,
          expiry: null,
          info_doc: null,
        },
      );
    }

    this.vendor_param.verify_doc.forEach((v) => {
      if (v['info_value'] != null && v['info_value'] != '') {
        this.sanitizedVendorParam.vendor_info.push({
          info_name: v['info_name'],
          info_value: v['info_value'],
          expiry: v['expiry'],
          info_doc: v['info_doc'],
        });
      }
    });

    return true;
  }

  validateData() {
    let validate = true;

    if (this.vendor_param.company_logo == null || this.vendor_param.company_logo == '') {
      this.VendorErrors['company_logo'] = 'Company logo is required';
      validate = false;
    } else {
      this.VendorErrors['company_logo'] = null;
    }


    if (this.vendor_param.operation_radius == null || this.vendor_param.operation_radius == '') {
      this.VendorErrors['operation_radius'] = 'Operation radius is required';
      validate = false;
    } else {
      this.VendorErrors['operation_radius'] = null;
    }

    if (this.vendor_param.industry == null || this.vendor_param.industry == '') {
      this.VendorErrors['industry'] = 'Industry is required';
      validate = false;
    } else {
      this.VendorErrors['industry'] = null;
    }


    if (this.vendor_param.company_address == null || this.vendor_param.company_address == '') {
      this.VendorErrors['company_address'] = 'Address is required';
      validate = false;
    } else {
      this.VendorErrors['company_address'] = null;
    }

    if (this.vendor_param.verify_doc.length == 0) {
      this.VendorErrors['verify_doc'] = 'Vendor info is required';
      validate = false;
    } else {
      this.VendorErrors['verify_doc'] = null;

      this.vendor_param.verify_doc.forEach((v, i) => {
        this.VendorErrors['document_' + i] = '';
        if (v['required'] && v['document_required']) {
          if (v['info_name'] == null) {
            this.VendorErrors['document_' + i] = this.VendorErrors['document_' + i] + ' &nbsp; Name field is required. ';
            validate = false;
          }
          if (v['info_value'] == null) {
            this.VendorErrors['document_' + i] = this.VendorErrors['document_' + i] + '&nbsp; Number field is required.';
            validate = false;
          }
          if (v['info_doc'] == null || v['info_doc'] == '') {
            this.VendorErrors['document_' + i] = this.VendorErrors['document_' + i] + '&nbsp; Document field is required.';
            validate = false;
          }
          if (v['expiry'] == null || v['expiry'] == '') {
            this.VendorErrors['document_' + i] = this.VendorErrors['document_' + i] + '&nbsp; Expiry field is required.';
            validate = false;
          }
        }
      });

    }
    console.log(this.VendorErrors);
    return validate;
  }


  upload(e, type) {

    e.preventDefault();
    const THIS = this;
    if (type == 'company-logo') {
      THIS.companyLoading = true;
    } else {
      THIS.galleryLoading = true;
    }
    const accepted = e.target.files;
    const eachFile = accepted[0];
    if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
      this.uploadThisMedia(eachFile, '1', function (uploaded) {
        if (type == 'company-logo') {
          THIS.companyLoading = false;
        } else {
          THIS.galleryLoading = false;
        }
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please delete some files',
              icon: 'error',
              dangerMode: true,
            });

          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Your request is not valid or there is something wrong.Please try again',
              icon: 'error',
              dangerMode: true,
            });
          }
          if (type == 'company-logo') {
            THIS.companyLoading = false;
          } else {
            THIS.galleryLoading = false;
          }
        } else {
          if (type == 'company-logo') {
            THIS.companyLogo(uploaded.file_path);
            THIS.companyLoading = false;
          } else {
            THIS.galleryUpdate(uploaded.file_path);
            THIS.vendor_param.gallery.push({id: uploaded.id, path: uploaded.file_path});
            THIS.galleryLoading = false;
          }
          THIS.checkIfHasError();
        }
      });
    } else {
      swal('This file is not supported for avatar. Please select jpg/png/gif file.');
      if (type == 'company-logo') {
        THIS.companyLoading = false;
      } else {
        THIS.galleryLoading = false;
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
    setTimeout(() => {
      if (type == 'company-logo') {
        THIS.companyLoading = false;
      } else {
        THIS.galleryLoading = false;
      }
    }, 5000);
    this.checkIfHasError();
  }


  companyLogo(logo: any) {
    const THIS = this;
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'company-logo-update?token=' + THIS.currentUser.token,
      type: 'post',
      data: JSON.stringify({logo: logo}),
      contentType: 'application/json',
      success: function (res) {
        if (res.status === 2000) {
          THIS.vendor_param.company_logo = res.data.company_logo;
        }
      },
      error: function (err) {
        console.error('Error:', err);
      }
    });
  }

  galleryUpdate(file_path: any) {
    const THIS = this;
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'company-gallery-update?token=' + THIS.currentUser.token,
      type: 'post',
      data: JSON.stringify({file_path: file_path}),
      contentType: 'application/json',
      success: function (res) {
        if (res.status === 2000) {
        }
      },
      error: function (err) {
        console.error('Error:', err);
      }
    });
  }

  removeGalleryImage(id) {
    const THIS = this;
    const index = THIS.vendor_param.gallery.findIndex(item => item.id === id);
    if (index !== -1) {
      THIS.vendor_param.gallery.splice(index, 1);
    }
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'company-gallery-remove?token=' + THIS.currentUser.token,
      type: 'post',
      data: JSON.stringify({gallery_id: id}),
      contentType: 'application/json',
      success: function (res) {
      },
      error: function (err) {
        console.error('Error:', err);
      }
    });
  }

  uploadDoc(e, each: any, index: any) {
    each.loading = true;
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    const eachFile = accepted[0];
    let media_type: any = 1;
    if (eachFile.type.startsWith('image/') === false) {
      media_type = 4;
    }
    this.uploadThisMedia(eachFile, media_type, function (uploaded) {
      e.target.value = '';
      each.loading = false;
      if (uploaded.status !== undefined) {
        if (uploaded.status === 9000) {
          swal({
            title: 'Storage Full',
            text: 'Your Storage is full. Please delete some files',
            icon: 'error',
            dangerMode: true,
          });
        } else {
          swal({
            title: 'Invalid file format uploaded',
            text: 'Your request is not valid or there is something wrong.Please try again',
            icon: 'error',
            dangerMode: true,
          });
        }
      } else {
        each.info_doc = uploaded.file_path;
        THIS.checkIfHasError(index);
      }
    });
    e.target.type = 'text';
    e.target.type = 'file';
  }

  updateRadius(e) {
    this.vendor_param.operation_radius = JSON.stringify(e);
    this.checkIfHasError();
  }

  selectedCategories(e: any[]) {
    this.selectedCategoriesArray = e;
    this.vendor_param.industry = e.map(v => v.name).join(',');
    this.checkIfHasError();
  }

  checkIfHasError(index: any = null) {
    if (this.VendorErrors['company_address'] != undefined || this.VendorErrors['company_address'] != null || this.VendorErrors['company_address'] != '') {
      if (this.vendor_param.company_address != null || this.vendor_param.company_address != '') {
        this.VendorErrors['company_address'] = null;
      }
    }
    if (this.VendorErrors['operation_radius'] != undefined || this.VendorErrors['operation_radius'] != null || this.VendorErrors['operation_radius'] != '') {
      if (this.vendor_param.operation_radius != null || this.vendor_param.operation_radius != '') {
        this.VendorErrors['operation_radius'] = null;
      }
    }
    if (this.VendorErrors['industry'] != undefined || this.VendorErrors['industry'] != null || this.VendorErrors['industry'] != '') {
      if (this.vendor_param.industry != null || this.vendor_param.industry != '') {
        this.VendorErrors['industry'] = null;
      }
    }
    if (this.VendorErrors['company_logo'] != undefined || this.VendorErrors['company_logo'] != null || this.VendorErrors['company_logo'] != '') {
      if (this.vendor_param.company_logo != null || this.vendor_param.company_logo != '') {
        this.VendorErrors['company_logo'] = null;
      }
    }
    if (index != null) {
      if (this.VendorErrors['document_' + index] != undefined || this.VendorErrors['document_' + index] != null || this.VendorErrors['document_' + index] != '') {

        this.vendor_param.verify_doc.forEach((v, i) => {
          if (index == i) {
            if (v['required'] && v['document_required']) {
              if (v['info_name'] != null && v['info_name'] != '') {
                this.VendorErrors['document_' + i] = null;
              }
              if (v['info_value'] != null || v['info_value'] != '') {
                this.VendorErrors['document_' + i] = null;

              }
              if (v['info_doc'] != null || v['info_doc'] != '') {
                this.VendorErrors['document_' + i] = null;
              }
              if (v['expiry'] != null || v['expiry'] != '') {
                this.VendorErrors['document_' + i] = null;
              }
            }
          }

        });
      }
    }
  }

  closeNewVersionModal() {
    (<any>$('#newDocModal')).modal('hide');
  }
}
