import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {APPCONFIG} from '../../config';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MediaService} from '../../_services/media.service';
import {AuthService} from '../../_services/auth.service';
import {CustomHelper} from '../../customhelper';
import {forEach} from '@angular/router/src/utils/collection';

declare let Ladda: any;
declare let swal: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'my-page-vendor',
  templateUrl: './vendorProgram.html',
  styles: []
})

// tslint:disable-next-line:component-class-suffix
export class RegisterVendorProgram {
  APPCONFIG = APPCONFIG;
  CustomHelper = CustomHelper;
  userInfo: any = null;
  error: any = false;
  editAddress: any = false;
  company_logo: any = null;


  vendor_info: any = [];
  expiry: null;
  newUpload: any = [];
  dateOption = [];
  step: any = 1;
  companyParam: any = {
    title: '',
    phone: '',
    email: '',
    website: '',
    service_description: '',
    address: '',
    address_lat: '',
    address_lon: '',
    operation_radius: '50',
    industry: '',
  };
  gallery_images: any = [];
  selectedCategoriesArray: any = [];

  valid = {
    address: 0,
  };

  companyLoading = false;
  galleryLoading = false;
  errors = {};
  step1Errors: any = {
    address: null,
    category: null,
  };
  step3Errors: any = {
    title: null,
    email: null,
    company_logo: null,
  };

  constructor(private router: Router, private http: HttpClient, private mediaService: MediaService, private authService: AuthService) {
    if (localStorage.getItem('currentUserInfo') !== null) {
      this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

      if (this.userInfo.verified_vendor == 0 || this.userInfo.verified_vendor_submitted == 1) {
        this.router.navigate([this.userInfo.team + '/dashboard']);
      }

      if (this.userInfo !== null) {
        setTimeout(() => {
          this.assignCompanyData();
        }, 300);
      }
    } else {
      delete localStorage.currentUserInfo;
      this.router.navigate(['/login']);
      localStorage.clear();
    }
  }

  expiryConfig(index: any, data: any) {
    const THIS: any = this;
    this.dateOption[index] = {
      altInput: true,
      minDate: 'today',
      defaultDate: data.expiry != null ? new Date(data.expiry) : null,
      onChange: function (selectedDates, dateStr, instance) {
      },
    };
    this.dateOption[index].onChange = function (selectedDates, dateStr, instance) {
      THIS.vendor_info[index].expiry = dateStr;
      THIS.errors['index_' + index] = null;
    };
    return this.dateOption[index];
  }

  assignCompanyData() {
    if (this.userInfo !== null) {
      this.companyParam.title = this.userInfo.company_name;
      this.companyParam.phone = this.userInfo.phone;
      this.companyParam.email = this.userInfo.email;
      this.companyParam.website = this.userInfo.website;
      this.companyParam.service_description = this.userInfo.service_description;
      this.companyParam.address = this.userInfo.company_address;

    }

  }

  addNewItem() {
    const param = {
      info_name: null,
      info_value: null,
      info_doc: null,
      expiry: null,
      loading: false,
      allowDelete: true,
      required: true,
      document_required: true,
    };
    this.vendor_info.push(param);
  }

  removeItem(i: any) {
    this.vendor_info.splice(i, 1);
  }

  next() {
    this.step1Errors = {
      address: null,
      category: null,
    };
    if (this.companyParam.address === '') {
      this.step1Errors.address = 'Address field is required.';
      return;
    }
    if (this.companyParam.industry === '') {
      this.step1Errors.category = 'Industry field is required.';
      return;
    }

    if (this.step === 1) {
      const updateVendorInfo = (name: string, value: any) => {
        const existingInfo = this.vendor_info.find(v => v.info_name === name);
        if (existingInfo) {
          existingInfo.info_value = value;
        } else {
          this.vendor_info.push({
            info_name: name,
            info_value: value,
            info_doc: null,
            expiry: null,
            step: this.step,
            loading: false,
            allowDelete: false,
            document_required: true,
          });
        }
      };

      updateVendorInfo('address', this.companyParam.address);
      updateVendorInfo('operation_radius', this.companyParam.operation_radius);
      updateVendorInfo('industry', this.companyParam.industry);

      if (this.step > 0 && this.step < 3) {
        this.step++;
      }

      const requiredDocuments = [
        'Company or Business Name Registration Certificate',
        'Public Liability Insurance',
        'Contractor/Trade License',
        'Workers Compensation Insurance',
      ];

      requiredDocuments.forEach((docName) => {
        if (!this.vendor_info.some(v => v.info_name === docName)) {
          this.vendor_info.push({
            info_name: docName,
            info_value: null,
            info_doc: null,
            expiry: null,
            loading: false,
            allowDelete: false,
            step: this.step,
            required: true,
            document_required: true,
          });
        }
      });
    }

    // Filter out any accidental duplicates in vendor_info
    this.vendor_info = this.vendor_info.filter((v, index, self) =>
      index === self.findIndex((t) => t.info_name === v.info_name)
    );
  }


  previous() {
    if (this.step <= 3 && this.step > 1) {
      this.step--;
    }
  }

  vendorValidation(data: any, index: any) {
    let validation: any = true;
    let Err = '';
    if (data.required) {
      if (data.document_required) {
        /*Validate Name*/
        if (data.info_name == null || data.info_name == '') {
          Err = Err + ' Name field is required.';
          validation = false;
        }
        /*Validate Value*/
        if (data.info_value == null || data.info_value == '') {
          Err = Err + '&nbsp; Value field is required.';
          validation = false;
        }

        /*Validate Expiry*/
        if (data.expiry == null || data.expiry == '') {
          Err = Err + '&nbsp; Expiry field is required.';
          validation = false;
        }
        /*Validate Doc*/
        if (data.info_doc == null || data.info_doc == '') {
          Err = Err + ' &nbsp; Document field is required.';
          validation = false;
        }
      }
    }
    if (Err != '') {
      this.errors['index_' + index] = Err;
    }
    return validation;
  }

  verifyVendorApply() {
    const THIS = this;
    THIS.error = false;
    THIS.errors = {};
    const ldd = document.getElementById('submitBTN');
    const ladda = Ladda.create(ldd);
    const vendors: any = [];
    let validation = true;
    this.vendor_info.forEach((v: any, i: any) => {
      if (!this.vendorValidation(v, i)) {
        validation = false;
      }
      if (v['info_value'] != null) {
        vendors.push(v);
      }
    });
    if (!validation) {
      return;
    }

    ladda.start();
    setTimeout(() => {
      $.ajax({
        url: this.APPCONFIG.apiAuth + 'verify-vendor-apply?token=' + THIS.userInfo.token,
        type: 'post',
        data: JSON.stringify({vendor_info: vendors, operation_radius: THIS.companyParam.operation_radius, address: THIS.companyParam.address, address_lat: THIS.companyParam.address_lat, address_lon: THIS.companyParam.address_lon}),
        contentType: 'application/json',
        success: function (res) {
          ladda.stop();
          if (res.status === 2000) {
            THIS.step = 3;
          } else {
            THIS.error = true;
          }
        },
        error: function (err) {
          ladda.stop();
          THIS.error = true;
          console.error('Error:', err);
        }
      });
    }, 1000);
  }


  uploadThisMedia(file, media_type, callback) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  uploadDoc(e, each: any, index: any) {
    each.loading = true;
    e.preventDefault();
    const THIS = this;
    const accepted = e.target.files;
    const eachFile = accepted[0];
    let media_type: any = 1;
    if (eachFile.type.startsWith('image/') === false) {
      media_type = 4;
    }
    this.uploadThisMedia(eachFile, media_type, function (uploaded) {
      e.target.value = '';
      each.loading = false;
      if (uploaded.status !== undefined) {
        if (uploaded.status === 9000) {
          swal({
            title: 'Storage Full',
            text: 'Your Storage is full. Please delete some files',
            icon: 'error',
            dangerMode: true,
          });
        } else {
          swal({
            title: 'Invalid file format uploaded',
            text: 'Your request is not valid or there is something wrong.Please try again',
            icon: 'error',
            dangerMode: true,
          });
        }
      } else {
        each.info_doc = uploaded.file_path;
        THIS.errors['index_' + index] = null;
      }
    });
    e.target.type = 'text';
    e.target.type = 'file';
  }

  submitHandler() {
    switch (this.step) {
      case 1:
        this.next();
        break;
      case 2:
        this.verifyVendorApply();
        break;
      case 3:
        this.updateInfo();
        break;
    }
  }

  checkUrl() {
    if (this.companyParam.website) {
      try {
        // tslint:disable-next-line:no-unused-expression
        new URL(this.companyParam.website);
        this.step3Errors.website = null;
        return true;
      } catch (e) {
        this.step3Errors.website = 'Please enter valid url.';
        return false;
      }
    } else {
      this.step3Errors.website = null;
    }
  }

  updateInfo() {
    this.step3Errors = {
      title: null,
      email: null,
      company_logo: null,
    };
    const THIS = this;
    const ldd = document.getElementById('submitBTN');
    const ladda = Ladda.create(ldd);
    if (this.companyParam.title === '') {
      this.step3Errors.title = 'Name is required';
      return true;
    }
    if (this.companyParam.email === '') {
      this.step3Errors.email = 'Email is required';
      return true;
    } else {
      const check = this.CustomHelper.emailValidation(this.companyParam.email);
      if (!check) {
        this.step3Errors.email = this.companyParam.email + '- is not a valid email address. Please type valid email address.';
        return true;
      }
    }

    if (THIS.company_logo == null) {
      this.step3Errors.company_logo = 'Company Logo is required.';
      return true;
    }
    if (THIS.companyParam.website != null && THIS.companyParam.website != '') {
      if (!this.companyParam.website.startsWith('https://')) {
        this.companyParam.website = 'https://' + this.companyParam.website;
      }
      if (!this.checkUrl()) {
        this.step3Errors.website = 'Please enter valid url.';
        return true;
      }
      if (!this.checkUrl()) {
        this.step3Errors.website = 'Please enter valid url.';
        return true;
      }
    }

    ladda.start();
    this.authService.updateMe(this.companyParam).subscribe(res => {
      ladda.stop();
      if (res.status === 2000) {

        THIS.router.navigate(['/auth/register/process/' + this.userInfo.team]);
      } else {
        swal(res.msg);
        return true;
      }
    });
  }

  companyLogo(logo: any) {
    const THIS = this;
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'company-logo-update?token=' + THIS.userInfo.token,
      type: 'post',
      data: JSON.stringify({logo: logo}),
      contentType: 'application/json',
      success: function (res) {
        if (res.status === 2000) {
          THIS.company_logo = res.data.company_logo;
          THIS.step3Errors.company_logo = null;
        }
      },
      error: function (err) {
        console.error('Error:', err);
      }
    });
  }

  galleryUpdate(file_path: any) {
    const THIS = this;
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'company-gallery-update?token=' + THIS.userInfo.token,
      type: 'post',
      data: JSON.stringify({file_path: file_path}),
      contentType: 'application/json',
      success: function (res) {
        if (res.status === 2000) {
        }
      },
      error: function (err) {
        console.error('Error:', err);
      }
    });
  }

  upload(e, type) {

    e.preventDefault();
    const THIS = this;
    if (type == 'company-logo') {
      THIS.companyLoading = true;
    } else {
      THIS.galleryLoading = true;
    }
    const accepted = e.target.files;
    const eachFile = accepted[0];
    if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
      this.uploadThisMedia(eachFile, '1', function (uploaded) {
        if (type == 'company-logo') {
          THIS.companyLoading = false;
        } else {
          THIS.galleryLoading = false;
        }
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please delete some files',
              icon: 'error',
              dangerMode: true,
            });

          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Your request is not valid or there is something wrong.Please try again',
              icon: 'error',
              dangerMode: true,
            });
          }
          if (type == 'company-logo') {
            THIS.companyLoading = false;
          } else {
            THIS.galleryLoading = false;
          }
        } else {
          if (type == 'company-logo') {
            THIS.companyLogo(uploaded.file_path);
            THIS.companyLoading = false;
            THIS.step3Errors.company_logo = null;
          } else {
            THIS.galleryUpdate(uploaded.file_path);
            THIS.gallery_images.push({id: uploaded.id, path: uploaded.file_path});
            THIS.galleryLoading = false;
          }

        }
      });
    } else {
      swal('This file is not supported for avatar. Please select jpg/png/gif file.');
      if (type == 'company-logo') {
        THIS.companyLoading = false;
      } else {
        THIS.galleryLoading = false;
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
    setTimeout(() => {
      if (type == 'company-logo') {
        THIS.companyLoading = false;
      } else {
        THIS.galleryLoading = false;
      }
    }, 5000);
  }


  removeGalleryImage(id) {
    const THIS = this;
    const index = this.gallery_images.findIndex(item => item.id === id);
    if (index !== -1) {
      THIS.gallery_images.splice(index, 1);
    }
    $.ajax({
      url: this.APPCONFIG.apiAuth + 'company-gallery-remove?token=' + THIS.userInfo.token,
      type: 'post',
      data: JSON.stringify({gallery_id: id}),
      contentType: 'application/json',
      success: function (res) {
        if (res.status === 2000) {
        }
      },
      error: function (err) {
        console.error('Error:', err);
      }
    });
  }

  handleAddressChange(place) {
    this.companyParam.address = place.formatted_address;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    // Store latitude and longitude in companyParam
    this.companyParam.address_lat = JSON.stringify(lat);
    this.companyParam.address_lon = JSON.stringify(lng);
  }

  resetAddress() {
    this.companyParam.address = this.userInfo.company_address;
    this.editAddress = false;
  }

  updateAddress() {
    if (this.companyParam.address != '') {
      this.userInfo.company_address = this.companyParam.address;
      this.editAddress = false;
    }
  }

  updateRadius(e) {
    this.companyParam.operation_radius = JSON.stringify(e);
  }

  selectedCategories(e: any[]) {
    this.selectedCategoriesArray = e;
    this.companyParam.industry = e.map(v => v.name).join(',');
  }

  openInfoModal() {
    (<any>$('#InfoModal')).modal('show');
  }

  getIcon(file_name) {
    // Define arrays of image and file extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
    const fileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'zip', 'rar'];

    // Extract the file extension from the file name
    const extension = file_name.split('.').pop().toLowerCase();

    // Check if the extension matches an image or file type
    if (imageExtensions.includes(extension)) {
      return 'image'; // Material Icon for image files
    } else if (fileExtensions.includes(extension)) {
      return 'insert_drive_file'; // Material Icon for generic files
    } else {
      return 'insert_drive_file'; // Material Icon for generic files
    }
  }

  checkRequired(data: any) {
    if (data.info_name == 'Company or Business Name Registration Certificate' || data.info_name == 'Public Liability Insurance') {
      return false;
    }
    return true;
  }

  checkIfRequired(e, data) {
    data.document_required = e;
  }

}
