import { Component} from '@angular/core';
import {FacilityService} from '../../_services/facility.service';
import {FcmService} from '../../_services/fcm.service';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
declare let swal: any;
declare let Ladda: any;


@Component({
    selector: 'page-facility',
    templateUrl: './facility.component.html'
})
export class FacilityComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    miniScreen = 0;


    step = 0;
    template = 0;
    ROUTES = '/facilities';
    currentUser = null;
    constructor(protected facilityService:FacilityService) {
        let THIS = this;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.currentUser = Session;
        let url = '/'+Session.team+this.ROUTES;
        window.history.pushState("","", url);

        $('body').on('click', '#previewNewFacility', function () {
            THIS.template = 0;
            setTimeout(function () {
                THIS.selectedFacility = localStorage.selectedFacility !== undefined ? JSON.parse(localStorage.selectedFacility) : null;
                THIS.selectFacility(THIS.selectedFacility);
            });
        });
        $('body').on('click', '#editNewFacility', function () {
            THIS.template = 0;
            setTimeout(function () {
                THIS.selectedFacility = localStorage.selectedFacility !== undefined ? JSON.parse(localStorage.selectedFacility) : null;
                THIS.editFacility(THIS.selectedFacility);
            });
        });
        $('body').on('click', '#importNewFacility', function () {
            THIS.template = 0;
            setTimeout(function () {
                THIS.selectedFacility = localStorage.selectedFacility !== undefined ? JSON.parse(localStorage.selectedFacility) : null;
                THIS.importFacility(THIS.selectedFacility);
            });
        });
        $('body').on('click', '#removeNewFacility', function () {
            THIS.template = 1;
            THIS.step = 1;
            setTimeout(function () {
                THIS.selectedFacility = localStorage.selectedFacility !== undefined ? JSON.parse(localStorage.selectedFacility) : null;
                THIS.removeId = THIS.selectedFacility.id;
                THIS.removeThisFacility(THIS.selectedFacility);
            });
        });

        this.selectedFacility = localStorage.selectedFacility !== undefined ? JSON.parse(localStorage.selectedFacility) : null;
        this.template = localStorage.selectedFacilityTemplate !== undefined ? localStorage.selectedFacilityTemplate : 1;
        this.step = localStorage.selectedFacilityStep !== undefined ? localStorage.selectedFacilityStep : 1;

        if(localStorage.selectedFacilityStepRemove !== undefined){
            delete localStorage.selectedFacilityStepRemove;
            THIS.template = 1;
            THIS.step = 1;
            setTimeout(function () {
              THIS.selectedFacility = localStorage.selectedFacility !== undefined ? JSON.parse(localStorage.selectedFacility) : null;
              THIS.removeId = THIS.selectedFacility.id;
              THIS.removeThisFacility(THIS.selectedFacility);
            });
        }
    }

    Removed = 0;
    selectedFacility = null;
    selectFacility(fac){
        this.selectedFacility = fac;
        this.template = 1;
    }
    editFacility(fac){
        this.selectedFacility = fac;
        this.template = 3;
        this.step = 1;
    }
    importFacility(fac){
        this.selectedFacility = fac;
        this.template = 3;
        this.step = 2;
    }



    removeId = 0;
    removeFac = {
        submitted : 0
    };
    removeChild = 0;
    ck = {
      ck1 : false,
      ck2 : false
    };
    rmThisFac(){
        let ldd = document.getElementById('rmFac');
        let ladda = Ladda.create(ldd);
        let THIS = this;
        let param = {id: this.removeId, removeChild: THIS.removeChild};
        this.removeFac.submitted = 1;
        ladda.start();
        this.facilityService.removeFacility(param).subscribe(res => {
            ladda.stop();
            this.removeFac.submitted = 0;
            this.template = 0;
            this.selectedFacility.id = 0;
            // Pusher emit
            (<any>$('#deleteFacModal')).modal('hide');
            $('.goToDash').click();
            setTimeout(() => {
              (<any>$('#DeleteFacSuccess')).modal('show');
            }, 1000)
        });
    }
    removeThisFacility(fac){
        this.selectedFacility = fac;
        this.removeId = fac.id;
        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) { if($(v).find('a.active').length > 0){ ___selectedTab = i; } });
        let child = ___selectedTab+1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child('+child+')').find('a').addClass('active');
        (<any>$('#deleteFacModal')).modal('show');
        this.ck.ck1 = false;
        this.ck.ck2 = false;
        this.Removed = 0;
    }


    ngOnInit() {
        let THIS = this;
    }
    ngOnDestroy() {
    }

}
