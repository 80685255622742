import { Component, Input} from '@angular/core';
import {APPCONFIG} from '../../../config';
import {CustomHelper} from '../../../customhelper';

@Component({
    selector: 'my-app-sidenav-calendar-menu',
    styles: [],
    templateUrl: './sidenav-calendar-menu.component.html'
})

export class SidenavCalendarMenuComponent {
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    @Input() changeTab:any;

    activeLinks: Array<number>;
    selectedTab: number;
    selectedPage: any;

    currentYear;
    currentMonth;
    currentUser = null;
    constructor() {
        let THIS = this;
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        let d = new Date();
        let m = d.getMonth();
        let y = d.getFullYear();
        this.currentMonth = m;
        this.currentYear = y;
        d.setMonth(m+1);
        setTimeout(function () {
            THIS.renderCalenderOne();
            THIS.renderCalenderTwo(d);
        }, 500);
    }


    renderDayView(time){
        $('.___dayView').attr('data-type', time);
        setTimeout(function () {
            $('.___dayView').click();
        }, 500);
    }
    renderCalenderOne(){
        let THIS = this;
        (<any>$('.datepicker1')).datepicker({
            inline: true,
            onChangeMonth: function(month, year){
                if(THIS.currentYear == year){
                    if(THIS.currentMonth < month){
                        THIS.DDT2.next();
                    } else {
                        THIS.DDT2.prev();
                    }
                } else if(THIS.currentYear < year) {
                    console.log(month);
                    if(month == 0){
                        THIS.DDT2.next();
                    }
                } else if(THIS.currentYear > year) {
                    if(month > 9){
                        THIS.DDT2.prev();
                    }
                }
                THIS.currentMonth = month;
                THIS.currentYear = year;
            },
            onSelect: function(formattedDate, date, inst){
                if(date != ''){
                    THIS.renderDayView(formattedDate);
                }
            }
        });
    }
    ddt2 = 1;
    DDT2 = null;
    renderCalenderTwo(d){
        let THIS = this;
        if(THIS.DDT2 == null){
            THIS.DDT2 = (<any>$('.datepicker2')).datepicker({
                inline: true,
                startDate: d,
                onSelect: function(formattedDate, date, inst){
                    if(date != ''){
                        THIS.renderDayView(formattedDate);
                    }
                }
            }).data('datepicker');
        }
    }

}
