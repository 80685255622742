import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {APPCONFIG} from '../../config';

declare let Ladda: any;

@Component({
  selector: 'register-layout',
  templateUrl: './register.component.html',
})
export class RegisterComponent {
  APPCONFIG = APPCONFIG;
  showImage: any = false;
  passType: any = 'password';
  confirmationPassType: any = 'password';

  errorMessage = '';
  valid = {
    first_name: 0,
    last_name: 0,
    email: 0,
    password: 0,
    password_confirmation: 0,
    'g-recaptcha-response': 0
  };
  param = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phone: '',
    country: 'AU',
    'g-recaptcha-response': '',
    tos: 0,
    verified_vendor: 0,
  };

  mobileMenu = false;

  validUniqueEmail = 1;
  emailErr = '';
  showVendorInfo = false;


  constructor(private router: Router) {
    const currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    if (currentUser != null) {
      this.router.navigate(['/dashboard']);
    }
    let GET_DATA: any = this.parseURLParams(location.href);
    if (GET_DATA !== undefined) {
      if (GET_DATA.em !== undefined) {
        if (GET_DATA.em.length > 0) {
          this.param.email = atob(GET_DATA.em[0]);
        }
      }
    }
    let url = location.origin + location.pathname;
    window.history.pushState('', '', url);


    setTimeout(function () {
      (<any>window).dataLayer = (<any>window).dataLayer || [];

      function gtag(a, b) {
        (<any>window).dataLayer.push(arguments);
      }

      gtag('js', new Date());
      gtag('config', 'UA-142622855-1');
    }, 2000);

  }


  validUniqueEmailCheck() {
    let THIS = this;
    if (this.emailValidation()) {
      $.ajax({
        url: THIS.APPCONFIG.apiAuth + 'email-check',
        type: 'post',
        data: {email: THIS.param.email},
        success: function (res) {
          if (res.status == 2000) {
            THIS.validUniqueEmail = 1;

          } else {
            THIS.validUniqueEmail = 0;
            THIS.emailErr = 'Email address already exist';
          }
        }
      });
    }
  }

  emailValidation() {
    let THIS = this;
    let email = $.trim(THIS.param.email);
    if (email == '') {
      this.emailErr = 'This field cannot be left blank';
      return false;
    }
    let domain = email.substring(email.lastIndexOf('@') + 1);
    // let restrDomain = ['gmail.com','yahoo.com','live.com','outlook.com','hotmail.com.au','live.com.au','hotmail.com'];
    let restrDomain = ['equidesk.com'];
    if (restrDomain.indexOf(domain.toLowerCase()) > -1) {
      this.emailErr = 'Free emails are not allowed. Please use your work/official email address';
      return false;
    } else {
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email)) {
        this.emailErr = 'Insert a valid email address';
        return false;
      } else {
        return true;
      }
    }
  }

  errorMessagePass = '';

  passwordCheck() {
    const password = this.param.password;
    if (password == '') {
      this.errorMessagePass = 'This field cannot be left blank';
      return false;
    }
    if (password.length < 8) {
      this.errorMessagePass = 'Password must have 8 characters minimum';
      return false;
    }
    const cl = /[a-zA-Z]/;
    const containsLetter = cl.test(password);
    const cd = /\d/;
    const containsDigit = cd.test(password);
    const cs = /[^a-zA-Z\d]/;
    const containsSpecial = cs.test(password);
    if (!containsLetter) {
      this.errorMessagePass = 'Password must have letter';
      return false;
    }
    if (!containsDigit && !containsSpecial) {
      this.errorMessagePass = 'Password must have a number or a special character';
      return false;
    }
    return true;
  }

  errorMessageConPass = '';

  passwordConCheck() {
    const password = this.param.password_confirmation;
    if (password == '') {
      this.errorMessageConPass = 'This field cannot be left blank';
      return false;
    }
    /*if(password.length < 8){ this.errorMessageConPass = 'Password must have 8 characters minimum'; return false; }
    const cl = /[a-zA-Z]/;
    const containsLetter  = cl.test(password);
    const cd = /\d/;
    const containsDigit  = cd.test(password);
    const cs = /[^a-zA-Z\d]/;
    const containsSpecial  = cs.test(password);
    if(!containsLetter){ this.errorMessageConPass = 'Password must have letter'; return false; }
    if(!containsDigit && !containsSpecial){ this.errorMessageConPass = 'Password must have a number or a special character'; return false; }*/
    if (this.param.password != this.param.password_confirmation) {
      this.errorMessageConPass = 'Password don\'t match. Please check!';
      return false;
    }
    return true;
  }

  validateRegister() {
    this.valid.first_name = 1;
    this.valid.last_name = 1;
    this.valid.email = 1;
    // this.valid['g-recaptcha-response'] = 1;
    if ($.trim(this.param.first_name) == '') {
      return false;
    }
    if ($.trim(this.param.last_name) == '') {
      return false;
    }
    if (this.param.email == '') {
      this.emailErr = 'This field cannot be left blank';
      return false;
    } else {
      this.emailValidation();
    }
    if (this.param.password == '') {
      this.errorMessagePass = 'This field cannot be left blank';
      return false;
    } else {
      this.passwordCheck();
    }
    if (this.param.password_confirmation == '') {
      this.errorMessageConPass = 'This field cannot be left blank';
      return false;
    } else {
      this.passwordConCheck();
    }
    // if($.trim(this.param['g-recaptcha-response']) == ''){ return false; }
    return true;
  }

  parseURLParams(url) {
    let queryStart = url.indexOf('?') + 1,
      queryEnd = url.indexOf('#') + 1 || url.length + 1,
      query = url.slice(queryStart, queryEnd - 1),
      pairs = query.replace(/\+/g, ' ').split('&'),
      parms = {}, i, n, v, nv;

    if (query === url || query === '') {
      return;
    }

    for (i = 0; i < pairs.length; i++) {
      nv = pairs[i].split('=', 2);
      n = decodeURIComponent(nv[0]);
      v = decodeURIComponent(nv[1]);

      if (!parms.hasOwnProperty(n)) {
        parms[n] = [];
      }
      parms[n].push(nv.length === 2 ? v : null);
    }
    return parms;
  }

  CheckTos() {
    if (this.param.tos === 0) {
      this.param.tos = 1;
    } else {
      this.param.tos = 0;
    }
  }

  register() {
    const THIS = this;
    let ldd = document.getElementById('submitBTN');
    let ladda = Ladda.create(ldd);
    const param = this.getUrlVars($('#registerForm').serializeArray());
    param['tos'] = this.param.tos;
    param['verified_vendor'] = this.param.verified_vendor;
    this.param['g-recaptcha-response'] = param['g-recaptcha-response'];
    if (this.validateRegister() && this.validUniqueEmail === 1) {
      ladda.start();
      $.ajax({
        url: THIS.APPCONFIG.apiAuth + 'register',
        type: 'post',
        data: param,
        success: function (res) {
          ladda.stop();
          if (res.status == 2000) {
            localStorage.registered = JSON.stringify(param);
            THIS.router.navigate(['/auth/register/success']);
          } else {
            console.log(2, res.error);
            THIS.errorMessage = res.error;
          }
        }
      });
    }
  }

  getUrlVars(url) {
    let myJson = {};
    for (let i = 0; i < url.length; i++) {
      myJson[url[i]['name']] = url[i]['value'];
    }
    return myJson;
  }

  checkVendor() {
    if (this.param.verified_vendor === 1) {
      this.param.verified_vendor = 0;
    } else {
      this.param.verified_vendor = 1;
    }
  }

  changePassType() {
    if (this.passType === 'password') {
      this.passType = 'text';
    } else if (this.passType === 'text') {
      this.passType = 'password';
    }
  }

  changePassTypeConfirm() {
    if (this.confirmationPassType === 'password') {
      this.confirmationPassType = 'text';
    } else if (this.confirmationPassType === 'text') {
      this.confirmationPassType = 'password';
    }
  }


}
