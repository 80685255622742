import {Component, Input, Output, EventEmitter} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
import {AssetService} from '../../../_services/asset.service';
import {FacilityService} from '../../../_services/facility.service';
import {MediaService} from '../../../_services/media.service';
import {FcmService} from '../../../_services/fcm.service';

declare let toastr: any;
declare let google: any;
declare let swal: any;
declare let Ladda: any;
declare let Pusher: any;

declare let fabric: any;
declare let Pickr: any;
declare let Inputmask: any;

@Component({
  selector: 'page-assets-create',
  templateUrl: './asset-create.component.html'
})
export class AssetCreateComponent {
  @Output() discard = new EventEmitter<number>();
  @Output() preview = new EventEmitter<number>();
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};

  viewDates = 1;
  inlinePrefixId = 0;

  updateChangesDiscard() {
    let THIS = this;
  }

  ContractStartOption = {
    altInput: true,
    defaultDate: null,
    minDate: null,
    altFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };
  ContractEndOption = {
    altInput: true,
    defaultDate: null,
    minDate: null,
    altFormat: this.CustomHelper.dateFormatFlat(),
    onChange: function (selectedDates, dateStr, instance) {
    },
  };
  param = {
    asset_id: '',
    parent_id: '',
    prefix: '',
    name: '',
    facility: '',
    media_files: '',
    attach_files: '',
    category_id: '',
    location: '',
    geo: '',
    brand: '',
    serial_number: '',
    purchase_cost: '',
    personnel: '',
    phone: '',
    email: '',
    contract_start: '',
    contract_end: '',
    notify: 0,
    notify_duration: '',
    depreciation_cost: '',
    eol: '',
    asset_life: '',
    depreciation_method: '',
  };

  validForm = 0;

  saveWorkOrderSubmit() {
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      let freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        let freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    let THIS = this;
    let ldd = document.getElementById('SaveThisLadda');
    let ladda = Ladda.create(ldd);
    let mediaFiles = '';
    $.each(THIS.selectedFilesPreview, function (i, v) {
      if (i == 0) {
        mediaFiles += v.id;
      } else {
        mediaFiles += ',' + v.id;
      }
    });
    let mediaAttach = '';
    $.each(THIS.selectedAssetAttach, function (i, v) {
      if (i == 0) {
        mediaAttach += v.id;
      } else {
        mediaAttach += ',' + v.id;
      }
    });
    this.param.media_files = mediaFiles;
    this.param.attach_files = mediaAttach;
    this.param.purchase_cost = (<any>$('input[name="purchase_cost"]').val()).replace(/\$/, '');
    this.param.depreciation_cost = (<any>$('input[name="depreciation_cost"]').val()).replace(/\$/, '');
    this.param.eol = (<any>$('input[name="eol"]').val()).replace(/\$/, '');
    if((<any>$('input[name="purchase_cost"]')).val().includes('AED')){
      this.param.purchase_cost = (<any>$('input[name="purchase_cost"]').val()).replace('AED', '');
    }
    if((<any>$('input[name="purchase_cost"]')).val().includes('AUD')){
      this.param.purchase_cost = (<any>$('input[name="purchase_cost"]').val()).replace('AUD', '');
    }

    if((<any>$('input[name="purchase_cost"]')).val().includes('-')){
      $('input[name="purchase_cost"]').closest('.form-position').find('.invalid').html('Purchase cost should be positive');
      return true;
    }else{
      $('input[name="purchase_cost"]').closest('.form-position').find('.invalid').html('');
    }
    let valid = this.validationThisForm();
    if(valid == 2){
      return true;
    }
    if (valid == 1) {
      let THIS = this;
      ladda.start();
      this.assetService.createAsset(this.param).subscribe(res => {
        ladda.stop();
        if (res.status == 7000) {
          this.coolDialogs.alert('The Asset ID is already been taken. Please use a unique one', {
            title: 'Form Validation',
            color: '#e0e0e0',
          });
        }
        if (res.status == 2000) {
          this.preview.emit(res.data[0]);
          toastr.success('New asset has been successfully created', 'New Asset', this.toastrOption);
          // PUSHER EMIT
        }
      });
    }
  }

  validationThisForm() {
    let valid = 1;
    let form = $('#workCreateForm');
    if (this.param.facility == '') {
      valid = 0;
      form.find('[data-el="facility"]').removeClass('has-error').addClass('has-error');
    } else {
      form.find('[data-el="facility"]').removeClass('has-error');
    }
    if (this.param.name == '') {
      valid = 0;
      form.find('[data-el="subject"]').removeClass('has-error').addClass('has-error');
    } else {
      form.find('[data-el="subject"]').removeClass('has-error');
    }
    this.validForm = valid;
    return valid;
  }

  clearValidation(e) {
    let trigger = $(e.target);
    let target = trigger.closest('.form-group');
    target.removeClass('has-error');
  }

  getLastPreId() {
    let THIS = this;
    this.assetService.getLastPreId().subscribe(res => {
      if (res.status == 2000) {
        this.param.prefix = res.data.prefix;
        this.param.asset_id = res.data.asset_id;
      }
    });
  }


  currentUser;

  constructor(private assetService: AssetService,
              private mediaService: MediaService,
              public snackBar: MatSnackBar,
              public fcmService: FcmService,
              private coolDialogs: NgxCoolDialogsService,
              private facilityService: FacilityService) {
    this.getLastPreId();
    this.getAllFacility();
    this.getAllRecursiveAssets();
    this.getAllcategory();
    this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

    let THIS = this;
    this.ContractStartOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.param.contract_start = THIS.CustomHelper.formatDateOnlyToDB(selectedDates);
    };
    this.ContractEndOption.onChange = function (selectedDates, dateStr, instance) {
      THIS.viewDates = 0;
      setTimeout(function () {
        THIS.ContractEndOption.minDate = new Date(THIS.param.contract_start);
        THIS.param.contract_end = THIS.CustomHelper.formatDateOnlyToDB(selectedDates);
      }, 500);
    };

    this.checkCurrency();
  }


  Facility = [];
  FacilityComp = [];

  getAllFacility() {
    let THIS = this;
    this.facilityService.getFacilitiesList().subscribe(res => {
      this.Facility.length = 0;
      this.FacilityComp.length = 0;
      this.FacilityComp = res.facility;
      $.each(res.facility, function (i, v) {
        v['pp'] = 0;
        THIS.Facility.push(v);
        $.each(v.child, function (j, vC) {
          vC['pp'] = 1;
          THIS.Facility.push(vC);
          $.each(vC.child, function (k, vCC) {
            vCC['pp'] = 2;
            THIS.Facility.push(vCC);
            $.each(vCC.child, function (l, vCCC) {
              vCCC['pp'] = 3;
              THIS.Facility.push(vCCC);
            });
          });
        });
      });
      setTimeout(function () {
        if ($('.reRenderFacility').length > 0) {
          let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
          if (facilityId > 0) {
            THIS.param.facility = facilityId.toString();
            (<any>$('.chosen-default-single-fac')).val(THIS.param.facility);
          }
        }
        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Facility'
        }).on('change', function (e, p) {
          if (e.target.value === '000') {
            (<any>$('.chosen-default-single-fac')).val(THIS.param.facility);
            (<any>$('.chosen-default-single-fac')).trigger("chosen:updated");
            let ___selectedTab = 0;
            let tabs = $('#sort-tabs').find('li');
            tabs.each(function (i, v) {
              if ($(v).find('a.active').length > 0) {
                ___selectedTab = i;
              }
            });
            let child = ___selectedTab + 1;
            (<any>$('.owl-carousel')).owlCarousel('destroy');
            $('#sort-tabs').find('li').find('a').removeClass('active');
            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
            (<any>$('#createFacilityModal')).modal('show');
          } else {
            THIS.param.facility = e.target.value;
            THIS.validationThisForm();
            THIS.updateChangesDiscard();
          }

        });
      }, 100);
    });
  }

  RecursiveAssets: any = [];

  getAllRecursiveAssets() {
    let THIS = this;
    THIS.RecursiveAssets = [];
    THIS.assetService.RecursiveAssets().subscribe(res => {
      THIS.RecursiveAssets = res;
      console.log(THIS.RecursiveAssets);
      /*     $.each(res.data, function (i, v) {
             v['pp'] = 0;
             THIS.RecursiveAssets.push(v);
             /!*$.each(v.child, function (j, vC) {
               vC['pp'] = 1;
               THIS.RecursiveAssets.push(vC);
               $.each(vC.child, function (k, vCC) {
                 vCC['pp'] = 2;
                 THIS.RecursiveAssets.push(vCC);
                 $.each(vCC.child, function (l, vCCC) {
                   vCCC['pp'] = 3;
                   THIS.RecursiveAssets.push(vCCC);
                 });
               });
             });*!/
           });*/
      setTimeout(function () {
        console.log(THIS.RecursiveAssets);
        // (<any>$('.chosen-default-single-asset')).val(THIS.param.parent_id);
        // (<any>$('.chosen-default-single-asset')).chosen('destroy');
        (<any>$('.chosen-default-single-asset')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'nested under'
        }).on('change', function (e, p) {
          THIS.param.parent_id = e.target.value;

        });
      }, 1000);
    });
  }

  Categories = [];
  CategoryDefault = [];
  CategoryCustom = [];

  getAllcategory() {
    let THIS = this;
    this.assetService.getAssetCatList().subscribe(res => {
      this.Categories.length = 0;
      this.CategoryDefault.length = 0;
      this.CategoryCustom.length = 0;
      this.Categories = res;
      let THIS = this;
      $.each(res, function (i, v) {
        if (v.user_id == 0) {
          THIS.CategoryDefault.push(v);
        } else {
          THIS.CategoryCustom.push(v);
        }
      });
      setTimeout(function () {
        (<any>$('.chosen-default-single-cat')).chosen('destroy');
        (<any>$('.chosen-default-single-cat')).chosen({
          allow_single_deselect: true,
          placeholder_text_single: 'Select Category'
        }).on('change', function (e, p) {
          THIS.param.category_id = e.target.value;
          THIS.updateChangesDiscard();
        });
      }, 100);
    });
  }


  CMap;

  GmapZoomIn(data) {
    this.CMap.setZoom(this.CMap.getZoom() + data);
  }

  geoLocation(place) {
    let THIS = this;
    this.param.geo = JSON.stringify({
      address: place.formatted_address,
      geometry: place.geometry.location
    });
    setTimeout(function () {
      let mapOptions = {
        zoom: 13,
        center: place.geometry.location,
        gestureHandling: 'cooperative',
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false
      };
      THIS.CMap = new google.maps.Map(document.getElementById('geo-map'), mapOptions);
      new google.maps.Marker({
        position: place.geometry.location,
        map: THIS.CMap,
        draggable: true,
        title: place.formatted_address
      });
    }, 100);
  }

  geoLocationMap(e) {
    let THIS = this;
    setTimeout(function () {
      if (THIS.param.geo == '') {
        let ltln = e.target.value.split(',');
        if (ltln.length == 2) {
          let latlon = new google.maps.LatLng(ltln[0], ltln[1]);
          THIS.param.geo = JSON.stringify({
            address: e.target.value,
            geometry: latlon
          });
          setTimeout(function () {
            let mapOptions = {
              zoom: 13,
              center: latlon,
              gestureHandling: 'cooperative',
              mapTypeControl: false,
              streetViewControl: false,
              zoomControl: false
            };
            THIS.CMap = new google.maps.Map(document.getElementById('geo-map'), mapOptions);
            new google.maps.Marker({
              position: latlon,
              map: THIS.CMap,
              draggable: true
            });
          }, 100);
        }
      }
    }, 100);
  }


  selectedFiles = [];
  selectedFilesPreview = [];

  removeThisMedia(id, media_type) {
    let param = {
      id: id, media_type: media_type
    };
    this.mediaService.remove(param).subscribe(res => {
    });
  }

  removeThisImage(index, id) {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesPreview.splice(index, 1);
    this.removeThisMedia(id, 1);
  }

  _uploadError = 0;
  _locPermit = 0;

  uploadThisMedia(file, media_type, localtion, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      this.updateChangesDiscard();
      callback(res);
    });
  }

  dragFilesDropped(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    if (e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        if (this.selectedFiles.length < 1) {
          let eachFile = e.dataTransfer.files[i];
          this.selectedFiles.push(eachFile);
          if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
            if (THIS.currentUser.modules.geo_tagged_photo == 1) {
              navigator.geolocation.getCurrentPosition(function (position) {
                if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                  THIS._locPermit = 1;
                  let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                  THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  THIS._locPermit = -1;
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                }
              }, function (error) {
                swal({
                  title: 'Share Geo Location',
                  text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                  icon: 'warning',
                  buttons: ['Want to share location', 'Don\'t want to share Location'],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                      if (uploaded.status != undefined) {
                        if (uploaded.status == 9000) {
                          swal({
                            title: 'Storage Full',
                            text: 'Your Storage is full. Please delete some files',
                            icon: 'error',
                            dangerMode: true,
                            className: 'swal-btn-center'
                          });
                        } else {
                          swal({
                            title: 'Invalid file format uploaded',
                            text: 'Please upload an image in Jpeg or Png only.',
                            icon: 'error',
                            dangerMode: true,
                            className: 'swal-btn-center'
                          });
                        }
                      } else {
                        THIS.selectedFilesPreview.push(uploaded);
                      }
                    });
                  } else {
                    swal('If you want to share location then change the browser settings and remove the block status');
                  }
                });
              });
            } else {
              THIS._locPermit = -1;
              THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                if (uploaded.status != undefined) {
                  if (uploaded.status == 9000) {
                    swal({
                      title: 'Storage Full',
                      text: 'Your Storage is full. Please delete some files',
                      icon: 'error',
                      dangerMode: true,
                      className: 'swal-btn-center'
                    });
                  } else {
                    swal({
                      title: 'Invalid file format uploaded',
                      text: 'Please upload an image in Jpeg or Png only.',
                      icon: 'error',
                      dangerMode: true,
                      className: 'swal-btn-center'
                    });
                  }
                } else {
                  THIS.selectedFilesPreview.push(uploaded);
                }
              });
            }
          } else {
            this._uploadError = 1;
          }
        }
      }
    }
  }

  selectTheseFiles(e) {
    e.preventDefault();
    this._uploadError = 0;
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedFiles.length < 1) {
        let eachFile = accepted[i];
        this.selectedFiles.push(eachFile);
        if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
          if (THIS.currentUser.modules.geo_tagged_photo == 1) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (THIS.currentUser.modules.geo_tagged_photo == 1) {
                THIS._locPermit = 1;
                let localtion = {lat: position.coords.latitude, lon: position.coords.longitude};
                THIS.uploadThisMedia(eachFile, '1', localtion, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              } else {
                THIS._locPermit = -1;
                THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                  if (uploaded.status != undefined) {
                    if (uploaded.status == 9000) {
                      swal({
                        title: 'Storage Full',
                        text: 'Your Storage is full. Please delete some files',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    } else {
                      swal({
                        title: 'Invalid file format uploaded',
                        text: 'Please upload an image in Jpeg or Png only.',
                        icon: 'error',
                        dangerMode: true,
                        className: 'swal-btn-center'
                      });
                    }
                  } else {
                    THIS.selectedFilesPreview.push(uploaded);
                  }
                });
              }
            }, function (error) {
              swal({
                title: 'Share Geo Location',
                text: 'Geo location sharing is blocked by your browser. If you want to share location then change the browser settings and upload again',
                icon: 'warning',
                buttons: ['Want to share location', 'Don\'t want to share Location'],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
                    if (uploaded.status != undefined) {
                      if (uploaded.status == 9000) {
                        swal({
                          title: 'Storage Full',
                          text: 'Your Storage is full. Please delete some files',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      } else {
                        swal({
                          title: 'Invalid file format uploaded',
                          text: 'Please upload an image in Jpeg or Png only.',
                          icon: 'error',
                          dangerMode: true,
                          className: 'swal-btn-center'
                        });
                      }
                    } else {
                      THIS.selectedFilesPreview.push(uploaded);
                    }
                  });
                } else {
                  swal('If you want to share location then change the browser settings and remove the block status');
                }
              });
            });
          } else {
            THIS._locPermit = -1;
            THIS.uploadThisMedia(eachFile, '1', null, function (uploaded) {
              if (uploaded.status != undefined) {
                if (uploaded.status == 9000) {
                  swal({
                    title: 'Storage Full',
                    text: 'Your Storage is full. Please delete some files',
                    icon: 'error',
                    dangerMode: true,
                    className: 'swal-btn-center'
                  });
                } else {
                  swal({
                    title: 'Invalid file format uploaded',
                    text: 'Please upload an image in Jpeg or Png only.',
                    icon: 'error',
                    dangerMode: true,
                    className: 'swal-btn-center'
                  });
                }
              } else {
                THIS.selectedFilesPreview.push(uploaded);
              }
            });
          }
        } else {
          this._uploadError = 1;
        }
      }
    }
  }

  dragFileOverStart() {
    let trigger = $('#uploadDragWork');
    trigger.addClass('onEnter');
  }

  dragFileOverEnd() {
    let trigger = $('#uploadDragWork');
    trigger.removeClass('onEnter');
  }

  selectedAssetAttach = [];

  uploadConfileTrigger() {
    $('#fileCon').click();
  }

  uploadConFile(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (var i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
        if (uploaded.status != undefined) {
          if (uploaded.status == 9000) {
            swal({
              title: 'Storage Full',
              text: 'Your Storage is full. Please delete some files',
              icon: 'error',
              dangerMode: true,
              className: 'swal-btn-center'
            });
          } else {
            swal({
              title: 'Invalid file format uploaded',
              text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
              icon: 'error',
              dangerMode: true,
              className: 'swal-btn-center'
            });
          }
        } else {
          THIS.selectedAssetAttach.push(uploaded);
        }
      });
    }
  }

  removeThisAttach(index, id) {
    this.selectedAssetAttach.splice(index, 1);
    this.removeThisMedia(id, 4);
  }

  addWorkAttach() {
    $('#workAttach').click();
  }

  uploadWorkAttach(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (this.selectedAssetAttach.length < 3) {
        let eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (uploaded) {
          if (uploaded.status != undefined) {
            if (uploaded.status == 9000) {
              swal({
                title: 'Storage Full',
                text: 'Your Storage is full. Please delete some files',
                icon: 'error',
                dangerMode: true,
                className: 'swal-btn-center'
              });
            } else {
              swal({
                title: 'Invalid file format uploaded',
                text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
                icon: 'error',
                dangerMode: true,
                className: 'swal-btn-center'
              });
            }
          } else {
            THIS.selectedAssetAttach.push(uploaded);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  confirmDiscard() {
    this.discard.emit(1);
  }

  rightSecPreview = 0;

  ngOnInit() {
    let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    $('.reRenderFacility').on('click', function () {
      THIS.getAllFacility();
    });
    //===============
    // Pusher
    //===============
    Pusher.logToConsole = true;
    let pusher = new Pusher('501b3b237bd799758df0', {
      cluster: 'ap4'
    });
    let channel = pusher.subscribe('bm-assets');
    channel.bind('RefreshAssetCategory', function (data) {
      if (Session.team === data.message.team) {
        THIS.getAllcategory();
      }
    });
    let channelWo = pusher.subscribe('bm-work-orders');
    channelWo.bind('RefreshFacility', function (data) {
      if (Session.team === data.message.team) {
        THIS.getAllRecursiveAssets();
      }
    });
    //===============
    // Pusher
    //===============
  }

  ngOnDestroy() {
  }


  UID_IMG = 0;
  // =============================
  // Photo Editor
  // =============================
  CANVAS = null;
  SHAPE = 'brush';
  IMAGE_PATH = '';
  CanvasTarget = 0;

  discardEditor() {
    let THIS = this;
    let modal = (<any>$('#photoEditorModal'));
    if (THIS.CANVAS != null) {
      THIS.CANVAS.dispose();
    }
    modal.modal('hide');

  }

  editImgCallUnique = 0;
  EditCurrentCanvas = null;
  CurrentEditPhoto = null;

  openPhotoEditor(preview) {
    let THIS = this;
    THIS.CurrentEditPhoto = preview;
    THIS.CanvasTarget = 0;
    let modal = (<any>$('#photoEditorModal'));
    let canvasW = window.innerWidth - 20;
    let canvasH = window.innerHeight - 130;
    $('._photoEditor_canvas').html('<canvas id="canvas" width="' + canvasW + 'px" height="' + canvasH + 'px"></canvas>');
    if (THIS.CANVAS != null) {
      this.CANVAS.clear();
    }
    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    modal.modal('show');
    let param = {id: preview.id, canvasW: canvasW, canvasH: canvasH};

    if (THIS.editImgCallUnique == 0) {
      THIS.editImgCallUnique = 1;
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/getEditDetails?token=' + THIS.currentUser.token,
        type: 'post',
        data: param,
        success: function (res) {
          THIS.editImgCallUnique = 0;
          if (res.status === 2000) {
            THIS.EditCurrentCanvas = res.data;
            let canvasW = res.data.width;
            let canvasH = res.data.height;
            $('#canvas').attr('width', canvasW + 'px');
            $('#canvas').attr('height', canvasH + 'px');
            setTimeout(function () {
              // if(THIS.CANVAS != null){
              //     THIS.CANVAS.dispose();
              // }

              THIS.CANVAS = new fabric.Canvas('canvas');
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.on('object:selected', function (e) {
                THIS.CANVAS.isDrawingMode = false;
                THIS.SHAPE = '';
              });
              THIS.CANVAS.freeDrawingBrush.color = '#ff3773';
              THIS.CANVAS.isDrawingMode = true;
              THIS.CANVAS.freeDrawingBrush.width = 5;

              let startY = 0,
                startX = 0;
              THIS.CANVAS.on('mouse:down', function (option) {
                startY = option.e.offsetY;
                startX = option.e.offsetX;
              });
              THIS.CANVAS.on('mouse:up', function (option) {
                let selected = THIS.CANVAS.getActiveObjects();
                if (selected.length === 0 && THIS.CANVAS.isDrawingMode === false) {
                  if (THIS.SHAPE === 'circle') {
                    let e = option.e;
                    if (e.offsetX - startX > 5) {
                      let circle = new fabric.Circle({
                        top: startY,
                        left: startX,
                        radius: (e.offsetX - startX) / 2,
                        stroke: THIS.CANVAS.freeDrawingBrush.color,
                        strokeWidth: 5,
                        fill: ''
                      });
                      THIS.CANVAS.add(circle);
                    }
                  }
                }
              });
              THIS.CustomHelper.tooltipRender();

              THIS.IMAGE_PATH = preview.file_path;
              let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&img=' + preview.file_path + '&rf=' + THIS.UID_IMG;
              $('.canvas-container').css({
                backgroundImage: 'url(\'' + src + '\')'
              });
              THIS.CANVAS.renderAll();
              setTimeout(function () {
                THIS.CanvasTarget = 1;
              }, 1000);
            }, 1000);
          }
        }
      });


      history.pushState(null, null, location.href);
      window.onpopstate = function () {
        modal.modal('hide');
        history.go(1);
      };

      if ($('.color-picker-editor').length > 0) {
        Pickr.create({
          el: '.color-picker-editor',
          theme: 'nano', // or 'monolith', or 'nano'
          defaultRepresentation: 'HEX',
          useAsButton: true,
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: true,
              input: true,
              save: true
            }
          }
        }).on('change', (color, instance) => {
          $('.color-picker-editor').css('color', color.toHEXA().toString());
          THIS.CANVAS.freeDrawingBrush.color = color.toHEXA().toString();
          THIS.CANVAS.getActiveObjects().forEach(function (obj) {
            obj.set('stroke', THIS.CANVAS.freeDrawingBrush.color);
          });
        });
      }
      $('.color-picker-editor').css('color', '#ff3773');
    }
  }

  // =============================
  // Photo Editor Actions
  // =============================
  SketchEnable() {
    this.CANVAS.isDrawingMode = !this.CANVAS.isDrawingMode;
    if (this.CANVAS.isDrawingMode === true) {
      this.SHAPE = 'brush';
    }
  }

  eraser() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    let obj = THIS.CANVAS.getObjects();
    for (let i = 0; i < obj.length; i++) {
      $.each(obj, function (j, v) {
        if (parseInt(j) > 0) {
          THIS.CANVAS.remove(v);
        }
      });
    }
    THIS.UID_IMG = Date.now();
    let src = THIS.APPCONFIG.uploadPreview + '/images?w=' + THIS.EditCurrentCanvas.width + '&no-loc=true&no-draw=true&img=' + THIS.CurrentEditPhoto.file_path + '&rf=' + THIS.UID_IMG;
    $('.canvas-container').css({
      backgroundImage: 'url(\'' + src + '\')'
    });
  }

  eraserSelected() {
    let THIS = this;
    THIS.CANVAS.isDrawingMode = false;
    THIS.SHAPE = '';
    THIS.CANVAS.getActiveObjects().forEach(function (obj) {
      THIS.CANVAS.remove(obj);
    });
  }

  drawCircle() {
    let THIS = this;
    this.CANVAS.isDrawingMode = false;
    this.SHAPE = 'circle';
    let circle = new fabric.Circle({
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      radius: 50,
      stroke: THIS.CANVAS.freeDrawingBrush.color,
      strokeWidth: 5,
      fill: ''
    });
    THIS.CANVAS.add(circle);
  }

  UpdatePhoto(iid) {
    let THIS = this;
    let ldd = document.getElementById(iid);
    let ladda = Ladda.create(ldd);
    ladda.start();
    THIS.CANVAS.discardActiveObject();
    THIS.CANVAS.requestRenderAll();
    setTimeout(function () {
      let modal = (<any>$('#photoEditorModal'));
      let canvas = (<any>document.getElementById('canvas'));
      let imgData = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      imgData = imgData.replace('data:image/png;base64,', '');
      let param = {
        imgData: imgData,
        media_type: 1,
        file_path: THIS.IMAGE_PATH
      };
      $.ajax({
        url: THIS.APPCONFIG.apiMedia + 'media/update?token=' + THIS.currentUser.token,
        data: param,
        type: 'post',
        success: function (res) {
          ladda.stop();
          modal.modal('hide');
          THIS.UID_IMG = Date.now();
        }
      });
    }, 1000);
  }

  checkCurrency() {
    Inputmask.extendAliases({
      pesos: {
        prefix: `${this.currentUser.settings.currency}`,
        groupSeparator: '',
        alias: 'numeric',
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        radixPoint: '.',
        placeholder: '0.00'
      }
    });

    setTimeout(function () {
      (<any>$('._currency_validate_eq')).inputmask({alias: 'pesos'});
    }, 300);
  }
  currencyIcon():string {
    if(this.currentUser.settings.currency == 'USD') {
      return '$';
    } else  {
      return this.currentUser.settings.currency + ' ';
    }
  }
  toggleImage() {
    $('#PhotoForm').slideToggle(100);
  }

}
