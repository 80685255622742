import {Component} from '@angular/core';
import {CustomHelper} from '../../customhelper';
import {APPCONFIG} from '../../config';
import {UserService} from '../../_services/user.service';
import {FacilityService} from '../../_services/facility.service';
import {WorkOrderService} from '../../_services/work-orders.service';
import {AuthService} from '../../_services/auth.service';
import {MediaService} from '../../_services/media.service';
import {FcmService} from '../../_services/fcm.service';

declare let swal: any;
declare let $: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
  selector: 'page-personnel',
  templateUrl: './personnel.component.html'
})
export class PersonnelComponent {
  CustomHelper = CustomHelper;
  APPCONFIG = APPCONFIG;
  Permission = 1;
  toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, 'closeButton': true, preventDuplicates: true};


  Total = 0;
  pageNo = 1;
  perPage = this.CustomHelper.getPerpage();
  pageEvent;
  sort_by = 'user_type';
  order_by = 'asc';

  paginateList() {
    if (this.pageEvent.pageSize == this.perPage) {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = this.pageEvent.pageIndex + 1;
      this.getAllData();
      localStorage.perpage = this.perPage;
    } else {
      this.perPage = this.pageEvent.pageSize;
      this.pageNo = 1;
      this.getAllData();
      localStorage.perpage = this.perPage;
    }
  }


  PageType = 1; // 1. personnel  2. Groups
  PageTypeChange(PageType) {
    this.selectedData.length = 0;
    this.PageType = PageType;
    this.Data.length = 0;
    this.DataGroup.length = 0;
    this.discardFresh();
    this.getAllData();
  }

  previewList = 0;
  Data = [];
  DataSingle = null;
  DataGroup = [];
  DataGroupSingle = null;

  getAllData() {
    let THIS = this;
    this.previewList = 0;
    let param = {
      keyword: this.searchKeyword,
      pageNo: this.pageNo,
      perPage: this.perPage,
      sort_by: this.sort_by,
      order_by: this.order_by,
      pageType: this.PageType
    };

    $.ajax({
      url: THIS.APPCONFIG.api + 'personnel/get/all?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        console.log(res);
        THIS.previewList = 1;
        if (res.status == 2000) {
          THIS.Total = res.total;
          let thisUsers = [];
          $.each(res.data, function (i, v) {
            if (v.id == THIS.currentUser.id) {
              v['name'] = v.first_name + ' ' + v.last_name;
              thisUsers.push(v);
            }
          });
          $.each(res.data, function (i, v) {
            if (v.id != THIS.currentUser.id) {
              v['name'] = v.first_name + ' ' + v.last_name;
              thisUsers.push(v);
            }
          });

          THIS.Data = thisUsers;
          setTimeout(function () {
            THIS.CustomHelper.tooltipRender();
          }, 100);
        }
      }
    });
  }

  selectedData = [];

  selectThisData(e, data) {
    this.DataSingle = null;
    e.preventDefault();
    if (this.currentUser.id != data.id) {
      if (e.ctrlKey) {
        if (e.which == 1) {
          let index = this.selectedData.indexOf(data.id);
          if (index > -1) {
            this.selectedData.splice(index, 1);
          } else {
            this.selectedData.push(data.id);
          }
        }
      } else {
        this.selectedData.length = 0;
        this.selectedData = [data.id];
        this.previewDataSingle(data);
        this._tab = 1;
        this.profileEdit = 0;


        this.SOR_projectItems.length = 0;
        this.SOR_projectItem_start = 0;
        this.SOR_projectItem_end = 10;
        this.SOR_projectItem_pageNo = 1;

        let ___selectedTab = 0;
        let tabs = $('#sort-tabs').find('li');
        tabs.each(function (i, v) {
          if ($(v).find('a.active').length > 0) {
            ___selectedTab = i;
          }
        });
        let child = ___selectedTab + 1;
        (<any>$('.owl-carousel')).owlCarousel('destroy');
        $('#sort-tabs').find('li').find('a').removeClass('active');
        $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
        (<any>$('#SingleUsertModal')).modal('show');
      }
    }

  }

  rightClickMenu(e, data) {
    e.preventDefault();

    if (this.selectedData.length < 2) {
      this.selectedData.length = 0;
      this.selectedData = [data.id];
      if (this.selectedData.indexOf(this.currentUser.id) != -1) {
        this.selectedData.length = 0;
        return false;
      }
    }

    let target = $(e.target);
    let w = target.closest('#right-click-holder').width();
    let h = target.closest('#right-click-holder').height();
    let p = target.closest('#right-click-holder').offset();
    let left = e.clientX - p.left;
    let top = e.clientY - p.top;
    let clickH = $('.drop-menu-right').height();
    // clickH = clickH < 150 ? 400 : clickH;
    if (this.selectedData.length > 1) {
      clickH = 150;
    }
    if ((w - left) < 230) {
      left = w - 250;
    }
    if (h < top + clickH) {
      top = top - (top + clickH - h);
    }
    if (top < 10) {
      top = 10;
    }

    let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
    if (ttarget.hasClass('open')) {
      $('.drop-menu-right').removeClass('open');
      ttarget.css({
        top: top,
        left: left
      });
      setTimeout(function () {
        ttarget.addClass('open');
      }, 100);
    } else {
      ttarget.css({
        top: top,
        left: left
      });
      ttarget.addClass('open');
    }
  }

  dropMenuRightToggle(e) {
    var container = $('.invoice-list');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $('.drop-menu-right').removeClass('open');
    }
  }

  checkAllRow(e) {
    console.log(this.Data.length);
    let THIS = this;
    let WO = this.Data;
    if (this.selectedData.length == this.Data.length - 1) {
      this.selectedData.length = 0;
    } else {
      this.selectedData.length = 0;
      $.each(WO, function (i, v) {
        if (v.id != THIS.currentUser.id) {
          THIS.selectedData.push(v.id);
        }
      });
    }

    setTimeout(function () {
      THIS.CustomHelper.tooltipRender();
    }, 100);
  }

  /*  checkAllRow(e) {
      let THIS = this;
      e.preventDefault();
      this.selectedData.length = [];
      console.log(this.selectedData.length );
      if (this.selectedData.length != this.Data.length) {
        this.Data.forEach(function (v) {
          THIS.selectedData.push(v.id);
        });
      } else {
        THIS.selectedData = [];
      }
      $('.drop-menu-right').removeClass('open');
    }*/

  checkThisRow(e, data) {
    e.preventDefault();
    let index = this.selectedData.indexOf(data.id);
    if (index > -1) {
      this.selectedData.splice(index, 1);
      /* if (this.selectedData.length == 1) {
           this.selectedData.length = 0;
       } else {
           this.selectedData.splice(index, 1);
       }*/
    } else {
      this.selectedData.push(data.id);
    }
    $('.drop-menu-right').removeClass('open');

  }

  selectThisRow(e, data) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.which == 1) {
        let index = this.selectedData.indexOf(data.id);
        if (index > -1) {
          this.selectedData.splice(index, 1);
        } else {
          this.selectedData.push(data.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    }

  }


  removedIds = [];

  removeDataPreview(id) {
    this.selectedData.length = 0;
    this.selectedData = [id];
    this.removeThisData();
  }

  deleteLoading;

  removeThisData() {
    this.deleteLoading = true;
    let ldd = document.getElementById('removeWorkOrderBtn');
    let ladda = Ladda.create(ldd);
    ladda.start();

    let THIS = this;
    let alertTitle = '';
    let alertMsg = '';
    if (this.PageType == 1) {
      alertTitle = 'Delete Personnel';
      alertMsg = 'Are you sure you want to delete these users? These users will be unassigned from any work order(s) assigned to if you proceed.';
      if (THIS.selectedData.length == 1) {
        let _id = THIS.selectedData[0];
        let index = this.Data.map(function (e) {
          return e.id;
        }).indexOf(_id);
        let _user = this.Data[index];
        if (_user.woAssign > 0) {
          alertMsg = 'Are you sure you want to delete this user? This user will be unassigned from any work order(s) assigned to if you proceed.';
        }
      }
    } else {
      alertTitle = 'Delete Group';
      alertMsg = 'Are you sure you want to delete these groups? These groups will be unassigned from any work order(s) assigned to if you proceed.';
      if (THIS.selectedData.length == 1) {
        let _id = THIS.selectedData[0];
        let index = this.Data.map(function (e) {
          return e.id;
        }).indexOf(_id);
        let _user = this.Data[index];
        if (_user.woAssign > 0) {
          alertMsg = 'Are you sure you want to delete this group? This group will be unassigned from any work order(s) assigned to if you proceed.';
        }
      }
    }
    let ids = THIS.selectedData;
    this.userService.removePersonnel({ids: ids}).subscribe(res => {
      this.getAllData();
      if (res.status == 2000) {
        ladda.stop();
        this.deleteLoading = false;
        this.Template = 0;
        let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
        THIS.selectedData.length = 0;
        toastr.success('Personnel group has been deleted successfully.', 'Personnel Group Deleted', THIS.toastrOption);
        (<any>$('#deleteWorkOrderModal')).modal('hide');
      }
    });
  }

  deleteThisWorkOrder() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#SingleUsertModal')).modal('hide');
    (<any>$('#deleteWorkOrderModal')).modal('show');
  }

  undoRemoveThisData(ids) {
    let THIS = this;
    /*THIS.assetService.undoRemoveAssets({ids: ids}).subscribe(res => {
        THIS.getAllData();
        swal('Removed assets has been restored successfully');
    });*/
  }

  reRenderGroups() {
    let THIS = this;
    setTimeout(function () {
      (<any>$('.chosen-default-multiple-group')).val(THIS.selectedProfileGroupId);
      (<any>$('.chosen-default-multiple-group')).chosen('destroy');
      (<any>$('.chosen-default-multiple-group')).chosen({
        allow_single_deselect: true,
        placeholder_text_multiple: 'Select groups'
      }).on('change', function (e, p) {
        if (p.selected != undefined) {
          THIS.selectedProfileGroupId.push(p.selected);
        } else {
          THIS.selectedProfileGroupId.splice(THIS.selectedProfileGroupId.indexOf(p.deselected), 1);
        }
      });
    }, 500);
  }

  editThisData() {
    let THIS = this;
    let id = [this.selectedData[0]];
    // this.previewData(id);
    this._tab = 1;
    this.profileEdit = 1;

    this.SOR_projectItems.length = 0;
    this.SOR_projectItem_start = 0;
    this.SOR_projectItem_end = 10;
    this.SOR_projectItem_pageNo = 1;

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#SingleUsertModal')).modal('show');
    setTimeout(function () {
      (<any>$('.chosen-default-multiple-group')).val(THIS.selectedProfileGroupId);
      (<any>$('.chosen-default-multiple-group')).chosen('destroy');
      (<any>$('.chosen-default-multiple-group')).chosen({
        allow_single_deselect: true,
        placeholder_text_multiple: 'Select groups'
      }).on('change', function (e, p) {
        if (p.selected != undefined) {
          THIS.selectedProfileGroupId.push(p.selected);
        } else {
          THIS.selectedProfileGroupId.splice(THIS.selectedProfileGroupId.indexOf(p.deselected), 1);
        }
      });
    }, 500);
  }


  searchKeyword = '';

  searchKeywordClear() {
    this.searchKeyword = '';
    this.getAllData();
  }

  filter() {
    let THIS = this;
    clearInterval(THIS.CustomHelper.woListSearch);
    THIS.CustomHelper.woListSearch = setInterval(function () {
      clearInterval(THIS.CustomHelper.woListSearch);
      THIS.getAllData();
    }, 1000);
  }

  highlighter(subject) {
    if (subject == '' && subject == null) {
      return subject;
    } else {
      subject = this.CustomHelper.makeStr(subject);
    }
    let matches = subject.match(new RegExp(this.searchKeyword, 'gi'));
    let res = '';
    $.each(matches, function (i, v) {
      res = subject.replace(v, `<span class='text-warning-highlight'> ${v} </span>`);
    });
    if (res == '') {
      res = subject;
    }
    return res;
  }

  currentUser = null;

  constructor(private userService: UserService,
              public workOrderService: WorkOrderService,
              public authService: AuthService,
              public mediaService: MediaService,
              public fcmService: FcmService,
              public facilityService: FacilityService) {
    let THIS = this;
    this.routeMake();
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.Permission = Session.user_type > 2 ? Session.modules.personnel : 1;
    this.currentUser = Session;
    // Pagination
    this.currentUser.pagination != null ? this.perPage = this.currentUser.pagination.acc : '';
    this.getAllData();
  }

  Template = 0;
  ROUTES = '/personnel';
  preId = '';
  GroupInfo = null;

  routeMake() {
    let route = this.CustomHelper.getRouteStr();
    if (route[2] != undefined) {
      if (route[2] == '') {
        this.Template = 0;
      } else if (route[2] == 'new') {
        this.createNewData();
      } else if (route[2] == 'edit') {
        if (route[3] != undefined) {
          let preId = route[3];
          this.editData(preId);
        }
      } else {
        let preId = route[2];
        this.previewData(preId);
      }
    } else {
      let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
      let url = '/' + Session.team + this.ROUTES;
      window.history.pushState('', '', url);
      this.Template = 0;
    }
  }

  previewData(preId) {
    /*this.Template = 2;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/'+Session.team+this.ROUTES+'/'+preId;
    this.preId = preId;
    window.history.pushState("","", url);
    this.selectedData.length = 0;
    this.selectedData.push(parseInt(preId));*/
    let THIS = this;

    this.selectedData.length = 0;
    this.selectedData = [preId];
    this._tab = 1;
    this.profileEdit = 0;
    this.Template = 0;
    this.uNotes.length = 0;

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#SingleUsertModal')).modal('show');

    this.uDocs.length = 0;
    this.userService.singlePersonnel({id: preId, docs: 1}).subscribe(res => {
      if (res.status == 2000) {
        this.DataSingle = res.data;
        this.uDocs = res.data.docs;
        this.selectedProfileGroupId.length = 0;
        $.each(res.data.groups, function (i, v) {
          THIS.selectedProfileGroupId.push(v.id.toString());
        });
        if (this.DataSingle.settings.mail_info == null) {
          this.DataSingle.settings.mail_info = {
            imap_port: '',
            incoming_server: '',
            outgoing_server: '',
            password: '',
            smtp_port: '',
            username: ''
          };
        }
        this.getAllGrouppersonnel();

        this.modAccess.length = 0;
        this.ModuleList = res.data.ModuleList;
        if (res.data.user_type < 3) {
          $.each(res.data.ModuleList, function (i, v) {
            THIS.modAccess.push(v.id);
          });
        } else {
          if (res.data.modules.work_orders == 1) {
            this.modAccess.push(2);
          }
          if (res.data.modules.invoices == 1) {
            this.modAccess.push(3);
          }
          if (res.data.modules.assets == 1) {
            this.modAccess.push(4);
          }
          if (res.data.modules.personnel == 1) {
            this.modAccess.push(5);
          }
          if (res.data.modules.inventory == 1) {
            this.modAccess.push(6);
          }
          if (res.data.modules.contacts == 1) {
            this.modAccess.push(7);
          }
          if (res.data.modules.calendar == 1) {
            this.modAccess.push(8);
          }
          if (res.data.modules.projects == 1) {
            this.modAccess.push(9);
          }
          if (res.data.modules.inspections == 1) {
            this.modAccess.push(10);
          }
          if (res.data.modules.reports == 1) {
            this.modAccess.push(11);
          }
          if (res.data.modules.library == 1) {
            this.modAccess.push(12);
          }
          if (res.data.modules.keys == 1) {
            this.modAccess.push(13);
          }
          if (res.data.modules.fm360 == 1) {
            this.modAccess.push(14);
          }
        }

        this.dtFacIdUpdate();
      }
    });
  }

  previewDataSlow(preId) {
    this.preId = '';
    // this.Template = 0;
    let THIS = this;
    setTimeout(function () {
      THIS.previewData(preId);
    }, 100);
  }

  previewDataSingle(data) {
    let preId = data.id;
    this.previewData(preId);
  }

  editData(preId) {
    this.Template = 3;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES + '/edit/' + preId;
    this.preId = preId;
    window.history.pushState('', '', url);
    this.selectedData.length = 0;
    this.selectedData.push(parseInt(preId));
  }

  editDataSlow(preId) {
    this.preId = '';
    // this.Template = 0;
    let THIS = this;
    setTimeout(function () {
      THIS.editData(preId);
    }, 100);
  }

  discardFresh() {
    this.Template = 0;
    this.preId = '';
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    let url = '/' + Session.team + this.ROUTES;
    window.history.pushState('', '', url);
    this.Template = 0;
  }

  selectedGroupData = [];

  rightClickMenuGroup(e, data) {
    e.preventDefault();

    if (this.selectedGroupData.length < 2) {
      this.selectedGroupData.length = 0;
      this.selectedGroupData = [data.id];
    }

    let target = $(e.target);
    let w = target.closest('#right-click-holder').width();
    let h = target.closest('#right-click-holder').height();
    let p = target.closest('#right-click-holder').offset();
    let left = e.clientX - p.left;
    let top = e.clientY - p.top;
    let clickH = $('.drop-menu-right').height();
    // clickH = clickH < 150 ? 400 : clickH;
    if (this.selectedGroupData.length > 1) {
      clickH = 150;
    }
    if ((w - left) < 230) {
      left = w - 250;
    }
    if (h < top + clickH) {
      top = top - (top + clickH - h);
    }
    if (top < 10) {
      top = 10;
    }

    let ttarget = target.closest('#right-click-holder').find('.drop-menu-right');
    if (ttarget.hasClass('open')) {
      $('.drop-menu-right').removeClass('open');
      ttarget.css({
        top: top,
        left: left
      });
      setTimeout(function () {
        ttarget.addClass('open');
      }, 100);
    } else {
      ttarget.css({
        top: top,
        left: left
      });
      ttarget.addClass('open');
    }
  }

  checkThisGroup(e, data) {
    e.preventDefault();
    let index = this.selectedGroupData.indexOf(data.id);
    if (index > -1) {
      if (this.selectedGroupData.length == 1) {
        this.selectedGroupData.length = 0;
      } else {
        this.selectedGroupData.splice(index, 1);
      }
    } else {
      this.selectedGroupData.push(data.id);
    }
    $('.drop-menu-right').removeClass('open');

  }

  selectThisGroup(e, data) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.which == 1) {
        let index = this.selectedGroupData.indexOf(data.id);
        if (index > -1) {
          this.selectedGroupData.splice(index, 1);
        } else {
          this.selectedGroupData.push(data.id);
        }
        $('.drop-menu-right').removeClass('open');
      }
    }

  }

  previewGroup(GroupInfo) {
    /*this.selectedGroupData = [GroupInfo.id];
    this.GroupInfo = GroupInfo;
    this.Template = 2;*/
    this.DataGroupSingle = GroupInfo;
    this._tab = 1;
    this.profileEdit = 0;

    let ___selectedTab = 0;
    let tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    let child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#SingleGroupModal')).modal('show');
  }

  editGroup(GroupInfo) {
    this.selectedGroupData = [GroupInfo.id];
    this.GroupInfo = GroupInfo;
    this.Template = 3;
  }

  removeGroupPreview(group) {
    this.selectedGroupData.length = 0;
    this.selectedGroupData = [group.id];
    this.removeThisGroup();
  }

  removeThisGroup() {
    let THIS = this;
    swal({
      title: 'Delete Group',
      text: 'Are you sure you want to delete this Group?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let ids = THIS.selectedGroupData;
        this.userService.removeGroup({ids: ids}).subscribe(res => {
          this.Template = 0;
          this.getAllData();
          swal({
            title: 'Success',
            text: res.msg,
            icon: 'success',
            dangerMode: false,
          }).then((willDelete) => {
            swal.stopLoading();
            swal.close();
          });
        });
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
  }


  // =================================
  // Modal Part
  // =================================
  previewDetails = 1;
  profileEdit = 0;
  _tab = 1;
  ProfileGroup = [];
  selectedProfileGroupId = [];

  getAllGrouppersonnel() {
    let THIS = this;
    let param = {
      keyword: '',
      pageNo: 1,
      perPage: 500,
      sort_by: 'first_name',
      order_by: 'asc',
      pageType: 2
    };
    this.userService.getAllPersonnel(param).subscribe(res => {
      if (res.status == 2000) {
        this.ProfileGroup = res.data;
      }
    });
  }

  updateGeneralInfo() {
    $('.rate-error').html('');
    let THIS = this;
    let ldd = document.getElementById('updateGenSorInfo');
    let ladda = Ladda.create(ldd);
    let uDocs = [];
    let errCheck = 0;
    $.each(this.uDocs, function (i, v) {
      if ($.trim(v.title).length == 0) {
        v.title = '';
        swal({
          text: 'Can\'t save document with blank title',
          icon: 'error',
          dangerMode: true,
          className: 'swal-btn-center'
        });
        errCheck = 1;
      }
      let e = v.expiry != null ? THIS.CustomHelper.formatDateTimeToDB(v.expiry) : v.expiry;
      v.expiry = e;
      uDocs.push(v);
    });
    if (errCheck == 1) {
      return true;
    }


    let names = this.DataSingle.name.split(' ');
    let first_name = names[0];
    let last_name = this.DataSingle.name.replace(names[0], '');
    let param = {
      id: this.DataSingle.id,
      first_name: first_name,
      last_name: last_name,
      avatar: this.DataSingle.avatar,
      email: this.DataSingle.email,
      phone: this.DataSingle.profile.phone,
      address: this.DataSingle.profile.company_address,
      hourly_rate: this.DataSingle.profile.hourly_rate,
      group_id: this.selectedProfileGroupId,
      mail_info: this.DataSingle.settings.mail_info,
      mail_info_active: this.DataSingle.settings.mail_info_active,
      facility_id: this.Data_facility_id,
      user_type: this.DataSingle.user_type,
      permission: this.DataSingle.permit,
      modAccess: this.modAccess,
      docs: uDocs
    };
    ladda.start();
    if (this.DataSingle.profile.hourly_rate < 0) {
      $('.rate-error').html('Hourly late should be positive value.');
      ladda.stop();
      return;
    }
    this.userService.editPersonnel(param).subscribe(res => {
      ladda.stop();
      if (res.status == 2000) {
        this.DataSingle = res.data;
        let THIS = this;
        toastr.success('Personnel information has been updated successfully', 'Personnel Information Update', THIS.toastrOption);
        this.profileEdit = 0;
        this.getAllData();
        this.previewData(THIS.DataSingle.id);
      } else {
        swal({
          text: res.error,
          icon: 'error',
          dangerMode: true,
          className: 'swal-btn-center'
        });
      }
    });
  }

  removeNegativeSign() {
    $('.rate-error').html('');
    if (this.DataSingle.profile.hourly_rate < 0) {
      this.DataSingle.profile.hourly_rate = parseInt(this.DataSingle.profile.hourly_rate.toString().substr(1));
    }
  }

  DataSingleWork = [];
  woHistory = {
    sort_by: 'creation_date',
    order_by: 'desc',
    searchKeyword: '',
    pageNo: 1,
    perPage: 10,
    totalWorkOrders: 0
  };

  paginateWoList() {
    if (this.pageEvent.pageSize == this.woHistory.perPage) {
      this.woHistory.perPage = this.pageEvent.pageSize;
      this.woHistory.pageNo = this.pageEvent.pageIndex + 1;
      this.getWorkOrderRelates();
    } else {
      this.woHistory.perPage = this.pageEvent.pageSize;
      this.woHistory.pageNo = 1;
      this.getWorkOrderRelates();
    }
  }

  getWorkOrderRelates() {
    let THIS = this;
    this.previewDetails = 0;
    let param = {
      sort_by: this.woHistory.sort_by,
      order_by: this.woHistory.order_by,
      is_active: 1,
      keyword: this.woHistory.searchKeyword,
      pageNo: this.woHistory.pageNo,
      perPage: this.woHistory.perPage,
      status: [],
      createdBy: this.DataSingle != null ? this.DataSingle.id : 0,
      onlyMe: 1
    };
    this.DataSingleWork.length = 0;
    this.workOrderService.getWorkOrdes(param).subscribe(res => {
      this.previewDetails = 1;
      if (res.status == 2000 && res.data.length > 0) {
        let Data = [];
        $.each(res.data, function (i, v) {
          Data.push(v);
        });
        this.DataSingleWork = Data;
        this.woHistory.totalWorkOrders = res.total;
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      } else {
        this.DataSingleWork.length = 0;
        this.woHistory.totalWorkOrders = 0;
      }
    });
  }

  getWorkOrderGroupRelates() {
    let THIS = this;
    this.previewDetails = 0;
    let param = {
      sort_by: this.woHistory.sort_by,
      order_by: this.woHistory.order_by,
      is_active: 1,
      keyword: this.woHistory.searchKeyword,
      pageNo: this.woHistory.pageNo,
      perPage: this.woHistory.perPage,
      status: [],
      groupBy: [],
      onlyMe: 1
    };
    $.each(THIS.DataGroupSingle.users, function (i, v) {
      param.groupBy.push(v.id);
    });
    this.DataSingleWork.length = 0;
    this.workOrderService.getWorkOrdes(param).subscribe(res => {
      this.previewDetails = 1;
      if (res.status == 2000 && res.data.length > 0) {
        let Data = [];
        $.each(res.data, function (i, v) {
          Data.push(v);
        });
        this.DataSingleWork = Data;
        this.woHistory.totalWorkOrders = res.total;
        setTimeout(function () {
          THIS.CustomHelper.tooltipRender();
        }, 100);
      } else {
        this.DataSingleWork.length = 0;
        this.woHistory.totalWorkOrders = 0;
      }
    });
  }

  showGraph = 0;
  lineOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          // max: 150,
          min: 0,
          stepSize: 1
        }
      }]
    },
    legend: {
      display: false
    },
  };
  dataGraph = {
    labels: ['Day 7', 'Day 6', 'Day 5', 'Day 4', 'Day 3', 'Yesterday', 'Today'],
    datasets: [{
      label: '',
      backgroundColor: '#a1a1a1',
      borderColor: '#a1a1a1',
      pointBackgroundColor: '#d53d43',
      pointBorderColor: '#d53d43',
      data: [0, 0, 0, 0, 0, 0, 0],
      fill: false,
      lineTension: 0,
    }]
  };

  getPersonalLoginHistory() {
    this.previewDetails = 0;
    let id = this.DataSingle != null ? this.DataSingle.id : 0;
    this.showGraph = 0;
    this.authService.getUserLoginHistory(id).subscribe(res => {
      this.previewDetails = 1;
      this.dataGraph.datasets[0]['data'] = res.data.reverse();
      this.showGraph = 1;
    });
  }

  modAccess = [];
  ModuleList = [];

  selectModAccess(id) {
    let THIS = this;
    if (id == 1) {
      this.modAccess.length = 0;
      $.each(THIS.ModuleList, function (i, v) {
        THIS.modAccess.push(v.id);
      });
    } else {
      if (this.modAccess.indexOf(1) > -1) {
        this.modAccess.splice(this.modAccess.indexOf(1), 1);
      }
      let index = this.modAccess.indexOf(id);
      if (index > -1) {
        this.modAccess.splice(index, 1);
      } else {
        this.modAccess.push(id);
      }
    }
  }

  Data_facility_id = [];

  dtFacIdUpdate() {
    let THIS = this;
    THIS.Data_facility_id.length = 0;
    $.each(THIS.DataSingle.facilities, function (i, v) {
      THIS.Data_facility_id.push(v.id.toString());
    });
  }

  changePermission(id) {
    this.DataSingle.permit = id;
  }

  selectUserType(type) {
    this.DataSingle.user_type = type;
    if (type == 2) {
      this.DataSingle.permit = 1;
      this.modAccess.length = 0;
      this.modAccess = [1, 2, 3, 4, 5, 6, 7, 8];

      let THIS = this;
      setTimeout(function () {
        (<any>$('.chosen-default-single-fac')).val('0');
        (<any>$('.chosen-default-single-fac')).attr('disabled', true);
        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).chosen({
          allow_single_deselect: true,
          placeholder_text_multiple: 'Select Facility'
        }).on('change', function (e, p) {
          if (p.selected != undefined) {
            THIS.Data_facility_id.push(p.selected);
          } else {
            THIS.Data_facility_id.splice(THIS.Data_facility_id.indexOf(p.deselected), 1);
          }
        });
      }, 400);

    } else {
      let THIS = this;
      setTimeout(function () {
        (<any>$('.chosen-default-single-fac')).val(THIS.Data_facility_id);
        (<any>$('.chosen-default-single-fac')).removeAttr('disabled');
        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).chosen({
          allow_single_deselect: true,
          placeholder_text_multiple: 'Select Facility'
        }).on('change', function (e, p) {
          if (p.selected != undefined) {
            THIS.Data_facility_id.push(p.selected);
          } else {
            THIS.Data_facility_id.splice(THIS.Data_facility_id.indexOf(p.deselected), 1);
          }
        });
      }, 400);
    }


  }

  uNotes = [];
  newNotes = '';
  newNtStatus = 0;

  createNewNote() {
    let ladda;
    let ldd = document.querySelector('.notes_input');
    ladda = Ladda.create(ldd);
    ladda.start();
    if (this.editNoteItem == null) {
      if (this.newNotes != '') {
        this.newNtStatus = 1;
        let param = {
          user_id: this.DataSingle.id,
          note: this.newNotes,
        };
        this.userService.noteCreate(param).subscribe(res => {
          console.log(2);
          this.newNotes = '';
          this.newNtStatus = 0;
          this.editNoteItemReady = 0;
          this.getAllNote();
        });
      }
    } else {
      if (this.newNotes != '') {
        this.newNtStatus = 1;
        let param = {
          id: this.editNoteItem.id,
          note: this.newNotes,
        };
        this.userService.noteEdit(param).subscribe(res => {
          this.newNotes = '';
          this.newNtStatus = 0;
          this.editNoteItem = null;
          this.editNoteItemReady = 0;
          this.getAllNote();
        });
      }
    }
  }

  updateEyesOnly(note) {
    note.my_eyes_only = note.my_eyes_only == 1 ? 0 : 1;
    let param = {
      id: note.id,
      my_eyes_only: note.my_eyes_only,
    };
    this.userService.noteEdit(param).subscribe(res => {
      this.getAllNote();
    });
  }

  getAllNote() {
    let THIS = this;
    this.userService.noteGetAll({user_id: [this.DataSingle.id]}).subscribe(res => {
      let Data = [];
      $.each(res.data, function (i, v) {
        v['created_at_human'] = THIS.CustomHelper.formatDate(v.created_at);
        Data.push(v);
      });
      this.uNotes = Data;
      console.log(this.uNotes);
    });
  }

  delNotId = 0;

  deleteThisNote() {
    let THIS = this;
    let ladda;
    const ldd = document.querySelector('#removeNoteBtn');
    ladda = Ladda.create(ldd);
    ladda.start();
    let param = {ids: [THIS.delNotId]};
    $.ajax({
      url: THIS.APPCONFIG.api + 'users/notes/remove?token=' + THIS.currentUser.token,
      type: 'post',
      data: param,
      success: function (res) {
        ladda.stop();
        if (res.status == 2000) {
          toastr.success('Note has been deleted successfully', 'Note Delete', THIS.toastrOption);
          THIS.getAllNote();
          (<any>$('#deleteNoteModal')).modal('hide');
          (<any>$('#SingleUsertModal')).modal('show');
        }
      }
    });
  }

  deleteNoteOpen() {
    let ___selectedTab = 0;
    const tabs = $('#sort-tabs').find('li');
    tabs.each(function (i, v) {
      if ($(v).find('a.active').length > 0) {
        ___selectedTab = i;
      }
    });
    const child = ___selectedTab + 1;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    $('#sort-tabs').find('li').find('a').removeClass('active');
    $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
    (<any>$('#SingleUsertModal')).modal('hide');
    (<any>$('#deleteNoteModal')).modal('show');
  }

  editNoteItem = null;
  editNoteItemReady = 0;

  editThisNote() {
    if (this.editNoteItem != null) {
      this.newNotes = this.editNoteItem.note;
      this.editNoteItemReady = 1;
    }
  }

  openProfile() {
    $('#myProfileGo').click();
    // swal('On processing. Dev not done yet');
  }


  uDocs = [];

  docGetAll() {
    /*this.docCreate.user_id = this.DataSingle.id;
    let param = this.docCreate;
    this.userService.docGetAll({user_id : [this.DataSingle.id]}).subscribe(res => {
        this.uDocs = res.data;
    });*/
  }

  addNewDoc = 0;

  cancelDocUpload(index) {
    if (index > -1) {
      this.uDocs.splice(index, 1);
    }
  }

  addNewDocItem() {
    let docCreate = {
      user_id: this.DataSingle.id,
      title: 'Untitled',
      expiry: null,
      notify_admin: 0,
      notify_admin_option: '0',
      notify_user: 0,
      notify_user_option: '0',
      files: []
    };
    this.uDocs.splice(0, 0, docCreate);
  }

  dateCreatedOption = [];

  dateCreatedOptionGen(index, dateTime) {
    let THIS = this;
    this.dateCreatedOption[index] = {
      timePicker: true,
      enableTime: true,
      altInput: true,
      minDate: 'today',
      defaultDate: dateTime != null ? new Date(dateTime) : null,
      altFormat: this.CustomHelper.dateFormatFlat() + ' ' + this.CustomHelper.timeFormatFlat('format'),
      onChange: function (selectedDates, dateStr, instance) {
      },
    };
    this.dateCreatedOption[index].onChange = function (selectedDates, dateStr, instance) {
      THIS.uDocs[index].expiry = dateStr;
    };

    return this.dateCreatedOption[index];
  }

  uploadThisMedia(file, media_type, localtion, callback) {
    let formData = new FormData();
    formData.append('files', file);
    formData.append('media_type', media_type);
    if (localtion != null) {
      formData.append('lat', localtion.lat);
      formData.append('lon', localtion.lon);
    }
    this.mediaService.create(formData).subscribe(res => {
      callback(res);
    });
  }

  selectDocFiles(e, index) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      if (THIS.uDocs[index].files.length == 0) {
        let eachFile = accepted[i];
        this.uploadThisMedia(eachFile, '4', null, function (res) {
          if (res.status != undefined) {
            swal({
              title: 'Invalid File Type',
              text: 'Invalid File type. Only Document files (Doc, DOCX, PPT, PPTX, TXT, PDF) are allowed',
              icon: 'error',
              dangerMode: true,
              className: 'swal-btn-center'
            });
          } else {
            THIS.uDocs[index].files.push(res);
          }
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  chooseDocFile(e) {
    $(e.target).find('.eachDocFile').click();
  }

  Facility = [];
  FacilityComp = [];
  facIds = [];

  getAllFacility() {
    let THIS = this;
    this.facilityService.getFacilitiesList().subscribe(res => {
      this.Facility.length = 0;
      this.FacilityComp.length = 0;
      this.FacilityComp = res.facility;
      $.each(res.facility, function (i, v) {
        v['pp'] = 0;
        THIS.Facility.push(v);
        THIS.facIds.push(v.id);
        $.each(v.child, function (j, vC) {
          vC['pp'] = 1;
          THIS.Facility.push(vC);
          THIS.facIds.push(vC.id);
          $.each(vC.child, function (k, vCC) {
            vCC['pp'] = 2;
            THIS.Facility.push(vCC);
            THIS.facIds.push(vCC.id);
            $.each(vCC.child, function (l, vCCC) {
              vCCC['pp'] = 3;
              THIS.Facility.push(vCCC);
              THIS.facIds.push(vCCC.id);
            });
          });
        });
      });
      setTimeout(function () {
        (<any>$('.chosen-default-single-fac')).val(THIS.Data_facility_id);
        (<any>$('.chosen-default-single-fac')).chosen('destroy');
        (<any>$('.chosen-default-single-fac')).chosen({
          allow_single_deselect: true,
          placeholder_text_multiple: 'Select Facility'
        }).on('change', function (e, p) {
          if (p.selected != undefined) {
            if (p.selected != '0') {
              let ind = THIS.Data_facility_id.indexOf('0');
              if (ind > -1) {
                THIS.Data_facility_id.splice(THIS.Data_facility_id.indexOf('0'), 1);
              }
            } else {
              THIS.Data_facility_id.length = 0;
            }
            THIS.Data_facility_id.push(p.selected);
          } else {
            THIS.Data_facility_id.splice(THIS.Data_facility_id.indexOf(p.deselected), 1);
          }

          (<any>$('.chosen-default-single-fac')).val(THIS.Data_facility_id);
          (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
        });
      }, 100);
    });
  }


  createNewData() {
    this.param_name = '';
    this.selectedFilesPreview = '';
    this.param = {
      avatar: '',
      first_name: '',
      last_name: '',
      user_type: 3,
      email: '',
      phone: '',
      address: '',
      hourly_rate: '',
      group_id: [],
      licenced: 0,
      abn: '',
    };
    $('.name-error').html('');
    $('.email-error').html('');
    $('.rate-error').html('');
    if (this.PageType == 1) {
      this.param.user_type = 3;

      let ___selectedTab = 0;
      let tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      let child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#createPersonnelModal')).modal('show');
      this.getAllGroupCr();
    }
    if (this.PageType == 2) {
      this.param.user_type = 6;

      let ___selectedTab = 0;
      let tabs = $('#sort-tabs').find('li');
      tabs.each(function (i, v) {
        if ($(v).find('a.active').length > 0) {
          ___selectedTab = i;
        }
      });
      let child = ___selectedTab + 1;
      (<any>$('.owl-carousel')).owlCarousel('destroy');
      $('#sort-tabs').find('li').find('a').removeClass('active');
      $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
      (<any>$('#createGroupModal')).modal('show');
    }
  }

  param_name = '';
  param = {
    avatar: '',
    first_name: '',
    last_name: '',
    user_type: 3,
    email: '',
    phone: '',
    address: '',
    hourly_rate: '',
    group_id: [],
    licenced: 0,
    abn: '',
  };
  validForm = 0;

  saveSubmit() {
    if (this.currentUser.subscription.status == 0 && this.currentUser.subscription.plan == '') {
      let freeAccountData = localStorage.getItem('freeAccountData');
      if (freeAccountData != null) {
        let freeAccount = JSON.parse(freeAccountData);
        if ((freeAccount['facility_count'] >= freeAccount['facility_limit']) ||
          (freeAccount['workorder_count'] >= freeAccount['workorder_limit']) ||
          (freeAccount['storage_count'] >= freeAccount['storage_limit']) ||
          (freeAccount['user_count'] >= freeAccount['user_limit'])) {
          $('.openSubscription').click();
          return false;
        }
      }
    }
    let ladda;
    if (this.PageType == 1) {
      let ldd = document.getElementById('crtPerson');
      ladda = Ladda.create(ldd);
    } else {
      let ldd = document.getElementById('crtGroup');
      ladda = Ladda.create(ldd);
    }
    let THIS = this;
    let param_name = this.param_name.split(' ');
    this.param.first_name = param_name[0] != undefined ? param_name[0] : '';
    this.param.last_name = this.param_name.replace(param_name[0], '');
    this.param.avatar = this.selectedFilesPreview;
    let valid = this.validationThisForm();
    if (valid == 1) {
      ladda.start();
      this.userService.createPersonnel(this.param).subscribe(res => {
        ladda.stop();
        if (res.status != undefined && res.status == 3000) {
          let ___selectedTab = 0;
          const tabs = $('#sort-tabs').find('li');
          tabs.each(function (i, v) {
            if ($(v).find('a.active').length > 0) {
              ___selectedTab = i;
            }
          });
          const child = ___selectedTab + 1;
          (<any>$('.owl-carousel')).owlCarousel('destroy');
          $('#sort-tabs').find('li').find('a').removeClass('active');
          $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
          (<any>$('#CreateUserLimit')).modal('show');
        } else if (res.status == 2000) {
          THIS.getAllData();
          if (this.PageType == 1) {
            toastr.success('New personnel has been successfully created', 'New personnel', THIS.toastrOption);
          } else {
            toastr.success('New group has been successfully created', 'New Group', THIS.toastrOption);
          }
          (<any>$('#createPersonnelModal')).modal('hide');
          (<any>$('#createGroupModal')).modal('hide');
          let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
          this.param_name = '';
          this.selectedFilesPreview = '';
          this.param = {
            avatar: '',
            first_name: '',
            last_name: '',
            user_type: 3,
            email: '',
            phone: '',
            address: '',
            hourly_rate: '',
            group_id: [],
            licenced: 0,
            abn: '',
          };
        } else if (res.status == 7000) {
          $('.email-error').html(res.msg);
          console.log(res.msg);
        } else {
          swal({
            text: res.error,
            icon: 'error',
            dangerMode: true,
            className: 'swal-btn-center'
          });
        }
      });
    }
  }

  CloseCreateUserLimit() {
    (<any>$('#CreateUserLimit')).modal('hide');
  }

  cancelCreatePerson() {
    this.param_name = '';
    this.selectedFilesPreview = '';
    this.param = {
      avatar: '',
      first_name: '',
      last_name: '',
      user_type: 3,
      email: '',
      phone: '',
      address: '',
      hourly_rate: '',
      group_id: [],
      licenced: 0,
      abn: '',
    };
  }

  validationThisForm() {
    let valid = 1;
    this.checkThisForm();
    $('.name-error').html('');
    $('.email-error').html('');
    $('.rate-error').html('');
    console.log(Number(this.param.hourly_rate));
    if (this.param.first_name == '') {
      valid = 0;
      $('.name-error').html('Name is required');
      return valid;
    }
    if (this.param.email == '') {
      valid = 0;
      $('.email-error').html('Email address is required');
      return valid;
    } else {
      let check = this.CustomHelper.emailValidation(this.param.email);
      if (!check) {
        $('.email-error').html(`${this.param.email} - is not a valid email address. Please type valid email address.`);
        this.param.email = '';
        valid = 0;
        return valid;
      }
    }
    if (this.param.hourly_rate !== '' && Number(this.param.hourly_rate) < 0) {
      valid = 0;
      $('.rate-error').html('Hourly late should be positive value.');
      return valid;
    }
    this.validForm = valid;
    return valid;
  }

  checkThisForm() {
    let param_name = this.param_name.split(' ');
    this.param.first_name = param_name[0] != undefined ? param_name[0] : '';
    this.param.last_name = this.param_name.replace(param_name[0], '');
    let valid = 1;
    if (this.param.first_name == '') {
      valid = 0;
    }
    if (this.param.email == '') {
      valid = 0;
    }
    this.validForm = valid;
    return valid;
  }


  DataGroupCr = [];

  getAllGroupCr() {
    let THIS = this;
    let param = {
      keyword: '',
      pageNo: 1,
      perPage: 500,
      sort_by: 'first_name',
      order_by: 'asc',
      pageType: 2
    };
    this.userService.getAllPersonnel(param).subscribe(res => {
      if (res.status == 2000) {
        this.DataGroupCr = res.data;
        setTimeout(function () {
          (<any>$('.chosen-default-multiple-group-modal')).chosen('destroy');
          (<any>$('.chosen-default-multiple-group-modal')).chosen({
            allow_single_deselect: true,
            placeholder_text_multiple: 'Select groups'
          }).on('change', function (e, p) {
            if (p.selected != undefined) {
              THIS.param.group_id.push(p.selected);
            } else {
              THIS.param.group_id.splice(THIS.param.group_id.indexOf(p.deselected), 1);
            }
          });
        }, 500);
      }
    });
  }

  avatarLoading = 0;
  selectedFilesPreview = '';

  selectTheseFiles(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    for (let i = 0; i < accepted.length; i++) {
      let eachFile = accepted[i];
      if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg') {
        THIS.avatarLoading = 1;
        this.uploadThisMedia(eachFile, '1', null, function (uploaded) {
          THIS.avatarLoading = 0;
          THIS.selectedFilesPreview = uploaded.file_path;
        });
      } else {
        swal({
          text: 'Only jpg/jpeg and png files are allowed',
          icon: 'error',
          dangerMode: true,
          className: 'swal-btn-center'
        });
      }
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }


  SOR_clients = [];
  SOR_client = '';

  getAllClients() {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'sor/project/getAllSor?token=' + THIS.currentUser.token,
      type: 'get',
      data: {},
      success: function (res) {
        if (res.status == 2000) {
          THIS.SOR_clients = res.data;
          setTimeout(function () {
            (<any>$('.clientData')).chosen('destroy');
            (<any>$('.clientData')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Client'
            }).on('change', function (e, p) {
              if (p != undefined) {
                THIS.SOR_client = p.selected;
                THIS.getAllProjects();
              }
            });
            (<any>$('.projectData')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Project'
            });
          }, 100);
        }
      }
    });
  }

  SOR_projects = [];
  SOR_project = '';

  getAllProjects() {
    let THIS = this;
    $.ajax({
      url: THIS.APPCONFIG.api + 'sor/project/getAll?token=' + THIS.currentUser.token,
      type: 'post',
      data: {sor_id: THIS.SOR_client, personId: THIS.DataSingle.id},
      success: function (res) {
        if (res.status == 2000) {
          THIS.SOR_projects = res.data;
          setTimeout(function () {
            (<any>$('.projectData')).chosen('destroy');
            (<any>$('.projectData')).chosen({
              allow_single_deselect: true,
              placeholder_text_single: 'Select Project'
            }).on('change', function (e, p) {
              if (p != undefined) {
                THIS.SOR_project = p.selected;
                THIS.getAllProjectItems();
              }
            });
          }, 100);
        }
      }
    });
  }

  SOR_projectItems = [];
  SOR_projectItem_start = 0;
  SOR_projectItem_end = 10;
  SOR_projectItem_pageNo = 1;
  SOR_empty = 0;

  getAllProjectItems() {
    let THIS = this;

    this.SOR_projectItem_start = 0;
    this.SOR_projectItem_end = 10;
    this.SOR_projectItem_pageNo = 1;

    let index = this.SOR_projects.map(function (e) {
      return e.id.toString();
    }).indexOf(THIS.SOR_project);
    if (index > -1) {
      this.SOR_empty = 0;
      this.SOR_projectItems = this.SOR_projects[index].info;
      if (this.SOR_projectItems.length == 0) {
        this.SOR_empty = 1;
      }
    }
  }

  SOR_pro_item_pagination(type) {
    if (type == 'next') {
      this.SOR_projectItem_pageNo++;
      this.SOR_projectItem_start = (this.SOR_projectItem_pageNo - 1) * 10;
      this.SOR_projectItem_end = (this.SOR_projectItem_pageNo - 0) * 10;
      if (this.SOR_projectItem_end > this.SOR_projectItems.length) {
        this.SOR_projectItem_end = this.SOR_projectItems.length;
      }
    }
    if (type == 'prev') {
      if (this.SOR_projectItem_pageNo > 1) {
        this.SOR_projectItem_pageNo--;
        this.SOR_projectItem_start = (this.SOR_projectItem_pageNo - 1) * 10;
        this.SOR_projectItem_end = (this.SOR_projectItem_pageNo - 0) * 10;
        if (this.SOR_projectItem_end > this.SOR_projectItems.length) {
          this.SOR_projectItem_end = this.SOR_projectItems.length;
        }
      }
    }
  }

  saveSorUserData() {
    let THIS = this;
    let ldd = document.getElementById('updateGenSorInfo');
    let ladda = Ladda.create(ldd);
    if (THIS.DataSingle.user_type != 3 && THIS.DataSingle.user_type != 6) {
      let Data = this.SOR_projectItems;

      let items = [];
      $.each(Data, function (i, v) {
        if (v.rate != null) {
          items.push({sor_item_id: v.id, user_id: THIS.DataSingle.id, rate: v.rate});
        }
      });

      if (items.length > 0) {
        ladda.start();
        $.ajax({
          url: THIS.APPCONFIG.api + 'sor/user/add?token=' + THIS.currentUser.token,
          type: 'post',
          data: {sor: items},
          success: function (res) {
            ladda.stop();
            if (res.status == 2000) {
              swal({
                title: 'Item Update',
                text: 'SOR item have been updated successfully',
                icon: 'success',
                dangerMode: false,
                className: 'swal-btn-center'
              });
            }
          }
        });
      }
    }
  }


  uploadAvatar() {
    if (this.profileEdit == 1) {
      $('#changeAvatar').click();
    }
  }

  uploadThisAvatar(e) {
    e.preventDefault();
    let THIS = this;
    let accepted = e.target.files;
    let eachFile = accepted[0];
    if (eachFile.type == 'image/png' || eachFile.type == 'image/jpeg' || eachFile.type == 'image/jpg' || eachFile.type == 'image/gif') {
      this.uploadThisMedia(eachFile, '1', null, function (uploaded) {
        THIS.DataSingle.avatar = uploaded.file_path;
      });
    } else {
      swal({
        title: 'Invalid File Type',
        text: 'This file is not supported for avatar. Please select jpg/png/gif file.',
        icon: 'error',
        dangerMode: true,
        className: 'swal-btn-center'
      });
    }
    e.target.type = 'text';
    e.target.type = 'file';
  }

  ngOnInit() {
    // let THIS = this;
    let Session = JSON.parse(localStorage.getItem('currentUserInfo'));
    // this.socket.on('_rfr_team_data_User_'+Session.team, function (v) {
    //     //THIS.getAllAssets();
    // });
    console.log(this.selectedData.length);
  }

  ngOnDestroy() {
  }

  currencyIcon(): string {
    if (this.currentUser.settings.currency == 'USD') {
      return '$';
    } else {
      return this.currentUser.settings.currency + ' ';
    }
  }

}
