import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssetService } from '../../_services/asset.service';
import { AuthService } from '../../_services/auth.service';
import { ContactsService } from '../../_services/contacts.service';
import { FacilityService } from '../../_services/facility.service';
import { InventoriesService } from '../../_services/inventories.service';
import { InvoicesService } from '../../_services/invoices.service';
import { Job_statusService } from '../../_services/job_status.service';
import { Job_typesService } from '../../_services/job_types.service';
import { MediaService } from '../../_services/media.service';
import { UserService } from '../../_services/user.service';
import { WorkOrderService } from '../../_services/work-orders.service';
import { CustomHelper } from '../../customhelper';
import { APPCONFIG } from '../../config';
declare let swal: any;

@Component({
    selector: 'my-page-team-activation',
    styles: [],
    templateUrl: './teamActivation.component.html'
})

export class TeamActivationComponent {

    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    user = {
        pin: '',
    };
    loading = 1;
    errorMessage = '';

    constructor( private router: Router,
                 private assetService: AssetService,
                 private authService: AuthService,
                 private contactsService: ContactsService,
                 private facilityService: FacilityService,
                 private inventoriesService: InventoriesService,
                 private invoicesService: InvoicesService,
                 private job_statusService: Job_statusService,
                 private job_typesService: Job_typesService,
                 private mediaService: MediaService,
                 private userService: UserService,
                 private workOrderService: WorkOrderService)
    {
        delete localStorage.currentUserInfo;
        let comp = this.CustomHelper.getRouteStr();
        this.user.pin = comp[2];
        this.login();
    }

    className = 'animated fadeInUp';
    login() {
        let THIS = this;
        this.loading = 1;
        $.ajax({
            url: THIS.APPCONFIG.apiAuth+'activation/team',
            type: 'post',
            data: THIS.user,
            success: function (res) {
                if (res.status == 2000) {
                    THIS.loading = 2;
                    localStorage.currentUserInfo = JSON.stringify(res.data);
                    setTimeout(function () {
                        THIS.assetService.refreshToken();
                        THIS.authService.refreshToken();
                        THIS.contactsService.refreshToken();
                        THIS.facilityService.refreshToken();
                        THIS.inventoriesService.refreshToken();
                        THIS.invoicesService.refreshToken();
                        THIS.job_statusService.refreshToken();
                        THIS.job_typesService.refreshToken();
                        THIS.mediaService.refreshToken();
                        THIS.userService.refreshToken();
                        THIS.workOrderService.refreshToken();
                        localStorage.firstComeWelcome = 1;
                        THIS.router.navigate(['/'+res.data.team+'/dashboard']);
                    },2000);
                } else {
                    THIS.errorMessage = 'Please fill up the form correctly. Information are not valid';
                    THIS.loading = 0;
                }
            }
        });
    }

}
