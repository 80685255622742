import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { APPCONFIG } from '../config';

import { FacilityModel } from '../_models/facility';

import { HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";
import {Job_typeModel} from "../_models/job_type";

@Injectable()
export class Job_statusService {

    public token: string;
    job_types = [];

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.refreshToken();
    }
    refreshToken(){
        let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.token = currentUser && currentUser.token;
    }

    getJobStatusList(): Observable<any> {
            return this.http
                .get<any>(`${APPCONFIG.api}job-status?token=`+this.token)
                .pipe(map(res => {
                    return res;
                }));
    }
    createJobStatus(param): Observable<any> {
            return this.http
                .post<any>(`${APPCONFIG.api}job-status?token=`+this.token, param)
                .pipe(map(res => {
                    return res;
                }));
    }
    editJobStatus(param): Observable<any> {
            return this.http
                .post<any>(`${APPCONFIG.api}job-status/`+param.id+`?token=`+this.token, {name:param.name})
                .pipe(map(res => {
                    return res;
                }));
    }
    removeJobStatus(id): Observable<any> {
            return this.http
                .delete<any>(`${APPCONFIG.api}job-status/`+id+`?token=`+this.token)
                .pipe(map(res => {
                    return res;
                }));
    }

}
