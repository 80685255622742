import {Component, EventEmitter, Output, Input, OnInit} from '@angular/core';
import {CustomHelper} from '../../../customhelper';
import {APPCONFIG} from '../../../config';
declare let swal: any;
declare let Ladda: any;
declare let toastr: any;
@Component({
    selector: 'page-editFm',
    templateUrl: './editFm.component.html',
    styles: []
})
export class EditFmComponent implements OnInit {
    @Input() FmId = 0;
    @Output() discard = new EventEmitter<number>();
    @Output() preview = new EventEmitter<number>();
    // =======================
    // General Variables
    // =======================
    currentUser;
    CustomHelper = CustomHelper;
    APPCONFIG = APPCONFIG;
    Permission = 1;

    toastrOption = {timeOut: 5000, progressBar: true, showDuration: 400, "closeButton": true, preventDuplicates: true};

    embed = '';
    src = '';
    runFM(){
        let h = this.CustomHelper.fullHeight(320);
        let fm = '<iframe class="_well rounded ash" style="height : '+h+'" src="'+this.src+'" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe>';
        setTimeout(() => {
            (<any>$('#fmNow')).html(fm);
        }, 100);
    }
    parseSrc(){
        if( this.embed.indexOf('<iframe') >= 0){
            let element = document.createElement('div');
            element.innerHTML = this.embed;
            let imgSrcUrls = element.getElementsByTagName("iframe");
            if(imgSrcUrls.length > 0){
                this.src = imgSrcUrls[0].getAttribute("src");
                this.runFM();
            } else {
                let ___selectedTab = 0;
                const tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) {
                    if ($(v).find('a.active').length > 0) {
                        ___selectedTab = i;
                    }
                });
                const child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#fmModal')).modal('show');
                // swal({
                //     title: 'FM360° Preview',
                //     text: 'The FM360° media may be not valid. Please check the embeded code.',
                //     icon: "error",
                //     dangerMode: true,
                //     className: 'swal-btn-center'
                // });
            }
        } else {
            if($.trim(this.embed) !== ''){
                this.src = this.embed;
                this.runFM();
            } else {
                let ___selectedTab = 0;
                const tabs = $('#sort-tabs').find('li');
                tabs.each(function (i, v) {
                    if ($(v).find('a.active').length > 0) {
                        ___selectedTab = i;
                    }
                });
                const child = ___selectedTab + 1;
                (<any>$('.owl-carousel')).owlCarousel('destroy');
                $('#sort-tabs').find('li').find('a').removeClass('active');
                $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                (<any>$('#fmModal')).modal('show');
                swal({
                    title: 'FM360° Preview',
                    text: 'The FM360° media may be not valid. Please check the embeded code.',
                    icon: "error",
                    dangerMode: true,
                    className: 'swal-btn-center'
                });
            }
        }
        this.getAllFacility();
    }

    constructor() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    }

    Facility = [];
    FacilityComp = [];
    getAllFacility() {
        let THIS = this;
        THIS.Facility.length = 0;
        THIS.FacilityComp.length = 0;
        $.ajax({
            url: THIS.APPCONFIG.api + 'facilities?token=' + THIS.currentUser.token,
            data: {},
            type: 'get',
            success: function (res) {
                THIS.FacilityComp = res.facility;
                $.each(res.facility, function (i, v) {
                    v['pp'] = 0;
                    THIS.Facility.push(v);
                    $.each(v.child, function (j, vC) {
                        vC['pp'] = 1;
                        THIS.Facility.push(vC);
                        $.each(vC.child, function (k, vCC) {
                            vCC['pp'] = 2;
                            THIS.Facility.push(vCC);
                            $.each(vCC.child, function (l, vCCC) {
                                vCCC['pp'] = 3;
                                THIS.Facility.push(vCCC);
                            });
                        });
                    });
                });
                setTimeout(function () {
                    if ($('.reRenderFacility').length > 0) {
                        let facilityId = parseInt($('.reRenderFacility').attr('data-id'));
                        if (facilityId > 0) {
                            THIS.param.facility_id = facilityId.toString();
                        }
                    }
                    (<any>$('.chosen-default-single-fac')).chosen('destroy');
                    (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                    (<any>$('.chosen-default-single-fac')).chosen({
                        allow_single_deselect: true,
                        placeholder_text_single: 'Select Facility'
                    }).on('change', function (e, p) {
                        if (e.target.value === '000') {
                          (<any>$('.chosen-default-single-fac')).val(THIS.param.facility_id);
                          (<any>$('.chosen-default-single-fac')).trigger('chosen:updated');
                            let ___selectedTab = 0;
                            let tabs = $('#sort-tabs').find('li');
                            tabs.each(function (i, v) {
                                if ($(v).find('a.active').length > 0) {
                                    ___selectedTab = i;
                                }
                            });
                            let child = ___selectedTab + 1;
                            (<any>$('.owl-carousel')).owlCarousel('destroy');
                            $('#sort-tabs').find('li').find('a').removeClass('active');
                            $('#sort-tabs').find('li:nth-child(' + child + ')').find('a').addClass('active');
                            (<any>$('#createFacilityModal')).modal('show');

                        } else {
                            THIS.param.facility_id = e.target.value;
                        }
                    });
                }, 100);
            }
        });
    }

    ngOnInit() {
        this.getAllFacility();
        this.getSingleFm();
    }

    FM = null;
    getSingleFm() {
        let THIS = this;
        $.ajax({
            url: THIS.APPCONFIG.api + 'fm360/single?token=' + THIS.currentUser.token,
            data: {uid: THIS.FmId},
            type: 'post',
            success: function (res) {
                if(res.status === 2000){
                    THIS.FM = res.data;
                    THIS.src = res.data.embed;
                    THIS.embed = res.data.embed;
                    THIS.param = {
                        id: res.data.id,
                        facility_id: res.data.facility_id,
                        title: res.data.title,
                        embed: res.data.embed,
                    }
                    THIS.runFM()
                }
            }
        });
    }

    param = {
        id: 0,
        facility_id: '',
        title: '',
        embed: '',
    }
    validationThisForm() {
        let valid = 1;
        if (this.param.title == '') {
            valid = 0;
            swal({
                title: 'FM360° Create',
                text: 'Title is required.',
                icon: "warning",
                dangerMode: true,
                className: 'swal-btn-center'
            });
        }
        if (this.param.facility_id == '') {
            valid = 0;
            swal({
                title: 'FM360° Create',
                text: 'Facility is required.',
                icon: "warning",
                dangerMode: true,
                className: 'swal-btn-center'
            });
        }
        return valid;
    }
    editFm(){
        if(this.validationThisForm() == 1){let THIS = this;
            let ldd = document.getElementById('editFm');
            let ladda = Ladda.create(ldd);
            ladda.start();
            $.ajax({
                url: THIS.APPCONFIG.api + 'fm360/edit?token=' + THIS.currentUser.token,
                type: 'post',
                data: THIS.param,
                success: function (res) {
                    ladda.stop();
                    if (res.status == 2000) {
                        THIS.preview.emit(THIS.FM.uid);
                    }
                  toastr.success(res.msg, 'FM360 Updated', this.toastrOption);
                }
            });
        }
    }

    discardFM(){
        this.discard.emit();
    }
}
